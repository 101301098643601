import * as React from 'react';
import SVGcloses from '../../atoms/SVGIcons/SVGcloses';
import { IFlash } from 'store/FlashMessages';
import wrapIntl from '../../atoms/wrapIntl';
import { IWrapIntl } from '../../atoms/wrapIntl/index';
import { useEffect } from 'react';

interface IFlashMessage {
  messages: IFlash[];
  markAsRead: (index: number) => unknown;
}

const FlashMessages: React.FC<IFlashMessage & IWrapIntl> = ({ messages, markAsRead, keyExists, formatMsg }) => {
  const time = new Date().getUTCSeconds();
  useEffect(() => {
    messages.forEach((value, index) => {
      if (!value.read && time < value.time + 1) {
        setTimeout(() => markAsRead(index), 5000);
      }
    });
  }, [messages]);
  return (
    <div className='flashMessage'>
      <ul>
        {messages.map(
          (m, i) =>
            !m.read && (
              <li key={i} className={m.type}>
                <span>{keyExists(m.text) ? formatMsg(m.text) : m.text}</span>
                <button onClick={() => markAsRead(i)}>
                  <SVGcloses />
                </button>
              </li>
            ),
        )}
      </ul>
    </div>
  );
};

export default wrapIntl(FlashMessages);
