import { compareMatrixData } from 'components/atoms/CompareMatrixData/data';
import { CompareConfig, CompareConfigMatrix } from 'components/components/Compare/data';
import { useEffect, useState } from 'react';
import { Option } from 'react-select';
import { useFetchCompareMatrix } from 'utils/useQuery/queryFetchHelpers';
import { helperFilterFunction, helperFindFunction } from './helpers';

export const useGetCompareMatrix = () => {
  const [compareMatrix, setCompareMatrix] = useState<CompareConfigMatrix[]>([]);

  const compareMatrixFetch = useFetchCompareMatrix();

  useEffect(() => {
    if (compareMatrixFetch.isSuccess) {
      setCompareMatrix(compareMatrixFetch.data);
    }
  }, [compareMatrixFetch.isSuccess, compareMatrixFetch.data]);

  return compareMatrix;
};

export const useGetFilteredMatrix = (
  compareMatrix: CompareConfigMatrix[] | undefined, 
  active: Option | undefined,
) => {
  const [filteredMatrix, setFilteredMatrix] = useState<CompareConfigMatrix>();

  useEffect(() => {
    compareMatrix && active && setFilteredMatrix(compareMatrix.filter(c => c.id === active.value)[0]);
  }, [compareMatrix, active]);

  return filteredMatrix;
};

export const useGetDebouncedTarget = (activeTarget: CompareConfig | undefined) => {
  const [changingActiveTarget, setChangingActiveTarget] = useState<boolean>(false);
  const [delayedActiveTarget, setDelayedActiveTarget] = useState<CompareConfig>();

  useEffect(() => {
    let setTarget: NodeJS.Timeout | null = null;
    setChangingActiveTarget(true);

    if (activeTarget) {
      setTarget = setTimeout(() => {
        setDelayedActiveTarget(activeTarget);
        setChangingActiveTarget(false);
      }, 500);
    }

    return () => {
      setTarget && clearTimeout(setTarget);
    };
  }, [activeTarget]);

  return { delayedActiveTarget, changingActiveTarget };
};

export const useHandleFirstRender = ({
  secondaryLadderId,
  filteredMatrix,
  setActiveTarget
} : {
  secondaryLadderId: string | undefined;
  filteredMatrix: CompareConfigMatrix | undefined;
  setActiveTarget: React.Dispatch<React.SetStateAction<CompareConfig | undefined>>;
}) => {
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    if (!firstRender || !filteredMatrix) return;

    if (secondaryLadderId) 
      setActiveTarget(helperFindFunction(filteredMatrix, secondaryLadderId));
    else
      setActiveTarget(filteredMatrix.targets[0]);

    setFirstRender(false);    
  }, [secondaryLadderId, filteredMatrix, firstRender, setActiveTarget]);
};

export const useSetDefaultLadder = ({
  compareMatrix,
  mainLadderId,
  setActive
}: {
  compareMatrix: CompareConfigMatrix[] | undefined;
  mainLadderId: string | undefined;
  setActive: React.Dispatch<React.SetStateAction<Option | undefined>>;
}) => {
  
  useEffect(() => {
    if (compareMatrix && !!compareMatrix.length) {
      let defaultValue: CompareConfigMatrix;

      if (mainLadderId) 
        defaultValue = helperFilterFunction(compareMatrix, mainLadderId);
      else 
        defaultValue = helperFilterFunction(compareMatrix, compareMatrixData['Moje hodnoty']);  // nastaví Mé hodnoty jako výchozí žebříček
      
      setActive({ value: defaultValue.id, label: defaultValue.name });
    }
  }, [compareMatrix, mainLadderId, setActive]);
};
