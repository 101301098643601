import styled from 'styled-components';
import { textGreen, yellowLighter } from '../../theme/colors';
import { mediaSmDefault } from '../../theme/grid';

export const StyledLogo = styled.span`
  display: flex;
  vertical-align: middle;
  align-items: flex-start; 
  
    ${mediaSmDefault} {
        align-items: center;
    }

  a {
    display: inline-block;
  }

  picture {
    display: inline-block;
    vertical-align: middle;
  }

  img {
    width: 8.2rem;
    display: block;

    ${mediaSmDefault} {
      width: 12.3rem;
    }
  }

  .tag {
    display: inline-flex;
    align-items: center;
    margin-left: 2rem;
    padding: 0.2rem 0.6rem;
    border-radius: 1.5rem;
    background: ${textGreen};
    color: #006644;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.5rem;
    height: 1.8rem;
    vertical-align: middle;
    position: static;
    cursor: pointer;
    transition: background 0.3s;

    &.tag--christmas {
      background: linear-gradient(180deg, #b31ce7 0%, #7f0da4 100%);
      color: #fff;
    }

    &:hover {
      background: ${yellowLighter};
    }

    ${mediaSmDefault} {
      margin-left: 1rem;
      padding: 0.5rem 1rem;
      font-size: 1.8rem;
      height: 2.4rem;
    }
  }
`;

export const BetaTag = styled.div`
  display: inline-block;
  margin-left: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 1.5rem;
  background: ${textGreen};
  color: #006644;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.5rem;
  height: 2.4rem;
  vertical-align: middle;
  position: static;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: ${yellowLighter};
  }
`;
