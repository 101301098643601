import * as React from 'react';
import Select, { ReactSelectProps } from 'react-select';
import { StyledCustomSelect } from './styles';

const CustomSelect: React.FC<ReactSelectProps> = props => (
  <StyledCustomSelect>
    <Select {...props} />
  </StyledCustomSelect>
);

export default CustomSelect;
