import * as React from 'react';
import FormGroupTitle from './FormGroupTitle';
import FormGroup from './FormGroup';
import { remove } from 'utils/option';
import { ReactNode } from 'react';

type InputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

interface IFormGroup {
  className?: string;
  text?: string | ReactNode;
  error?: React.ReactNode;
  withoutLabel?: boolean;
}

export const FormInput: React.FC<IFormGroup & InputProps> = props => {
  return (
    <FormGroup className={props.className} error={props.error}>
      {!props.withoutLabel && <FormGroupTitle text={props.text} id={props.id} required={props.required} />}
      <input {...remove({ ...props }, ['error', 'className'])} />
    </FormGroup>
  );
};

export default FormGroup;
