import * as React from 'react';
import { StyledTextBox } from './style';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';

interface ITextBox {
  className?: string;
  background?: string;
  type?: string;
  text?: string;
  id?: string;
}

export const TextBox: React.FC<ITextBox> = ({ children, background, className, id }) => {
  return (
    <StyledTextBox className={className} background={background} id={id}>
      <Grid>
        <Row>
          <Col xs={12}>{children}</Col>
        </Row>
      </Grid>
    </StyledTextBox>
  );
};

export default TextBox;
