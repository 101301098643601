import { Box, Button, Flex, Icon, Img, Link, Typography, useModal } from '@xcorejs/ui';
import { FreeTag, PlusTag } from 'components/atoms/Logo';
import { RequestContext } from 'components/atoms/RequestContext';
import SVGarrow from 'components/atoms/SVGIcons/SVGarrow';
import { useCarousel } from 'components/components/Carousel/data';
import LifeValuesPresentingModal from 'components/components/modals/LifeValuesPresentingModal';
import { ButtonProps } from 'components/pages/Presenting/data';
import { PresentingButton, PresentingButtonNoLink } from 'components/pages/Presenting/pages';
import React, { FC, Fragment, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import MultiCarousel from 'react-multi-carousel';
import { Swipeable } from 'react-swipeable';
import styled from 'styled-components';
import { useFetchShopSummary } from 'utils/useQuery/queryFetchHelpers';
import LadderIcon from '../icons/24/ladder.svg';
import AllLaddersModalBeta from '../modals/AllLaddersModalBeta';
import PresentingPageModal from '../modals/PresentingPageModal';

export type LocalizedString = {
  cs: string;
  en: string;
};

export type CarouselButtonProps = Omit<ButtonProps, 'text'> & {
  text: LocalizedString;
  presentingId?: number;
  border?: string;
};

export type CarouselProps = {
  img: string;
  imgActive: string;
  heading: LocalizedString;
  boxText: LocalizedString;
  button: CarouselButtonProps;
  bottomText?: LocalizedString;
  link?: {
    text: LocalizedString;
    href: string;
  };
  openModalWithAllLadders?: boolean;
  openModalWithPresenting?: boolean;
  openModalWithGeneric?: boolean;
  isPlus?: boolean;
  displayInCarousel?: boolean;
}

const data: CarouselProps[] = [
  {
    img: '20_Superschopnosti_fin.png',
    imgActive: 'Superschopnosti.png',
    heading: {
      cs:'<span style="color: #F9F5DC">Superschopnosti</span>',
      en: '<span style="color: #F9F5DC">Superpowers</span>',
    },
    boxText: {
      cs: 'Je na čase objevit své skryté vlohy. Každý v něčem vyniká. Znáš dobře své silné stránky? Tohle není klasický hodnotový žebříček, ale ocenit občas sám sebe je velmi důležité!',
      en: 'It is time to discover your hidden talents. Everyone excels at something. Do you know your strengths well? This is not a classic value ladder, but appreciating yourself is very important!',
    },
    button: {
      icon: '',
      text: {
        cs: '<span style="color: #F9F5DC">O silných stránkách</span>',
        en: '<span style="color: #F9F5DC">About strengths</span>'
      },
      background: '#43aba0',
      presentingId: 35,
    },
    isPlus: true,
    openModalWithGeneric: true,
  },
  {
    img: '20_partner_fin.png',
    imgActive: 'Idealni_partner.png',
    heading: {
      cs: '<span style="color: #F9F5DC">Ideální partner</span>',
      en: '<span style="color: #F9F5DC">Ideal partner</span>',
    },
    boxText: {
      cs: 'Jaké hodnoty by měl podle tebe mít? Abys mohl svůj ideál potkat, musíš nejdřív své představy o něm správně pojmenovat.',
      en: 'What values should your ideal partner have? In order to meet your ideal, you must first correctly name your ideas about him.',
    },
    button: {
      icon: '',
      text: {
        cs: '<span style="color: #F9F5DC">Jaký má být?</span>',
        en: '<span style="color: #F9F5DC">How should he be?</span>',
      },
      background: '#c06da5',
      presentingId: 25,
    },
    isPlus: true,
    openModalWithGeneric: true,
  },
  {
    img: '20_zivot_fin.png',
    imgActive: 'zivotni_i.png',
    heading: {
      cs: '<span style="color: #F9F5DC">Životní hodnoty</span>',
      en: '<span style="color: #F9F5DC">Life values</span>',
    },
    boxText: {
      cs: 'Svět se mění a my s ním. Sleduj vývoj svých osobních hodnot dlouhodobě. Každoroční aktualizaci svého hodnotového žebříčku získáš v Eticia <span style="color: #F9F5DC"><strong>zdarma.</span></strong>',
      en: 'The world is changing and so are we. Monitor the development of your personal values in the long term. You will get an annual update of your value ladder <span style="color: #F9F5DC"><strong>for free</span></strong> in Eticia.'
    },
    button: {
      icon: '',
      text: {
        cs: '<span style="color: #F9F5DC">O životních hodnotách</span>',
        en: '<span style="color: #F9F5DC">About life values</span>',
      },
      background: '#3f8f74',
      href: ''
    },
    link: {
      text: {
        cs: 'Mé a životní hodnoty',
        en: 'My and life values'
      },
      href: ''
    },
    openModalWithPresenting: true
  },
  {
    img: '50_spokojenost.png',
    imgActive: 'yel_spokojenost.png',
    heading: {
      cs: '<span style="color: #F9F5DC">Moje spokojenost</span>',
      en: '<span style="color: #F9F5DC">My satisfaction</span>',
    },
    boxText: {
      cs: 'Jak být spokojený? Může to být překvapivě jednoduché. Tento žebříček ti pomůže najít hodnoty, které jsou pro tebe zdrojem štěstí a pohody.',
      en: 'How to be satisfied? It can be surprisingly simple. This ladder will help you find values that are a source of happiness and comfort for you.',
    },
    button: {
      icon: '',
      text: {
        cs: 'Žebříček spokojenost',
        en: 'Satisfaction ladder'
      },
      background: '#F7E199',
      presentingId: 24,
    },
    isPlus: true,
    openModalWithGeneric: true,
  },
  {
    img: '20_deti_fin.png',
    imgActive: 'rodice_deti.png',
    heading: {
      cs: '<span style="color: #F9F5DC">Hodnoty pro děti</span>',
      en: '<span style="color: #F9F5DC">Values for children</span>',
    },
    boxText: {
      cs: 'Děti hodnoty milují. Pro uživatele do 16 let nabízí Eticia speciální, upravenou verzi žebříčku. Své hodnoty mohou děti objevovat samostatně nebo v rámci školní výuky apod.',
      en: 'Children love values. Eticia offers a special, modified version of the ladder for users up to 16 years of age. Children can discover their values independently or as part of school education, etc.'
    },
    button: {
      icon: '',
      text: {
        cs: '<span style="color: #F9F5DC">Pro děti i rodiče</span>',
        en: '<span style="color: #F9F5DC">For children and parents</span>',
      },
      background: '#6ba3c0',
      presentingId: 30,
    },
    isPlus: false,
    openModalWithGeneric: true,
  },
  {
    img: 'prace_50.png',
    imgActive: 'ma_prace.png',
    heading: {
      cs: '<span style="color: #F9F5DC">Má vysněná práce</span>',
      en: '<span style="color: #F9F5DC">My dream job</span>',
    },
    boxText: {
      cs: 'Jaké hodnoty by měla naplňovat práce, kterou doopravdy miluješ? Prací trávíme hromadu času. A měla by nás bavit.',
      en: 'What values should the work that you really love fulfill? We spend a lot of time working. And it should entertain us.',
    },
    button: {
      icon: '',
      text: {
        cs: '<span style="color: #F9F5DC">O žebříčku</span>',
        en: '<span style="color: #F9F5DC">About the ladder</span>',
      },
      background: '#8882AA',
      presentingId: 28,
    },
    isPlus: true,
    openModalWithGeneric: true,
  },
  {
    img: '20_dalsi.png',
    imgActive: 'vsechny_zeb.png',
    heading: {
      cs: '<span style="color: #FFA4A3">... další žebříčky Eticia</span>',
      en: '<span style="color: #FFA4A3">... other Eticia ladders</span>',
    },
    boxText: {
      cs: 'Celý seznam obsahuje 14 zajímavých žebříčků, které jsou užitečné pro nejrůznější životní situace',
      en: 'The full list contains 14 interesting ladders that are useful for various life situations'
    },
    button: {
      icon: '',
      text: {
        cs: '<span style="color: #F9F5DC">Seznam všech žebříčků</span>',
        en: '<span style="color: #F9F5DC">List of all ladder</span>s'
      },
      background: '#2222',
      border: '1px solid #222',
    },
    isPlus: true,
    openModalWithAllLadders: true
  },
];

interface CarouselFCProps {
  presentingV?: boolean;
}

const Carousel: FC<CarouselFCProps> = ({ presentingV, ...props }) => {
  const topCarousel = useCarousel();
  const [activeIndex, setActiveIndex] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [openLifeValuesPresentingModal] = useModal(LifeValuesPresentingModal);
  const [openPresentingPageModal] = useModal(PresentingPageModal);

  const [openAllLaddersBeta] = useModal(AllLaddersModalBeta);

  const inApp = location.pathname.includes('/app');

  const shopSummaryFetch = useFetchShopSummary(inApp);

  useEffect(() => {
    topCarousel.ref.current && topCarousel.ref.current.goToSlide(2);
  }, [topCarousel.ref]);

  useEffect(() => {
    setActiveIndex(() => currentIndex % data.length + 2 >= data.length ?
      currentIndex % data.length + 2 - data.length === 0 ? 0 : 1 : currentIndex % data.length + 2);
  }, [currentIndex]);

  return (
    <Flex
      justifyContent='center'
      alignItems='center'
      position='relative'
      width='100%'
      maxWidth='102.4rem'
      mx='auto'
      flexDirection='column'
    >
      {shopSummaryFetch.data && (
        <Flex alignItems='center' justifyContent='center' mb={{ _: '4rem', sm: '2rem' }}>
          <Typography color='#e7e6cf'>
            <FormattedMessage id='carousel.availableMagicTokens.left' />
          </Typography>

          <Icon svg={<LadderIcon />} mx='1rem' fill='yellow' />

          <Typography fontFamily='OliMedium' color='yellow'>
            <FormattedMessage 
              id='carousel.availableMagicTokens.right'
              values={{ tokens: shopSummaryFetch.data.availableMagicTokenCount }} />
          </Typography>
        </Flex>
      )}

      {!presentingV && (
        <Button
          onClick={openAllLaddersBeta}
          size='md'
          mb={{ _: '4rem', sm: '2rem' }}
          background='rgba(12, 14, 15, 0.4)'
          color='#fff'
          borderColor='#ddd'
          _hover={{
            backgroundColor: 'rgba(12, 14, 15, 0.6) !important',
          }}
        >
          <FormattedMessage id='carousel.createLadder' />
        </Button>
      )}
      
      <Swipeable
        onSwipedLeft={() => {
          topCarousel.next();
        }}
        onSwipedRight={() => {
          topCarousel.prev();
        }}
        style={{ width: '100%' }}
      >
        <CarouselStyle maxWidth='68rem' mx='auto' {...props}>
          <MultiCarousel
            ssr
            swipeable={false}
            draggable={false}
            keyBoardControl={false}
            responsive={{
              desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 5,
              },
              sm: {
                breakpoint: { max: 1023, min: 480 },
                items: 3
              },
              xs: {
                breakpoint: { max: 479, min: 0 },
                items: 3
              }
            }}
            ref={topCarousel.ref}
            arrows={false}
            infinite
            afterChange={ (previousSlide, { currentSlide }) => {
              setCurrentIndex(currentSlide);
            }}
          >
            {data.map((d, index) => (
              <Flex
                alignItems='center'
                justifyContent='center'
                width='100%'
                key={index}
                onClick={() => {                  
                  if (index > activeIndex) {
                    if (activeIndex < 2 && index + 2 >= data.length) {
                      // skok ze začátku na konec
                      if (data.length - activeIndex - index == 1) {
                        topCarousel.prev();
                      } else {
                        topCarousel.prev(),
                        setTimeout(() => topCarousel.prev(), 500);
                      }
                    } else {
                      if (index - activeIndex > 1) {
                        // obyčejný posun doprava
                        topCarousel.next(),
                        setTimeout(() => topCarousel.next(), 500);
                      } else {
                        topCarousel.next();
                      }
                    }
                  } else if (index < activeIndex) {
                    if (data.length - activeIndex <= 2 && index <= 1) {
                      // skok z konce na začátek
                      if (data.length - activeIndex - index == 1) {
                        topCarousel.next();
                      } else {
                        topCarousel.next(),
                        setTimeout(() => topCarousel.next(), 500);
                      }
                    } else {
                      if (activeIndex - index > 1) {
                        // obyčejný posun doleva
                        topCarousel.prev(),
                        setTimeout(() => topCarousel.prev(), 500);
                      } else {
                        topCarousel.prev();
                      }
                    }
                  } else {
                    if (d.openModalWithPresenting != undefined) {
                      openLifeValuesPresentingModal({});
                    } else {
                      openPresentingPageModal({ pageId: d.button.presentingId || 0, loggedUser: inApp });
                    }
                  }                  
                }}
              >
                {index === activeIndex ? 
                  d.openModalWithPresenting != undefined ? (
                    <Img
                      src={`/img/presenting/${d.imgActive}`}
                      display='block'
                      width={{ _: '10.4rem', sm: '11.1rem' } as any}
                      mx='auto'
                      alt=''
                    />
                  ) : (
                    <Img
                      src={`/img/presenting/${d.imgActive}`}
                      display='block'
                      width={{ _: '10.4rem', sm: '11.1rem' } as any}
                      mx='auto'
                      alt=''
                    />
                  )
                  : (
                    <Img
                      src={`/img/presenting/${d.img}`}
                      display='block'
                      width={{ _: '7.8rem', sm: '8.4rem' } as any}
                      mx='auto'
                      alt=''
                    />
                  )}
              </Flex>
            )
            )}
          </MultiCarousel>
        </CarouselStyle>

        <RequestContext>
          {({ locale }) => (
            <Fragment>
              <Flex alignItems='center' position='relative' width='100%'>
                <Flex flexDirection='column' alignItems='center' justifyContent='center' width='100%'>
                  <Typography fontSize={{ _: '1.8rem', sm: '2rem' }} lineHeight='3rem' textAlign='center' color='yellow'>
                    <div dangerouslySetInnerHTML={{ __html: data[activeIndex].heading[locale] }} />
                  </Typography>

                  <Box position='relative' mt='3rem' width={{ _: '100%', sm: 'auto' }} px='4rem'>
                    <Box width='100%' mx='auto'>
                      <Box
                        width='100%'
                        maxWidth='45rem'
                        mx='auto'
                      >
                        <Typography
                          color={{ _: 'rgba(255, 255, 255, 0.8)', sm: '#fff' } as any}
                          fontSize={{ _: '1.4rem', sm: '1.5rem' }}
                          minHeight={{ _: '14rem', sm: '8rem' }}
                          display='flex'
                          alignItems='center'
                        >
                          <div dangerouslySetInnerHTML={{ __html: data[activeIndex].boxText[locale] }} />
                        </Typography>

                        {/*
                        {data[activeIndex].link && (
                          <Flex mt='1rem' justifyContent='center'>
                            <Link href={data[activeIndex].link!.href} v='underline'>
                              {data[activeIndex].link!.text}
                            </Link>
                          </Flex>
                        )}
                        */}
                      </Box>
                    </Box>

                    <Flex
                      position='absolute'
                      width={{ _: 'calc(100% + 2rem)', sm: 'calc(100% + 12rem + 8.6rem)' }}
                      top='50%'
                      left='50%'
                      transform='translateX(-50%) translateY(-50%)'
                      alignItems='center'
                      justifyContent='space-between'
                    >
                      <Box
                        cursor='pointer'
                        opacity={0.3}
                        onClick={() => topCarousel.prev()}
                        _hover={{
                          opacity: 0.8
                        }}
                        height='8rem'
                        transition='opacity 300ms'
                      >
                        <SVGarrow {...{ style: { width: '100%', height: '100%' } } as any} />
                      </Box>

                      <Box
                        cursor='pointer'
                        opacity={0.3}
                        onClick={() => topCarousel.next()}
                        _hover={{
                          opacity: 0.8
                        }}
                        height='8rem'
                        transform='rotate(180deg)'
                        transition='opacity 300ms'
                      >
                        <SVGarrow {...{ style: { width: '100%', height: '100%' } } as any} />
                      </Box>
                    </Flex>
                  </Box>
                </Flex>
              </Flex>

              {!presentingV && (
                <Flex mt={{ _: '3rem', sm: '3rem' }} justifyContent='center'>
                  <Box position='relative'>

                    {data[activeIndex].openModalWithPresenting ? (
                      <Box onClick={openLifeValuesPresentingModal}>
                        <PresentingButton
                          _button={data[activeIndex].button}
                          locale={locale}
                        />
                      </Box>
                    ) : data[activeIndex].openModalWithAllLadders ? (
                      <Box onClick={openAllLaddersBeta}>
                        <PresentingButton
                          _button={data[activeIndex].button}
                          locale={locale}
                        />
                      </Box>
                    ) : data[activeIndex].openModalWithGeneric ? (
                      <Box
                        onClick={() => openPresentingPageModal({
                          pageId: data[activeIndex].button.presentingId || 0,
                          loggedUser: inApp
                        })}>
                        <PresentingButtonNoLink
                          _button={data[activeIndex].button}
                          locale={locale}
                        />
                      </Box>
                    ) : (
                      <PresentingButton
                        _button={data[activeIndex].button}
                        locale={locale}
                      />
                    )}

                    <Flex position='absolute' right='0' top='-15px' transform='scale(0.8)'>
                      <FreeTag>Eticia Free</FreeTag>

                      {data[activeIndex].isPlus && (
                        <PlusTag marginLeft='1rem'>
                        Eticia Plus
                        </PlusTag>
                      )}
                    </Flex>
                  </Box>
                </Flex>
              )}

            </Fragment>
          )}
        </RequestContext>

        {presentingV && (
          <Button
            onClick={openAllLaddersBeta}
            display='flex'
            size='md'
            mx='auto'
            mt={{ _: '4rem', sm: '2rem' }}
            background='rgba(12, 14, 15, 0.4)'
            color='#fff'
            borderColor='#ddd'
            _hover={{
              backgroundColor: 'rgba(12, 14, 15, 0.6) !important',
            }}
          >
            <FormattedMessage id='carousel.createLadder' />
          </Button>
        )}
        
        <Flex
          mt={{ _: '3rem', sm: '3rem' }}
          justifyContent='center'
          alignItems='center'
          cursor='pointer'
          onClick={openAllLaddersBeta}
        >
          <Img
            src='/img/icon_vsechny_zebricky.png'
            alt=''
            width='5rem'
            height='5rem'
            mr='1.2rem'
            display={{ _: 'none', sm: 'flex' }}
          />

          <Link v='underline' color='#B3D2E3' borderColor='#B3D2E3'>
            <FormattedMessage id='carousel.allLadders' />
          </Link>
        </Flex>
      </Swipeable>
    </Flex>
  );
};

export default Carousel;

export const CarouselStyle = styled(Box)`
  position: relative;
  justify-content: center;
    
  .react-multi-carousel-list {
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
    // justify-content: center;
  }
  
  .react-multi-carousel-track {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    position: relative;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    will-change: transform, transition
  }
  
  .react-multi-carousel-dot-list {
    padding: 0;
    position: absolute;
    bottom: 3rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
  }
    
  .react-multi-carousel-item {
    transform-style: preserve-3d;
    backface-visibility: hidden;
    display: flex;
  }
  
  @media all and (-ms-high-contrast:none),
  (-ms-high-contrast:active) {
  .react-multi-carousel-item {
      flex-shrink: 0 !important
    }
  .react-multi-carousel-track {
      overflow: visible !important
    }
  }
  
  @media screen and (min-height: 450px) {
    //touch-action: pan-x;
  }
`;
