import React, { FC, useEffect } from 'react';
import LoadingSpinner from 'components/atoms/Animations/LoadingSpinner';
import { Flex, Heading3, Box, Heading4, Heading2, Heading5, Heading1, Heading6, Paragraph, FlexProps } from '@xcorejs/ui';
import { BlueTheme, BlackTheme } from 'components/theme/colors';
import { TimeNameProps, TimeNumberProps, TimeFlexProps } from './styles';
import { FormattedMessage } from 'react-intl';

interface CountdownProps {
  startTime: number;
  props?: FlexProps;
}

const Countdown: FC<CountdownProps> = ({ startTime, props }) => {
  const [timeToEnd, setTimeToEnd] = React.useState(startTime);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeToEnd((prevTimeToEnd) => {
        if (prevTimeToEnd <= 0) {
          clearInterval(interval);
          return 0;
        }
        return prevTimeToEnd - 1;
      });
    }, 1000);
    
    return () => clearInterval(interval);
  }, []);

  const padStart = (number: number) => {
    return number.toString().padStart(2, '0');
  };

  const days = Math.floor(timeToEnd / 86400);
  // eslint-disable-next-line no-extra-parens
  const hours = Math.floor((timeToEnd % 86400) / 3600);
  // eslint-disable-next-line no-extra-parens
  const minutes = Math.floor(((timeToEnd % 86400) % 3600) / 60);
  // eslint-disable-next-line no-extra-parens
  const seconds = ((timeToEnd % 86400) % 3600) % 60;
    
  return ( 
    <Flex
      alignItems='center'
      justifyContent='center'
      {...props}
    >
      <Flex
        flexDirection='column'
        alignItems='center'
        {...TimeFlexProps}
      >
        <Heading1
          as='p' 
          textAlign='center'
          {...TimeNumberProps}
        >{days}</Heading1>

        <Paragraph {...TimeNameProps}>
          <FormattedMessage id='countdown.days' values={{ days: days }} />
        </Paragraph>
      </Flex>

      <Flex
        flexDirection='column'
        alignItems='center'
        marginLeft={{ _: '1rem', sm: '2rem' }}
        {...TimeFlexProps}
      >
        <Heading1
          as='p'
          textAlign='center'
          {...TimeNumberProps}
        >{padStart(hours)}</Heading1>

        <Paragraph {...TimeNameProps}>
          <FormattedMessage id='countdown.hours' />
        </Paragraph>
      </Flex>

      <Flex
        flexDirection='column'
        alignItems='center'
        marginLeft={{ _: '1rem', sm: '2rem' }}
        {...TimeFlexProps}
      >
        <Heading1
          as='p'
          textAlign='center'
          {...TimeNumberProps}
        >{padStart(minutes)}</Heading1>

        <Paragraph {...TimeNameProps}>
          <FormattedMessage id='countdown.minutes' />
        </Paragraph>
      </Flex>

      <Flex
        flexDirection='column'
        alignItems='center'
        marginLeft={{ _: '1rem', sm: '2rem' }}
        {...TimeFlexProps}
      >
        <Heading1
          as='p'
          textAlign='center'
          {...TimeNumberProps}
        >
          {padStart(seconds)}
        </Heading1>

        <Paragraph
          {...TimeNameProps}
        >
          <FormattedMessage id='countdown.seconds' />
        </Paragraph>
      </Flex>
    </Flex>
  );
};

const CountdownFetch: FC<Pick<CountdownProps, 'props'>> = ({ ...props }) => {
  // const [startTime, setStartTime] = React.useState();
  // const [loading, setLoading] = React.useState(true);
  // const [error, setError] = React.useState(false);

  const [startTime, setStartTime] = React.useState(60 * 60 * 7);
  const [loading, setLoading] = React.useState(false);

  if (loading) return <LoadingSpinner />;
  
  return <Countdown startTime={startTime} {...props} />;
};
 
export default CountdownFetch;