import * as React from 'react';
import { FC, useState, useContext } from 'react';
import { ITestResultPreviewResponse } from 'components/pages/App/TestIntro/data';
import { Button, Flex, Heading2, Icon, Img, Modal, Typography, Paragraph, Link } from '@xcorejs/ui';
import { BlueTheme,  } from 'components/theme/colors';
import { CompareIcon } from '../../icons/16/compare.svg';
import BackIcon from '../../icons/leftArrow.svg';
import { GameContext } from 'components/App';
import EticiaPlusBanner from 'components/molecules/Banners/EticiaPlusBanner';
import { useSelectOption } from './hooks';
import CompareWithoutTest from './CompareWithoutTest';
import CompareWithTest from './CompareWithTest';

export interface LaddersCompareModalProps {
  result: ITestResultPreviewResponse;
}

const LaddersCompareModal: FC<LaddersCompareModalProps> = ({ result }) => {
  const { eticiaPlus } = useContext(GameContext);
  const selectOptions = useSelectOption(result);
  const [activePage, setActivePage] = useState(0);

  const LinkJSX = () => (
    <Link v='underline' marginX='auto' marginTop='2rem' fontSize='1.2rem' onClick={() => setActivePage(1)}>
      Jak takové porovnání vypadá?
    </Link>
  );

  return (
    <Modal
      background={BlueTheme.primary}
      overflowX='hidden'
      paddingTop={{ _: '4rem', sm: '4rem' }}
      paddingX={{ _: '1.6rem', sm: '4rem' }}
      paddingBottom={{ _: '6rem', sm: '6rem' }}
      maxWidth='63rem'
      _close={{
        zIndex: 9999,
      }}
    >
      {activePage === 0 ? (
        <>
          {!eticiaPlus && (
            <EticiaPlusBanner />
          )}
      
          <Flex justifyContent='center' alignItems='center' marginTop={{ _: '1rem' }} position='relative'>
            <Heading2>
        Porovnávač žebříčků
            </Heading2>
          </Flex>

          <Paragraph>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam repellat error excepturi possimus ipsum!
          </Paragraph>

          <Typography
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            mt='3rem'
            px='4rem'
            py='0.5rem'
            color='#fff'
            lineHeight='2'
            borderRadius='0.4rem'
            bg='rgba(11, 12, 28, 0.3)'
            maxWidth='32rem'
            width='100%'
            mx='auto'
          >
            <span style={{ fontWeight: 500 }}>{result.name}</span>
            <span>{new Date(result.doneAt * 1000).toLocaleDateString()}</span>
          </Typography>

          <Flex justifyContent='center' my='1rem'>
            <Icon svg={<CompareIcon />} fill='rgba(254, 254, 254, 0.5)' />
          </Flex>

          {selectOptions && selectOptions.length > 0 ? (
            <CompareWithTest
              selectOptions={selectOptions}
              result={result}
            >
              {LinkJSX()}
            </CompareWithTest>
          ) : (
            <CompareWithoutTest result={result}>
              {LinkJSX()}
            </CompareWithoutTest>
          )}          
        </>
      ) : (
        <>
          <Icon 
            onClick={() => setActivePage(0)} 
            svg={<BackIcon />} 
            position='absolute' 
            zIndex={100}
            left='1.5rem' 
            top='1.5rem'
            cursor='pointer' 
            color={`${BlueTheme.light}`}
          />
          <Img src='/img/journal/porovnani1.jpg' alt='' width='100%' />
          <Button onClick={() => setActivePage(0)}>Zpět</Button>
        </>
      )}
    </Modal>
  );
};

export default LaddersCompareModal;

export const closeModal = (innerFn?: () => void) => {
  const modalWindow = document.querySelector('reach-portal');
  modalWindow && modalWindow.remove();
  innerFn && innerFn();
};
