import { Box, Container, Heading2, Heading3, Icon, Img, Paragraph } from '@xcorejs/ui';
import { IconBlink } from 'components/atoms/Icons/IconBlink';
import TribalTestMissing from 'components/atoms/TribalTestMissing/TribalTestMissing';
import SecondaryValues from 'components/organisms/InfoSlides/ValuePage/SecondaryValues';
import { OliLight } from 'components/theme/fonts';
import { IGameStatus } from 'config/api/game';
import * as React from 'react';
import { Fragment } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import Fade from 'react-reveal/Fade';
import DownIcon from '../../../components/icons/16/down.svg';
import SvetHodnotIcon from '../../../components/icons/48/svethodnot.svg';
import withPopups, { IWithPopups } from '../../../molecules/withRedux/withPopups';
import SliderValues from './Slider/StyledSliderValues';
import ValueCard from './ValueCard/ValueCard';

interface IValuePage {
  gameStatus: IGameStatus;
}

interface IValuePageState {
  detail: string | null;
  primary: boolean;
  gameDetail: IGameStatus | null;
}

// class ValuePage extends React.Component<IValuePage & IWithEticons & IWithPopups, IValuePageState> {
class ValuePage extends React.Component<IValuePage & IWithPopups, IValuePageState> {
  public state: IValuePageState = { 
    detail: null, 
    primary: true, 
    gameDetail: this.props.gameStatus
  };

  private scrollDown = () => {
    window.scrollBy({ top: 300, behavior: 'smooth' });
  }
  
  public render() {
    const { gameStatus: { tribalTestResult : result } } = this.props;

    return (
      <Box pt={{ _: '6rem', md: '12rem' }}>
        <Container maxWidth='85rem' mx='auto' flexDirection='column' mb='6rem' alignItems='center'>
          <Fade ssrReveal bottom duration={1000} distance={'5rem'}>
            <Heading3 as='h1'>
              <Icon svg={<SvetHodnotIcon />} mr='2rem' />
              <FormattedMessage id={'homepage.box.values.heading'} />
            </Heading3>

            <Heading2 mt='4rem'>
              <FormattedMessage id={'homepage.box.values.subheading'} />
            </Heading2>

            <Paragraph mt='1.5rem' color='rgba(255, 255, 255, 0.7)'>
              <FormattedHTMLMessage id={'homepage.box.values.text'} />
            </Paragraph>
 
            {this.props.gameStatus.tribalTestResult ? (
              <Box mt='4rem' position='relative' overflow='hidden'>
                <Img 
                  src='/img/atlas/eticiameter-bg.png'
                  alt='' 
                  width={{ _: '100%', sm: '350px' } as any} 
                  opacity={0.2}
                />

                <Box
                  position='absolute'
                  width='100%'
                  height='100%'
                  left={0}
                  top={0}
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  alignItems='center'
                  style={{
                    backgroundBlendMode: 'darken'
                  }}
                >
                  <Paragraph 
                    fontSize='2.2rem'
                    lineHeight='2.6rem'
                    color='rgba(255, 255, 255, 0.9)' 
                    letterSpacing='1.1px'
                    fontFamily={OliLight}>
                    <FormattedHTMLMessage id='valuePage.valueCard.press1' />
                  </Paragraph>

                  <IconBlink 
                    svg={<DownIcon />}
                    mt='3rem'
                    duration='6s'
                    opacityStart={0.9}
                    opacityEnd={0.3}
                    scale={2} 
                    onClick={this.scrollDown}
                    cursor='pointer'
                  />
                </Box>              
              </Box>
            ) : (
              <TribalTestMissing lighter buttonBackgroundColor='rgb(80, 153, 129)' />
            )}            
          </Fade>
        </Container>

        <SliderValues
          setDetail={(detail: string | null) => this.setState({ detail, primary: true })}
          disabled={this.props.gameStatus.tribalTestResult ? false : true}
          gameStatus={this.props.gameStatus}
        />

        <Container maxWidth='85rem' mx='auto' flexDirection='column' my='6rem' alignItems='center'>
          <Fade ssrReveal bottom duration={1000} distance={'5rem'}>
            <Heading2>
              <FormattedHTMLMessage id='valuePage.otherValues' />
            </Heading2>

            <Paragraph mt='1.5rem' color='rgba(255, 255, 255, 0.7)'>
              <FormattedHTMLMessage id='valuePage.otherValues2' />
            </Paragraph>

            <Box mt='3rem'>
              <SecondaryValues 
                disabled={this.props.gameStatus.tribalTestResult ? false : true}
                setDetail={(detail: string | null) => this.setState({ detail, primary: false })} />
            </Box>
          </Fade>
        </Container>


        {/*
        <div className={'bg-white'}>
          <TextBox className='green'>
            <Fade ssrReveal bottom duration={1000} distance={'5rem'}>
              <BiggerText>
                <FormattedHTMLMessage id='valueWord.inspiration' />
              </BiggerText>

              <Box mt='3rem'>
                <InfoPopupButton type='raw' content={BetaPopup} title={'popup.beta.title'} button={null}>
                  <StyledSecondaryButtonLink as='span' to={''}>
                    <FormattedMessage id='valueWord.inspiration.button' />
                  </StyledSecondaryButtonLink>
                </InfoPopupButton>
              </Box>

              <Heading2>
                <FormattedHTMLMessage id='valueWord.about' />
              </Heading2>

              <BiggerText>
                <FormattedHTMLMessage id='valueWord.about.text' />
              </BiggerText>

              <Row className='videos'>
                <Col xs={12} sm={4}>
                  <Box mt='3rem' onClick={() => popups.custom(() => <VimeoPopup id={'314753998'} />)}>
                    <img src='/img/video/1_video.png' alt='' />
                    <Box mt='1rem'>
                      <SectionText>
                        <FormattedMessage id='valueWord.about.video1' />
                      </SectionText>
                    </Box>
                  </Box>
                </Col>
                <Col xs={12} sm={4}>
                  <Box mt='3rem' onClick={() => popups.custom(() => <VimeoPopup id={'376328496'} />)}>
                    <img src='/img/video/2_video.png' alt='' />

                    <Box mt='1rem'>
                      <SectionText>
                        <FormattedMessage id='valueWord.about.video2' />
                      </SectionText>
                    </Box>
                  </Box>
                </Col>
                <Col xs={12} sm={4}>
                  <Box mt='3rem'>
                    <img src='/img/video/3_video.png' alt='' />

                    <Box mt='1rem'>
                      <SectionText>
                        <FormattedMessage id='valueWord.about.video3' />
                      </SectionText>
                    </Box>
                  </Box>
                </Col>
              </Row>
            </Fade>
          </TextBox>
        </div>
        */}

        {/*
        <div className={'bg-blue'}>
          <TextBox className='blue' background='slider-types-bg' id='value-page-eticons'>
            <Fade ssrReveal bottom duration={1000} distance={'5rem'}>
              <Heading2>
                <FormattedMessage id={'homepage.box.types.heading'} />
              </Heading2>
              <BiggerText>
                <FormattedHTMLMessage id={'homepage.box.types.text'} />
              </BiggerText>
            </Fade>
          </TextBox>

          {eticons !== null ? (
            <Fade ssrReveal bottom duration={1000} distance={'5rem'}>
              <SliderTypes values={denormalize<IEticon>(eticons)} tribal={result} />
            </Fade>
          ) : (
            <LoadingSpinnerLocal />
          )}
        </div>
        */}

        {this.state.detail && (
          <Fragment>
            <ValueCard
              lifeValues={result ? result.lifeValues && result.lifeValues : null}
              value={this.state.detail}
              primary={this.state.primary}
              onClose={() => this.setState({ detail: null })} />
          </Fragment>
        )}
      </Box>
    );
  }
}

// export default withEticons(withPopups(ValuePage));
export default withPopups(ValuePage);
