import { Box, BoxProps, Flex, Heading3, Img, Paragraph, useModal } from '@xcorejs/ui';
import { useGetScrollPosition } from 'components/atoms/hooks/useGetScrollPosition';
import { useGetVisibleArrow } from 'components/atoms/hooks/useGetVisibleArrow';
import { useIsInViewport } from 'components/atoms/hooks/useIsInViewport';
import SVGarrow from 'components/atoms/SVGIcons/SVGarrow';
import EticonModal from 'components/components/modals/EticonModal';
import { OliThin } from 'components/theme/fonts';
import { mediaMdDefault } from 'components/theme/grid';
import React, { FC, RefObject, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { eticonData } from './data';

interface EticonCarouselProps {
  _carousel?: BoxProps;
  _eticonList?: {
    bgEven?: string;
    bgOdd?: string;
  };
}

const EticonCarousel: FC<EticonCarouselProps> = ({ _carousel, _eticonList }) => {
  const ulRef = useRef<HTMLUListElement>(null);
  const liRef = useRef<HTMLLIElement>(null);
  const isInViewport = useIsInViewport(liRef);
  const [openEticonModal] = useModal(EticonModal);
  const position = useGetScrollPosition({ ulRef });
  const visibleArrow = useGetVisibleArrow(position);
  
  useEffect(() => {
    if (liRef.current) 
      isInViewport ? liRef.current.style.width = '0px' : liRef.current.style.width;
  }, [isInViewport]);

  const moveList = (direction: 'left' | 'right', ref: RefObject<HTMLElement>) => {
    const expo = 1.2;
    const step = ref.current ? ref.current.offsetWidth / expo : 0;

    if (ref.current) {
      if (direction === 'left') {
        ref.current.scrollLeft -= step;
      } else if (direction === 'right') {
        ref.current.scrollLeft += step;
      }
    }
  };
  
  const stopPropagationClick = (e:React.MouseEvent<HTMLDivElement, MouseEvent>) => (
    direction: 'left' | 'right',
    ref: RefObject<HTMLElement>
  ) => {
    e.stopPropagation();
    moveList(direction, ref);
  };
  
  return (
    <StyledCarousel {..._carousel}>
      <StyledEticonList 
        ref={ulRef}
        onClick={openEticonModal}
        {..._eticonList}
      >
        <li ref={liRef} style={{ width: '100vw', flexShrink: 0, transition: 'width 2500ms ease' }}></li>
        {eticonData.map((e, i) => {
          return (
            <StyledEticonItem key={e.image}>
              <Img
                src={'/img/eticons/' + e.image}
                alt={e.name}
                display='block'
                width={{ _: '10.4rem', sm: '11.1rem' } as any}
                mx='auto' />
              <Heading3 mt='1rem'>{e.name}</Heading3>
              <Paragraph
                mt='1rem'
                fontFamily={OliThin}
                fontSize={{ _: '1.4rem', md: '1.6rem' }}
                lineHeight={{ _: '1.6rem', md: '1.8rem' }}
                opacity={0.5}>
                {e.values}
              </Paragraph>
            </StyledEticonItem>
          );
        })}
        <Flex
          position='absolute'
          justifyContent='space-between'
          alignItems='center'
          width='100%'
          height='0'
          top='50%'
        >
          <Box
            cursor='pointer'
            opacity={visibleArrow === 'right' ?  0 : 0.3}
            onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => stopPropagationClick(e)('left', ulRef)}
            _hover={{
              opacity: visibleArrow === 'right' ?  0 : 0.8
            }}
            height='8rem'
            transition='opacity 300ms'
          >
            <SVGarrow {...{ style: { height: '100%' } } as any} stroke='white' />
          </Box>

          <Box
            cursor='pointer'
            opacity={visibleArrow === 'left' ?  0 : 0.3}
            onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => stopPropagationClick(e)('right', ulRef)}
            _hover={{
              opacity: visibleArrow === 'left' ?  0 : 0.8
            }}
            height='8rem'
            transform='rotate(180deg)'
            transition='opacity 300ms'
          >
            <SVGarrow {...{ style: { height: '100%' } } as any} stroke='white' />
          </Box>
        </Flex>
      </StyledEticonList>
    </StyledCarousel> 
  );
};
 
export default EticonCarousel;

type StyledEticonListProps = {
  bgEven?: string;
  bgOdd?: string;
}

const StyledCarousel = styled(Box)`
  position: relative;
  width: ${({ width }) => width || '100%'};
  left: ${({ left }) => left || 0};
  bottom: 0;

  ${mediaMdDefault} {
    width: 110rem;
    left: unset;
    bottom: unset;
    margin: auto;
  }
`;

const StyledEticonList = styled.ul<StyledEticonListProps>`
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
  cursor: pointer;

  ::-webkit-scrollbar {
    height: 10px;
  }
  
  li:nth-child(even) {
    background-color: ${({ bgEven }) => bgEven || '#5d7ab1'};
  }
  
  li:nth-child(odd) {
    background-color: ${({ bgOdd }) => bgOdd || '#4f6da6'};
  }
`;

const StyledEticonItem = styled.li`
  flex-shrink: 0;
  width: 14rem;
  padding: 2rem 1rem;

  ${mediaMdDefault} {
    width: 22rem;
    height: 22rem;
  }
`;
