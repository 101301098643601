import { Box, Container, Heading1, Typography, Flex, Img } from '@xcorejs/ui';
import { FC } from 'react';
import * as React from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

const Homepage: FC = () => {
  return (
    <Box position='relative' mt={{ _: '-5rem', md: '-6rem' }}>
      <Box position='absolute' top={{ _: 0, md: '42%' }} left='50%' transform='translate(-50%)' zIndex={10} width={{ _: '100%', md: 'auto' }}>
        <Container justifyContent={{ _: 'center', md: 'flex-start' }}>
          <Fade ssrReveal bottom duration={1000} distance={'5rem'} delay={750}>
            <Flex
              flexDirection={{ _: 'column' }}
              height={{ _: '76vh', md: 'auto' }}
              mt={{ _: '12vh', md: '0' }}
              justifyContent={{ _: 'space-between' }}
              width={{ _: '100%', md: 'auto' }}
            >
              <Heading1
                position='relative'
                textAlign='center'
                color='#fff'
                fontSize={{ _: '4rem', md: '5.5rem' }}
                lineHeight={{ _: '4.2rem', md: '6.5rem' }}
              >
                Svět se točí <br />
                A my s ním...
              </Heading1>

              <Typography
                mt='3.2rem'
                fontSize={{ md: '2rem' }}
                lineHeight={{ md: '2.5rem' }}
                textAlign='center'
                color='yellowWhite'
                maxWidth={{ _: '200px', sm: 'none' }}
                margin={{ _: '0 auto', sm: '3.2rem 0 0' }}
              >
                brzy spustíme novou verzi Eticia 21
              </Typography>
            </Flex>
          </Fade>
        </Container>
      </Box>

      <IframeDesktopStyle
        width='100vw'
        height='100vh'
        position='relative'
        overflow='hidden'
        display={{ _: 'none', md: 'block' }}
      >
        <iframe
          src='https://player.vimeo.com/video/661451515?h=87cb75ab1e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;muted=1&amp;autoplay=1&amp;loop=1&amp;background=1&amp;autoplay=1&amp;loop=1&amp;byline=0'
          frameBorder='0'
          allow='autoplay; fullscreen; picture-in-picture'
          allowFullScreen
          title='Eticia 21'
        />
      </IframeDesktopStyle>

      <IframeMobileStyle
        width='100vw'
        height='100vh'
        position='relative'
        overflow='hidden'
        display={{ _: 'block', md: 'none' }}
      >
        <iframe
          src='https://player.vimeo.com/video/664188716?h=ff2a8d86a2&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;muted=1&amp;autoplay=1&amp;loop=1&amp;background=1&amp;autoplay=1&amp;loop=1&amp;byline=0'
          frameBorder='0'
          allow='autoplay; fullscreen; picture-in-picture'
          allowFullScreen
          title='Eticia 21'
        />
      </IframeMobileStyle>

      <script src='https://player.vimeo.com/api/player.js'></script>
    </Box>
  );
};

export default Homepage;

const IframeDesktopStyle = styled(Box)`
  background: #000;
  
  iframe {
    width: 100vw;
    height: 27.3vw;
    min-height: 100vh;
    min-width: 366vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const IframeMobileStyle = styled(Box)`
  background: #000;
  
  iframe {
    width: 100vw;
    height: 83.1vw;
    min-height: 100vh;
    min-width: 120vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
