import styled, { css } from 'styled-components';
import {
  darkBaseRGB,
  GreenTheme,
  RedTheme,
  selectColor,
  whiteFade,
  gray,
  violet,
  VioletTheme,
  premium,
  premiumDark,
  white,
  primaryGreen,
  darkenGreen,
  darkBaseHEX,
  yellow,
  premiumBlack,
  yellowRGB, BlueTheme, GrayTheme, MidnightTheme,
} from '../../../theme/colors';
import { StyledTextBox } from '../../../organisms/TextBox/style';
import { Heading2, Heading3 } from '../../../atoms/Headings';
import { BiggerText, SectionText } from '../../../atoms/Text';
import { Row, Col } from 'react-styled-flexboxgrid';
import { mediaLgDefault, mediaMdDefault, mediaSmDefault } from '../../../theme/grid';
import { UL1 } from '../../../organisms/VisualValuesComparison/style';
import { CardList } from '../../../organisms/PartnerTest/Result/styles';
import {
  StyledGiftButtonLink,
  StyledPlayButton,
  StyledPrimaryButtonLink,
} from '../../../atoms/Buttons/styles';
import { bold } from '../../../theme/fonts';

export const PositiveValues = styled.div`
  .list-wrapper {
    margin-bottom: 3rem;

    ${mediaMdDefault} {
      margin-bottom: 0;
    }
  }
`;

export const ValuesAdvantages = styled.div`
  span {
    color: ${selectColor};
    font-size: 2rem;
    line-height: 2.5rem;
  }

  p {
    margin-top: 2rem;
    font-size: 1.6rem;
    line-height: 1.8rem;

    ${mediaSmDefault} {
      font-size: 2rem;
      line-height: 2.6rem;
    }
  }
`;

export const ImgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 9rem;
  margin-top: 3rem;
  margin-bottom: 1rem;

  ${mediaMdDefault} {
    margin-top: 0;
  }

  img {
    height: 100%;
  }
`;

export const GraphsSection = styled.section`
  ${StyledTextBox} {
    ${BiggerText} {
      color: ${whiteFade};
    }

    ${Heading3} {
      margin-top: 0;
      margin-bottom: 3rem;
      color: #fff;
      font-weight: 400;
      font-size: 2.4rem;
      letter-spacing: 0.5px;
      line-height: 3rem;
    }

    ${CardList} {
      margin-bottom: 4rem;

      ${mediaMdDefault} {
        margin-bottom: 0;
      }
    }
  }

  .alignCenter {
    ${Row as any} {
      align-items: center;
    }
  }

  ${UL1} {
    display: inline-block;

    li {
      color: #fff;
      font-size: 1.6rem;
      line-height: 2rem;
    }

    ${mediaMdDefault} {
      margin-bottom: 0;
    }
  }
`;

export const GraphEticon = styled.div<{ color: 'green' | 'blue' }>`
  display: block;
  padding: 3rem;
  position: relative;
  max-width: 30rem;
  margin: 0 auto;
  border-radius: 0.4rem;
  box-shadow: 0 5px 10px 0 rgba(${darkBaseRGB}, 0.4);

  ${props =>
    props.color === 'green' &&
    css`
      background: radial-gradient(circle at top, ${GreenTheme.primary} 0%, ${GreenTheme.darken} 100%);
    `};

  ${props =>
    props.color === 'blue' &&
    css`
      background: radial-gradient(circle at top, ${BlueTheme.primary} 0%, ${BlueTheme.darken} 100%);
    `};

  &:after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(${darkBaseRGB}, 0.15);
  }

  strong {
    display: block;
    position: relative;
    margin-bottom: 1.5rem;
    font-size: 2.4rem;
    letter-spacing: 0.5px;
    line-height: 3.1rem;
    z-index: 1;
  }

  img {
    display: block;
    position: relative;
    max-width: 16rem;
    margin: 0 auto;
    z-index: 1;
  }
`;

export const FlexOrder = styled.div`
  ${Row as any} {
    ${Col as any} {
      order: 3;

      &:first-child {
        order: 1;
      }

      &:last-child {
        order: 2;
      }

      ${mediaSmDefault} {
        order: 2;

        &:first-child {
          order: 1;
        }

        &:last-child {
          order: 3;
        }
      }
    }
  }
`;

export const HowItWorksSection = styled.section`
  ${BiggerText} {
    color: ${whiteFade};
  }

  ${ValuesAdvantages} {
    margin-top: 3rem;

    ${mediaSmDefault} {
      margin-top: 4rem;
    }

    ${ImgWrapper} {
      height: 4.6rem;

      ${mediaSmDefault} {
        height: 7.6rem;
      }
    }

    span {
      color: ${whiteFade};
    }
  }
`;

export const StyledDiscover = styled.section`
  ${StyledTextBox} {
    padding-bottom: 0;

    ${Heading2} {
      ${mediaMdDefault} {
        padding-top: 8rem;
        font-size: 3.2rem;
        line-height: 3.9rem;
      }
    }
  }

  ${SectionText} {
    margin-top: 1rem;
    font-size: 1.8rem;
    line-height: 2rem;
  }

  .boxes {
    margin-top: 3rem;

    ${Col} {
      padding: 3rem 2rem;

      ${mediaSmDefault} {
        padding: 5rem 2rem;
      }

      &:nth-child(odd) {
        background-color: rgba(16, 17, 37, 0.5);
      }

      &:nth-child(even) {
        background-color: rgba(25, 37, 105, 0.5);
      }

      img {
        display: block;
        margin: 0 auto 1rem;
        width: 4.8rem;
        height: 4.8rem;
        opacity: 0.5;
      }
    }
  }
`;

export const TopicsSection = styled.section`
  ${BiggerText} {
    color: ${whiteFade};
  }
`;

export const TopicsSlider = styled.div`
  display: flex;
  align-items: center;

  svg {
    min-width: 2.8rem;
    transition: transform 0.3s;
    cursor: pointer;

    &:first-child {
      margin-right: 3rem;

      @media (min-width: 1500px) {
        margin-right: 6rem;
      }
    }

    &:last-child {
      margin-left: 3rem;
      transform: rotate(180deg);

      @media (min-width: 1500px) {
        margin-left: 6rem;
      }
    }

    &:hover {
      &:first-child {
        transform: translateX(-1rem);
      }

      &:last-child {
        transform: rotate(180deg) translateX(-1rem);
      }
    }
  }

  ${Row} {
    flex: 1 1 auto;
  }

  @media (min-width: 1500px) {
    //margin: 0 -8.8rem;
  }
`;

export const Circle = styled.div<{ color: 'purple' | 'orange' | 'green' | 'blue' | 'red' | 'black' }>`
  position: relative;
  box-shadow: 0 0.5rem 1rem 0 rgba(${darkBaseRGB}, 0.4);
  width: 10rem;
  height: 10rem;
  margin: 0 auto;
  border-radius: 50%;
  transition: box-shadow 0.3s;

  ${mediaSmDefault} {
    width: 19rem;
    height: 19rem;
  }

  ${mediaMdDefault} {
    width: 12rem;
    height: 12rem;
  }

  ${mediaLgDefault} {
    width: 18rem;
    height: 18rem;
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 60%;
    max-height: 60%;
    transform: translateX(-50%) translateY(-50%);
  }

  ${props =>
    props.color === 'purple' &&
    css`
      background: radial-gradient(circle at top, ${VioletTheme.primary} 0%, ${VioletTheme.darken} 100%);
    `};

  ${props =>
    props.color === 'orange' &&
    css`
      background: radial-gradient(circle at top, #cb7a24 0%, #b46918 100%);
    `};

  ${props =>
    props.color === 'green' &&
    css`
      background: radial-gradient(circle at top, ${GreenTheme.primary} 0%, ${GreenTheme.darken} 100%);
    `};

  ${props =>
    props.color === 'blue' &&
    css`
      background: radial-gradient(circle at top, ${BlueTheme.primary} 0%, ${BlueTheme.darken} 100%);
    `};

  ${props =>
    props.color === 'red' &&
    css`
      background: radial-gradient(circle at top, ${RedTheme.primary} 0%, ${RedTheme.darken} 100%);
    `};

  ${props =>
    props.color === 'black' &&
    css`
      background: radial-gradient(circle at top, ${GrayTheme.primary} 0%, ${GrayTheme.darken} 100%);
    `};
`;

export const CircleDescription = styled.div`
  margin-top: 2rem;
  margin-bottom: 3rem;

  ${mediaSmDefault} {
    margin-top: 3rem;
  }

  strong {
    display: block;
    margin-bottom: 1.5rem;
    color: #fff;
    font-size: 1.8rem;
    line-height: 2.3rem;
  }

  p {
    margin: 0;
    opacity: 0.8;
    color: ${gray};
    font-size: 1.6rem;
    line-height: 2rem;
  }

  ${mediaMdDefault} {
    margin-bottom: 0;
  }
`;

export const ValuesAsGiftSection = styled.section`
  ${BiggerText} {
    color: ${selectColor};
  }

  ${StyledTextBox} {
    background: ${gray};

    ${Heading2} {
      color: ${violet};
    }
  }
`;

export const StyledLifeValuesSection = styled.section`
  ${BiggerText} {
    color: ${whiteFade};
  }

  ${StyledTextBox} {
    padding-bottom: 0;
    background: radial-gradient(circle at top, ${GreenTheme.primary} 0%, ${GreenTheme.darken} 100%);
  }
`;

export const StyledPremiumStrip = styled.section`
  padding: 5rem 0;
  background: radial-gradient(circle at top, ${premium} 0%, ${premiumDark} 100%);
  font-size: 1.8rem;
  line-height: 2.4rem;

  .inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  ${StyledPlayButton} {
    margin: 1.5rem;
  }

  .flex {
    flex-direction: column;

    ${mediaSmDefault} {
      flex-direction: row;
    }
  }
`;

export const StyledLadders = styled.section`
  ${StyledTextBox} {
    background: radial-gradient(circle at top, ${primaryGreen} 0%, ${darkenGreen} 100%);

    ${mediaMdDefault} {
      padding-bottom: 7rem;
    }

    ${Heading2} {
      &:first-child {
        color: ${darkBaseHEX};

        ${mediaMdDefault} {
          padding-top: 8rem;
          font-size: 3.2rem;
          letter-spacing: 0.5px;
          line-height: 3.9rem;
        }
      }

      &:nth-child(2) {
        color: ${white};

        ${mediaMdDefault} {
          padding-top: 5rem;
        }
      }
    }

    ${Heading3} {
      margin-top: 1rem;
      color: rgba(255, 255, 255, 0.7);
      font-size: 1.6rem;
      line-height: 2.4rem;
      letter-spacing: 0.5px;
      font-weight: 400;

      ${mediaSmDefault} {
        font-size: 2.4rem;
        line-height: 2.9rem;
      }
    }
  }

  .img {
    margin-top: 4rem;

    img {
      display: block;
      width: 100%;
      max-width: 85rem;
      margin: 0 auto;
    }
  }

  .knowledge {
    margin-top: 4rem;

    ${Heading3} {
      color: ${yellow};
      font-size: 2.4rem;
      line-height: 3.2rem;
    }

    ${UL1} {
      padding: 0 3rem;

      ${mediaSmDefault} {
        padding: 0;
      }

      li {
        justify-content: center;

        span {
          margin-top: 0;

          ${mediaSmDefault} {
            font-size: 1.8rem;
            line-height: 1.8rem;
          }
        }
      }
    }

    ${ValuesAdvantages} {
      padding: 0 1.6rem;
      margin-top: -2rem;
      margin-bottom: 4rem;

      ${mediaSmDefault} {
        padding: 0;
        margin-top: 2rem;
        margin-bottom: 6rem;
      }
    }
  }

  .btn-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > a,
    & > button {
      margin: 1rem 1.5rem;
      width: 100%;

      ${StyledPrimaryButtonLink}, ${StyledGiftButtonLink} {
        width: 100%;
      }

      ${mediaSmDefault} {
        font-size: 2.2rem;
        letter-spacing: 0.5px;
        line-height: 2.8rem;
        width: auto;

        ${StyledPrimaryButtonLink}, ${StyledGiftButtonLink} {
          width: auto;
        }
      }
    }

    ${mediaMdDefault} {
      flex-direction: row;
    }
  }
`;

export const StyledVariants = styled.div`
  ${Heading2} {
    color: ${premiumBlack};
  }

  .boxes {
    ${mediaLgDefault} {
      padding: 0 6.8rem;

      ${Col} {
        padding-right: 4.2rem;
        padding-left: 4.2rem;
      }
    }
  }

  .card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    padding-bottom: 5rem;
    margin: 0 auto;
    max-width: calc(100% - 3rem);
    border-radius: 1.2rem;

    &.card--blue {
      background: radial-gradient(circle at top, ${MidnightTheme.primary} 0%, ${MidnightTheme.darken} 100%);
    }

    &.card--green {
      background: radial-gradient(circle at top, #1d7c5a 0%, #0b1c15 100%);
    }

    &.card--cyan {
      background: radial-gradient(circle at top, #1d757c 0%, #0b1c1a 100%);
      margin-bottom: 3rem;

      ${mediaSmDefault} {
        margin-bottom: 0;
      }
    }

    ${mediaMdDefault} {
      padding: 3rem 3rem 5rem;
      max-width: 100%;
    }

    svg {
      height: 3.2rem;

      path {
        fill: rgba(255, 255, 255, 0.5);
      }
    }

    ${Heading3} {
      margin-top: 1rem;
      color: #fff;
      font-size: 2.4rem;
      line-height: 3.2rem;
      text-align: center;
      font-weight: 400;
    }

    img {
      margin: 1rem auto;
    }

    ${SectionText} {
      margin-top: 1rem;
      margin-bottom: 0;
      color: rgba(255, 255, 255, 0.5);
      font-size: 1.6rem;
      line-height: 2.4rem;
    }

    .price {
      margin-top: 1rem;
      color: ${yellow};
      font-size: 2.4rem;
      line-height: 3.2rem;
      margin-bottom: 1rem;

      ${mediaSmDefault} {
        margin-top: 3rem;
        margin-bottom: 2rem;
      }
    }

    ${mediaSmDefault} {
      ${StyledPrimaryButtonLink} {
        font-size: 2.2rem;
        letter-spacing: 0.5px;
        line-height: 2.8rem;
        padding-left: 5rem;
        padding-right: 5rem;
      }
    }
  }

  .card-info {
    margin-top: 3rem;
    margin-bottom: 5rem;

    ${mediaMdDefault} {
      margin-bottom: 0;
    }

    ${SectionText} {
      margin-bottom: 1rem;
      padding: 0 3rem;
      opacity: 0.7;
      color: #242941;
      font-size: 1.6rem;
      line-height: 2.4rem;
    }

    .variant {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 1rem;
      color: rgba(${darkBaseRGB}, 0.5);
      font-size: 1.6rem;
      line-height: 2.4rem;

      ${mediaSmDefault} {
        margin-top: 2rem;
      }

      strong {
        display: block;
      }

      strong,
      .c2a {
        color: ${premiumBlack};
        font-size: 2.2rem;
        line-height: 3.2rem;
        font-weight: ${bold};
      }

      .c2a {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 2.5rem;
        width: 13.5rem;
        height: 5rem;
        border: 1px solid ${yellow};
        border-radius: 3.5rem;
        background-color: rgba(${yellowRGB}, 0.25);

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
`;
