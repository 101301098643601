import * as React from 'react';
import { StyledInfoMessage } from './styles';
import SVGcloses from '../../atoms/SVGIcons/SVGcloses';
import { Col, Row, Grid } from 'react-styled-flexboxgrid';
import ExternalSVG from '../../atoms/ExternalSVG';
import { useState } from 'react';
import { useRequest } from '../../atoms/RequestContext/index';

const InfoMessage: React.FC = ({ children }) => {
  const [state, setState] = useState<{ cookie: boolean }>({
    cookie: false,
  });
  const { cookies, setCookie } = useRequest();

  const onClose = () => {
    setState({ cookie: true });
    setTimeout(() => setCookie('infoMessage', '1', { expires: 365 }), 1000);
  };

  return !Object.keys(cookies).includes('infoMessage') ? (
    <StyledInfoMessage closed={state.cookie}>
      <Grid>
        <Row>
          <Col xs={12}>
            <div>
              <ExternalSVG src={'/img/result/results-bell.svg'} />
              {children}
            </div>

            <span className='close' onClick={() => onClose()}>
              <SVGcloses />
            </span>
          </Col>
        </Row>
      </Grid>
    </StyledInfoMessage>
  ) : null;
};

export default InfoMessage;
