import { BlueTheme, whiteFade } from 'components/theme/colors';
import { mediaMdDefault } from 'components/theme/grid';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledNavigation = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: #0b0c1c;
  z-index: 1003;
  height: 6rem;
  width: 100%;

  ${mediaMdDefault} {
    height: 6rem;
  }

  .active {
    opacity: 1;
  }
`;

interface MobileMenuProps {
  isMobileOpen: boolean;
}

export const MobileMenu = styled.div<MobileMenuProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 6rem;
    
  div {
    height: 6rem;
    width: 7rem;
    align-self: end;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.7rem;

    span {
      width: 4rem;
      height: 0.2rem;
      background-color: ${BlueTheme.darken};
    }
  }

  ul {
    display: ${props => props.isMobileOpen ? 'flex' : 'none'};
    flex-direction: column;
    align-items: center;
    width: 100%;

    li {
      background-color: #1d1c21cc;
      border-bottom: 2px outset ${BlueTheme.darken};
      display: flex;
      align-items: center;
      padding-right: 2rem;
      width: 100%;
      height: 5rem;
      transition: border-color 300ms ease;
      
      a {
        color: ${whiteFade};
        width: 100%;
        text-align: right;
        opacity: 0.8;
        transition: opacity 300ms ease;
  
        &:hover {
          opacity: 1;
        }
      }

      &:hover {
        border-color: ${whiteFade};
      }
    }      
  }

  ${mediaMdDefault} {
    display: none;
  }
`;

export const DesktopMenu = styled.ul`
  display: none;

  ${mediaMdDefault} {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 5rem;
  
    li {
      a {
        color: ${whiteFade};
        width: 100%;
        opacity: 0.8;
        transition: opacity 300ms ease;
  
        &:hover {
          opacity: 1;
        }
      }
    }
  }
`;

interface LogoProps {
  showLogo: boolean;
}

export const Logo = styled.img<LogoProps>`
  position: absolute;
  width: auto;
  height: 2rem;
  left: 2rem;
  top: 2rem;
  z-index: 1004;

  ${mediaMdDefault} {
    height: 3rem;
    top: 1.5rem;
    left: 1rem;
  }

  transition: opacity 300ms ease-in-out;
  opacity: ${({ showLogo }) => showLogo ? 1 : 0};
`;

export const StyledLink = styled(Link)`
  position: absolute;
  top: 0.9rem;
  right: 1rem;
  display: none;

  ${mediaMdDefault} {
    display: inline-flex;
  }
`;