import React from 'react';
import { Modal, Paragraph, Flex, Heading2, Img } from '@xcorejs/ui';
import { BlueTheme } from 'components/theme/colors';
import { FormattedMessage } from 'react-intl';

const EticonModal = () => {
  return ( 
    <Modal background={BlueTheme.primary}>
      <Flex flexDirection='column' alignItems='center' justifyContent='center'>
        <Img src='/img/homepage/80x80_etikony.png' alt='eticon' />
        <Heading2 mt='4rem'>
          <FormattedMessage id='homepage.box.eticon.modal.heading' />
        </Heading2>
        <Paragraph mt='2rem' fontSize={{ _: '14px', md: '16px' }}>
          <FormattedMessage id='homepage.box.eticon.modal.text' />
        </Paragraph>
      </Flex>
    </Modal>
  );
};
 
export default EticonModal;