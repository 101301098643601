import React, { FC } from 'react';
import { InterestingCardProp } from './data';
import { TestTip } from './styles';
import { Heading3, Flex, Paragraph, Button, useModal, Img } from '@xcorejs/ui';
import { FormattedMessage } from 'react-intl';
import { yellowWhite } from 'components/theme/colors';
import ComparisonModal from 'components/components/modals/HelperModals/Comparison/ComparisonModal';

export interface TestTipCardProps {
  card: InterestingCardProp;
  group: 'personal' | 'multiple';
}

const TestTipCard: FC<TestTipCardProps> = ({ card, group }) => {
  const [openComparisonModal] = useModal(ComparisonModal);
  const [close] = useModal();
    
  return ( 
    <TestTip backgroundColor={card.backgroundColor}>
      <Img src={card.icon} alt='ikona porovnání' maxHeight='10rem' />
      
      <Heading3 color='black' flexGrow={2} display='flex' alignItems='center' marginTop='2rem'>
        <FormattedMessage id={`${card.i18nId}.title`} />
      </Heading3>

      <Flex
        flexDirection='column'
        justifyContent='space-between'
        alignItems='center'
        flexGrow={1}
        marginTop='2rem'
      >
        <Paragraph 
          color={yellowWhite}
          maxWidth={{ _:'20ch', md: '20ch' }}
        >
          <FormattedMessage id={`${card.i18nId}.text`} />
        </Paragraph>

        <Button
          onClick={() => openComparisonModal({ id: card.groupId, group, close })}
          height='auto'
          marginTop='1rem'
          fontSize='1.4rem'
          lineHeight='1.6rem'
          padding='1rem 3rem'
        >O porovnání</Button>
      </Flex>
    </TestTip>
  );
};
 
export default TestTipCard;
