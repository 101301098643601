import React, { useCallback, FC } from 'react';
import { StyledGrid } from './styles';
import { data } from './data';
import FlipCard from './FlipCard';

export interface FlipCardsBlockProps {
  onClose:  (innerFunction?: (() => void) | undefined) => void;
}

const FlipCardsBlock: FC<FlipCardsBlockProps> = ({ onClose }) => {
  const [flippedCard, setFlippedCard] = React.useState<boolean[]>(Array(data.length).fill(false));
  
  const flipCard = useCallback((index: number) => {
    const newFlippedCard = Array(data.length).fill(false);
    newFlippedCard[index] = true;
    setFlippedCard(newFlippedCard);
  }, []);

  const resetFlipCard = useCallback(() => {
    setFlippedCard(Array(data.length).fill(false));
  }, []);
  
  return ( 
    <StyledGrid>
      {data.map(({ className, ...rest }, index) => (
        <FlipCard
          key={index}
          {...rest}
          className={`${className}${flippedCard[index] ? ' flipped' : ''}`}
          onClick={() => flipCard(index)}
          onMouseLeave={() => resetFlipCard()}
          onClose={onClose}
        />
      ))}
    </StyledGrid> 
  );
};
 
export default FlipCardsBlock;