export const queryKeys = {
  VALUE_TEST_RESULTS_TRIBAL: 'valueTestResultsTribal',
  GAME_STATUS: 'gameStatus',
  STATISTICS_GROUP_MATCH_TEST: 'statistics',
  CURRENT_PROFILE_DETAIL: 'profileDetail',
  CURRENT_PROFILE: 'profile',
  STATISTICS_LIFE_VALUES: 'statLifeValues',
  STATISTICS_GROUP_MATCH_PROFILE: 'groupMatchProfile',
  JOURNAL: 'journal',
  LIFE_VALUES_DETAIL: 'lifeValuesDetail',
  SURVEY_RESULTS: 'surveyResults',
  TEST_RESULT_DETAIL: 'testResultDetail',
  COMPARE_MATRIX: 'compareMatrix',
  SHOP_SUMMARY: 'shopSummary',
  SEMAPHORE: 'semaphore',
  LIFE_VALUES: 'lifeValues',
} as const;

export const queryKeysAll = Object.values(queryKeys);
