import { Box, Flex, Icon, Img, Paragraph } from '@xcorejs/ui';
import AnimatedArrow from 'components/atoms/Icons/AnimatedArrow';
import StartPlay, { SalmonColor } from 'components/atoms/StartPlay/StartPlay';
import { createUrl } from 'components/pages/routes';
import { RedTheme, yellowRGB } from 'components/theme/colors';
import { OliThin } from 'components/theme/fonts';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { Swipeable } from 'react-swipeable';
import HutIcon from '../../../../public/img/hodnotova-hra-presenting/chyse-staresinu.svg';
import RewardIcon from '../../../../public/img/hodnotova-hra-presenting/co-ziskas.svg';
import HowToIcon from '../../../../public/img/hodnotova-hra-presenting/jak-hrat.svg';
import { StyledCard, StyledList } from './styles';

const GameInfoCarousel: FC<{onlyInnerArrow?: boolean}> = ({ onlyInnerArrow }) => {
  const flexRef = React.useRef<HTMLDivElement>(null);
  const { active, changeActive } = useChangeActive(flexRef);
  const [currentOpacity, setCurrentOpacity] = React.useState<number>(0);
  const [recentlyClicked, setRecentlyClicked] = React.useState<boolean>(false);

  const arrowStyle = {
    paddingY: '30rem',
    top: '-30%',
    cursor: 'pointer',
    transform: { _:'scale(0.5)', md: 'scale(0.7)' },
  };
  // const arrowSidePosition = { _: '-40px', md: '-50px' };
  const arrowSidePosition = { _: '0px', md: '-50px' };

  React.useEffect(() => {
    setTimeout(() => setCurrentOpacity(1), 1000);
  }
  , []);

  const restartAnimations = (name: string): void => {
    for (const animation of document.getAnimations()) {
      // eslint-disable-next-line no-extra-parens
      if ((animation as any).animationName === name) {
        animation.cancel();
        animation.play();
      }
    }
  };

  const handleClick = (direction: 'left' | 'right') => {
    if (recentlyClicked) return;
    changeActive(direction);
    restartAnimations('move');
    setRecentlyClicked(true);
    setTimeout(() => setRecentlyClicked(false), 500);
  };

  return ( 
    <Box
      color='#FFF'
      width='100%'
      position='relative'
      opacity={currentOpacity}
      transition='opacity 1s ease-in-out'
    >
      <Swipeable
        onSwipedLeft={() => handleClick('right')}
        onSwipedRight={() => handleClick('left')}
        style={{ width: '100%' }}
      >
        <Flex
          ref={flexRef}
          width='100%'
          overflow='hidden'
          pb={{ _: '2rem' }} >
          <Hut />
          <Reward />
          <HowTo />
        </Flex>

        <Box
          position='absolute'
          onClick={() => handleClick('left')}
          display={active === 0 ? 'none' : 'block'}
          left={arrowSidePosition}
          {...arrowStyle}
        >
          <AnimatedArrow transform='rotate(180deg)' innerColor={RedTheme.primary} onlyInner={onlyInnerArrow} />
        </Box>

        <Box
          position='absolute'
          display={active === 2 ? 'none' : 'block'}
          onClick={() => handleClick('right')}
          right={arrowSidePosition}
          {...arrowStyle}
        >
          <AnimatedArrow innerColor={RedTheme.primary} onlyInner={onlyInnerArrow} />
        </Box>
      </Swipeable>
    </Box>
  );
};
 
export default GameInfoCarousel;

const iconStyle = {
  mt: { _: '2rem' },
};

const paragraphStyle = {
  mt: { _: '2rem', md: '3rem' },
  fontFamily: OliThin,
  fontSize: { _: '1.4rem', md: '1.6rem' },
};

const Hut: FC = () => {
  return (
    <StyledCard>
      <Icon svg={<HutIcon />} fill='#3f9578' transform={{ _: 'scale(1.2)' }} {...iconStyle} />

      <Paragraph {...paragraphStyle}>
        <FormattedMessage id='gameInfoCarousel.hut.paragraph' />
      </Paragraph>
          
      <Img src='/img/game/chyse_320x120.png' alt='chyse' height='85px' />

      <Box position='relative' paddingBottom='4rem'>
        <Paragraph color={yellowRGB} {...paragraphStyle}>
          <FormattedMessage id='gameInfoCarousel.hut.paragraph2' />
        </Paragraph>

        <Img src='/img/eticiam.png' alt='eticiam' position='absolute' transform='rotate(-30deg)' top='40px' right='-40px' zIndex={1} />
      </Box>
    </StyledCard>
  );
};

const Reward: FC = () => {
  return (
    <StyledCard>
      <Icon svg={<RewardIcon />} fill={RedTheme.primary} transform={{ _: 'scale(1.2)' }} {...iconStyle} />

      <Paragraph {...paragraphStyle}>
        <FormattedMessage id='gameInfoCarousel.reward.paragraph' />
      </Paragraph>

      <StyledList
        marker='/img/24/box.svg'
        markerColor='#FFF'
        fontSize={{ _: '1.4rem' }}>
        <li><FormattedMessage id='gameInfoCarousel.reward.list1' /></li>
        <li><FormattedMessage id='gameInfoCarousel.reward.list2' /></li>
        <li><FormattedMessage id='gameInfoCarousel.reward.list3' /></li>
      </StyledList>

      <Paragraph color={RedTheme.primary} {...paragraphStyle}>
        <FormattedMessage id='gameInfoCarousel.reward.paragraph2' />
      </Paragraph>
    </StyledCard>
  );
};

const HowTo: FC = () => {
  const location = useLocation();
  const history = useHistory();

  return (
    <StyledCard>
      <Icon svg={<HowToIcon />} fill={SalmonColor} transform={{ _: 'scale(0.9)' }} {...iconStyle} />

      <Paragraph {...paragraphStyle}>
        <FormattedMessage id='gameInfoCarousel.howTo.paragraph' />
      </Paragraph>

      <Paragraph color={SalmonColor} {...paragraphStyle}>
        <FormattedMessage id='gameInfoCarousel.howTo.paragraph2' />
      </Paragraph>

      <StartPlay
        onClick={() => {
          const iframe = window.top !== window.self;
          const iframeCondition = location.pathname.includes(createUrl('valueGame'));

          if (location.pathname.includes('/app') || iframe) {
            window.sessionStorage.setItem('hasClickedStart', 'true');
            window.sessionStorage.setItem('hasPlayedSession', 'true');
            window.dispatchEvent(new Event('storage'));

            if (iframeCondition) {
              window.top.postMessage({ type :'closeRedirect_' + new URL(window.location.origin + createUrl('app.gameGame')) }, '*');
            }
          } else {
            history.push({
              pathname: createUrl('register'),
              state: { redirect: createUrl('app.gameGame') }
            });
          }
        }}
      />
    </StyledCard>
  );
};

const useChangeActive = (ref: React.RefObject<any>) => {
  const [active, setActive] = React.useState<number>(0);
  
  const changeActive = (direction: 'left' | 'right') => {
    if (ref.current) {      
      const { clientWidth } = ref.current;
      
      ref.current.scrollBy({
        left: direction === 'left' ? -clientWidth : direction === 'right' ? clientWidth : 0,
        behavior: 'smooth',
      });

      direction === 'left' && setActive(prev => prev === 0 ? 0 : prev - 1);
      direction === 'right' && setActive(prev => prev === 2 ? 2 : prev + 1);
    }
  };

  return { active, changeActive };
};