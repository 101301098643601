import styled, { css } from 'styled-components';

import { StyledFakeLinkBorder, StyledLinkBorder } from '../../atoms/Buttons/styles';
import { Heading1, Heading2, Heading3 } from '../../atoms/Headings';
import { BackgroundedText, SectionText } from '../../atoms/Text';
import { PaymentInfoPrice } from '../../pages/App/ValueComparison/Comparisons/style2';
import {
  blue,
  BlueTheme,
  darkBaseRGB,
  darkenGreen, GoldTheme,
  gray,
  grayRGB,
  GreenTheme,
  lightGrayBG,
  primaryGreen,
  RedTheme,
  textGreen,
  white,
  yellow,
} from '../../theme/colors';
import { mediaHonzaDefault, mediaLgDefault, mediaMdDefault, mediaSmDefault, mediaSmMax } from '../../theme/grid';
import { StyledTestInfoCard } from '../../atoms/Cards/styles';
import { StyledImgSlider } from '../Slider/styles';
import { Col, Grid } from 'react-styled-flexboxgrid';

export const BtnGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;

  ${mediaSmDefault} {
    margin-top: 6rem;
    flex-direction: row;
    justify-content: center;
  }

  & > div,
  & > a {
    width: 100%;
    margin: 0 1rem;

    &:first-child {
      margin-bottom: 2rem;

      ${mediaSmDefault} {
        margin-bottom: 0;
      }

      a,
      button {
        background: ${primaryGreen};
        color: #fff;

        &:hover {
          background: ${darkenGreen};
        }
      }
    }

    a,
    button {
      width: 100%;
      font-size: 1.6rem;
      letter-spacing: 0.5px;
      line-height: 1.8rem;

      ${mediaSmDefault} {
        padding-left: 3rem !important;
        padding-right: 3rem !important;
        font-size: 2.2rem;
        letter-spacing: 0.5px;
        line-height: 2.8rem;
      }
    }
  }

  ${SectionText} {
    margin-top: 1rem;
    font-size: 1.8rem;

    ${mediaSmDefault} {
      margin-top: 2rem;
      font-size: 2.4rem;
      line-height: 3.1rem;
    }
  }
`;

export const SmallerText = styled.p`
  margin-top: 3rem;
  margin-bottom: 2rem;
  color: ${gray};
  font-size: 1.6rem;
  line-height: 2rem;

  ${mediaMdDefault} {
    max-width: 63rem;
  }
`;

export const UL1 = styled.ul<{ color?: 'blue' | 'black' | 'white'; checkColor?: 'white' | 'green' | 'textGreen' }>`
  margin-top: 4rem;
  margin-bottom: 4rem;
  display: inline-block;

  li {
    display: flex;
    align-items: center;
    margin-top: 2rem;

    &:first-child {
      margin-top: 0;
    }

    &:before {
      content: "";
      display: inline-block;
      margin-right: 2rem;
      min-width: 2.4rem;
      height: 1.7rem;
      background: url("/img/check_yellow.svg");
      background-size: cover;
      
      ${mediaSmMax} {
        min-width: 2.2rem;
        height: 1.6rem;
      }
      
      ${mediaHonzaDefault} {
        min-width: 2.12rem;
        height: 1.5rem;
      }
      
      ${props =>
    props.checkColor === 'white' &&
        css`
          background: url("/img/check_white.svg");
          background-size: cover;
        `}
      
      ${props =>
    props.checkColor === 'green' &&
        css`
          background: url("/img/check_green.svg");
          background-size: cover;
        `}
      
      ${props =>
    props.checkColor === 'textGreen' &&
        css`
          background: url("/img/check_textGreen.svg");
          background-size: cover;
        `}
    }

    span {
      margin-top: -0.3rem;
      color: ${yellow};
      font-size: 2rem;
      line-height: 2.5rem;
      text-align: left;
      
      ${mediaSmMax} {
        font-size: 1.6rem;
        line-height: 2rem;
      }
    }    
    
    &.white {
      span {
        color: #fff;
        
        strong {
          color: ${yellow};
        }
      }
  }

  ${props =>
    props.color === 'blue' &&
    css`
      li {
        &:before {
          content: url("/img/check_blue.svg");
        }

        span {
          color: ${blue};
        }
      }
    `}

  ${props =>
    props.color === 'black' &&
    css`
      li {
        &:before {
          content: url("/img/check_black.svg");
        }

        span {
          color: #fff;
        }
      }
    `}
`;

export const ValuesComparisonVisual = styled.div<{ inApp?: boolean }>`
  padding-top: 3rem;
  padding-bottom: 1rem;
  text-align: center;
  position: relative;
  background: radial-gradient(circle at top, #1d2b7c 0%, #0b0c1c 100%);

  ${mediaSmDefault} {
    padding-top: 8rem;
    padding-bottom: 6rem;
    text-align: center;
  }

  ${props =>
    !props.inApp &&
    css`
      padding-top: 9rem;
    `}

  .osoby {
    display: block;
    margin: 0 auto;
  }

  .visual-headings {
    margin-bottom: 1rem;

    ${mediaLgDefault} {
      display: inline-block;
      text-align: left;
    }
  }

  .bottom-text {
    font-size: 1.3rem;
    line-height: 1.6rem;
    color: rgba(${grayRGB}, 0.5);

    ${mediaSmDefault} {
      font-size: 1.6rem;
      line-height: 2rem;
      color: rgba(${grayRGB}, 0.7);
    }
  }

  .head-info {
    margin-top: 1.5rem;
    font-size: 1.8rem;
    letter-spacing: 0.5px;
    line-height: 2.4rem;
    color: ${textGreen};

    ${mediaSmDefault} {
      font-size: 2.9rem;
      line-height: 3.1rem;
    }

    ${mediaLgDefault} {
      padding-left: 7.8rem;
    }
  }

  .head-info2 {
    margin-top: 1rem;
    color: rgba(245, 246, 247, 0.7);
    font-size: 1.6rem;
    line-height: 2.4rem;

    ${mediaSmDefault} {
      font-size: 2.2rem;
      margin-top: 2rem;
    }

    ${mediaLgDefault} {
      padding-left: 7.8rem;
    }
  }

  ${PaymentInfoPrice} {
    margin-top: 2rem;
    margin-bottom: 2rem;

    ${mediaMdDefault} {
      margin-bottom: 0;
    }
  }

  ${BackgroundedText} {
    padding: 3rem;
    max-width: 52rem;
    margin: 0 auto;
    border-radius: 0.4rem;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 10px 0 rgba(${darkBaseRGB}, 0.4);

    ${SectionText} {
      margin-top: 3rem;

      &.info {
        margin-top: 0;
        color: rgba(255, 255, 255, 0.5);
      }
    }
  }

  ${Heading3} {
    margin-bottom: 1rem;
    color: ${yellow};
    font-size: 1.8rem;
    line-height: 2.4rem;
    text-align: center;

    ${mediaSmDefault} {
      font-size: 2.4rem;
      line-height: 2.9rem;
    }
  }

  ${UL1} {
    margin: 0;
    padding-right: 3.4rem;
    display: inline-block;
    text-align: center;

    ${mediaMdDefault} {
      display: block;
    }

    li {
      margin-top: 3rem;
      display: inline-flex;

      &:before {
        margin-right: 1rem;
      }

      span {
        text-align: center;
        font-size: 1.6rem;
        line-height: 2.4rem;
      }
    }
  }

  ${PaymentInfoPrice} {
    margin-top: 3rem;
    text-align: center;
    color: ${lightGrayBG};
    font-size: 2rem;
    line-height: 2.2rem;

    ${mediaSmDefault} {
      font-size: 2.4rem;
      line-height: 2.4rem;
    }
  }

  ${StyledLinkBorder}, ${StyledFakeLinkBorder} {
    display: inline-block;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    text-align: center;

    ${mediaSmDefault} {
      margin-top: 2rem;
      margin-bottom: 2rem;
      font-size: 1.8rem;
      line-height: 2rem;
    }
  }

  ${StyledTestInfoCard} {
    img {
      opacity: 0.5;
      transition: opacity 0.3s;
    }

    .text-bottom {
      color: rgba(${grayRGB}, 0.5);
      transition: color 0.3s;
    }

    &:hover {
      img {
        opacity: 1;
      }

      .text-bottom {
        color: rgba(${grayRGB}, 1);
      }
    }
  }

  .cards {
    ${Col} {
      flex-basis: 50%;
      max-width: 50%;
    }
  }

  .custom-order {
    flex-direction: column;

    & > div {
      order: 2;

      &:first-child {
        order: 3;
      }

      &:nth-child(2) {
        order: 1;
      }
    }
    
    ${BtnGroup} {
      align-items: flex-start;
    }

    ${mediaSmDefault} {
      & > div {
        &:first-child {
          order: 1;
        }

        &:nth-child(2) {
          order: 2;
        }
      }
    }
  }

  .u--desktop {
    display: none;

    ${mediaSmDefault} {
      display: block;
    }
  }

  .u--mobile {
    ${mediaSmDefault} {
      display: none;
    }
  }
`;
export const StyledHeadingValuesComparison = styled(Heading1)`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 2.8rem;
  letter-spacing: 0.6px;
  line-height: 3.2rem;
  font-weight: 400;

  ${mediaSmDefault} {
    font-size: 3.6rem;
    letter-spacing: 0.6px;
    line-height: 3.6rem;
  }

  svg {
    margin-bottom: 1rem;
    width: 4.8rem;
  }

  ${mediaSmDefault} {
    display: inline-flex;
    flex-direction: row;
    font-size: 4rem;
    letter-spacing: 0.5px;
    line-height: 4rem;

    svg {
      margin-right: 3rem;
      margin-bottom: 0;
    }
  }
`;

export const StyledHowToValuesComparisonPopup = styled.div`
  svg {
    path {
      fill: ${white};
    }
  }

  ${Heading2} {
    margin-top: 3rem;
    color: ${yellow};
    font-weight: 400;
  }

  ${StyledImgSlider} {
    margin-top: 3rem;

    & > div {
      justify-content: space-between;
    }

    .slide {
      width: 100%;
      max-width: 40rem;
    }
  }

  ${Grid} {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const StyledGamesForTwoVisualPopup = styled.div`
  ${Heading2} {
    color: ${yellow};
  }

  ${Heading3} {
    color: #fff;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  p {
    margin-top: 1rem !important;
  }

  .img {
    margin-top: 0 !important;
    padding: 2rem;
    display: inline-flex;
    border-radius: 0.4rem;

    img {
      height: 6rem;
    }

    &.red {
      background: radial-gradient(circle at top, ${RedTheme.primary} 0%, ${RedTheme.darken} 100%);
    }

    &.green {
      background: radial-gradient(circle at top, ${GreenTheme.primary} 0%, ${GreenTheme.darken} 100%);
    }

    &.blue {
      background: radial-gradient(circle at top, ${BlueTheme.primary} 0%, ${BlueTheme.darken} 100%);
    }

    &.yellow {
      background: radial-gradient(circle at top, ${GoldTheme.primary} 0%, ${GoldTheme.darken} 100%);
    }
  }
`;
