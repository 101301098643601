import { ContentSwitchStyle, Flex } from '../../organisms/PartnerTest/Result/styles';
import { NavLink } from 'react-router-dom';
import SVGarrow from '../../atoms/SVGIcons/SVGarrow';
import { Heading1 } from '../../atoms/Headings';
import { FormattedMessage } from 'react-intl';
import * as React from 'react';

interface ITopBarData {
  next?: string;
  back?: string;
  title: string;
}

class TopBarContentSwitch extends React.Component<ITopBarData> {
  public render() {
    const { next, back, title, children } = this.props;
    const visibility = next ? (back ? 'both' : 'right') : 'left';
    return (
      <ContentSwitchStyle visibility={visibility}>
        <Flex>
          <NavLink to={back ? back : next!}>
            <SVGarrow />
          </NavLink>
          <Heading1>
            <FormattedMessage id={title} />
          </Heading1>
          <NavLink to={next ? next : back!}>
            <SVGarrow />
          </NavLink>
        </Flex>
        {children}
      </ContentSwitchStyle>
    );
  }
}

export default TopBarContentSwitch;
