import { Link, useModal } from '@xcorejs/ui';
import SurveyHelpModal from 'components/organisms/Test/Question/SurveyHelpModal';
import * as React from 'react';
import { Row, Col } from 'react-styled-flexboxgrid';
import { ISurveyBaseQuestion, IAdditionalQuestion } from '../../data';
import { IResponse } from '../../data';
import { StyledTextArea } from '../../../../../atoms/FormItems/styles';
import { Answers } from '../../styles';

export interface IFree {
  question: IAdditionalQuestion<ISurveyBaseQuestion>;
  onChange: (response: IResponse<{}>) => void;
  theme: string;
}

const Free: React.FC<IFree> = ({ question, onChange, theme }) => {
  const [openSurveyHelpModal] = useModal(SurveyHelpModal);
  return (
    <Row>
      <Col xs={12} md={6} mdOffset={3}>
        <h1 dangerouslySetInnerHTML={{ __html: question.data.text as string }} />

        <Answers>
          <StyledTextArea
            onChange={e =>
              onChange({
                data: e.target.value,
                submitable: true,
              })
            }
          />
        </Answers>

        {question.help && (
          <Link
            onClick={() => openSurveyHelpModal({ help: question.help, theme: theme })}
            color='#fff'
            borderColor='#fff'
            v='underline'
            mt='1rem'
          >
            Více info
          </Link>
        )}
      </Col>
    </Row>
  );
};

export default Free;
