import { StyledFakeLinkBorder } from 'components/atoms/Buttons/styles';
import { StyledRegularParagraph } from 'components/atoms/Text';
import * as React from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Row } from 'react-styled-flexboxgrid';

import { INotification } from '../../../../../store/Entities/Notifications';
import { StyledMessage, StyledMessageList } from './styles';

interface IMessagesTable {
  data: INotification[];
  markAsReaded: (id: string) => void;
}

const MessagesTable: React.FC<IMessagesTable> = ({ data, markAsReaded }) => {
  const [count, setCount] = useState<number>(6);
  const sortedData = data.sort((a, b) =>
    a.status === b.status ? b.createdAt - a.createdAt : a.status === 'unread' ? -1 : 1,
  );
  const unreadedMessages = sortedData.filter(x => x.status === 'unread');
  return (
    <>
      <Row>
        <Col xs={12} md={6} mdOffset={3}>
          <StyledMessageList read={false}>
            {unreadedMessages
              .slice(0, unreadedMessages.length > count ? count : unreadedMessages.length)
              .map((message, index) => (
                <StyledMessage key={index} onClick={() => markAsReaded(message.id)}>
                  <div className='deco' />
                  <StyledRegularParagraph>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: message.message,
                      }}
                    />
                    <br />
                    <StyledFakeLinkBorder
                      // to="#"
                      color='yellow'
                      onClick={() => markAsReaded(message.id)}
                    >
                      <FormattedMessage id='profile.messages.confirm' />
                    </StyledFakeLinkBorder>
                  </StyledRegularParagraph>
                </StyledMessage>
              ))}
          </StyledMessageList>
          {unreadedMessages.length <= count && (
            <StyledMessageList read={true}>
              {sortedData
                .slice(0, count)
                .filter(x => x.status !== 'unread')
                .map((message, index) => (
                  <StyledMessage key={index} onClick={() => markAsReaded(message.id)}>
                    <div className='deco' />
                    <StyledRegularParagraph>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: message.message,
                        }}
                      />
                    </StyledRegularParagraph>
                  </StyledMessage>
                ))}
            </StyledMessageList>
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6} mdOffset={3}>
          {data.length > count && (
            <StyledMessageList>
              <StyledFakeLinkBorder
                className='back'
                onClick={() => (data.length > count + 3 ? setCount(count + 3) : setCount(data.length))}
              >
                <FormattedMessage id='profile.messages.older' />
              </StyledFakeLinkBorder>
            </StyledMessageList>
          )}
        </Col>
      </Row>
    </>
  );
};

export default MessagesTable;
