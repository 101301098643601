import { Box, Button, Container, Flex, Grid, Heading2, Icon, Link as UILink, Paragraph, Tag, Typography, useModal } from '@xcorejs/ui';
import LoadingSpinner from 'components/atoms/Animations/LoadingSpinner';
import { useScrollToAnchor } from 'components/atoms/hooks/useScrollToAnchor';
import TribalTime from 'components/atoms/TribalTime';
import { remainingDayTime } from 'components/atoms/ValuesAfterYear/remainingDaysCount';
import Carousel from 'components/components/Carousel';
import HowToCompareModal from 'components/components/modals/HelperModals/HowToCompareModal';
import SemaphoreModal from 'components/components/modals/HelperModals/SemaphoreModal';
import LifeValuesModal from 'components/components/modals/LifeValuesModal';
import LifeValuesPresentingModal from 'components/components/modals/LifeValuesPresentingModal';
import { createUrl } from 'components/pages/routes';
import { yellowWhite } from 'components/theme/colors';
import { JOURNAL } from 'config/apiRoutes';
import * as React from 'react';
import { FC } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { useQueryClient } from 'react-query';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';
import { useFetchJournal } from 'utils/useQuery/queryFetchHelpers';
import { queryKeys } from 'utils/useQuery/queryKeys';
import LoadingSpinnerLocal from '../../../../atoms/Animations/LoadingSpinnerLocal';
import { CompareIcon } from '../../../../components/icons/16/compare.svg';
import { NoteIcon } from '../../../../components/icons/16/note.svg';
import { PdfIcon } from '../../../../components/icons/16/pdf.svg';
import { SemaforIcon } from '../../../../components/icons/16/semafor.svg';
import { ONE_ROUND_TEST_ID } from '../../TestIntro/data';
import { TableStyle } from './styles';
import ValueTestResultTable from './ValueTestResultTable';
const CompareMatrix = React.lazy(() => import('../../../../components/CompareMatrix/CompareMatrix'));

const Journal: FC = () => {
  const [openLifeValuesModal] = useModal(LifeValuesModal);
  // const [openLaddersCompareMatrixModal] = useModal(LaddersCompareMatrixModal);
  const [openLifeValuesPresentingModal] = useModal(LifeValuesPresentingModal);
  const [openSemaphoreModal] = useModal(SemaphoreModal);
  const [openHowToCompareModal] = useModal(HowToCompareModal);
  const [close] = useModal();
  useScrollToAnchor();

  const journalFetch = useFetchJournal();
  
  const journal = journalFetch.data;

  const queryClient = useQueryClient();
  const reload = () => queryClient.invalidateQueries([queryKeys.JOURNAL, JOURNAL]);

  return (
    <>
      <Box pt={{ _: '3rem', sm: '4rem' }} pb={{ _: '6rem', sm: '8rem' }}>
        <Container flexDirection='column'>          
          {journalFetch.isLoading && (
            <LoadingSpinnerLocal />
          )}
          
          {journal && (
            <Box>
              <Flex flexDirection='column' alignItems='center'>
                <Heading2 px={{ _: '2rem', sm: 0 }} color='#e7e6cf'>
                  <FormattedMessage id='journal.myValues' />
                </Heading2>

                <Paragraph mt='1rem'>
                  <FormattedMessage id='journal.myValues.text' />
                </Paragraph>
              </Flex>

              <Box bg='rgba(11, 12, 28, 0.3)' pt='1rem' pb={{ _: '0.1rem', sm: '1rem' }} mt={{ _: '2rem', sm: '3rem' }}>
                {journal.mains.length > 0  ?
                  journal.myLifeValues === null ? (
                    <TableStyle>
                      <tbody>
                        <tr>
                          <td />
                          <td>
                            <Box textAlign='center'>
                              <Tag
                                display='block'
                                mx='auto'
                                bg='#ab5371'
                                border='2px solid #ab5371'
                                py='0.4rem'
                                width='24rem'
                                justifyContent='center'
                                whiteSpace='nowrap'
                                overflow='hidden'
                                textOverflow='ellipsis'
                              >
                                <FormattedMessage id='journal.topTable.yearText' /> {new Date(journal.mains[0].doneAt*1000).getFullYear()}
                              </Tag>
                            </Box>

                            {remainingDayTime(journal.mains[0].doneAt) >= 0 && (
                              <Typography whiteSpace={{ sm: 'nowrap', _: 'normal' }} fontSize='14px' mt='1rem'>
                                <FormattedMessage id='journal.topTable.missingCompare' />
                                <UILink v='underline' onClick={openLifeValuesPresentingModal}>
                                  <FormattedMessage id='journal.topTable.readMore' />
                                </UILink>
                              </Typography>
                            )}

                            <Box mt='0.5rem'>
                              <TribalTime doneAt={journal.mains[0].doneAt} />
                            </Box>

                            {remainingDayTime(journal.mains[0].doneAt) >= 0 ? '' : (
                              <Flex justifyContent='center' mt='1rem'>
                                <Button as='a' href={`/cs/app/hra-intro?t=${ONE_ROUND_TEST_ID}&redirect=0`} s='xs'>
                                  <FormattedMessage id='journal.topTable.createNew' />
                                </Button>
                              </Flex>
                            )}
                          </td>
                          <td />
                        </tr>
                      </tbody>
                    </TableStyle>
                  ) : (
                    <TableStyle>
                      <tbody>
                        <tr>
                          <td />
                          <td>
                            <Box textAlign='center'>
                              <Tag
                                display='block'
                                mx='auto'
                                bg='#ab5371'
                                border='2px solid #ab5371'
                                py='0.4rem'
                                width='24rem'
                                justifyContent='center'
                                whiteSpace='nowrap'
                                overflow='hidden'
                                textOverflow='ellipsis'
                              >
                                <FormattedMessage id='journal.topTable.yearText' /> {journal.myLifeValues.yearFrom} - {journal.myLifeValues.yearTo}
                              </Tag>
                            </Box>

                            <Box mt='2rem'>
                              <TribalTime doneAt={journal.myLifeValues.doneAt} />
                            </Box>
                          </td>
                          <td>
                            <Flex justifyContent={{ _: 'center', md: 'flex-end' }}>
                              <UILink
                                as='span'
                                v='underline'
                                ml={{ _: 0, sm: '2rem', lg: '3rem' }}
                                mt={{ _: '2rem', sm: 0 }}
                                onClick={openLifeValuesModal}
                              >
                                <FormattedMessage id={'profile.results.detail'} />
                              </UILink>
                            </Flex>
                          </td>
                        </tr>
                      </tbody>
                    </TableStyle>
                  )
                  : (
                    <Flex flexDirection='column' alignItems='center' py='2rem'>
                      <Typography color='#fff'>
                        <FormattedMessage id='journal.topTable.noData' />
                      </Typography>

                      <Link to={createUrl('app.gameIntro') + '?t=welcome'}>
                        <Tag
                          mt='2rem'
                          display='block'
                          mx='auto'
                          bg='#008056'
                          border='2px solid #008056'
                          py='0.4rem'
                          width='24rem'
                          justifyContent='center'
                          whiteSpace='nowrap'
                          overflow='hidden'
                          textOverflow='ellipsis'
                          textAlign='center'
                        >
                          <FormattedMessage id='journal.topTable.startNew' />
                        </Tag>
                      </Link>
                    </Flex>
                  )}
              </Box>

              <Box>
                {journal.mains && (
                  <Box mt='1.5rem'>
                    <ValueTestResultTable
                      main={true}
                      results={journal.mains}
                      onReload={reload}
                    />
                  </Box>
                )}
              </Box>

              <Heading2 mt='6rem'>
                <FormattedMessage id={'profile.results.values'} />
              </Heading2>

              <Box mt='3rem'>
                {journal.others.length ? (
                  <>
                    <Paragraph mt='1rem'>
                      <FormattedMessage id='journal.myValues.text2' />
                    </Paragraph>

                    <ValueTestResultTable
                      main={false}
                      results={journal.others}
                      onReload={reload}
                    />
                  </>
                ) : (
                  <Paragraph color='rgb(255, 255, 255)'>
                    <FormattedMessage id={'profile.testResults.none'} />
                  </Paragraph>
                )}
              </Box>


              <Box textAlign='center'>
                <Grid
                  columns={{ _: 'repeat(2, 1fr)', sm: 'repeat(4, 1fr)' }}
                  rows='auto'
                  mt='4rem'
                  gap='1rem'
                  justifyItems='center'
                >
                  <Typography textAlign='left' display='flex' alignItems='center' color='rgba(255, 255, 255, 0.7)'>
                    <Icon
                      display='flex'
                      alignItems='center'
                      justifyContent='center'
                      svg={<NoteIcon />}
                      width='3rem'
                      height='3rem'
                      border='2px solid #fff'
                      borderRadius='50%'
                      mr='1rem'
                      opacity={0.5}
                      fill='#fff'
                    />

                    <FormattedHTMLMessage id='journal.helper1' />
                  </Typography>

                  <Typography
                    textAlign='left'
                    display='flex'
                    alignItems='center' 
                    color={yellowWhite}
                    textDecoration='underline'
                    cursor='pointer'
                    onClick={() => openSemaphoreModal({ close })}
                  >
                    <Icon
                      display='flex'
                      alignItems='center'
                      justifyContent='center'
                      svg={<SemaforIcon />}
                      width='3rem'
                      height='3rem'
                      border='2px solid #fff'
                      borderRadius='50%'
                      mr='1rem'
                      opacity={0.5}
                      fill='#fff'
                    />

                    <FormattedHTMLMessage id='journal.helper2' />
                  </Typography>

                  <Typography
                    textAlign='left'
                    display='flex'
                    alignItems='center'
                    color={yellowWhite}
                    textDecoration='underline'
                    cursor='pointer'
                    onClick={() => openHowToCompareModal({ close })}
                  >
                    <Icon
                      display='flex'
                      alignItems='center'
                      justifyContent='center'
                      svg={<CompareIcon />}
                      width='3rem'
                      height='3rem'
                      border='2px solid #fff'
                      borderRadius='50%'
                      mr='1rem'
                      opacity={0.5}
                      fill='#fff'
                    />

                    <FormattedHTMLMessage id='journal.helper3' />
                  </Typography>

                  <Typography textAlign='left' display='flex' alignItems='center' color='rgba(255, 255, 255, 0.7)'>
                    <Icon
                      display='flex'
                      alignItems='center'
                      justifyContent='center'
                      svg={<PdfIcon />}
                      width='3rem'
                      height='3rem'
                      border='2px solid #fff'
                      borderRadius='50%'
                      mr='1rem'
                      opacity={0.5}
                      fill='#fff'
                    />

                    <FormattedHTMLMessage id='journal.helper4' />
                  </Typography>
                </Grid>
              </Box>
            </Box>
          )}
        </Container>
      </Box>

      <Box bg='rgba(11, 12, 28, 0.3)' py={{ _: '6rem', sm: '8rem' }}>
        <Fade bottom ssrReveal duration={1000} distance='5rem'>
          <Container justifyContent='center'>
            <Flex flexDirection='column' alignItems='center' width='100%'>
              <Heading2 px={{ _: '2rem', sm: 0 }} color='#e7e6cf'>
                <FormattedMessage id={'journal.ladders.heading'} />
              </Heading2>

              <Box mt={{ _: '2rem', sm: '2rem' }} width='100%'>
                <Carousel />
              </Box>

              {/*
              <Box mt='6rem'>
                <Flex mt='6rem' flexDirection='column' alignItems='center'>
                  <Link to={createUrl('app.shop')}>
                    <Flex flexDirection='column' alignItems='center'>
                      <Img src='/img/plus-coffee@2x.png' width='26rem' alt='' />

                      <PremiumButton
                        customChildren={
                          <Flex flexDirection='column'>
                            <Paragraph fontWeight={500} color='yellow' lineHeight='1.6rem'>
                              <FormattedMessage id={'journal.compare.eticiaPlus'} />
                            </Paragraph>

                            <Paragraph fontWeight={500} fontSize='1.4rem' lineHeight='1.8rem'>
                              <FormattedMessage id={'journal.compare.price'} />
                            </Paragraph>
                          </Flex>
                        }
                        mt='3rem'
                      />
                    </Flex>
                  </Link>

                  <Stack gap='2rem' wrapItems mt='2rem' alignItems='center'>
                    <SVGpayCard />
                    <SVGpayBank />
                    <SVGpayMobile />
                  </Stack>

                  <Paragraph mt='2rem'>
                    <FormattedMessage id={'journal.compare.payment'} />
                  </Paragraph>
                </Flex>
              </Box>
              */}
            </Flex>
          </Container>
        </Fade>
      </Box>

      <Box py={{ _: '6rem', sm: '8rem' }} id='compare-matrix'>
        <Fade bottom ssrReveal duration={1000} distance='5rem'>
          {/* <Container justifyContent='center'>
            <Flex flexDirection='column' alignItems='center' width='100%'>
              <Heading2 px={{ _: '2rem', sm: 0 }} color='#e7e6cf'>
                <FormattedMessage id={'journal.ladders.heading'} />
              </Heading2>

              <Typography mt='1rem' maxWidth='85rem' px={{ _: '2rem', sm: 0 }} alignItems='center' color='#fff'>
                ikátní funkce porovnání žebříčků umožňuje hledat společné i rozdílné hodnoty u tvých žebříčků,  
                vzájemné své hodnoty porovnávat s druhou osobou a sledovat vývoj svých žebříčků v čase.
              </Typography>

              <ValueLadderImg />

              <Button mt={{ _: '3rem', sm: '4rem' }} onClick={() => openLaddersCompareMatrixModal({ infoTextHidden: true })}>
                Jaké žebříčky můžeš porovnat?
              </Button>
            </Flex>
          </Container> */}
          <React.Suspense fallback={<LoadingSpinner />}>
            <CompareMatrix />
          </React.Suspense>
        </Fade>
      </Box>
    </>
  );
};

export default Journal;
