import * as React from 'react';
import HelpPopupBody from '../../../atoms/Popups/HelpPopup/HelpPopupBody';
import HelpPopupFooter from '../../../atoms/Popups/HelpPopup/HelpPopupFooter';
import HelpPopup from 'components/atoms/Popups/HelpPopup';
import { SecondaryButton } from 'components/atoms/Buttons';
import { FormattedMessage } from 'react-intl';
import { PopupComponent } from '../../../../store/Popup/index';

interface IDialogPopupProps {
  title?: string;
  resolve: (result?: string) => unknown;
}

const DialogPopup: React.FC<IDialogPopupProps> = ({ children, title, resolve }) => {
  return (
    <HelpPopup title={title} onClose={() => resolve('close')}>
      <HelpPopupBody>{children}</HelpPopupBody>

      <HelpPopupFooter>
        <SecondaryButton onClick={() => resolve('ok')}>
          <FormattedMessage id={'popup.default.button'} />
        </SecondaryButton>
        <SecondaryButton onClick={() => resolve('cancel')}>
          <FormattedMessage id={'popup.default.button.cancel'} />
        </SecondaryButton>
      </HelpPopupFooter>
    </HelpPopup>
  );
};

export interface IDialogPopup {
  content: string;
  title?: string;
}

export const dialogPopup = (data: IDialogPopup): PopupComponent => ({ resolve }) => (
  <DialogPopup title={data.title} resolve={resolve}>
    <FormattedMessage id={data.content} />
  </DialogPopup>
);
