import * as React from 'react';
import { StyledValueDefinitionQuote } from '../styles';

interface IPlaceholder {
  label: string;
  color: string;
}

const Placeholder: React.FC<IPlaceholder> = ({ label, color }) => (
  <StyledValueDefinitionQuote data-color={color}>
    <span className='label'>{label}</span>

    <div>
      <strong>Navrhni vlastní {label.toLocaleLowerCase()}!</strong>
      <p>
        Obsah průběžně doplňujeme na základě podnětů uživatelů, pošli nám svůj nápad na{' '}
        <a href='mailto:napad@eticia.cz' target='_blank' rel='noopener noreferrer'>
          <span>napad@eticia.cz</span>
        </a>
      </p>
    </div>
  </StyledValueDefinitionQuote>
);

export default Placeholder;
