import * as React from 'react';
import { Col, Grid, Row } from 'react-styled-flexboxgrid';
import { Heading2 } from '../../atoms/Headings';
import { FormattedMessage } from 'react-intl';
import { StyledLink } from '../../atoms/Buttons/styles';
import { PrimaryButtonButton } from '../../atoms/Buttons';
import { createUrl } from '../routes';
import Fade from 'react-reveal/Fade';
import { IThemeProps, useTheme } from '../../templates/General/ThemeContext';
import { StyledIntro } from '../styles';
import { withRouter, RouteComponentProps } from 'react-router';

interface IIntroState {
  slideIndex: number;
}

const slides = [
  {
    img: '/img/intro/motiv-1@2x.png',
    head: 'intro.text.head.1',
    desc: 'intro.text.desc.1',
    btn: 'intro.text.btn.1',
    theme: 'darkenGreen',
  },
  {
    img: '/img/intro/motiv-2@2x.png',
    head: 'intro.text.head.2',
    desc: 'intro.text.desc.2',
    btn: 'intro.text.btn.2',
    theme: 'darkenBlue',
  },
  {
    img: '/img/intro/motiv-3@2x.png',
    head: 'intro.text.head.3',
    desc: 'intro.text.desc.3',
    btn: 'intro.text.btn.3',
    theme: 'violet',
  },
];

class Intro extends React.Component<RouteComponentProps<{}> & IThemeProps, IIntroState> {
  public state: IIntroState = { slideIndex: 0 };

  public render() {
    return (
      <StyledIntro>
        <Grid>
          <Row>
            <Col xs={12}>
              <div className='intro'>
                <h1>
                  <img
                    className='eticia'
                    src='/img/partners/institut.png'
                    srcSet='/img/eticia-logo-full.png, /img/eticia-logo-full@2x.png 2x, ../img/eticia-logo-full@3x.png 3x'
                    alt='Eticia'
                  />
                </h1>

                <div className={'img'}>
                  <img src={slides[0].img} className={this.state.slideIndex === 0 ? '' : 'disabled'} />
                  <img src={slides[1].img} className={this.state.slideIndex === 1 ? '' : 'disabled'} />
                  <img src={slides[2].img} className={this.state.slideIndex === 2 ? '' : 'disabled'} />
                </div>

                <div className={'slider-box'}>
                  <span className={'left'} onClick={this.onPrev}>
                    <img
                      src='/img/intro/arrow-left.svg'
                      alt='left'
                      className={this.state.slideIndex === 0 ? 'disabled' : ''}
                    />
                  </span>
                  <span className={'dots'}>
                    <span className={'dots'}>
                      <img
                        src='/img/intro/1.svg'
                        alt='1'
                        className={this.state.slideIndex === 0 ? 'active' : ''}
                        onClick={() => this.updateState(0)}
                      />
                      <img
                        src='/img/intro/2.svg'
                        alt='2'
                        className={this.state.slideIndex === 1 ? 'active' : ''}
                        onClick={() => this.updateState(1)}
                      />
                      <img
                        src='/img/intro/3.svg'
                        alt='3'
                        className={this.state.slideIndex === 2 ? 'active' : ''}
                        onClick={() => this.updateState(2)}
                      />
                    </span>
                  </span>
                  <span className={'right'} onClick={this.onNext}>
                    <img src='/img/intro/arrow-right.svg' alt='right' />
                  </span>
                </div>

                <div className={'text'}>
                  <Fade ssrReveal bottom appear={true} duration={1000} distance={'5rem'} spy={this.state.slideIndex}>
                    <Heading2>
                      <FormattedMessage id={slides[this.state.slideIndex].head} />
                    </Heading2>
                  </Fade>

                  <span className={'desc'}>
                    <Fade
                      ssrReveal
                      bottom
                      appear={true}
                      duration={1000}
                      delay={200}
                      spy={this.state.slideIndex}
                      distance={'5rem'}
                    >
                      <FormattedMessage id={slides[this.state.slideIndex].desc} />
                    </Fade>
                  </span>

                  <div className='btnHolder'>
                    <Fade
                      ssrReveal
                      bottom
                      appear={true}
                      duration={1000}
                      delay={400}
                      spy={this.state.slideIndex}
                      distance={'5rem'}
                    >
                      <PrimaryButtonButton onClick={this.onNext}>
                        <FormattedMessage id={slides[this.state.slideIndex].btn} />
                      </PrimaryButtonButton>
                    </Fade>
                  </div>
                </div>

                <div className={'skip'}>
                  <StyledLink
                    to={
                      this.props.location.pathname.split('/')[3] === undefined
                        ? createUrl('')
                        : createUrl('register') + '/' + this.props.location.pathname.split('/')[3]!
                    }
                  >
                    <FormattedMessage id={'skip.intro'} />
                  </StyledLink>
                </div>
              </div>
            </Col>
          </Row>
        </Grid>
      </StyledIntro>
    );
  }

  private onNext = () => {
    this.updateState(this.state.slideIndex + 1);
  };

  private onPrev = () => {
    this.updateState(this.state.slideIndex - 1);
  };

  private updateState = (i: number) => {
    if (i > 2) {
      window.location.replace(
        this.props.location.pathname.split('/')[3] === undefined
          ? createUrl('')
          : createUrl('register') + '/' + this.props.location.pathname.split('/')[3]!,
      );
      return;
    }
    this.setState({
      ...this.state,
      slideIndex: i,
    });
    if (i < slides.length && i >= 0) {
      this.props.theme.setBackground(slides[i].theme);
    }
  };
}

export default withRouter(useTheme(Intro));
