export type CompareMatrixDataProps = {
  [key: string]: string;
};

export const compareMatrixData: CompareMatrixDataProps = 
{
  'Co na tobě oceňuji?': '18c05bd1-e8f3-42ca-bcc2-a28ac1a2e852',
  'Rodiče a děti': 'e27f62d1-322e-4549-a1fa-ff6f9b59e2cf',
  'Můj ideální partner': 'c212ba8c-9fed-492a-9ba4-44af2ab5291d',
  'Moje spokojenost': 'beab7d9b-9cb1-4876-91cd-e6e5dcd5a85e',
  'Moje hodnoty': 'fa385f22-7954-4029-8e18-06dc3b92f400',
  'Má vysněná práce': 'd1ded0db-e903-42be-a5f3-eca4a02bbedc',
  'Má přání': 'a4a379cf-a979-4f1f-82a6-039a647d6083',
  'Hodnoty tady a teď': '3c132621-85d3-43e3-9dbe-aea269620486',
  'Moje superschopnosti': '399a3d53-ce8a-402d-8cd3-a877d782e924',
};

export const findMatrixDataKey = (obj: Record<string, string>, value: string): string | undefined => {
  return Object.keys(obj).find(key => obj[key] === value);
};
