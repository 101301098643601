import Subnav from 'components/atoms/Subnav';
import NavItem from 'components/atoms/Nav/NavItem';
import { createUrl } from 'components/pages/routes';
import { NavLink, useLocation } from 'react-router-dom';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Flex, Heading4, Link, Img } from '@xcorejs/ui';
import React, { FC } from 'react';

const StatisticsPage: FC = ({ children }) => {
  const { pathname } = useLocation();

  return (
    <>
      <Subnav>
        <NavItem to={createUrl('app.stats')} exact label='statistics.menu.funny' />
        <NavItem to={createUrl('app.statsGeneral')} label='statistics.menu.general' />
        <NavItem to={createUrl('app.statsWorld')} label='statistics.menu.world' />        
      </Subnav>

      <Flex>
        {children}
      </Flex>

      <Heading4 textAlign='center' mt={{ _:'4rem', md: '6rem' }}>
        <FormattedHTMLMessage id='statistics.site.universal.head' />
      </Heading4>

      <Flex
        width='100%'
        mt={{ _: '2rem', md: '4rem' }}
        pb='3rem'
        mx='auto'
        maxWidth='85rem'
      >
        <Flex width='33.33%' justifyContent='center'>
          <NavLink
            to={createUrl('app.stats')}
          >
            <Flex flexDirection='column' alignItems='center' role='group'>
              <Img src='/img/statistics/fun_page.png' alt='' width='48px' height='48px' transform={{ _: 'scale(0.8)', md: 'scale(1)' }} />

              <Link
                mt={{ _: '1.2rem', md:'1.5rem' }}
                fontSize={{ _: '1.4rem', md:'1.8rem' }}
                textAlign='center'
                lineHeight={{ _: '2rem', md:'2.2rem' }}
                maxWidth={{ _: '80%', md: '100%' }}
                color={pathname === createUrl('app.stats') ? 'yellow' : 'rgba(255, 255, 255, 0.8)' }
                fontWeight={pathname === createUrl('app.stats') ? 700 : 400}
                borderColor={pathname === createUrl('app.stats') ? 'transparent' : 'rgba(255, 255, 255, 0.8)'}
                _groupHover={{
                  color: 'yellow',
                  borderColor: 'transparent'
                }}
              >
                <FormattedHTMLMessage id='statistics.site.universal.text2' />
              </Link>
            </Flex>
          </NavLink>
        </Flex>

        <Flex width='33.33%' justifyContent='center'>
          <NavLink
            to={createUrl('app.statsGeneral')}
          >
            <Flex flexDirection='column' alignItems='center' role='group'>
              <Img src='/img/statistics/general_page.png' alt='' width='48px' height='48px' transform={{ _: 'scale(0.8)', md: 'scale(1)' }} />

              <Link
                mt={{ _: '1.2rem', md:'1.5rem' }}
                fontSize={{ _: '1.4rem', md:'1.8rem' }}
                textAlign='center'
                lineHeight={{ _: '2rem', md:'2.2rem' }}
                maxWidth={{ _: '80%', md: '100%' }}
                color={pathname === createUrl('app.statsGeneral') ? 'yellow' : 'rgba(255, 255, 255, 0.8)' }
                fontWeight={pathname === createUrl('app.statsGeneral') ? 700 : 400}
                borderColor={pathname === createUrl('app.statsGeneral') ? 'transparent' : 'rgba(255, 255, 255, 0.8)'}
                _groupHover={{
                  color: 'yellow',
                  borderColor: 'transparent'
                }}
              >
                <FormattedHTMLMessage id='statistics.site.universal.text3' />
              </Link>
            </Flex>
          </NavLink>
        </Flex>

        <Flex width='33.33%' justifyContent='center'>
          <NavLink to={createUrl('app.statsWorld')}>
            <Flex flexDirection='column' alignItems='center' role='group'>
              <Img src='/img/statistics/world_page.png' alt='' width='48px' height='48px' transform={{ _: 'scale(0.8)', md: 'scale(1)' }} />

              <Link
                mt={{ _: '1.2rem', md:'1.5rem' }}
                fontSize={{ _: '1.4rem', md:'1.8rem' }}
                textAlign='center'
                lineHeight={{ _: '2rem', md:'2.2rem' }}
                maxWidth={{ _: '80%', md: '100%' }}
                color={pathname === createUrl('app.statsWorld') ? 'yellow' : 'rgba(255, 255, 255, 0.8)' }
                fontWeight={pathname === createUrl('app.statsWorld') ? 700 : 400}
                borderColor={pathname === createUrl('app.statsWorld') ? 'transparent' : 'rgba(255, 255, 255, 0.8)'}
                _groupHover={{
                  color: 'yellow',
                  borderColor: 'transparent'
                }}
              >
                <FormattedHTMLMessage id='statistics.site.universal.text4' />
              </Link>
            </Flex>
          </NavLink>
        </Flex>
      </Flex>
    </>
  );
};

export default StatisticsPage;

//<li>
//        <NavLink
//        className='nav-link'
//      {...{/* eslint-disable-next-line no-extra-parens */}}
//    to={`/${(global as any).requestContext.locale}/app/presenting?id=16`}
//  {...{ label: 'statistics.menu.world' as any }}
//          >
//          <FormattedMessage id={'statistics.menu.world'} />
//      </NavLink>
//   </li>