import { AVATAR, DATING_PHOTO, EDIT_PROFILE, EDIT_PROFILE_GROUPS, PASSWORD_CHANGE } from 'config/apiRoutes';
import { IUserInfo } from 'store/User';
import { jsonFetch, wrappedFetch } from 'utils/fetch';
import { DELETE_PROFILE } from '../../../../../config/apiRoutes';

export const changePassword = async (
  oldPass: string,
  newPass: string,
  newPassAgain: string,
  onSuccess: () => any,
  onError: (error: string) => any,
) => {
  try {
    await jsonFetch(PASSWORD_CHANGE, {
      method: 'POST',
      body: JSON.stringify({
        passwordOld: oldPass,
        password: newPass,
        passwordConfirm: newPassAgain,
      }),
    });
    onSuccess();
  } catch (e) {
    const json = await e.data.json();
    json && onError(json.error);
  }
};

export interface IEditProfile {
  email: string;
  nick: string;
  sex: number;
  child: boolean;
  yearOfBirth: number;
  city: string;
  maritalStatus: number;
  workType: number;
  education: number;
  regionId: string | undefined;
  countryId: string | undefined;
  level: number;
  user: boolean;
}

export const changeUser = (user: IUserInfo, 
  data: string | number | string[] | boolean, dataField: keyof IEditProfile) => {
  return {
    email: dataField === 'email' ? data as string : user.email,
    nick: dataField === 'nick' ? data as string : user.nick,
    sex: user.sex,
    yearOfBirth: user.yearOfBirth,
    city: user.city,
    maritalStatus: user.maritalStatus,
    workType: user.workType,
    education: user.education,
    regionId: user.region ? user.region.id : undefined,
    countryId: user.country ? user.country.id : undefined,
    child: dataField === 'child' ? data as boolean : user.child,
    level: dataField === 'level' ? data as number : user.level,
  } as IEditProfile;
};

export const changeUserData = async (
  newUserData: IEditProfile | IUserInfo,
  onSuccess: () => any,
  onError: (error: string) => any,
) => {
  try {
    await jsonFetch(EDIT_PROFILE, {
      method: 'POST',
      //todo: přidat header bez cache
      body: JSON.stringify({
        ...newUserData,
      }),
    });
    onSuccess();
  } catch (e) {
    const json = await e.data.json();
    json && onError(json.error);
  }
};

export const changeProfileGroups = async (
  groups: string[],
  onSuccess: () => any,
  onError: (error: string) => any,
) => {
  try {
    await jsonFetch(EDIT_PROFILE_GROUPS, {
      method: 'POST',
      body: JSON.stringify({
        groups
      }),
    });
    onSuccess();
  } catch (e) {
    const json = await e.data.json();
    json && onError(json.error);
  }
};

export const changeAvatar = async (reqInit: any, onSuccess: () => any, onError: (e: string) => any) => {
  try {
    await wrappedFetch(AVATAR, reqInit);

    onSuccess();
  } catch (e) {
    const json = await e.data.json();
    onError(json ? json.error : e);
  }
};

export const changeDatingPhoto = async (reqInit: any, onSuccess: () => any, onError: (e: string) => any) => {
  try {
    await wrappedFetch(DATING_PHOTO, reqInit);

    onSuccess();
  } catch (e) {
    const json = await e.data.json();
    onError(json ? json.error : e);
  }
};

export const useEticon = async (onSuccess: () => any) => {
  try {
    await jsonFetch(AVATAR, {
      method: 'DELETE',
    });

    onSuccess();
  } catch (e) {
    onSuccess();
  }
};

export const deleteAccount = async (onSuccess: () => any, onError: (e: string) => any) => {
  try {
    const res = await wrappedFetch(DELETE_PROFILE, {
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
      },
    });
    if (!res.ok) {
      throw res;
    }
    onSuccess();
  } catch (e) {
    const json = e.data ? await e.data.json() : { error: 'error' };
    onError(json ? json.error : e);
  }
};
