import styled, { css } from 'styled-components';
import {
  blue,
  blueRGB,
  darkBaseHEX,
  darkBaseRGB,
  darkenWhite,
  primaryBlue,
  primaryGreen,
  selectColor,
} from '../../../../theme/colors';
import { Heading3 } from '../../../../atoms/Headings';
import { IFaqBox } from './Box';
import { StyledFakeLinkBorder } from '../../../../atoms/Buttons/styles';

export const StyledFaqBox = styled.div<IFaqBox>`
  display: block;
  padding: 2rem 3rem;
  height: calc(100% - 3rem);
  margin-bottom: 3rem;
  background: radial-gradient(
      circle,
      rgba(${blueRGB}, 0.25) 0%,
      ${darkenWhite} 100%
    ),
    #fff;
  box-shadow: 0 0.5rem 1rem 0 rgba(${darkBaseRGB}, 0.4);
  border-radius: 0.4rem;

  ${Heading3} {
    color: ${darkBaseHEX};
    margin-bottom: 3rem;
    font-size: 2rem;
    line-height: 2.6rem;
    font-weight: 500;
  }
  
  svg {
    path {
      ${props =>
    props.iconColor === 'green' &&
        css`
          fill: ${primaryGreen};
        `}
      ${props =>
    props.iconColor === 'blue' &&
        css`
          fill: ${primaryBlue};
        `}
      ${props =>
    props.iconColor === 'gray' &&
        css`
          fill: ${selectColor};
        `}
    }
  }
  
  ${StyledFakeLinkBorder} {
    margin-bottom: 2rem;
    display: block;
    font-size: 1.6rem;
    line-height: 2rem;
    color: rgba(${blueRGB}, 0.8);
    
    &:hover {
      color: ${blue};
    }
  }
  
  img {
    display: block;
    margin: 0 auto 1.5rem;
    max-height: 4.4rem;
  }
`;

export const StyledFaqBoxes = styled.div`
  margin-top: 4rem;
`;
