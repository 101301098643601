import styled from 'styled-components';
import { yellow } from '../../theme/colors';

export const StyledPersonBox = styled.div`
  img {
    border-radius: 50%;
    max-width: 22rem;
  }

  h3 {
    margin-top: 3rem;
    color: ${yellow};
    font-size: 2.6rem;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 3.3rem;
  }

  p {
    margin-top: 2rem;
    color: #fff;
    font-size: 1.8rem;
    line-height: 3rem;
    text-align: center;
  }
`;
