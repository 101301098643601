import * as React from 'react';
import { wrappedFetch } from '../../../utils/fetch';
import { _throw } from '../../../utils/option';
import { fetchException } from '../../../utils/exceptions';
import { FC, useEffect, useState } from 'react';

interface IExternalSVG {
  src: string;
  className?: string;
}

const ExternalSVG: FC<IExternalSVG> = ({ src, className }) => {
  const [content, setContent] = useState<string | null>(null);

  useEffect(() => {
    wrappedFetch(src)
      .then(r => r.ok ? r : _throw(fetchException(r)))
      .then(r => r.headers.get('Content-Type')!.includes('xml') ? r : _throw(fetchException(r)))
      .then(r => r.text())
      .then(content => setContent(content));
  }, [src]);

  if (content === null) {
    return null;
  }

  return <div dangerouslySetInnerHTML={{ __html: content }} className={`external-svg ${className || ''}`} />;
};

export default ExternalSVG;
