import * as React from 'react';
import { IQuestionHelp, ISurveySimpleQuestion } from '../../../data';
import { Row, Col } from 'react-styled-flexboxgrid';
import SimpleItem from '../SimpleItem';
import { QuestionFlexBox, QuestionBox } from '../../../../../../atoms/FormItems/PartnerTest/styles';

export interface ILayoutC {
  data: ISurveySimpleQuestion;
  help: IQuestionHelp;
  selected: string[];
  onChange: (id: string, val: boolean) => void;
  theme: string;
}

const LayoutC: React.FC<ILayoutC> = props => (
  <Row>
    <Col xs={12}>
      <h1 dangerouslySetInnerHTML={{ __html: props.data.text as string }} />

      {props.data.description !== null && <p dangerouslySetInnerHTML={{ __html: props.data.description as string }} />}

      <QuestionFlexBox grid={'big2Small'}>
        <QuestionBox>
          <span>
            <QuestionBox isDisabled={true}>
              <div>
                <img src={props.data.image || undefined} />
              </div>
            </QuestionBox>
          </span>
        </QuestionBox>
        <QuestionBox>
          <span>
            {props.data.answers
              .sort((a, b) => a.priority - b.priority)
              .map(a => (
                <SimpleItem key={a.id} {...props} a={a} />
              ))}
          </span>
        </QuestionBox>
      </QuestionFlexBox>
    </Col>
  </Row>
);
export default LayoutC;
