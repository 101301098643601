import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import { StyledValueComparisonSection } from '../Comparisons/styles';
import { StyledGamesConfiguration } from '../ComparisonSetup/style';
import { FormattedMessage } from 'react-intl';
import * as React from 'react';
import { Heading1 } from '../../../../atoms/Headings/index';
import { SmallerText } from '../../../../organisms/VisualValuesComparison/style';
import { PrimaryButton } from '../../../../atoms/Buttons/index';
import { StyledTextArea } from '../../../../atoms/FormItems/styles';
import { sendInvitation, IInvitationData, IFetchException } from './data';
import { StyledLinkBorder } from '../../../../atoms/Buttons/styles';
import { RouteComponentProps, withRouter } from 'react-router';
import { _switch } from '../../../../../utils/option';
import LoadingSpinner from '../../../../atoms/Animations/LoadingSpinner';
import { createUrl } from '../../../routes';
import InlineFormInput from '../../../../atoms/InlineForm/InlineFormInput';
import wrapIntl, { IWrapIntl } from '../../../../atoms/wrapIntl';

interface ISendInvitationState {
  state: number;
  data: IInvitationData;
  errors: IError;
}

interface IError {
  Name?: string;
  Email?: string;
}

class SendInvitation extends React.Component<RouteComponentProps<{ id: string }> & IWrapIntl> {
  public state: ISendInvitationState = {
    state: 0,
    data: {
      name: '',
      email: '@',
      message: this.props.formatMsg('config.invitationEmail.defaultMessage'),
    },
    errors: {},
  };

  public render() {
    // @ts-ignore
    return (
      <Grid>
        <Row>
          <Col xs={12} md={6} mdOffset={3}>
            <StyledValueComparisonSection>
              <StyledGamesConfiguration>
                {_switch([
                  () => (
                    <>
                      <Heading1>
                        <FormattedMessage id={'config.invitationEmail.heading'} />
                      </Heading1>

                      <SmallerText>
                        <FormattedMessage id={'config.invitationEmail.text'} />
                      </SmallerText>

                      <form>
                        <div className='form-inline-wrapper'>
                          <div className='form-inline'>
                            <FormattedMessage id={'config.invitationEmail.form1'} />
                            <InlineFormInput
                              type='text'
                              name='name'
                              placeholder={'Zadej své jméno nebo přezdívku'}
                              value={this.state.data.name}
                              //@ts-ignore
                              onChange={e =>
                                this.setState({
                                  data: {
                                    ...this.state.data,
                                    name: e.target.value,
                                  },
                                })
                              }
                              error={this.state.errors && this.state.errors.Name}
                            />
                          </div>

                          <div className='form-inline'>
                            <FormattedMessage id={'config.invitationEmail.form2'} />
                            <InlineFormInput
                              type='text'
                              name='email'
                              value={this.state.data.email}
                              //@ts-ignore
                              onChange={e =>
                                this.setState({
                                  data: {
                                    ...this.state.data,
                                    email: e.target.value,
                                  },
                                })
                              }
                              error={this.state.errors && this.state.errors.Email}
                            />
                          </div>
                        </div>

                        <StyledTextArea
                          value={this.state.data.message}
                          onChange={(e: any) =>
                            this.setState({
                              data: {
                                ...this.state.data,
                                message: e.target.value,
                              },
                            })
                          }
                        />
                      </form>

                      <PrimaryButton
                        onClick={() => {
                          this.setState({ state: 1 });
                          sendInvitation(this.state.data, this.props.match.params.id)
                            .then(() => this.setState({ state: 2 }))
                            .catch((e: IFetchException) =>
                              e.data.json().then(errors =>
                                this.setState({
                                  state: 0,
                                  errors,
                                }),
                              ),
                            );
                        }}
                      >
                        <FormattedMessage id={'config.sendInvite'} />
                      </PrimaryButton>

                      <div className={'back'}>
                        <StyledLinkBorder to={createUrl('app.comparison') + '/moje-porovnani'}>
                          <FormattedMessage id={'config.back'} />
                        </StyledLinkBorder>
                      </div>
                    </>
                  ),
                  () => <LoadingSpinner />,
                  () => (
                    <>
                      <Heading1>
                        <FormattedMessage id={'config.invitationEmail.success'} />
                      </Heading1>
                      <div className={'back'}>
                        <StyledLinkBorder to={createUrl('app.comparison') + '/moje-porovnani'}>
                          <FormattedMessage id={'config.back'} />
                        </StyledLinkBorder>
                      </div>
                    </>
                  ),
                ])()(this.state.state)()}
              </StyledGamesConfiguration>
            </StyledValueComparisonSection>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default withRouter(wrapIntl(SendInvitation));
