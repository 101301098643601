import { Button, Card, Flex, Heading3, Heading4, Link, Tag, Typography, Grid, Box, Paragraph } from '@xcorejs/ui';
import { PlusTag } from 'components/atoms/Logo';
import { CompareConfig } from 'components/components/Compare/data';
import { IValueTestResultDetail } from 'config/api/valueTestResult';
import { IVirtualResult, IVirtualTestDetail } from 'config/api/virtualValueTestDetail';
import * as React from 'react';
import { FC, Fragment, useState } from 'react';
import { Link as ReactLink } from 'react-router-dom';

interface Block3ValuesProps {
  visible: boolean;
  valueTestResultA: IValueTestResultDetail;
  valueTestResultB: IValueTestResultDetail;
  virtualResult?: IVirtualResult;
  compareConfig?: CompareConfig;
}

const Block3Values: FC<Block3ValuesProps> = (
  {
    visible,
    valueTestResultA,
    valueTestResultB,
    virtualResult,
    compareConfig
  }
) => {
  const [allValuesVisible, setAllValuesVisible] = useState(false);
  const [allValues2Visible, setAllValues2Visible] = useState(false);
  const [openAllValues, setOpenAllValues] = useState(false);

  return (
    <Flex
      flexDirection='column'
      alignItems='center'
      mt='6rem'
    >
      <Flex
        border='2px solid rgba(255,255,255,0.3)'
        width='5rem'
        height='5rem'
        alignItems='center'
        justifyContent='center'
        borderRadius='50%'
      >
        <Typography fontSize='3rem' lineHeight='3rem'>
          3
        </Typography>
      </Flex>

      <Heading3 mt='2rem'>
        Tvé poslední dva žebříčky
      </Heading3>

      <Typography mt='1rem' textAlign='center'>
        Podívej se na podrobně na tvé žebříčky a také na to, jak se tvé důležité hodnoty a jejich pořadí
        proměnily. Některé z důležitých v minulé volbě mohou být najednou na úplném konci tvého žebříčku.
      </Typography>

      {/* <Flex
        {...{ style: { gap: '0.5rem' } }}
        width='100%'
      >
        <Flex flexDirection='column' width='50%'>
          <Tag
            mt='2rem'
            display='block'
            mx='auto'
            bg='transparent'
            border='2px solid'
            borderColor='primaryGreen'
            py='0.4rem'
            justifyContent='center'
            whiteSpace='nowrap'
            overflow='hidden'
            textOverflow='ellipsis'
            textAlign='center'
            width='100%'
          >
            {compareConfig ? compareConfig.templateA.name : (
              <>Mé hodnoty {virtualResult && virtualResult.yearTo}</>
            )}
          </Tag>

          <Typography mt='1rem' textAlign='center'>
            {new Date(valueTestResultA.doneAt * 1000).toLocaleDateString()}
          </Typography>

          <Flex flexDirection='column' {...{ style: { gap: '0.5rem' } }} mt='2rem'>
            {valueTestResultA.lifeValues.slice(0, allValuesVisible ? 42 : 7).map((value, i) => (
              <Card
                key={value.valueId}
                px='1.6rem'
                py='0.8rem'
                minHeight='4.1rem'
                bg='primaryGreen'
                borderRadius='0.8rem'
                _body={{
                  padding: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start'
                }}
              >
                <Typography width='1.4rem' mr='1rem' color='rgba(255, 255, 255, 0.5)'>
                  {i+1}.
                </Typography>

                <Typography color='#fff' textAlign='left'>
                  {value.name}
                </Typography>
              </Card>
            ))}

            <Flex justifyContent='center' mt='1rem'>
              {visible ? 
                allValuesVisible ? <Fragment /> : <Link v='underline' as='span' onClick={() => setAllValuesVisible(true)}>Celý žebříček</Link>
                : (
                // eslint-disable-next-line no-extra-parens
                  <ReactLink to={`/${(global as any).requestContext.locale}/app/presenting?id=12`} onClick={close}>
                    <Flex flexDirection='column' alignItems='center'>
                      <Link v='underline' as='span'>Celý žebříček</Link>
                      <PlusTag mt='1rem' />
                    </Flex>
                  </ReactLink>
                )}
            </Flex>
          </Flex>
        </Flex>

        <Flex flexDirection='column' width='50%'>
          <Tag
            mt='2rem'
            display='block'
            mx='auto'
            bg='transparent'
            border='2px solid'
            borderColor='#5293b6'
            py='0.4rem'
            justifyContent='center'
            whiteSpace='nowrap'
            overflow='hidden'
            textOverflow='ellipsis'
            textAlign='center'
            width='100%'
          >
            {compareConfig ? compareConfig.templateB.name : (
              <>Mé hodnoty {virtualResult && virtualResult.yearFrom}</>
            )}
          </Tag>

          <Typography mt='1rem' textAlign='center'>
            {new Date(valueTestResultB.doneAt * 1000).toLocaleDateString()}
          </Typography>

          <Flex flexDirection='column' {...{ style: { gap: '0.5rem' } }} mt='2rem'>
            {valueTestResultB.lifeValues.slice(0, allValues2Visible ? 42 : 7).map((value, i) => (
              <Card
                key={value.valueId}
                px='1.6rem'
                py='0.8rem'
                minHeight='4.1rem'
                bg='#5293b6'
                border='0.8rem'
                _body={{
                  padding: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start'
                }}
              >
                <Typography width='1.4rem' mr='1rem' color='rgba(255, 255, 255, 0.5)'>
                  {i+1}.
                </Typography>

                <Typography color='#fff' textAlign='left'>
                  {value.name}
                </Typography>
              </Card>
            ))}

            <Flex justifyContent='center' mt='1rem'>
              {visible ? 
                allValues2Visible ? <Fragment /> : <Link v='underline' as='span' onClick={() => setAllValues2Visible(true)}>Celý žebříček</Link>
                : (
                // eslint-disable-next-line no-extra-parens
                  <ReactLink to={`/${(global as any).requestContext.locale}/app/presenting?id=12`} onClick={close}>
                    <Flex flexDirection='column' alignItems='center'>
                      <Link v='underline' as='span'>Celý žebříček</Link>
                      <PlusTag mt='1rem' />
                    </Flex>
                  </ReactLink>
                )}
            </Flex>
          </Flex>
        </Flex>
      </Flex> */}

      <Grid
        columns='1fr 3.5rem 1fr'
        rows='auto'
        gap='0.5rem 0'
        width='100%'
      >
        <Tag
          mt='2rem'
          display='block'
          mx='auto'
          bg='transparent'
          border='2px solid'
          borderColor='primaryGreen'
          py='0.4rem'
          justifyContent='center'
          whiteSpace='nowrap'
          overflow='hidden'
          textOverflow='ellipsis'
          textAlign='center'
          width='100%'
          borderRadius='0.5rem'
        >
          {compareConfig ? compareConfig.templateA.name : (
            <>Mé hodnoty {virtualResult && virtualResult.yearTo}</>
          )}
        </Tag>

        <Box />

        <Tag
          mt='2rem'
          display='block'
          mx='auto'
          bg='transparent'
          border='2px solid'
          borderColor='#2B406B'
          py='0.4rem'
          justifyContent='center'
          whiteSpace='nowrap'
          overflow='hidden'
          textOverflow='ellipsis'
          textAlign='center'
          width='100%'
          borderRadius='0.5rem'
        >
          {compareConfig ? compareConfig.templateB.name : (
            <>Mé hodnoty {virtualResult && virtualResult.yearFrom}</>
          )}
        </Tag>

        <Typography mt='1rem' textAlign='center'>
          {new Date(valueTestResultA.doneAt * 1000).toLocaleDateString()}
        </Typography>

        <Box />

        <Typography mt='1rem' textAlign='center'>
          {new Date(valueTestResultB.doneAt * 1000).toLocaleDateString()}
        </Typography>

        {valueTestResultA.lifeValues.slice(0, openAllValues ? 42 : 7).map((value, i) => (
          <>
            <Card
              key={value.valueId}
              px='1.6rem'
              py='0.8rem'
              minHeight='4.1rem'
              bg='primaryGreen'
              borderRadius='0.8rem'
              justifyContent='center'
              marginTop={i >= 10 && i <= 30 && i % 10 === 0 ? '1rem' : '0'}
              _body={{
                padding: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end'
              }}
            >
              <Typography 
                color={i < 10 ?'#fff' : 'rgba(255, 255, 255, 0.7)'}
                textAlign='right'
                fontSize={{ _:'1.3rem', sm:'1.5rem' }}
                lineHeight='1'
              >
                {value.name}
              </Typography>
            </Card>

            <Flex
              placeSelf='center'
              alignItems='center'
              justifyContent='center'
              width='2.4rem'
              height='2.4rem'
              border='1px solid rgb(255, 255, 255)'
              borderRadius='9999px'
              background='linear-gradient(90deg, rgb(62, 143, 116) 0%, rgb(62, 143, 116) 50%, #2B406B 50%, #2B406B 100%)'
              opacity={i < 10 ? 1 : 0.7}
              color='rgb(255, 255, 255)'
              fontSize='1.4rem'
              lineHeight='1'
              marginTop={i >= 10 && i <= 30 && i % 10 === 0 ? '1rem' : '0'}
              paddingTop='3px'
            >{i + 1}</Flex>
            
            <Card
              key={valueTestResultB.lifeValues[i].valueId}
              px='1.6rem'
              py='0.8rem'
              minHeight='4.1rem'
              bg='#2B406B'
              borderRadius='0.8rem'
              justifyContent='center'
              marginTop={i >= 10 && i <= 30 && i % 10 === 0 ? '1rem' : '0'}
              _body={{
                padding: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start'
              }}
            >
              <Typography 
                color={i < 10 ? '#fff' : 'rgba(255, 255, 255, 0.7)'}
                textAlign='left'
                fontSize={{ _:'1.3rem', sm:'1.5rem' }}
                lineHeight='1'>
                {valueTestResultB.lifeValues[i].name}
              </Typography>
            </Card>
          </>
        ))}

      </Grid>

      <Flex justifyContent='center' mt='1rem'>
        {visible ? 
          openAllValues ? <Fragment /> : <Link v='underline' as='span' onClick={() => setOpenAllValues(true)}>Celý žebříček</Link>
          : (
          // eslint-disable-next-line no-extra-parens
            <ReactLink to={`/${(global as any).requestContext.locale}/app/presenting?id=12`} onClick={close}>
              <Flex flexDirection='column' alignItems='center'>
                <Link v='underline' as='span'>Celý žebříček</Link>
                <PlusTag mt='1rem' />
              </Flex>
            </ReactLink>
          )}
      </Flex>
    </Flex>
  );
};

export default Block3Values;