import Activation from 'components/pages/Activation';
import NewPage from 'components/pages/Homepage/new';
import Presenting from 'components/pages/Presenting';
import Shop from 'components/pages/Shop';
import { createUrlFactory, IRoute, processRoutes } from '../../utils/router';
import About from './About';
import AcceptInvite from './AcceptInvite';
import Join from './App/About/Join';
import Support from './App/About/Support';
import Feedback from './App/Feedback';
import SuggestValue from './App/Feedback/SuggestValue';
import { appRoutes } from './App/routes';
import Error404 from './Error404';
import Error500 from './Error500';
import Eticiameter from './Eticiameter/Eticiameter';
import EticiaPlus from './EticiaPlus/eticiaPlus';
import ForgottenPassword from './ForgottenPassword';
import ForgottenPasswordChange from './ForgottenPasswordChange';
import Homepage from './Homepage';
import Intro from './Intro';
import Login from './Login';
import Playground from './Playground';
import RegistrationComplete from './RegisterComplete';
import Registration from './Registration';
import RegisterAfterGoogle from './Registration/AfterGoogle/RegisterAfterGoogle';
import RegistrationFull from './RegistrationFull';
import Sandbox from './Sandbox';
import ValueComparisonLanding from './ValueComparisonLanding';
import ValueGame from './ValueGame';


export interface IThemeData {
  authUser?: boolean;
  background?: string;
  layout?: string;
  enableMenu?: boolean;
  dataMenu?: boolean;
  enableScroll?: boolean;
  legacyMenu?: boolean;
  hint?: string;
}

export type ThemeRoute = IRoute<IThemeData>;

export const eticiaRoute: ThemeRoute = {
  path: 'route.root',
  exact: true,
  component: Homepage,
  data: {
    legacyMenu: false,
    background: 'blue'
  },
  children: {
    intro: {
      path: 'route.intro',
      exact: false,
      component: Intro,
    },
    register: {
      path: 'route.register',
      exact: false,
      data: {
        background: 'blue',
      },
      component: Registration,
      children: {
        profile: {
          path: 'route.register.profile',
          exact: true,
          component: RegistrationComplete,
          data: {
            background: 'blue',
          },
        },
        closed: {
          path: 'route.register.closed',
          exact: true,
          component: RegistrationFull,
        },
        afterGoogle: {
          path: 'route.register-after-google',
          exact: false,
          component: RegisterAfterGoogle,
          data: {
            background: 'blue',
          },
        },
      },
    },
    login: {
      path: 'route.login',
      exact: true,
      component: Login,
    },
    forgPass: {
      path: 'route.forgotten-password',
      exact: true,
      component: ForgottenPassword,
    },
    passReset: {
      path: 'route.password-reset',
      exact: true,
      component: ForgottenPasswordChange,
    },
    e404: {
      path: 'route.404',
      exact: true,
      component: Error404,
      data: { legacyMenu: true },
    },
    e500: {
      path: 'route.500',
      exact: true,
      component: Error500,
      data: { legacyMenu: true },
    },
    sandbox: {
      path: 'route.sandbox',
      exact: false,
      component: Sandbox,
    },
    about: {
      path: 'route.about',
      exact: true,
      component: About,
      data: {
        background: 'black'
      },
      children: {
        join: {
          path: 'route.about.join',
          exact: true,
          component: Join,
          data: {
            legacyMenu: true,
          },
        },
        support: {
          path: 'route.about.support',
          exact: true,
          component: Support,
          data: {
            layout: 'basic',
            legacyMenu: true,
          },
        },
        suggest: {
          path: 'route.about.suggest-value',
          exact: true,
          component: SuggestValue,
          data: {
            layout: 'basic',
            legacyMenu: true,
          },
        },
        feedback: {
          path: 'route.about.feedback',
          exact: true,
          component: Feedback,
          data: {
            layout: 'basic',
            legacyMenu: true,
          },
        },
      },
    },
    valuecomparisonlanding: {
      path: 'route.valuecomparisonlanding',
      exact: true,
      component: ValueComparisonLanding,
      data: {
        layout: 'homepage',
        legacyMenu: true,
      },
    },
    acceptInvite: {
      path: 'route.acceptInvite',
      exact: true,
      component: AcceptInvite,
      data: {
        authUser: false,
      },
    },
    presenting: {
      path: 'route.presenting',
      exact: true,
      component: Presenting,
      data: {
        layout: 'homepage',
        authUser: false,
        // legacyMenu: true,
        background: 'gray',
      },
    },
    shop: {
      path: 'route.shop',
      exact: false,
      component: Shop,
      data: {
        background: 'violet',
      },
    },
    activation: {
      path: 'route.activation',
      component: Activation,
      exact: false,
      data: {
        background: 'plus',
      }
    },
    app: appRoutes,
    newPage: {
      path: 'route.newPage',
      component: NewPage,
      exact: true,
    },
    eticiaPlus: {
      path: 'route.eticiaPlus',
      component: EticiaPlus,
      exact: true,
      data: {
        background: 'black'
      }
    },
    valueGame: {
      path: 'route.valueGame',
      component: ValueGame,
      exact: true,
      data: {
        background: 'black'
      }
    },
    eticiameter: {
      path: 'route.eticiameter',
      component: Eticiameter,
      exact: true,
      data: {
        background: 'eticiaMeter',
      }
    },
    playground: {
      path: 'route.playground',
      component: Playground,
      exact: true,
      data: {
        background: 'black'
      }
    },
  },
};

const translations = {
  en: require('config/translations/locales/routes.en.json'),
  cs: require('config/translations/locales/routes.cs.json'),
};

// eslint-disable-next-line no-extra-parens
export const createUrl = createUrlFactory(eticiaRoute, translations, () => (global as any).requestContext.locale);
export const createPresentingUrl = (id: number, inApp = false) => createUrl(inApp ? 'app.presenting' : 'presenting') + '?id=' + id;

export const createFlatRoutes = (locale: string) => processRoutes(eticiaRoute, translations, locale);
