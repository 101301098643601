import ValueTestResult from './ValueTestResult';
import SurveyResult from './SurveyResult';
import ValuesForTwoResult from './PartnerTestResult/ValuesForTwoResult';

export const resultRoutes = {
  testResult: {
    path: 'route.result.value-game',
    component: ValueTestResult,
    data: {
      background: 'blue',
      authUser: true,
    },
  },
  partnerResult: {
    path: 'route.result.partner-game',
    component: ValuesForTwoResult,
    data: {
      background: 'midnight',
      authUser: true,
    },
  },
  surveyResult: {
    path: 'route.result.survey',
    component: SurveyResult,
    data: {
      background: 'midnight',
      authUser: true,
    },
  },
};
