import { FC, useContext, Fragment } from 'react';
import { Flex, FlexProps, Icon, Link as UILink, Typography } from '@xcorejs/ui';
import LadderIcon from '../components/icons/24/ladder.svg';
import * as React from 'react';
import { GameContext } from 'components/App';
import { PlusTag } from 'components/atoms/Logo';
import { SHOP_SUMMARY } from 'config/apiRoutes';
import { _switch } from 'utils/option';
import { ShopSummaryResponse } from 'components/pages/App/Shop/data';
import Fetch from 'components/atoms/Fetch';
import { Link, useLocation } from 'react-router-dom';
import { createUrl } from 'components/pages/routes';

interface EticiaPlusTimingBoxProps extends FlexProps {
  capitalize?: boolean;
}

const EticiaPlusTimingBox: FC<EticiaPlusTimingBoxProps> = ({ capitalize,  ...props }) => {
  const { eticiaPlus } = useContext(GameContext);
  const shopUrl = createUrl('app.shop');
  const { pathname } = useLocation();

  return (
    <Flex
      borderRadius='1.2rem'
      bg='rgba(11, 12, 28, 0.5)'
      flexDirection={{ _: 'column', sm: 'row' }}
      alignItems='center'
      justifyContent='center'
      width='100%'
      py='1.8rem'
      {...props}
    >
      <PlusTag>
        Eticia Plus
      </PlusTag>

      <Fetch url={SHOP_SUMMARY}>
        {_switch({
          success: (summary: ShopSummaryResponse) => (
            <>
              <Typography
                mt={{ _: '1rem', sm: 0 }}
                ml={{ sm: '1rem' }}
                display='flex'
                alignItems='center'
                flexDirection={{ _: 'column', sm: 'row' }}
              >
                {eticiaPlus ? (
                  <>
                    {summary.eticiaPlusValidTo && (
                      <>
                        Platnost tvého členství:{' '}
                        {Math.round(Math.abs(new Date(summary.eticiaPlusValidTo).getTime()
                          - new Date(Date.now()).getTime()) / (24 * 60 * 60 * 1000)) > 15000
                          ? (
                            <strong style={{ color: 'rgba(242, 173, 124, 1)', marginLeft: '2px' }}>
                              {capitalize ? 'Navždy' : 'navždy'}
                            </strong>
                          ) : (
                            <Fragment>
                              {Math.round(Math.abs(new Date(summary.eticiaPlusValidTo).getTime()
                                - new Date(Date.now()).getTime()) / (24 * 60 * 60 * 1000))} dní
                              {': '}
                              <strong style={{ color: 'rgba(242, 173, 124, 1)', marginLeft: '2px' }}>
                                {new Date(summary.eticiaPlusValidTo).toLocaleDateString()}
                              </strong>
                            </Fragment>
                          )}
                      </>
                    )}

                    <Typography as='p' ml={{ sm: '4rem' }} color='rgba(244, 244, 246, 0.7)'>
                        K dispozici máš:{' '}
                      <strong style={{ color: 'rgba(242, 173, 124, 1)' }}>{summary.availableMagicTokenCount}
                        <Icon svg={<LadderIcon />} fill='rgba(242, 173, 124, 0.8)' width='1.2rem' height='1.8rem' ml='0.2rem' />
                      </strong>

                      {shopUrl !== pathname && (
                        <Link to={createUrl('app.shop') + '#shop'}>
                          <UILink color='rgba(242, 173, 124, 0.8)' ml='1rem' v='underline' borderBottom='1px solid'>
                            Přikoupit
                          </UILink>
                        </Link>
                      )}
                    </Typography>
                  </>
                ) :
                  'Nemáte aktivované členství Eticia Plus'
                }
              </Typography>
            </>
          ),
          fetching: () => <></>,
        })()}
      </Fetch>
    </Flex>
  );
};

export default EticiaPlusTimingBox;
