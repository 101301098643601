import * as React from 'react';
import { Row, Col } from 'react-styled-flexboxgrid';
import { FormattedMessage } from 'react-intl';
import { Heading1 } from '../../../../../atoms/Headings';
import { StyledGamesConfiguration } from '../style';
import { PrimaryButton } from '../../../../../atoms/Buttons';
import { StyledFakeLinkBorder } from '../../../../../atoms/Buttons/styles';
import { ITestTemplate, ISurvey } from '../../../../Sandbox/TestFactory';
import { Circle, CircleDescription } from '../../../../ValueComparisonLanding/components/styles';
import { IStringMap } from 'utils';
import { SectionText } from '../../../../../atoms/Text';
import { BackgroundedBox } from '../../Comparisons/styles';
import SVGresultTwo from '../../../../../atoms/SVGIcons/result/SVGresultTwo';
import { TREE_TEST_ID } from '../../../TestIntro/data';
import Fetch from '../../../../../atoms/Fetch';
import { TEST_TEMPLATE } from '../../../../../../config/apiRoutes';
import { _switch } from '../../../../../../utils/option';
import LoadingSpinner from '../../../../../atoms/Animations/LoadingSpinner';

interface IChooseTopics {
  submit: (surveys: string[]) => any;
  back: () => any;
}

interface IChooseTopicsState {
  surveys: IStringMap<boolean>;
}

class ChooseTopics extends React.Component<IChooseTopics, IChooseTopicsState> {
  public state: IChooseTopicsState = { surveys: {} };

  public render() {
    return (
      <Fetch url={TEST_TEMPLATE(TREE_TEST_ID)}>
        {_switch({
          success: (template: ITestTemplate) => (
            <Col xs={12}>
              <StyledGamesConfiguration>
                <Heading1 className='welcome-heading'>
                  <SVGresultTwo />
                  <FormattedMessage id={'config.chooseTopics.heading'} />
                </Heading1>

                <BackgroundedBox style={{ marginTop: '3rem', marginBottom: '3rem', background: 'rgba(11, 12, 28, 0.5)' }}>
                  <SectionText opacity={true} style={{ marginTop: 0 }}>
                    <FormattedMessage id={'config.chooseTopics.text'} />
                  </SectionText>
                </BackgroundedBox>

                <div className={'boxes topics'}>
                  <Row>
                    {template.surveys.map(s => (
                      <Col key={s.id} xs={12} sm={4} md={2}>
                        <div onClick={this.select(template, s)}>
                          <Circle color={'green'} className={this.getClass(template, s)}>
                            <img src={s.image} alt='' />
                          </Circle>

                          <CircleDescription className={this.getClass(template, s)}>
                            <strong>{s.name}</strong>
                            <p>{s.text}</p>
                          </CircleDescription>
                        </div>
                      </Col>
                    ))}
                  </Row>

                  <PrimaryButton
                    onClick={() => this.props.submit(this.getSurveys())}
                    isUnvisible={!(this.getSurveys().length >= template.minSurveyCount)}
                  >
                    <FormattedMessage id={'config.next'} />
                  </PrimaryButton>

                  <div className={'back'}>
                    <StyledFakeLinkBorder color='yellow' onClick={this.props.back}>
                      <FormattedMessage id={'config.back'} />
                    </StyledFakeLinkBorder>
                  </div>
                </div>
              </StyledGamesConfiguration>
            </Col>
          ),
          fetching: () => <LoadingSpinner />,
        })()}
      </Fetch>
    );
  }

  private getClass = (template: ITestTemplate, survey: ISurvey) =>
    this.state.surveys[survey.id]
      ? 'isSelected'
      : this.getSurveys().length === template.maxSurveyCount
        ? 'isDisabled'
        : '';

  private select = (template: ITestTemplate, survey: ISurvey) => () =>
    !this.state.surveys[survey.id]
      ? this.getSurveys().length !== template.maxSurveyCount &&
        this.setState({
          surveys: { ...this.state.surveys, [survey.id]: true },
        })
      : this.setState({
        surveys: { ...this.state.surveys, [survey.id]: false },
      });

  private getSurveys = () => Object.keys(this.state.surveys).filter(k => this.state.surveys[k]);
}

export default ChooseTopics;
