import * as React from 'react';
import { ITestCompare, ISurveyCompare } from '../data';
import { IStringMap } from 'utils';
import { ILifeValue } from 'store/Entities/LifeValues';
import TestResultThemes from '../ThemePages/TestResultThemes';
import TestResultFinal from '../FinalPages/TestResultFinal';
import { defaultConfig } from '../DefaultPages/DefaultConfig';
import { togetherConfig } from '../TogetherPages/TogetherConfig';
import { statisticConfig } from '../StatisticPages/StatisticConfig';

export const ResultConfig: IConfig = {
  pages: [
    { ...defaultConfig },
    { ...togetherConfig },
    {
      label: 'results.partners.menu.themes',
      name: 'themes',
      containSurvey: true,
      generate: (result, _, pageNumber) => (
        <TestResultThemes
          key={pageNumber || 0}
          surveyResults={sortSurveys(result.surveyResults)}
          profiles={result.profiles}
          currentPage={pageNumber || 0}
        />
      ),
    },
    { ...statisticConfig },
    {
      label: 'results.partners.final.final.heading',
      name: 'final',
      generate: result => (
        <TestResultFinal
          valueCompare={result.valueTestCompares}
          allLifeValues={result.valueTestResults[Object.keys(result.valueTestResults)[0]].allLifeValues}
          profiles={result.profiles}
        />
      ),
    },
  ],
};

export const ConfigWrapper = (data: IPage[]) =>
  data.map((x, index) => {
    return {
      ...x,
      id: index,
      to: '/' + x.name,
    };
  });

interface IConfig {
  pages: IPage[];
}

export interface IPage {
  id?: number;
  label: string;
  to?: string;
  name: string;
  header?: (result: ITestCompare) => React.ReactNode;
  surveyPages?: number;
  generate?: (result: ITestCompare, lifeValues: IStringMap<ILifeValue>, pageNumber?: number) => React.ReactNode;
  subpage?: ISubpage[];
  containSurvey?: boolean;
  footer?: (result: ITestCompare) => React.ReactNode;
}

interface ISubpage {
  label: string;
  name: string;
  header?: (result: ITestCompare) => React.ReactNode;
  generate: (result: ITestCompare, lifeValues: IStringMap<ILifeValue>) => React.ReactNode;
  footer?: (result: ITestCompare) => React.ReactNode;
}

export const pageCounter = (array: ISurveyCompare[]) => array.length - 1;

const sortSurveys = (surveys: ISurveyCompare[]) => {
  const sorted = surveys.sort((a, b) => a.priority - b.priority);
  sorted.forEach(x => x.questions.sort((a, b) => a.priority - b.priority));
  return sorted;
};
