import * as React from 'react';
import SVGclose from '../../SVGIcons/SVGclose';
import { LevelUp3 } from './styles';
import { Heading2 } from '../../Headings';
import { SecondaryButton } from '../../Buttons';
import { CustomText } from '../../Text';
import { FormattedMessage } from 'react-intl';
import QuestObjective from '../../../organisms/QuestLog/QuestObjective';
import { IWrapIntl } from '../../wrapIntl';
import wrapIntl from '../../wrapIntl';
import { PopupComponent } from 'store/Popup';

const Level3: PopupComponent<IWrapIntl> = ({ formatMsg, resolve }) => {
  return (
    <>
      <LevelUp3>
        <div className='content'>
          <a className='close' onClick={() => resolve()}>
            <SVGclose />
          </a>

          <img src='/img/popup/hra-lvl-3.png' alt='' />
          <Heading2>
            <FormattedMessage id={'popupLvl.3.heading'} />
          </Heading2>

          <CustomText>
            <FormattedMessage id={'popupLvl.3.text'} />
          </CustomText>

          <CustomText>
            <strong>
              <FormattedMessage id={'popupLvl.next.3'} />
            </strong>
          </CustomText>

          <div className='next'>
            <QuestObjective
              icon={'/img/ico/tests/game-stats.svg'}
              color='transparent'
              title={formatMsg('questlog.statistics.title')}
              text={formatMsg('questlog.statistics.text')}
              icoBackground={'grey'}
            />

            <QuestObjective
              icon={'/img/ico/tests/game-survey.svg'}
              color='transparent'
              title={formatMsg('questlog.dilema.title')}
              text={formatMsg('questlog.dilema.text')}
              icoBackground={'violet'}
            />
          </div>

          <SecondaryButton onClick={() => resolve()}>
            <FormattedMessage id={'popupLvl.btn'} />
          </SecondaryButton>
        </div>
      </LevelUp3>
    </>
  );
};

export default wrapIntl(Level3);
