import { connect } from 'react-redux';
import * as React from 'react';
import { IApplicationState } from '../../../store/store';
import LoadingSpinner from '../../atoms/Animations/LoadingSpinner';
import { isLoadable, IRescource, isFetched, isReadable } from '../../../utils/rescource';

interface IInnerStateProps<T, OutterProps> {
  props: OutterProps;
  rescource: IRescource<T>;
}

interface IInnerDispatchProps {
  load: () => any;
}

export type InnerProps<T, OutterProps> = IInnerStateProps<T, OutterProps> & IInnerDispatchProps;

export type GetStateData<T> = (state: IApplicationState) => IRescource<T>;
export type GetLoader = (dispatch: any) => () => any;
export type GetComponentProps<T, ComponentProps> = (props: InnerProps<T, {}>) => ComponentProps;

export default <T, IComponentProps>(
  getStateData: GetStateData<T>,
  getLoader: GetLoader,
  getComponentProps: GetComponentProps<T, IComponentProps>,
) => <OutterProps extends {}>(
  Component: React.ComponentType<OutterProps & IComponentProps>,
  readable = true,
): React.ComponentType<OutterProps> =>
    connect(
      (state: IApplicationState, props: OutterProps): IInnerStateProps<T, OutterProps> => ({
        props,
        rescource: getStateData(state),
      }),
      (dispatch: any): IInnerDispatchProps => ({
        load: getLoader(dispatch),
      }),
    )((props: InnerProps<T, OutterProps>) => {
      React.useEffect(() => {
        if (isLoadable(props.rescource)) {
          props.load();
        }
      });
      return !isFetched(props.rescource) ? (
        <LoadingSpinner />
      ) : !readable || isReadable(props.rescource) ? (
        <Component {...props.props} {...getComponentProps(props)} />
      ) : null;
    });

export const mergeRes = <T extends {}>(props: InnerProps<T, any>) =>
  props.props.rescource !== undefined ? props.props.rescource : {};
