import { createAction, createReducer } from 'redux-act';

export interface IFlashMessagesState {
  flashMessages: IFlash[];
}

export interface IFlash {
  text: string;
  read: boolean;
  type: 'error' | 'info';
  time: number;
}

export const emptyLayoutState: IFlashMessagesState = {
  flashMessages: [],
};

export const addInfo = createAction<string, IFlash>('flash: info', text => ({
  text,
  read: false,
  type: 'info',
  time: new Date().getUTCSeconds(),
}));

export const addError = createAction<string, IFlash>('flash: error', text => ({
  text,
  read: false,
  type: 'error',
  time: new Date().getUTCSeconds(),
}));

export const markAsRead = createAction('flash: mark as read', (i: number) => i);

export const flashMessagesReducer = createReducer(
  {
    [addInfo.getType()]: (state, flash: IFlash) => ({
      ...state,
      flashMessages: [...state.flashMessages, flash],
    }),
    [addError.getType()]: (state, flash: IFlash) => ({
      ...state,
      flashMessages: [...state.flashMessages, flash],
    }),
    [markAsRead.getType()]: (state, i: number) => {
      const fm = [...state.flashMessages];
      fm[i].read = true;
      return {
        ...state,
        flashMessages: fm,
      };
    },
  },
  emptyLayoutState,
);
