import { TEST_TEMPLATES, JOURNAL } from 'config/apiRoutes';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import { Col, Grid, Row } from 'react-styled-flexboxgrid';

import { _switch } from '../../../../../utils/option';
import LoadingSpinner from '../../../../atoms/Animations/LoadingSpinner';
import { PrimaryButtonLink } from '../../../../atoms/Buttons';
import Fetch from '../../../../atoms/Fetch';
import { Heading1 } from '../../../../atoms/Headings';
import { CustomText } from '../../../../atoms/Text';
import { createUrl } from '../../../routes';
import { ITestTemplate } from '../../../Sandbox/TestFactory';
import {
  TREE_TEST_STATE,
  ITreeTestResultPreviewResponse,
  ITestResultPreviewResponse,
  IJournal, TREE_TEST_ID
} from '../../TestIntro/data';
import { StyledValueComparisonSection } from '../Comparisons/styles';
import ChooseTest from './components/ChooseTest';
import ChooseTopics from './components/ChooseTopics';
import Confirmation from './components/Confirmation';
import { saveConfiguration } from './data';

interface IComparisonIntroState {
  state: number;
  partner: boolean | null;
  surveys: string[];
  response: ITestResultPreviewResponse | null;
}

class ComparisonSetup extends React.Component<RouteComponentProps<{ id: string }>, IComparisonIntroState> {
  public state: IComparisonIntroState = {
    state: 0,
    partner: null,
    surveys: [],
    response: null,
  };

  public render() {
    // magic token id
    const id = this.props.match.params.id;
    return (
      <Fetch url={JOURNAL}>
        {_switch({
          success: (journal: IJournal) => (
            <>
              {this.check(journal.trees, id) ? (
                <Grid>
                  <Row>
                    <StyledValueComparisonSection>
                      <Col xs={12} md={6}>
                        <Heading1>
                          <FormattedMessage id={'config.usedVocher.heading'} />
                        </Heading1>

                        <CustomText>
                          <FormattedMessage id={'config.usedVocher.text'} />
                        </CustomText>

                        <PrimaryButtonLink to={createUrl('app.comparison') + '/moje-porovnani'}>
                          <FormattedMessage id={'config.usedVocher.button'} />
                        </PrimaryButtonLink>
                      </Col>
                    </StyledValueComparisonSection>
                  </Row>
                </Grid>
              ) : (
                <Grid>
                  <Row>
                    <StyledValueComparisonSection>
                      {_switch([
                        () => (
                          <ChooseTest
                            submit={() => this.setState({ state: 1 })}
                          />
                        ),
                        () => (
                          <ChooseTopics
                            submit={(surveys: string[]) => this.setState({ state: 2, surveys })}
                            back={() => this.setState({ state: 0 })}
                          />
                        ),
                        () => (
                          <Confirmation
                            surveys={this.state.surveys}
                            submit={this.submit}
                            back={() => this.setState({ state: 1, surveys: [] })}
                          />
                        ),
                        () => (
                          <Redirect
                            to={{
                              pathname: createUrl('app.comparison.invitation'),
                              state: {
                                ...this.state.response,
                                voucherId: this.props.match.params.id,
                                button: true,
                              },
                            }}
                            push={true}
                          />
                        ),
                      ])()(this.state.state)()}
                    </StyledValueComparisonSection>
                  </Row>
                </Grid>
              )}
            </>
          ),
          fetching: () => <LoadingSpinner />,
        })()}
      </Fetch>
    );
  }

  private check = (vouchers: ITreeTestResultPreviewResponse[], magicTokenId: string) =>
    (vouchers.find(x => x.magicTokenId === magicTokenId)!.state || 0) > TREE_TEST_STATE.waitingForSetup;

  private findTest = (vouchers: ITreeTestResultPreviewResponse[], magicTokenId: string) =>
    vouchers.find(x => x.magicTokenId === magicTokenId);

  private submit = (note: string) =>
    saveConfiguration({
      testTemplateId: TREE_TEST_ID,
      surveyIds: this.state.surveys,
      note,
    }).then(response =>
      this.setState({
        state: 3,
        response,
      }),
    );
}

export default withRouter(ComparisonSetup);
