import { Heading1, Paragraph } from '@xcorejs/ui';
import { BlueTheme } from 'components/theme/colors';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import Fade from 'react-reveal/Fade';
import { SectionHomepage } from '../styles';
import EticonCarousel from './Carousel/EticonCarousel';

const Eticon = () => {
  return ( 
    <SectionHomepage backgroundColor={BlueTheme.darken} pb={{ _:'0', md: '6rem' }}>
      <Fade ssrReveal bottom duration={1000} distance={'3rem'}>
        <Heading1 textAlign='center' mb='3rem'>
          <FormattedMessage id='homepage.box.eticon.title' />
        </Heading1>
        <Paragraph fontFamily='Oli Grotesk Light' mb='5rem'>
          <FormattedMessage id='homepage.box.eticon.subtitle' />
        </Paragraph>
      </Fade>
      <EticonCarousel _carousel={{ width: '100vw', left: '-3rem' }} />
    </SectionHomepage>
  );
};
 
export default Eticon;