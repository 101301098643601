import { Box, Modal } from '@xcorejs/ui';
import { createPresentingUrl } from 'components/pages/routes';
import { BlueTheme } from 'components/theme/colors';
import * as React from 'react';
import { FC } from 'react';
import styled from 'styled-components';

interface PresentingPageModalProps {
  pageId: number;
  loggedUser?: boolean;
}

const PresentingPageModal: FC<PresentingPageModalProps> = ({ pageId, loggedUser }) => {
  const src = createPresentingUrl(pageId, loggedUser);

  console.log('presenting page url', src);

  return (
    <Modal
      background={BlueTheme.primary}
      p={{ _: '0', sm: '0' }}
      overflowX='hidden'
      height='auto'
      _close={{
        zIndex: 100,
        position: 'absolute'
      }}
    >
      <BoxStyle>
        <iframe
          src={src}
          width='100%'
          height='100%'
          frameBorder='0'
        />
      </BoxStyle>
    </Modal>
  );
};

export default PresentingPageModal;

const BoxStyle = styled(Box)`
  iframe {
    margin-top: -6rem;
    height: 100vh;
    max-height: calc(100vh - 6rem);
  }
`;