import { pdf } from '@react-pdf/renderer';
import { Box, Button, Container, Heading1, Typography } from '@xcorejs/ui';
import Subnav from 'components/atoms/Subnav';
import PdfResultId from 'components/pages/App/pdfs/result/[id]';
import { ITestResultResponse } from 'components/pages/App/TestIntro/data';
import { createUrl } from 'components/pages/routes';
import { CURRENT_PROFILE, TESTS_RESULTS_DETAIL } from 'config/apiRoutes';
import { saveAs } from 'file-saver';
import { FC, useEffect, useState } from 'react';
import * as React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { IUserInfo } from 'store/User';
import { IFetchException } from 'utils/exceptions';
import { jsonFetch } from 'utils/fetch';

const generatePdfDocument = async (result: ITestResultResponse, user: IUserInfo, fileName: string, ) => {
  const blob = await pdf((
    <PdfResultId result={result} user={user} />
  )).toBlob();
  saveAs(blob, fileName);
};

const PdfResult: FC = () => {
  const [user, setUser] = useState<IUserInfo>();
  const [testResponse, setTestResponse] = useState<ITestResultResponse>();

  const authResult = new URLSearchParams(window.location.search);
  const code = authResult.get('id');

  useEffect(() => {
    jsonFetch<IUserInfo>(CURRENT_PROFILE)
      .then(user => setUser(user))
      .catch((e: IFetchException) => console.log('error'));
    // eslint-disable-next-line react-hooks/exhaustive-deps

    code && jsonFetch<ITestResultResponse>(TESTS_RESULTS_DETAIL(code))
      .then(testResponse => setTestResponse(testResponse))
      .catch((e: IFetchException) => console.log('error'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    user && testResponse && code && generatePdfDocument(testResponse, user, code);
  }, [user, testResponse, code]);

  return (
    <Box>
      <Subnav />

      <Container py={{ _: '6rem', sm: '18rem' }} flexDirection='column' alignItems='center' maxWidth='48rem'>
        <Heading1 textAlign='center'>
          <FormattedHTMLMessage id='resultPdfDetail.heading' />
        </Heading1>

        <Typography mt='3rem'>
          <FormattedHTMLMessage id='resultPdfDetail.text' />
        </Typography>

        <Link to={createUrl('app.journal')}>
          <Button as='span' mt='6rem'>
            <FormattedHTMLMessage id='resultPdfDetail.button' />
          </Button>
        </Link>
      </Container>
    </Box>
  );
};

export default PdfResult;