import * as React from 'react';
import SVGclose from '../../../atoms/SVGIcons/SVGclose';
import SVGok from '../../../atoms/SVGIcons/SVGok';
import { voteDefinition } from '../actions';

interface IVote {
  id: string;
  defId: string;
  type: string;
  onVote: (id: string, type: string, text?: string) => any;
}

const Vote: React.FC<IVote> = ({ id, defId, type, onVote }) => (
  <div className={'action-btns'}>
    <span onClick={() => voteDefinition(id, defId, type, false).then(onVote(defId, type))}>
      <SVGclose />
    </span>
    <span onClick={() => voteDefinition(id, defId, type, true).then(onVote(defId, type))}>
      <SVGok />
    </span>
  </div>
);

export default Vote;
