import React, { FC } from 'react';
import { Box, Paragraph } from '@xcorejs/ui';
import { OliRegular } from 'components/theme/fonts';

type QuestionCircleProps = {
    circleSize: 'sm' | 'large';
}

const QuestionCircle: FC<QuestionCircleProps> = ({ circleSize }) => {
  return ( 
    <Box
      border='rgba(11, 12, 28, 1)'
      bg='rgba(11, 12, 28, 0.5)'
      width={circleSize === 'sm' ? '8.5rem' : '10rem'}
      height={circleSize === 'sm' ? '8.5rem' : '10rem'}
      borderRadius='50%'
      overflow='hidden'
      display='flex'
      alignItems='center'
      justifyContent='center'
    >
      <Paragraph 
        fontSize='3.8rem !important' 
        lineHeight='2.2rem !important'
        margin='0 !important'
        fontFamily={OliRegular + '!important'}
        color='rgba(255, 255, 255, 0.5) !important'>
          ?
      </Paragraph>
    </Box>
  );
};
 
export default QuestionCircle;