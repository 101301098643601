import * as React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Col, Grid, Row } from 'react-styled-flexboxgrid';

import { TESTS_RESULTS } from 'config/apiRoutes';
import { _switch } from 'utils/option';
import LoadingSpinner from '../../../atoms/Animations/LoadingSpinner';
import { PrimaryButtonLink } from '../../../atoms/Buttons';
import Fetch from '../../../atoms/Fetch';
import { Heading2 } from '../../../atoms/Headings';
import { CustomText } from '../../../atoms/Text';
import { createUrl } from '../../../pages/routes';
import { WaitingForResult as StyledWaitingForResult } from './styles';
import { ITestResultPreviewResponse } from '../../../pages/App/TestIntro/data';

const WaitingForResult: React.FC<{ id: string; errorCode: number }> = ({ id, errorCode }) => (
  <StyledWaitingForResult>
    {errorCode === 403 ? (
      <>
        <Heading2>
          <FormattedMessage id={'results.partners.notYourTest.heading'} />
        </Heading2>

        <CustomText>
          <p>
            <FormattedHTMLMessage id={'results.partners.notYourTest.text'} />
          </p>
        </CustomText>
        <PrimaryButtonLink to={createUrl('app')}>
          <FormattedMessage id={'results.partners.notYourTest.button'} />
        </PrimaryButtonLink>
      </>
    ) : (
      <>
        <Fetch url={TESTS_RESULTS}>
          {_switch({
            success: (data: ITestResultPreviewResponse[]) => (
              <>
                <Heading2>
                  <FormattedMessage id={'results.partners.together.waiting'} />
                </Heading2>

                <CustomText>
                  <p>
                    <FormattedHTMLMessage id={'results.partners.together.waitingText'} />
                  </p>
                </CustomText>

                {data.filter(x => x.testConfigId === id).length > 0 && (
                  <CustomText>
                    <p>
                      <FormattedHTMLMessage id={'results.continue.valueTest'} />
                    </p>
                  </CustomText>
                )}

                <div className={'btn-wrap'}>
                  {data.filter(x => x.testConfigId === id).length > 0 && (
                    <PrimaryButtonLink
                      to={{
                        pathname: createUrl('app.testResult', {
                          id: data.filter(x => x.testConfigId === id)[0].valueTestResultId ?? '',
                        }),
                        state: createUrl('app.comparison') + '/moje-porovnani',
                      }}
                    >
                      <FormattedMessage id={'results.continue.valueTest.next'} />
                    </PrimaryButtonLink>
                  )}

                  <PrimaryButtonLink to={createUrl('app.comparison') + '/moje-porovnani'}>
                    <FormattedMessage id={'results.continue.invites'} />
                  </PrimaryButtonLink>
                </div>
              </>
            ),
            fetching: () => <LoadingSpinner />,
            reload: () => <LoadingSpinner />,
            fail: () => (
              <Grid>
                <Row>
                  <Col xs={12} md={6} mdOffset={3}>
                    <Heading2>
                      <FormattedMessage id={'results.partners.together.waiting'} />
                    </Heading2>

                    <CustomText>
                      <p>
                        <FormattedHTMLMessage id={'results.partners.together.waitingText'} />
                      </p>
                    </CustomText>
                    <PrimaryButtonLink to={createUrl('app.comparison') + '/moje-porovnani'}>
                      <FormattedMessage id={'results.continue.invites'} />
                    </PrimaryButtonLink>
                  </Col>
                </Row>
              </Grid>
            ),
          })()}
        </Fetch>
      </>
    )}
  </StyledWaitingForResult>
);

export default WaitingForResult;
