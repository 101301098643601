import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { createUrl } from './routes';
import { PrimaryButtonLink } from '../atoms/Buttons';
import { StyledAcceptInvite } from './styles';
import { Heading1 } from '../atoms/Headings';
import { CustomText } from '../atoms/Text';
import withPopups, { IWithPopups } from '../molecules/withRedux/withPopups';
import SVGplay from '../atoms/SVGIcons/SVGplay';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { PlayButton } from '../atoms/Buttons';
import VimeoPopup from '../atoms/Popups/VimeoPopup';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import { StyledLinkBorder } from '../atoms/Buttons/styles';

const AcceptInvite: React.FC<RouteComponentProps<{ id: string }> & IWithPopups> = ({ match, popups }) => (
  <StyledAcceptInvite>
    <Grid>
      <Row>
        <Col xs={12}>
          <Heading1>
            <FormattedMessage id={'acceptInvite.heading'} />
          </Heading1>

          <CustomText>
            <FormattedHTMLMessage id={'acceptInvite.text'} />
          </CustomText>

          <PrimaryButtonLink to={createUrl('app.comparison.startTest', { id: match.params.id })}>
            <FormattedMessage id={'acceptInvite.btn'} />
          </PrimaryButtonLink>

          <small>
            <FormattedMessage id={'acceptInvite.register'} />
          </small>

          <CustomText>
            <FormattedHTMLMessage id={'acceptInvite.text3'} />{' '}
            <StyledLinkBorder to={createUrl('valuecomparisonlanding')} color={'yellow'} target={'_blank'}>
              <FormattedHTMLMessage id={'acceptInvite.link'} />
            </StyledLinkBorder>
          </CustomText>

          <img
            src='/img/logo/eticia-logo.png'
            srcSet='/img/logo/eticia-logo.png, /img/logo/eticia-logo@2x.png 2x'
            alt='Eticia21'
            className={'logo'}
          />

          <CustomText>
            <FormattedMessage id={'acceptInvite.text2'} />
          </CustomText>

          <PlayButton onClick={() => popups.custom(() => <VimeoPopup id={'376328496'} />)}>
            <SVGplay />
            <FormattedMessage id={'vlc.video.play'} />
          </PlayButton>
        </Col>
      </Row>
    </Grid>
  </StyledAcceptInvite>
);

export default withRouter(withPopups(AcceptInvite));
