import styled from 'styled-components';
import { white, yellow, darkBaseHEX, primaryGreen, yellowLighter, whiteFade, grayFooter } from '../../theme/colors';
import { Fox } from '../../theme/fonts';

export const StyledTextInput = styled.input`
  width: 100%;
  border-radius: 0.4rem;
  background-color: ${white};
  color: ${darkBaseHEX};
  font-size: 1.8rem;
  line-height: 2.2rem;
  padding: 1.4rem 2rem;
  border: none;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${darkBaseHEX};
    opacity: 0.4;
  }
`;

const TextareaElement = StyledTextInput.withComponent('textarea');

// TODO: Fix typing after upgrade
export const StyledTextArea = styled(TextareaElement)`
  height: 10.5rem;
  resize: none;
  font-family: ${Fox};
  border: 2px solid ${whiteFade};
  transition: border 0.3s;

  &:hover,
  &:focus {
    border: 2px solid ${grayFooter};
  }
`;

export const StyledCheckboxInput = styled.div`
  position: relative;
  text-align: left;

  .error {
    display: block;
    text-align: left;
    color: ${yellow};
    margin-bottom: 0;
    margin-top: 2rem;
  }

  .terms {
    color: ${yellow};
    border-bottom: 1px solid ${yellow};
    font-size: 1.8rem;
    line-height: ${props => (props.theme.size ? props.theme.size : 3.2)}rem;
    cursor: pointer;
  }

  .fake-checkbox {
    width: 3.2rem;
    height: 3.2rem;
    display: inline-block;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 0.4rem;
    background-color: #fff;
    transition: border-color 300ms ease;

    &.green {
      background-color: ${primaryGreen};
    }

    &:before {
      content: url(/img/ico/check-white.svg);
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      opacity: 0;
      transition: opacity 300ms ease;
      width: 2.4rem;
      height: 1.7rem;
    }
  }

  input[type="checkbox"],
  input[type="radio"] {
    width: 4rem;
    height: 4rem;
    display: none;

    & + label {
      //font-size: 1.8rem;
      margin-left: 0;
      padding-left: 5.2rem;
      line-height: 2.2rem;

      &:before,
      &:after {
        display: none;
      }
    }

    &:checked + label {
      &:before {
        background-color: ${yellow};
      }

      &:after {
        opacity: 1;
      }
    }

    &:hover {
      & + label {
        .fake-checkbox {
          border-color: rgba(255, 255, 255, 0.8);
        }
      }
    }

    &:checked + label {
      .fake-checkbox {
        background-color: ${yellow};
        border-color: rgba(255, 255, 255, 0.8);

        &:before {
          opacity: 1;
        }
      }
    }
  }

  label {
    //font-size: 1.8rem;

    a {
      opacity: 0.8;
      color: ${yellow};
      font-size: 1.8rem;
      line-height: 2.2rem;
      display: inline-block;
      cursor: pointer;

      span {
        border-bottom: 1px solid ${yellowLighter};
        transition: border 0.3s;
      }

      &:hover {
        span {
          border-bottom: 1px solid transparent;
        }
      }
    }
  }
`;

export const StyledFormLabel = styled.label`
  margin-bottom: 1.5rem;
  display: inline-block;
  font-size: 2rem;
  line-height: 2.6rem;
  color: ${white};
  opacity: 0.9;
`;
