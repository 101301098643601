import styled, { css } from 'styled-components';
import { mediaMdDefault } from '../../theme/grid';
import StyledSubnav from '../../atoms/Subnav/styles';
import { StyledShowMenuButton } from '../ShowMenuButton/style';
import { Grid } from 'react-styled-flexboxgrid';
import { darkBaseRGB } from '../../theme/colors';
import { StyledHelpLink } from '../../atoms/Popups/styles';

export const StyledPage = styled.div`
  display: flex;
  position: relative;
  min-height: 100vh;

  *::selection {
    background-color: ${props => props.theme.darken};
    color: #fff;
  }

  &.no-scroll {
    height: 100vh;
    overflow: hidden;
  }

  &.no-oil {
    main {
      padding: 4rem 0 0;
    }
  }

  &.intro {
    &:before,
    &:after {
      display: none;
    }
  }

  &.homepage {
    &:before,
    &:after {
      display: none;
    }

    main {
      padding-bottom: 0;

      ${mediaMdDefault} {
        padding-top: 0;
      }
    }
  }

  &.basic {
    &:before,
    &:after {
      display: none;
    }

    main {
      padding-top: 15rem;
      padding-bottom: 0;
    }
  }

  &.sticky-footer {
    main {
      & > section {
        padding-bottom: 16rem;
        min-height: calc(100vh - 18.5rem);
      }

      footer {
        position: absolute;
        bottom: 0;
        width: 100%;
      }
    }

    ${mediaMdDefault} {
      main {
        & > section {
          padding-bottom: 10rem;
          min-height: calc(100vh - 18.5rem);
        }
      }
    }
  }

  &.showableMenu {
    ${StyledSubnav} {
      ${StyledShowMenuButton} {
        ${mediaMdDefault} {
          display: inline-block;
        }
      }
    }
  }
`;
export const StyledMain = styled.main<{ layout?: string }>`
  position: relative;
  padding: 5rem 0 0;
  width: 100%;
  min-height: 100vh;

  ${mediaMdDefault} {
    padding: 6rem 0 0;
  }

  ${Grid as any} {
    max-width: 100%;
    padding-right: ${props => props.theme.flexboxgrid.gutterWidth / 2}rem;
    padding-left: ${props => props.theme.flexboxgrid.gutterWidth / 2}rem;
  }

  .flashMessage {
    display: block;
    position: relative;

    li {
      width: calc(100% - 60px);
      text-align: center;
      padding: 1.5rem 4rem;
      margin-left: 3rem;
      background-color: rgba(${props => props.theme.flash}, 0.5);
      border-radius: 4px;
      transition: background 0.3s;
      position: relative;
    }

    button {
      position: absolute;
      top: 50%;
      margin-top: -0.8rem;
      right: 1.5rem;
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      appearance: none;
      background: none;
      border: 0;
      display: inline-block;
      padding: 0;
      cursor: pointer;

      svg {
        transition: transform 0.3s;
      }

      &:hover {
        svg {
          transform: rotate(90deg);
        }
      }

      &:focus {
        outline: none;
      }
    }
  }

  ${props =>
    props.layout === 'test' &&
    css`
      padding: 0;
      background: rgba(${darkBaseRGB}, 0.15);

      ${mediaMdDefault} {
        padding: 0;
      }
    `};

  & > .main-popup-button {
    position: fixed;
    right: 3rem;
    bottom: 3rem;

    ${mediaMdDefault} {
      ${StyledHelpLink} {
        width: 5rem;
        height: 5rem;
      }
    }
  }
`;
