import { Box, Button, Flex, Heading3, Icon, Img, Paragraph, useModal } from '@xcorejs/ui';
import { FreeTag, PlusTag } from 'components/atoms/Logo';
import { createUrl } from 'components/pages/routes';
import { BlueTheme } from 'components/theme/colors';
import { OliLight } from 'components/theme/fonts';
import React, { CSSProperties, FC, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import BackIcon from '../../icons/leftArrow.svg';
import AllLaddersModalBeta from '../AllLaddersModalBeta';
import LaddersCompareMatrixModal from '../LaddersCompareMatrixModal/LaddersCompareMatrixModal';
import LifeValuesPresentingModal from '../LifeValuesPresentingModal';
import { FlipCardProps } from './data';
import { FlipCardsBlockProps } from './FlipCardsBlock';

type FCFlipCardProps = FlipCardProps & FlipCardsBlockProps & {
  onClick: () => void;
  onMouseLeave: () => void;
};

const FlipCard: FC<FCFlipCardProps> = ({ 
  className, 
  onClick, 
  onMouseLeave,
  backDescription,
  frontDescription,
  img,
  title,
  link,
  onClose,
  free,
  plus
}) => {
  const [openLaddersCompareMatrixModal] = useModal(LaddersCompareMatrixModal);
  const [openAllLaddersModal] = useModal(AllLaddersModalBeta);
  const [openLifeValuesPresentingModal] = useModal(LifeValuesPresentingModal);
  const [close] = useModal();
  const [justClicked, setJustClicked] = useState(false);

  const handleClick = () => {
    setJustClicked(true);
    onClick();
    setTimeout(() => setJustClicked(false), 500);
  };

  const handleMouseLeave = () => {
    if (!justClicked) onMouseLeave();
  };
  
  const button = useMemo(() => {
    const modalHelper = (openModal: () => void) => onClose ? onClose(() => openModal()) : openModal();

    if (!link) return undefined;
    if (link.type === 'link') return {
      to: createUrl(link.path), 
      onClick: () => onClose ? onClose() : null
    };
    if (link.type === 'modal') {
      switch (link.path) {
      case 'ladders-compare-matrix':
        return () => modalHelper(() => openLaddersCompareMatrixModal({ close }));
      case 'all-ladders':
        return () => modalHelper(() => openAllLaddersModal({}));
      case 'life-values-presenting':
        return () => modalHelper(() => openLifeValuesPresentingModal({}));
      default:
        return undefined;
      }
    }
  }, [link, onClose, openLaddersCompareMatrixModal, close, openAllLaddersModal, openLifeValuesPresentingModal]);

  const littleMoveDelay = useMemo(() => ({ '--flip-card--animation-delay': '-' + Math.ceil(Math.random() * 10000) + 'ms' }), []);
  // nastavuje delay animace 'littleMove' uvnitř css souboru

  return (
  
    <Box
      className={`flip-card ${className}`}
      onClick={handleClick}
      onMouseLeave={handleMouseLeave}
      style={littleMoveDelay as CSSProperties}
    >
      <Box className='flip-card__inner'>
        <Flex className='flip-card__front'>
          {plus || free ? (
            <Flex justifyContent='flex-end' alignItems='flex-start' position='absolute' top='0.5rem' right='0.5rem' zIndex={className.includes('flipped') ? 0 : 1} transition='opacity 500ms ease, z-index 500ms ease'>
              {free ? <FreeTag transform='scale(0.8)' onClick={() => null}>Free</FreeTag> : null}
              {plus ? <PlusTag transform='scale(0.8)' onClick={() => null}>Plus</PlusTag> : null}
            </Flex>
          ) : null }
          
          <Img src={img} alt='' />

          <Flex
            flexDirection='column'
            alignItems='center'
            width='100%'
            position='absolute'
            bottom='0'
            background='linear-gradient(180deg, transparent 0%, rgba(12,14,15,0.1) 10%, rgba(12, 14, 15, 0.5))'
            padding={{ _: '1rem 1.5rem', sm: '1.5rem 2rem' }}
          >
            <Heading3 marginTop={{ _: '0.5rem', sm: '1rem' }}>
              <FormattedMessage id={title} />
            </Heading3>

            <Paragraph
              marginTop={{ _: '0.5rem', sm: '1rem' }}
              lineHeight='1.7rem'
            >
              <FormattedMessage id={frontDescription} />
            </Paragraph>
          </Flex>
        </Flex>

        <Flex className='flip-card__back'>
          <Icon
            svg={<BackIcon />}
            onClick={(e) => {
              e.stopPropagation();
              onMouseLeave();
            }}
            position='absolute' 
            zIndex={100}
            left='0.5rem' 
            top='0.5rem'
            width='3rem'
            height='3rem'
            cursor='pointer'
            display={{ _: 'block', sm: 'none' }}
            color={`${BlueTheme.light}`}
          />
          
          <Heading3>
            <FormattedMessage id={title} />
          </Heading3>

          <Paragraph
            marginTop={{ _: '0.5rem', sm: '1rem' }}
            fontSize={{ _: '1.3rem', sm: '1.3rem' }}
            lineHeight={{ _: '1.7rem', sm: '1.7rem' }}
            fontFamily={OliLight}
            textAlign='center'
          >
            <FormattedMessage id={backDescription} />
          </Paragraph>
          {button ? 
            typeof button === 'object' ? (
              <Link to={button.to} onClick={button.onClick}>
                <FormattedMessage id={link!.text} />
              </Link>
            ) : (
              <Button
                onClick={button}
                marginTop={{ _: '0.5rem', sm: '1rem' }}
                fontSize={{ _: '1.3rem', sm: '1.3rem' }}
                lineHeight={{ _: '1.7rem', sm: '1.7rem' }}
                height='unset'
                padding={{ _: '1rem 3rem !important', sm: '1rem 3rem !important' }}
              >
                <FormattedMessage id={link!.text} />
              </Button>
            )
            : null}
        </Flex>
      </Box>
    </Box>
  );
};
 
export default FlipCard;