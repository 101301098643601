import * as React from 'react';

const SVGclosegray: React.FC = () => {
  return (
    <svg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' transform='translate(-1318.000000, -312.000000)'>
        <g className='fill' transform='translate(535.000000, 269.000000)' fill='#D1D3DA'>
          <g transform='translate(770.000000, 30.000000)'>
            <path d='M26.4117647,25 L37,35.5882353 L35.5882353,37 L25,26.4117647 L14.4117647,37 L13,35.5882353 L23.5882353,25 L13,14.4117647 L14.4117647,13 L25,23.5882353 L35.5882353,13 L37,14.4117647 L26.4117647,25 Z' />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SVGclosegray;
