import * as React from 'react';
import { FC } from 'react';
import Main from './components/Main';
import { StyledHomepage } from '../styles';
import CarouselHP from './components/CarouselHP';
import Explain from './components/Explain';
import Eticon from './components/Eticon';
import WhatNext from './components/WhatNext';
import ValueGame from './components/ValueGame';
import AboutEticia from './components/AboutEticia';
import Supporters from './components/Supporters';
import Navigation from 'components/atoms/HPNav';

const Homepage: FC = () => {
  return (
    <>
      <Navigation />

      <StyledHomepage>
        <Main />
        <CarouselHP />
        <Explain />
        <Eticon />
        <WhatNext />
        <ValueGame />
        <AboutEticia />
        <Supporters />
      </StyledHomepage>
    </>
  );
};

export default Homepage;

