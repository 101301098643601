import * as React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { StatisticItem, StyledCircle } from './styles';
import Pulse from 'react-reveal/Pulse';
import ExternalSVG from '../../atoms/ExternalSVG';
import { ISliderItem } from './data';
import StatisticsItemBadge from './StatisticsItemBadge';

type StatisticsSliderItemProps = {
  item: ISliderItem;
  statisticLevel?: number;
  big?: number;
};
const StatisticsSliderItem: React.FC<StatisticsSliderItemProps> = ({ item, big, statisticLevel }) => {
  const showText =
    statisticLevel && item.visible ? (
      statisticLevel <= item.visible ? (
        <StatisticsItemBadge variant='level' level={item.visible} />
      ) : (
        undefined
      )
    ) : item.locked ? (
      <StatisticsItemBadge variant='premium' />
    ) : (
      undefined
    );

  return big !== undefined ? (
    <StatisticItem
      color={item.color}
      size='big'
      locked={statisticLevel && item.visible ? item.locked || statisticLevel <= item.visible : item.locked}
      className={'big'}
    >
      <StyledCircle size='big'>
        <Pulse spy={big}>
          <div>
            {item.icon ? (
              <ExternalSVG src={item.icon!} className={'icon-top'} />
            ) : (
              <ExternalSVG src={'/img/stats-question-mark.svg'} className={'icon-top'} />
            )}

            <div className='number'>
              {item.percentage}
              {item.percentage ? ' %' : <FormattedMessage id='statistics.slider.lock' />}
            </div>

            <div>
              {item.text2 && (
                <div className='total'>
                  <FormattedMessage id={item.text2!} />
                </div>
              )}

              {item.text && (
                <div className='total'>
                  <FormattedMessage id={item.text} />
                </div>
              )}
            </div>
          </div>
        </Pulse>
      </StyledCircle>

      {showText}

      <div className='description'>
        <FormattedHTMLMessage id={item.textBottom} />
      </div>
    </StatisticItem>
  ) : (
    <StatisticItem
      color={item.color}
      size='small'
      locked={statisticLevel && item.visible ? item.locked || statisticLevel <= item.visible : item.locked}
    >
      <ExternalSVG src={'/img/statisticsCircle.svg'} className={'svg-bg'} />

      <StyledCircle size='small'>
        {item.percentage ? (
          <strong className='small-percantage'>
            {item.percentage}
            {' %'}
          </strong>
        ) : (
          item.icon && <img src={item.icon} alt='' />
        )}

        {item.iconText && (
          <span className='iconText'>
            <FormattedMessage id={item.iconText!} />
          </span>
        )}

        <div className='total'>
          {item.text2 && (
            <div>
              <strong>
                <FormattedMessage id={item.text2!} />
              </strong>
            </div>
          )}

          {item.text && <FormattedMessage id={item.text} />}

          {statisticLevel && item.visible
            ? item.locked || statisticLevel <= item.visible
            : item.locked && <ExternalSVG src='/img/ico/game-locked.svg' className='locked' />}
        </div>
      </StyledCircle>

      {showText}

      <div className='description'>
        <FormattedHTMLMessage id={item.textBottom} />
      </div>
    </StatisticItem>
  );
};

export default StatisticsSliderItem;
