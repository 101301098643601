import * as React from 'react';
import { connect } from 'react-redux';
import Registration from './component';
import { openRawInfoPopup, openHtmlInfoPopup } from '../../../store/Popup/infoPopups';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: any) => ({
  popup: (content: React.ComponentType, title?: string, button?: string) =>
    // dispatch(layoutOpenPopup("raw", body, "terms.heading", button)),
    openRawInfoPopup({ content, title, button })(dispatch),
  popupParents: () =>
    // dispatch(layoutOpenPopup("html", "terms.parents", "terms.parents.heading"))
    openHtmlInfoPopup({ content: 'terms.parents', title: 'terms.heading' })(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Registration);