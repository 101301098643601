import styled from 'styled-components';

import { OliThin } from 'components/theme/fonts';
import { setStyleValue } from 'utils/setStyleValue';
import { mediaMdDefault } from 'components/theme/grid';

interface StyledListProps {
  fontSize?: string | {
    xs?: string;
    sm?: string;
    md?: string;
    lg?: string;
    _?: string;
  };
  marker: string;
  markerColor: string;  
}

export const StyledList = styled.ul<StyledListProps>`
  font-size: ${({ fontSize }) => setStyleValue(fontSize, '1.4rem')};

  list-style: none;
  padding-left: 0;
  margin-top: 2rem;
  
  display: flex;
  flex-direction: column;
  align-items: center;

  li {
    position: relative;
    padding-left: 2rem;
    margin-bottom: 2rem;
    font-size: 1.4rem;
    font-family: ${OliThin};
    color: #fff;
    width: max-content;

    &:before {
      content: '';
      position: absolute;
      top: -5px;
      left: -10px;
      width: 2.4rem;
      height: 2.4rem;
      background-color: ${({ markerColor }) => markerColor};
      mask-image: url(${({ marker }) => marker});
      -webkit-mask-image: url(${({ marker }) => marker});
      mask-repeat: no-repeat;
    }
  }

  ${mediaMdDefault} {
    font-size: ${({ fontSize }) => setStyleValue(fontSize, '1.6rem')};
  }
`;

export const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  flex-shrink: 0;
  width: inherit;

  & > * {
    max-width: 25rem;

    ${mediaMdDefault} {
      max-width: 30rem;
    }
  }
`;
