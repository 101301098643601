import { DetailedHTMLProps, InputHTMLAttributes } from 'react';
import * as React from 'react';
import { IInlineFormGroup } from './InlineFormGroup';
import styled from 'styled-components';
import { Box, BoxProps, Typography } from '@xcorejs/ui';

type InputProps = Partial<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>>;
type TextAreaProps = Partial<DetailedHTMLProps<InputHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>>;


interface IInlineFormInput extends InputProps {
  input?: (value: string) => unknown;
  multiline?: boolean;
}

interface IInlineFormTextarea extends TextAreaProps {
  input?: (value: string) => unknown;
  multiline?: boolean;
}

const removeProps = (_props: IInlineFormGroup & IInlineFormInput & IInlineFormTextarea) => {
  const props = { ..._props };
  delete props.input;
  delete props.error;
  delete props.className;
  return props;
};

const InlineFormInput: React.FC<IInlineFormGroup & IInlineFormInput & BoxProps> = props => (
  <>
    <InputStyle
      bg='rgba(11, 12, 28, 0.7)'
      borderRadius='2.6rem'
      color='rgba(255, 255, 255, 0.8)'
      p='1.6rem'
      lineHeight='2rem'
      textAlign='center'
      {...props as BoxProps}
    >
      {props.multiline ? (
        <textarea
          style={{ height: '100%' }}
          {...{ ...removeProps(props as any), className: props.error ? 'error' : '' } as any}
          onChange={e => {
            props.onChange && props.onChange(e);
            props.input && props.input(e.target.value);
          }}
        />
      ) : (
        <input
          {...{ ...removeProps(props as any), className: props.error ? 'error' : '' }}
          onChange={e => {
            props.onChange && props.onChange(e);
            props.input && props.input(e.target.value);
          }}
        />
      )}
    </InputStyle>

    <Typography color='#e3d240' mt='0.5rem' textAlign='center'>{props.error}</Typography>
  </>
);

export default InlineFormInput;

export const InputStyle = styled(Box)`
  input, textarea {
    color: inherit;
    font-family: inherit;
    background: transparent;
    border: none;
    box-shadow: inherit;
    -webkit-appearance: inherit;
    transition: inherit;
    resize: none;
    width: 100%;
    text-align: inherit;

    &:focus {
      border: none;
      outline: none;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 3rem transparent inset !important;
    }

    ::-webkit-inner-spin-button{
      -webkit-appearance: none; 
      margin: 0; 
    }
    
    ::-webkit-outer-spin-button{
      -webkit-appearance: none; 
      margin: 0; 
    }    
  }
`;
