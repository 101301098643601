import styled, { css } from 'styled-components';
import { darkBaseRGB, yellow, white, gray, grayRGB } from '../../theme/colors';
import { mediaMdDefault, mediaSmMax } from '../../theme/grid';

export const StyledRegularParagraph = styled.p`
  font-size: 1.6rem;
  line-height: 2.4rem;
`;

const StyledBodyText = styled.div`
  color: ${white};
  font-weight: 300;
  text-align: center;
  font-size: 1.6rem;
  line-height: 2.4rem;

  p:not(:first-child) {
    margin-top: 2rem;
  }

  ${mediaMdDefault} {
    text-align: left;
    font-size: 1.8rem;
    line-height: 2.7rem;
  }

  em {
    font-style: normal;
    color: ${yellow};
    font-weight: 500;
  }

  strong {
    font-weight: 500;
  }
`;

export const StyledSmallerText = styled(StyledBodyText)`
  font-size: 1.8rem;
  line-height: 2.8rem;

  ${mediaSmMax} {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  em {
    font-weight: 400;
    color: rgba(255, 255, 255, 0.7);
  }
`;

export const BiggerText = StyledBodyText;
export const CustomText = StyledSmallerText;

export const StrongBigText = styled.strong`
  color: ${white};
  font-weight: 500;
  font-size: 2rem;
  line-height: 3rem;
  display: block;

  ${mediaSmMax} {
    text-align: center;
    font-size: 1.8rem;
    line-height: 2.8rem;
  }
`;

export const BackgroundedText = styled.div`
  padding: 2rem;
  border-radius: 0.4rem;
  background-color: rgba(${darkBaseRGB}, 0.25);
  box-shadow: 0 0.5rem 1rem 0 rgba(${darkBaseRGB}, 0.4);
`;

export const SectionText = styled.p<{ color?: string; opacity?: boolean }>`
  color: ${gray};
  font-size: 1.6rem;
  line-height: 2rem;
  text-align: center;

  ${props =>
    props.color &&
    css`
      color: ${props.color} !important;
    `}

  &.opacity {
    color: rgba(${grayRGB}, 0.7);
  }

  ${props =>
    props.opacity &&
    css`
      color: rgba(${grayRGB}, 0.7);
    `}
`;
