import { IStringMap } from '../../utils';

{
  /* refactor barev */
}
export const transparent = 'transparent';

export const darkBaseRGB = '25, 29, 44';
export const darkBaseHEX = '#191D2C';
export const graphContour = '#dad5be';

export const yellow = '#ffde71';
export const yellowRGB = '255, 222, 113';
export const yellowLighter = '#FAD457';
export const darkenYellow = '#C3B222';

export const helpIcon = '255, 255, 255';
export const helpIconHover = '255, 255, 255';

export const blue = '#91A1B8';
export const blueRGB = '145, 161, 184';
export const lightBlue = '#91A1B8';
export const darkenBlue = '#4d6aa1';
export const extraDarkBlue = '#435C8D';

export const primaryDarling = '#AA70A0';
export const lightDarling = '#C4A2B3';
export const darkenDarling = '#A36B99';
export const extraDarkDarling = '#8C5D86';

export const primaryMidnight = '#45919E';
export const lightMidnight = '#85A7AF';
export const darkenMidnight = '#3E838F';
export const extraDarkMidnight = '#36717D';
export const flashMidnight = '#1D2B7C';

export const primaryPlus = '#B14669';
export const lightPlus = '#C68993';
export const darkenPlus = '#A64263';
export const extraDarkPlus = '#8F3A58';
export const flashPlus = '#BE4C7C';

export const primaryPink = '#AF7DC2';
export const lightPink = '#C0A5BF';
export const darkenPink = '#9B70AC';
export const extraDarkPink = '#856196';
export const flashPink = '#FF0164';

export const red = '#b2476a';

export const gray = '#f4f4f6';
export const grayRGB = '244, 244, 246';

export const primaryGray = '#82809C';
export const lightGray = '#A7A5AA';
export const darkenGray = '#73718A';
export const extraDarkGray = '#636279';
export const flashGray = '#88889c';

export const primaryKids = '#5BA0C6';
export const lightKids = '#94BAC5';
export const darkenKids = '#5293B6';
export const extraDarkKids = '#477F9F';
export const flashKids = '#38BAD4';

export const orange = '#F2AD7C';

export const white = '#ffffff';
export const whiteFade = '#D1D3DA';
export const darkenWhite = '#E5EBF2';
export const yellowWhite = '#e7e6cf';

export const darkenGreen = '#449f81';
export const primaryGreen = '#3e8f74';
export const primaryGreenRGB = '5, 134, 92';
export const lightGreen = '#88V79D';
export const ExtraDarkGreen = '#367B66';

export const peaGreen = 'rgba(90, 159, 136, 1)';

export const darkenGreenOverlay = '#4e2551';
export const primaryGreenOverlay = '#542a52';

export const violet = '#7E76ab';
export const primaryViolet = '#7E76ab';
export const darkenViolet = '#746D9C';
export const lightViolet = '#A8A3B5';
export const extraDarkViolet = '#645E88';
export const flashViolet = '#7E76ab';

export const textGreen = '#00E58D';
export const textGreenRGB = '0, 229, 141';

export const timelineViolet = '#390B7E';

export const premium = '#7F2099';
export const premiumDark = '#5A0C70';
export const premiumBlue = '#141B49';
export const premiumBlack = '#1B2032';
{
  /* puvodni barvy */
}
export const flashBlueRGB = '145, 161, 184';
export const primaryBlue = '#5674B4';
export const primaryBlueRGB = '86, 118, 179';

export const darkBlueExtra = '#080B16';
export const selectColor = '#656873';

export const lightGrayBG = '#F8F8F8';
export const blueBtnBG = '#1E5080';

export const grayFooter = '#AEAEBE';
export const grayFooterRGB = '174, 174, 190';

export interface IColorTheme {
  graphContour: string;
  primary: string;
  light: string;
  darken: string;
  extraDark: string;
  overlay: string;
  text: string;
}

export const GreenTheme = {
  graphContour: graphContour,
  primary: primaryGreen,
  darken: darkenGreen,
  overlay: transparent,
  text: textGreen,
  flash: textGreenRGB,
  light: lightGreen,
  extraDark: ExtraDarkGreen,

};

export const BlueTheme = {
  graphContour: graphContour,
  primary: primaryBlue,
  darken: darkenBlue,
  overlay: transparent,
  text: white,
  flash: flashBlueRGB,
  light: lightBlue,
  extraDark: extraDarkBlue,
};

export const GoldTheme = {
  graphContour: graphContour,
  primary: '#cbcb24',
  darken: '#b4a418',
  extraDark: '#b4a418',
  overlay: transparent,
  text: white,
  flash: '#b4a418',
  light: '#b4a418',
};

export const RedTheme = {
  graphContour: graphContour,
  primary: red,
  darken: '#b1476a',
  extraDark: '#b1476a',
  overlay: transparent,
  text: white,
  flash: red,
  light: red,
};

export const VioletTheme = {
  graphContour: graphContour,
  primary: primaryViolet,
  darken: darkenViolet,
  overlay: transparent,
  text: white,
  flash: flashViolet,
  light: lightViolet,
  extraDark: extraDarkViolet,
  
};

export const MidnightTheme = {
  graphContour: graphContour,
  primary: primaryMidnight,
  darken: darkenMidnight,
  overlay: transparent,
  text: white,
  flash: flashMidnight,
  light: lightMidnight,
  extraDark: extraDarkMidnight,

};

export const EmeraldTheme = {
  graphContour: graphContour,
  primary: '#1d757c',
  darken: '#45919E',
  extraDark: '#45919E',
  overlay: transparent,
  text: white,
  flash: '#1d757c',
  light: lightPlus,
};

export const KidsTheme = {
  graphContour: graphContour,
  primary: primaryKids,
  darken: darkenKids,
  overlay: transparent,
  text: white,
  flash: flashKids,
  light: lightKids,
  extraDark: extraDarkKids,
  
};

export const TreeTheme = {
  graphContour: graphContour,
  primary: '#68c3e5',
  darken: '#3dad74',
  extraDark: '#3dad74',
  overlay: transparent,
  text: white,
  flash: '#68c3e5',
  light: white,
};

export const PlusTheme = {
  graphContour: graphContour,
  primary: primaryPlus,
  darken: darkenPlus,
  overlay: transparent,
  text: white,
  flash: flashPlus,
  lightPlus: lightPlus,
  light: lightPlus,
  extraDark: extraDarkPlus,
  
};

export const GrayTheme = {
  graphContour: graphContour,
  primary: primaryGray,
  darken: darkenGray,
  overlay: transparent,
  text: white,
  flash: flashGray,
  light: lightGray,
  extraDark: extraDarkGray,
  
};

export const PinkTheme = {
  graphContour: graphContour,
  primary: primaryPink,
  darken: darkenPink,
  overlay: transparent,
  text: white,
  flash: flashPink,
  light: lightPink,
  extraDark: extraDarkPink,
  
};

export const CenterTheme = {
  graphContour: graphContour,
  primary: '#2f8f6b',
  darken: '#205f47',
  extraDark: '#205f47',
  overlay: transparent,
  text: white,
  light: white,

};

export const DarlingTheme = {
  graphContour: graphContour,
  primary: primaryDarling,
  darken: darkenDarling,
  overlay: transparent,
  text: white,
  light: lightDarling,
  extraDark: extraDarkDarling,

};

export const BlackTheme = {
  graphContour: graphContour,
  primary: '#1d1c21',
  darken: '#1d1c21',
  extraDark: '#1d1c21',
  overlay: transparent,
  text: white,
  light: white,
};

export const MiddleStatisticsTheme = {
  graphContour: graphContour,
  primary: 'rgb(77, 106, 161)',
  darken: 'rgb(67, 94, 141)',
  extraDark: 'rgb(42, 59, 90)',
  overlay: transparent,
  text: white,
  light: white,
};

export const LeastStatisticsTheme = {
  graphContour: graphContour,
  primary: '#6780af',
  darken: '#5974a8',
  extraDark: 'rgb(145, 161, 184)',
  overlay: transparent,
  text: whiteFade,
  light: white,
};

export const BrownTheme = {
  graphContour: graphContour,
  primary: '#734A4A',
  darken: '#644143',
  extraDark: '#301f20',
  overlay: transparent,
  text: white,
  light: '#996262',
};

export const Color1Theme = {
  graphContour: graphContour,
  primary: '#FFFAA0',
  darken: '#CCC880',
  extraDark: '#999660',
  overlay: transparent,
  text: '#121212',
  light: '#F7FFA0',
};

export const Color2Theme = {
  graphContour: graphContour,
  primary: '#FFB3C6',
  darken: '#FF8FA6',
  extraDark: '#FF6B86',
  overlay: transparent,
  text: '#121212',
  light: '#FFD3E6',
};

export const Color3Theme = {
  graphContour: graphContour,
  primary: '#A5FFB3',
  darken: '#7AFF8F',
  extraDark: '#4AFF6B',
  overlay: transparent,
  text: '#121212',
  light: '#D3FFD3',
};

export const Color4Theme = {
  graphContour: graphContour,
  primary: '#98DEDE',
  darken: '#6CC1C1',
  extraDark: '#3FA4A4',
  overlay: transparent,
  text: '#121212',
  light: '#B2ECEC',
};

export const EticiaMeterTheme = {
  graphContour: graphContour,
  primary: '#3FC9EC',
  darken: '#6bc2d6',
  extraDark: '#1F89AC',
  overlay: transparent,
  text: '#fafafa',
  light: '#5bbcd2',
};

export const ColoredThemes: IStringMap<IColorTheme> = {
  green: GreenTheme,
  blue: BlueTheme,
  gold: GoldTheme,
  red: RedTheme,
  violet: VioletTheme,
  midnight: MidnightTheme,
  pink: PinkTheme,
  emerald: EmeraldTheme,
  darling: DarlingTheme,
  kids: KidsTheme,
  tree: TreeTheme,
  plus: PlusTheme,
  gray: GrayTheme,
  centerTheme: CenterTheme,
  darlingTheme: DarlingTheme,
  black: BlackTheme,
  middleStatisticsTheme: MiddleStatisticsTheme,
  leastStatisticsTheme: LeastStatisticsTheme,
  brown: BrownTheme,
  color1: Color1Theme,
  color2: Color2Theme,
  color3: Color3Theme,
  color4: Color4Theme,
  eticiaMeter: EticiaMeterTheme,
};
