import PdfResult from 'components/pages/App/pdfs/result';
import WelcomePlus from 'components/pages/App/WelcomePlus';
import { IThemeData } from '../routes';
import { IRoute } from '../../../utils/router';
import ValueTest from './ValueTest';
import TestIntro from './TestIntro';
import ValueCenter from './ValueCenter';
import Feedback from './Feedback';
import FeedbackBug from './Feedback/FeedbackBug';
import Survey from './Survey';
import { valueComparisonRoutes } from './ValueComparison/routes';
import { resultRoutes } from './Result/routes';
import { aboutRoutes } from './About/routes';
import { journalRoutes } from './Journal/routes';
import PartnerTest from './ValueTest/PartnerTest';
import Faq from './Faq';
import Profile from './Profile';
import ValuesEticons from './ValuesEticons';
import Shop from './Shop';
import Presenting from 'components/pages/App/Presenting';
import Activation from 'components/pages/App/Activation';
import General from 'components/pages/App/Statistics/General';
import Funny from 'components/pages/App/Statistics/Funny';
import Game from 'components/pages/App/ValueCenter/Game';
import PdfResultId from 'components/pages/App/pdfs/result/[id]';
import EticiaPlusPresenting from './EticiaPlus/EticiaPlus';
import World from './Statistics/World';
import InterestingComparison from './InterestingComparison';

export const appRoutes: IRoute<IThemeData> = {
  path: 'route.app',
  exact: false,
  component: ValueCenter,
  data: {
    authUser: true,
    background: 'blue',
  },
  children: {
    ...valueComparisonRoutes,
    ...resultRoutes,
    ...aboutRoutes,
    ...journalRoutes,
    valueWord: {
      path: 'route.valueWord',
      exact: true,
      component: ValuesEticons,
      data: {
        layout: 'homepage',
        authUser: true,
        background: 'green',
      },
    },
    game: {
      path: 'route.game',
      exact: true,
      component: ValueTest,
      data: {
        layout: 'test',
        authUser: true,
        background: 'blue',
      },
    },
    partnerTest: {
      path: 'route.partner-test',
      exact: true,
      component: PartnerTest,
      data: {
        layout: 'test',
        authUser: true,
      },
    },
    survey: {
      path: 'route.survey',
      exact: true,
      component: Survey,
      data: {
        layout: 'test',
        authUser: true,
        background: 'midnight'
      },
    },
    gameIntro: {
      path: 'route.gameIntro',
      exact: true,
      component: TestIntro,
      data: {
        authUser: true,
        layout: 'test',
        background: 'blue'
      },
    },
    feedback: {
      path: 'route.feedback',
      exact: true,
      component: Feedback,
      data: {
        authUser: true,
      },
      children: {
        bug: {
          path: 'route.feedback.bug',
          exact: true,
          component: FeedbackBug,
          data: {
            authUser: true,
          },
        },
      },
    },
    stats: {
      path: 'route.statistics',
      exact: true,
      component: Funny,
      data: {
        authUser: true,
        background: 'blue',
      },
    },
    statsGeneral: {
      path: 'route.statistics.general',
      exact: true,
      component: General,
      data: {
        authUser: true,
        background: 'gray',
      },
    },
    statsWorld: {
      path: 'route.statistics.world',
      exact: true,
      component: World,
      data: {
        authUser: true,
        background: 'midnight',
      },
    },
    faq: {
      path: 'route.faq',
      exact: true,
      component: Faq,
      data: {
        background: 'blue',
        authUser: true,
      },
    },
    profile: {
      path: 'route.profile',
      exact: false,
      component: Profile,
      data: {
        background: 'blue',
        authUser: true,
      }
    },
    shop: {
      path: 'route.shop',
      exact: false,
      component: Shop,
      data: {
        background: 'violet',
        authUser: true,
      },
    },
    presenting: {
      path: 'route.presenting',
      exact: false,
      component: Presenting,
      data: {
        background: 'emerald',
        authUser: true,
      },
    },
    activation: {
      path: 'route.activation',
      component: Activation,
      exact: false,
      data: {
        background: 'plus',
        authUser: true,
      }
    },
    gameGame: {
      path: 'route.gameGame',
      component: Game,
      exact: false,
      data: {
        background: 'black',
        authUser: true,
      }
    },
    pdfResultId: {
      path: 'route.pdfResultId',
      exact: false,
      component: PdfResultId,
      data: {
        authUser: true,
      }
    },
    pdfResult: {
      path: 'route.pdfResult',
      exact: false,
      component: PdfResult,
      data: {
        authUser: true,
        background: 'blue',
      }
    },
    welcomePlus: {
      path: 'route.welcomePlus',
      exact: false,
      component: WelcomePlus,
      data: {
        authUser: true,
        background: 'plus',
      }
    },
    eticiaPlus: {
      path: 'route.eticiaPlus',
      exact: true,
      component: EticiaPlusPresenting,
      data: {
        authUser: true,
        background: 'plus',
      }
    },
    valuesForTwo: {
      path: 'route.valuesForTwo',
      exact: true,
      component: InterestingComparison,
      data: {
        authUser: true,
        background: 'darling',
      }
    },
    personalValues: {
      path: 'route.personalValues',
      exact: true,
      component: InterestingComparison,
      data: {
        authUser: true,
        background: 'blue',
      }
    }
  },
};
