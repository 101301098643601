import { Img } from '@xcorejs/ui';
import LoadingSpinnerLocal from 'components/atoms/Animations/LoadingSpinnerLocal';
import { useUser } from 'components/atoms/hooks/useUser';
import { IStatistic } from 'components/molecules/StatisticsSlider/data';
import Ladders from 'components/pages/App/Statistics/components/Ladders';
import StatisticsModal, { StatisticsModalProps } from 'components/pages/App/Statistics/components/StatisticModal';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useFetchStatGroupMatchProfile } from 'utils/useQuery/queryFetchHelpers';
import { ModalContentStyle, StatisticsModalStyle } from '../style';

const RelationshipModal: FC<StatisticsModalProps> = () => {
  const user = useUser();
  const statisticsProfileQuery = useFetchStatGroupMatchProfile();

  if (statisticsProfileQuery.isLoading)
    return <LoadingSpinnerLocal />;

  const statistics = statisticsProfileQuery.data as IStatistic[];
  
  return (
    <StatisticsModal
      {...StatisticsModalStyle({ minHeightInput:'65.5rem', minMobileHeightInput:'66rem', colorSchemeInput:'gray' })}
      content={
        [
          {
            heading: <FormattedMessage id='statistics.site.other.result3.head1' />,
            tag: <FormattedMessage id='statistics.relationship.content1.tag' />,
            _tag: {
              bg: 'rgba(72, 73, 91, 1)'
            },
            text: <FormattedMessage id='statistics.relationship.content1.text' />,
            box1: <Img src='//placehold.it/180x273' alt='' />,
            text2: <FormattedMessage id='statistics.relationship.content1.text2' />,
            ...ModalContentStyle
          },
          {
            heading: <FormattedMessage id='statistics.site.other.result3.head1' />,
            tag: statistics
              .filter(s => s.maritalStatus === 2)
              .reduce((max, stat) => max.value < stat.value ? max : stat).title,
            text: <FormattedMessage id='statistics.relationship.content2.text2' />,
            circle: {
              name: statistics
                .filter(s => s.maritalStatus === 2)
                .reduce((max, stat) => max.value < stat.value ? max : stat).title,
              img: statistics
                .filter(s => s.maritalStatus === 2)
                .reduce((max, stat) => max.value < stat.value ? max : stat).image,
              circleSize: 'large'
            },
            text2: <FormattedMessage id='statistics.relationship.content2.text2' />,
            box2: <Ladders
              user={user}
              values={
                statistics
                  .filter(s => s.maritalStatus === 2)
                  .reduce((max, stat) => max.value < stat.value ? max : stat)
                  .topTenValueResults
              } />,
            ...ModalContentStyle
          },
          {
            heading: <FormattedMessage id='statistics.site.other.result3.head1' />,
            tag: statistics
              .filter(s => s.maritalStatus === 1)
              .reduce((max, stat) => max.value < stat.value ? max : stat).title,
            _tag: {
              bg: 'rgba(10, 65, 116, 1)'
            },
            text: <FormattedMessage id='statistics.relationship.content3.text' />,
            circle: {
              name: statistics
                .filter(s => s.maritalStatus === 1)
                .reduce((max, stat) => max.value < stat.value ? max : stat).title,
              img: statistics
                .filter(s => s.maritalStatus === 1)
                .reduce((max, stat) => max.value < stat.value ? max : stat).image,
              circleSize: 'large'
            },
            text2: <FormattedMessage id='statistics.relationship.content3.text2' />,
            box2: <Ladders
              user={user}
              values={
                statistics
                  .filter(s => s.maritalStatus === 1)
                  .reduce((max, stat) => max.value < stat.value ? max : stat)
                  .topTenValueResults
              } />,
            ...ModalContentStyle
          },
        ]
      }
    />
  );
};

export default RelationshipModal;
