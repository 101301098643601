import * as React from 'react';
import { IProfile } from '../data';
import { IStringMap } from '../../../../../../utils';
import { Col, Row } from 'react-styled-flexboxgrid';
import { StyledColorBox, ColorBoxName, ImgWrapper } from '../../../../../organisms/PartnerTest/Result/styles';
import { Heading2 } from '../../../../../atoms/Headings';
import { StyledResultBoxes } from 'components/molecules/TestResult/AnswerBox/styles';
import { IValueTestResultDetail } from 'config/api/valueTestResult';
import { CustomText } from '../../../../../atoms/Text/index';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

interface IData {
  profiles: IStringMap<IProfile>;
  eticons: IStringMap<IValueTestResultDetail>;
}

const DefaultEticon: React.FC<IData> = ({ profiles, eticons }) => (
  <StyledResultBoxes>
    <Row>
      {Object.keys(profiles).map((key, index) => (
        <Col xs={12} md={5} mdOffset={index === 0 ? 1 : 0} lg={4} lgOffset={index === 0 ? 2 : 0} key={index}>
          <Fade bottom ssrReveal duration={1000} distance={'5rem'}>
            <div>
              <Heading2>{profiles[key].fullname == null ? profiles[key].nick : profiles[key].fullname}</Heading2>

              <StyledColorBox color={index === 0 ? 'green' : 'blue'} className='eticon'>
                <div>
                  <Zoom delay={400}>
                    <ImgWrapper>
                      <img src={eticons[key].eticon.icon} alt={eticons[key].eticon.name} />
                    </ImgWrapper>
                    <ColorBoxName>{eticons[key].eticon.name}</ColorBoxName>
                  </Zoom>
                </div>
              </StyledColorBox>
              <CustomText>
                <p
                  dangerouslySetInnerHTML={{
                    __html: eticons[key].eticon.text,
                  }}
                />
              </CustomText>
            </div>
          </Fade>
        </Col>
      ))}
    </Row>
  </StyledResultBoxes>
);

export default DefaultEticon;
