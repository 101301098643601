import styled, { css } from 'styled-components';
import {
  blueRGB,
  darkBaseHEX,
  darkBaseRGB,
  darkenBlue, MidnightTheme, PlusTheme,
  premium,
  premiumDark,
  primaryBlue,
  primaryBlueRGB,
  primaryGreen,
  red,
  white,
  yellow,
  ColoredThemes,
  yellowWhite,
  graphContour
} from '../../../theme/colors';
import { StyledPrimaryButton } from '../../Buttons/styles';
import { bold } from '../../../theme/fonts';
import { mediaMdDefault, mediaSmDefault, mediaSmMax } from '../../../theme/grid';


export const StyledFaqPopup = styled.div<{
color?: 'red' | 'blue' | 'yellow' | 'green' | 'black';
  background?: string;
  width?: number;
  fullScreen?: boolean;
  border?: 'valuesForTwo';
}>`
  padding: 3rem 2rem 4rem;
  border-radius: 0.4rem;

  background: radial-gradient(circle at top, rgba(${blueRGB}, .25) 0%, #e5ebf2 100%), #fff;
  box-shadow: 0 1rem 2rem 0 rgba(${darkBaseRGB}, 0.5);
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 65rem;
  max-width: calc(100% - 4rem);
  max-height: calc(100vh - 4rem);
  min-height: 489px;
  margin: 0 auto;
  color: ${darkBaseHEX};
  white-space: normal;
  overflow: auto;
  
  ${mediaSmDefault} {
    padding: 3rem 6rem 4rem;
    
    ${props =>
    props.width &&
      css`
        width: ${props.width}rem;
      `}
  }
  
  ${props =>
    props.fullScreen &&
    css`
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;

      ${props.background === 'blue' &&
        css`
          background: radial-gradient(circle at top, rgba(${primaryBlueRGB}, 0.9) 0%, rgba(23, 105, 180, 0.9) 100%) !important;
        `}
    `}

  ${props =>
    props.color === 'red' &&
    css`
      border-top: 5px solid ${red};
    `}
  
  ${props =>
    props.color === 'yellow' &&
    css`
      border-top: 5px solid ${yellow};
    `}
  
  ${props =>
    props.color === 'blue' &&
    css`
      border-top: 5px solid ${primaryBlue};
    `}
  
  ${props =>
    props.color === 'green' &&
    css`
      border-top: 5px solid ${primaryGreen};
    `}
  
  ${props =>
    props.background === 'blue' &&
    css`
      background: radial-gradient(circle at top, ${primaryBlue} 0%, ${darkenBlue} 100%);

      ${StyledPrimaryButton} {
        color: ${white};
      }
    `}
  
  ${props =>
    props.background === 'valuesForTwo' &&
    css`
      background: radial-gradient(circle at top, ${MidnightTheme.primary} 0%, ${MidnightTheme.darken} 100%);

      ${StyledPrimaryButton} {
        color: ${white};
      }
    `}
  
  ${props =>
    props.background === 'green' &&
    css`
      background: radial-gradient(circle at top, ${PlusTheme.primary} 0%, ${PlusTheme.darken} 100%);

      ${StyledPrimaryButton} {
        color: ${white};
      }
    `}
  
  ${props =>
    props.background === 'premium' &&
    css`
      background: radial-gradient(circle at top, ${premium} 0%, ${premiumDark} 100%);

      ${StyledPrimaryButton} {
        color: ${white};
      }
    `}
    
  ${props =>
    props.background === 'blue' &&
    css`
      background: radial-gradient(circle at top,${ColoredThemes.blue.primary} 0%, ${ColoredThemes.blue.darken} 100%) !important;

      ${StyledPrimaryButton} {
        color: ${white};
        background: ${ColoredThemes.blue.extraDark} !important;
        border: 2px solid ${graphContour} !important;
      }
    `}

  ${props =>
    props.background === 'gray' &&
    css`
      background: radial-gradient(circle at top, ${ColoredThemes.gray.darken} 0%, ${ColoredThemes.gray.darken} 100%) !important;

      ${StyledPrimaryButton} {
        color: ${white};
        background: ${ColoredThemes.gray.extraDark} !important;
        border: 2px solid ${graphContour} !important;
      }
    `}

  ${props =>
    props.background === 'plus' &&
    css`
      background: radial-gradient(circle at top, ${ColoredThemes.plus.darken} 0%, ${ColoredThemes.plus.darken} 100%) !important;

      ${StyledPrimaryButton} {
        color: ${white};
        background: ${ColoredThemes.plus.extraDark} !important;
        border: 2px solid ${graphContour} !important;
      }
    `}
    
  ${props =>
    props.background === 'midnight' &&
    css`
      background: radial-gradient(circle at top, ${ColoredThemes.midnight.darken} 0%, ${ColoredThemes.midnight.darken} 100%) !important;

      ${StyledPrimaryButton} {
        color: ${white};
        background: ${ColoredThemes.midnight.extraDark} !important;
        border: 2px solid ${graphContour} !important;
      }
    `}

  ${props =>
    props.background === 'pink' &&
    css`
      background: radial-gradient(circle at top, ${ColoredThemes.pink.darken} 0%, ${ColoredThemes.pink.darken} 100%) !important;

      ${StyledPrimaryButton} {
        color: ${white};
        background: ${ColoredThemes.pink.extraDark} !important;
        border: 2px solid ${graphContour} !important;
      }
    `}

  ${props =>
    props.background === 'violet' &&
    css`
      background: radial-gradient(circle at top, ${ColoredThemes.violet.darken} 0%, ${ColoredThemes.violet.darken} 100%) !important;

      ${StyledPrimaryButton} {
        color: ${white};
        background: ${ColoredThemes.violet.extraDark} !important;
        border: 2px solid ${graphContour} !important;
      }
    `}

  ${props =>
    props.background === 'kids' &&
    css`
      background: radial-gradient(circle at top, ${ColoredThemes.kids.darken} 0%, ${ColoredThemes.kids.darken} 100%) !important;

      ${StyledPrimaryButton} {
        color: ${white};
        background: ${ColoredThemes.kids.extraDark} !important;
        border: 2px solid ${graphContour} !important;
      }
    `}

  ${props =>
    props.background === 'darling' &&
    css`
      background: radial-gradient(circle at top, ${ColoredThemes.darling.darken} 0%, ${ColoredThemes.darling.darken} 100%) !important;

      ${StyledPrimaryButton} {
        color: ${white};
        background: ${ColoredThemes.darling.extraDark} !important;
        border: 2px solid ${graphContour} !important;
      }
    `}

  ${props =>
    props.border === 'valuesForTwo' &&
    css`
      border: 0.4rem solid ${premium};
    `}

  ${StyledPrimaryButton} {
    margin-top: 3rem;
    background: transparent;
    border: 2px solid ${yellow};
    width: 50%; 
    max-width: 28rem;
    padding-left: 2rem;
    padding-right: 2rem;
    /* bottom: 30px; */
    position: relative; /* absolute */
    left: 25%; /* 50 */
    transform: translate(-50%, 0);    
    ${mediaSmMax} {
      margin-top: 1rem;
    }
    
    &:hover {
      background: ${yellow};
    }
  }

  .external-svg {
    svg {
      display: block;
      width: 4.8rem;
      height: 4.8rem;
      max-height: 4.8rem;
      margin: 0 auto;

      path {
        fill: ${primaryBlue};
      }
    }
  }

  h2 {
    margin-top: 2rem;
    color: ${primaryBlue};
    font-size: 2.4rem;
    line-height: 3.2rem;
    
    ${mediaSmMax} {
      margin-top: 1rem;
    }
  }

  h3 {
    margin-top: 3rem;
    /*color: ${yellowWhite}; příprava na dark mode?*/
    color: ${darkBaseHEX};
    font-size: 2.2rem;
    line-height: 3.4rem;
    font-weight: ${bold};
    
    ${mediaSmMax} {
      margin-top: 2rem;
    }
    
    &.clicked {
      color: ${primaryBlue};
      cursor: pointer;
    }
  }

  p {
    margin-top: 2rem;
    /*color: ${yellowWhite}; příprava na dark mode?*/
    color: ${darkBaseHEX};
    font-size: 1.6rem;
    line-height: 2.4rem;
 
  p#line1 {
    font-size: 1.8rem;
    line-height: 2.6rem;
  }
  p#line1final {
    font-size: 1.8rem;
    line-height: 2.6rem;
  }
  p#line2 {
    font-size: 1.4rem;
    line-height: 2.6rem;
  }
  p#line3 {
    font-size: 1.4rem;
    line-height: 2.6rem;
  }
  
  .faq-next {
    margin-top: 5rem;
    
    ${mediaSmMax} {
      margin-top: 3rem;
    }
    
    h3 {
      margin-top: 0;
      
      ${mediaSmMax} {
        display: none;
      }
    }
    
    ${StyledPrimaryButton} {
      margin-top: 1.5rem;
      margin-bottom: -1.5rem;
      border: transparent;
      padding: 0;
      height: auto;
      color: ${primaryBlue};
      
      span {
        border-bottom: 1px solid ${primaryBlue};
      }
      
      &:hover {
        background: transparent;
        
        span {
          border-bottom: 1px solid transparent;
        }
      }
      
      ${mediaSmMax} {
        margin-top: 0;
        margin-bottom: 1rem;
      }
    }
  }
  
  .green {
    margin-top: 4rem;
    
    p {
      color: ${primaryGreen};
      font-size: 2.4rem;
      line-height: 3.2rem;
    }
  }
  
  .icons {
    .external-svg:not(:first-child) {
      margin-top: 4rem;
    }
    
    h3 {
      margin-top: 1rem;
    }
  }
  
  .min-height {
    ${mediaSmDefault} {
      width: 100%;
      padding-bottom: 58.9%;
      margin-bottom: 2rem;
      position: relative;
    
      picture {
        position: absolute;
        height: 100%;
        left: 0;
        top: 0;
      }
    }
  }
`;

export const StyledFaqCloseButton = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 4rem;
  height: 4rem;
  top: 1.5rem;
  right: 1.5rem;
  border: 2px solid ${graphContour};
  border-radius: 50%;
  cursor: pointer;

  svg {
    width: 1.7rem;
    height: 1.7rem;
  }
`;

export const StyledFaqPopupLink = styled.div`
  margin-top: 1rem;
  color: ${primaryBlue};
  font-size: 1.6rem;
  line-height: 2.4rem;
  cursor: pointer;

  &:first-of-type {
    margin-top: 4rem;
  }

  span {
    border-bottom: 1px solid ${primaryBlue};
    transition: border 0.3s;
  }

  &:hover {
    span {
      border-bottom: 1px solid transparent;
    }
  }
`;

export const StyledFaqImgPopup = styled.div<{ size?: string }>`
  border-radius: 0.4rem;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  max-width: calc(100% - 4rem);
  max-height: calc(100vh - 4rem);
  margin: 0 auto;
  color: ${darkBaseHEX};
  white-space: normal;
  overflow: auto;

  img {
    display: block;

    @media (min-width: 360px) {
      width: 32rem;
    }

    ${mediaMdDefault} {
      width: 50rem;

      ${props =>
    props.size &&
        css`
          width: ${props.size};
        `}
    }
  }

  div {
    position: relative;
  }

  ${StyledFaqCloseButton} {
    z-index: 1;
  }

  ${StyledPrimaryButton} {
    position: absolute;
    bottom: 3rem;
    left: 50%;
    transform: translate(-50%);
  }
`;
