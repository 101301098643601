import InlineFormInput from 'components/atoms/InlineForm/InlineFormInput';
import InlineFormSelect from 'components/atoms/InlineForm/InlineFormSelect';
import wrapIntl, { IWrapIntl } from 'components/atoms/wrapIntl';
import withPopups, { IWithPopups } from 'components/molecules/withRedux/withPopups';
import { CheckboxInputStyle, CheckedIconStyle } from 'components/pages/App/Shop/Buy';
import {
  IRegisterMeeticiaUserErrors,
  IRegisterMeeticiaUserVm,
  IRegisterUserVm
} from 'components/pages/Registration/component';
import * as React from 'react';
import { Box, Container, Flex, Img, Typography, Heading3, Button, Link } from '@xcorejs/ui';
import { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Fade from 'react-reveal/Fade';
import { Option } from 'react-select';

interface MeeticiaFormProps {
  errors: IRegisterMeeticiaUserErrors;
  sendRegistration: (user: IRegisterUserVm) => unknown;
  knownUserInfo: IRegisterMeeticiaUserVm | null;
  popup: (body: React.ComponentType, title: string, button?: string) => unknown;
}

const MeeticiaForm: FC<MeeticiaFormProps & IWrapIntl & IWithPopups> = (
  {
    knownUserInfo,
    errors,
    sendRegistration,
    formatMsg,
    popups
  }) => {
  const [user, setUser] = React.useState<IRegisterMeeticiaUserVm>({
    name: knownUserInfo && knownUserInfo.name ? errors.Name ? '' : knownUserInfo.name : '',
    nick: knownUserInfo && knownUserInfo.nick ? errors.Nick ? '' : knownUserInfo.nick : '',
    email: knownUserInfo && knownUserInfo.email ? errors.Email ? '' : knownUserInfo.email : '',
    password: knownUserInfo && knownUserInfo.password ? errors.Password ? '' : knownUserInfo.password : '',
    femalePronouns: knownUserInfo && knownUserInfo.femalePronouns ? knownUserInfo.femalePronouns : undefined,
    child: false,
    yearOfBirth: knownUserInfo && knownUserInfo.yearOfBirth ? knownUserInfo.yearOfBirth : undefined,
    interestSex: knownUserInfo && knownUserInfo.interestSex ? knownUserInfo.interestSex : undefined,
    interestType: knownUserInfo && knownUserInfo.interestType ? knownUserInfo.interestType : undefined,
    interestAgeFrom: knownUserInfo && knownUserInfo.interestAgeFrom ? knownUserInfo.interestAgeFrom : undefined,
    interestAgeTo: knownUserInfo && knownUserInfo.interestAgeTo ? knownUserInfo.interestAgeTo : undefined,
    description: knownUserInfo && knownUserInfo.description ? knownUserInfo.description : '',
    file: knownUserInfo && knownUserInfo.file ? knownUserInfo.file : undefined,
  });
  const [agree, setAgree] = useState<boolean | 'error'>(false);

  return (
    <Flex
      bg="url('/img/meeticia/background.jpg')"
      backgroundPosition='center bottom'
      backgroundSize='cover'
      {...{ backgroundAttachment: 'fixed' }}
      minHeight='100vh'
      top={0}
      pt='6rem'
      pb='3.5rem'
      mt='-6rem'
      mb='-3.5rem'
    >
      <Container flexDirection='column' alignItems='center'>
        <Img src='/img/meeticia/logo_meet.png' width='18.9rem' height='7.6rem' alt='' />

        <Typography textAlign='center' marginTop='3rem'>
          Zaregistruj se do Meetica BETA a doplň své údaje.
        </Typography>

        <Flex width='100%' justifyContent='center'>
          <form
            style={{ width: '100%' }}
            onSubmit={e => {
              e.preventDefault();
              agree ? sendRegistration(user) : setAgree('error');
            }}
          >
            <Fade ssrReveal bottom duration={1000} distance={'5rem'}>
              <Box>
                <Box maxWidth='41.6rem' width='100%' mx='auto'>
                  <Flex flexDirection='column' mt='3.2rem'>
                    <Typography mb='0.5rem' textAlign='center' color='#fff'>
                      Má přezdívka pro seznamku (viditelné)
                    </Typography>

                    <InlineFormInput
                      name='name'
                      error={errors.Nick && errors.Nick[0]}
                      value={user.nick}
                      input={nick => setUser({ ...user, nick })}
                      background='rgba(11, 12, 28, 0.5)'
                    />
                  </Flex>

                  <Flex flexDirection='column' mt='2rem'>
                    <Typography mb='0.5rem' textAlign='center' color='#fff'>
                      E-mail (jen pro naší komunikaci s tebou)
                    </Typography>

                    <InlineFormInput
                      name='email'
                      type='email'
                      error={errors.Email && errors.Email[0]}
                      value={user.email}
                      input={email => setUser({ ...user, email })}
                      background='rgba(11, 12, 28, 0.5)'
                    />
                  </Flex>
                  <Flex flexDirection='column' mt='2rem'>
                    <Typography mb='0.5rem' textAlign='center' color='#fff'>
                      Heslo (min. 6 znaků)
                    </Typography>

                    <InlineFormInput
                      name='password'
                      type='password'
                      error={errors.Password && errors.Password[0]}
                      value={user.password}
                      input={password => setUser({ ...user, password })}
                      background='rgba(11, 12, 28, 0.5)'
                    />
                  </Flex>

                  <Heading3 mt='4rem'>
                    Informace a požadavky pro seznámení
                  </Heading3>

                  <Flex flexDirection='column' mt='2rem'>
                    <Typography mb='0.5rem' textAlign='center' color='#fff'>
                      Rok mého narození
                    </Typography>

                    <InlineFormInput
                      name='age'
                      type='number'
                      error={errors.yearOfBirth && errors.yearOfBirth[0]}
                      value={user.yearOfBirth}
                      input={yearOfBirth => setUser({
                        ...user, yearOfBirth: isNaN(Number(yearOfBirth)) ? undefined : Number(yearOfBirth)
                      })}
                      background='rgba(11, 12, 28, 0.5)'
                    />
                  </Flex>

                  <Flex flexDirection='column' mt='2rem'>
                    <Typography mb='0.1rem' textAlign='center' color='#fff'>
                      Jsem
                    </Typography>
                    <Typography mb='0.5rem' textAlign='center' color='#C3A6BF' fontSize='12px'>
                      * V testování BETA zatím pouze ⚥.
                    </Typography>

                    <InlineFormSelect
                      name='name'
                      placeholder='Vyber pohlaví'
                      error={errors.sex}
                      value={user.femalePronouns === undefined ? '-1' : user.femalePronouns === '1' ? '1' : '2'}
                      options={[
                        { label: formatMsg('registration.choose'), value: '-1' },
                        ...['Žena', 'Muž'].map((label, value) => ({
                          label,
                          value: (value + 1).toString(),
                        })),
                      ]}
                      disabled={[-1]}
                      onChange={opt =>
                        setUser({
                          ...user,
                          // eslint-disable-next-line no-extra-parens
                          femalePronouns: (opt as Option).value as string,
                        })
                      }
                      required
                      selectStyle={{
                        background: 'rgba(11, 12, 28, 0.5)'
                      }}
                    />
                  </Flex>

                  <Flex flexDirection='column' mt='2rem'>
                    <Typography mb='0.5rem' textAlign='center' color='#fff'>
                      Chci dostávat nabídky od:
                    </Typography>

                    <InlineFormSelect
                      name='name'
                      error={errors.interestSex && errors.interestSex[0]}
                      value={user.interestSex === undefined ? '-1' : user.interestSex === '1' ? '1' : '2'}
                      options={[
                        { label: formatMsg('registration.choose'), value: -1 },
                        ...['Žen', 'Mužů'].map((label, value) => ({
                          label,
                          value: (value + 1).toString()
                        })),
                      ]}
                      disabled={[-1]}
                      onChange={opt =>
                        setUser({
                          ...user,
                          // eslint-disable-next-line no-extra-parens
                          interestSex: (opt as Option).value as string,
                        })
                      }
                      required
                      selectStyle={{
                        background: 'rgba(11, 12, 28, 0.5)',
                      }}
                    />
                  </Flex>

                  <Flex flexDirection='column' mt='2rem'>
                    <Typography mb='0.5rem' textAlign='center' color='#fff'>
                      ve věku od - do
                    </Typography>

                    <Flex display='flex' justifyContent='space-between' alignItems='center'>
                      <InlineFormInput
                        name='interestAgeFrom'
                        type='number'
                        error={errors.interestAgeFrom && errors.interestAgeFrom[0]}
                        value={user.interestAgeFrom}
                        input={interestAgeFrom => setUser({
                          ...user, interestAgeFrom: isNaN(Number(interestAgeFrom)) ? undefined : Number(interestAgeFrom)
                        })}
                        background='rgba(11, 12, 28, 0.5)'
                      />

                      <Flex px='10px'>
                        -
                      </Flex>

                      <InlineFormInput
                        name='interestAgeTo'
                        type='number'
                        error={errors.interestAgeTo && errors.interestAgeTo[0]}
                        value={user.interestAgeTo}
                        input={interestAgeTo => setUser({
                          ...user, interestAgeTo: isNaN(Number(interestAgeTo)) ? undefined : Number(interestAgeTo)
                        })}
                        background='rgba(11, 12, 28, 0.5)'
                      />
                    </Flex>
                  </Flex>

                  <Flex flexDirection='column' mt='2rem'>
                    <Typography mb='0.5rem' textAlign='center' color='#fff'>
                      Něco o mně: (max. 140 znaků)
                    </Typography>

                    <InlineFormInput
                      multiline
                      name='description'
                      error={errors.description && errors.description[0]}
                      value={user.description}
                      input={description => setUser({ ...user, description })}
                      background='rgba(11, 12, 28, 0.5)'
                      height='14.4rem'
                    />
                  </Flex>

                  <Heading3 mt='4rem'>
                    Vlož svou fotografii 
                  </Heading3>

                  <Typography textAlign='center' mt='1rem'>
                    Tvá fotografie by měla být na šířku, dostatečně zřetelná a tvůj obličej by měl být vidět&nbsp;
                    zepředu (bez slunečních brýlí atp.)   
                  </Typography>

                  <Box maxWidth='37rem' mx='auto'>
                    <Img src='/img/meeticia/picture_registrace.png' mt='3rem' mx='auto' display='block' alt='' />

                    <Flex as='label' mt='3rem' className='custom-file-upload' justifyContent='center' flexDirection='column' alignItems='center'>
                      <Button v='outline' bg='rgba(11, 12, 28, 0.4)' borderColor='rgba(231, 230, 207, 1)' as='span' flex='0 0 auto'>
                        Nahraj své foto (povinné) 
                      </Button>
                      <Typography mb='0.5rem' textAlign='center' color='#C3A6BF' fontSize='12px'>
                        * Bez fota nelze vytvořit profil.
                      </Typography>
                      <Typography mt='1rem' display='flex' alignItems='center'>
                        {user.file ?
                          user.file.name
                          :
                          'Vyber soubor'
                        }
                      </Typography>

                      <input
                        style={{ display: 'none' }}
                        type='file'
                        accept='image/*'
                        onChange={(e) => setUser({ ...user, file: e.target!.files![0]! })}
                      />
                    </Flex>

                    {errors.file && (
                      <Typography color='#e3d240' mt='0.5rem' textAlign='center'>{errors.file}</Typography>
                    )}

                    <Flex as='label' mt='3rem' display='flex' alignItems='center' justifyContent='center'>
                      <Box position='relative'>
                        <CheckboxInputStyle type='checkbox' id='agree' onChange={(e) => setAgree(e.target.checked)} />
                        <CheckedIconStyle className='checkbox-icon-checked'  />
                      </Box>

                      <Box ml='1.6rem'>
                        <Typography
                          color='rgba(255, 255, 255, 0.7)'
                          fontSize='1.6rem'
                          textAlign='left'
                          display='inline-flex'
                          flexDirection='column'
                          alignItems='flex-start'
                        >
                          <FormattedMessage id={'register.meeticia.agree'} />
                          &nbsp;
                          <Link
                            v='underline'
                            color='yellow'
                            borderColor='yellow'
                            target='_blank'
                            href='https://www.betatest.meeticia.cz/informovany-souhlas'
                          >
                            <FormattedMessage id={'register.meeticia.agree.terms'} />
                          </Link>
                        </Typography>
                      </Box>
                    </Flex>
                  </Box>

                  <Flex justifyContent='center'>
                    <Button mt='3rem' display={!agree}>
                      Uložit a pokračovat
                    </Button>
                  </Flex>
                </Box>
              </Box>
            </Fade>
          </form>
        </Flex>
      </Container>
    </Flex>
  );
};

export default withPopups(wrapIntl(MeeticiaForm));
