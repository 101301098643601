import { Button, Flex, Modal, Paragraph, Stack, Tag, Typography } from '@xcorejs/ui';
import { FormInput } from 'components/atoms/Form/FormInput';
import { RequestContext } from 'components/atoms/RequestContext';
import { BlueTheme } from 'components/theme/colors';
import * as React from 'react';
import { useState } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { useQueryClient } from 'react-query';
import { queryKeys } from 'utils/useQuery/queryKeys';
import { putNote } from './action';
import { InputStyle } from './styles';

interface IDataProps {
  valueTestResultId: string;
  defaultText: string;
  onClose: () => unknown;
  onReload?: () => unknown;
  eticonName: string;
  doneAt: number;
}

const CommentModal: React.FC<IDataProps> = (
  {
    valueTestResultId,
    defaultText,
    onClose,
    onReload,
    eticonName,
    doneAt
  }
) => {
  const [text, updateText] = useState(defaultText);
  const queryClient = useQueryClient();

  return (
    <Modal
      background={BlueTheme.primary}
      padding={{ _: '3rem', sm: '6rem 3rem 3rem' }}
    >
      <Flex flexDirection='column' alignItems='center'>
        <Tag
          bg='rgba(11, 12, 28, 0.1)'
          fontSize='2rem'
          lineHeight='2.6rem'
          letterSpacing='0.5px'
          px='5rem'
          py='1rem'
          borderRadius='23.5rem'
        >
          <FormattedMessage id={'journal.commentModal.heading'} />
        </Tag>

        <Flex flexWrap='wrap' mt='2rem'>
          <Typography color='rgba(244, 244, 246, 0.8)'>
            <RequestContext>
              {({ locale }) => (
                <Paragraph color='rgba(244, 244, 246, 0.8)'>
                  {new Date(doneAt * 1000).toLocaleDateString(locale, {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  })}
                </Paragraph>
              )}
            </RequestContext>
          </Typography>

          <Typography ml='2rem' fontWeight={800}>
            {eticonName}
          </Typography>
        </Flex>

        <Stack direction='column' gap='3rem' alignItems='center' mt='3rem'>
          <Typography>
            <FormattedHTMLMessage id={'journal.commentModal.note'} />
          </Typography>

          <InputStyle>
            <FormInput
              type='text'
              required
              value={text}
              onChange={(e: any) => updateText(e.target.value)}
              withoutLabel
            />
          </InputStyle>

          <Button
            onClick={() => {
              putNote(valueTestResultId, text)
                .finally(() => queryClient.invalidateQueries([queryKeys.JOURNAL]));
              onReload && onReload();
              onClose();
            }}
            minWidth='10.5rem'>
            <FormattedMessage id={'journal.commentModal.save'} />
          </Button>
        </Stack>
      </Flex>
    </Modal>
  );
};

export default CommentModal;
