import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import withLifeValues, { IWithLifeValues } from '../../../molecules/withRedux/withLifeValues';
import LoadingSpinnerLocal from '../../../atoms/Animations/LoadingSpinnerLocal';
import { denormalize } from 'utils/helpers';
import { Flex, Link } from '@xcorejs/ui';

interface ISliderValuesProps {
  setDetail: (detail: string | null) => void;
  disabled?: boolean;
}

class SecondaryValues extends React.Component<
  ISliderValuesProps & RouteComponentProps<{}> & IWithLifeValues
  > {

  public render() {
    const { lifeValues, disabled, setDetail } = this.props;
    const values = lifeValues && denormalize(lifeValues)
      .filter(a => a.type === 'secondary' && !a.frontHidden)
      .sort((a, b) => a.name.localeCompare(b.name));

    return (
      <Flex flexWrap='wrap' textAlign='center' justifyContent='center' maxWidth={{ _:'100%', md: '50rem' }}>
        {lifeValues !== null ?
          values
            .map((v, i) => (
              <Link
                v='underline'
                display='inline-flex'
                key={i}
                mr='1.2rem'
                mt='0.6rem'
                lineHeight='1.6rem'
                color='rgba(255, 255, 255, 0.7)'
                borderColor='rgba(255, 255, 255, 0.7)'
                onClick={() => disabled ? null : setDetail(v.id)}
              >
                {v.name}
              </Link>
            ))
          : (
            <LoadingSpinnerLocal />
          )}
      </Flex>
    );
  }
}

export default withRouter(withLifeValues(SecondaryValues));
