import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { StyledEmptyGeoInfo, StyledStatisticsSlider } from './styles';
import { useState } from 'react';
import { createUrl } from '../../pages/routes';
import { StyledSmallButtonLink } from '../../atoms/Buttons/styles';
import SVGsliderArrow from '../../atoms/SVGIcons/SVGsliderArrow';
import useFetch from '../../atoms/Fetch/useFetch';
import { STATISTICS_GROUP_MATCH_TEST, STATISTICS_GROUP_MATCH_PROFILE } from 'config/apiRoutes';
import LoadingSpinnerLocal from '../../atoms/Animations/LoadingSpinnerLocal';
import { defaultItems, IStatistic, mapResult, ISliderItem } from './data';
import StatisticsSliderItem from './StatisticsSliderItem';
import withUser from '../withRedux/withUser';
import { IWithUser } from '../withRedux/withUser';
import { IUserState } from 'store/User';
import Zoom from 'react-reveal/Zoom';

interface IProps {
  big?: boolean;
  withArrows?: boolean;
  testID?: string;
  giveDataAfter?: (StatisticsLevel: number, statistic?: ISliderItem) => any;
  skipStatisticLevel?: boolean;
}

const StatisticsSlider: React.FC<IProps & IWithUser> = ({
  big,
  withArrows,
  testID,
  rescource,
  giveDataAfter,
  user,
  skipStatisticLevel,
}) => {
  const [state, setState] = useState<{ index: number }>({
    index: Math.floor(defaultItems.length / 2),
  });
  const result = useFetch<IStatistic[]>(
    testID ? STATISTICS_GROUP_MATCH_TEST(testID) : STATISTICS_GROUP_MATCH_PROFILE,
  );

  const geoInfo = (rescource.user as IUserState).fullProfile;

  const StatisticsLevel = skipStatisticLevel
    ? 10000
    : !geoInfo
      ? 0
      : testID
        ? Math.max(2, user.level + 1)
        : user.level + 1;

  if (result.state === 'fetching' && geoInfo) {
    return <LoadingSpinnerLocal />;
  }

  const index = state.index;
  const lowerIndex = (index - 1 + defaultItems.length) % defaultItems.length;
  const superLowerIndex = (index - 2 + defaultItems.length) % defaultItems.length;
  const higherIndex = (index + 1) % defaultItems.length;
  const superHigherIndex = (index + 2) % defaultItems.length;

  const realValues = result.state === 'success' ? result.result : undefined;

  const items = realValues ? mapResult(realValues, StatisticsLevel) : defaultItems;
  return (
    <>
      <StyledStatisticsSlider big={big} geoInfo={StatisticsLevel < 2}>
        {withArrows && (
          <span
            className={'before'}
            onClick={() =>
              setState({
                index: lowerIndex,
              })
            }
          >
            <SVGsliderArrow />
          </span>
        )}

        <Zoom delay={200}>
          <div className='slide'>
            {big && (
              <span
                className='pointer left inBigSlider'
                onClick={() =>
                  setState({
                    index: lowerIndex,
                  })
                }
              >
                <StatisticsSliderItem item={items[superLowerIndex]} statisticLevel={StatisticsLevel} />
              </span>
            )}

            <span
              className='pointer left'
              onClick={() =>
                setState({
                  index: lowerIndex,
                })
              }
            >
              <StatisticsSliderItem item={items[lowerIndex]} statisticLevel={StatisticsLevel} />
            </span>

            <StatisticsSliderItem item={items[index]} big={index} statisticLevel={StatisticsLevel} />

            <span
              className='pointer'
              onClick={() =>
                setState({
                  index: higherIndex,
                })
              }
            >
              <StatisticsSliderItem item={items[higherIndex]} statisticLevel={StatisticsLevel} />
            </span>

            {big && (
              <span
                className='pointer inBigSlider'
                onClick={() =>
                  setState({
                    index: higherIndex,
                  })
                }
              >
                <StatisticsSliderItem item={items[superHigherIndex]} statisticLevel={StatisticsLevel} />
              </span>
            )}
          </div>
        </Zoom>

        {withArrows && (
          <span
            className={'after'}
            onClick={() =>
              setState({
                index: higherIndex,
              })
            }
          >
            <SVGsliderArrow />
          </span>
        )}

        {StatisticsLevel < 2 && (
          <StyledEmptyGeoInfo>
            <StyledSmallButtonLink
              to={{
                pathname:
                  StatisticsLevel === 0 ? createUrl('app.profile') + '/profile-info' : createUrl('app.gameIntro'),
                state: { redirect: window.location.pathname },
              }}
              borderColor={'yellow'}
            >
              <FormattedMessage id={StatisticsLevel === 0 ? 'statistics.slider.geo' : 'statistics.slider.HH0'} />
            </StyledSmallButtonLink>
          </StyledEmptyGeoInfo>
        )}
      </StyledStatisticsSlider>
      {giveDataAfter !== undefined && giveDataAfter(StatisticsLevel, items[index] || undefined)}
    </>
  );
};

export default withUser(StatisticsSlider);
