import * as React from 'react';
import { Heading2 } from '../../../atoms/Headings';
import { BiggerText } from '../../../atoms/Text';
import { Row, Col } from 'react-styled-flexboxgrid';
import TextBox from '../../../organisms/TextBox';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import Fade from 'react-reveal/Fade';
import { TopicsSection, Circle, CircleDescription, TopicsSlider } from './styles';

const Topics: React.FC = () => (
  <TopicsSection className={'bg-gray'}>
    <TextBox className='black'>
      <Fade ssrReveal bottom duration={1000} distance={'5rem'}>
        <Heading2>
          <FormattedMessage id={'vlc.topics.heading'} />
        </Heading2>

        <BiggerText>
          <FormattedMessage id={'vlc.topics.text'} />
        </BiggerText>

        <TopicsSlider>
          {/*<SVGarrow />*/}

          <Row>
            <Col xs={12} sm={4} md={2}>
              <Circle color={'purple'}>
                <img src='/img/partnerske-porovnani/okruhy/pohled.svg' alt='' />
              </Circle>

              <CircleDescription>
                <strong>
                  <FormattedMessage id={'vlc.topics.circle.1.heading'} />
                </strong>
                <p>
                  <FormattedHTMLMessage id={'vlc.topics.circle.1.text'} />
                </p>
              </CircleDescription>
            </Col>

            <Col xs={12} sm={4} md={2}>
              <Circle color={'orange'}>
                <img src='/img/partnerske-porovnani/okruhy/zabava.svg' alt='' />
              </Circle>

              <CircleDescription>
                <strong>
                  <FormattedMessage id={'vlc.topics.circle.2.heading'} />
                </strong>
                <p>
                  <FormattedHTMLMessage id={'vlc.topics.circle.2.text'} />
                </p>
              </CircleDescription>
            </Col>

            <Col xs={12} sm={4} md={2}>
              <Circle color={'green'}>
                <img src='/img/partnerske-porovnani/okruhy/24-hodin.svg' alt='' />
              </Circle>
              <CircleDescription>
                <strong>
                  <FormattedMessage id={'vlc.topics.circle.3.heading'} />
                </strong>
                <p>
                  <FormattedHTMLMessage id={'vlc.topics.circle.3.text'} />
                </p>
              </CircleDescription>
            </Col>
            <Col xs={12} sm={4} md={2}>
              <Circle color={'blue'}>
                <img src='/img/partnerske-porovnani/okruhy/spoluprace.svg' alt='' />
              </Circle>
              <CircleDescription>
                <strong>
                  <FormattedMessage id={'vlc.topics.circle.4.heading'} />
                </strong>
                <p>
                  <FormattedHTMLMessage id={'vlc.topics.circle.4.text'} />
                </p>
              </CircleDescription>
            </Col>
            <Col xs={12} sm={4} md={2}>
              <Circle color={'red'}>
                <img src='/img/partnerske-porovnani/okruhy/sex-drogy.svg' alt='' />
              </Circle>

              <CircleDescription>
                <strong>
                  <FormattedMessage id={'vlc.topics.circle.5.heading'} />
                </strong>
                <p>
                  <FormattedHTMLMessage id={'vlc.topics.circle.5.text'} />
                </p>
              </CircleDescription>
            </Col>
            <Col xs={12} sm={4} md={2}>
              <Circle color={'black'}>
                <img src='/img/partnerske-porovnani/okruhy/domacnost.svg' alt='' />
              </Circle>
              <CircleDescription>
                <strong>
                  <FormattedMessage id={'vlc.topics.circle.6.heading'} />
                </strong>
                <p>
                  <FormattedHTMLMessage id={'vlc.topics.circle.6.text'} />
                </p>
              </CircleDescription>
            </Col>
          </Row>

          {/*<SVGarrow />*/}
        </TopicsSlider>
      </Fade>
    </TextBox>
  </TopicsSection>
);

export default Topics;
