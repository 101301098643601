import { jsonFetch } from '../../../utils/fetch';
import { LIFE_VALUES_DEF, LIFE_VALUES_VOTE } from 'config/apiRoutes';

export const sendDefinition = (id: string, content: string) =>
  jsonFetch(LIFE_VALUES_DEF(id), {
    method: 'POST',
    body: JSON.stringify({ content }),
  });

export const voteDefinition = (id: string, defId: string, type: string, agree: boolean) =>
  jsonFetch(LIFE_VALUES_VOTE(id), {
    method: 'POST',
    body: JSON.stringify({ id: defId, type, agree }),
  });
