import React, { FC, useState } from 'react';
import { Flex, Heading2, Typography, Box, Paragraph, Icon } from '@xcorejs/ui';
import StartPlay from 'components/atoms/StartPlay/StartPlay';
import GameInfoCarousel from 'components/components/Carousel/GameInfoCarousel';
import { BlackTheme } from 'components/theme/colors';
import { useHistory } from 'react-router-dom';
import { createUrl } from 'components/pages/routes';
import PhoneIcon from '../../components/icons/24/smartphone.svg';
import GameAnimationIntro from 'components/components/Animations/GameAnimationIntro';
import { useIsInViewport } from 'components/atoms/hooks/useIsInViewport';
import { OliLight } from 'components/theme/fonts';
import { SectionWrapper, BackgroundWrapper, StyledBackground, animation, GameFlex, CarouselBox } from './styles';
import { FormattedMessage } from 'react-intl';

interface ValueGamePresentingProps {
  withMenu?: boolean;
  smaller?: boolean;
  onlyOnce?: boolean;
  insideApp?: boolean;
  afterRegistration?: boolean;
}

const ValueGamePresenting: FC<ValueGamePresentingProps> = ({
  withMenu,
  smaller,
  onlyOnce,
  insideApp,
  afterRegistration 
}) => {
  const hasPlayedSession = onlyOnce ? sessionStorage.getItem('hasPlayedSession') : false;
  const hasClickedStart = onlyOnce ? sessionStorage.getItem('hasClickedStart') : false;

  const history = useHistory();
  const [intro, setIntro] = useState<boolean>(onlyOnce && hasPlayedSession && hasClickedStart ? false : true);
  const [animationStatus, setAnimationStatus] = useState<number>(onlyOnce && hasPlayedSession ? 1 : 0);

  const flexRef = React.useRef<HTMLDivElement>(null);
  const infoInViewport = useIsInViewport(flexRef);
  const [firstTimeInView, setFirstTimeInView] = React.useState<boolean>(false);

  const disableIntro = () => {
    setIntro(false);
    if (onlyOnce || afterRegistration) {
      window.sessionStorage.setItem('hasClickedStart', 'true');
      window.dispatchEvent(new Event('storage'));
    }
  };

  const handleClick = () => {
    const iframe = window.top !== window.self;
    const iframeCondition = location.pathname.includes(createUrl('valueGame'));

    disableIntro();
    if (insideApp || iframe) {
      if (iframeCondition) {
        window.top.postMessage({ type :'closeRedirect_' + new URL(window.location.origin + createUrl('app.gameGame')) }, '*');
      }
    } else {
      history.push({
        pathname: createUrl('register'),
        state: { redirect: createUrl('app.gameGame') },
      });
    }
  };

  React.useEffect(() => {
    if (infoInViewport === true && firstTimeInView === false) {
      setFirstTimeInView(true);
    }
  }
  , [infoInViewport, firstTimeInView]);
  
  return ( 
    <Flex
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      height={withMenu ? { _: 'calc(100vh - 5rem)', md:'calc(100vh - 6rem)' } : '100vh'}
      position='relative'
      zIndex={2}
      padding={withMenu ? { _:'3rem' } : { _: '3rem', md: '10rem' }}
    >
      <Heading2 color={BlackTheme.text} fontFamily={OliLight}>
        <FormattedMessage id='game.page.title' />
      </Heading2>

      <Typography mt='2rem' fontFamily={OliLight}>
        <FormattedMessage id='game.page.description' />
      </Typography>

      <Flex justifyContent='center' alignItems='center' flexGrow={1} mt='2rem'>
        <GameFlex 
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          border='2px solid #006d6a'
          width={smaller ? '37.5rem' : '37.9rem'}
          maxHeight={smaller ? '60rem' : '61rem'}
          borderRadius='2px'
          position='relative'
          ref={flexRef}
        >
          <GlowingBorder />
          
          {intro ? (
            <SectionWrapper>
              {firstTimeInView === true && animationStatus === 0 ? 
                <GameAnimationIntro setStatus={setAnimationStatus} onlyOnce={onlyOnce} /> 
                : null}
              
              <Box
                backgroundImage='url(/img/hodnotova-hra-presenting/Eticiagamegif_6.gif)'
                backgroundSize='cover'
                backgroundPosition='bottom'
                backgroundRepeat='no-repeat'
                position='absolute'
                top='0'
                left='0'
                width='100%'
                height='100%'
              />

              {animationStatus === 1 ? (
                <>
                  <Heading2
                    maxWidth='22rem'
                    color={BlackTheme.text}
                    position='relative'
                    zIndex={2}
                    opacity={0}
                    animation={animation(0)}
                  >
                    <FormattedMessage id='game.page.intro.title' />
                  </Heading2>

                  <StartPlay
                    onClick={handleClick}
                    marginTop='4rem'
                    opacity={0}
                    animation={animation(500)}
                  />

                  <Paragraph 
                    mt='4rem'
                    maxWidth='22rem'
                    fontSize='1.6rem'
                    color={BlackTheme.text}
                    position='relative'
                    zIndex={2}
                    opacity={0}
                    animation={animation(1000)}
                  >
                    <FormattedMessage id='game.page.intro.description' />
                    <Icon svg={<PhoneIcon />} ml='0.5rem' />
                  </Paragraph>
                </>
              ) : null}

              <Box
                position='absolute'
                top='0'
                left='0'
                width='100%'
                height='100%'
                background='linear-gradient(180deg, rgba(0,0,0,0.7), rgba(0,0,0,0.2))'
              />
            </SectionWrapper>
          ) : null}

        </GameFlex>

        <CarouselBox
          ml='14rem'
          maxWidth='355px'
          backgroundColor='#1d1c21'
        > 
          <GameInfoCarousel />
        </CarouselBox>
      </Flex>
    </Flex>
  );
};
 
export default ValueGamePresenting;

export const GlowingBorder: FC = () => {
  return (
    <BackgroundWrapper>
      <StyledBackground color='rgb(140,135,237)' />
      <StyledBackground color='rgb(96,141,171)' delayed />
    </BackgroundWrapper>
  );
};
