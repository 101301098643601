import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Heading1, Heading2 } from '../../../../../atoms/Headings';
import {
  BackgroundedBox,
  StyledAddVoucher,
  TableTag,
  StyledSuccessVoucher,
  FlexColumn,
  StyledValueComparisonSection,
} from '../../Comparisons/styles';
import Form from '../../../../../molecules/Form';
import { MoreLink } from '../../LandingPage/style';
import SVGarrowRight from '../../../../../atoms/SVGIcons/SVGarrow-right';
import { StyledFakeLinkBorder, StyledLinkBorder } from '../../../../../atoms/Buttons/styles';
import Fade from 'react-reveal/Fade';
import { createUrl } from '../../../../routes';
import { _switch } from '../../../../../../utils/option';
import LoadingSpinner from '../../../../../atoms/Animations/LoadingSpinner';
import { PrimaryButton } from '../../../../../atoms/Buttons/index';
import { claimVoucher } from '../data';
import { Row, Col, Grid } from 'react-styled-flexboxgrid';
import SVGvoucher from '../../../../../atoms/SVGIcons/SVGvoucher';
import { SmallerText } from '../../../../../organisms/VisualValuesComparison/style';
import { FormInput } from '../../../../../atoms/Form/FormInput';

interface IAddVoucher {
  code: string | null;
  back?: () => any;
}

interface IAddVoucherState {
  state: number;
  input: string;
}

class AddVoucher extends React.Component<IAddVoucher, IAddVoucherState> {
  public state: IAddVoucherState = { state: 0, input: '' };

  public render() {
    const { code, back } = this.props;
    return (
      <StyledValueComparisonSection>
        <FlexColumn>
          <Fade bottom ssrReveal duration={1000} distance={'5rem'}>
            <StyledAddVoucher>
              <Grid>
                <Row>
                  <Col xs={12} md={6} mdOffset={3}>
                    {_switch([
                      () => (
                        <>
                          <Heading1>
                            <FormattedMessage id={'voucher.enterCode'} />
                          </Heading1>

                          <BackgroundedBox>
                            <Form>
                              {code ? (
                                <FormInput disabled value={code} />
                              ) : (
                                <>
                                  <Heading2>
                                    <FormattedMessage id={'voucher.enterCodeInput'} />
                                  </Heading2>
                                  <FormInput
                                    value={this.state.input}
                                    onChange={e => this.setState({ input: e.target.value })}
                                    required
                                  />
                                </>
                              )}

                              {code ? (
                                <>
                                  <StyledFakeLinkBorder onClick={() => back && back()}>
                                    <FormattedMessage id={'config.back'} />
                                  </StyledFakeLinkBorder>
                                </>
                              ) : (
                                <>
                                  <TableTag color={'black'} background={'yellow'} onClick={this.submit}>
                                    <FormattedMessage id={'voucher.code.btn'} />
                                  </TableTag>

                                  <MoreLink to={createUrl('app.comparison')} target='_blank' rel='noopener noreferrer'>
                                    <span>
                                      <FormattedMessage id={'gamesForTwo.more'} />
                                    </span>
                                    <SVGarrowRight />
                                  </MoreLink>
                                </>
                              )}
                            </Form>
                          </BackgroundedBox>

                          <div className={'back'}>
                            <StyledLinkBorder to={createUrl('app.comparison') + '/seznam-voucher'} color='yellow'>
                              <FormattedMessage id={'config.back'} />
                            </StyledLinkBorder>
                          </div>
                        </>
                      ),
                      () => <LoadingSpinner />,
                      () => (
                        <>
                          <StyledSuccessVoucher>
                            <Heading1>
                              <FormattedMessage id={'voucher.enterCode.success'} />
                            </Heading1>

                            <SVGvoucher />

                            <SmallerText>
                              <FormattedMessage id={'voucher.enterCode.success.text'} />
                            </SmallerText>

                            <a href={createUrl('app.comparison') + '/moje-porovnani'}>
                              <PrimaryButton>
                                <FormattedMessage id={'voucher.enterCode.successBtn'} />
                              </PrimaryButton>
                            </a>
                          </StyledSuccessVoucher>
                          <Heading1 />
                        </>
                      ),
                    ])()(this.state.state)()}
                  </Col>
                </Row>
              </Grid>
            </StyledAddVoucher>
          </Fade>
        </FlexColumn>
      </StyledValueComparisonSection>
    );
  }

  private submit = () => {
    this.setState({ state: 1 });
    claimVoucher(this.state.input)
      .then(() => this.setState({ state: 2 }))
      .catch(() => {
        this.setState({ state: 0 });
        alert('Kód není platný nebo už byl použit');
      });
  };
}

export default AddVoucher;
