import { IEntitiesState, entityReducer } from './Entities';
import { IUserState } from './User';
import { userReducer } from './User/UserReducer';
import { IPopupState, popupReducer } from './Popup';
import { combineReducers } from 'redux';
import { IFlashMessagesState, flashMessagesReducer } from './FlashMessages/index';

export interface IApplicationState {
  user: IUserState;
  entities: IEntitiesState;
  flashMessages: IFlashMessagesState;
  popup: IPopupState;
}

export const rootReducer = combineReducers({
  user: userReducer,
  entities: entityReducer,
  flashMessages: flashMessagesReducer,
  popup: popupReducer,
});

export type IAppThunkAction<TAction> = (dispatch: (action: TAction) => void, getState: () => IApplicationState) => void;
