import React, { FC, ReactNodeArray } from 'react';
import { Modal, Heading2, Flex, Paragraph, Tag, Heading3, Link as XLink } from '@xcorejs/ui';
import { BlueTheme, PlusTheme } from 'components/theme/colors';
import FlipCardsBlock from './FlipCardsBlock';
import { StyledMain } from './styles';
import Countdown from './Countdown';
import { useLocation, useHistory } from 'react-router-dom';
import { createUrl } from 'components/pages/routes';
import { FormattedMessage } from 'react-intl';
import { PrimaryButton } from 'components/components/Button';

export type EticiaInfoModalProps = {
  onClose?: () => void;
};

const EticiaInfoModal: FC<EticiaInfoModalProps> = ({ onClose }) => {
  const history = useHistory();
  const closeModalHelper = (innerFunction?: () => void) => {
    onClose && onClose();
    innerFunction && innerFunction();
  };

  const handleBuy = () => {
    history.push(createUrl('app.shop'));
    onClose && onClose();
  };

  return ( 
    <Modal
      background={BlueTheme.darken}
      padding={{ _: '6rem 1rem 6rem', sm: '4rem 1.6rem' }}
      maxWidth={{ _: '90rem' }}
    >
      <StyledMain>
        <Heading2>
          <FormattedMessage id='infoModal.title' />
        </Heading2>

        <Paragraph marginTop='1rem'>
          <FormattedMessage id='infoModal.description' />
        </Paragraph>

        <FlipCardsBlock onClose={closeModalHelper} />

        <Flex
          flexDirection='column'
          alignItems='center'
          marginTop='2rem'
        >
          <Heading3 color={BlueTheme.text} lineHeight='3.2rem'>
            <FormattedMessage
              id='infoModal.discount' 
              values={{ tag: (...chunks: ReactNodeArray) => (
                <Tag
                  borderRadius='0.5rem'
                  background={PlusTheme.primary}
                  color={PlusTheme.text}
                  marginLeft='0.5rem'
                  fontSize='1.6rem'
                  transform='translateY(-0.2rem)'
                >{chunks}</Tag>
              ) }}
            />
            <span className='slashed'>149,- Kč</span> / 121,- Kč
          </Heading3>

          <Heading2 as='p' marginTop={{ _: '2rem' }}>
            <FormattedMessage
              id='infoModal.code'
              values={{ strong: (...chunks: ReactNodeArray) => (
                <strong>{chunks}</strong>
              ) }}
            />
          </Heading2>

          <PrimaryButton
            onClick={handleBuy}
            background='#0005'
            color='#ddd'
            _hover={{ backgroundColor: '#222A !important' }}
            height='unset'
            paddingY='1rem'
            paddingX='3rem'
            marginTop='1rem'
          >
            <FormattedMessage id='infoModal.buy' />
          </PrimaryButton>

          <Paragraph marginTop='2rem' color={BlueTheme.text}>
            <FormattedMessage id='infoModal.timeLeft' />
          </Paragraph>

          <Countdown props={{ marginTop: { _: '1rem' } }} />
        </Flex>
      </StyledMain>
    </Modal>
  );
};
 
export default EticiaInfoModal;