import { GradientProps } from 'components/pages/Presenting/data';
import { IColorTheme } from 'components/theme/colors';

export type IModal = {
  /** ID ankety */ surveyID?: string;
  /** cesta ke gifu */ asset?: string;
  /**  */ gifLength: number;
  /** nadpis */ text?: string;
  /** podnadpis */ text2?: string;
  /** zobrazit oddělovač */ divider?: boolean;
  /** hlavní text */ text3?: string;
  /** cesta k obrázku pod hlavním textem */ image2?: string;
  /** cesta k obrázku pro bonusovou statistiku */ image2Statistics?: string;
  /** text pro bonusovou statistiku */ text2Statistics?: string;
  /** text nad tlačítkem pro intro nebo preIntro, v summary nebo exit se použije jako alt hodnota k obrázku ankety text4Image 
   */ text4?: string;
  /** cesta k obrázku pro anketu na hlavním obrázku pro exit nebo summary */ text4Image?: string;
  /** opakování akce vložené do onAction při kliknutí na obrázek ankety */ repeatAction?: boolean;
  /**  */ linkText?: string;
  /**  */ buttonText?: string;
  /**  */ buttonBg?: string;
  /**  */ buttonGradient?: GradientProps;
  /**  */ buttonColor?: string;
  /** nadpis v horním pruhu hry, který přepíše původní nadpis */ head?: string;
  /** cesta k obrázku který se přesouvá do inventáře */ inventoryImage?: string;
  /** znova spustí anketu během summary/exit místo zobrazení výsledků */ repeatSurvey?: boolean;
  /** text který se zobrazí před odkazem na test */ testText?: string;
  /**  */ testButtonText?: string;

}

export type IPopup = {
  bgImage?: string;
  bgGradientHidden?: boolean;
  image0?: string;
  text?: string;
  text2?: string;
  image?: string;
  divider?: boolean;
  text3?: string;
  image2?: string;
  text4?: string;
  buttonText?: string;
  buttonBg?: string;
  buttonGradient?: GradientProps;
  buttonColor?: string;
}

export type ILevel = {
  name: string;
  image?: string;
  buttonText?: string;
  theme: Partial<IColorTheme>;
  gradient?: GradientProps;
  textColor?: string;
  map: {
    mapBottom: number;
    playerBottom: number;
    playerLeft: number;
    zoomX: number;
    zoomY: number;
  };
  intro: IModal[];
  exit: IModal[];
  summary: IModal[];
  popup?: IPopup;
  preIntro?: IModal[];
}

export const gameLevels: ILevel[] = [
  {
    name: 'game.level0.name',
    image: '0.png',
    buttonText: 'game.level0.buttonText',
    theme: {
      primary: '#4C6EAE',
      darken: '#3E5A8E'
    },
    gradient: {
      color1: 'rgba(76, 110, 174, 0.0)',
      color2: 'rgba(76, 110, 174, 0.0)',
      color3: 'rgba(62, 90, 142, 0.6)',
      color4: 'rgba(62, 90, 142, 0.6)',
      speed: '15s'
    },
    map: {
      mapBottom: 0,
      playerBottom: 9,
      playerLeft: 31,
      zoomX: -104,
      zoomY: 0
    },
    intro: [
      {
        asset: 'gif/most-start.gif',
        gifLength: 3200,
        text: 'game.level0.intro0.text',
        text2: 'game.level0.intro0.text2',
        divider: true,
        text3: 'game.level0.intro0.text3',
        image2: 'images/chyse_s.png',
        buttonText: 'game.level0.intro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.3)',
          color2: 'rgba(54, 113, 125, 0.3)',
          color3: 'rgba(62, 131, 143, 0.8)',
          color4: 'rgba(54, 113, 125, 0.6)',
          speed: '10s'
        },
        buttonColor: '#f3f1e7',
        head: 'game.level0.intro0.head'
      },
      {
        asset: 'gif/0_most_uvod.gif',
        gifLength: 3500,
        text: 'game.level0.intro1.text',
        text2: 'game.level0.intro1.text2',
        divider: true,
        text3: 'game.level0.intro1.text3',
        text4: 'game.level0.intro1.text4',
        buttonText: 'game.level0.intro1.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.3)',
          color2: 'rgba(54, 113, 125, 0.3)',
          color3: 'rgba(62, 131, 143, 0.8)',
          color4: 'rgba(54, 113, 125, 0.6)',
          speed: '10s'
        },
        buttonColor: '#f3f1e7',
        head: 'game.level0.intro1.head'
      }
    ],
    summary: [
      {
        asset: 'images/0_Most_summa.png',
        gifLength: 800,
        text: 'game.level0.summary0.text',
        text2: 'game.level0.summary0.text2',
        divider: true,
        image2: 'images/lvl0_zebr_exit.png',
        buttonText: 'game.level0.summary0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(76, 110, 174, 0.3)',
          color2: 'rgba(62, 90, 142, 0.3)',
          color3: 'rgba(76, 110, 174, 0.8)',
          color4: 'rgba(62, 90, 142, 0.6)',
          speed: '25s'
        },
        buttonColor: '#f3f1e7',
        head: 'game.level0.summary0.head'
      },
      {
        asset: 'images/0_Most_summa.png',
        gifLength: 800,
        text: 'game.level0.summary1.text',
        text2: 'game.level0.summary1.text2',
        divider: true,
        image2: 'images/lvl0_zebr_exit.png',
        buttonText: 'game.level0.summary1.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.3)',
          color2: 'rgba(54, 113, 125, 0.3)',
          color3: 'rgba(62, 131, 143, 0.8)',
          color4: 'rgba(54, 113, 125, 0.6)',
          speed: '25s'
        },
        buttonColor: '#f3f1e7',
        head: 'game.level0.summary1.head'
      }
    ],
    exit: [
      {
        asset: 'gif/most-start.gif',
        gifLength: 3500,
        text: 'game.level0.exit0.text',
        text2: 'game.level0.exit0.text2',
        divider: true,
        image2: 'images/lvl0_zebr_exit.png',
        text4: 'game.level0.exit0.text4',
        buttonText: 'game.level0.exit0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.3)',
          color2: 'rgba(54, 113, 125, 0.3)',
          color3: 'rgba(62, 131, 143, 0.8)',
          color4: 'rgba(54, 113, 125, 0.6)',
          speed: '25s'
        },
        buttonColor: '#f3f1e7',
        head: 'game.level0.exit0.head'
      },
      {
        asset: 'gif/most-start.gif',
        gifLength: 3500,
        text: 'game.level0.exit1.text',
        text2: 'game.level0.exit1.text2',
        divider: true,
        image2: 'images/lvl0_zebr_exit.png',
        text4: 'game.level0.exit1.text4',
        buttonText: 'game.level0.exit1.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.3)',
          color2: 'rgba(54, 113, 125, 0.3)',
          color3: 'rgba(62, 131, 143, 0.5)',
          color4: 'rgba(54, 113, 125, 0.4)',
          speed: '25s'
        },
        buttonColor: '#f3f1e7',
        head: 'game.level0.exit1.head'
      }
    ]
  },
  {
    name: 'game.level1.name',
    image: '1_St.png',
    buttonText: 'game.level1.buttonText',
    theme: {
      primary: '#734A4A',
      darken: '#40201F'
    },
    gradient: {
      color1: 'rgba(115, 74, 74, 0.0)',
      color2: 'rgba(115, 74, 74, 0.0)',
      color3: 'rgba(64, 32, 31, 0.5)',
      color4: 'rgba(64, 32, 31, 0.7)',
      speed: '15s'
    },
    map: {
      mapBottom: 0,
      playerBottom: 17,
      playerLeft: 69,
      zoomX: -375,
      zoomY: 150
    },
    intro: [
      {
        surveyID: '199045fe-9671-4b78-a0cc-5d59851e7594',
        asset: 'gif/1_INTRO_Brana.gif',
        gifLength: 4000,
        text: 'game.level1.intro0.text',
        text2: 'game.level1.intro0.text2',
        divider: true,
        text3: 'game.level1.intro0.text3',
        buttonText: 'game.level1.intro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.4)',
          color3: 'rgba(62, 131, 143, 0.5)',
          color4: 'rgba(54, 113, 125, 0.4)',
          speed: '10s'
        },
        buttonColor: '#f3f1e7',
        head: 'game.level1.intro0.head'
      }
    ],
    summary: [
      {
        asset: 'images/1_Brana_summary.png',
        gifLength: 900,
        text: 'game.level1.summary0.text',
        text2: 'game.level1.summary0.text2',
        divider: false,
        image2: 'images/hodnot_lv1.png',
        buttonText: 'game.level1.summary0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.4)',
          color3: 'rgba(62, 131, 143, 0.5)',
          color4: 'rgba(54, 113, 125, 0.4)',
          speed: '10s'
        },
        buttonColor: '#f3f1e7',
        head: 'game.level1.summary0.head'
      }
    ],
    exit: [
      {
        asset: 'gif/branaB.gif',
        gifLength: 5000,
        text: 'game.level1.exit0.text',
        text2: 'game.level1.exit0.text2',
        divider: true,
        text3: 'game.level1.exit0.text3',
        image2: 'images/hodnot_lv1.png',
        buttonText: 'game.level1.exit0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.4)',
          color3: 'rgba(62, 131, 143, 0.5)',
          color4: 'rgba(54, 113, 125, 0.4)',
          speed: '10s'
        },
        buttonColor: '#f3f1e7',
        head: 'game.level1.exit0.head'
      }
    ],
    popup: {
      bgImage: 'udoli_340x540px.png',
      image0: '',
      text: 'game.level1.popup.text',
      image: 'images/les_prekvapeni_90x90.png',
      divider: false,
      text3: 'game.level1.popup.text3',
      image2: '',
      buttonText: 'game.level1.popup.buttonText'
    }
  },
  {
    name: 'game.level2.name',
    image: 'images/strom_exit_.png',
    buttonText: 'game.level2.buttonText',
    theme: {
      primary: '#3e838f',
      darken: '#2E5F6C'
    },
    gradient: {
      color1: 'rgba(62, 131, 143, 0.0)',
      color2: 'rgba(54, 113, 125, 0.0)',
      color3: 'rgba(62, 131, 143, 0.9)',
      color4: 'rgba(54, 113, 125, 0.9)',
      speed: '15s'
    },
    map: {
      mapBottom: -10,
      playerBottom: 23,
      playerLeft: 44,
      zoomX: -199,
      zoomY: 100
    },
    intro: [
      {
        surveyID: 'a6347078-2203-44cf-a17d-e1176d8f4495',
        asset: 'gif/strom-nalady.gif',
        gifLength: 5200,
        text: 'game.level2.intro0.text',
        text2: 'game.level2.intro0.text2',
        divider: true,
        text3: 'game.level2.intro0.text3',
        text4: 'game.level2.intro0.text4',
        buttonText: 'game.level2.intro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.4)',
          color3: 'rgba(62, 131, 143, 0.5)',
          color4: 'rgba(54, 113, 125, 0.4)',
          speed: '10s'
        },
        buttonColor: '#f3f1e7',
        head: 'game.level2.intro0.head'
      }
    ],
    summary: [
      {
        asset: 'gif/SUMMARY_LV4_STROM_SHORT.gif',
        gifLength: 2600,
        text: 'game.level2.summary0.text',
        text2: 'game.level2.summary0.text2',
        divider: true,
        text3: 'game.level2.summary0.text3',
        text4: 'game.level2.summary0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level2.summary0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.4)',
          color3: 'rgba(62, 131, 143, 0.5)',
          color4: 'rgba(54, 113, 125, 0.4)',
          speed: '10s'
        },
        buttonColor: '#f3f1e7',
        head: 'game.level2.summary0.head',
        inventoryImage: 'images/podzimni_list.png'
      }
    ],
    exit: [
      {
        asset: 'gif/2_EXIT_Strom-opad.gif',
        gifLength: 1000,
        text: 'game.level2.exit0.text',
        text2: 'game.level2.exit0.text2',
        divider: false,
        text3: 'game.level2.exit0.text3',
        text4: 'game.level2.exit0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        linkText: 'game.level2.exit0.linkText',
        buttonText: 'game.level2.exit0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.4)',
          color3: 'rgba(62, 131, 143, 0.5)',
          color4: 'rgba(54, 113, 125, 0.4)',
          speed: '10s'
        },
        buttonColor: '#f3f1e7',
        head: 'game.level2.exit0.head',
        inventoryImage: 'images/podzimni_list.png'
      }
    ],
    popup: {
      bgGradientHidden: true,
      image0: 'images/napov_prac.png',
      text: 'game.level2.popup.text',
      text2: 'game.level2.popup.text2',
      image: '',
      divider: true,
      text3: 'game.level2.popup.text3',
      image2: '',
      buttonText: 'game.level2.popup.buttonText',
      buttonBg: 'rgb(62, 177, 145)',
      buttonGradient: {
        color1: 'rgba(62, 131, 143, 0.5)',
        color2: 'rgba(54, 113, 125, 0.6)',
        color3: 'rgba(62, 131, 143, 0.7)',
        color4: 'rgba(54, 113, 125, 0.8)',
        speed: '10s'
      }
    }
  },
  {
    name: 'game.level3.name',
    image: '3_hous.png',
    buttonText: 'game.level3.buttonText',
    theme: {
      primary: '#3E8F74',
      darken: '#2E6759'
    },
    gradient: {
      color1: 'rgba(62, 143, 116, 0.5)',
      color2: 'rgba(62, 143, 116, 0.0)',
      color3: 'rgba(46, 103, 89, 0.5)',
      color4: 'rgba(46, 103, 89, 0.3)',
      speed: '15s'
    },
    map: {
      mapBottom: -20,
      playerBottom: 28,
      playerLeft: 41,
      zoomX: -178,
      zoomY: 0
    },
    intro: [
      {
        surveyID: '9426e380-cc4e-4f47-a504-49f33196d891',
        asset: 'gif/3_INTRO_zarustani_intro.gif',
        gifLength: 2900,
        text: 'game.level3.intro0.text',
        text2: 'game.level3.intro0.text2',
        divider: true,
        text3: 'game.level3.intro0.text3',
        buttonText: 'game.level3.intro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.5)',
          color3: 'rgba(62, 131, 143, 0.5)',
          color4: 'rgba(54, 113, 125, 0.7)',
          speed: '10s'
        },
        buttonColor: '#f3f1e7',
        head: 'game.level3.intro0.head'
      }
    ],
    summary: [
      {
        asset: 'gif/3_EXIT_Odrustani_zubB.gif',
        gifLength: 1900,
        text: 'game.level3.summary0.text',
        text2: 'game.level3.summary0.text2',
        divider: true,
        text3: 'game.level3.summary0.text3',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level3.summary0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.5)',
          color3: 'rgba(62, 131, 143, 0.5)',
          color4: 'rgba(54, 113, 125, 0.7)',
          speed: '10s'
        },
        buttonColor: '#f3f1e7',
        head: 'game.level3.summary0.head',
        inventoryImage: 'images/kanci_zub.png'
      }
    ],
    exit: [
      {
        asset: 'gif/3_EXIT_Odrustani_zubB.gif',
        gifLength: 1900,
        text: 'game.level3.exit0.text',
        text2: 'game.level3.exit0.text2',
        divider: false,
        text3: 'game.level3.exit0.text3',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level3.exit0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.5)',
          color3: 'rgba(62, 131, 143, 0.5)',
          color4: 'rgba(54, 113, 125, 0.7)',
          speed: '10s'
        },
        buttonColor: '#f3f1e7',
        head: 'game.level3.exit0.head',
        inventoryImage: 'images/kanci_zub.png'
      }
    ]
  },
  {
    name: 'game.level4.name',
    image: '0.png',
    buttonText: 'game.level4.buttonText',
    theme: {
      primary: '#3E838F',
      darken: '#29525E'
    },
    gradient: {
      color1: 'rgba(62, 131, 143, 0.1)',
      color2: 'rgba(62, 131, 143, 0.3)',
      color3: 'rgba(41, 82, 94, 0.8)',
      color4: 'rgba(41, 82, 94, 0.8)',
      speed: '20s'
    },
    map: {
      mapBottom: -27,
      playerBottom: 31,
      playerLeft: 65,
      zoomX: -370,
      zoomY: 0
    },
    intro: [
      {
        surveyID: '2480616f-97bd-4843-b4f3-9bd0797c229e',
        asset: 'gif/LV_4_jelen.gif',
        gifLength: 5200,
        text: 'game.level4.intro0.text',
        text2: 'game.level4.intro0.text2',
        divider: true,
        text3: 'game.level4.intro0.text3',
        text4: 'game.level4.intro0.text4',
        buttonText: 'game.level4.intro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.0)',
          color2: 'rgba(54, 113, 125, 0.2)',
          color3: 'rgba(62, 131, 143, 0.5)',
          color4: 'rgba(54, 113, 125, 0.9)',
          speed: '10s'
        },
        buttonColor: '#f3f1e7',
        head: 'game.level4.intro0.head'
      },
      {
        surveyID: '76eacf58-4320-4fe1-ba60-2a9431b7c4b6',
        asset: 'gif/LV4_sova.gif',
        gifLength: 5500,
        text: 'game.level4.intro1.text',
        text2: 'game.level4.intro1.text2',
        divider: true,
        text3: 'game.level4.intro1.text3',
        text4: 'game.level4.intro1.text4',
        buttonText: 'game.level4.intro1.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.0)',
          color2: 'rgba(54, 113, 125, 0.2)',
          color3: 'rgba(62, 131, 143, 0.5)',
          color4: 'rgba(54, 113, 125, 0.9)',
          speed: '10s'
        },
        buttonColor: '#f3f1e7',
        head: 'game.level4.intro1.head'
      },
      {
        surveyID: '3d38aa59-2cf1-42ff-8411-f961110e12a2',
        asset: 'gif/LV_4_-hrabos.gif',
        gifLength: 5200,
        text: 'game.level4.intro2.text',
        text2: 'game.level4.intro2.text2',
        divider: true,
        text3: 'game.level4.intro2.text3',
        text4: 'game.level4.intro2.text4',
        buttonText: 'game.level4.intro2.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.0)',
          color2: 'rgba(54, 113, 125, 0.2)',
          color3: 'rgba(62, 131, 143, 0.5)',
          color4: 'rgba(54, 113, 125, 0.9)',
          speed: '10s'
        },
        buttonColor: '#f3f1e7',
        head: 'game.level4.intro2.head'
      }
    ],
    summary: [
      {
        asset: 'gif/4_EXIT_zviratka.gif',
        gifLength: 5900,
        text: 'game.level4.summary0.text',
        text2: 'game.level4.summary0.text2',
        divider: true,
        text3: 'game.level4.summary0.text3',
        text4: 'game.level4.summary0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level4.summary0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.0)',
          color2: 'rgba(54, 113, 125, 0.2)',
          color3: 'rgba(62, 131, 143, 0.5)',
          color4: 'rgba(54, 113, 125, 0.9)',
          speed: '10s'
        },
        buttonColor: '#f3f1e7',
        head: 'game.level4.summary0.head'
      },
      {
        asset: 'gif/4_EXIT_zviratka.gif',
        gifLength: 4200,
        text: 'game.level4.summary1.text',
        text2: 'game.level4.summary1.text2',
        divider: true,
        text3: 'game.level4.summary1.text3',
        text4: 'game.level4.summary1.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level4.summary1.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.0)',
          color2: 'rgba(54, 113, 125, 0.2)',
          color3: 'rgba(62, 131, 143, 0.5)',
          color4: 'rgba(54, 113, 125, 0.9)',
          speed: '10s'
        },
        buttonColor: '#f3f1e7',
        head: 'game.level4.summary1.head'
      },
      {
        asset: 'gif/4_EXIT_zviratka.gif',
        gifLength: 4200,
        text: 'game.level4.summary2.text',
        text2: 'game.level4.summary2.text2',
        divider: true,
        text3: 'game.level4.summary2.text3',
        text4: 'game.level4.summary2.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level4.summary2.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.0)',
          color2: 'rgba(54, 113, 125, 0.2)',
          color3: 'rgba(62, 131, 143, 0.5)',
          color4: 'rgba(54, 113, 125, 0.9)',
          speed: '10s'
        },
        buttonColor: '#f3f1e7',
        head: 'game.level4.summary2.head'
      }
    ],
    exit: [
      {
        asset: 'gif/4_EXIT_zviratka.gif',
        gifLength: 5200,
        text: 'game.level4.exit0.text',
        text2: 'game.level4.exit0.text2',
        divider: false,
        text3: 'game.level4.exit0.text3',
        text4: 'game.level4.exit0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level4.exit0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.0)',
          color2: 'rgba(54, 113, 125, 0.2)',
          color3: 'rgba(62, 131, 143, 0.5)',
          color4: 'rgba(54, 113, 125, 0.9)',
          speed: '10s'
        },
        buttonColor: '#f3f1e7',
        head: 'game.level4.exit0.head'
      },
      {
        asset: 'gif/4_EXIT_zviratka.gif',
        gifLength: 5200,
        text: 'game.level4.exit1.text',
        text2: 'game.level4.exit1.text2',
        divider: false,
        text3: 'game.level4.exit1.text3',
        text4: 'game.level4.exit1.text4',
        buttonText: 'game.level4.exit1.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.0)',
          color2: 'rgba(54, 113, 125, 0.2)',
          color3: 'rgba(62, 131, 143, 0.5)',
          color4: 'rgba(54, 113, 125, 0.9)',
          speed: '10s'
        },
        buttonColor: '#f3f1e7',
        head: 'game.level4.exit1.head'
      },
      {
        asset: 'gif/4_EXIT_zviratka.gif',
        gifLength: 5200,
        text: 'game.level4.exit2.text',
        text2: 'game.level4.exit2.text2',
        divider: false,
        text3: 'game.level4.exit2.text3',
        text4: 'game.level4.exit2.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level4.exit2.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.0)',
          color2: 'rgba(54, 113, 125, 0.2)',
          color3: 'rgba(62, 131, 143, 0.5)',
          color4: 'rgba(54, 113, 125, 0.9)',
          speed: '10s'
        },
        buttonColor: '#f3f1e7',
        head: 'game.level4.exit2.head'
      }
    ],
    preIntro: [
      {
        asset: 'gif/LV_4cesta_FINAL_.gif',
        gifLength: 4900,
        text: 'game.level4.preIntro0.text',
        text2: 'game.level4.preIntro0.text2',
        divider: true,
        text3: 'game.level4.preIntro0.text3',
        text4: 'game.level4.preIntro0.text4',
        buttonText: 'game.level4.preIntro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.0)',
          color2: 'rgba(54, 113, 125, 0.2)',
          color3: 'rgba(62, 131, 143, 0.57)',
          color4: 'rgba(54, 113, 125, 0.9)',
          speed: '10s'
        },
        buttonColor: '#f3f1e7',
        head: 'game.level4.preIntro0.head'
      }
    ],
    popup: {
      image0: 'images/mikro_100x60.png',
      text: 'game.level4.popup.text',
      text2: 'game.level4.popup.text2',
      image: '',
      divider: true,
      text3: 'game.level4.popup.text3',
      image2: '',
      text4: 'game.level4.popup.text4',
      buttonText: 'game.level4.popup.buttonText'
    }
  },
  {
    name: 'game.level5.name',
    image: '0.png',
    buttonText: 'game.level5.buttonText',
    theme: {
      primary: '#3E8F74',
      darken: '#2E6759'
    },
    gradient: {
      color1: 'rgba(62, 143, 116, 0.1)',
      color2: 'rgba(62, 143, 116, 0.3)',
      color3: 'rgba(46, 103, 89, 0.4)',
      color4: 'rgba(46, 103, 89, 0.4)',
      speed: '15s'
    },
    map: {
      mapBottom: -30,
      playerBottom: 35,
      playerLeft: 48,
      zoomX: -228,
      zoomY: 0
    },
    intro: [
      {
        asset: 'gif/5_INTRO_Piknik_EDIT.gif',
        gifLength: 4500,
        text: 'game.level5.intro0.text',
        text2: 'game.level5.intro0.text2',
        divider: true,
        text3: 'game.level5.intro0.text3',
        text4: 'game.level5.intro0.text4',
        buttonText: 'game.level5.intro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.4)',
          color3: 'rgba(62, 131, 143, 0.5)',
          color4: 'rgba(54, 113, 125, 0.4)',
          speed: '15s'
        },
        buttonColor: '#f3f1e7',
        head: 'game.level5.intro0.head'
      }
    ],
    summary: [
      {
        asset: 'images/hruska.png',
        gifLength: 1800,
        text: 'game.level5.summary0.text',
        text2: 'game.level5.summary0.text2',
        divider: true,
        text3: 'game.level5.summary0.text3',
        text4: 'game.level5.summary0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        repeatAction: true,
        buttonText: 'game.level5.summary0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.4)',
          color3: 'rgba(62, 131, 143, 0.5)',
          color4: 'rgba(54, 113, 125, 0.4)',
          speed: '10s'
        },
        buttonColor: '#f3f1e7',
        head: 'game.level5.summary0.head'
      }
    ],
    exit: [
      {
        asset: 'gif/Exit_5_Hruskojablon_EDIT.gif',
        gifLength: 3000,
        text: 'game.level5.exit0.text',
        text2: 'game.level5.exit0.text2',
        divider: true,
        text3: 'game.level5.exit0.text3',
        text4: 'game.level5.exit0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        repeatAction: true,
        buttonText: 'game.level5.exit0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.4)',
          color3: 'rgba(62, 131, 143, 0.5)',
          color4: 'rgba(54, 113, 125, 0.4)',
          speed: '10s'
        },
        buttonColor: '#f3f1e7',
        head: 'game.level5.exit0.head'
      }
    ],
    popup: {
      bgImage: 'blue_i.png',
      image0: 'images/zebr_70x160.png',
      text: 'game.level5.popup.text',
      text2: 'game.level5.popup.text2',
      image: '',
      divider: true,
      text3: 'game.level5.popup.text3',
      image2: '',
      text4: 'game.level5.popup.text4',
      buttonText: 'game.level5.popup.buttonText'
    }
  },
  {
    name: 'game.level6.name',
    image: '0.png',
    buttonText: 'game.level6.buttonText',
    theme: {
      primary: '#A36B99',
      darken: '#885890'
    },
    gradient: {
      color1: 'rgba(163, 107, 153, 0.2)',
      color2: 'rgba(163, 107, 153, 0.3)',
      color3: 'rgba(136, 89, 128, 0.6)',
      color4: 'rgba(136, 89, 128, 0.6)',
      speed: '15s'
    },
    map: {
      mapBottom: -32,
      playerBottom: 41,
      playerLeft: 22,
      zoomX: -54,
      zoomY: 0
    },
    intro: [
      {
        surveyID: 'fa164da9-91c0-4da4-9fe7-e9a4817a1df0',
        asset: 'gif/gif6-sakura.gif',
        gifLength: 3800,
        text: 'game.level6.intro0.text',
        text2: 'game.level6.intro0.text2',
        divider: true,
        text3: 'game.level6.intro0.text3',
        text4: 'game.level6.intro0.text4',
        buttonText: 'game.level6.intro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        },
        buttonColor: '#f3f1e7'
      }
    ],
    summary: [
      {
        asset: 'gif/6_EXIT1__Sakura.gif',
        gifLength: 1000,
        text: 'game.level6.summary0.text',
        text2: 'game.level6.summary0.text2',
        divider: true,
        text3: 'game.level6.summary0.text3',
        text4: 'game.level6.summary0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level6.summary0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        },
        buttonColor: '#f3f1e7'
      }
    ],
    exit: [
      {
        asset: 'gif/6_EXIT1__Sakura.gif',
        gifLength: 4800,
        text: 'game.level6.exit0.text',
        text2: 'game.level6.exit0.text2',
        divider: true,
        text3: 'game.level6.exit0.text3',
        text4: 'game.level6.exit0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level6.exit0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        },
        buttonColor: '#f3f1e7'
      }
    ]
  },
  {
    name: 'game.level7.name',
    image: '0.png',
    buttonText: 'game.level7.buttonText',
    theme: {
      primary: '#727189',
      darken: '#4D6AA1'
    },
    gradient: {
      color1: 'rgba(113, 114, 137, 0.3)',
      color2: 'rgba(113, 114, 137, 0.0)',
      color3: 'rgba(77, 106, 161, 0.5)',
      color4: 'rgba(77, 106, 161, 0.6)',
      speed: '15s'
    },
    map: {
      mapBottom: -52,
      playerBottom: 55,
      playerLeft: 51,
      zoomX: -224,
      zoomY: 0
    },
    intro: [
      {
        surveyID: 'a9d9e0c7-4d3e-40ef-bbbc-1e7f4286940f',
        asset: 'gif/7_INTRO_statistikarka.gif',
        gifLength: 4300,
        text: 'game.level7.intro0.text',
        text2: 'game.level7.intro0.text2',
        divider: true,
        text3: 'game.level7.intro0.text3',
        text4: 'game.level7.intro0.text4',
        buttonText: 'game.level7.intro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.4)',
          color3: 'rgba(62, 131, 143, 0.5)',
          color4: 'rgba(54, 113, 125, 0.4)',
          speed: '15s'
        },
        head: 'game.level7.intro0.head'
      }
    ],
    summary: [
      {
        asset: 'gif/7_EXIT_Baba1.gif',
        gifLength: 1000,
        text: 'game.level7.summary0.text',
        text2: 'game.level7.summary0.text2',
        divider: true,
        text3: 'game.level7.summary0.text3',
        image2Statistics: 'images/image_Statistic_pracovni.png',
        text2Statistics: 'game.level7.summary0.text2Statistics',
        text4: 'game.level7.summary0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level7.summary0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.4)',
          color3: 'rgba(62, 131, 143, 0.5)',
          color4: 'rgba(54, 113, 125, 0.4)',
          speed: '15s'
        },
        inventoryImage: 'images/nejblizsi_hodnoty.png',
        repeatSurvey: true
      }
    ],
    exit: [
      {
        asset: 'gif/7_EXIT_Baba1.gif',
        gifLength: 4200,
        text: 'game.level7.exit0.text',
        text2: 'game.level7.exit0.text2',
        divider: true,
        text3: 'game.level7.exit0.text3',
        image2Statistics: 'images/image_Statistic_pracovni.png',
        text2Statistics: 'game.level7.exit0.text2Statistics',
        text4: 'game.level7.exit0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level7.exit0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.4)',
          color3: 'rgba(62, 131, 143, 0.5)',
          color4: 'rgba(54, 113, 125, 0.4)',
          speed: '15s'
        },
        inventoryImage: 'images/nejblizsi_hodnoty.png',
        repeatSurvey: true
      }
    ],
    preIntro: [
      {
        asset: 'gif/LVL_7INT_statistikarka_FINAL_PREINTRO.gif',
        gifLength: 2900,
        text: 'game.level7.preIntro0.text',
        text2: 'game.level7.preIntro0.text2',
        text3: 'game.level7.preIntro0.text3',
        text4: 'game.level7.preIntro0.text4',
        buttonText: 'game.level7.preIntro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.4)',
          color3: 'rgba(62, 131, 143, 0.5)',
          color4: 'rgba(54, 113, 125, 0.4)',
          speed: '15s'
        },
        head: 'game.level7.preIntro0.head'
      }
    ]
  },
  {
    name: 'game.level8.name',
    image: '0.png',
    buttonText: 'game.level8.buttonText',
    theme: {
      primary: '#727189',
      darken: '#8F8EA3'
    },
    gradient: {
      color1: 'rgba(113, 114, 137, 0.3)',
      color2: 'rgba(113, 114, 137, 0.0)',
      color3: 'rgba(143, 142, 163, 0.5)',
      color4: 'rgba(143, 142, 163, 0.6)',
      speed: '15s'
    },
    map: {
      mapBottom: -62,
      playerBottom: 72,
      playerLeft: 22,
      zoomX: -100,
      zoomY: 0
    },
    intro: [
      {
        asset: 'gif/LV_8_Hora_snezeni.gif',
        gifLength: 5000,
        text: 'game.level8.intro0.text',
        text2: 'game.level8.intro0.text2',
        divider: false,
        text3: 'game.level8.intro0.text3',
        text4: 'game.level8.intro0.text4',
        buttonText: 'game.level8.intro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        },
        head: 'game.level8.intro0.head'
      }
    ],
    summary: [
      {
        asset: 'images/LV_8_SUMMARY.png',
        gifLength: 1000,
        text: 'game.level8.summary0.text',
        text2: 'game.level8.summary0.text2',
        divider: true,
        text3: 'game.level8.summary0.text3',
        image2Statistics: 'images/image_Statistic_pracovni.png',
        text2Statistics: 'game.level8.summary0.text2Statistics',
        text4: 'game.level8.summary0.text4',
        buttonText: 'game.level8.summary0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        },
        inventoryImage: 'images/totem_zvire.png'
      }
    ],
    exit: [
      {
        asset: 'gif/LV_8_Hora_snezeni_EXIT.gif',
        gifLength: 3500,
        text: 'game.level8.exit0.text',
        text2: 'game.level8.exit0.text2',
        divider: true,
        text3: 'game.level8.exit0.text3',
        image2Statistics: 'images/image_Statistic_pracovni.png',
        text2Statistics: 'game.level8.exit0.text2Statistics',
        text4: 'game.level8.exit0.text4',
        buttonText: 'game.level8.exit0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        },
        head: 'game.level8.exit0.head',
        inventoryImage: 'images/totem_zvire.png'
      }
    ],
    popup: {
      image0: 'images/napov_prac.png',
      text: 'game.level8.popup.text',
      text2: 'game.level8.popup.text2',
      image: '',
      divider: true,
      text3: 'game.level8.popup.text3',
      text4: 'game.level8.popup.text4',
      buttonText: 'game.level8.popup.buttonText'
    }
  },
  {
    name: 'game.level9.name',
    image: '0.png',
    buttonText: 'game.level9.buttonText',
    theme: {
      primary: '#4C6EAE',
      darken: '#3E5A8E'
    },
    gradient: {
      color1: 'rgba(76, 110, 174, 0.0)',
      color2: 'rgba(76, 110, 174, 0.0)',
      color3: 'rgba(62, 90, 142, 0.6)',
      color4: 'rgba(62, 90, 142, 0.6)',
      speed: '15s'
    },
    map: {
      mapBottom: -60,
      playerBottom: 65,
      playerLeft: 52,
      zoomX: -180,
      zoomY: 0
    },
    intro: [
      {
        surveyID: 'f134b00d-9ad8-422a-8be9-28358e2426f5',
        asset: 'gif/9_INTRO_vahavy.gif ',
        gifLength: 7000,
        text: 'game.level9.intro0.text',
        text2: 'game.level9.intro0.text2',
        divider: true,
        text3: 'game.level9.intro0.text3',
        text4: 'game.level9.intro0.text4',
        buttonText: 'game.level9.intro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    summary: [
      {
        asset: 'images/hruska.png',
        gifLength: 1000,
        text: 'game.level9.summary0.text',
        text2: 'game.level9.summary0.text2',
        divider: true,
        text3: 'game.level9.summary0.text3',
        text4: 'game.level9.summary0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level9.summary0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    exit: [
      {
        asset: 'gif/9_EXIT_Most.gif',
        gifLength: 4200,
        text: 'game.level9.exit0.text',
        text2: 'game.level9.exit0.text2',
        divider: true,
        text3: 'game.level9.exit0.text3',
        text4: 'game.level9.exit0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level9.exit0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    popup: {
      bgImage: '340x540_rozhodov__1_.png',
      image0: '',
      text: 'game.level9.popup.text',
      text2: 'game.level9.popup.text2',
      image: 'images/rozhodovani_modal_90x90round.png',
      divider: true,
      text3: 'game.level9.popup.text3',
      text4: 'game.level9.popup.text4',
      buttonText: 'game.level9.popup.buttonText'
    }
  },
  {
    name: 'game.level10.name',
    image: '0.png',
    buttonText: 'game.level10.buttonText',
    theme: {
      primary: '#727189',
      darken: '#4D6AA1'
    },
    gradient: {
      color1: 'rgba(113, 114, 137, 0.0)',
      color2: 'rgba(113, 114, 137, 0.0)',
      color3: 'rgba(77, 106, 161, 0.6)',
      color4: 'rgba(77, 106, 161, 0.6)',
      speed: '15s'
    },
    map: {
      mapBottom: -57,
      playerBottom: 63,
      playerLeft: 83,
      zoomX: -375,
      zoomY: 0
    },
    intro: [
      {
        surveyID: '356a1382-5019-494f-b463-f7bc8ab59b2a',
        asset: 'gif/11_INTRO-sjezd-fin_EDIT.gif',
        gifLength: 7200,
        text: 'game.level10.intro0.text',
        text2: 'game.level10.intro0.text2',
        divider: true,
        text3: 'game.level10.intro0.text3',
        text4: 'game.level10.intro0.text4',
        buttonText: 'game.level10.intro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    summary: [
      {
        asset: 'images/hruska.png',
        gifLength: 1000,
        text: 'game.level10.summary0.text',
        text2: 'game.level10.summary0.text2',
        divider: true,
        text3: 'game.level10.summary0.text3',
        text4: 'game.level10.summary0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level10.summary0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    exit: [
      {
        asset: 'gif/11_EXIT_sjezd_mlha.gif',
        gifLength: 7200,
        text: 'game.level10.exit0.text',
        text2: 'game.level10.exit0.text2',
        divider: true,
        text3: 'game.level10.exit0.text3',
        text4: 'game.level10.exit0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level10.exit0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    preIntro: [
      {
        asset: 'gif/11_INTRO-sjezd-fin_EDIT.gif',
        gifLength: 4000,
        text: 'game.level10.preIntro0.text',
        text2: 'game.level10.preIntro0.text2',
        divider: false,
        text3: 'game.level10.preIntro0.text3',
        text4: 'game.level10.preIntro0.text4',
        buttonText: 'game.level10.preIntro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ]
  },
  {
    name: 'game.level11.name',
    image: '0.png',
    buttonText: 'game.level11.buttonText',
    theme: {
      primary: '#383159',
      darken: '#211238'
    },
    gradient: {
      color1: 'rgba(56, 49, 89, 0.0)',
      color2: 'rgba(33, 18, 56, 0.9)',
      color3: 'rgba(56, 49, 89, 0.0)',
      color4: 'rgba(33, 18, 56, 0.8)',
      speed: '15s'
    },
    map: {
      mapBottom: -48,
      playerBottom: 52,
      playerLeft: 71,
      zoomX: -320,
      zoomY: 0
    },
    intro: [
      {
        surveyID: 'be29bea9-bc87-4e15-bb07-c1fc48ad6d1c',
        asset: 'gif/11_INTRO_casoprostor_420.gif',
        gifLength: 5000,
        text: 'game.level11.intro0.text',
        text2: 'game.level11.intro0.text2',
        divider: true,
        text3: 'game.level11.intro0.text3',
        text4: 'game.level11.intro0.text4',
        buttonText: 'game.level11.intro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    summary: [
      {
        asset: 'gif/11_SUMMARY_Casoprostor.gif',
        gifLength: 1000,
        text: 'game.level11.summary0.text',
        text2: 'game.level11.summary0.text2',
        divider: true,
        text3: 'game.level11.summary0.text3',
        text4: 'game.level11.summary0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level11.summary0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        },
        repeatSurvey: true
      }
    ],
    exit: [
      {
        asset: 'gif/11_EXIT_Casoprostor.gif',
        gifLength: 1000,
        text: 'game.level11.exit0.text',
        text2: 'game.level11.exit0.text2',
        divider: true,
        text3: 'game.level11.exit0.text3',
        text4: 'game.level11.exit0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level11.exit0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        },
        repeatSurvey: true
      }
    ]
  },
  {
    name: 'game.level12.name',
    image: '0.png',
    buttonText: 'game.level12.buttonText',
    theme: {
      primary: '#746D9C',
      darken: '#545075'
    },
    gradient: {
      color1: 'rgba(116, 109, 156, 0.0)',
      color2: 'rgba(116, 109, 156, 0.0)',
      color3: 'rgba(84, 80, 117, 0.6)',
      color4: 'rgba(84, 80, 117, 0.6)',
      speed: '15s'
    },
    map: {
      mapBottom: -57,
      playerBottom: 73,
      playerLeft: 42,
      zoomX: -185,
      zoomY: 0
    },
    intro: [
      {
        surveyID: '36a94fc9-d2f7-46ed-b0be-469ed0d8e6b0',
        asset: 'gif/12_INTRO_hospoda.gif',
        gifLength: 5000,
        text: 'game.level12.intro0.text',
        text2: 'game.level12.intro0.text2',
        divider: true,
        text3: 'game.level12.intro0.text3',
        text4: 'game.level12.intro0.text4',
        buttonText: 'game.level12.intro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    summary: [
      {
        asset: 'gif/12_SUMMARY_Hostinec.gif',
        gifLength: 1000,
        text: 'game.level12.summary0.text',
        text2: 'game.level12.summary0.text2',
        divider: true,
        text3: 'game.level12.summary0.text3',
        text4: 'game.level12.summary0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level12.summary0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    exit: [
      {
        asset: 'gif/12_EXIT_hospoda.gif',
        gifLength: 1000,
        text: 'game.level12.exit0.text',
        text2: 'game.level12.exit0.text2',
        divider: true,
        text3: 'game.level12.exit0.text3',
        text4: 'game.level12.exit0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level12.exit0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    preIntro: [
      {
        asset: 'images/napov_prac.png',
        gifLength: 2400,
        text: 'game.level12.preIntro0.text',
        text2: 'game.level12.preIntro0.text2',
        divider: true,
        text3: 'game.level12.preIntro0.text3',
        text4: 'game.level12.preIntro0.text4',
        buttonText: 'game.level12.preIntro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.0)',
          color2: 'rgba(54, 113, 125, 0.2)',
          color3: 'rgba(62, 131, 143, 0.57)',
          color4: 'rgba(54, 113, 125, 0.9)',
          speed: '10s'
        },
        buttonColor: '#f3f1e7',
        head: 'game.level12.preIntro0.head'
      }
    ],
    popup: {
      image0: 'images/napov_prac.png',
      text: 'game.level12.popup.text',
      text2: 'game.level12.popup.text2',
      image: '',
      divider: true,
      text3: 'game.level12.popup.text3',
      text4: 'game.level12.popup.text4',
      buttonText: 'game.level12.popup.buttonText'
    }
  },
  {
    name: 'game.level13.name',
    image: '0.png',
    buttonText: 'game.level13.buttonText',
    theme: {
      primary: '#3E8F74',
      darken: '#2E6759'
    },
    gradient: {
      color1: 'rgba(62, 143, 116, 0.0)',
      color2: 'rgba(62, 143, 116, 0.0)',
      color3: 'rgba(46, 103, 89, 0.6)',
      color4: 'rgba(46, 103, 89, 0.6)',
      speed: '15s'
    },
    map: {
      mapBottom: -78,
      playerBottom: 83,
      playerLeft: 50,
      zoomX: -185,
      zoomY: 0
    },
    intro: [
      {
        surveyID: 'efd2b777-91d6-491a-9e32-5accece850c5',
        asset: 'gif/13_INTRO_vetesnik.gif',
        gifLength: 5000,
        text: 'game.level13.intro0.text',
        text2: 'game.level13.intro0.text2',
        divider: true,
        text3: 'game.level13.intro0.text3',
        text4: 'game.level13.intro0.text4',
        buttonText: 'game.level13.intro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    summary: [
      {
        asset: 'gif/13_SUMMARY_vetesnik.gif',
        gifLength: 1000,
        text: 'game.level13.summary0.text',
        text2: 'game.level13.summary0.text2',
        divider: true,
        text3: 'game.level13.summary0.text3',
        text4: 'game.level13.summary0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level13.summary0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        },
        inventoryImage: 'images/nezrale_jablko.png'
      }
    ],
    exit: [
      {
        asset: 'gif/13_EXIT_vetesnik.gif',
        gifLength: 1000,
        text: 'game.level13.exit0.text',
        text2: 'game.level13.exit0.text2',
        divider: true,
        text3: 'game.level13.exit0.text3',
        text4: 'game.level13.exit0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level13.exit0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        },
        inventoryImage: 'images/nezrale_jablko.png'
      }
    ]
  },
  {
    name: 'game.level14.name',
    image: '0.png',
    buttonText: 'game.level14.buttonText',
    theme: {
      primary: '#3E838F',
      darken: '#29525E'
    },
    gradient: {
      color1: 'rgba(62, 131, 143, 0.0)',
      color2: 'rgba(62, 131, 143, 0.0)',
      color3: 'rgba(41, 82, 94, 0.6)',
      color4: 'rgba(41, 82, 94, 0.6)',
      speed: '15s'
    },
    map: {
      mapBottom: -82,
      playerBottom: 93,
      playerLeft: 80,
      zoomX: -370,
      zoomY: 0
    },
    intro: [
      {
        surveyID: '83a81706-3d32-432e-80aa-be5f06e80f7b',
        asset: 'gif/16_INTRO_psi.gif',
        gifLength: 5000,
        text: 'game.level14.intro0.text',
        text2: 'game.level14.intro0.text2',
        divider: true,
        text3: 'game.level14.intro0.text3',
        text4: 'game.level14.intro0.text4',
        buttonText: 'game.level14.intro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    summary: [
      {
        asset: 'gif/14_SUMARY_ocas.gif',
        gifLength: 1000,
        text: 'game.level14.summary0.text',
        text2: 'game.level14.summary0.text2',
        divider: true,
        text3: 'game.level14.summary0.text3',
        text4: 'game.level14.summary0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level14.summary0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    exit: [
      {
        asset: 'gif/14_EXIT_psi.gif',
        gifLength: 1000,
        text: 'game.level14.exit0.text',
        text2: 'game.level14.exit0.text2',
        divider: true,
        text3: 'game.level14.exit0.text3',
        text4: 'game.level14.exit0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level14.exit0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143,, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ]
  },
  {
    name: 'game.level15.name',
    image: '0.png',
    buttonText: 'game.level15.buttonText',
    theme: {
      primary: '#727189',
      darken: '#4D6AA1'
    },
    gradient: {
      color1: 'rgba(113, 114, 137, 0.0)',
      color2: 'rgba(113, 114, 137, 0.0)',
      color3: 'rgba(77, 106, 161, 0.6)',
      color4: 'rgba(77, 106, 161, 0.6)',
      speed: '15s'
    },
    map: {
      mapBottom: -90,
      playerBottom: 103,
      playerLeft: 71,
      zoomX: -230,
      zoomY: 29
    },
    intro: [
      {
        surveyID: 'ba01f85d-aa93-4688-b45d-c30146fd94c7',
        asset: 'gif/13_INTRO_new_Vodopad.gif',
        gifLength: 1800,
        text: 'game.level15.intro0.text',
        text2: 'game.level15.intro0.text2',
        divider: true,
        text3: 'game.level15.intro0.text3',
        text4: 'game.level15.intro0.text4',
        buttonText: 'game.level15.intro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    summary: [
      {
        asset: 'gif/15_EXIT_FIN_Skok_vodopad.gif',
        gifLength: 1000,
        text: 'game.level15.summary0.text',
        text2: 'game.level15.summary0.text2',
        divider: true,
        text3: 'game.level15.summary0.text3',
        text4: 'game.level15.summary0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level15.summary0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        },
        repeatSurvey: true
      }
    ],
    exit: [
      {
        asset: 'gif/15_EXIT_FIN_Skok_vodopad.gif',
        gifLength: 5500,
        text: 'game.level15.exit0.text',
        text2: 'game.level15.exit0.text2',
        divider: true,
        text3: 'game.level15.exit0.text3',
        text4: 'game.level15.exit0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level15.exit0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        },
        repeatSurvey: true
      }
    ],
    preIntro: [
      {
        asset: 'gif/15_Intro_vodopad.gif',
        gifLength: 4000,
        text: 'game.level15.preIntro0.text',
        text2: 'game.level15.preIntro0.text2',
        divider: false,
        text3: 'game.level15.preIntro0.text3',
        text4: 'game.level15.preIntro0.text4',
        buttonText: 'game.level15.preIntro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    popup: {
      bgImage: 'udoli_340x540px.png',
      image0: '',
      text: 'game.level15.popup.text',
      text2: 'game.level15.popup.text2',
      image: '',
      divider: true,
      text3: 'game.level15.popup.text3',
      text4: 'game.level15.popup.text4',
      buttonText: 'game.level15.popup.buttonText'
    }
  },
  {
    name: 'game.level16.name',
    image: '0.png',
    buttonText: 'game.level16.buttonText',
    theme: {
      primary: '#4C6EAE',
      darken: '#3E5A8E'
    },
    gradient: {
      color1: 'rgba(76, 110, 174, 0.0)',
      color2: 'rgba(76, 110, 174, 0.0)',
      color3: 'rgba(62, 90, 142, 0.6)',
      color4: 'rgba(62, 90, 142, 0.6)',
      speed: '15s'
    },
    map: {
      mapBottom: -76,
      playerBottom: 89,
      playerLeft: 48,
      zoomX: -190,
      zoomY: 100
    },
    intro: [
      {
        asset: 'gif/16_INTRO_lodka_darek_1.gif',
        gifLength: 5000,
        text: 'game.level16.intro0.text',
        text2: 'game.level16.intro0.text2',
        divider: true,
        text3: 'game.level16.intro0.text3',
        text4: 'game.level16.intro0.text4',
        buttonText: 'game.level16.intro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    summary: [
      {
        asset: 'gif/16_EXITlodka_kamarad_1.gif',
        gifLength: 1000,
        text: 'game.level16.summary0.text',
        text2: 'game.level16.summary0.text2',
        divider: true,
        text3: 'game.level16.summary0.text3',
        text4: 'game.level16.summary0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level16.summary0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        },
        inventoryImage: 'images/idealni_partak.png',
        testText: 'game.level16.summary0.testText',
        testButtonText: 'game.level16.summary0.testButtonText'
      }
    ],
    exit: [
      {
        asset: 'gif/16_EXITlodka_kamarad_1.gif',
        gifLength: 1000,
        text: 'game.level16.exit0.text',
        text2: 'game.level16.exit0.text2',
        divider: true,
        text3: 'game.level16.exit0.text3',
        text4: 'game.level16.exit0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level16.exit0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        },
        inventoryImage: 'images/idealni_partak.png'
      }
    ],
    preIntro: [
      {
        asset: 'gif/16_PREINTRO_vynor.gif',
        gifLength: 2200,
        text: 'game.level16.preIntro0.text',
        text2: 'game.level16.preIntro0.text2',
        divider: false,
        text3: 'game.level16.preIntro0.text3',
        text4: 'game.level16.preIntro0.text4',
        buttonText: 'game.level16.preIntro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ]
  },
  {
    name: 'game.level17.name',
    image: '0.png',
    buttonText: 'game.level17.buttonText',
    theme: {
      primary: '#4C6EAE',
      darken: '#3E5A8E'
    },
    gradient: {
      color1: 'rgba(76, 110, 174, 0.0)',
      color2: 'rgba(76, 110, 174, 0.0)',
      color3: 'rgba(62, 90, 142, 0.6)',
      color4: 'rgba(62, 90, 142, 0.6)',
      speed: '15s'
    },
    map: {
      mapBottom: -79,
      playerBottom: 91,
      playerLeft: 22,
      zoomX: -130,
      zoomY: 30
    },
    intro: [
      {
        surveyID: '8e50e5fa-96ea-4b57-8c65-574f521506cb',
        asset: 'gif/17_INTRO_lodka_kamarad_1.gif',
        gifLength: 5000,
        text: 'game.level17.intro0.text',
        text2: 'game.level17.intro0.text2',
        divider: true,
        text3: 'game.level17.intro0.text3',
        text4: 'game.level17.intro0.text4',
        buttonText: 'game.level17.intro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    summary: [
      {
        asset: 'gif/17_INTRO_lodka_kamarad_1.gif',
        gifLength: 1000,
        text: 'game.level17.summary0.text',
        text2: 'game.level17.summary0.text2',
        divider: true,
        text3: 'game.level17.summary0.text3',
        text4: 'game.level17.summary0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level17.summary0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    exit: [
      {
        asset: 'gif/16-INTRO_pereje.gif',
        gifLength: 1000,
        text: 'game.level17.exit0.text',
        text2: 'game.level17.exit0.text2',
        divider: true,
        text3: 'game.level17.exit0.text3',
        text4: 'game.level17.exit0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level17.exit0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ]
  },
  {
    name: 'game.level18.name',
    image: '0.png',
    buttonText: 'game.level18.buttonText',
    theme: {
      primary: '#4C6EAE',
      darken: '#3E5A8E'
    },
    gradient: {
      color1: 'rgba(76, 110, 174, 0.0)',
      color2: 'rgba(76, 110, 174, 0.0)',
      color3: 'rgba(62, 90, 142, 0.6)',
      color4: 'rgba(62, 90, 142, 0.6)',
      speed: '15s'
    },
    map: {
      mapBottom: -84,
      playerBottom: 99,
      playerLeft: 5,
      zoomX: -70,
      zoomY: 20
    },
    intro: [
      {
        surveyID: '2a2622d7-5bc3-451f-87ab-18b2c2486501',
        asset: 'gif/17_INTRO_chameleon.gif',
        gifLength: 4900,
        text: 'game.level18.intro0.text',
        text2: 'game.level18.intro0.text2',
        divider: true,
        text3: 'game.level18.intro0.text3',
        text4: 'game.level18.intro0.text4',
        buttonText: 'game.level18.intro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    summary: [
      {
        asset: 'images/hruska.png',
        gifLength: 1000,
        text: 'game.level18.summary0.text',
        text2: 'game.level18.summary0.text2',
        divider: true,
        text3: 'game.level18.summary0.text3',
        text4: 'game.level18.summary0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level18.summary0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        },
        inventoryImage: 'images/idealni_partak.png'
      }
    ],
    exit: [
      {
        asset: 'gif/17_Exit_chameleon_3colour.gif',
        gifLength: 4000,
        text: 'game.level18.exit0.text',
        text2: 'game.level18.exit0.text2',
        divider: true,
        text3: 'game.level18.exit0.text3',
        text4: 'game.level18.exit0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level18.exit0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        },
        inventoryImage: 'images/idealni_partak.png'
      }
    ]
  },
  {
    name: 'game.level19.name',
    image: '0.png',
    buttonText: 'game.level19.buttonText',
    theme: {
      primary: '#0264A6',
      darken: '#003d75'
    },
    gradient: {
      color1: 'rgba(1, 88, 146, 0.0)',
      color2: 'rgba(1, 88, 146, 0.0)',
      color3: 'rgba(0, 52, 104, 0.6)',
      color4: 'rgba(0, 36, 71, 0.6)',
      speed: '15s'
    },
    map: {
      mapBottom: -95,
      playerBottom: 108,
      playerLeft: 9,
      zoomX: -90,
      zoomY: 40
    },
    intro: [
      {
        surveyID: '7a294ae2-bc45-4726-b7a4-bb13382db449',
        asset: 'gif/19_INTRO_zaba.gif',
        gifLength: 5000,
        text: 'game.level19.intro0.text',
        text2: 'game.level19.intro0.text2',
        divider: true,
        text3: 'game.level19.intro0.text3',
        text4: 'game.level19.intro0.text4',
        buttonText: 'game.level19.intro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    summary: [
      {
        asset: 'images/19_INTRO_zaba.png',
        gifLength: 1000,
        text: 'game.level19.summary0.text',
        text2: 'game.level19.summary0.text2',
        divider: true,
        text3: 'game.level19.summary0.text3',
        text4: 'game.level19.summary0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level19.summary0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    exit: [
      {
        asset: 'gif/19_EXIT_zaba.gif',
        gifLength: 1000,
        text: 'game.level19.exit0.text',
        text2: 'game.level19.exit0.text2',
        divider: true,
        text3: 'game.level19.exit0.text3',
        text4: 'game.level19.exit0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level19.exit0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ]
  },
  {
    name: 'game.level20.name',
    image: '0.png',
    buttonText: 'game.level20.buttonText',
    theme: {
      primary: '#4C6EAE',
      darken: '#3E5A8E'
    },
    gradient: {
      color1: 'rgba(76, 110, 174, 0.0)',
      color2: 'rgba(76, 110, 174, 0.0)',
      color3: 'rgba(62, 90, 142, 0.6)',
      color4: 'rgba(62, 90, 142, 0.6)',
      speed: '15s'
    },
    map: {
      mapBottom: -98,
      playerBottom: 105,
      playerLeft: 43,
      zoomX: -185,
      zoomY: 0
    },
    intro: [
      {
        surveyID: '9956cc01-00d4-4a5b-8fbd-ac939d2c1a57',
        asset: 'gif/20_INTRO_meandy.gif',
        gifLength: 5500,
        text: 'game.level20.intro0.text',
        text2: 'game.level20.intro0.text2',
        divider: true,
        text3: 'game.level20.intro0.text3',
        image2Statistics: 'images/image_Statistic_pracovni.png',
        text2Statistics: 'game.level20.intro0.text2Statistics',
        text4: 'game.level20.intro0.text4',
        buttonText: 'game.level20.intro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    summary: [
      {
        asset: 'images/hruska.png',
        gifLength: 5500,
        text: 'game.level20.summary0.text',
        text2: 'game.level20.summary0.text2',
        divider: true,
        text3: 'game.level20.summary0.text3',
        image2Statistics: 'images/image_Statistic_pracovni.png',
        text2Statistics: 'game.level20.summary0.text2Statistics',
        text4: 'game.level20.summary0.text4',
        buttonText: 'game.level20.summary0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    exit: [
      {
        asset: 'gif/20_Exit_Meandy.gif',
        gifLength: 400,
        text: 'game.level20.exit0.text',
        text2: 'game.level20.exit0.text2',
        divider: true,
        text3: 'game.level20.exit0.text3',
        image2Statistics: 'images/image_Statistic_pracovni.png',
        text2Statistics: 'game.level20.exit0.text2Statistics',
        text4: 'game.level20.exit0.text4',
        buttonText: 'game.level20.exit0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    popup: {
      image0: 'images/napov_prac.png',
      text: 'game.level20.popup.text',
      text2: 'game.level20.popup.text2',
      image: '',
      divider: true,
      text3: 'game.level20.popup.text3',
      image2: '',
      text4: 'game.level20.popup.text4',
      buttonText: 'game.level20.popup.buttonText',
      buttonBg: 'rgb(62, 177, 145)',
      buttonGradient: {
        color1: 'rgba(62, 131, 143, 0.5)',
        color2: 'rgba(54, 113, 125, 0.6)',
        color3: 'rgba(62, 131, 143, 0.7)',
        color4: 'rgba(54, 113, 125, 0.8)',
        speed: '10s'
      }
    }
  },
  {
    name: 'game.level21.name',
    image: '0.png',
    buttonText: 'game.level21.buttonText',
    theme: {
      primary: '#5493B4',
      darken: '#0F6E94'
    },
    gradient: {
      color1: 'rgba(84, 147, 180, 0.0)',
      color2: 'rgba(84, 147, 180, 0.0)',
      color3: 'rgba(15, 110, 148, 0.6)',
      color4: 'rgba(15, 110, 148, 0.6)',
      speed: '15s'
    },
    map: {
      mapBottom: -112,
      playerBottom: 117,
      playerLeft: 52,
      zoomX: -185,
      zoomY: 0
    },
    intro: [
      {
        surveyID: 'e7c1732d-c68b-4ca8-a979-3536414dbddd',
        asset: 'gif/20_EXIT_Meandy_rybar.gif',
        gifLength: 6000,
        text: 'game.level21.intro0.text',
        text2: 'game.level21.intro0.text2',
        divider: true,
        text3: 'game.level21.intro0.text3',
        text4: 'game.level21.intro0.text4',
        buttonText: 'game.level21.intro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    summary: [
      {
        asset: 'images/hruska.png',
        gifLength: 1000,
        text: 'game.level21.summary0.text',
        text2: 'game.level21.summary0.text2',
        divider: true,
        text3: 'game.level21.summary0.text3',
        text4: 'game.level21.summary0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level21.summary0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    exit: [
      {
        asset: 'gif/21_EXIT_rybar_vymotan.gif',
        gifLength: 1000,
        text: 'game.level21.exit0.text',
        text2: 'game.level21.exit0.text2',
        divider: true,
        text3: 'game.level21.exit0.text3',
        text4: 'game.level21.exit0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level21.exit0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ]
  },
  {
    name: 'game.level22.name',
    image: '0.png',
    buttonText: 'game.level22.buttonText',
    theme: {
      primary: '#734A4A',
      darken: '#40201F'
    },
    gradient: {
      color1: 'rgba(115, 74, 74, 0.0)',
      color2: 'rgba(115, 74, 74, 0.0)',
      color3: 'rgba(64, 32, 31, 0.6)',
      color4: 'rgba(64, 32, 31, 0.6)',
      speed: '15s'
    },
    map: {
      mapBottom: -112,
      playerBottom: 122,
      playerLeft: 26,
      zoomX: -160,
      zoomY: 0
    },
    intro: [
      {
        surveyID: '08fe5825-2336-43f5-af6e-10343c2e58c2',
        asset: 'gif/22_INTRO_rybar_pohosteni_EDIT.gif',
        gifLength: 5000,
        text: 'game.level22.intro0.text',
        text2: 'game.level22.intro0.text2',
        divider: true,
        text3: 'game.level22.intro0.text3',
        text4: 'game.level22.intro0.text4',
        buttonText: 'game.level22.intro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    summary: [
      {
        asset: 'gif/22_SUMARY_rybar_pohosteni_.gif',
        gifLength: 1000,
        text: 'game.level22.summary0.text',
        text2: 'game.level22.summary0.text2',
        divider: true,
        text3: 'game.level22.summary0.text3',
        image2Statistics: 'images/image_Statistic_pracovni.png',
        text2Statistics: 'game.level22.summary0.text2Statistics',
        text4: 'game.level22.summary0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level22.summary0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        },
        inventoryImage: 'images/kouzelne_padlo.png'
      }
    ],
    exit: [
      {
        asset: 'gif/22_EXIT_rybar_pohosteni_EDIT.gif',
        gifLength: 1000,
        text: 'game.level22.exit0.text',
        text2: 'game.level22.exit0.text2',
        divider: false,
        text3: 'game.level22.exit0.text3',
        image2Statistics: 'images/image_Statistic_pracovni.png',
        text2Statistics: 'game.level22.exit0.text2Statistics',
        text4: 'game.level22.exit0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level22.exit0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        },
        inventoryImage: 'images/kouzelne_padlo.png'
      }
    ]
  },
  {
    name: 'game.level23.name',
    image: '0.png',
    buttonText: 'game.level23.buttonText',
    theme: {
      primary: '#5493B4',
      darken: '#0F6E94'
    },
    gradient: {
      color1: 'rgba(84, 147, 180, 0.0)',
      color2: 'rgba(84, 147, 180, 0.0)',
      color3: 'rgba(15, 110, 148, 0.6)',
      color4: 'rgba(15, 110, 148, 0.6)',
      speed: '15s'
    },
    map: {
      mapBottom: -120,
      playerBottom: 142,
      playerLeft: 51,
      zoomX: -178,
      zoomY: 0
    },
    intro: [
      {
        surveyID: '368ba3b1-27b3-4a39-b1f6-3b3736e05373',
        asset: 'gif/23_INTRO_boure.gif',
        gifLength: 5000,
        text: 'game.level23.intro0.text',
        text2: 'game.level23.intro0.text2',
        divider: true,
        text3: 'game.level23.intro0.text3',
        text4: 'game.level23.intro0.text4',
        buttonText: 'game.level23.intro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    summary: [
      {
        asset: 'gif/23_Summary-Boure.gif',
        gifLength: 1000,
        text: 'game.level23.summary0.text',
        text2: 'game.level23.summary0.text2',
        divider: true,
        text3: 'game.level23.summary0.text3',
        text4: 'game.level23.summary0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level23.summary0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    exit: [
      {
        asset: 'gif/23_EXIT-Bouře.gif',
        gifLength: 1000,
        text: 'game.level23.exit0.text',
        text2: 'game.level23.exit0.text2',
        divider: true,
        text3: 'game.level23.exit0.text3',
        text4: 'game.level23.exit0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level23.exit0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    preIntro: [
      {
        asset: 'gif/23_PREINTRO_louceni_OK.gif',
        gifLength: 5300,
        text: 'game.level23.preIntro0.text',
        text2: 'game.level23.preIntro0.text2',
        divider: true,
        text3: 'game.level23.preIntro0.text3',
        text4: 'game.level23.preIntro0.text4',
        buttonText: 'game.level23.preIntro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ]
  },
  {
    name: 'game.level24.name',
    image: '0.png',
    buttonText: 'game.level24.buttonText',
    theme: {
      primary: '#5493B4',
      darken: '#0F6E94'
    },
    gradient: {
      color1: 'rgba(84, 147, 180, 0.0)',
      color2: 'rgba(84, 147, 180, 0.0)',
      color3: 'rgba(15, 110, 148, 0.6)',
      color4: 'rgba(15, 110, 148, 0.6)',
      speed: '15s'
    },
    map: {
      mapBottom: -146,
      playerBottom: 159,
      playerLeft: 64,
      zoomX: -370,
      zoomY: 0
    },
    intro: [
      {
        surveyID: 'db47b4b5-dc68-4fd5-b708-4d6f2a212bb9',
        asset: 'gif/24_Intro_domoroci.gif',
        gifLength: 5000,
        text: 'game.level24.intro0.text',
        text2: 'game.level24.intro0.text2',
        divider: true,
        text3: 'game.level24.intro0.text3',
        buttonText: 'game.level24.intro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    summary: [
      {
        asset: 'images/hruska.png',
        gifLength: 1000,
        text: 'game.level24.summary0.text',
        text2: 'game.level24.summary0.text2',
        divider: true,
        text3: 'game.level24.summary0.text3',
        text4: 'game.level24.summary0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level24.summary0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    exit: [
      {
        asset: 'gif/24_EXIT_Sipy_EDIT.gif',
        gifLength: 2000,
        text: 'game.level24.exit0.text',
        text2: 'game.level24.exit0.text2',
        divider: true,
        text3: 'game.level24.exit0.text3',
        image2Statistics: 'images/image_Statistic_pracovni.png',
        text4: 'game.level24.exit0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level24.exit0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    preIntro: [
      {
        asset: 'gif/24_PREINTRO_Domorodci.gif',
        gifLength: 5300,
        text: 'game.level24.preIntro0.text',
        text2: 'game.level24.preIntro0.text2',
        divider: true,
        text3: 'game.level24.preIntro0.text3',
        buttonText: 'game.level24.preIntro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ]
  },
  {
    name: 'game.level25.name',
    image: '0.png',
    buttonText: 'game.level25.buttonText',
    theme: {
      primary: '#0264A6',
      darken: '#003d75'
    },
    gradient: {
      color1: 'rgba(1, 88, 146, 0.0)',
      color2: 'rgba(1, 88, 146, 0.0)',
      color3: 'rgba(0, 52, 104, 0.6)',
      color4: 'rgba(0, 36, 71, 0.6)',
      speed: '15s'
    },
    map: {
      mapBottom: -160,
      playerBottom: 184,
      playerLeft: 9,
      zoomX: -50,
      zoomY: 0
    },
    intro: [
      {
        surveyID: 'e9779b32-14c8-44be-ae96-6b5857ca3c09',
        asset: 'gif/25_INTRO_plaz_priplouvani.gif',
        gifLength: 5000,
        text: 'game.level25.intro0.text',
        text2: 'game.level25.intro0.text2',
        divider: true,
        text3: 'game.level25.intro0.text3',
        buttonText: 'game.level25.intro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    summary: [
      {
        asset: 'images/25_SUMMARY_plaz_.png',
        gifLength: 1000,
        text: 'game.level25.summary0.text',
        text2: 'game.level25.summary0.text2',
        divider: true,
        text3: 'game.level25.summary0.text3',
        text4: 'game.level25.summary0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level25.summary0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    exit: [
      {
        asset: 'gif/25_EXIT_Plechovka.gif',
        gifLength: 1000,
        text: 'game.level25.exit0.text',
        text2: 'game.level25.exit0.text2',
        divider: true,
        text3: 'game.level25.exit0.text3',
        text4: 'game.level25.exit0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level25.exit0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    popup: {
      bgImage: 'udoli_340x540px.png',
      image0: '',
      text: 'game.level25.popup.text',
      text2: 'game.level25.popup.text2',
      image: 'images/rozhodovani_modal_90x90round.png',
      divider: false,
      text3: 'game.level25.popup.text3',
      image2: '',
      text4: 'game.level25.popup.text4',
      buttonText: 'game.level25.popup.buttonText'
    }
  },
  {
    name: 'game.level26.name',
    image: '0.png',
    buttonText: 'game.level26.buttonText',
    theme: {
      primary: '#3E838F',
      darken: '#29525E'
    },
    gradient: {
      color1: 'rgba(62, 131, 143, 0.0)',
      color2: 'rgba(62, 131, 143, 0.0)',
      color3: 'rgba(41, 82, 94, 0.6)',
      color4: 'rgba(41, 82, 94, 0.6)',
      speed: '15s'
    },
    map: {
      mapBottom: -182,
      playerBottom: 201,
      playerLeft: 31,
      zoomX: -50,
      zoomY: 0
    },
    intro: [
      {
        surveyID: 'a4b79932-ef78-4a21-b5b3-e6553a8740db',
        asset: 'gif/Intro_26_mravenci_EDIT.gif',
        gifLength: 5000,
        text: 'game.level26.intro0.text',
        text2: 'game.level26.intro0.text2',
        divider: true,
        text3: 'game.level26.intro0.text3',
        text4: 'game.level26.intro0.text4',
        buttonText: 'game.level26.intro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    summary: [
      {
        asset: 'images/hruska.png',
        gifLength: 1000,
        text: 'game.level26.summary0.text',
        text2: 'game.level26.summary0.text2',
        divider: true,
        text3: 'game.level26.summary0.text3',
        image2Statistics: 'images/image_Statistic_pracovni.png',
        text4: 'game.level26.summary0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level26.summary0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    exit: [
      {
        asset: 'gif/26_EXIT_ptaci_EDIT.gif',
        gifLength: 1000,
        text: 'game.level26.exit0.text',
        divider: true,
        text3: 'game.level26.exit0.text3',
        image2Statistics: 'images/image_Statistic_pracovni.png',
        text4: 'game.level26.exit0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level26.exit0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ]
  },
  {
    name: 'game.level27.name',
    image: '0.png',
    buttonText: 'game.level27.buttonText',
    theme: {
      primary: '#727189',
      darken: '#4D6AA1'
    },
    gradient: {
      color1: 'rgba(113, 114, 137, 0.0)',
      color2: 'rgba(113, 114, 137, 0.0)',
      color3: 'rgba(77, 106, 161, 0.6)',
      color4: 'rgba(77, 106, 161, 0.6)',
      speed: '15s'
    },
    map: {
      mapBottom: -198,
      playerBottom: 224,
      playerLeft: 45,
      zoomX: -160,
      zoomY: 120
    },
    intro: [
      {
        surveyID: '278a53ae-dfac-4ef4-a63a-53d42ffaca6f',
        asset: 'gif/27_INTRO_cesta_k_sopce_mnich_EDIT.gif',
        gifLength: 5000,
        text: 'game.level27.intro0.text',
        text2: 'game.level27.intro0.text2',
        divider: false,
        text3: 'game.level27.intro0.text3',
        buttonText: 'game.level27.intro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    summary: [
      {
        asset: 'images/hruska.png',
        gifLength: 1000,
        text: 'game.level27.summary0.text',
        text2: 'game.level27.summary0.text2',
        divider: true,
        text3: 'game.level27.summary0.text3',
        text4: 'game.level27.summary0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level27.summary0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    exit: [
      {
        asset: 'gif/27_INTRO_cesta_k_sopce_mnich_EDIT.gif',
        gifLength: 1000,
        text: 'game.level27.exit0.text',
        text2: 'game.level27.exit0.text2',
        divider: true,
        text3: 'game.level27.exit0.text3',
        text4: 'game.level27.exit0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level27.exit0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    preIntro: [
      {
        asset: 'gif/27_PRE_INTRO_fin_cesta_bez_Mnich_.gif',
        gifLength: 5300,
        text: 'game.level27.preIntro0.text',
        text2: 'game.level27.preIntro0.text2',
        divider: true,
        text3: 'game.level27.preIntro0.text3',
        buttonText: 'game.level27.preIntro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ]
  },
  {
    name: 'game.level28.name',
    image: '0.png',
    buttonText: 'game.level28.buttonText',
    theme: {
      primary: '#4C6EAE',
      darken: '#3E5A8E'
    },
    gradient: {
      color1: 'rgba(76, 110, 174, 0.0)',
      color2: 'rgba(76, 110, 174, 0.0)',
      color3: 'rgba(62, 90, 142, 0.6)',
      color4: 'rgba(62, 90, 142, 0.6)',
      speed: '15s'
    },
    map: {
      mapBottom: -209,
      playerBottom: 235,
      playerLeft: 41,
      zoomX: -170,
      zoomY: 40
    },
    intro: [
      {
        surveyID: '93cc05ea-4166-46bd-aacc-6d0bc352f13b',
        asset: 'gif/28_INTRO_ostrov_view_EDIT.gif',
        gifLength: 8500,
        text: 'game.level28.intro0.text',
        text2: 'game.level28.intro0.text2',
        divider: true,
        text3: 'game.level28.intro0.text3',
        text4: 'game.level28.intro0.text4',
        buttonText: 'game.level28.intro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        },
        head: 'game.level28.intro0.head'
      }
    ],
    summary: [
      {
        asset: 'gif/28_EXIT_ostrov-05_zare-2.gif',
        gifLength: 1000,
        text: 'game.level28.summary0.text',
        text2: 'game.level28.summary0.text2',
        divider: true,
        text3: 'game.level28.summary0.text3',
        text4: 'game.level28.summary0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level28.summary0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    exit: [
      {
        asset: 'gif/28_EXIT_ostrov-05_zare-2.gif',
        gifLength: 3000,
        text: 'game.level28.exit0.text',
        text2: 'game.level28.exit0.text2',
        divider: true,
        text3: 'game.level28.exit0.text3',
        text4: 'game.level28.exit0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level28.exit0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        },
        head: 'game.level28.exit0.head'
      }
    ],
    preIntro: [
      {
        asset: 'gif/28_PREINTRO_ostrov_view_EDIT.gif',
        gifLength: 1800,
        text: 'game.level28.preIntro0.text',
        text2: 'game.level28.preIntro0.text2',
        divider: true,
        text3: 'game.level28.preIntro0.text3',
        text4: 'game.level28.preIntro0.text4',
        buttonText: 'game.level28.preIntro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        },
        head: 'game.level28.preIntro0.head'
      }
    ]
  },
  {
    name: 'game.level29.name',
    image: '0.png',
    buttonText: 'game.level29.buttonText',
    theme: {
      primary: '#3E838F',
      darken: '#29525E'
    },
    gradient: {
      color1: 'rgba(62, 131, 143, 0.0)',
      color2: 'rgba(62, 131, 143, 0.0)',
      color3: 'rgba(41, 82, 94, 0.6)',
      color4: 'rgba(41, 82, 94, 0.6)',
      speed: '15s'
    },
    map: {
      mapBottom: -214,
      playerBottom: 226,
      playerLeft: 59,
      zoomX: -170,
      zoomY: 40
    },
    intro: [
      {
        surveyID: 'e4ab6b09-135c-4b26-a727-2c08b93c58ea',
        asset: 'gif/Intro_29_Mnich_pristup_EDIT.gif',
        gifLength: 5000,
        text: 'game.level29.intro0.text',
        text2: 'game.level29.intro0.text2',
        divider: false,
        text3: 'game.level29.intro0.text3',
        text4: 'game.level29.intro0.text4',
        buttonText: 'game.level29.intro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        },
        head: 'game.level29.intro0.head'
      }
    ],
    summary: [
      {
        asset: 'images/hruska.png',
        gifLength: 1000,
        text: 'game.level29.summary0.text',
        text2: 'game.level29.summary0.text2',
        divider: true,
        text3: 'game.level29.summary0.text3',
        text4: 'game.level29.summary0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level29.summary0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        },
        inventoryImage: 'images/moudra_kniha.png'
      }
    ],
    exit: [
      {
        asset: 'gif/Exit_29_Mnich_kniha_EDIT.gif',
        gifLength: 4000,
        text: 'game.level29.exit0.text',
        text2: 'game.level29.exit0.text2',
        divider: true,
        text3: 'game.level29.exit0.text3',
        text4: 'game.level29.exit0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level29.exit0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        },
        inventoryImage: 'images/moudra_kniha.png'
      }
    ],
    preIntro: [
      {
        asset: 'gif/29_PREINTRO_cesta_dolu.gif',
        gifLength: 3000,
        text: 'game.level29.preIntro0.text',
        text2: 'game.level29.preIntro0.text2',
        divider: true,
        text3: 'game.level29.preIntro0.text3',
        text4: 'game.level29.preIntro0.text4',
        buttonText: 'game.level29.preIntro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        },
        head: 'game.level29.preIntro0.head'
      }
    ]
  },
  {
    name: 'game.level30.name',
    image: '0.png',
    buttonText: 'game.level30.buttonText',
    theme: {
      primary: '#374B72',
      darken: '#2A3956'
    },
    gradient: {
      color1: 'rgba(55, 75, 114, 0.0)',
      color2: 'rgba(55, 75, 114, 0.0)',
      color3: 'rgba(42, 57, 86, 0.6)',
      color4: 'rgba(42, 57, 86, 0.6)',
      speed: '15s'
    },
    map: {
      mapBottom: -210,
      playerBottom: 218,
      playerLeft: 84,
      zoomX: -170,
      zoomY: 40
    },
    intro: [
      {
        surveyID: '7152611e-5aaa-486a-b630-91e30adbfc09',
        asset: 'gif/30_INTRO_plaz_kniha_EDIT.gif',
        gifLength: 5000,
        text: 'game.level30.intro0.text',
        text2: 'game.level30.intro0.text2',
        divider: true,
        text3: 'game.level30.intro0.text3',
        text4: 'game.level30.intro0.text4',
        buttonText: 'game.level30.intro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    summary: [
      {
        asset: 'images/hruska.png',
        gifLength: 1000,
        text: 'game.level30.summary0.text',
        text2: 'game.level30.summary0.text2',
        divider: true,
        text3: 'game.level30.summary0.text3',
        text4: 'game.level30.summary0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level30.summary0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    exit: [
      {
        asset: 'gif/30_EXIT_plaz_noc_EDIT.gif',
        gifLength: 1000,
        text: 'game.level30.exit0.text',
        text2: 'game.level30.exit0.text2',
        divider: true,
        text3: 'game.level30.exit0.text3',
        text4: 'game.level30.exit0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level30.exit0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ]
  },
  {
    name: 'game.level31.name',
    image: '0.png',
    buttonText: 'game.level31.buttonText',
    theme: {
      primary: '#0264A6',
      darken: '#003d75'
    },
    gradient: {
      color1: 'rgba(1, 88, 146, 0.0)',
      color2: 'rgba(1, 88, 146, 0.0)',
      color3: 'rgba(0, 52, 104, 0.6)',
      color4: 'rgba(0, 36, 71, 0.6)',
      speed: '15s'
    },
    map: {
      mapBottom: -217,
      playerBottom: 223,
      playerLeft: 85,
      zoomX: -170,
      zoomY: 40
    },
    intro: [
      {
        surveyID: 'f72d8fd9-c72d-4e42-a58c-5619edfd7458',
        asset: 'gif/31_INTRO_Krab_sen_EDIT.gif',
        gifLength: 6000,
        text: 'game.level31.intro0.text',
        text2: 'game.level31.intro0.text2',
        divider: true,
        text3: 'game.level31.intro0.text3',
        text4: 'game.level31.intro0.text4',
        buttonText: 'game.level31.intro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        },
        head: 'game.level31.intro0.head'
      }
    ],
    summary: [
      {
        asset: 'images/hruska.png',
        gifLength: 1000,
        text: 'game.level31.summary0.text',
        text2: 'game.level31.summary0.text2',
        divider: true,
        text3: 'game.level31.summary0.text3',
        text4: 'game.level31.summary0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level31.summary0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        },
        inventoryImage: 'images/vecny_ohen.png'
      }
    ],
    exit: [
      {
        asset: 'gif/31_EXIT_krab_eat.gif',
        gifLength: 2000,
        text: 'game.level31.exit0.text',
        text2: 'game.level31.exit0.text2',
        divider: true,
        text3: 'game.level31.exit0.text3',
        text4: 'game.level31.exit0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level31.exit0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        },
        head: 'game.level31.exit0.head',
        inventoryImage: 'images/vecny_ohen.png'
      }
    ],
    popup: {
      image0: 'images/napov_prac.png',
      text: 'game.level31.popup.text',
      text2: 'game.level31.popup.text2',
      image: '',
      divider: true,
      text3: 'game.level31.popup.text3',
      image2: '',
      text4: 'game.level31.popup.text4',
      buttonText: 'game.level31.popup.buttonText'
    }
  },
  {
    name: 'game.level32.name',
    image: '0.png',
    buttonText: 'game.level32.buttonText',
    theme: {
      primary: '#5493B4',
      darken: '#0F6E94'
    },
    gradient: {
      color1: 'rgba(84, 147, 180, 0.0)',
      color2: 'rgba(84, 147, 180, 0.0)',
      color3: 'rgba(15, 110, 148, 0.6)',
      color4: 'rgba(15, 110, 148, 0.6)',
      speed: '15s'
    },
    map: {
      mapBottom: -228,
      playerBottom: 233,
      playerLeft: 75,
      zoomX: -160,
      zoomY: 0
    },
    intro: [
      {
        surveyID: '6d907979-4993-4b57-9b37-e8a6610e20e7',
        asset: 'gif/32_INTRO_Ohen_zapaleni_v1.gif ',
        gifLength: 5000,
        text: 'game.level32.intro0.text',
        text2: 'game.level32.intro0.text2',
        divider: true,
        text3: 'game.level32.intro0.text3',
        text4: 'game.level32.intro0.text4',
        buttonText: 'game.level32.intro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    summary: [
      {
        asset: 'gif/32_EXIT_Ohen_H.gif',
        gifLength: 1000,
        text: 'game.level32.summary0.text',
        text2: 'game.level32.summary0.text2',
        divider: true,
        text3: 'game.level32.summary0.text3',
        text4: 'game.level32.summary0.text4',
        buttonText: 'game.level32.summary0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    exit: [
      {
        asset: 'gif/32_EXIT_Ohen.gif',
        gifLength: 1000,
        text: 'game.level32.exit0.text',
        text2: 'game.level32.exit0.text2',
        divider: true,
        text3: 'game.level32.exit0.text3',
        image2Statistics: 'images/image_Statistic_pracovni.png',
        text4: 'game.level32.exit0.text4',
        buttonText: 'game.level32.exit0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ]
  },
  {
    name: 'game.level33.name',
    image: '0.png',
    buttonText: 'game.level33.buttonText',
    theme: {
      primary: '#5493B4',
      darken: '#0F6E94'
    },
    gradient: {
      color1: 'rgba(84, 147, 180, 0.0)',
      color2: 'rgba(84, 147, 180, 0.0)',
      color3: 'rgba(15, 110, 148, 0.6)',
      color4: 'rgba(15, 110, 148, 0.6)',
      speed: '15s'
    },
    map: {
      mapBottom: -230,
      playerBottom: 236,
      playerLeft: 77,
      zoomX: -160,
      zoomY: 0
    },
    intro: [
      {
        surveyID: '9d9fe4ad-9a30-41f7-b0a0-b84a0bc49e61',
        asset: 'gif/33_INTRO_Ohen_v_dali_lod_H.gif',
        gifLength: 5000,
        text: 'game.level33.intro0.text',
        text2: 'game.level33.intro0.text2',
        divider: true,
        text3: 'game.level33.intro0.text3',
        text4: 'game.level33.intro0.text4',
        buttonText: 'game.level33.intro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    summary: [
      {
        asset: 'images/hruska.png',
        gifLength: 1000,
        text: 'game.level33.summary0.text',
        text2: 'game.level33.summary0.text2',
        divider: true,
        text3: 'game.level33.summary0.text3',
        text4: 'game.level33.summary0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level33.summary0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    exit: [
      {
        asset: 'gif/33_EXIT_lod_pristava.gif',
        gifLength: 1000,
        text: 'game.level33.exit0.text',
        text2: 'game.level33.exit0.text2',
        divider: true,
        text3: 'game.level33.exit0.text3',
        text4: 'game.level33.exit0.text4',
        text4Image: 'images/image_Statistic_pracovni.png',
        buttonText: 'game.level33.exit0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ]
  },
  {
    name: 'game.level34.name',
    image: '0.png',
    buttonText: 'game.level34.buttonText',
    theme: {
      primary: '#5493B4',
      darken: '#0F6E94'
    },
    gradient: {
      color1: 'rgba(84, 147, 180, 0.0)',
      color2: 'rgba(84, 147, 180, 0.0)',
      color3: 'rgba(15, 110, 148, 0.6)',
      color4: 'rgba(15, 110, 148, 0.6)',
      speed: '15s'
    },
    map: {
      mapBottom: -232,
      playerBottom: 240,
      playerLeft: 80,
      zoomX: -250,
      zoomY: 0
    },
    intro: [
      {
        asset: 'gif/Intro_34_Pirátka_stop_EDIT.gif',
        gifLength: 5000,
        text: 'game.level34.intro0.text',
        text2: 'game.level34.intro0.text2',
        divider: true,
        text3: 'game.level34.intro0.text3',
        text4: 'game.level34.intro0.text4',
        buttonText: 'game.level34.intro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    summary: [
      {
        asset: 'images/hruska.png',
        gifLength: 1000,
        text: 'game.level34.summary0.text',
        text2: 'game.level34.summary0.text2',
        divider: true,
        text3: 'game.level34.summary0.text3',
        buttonText: 'game.level34.summary0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(105, 71, 2, 0.8)',
          speed: '10s'
        }
      }
    ],
    exit: [
      {
        asset: 'gif/Exit_34_Piratka_zebrick_EDIT.gif',
        gifLength: 3000,
        text: 'game.level34.exit0.text',
        text2: 'game.level34.exit0.text2',
        divider: true,
        text3: 'game.level34.exit0.text3',
        buttonText: 'game.level34.exit0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ]
  },
  {
    name: 'game.level35.name',
    image: '0.png',
    buttonText: 'game.level35.buttonText',
    theme: {
      primary: '#734A4A',
      darken: '#40201F'
    },
    gradient: {
      color1: 'rgba(115, 74, 74, 0.0)',
      color2: 'rgba(115, 74, 74, 0.0)',
      color3: 'rgba(64, 32, 31, 0.6)',
      color4: 'rgba(64, 32, 31, 0.6)',
      speed: '15s'
    },
    map: {
      mapBottom: -236,
      playerBottom: 252,
      playerLeft: 58,
      zoomX: -30,
      zoomY: 0
    },
    intro: [
      {
        asset: 'gif/Intro_35_Loď_práce_EDIT.gif',
        gifLength: 5000,
        text: 'game.level35.intro0.text',
        text2: 'game.level35.intro0.text2',
        divider: true,
        text3: 'game.level35.intro0.text3',
        text4: 'game.level35.intro0.text4',
        buttonText: 'game.level35.intro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    summary: [
      {
        asset: 'images/hruska.png',
        gifLength: 1000,
        text: 'game.level35.summary0.text',
        text2: 'game.level35.summary0.text2',
        divider: true,
        text3: 'game.level35.summary0.text3',
        text4: 'game.level35.summary0.text4',
        buttonText: 'game.level35.summary0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        },
        inventoryImage: 'images/trus_stesti.png'
      }
    ],
    exit: [
      {
        asset: 'gif/Exit_35_Ptaci_trus_EDIT.gif',
        gifLength: 3500,
        text: 'game.level35.exit0.text',
        text2: 'game.level35.exit0.text2',
        divider: true,
        text3: 'game.level35.exit0.text3',
        image2: 'images/kanci_zub.png',
        text4: 'game.level35.exit0.text4',
        buttonText: 'game.level35.exit0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        },
        inventoryImage: 'images/trus_stesti.png'
      }
    ]
  },
  {
    name: 'game.level36.name',
    image: '0.png',
    buttonText: 'game.level36.buttonText',
    theme: {
      primary: '#5493B4',
      darken: '#0F6E94'
    },
    gradient: {
      color1: 'rgba(84, 147, 180, 0.0)',
      color2: 'rgba(84, 147, 180, 0.0)',
      color3: 'rgba(15, 110, 148, 0.6)',
      color4: 'rgba(15, 110, 148, 0.6)',
      speed: '15s'
    },
    map: {
      mapBottom: -261,
      playerBottom: 264,
      playerLeft: 64,
      zoomX: -30,
      zoomY: 0
    },
    intro: [
      {
        surveyID: '607fdc2f-3fb9-465d-b835-77fc15f4b6bf',
        asset: 'gif/36_PREINTRO_mesto.gif',
        gifLength: 5000,
        text: 'game.level36.intro0.text',
        text2: 'game.level36.intro0.text2',
        divider: true,
        text3: 'game.level36.intro0.text3',
        text4: 'game.level36.intro0.text4',
        buttonText: 'game.level36.intro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 1252, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    summary: [
      {
        asset: 'images/hruska.png',
        gifLength: 1000,
        text: 'game.level36.summary0.text',
        text2: 'game.level36.summary0.text2',
        divider: true,
        text3: 'game.level36.summary0.text3',
        text4: 'game.level36.summary0.text4',
        buttonText: 'game.level36.summary0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 1252, 0.8)',
          speed: '10s'
        }
      }
    ],
    exit: [
      {
        asset: 'gif/36_EXIT_majak.gif',
        gifLength: 3500,
        text: 'game.level36.exit0.text',
        text2: 'game.level36.exit0.text2',
        divider: true,
        text3: 'game.level36.exit0.text3',
        text4: 'game.level36.exit0.text4',
        buttonText: 'game.level36.exit0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ]
  },
  {
    name: 'game.level37.name',
    image: '0.png',
    buttonText: 'game.level37.buttonText',
    theme: {
      primary: '#727189',
      darken: '#4D6AA1'
    },
    gradient: {
      color1: 'rgba(113, 114, 137, 0.0)',
      color2: 'rgba(113, 114, 137, 0.0)',
      color3: 'rgba(77, 106, 161, 0.6)',
      color4: 'rgba(77, 106, 161, 0.6)',
      speed: '15s'
    },
    map: {
      mapBottom: -276,
      playerBottom: 276,
      playerLeft: 12,
      zoomX: -50,
      zoomY: 0
    },
    intro: [
      {
        surveyID: '607fdc2f-3fb9-465d-b835-77fc15f4b6bf',
        asset: 'gif/37_INTRO_clo.gif',
        gifLength: 5000,
        text: 'game.level37.intro0.text',
        text2: 'game.level37.intro0.text2',
        divider: true,
        text3: 'game.level37.intro0.text3',
        text4: 'game.level37.intro0.text4',
        buttonText: 'game.level37.intro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    summary: [
      {
        asset: 'gif/36_EXIT_clo.gif',
        gifLength: 1000,
        text: 'game.level37.summary0.text',
        text2: 'game.level37.summary0.text2',
        divider: true,
        text3: 'game.level37.summary0.text3',
        text4: 'game.level37.summary0.text4',
        buttonText: 'game.level37.summary0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    exit: [
      {
        asset: 'gif/36_EXIT_clo.gif',
        gifLength: 1000,
        text: 'game.level37.exit0.text',
        text2: 'game.level37.exit0.text2',
        divider: true,
        text3: 'game.level37.exit0.text3',
        text4: 'game.level37.exit0.text4',
        buttonText: 'game.level37.exit0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    popup: {
      bgImage: 'udoli_340x540px.png',
      image0: '',
      text: 'game.level37.popup.text',
      text2: 'game.level37.popup.text2',
      image: 'images/rozhodovani_modal_90x90round.png',
      divider: false,
      text3: 'game.level37.popup.text3',
      image2: '',
      text4: 'game.level37.popup.text4',
      buttonText: 'game.level37.popup.buttonText'
    }
  },
  {
    name: 'game.level38.name',
    image: '0.png',
    buttonText: 'game.level38.buttonText',
    theme: {
      primary: '#0264A6',
      darken: '#003d75'
    },
    gradient: {
      color1: 'rgba(1, 88, 146, 0.0)',
      color2: 'rgba(1, 88, 146, 0.0)',
      color3: 'rgba(0, 52, 104, 0.6)',
      color4: 'rgba(0, 36, 71, 0.6)',
      speed: '15s'
    },
    map: {
      mapBottom: -276,
      playerBottom: 292,
      playerLeft: 12,
      zoomX: -50,
      zoomY: 0
    },
    intro: [
      {
        surveyID: '607fdc2f-3fb9-465d-b835-77fc15f4b6bf',
        asset: 'gif/38_INTRO_pruvodce_prac.gif',
        gifLength: 5000,
        text: 'game.level38.intro0.text',
        text2: 'game.level38.intro0.text2',
        divider: true,
        text3: 'game.level38.intro0.text3',
        text4: 'game.level38.intro0.text4',
        buttonText: 'game.level38.intro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    summary: [
      {
        asset: 'gif/38_SUMMARY_pruvodce.gif',
        gifLength: 1000,
        text: 'game.level38.summary0.text',
        text2: 'game.level38.summary0.text2',
        divider: true,
        text3: 'game.level38.summary0.text3',
        text4: 'game.level38.summary0.text4',
        buttonText: 'game.level38.summary0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    exit: [
      {
        asset: 'gif/38_EXIT_pruvodce.gif',
        gifLength: 1000,
        text: 'game.level38.exit0.text',
        text2: 'game.level38.exit0.text2',
        divider: true,
        text3: 'game.level38.exit0.text3',
        text4: 'game.level38.exit0.text4',
        buttonText: 'game.level38.exit0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 1252, 0.8)',
          speed: '10s'
        }
      }
    ]
  },
  {
    name: 'game.level39.name',
    image: '0.png',
    buttonText: 'game.level39.buttonText',
    theme: {
      primary: '#0264A6',
      darken: '#003d75'
    },
    gradient: {
      color1: 'rgba(1, 88, 146, 0.0)',
      color2: 'rgba(1, 88, 146, 0.0)',
      color3: 'rgba(0, 52, 104, 0.6)',
      color4: 'rgba(0, 36, 71, 0.6)',
      speed: '15s'
    },
    map: {
      mapBottom: -287,
      playerBottom: 309,
      playerLeft: 14,
      zoomX: -370,
      zoomY: 0
    },
    intro: [
      {
        surveyID: '607fdc2f-3fb9-465d-b835-77fc15f4b6bf',
        asset: 'gif/39_INTRO_plaz.gif',
        gifLength: 5000,
        text: 'game.level39.intro0.text',
        text2: 'game.level39.intro0.text2',
        divider: true,
        text3: 'game.level39.intro0.text3',
        text4: 'game.level39.intro0.text4',
        buttonText: 'game.level39.intro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(154, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    summary: [
      {
        asset: 'gif/39_SUMMARY_malirka1.gif',
        gifLength: 1000,
        text: 'game.level39.summary0.text',
        text2: 'game.level39.summary0.text2',
        divider: true,
        text3: 'game.level39.summary0.text3',
        text4: 'game.level39.summary0.text4',
        buttonText: 'game.level39.summary0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    exit: [
      {
        asset: 'gif/39_EXIT_malirka.gif',
        gifLength: 1000,
        text: 'game.level39.exit0.text',
        text2: 'game.level39.exit0.text2',
        divider: true,
        text3: 'game.level39.exit0.text3',
        text4: 'game.level39.exit0.text4',
        buttonText: 'game.level39.exit0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ]
  },
  {
    name: 'game.level40.name',
    image: '0.png',
    buttonText: 'game.level40.buttonText',
    theme: {
      primary: '#3E838F',
      darken: '#29525E'
    },
    gradient: {
      color1: 'rgba(62, 131, 143, 0.0)',
      color2: 'rgba(62, 131, 143, 0.0)',
      color3: 'rgba(41, 82, 94, 0.6)',
      color4: 'rgba(41, 82, 94, 0.6)',
      speed: '15s'
    },
    map: {
      mapBottom: -284,
      playerBottom: 304,
      playerLeft: 23,
      zoomX: -250,
      zoomY: 0
    },
    intro: [
      {
        surveyID: '607fdc2f-3fb9-465d-b835-77fc15f4b6bf',
        asset: 'gif/40_INTRO_zahrada.gif',
        gifLength: 5000,
        text: 'game.level40.intro0.text',
        text2: 'game.level40.intro0.text2',
        divider: true,
        text3: 'game.level40.intro0.text3',
        text4: 'game.level40.intro0.text4',
        buttonText: 'game.level40.intro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    summary: [
      {
        asset: 'gif/40_EXIT_uprava.gif',
        gifLength: 1000,
        text: 'game.level40.summary0.text',
        text2: 'game.level40.summary0.text2',
        divider: true,
        text3: 'game.level40.summary0.text3',
        text4: 'game.level40.summary0.text4',
        buttonText: 'game.level40.summary0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    exit: [
      {
        asset: 'gif/40_EXIT_uprava.gif',
        gifLength: 1000,
        text: 'game.level40.exit0.text',
        text2: 'game.level40.exit0.text2',
        divider: true,
        text3: 'game.level40.exit0.text3',
        text4: 'game.level40.exit0.text4',
        buttonText: 'game.level40.exit0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ]
  },
  {
    name: 'game.level41.name',
    image: '0.png',
    buttonText: 'game.level41.buttonText',
    theme: {
      primary: '#374B72',
      darken: '#2A3956'
    },
    gradient: {
      color1: 'rgba(55, 75, 114, 0.0)',
      color2: 'rgba(55, 75, 114, 0.0)',
      color3: 'rgba(42, 57, 86, 0.6)',
      color4: 'rgba(42, 57, 86, 0.6)',
      speed: '15s'
    },
    map: {
      mapBottom: -292,
      playerBottom: 303,
      playerLeft: 57,
      zoomX: -250,
      zoomY: 0
    },
    intro: [
      {
        surveyID: 'db0611a9-ce5c-4b99-bf34-8e6bc9bf1bd3',
        asset: 'gif/41_INTRO_bar.gif',
        gifLength: 5000,
        text: 'game.level41.intro0.text',
        text2: 'game.level41.intro0.text2',
        divider: true,
        text3: 'game.level41.intro0.text3',
        text4: 'game.level41.intro0.text4',
        buttonText: 'game.level41.intro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    summary: [
      {
        asset: 'gif/41_SUMMARY_bar.gif',
        gifLength: 1000,
        text: 'game.level41.summary0.text',
        text2: 'game.level41.summary0.text2',
        divider: true,
        text3: 'game.level41.summary0.text3',
        text4: 'game.level41.summary0.text4',
        buttonText: 'game.level41.summary0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    exit: [
      {
        asset: 'gif/41_EXIT_Bar.gif',
        gifLength: 1000,
        text: 'game.level41.exit0.text',
        text2: 'game.level41.exit0.text2',
        divider: true,
        text3: 'game.level41.exit0.text3',
        text4: 'game.level41.exit0.text4',
        buttonText: 'game.level41.exit0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    preIntro: [
      {
        asset: 'gif/41_PREINTRO_BAR.gif',
        gifLength: 5300,
        text: 'game.level41.preIntro0.text',
        text2: 'game.level41.preIntro0.text2',
        divider: true,
        text3: 'game.level41.preIntro0.text3',
        text4: 'game.level41.preIntro0.text4',
        buttonText: 'game.level41.preIntro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ]
  },
  {
    name: 'game.level42.name',
    image: '0.png',
    buttonText: 'game.level42.buttonText',
    theme: {
      primary: '#374B72',
      darken: '#2A3956'
    },
    gradient: {
      color1: 'rgba(55, 75, 114, 0.0)',
      color2: 'rgba(55, 75, 114, 0.0)',
      color3: 'rgba(42, 57, 86, 0.6)',
      color4: 'rgba(42, 57, 86, 0.6)',
      speed: '15s'
    },
    map: {
      mapBottom: -296,
      playerBottom: 311,
      playerLeft: 73,
      zoomX: -250,
      zoomY: 0
    },
    intro: [
      {
        surveyID: '6026d13f-ccc7-47e4-bbaa-2a60593ae2d6',
        asset: 'gif/42_INTRO_Kino.gif',
        gifLength: 5000,
        text: 'game.level42.intro0.text',
        text2: 'game.level42.intro0.text2',
        divider: true,
        text3: 'game.level42.intro0.text3',
        text4: 'game.level42.intro0.text4',
        buttonText: 'game.level42.intro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    summary: [
      {
        asset: 'gif/42_EXIT_Kino.gif',
        gifLength: 1000,
        text: 'game.level42.summary0.text',
        text2: 'game.level42.summary0.text2',
        divider: true,
        text3: 'game.level42.summary0.text3',
        text4: 'game.level42.summary0.text4',
        buttonText: 'game.level42.summary0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    exit: [
      {
        asset: 'gif/42_EXIT_Kino.gif',
        gifLength: 1000,
        text: 'game.level42.exit0.text',
        text2: 'game.level42.exit0.text2',
        divider: true,
        text3: 'game.level42.exit0.text3',
        text4: 'game.level42.exit0.text4',
        buttonText: 'game.level42.exit0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    preIntro: [
      {
        asset: 'gif/42_PREINTRO_planetarium.gif',
        gifLength: 5300,
        text: 'game.level42.preIntro0.text',
        text2: 'game.level42.preIntro0.text2',
        divider: true,
        text3: 'game.level42.preIntro0.text3',
        text4: 'game.level42.preIntro0.text4',
        buttonText: 'game.level42.preIntro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ]
  },
  {
    name: 'game.level43.name',
    image: '0.png',
    buttonText: 'game.level43.buttonText',
    theme: {
      primary: '#374B72',
      darken: '#2A3956'
    },
    gradient: {
      color1: 'rgba(55, 75, 114, 0.0)',
      color2: 'rgba(55, 75, 114, 0.0)',
      color3: 'rgba(42, 57, 86, 0.6)',
      color4: 'rgba(42, 57, 86, 0.6)',
      speed: '15s'
    },
    map: {
      mapBottom: -296,
      playerBottom: 309,
      playerLeft: 86,
      zoomX: -250,
      zoomY: 0
    },
    intro: [
      {
        surveyID: 'db0611a9-ce5c-4b99-bf34-8e6bc9bf1bd3',
        asset: 'gif/43_INTRO_Zed_.gif',
        gifLength: 5000,
        text: 'game.level43.intro0.text',
        text2: 'game.level43.intro0.text2',
        divider: true,
        text3: 'game.level43.intro0.text3',
        text4: 'game.level43.intro0.text4',
        buttonText: 'game.level43.intro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    summary: [
      {
        asset: 'gif/43_INTRO_Zed_.gif',
        gifLength: 1000,
        text: 'game.level43.summary0.text',
        text2: 'game.level43.summary0.text2',
        divider: true,
        text3: 'game.level43.summary0.text3',
        text4: 'game.level43.summary0.text4',
        buttonText: 'game.level43.summary0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    exit: [
      {
        asset: 'gif/42_EXIT_hotel.gif',
        gifLength: 1000,
        text: 'game.level43.exit0.text',
        text2: 'game.level43.exit0.text2',
        divider: true,
        text3: 'game.level43.exit0.text3',
        text4: 'game.level43.exit0.text4',
        buttonText: 'game.level43.exit0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ]
  },
  {
    name: 'game.level44.name',
    image: '0.png',
    buttonText: 'game.level44.buttonText',
    theme: {
      primary: '#0264A6',
      darken: '#003d75'
    },
    gradient: {
      color1: 'rgba(1, 88, 146, 0.0)',
      color2: 'rgba(1, 88, 146, 0.0)',
      color3: 'rgba(0, 52, 104, 0.6)',
      color4: 'rgba(0, 36, 71, 0.6)',
      speed: '15s'
    },
    map: {
      mapBottom: -273,
      playerBottom: 284,
      playerLeft: 81,
      zoomX: -250,
      zoomY: 0
    },
    intro: [
      {
        surveyID: '0510c51f-ce70-4410-a2e6-1dfa8d075456',
        asset: 'gif/44_INTRO_piedestal.gif',
        gifLength: 5000,
        text: 'game.level44.intro0.text',
        text2: 'game.level44.intro0.text2',
        divider: true,
        text3: 'game.level44.intro0.text3',
        text4: 'game.level44.intro0.text4',
        buttonText: 'game.level44.intro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    summary: [
      {
        asset: 'gif/44_SUMMARY_Piedestal.gif',
        gifLength: 1000,
        text: 'game.level44.summary0.text',
        text2: 'game.level44.summary0.text2',
        divider: true,
        text3: 'game.level44.summary0.text3',
        text4: 'game.level44.summary0.text4',
        buttonText: 'game.level44.summary0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    exit: [
      {
        asset: 'gif/44_EXIT_Piedestal.gif',
        gifLength: 1000,
        text: 'game.level44.exit0.text',
        text2: 'game.level44.exit0.text2',
        divider: true,
        text3: 'game.level44.exit0.text3',
        text4: 'game.level44.exit0.text4',
        buttonText: 'game.level44.exit0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ]
  },
  {
    name: 'game.level45.name',
    image: '0.png',
    buttonText: 'game.level45.buttonText',
    theme: {
      primary: '#A36B99',
      darken: '#885890'
    },
    gradient: {
      color1: 'rgba(163, 107, 153, 0.0)',
      color2: 'rgba(163, 107, 153, 0.0)',
      color3: 'rgba(136, 89, 128, 0.6)',
      color4: 'rgba(136, 89, 128, 0.6)',
      speed: '15s'
    },
    map: {
      mapBottom: -277,
      playerBottom: 290,
      playerLeft: 29,
      zoomX: -250,
      zoomY: 0
    },
    intro: [
      {
        surveyID: '607fdc2f-3fb9-465d-b835-77fc15f4b6bf',
        asset: 'gif/45_INTRO_kavarna.gif',
        gifLength: 5000,
        text: 'game.level45.intro0.text',
        text2: 'game.level45.intro0.text2',
        divider: true,
        text3: 'game.level45.intro0.text3',
        text4: 'game.level45.intro0.text4',
        buttonText: 'game.level45.intro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    summary: [
      {
        asset: 'images/hruska.png',
        gifLength: 1000,
        text: 'game.level45.summary0.text',
        text2: 'game.level45.summary0.text2',
        divider: true,
        text3: 'game.level45.summary0.text3',
        text4: 'game.level45.summary0.text4',
        buttonText: 'game.level45.summary0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    exit: [
      {
        asset: 'images/hruska.png',
        gifLength: 1000,
        text: 'game.level45.exit0.text',
        text2: 'game.level45.exit0.text2',
        divider: true,
        text3: 'game.level45.exit0.text3',
        text4: 'game.level45.exit0.text4',
        buttonText: 'game.level45.exit0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ]
  },
  {
    name: 'game.level46.name',
    image: '0.png',
    buttonText: 'game.level46.buttonText',
    theme: {
      primary: '#3E8F74',
      darken: '#2E6759'
    },
    gradient: {
      color1: 'rgba(62, 143, 116, 0.0)',
      color2: 'rgba(62, 143, 116, 0.0)',
      color3: 'rgba(46, 103, 89, 0.6)',
      color4: 'rgba(46, 103, 89, 0.6)',
      speed: '15s'
    },
    map: {
      mapBottom: -298,
      playerBottom: 314,
      playerLeft: 36,
      zoomX: -250,
      zoomY: 0
    },
    intro: [
      {
        surveyID: '607fdc2f-3fb9-465d-b835-77fc15f4b6bf',
        asset: 'gif/46_INTRO_Cedule.gif',
        gifLength: 5000,
        text: 'game.level46.intro0.text',
        text2: 'game.level46.intro0.text2',
        divider: true,
        text3: 'game.level46.intro0.text3',
        text4: 'game.level46.intro0.text4',
        buttonText: 'game.level46.intro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    summary: [
      {
        asset: 'images/46_SUMMARY_mesto.png',
        gifLength: 1000,
        text: 'game.level46.summary0.text',
        text2: 'game.level46.summary0.text2',
        divider: true,
        text3: 'game.level46.summary0.text3',
        text4: 'game.level46.summary0.text4',
        buttonText: 'game.level46.summary0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    exit: [
      {
        asset: 'gif/46_EXIT_mesto.gif',
        gifLength: 1000,
        text: 'game.level46.exit0.text',
        text2: 'game.level46.exit0.text2',
        divider: true,
        text3: 'game.level46.exit0.text3',
        text4: 'game.level46.exit0.text4',
        buttonText: 'game.level46.exit0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ]
  },
  {
    name: 'game.level47.name',
    image: '0.png',
    buttonText: 'game.level47.buttonText',
    theme: {
      primary: '#3E838F',
      darken: '#29525E'
    },
    gradient: {
      color1: 'rgba(62, 131, 143, 0.0)',
      color2: 'rgba(62, 131, 143, 0.0)',
      color3: 'rgba(41, 82, 94, 0.6)',
      color4: 'rgba(41, 82, 94, 0.6)',
      speed: '15s'
    },
    map: {
      mapBottom: -305,
      playerBottom: 325,
      playerLeft: 37,
      zoomX: -250,
      zoomY: 0
    },
    intro: [
      {
        surveyID: '607fdc2f-3fb9-465d-b835-77fc15f4b6bf',
        asset: 'gif/47_INTRO_skladka.gif',
        gifLength: 5000,
        text: 'game.level47.intro0.text',
        text2: 'game.level47.intro0.text2',
        divider: true,
        text3: 'game.level47.intro0.text3',
        text4: 'game.level47.intro0.text4',
        buttonText: 'game.level47.intro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    summary: [
      {
        asset: 'gif/47_INTRO_skladka.gif',
        gifLength: 1000,
        text: 'game.level47.summary0.text',
        text2: 'game.level47.summary0.text2',
        divider: true,
        text3: 'game.level47.summary0.text3',
        text4: 'game.level47.summary0.text4',
        buttonText: 'game.level47.summary0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    exit: [
      {
        asset: 'gif/47_EXIT_zavora.gif',
        gifLength: 1000,
        text: 'game.level47.exit0.text',
        text2: 'game.level47.exit0.text2',
        divider: true,
        text3: 'game.level47.exit0.text3',
        text4: 'game.level47.exit0.text4',
        buttonText: 'game.level47.exit0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ]
  },
  {
    name: 'game.level48.name',
    image: '0.png',
    buttonText: 'game.level48.buttonText',
    theme: {
      primary: '#727189',
      darken: '#8F8EA3'
    },
    gradient: {
      color1: 'rgba(113, 114, 137, 0.0)',
      color2: 'rgba(113, 114, 137, 0.0)',
      color3: 'rgba(143, 142, 163, 0.6)',
      color4: 'rgba(143, 142, 163, 0.6)',
      speed: '15s'
    },
    map: {
      mapBottom: -311,
      playerBottom: 332,
      playerLeft: 56,
      zoomX: -250,
      zoomY: 0
    },
    intro: [
      {
        surveyID: '607fdc2f-3fb9-465d-b835-77fc15f4b6bf',
        asset: 'gif/48_INTRO_uzina_konfliktu_hodnot.gif',
        gifLength: 5000,
        text: 'game.level48.intro0.text',
        text2: 'game.level48.intro0.text2',
        divider: true,
        text3: 'game.level48.intro0.text3',
        text4: 'game.level48.intro0.text4',
        buttonText: 'game.level48.intro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    summary: [
      {
        asset: 'gif/48_EXIT_uzina_konfliktu_hodnot.gif',
        gifLength: 1000,
        text: 'game.level48.summary0.text',
        text2: 'game.level48.summary0.text2',
        divider: true,
        text3: 'game.level48.summary0.text3',
        text4: 'game.level48.summary0.text4',
        buttonText: 'game.level48.summary0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    exit: [
      {
        asset: 'gif/48_EXIT_uzina_konfliktu_hodnot.gif',
        gifLength: 1000,
        text: 'game.level48.exit0.text',
        text2: 'game.level48.exit0.text2',
        divider: true,
        text3: 'game.level48.exit0.text3',
        text4: 'game.level48.exit0.text4',
        buttonText: 'game.level48.exit0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ]
  },
  {
    name: 'game.level49.name',
    image: '0.png',
    buttonText: 'game.level49.buttonText',
    theme: {
      primary: '#727189',
      darken: '#4D6AA1'
    },
    gradient: {
      color1: 'rgba(113, 114, 137, 0.0)',
      color2: 'rgba(113, 114, 137, 0.0)',
      color3: 'rgba(77, 106, 161, 0.6)',
      color4: 'rgba(77, 106, 161, 0.6)',
      speed: '15s'
    },
    map: {
      mapBottom: -324,
      playerBottom: 342,
      playerLeft: 46,
      zoomX: -250,
      zoomY: 0
    },
    intro: [
      {
        surveyID: '607fdc2f-3fb9-465d-b835-77fc15f4b6bf',
        asset: 'gif/49_INTRO_Rokle-nadeje.gif',
        gifLength: 5000,
        text: 'game.level49.intro0.text',
        text2: 'game.level49.intro0.text2',
        divider: true,
        text3: 'game.level49.intro0.text3',
        text4: 'game.level49.intro0.text4',
        buttonText: 'game.level49.intro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    summary: [
      {
        asset: 'gif/49_EXIT_Rokle-nadeje.gif',
        gifLength: 1000,
        text: 'game.level49.summary0.text',
        text2: 'game.level49.summary0.text2',
        divider: true,
        text3: 'game.level49.summary0.text3',
        text4: 'game.level49.summary0.text4',
        buttonText: 'game.level49.summary0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    exit: [
      {
        asset: 'gif/49_EXIT_Rokle-nadeje.gif',
        gifLength: 1000,
        text: 'game.level49.exit0.text',
        text2: 'game.level49.exit0.text2',
        divider: true,
        text3: 'game.level49.exit0.text3',
        text4: 'game.level49.exit0.text4',
        buttonText: 'game.level49.exit0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ]
  },
  {
    name: 'game.level50.name',
    image: '0.png',
    buttonText: 'game.level50.buttonText',
    theme: {
      primary: '#727189',
      darken: '#8F8EA3'
    },
    gradient: {
      color1: 'rgba(113, 114, 137, 0.0)',
      color2: 'rgba(113, 114, 137, 0.0)',
      color3: 'rgba(143, 142, 163, 0.6)',
      color4: 'rgba(143, 142, 163, 0.6)',
      speed: '15s'
    },
    map: {
      mapBottom: -332,
      playerBottom: 347,
      playerLeft: 29,
      zoomX: -250,
      zoomY: 0
    },
    intro: [
      {
        surveyID: '607fdc2f-3fb9-465d-b835-77fc15f4b6bf',
        asset: 'gif/Honzagif12-hostinsky2.gif',
        gifLength: 5000,
        text: 'game.level50.intro0.text',
        text2: 'game.level50.intro0.text2',
        divider: true,
        text3: 'game.level50.intro0.text3',
        text4: 'game.level50.intro0.text4',
        buttonText: 'game.level50.intro0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    summary: [
      {
        asset: 'images/hruska.png',
        gifLength: 1000,
        text: 'game.level50.summary0.text',
        text2: 'game.level50.summary0.text2',
        divider: true,
        text3: 'game.level50.summary0.text3',
        text4: 'game.level50.summary0.text4',
        buttonText: 'game.level50.summary0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ],
    exit: [
      {
        asset: 'images/hruska.png',
        gifLength: 1000,
        text: 'game.level50.exit0.text',
        text2: 'game.level50.exit0.text2',
        divider: true,
        text3: 'game.level50.exit0.text3',
        text4: 'game.level50.exit0.text4',
        buttonText: 'game.level50.exit0.buttonText',
        buttonBg: 'rgb(62, 177, 145)',
        buttonGradient: {
          color1: 'rgba(62, 131, 143, 0.5)',
          color2: 'rgba(54, 113, 125, 0.6)',
          color3: 'rgba(62, 131, 143, 0.7)',
          color4: 'rgba(54, 113, 125, 0.8)',
          speed: '10s'
        }
      }
    ]
  }
];
