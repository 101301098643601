import * as React from 'react';

const SVGarrow: React.FC<{stroke?: string} & {}> = ({ stroke = '#fff', ...props }) => {
  return (
    <svg
      version='1.2'
      baseProfile='tiny-ps'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 33 100'
      width='33'
      height='80'
      {...props}
    ><title>image</title>
      <g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <path d='M30 98L5 50L30 2' fill='transparent' stroke={stroke} strokeWidth={3} />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SVGarrow;
