import { IGameStatus } from 'config/api/game';
import { useEffect, useState } from 'react';
import { ILifeValueDetail } from 'store/Entities/LifeValues';
import { useFetchGameStatus, useFetchJournal, useFetchLifeValueDetail } from 'utils/useQuery/queryFetchHelpers';

export const useGetLifeValueDetail = (value: string) => {
  const [detail, setDetail] = useState<ILifeValueDetail | null>(null);

  const testQuery = useFetchLifeValueDetail(value);
  
  useEffect(() => {
    if (testQuery.isSuccess) {
      setDetail(testQuery.data);
    }
  }, [testQuery.data, testQuery.isSuccess]);

  return detail;
};

export const useGetGameStatus = (enabled = true) => {
  const [status, setStatus] = useState<IGameStatus | null>(null);

  const testQuery = useFetchGameStatus(enabled);

  useEffect(() => {
    if (testQuery.isSuccess) {
      setStatus(testQuery.data);
    }
  }, [testQuery.data, testQuery.isSuccess]);

  return status;
};

export const useGetResultId = (): string | null | undefined => {
  const [resultId, setResultId] = useState<string | null>(null);
  const journalQuery = useFetchJournal();

  useEffect(() => {    
    if (journalQuery.isSuccess) {
      const journal = journalQuery.data;
      journal.mains.length > 0 && setResultId(journal.mains[0].id);
    }
  }, [journalQuery.data, journalQuery.isSuccess]);

  return resultId;
};
