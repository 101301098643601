import React from 'react';
import StatisticsPage from '../index';
import { Flex, Img, Box, Tag, Paragraph } from '@xcorejs/ui';
import CollectingData from '../components/CollectingData';
import { FormattedHTMLMessage } from 'react-intl';

const World = () => {
  return ( 
    <StatisticsPage>
      <Flex marginX='auto' flexDirection='column' alignItems='center' minWidth='90vw'>
        <Box position='relative' mt={{ _:'4rem' }}>
          <Img src='/img/atlas/eticiameter.svg' alt='' />

          <Tag
            borderRadius='0.5rem'
            bg='black'
            position='absolute' 
            transform='scale(0.7)'
            right='-4rem'
            top='-1.5rem'>Připravujeme</Tag>
        </Box>

        <Paragraph mt='2rem'>
          <FormattedHTMLMessage id='statistics.world.text' />
        </Paragraph>
        
        <Flex
          justifyContent='center'
          alignItems='center'
          overflow='hidden'
          height={{ _: '25rem', md: '35rem' }}
          width='100%'
          marginTop={{ _:'2rem', md:'4rem' }}
          position='relative'
        >
          <Img
            src='/img/atlas/eticiameter-bg.png'
            alt=''
            opacity={0.3}
            position='absolute'
            height='100%'
            maxWidth='unset'
          />

          <Img
            src='/img/statistics/world/mobil.png'
            alt=''
            height='100%'
            maxWidth='unset'
            zIndex={1}
            display={{ _: 'block', md: 'none' }}
          />

          <Img
            src='/img/statistics/world/desktop.png'
            alt=''
            height='100%'
            maxWidth='unset'
            zIndex={1}
            display={{ _: 'none', md: 'block' }}
          />
        </Flex>

        <Flex justifyContent='center' paddingX='2rem' mt={{ _: '2rem', md:'4rem' }}>
          <CollectingData />
        </Flex>

      </Flex>
    </StatisticsPage>
  );
};
 
export default World;