import { useState, useEffect } from 'react';
import { debounce } from 'lodash';

type useGetScrollPositionProps = {
  ulRef: React.RefObject<HTMLUListElement>;
};

export const useGetScrollPosition = ({ ulRef }: useGetScrollPositionProps) => {
  const [position, setPosition] = useState<number>(0);

  const handleScroll = (ref: HTMLUListElement | null) => {
    if (ref) {
      const { scrollLeft, scrollWidth, clientWidth } = ref;
      const maxScrollLeft = scrollWidth - clientWidth;
      const newPosition = scrollLeft / maxScrollLeft * 100;
      setPosition(newPosition);
    }
  };

  useEffect(() => {
    let currentRef = ulRef.current;
    const debouncedHandleScroll = debounce(handleScroll, 50); // ms
    
    if (currentRef) {
      currentRef.addEventListener('scroll', () => debouncedHandleScroll(currentRef));
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener('scroll', () => debouncedHandleScroll(currentRef));
      }
    };
  }, [ulRef]);


  return position ;
};
