import * as React from 'react';
import { FC } from 'react';
import SVGresultTwo from '../../atoms/SVGIcons/result/SVGresultTwo';
import { FormattedMessage } from 'react-intl';
import { StyledHeadingValuesComparison } from './style';

const HeadingValuesComparison: FC = () => (
  <StyledHeadingValuesComparison>
    <SVGresultTwo />
    <FormattedMessage id='vlc.visual.heading' />
  </StyledHeadingValuesComparison>
);

export default HeadingValuesComparison;
