import * as React from 'react';
import { IProfile, IValueTestCompare } from '../data';
import ValuesGraph from '../../../../../molecules/Results/Graph/ValuesGraph';
import { Col, Row } from 'react-styled-flexboxgrid';
import { GraphLegendCircle, CardList } from '../../../../../organisms/PartnerTest/Result/styles';
import { CustomText, SectionText } from '../../../../../atoms/Text';
import { FormattedMessage } from 'react-intl';
import { IStringMap } from '../../../../../../utils';
import { Heading2 } from '../../../../../atoms/Headings';
import { StyledResultBoxes } from '../../../../../molecules/TestResult/AnswerBox/styles';
import { IValueTestResultDetail } from 'config/api/valueTestResult';
import PopupIcon from '../../../../../organisms/MainPopup/PopupIcon';
import SimpleCard from '../../../../../atoms/Cards/SimpleCard';
import { Box } from '@xcorejs/ui';
import { Flex } from '@xcorejs/ui';
import FAQPopup from '../../../../../atoms/Popups/FAQPopup';
import { ValueTestResultGraph } from '../../../../../atoms/Popups/FAQPopup/popups';
import Fade from 'react-reveal/Fade';
import { GraphLegend } from 'components/organisms/PartnerTest/Result/graphLegend';

interface IData {
  practicalMatch: IValueTestCompare;
  profiles: IStringMap<IProfile>;
  graphCharacters: IStringMap<IValueTestResultDetail>;
}

const TogetherPracticalMatch: React.FC<IData> = ({ practicalMatch, graphCharacters, profiles: mainProfiles }) => {
  const [state, setState] = React.useState<{ character: boolean }>({
    character: true,
  });
  const practicalCharacteristics = practicalMatch.practicalCharacteristics;
  const profiles = Object.keys(mainProfiles);
  const practicalResults = practicalMatch.practicalResults;
  return (
    <>
      <StyledResultBoxes className='together'>
        <Row>
          <Col xs={12} md={5} mdOffset={1} lg={4} lgOffset={2}>
            {state.character ? (
              <Fade bottom ssrReveal duration={1000} distance={'5rem'}>
                <div>
                  <Heading2 color='yellow'>
                    <FormattedMessage id={'results.partners.final.subheading.agreements'} />
                  </Heading2>

                  <CustomText>
                    <FormattedMessage
                      id={
                        (practicalCharacteristics.length <= 10
                          ? 'results.partners.together.practicalMatch' + practicalCharacteristics.length
                          : 'results.partners.together.practicalMatch10') + '.part1'
                      }
                    />{' '}
                    {practicalCharacteristics.length}{' '}
                    <FormattedMessage
                      id={
                        (practicalCharacteristics.length <= 10
                          ? 'results.partners.together.practicalMatch' + practicalCharacteristics.length
                          : 'results.partners.together.practicalMatch10') + '.partBold2'
                      }
                    />{' '}
                    <FormattedMessage
                      id={
                        (practicalCharacteristics.length <= 10
                          ? 'results.partners.together.practicalMatch' + practicalCharacteristics.length
                          : 'results.partners.together.practicalMatch10') + '.part3'
                      }
                    />
                  </CustomText>
                </div>

                <CardList>
                  {practicalCharacteristics.map((value, i) => (
                    <Fade key={i} bottom ssrReveal duration={1000} distance={'5rem'} delay={200 * i}>
                      <Box mb='1rem' key={i}>
                        <SimpleCard color='blue' name={value.text} size='small' />
                      </Box>
                    </Fade>
                  ))}
                </CardList>
              </Fade>
            ) : (
              <Fade bottom ssrReveal duration={1000} distance={'5rem'}>
                {GraphLegend}
              </Fade>
            )}
          </Col>

          <Col xs={12} md={5} lg={4}>
            <Fade bottom ssrReveal duration={1000} distance={'5rem'}>
              <Flex mb='3rem' alignItems='center' justifyContent='center'>
                <GraphLegendCircle color={'blue'} />

                <span style={{ color: '#47a7ff' }}>
                  <FormattedMessage id='results.partners.together.practicalMatch.graph' />
                </span>
              </Flex>

              <ValuesGraph
                points={[
                  {
                    points: graphCharacters[profiles[0]].characters.map(x => x.percentage),
                    fill: '#247BCB',
                    fillOpacity: '1',
                    stroke: '#247BCB',
                    strokeWidth: '3px',
                  },
                  {
                    points: graphCharacters[profiles[1]].characters.map(x => x.percentage),
                    fill: '#247BCB',
                    fillOpacity: '1',
                    stroke: '#247BCB',
                    strokeWidth: '3px',
                  },
                ]}
                backgroundIndex={1}
                pointToCircle={() => {
                  return {
                    index: 1,
                    additions: practicalResults.map(value => value.percentage),
                  };
                }}
              />

              <Flex mt='2rem' mb='2rem' alignItems='center' justifyContent='center'>
                <GraphLegendCircle color='black'>
                  <img src='/img/occto-small.svg' alt='' width='24px' height='24px' />
                </GraphLegendCircle>

                <span style={{ color: 'rgba(244, 244, 246, 0.8)' }}>
                  <FormattedMessage id='results.partners.together.practicalMatch.area' />
                </span>
              </Flex>

              <Flex mt='2rem' mb='2rem' alignItems='center' justifyContent='center'>
                <GraphLegendCircle color='white' />

                <span style={{ color: 'rgba(244, 244, 246, 0.5)' }}>
                  <FormattedMessage id='results.partners.together.practicalMatch.together' />
                </span>
              </Flex>

              <Box mt='2rem'>
                <SectionText color='#47A7FF' onClick={() => setState({ character: !state.character })}>
                  {state.character ? (
                    <FormattedMessage id={'partnerResult.graph.faq'} />
                  ) : (
                    <FormattedMessage id={'results.graph.together'} />
                  )}
                </SectionText>
              </Box>
            </Fade>
          </Col>
        </Row>
      </StyledResultBoxes>

      <FAQPopup popup={ValueTestResultGraph}>
        <PopupIcon text={'partnerResult.helpPopup.practicalMatch'} />
      </FAQPopup>
    </>
  );
};

export default TogetherPracticalMatch;
