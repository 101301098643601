import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactNode } from 'react';

interface IFormGroup {
  text?: string | ReactNode;
  id?: string;
  required?: boolean;
}

export const FormGroupTitle: React.FC<IFormGroup> = ({ text, id, required, children }) => {
  return (
    <label htmlFor={id}>
      {text}
      {!required && (
        <span className={'notRequired'}>
          <FormattedMessage id={'required.not'} />
        </span>
      )}
      {children}
    </label>
  );
};

export default FormGroupTitle;
