import * as React from 'react';
import CustomSelect from '../Select';
import { Option, OnChangeHandler } from 'react-select';
import InlineFormGroup, { IInlineFormGroup } from './InlineFormGroup';
import { Box, BoxProps } from '@xcorejs/ui';
import styled from 'styled-components';

interface IFormGroupInline {
  name?: string;
  value?: string | number;
  onChange?: OnChangeHandler;
  placeholder?: string;
  options?: Option[];
  searchable?: boolean;
  required?: boolean;
  disabled?: number[];
  selectStyle?: BoxProps;
}

export const InlineFormSelect: React.FC<IFormGroupInline & IInlineFormGroup> = ({
  className,
  error,
  name,
  value,
  onChange,
  placeholder,
  options,
  searchable = false,
  required,
  disabled,
  selectStyle
}) => (
  <InlineFormGroup className={className} error={error}>
    <SelectStyle
      bg='rgba(11, 12, 28, 0.7)'
      borderRadius='2.6rem'
      color='rgba(255, 255, 255, 0.8)'
      p='1.6rem'
      lineHeight='2rem'
      textAlign='center'
      {...selectStyle}
    >
      <CustomSelect
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        options={
          options && disabled
            ? options.map(x => {
              return { ...x, disabled: disabled.some(z => z === x.value) };
            })
            : options
        }
        searchable={searchable}
        required={required}
        onFocus={() => blur()}
      />
    </SelectStyle>
  </InlineFormGroup>
);

export default InlineFormSelect;

const SelectStyle = styled(Box)`
  .Select {
    border: 0 !important;
  }

  .Select-control {
    width: 100% !important;
  }
  
  .Select-input > input {
    border: 0 !important;
  }
  
  .Select-control .Select-value {
    padding-left: 4.2rem !important;
    padding-right: 4.2rem !important;
    text-align: center !important;
  }
`;
