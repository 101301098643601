import styled from 'styled-components';
import { mediaMdDefault } from '../../../../theme/grid';
import { Heading2 } from '../../../../atoms/Headings';
import { SectionText } from '../../../../atoms/Text';
import { EticonLvl } from '../Center/styles';
import { yellow } from '../../../../theme/colors';

export default styled.section`
  text-align: center;
  padding: 3rem 0;

  ${mediaMdDefault} {
    text-align: left;
    padding-top: 9rem;
  }

  ${Heading2} {
    text-align: center;
    color: ${yellow};
  }

  ${SectionText} {
    margin-top: 1.5rem;
    text-align: center;
  }

  ${EticonLvl} {
    margin-top: 3rem;
  }

  .lvl-img {
    display: block;
    margin: 3rem auto;
    max-width: 32rem;
  }
`;
