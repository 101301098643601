import { Flex, FlexProps, Heading3, Typography } from '@xcorejs/ui';
import * as React from 'react';
import { FC } from 'react';

interface Block1MatchProps extends FlexProps{
  valueMatch: number;
}

const Block1Match: FC<Block1MatchProps> = ({ valueMatch, ...props }) => {
  return (
    <Flex
      flexDirection='column'
      alignItems='center'
      mt='6rem'
      {...props}
    >
      <Flex
        border='2px solid rgba(255,255,255,0.3)'
        width='5rem'
        height='5rem'
        alignItems='center'
        justifyContent='center'
        borderRadius='50%'
      >
        <Typography fontSize='3rem' lineHeight='3rem'>
          1
        </Typography>
      </Flex>

      <Heading3 mt='2rem'>
        Tvá shoda hodnot
      </Heading3>

      <Typography mt='1rem' textAlign='center'>
        Ukazuje, jak moc se tvé poslední dva žebříčky od sebe liší.
      </Typography>

      <Flex mt='4rem' justifyContent='center'>
        <Flex
          alignItems='center'
          justifyContent='center'
          background={`conic-gradient(#3e8f74 ${valueMatch * 3.6}deg, rgba(11, 12, 28, 0.3) 0 0)`}
          boxShadow='0 5px 15px 0 rgba(11, 12, 28, 0.2)'
          width='12rem'
          height='12rem'
          borderRadius='50%'
          position='relative'
        >
          <Flex
            bg='rgb(38, 52, 87, 1)'
            position='absolute'
            width='calc(100% - 0.8rem)'
            height='calc(100% - 0.8rem)'
            borderRadius='50%'
          />

          <Typography
            fontSize='3rem'
            lineHeight='4rem'
            position='relative'
            fontWeight={400}
            zIndex={1}
            color='#fff'
          >
            {Math.round(valueMatch)} %
          </Typography>
        </Flex>
      </Flex>

      <Typography mt='4rem' textAlign='center'>
        Čím vyšší shoda, tím jsou tvé hodnoty neměnné a stále. Pakliže je shoda nižší nežli 80%, značí to,
        že některé z tvých priorit se posunuly.
        Shoda méně než 70% je u tohoto porovnání méně obvyklá a defacto ukazuje na opravdu výraznou změnu
        tvých hodnotových preferencí.
      </Typography>
    </Flex>
  );
};

export default Block1Match;