import SurveyHelpModal from 'components/organisms/Test/Question/SurveyHelpModal';
import { FC } from 'react';
import * as React from 'react';
import { Row, Col } from 'react-styled-flexboxgrid';
import { ISurveyBaseQuestion, IAdditionalQuestion } from '../../data';
import { Box, Link, useModal } from '@xcorejs/ui';
import styled from 'styled-components';

export interface IFree {
  question: IAdditionalQuestion<ISurveyBaseQuestion>;
  theme: string;
}

const WithoutAnswer: FC<IFree> = ({ question, theme }) => {
  const [openSurveyHelpModal] = useModal(SurveyHelpModal);
  const questionHelp = question.help.title !== null || question.help.content !== null || question.help.image !== null;

  return (
    <Row>
      <Col xs={12} md={6} mdOffset={3}>
        <h1 dangerouslySetInnerHTML={{ __html: question.data.text as string }} />

        {question.data.description !== null
          && <p dangerouslySetInnerHTML={{ __html: question.data.description as string }} />}

        {question.data.image && (
          <img src={question.data.image} alt='' className='question-img desktop' />
        )}

        {question.data.mobileImage && (
          <img src={question.data.mobileImage} alt='' className='question-img mobile' />
        )}

        {question.videoLink && (
          <AspectRatioStyle height='0' position='relative' paddingBottom='56.25%' mt='3rem'>
            <iframe src={question.videoLink}></iframe>
          </AspectRatioStyle>
        )}

        {question.help.content !== null && <p>{question.help.content}</p>}

        {questionHelp && (
          <Link
            onClick={() => openSurveyHelpModal({ help: question.help, theme: theme })}
            color='#fff'
            borderColor='#fff'
            v='underline'
            mt='1rem'
          >
            Více info
          </Link>
        )}
      </Col>
    </Row>
  );
};

export default WithoutAnswer;

const AspectRatioStyle = styled(Box)`
  & > iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border: 0;
  }
`;
