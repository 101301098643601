import { GameContext } from 'components/App';
import { useContext } from 'react';
import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import StyledSubnav, { FlexDiv } from './styles';
import ShowMenuButton from '../../organisms/ShowMenuButton';
import { BurgerFlex } from '../../organisms/Layout/Header/styles';
import Logo from '../Logo';
import { IThemeProps, useTheme } from '../../templates/General/ThemeContext';
import SVGarrow from '../SVGIcons/SVGarrow';
import wrapIntl, { IWrapIntl } from '../wrapIntl';
import { INavItem, isActive } from '../Nav/NavItem';

export interface ISubnav {
  sideMenuIsOpen?: boolean;
  stepsOnMobile?: boolean;
  black?: boolean;
}

const Subnav: React.FC<ISubnav & IThemeProps & RouteComponentProps<{}> & IWrapIntl> = ({
  theme,
  children,
  formatMsg,
  history,
  stepsOnMobile,
  location,
  black
}) => {
  // @ts-ignore
  // eslint-disable-next-line no-extra-parens
  const tempItems = React.Children.map(children, c => (c as React.ReactElement<INavItem>).props);
  const { meeticia } = useContext(GameContext);
  const firstTime = localStorage.getItem('firstTime');

  {/* byl tu bug, kdy NavItem dobře neodkazoval,
    takže jsem bohužel musel udělat takovouhle prasárnu, kdy nyní Subnav umí přijmout i obyč NavLink :/ */}
  // @ts-ignore
  const items = tempItems ? tempItems.map((item, i) => item.to ? item : item.children.props)  : undefined;

  const active =
    items && items.length > 0
      ? items.find(x =>
        isActive(
          x.to,
          x.active,
          x.exact,
        )(
          {
            params: {},
            isExact: false,
            path: '',
            url: '',
          },
          location,
        ),
      )
      : undefined;


  return (
    
    <StyledSubnav
      sideMenuIsOpen={theme.data.enableMenu}
      stepsOnMobile={stepsOnMobile}
      black={black}
      withoutBackground={!children}
    >
      {meeticia || firstTime ? <div /> : (
        <BurgerFlex>
          <div>
            <ShowMenuButton sideMenuIsOpen={theme.data.enableMenu} />
            <Logo app />
          </div>
        </BurgerFlex>
      )}

      <ul>{children}</ul>

      {items && (
        <FlexDiv>
            
          <select
            onChange={e => history.push(e.target.value)}
            dir='rtl'
            value={active ? active.to : undefined}
          >
            {items &&
              items.map((i, index) => (
                <option
                  key={index}
                  value={i.to}
                >
                  {stepsOnMobile ? formatMsg(i.label) + ' ' + (index + 1) + '/' + items.length : formatMsg(i.label)}
                </option>
              ))}
          </select>
          {items.length > 1 && (
            <SVGarrow />
          )
          }
            
        </FlexDiv>
      )}
    </StyledSubnav>
    
  );
};

// eslint-disable-next-line react-hooks/rules-of-hooks
export default useTheme<ISubnav>(withRouter(wrapIntl(Subnav)));
