import * as React from 'react';
import { IProfile, ISurveyCompare } from '../data';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Col } from 'react-styled-flexboxgrid';
import { StyledColorBox } from '../../../../../organisms/PartnerTest/Result/styles';
import { IStringMap } from '../../../../../../utils';
import SVGpartnerQuestion from '../../../../../atoms/SVGIcons/SVGpartnerQuestion';
import { IValueTestResultDetail } from 'config/api/valueTestResult';
import HeadingValuesComparison from '../../../../../organisms/VisualValuesComparison/Heading';
import { StyledDefaultIntroduction, StyledDefaultIntroductionPopup } from '../styles';
import ExternalSVG from '../../../../../atoms/ExternalSVG';
import Fade from 'react-reveal/Fade';
import { ISurvey } from 'components/pages/Sandbox/TestFactory';
import { HelpLink } from '../../../../../atoms/Popups/HelpLink';
import FAQPopup from '../../../../../atoms/Popups/FAQPopup';
import { Fragment } from 'react';
import { Box } from '@xcorejs/ui';
import BasePopup from '../../../../../atoms/Popups/FAQPopup/BasePopup';
import { CustomText } from '../../../../../atoms/Text';
import { UL1 } from '../../../../../organisms/VisualValuesComparison/style';
import { Heading2 } from '../../../../../atoms/Headings';

interface IData {
  profiles: IStringMap<IProfile>;
  finishedAt: number;
  eticons: IStringMap<IValueTestResultDetail>;
  surveys: ISurveyCompare[];
  partner: string;
  surveysConfig: ISurvey[];
}

const IntroductionResult = () => (
  <BasePopup button='ok' background='blue' width={81} withoutCloseButton={true}>
    <StyledDefaultIntroductionPopup>
      <img src='/img/faq/faq.svg' alt='' />

      <Heading2>
        <FormattedMessage id='results.partners.final.intro.popup.heading' />
      </Heading2>

      <CustomText>
        <FormattedHTMLMessage id='results.partners.final.intro.popup.text' />
      </CustomText>

      <UL1 color='white'>
        <li>
          <span>
            <FormattedHTMLMessage id='test.results.values.ul.li1' />
          </span>
        </li>
        <li>
          <span>
            <FormattedHTMLMessage id='test.results.values.ul.li2' />
          </span>
        </li>
        <li>
          <span>
            <FormattedHTMLMessage id='test.results.values.ul.li3' />
          </span>
        </li>
      </UL1>

      <CustomText>
        <FormattedHTMLMessage id='results.partners.final.intro.popup.text2' />
      </CustomText>
    </StyledDefaultIntroductionPopup>
  </BasePopup>
);

const DefaultIntroduction: React.FC<IData> = ({ profiles, eticons, surveys, partner, surveysConfig }) => {
  return (
    <Fragment>
      <StyledDefaultIntroduction>
        <Col xs={12} md={10} mdOffset={1}>
          <Fade bottom ssrReveal duration={1000} distance={'5rem'}>
            <HeadingValuesComparison />

            <div className='test-type'>
              {partner === 'partner' ? (
                <FormattedHTMLMessage id='results.partners.final.intro.partners' />
              ) : (
                <FormattedHTMLMessage id='results.partners.final.intro.friends' />
              )}
            </div>

            <div className='people'>
              {Object.keys(profiles).map((key, index) => (
                <>
                  <div className='box'>
                    <div className={`img ${index === 0 ? 'green' : 'blue'}`}>
                      <img src={eticons[key].eticon.icon} alt={eticons[key].eticon.name} />
                    </div>

                    <StyledColorBox color={index === 0 ? 'green' : 'blue'} key={key + 0}>
                      {profiles[key].fullname == null ? profiles[key].nick : profiles[key].fullname}
                    </StyledColorBox>
                  </div>

                  {index === 0 && <ExternalSVG src='/img/and.svg' className='divider' />}
                </>
              ))}
            </div>

            <div className='intro-type-topics'>
              {surveys.map((x, i) => (
                <div key={i}>
                  {surveysConfig.find(s => s.id === x.surveyId) ? (
                    <img
                      height='60px'
                      style={{ marginRight: '1.5rem' }}
                      src={surveysConfig.find(s => s.id === x.surveyId)!.image}
                      alt={x.surveyName}
                    />
                  ) : (
                    <SVGpartnerQuestion />
                  )}

                  {x.surveyName}
                </div>
              ))}
            </div>
          </Fade>
        </Col>
      </StyledDefaultIntroduction>

      <Box position='fixed' bottom='3rem' right='3rem'>
        <FAQPopup
          showOnLoad={'IntroductionResult'}
          popup={IntroductionResult}
          className={'faq-popup-button'}
          delay={2000}
          // showEveryTime={true} TODO alfonz fix bug
        >
          <HelpLink size={5} />
        </FAQPopup>
      </Box>
    </Fragment>
  );
};

export default DefaultIntroduction;
