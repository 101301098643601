import * as React from 'react';
import { IUserState, IUserInfo } from '../../../store/User/index';
import { fetchUserInfo } from '../../../store/User/UserActions';
import { IRescource } from '../../../utils/rescource';
import { getData } from '../../../utils/rescource';
import withRedux, { mergeRes } from '.';

export interface IWithUser {
  user: IUserInfo;
  rescource: { user: IRescource<IUserInfo> };
}

const withUser = withRedux(
  state => state.user,
  dispatch => () => dispatch(fetchUserInfo()),
  (props): IWithUser => ({
    user: getData(props.rescource),
    rescource: { ...mergeRes(props), user: props.rescource },
  }),
);

export default withUser;

interface IWithUserProps {
  children: (user: IUserInfo, rescource: { user: IUserState }) => React.ReactNode;
}

interface IWithUserOuterProps extends IWithUserProps {
  readable?: boolean;
}

const _WithUser: React.FC<IWithUserProps & IWithUser> = ({ children, user, rescource }) => (
  <>{children(user, rescource as any)}</>
);

export const WithUser: React.FC<IWithUserOuterProps> = props => {
  const WithUserInner = withUser(_WithUser, props.readable);
  return <WithUserInner {...props} />;
};
