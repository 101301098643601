import { Box, Flex, Heading4, Img, Paragraph } from '@xcorejs/ui';
import InlineFormSelect from 'components/atoms/InlineForm/InlineFormSelect';
import { BlueTheme } from 'components/theme/colors';
import * as d3 from 'd3';
import { geoPath } from 'd3-geo';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Option } from 'react-select';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import CountryInfo from './components/CountryInfo';
import MapPin from './components/MapPin';
import { calcMapPosition, markerData, scaleToPercentage, useD3map } from './data';
import { BoxTransformStyle, SelectStyle } from './styles';
import { Coordinates, TransformElement } from './types';

export interface GeographyMapProps {
  initialX: number;
  initialY: number;
  initialScale: number;
  minScale: number;
  maxScale: number;
  device: 'phone' | 'pc';
}

const GeographyMap: FC<GeographyMapProps> = ({
  initialX,
  initialY,
  initialScale,
  minScale,
  maxScale,
  device
}) => {
  // načtení dat z topojsonu
  const geographies = useD3map();
  // vybrání vhodné projekce viz https://d3js.org/d3-geo/projection
  const projection = d3.geoNaturalEarth1();

  const [markerPos, setMarkerPos] = useState<Coordinates>({ x: 0, y: 0 });
  const mapRef = useRef<HTMLDivElement>(null);

  const [country, setCountry] = useState<string | null>(null);
  const [mapPosition, setMapPosition] = useState<Coordinates & {scale: number}>({
    x: initialX,
    y: initialY,
    scale: initialScale
  });
  const [markerScale, setMarkerScale] = useState<number>(1);

  useEffect(() => {
    const K = { pc: 2.2, phone: 6.2 }; // nějaký poměr jak to má být velký, menší číslo menší velikost a naopak
    const scale = K[device] / mapPosition.scale;
    setMarkerScale(Math.min(scale, 1));
  }, [device, mapPosition.scale]);
    
  const markerOpacity = useCallback((scale: number, markerMinScale: number) => {
    const x = (maxScale - minScale) * (markerMinScale / 100);
    return scale > x ? 1 : 0;
  }, [maxScale, minScale]);

  if (!geographies) return null;

  const optionToCountry = (option: Option | null) => {
    if (!option || !option.value) return;

    typeof option.value === 'string' && focusCountry(option.value);
  };

  const focusCountry = (countryName: string) => {
    const country = markerData.find(c => c.NAME === countryName);
    if (!country) return;
    if (!mapRef.current) return;

    const wrapperRef = mapRef.current.parentElement!;    
    const [x, y] = calcMapPosition(
      wrapperRef.clientWidth,
      wrapperRef.clientHeight,
      maxScale,
      country);

    const scale = country.zoomTo.scale && country.zoomTo.scale[device] || maxScale;
    setCountry(country.NAME);
    setMapPosition({ x, y, scale });
  };

  const handleMapMovement = (el: TransformElement) => {
    // console.log(el.positionX, el.positionY, el.scale, minScale);
    
    // na telefonu když se oddálilo na max tak se sekla mapa, takhle zůstane pořád trochu funkční
    if (el.scale <= minScale * 1.001) {
      el.scale = minScale * 1.001;
    }
    setMapPosition({ x: el.positionX, y: el.positionY, scale: el.scale });
  };

  const calcPercentage = (scale: number) => {
    return scaleToPercentage(scale, minScale, maxScale);
  };

  const step = device === 'pc' ? 10 : device === 'phone' ? 150 : 1;

  return ( 
    <Flex
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      marginTop={{ _: '2rem' }}
      position='relative'
    >
      {/* <Box position='absolute' bottom='0' right='0' zIndex={2} opacity={0.1}>
        <input type='number' value={markerPos.x} onChange={e => setMarkerPos(prev => ({ ...prev, x: Number(e.target.value) }))} />
        <input type='number' value={markerPos.y} onChange={e => setMarkerPos(prev => ({ ...prev, y: Number(e.target.value) }))} />
      </Box> */}
      
      <Flex flexDirection='column' alignItems='center' justifyContent='center' position='relative' zIndex={99}>
        <Heading4 marginBottom={{ _: '2rem' }} marginX={{ _: '3rem' }}>
            Choose a country you are interested in
        </Heading4>

        <SelectStyle>
          <InlineFormSelect
            name='countryName'
            value={country || ''}
            placeholder='Select a country'
            options={markerData.map(marker => ({ value: marker.NAME, label: marker.country || marker.NAME }))}
            onChange={opt => optionToCountry(opt)}
            selectStyle={{
              bg: BlueTheme.primary,
              minWidth: { _:'26rem', md: '36rem' },
              borderRadius: '0.5rem',
              paddingY: '1.5rem'
            }}
            searchable={true}
          />
        </SelectStyle>
      </Flex>

      <BoxTransformStyle marginTop={{ _: '2rem' }}>
        <Img
          src='/img/eticiameter/background-map.png'
          alt='Map'
          position='absolute'
          top='0'
          left='0'
          width='100%'
          height='100%'
          // transform={`scale(${1.01 + calcPercentage(mapPosition.scale) / 5})`}
          // transition='transform 150ms ease'
          zIndex={0} />
        
        <TransformWrapper
          scale={mapPosition.scale}
          positionX={mapPosition.x}
          positionY={mapPosition.y}
          options={{ maxScale, minScale }}
          wheel={{ step }}
          doubleClick={{ disabled: true }}
          onPanningStop={(e: TransformElement) => handleMapMovement(e)}
          onPinchingStop={(e: TransformElement) => handleMapMovement(e)}
          onWheel={(e: TransformElement) => handleMapMovement(e)}
          
        >
          <TransformComponent>
            <Box width={{ _: '100%', md: '120rem' }} height='auto' ref={mapRef} position='relative'>
              <svg viewBox='100 0 870 430'> {/* hodně trial a error souřadnic, pokud se změní projekce, tak je nutný změnit i tohle */}
                <g className='countries'>
                  {
                    geographies.map((d, i) => {
                      // překresluje celou mapu, takže není nic jiného vidět
                      if (d.properties.NAME === 'Antarctica') return null;
                      return (
                        <CountryInfo
                          key={ `path-${i}-${device}` }
                          i={i}
                          device={device}
                          firstCondition={country === d.properties.NAME}
                          secondCondition={mapPosition.scale > maxScale / 2}>
                          <path
                            // @ts-ignore, IDGAF normálně funguje
                            d={ geoPath().projection(projection)(d) }
                            fill={`url(#gradient-${i}-${device})`}
                            strokeWidth={0.1}
                            stroke='#999'
                            onClick={() => {
                              console.log(d.properties.NAME);
                              // console.log(`
                              // {
                              //   NAME: '${d.properties.NAME}',
                              //   x: ${markerPos.x},
                              //   y: ${markerPos.y},
                              //   value: 'Nějaká hodnota',
                              //   minScale: x,
                              //   zoomTo: {
                              //     x: { pc: 0, phone: ${Math.round(mapPosition.x)} },
                              //     y: { pc: 0, phone: ${Math.round(mapPosition.y)} },
                              //     scale: { pc: 0, phone: ${Math.round(mapPosition.scale)} }
                              //   }
                              // },
                              // `);
                              focusCountry(d.properties.NAME);
                            }}
                          >
                          </path>
                        </CountryInfo>
                      );
                    })
                  }
                </g>

                <g className='markers' style={{ zIndex: 99 }}>
                  {/* <MarkerStyle opacity={1}>
                    <StyledForeignObject x={markerPos.x} y={markerPos.y} width='7%' height='5%'>
                      <div style={{ transform: `scale(${markerScale})` }}>
                        <img src='/img/eticiameter/map-pin.svg' alt='Image' />
                        
                        <p>Název země</p>
                        <p>Hodnota země</p>
                      </div>
                    </StyledForeignObject>
                  </MarkerStyle> */}
                  {markerData.map((marker, i) => (                  
                    <MapPin key={ `marker-${i}` } marker={marker} focusCountry={focusCountry} markerOpacity={markerOpacity} markerScale={markerScale} country={country} mapScale={mapPosition.scale} />
                  ))}
                </g>
              </svg>
              
            </Box>
          </TransformComponent>
        </TransformWrapper>

        <Paragraph
          backgroundImage='url(/img/eticiameter/kolecko.png)'
          backgroundPosition='center'
          backgroundSize='cover'
          backgroundRepeat='no-repeat'
          position='absolute'
          bottom={{ _: '4rem' }}
          left={{ _:'2rem' }}
          height={{ _: '5rem' }}
          width={{ _: '5rem' }}
          zIndex={1}
          display='flex'
          alignItems='center'
          justifyContent='center'
          color='white'
          lineHeight='1'
        >{Math.round(calcPercentage(mapPosition.scale))}<br />&#37;</Paragraph>
      </BoxTransformStyle>
    </Flex>
  );
};
 
export default GeographyMap;
