import styled from 'styled-components';
import { mediaLandscapePhone, mediaSmDefault } from '../../../theme/grid';

export const StyledVimeoPopup = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  max-width: 100vw;
  background: #000;

  ${mediaLandscapePhone} {
    max-width: 77vw;
  }

  ${mediaSmDefault} {
    max-width: 65vw;
  }

  .content {
    background: #000;
    padding: 6rem 0 0;

    ${mediaSmDefault} {
      padding: 6rem 3rem 3rem;
    }

    & > div {
      width: 100%;
      padding-bottom: 56.25%;
      position: relative;
    }

    .close {
      position: absolute;
      right: 3rem;
      top: 2rem;
    }
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
`;
