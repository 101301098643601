import { Box, Flex, Img, Link, Paragraph, Tag, Typography, TypographyProps, useModal } from '@xcorejs/ui';
import { GameContext } from 'components/App';
import { PlusTag } from 'components/atoms/Logo';
import StatisticsModal, { StatisticsModalProps } from 'components/pages/App/Statistics/components/StatisticModal';
import { peaGreen, yellow } from 'components/theme/colors';
import React, { ComponentType, FC, Fragment, ReactNode, useContext } from 'react';
import { FormattedHTMLMessage } from 'react-intl';

interface StatisticsCardProps {
  heading: string | ReactNode;
  _heading?: TypographyProps;
  paragraph: string | ReactNode;
  subheading?: string | ReactNode;
  _subheading?: TypographyProps;
  noSubheading?: boolean;
  linkText?: string | ReactNode;
  noLink?: boolean;
  modal?: StatisticsModalProps;
  modalComponent?: ComponentType;
  imgUrl?: string;
  onlyPlusOrGame?: boolean;
  minGameLevelToSee?: number;
  narrowPadding?: boolean;
}

const StatisticsCard: FC<StatisticsCardProps> = (
  {
    heading,
    _heading,
    linkText,
    paragraph,
    subheading,
    _subheading,
    noSubheading,
    noLink,
    modal,
    modalComponent,
    imgUrl,
    children,
    onlyPlusOrGame,
    minGameLevelToSee,
    narrowPadding
  }
) => {
  const { eticiaPlus, userLvl } = useContext(GameContext);
  const [openModal] = useModal(modalComponent ? modalComponent : StatisticsModal);
  const linkVisible = onlyPlusOrGame ? eticiaPlus || userLvl > (minGameLevelToSee || 0) : true;

  return (
    <Box
      pt='3rem'
      pb='5rem'
      px={narrowPadding ? { _:'1rem', md: '3rem' } : '3rem'}
      bg='rgba(11, 12, 28, 0.2)'
      maxWidth='41rem'
      width='100%'
      borderRadius='4px'
      overflow='hidden'
      textAlign='center'
    >
      {imgUrl && (
        <Img
          src={'/img/' + imgUrl}
          alt=''
          width='6.4rem'
          height='6.4rem'
          display='block'
          mx='auto'
          mb='2rem'
          opacity={0.5} />
      )}

      <Paragraph fontSize='1.8rem' lineHeight='2.2rem' color={yellow} {..._heading}>
        {heading}
      </Paragraph>

      <Paragraph
        mt='1rem'
        color='rgba(255, 255, 255, 0.7)'
        minHeight='3.2rem'
        {...ParagraphStyle}>
        {paragraph}
      </Paragraph>

      {!noSubheading && (
        <Paragraph fontWeight={800} mt='3rem' {...ParagraphStyle} {..._subheading}>
          {subheading ? subheading : 'Největší rozdíl mezi skupinami'}
        </Paragraph>
      )}

      {children}

      {linkVisible ?
        noLink ? <Fragment /> : (
          <Flex justifyContent='center'>
            {modalComponent && (
              <Link
                v='underline'
                color='rgba(255, 255, 255, 0.7)'
                mt='3rem'
                fontSize='1.4rem'
                lineHeight='1.8rem'
                borderColor='rgba(255, 255, 255, 0.7)'
                onClick={openModal}
              >
                {linkText ? linkText : <FormattedHTMLMessage id='statistics.groups.values' />}
              </Link>
            )}
            {modal && (
              <Link
                v='underline'
                color='rgba(255, 255, 255, 0.7)'
                mt='3rem'
                fontSize='1.4rem'
                lineHeight='1.8rem'
                borderColor='rgba(255, 255, 255, 0.7)'
                onClick={() => openModal({ ...modal })}
              >
                {linkText ? linkText : <FormattedHTMLMessage id='statistics.groups.values' />}
              </Link>
            )}
          </Flex>
        )
        : (
          <Box
            p='2rem'
            bg='rgba(11, 12, 28, 0.5)'
            borderRadius='4px'
            mt='3rem'
          >
            <Typography fontSize='1.3rem' lineHeight='1.8rem' color='rgba(255, 255, 255, 0.8)'>
              <FormattedHTMLMessage id='statistics.groups.notVisible' />
            </Typography>

            <Flex justifyContent='center' mt='2rem'>
              <PlusTag />

              <Tag
                ml='1rem'
                display='inline-flex'
                bg={peaGreen}
                borderRadius='0.5rem'
                lineHeight='1'
              >
                <FormattedHTMLMessage id='statistics.groups.unlock' />
              </Tag>
            </Flex>
          </Box>
        )}
    </Box>
  );
};

export default StatisticsCard;

const ParagraphStyle = {
  fontSize: '1.4rem',
  lineHeight: '2.1rem'
};
