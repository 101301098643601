import { connect } from 'react-redux';
import Component from './component';
import { fetchNotifications, markAsReadNotification } from '../../../store/Entities/Notifications/index';
import { fetchUserInfo } from '../../../store/User/UserActions';
import { IApplicationState } from '../../../store/store';

export default connect(
  (state: IApplicationState) => ({
    notifications: state.entities.notifications,
  }),
  (dispatch: any) => ({
    fetchNotifications: () => dispatch(fetchNotifications()),
    fetchUserInfo: () => fetchUserInfo()(dispatch),
    markAsRead: (id: string) => markAsReadNotification(id)(dispatch),
  }),
)(Component);
