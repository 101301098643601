import * as React from 'react';
import { StyledSlider, StyledSliderValues } from './styles';
import SVGarrow from '../../../../atoms/SVGIcons/SVGarrow';
import canUseDOM from '../../../../../utils/canUseDOM';
import { ILifeValue } from '../../../../../store/Entities/LifeValues';
import { FormattedMessage } from 'react-intl';
import { IValueTestResultDetail } from 'config/api/valueTestResult';
import { startSurvey } from 'config/api/surveyInternal';
import Fetch from '../../../../atoms/Fetch';
import { GAME_STATUS, IGameStatus, ISimpleSurveyVm } from 'config/api/game';
import { _switch } from '../../../../../utils/option';
import LoadingSpinner from '../../../../atoms/Animations/LoadingSpinner';
import { createUrl } from '../../../../pages/routes';
import { RouteComponentProps, withRouter } from 'react-router';
import withLifeValues, { IWithLifeValues } from '../../../../molecules/withRedux/withLifeValues';
import LoadingSpinnerLocal from '../../../../atoms/Animations/LoadingSpinnerLocal';
import { denormalize } from '../../../../../utils/helpers';
import { Swipeable } from 'react-swipeable';
import { Flex, Img, Link } from '@xcorejs/ui';
import { NavLink } from 'react-router-dom';
import { StyledLink } from 'components/components/modals/LaddersCompareModal/style';

interface ISliderValuesProps {
  setDetail: (detail: string | null, survey?: ISimpleSurveyVm) => void;
  disabled?: boolean;
  gameStatus?: IGameStatus;
}

interface ISliderValuesState {
  step: number;
}

const isDesktop = () => canUseDOM ? window.innerWidth > 767 : true;

class SliderValues extends React.Component<
  ISliderValuesProps & RouteComponentProps<{}> & IWithLifeValues,
  ISliderValuesState
> {
  public state: ISliderValuesState = {
    step: 0,
  };

  public render() {
    const { lifeValues } = this.props;
    const values = lifeValues && denormalize(lifeValues).filter(a => a.type === 'main' && !a.frontHidden);
    const sortedValues = values.sort((a, b) => a.name.localeCompare(b.name));
    const l = values.length;
    const { step } = this.state;
    
    return (
      <>
        {lifeValues !== null ? (
          <Swipeable
            onSwipedRight={() => {
              step > 0 && this.setState({ step: step - 1 });
            }}
            onSwipedLeft={() => {
              // eslint-disable-next-line no-extra-parens
              (step * (window.innerWidth < 480 ? 1 : 2) + (window.innerWidth < 480 ? 1 : 2) < values.length)
              && this.setState({ step: step + 1 });
            }}
          >
            <StyledSliderValues>
              <StyledSlider>
                {this.props.gameStatus && 
                this.props.gameStatus.availableSurveys ? (
                    <div className='slider'>
                      <div className='items'>
                        {sortedValues.map(v =>
                          this.renderItem(
                            v,
                            this.props.gameStatus && 
                          this.props.gameStatus.availableSurveys.lifeValues.find(s => s.lifeValueId === v.id),
                          ),
                        )}
                      </div>
                    </div>
                  ) : (
                    <Fetch url={GAME_STATUS}>
                      {_switch({
                        success: (status: IGameStatus) => (
                          <>
                            {console.log('StyledSliderValues.tsx game_status', status)}
                            <div className='slider'>
                              <div className='items'>
                                {sortedValues.map(v =>
                                  this.renderItem(
                                    v,
                                    status.availableSurveys.lifeValues.find(s => s.lifeValueId === v.id),
                                  ),
                                )}
                              </div>
                            </div>
                          </>
                        ),
                        fetching: () => <LoadingSpinner />,
                      })()}
                    </Fetch>
                  )}

                {step > 0 && (
                  <div className='arr-before' onClick={() => this.setState({ step: step - 1 })}>
                    <SVGarrow />
                  </div>
                )}

                {(isDesktop() && step + 6 < values.length ||
                  !isDesktop() &&
                  step * (window.innerWidth < 480 ? 1 : 2) + (window.innerWidth < 480 ? 1 : 2) < values.length) && (
                  <div className='arr-after' onClick={() => this.setState({ step: step + 1 })}>
                    <SVGarrow />
                  </div>
                )}
              </StyledSlider>
            </StyledSliderValues>
          </Swipeable>
        ) : (
          <Flex minHeight={{ _: '17rem' }} width='100%' justifyContent='center'>
            <LoadingSpinnerLocal />
          </Flex>
        )}

        <Flex
          justifyContent='center'
          alignItems='center'
          marginTop='2rem'
        >
          <Img src='/img/ico/game-question.svg' alt='' marginRight='1rem' />
        
          <StyledLink to={createUrl('app.journal') + '/aktivita'}>
            Zobraz si všechny své odpovědi sekci Mé odpovědi, které nalezneš v záložce Hodnotového deníku
          </StyledLink>
        </Flex>
      </>
    );
  }

  private renderItem = (v: ILifeValue, survey?: ISimpleSurveyVm) => (
    <div
      key={v.id}
      className='plate'
      style={{
        left: -100 / 6 * this.state.step * (isDesktop() ? 1 : 6) + '%',
        cursor: 'pointer'
      }}
      onClick={() => this.props.disabled ? null : this.props.setDetail(v.id, survey )}
    >
      
      {survey ? (
        <>
          <span
            className={survey.done ? 'game-question-wrapper done' : 'game-question-wrapper'}
            // onClick={() =>
            //   survey.activeResultId
            //     ? this.props.history.push(createUrl('app.survey', { id: survey.activeResultId }))
            //     : startSurvey(survey.id, v.id).then(r => this.props.history.push(createUrl('app.survey', { id: r.id })))
            // }
          >
            {survey.done ? (
              <Img src='/img/ico/game-question--done.svg' alt='' />
            ) : (
              <Img src='/img/ico/game-question.svg' alt='' />
            )}
          </span>
        </>
      ) : (
        <></>
      )}

      <img src={v.icon} alt='' />
      <span className={'name'}>{v.name}</span>
      <div className='hoverText'>
        <FormattedMessage id={'slider.values.pickedBy'} /> {v.topUsage.toFixed(0)} {' % '}
        <FormattedMessage id={'slider.values.players'} />
      </div>
      <span className={'rotate-ico-wrapper'}>
        <img className='rotate-ico' src={'/img/ico/rotate.svg'} alt='rotate ico' />
      </span>
    </div>
  );
}

export default withRouter(withLifeValues(SliderValues));
