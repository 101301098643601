import { createUrl } from 'components/pages/routes';

const forTwo = '/img/C2A/Button_HP2_VAN_1.png';
const giftBox = '/img/C2A/Button_HP2_VAN_2.png';

export enum C2ANames {
  'forTwo',
  'giftBox',
}

const banners = [forTwo, giftBox];
const links = [() => createUrl('app.comparison'), () => createUrl('app.comparison')];

export const GetC2A = (name: C2ANames) => banners[name];
export const GetC2ALink = (name: C2ANames) => links[name];

export const GetRandomC2A = () => Math.floor(Math.random() * banners.length - 0.00001);
