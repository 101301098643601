import * as React from 'react';
import { IQuestionHelp, ISurveySimpleQuestion } from '../../../data';
import { Row, Col } from 'react-styled-flexboxgrid';
import SimpleItem from '../SimpleItem';
import { QuestionFlexBox } from '../../../../../../atoms/FormItems/PartnerTest/styles';
import { Box, Container, Link, Typography, Flex, Stack, useModal, Modal, Heading2, Img } from '@xcorejs/ui';
import { FormattedMessage } from 'react-intl';
import { useState } from 'react';
import Fade from 'react-reveal/Fade';
import HeadShake from 'react-reveal/HeadShake';
import SVGclose from 'components/atoms/SVGIcons/SVGclose';

export interface ILayoutB {
  data: ISurveySimpleQuestion;
  help: IQuestionHelp;
  selected: string[];
  selectedMinus?: string[];
  onChange: (id: string, val: boolean) => void;
  onChangeMinus?: (id: string, val: boolean) => void;
  type?: 'd21minus' | 'd21plus';
  theme: string;
}

const LayoutB: React.FC<ILayoutB> = props => {
  const { data, help, type, selected, selectedMinus, theme } = props;
  const bigImg = data.answers.length === 2 && data.answers[0].image !== null && data.answers[0].image !== null;
  const [visibleInfo, setVisibleInfo] = useState(false);

  const renderItems = () =>
    data.answers.sort((a, b) => a.priority - b.priority)
      .map(a => <SimpleItem key={a.id} type={type} {...props} a={a} />);

  return (
    <Row>
      <Col xs={12}>
        <h1 dangerouslySetInnerHTML={{ __html: data.text as string }} />

        {data.description !== null && <p dangerouslySetInnerHTML={{ __html: data.description as string }} />}

        {data.image && (
          <img src={data.image} alt='' className='question-img desktop' />
        )}

        {data.mobileImage && (
          <img src={data.mobileImage} alt='' className='question-img mobile' />
        )}

        {bigImg ? (
          <QuestionFlexBox grid={'images'}>{renderItems()}</QuestionFlexBox>
        ) : (
          <>
            {(data.answers.length === 2 || data.answers.length === 3) && (
              <QuestionFlexBox>{renderItems()}</QuestionFlexBox>
            )}
            {data.answers.length === 4 && <QuestionFlexBox grid={'small'}>{renderItems()}</QuestionFlexBox>}

            {data.answers.length > 4 &&
            (data.answers.length > 6 ? (
              <QuestionFlexBox>{renderItems()}</QuestionFlexBox>
            ) : (
              <QuestionFlexBox grid={'3row'}>{renderItems()}</QuestionFlexBox>
            ))}
          </>
        )}
      </Col>

      {(type === 'd21plus' || type === 'd21minus') && (
        <Box
          position='fixed'
          width='100%'
          left={0}
          bottom='0'
          bg='radial-gradient(circle at top, #90A5BD 0%, #697B90 100%)'
          py='1.6rem'
          zIndex={10}
        >
          <Container flexDirection='column' alignItems='center' justifyContent='center'>
            <Stack
              direction={{ _: 'column', sm: 'row' }}
              width='100%'
              justifyContent='space-between'
              alignItems='center'
            >
              <Flex alignItems='center' flexDirection={{ _: 'column', sm: 'row' }}>
                <Typography fontWeight={700}>
                  <FormattedMessage id='survey.d21.youHave' />
                </Typography>

                <Flex alignItems='center' ml={{ _: 0, sm: '3rem' }} mt={{ _: '1rem', sm: 0 }}>
                  <HeadShake top spy={selected.length}>
                    <Box
                      position='relative'
                      display='flex'
                      alignItems='center'
                      justifyContent='center'
                      as='span'
                      width={{ _: '2.4rem', sm: '3rem' }}
                      height={{ _: '2.4rem', sm: '3rem' }}
                      borderRadius='50%'
                      bg='#009966'
                      border='2px solid #009966'
                      opacity={1}
                      mr='1rem'
                      fontWeight={700}
                    >
                      {data.maxAnswersCount - selected.length}
                    </Box>
                  </HeadShake>
                  <Typography fontSize={{ _: '2.1rem', sm: '2.4rem' }}>
                    <FormattedMessage id='survey.d21.plus' />
                  </Typography>
                </Flex>

                {type === 'd21minus' && (
                  <Flex alignItems='center' ml={{ _: 0, sm: '3rem' }} mt={{ _: '1rem', sm: 0 }}>
                    <HeadShake top spy={selectedMinus && selectedMinus.length}>
                      <Box
                        position='relative'
                        alignItems='center'
                        justifyContent='center'
                        display='flex'
                        as='span'
                        width={{ _: '2.4rem', sm: '3rem' }}
                        height={{ _: '2.4rem', sm: '3rem' }}
                        borderRadius='50%'
                        background='#f64747'
                        border='2px solid #f64747'
                        mr='1rem'
                        fontWeight={700}
                      >
                        {selectedMinus && 1 - selectedMinus.length}
                      </Box>
                    </HeadShake>
                    <Typography fontSize={{ _: '2.1rem', sm: '2.4rem' }}>
                      <FormattedMessage id='survey.d21.minus' />
                    </Typography>
                  </Flex>
                )}
              </Flex>

              <Link v='underline' onClick={() => setVisibleInfo(!visibleInfo)} mt={{ _: '1rem', sm: 0 }}>
                <FormattedMessage id='survey.d21.more' />
              </Link>
            </Stack>
          </Container>
        </Box>
      )}


      {visibleInfo && (
        <Box
          position='fixed'
          width='100%'
          left={0}
          bottom='0'
          bg='radial-gradient(circle at top, #90A5BD 0%, #697B90 100%)'
          p='1.6rem'
          zIndex={10}
        >
          <Box
            position='absolute'
            right='3rem'
            top='3rem'
            onClick={() => setVisibleInfo(!visibleInfo)}
            zIndex={11}
            cursor='pointer'
          >
            <SVGclose />
          </Box>

          <Box maxWidth='60rem' mx='auto' mb={{ _: '2rem', sm: '8rem' }}>
            <Fade ssrReveal bottom duration={1000} distance={'5rem'}>
              <Img src='/img/logo_I21.png' alt='' />

              <Heading2>
                <FormattedMessage id='survey.d21.info.heading' />
              </Heading2>

              <Typography mt='2rem'>
                <FormattedMessage id='survey.d21.info.text1' />
              </Typography>

              <Typography mt='2rem'>
                <FormattedMessage id='survey.d21.info.text2' />
              </Typography>

              <Link variant='underline' href='https://www.ih21.org/o-metode' target='_blank' mt='2rem'>
                <FormattedMessage id='survey.d21.info.link' />
              </Link>
            </Fade>
          </Box>
        </Box>
      )}
    </Row>
  );
};

export default LayoutB;
