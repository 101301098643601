import * as React from 'react';
import { Col, Grid, Row } from 'react-styled-flexboxgrid';
import Fade from 'react-reveal/Fade';
import { Heading2 } from '../../../../atoms/Headings';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { StyledProfilePage } from '../styles';
import { FormInput } from '../../../../atoms/Form/FormInput';
import { SecondaryButtonButton } from '../../../../atoms/Buttons';
import Form from '../../../../molecules/Form';
import { StyledLinkBorder } from '../../../../atoms/Buttons/styles';
import { createUrl } from '../../../routes';
import { useState } from 'react';
import { changePassword } from './actions';

interface PasswordBox {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirmed: string;
}

const defaultPasswordsValue: PasswordBox = {
  oldPassword: '',
  newPassword: '',
  newPasswordConfirmed: '',
};

const Password: React.FC = () => {
  const [passwords, setPasswords] = useState<PasswordBox>(defaultPasswordsValue);
  const [errorOld, setErrorOld] = useState<boolean>(false);
  const [errorNew, setErrorNew] = useState<boolean>(false);
  const [errorConfirm, setErrorConfirm] = useState<boolean>(false);
  const [passwordChanged, setPassWordChanged] = useState<boolean>(false);

  return (
    <StyledProfilePage>
      <Grid>
        <Fade bottom ssrReveal duration={1000} distance={'5rem'}>
          <div>
            <Heading2>
              <FormattedMessage id='profile.settings.password' />
            </Heading2>
          </div>

          <Row>
            <Col xs={12} md={4} mdOffset={4}>
              {passwordChanged ? (
                <FormattedMessage id='settings.password.changed' />
              ) : (
                <Form
                  onSubmit={e => {
                    e.preventDefault();
                    if (passwords.newPassword !== '' && passwords.newPassword.length < 6) {
                      setErrorNew(true);
                    } else {
                      if (
                        passwords.newPassword !== '' &&
                        passwords.newPasswordConfirmed !== '' &&
                        passwords.newPassword !== passwords.newPasswordConfirmed
                      ) {
                        setErrorConfirm(true);
                      } else {
                        changePassword(
                          passwords.oldPassword,
                          passwords.newPassword,
                          passwords.newPasswordConfirmed,
                          () => setPassWordChanged(true),
                          () => setErrorOld(true),
                        );
                      }
                    }
                  }}
                >
                  <FormattedMessage id='settings.password.old'>
                    {message => (
                      <FormattedMessage id='settings.password.old.error'>
                        {message2 => (
                          <FormInput
                            type='password'
                            text={message as string}
                            id='oldpass'
                            required={true}
                            value={passwords.oldPassword}
                            onChange={value => {
                              setErrorOld(false);
                              setPasswords({
                                ...passwords,
                                oldPassword: value.target.value,
                              });
                            }}
                            error={errorOld ? message2 : undefined}
                          />
                        )}
                      </FormattedMessage>
                    )}
                  </FormattedMessage>

                  <FormattedMessage id='settings.password.new'>
                    {message => (
                      <FormattedMessage id='settings.password.new.error'>
                        {message2 => (
                          <FormInput
                            type='password'
                            text={message as string}
                            id='newPass'
                            required={true}
                            value={passwords.newPassword}
                            onChange={value =>
                              setPasswords({
                                ...passwords,
                                newPassword: value.target.value,
                              })
                            }
                            onBlur={e => {
                              e.preventDefault();
                              setErrorNew(passwords.newPassword !== '' && passwords.newPassword.length < 6);
                            }}
                            error={errorNew ? message2 : undefined}
                          />
                        )}
                      </FormattedMessage>
                    )}
                  </FormattedMessage>

                  <FormattedMessage id='settings.password.confirm'>
                    {message => (
                      <FormattedMessage id='settings.password.confirm.error'>
                        {message2 => (
                          <FormInput
                            type='password'
                            text={message as string}
                            id='newPassAgain'
                            required={true}
                            value={passwords.newPasswordConfirmed}
                            onChange={value =>
                              setPasswords({
                                ...passwords,
                                newPasswordConfirmed: value.target.value,
                              })
                            }
                            onBlur={e => {
                              e.preventDefault();
                              setErrorConfirm(
                                passwords.newPassword !== '' &&
                                  passwords.newPasswordConfirmed !== '' &&
                                  passwords.newPassword !== passwords.newPasswordConfirmed,
                              );
                            }}
                            error={errorConfirm ? message2 : undefined}
                          />
                        )}
                      </FormattedMessage>
                    )}
                  </FormattedMessage>

                  <SecondaryButtonButton>
                    <FormattedHTMLMessage id='settings.password.change.btn' />
                  </SecondaryButtonButton>
                </Form>
              )}
            </Col>
          </Row>

          <StyledLinkBorder className='back' to={createUrl('app.profile')}>
            <FormattedMessage id='profile.settings.password.back' />
          </StyledLinkBorder>
        </Fade>
      </Grid>
    </StyledProfilePage>
  );
};

export default Password;
