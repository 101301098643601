import * as React from 'react';

const SVGsliderArrow: React.FC = () => {
  return (
    <svg width='30px' height='60px' viewBox='0 0 30 60' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <g id='MVP-Tuning' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' opacity='1'>
        <g id='Statistiky' transform='translate(-745.000000, -544.000000)' fill='#FFFFFF'>
          <g id='CONTENT' transform='translate(310.000000, 0.000000)'>
            <polygon id='ico/slide-left' points='435 575.000338 465 544 441.494365 574.63206 465 604' />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SVGsliderArrow;
