import styled from 'styled-components';
import { mediaSmDefault } from '../../../theme/grid';
import { SectionText } from '../../../atoms/Text';
import { Heading2 } from '../../../atoms/Headings';
import { bold } from '../../../theme/fonts';
import { darkBaseHEX, darkenYellow, gray, grayFooterRGB, yellow } from '../../../theme/colors';
import { StyledStatisticsSlider } from '../../../molecules/StatisticsSlider/styles';
import { TypographyProps } from 'styled-system';
import { TagProps, ModalProps } from '@xcorejs/ui';

export const StyledStatistics = styled.section`
  padding-top: 6rem;
  display: block;
  text-align: center;

  ${SectionText} {
    margin-left: auto;
    margin-right: auto;
    max-width: 63rem;
  }

  ${Heading2} {
    margin-top: 6rem;
    letter-spacing: 0.5px;
    font-weight: ${bold};

    &:first-child {
      margin-top: 0;
    }
  }

  .filter {
    margin-top: 3rem;
    font-size: 2rem;
    letter-spacing: 0.5px;
    line-height: 2.6rem;

    div {
      margin-top: 2rem;

      &:first-child {
        margin-top: 0;
      }

      strong {
        display: block;
        color: ${yellow};

        ${mediaSmDefault} {
          display: inline-block;
        }
      }
    }
  }

  .counter {
    margin-top: 3rem;
    color: ${yellow};
    font-size: 2.4rem;
    letter-spacing: 0.5px;
    line-height: 2.9rem;
  }

  .btn-wrap {
    margin-top: 3rem;
  }

  ${StyledStatisticsSlider} {
    margin-top: 3rem;
    margin-left: -1.6rem;
    margin-right: -1.6rem;

    ${mediaSmDefault} {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .filter-box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin: 1.5rem -0.75rem 0;

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      margin: 0.75rem;
      padding: 1rem;
      border-radius: 0.4rem;
      width: 24rem;
      height: 18rem;
      cursor: pointer;
      border: 2px solid rgba(${grayFooterRGB}, 0.6);
      transition: border 300ms;

      span {
        margin-top: 2rem;
      }

      &:hover {
        border: 2px solid #fff;
      }

      img {
        margin-top: 0;
        max-height: 10rem;
      }

      &.active {
        border: 2px solid ${yellow};
        font-weight: 500;
      }

      &.disabled {
        border: 2px solid rgba(${grayFooterRGB}, 0.5);
        cursor: not-allowed;

        img {
          opacity: 0.5;
        }

        .overlay {
          position: absolute;
          font-size: 2rem;
          letter-spacing: 0.5px;
          line-height: 2.5rem;
          color: ${darkBaseHEX};

          strong {
            display: block;
          }
        }

        svg {
          display: block;
          margin: 0 auto 1rem;

          path {
            fill: ${darkBaseHEX};
          }
        }
      }
    }
  }

  .main-popup-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2.5rem;
    margin-bottom: 4rem;
    color: ${yellow};

    .help-link {
      margin-right: 1.5rem;
    }
  }

  .opacity {
    opacity: 0.7;
  }

  .statistics-values {
    max-width: 85rem;
    margin: 3rem auto 0;
  }
`;

export const InfoLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  
  .mark {
    margin-right: 1rem;
    height: 2rem;
    width: 2rem;
    min-width: 2rem;
    border-radius: .4rem;
    background: radial-gradient(circle at top, ${yellow} 0%, ${darkenYellow} 100%);
  }
  
  span {
    margin-top: .2rem;
    color: ${gray};
    font-size: 1.6rem;
    line-height: 2rem;
  }
`;

interface StatisticsModalProps {
  minHeightInput?: string;
  minMobileHeightInput?: string;
  colorSchemeInput?: 'blue' | 'gray';
}

export const StatisticsModalStyle = ({ 
  minHeightInput = '60rem', 
  minMobileHeightInput = '60rem', 
  colorSchemeInput 
}: StatisticsModalProps): {colorScheme: string; _modal: ModalProps} => ({
  colorScheme: colorSchemeInput === 'gray' ? 'rgba(106, 104, 131, 1)' : colorSchemeInput === 'blue' ? 'rgb(71, 95, 150)' : 'rgb(100, 100, 100)',
  _modal: {
    minHeight: { _: minMobileHeightInput || 'unset', md: minHeightInput }
  },
});

export const ModalContentStyle: {
  _heading?: TypographyProps;
  _tag?: TagProps;
} = {
  _tag: {
    fontSize: '2rem',
    lineHeight: '1',
    // wordBreak: 'break-word',
    whiteSpace: 'wrap',
    textAlign: 'center',
    fontFamily: 'OliRegular',
    color: 'rgb(90, 160, 120)',
    background: 'white',
    padding: '0.8rem 3rem',
    marginBottom: '1rem',
    borderRadius: '0.5rem',
  },
  _heading: {
    fontSize: '1.6rem',
    lineHeight: '2rem',
  }
};
