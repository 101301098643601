import { DropTarget, DropTargetSpec, DropTargetMonitor, DropTargetConnector } from 'react-dnd';
import { GAME_CARD } from '../Cards/DraggableCard/index';
import Options, { IOptions } from './component';
import { ICardValue } from '../data';

const cardTarget: DropTargetSpec<IOptions> = {
  drop: (props: IOptions, monitor: DropTargetMonitor) => {
    props.resetValue((monitor.getItem().value as ICardValue).value.id);
  },
};

const collect = (connect: DropTargetConnector, monitor: DropTargetMonitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  isOverCurrent: monitor.isOver({ shallow: true }),
  canDrop: monitor.canDrop(),
  itemType: monitor.getItemType(),
  isDragging: !!monitor.getItem(),
});

export default DropTarget(GAME_CARD, cardTarget, collect)(Options);
