import React, { FC, ReactNodeArray } from 'react';
import { comparisonData } from './data';
import { Modal, Heading2, Flex, Grid, Paragraph, List, ListItem, Icon, Button } from '@xcorejs/ui';
import { GreenTheme, yellowWhite, BlueTheme } from 'components/theme/colors';
import { FormattedMessage } from 'react-intl';
import { SVGFillColor } from 'components/pages/App/InterestingComparison/styles';
import { createUrl } from 'components/pages/routes';
import { CompareSVG } from '../../../icons/16/compare.svg';
import { Link, useHistory } from 'react-router-dom';
import FetchedComparisonText from './FetchedComparisonText';
import LadderInfoCard from './LadderInfoCard';
import useFetch from 'components/atoms/Fetch/useFetch';
import { JOURNAL } from 'config/apiRoutes';
import { IJournal } from 'components/pages/App/TestIntro/data';

export interface ComparisonModalProps {
  id: string;
  group: 'personal' | 'multiple';
  close: () => void;
}

const ComparisonModal: FC<ComparisonModalProps> = ({ id, group, close }) => {
  const data = comparisonData[group].find((item) => item.id === id);
  const history = useHistory();
  const { result, state } = useFetch<IJournal>(JOURNAL);

  if (!data) return (
    <Modal
      background={GreenTheme.primary}
      p={{ _: '4rem 1.6rem', sm: '4rem 4rem' }}
    >
      <Heading2>Toto porovnání neexistuje</Heading2>
    </Modal>
  );

  const redirectToLadder = (testId: string | undefined) => {
    history.push(`${createUrl('app.gameIntro')}?t=${testId}&redirect=0`);
    close();
  };
  
  return ( 
    <Modal
      background={data.backgroundColor}
      p={{ _: '4rem 1.6rem', sm: '4rem 4rem' }}
      alignItems='center'
    >
      <Heading2 marginTop='1rem' color={BlueTheme.text}>
        {data.title}
      </Heading2>
      
      <List
        variant='ordered'
        marginTop='2rem'
      >
        {data.steps.map((step, index) => (
          <ListItem
            key={index}
            justifyContent={{ _: 'flex-start', sm: 'center' }}
            marginBottom={index < data.steps.length - 1 ? '0.5rem !important' : '0 !important'}
            _before={{
              display: 'none',
            }}
            fontSize={{ _: '1.3rem', sm: '1.6rem' }}
            lineHeight='1.2'
            paddingLeft='0 !important'
            color='rgba(255, 255, 255, 0.7)'
            alignItems='center'
            flexDirection='column'
            textAlign='center'
          >
            {typeof step === 'string' ? 
              step
              : (
                <SVGFillColor color='white'>
                  {step}
                </SVGFillColor>
              )}
          </ListItem>
        ))}
      </List>

      <LadderInfoCard
        onClick={redirectToLadder} 
        data={data.comparison}
        journal={result}
        state={state}
        close={close}
      />

      <FetchedComparisonText data={data} />

      <Flex justifyContent='center' marginTop='2rem'>
        <Button
          background='rgba(12, 14, 15, 0.5)'
          color='white'
          height='auto'
          onClick={() => close()}
        >
          <FormattedMessage id='comparison.modal.close' />
        </Button>
      </Flex>
    </Modal>
  );
};
 
export default ComparisonModal;
