import React, { useEffect } from 'react';
import { feature } from 'topojson-client';
import { FeatureCollection, Geography, Marker } from './types';

const _markerData: Marker[] = [
  // Evropa
  {
    NAME: 'Czechia',
    x: 484,
    y: 86,
    country: 'Czech Republic',
    value: 'Nějaká hodnota',
    minScale: 55,
    zoomTo: {
      x: { pc: -7422, phone: -4273 },
      y: { pc: -1547, phone: -780 },
      scale: { pc: 14, phone: 26 }
    }
  },
  {
    NAME: 'Slovakia',
    x: 494,
    y: 89,
    value: 'Nějaká hodnota',
    minScale: 70,
    zoomTo: {
      x: { pc: 0, phone: -4389 },
      y: { pc: 0, phone: -847 },
      scale: { phone: 26 }
    },
  },
  {
    NAME: 'Germany',
    x: 475,
    y: 80,
    value: 'Nějaká hodnota',
    minScale: 5,
    zoomTo: {
      x: { pc: 0, phone: -4154 },
      y: { pc: 0, phone: -780 },
      scale: { pc: 0, phone: 26 }
    },
  },
  {
    NAME: 'Poland',
    x: 495,
    y: 80,
    value: 'Nějaká hodnota',
    minScale: 10,
    zoomTo: {
      x: { pc: 0, phone: -4380 },
      y: { pc: 0, phone: -697 },
      scale: { pc: 0, phone: 26 }
    },
  },
  {
    NAME: 'Denmark',
    x: 470,
    y: 70,
    value: 'Nějaká hodnota',
    minScale: 70,
    zoomTo: {
      x: { pc: 0, phone: -4165 },
      y: { pc: 0, phone: -612 },
      scale: { pc: 0, phone: 26 }
    },
  },
  {
    NAME: 'United Kingdom',
    x: 446,
    y: 78,
    value: 'Nějaká hodnota',
    minScale: 5,
    zoomTo: {
      x: { pc: 0, phone: -3825 },
      y: { pc: 0, phone: -612 },
      scale: { pc: 0, phone: 26 }
    },
  },
  {
    NAME: 'Ireland',
    x: 431,
    y: 77,
    value: 'Nějaká hodnota',
    minScale: 60,
    zoomTo: {
      x: { pc: 0, phone: -3689 },
      y: { pc: 0, phone: -670 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Netherlands',
    x: 462,
    y: 79,
    value: 'Nějaká hodnota',
    minScale: 65,
    zoomTo: {
      x: { pc: 0, phone: -4031 },
      y: { pc: 0, phone: -688 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Belgium',
    x: 461,
    y: 84,
    value: 'Nějaká hodnota',
    minScale: 70,
    zoomTo: {
      x: { pc: 0, phone: -4009 },
      y: { pc: 0, phone: -731 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Luxembourg',
    x: 464,
    y: 87,
    value: 'Nějaká hodnota',
    minScale: 95,
    zoomTo: {
      x: { pc: 0, phone: -4032 },
      y: { pc: 0, phone: -733 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'France',
    x: 455,
    y: 96,
    value: 'Nějaká hodnota',
    minScale: 5,
    zoomTo: {
      x: { pc: 0, phone: -3946 },
      y: { pc: 0, phone: -853 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Spain',
    x: 442,
    y: 114,
    value: 'Nějaká hodnota',
    minScale: 5,
    zoomTo: {
      x: { pc: 0, phone: -3834 },
      y: { pc: 0, phone: -1046 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Portugal',
    x: 429,
    y: 118,
    value: 'Nějaká hodnota',
    minScale: 30,
    zoomTo: {
      x: { pc: 0, phone: -3676 },
      y: { pc: 0, phone: -1122 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Andorra',
    x: 453,
    y: 108,
    value: 'Nějaká hodnota',
    minScale: 95,
    zoomTo: {
      x: { pc: 0, phone: -3942 },
      y: { pc: 0, phone: -978 },
      scale: { pc: 0, phone: 0 }
    }
  },
  {
    NAME: 'Monaco',
    x: 468,
    y: 104,
    value: 'Nějaká hodnota',
    minScale: 95,
    zoomTo: {
      x: { pc: 0, phone: -4089 },
      y: { pc: 0, phone: -907 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Italy',
    x: 475,
    y: 100,
    value: 'Nějaká hodnota',
    minScale: 5,
    zoomTo: {
      x: { pc: 0, phone: -4241 },
      y: { pc: 0, phone: -1009 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Switzerland',
    x: 468,
    y: 95,
    value: 'Nějaká hodnota',
    minScale: 60,
    zoomTo: {
      x: { pc: 0, phone: -4125 },
      y: { pc: 0, phone: -926 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Liechtenstein',
    x: 472,
    y: 94,
    value: 'Nějaká hodnota',
    minScale: 95,
    zoomTo: {
      x: { pc: 0, phone: -4142 },
      y: { pc: 0, phone: -858 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Austria',
    x: 484,
    y: 93,
    value: 'Nějaká hodnota',
    minScale: 60,
    zoomTo: {
      x: { pc: 0, phone: -4255 },
      y: { pc: 0, phone: -831 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Slovenia',
    x: 485,
    y: 98,
    value: 'Nějaká hodnota',
    minScale: 70,
    zoomTo: {
      x: { pc: 0, phone: -4278 },
      y: { pc: 0, phone: -866 },
      scale: { pc: 14, phone: 26 }
    }
  },
  {
    NAME: 'Croatia',
    x: 489,
    y: 99,
    value: 'Nějaká hodnota',
    minScale: 65,
    zoomTo: {
      x: { pc: 0, phone: -4328 },
      y: { pc: 0, phone: -883 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Bosnia and Herz.',
    x: 493,
    y: 103,
    value: 'Nějaká hodnota',
    minScale: 70,
    zoomTo: {
      x: { pc: 0, phone: -4378 },
      y: { pc: 0, phone: -913 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Serbia',
    x: 500,
    y: 103,
    value: 'Nějaká hodnota',
    minScale: 65,
    zoomTo: {
      x: { pc: 0, phone: -4430 },
      y: { pc: 0, phone: -927 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Montenegro',
    x: 496,
    y: 107,
    value: 'Nějaká hodnota',
    minScale: 85,
    zoomTo: {
      x: { pc: 0, phone: -4401 },
      y: { pc: 0, phone: -976 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Kosovo',
    x: 500,
    y: 108,
    value: 'Nějaká hodnota',
    minScale: 95,
    zoomTo: {
      x: { pc: 0, phone: -4431 },
      y: { pc: 0, phone: -1005 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Albania',
    x: 499,
    y: 113,
    value: 'Nějaká hodnota',
    minScale: 80,
    zoomTo: {
      x: { pc: 0, phone: -4446 },
      y: { pc: 0, phone: -1033 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'North Macedonia',
    x: 503,
    y: 111,
    country: 'N. Macedonia',
    value: 'Nějaká hodnota',
    minScale: 85,
    zoomTo: {
      x: { pc: 0, phone: -4470 },
      y: { pc: 0, phone: -1032 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Greece',
    x: 503,
    y: 117,
    value: 'Nějaká hodnota',
    minScale: 50,
    zoomTo: {
      x: { pc: 0, phone: -4496 },
      y: { pc: 0, phone: -1057 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Bulgaria',
    x: 511,
    y: 108,
    value: 'Nějaká hodnota',
    minScale: 45,
    zoomTo: {
      x: { pc: 0, phone: -4558 },
      y: { pc: 0, phone: -989 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Romania',
    x: 509,
    y: 98,
    value: 'Nějaká hodnota',
    minScale:40,
    zoomTo: {
      x: { pc: 0, phone: -4549 },
      y: { pc: 0, phone: -859 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Hungary',
    x: 495,
    y: 95,
    value: 'Nějaká hodnota',
    minScale: 55,
    zoomTo: {
      x: { pc: 0, phone: -4393 },
      y: { pc: 0, phone: -827 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Moldova',
    x: 518,
    y: 94,
    value: 'Nějaká hodnota',
    minScale: 75,
    zoomTo: {
      x: { pc: 0, phone: -4644 },
      y: { pc: 0, phone: -790 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Ukraine',
    x: 523,
    y: 88,
    value: 'Nějaká hodnota',
    minScale: 10,
    zoomTo: {
      x: { pc: 0, phone: -4697 },
      y: { pc: 0, phone: -727 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Belarus',
    x: 514,
    y: 75,
    value: 'Nějaká hodnota',
    minScale: 35,
    zoomTo: {
      x: { pc: 0, phone: -4605 },
      y: { pc: 0, phone: -639 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Lithuania',
    x: 504,
    y: 70,
    value: 'Nějaká hodnota',
    minScale: 75,
    zoomTo: {
      x: { pc: 0, phone: -4475 },
      y: { pc: 0, phone: -558 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Latvia',
    x: 505,
    y: 65,
    value: 'Nějaká hodnota',
    minScale: 70,
    zoomTo: {
      x: { pc: 0, phone: -4489 },
      y: { pc: 0, phone: -499 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Estonia',
    x: 507,
    y: 60,
    value: 'Nějaká hodnota',
    minScale: 80,
    zoomTo: {
      x: { pc: 0, phone: -4510 },
      y: { pc: 0, phone: -427 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Finland',
    x: 505,
    y: 50,
    value: 'Nějaká hodnota',
    minScale: 15,
    zoomTo: {
      x: { pc: 0, phone: -4504 },
      y: { pc: 0, phone: -310 },
      scale: { pc: 0, phone: 26 }
    },
  },
  {
    NAME: 'Sweden',
    x: 482,
    y: 50,
    value: 'Nějaká hodnota',
    minScale: 15,
    zoomTo: {
      x: { pc: 0, phone: -4289 },
      y: { pc: 0, phone: -359 },
      scale: { pc: 0, phone: 26 }
    },
  },
  {
    NAME: 'Norway',
    x: 469,
    y: 55,
    value: 'Nějaká hodnota',
    minScale: 15,
    zoomTo: {
      x: { pc: 0, phone: -4169 },
      y: { pc: 0, phone: -334 },
      scale: { pc: 0, phone: 26 }
    },
  },
  {
    NAME: 'Iceland',
    x: 412,
    y: 43,
    value: 'Nějaká hodnota',
    minScale: 30,
    zoomTo: {
      x: { pc: 0, phone: -3500 },
      y: { pc: 0, phone: -262 },
      scale: { pc: 0, phone: 26 }
    },
  },
  // Severní Amerika
  {
    NAME: 'Canada',
    x: 250,
    y: 80,
    value: 'Nějaká hodnota',
    minScale: 1,
    zoomTo: {
      x: { pc: 0, phone: -1714 },
      y: { pc: 0, phone: -696 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'United States of America',
    country: 'United States',
    x: 230,
    y: 130,
    value: 'Nějaká hodnota',
    minScale: 1,
    zoomTo: {
      x: { pc: 0, phone: -1586 },
      y: { pc: 0, phone: -1335 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Mexico',
    x: 185,
    y: 170,
    value: 'Nějaká hodnota',
    minScale: 20,
    zoomTo: {
      x: { pc: 0, phone: -1072 },
      y: { pc: 0, phone: -1721 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Cuba',
    x: 240,
    y: 170,
    value: 'Nějaká hodnota',
    minScale: 50,
    zoomTo: {
      x: { pc: 0, phone: -1715 },
      y: { pc: 0, phone: -1742 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Jamaica',
    x: 247,
    y: 184,
    value: 'Nějaká hodnota',
    minScale: 80,
    zoomTo: {
      x: { pc: 0, phone: -1702 },
      y: { pc: 0, phone: -1753 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Haiti',
    x: 258,
    y: 181,
    value: 'Nějaká hodnota',
    minScale: 70,
    zoomTo: {
      x: { pc: 0, phone: -1770 },
      y: { pc: 0, phone: -1743 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Dominican Rep.',
    x: 265,
    y: 181,
    value: 'Nějaká hodnota',
    minScale: 60,
    zoomTo: {
      x: { pc: 0, phone: -1886 },
      y: { pc: 0, phone: -1743 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Puerto Rico',
    x: 276,
    y: 184,
    value: 'Nějaká hodnota',
    minScale: 85,
    zoomTo: {
      x: { pc: 0, phone: -2012 },
      y: { pc: 0, phone: -1873 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Belize',
    x: 217,
    y: 186,
    value: 'Nějaká hodnota',
    minScale: 95,
    zoomTo: {
      x: { pc: 0, phone: -1381 },
      y: { pc: 0, phone: -1891 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Guatemala',
    x: 211,
    y: 193,
    value: 'Nějaká hodnota',
    minScale: 85,
    zoomTo: {
      x: { pc: 0, phone: -1337 },
      y: { pc: 0, phone: -1875 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Honduras',
    x: 221,
    y: 194,
    value: 'Nějaká hodnota',
    minScale: 75,
    zoomTo: {
      x: { pc: 0, phone: -1455 },
      y: { pc: 0, phone: -1958 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'El Salvador',
    x: 214,
    y: 198,
    value: 'Nějaká hodnota',
    minScale: 90,
    zoomTo: {
      x: { pc: 0, phone: -1382 },
      y: { pc: 0, phone: -1946 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Nicaragua',
    x: 224,
    y: 201,
    value: 'Nějaká hodnota',
    minScale: 75,
    zoomTo: {
      x: { pc: 0, phone: -1494 },
      y: { pc: 0, phone: -1955 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Costa Rica',
    x: 227,
    y: 209,
    value: 'Nějaká hodnota',
    minScale: 85,
    zoomTo: {
      x: { pc: 0, phone: -1520 },
      y: { pc: 0, phone: -2119 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Panama',
    x: 237,
    y: 213,
    value: 'Nějaká hodnota',
    minScale: 80,
    zoomTo: {
      x: { pc: 0, phone: -1613 },
      y: { pc: 0, phone: -2139 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Antigua and Barb.',
    x: 287,
    y: 187,
    value: 'Nějaká hodnota',
    minScale: 95,
    zoomTo: {
      x: { pc: 0, phone: -2133 },
      y: { pc: 0, phone: -1895 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Dominica',
    x: 288,
    y: 192,
    value: 'Nějaká hodnota',
    minScale: 95,
    zoomTo: {
      x: { pc: 0, phone: -2167 },
      y: { pc: 0, phone: -1930 },
      scale: { pc: 0, phone: 26 }
    }
  },
  // pořád chybí nějaký ostrůvky, ale teď se mi nechtějí hledat
  // Jižní Amerika
  {
    NAME: 'Colombia',
    x: 255,
    y: 229,
    value: 'Nějaká hodnota',
    minScale: 20,
    zoomTo: {
      x: { pc: 0, phone: -1500 },
      y: { pc: 0, phone: -1902 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Venezuela',
    x: 273,
    y: 215,
    value: 'Nějaká hodnota',
    minScale: 15,
    zoomTo: {
      x: { pc: 0, phone: -1670 },
      y: { pc: 0, phone: -1788 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Trinidad and Tobago',
    country: 'Trinidad and Tob.',
    x: 287,
    y: 208,
    value: 'Nějaká hodnota',
    minScale: 95,
    zoomTo: {
      x: { pc: 0, phone: -1796 },
      y: { pc: 0, phone: -1755 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Guyana',
    x: 292,
    y: 220,
    value: 'Nějaká hodnota',
    minScale: 70,
    zoomTo: {
      x: { pc: 0, phone: -1857 },
      y: { pc: 0, phone: -1865 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Suriname',
    x: 301,
    y: 227,
    value: 'Nějaká hodnota',
    minScale: 85,
    zoomTo: {
      x: { pc: 0, phone: -1924 },
      y: { pc: 0, phone: -1876 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Brazil',
    x: 300,
    y: 250,
    value: 'Nějaká hodnota',
    minScale: 5,
    zoomTo: {
      x: { pc: 0, phone: -2294 },
      y: { pc: 0, phone: -2526 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Ecuador',
    x: 240,
    y: 242,
    value: 'Nějaká hodnota',
    minScale: 40,
    zoomTo: {
      x: { pc: 0, phone: -1648 },
      y: { pc: 0, phone: -2469 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Peru',
    x: 248,
    y: 260,
    value: 'Nějaká hodnota',
    minScale: 20,
    zoomTo: {
      x: { pc: 0, phone: -1738 },
      y: { pc: 0, phone: -2606 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Bolivia',
    x: 280,
    y: 290,
    value: 'Nějaká hodnota',
    minScale: 15,
    zoomTo: {
      x: { pc: 0, phone: -2103 },
      y: { pc: 0, phone: -2990 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Paraguay',
    x: 295,
    y: 310,
    value: 'Nějaká hodnota',
    minScale: 50,
    zoomTo: {
      x: { pc: 0, phone: -2276 },
      y: { pc: 0, phone: -3193 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Argentina',
    x: 287,
    y: 332,
    value: 'Nějaká hodnota',
    minScale: 5,
    zoomTo: {
      x: { pc: 0, phone: -2160 },
      y: { pc: 0, phone: -3390 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Chile',
    x: 270,
    y: 312,
    value: 'Nějaká hodnota',
    minScale: 35,
    zoomTo: {
      x: { pc: 0, phone: -1971 },
      y: { pc: 0, phone: -3218 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Uruguay',
    x: 308,
    y: 342,
    value: 'Nějaká hodnota',
    minScale: 50,
    zoomTo: {
      x: { pc: 0, phone: -2390 },
      y: { pc: 0, phone: -3473 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Falkland Is.',
    x: 313,
    y: 399,
    value: 'Nějaká hodnota',
    minScale: 70,
    zoomTo: {
      x: { pc: 0, phone: -2384 },
      y: { pc: 0, phone: -4039 },
      scale: { pc: 0, phone: 26 }
    }
  },
  // Asie
  {
    NAME: 'Russia',
    x: 540,
    y: 60,
    value: 'Nějaká hodnota',
    minScale: 5,
    zoomTo: {
      x: { pc: 0, phone: -4877 },
      y: { pc: 0, phone: -566 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Turkey',
    x: 525,
    y: 120,
    value: 'Nějaká hodnota',
    minScale: 20,
    zoomTo: {
      x: { pc: 0, phone: -4697 },
      y: { pc: 0, phone: -1146 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Georgia',
    x: 555,
    y: 109,
    value: 'Nějaká hodnota',
    minScale: 80,
    zoomTo: {
      x: { pc: 0, phone: -5061 },
      y: { pc: 0, phone: -1062 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Armenia',
    x: 559,
    y: 115,
    value: 'Nějaká hodnota',
    minScale: 90,
    zoomTo: {
      x: { pc: 0, phone: -5079 },
      y: { pc: 0, phone: -1103 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Azerbaijan',
    x: 567,
    y: 114,
    value: 'Nějaká hodnota',
    minScale: 75,
    zoomTo: {
      x: { pc: 0, phone: -5167 },
      y: { pc: 0, phone: -1133 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Iran',
    x: 577,
    y: 131,
    value: 'Nějaká hodnota',
    minScale: 20,
    zoomTo: {
      x: { pc: 0, phone: -5285 },
      y: { pc: 0, phone: -1248 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Turkmenistan',
    x: 593,
    y: 117,
    value: 'Nějaká hodnota',
    minScale: 50,
    zoomTo: {
      x: { pc: 0, phone: -5489 },
      y: { pc: 0, phone: -1145 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Uzbekistan',
    x: 604,
    y: 110,
    value: 'Nějaká hodnota',
    minScale: 50,
    zoomTo: {
      x: { pc: 0, phone: -5570 },
      y: { pc: 0, phone: -1114 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Kazakhstan',
    x: 606,
    y: 89,
    value: 'Nějaká hodnota',
    minScale: 10,
    zoomTo: {
      x: { pc: 0, phone: -5582 },
      y: { pc: 0, phone: -821 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Kyrgyzstan',
    x: 631,
    y: 110,
    value: 'Nějaká hodnota',
    minScale: 70,
    zoomTo: {
      x: { pc: 0, phone: -5861 },
      y: { pc: 0, phone: -1132 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Tajikistan',
    x: 624,
    y: 121,
    value: 'Nějaká hodnota',
    minScale: 80,
    zoomTo: {
      x: { pc: 0, phone: -5806 },
      y: { pc: 0, phone: -1194 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Afghanistan',
    x: 615,
    y: 131,
    value: 'Nějaká hodnota',
    minScale: 30,
    zoomTo: {
      x: { pc: 0, phone: -5695 },
      y: { pc: 0, phone: -1293 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Pakistan',
    x: 629,
    y: 144,
    value: 'Nějaká hodnota',
    minScale: 25,
    zoomTo: {
      x: { pc: 0, phone: -5803 },
      y: { pc: 0, phone: -1364 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'India',
    x: 650,
    y: 162,
    value: 'Nějaká hodnota',
    minScale: 5,
    zoomTo: {
      x: { pc: 0, phone: -6053 },
      y: { pc: 0, phone: -1675 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Sri Lanka',
    x: 664,
    y: 216,
    value: 'Nějaká hodnota',
    minScale: 60,
    zoomTo: {
      x: { pc: 0, phone: -6206 },
      y: { pc: 0, phone: -2215 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Nepal',
    x: 665,
    y: 152,
    value: 'Nějaká hodnota',
    minScale: 70,
    zoomTo: {
      x: { pc: 0, phone: -6238 },
      y: { pc: 0, phone: -1565 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Bhutan',
    x: 682,
    y: 154,
    value: 'Nějaká hodnota',
    minScale: 90,
    zoomTo: {
      x: { pc: 0, phone: -6398 },
      y: { pc: 0, phone: -1615 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Bangladesh',
    x: 683,
    y: 165,
    value: 'Nějaká hodnota',
    minScale: 75,
    zoomTo: {
      x: { pc: 0, phone: -6411 },
      y: { pc: 0, phone: -1695 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Myanmar',
    x: 701,
    y: 173,
    value: 'Nějaká hodnota',
    minScale: 30,
    zoomTo: {
      x: { pc: 0, phone: -6611 },
      y: { pc: 0, phone: -1696 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Thailand',
    x: 716,
    y: 191,
    value: 'Nějaká hodnota',
    minScale: 45,
    zoomTo: {
      x: { pc: 0, phone: -6765 },
      y: { pc: 0, phone: -1933 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Laos',
    x: 717,
    y: 178,
    value: 'Nějaká hodnota',
    minScale: 70,
    zoomTo: {
      x: { pc: 0, phone: -6789 },
      y: { pc: 0, phone: -1851 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Vietnam',
    x: 723,
    y: 172,
    value: 'Nějaká hodnota',
    minScale: 60,
    zoomTo: {
      x: { pc: 0, phone: -6864 },
      y: { pc: 0, phone: -1830 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Cambodia',
    x: 727,
    y: 201,
    value: 'Nějaká hodnota',
    minScale: 65,
    zoomTo: {
      x: { pc: 0, phone: -6909 },
      y: { pc: 0, phone: -2085 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Malaysia',
    x: 721,
    y: 226,
    value: 'Nějaká hodnota',
    minScale: 75,
    zoomTo: {
      x: { pc: 0, phone: -6833 },
      y: { pc: 0, phone: -2381 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Mongolia',
    x: 694,
    y: 96,
    value: 'Nějaká hodnota',
    minScale: 30,
    zoomTo: {
      x: { pc: 0, phone: -6540 },
      y: { pc: 0, phone: -948 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'China',
    x: 710,
    y: 120,
    value: 'Nějaká hodnota',
    minScale: 5,
    zoomTo: {
      x: { pc: 0, phone: -6697 },
      y: { pc: 0, phone: -1256 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Taiwan',
    x: 763,
    y: 166,
    value: 'Nějaká hodnota',
    minScale: 70,
    zoomTo: {
      x: { pc: 0, phone: -7275 },
      y: { pc: 0, phone: -1710 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Japan',
    x: 796,
    y: 126,
    value: 'Nějaká hodnota',
    minScale: 30,
    zoomTo: {
      x: { pc: 0, phone: -7593 },
      y: { pc: 0, phone: -1250 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'South Korea',
    x: 769,
    y: 127,
    value: 'Nějaká hodnota',
    minScale: 50,
    zoomTo: {
      x: { pc: 0, phone: -7351 },
      y: { pc: 0, phone: -1297 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'North Korea',
    x: 761,
    y: 115,
    value: 'Nějaká hodnota',
    minScale: 50,
    zoomTo: {
      x: { pc: 0, phone: -7269 },
      y: { pc: 0, phone: -1200 },
      scale: { pc: 0, phone: 26 }
    }
  },
  // Austrálie a Oceánie
  {
    NAME: 'Philippines',
    x: 776,
    y: 206,
    value: 'Nějaká hodnota',
    minScale: 30,
    zoomTo: {
      x: { pc: 0, phone: -7424 },
      y: { pc: 0, phone: -2139 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Brunei',
    x: 755,
    y: 226,
    value: 'Nějaká hodnota',
    minScale: 80,
    zoomTo: {
      x: { pc: 0, phone: -7187 },
      y: { pc: 0, phone: -2386 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Indonesia',
    x: 770,
    y: 246,
    value: 'Nějaká hodnota',
    minScale: 15,
    zoomTo: {
      x: { pc: 0, phone: -7347 },
      y: { pc: 0, phone: -2589 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Timor-Leste',
    x: 784,
    y: 267,
    value: 'Nějaká hodnota',
    minScale: 75,
    zoomTo: {
      x: { pc: 0, phone: -7505 },
      y: { pc: 0, phone: -2829 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Papua New Guinea',
    x: 836,
    y: 259,
    value: 'Nějaká hodnota',
    minScale: 25,
    zoomTo: {
      x: { pc: 0, phone: -8075 },
      y: { pc: 0, phone: -2744 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Solomon Is.',
    x: 874,
    y: 269,
    value: 'Nějaká hodnota',
    minScale: 80,
    zoomTo: {
      x: { pc: 0, phone: -8484 },
      y: { pc: 0, phone: -2816 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Australia',
    x: 800,
    y: 322,
    value: 'Nějaká hodnota',
    minScale: 5,
    zoomTo: {
      x: { pc: 0, phone: -7674 },
      y: { pc: 0, phone: -3430 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'New Zealand',
    x: 870,
    y: 370,
    value: 'Nějaká hodnota',
    minScale: 35,
    zoomTo: {
      x: { pc: 0, phone: -8410 },
      y: { pc: 0, phone: -3866 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'New Caledonia',
    x: 880,
    y: 305,
    value: 'Nějaká hodnota',
    minScale: 65,
    zoomTo: {
      x: { pc: 0, phone: -8535 },
      y: { pc: 0, phone: -3206 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Vanuatu',
    x: 891,
    y: 288,
    value: 'Nějaká hodnota',
    minScale: 70,
    zoomTo: {
      x: { pc: 0, phone: -8672 },
      y: { pc: 0, phone: -3056 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Fiji',
    x: 918,
    y: 294,
    value: 'Nějaká hodnota',
    minScale: 70,
    zoomTo: {
      x: { pc: 0, phone: -8953 },
      y: { pc: 0, phone: -3102 },
      scale: { pc: 0, phone: 26 }
    }
  },
  // Afrika
  {
    NAME: 'Syria',
    x: 547,
    y: 131,
    value: 'Nějaká hodnota',
    minScale: 50,
    zoomTo: {
      x: { pc: 0, phone: -4957 },
      y: { pc: 0, phone: -1373 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Iraq',
    x: 558,
    y: 136,
    value: 'Nějaká hodnota',
    minScale: 45,
    zoomTo: {
      x: { pc: 0, phone: -5076 },
      y: { pc: 0, phone: -1401 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Kuwait',
    x: 571,
    y: 149,
    value: 'Nějaká hodnota',
    minScale: 75,
    zoomTo: {
      x: { pc: 0, phone: -5218 },
      y: { pc: 0, phone: -1535 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Saudi Arabia',
    x: 566,
    y: 156,
    value: 'Nějaká hodnota',
    minScale: 20,
    zoomTo: {
      x: { pc: 0, phone: -5172 },
      y: { pc: 0, phone: -1651 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Qatar',
    x: 582,
    y: 161,
    value: 'Nějaká hodnota',
    minScale: 85,
    zoomTo: {
      x: { pc: 0, phone: -5326 },
      y: { pc: 0, phone: -1712 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'United Arab Emirates',
    country: 'United Arab Em.',
    x: 590,
    y: 166,
    value: 'Nějaká hodnota',
    minScale: 70,
    zoomTo: {
      x: { pc: 0, phone: -5413 },
      y: { pc: 0, phone: -1739 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Oman',
    x: 598,
    y: 171,
    value: 'Nějaká hodnota',
    minScale: 60,
    zoomTo: {
      x: { pc: 0, phone: -5504 },
      y: { pc: 0, phone: -1798 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Yemen',
    x: 575,
    y: 191,
    value: 'Nějaká hodnota',
    minScale: 50,
    zoomTo: {
      x: { pc: 0, phone: -5267 },
      y: { pc: 0, phone: -2001 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Lebanon',
    x: 540,
    y: 134,
    value: 'Nějaká hodnota',
    minScale: 90,
    zoomTo: {
      x: { pc: 0, phone: -4873 },
      y: { pc: 0, phone: -1407 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Palestine',
    x: 539,
    y: 141,
    value: 'Nějaká hodnota',
    minScale: 95,
    zoomTo: {
      x: { pc: 0, phone: -4866 },
      y: { pc: 0, phone: -1463 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Israel',
    x: 538,
    y: 146,
    value: 'Nějaká hodnota',
    minScale: 75,
    zoomTo: {
      x: { pc: 0, phone: -4866 },
      y: { pc: 0, phone: -1463 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Jordan',
    x: 544,
    y: 142,
    value: 'Nějaká hodnota',
    minScale: 55,
    zoomTo: {
      x: { pc: 0, phone: -4930 },
      y: { pc: 0, phone: -1495 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Egypt',
    x: 528,
    y: 157,
    value: 'Nějaká hodnota',
    minScale: 30,
    zoomTo: {
      x: { pc: 0, phone: -4765 },
      y: { pc: 0, phone: -1602 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Libya',
    x: 493,
    y: 154,
    value: 'Nějaká hodnota',
    minScale: 20,
    zoomTo: {
      x: { pc: 0, phone: -4391 },
      y: { pc: 0, phone: -1554 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Tunisia',
    x: 474,
    y: 136,
    value: 'Nějaká hodnota',
    minScale: 55,
    zoomTo: {
      x: { pc: 0, phone: -4177 },
      y: { pc: 0, phone: -1397 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Algeria',
    x: 458,
    y: 138,
    value: 'Nějaká hodnota',
    minScale: 15,
    zoomTo: {
      x: { pc: 0, phone: -3990 },
      y: { pc: 0, phone: -1435 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Morocco',
    x: 434,
    y: 140,
    value: 'Nějaká hodnota',
    minScale: 30,
    zoomTo: {
      x: { pc: 0, phone: -3729 },
      y: { pc: 0, phone: -1421 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Mauritania',
    x: 421,
    y: 176,
    value: 'Nějaká hodnota',
    minScale: 20,
    zoomTo: {
      x: { pc: 0, phone: -3575 },
      y: { pc: 0, phone: -1853 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Mali',
    x: 444,
    y: 181,
    value: 'Nějaká hodnota',
    minScale: 15,
    zoomTo: {
      x: { pc: 0, phone: -3853 },
      y: { pc: 0, phone: -1935 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Niger',
    x: 477,
    y: 179,
    value: 'Nějaká hodnota',
    minScale: 10,
    zoomTo: {
      x: { pc: 0, phone: -4206 },
      y: { pc: 0, phone: -1877 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Chad',
    x: 500,
    y: 181,
    value: 'Nějaká hodnota',
    minScale: 10,
    zoomTo: {
      x: { pc: 0, phone: -4445 },
      y: { pc: 0, phone: -1898 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Sudan',
    x: 530,
    y: 181,
    value: 'Nějaká hodnota',
    minScale: 10,
    zoomTo: {
      x: { pc: 0, phone: -4766 },
      y: { pc: 0, phone: -1892 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Eritrea',
    x: 550,
    y: 190,
    value: 'Nějaká hodnota',
    minScale: 65,
    zoomTo: {
      x: { pc: 0, phone: -4981 },
      y: { pc: 0, phone: -1999 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Djibouti',
    x: 562,
    y: 204,
    value: 'Nějaká hodnota',
    minScale: 95,
    zoomTo: {
      x: { pc: 0, phone: -5106 },
      y: { pc: 0, phone: -2172 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Somaliland',
    x: 572,
    y: 209,
    value: 'Nějaká hodnota',
    minScale: 80,
    zoomTo: {
      x: { pc: 0, phone: -5225 },
      y: { pc: 0, phone: -2221 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Somalia',
    x: 575,
    y: 223,
    value: 'Nějaká hodnota',
    minScale: 70,
    zoomTo: {
      x: { pc: 0, phone: -5248 },
      y: { pc: 0, phone: -2271 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Ethiopia',
    x: 555,
    y: 214,
    value: 'Nějaká hodnota',
    minScale: 25,
    zoomTo: {
      x: { pc: 0, phone: -5044 },
      y: { pc: 0, phone: -2200 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Kenya',
    x: 551,
    y: 233,
    value: 'Nějaká hodnota',
    minScale: 35,
    zoomTo: {
      x: { pc: 0, phone: -5001 },
      y: { pc: 0, phone: -2466 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Uganda',
    x: 537,
    y: 234,
    value: 'Nějaká hodnota',
    minScale: 75,
    zoomTo: {
      x: { pc: 0, phone: -4858 },
      y: { pc: 0, phone: -2477 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'S. Sudan',
    x: 530,
    y: 217,
    value: 'Nějaká hodnota',
    minScale: 40,
    zoomTo: {
      x: { pc: 0, phone: -4761 },
      y: { pc: 0, phone: -2242 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Central African Rep.',
    x: 503,
    y: 218,
    value: 'Nějaká hodnota',
    minScale: 35,
    zoomTo: {
      x: { pc: 0, phone: -4484 },
      y: { pc: 0, phone: -2264 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Cameroon',
    x: 483,
    y: 224,
    value: 'Nějaká hodnota',
    minScale: 45,
    zoomTo: {
      x: { pc: 0, phone: -4276 },
      y: { pc: 0, phone: -2304 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Nigeria',
    x: 472,
    y: 210,
    value: 'Nějaká hodnota',
    minScale: 20,
    zoomTo: {
      x: { pc: 0, phone: -4160 },
      y: { pc: 0, phone: -2149 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Benin',
    x: 456,
    y: 206,
    value: 'Nějaká hodnota',
    minScale: 80,
    zoomTo: {
      x: { pc: 0, phone: -3962 },
      y: { pc: 0, phone: -2159 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Togo',
    x: 452,
    y: 214,
    value: 'Nějaká hodnota',
    minScale: 90,
    zoomTo: {
      x: { pc: 0, phone: -3922 },
      y: { pc: 0, phone: -2207 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Ghana',
    x: 447,
    y: 219,
    value: 'Nějaká hodnota',
    minScale: 70,
    zoomTo: {
      x: { pc: 0, phone: -3872 },
      y: { pc: 0, phone: -2205 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Côte d\'Ivoire',
    x: 435,
    y: 216,
    value: 'Nějaká hodnota',
    minScale: 60,
    zoomTo: {
      x: { pc: 0, phone: -3756 },
      y: { pc: 0, phone: -2222 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Liberia',
    x: 426,
    y: 223,
    value: 'Nějaká hodnota',
    minScale: 80,
    zoomTo: {
      x: { pc: 0, phone: -3634 },
      y: { pc: 0, phone: -2256 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Sierra Leone',
    x: 418,
    y: 214,
    value: 'Nějaká hodnota',
    minScale: 90,
    zoomTo: {
      x: { pc: 0, phone: -3558 },
      y: { pc: 0, phone: -2193 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Guinea',
    x: 420,
    y: 206,
    value: 'Nějaká hodnota',
    minScale: 50,
    zoomTo: {
      x: { pc: 0, phone: -3578 },
      y: { pc: 0, phone: -2154 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Guinea-Bissau',
    x: 410,
    y: 203,
    value: 'Nějaká hodnota',
    minScale: 90,
    zoomTo: {
      x: { pc: 0, phone: -3488 },
      y: { pc: 0, phone: -2095 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Gambia',
    x: 409,
    y: 198,
    value: 'Nějaká hodnota',
    minScale: 95,
    zoomTo: {
      x: { pc: 0, phone: -3454 },
      y: { pc: 0, phone: -2050 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Senegal',
    x: 410,
    y: 192,
    value: 'Nějaká hodnota',
    minScale: 75,
    zoomTo: {
      x: { pc: 0, phone: -3450 },
      y: { pc: 0, phone: -2040 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Burkina Faso',
    x: 445,
    y: 199,
    value: 'Nějaká hodnota',
    minScale: 50,
    zoomTo: {
      x: { pc: 0, phone: -3832 },
      y: { pc: 0, phone: -2100 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Eq. Guinea',
    x: 477,
    y: 234,
    value: 'Nějaká hodnota',
    minScale: 95,
    zoomTo: {
      x: { pc: 0, phone: -4185 },
      y: { pc: 0, phone: -2469 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Gabon',
    x: 481,
    y: 240,
    value: 'Nějaká hodnota',
    minScale: 55,
    zoomTo: {
      x: { pc: 0, phone: -4245 },
      y: { pc: 0, phone: -2550 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Congo',
    x: 492,
    y: 238,
    value: 'Nějaká hodnota',
    minScale: 45,
    zoomTo: {
      x: { pc: 0, phone: -4361 },
      y: { pc: 0, phone: -2485 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Dem. Rep. Congo',
    x: 513,
    y: 238,
    value: 'Nějaká hodnota',
    minScale: 15,
    zoomTo: {
      x: { pc: 0, phone: -4580 },
      y: { pc: 0, phone: -2496 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Rwanda',
    x: 529,
    y: 246,
    value: 'Nějaká hodnota',
    minScale: 95,
    zoomTo: {
      x: { pc: 0, phone: -4747 },
      y: { pc: 0, phone: -2520 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Burundi',
    x: 529,
    y: 252,
    value: 'Nějaká hodnota',
    minScale: 95,
    zoomTo: {
      x: { pc: 0, phone: -4747 },
      y: { pc: 0, phone: -2582 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Tanzania',
    x: 541,
    y: 256,
    value: 'Nějaká hodnota',
    minScale: 20,
    zoomTo: {
      x: { pc: 0, phone: -4885 },
      y: { pc: 0, phone: -2650 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Mozambique',
    x: 550,
    y: 287,
    value: 'Nějaká hodnota',
    minScale: 15,
    zoomTo: {
      x: { pc: 0, phone: -4933 },
      y: { pc: 0, phone: -2989 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Malawi',
    x: 539,
    y: 281,
    value: 'Nějaká hodnota',
    minScale: 65,
    zoomTo: {
      x: { pc: 0, phone: -4853 },
      y: { pc: 0, phone: -2929 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Zambia',
    x: 524,
    y: 284,
    value: 'Nějaká hodnota',
    minScale: 30,
    zoomTo: {
      x: { pc: 0, phone: -4695 },
      y: { pc: 0, phone: -2920 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Angola',
    x: 495,
    y: 278,
    value: 'Nějaká hodnota',
    minScale: 10,
    zoomTo: {
      x: { pc: 0, phone: -4403 },
      y: { pc: 0, phone: -2860 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Namibia',
    x: 495,
    y: 307,
    value: 'Nějaká hodnota',
    minScale: 10,
    zoomTo: {
      x: { pc: 0, phone: -4379 },
      y: { pc: 0, phone: -3228 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'South Africa',
    x: 514,
    y: 324,
    value: 'Nějaká hodnota',
    minScale: 5,
    zoomTo: {
      x: { pc: 0, phone: -4582 },
      y: { pc: 0, phone: -3429 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Lesotho',
    x: 522,
    y: 331,
    value: 'Nějaká hodnota',
    minScale: 85,
    zoomTo: {
      x: { pc: 0, phone: -4672 },
      y: { pc: 0, phone: -3438 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'eSwatini',
    x: 531,
    y: 322,
    value: 'Nějaká hodnota',
    minScale: 85,
    zoomTo: {
      x: { pc: 0, phone: -4731 },
      y: { pc: 0, phone: -3330 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Botswana',
    x: 514,
    y: 307,
    value: 'Nějaká hodnota',
    minScale: 30,
    zoomTo: {
      x: { pc: 0, phone: -4579 },
      y: { pc: 0, phone: -3223 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Zimbabwe',
    x: 528,
    y: 297,
    value: 'Nějaká hodnota',
    minScale: 30,
    zoomTo: {
      x: { pc: 0, phone: -4745 },
      y: { pc: 0, phone: -3113 },
      scale: { pc: 0, phone: 26 }
    }
  },
  {
    NAME: 'Madagascar',
    x: 571,
    y: 301,
    value: 'Nějaká hodnota',
    minScale: 15,
    zoomTo: {
      x: { pc: 0, phone: -5209 },
      y: { pc: 0, phone: -3109 },
      scale: { pc: 0, phone: 26 }
    }
  },
];

export const markerData = _markerData.sort((a, b) => a.NAME.localeCompare(b.NAME));

export const useD3map = () => {
  const [geographies, setGeographies] = React.useState<Geography[]>([]);
  const geoUrl = '/topojson/geo.json';

  useEffect(() => {
    fetch(geoUrl)
      .then((response) => { 
        if (response.status === 200)
          return response.json();
        else
          throw new Error('Failed to fetch data');
      })
      .then((data) => {
        // knihovna neví co tvoří asi, takže simple přetypování
        const f = feature(data, data.objects.ne_50m_admin_0_countries) as unknown as FeatureCollection;
        setGeographies(f.features); 
      })
      .catch((error) => {
        console.error(error);
      });
  
  }, []);

  return geographies;
};


/**
 * Funkce pro výpočet pozice markeru na mapě
 * @param mapWidth šířka mapy
 * @param mapHeight výška mapy
 * @param maxScale maximální přiblížení mapy
 * @param markerObj
 * @param deviceType zatím není implementovaný
 * @returns [x, y] souřadnice markeru na mapě
 */
export const calcMapPosition = (
  mapWidth: number, mapHeight: number, maxScale: number, markerObj: Marker
): [number, number] => {
  // eslint-disable-next-line no-extra-parens
  const scale = (markerObj.zoomTo.scale && markerObj.zoomTo.scale.phone) || maxScale;
  const BASE_SCREEN = 360; // základní šířka telefonu při které se vše počítá, stejně jako u nastavování Markerů
  const BASE_X = 0.3; // excel tabulka
  // hodnoty se dají získat z element.getBoundingClientRect() funkce, ale ta zobrazí správné data až po přiblížení, které nemusí být aktivní, proto to počítám ručně zde
  const BASE_X_OFFSET = BASE_SCREEN * BASE_X; // levá hrana mapy, mění se kvůli svg viewBoxu
  const BASE_Y_OFFSET = 150; // horní hrana mapy
  const BASE_WIDTH = BASE_SCREEN * scale; // šířka mapy při základním rozlišení a maximálním zoomu
  const BASE_HEIGHT = 180 * scale;

  const [x, y] = [markerObj.zoomTo.x.phone, markerObj.zoomTo.y.phone];

  const calcX = (x - BASE_X_OFFSET) * (mapWidth * scale) / BASE_WIDTH + mapWidth * BASE_X;
  const calcY = (y - BASE_Y_OFFSET) * (mapHeight * scale) / BASE_HEIGHT + BASE_Y_OFFSET;

  return [calcX, calcY];
};

export const scaleToPercentage = (scale: number, minScale: number, maxScale: number) => {
  const clampedScale = Math.min(Math.max(scale, minScale), maxScale);

  // Calculate the percentage
  const percentage = (clampedScale - minScale) / (maxScale - minScale) * 100;

  return percentage;
};
