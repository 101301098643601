export type ButtonProps = {
  text: string;
  background?: string;
  icon?: string;
  href?: string;
  redirectAfterRegistration?: boolean;
}

export type LinkProps = {
  text: string;
  link?: string;
  newWindow?: boolean;
}

export type GradientProps = {
  color1: string;
  color2: string;
  color3: string;
  color4: string;
  speed?: string;
}

export type BackgroundAnimatedOverlayProps = {
  imageUrl: string;
  opacity: number;
  repeat: {
    phone: string;
    desktop: string;
  };
  size: {
    phone: string;
    desktop: string;
  };
}

export type Section1Props = {
 backgroundImg: string;
 backgroundImgThinner: string;
 backgroundImg2: string;
 backgroundImg2Mobile: string;
 backgroundImg2Tablet: string;
 backgroundAnimatedOverlay?: BackgroundAnimatedOverlayProps;
 gradient?: GradientProps;
 img: string;
 imgMobile: string;
 imgThinner: string;
 imgThinnerMobile: string;
 header: string;
 subtitle: string;
 text?: string;
 button?: ButtonProps;
 link?: LinkProps;
 eticiaPlus?: boolean;
 bottomText?: string;
}

export type Section2Props = {
 bgColor: string;
 backgroundImg: string;
 header: string;
 subheader: string;
 subtitle: string;
 downtext?: string;
}

export type Section3Props = {
 backgroundImg: string;
 text: string;
 header: string;
 subtitle: string;
 button: ButtonProps;
}

export type Section4Props = {
 backgroundColor: string;
 image: string;
 header: string;
 subtitle: string;
 boxHeaderText: string;
 box1: {
   text: string;
   img: string;
   width: string;
   height: string;
 };
 box2: {
   text: string;
   img: string;
   width: string;
   height: string;
 };
 box3: {
   text: string;
   img: string;
   width: string;
   height: string;
 };
 boxBottomText?: string;
}

export type Section4aProps = {
  backgroundColor: string;
  text: string;
  image: string;
}

export type Section5Props = {
 backgroundColor: string;
 header: string;
 text1: string;
 text2: string;
 text3: string;
 downtext: string;
 customIcon1?: string;
 customIcon2?: string;
 customIcon3?: string;
}

export type Section6Props = {
 backgroundColor: string;
 image: string;
 header: string;
 subtitle: string;
 image2: string;
 image2Mobile: string;
}

export type Section7Props = {
 backgroundColor: string;
 image: string;
 header: string;
 link1: string;
 link1Url: string;
 link2: string;
 link2Url: string;
 subtitle: string;
}

export type Section8Props = {
  backgroundColor: string;
  heading: string;
  subheading: string;
  linkText: string;
}

export type Section9Props = {
  gradient?: GradientProps;
  backgroundImg: string;
  heading: string;
  subheading: string;
  text: string;
  button: ButtonProps;
  icon: string;
}

export type Section10Props = {
  backgroundImage: string;
  backgroundColor: string;
  heading: string;
  text: string;
  plusHeading: string;
  list: string[][];
  plusOther: string;
  button: ButtonProps;
  gradient?: GradientProps;
}

export type Section11Props = {
  backgroundColor: string;
  heading: string;
  subheading: string;
  box1: {
    heading: string;
    text: string;
    text2: string;
    text3: string;
    price: string;
    buyBtn: string;
    voucherBtn: string;
    moreLink: string;
  };
  box2: {
    heading: string;
    text: string;
    text2: string;
    text3: string;
    price: string;
    buyBtn: string;
    voucherBtn: string;
    moreLink: string;
  };
  box3: {
    heading: string;
    text: string;
    text2: string;
    text3: string;
    text4: string;
    price: string;
    buyBtn: string;
    voucherBtn: string;
    moreLink: string;
    text5: string;
    text6: string;
  };
  button: ButtonProps;
  info: string;
}

export type Section12Props = {
  backgroundColor: string;
  heading: string;
  text: string;
  btn1: string;
  text2: string;
}

export type Section13Props = {
  backgroundColor: string;
  heading: string;
  text: string;
  button: ButtonProps;
  linkText: string;
}

const DefaultSection9: Section9Props = {
  backgroundImg: 'section9.jpg',
  heading: 'Velká <strong>hodnotová hra</strong> Eticia 21',
  subheading: 'Výprava do světa hodnot',
  text: 'Zahraj si online dobrodružnou hru, plnou zábavy i poznání. Čeká tě víc než 40 zastávek plných napětí, překvapení, otázek...a hlavně hodnot. Že jsou hodnoty vážná věc? To ano, ale i život je tak trochu hra :)',
  button: {
    text: 'Zjisti více'
  },
  icon: 'string',
  gradient: {
    color1: 'rgba(0, 0, 0, 0.3)',
    color2: 'rgba(38, 61, 34, 0.3)',
    color3: 'rgba(0, 0, 0, 0.3)',
    color4: 'rgba(38, 61, 34, 0.3)',
    speed: '5s'
  },
};

export const DefaultSection10: Section10Props = {
  backgroundImage: 'pozadi_plus1.jpg',
  backgroundColor: 'radial-gradient(circle at top, #BE4C7C 0%, #7B0265 100%)',
  heading: 'Užij si plnou verzi a objevuj víc. Již od 121,- Kč.',
  text: 'S Eticia Plus si můžeš vytvářet speciální hodnotové žebříčky pro nejrůznější otázky a životní situace. Získáš vždy podrobné vyhodnocení a také možnost jednotlivé žebříčky mezi sebou detailně porovnat. Navíc zjistíš, kdo má podobné hodnoty jako ty a můžeš si užít objevování společných hodnot s partnerem, kamarády, rodinou či spolupracovníky.',
  plusHeading: 'Co v <span style="color: #ffde71; text-decoration: underline; text-underline-offset: 0.5rem">Eticia Plus</span> najdeš?',
  list: [
    [
      'Kompletní vyhodnocení',
      'book_160x160.png',
    ],
    [
      'Speciální hodnotové žebříčky',
      'Ladder_160x160.png',
    ],
    [
      'Źivotní hodnoty v plném vydání',
      'sand_clock_160x160.png',
    ],
    [
      'Podrobné hodnotové grafy',
      'chart_160x160.png',
    ],
    [
      'Statistiky a výsledky anket',
      'statistic_160x160.png',
    ],
    [
      'Porovnání žebříčků a sledování změn',
      'compare_160x160.png',
    ],
  ],
  plusOther: 'a ještě něco dalšího...',
  button: {
    text: 'zjisti víc'
  },
  gradient: {
    color1: 'rgba(142, 50, 90, 0.5)',
    color2: 'rgb(142, 50, 90, 0.1)',
    color3: 'rgba(142, 50, 90, 0.5)',
    color4: 'rgba(142, 50, 90, 0.1)',
    speed: '10s'
  },
};

export const DefaultSection11: Section11Props = {
  backgroundColor: 'radial-gradient(circle at top, #FFFFFF 0%, #EBEBEB 100%)',
  heading: 'Pořiď si členství Eticia Plus,<br> nebo ho věnuj jako dárek',
  subheading: 'Udělej svým blízkým radost skutečnými hodnotami. Nehmotné dárky jsou skvělé a s Eticia Plus navíc podpoříš i další dobré věci.*',
  box1: {
    heading: 'na 1 rok',
    text: '<strong>Plný přístup do Eticia </strong><br/>obsahuje všechny funkcionality',
    text2: '<strong>+ 1 žebříček navíc</strong><br/> podle tvého výběru',
    text3: '+ 1x životní hodnoty<br/>s kompletním vyhodnocením',
    price: '121,- Kč',
    buyBtn: 'Koupit',
    voucherBtn: 'Koupit jako dárek',
    moreLink: 'Více informací'
  },
  box2: {
    heading: 'na 3 roky',
    text: '<strong>Plný přístup do Eticia </strong><br/>obsahuje všechny funkcionality',
    text2: '<strong>+ 3 žebříčky</strong><br/> podle tvého výběru',
    text3: '+ 3x životní hodnoty<br/>s kompletním vyhodnocením',
    price: '249,- Kč',
    buyBtn: 'Koupit',
    voucherBtn: 'Koupit jako dárek',
    moreLink: 'Více informací',
  },
  box3: {
    heading: 'na 6 let',
    text: '<strong>Výjimečné členství pro ty,</strong> kteří si chtějí užívat Eticia naplno',
    text2: '<strong>+ 6 žebříčků</strong><br/> podle tvého výběru',
    text3: '<strong>+ 1x </strong><br/> Strom společných hodnot',
    text4: 'Navždy životní hodnoty s kompletním vyhodnocením',
    price: '777,- Kč *',
    buyBtn: 'Koupit',
    voucherBtn: 'Koupit jako dárek',
    moreLink: 'Více informací',
    text5: '* každým Eticia Plus pomáháš',
    text6: 'zakoupením tohoto členství do fondu znesnaze.cz částkou 73,- Kč',
  },
  button: {
    text: 'Více informací o Eticia Plus'
  },
  info: 'Členství ti zašleme ve formě poukazu do tvého e-mailu. Ten si následně aktivuješ na stránkách Eticia, nebo ho můžeš zaslat jako dárek někomu jinému. <br/><br/> *Zakoupením Eticia Plus nám pomůžeš rozvíjet Eticia dál. A navíc spolu s námi přispěješ v rámci vybrané sbírky Znesnáze21 těm, kdo to opravdu potřebují. Příliš dobrých věcí najednou? To určitě zvládneš! Kladných hodnot není nikdy dost :)',
};

const DefaultSection12: Section12Props = {
  backgroundColor: 'radial-gradient(circle at top, #071F2A 0%, #071F2A 100%)',
  heading: 'O Eticia 21',
  text: 'Smyslem naší práce je zprostředkovat přístupnou formou téma hodnot a jejich místa v našem životě co nejširšímu publiku. Proč to děláme? Protože jsme přesvědčeni, že nejen osobní spokojenost a štěstí každého z nás s hodnotami souvisí, ale i o tom, že diskuze o hodnotách má a bude mít v 21.století zásadní roli pro směřování celé naší společnosti. Snažíme se vytvořit nástroj, který pomůže lidem si vlastní hodnoty nejen lépe uvědomovat ve svém osobním životě, ale i o nich společně lépe komunikovat. Hodnoty totiž utvářejí náš lidský svět. Nikdy je nepřestávejme hledat.',
  btn1: 'Více o projektu',
  text2: 'Eticia 21 je český projekt, který se již brzy chystá i do světa.',
};

const DefaultSection13: Section13Props = {
  backgroundColor: 'radial-gradient(circle at top, #727189 0%, #727189 100%)',
  heading: '',
  text: '<strong>Základní verze Eticia 21 je pro každého zdarma.</strong><span style="color: #D8CCD9">Její součástí jsou žebříčky # Mé hodnoty,  # Životní hodnoty a také online hodnotová hra Výprava do světa hodnot.</span>',
  button: {
    text: ''
  },
  linkText: 'Úvodní stránka Eticia 21'
};

export type PageProps = {
 url?: string;
 doNotDuplicateFirstSection?: boolean;
 section1?: Section1Props;
 section2?: Section2Props;
 section3?: Section3Props;
 section4?: Section4Props;
 section4a?: Section4aProps;
 section5?: Section5Props;
 section6?: Section6Props;
 section7?: Section7Props;
 section8?: Section8Props;
 section9?: Section9Props;
 section10?: Section10Props;
 section11?: Section11Props;
 section12?: Section12Props;
 section13?: Section13Props;
}


const Page0: PageProps = {
  section1: {
    header: 'Jak já dosáhnu svého cíle?',
    backgroundImg: 'spokojenost.png',
    backgroundImgThinner: 'spokojenost.png',
    backgroundImg2: 'spokojenost2.png',
    backgroundImg2Mobile: 'spokojenost2Mobile.png',
    backgroundImg2Tablet: 'spokojenost2Tablet.png',
    gradient: {
      color1: 'rgba(7, 2, 8, 0.5)',
      color2: 'rgba(0, 23, 194, 0.5)',
      color3: 'rgba(7, 2, 8, 0.5)',
      color4: 'rgba(0, 23, 194, 0.5)',
      speed: '10s'
    },
    img: '0_Me_hodnoty_icon__1_.png',
    imgMobile: '0_Me_hodnoty_icon__1_.png',
    imgThinner: '0_Me_hodnoty_icon__1_.png',
    imgThinnerMobile: '0_Me_hodnoty_icon__1_.png',
    subtitle: 'Hledej hodnoty, které ti na cestě k němu pomohou.',
    button: {
      text: 'Blíž k cíli',
      background: '#999',
      icon: 'ladder.png'
    },
    text: 'Vytvořit žebříček',
    eticiaPlus: false,
    bottomText: 'aaaaa'
  },
  section2: {
    bgColor: 'radial-gradient(circle at top, rgba(187,204,249,1) 0%, rgba(152,176,242,1) 100%)',
    backgroundImg: '0_Me_hodnoty_o_hodnotach.png',
    header: 'Sen? Zase o něco splnitelnější...',
    subheader: 'Hodnoty tě k němu povedou.',
    subtitle: 'Mít jasné cíle, vznešené ideály a pevný záměr je důležité. Ale samo o sobě to úspěch našeho konání ještě nezaručí. Abychom došli k jakémukoli cíli, potřebujeme znát cestu, mít určité schopností a získat dovedností, které nám pomohou překonat překážky, na které narazíme. Najdi si vhodné pomocníky mezi hodnotami a vyber ty, na které považuješ za dobré se na své cestě spolehnout.',
    downtext: 'Za vším hledej hodnoty.'
  },
  section3: {
    backgroundImg: 'section9.jpg',
    text: 'Tento hodnotový žebříček je také součástí hry, kterou si můžeš v Eticia 21 <strong>zdarma zahrát</strong>',
    header: 'Výprava do světa hodnot',
    subtitle: 'Online dobrodružná hra',
    button: {
      text: 'Zjistit více'
    }
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'ikona_univerz.png',
    header: 'Co je pro mě <strong>opravdu důležité?</strong>',
    subtitle: 'Chceš vědět, na čem v životě opravdu záleží? Co utváří tvůj pohled na svět a ovlivňuje tvá rozhodování?',
    boxHeaderText: 'Mé hodnoty',
    box1: {
      text: 'Vytvoření žebříčku ti může pomoct snadno se orientovat v tvém vnitřním rozpoložení',
      img: '1_butt_s4.png',
      width: '4rem',
      height: '4rem'
    },
    box2: {
      text: 'Usnadní ti rozhodování v&nbsp;každodenním životě i v&nbsp;dlouhodobém směřování',
      img: '2_butt_s4.png',
      width: '4rem',
      height: '4rem'
    },
    box3: {
      text: 'A navíc - věnovat se chvíli jen sám sobě a svým myšlenkám je skvělé',
      img: '3_butt_s4.png',
      width: '4rem',
      height: '4rem'
    }
  },
  section4a: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'ikona_univerz.png',
    text: 'A Lorem ipsum dor et alk xjvz asove vlygcrbv wzmvgeklb eugvoeg'
  },
  section5: {
    backgroundColor: 'radial-gradient(circle at top, #E7DCA5 0%, #D7C87F 100%)',
    header: 'Co tě čeká?',
    text1: 'Výběr ze 42 pečlivě vybraných životních hodnot',
    text2: 'Několik zajímavých otázek směřujících k tomu důležitému v tvém životě',
    text3: 'Podrobné vyhodnocení s osobním hodnotovým etikonem, charakteristikami a grafem',
    downtext: 'Opakování žebříčku zdarma vždy po 365 dnech a možnost sledování vývoje důležitých životních hodnot v dlouhodobém horizontu',
    customIcon1: 'ikona_univerz.png',
    customIcon2: 'ikona_univerz.png',
    customIcon3: 'ikona_univerz.png'
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #EE8662 0%, #D47756 100%)',
    image: 'ikona_univerz.png',
    header: 'Jak se vyvíjí tvé <strong>hodnoty v čase</strong>?',
    subtitle: 'Naše hodnoty se mění. Naše indikátory spokojenosti také. Některé hodnoty nám zůstávají, jiné se proměňují. Díky unikátnímu algoritmu můžeš sledovat vývoj svých hodnot i změny v grafech hodnotových preferencí.',
    image2: 'zo_graph_.png',
    image2Mobile: 'zo_graph_.png'
  },
  section7: {
    backgroundColor: 'radial-gradient(circle, #90A5BD 0%, #697B90 100%)',
    image: 'ikona_univerz.png',
    header: 'Tento žebříček můžeš také porovnat se svými hodnotami',
    link1: 'Moje spokojenost',
    link1Url: '',
    link2: 'Moje hodnoty',
    link2Url: '',
    subtitle: 'Každý žebříček je jiný a možná budeš překvapen, jak moc blízké či vzdálené jsou tvé hodnoty a jak se tvůj graf vykresluje jinak.',
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #4d6AA1 0%, #4d6AA1 99.92%)',
    heading: 'Chceš zjistit o sobě a svých hodnotách víc?',
    subheading: 'Objev 12 žebříčků pro tvoji spokojenost ',
    linkText: ''
  },
  section9: DefaultSection9,
  section10: DefaultSection10,
  section11: DefaultSection11,
  section12: DefaultSection12,
  section13: DefaultSection13
};

const Page1: PageProps = {
  section1: {
    header: 'PRÁVĚ TEĎ: Co je pro mě opravdu důležité?',
    backgroundImg: 'spokojenost.png',
    img: '//placehold.it/238x200',
    imgMobile: '//placehold.it/238x200',
    imgThinner: '//placehold.it/238x200',
    imgThinnerMobile: '//placehold.it/238x200',
    subtitle: 'Žebříček pro každodenní rozhodování či mimořádné situace',
    button: {
      text: 'Hodnoty tady a teď'
    },
    text: 'Vytvořit žebříček',

    backgroundImgThinner: 'spokojenost.png',
    backgroundImg2: 'spokojenost2.png',
    backgroundImg2Mobile: 'spokojenost2Mobile.png',
    backgroundImg2Tablet: 'spokojenost2Tablet.png',
    gradient: {
      color1: 'rgba(7, 2, 8, 0.5)',
      color2: 'rgba(0, 23, 194, 0.5)',
      color3: 'rgba(7, 2, 8, 0.5)',
      color4: 'rgba(0, 23, 194, 0.5)',
      speed: '10s'
    },
  },
  section2: {
    bgColor: 'radial-gradient(circle at top, rgba(187,204,249,1) 0%, rgba(152,176,242,1) 100%)',
    backgroundImg: '//placehold.it/238x200',
    header: 'Co je pro mě právě teď opravdu důležité?',
    subheader: 'Co je pro mě pro mě důležité dnes, zítra už platit nemusí...',
    subtitle: 'Život je neustále v pohybu, všechno je proměnlivé. Možná právě stojíš před důležitým životním rozhodnutím a nějaká nová situace změnila tvůj aktuální pohled na věc, kterou řešíš. Možná si jen potřebuješ uspořádat myšlenky s ohledem na současné dění. V takových situacích se totiž naše důležité hodnoty mohou v různé míře proměňovat a ovlivňovat / usměrňovat tak naše rozhodování.',
    downtext: 'Každá situace v životě si žádá své...'
  },
  section3: {
    backgroundImg: '//placehold.it/2560x330',
    text: 'Tento hodnotový žebříček je také součástí hry, kterou si můžeš v Eticia 21 <strong>zdarma zahrát</strong>',
    header: 'Výprava do světa hodnot',
    subtitle: 'Online dobrodružná hra',
    button: {
      text: 'Zjistit více'
    }
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'ikona_univerz.png',
    header: 'Zjisti, co je pro tebe <strong> důležité právě teď </strong>',
    subtitle: 'Rozhoduješ se o nové situaci v rodině, měníš zaměstnání či právě řešíš začínající vztah? Tento žebříček ti pomůže ujasnit si priority při aktuálně probíhajících životních změnách.',
    boxHeaderText: 'Hodnoty tady a teď',
    box1: {
      text: 'Zaměříš se na své pocity a potřeby, které máš v současné chvíli',
      img: '1_butt_s4.png',
      width: '4rem',
      height: '4rem'
    },
    box2: {
      text: 'Uvědomíš si, nakolik se tvé hodnotové priority mohou měnit',
      img: '2_butt_s4.png',
      width: '4rem',
      height: '4rem'
    },
    box3: {
      text: 'xxx',
      img: '3_butt_s4.png',
      width: '4rem',
      height: '4rem'
    }
  },
  section5: {
    backgroundColor: 'radial-gradient(circle at top, #E7DCA5 0%, #D7C87F 100%)',
    header: 'Co tě čeká?',
    text1: 'Výběr ze 42 pečlivě vybraných hodnot, podobně jako v testu Moje hodnoty',
    text2: 'Několik zajímavých otázek na aktuální životní situaci',
    text3: 'Možnost vytvořit si žebříček na téma, které mě právě teď zajímá',
    downtext: 'xxxx   (Poznáš tak hodnoty, které ovlivňují tvé rozhodování v konkrétních životních situacích)',
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #EE8662 0%, #D47756 100%)',
    image: 'ikona_univerz.png',
    header: 'Jak se vyvíjí tvé <strong>hodnoty v čase</strong>?',
    subtitle: 'Naše hodnoty se mění. Naše indikátory spokojenosti také. Některé hodnoty nám zůstávají, jiné se proměňují. Díky unikátnímu algoritmu můžeš sledovat vývoj tvých hodnot i změny v grafech hodnotových preferencí.',
    image2: 'zo_graph_.png',
    image2Mobile: 'zo_graph_.png'
  },
  section7: {
    backgroundColor: 'radial-gradient(circle, #90A5BD 0%, #697B90 100%)',
    image: 'ikona_univerz.png',
    header: 'Tento žebříček můžeš také porovnat se svými hodnotami',
    link1: 'Moje spokojenost',
    link1Url: '',
    link2: 'Moje hodnoty',
    link2Url: '',
    subtitle: 'Každý žebříček je jiný a možná budeš překvapen, jak moc blízké či vzdálené jsou tvé hodnoty a jak se tvůj graf vykresluje jinak.',
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #53C5A0 0%, #2CA981 99.92%)',
    heading: 'Chceš o svých hodnotách zjistit víc?',
    subheading: 'Vyzkoušej další z sady speciálních hodnotových žebříčků Eticia Plus.',
    linkText: ''
  },
};

const Page2: PageProps = {
  section1: {
    header: 'Životní hodnoty',
    backgroundImg: 'bila_3.jpg',
    img: '//placehold.it/238x200',
    imgMobile: '//placehold.it/238x200',
    imgThinner: '//placehold.it/238x200',
    imgThinnerMobile: '//placehold.it/238x200',
    subtitle: 'Svět se mění a my s ním... Ale jak moc?',
    button: {
      text: 'Mé hodnoty'
    },
    text: 'Vytvořit žebříček',

    backgroundImgThinner: 'spokojenost.png',
    backgroundImg2: 'spokojenost2.png',
    backgroundImg2Mobile: 'spokojenost2Mobile.png',
    backgroundImg2Tablet: 'spokojenost2Tablet.png',
    gradient: {
      color1: 'rgba(7, 2, 8, 0.5)',
      color2: 'rgba(0, 23, 194, 0.5)',
      color3: 'rgba(7, 2, 8, 0.5)',
      color4: 'rgba(0, 23, 194, 0.5)',
      speed: '10s'
    },
  },
  section2: {
    bgColor: 'radial-gradient(circle at top, rgba(187,204,249,1) 0%, rgba(152,176,242,1) 100%)',
    backgroundImg: '//placehold.it/238x200',
    header: 'Víš, které hodnoty tě provázejí dlouhodobě?',
    subheader: 'Některé naše hodnoty se mění v závislosti na nejrůznějších situacích, které zažíváme, jiné nás provázejí po dlouhá období, avšak ty nejdůležitější, ty nás provázejí třeba i celý život.',
    subtitle: 'Abys našel právě ty hodnoty, které jsou pro tebe důležité dlouhodobě, je potřeba se svému hodnotovému žebříčku věnovat pravidelně. S pomocí Eticia si ho můžeš každých 365 dní aktualizovat, abys zjsitil,  jakým způsobem se tvé hodnoty během roku proměnily. Tyto změny můžeš sledovat po celou dobu, kdy budeš Eticia používat ( doufáme, že nejméně do tvých 120 let!)',
    downtext: 'Hledat v životě právě ty trvalejší hodnoty je velmi užitečné a může to dokonce přispět k tvé dlouhodobé spokojenosti.'
  },
  section3: {
    backgroundImg: '//placehold.it/1920x330',
    text: 'Tento hodnotový žebříček je také součástí hry, kterou si můžeš v Eticia 21 <strong>zdarma zahrát</strong>',
    header: 'Výprava do světa hodnot',
    subtitle: 'Online dobrodružná hra',
    button: {
      text: 'Zjistit více'
    }
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'ikona_univerz.png',
    header: 'Životní hodnoty - více tvých žebříčků v čase',
    subtitle: '<strong>Životní hodnoty se skládají z tvých žebříčků "Mé hodnoty" za daný rok.</strong> ',
    boxHeaderText: 'Mé hodnoty / Životní hodnoty (?)',
    box1: {
      text: 'xxxx Získáš každoroční aktualizace tvých hodnot v Eticia zdarma',
      img: '1_butt_s4.png',
      width: '4rem',
      height: '4rem'
    },
    box2: {
      text: 'xxxx Objevíš své opravdu dlouhodobě důležité hodnoty',
      img: '2_butt_s4.png',
      width: '4rem',
      height: '4rem'
    },
    box3: {
      text: 'xxxx Můžeš vývoj svých životních hodnot sledovat i v horizontu mnoha let',
      img: '3_butt_s4.png',
      width: '4rem',
      height: '4rem'
    }
  },
  section5: {
    backgroundColor: 'radial-gradient(circle at top, #E7DCA5 0%, #D7C87F 100%)',
    header: 'Co tě čeká?',
    text1: 'Výběr z 42 hodnot, podobně jako v testu Moje hodnoty',
    text2: 'Několik zajímavých otázek ..........',
    text3: 'Podrobné vyhodnocení a informace k tématu',
    downtext: 'A také nahlédneš do statistik...',
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #EE8662 0%, #D47756 100%)',
    image: 'ikona_univerz.png',
    header: 'Jak se vyvíjí tvé <strong>hodnoty v čase</strong>?',
    subtitle: 'Naše hodnoty se mění. Naše indikátory spokojenosti také. Některé hodnoty nám zůstávají, jiné se proměňují. Díky unikátnímu algoritmu můžeš sledovat vývoj tvých hodnot i změny v grafech hodnotových preferencí.',
    image2: 'zo_graph_.png',
    image2Mobile: 'zo_graph_.png'
  },
  section7: {
    backgroundColor: 'radial-gradient(circle, #90A5BD 0%, #697B90 100%)',
    image: 'ikona_univerz.png',
    header: 'Tento žebříček můžeš také porovnat se svými hodnotami',
    link1: 'Moje spokojenost',
    link1Url: '',
    link2: 'Moje hodnoty',
    link2Url: '',
    subtitle: 'Každý žebříček je jiný a možná budeš překvapen, jak moc blízké či vzdálené jsou tvé hodnoty a jak se tvůj graf vykresluje jinak.',
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #53C5A0 0%, #2CA981 99.92%)',
    heading: 'Speciální hodnotové žebříčky Eticia Plus?',
    subheading: 'Vyzkoušej další z sady speciálních hodnotových žebříčků Eticia Plus.',
    linkText: ''
  },
};

const Page3: PageProps = {
  section1: {
    header: 'Co mě dělá spokojeným?',
    backgroundImg: '3_spokoj.jpg',
    img: 'zabr_spokojenost.png',
    imgMobile: 'zabr_spokojenost.png',
    imgThinner: 'zabr_spokojenost.png',
    imgThinnerMobile: 'zabr_spokojenost.png',
    subtitle: 'Poznávej hodnoty, které utvářejí tvou osobní pohodu a spokojenost.',
    button: {
      text: 'Moje spokojenost'
    },
    text: 'Vytvořit žebříček',
    backgroundImgThinner: 'spokojenost.png',
    backgroundImg2: 'spokojenost2.png',
    backgroundImg2Mobile: 'spokojenost2Mobile.png',
    backgroundImg2Tablet: 'spokojenost2Tablet.png',
    gradient: {
      color1: 'rgba(7, 2, 8, 0.5)',
      color2: 'rgba(0, 23, 194, 0.5)',
      color3: 'rgba(7, 2, 8, 0.5)',
      color4: 'rgba(0, 23, 194, 0.5)',
      speed: '10s'
    },
  },
  section2: {
    bgColor: 'radial-gradient(circle at top, rgba(187,204,249,1) 0%, rgba(152,176,242,1) 100%)',
    backgroundImg: '3_spok_lelek.png',
    header: 'Být opravdu spokojený je jednou z nejlepších věcí, které můžeš sám pro sebe i pro svět udělat!',
    subheader: 'Chvilkový pocit štěstí je fajn, ale jak se říká, je to "muška jenom zlatá”. Z dlouhodobého hlediska je v našem životě mnohem důležitější<strong> pocit celkové spokojenosti.</strong>”.',
    subtitle: 'Taková životní spokojenost se utváří v průběhu času a souvisí úzce s tím, zda se nám daří žít tak, jak opravdu chceme a považujeme za “dobré” a “správné”. Jinými slovy - souvisí s tím, <strong>nakolik se nám daří v životě naplňovat naše hodnoty.</strong> Někdy větší spokojenosti můžeme dosáhnout tak, že <strong>něco v našem životě změníme</strong>, a jindy naopak tím, že <strong>změníme svůj postoj</strong> k dané situaci.',
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: '3_spok_arrows.png',
    header: 'Co dělá <strong>spokojeným</strong> tebe?',
    subtitle: 'S pomocí Eticia 21 si můžeš sestavit speciální hodnotový žebříček, který ti pomůže si na tuto otázku opravdu podrobně odpovědět.',
    boxHeaderText: 'Moje spokojenost',
    box1: {
      text: 'Najdeš hodnoty, které k tvé spokojenosti nejvíce přispívají.',
      img: '1_butt_s4.png',
      width: '4rem',
      height: '4rem'
    },
    box2: {
      text: 'Objevíš zajímavé cesty, které tě k ní mohou dovést.',
      img: '2_butt_s4.png',
      width: '4rem',
      height: '4rem'
    },
    box3: {
      text: 'Zjistíš, co všechno se za slovem “spokojenost” může skrývat.',
      img: '3_butt_s4.png',
      width: '4rem',
      height: '4rem'
    }
  },
  section5: {
    backgroundColor: 'radial-gradient(circle at top, #E7DCA5 0%, #D7C87F 100%)',
    header: 'Co tě čeká?',
    text1: 'Výběr ze 42 hodnot, podobně jako v základním žebříčku # Mé hodnoty',
    text2: 'Několik zajímavých otázek ohledně tvé spokojenosti',
    text3: 'Podrobné vyhodnocení a informace k tématu',
    downtext: 'Díky statistikám Eticia také zjistíš, jak spokojenost vnímají druzí. Někdy to jsou opravdu zajímavá zjištění :).',
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #EE8662 0%, #D47756 100%)',
    image: 'ikona_univerz.png',
    header: 'Jak se vyvíjejí tvé <strong>hodnoty v čase</strong>?',
    subtitle: 'Změna je život… Naše potřeby, cíle i motivace se čas od času mění. A co nám přinášelo spokojenost před nějakou dobou, můžeme dnes vnímat jinak. Díky funkci porovnávání žebříčků v Eticia+ můžeš zjistit, jak se tvé vlastní vnímání spokojenosti vyvíjí.',
    image2: 'zo_graph_.png',
    image2Mobile: 'zo_graph_.png'
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #53C5A0 0%, #2CA981 99.92%)',
    heading: 'Chceš o svých hodnotách zjistit víc?',
    subheading: 'Vyzkoušej další z sady speciálních hodnotových žebříčků Eticia Plus.',
    linkText: ''
  },
};

const Page4: PageProps = {
  section1: {
    header: 'Čeho bych si přál víc?',
    backgroundImg: 'spokojenost.png',
    img: '//placehold.it/238x200',
    imgMobile: '//placehold.it/238x200',
    imgThinner: '//placehold.it/238x200',
    imgThinnerMobile: '//placehold.it/238x200',
    subtitle: 'Poznávej hodnoty, které jsou pro tebe v současné chvíli obzvlášť přitažlivé a zjisti, proč tomu tak je.',
    button: {
      text: 'Má přání'
    },
    text: 'Vytvořit žebříček',

    backgroundImgThinner: 'spokojenost.png',
    backgroundImg2: 'spokojenost2.png',
    backgroundImg2Mobile: 'spokojenost2Mobile.png',
    backgroundImg2Tablet: 'spokojenost2Tablet.png',
    gradient: {
      color1: 'rgba(7, 2, 8, 0.5)',
      color2: 'rgba(0, 23, 194, 0.5)',
      color3: 'rgba(7, 2, 8, 0.5)',
      color4: 'rgba(0, 23, 194, 0.5)',
      speed: '10s'
    },
  },
  section2: {
    bgColor: 'radial-gradient(circle at top, rgba(187,204,249,1) 0%, rgba(152,176,242,1) 100%)',
    backgroundImg: '//placehold.it/238x200',
    header: 'Za vším hledej hodnoty',
    subheader: 'Není těžké zavřít oči a představit si hned několik věcí, které bychom rádi měli, získali, nebo zažili…',
    subtitle: 'Za každým našim přáním se skrývají určité hodnoty. Ve skutečnosti jde totiž o ně a každá věc či událost v našem životě nám určitou hodnotu přináší. V životě se snažíme dosahovat konkrétních cílů, ale v hloubi duše toužíme právě po hodnotách, které pro nás tyto cíle představují. Objevovat hodnoty, které nás přitahují, znamená zjistit, proč jsou naše přání taková, jaká jsou.',
    downtext: 'xxx'
  },
  section3: {
    backgroundImg: '//placehold.it/1920x330',
    text: 'Tento hodnotový žebříček je také součástí hry, kterou si můžeš v Eticia 21 <strong>zdarma zahrát</strong>',
    header: 'Výprava do světa hodnot',
    subtitle: 'Online dobrodružná hra',
    button: {
      text: 'Zjistit více'
    }
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'ikona_univerz.png',
    header: 'A čeho si přeješ víc ty?',
    subtitle: 'S pomocí Eticia 21 si můžeš sestavit speciální hodnotový žebříček, který ti pomůže si na tuto otázku opravdu podrobně odpovědět.',
    boxHeaderText: 'Má přání',
    box1: {
      text: 'si uvědomíš, co doopravdy chceš a proč',
      img: '1_butt_s4.png',
      width: '4rem',
      height: '4rem'
    },
    box2: {
      text: 'zjistíš, proč je důležité věnovat pozornost svým přáním, ať už jsou splnitelná, anebo ne',
      img: '2_butt_s4.png',
      width: '4rem',
      height: '4rem'
    },
    box3: {
      text: 'a najdeš možná i úplně nové cesty, které tě ke splnění těch správných přání dovedou',
      img: '3_butt_s4.png',
      width: '4rem',
      height: '4rem'
    }
  },
  section5: {
    backgroundColor: 'radial-gradient(circle at top, #E7DCA5 0%, #D7C87F 100%)',
    header: 'Co tě čeká?',
    text1: 'Výběr ze 42 hodnot, podobně jako v testu Mé hodnoty',
    text2: 'Několik zajímavých otázek ohledně tvých přání',
    text3: 'Podrobné vyhodnocení a informace k tématu',
    downtext: 'Díky statistikám Eticia se dozvíš něco o přáních jiných lidí. Někdy to jsou opravdu zajímavá zjištění :)',
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #EE8662 0%, #D47756 100%)',
    image: 'ikona_univerz.png',
    header: 'Jak se <strong>tvá přání</strong> v průběhu života mění?',
    subtitle: 'Díky unikátnímu porovnání tvých hodnot se můžeš podívat, jak se tvá přání mění v čase a to i s odstupem několika let.',
    image2: '4_prani_1graf.png',
    image2Mobile: '4_prani_1graf.png'
  },
  section7: {
    backgroundColor: 'radial-gradient(circle, #90A5BD 0%, #697B90 100%)',
    image: 'ikona_univerz.png',
    header: 'Tento žebříček můžeš také porovnat se svými hodnotami',
    link1: 'Moje spokojenost',
    link1Url: '',
    link2: 'Moje hodnoty',
    link2Url: '',
    subtitle: 'Každý žebříček je jiný a možná budeš překvapen, jak moc blízké či vzdálené jsou tvé hodnoty a jak se tvůj graf vykresluje jinak.',
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #53C5A0 0%, #2CA981 99.92%)',
    heading: 'Chceš o svých hodnotách zjistit víc?',
    subheading: 'Vyzkoušej další z sady speciálních hodnotových žebříčků Eticia Plus.',
    linkText: ''
  },
};

const Page5: PageProps = {
  section1: {
    header: 'Jak dosáhnu svého cíle?',
    backgroundImg: 'spokojenost.png',
    img: '//placehold.it/238x200',
    imgMobile: '//placehold.it/238x200',
    imgThinner: '//placehold.it/238x200',
    imgThinnerMobile: '//placehold.it/238x200',
    subtitle: 'Hledej hodnoty, které ti na cestě k němu pomohou.',
    button: {
      text: 'Blíž k cíli'
    },
    text: 'Vytvořit žebříček',

    backgroundImgThinner: 'spokojenost.png',
    backgroundImg2: 'spokojenost2.png',
    backgroundImg2Mobile: 'spokojenost2Mobile.png',
    backgroundImg2Tablet: 'spokojenost2Tablet.png',
    gradient: {
      color1: 'rgba(7, 2, 8, 0.5)',
      color2: 'rgba(0, 23, 194, 0.5)',
      color3: 'rgba(7, 2, 8, 0.5)',
      color4: 'rgba(0, 23, 194, 0.5)',
      speed: '10s'
    },
  },
  section2: {
    bgColor: 'radial-gradient(circle at top, rgba(187,204,249,1) 0%, rgba(152,176,242,1) 100%)',
    backgroundImg: '//placehold.it/238x200',
    header: 'Sen? Zase o něco splnitelnější...',
    subheader: 'Hodnoty tě k němu povedou',
    subtitle: 'Abychom se k našim cílům přiblížili a dokázali naše sny realizovat, potřebujeme mít pevný záměr a určité schopností. A také získat potřebné dovedností, které nám pomohou překonat překážky, na které narazíme. Najdi si vhodné pomocníky mezi hodnotami a vyber ty, na které považuješ za dobré se na své cestě spolehnout. Uvidíš, že cesta za tvým snem bude s těmi správnými hodnotami mnohem snazší.',
    downtext: 'xx'
  },
  section3: {
    backgroundImg: '//placehold.it/1920x330',
    text: 'Tento hodnotový žebříček je také součástí hry, kterou si můžeš v Eticia 21 <strong>zdarma zahrát</strong>',
    header: 'Výprava do světa hodnot',
    subtitle: 'Online dobrodružná hra',
    button: {
      text: 'Zjistit více'
    }
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'ikona_univerz.png',
    header: 'Víš, čeho chceš dosáhnout a znáš svůj cíl?',
    subtitle: 'S pomocí tohoto žebříčku zjistíš, které hodnoty posilují tvou schopnost a zvyšují tvou šanci udělat a dokázat to, co opravdu chceš.',
    boxHeaderText: 'Blíž k cíli',
    box1: {
      text: 'xxxx    zjistíš, že některé hodnoty v žebříčku představují cíl, jiné zas cestu k onomu cíli',
      img: '1_butt_s4.png',
      width: '4rem',
      height: '4rem'
    },
    box2: {
      text: 'xxxx    uvědomíš si, že cesta i cíl mohou být stejně důležité',
      img: '2_butt_s4.png',
      width: '4rem',
      height: '4rem'
    },
    box3: {
      text: 'xxxx    prozkoumáš hodnoty, které ti pomáhají v dosahování tvých cílů',
      img: '3_butt_s4.png',
      width: '4rem',
      height: '4rem'
    }
  },
  section5: {
    backgroundColor: 'radial-gradient(circle at top, #E7DCA5 0%, #D7C87F 100%)',
    header: 'Co tě čeká?',
    text1: 'Výběr z 42 hodnot, podobně jako v testu Mé hodnoty',
    text2: 'Několik zajímavých otázek vztahujících se k cílům a cestám',
    text3: 'Podrobné vyhodnocení a informace k tématu',
    downtext: 'Výsledek můžeš porovnávat s dalšími žebříčky v tvém hodnotovém deníku',
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #EE8662 0%, #D47756 100%)',
    image: 'ikona_univerz.png',
    header: 'Jak se vyvíjí tvé <strong>hodnoty v čase</strong>?',
    subtitle: 'Naše hodnoty se mění. Naše indikátory spokojenosti také. Některé hodnoty nám zůstávají, jiné se proměňují. Díky unikátnímu algoritmu můžeš sledovat vývoj tvých hodnot i změny v grafech hodnotových preferencí.',
    image2: 'zo_graph_.png',
    image2Mobile: 'zo_graph_.png'
  },
  section7: {
    backgroundColor: 'radial-gradient(circle, #90A5BD 0%, #697B90 100%)',
    image: 'ikona_univerz.png',
    header: 'Tento žebříček můžeš také porovnat se svými hodnotami',
    link1: 'Moje spokojenost',
    link1Url: '',
    link2: 'Moje hodnoty',
    link2Url: '',
    subtitle: 'Každý žebříček je jiný a možná budeš překvapen, jak moc blízké či vzdálené jsou tvé hodnoty a jak se tvůj graf vykresluje jinak.',
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #53C5A0 0%, #2CA981 99.92%)',
    heading: 'Chceš o svých hodnotách zjistit víc?',
    subheading: 'Vyzkoušej další z sady speciálních hodnotových žebříčků Eticia Plus.',
    linkText: ''
  },
};

const Page6: PageProps = {
  section1: {
    header: 'Jaký by měl být můj “ideální” partner?',
    backgroundImg: 'spokojenost.png',
    img: '6_Muj_idealni_partner_icon.png',
    imgMobile: '6_Muj_idealni_partner_icon.png',
    imgThinner: '6_Muj_idealni_partner_icon.png',
    imgThinnerMobile: '6_Muj_idealni_partner_icon.png',
    subtitle: 'Každý má svůj ideál... ',
    button: {
      text: 'Můj ideální partner'
    },
    text: 'Vytvořit žebříček',

    backgroundImgThinner: 'spokojenost.png',
    backgroundImg2: 'spokojenost2.png',
    backgroundImg2Mobile: 'spokojenost2Mobile.png',
    backgroundImg2Tablet: 'spokojenost2Tablet.png',
    gradient: {
      color1: 'rgba(7, 2, 8, 0.5)',
      color2: 'rgba(0, 23, 194, 0.5)',
      color3: 'rgba(7, 2, 8, 0.5)',
      color4: 'rgba(0, 23, 194, 0.5)',
      speed: '10s'
    },
  },
  section2: {
    bgColor: 'radial-gradient(circle at top, rgba(187,204,249,1) 0%, rgba(152,176,242,1) 100%)',
    backgroundImg: '6_Muj_idealni_partner_o_hodnotach.png',
    header: 'Jaké hodnoty hledáš u svého partnera či partnerky?',
    subheader: 'I když všichni dobře víme, že “nikdo nejsme dokonalí”, tak naše představy o ideálu hrají při výběru životních partnerů velkou roli.',
    subtitle: 'Každý člověk má své kvality. K druhým lidem nás přitahují určité hodnoty, které na nich vidíme a shledáváme přitažlivými. Znát dobře svou představu a vědět, co od svého partnera vlastně očekáváš, může být velmi přínosné. A je úplně jedno, jestli v současné chvíli partnera máš, nebo hledáš, a nebo jen tak jednou někoho potkáš... Tenhle žebříček ti pomůže zjistit, po čem třeba v skrytu duše toužíš, a jaké hodnoty by měl naplňovat člověk, kterého si dovedeš představit ve své blízkosti.',
    downtext:  'Výsledek můžeš porovnávat s dalšími žebříčky v tvém hodnotovém deníku',
  },
  section3: {
    backgroundImg: '//placehold.it/1920x330',
    text: 'Tento hodnotový žebříček je také součástí hry, kterou si můžeš v Eticia 21 <strong>zdarma zahrát</strong>',
    header: 'Výprava do světa hodnot',
    subtitle: 'Online dobrodružná hra',
    button: {
      text: 'Zjistit více'
    }
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'ikona_univerz.png',
    header: 'Jaké hodnoty od svého “ideálního partnera” očekáváš ty?',
    subtitle: 'S pomocí Eticia 21 si můžeš sestavit speciální hodnotový žebříček, který ti pomůže si na tuto otázku opravdu podrobně odpovědět.',
    boxHeaderText: 'Můj ideální partner',
    box1: {
      text: 'Sice svého vysněného partnera nepotkáš, ale lépe si uvědomíš, jaký by měl vlastně být.',
      img: '1_butt_s4.png',
      width: '4rem',
      height: '4rem'
    },
    box2: {
      text: 'Objevíš hodnoty, které jsou pro tebe tak důležité, že přes ně prostě “nejede vlak” a naopak zjistíš, co třeba tak podstatné není…',
      img: '2_butt_s4.png',
      width: '4rem',
      height: '4rem'
    },
    box3: {
      text: 'Zjistíš něco i sám o sobě, protože ten pravý “ideál” nosíš ve své hlave právě ty sám.',
      img: '3_butt_s4.png',
      width: '4rem',
      height: '4rem'
    }
  },
  section5: {
    backgroundColor: 'radial-gradient(circle at top, #E7DCA5 0%, #D7C87F 100%)',
    header: 'Co tě čeká?',
    text1: 'výběr ze 42 hodnot, speciálně vybraných pro téma vztahů a partnerství',
    text2: 'několik nečekaných otázek ohledně tvého vnímání vztahů',
    text3: 'podrobné vyhodnocení včetně hodnotového grafu a informace k tématu',
    downtext: '+ výsledek můžeš porovnávat s dalšími žebříčky v tvém hodnotovém deníku',
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #EE8662 0%, #D47756 100%)',
    image: 'ikona_univerz.png',
    header: 'Jak se vyvíjí tvé <strong>hodnoty v čase</strong>?',
    subtitle: 'Naše hodnoty se mění. Naše indikátory spokojenosti také. Některé hodnoty nám zůstávají, jiné se proměňují. Díky unikátnímu algoritmu můžeš sledovat vývoj tvých hodnot i změny v grafech hodnotových preferencí.',
    image2: 'zo_graph_.png',
    image2Mobile: 'zo_graph_.png'
  },
  section7: {
    backgroundColor: 'radial-gradient(circle, #90A5BD 0%, #697B90 100%)',
    image: 'ikona_univerz.png',
    header: 'Tento žebříček můžeš také porovnat se svými hodnotami',
    link1: 'Moje spokojenost',
    link1Url: '',
    link2: 'Moje hodnoty',
    link2Url: '',
    subtitle: 'Každý žebříček je jiný a možná budeš překvapen, jak moc blízké či vzdálené jsou tvé hodnoty a jak se tvůj graf vykresluje jinak.',
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #53C5A0 0%, #2CA981 99.92%)',
    heading: 'Chceš o svých hodnotách zjistit víc?',
    subheading: 'Vyzkoušej další z sady speciálních hodnotových žebříčků Eticia Plus.',
    linkText: ''
  },
};

const Page7: PageProps = {
  section1: {
    header: 'Jaký vztah si přeji?',
    backgroundImg: 'spokojenost.png',
    img: '//placehold.it/238x200',
    imgMobile: '//placehold.it/238x200',
    imgThinner: '//placehold.it/238x200',
    imgThinnerMobile: '//placehold.it/238x200',
    subtitle: 'Vztahy tvoří nejen lidé, ale i jejich představy',
    button: {
      text: 'Báječný vztah'
    },
    text: 'Vytvořit žebříček',

    backgroundImgThinner: 'spokojenost.png',
    backgroundImg2: 'spokojenost2.png',
    backgroundImg2Mobile: 'spokojenost2Mobile.png',
    backgroundImg2Tablet: 'spokojenost2Tablet.png',
    gradient: {
      color1: 'rgba(7, 2, 8, 0.5)',
      color2: 'rgba(0, 23, 194, 0.5)',
      color3: 'rgba(7, 2, 8, 0.5)',
      color4: 'rgba(0, 23, 194, 0.5)',
      speed: '10s'
    },
  },
  section2: {
    bgColor: 'radial-gradient(circle at top, rgba(187,204,249,1) 0%, rgba(152,176,242,1) 100%)',
    backgroundImg: '//placehold.it/238x200',
    header: 'Každý z nás má svoji představu, jak by měl šťastný a spokojený partnerský vztah fungovat.',
    subheader: 'Vědět, co od partnerství očekáváš a umět o tom komunikovat, je opravdu důležité. Sestavení hodnotového žebříčku ti v tom může pomoct.',
    subtitle: 'Nezáleží na tom, jestli právě jsi, či nejsi v partnerském vztahu. Můžeš se víc zamyslet nad očekáváními, která máš ve svém současném vztahu, případně si udělat jasno v tom, jaké hodnoty bys jednou chtěl zažívat ve svém budoucím partnerství. A nebo si jednoduše jen tak... představit a pojmenovat hodnoty, které se ti v blízkém vztahu s druhým prostě jen líbí a jsou pro tebe důležité.',
    downtext: 'xxx'
  },
  section3: {
    backgroundImg: '//placehold.it/1920x330',
    text: 'Tento hodnotový žebříček je také součástí hry, kterou si můžeš v Eticia 21 <strong>zdarma zahrát</strong>',
    header: 'Výprava do světa hodnot',
    subtitle: 'Online dobrodružná hra',
    button: {
      text: 'Zjistit více'
    }
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'ikona_univerz.png',
    header: 'Jaké hodnoty by měl naplňovat tvůj <strong>báječný vztah?</strong>',
    subtitle: 'S pomocí Eticia 21 si můžeš sestavit speciální hodnotový žebříček, který ti pomůže si na tuto otázku odpovědět.',
    boxHeaderText: 'Báječný vztah',
    box1: {
      text: 'Podrobně prozkoumáš hodnoty, které jsou pro tebe v existujícím nebo potencionálním partnerství zásadní.',
      img: '1_butt_s4.png',
      width: '4rem',
      height: '4rem'
    },
    box2: {
      text: 'Odhalíš poměr mezi svým očekáváním a schopností jej v životě prakticky realizovat.',
      img: '2_butt_s4.png',
      width: '4rem',
      height: '4rem'
    },
    box3: {
      text: 'Zjistíš, že komunikovat o svých očekáváních s druhými není možná až tak obtížné, pokud dobře víš, co chceš.',
      img: '3_butt_s4.png',
      width: '4rem',
      height: '4rem'
    }
  },
  section5: {
    backgroundColor: 'radial-gradient(circle at top, #E7DCA5 0%, #D7C87F 100%)',
    header: 'Co tě čeká?',
    text1: 'Výběr ze 42 hodnot, podobně jako v základním žebříčku Mé hodnoty',
    text2: 'Několik zajímavých otázek ohledně vztahů a partnerství',
    text3: 'Podrobné vyhodnocení a informace k tématu',
    downtext: 'A navíc získáš přístup do statistik Eticia, kde tě čeká spousta nových zjištění o hodnotách ostatních uživatelů. Někdy je opravdu zajímavé zjistit, jak vnímají hodnoty druzí.',
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #EE8662 0%, #D47756 100%)',
    image: 'ikona_univerz.png',
    header: 'Jak se vyvíjí tvé <strong>hodnoty v čase</strong>?',
    subtitle: 'Naše hodnoty se mění. Naše indikátory spokojenosti také. Některé hodnoty nám zůstávají, jiné se proměňují. Díky unikátnímu algoritmu můžeš sledovat vývoj tvých hodnot i změny v grafech hodnotových preferencí.',
    image2: 'zo_graph_.png',
    image2Mobile: 'zo_graph_.png'
  },
  section7: {
    backgroundColor: 'radial-gradient(circle, #90A5BD 0%, #697B90 100%)',
    image: 'ikona_univerz.png',
    header: 'Tento žebříček můžeš také porovnat se svými hodnotami',
    link1: 'Moje spokojenost',
    link1Url: '',
    link2: 'Moje hodnoty',
    link2Url: '',
    subtitle: 'Každý žebříček je jiný a možná budeš překvapen, jak moc blízké či vzdálené jsou tvé hodnoty a jak se tvůj graf vykresluje jinak.',
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #53C5A0 0%, #2CA981 99.92%)',
    heading: 'Chceš o svých hodnotách zjistit víc?',
    subheading: 'Vyzkoušej další z sady speciálních hodnotových žebříčků Eticia Plus.',
    linkText: ''
  },
};

const Page8: PageProps = {
  section1: {
    header: 'Co mě dělá spokojeným?',
    backgroundImg: 'spokojenost.png',
    img: '//placehold.it/238x200',
    imgMobile: '//placehold.it/238x200',
    imgThinner: '//placehold.it/238x200',
    imgThinnerMobile: '//placehold.it/238x200',
    subtitle: 'string',
    button: {
      text: 'text'
    },
    text: 'Vytvořit žebříček',

    backgroundImgThinner: 'spokojenost.png',
    backgroundImg2: 'spokojenost2.png',
    backgroundImg2Mobile: 'spokojenost2Mobile.png',
    backgroundImg2Tablet: 'spokojenost2Tablet.png',
    gradient: {
      color1: 'rgba(7, 2, 8, 0.5)',
      color2: 'rgba(0, 23, 194, 0.5)',
      color3: 'rgba(7, 2, 8, 0.5)',
      color4: 'rgba(0, 23, 194, 0.5)',
      speed: '10s'
    },
  },
  section2: {
    bgColor: 'radial-gradient(circle at top, rgba(187,204,249,1) 0%, rgba(152,176,242,1) 100%)',
    backgroundImg: '//placehold.it/238x200',
    header: 'O spokojenosti',
    subheader: 'Chvilkový pocit <strong>štěstí je fajn</strong>, ale jak se říká, je to “muška jenom zlatá”.',
    subtitle: 'Z dlouhodobého hlediska je v našem životě mnohem důležitější pocit celkové spokojenosti. Taková životní spokojenost se utváří v průběhu času a souvisí úzce s tím, zda se nám daří žít tak, jak opravdu chceme a považujeme za “dobré” či “správné”. Spokojenost tedy úzce souvisí právě s našimi hodnotami a s tím, nakolik se nám daří je v životě naplňovat. Někdy větší spokojenosti můžeme dosáhnout tak, že něco v našem životě změníme, a jindy naopak tím, že změníme svůj postoj k dané situaci.',
    downtext: 'Štěstí může uletět, ale spokojenost se dá chytit...'
  },
  section3: {
    backgroundImg: '//placehold.it/1920x330',
    text: 'Tento hodnotový žebříček je také součástí hry, kterou si můžeš v Eticia 21 <strong>zdarma zahrát</strong>',
    header: 'Výprava do světa hodnot',
    subtitle: 'Online dobrodružná hra',
    button: {
      text: 'Zjistit více'
    }
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'ikona_univerz.png',
    header: 'Co dělá <strong>spokojeným</strong> právě tebe?',
    subtitle: 'Pomocí Eticia 21 si můžeš sestavit speciální hodnotový žebříček, který ti pomůže si na tuto otázku opravdu podrobně odpovědět.',
    boxHeaderText: 'Moje spokojenost',
    box1: {
      text: 'Najdeš hodnoty, které nejvíc přispívají k tvé <strong>spokojenosti</strong>',
      img: '1_butt_s4.png',
      width: '4rem',
      height: '4rem'
    },
    box2: {
      text: 'Začneš si vytvářet svou <strong>osobní strategii</strong> pro její dosahování',
      img: '2_butt_s4.png',
      width: '4rem',
      height: '4rem'
    },
    box3: {
      text: 'Zjstíš, proč je užitečné se své vlastní spokojenosti pravidelně věnovat',
      img: '3_butt_s4.png',
      width: '4rem',
      height: '4rem'
    }
  },
  section5: {
    backgroundColor: 'radial-gradient(circle at top, #E7DCA5 0%, #D7C87F 100%)',
    header: 'Co tě čeká?',
    text1: 'Výběr z 42 hodnot, podobně jako v testu Moje hodnoty',
    text2: 'Několik zajímavých otázek ohledně tvé spokojenosti',
    text3: 'Podrobné vyhodnocení a informace k tématu',
    downtext: 'A také nahlédneš do statistik pod pokličku spokojenosti...',
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #EE8662 0%, #D47756 100%)',
    image: 'ikona_univerz.png',
    header: 'Jak se vyvíjí tvé <strong>hodnoty v čase</strong>?',
    subtitle: 'Naše hodnoty se mění. Naše indikátory spokojenosti také. Některé hodnoty nám zůstávají, jiné se proměňují. Díky unikátnímu algoritmu můžeš sledovat vývoj tvých hodnot i změny v grafech hodnotových preferencí.',
    image2: 'zo_graph_.png',
    image2Mobile: 'zo_graph_.png',
  },
  section7: {
    backgroundColor: 'radial-gradient(circle, #90A5BD 0%, #697B90 100%)',
    image: 'ikona_univerz.png',
    header: 'Tento žebříček můžeš také porovnat se svými hodnotami',
    link1: 'Moje spokojenost',
    link1Url: '',
    link2: 'Moje hodnoty',
    link2Url: '',
    subtitle: 'Každý žebříček je jiný a možná budeš překvapen, jak moc blízké či vzdálené jsou tvé hodnoty a jak se tvůj graf vykresluje jinak.',
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #53C5A0 0%, #2CA981 99.92%)',
    heading: 'Chceš o svých hodnotách zjistit víc?',
    subheading: 'Vyzkoušej další z sady speciálních hodnotových žebříčků Eticia Plus.',
    linkText: ''
  },
};

const Page9: PageProps = {
  section1: {
    header: 'Co mě dělá spokojeným?',
    backgroundImg: 'spokojenost.png',
    img: '//placehold.it/238x200',
    imgMobile: '//placehold.it/238x200',
    imgThinner: '//placehold.it/238x200',
    imgThinnerMobile: '//placehold.it/238x200',
    subtitle: 'string',
    button: {
      text: 'text'
    },
    text: 'Vytvořit žebříček',

    backgroundImgThinner: 'spokojenost.png',
    backgroundImg2: 'spokojenost2.png',
    backgroundImg2Mobile: 'spokojenost2Mobile.png',
    backgroundImg2Tablet: 'spokojenost2Tablet.png',
    gradient: {
      color1: 'rgba(7, 2, 8, 0.5)',
      color2: 'rgba(0, 23, 194, 0.5)',
      color3: 'rgba(7, 2, 8, 0.5)',
      color4: 'rgba(0, 23, 194, 0.5)',
      speed: '10s'
    },
  },
  section2: {
    bgColor: 'radial-gradient(circle at top, rgba(187,204,249,1) 0%, rgba(152,176,242,1) 100%)',
    backgroundImg: '//placehold.it/238x200',
    header: 'O spokojenosti',
    subheader: 'Chvilkový pocit <strong>štěstí je fajn</strong>, ale jak se říká, je to “muška jenom zlatá”.',
    subtitle: 'Z dlouhodobého hlediska je v našem životě mnohem důležitější pocit celkové spokojenosti. Taková životní spokojenost se utváří v průběhu času a souvisí úzce s tím, zda se nám daří žít tak, jak opravdu chceme a považujeme za “dobré” či “správné”. Spokojenost tedy úzce souvisí právě s našimi hodnotami a s tím, nakolik se nám daří je v životě naplňovat. Někdy větší spokojenosti můžeme dosáhnout tak, že něco v našem životě změníme, a jindy naopak tím, že změníme svůj postoj k dané situaci.',
    downtext: 'Štěstí může uletět, ale spokojenost se dá chytit...'
  },
  section3: {
    backgroundImg: '//placehold.it/1920x330',
    text: 'Tento hodnotový žebříček je také součástí hry, kterou si můžeš v Eticia 21 <strong>zdarma zahrát</strong>',
    header: 'Výprava do světa hodnot',
    subtitle: 'Online dobrodružná hra',
    button: {
      text: 'Zjistit více'
    }
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'ikona_univerz.png',
    header: 'Co dělá <strong>spokojeným</strong> právě tebe?',
    subtitle: 'Pomocí Eticia 21 si můžeš sestavit speciální hodnotový žebříček, který ti pomůže si na tuto otázku opravdu podrobně odpovědět.',
    boxHeaderText: 'Moje spokojenost',
    box1: {
      text: 'Najdeš hodnoty, které nejvíc přispívají k tvé <strong>spokojenosti</strong>',
      img: '1_butt_s4.png',
      width: '4rem',
      height: '4rem'
    },
    box2: {
      text: 'Začneš si vytvářet svou <strong>osobní strategii</strong> pro její dosahování',
      img: '2_butt_s4.png',
      width: '4rem',
      height: '4rem'
    },
    box3: {
      text: 'Zjstíš, proč je užitečné se své vlastní spokojenosti pravidelně věnovat',
      img: '3_butt_s4.png',
      width: '4rem',
      height: '4rem'
    }
  },
  section5: {
    backgroundColor: 'radial-gradient(circle at top, #E7DCA5 0%, #D7C87F 100%)',
    header: 'Co tě čeká?',
    text1: 'Výběr z 42 hodnot, podobně jako v testu Moje hodnoty',
    text2: 'Několik zajímavých otázek ohledně tvé spokojenosti',
    text3: 'Podrobné vyhodnocení a informace k tématu',
    downtext: 'A také nahlédneš do statistik pod pokličku spokojenosti...',
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #EE8662 0%, #D47756 100%)',
    image: 'ikona_univerz.png',
    header: 'Jak se vyvíjí tvé <strong>hodnoty v čase</strong>?',
    subtitle: 'Naše hodnoty se mění. Naše indikátory spokojenosti také. Některé hodnoty nám zůstávají, jiné se proměňují. Díky unikátnímu algoritmu můžeš sledovat vývoj tvých hodnot i změny v grafech hodnotových preferencí.',
    image2: 'zo_graph_.png',
    image2Mobile: 'zo_graph_.png'
  },
  section7: {
    backgroundColor: 'radial-gradient(circle, #90A5BD 0%, #697B90 100%)',
    image: 'ikona_univerz.png',
    header: 'Tento žebříček můžeš také porovnat se svými hodnotami',
    link1: 'Moje spokojenost',
    link1Url: '',
    link2: 'Moje hodnoty',
    link2Url: '',
    subtitle: 'Každý žebříček je jiný a možná budeš překvapen, jak moc blízké či vzdálené jsou tvé hodnoty a jak se tvůj graf vykresluje jinak.',
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #53C5A0 0%, #2CA981 99.92%)',
    heading: 'Chceš o svých hodnotách zjistit víc?',
    subheading: 'Vyzkoušej další z sady speciálních hodnotových žebříčků Eticia Plus.',
    linkText: ''
  },
};

const Page10: PageProps = {
  section1: {
    header: 'Co mě dělá spokojeným?',
    backgroundImg: 'spokojenost.png',
    img: '//placehold.it/238x200',
    imgMobile: '//placehold.it/238x200',
    imgThinner: '//placehold.it/238x200',
    imgThinnerMobile: '//placehold.it/238x200',
    subtitle: 'string',
    button: {
      text: 'text'
    },
    text: 'Vytvořit žebříček',

    backgroundImgThinner: 'spokojenost.png',
    backgroundImg2: 'spokojenost2.png',
    backgroundImg2Mobile: 'spokojenost2Mobile.png',
    backgroundImg2Tablet: 'spokojenost2Tablet.png',
    gradient: {
      color1: 'rgba(7, 2, 8, 0.5)',
      color2: 'rgba(0, 23, 194, 0.5)',
      color3: 'rgba(7, 2, 8, 0.5)',
      color4: 'rgba(0, 23, 194, 0.5)',
      speed: '10s'
    },
  },
  section2: {
    bgColor: 'radial-gradient(circle at top, rgba(187,204,249,1) 0%, rgba(152,176,242,1) 100%)',
    backgroundImg: '//placehold.it/238x200',
    header: 'O spokojenosti',
    subheader: 'Chvilkový pocit <strong>štěstí je fajn</strong>, ale jak se říká, je to “muška jenom zlatá”.',
    subtitle: 'Z dlouhodobého hlediska je v našem životě mnohem důležitější pocit celkové spokojenosti. Taková životní spokojenost se utváří v průběhu času a souvisí úzce s tím, zda se nám daří žít tak, jak opravdu chceme a považujeme za “dobré” či “správné”. Spokojenost tedy úzce souvisí právě s našimi hodnotami a s tím, nakolik se nám daří je v životě naplňovat. Někdy větší spokojenosti můžeme dosáhnout tak, že něco v našem životě změníme, a jindy naopak tím, že změníme svůj postoj k dané situaci.',
    downtext: 'Štěstí může uletět, ale spokojenost se dá chytit...'
  },
  section3: {
    backgroundImg: '//placehold.it/1920x330',
    text: 'Tento hodnotový žebříček je také součástí hry, kterou si můžeš v Eticia 21 <strong>zdarma zahrát</strong>',
    header: 'Výprava do světa hodnot',
    subtitle: 'Online dobrodružná hra',
    button: {
      text: 'Zjistit více'
    }
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'ikona_univerz.png',
    header: 'Co dělá <strong>spokojeným</strong> právě tebe?',
    subtitle: 'Pomocí Eticia 21 si můžeš sestavit speciální hodnotový žebříček, který ti pomůže si na tuto otázku opravdu podrobně odpovědět.',
    boxHeaderText: 'Moje spokojenost',
    box1: {
      text: 'Najdeš hodnoty, které nejvíc přispívají k tvé <strong>spokojenosti</strong>',
      img: '1_butt_s4.png',
      width: '4rem',
      height: '4rem'
    },
    box2: {
      text: 'Začneš si vytvářet svou <strong>osobní strategii</strong> pro její dosahování',
      img: '2_butt_s4.png',
      width: '4rem',
      height: '4rem'
    },
    box3: {
      text: 'Zjstíš, proč je užitečné se své vlastní spokojenosti pravidelně věnovat',
      img: '3_butt_s4.png',
      width: '4rem',
      height: '4rem'
    }
  },
  section5: {
    backgroundColor: 'radial-gradient(circle at top, #E7DCA5 0%, #D7C87F 100%)',
    header: 'Co tě čeká?',
    text1: 'Výběr z 42 hodnot, podobně jako v testu Moje hodnoty',
    text2: 'Několik zajímavých otázek ohledně tvé spokojenosti',
    text3: 'Podrobné vyhodnocení a informace k tématu',
    downtext: 'A také nahlédneš do statistik pod pokličku spokojenosti...',
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #EE8662 0%, #D47756 100%)',
    image: 'ikona_univerz.png',
    header: 'Jak se vyvíjí tvé <strong>hodnoty v čase</strong>?',
    subtitle: 'Naše hodnoty se mění. Naše indikátory spokojenosti také. Některé hodnoty nám zůstávají, jiné se proměňují. Díky unikátnímu algoritmu můžeš sledovat vývoj tvých hodnot i změny v grafech hodnotových preferencí.',
    image2: 'zo_graph_.png',
    image2Mobile: 'zo_graph_.png'
  },
  section7: {
    backgroundColor: 'radial-gradient(circle, #90A5BD 0%, #697B90 100%)',
    image: 'ikona_univerz.png',
    header: 'Tento žebříček můžeš také porovnat se svými hodnotami',
    link1: 'Moje spokojenost',
    link1Url: '',
    link2: 'Moje hodnoty',
    link2Url: '',
    subtitle: 'Každý žebříček je jiný a možná budeš překvapen, jak moc blízké či vzdálené jsou tvé hodnoty a jak se tvůj graf vykresluje jinak.',
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #53C5A0 0%, #2CA981 99.92%)',
    heading: 'Chceš o svých hodnotách zjistit víc?',
    subheading: 'Vyzkoušej další z sady speciálních hodnotových žebříčků Eticia Plus.',
    linkText: ''
  },
};

const Page11: PageProps = {
  section1: {
    header: 'Co mě dělá spokojeným?',
    backgroundImg: 'spokojenost.png',
    img: '//placehold.it/238x200',
    imgMobile: '//placehold.it/238x200',
    imgThinner: '//placehold.it/238x200',
    imgThinnerMobile: '//placehold.it/238x200',
    subtitle: 'string',
    button: {
      text: 'text'
    },
    text: 'Vytvořit žebříček',

    backgroundImgThinner: 'spokojenost.png',
    backgroundImg2: 'spokojenost2.png',
    backgroundImg2Mobile: 'spokojenost2Mobile.png',
    backgroundImg2Tablet: 'spokojenost2Tablet.png',
    gradient: {
      color1: 'rgba(7, 2, 8, 0.5)',
      color2: 'rgba(0, 23, 194, 0.5)',
      color3: 'rgba(7, 2, 8, 0.5)',
      color4: 'rgba(0, 23, 194, 0.5)',
      speed: '10s'
    },
  },
  section2: {
    bgColor: 'radial-gradient(circle at top, rgba(187,204,249,1) 0%, rgba(152,176,242,1) 100%)',
    backgroundImg: '//placehold.it/238x200',
    header: 'O spokojenosti',
    subheader: 'Chvilkový pocit <strong>štěstí je fajn</strong>, ale jak se říká, je to “muška jenom zlatá”.',
    subtitle: 'Z dlouhodobého hlediska je v našem životě mnohem důležitější pocit celkové spokojenosti. Taková životní spokojenost se utváří v průběhu času a souvisí úzce s tím, zda se nám daří žít tak, jak opravdu chceme a považujeme za “dobré” či “správné”. Spokojenost tedy úzce souvisí právě s našimi hodnotami a s tím, nakolik se nám daří je v životě naplňovat. Někdy větší spokojenosti můžeme dosáhnout tak, že něco v našem životě změníme, a jindy naopak tím, že změníme svůj postoj k dané situaci.',
    downtext: 'Štěstí může uletět, ale spokojenost se dá chytit...'
  },
  section3: {
    backgroundImg: '//placehold.it/1920x330',
    text: 'Tento hodnotový žebříček je také součástí hry, kterou si můžeš v Eticia 21 <strong>zdarma zahrát</strong>',
    header: 'Výprava do světa hodnot',
    subtitle: 'Online dobrodružná hra',
    button: {
      text: 'Zjistit více'
    }
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'ikona_univerz.png',
    header: 'Co dělá <strong>spokojeným</strong> právě tebe?',
    subtitle: 'Pomocí Eticia 21 si můžeš sestavit speciální hodnotový žebříček, který ti pomůže si na tuto otázku opravdu podrobně odpovědět.',
    boxHeaderText: 'Moje spokojenost',
    box1: {
      text: 'Najdeš hodnoty, které nejvíc přispívají k tvé <strong>spokojenosti</strong>',
      img: '1_butt_s4.png',
      width: '4rem',
      height: '4rem'
    },
    box2: {
      text: 'Začneš si vytvářet svou <strong>osobní strategii</strong> pro její dosahování',
      img: '2_butt_s4.png',
      width: '4rem',
      height: '4rem'
    },
    box3: {
      text: 'Zjstíš, proč je užitečné se své vlastní spokojenosti pravidelně věnovat',
      img: '3_butt_s4.png',
      width: '4rem',
      height: '4rem'
    }
  },
  section5: {
    backgroundColor: 'radial-gradient(circle at top, #E7DCA5 0%, #D7C87F 100%)',
    header: 'Co tě čeká?',
    text1: 'Výběr z 42 hodnot, podobně jako v testu Moje hodnoty',
    text2: 'Několik zajímavých otázek ohledně tvé spokojenosti',
    text3: 'Podrobné vyhodnocení a informace k tématu',
    downtext: 'A také nahlédneš do statistik pod pokličku spokojenosti...',
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #EE8662 0%, #D47756 100%)',
    image: 'ikona_univerz.png',
    header: 'Jak se vyvíjí tvé <strong>hodnoty v čase</strong>?',
    subtitle: 'Naše hodnoty se mění. Naše indikátory spokojenosti také. Některé hodnoty nám zůstávají, jiné se proměňují. Díky unikátnímu algoritmu můžeš sledovat vývoj tvých hodnot i změny v grafech hodnotových preferencí.',
    image2: 'zo_graph_.png',
    image2Mobile: 'zo_graph_.png',
  },
  section7: {
    backgroundColor: 'radial-gradient(circle, #90A5BD 0%, #697B90 100%)',
    image: 'ikona_univerz.png',
    header: 'Tento žebříček můžeš také porovnat se svými hodnotami',
    link1: 'Moje spokojenost',
    link1Url: '',
    link2: 'Moje hodnoty',
    link2Url: '',
    subtitle: 'Každý žebříček je jiný a možná budeš překvapen, jak moc blízké či vzdálené jsou tvé hodnoty a jak se tvůj graf vykresluje jinak.',
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #53C5A0 0%, #2CA981 99.92%)',
    heading: 'Chceš o svých hodnotách zjistit víc?',
    subheading: 'Vyzkoušej další z sady speciálních hodnotových žebříčků Eticia Plus.',
    linkText: ''
  },
};

const Page12: PageProps = {
  section10: DefaultSection10,
  section11: DefaultSection11,
};

const Page13: PageProps = {
  section1: {
    header: 'Jak dosáhnu svého cíle?',
    backgroundImg: 'spokojenost.png',
    img: '//placehold.it/238x200',
    imgMobile: '//placehold.it/238x200',
    imgThinner: '//placehold.it/238x200',
    imgThinnerMobile: '//placehold.it/238x200',
    subtitle: ' Hledej hodnoty, které ti na cestě k němu pomohou.',
    button: {
      text: 'Blíž k cíli'
    },
    text: 'Vytvořit žebříček',

    backgroundImgThinner: 'spokojenost.png',
    backgroundImg2: 'spokojenost2.png',
    backgroundImg2Mobile: 'spokojenost2Mobile.png',
    backgroundImg2Tablet: 'spokojenost2Tablet.png',
    gradient: {
      color1: 'rgba(7, 2, 8, 0.5)',
      color2: 'rgba(0, 23, 194, 0.5)',
      color3: 'rgba(7, 2, 8, 0.5)',
      color4: 'rgba(0, 23, 194, 0.5)',
      speed: '10s'
    },
  },
  section2: {
    bgColor: 'radial-gradient(circle at top, rgba(187,204,249,1) 0%, rgba(152,176,242,1) 100%)',
    backgroundImg: '//placehold.it/238x200',
    header: 'Tvůj sen se může stát o něco splnitelnějším',
    subheader: 'Když najdeš ty správné pomocníky...',
    subtitle: 'Abychom se k našim cílům přiblížili a dokázali naše sny realizovat, potřebujeme mít pevný záměr a určité schopností. A také získat potřebné dovedností, které nám pomohou překonat překážky, na které narazíme. Najdi si vhodné pomocníky mezi hodnotami a vyber ty, na které považuješ za dobré se na své cestě spolehnout. Uvidíš, že cesta za tvým snem bude s těmi správnými hodnotami mnohem snazší.',
    downtext: 'xxx'
  },
  section3: {
    backgroundImg: '//placehold.it/1920x330',
    text: 'Tento hodnotový žebříček je také součástí hry, kterou si můžeš v Eticia 21 <strong>zdarma zahrát</strong>',
    header: 'Výprava do světa hodnot',
    subtitle: 'Online dobrodružná hra',
    button: {
      text: 'Zjistit více'
    }
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'ikona_univerz.png',
    header: 'Co dělá <strong>spokojeným</strong> právě tebe?',
    subtitle: 'Pomocí Eticia 21 si můžeš sestavit speciální hodnotový žebříček, který ti pomůže si na tuto otázku opravdu podrobně odpovědět.',
    boxHeaderText: 'Moje spokojenost',
    box1: {
      text: 'Najdeš hodnoty, které nejvíc přispívají k tvé <strong>spokojenosti</strong>',
      img: '1_butt_s4.png',
      width: '4rem',
      height: '4rem'
    },
    box2: {
      text: 'Začneš si vytvářet svou <strong>osobní strategii</strong> pro její dosahování',
      img: '2_butt_s4.png',
      width: '4rem',
      height: '4rem'
    },
    box3: {
      text: 'Zjstíš, proč je užitečné se své vlastní spokojenosti pravidelně věnovat',
      img: '3_butt_s4.png',
      width: '4rem',
      height: '4rem'
    }
  },
  section5: {
    backgroundColor: 'radial-gradient(circle at top, #E7DCA5 0%, #D7C87F 100%)',
    header: 'Co tě čeká?',
    text1: 'Výběr z 42 hodnot, podobně jako v testu Moje hodnoty',
    text2: 'Několik zajímavých otázek ohledně tvé spokojenosti',
    text3: 'Podrobné vyhodnocení a informace k tématu',
    downtext: 'A také nahlédneš do statistik pod pokličku spokojenosti...',
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #EE8662 0%, #D47756 100%)',
    image: 'ikona_univerz.png',
    header: 'Jak se vyvíjí tvé <strong>hodnoty v čase</strong>?',
    subtitle: 'Naše hodnoty se mění. Naše indikátory spokojenosti také. Některé hodnoty nám zůstávají, jiné se proměňují. Díky unikátnímu algoritmu můžeš sledovat vývoj tvých hodnot i změny v grafech hodnotových preferencí.',
    image2: 'zo_graph_.png',
    image2Mobile: 'zo_graph_.png'
  },
  section7: {
    backgroundColor: 'radial-gradient(circle, #90A5BD 0%, #697B90 100%)',
    image: 'ikona_univerz.png',
    header: 'Tento žebříček můžeš také porovnat se svými hodnotami',
    link1: 'Moje spokojenost',
    link1Url: '',
    link2: 'Moje hodnoty',
    link2Url: '',
    subtitle: 'Každý žebříček je jiný a možná budeš překvapen, jak moc blízké či vzdálené jsou tvé hodnoty a jak se tvůj graf vykresluje jinak.',
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #53C5A0 0%, #2CA981 99.92%)',
    heading: 'Chceš o svých hodnotách zjistit víc?',
    subheading: 'Vyzkoušej další z sady speciálních hodnotových žebříčků Eticia Plus.',
    linkText: ''
  },
};

const Page14: PageProps = {
  section1: {
    header: 'Co mě dělá spokojeným?',
    backgroundImg: 'spokojenost.png',
    img: '//placehold.it/238x200',
    imgMobile: '//placehold.it/238x200',
    imgThinner: '//placehold.it/238x200',
    imgThinnerMobile: '//placehold.it/238x200',
    subtitle: 'string',
    button: {
      text: 'text'
    },
    text: 'Vytvořit žebříček',

    backgroundImgThinner: 'spokojenost.png',
    backgroundImg2: 'spokojenost2.png',
    backgroundImg2Mobile: 'spokojenost2Mobile.png',
    backgroundImg2Tablet: 'spokojenost2Tablet.png',
    gradient: {
      color1: 'rgba(7, 2, 8, 0.5)',
      color2: 'rgba(0, 23, 194, 0.5)',
      color3: 'rgba(7, 2, 8, 0.5)',
      color4: 'rgba(0, 23, 194, 0.5)',
      speed: '10s'
    },
  },
  section2: {
    bgColor: 'radial-gradient(circle at top, rgba(187,204,249,1) 0%, rgba(152,176,242,1) 100%)',
    backgroundImg: '//placehold.it/238x200',
    header: 'O spokojenosti',
    subheader: 'Chvilkový pocit <strong>štěstí je fajn</strong>, ale jak se říká, je to “muška jenom zlatá”.',
    subtitle: 'Z dlouhodobého hlediska je v našem životě mnohem důležitější pocit celkové spokojenosti. Taková životní spokojenost se utváří v průběhu času a souvisí úzce s tím, zda se nám daří žít tak, jak opravdu chceme a považujeme za “dobré” či “správné”. Spokojenost tedy úzce souvisí právě s našimi hodnotami a s tím, nakolik se nám daří je v životě naplňovat. Někdy větší spokojenosti můžeme dosáhnout tak, že něco v našem životě změníme, a jindy naopak tím, že změníme svůj postoj k dané situaci.',
    downtext: 'Štěstí může uletět, ale spokojenost se dá chytit...'
  },
  section3: {
    backgroundImg: '//placehold.it/1920x330',
    text: 'Tento hodnotový žebříček je také součástí hry, kterou si můžeš v Eticia 21 <strong>zdarma zahrát</strong>',
    header: 'Výprava do světa hodnot',
    subtitle: 'Online dobrodružná hra',
    button: {
      text: 'Zjistit více'
    }
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'ikona_univerz.png',
    header: 'Co dělá <strong>spokojeným</strong> právě tebe?',
    subtitle: 'Pomocí Eticia 21 si můžeš sestavit speciální hodnotový žebříček, který ti pomůže si na tuto otázku opravdu podrobně odpovědět.',
    boxHeaderText: 'Moje spokojenost',
    box1: {
      text: 'Najdeš hodnoty, které nejvíc přispívají k tvé <strong>spokojenosti</strong>',
      img: '1_butt_s4.png',
      width: '4rem',
      height: '4rem'
    },
    box2: {
      text: 'Začneš si vytvářet svou <strong>osobní strategii</strong> pro její dosahování',
      img: '2_butt_s4.png',
      width: '4rem',
      height: '4rem'
    },
    box3: {
      text: 'Zjstíš, proč je užitečné se své vlastní spokojenosti pravidelně věnovat',
      img: '3_butt_s4.png',
      width: '4rem',
      height: '4rem'
    }
  },
  section5: {
    backgroundColor: 'radial-gradient(circle at top, #E7DCA5 0%, #D7C87F 100%)',
    header: 'Co tě čeká?',
    text1: 'Výběr z 42 hodnot, podobně jako v testu Moje hodnoty',
    text2: 'Několik zajímavých otázek ohledně tvé spokojenosti',
    text3: 'Podrobné vyhodnocení a informace k tématu',
    downtext: 'A také nahlédneš do statistik pod pokličku spokojenosti...',
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #EE8662 0%, #D47756 100%)',
    image: 'ikona_univerz.png',
    header: 'Jak se vyvíjí tvé <strong>hodnoty v čase</strong>?',
    subtitle: 'Naše hodnoty se mění. Naše indikátory spokojenosti také. Některé hodnoty nám zůstávají, jiné se proměňují. Díky unikátnímu algoritmu můžeš sledovat vývoj tvých hodnot i změny v grafech hodnotových preferencí.',
    image2: 'zo_graph_.png',
    image2Mobile: 'zo_graph_.png'
  },
  section7: {
    backgroundColor: 'radial-gradient(circle, #90A5BD 0%, #697B90 100%)',
    image: 'ikona_univerz.png',
    header: 'Tento žebříček můžeš také porovnat se svými hodnotami',
    link1: 'Moje spokojenost',
    link1Url: '',
    link2: 'Moje hodnoty',
    link2Url: '',
    subtitle: 'Každý žebříček je jiný a možná budeš překvapen, jak moc blízké či vzdálené jsou tvé hodnoty a jak se tvůj graf vykresluje jinak.',
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #53C5A0 0%, #2CA981 99.92%)',
    heading: 'Chceš o svých hodnotách zjistit víc?',
    subheading: 'Vyzkoušej další z sady speciálních hodnotových žebříčků Eticia Plus.',
    linkText: ''
  },
};

const Page15: PageProps = {
  section1: {
    header: 'Co mě dělá spokojeným?',
    backgroundImg: 'spokojenost.png',
    img: '//placehold.it/238x200',
    imgMobile: '//placehold.it/238x200',
    imgThinner: '//placehold.it/238x200',
    imgThinnerMobile: '//placehold.it/238x200',
    subtitle: 'string',
    button: {
      text: 'text'
    },
    text: 'Vytvořit žebříček',

    backgroundImgThinner: 'spokojenost.png',
    backgroundImg2: 'spokojenost2.png',
    backgroundImg2Mobile: 'spokojenost2Mobile.png',
    backgroundImg2Tablet: 'spokojenost2Tablet.png',
    gradient: {
      color1: 'rgba(7, 2, 8, 0.5)',
      color2: 'rgba(0, 23, 194, 0.5)',
      color3: 'rgba(7, 2, 8, 0.5)',
      color4: 'rgba(0, 23, 194, 0.5)',
      speed: '10s'
    },
  },
  section2: {
    bgColor: 'radial-gradient(circle at top, rgba(187,204,249,1) 0%, rgba(152,176,242,1) 100%)',
    backgroundImg: '//placehold.it/238x200',
    header: 'O spokojenosti',
    subheader: 'Chvilkový pocit <strong>štěstí je fajn</strong>, ale jak se říká, je to “muška jenom zlatá”.',
    subtitle: 'Z dlouhodobého hlediska je v našem životě mnohem důležitější pocit celkové spokojenosti. Taková životní spokojenost se utváří v průběhu času a souvisí úzce s tím, zda se nám daří žít tak, jak opravdu chceme a považujeme za “dobré” či “správné”. Spokojenost tedy úzce souvisí právě s našimi hodnotami a s tím, nakolik se nám daří je v životě naplňovat. Někdy větší spokojenosti můžeme dosáhnout tak, že něco v našem životě změníme, a jindy naopak tím, že změníme svůj postoj k dané situaci.',
    downtext: 'Štěstí může uletět, ale spokojenost se dá chytit...'
  },
  section3: {
    backgroundImg: '//placehold.it/1920x330',
    text: 'Tento hodnotový žebříček je také součástí hry, kterou si můžeš v Eticia 21 <strong>zdarma zahrát</strong>',
    header: 'Výprava do světa hodnot',
    subtitle: 'Online dobrodružná hra',
    button: {
      text: 'Zjistit více'
    }
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'ikona_univerz.png',
    header: 'Co dělá <strong>spokojeným</strong> právě tebe?',
    subtitle: 'Pomocí Eticia 21 si můžeš sestavit speciální hodnotový žebříček, který ti pomůže si na tuto otázku opravdu podrobně odpovědět.',
    boxHeaderText: 'Moje spokojenost',
    box1: {
      text: 'Najdeš hodnoty, které nejvíc přispívají k tvé <strong>spokojenosti</strong>',
      img: '1_butt_s4.png',
      width: '4rem',
      height: '4rem'
    },
    box2: {
      text: 'Začneš si vytvářet svou <strong>osobní strategii</strong> pro její dosahování',
      img: '2_butt_s4.png',
      width: '4rem',
      height: '4rem'
    },
    box3: {
      text: 'Zjstíš, proč je užitečné se své vlastní spokojenosti pravidelně věnovat',
      img: '3_butt_s4.png',
      width: '4rem',
      height: '4rem'
    }
  },
  section5: {
    backgroundColor: 'radial-gradient(circle at top, #E7DCA5 0%, #D7C87F 100%)',
    header: 'Co tě čeká?',
    text1: 'Výběr z 42 hodnot, podobně jako v testu Moje hodnoty',
    text2: 'Několik zajímavých otázek ohledně tvé spokojenosti',
    text3: 'Podrobné vyhodnocení a informace k tématu',
    downtext: 'A také nahlédneš do statistik pod pokličku spokojenosti...',
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #EE8662 0%, #D47756 100%)',
    image: 'ikona_univerz.png',
    header: 'Jak se vyvíjí tvé <strong>hodnoty v čase</strong>?',
    subtitle: 'Naše hodnoty se mění. Naše indikátory spokojenosti také. Některé hodnoty nám zůstávají, jiné se proměňují. Díky unikátnímu algoritmu můžeš sledovat vývoj tvých hodnot i změny v grafech hodnotových preferencí.',
    image2: '//placehold.it/300x352',
    image2Mobile: '//placehold.it/300x352'
  },
  section7: {
    backgroundColor: 'radial-gradient(circle, #90A5BD 0%, #697B90 100%)',
    image: 'ikona_univerz.png',
    header: 'Tento žebříček můžeš také porovnat s tvými hodnotami',
    link1: 'Moje spokojenost',
    link1Url: '',
    link2: 'Moje hodnoty',
    link2Url: '',
    subtitle: 'Každý žebříček je jiný a možná budeš překvapen, jak moc blízké či vzdálené jsou tvé hodnoty a jak se tvůj graf vykresluje jinak.',
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #53C5A0 0%, #2CA981 99.92%)',
    heading: 'Chceš o svých hodnotách zjistit víc?',
    subheading: 'Vyzkoušej další z sady speciálních hodnotových žebříčků Eticia Plus.',
    linkText: ''
  },
};

const Page16: PageProps = {
  section1: {
    header: 'Co mě dělá spokojeným?',
    backgroundImg: 'spokojenost.png',
    img: '//placehold.it/238x200',
    imgMobile: '//placehold.it/238x200',
    imgThinner: '//placehold.it/238x200',
    imgThinnerMobile: '//placehold.it/238x200',
    subtitle: 'string',
    button: {
      text: 'text'
    },
    text: 'Vytvořit žebříček',
    backgroundImgThinner: 'spokojenost.png',
    backgroundImg2: 'spokojenost2.png',
    backgroundImg2Mobile: 'spokojenost2Mobile.png',
    backgroundImg2Tablet: 'spokojenost2Tablet.png',
    gradient: {
      color1: 'rgba(7, 2, 8, 0.5)',
      color2: 'rgba(0, 23, 194, 0.5)',
      color3: 'rgba(7, 2, 8, 0.5)',
      color4: 'rgba(0, 23, 194, 0.5)',
      speed: '10s'
    },
  },
  section2: {
    bgColor: 'radial-gradient(circle at top, rgba(187,204,249,1) 0%, rgba(152,176,242,1) 100%)',
    backgroundImg: '//placehold.it/238x200',
    header: 'O spokojenosti',
    subheader: 'Chvilkový pocit <strong>štěstí je fajn</strong>, ale jak se říká, je to “muška jenom zlatá”.',
    subtitle: 'Z dlouhodobého hlediska je v našem životě mnohem důležitější pocit celkové spokojenosti. Taková životní spokojenost se utváří v průběhu času a souvisí úzce s tím, zda se nám daří žít tak, jak opravdu chceme a považujeme za “dobré” či “správné”. Spokojenost tedy úzce souvisí právě s našimi hodnotami a s tím, nakolik se nám daří je v životě naplňovat. Někdy větší spokojenosti můžeme dosáhnout tak, že něco v našem životě změníme, a jindy naopak tím, že změníme svůj postoj k dané situaci.',
    downtext: 'Štěstí může uletět, ale spokojenost se dá chytit...'
  },
  section3: {
    backgroundImg: '//placehold.it/1920x330',
    text: 'Tento hodnotový žebříček je také součástí hry, kterou si můžeš v Eticia 21 <strong>zdarma zahrát</strong>',
    header: 'Výprava do světa hodnot',
    subtitle: 'Online dobrodružná hra',
    button: {
      text: 'Zjistit více'
    }
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'ikona_univerz.png',
    header: 'Co dělá <strong>spokojeným</strong> právě tebe?',
    subtitle: 'Pomocí Eticia 21 si můžeš sestavit speciální hodnotový žebříček, který ti pomůže si na tuto otázku opravdu podrobně odpovědět.',
    boxHeaderText: 'Moje spokojenost',
    box1: {
      text: 'Najdeš hodnoty, které nejvíc přispívají k tvé <strong>spokojenosti</strong>',
      img: '1_butt_s4.png',
      width: '4rem',
      height: '4rem'
    },
    box2: {
      text: 'Začneš si vytvářet svou <strong>osobní strategii</strong> pro její dosahování',
      img: '2_butt_s4.png',
      width: '4rem',
      height: '4rem'
    },
    box3: {
      text: 'Zjstíš, proč je užitečné se své vlastní spokojenosti pravidelně věnovat',
      img: '3_butt_s4.png',
      width: '4rem',
      height: '4rem'
    }
  },
  section5: {
    backgroundColor: 'radial-gradient(circle at top, #E7DCA5 0%, #D7C87F 100%)',
    header: 'Co tě čeká?',
    text1: 'Výběr z 42 hodnot, podobně jako v testu Moje hodnoty',
    text2: 'Několik zajímavých otázek ohledně tvé spokojenosti',
    text3: 'Podrobné vyhodnocení a informace k tématu',
    downtext: 'A také nahlédneš do statistik pod pokličku spokojenosti...',
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #EE8662 0%, #D47756 100%)',
    image: 'ikona_univerz.png',
    header: 'Jak se vyvíjí tvé <strong>hodnoty v čase</strong>?',
    subtitle: 'Naše hodnoty se mění. Naše indikátory spokojenosti také. Některé hodnoty nám zůstávají, jiné se proměňují. Díky unikátnímu algoritmu můžeš sledovat vývoj tvých hodnot i změny v grafech hodnotových preferencí.',
    image2: '//placehold.it/300x352',
    image2Mobile: '//placehold.it/300x352'
  },
  section7: {
    backgroundColor: 'radial-gradient(circle, #90A5BD 0%, #697B90 100%)',
    image: 'ikona_univerz.png',
    header: 'Tento žebříček můžeš také porovnat se svými hodnotami',
    link1: 'Moje spokojenost',
    link1Url: '',
    link2: 'Moje hodnoty',
    link2Url: '',
    subtitle: 'Každý žebříček je jiný a možná budeš překvapen, jak moc blízké či vzdálené jsou tvé hodnoty a jak se tvůj graf vykresluje jinak.',
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #53C5A0 0%, #2CA981 99.92%)',
    heading: 'Chceš o svých hodnotách zjistit víc?',
    subheading: 'Vyzkoušej další z sady speciálních hodnotových žebříčků Eticia Plus.',
    linkText: ''
  },
};

const Page17: PageProps = {
  section1: {
    header: 'Co mě dělá spokojeným?',
    backgroundImg: 'spokojenost.png',
    img: '//placehold.it/238x200',
    imgMobile: '//placehold.it/238x200',
    imgThinner: '//placehold.it/238x200',
    imgThinnerMobile: '//placehold.it/238x200',
    subtitle: 'string',
    button: {
      text: 'text'
    },
    text: 'Vytvořit žebříček',

    backgroundImgThinner: 'spokojenost.png',
    backgroundImg2: 'spokojenost2.png',
    backgroundImg2Mobile: 'spokojenost2Mobile.png',
    backgroundImg2Tablet: 'spokojenost2Tablet.png',
    gradient: {
      color1: 'rgba(7, 2, 8, 0.5)',
      color2: 'rgba(0, 23, 194, 0.5)',
      color3: 'rgba(7, 2, 8, 0.5)',
      color4: 'rgba(0, 23, 194, 0.5)',
      speed: '10s'
    },
  },
  section2: {
    bgColor: 'radial-gradient(circle at top, rgba(187,204,249,1) 0%, rgba(152,176,242,1) 100%)',
    backgroundImg: '//placehold.it/238x200',
    header: 'O spokojenosti',
    subheader: 'Chvilkový pocit <strong>štěstí je fajn</strong>, ale jak se říká, je to “muška jenom zlatá”.',
    subtitle: 'Z dlouhodobého hlediska je v našem životě mnohem důležitější pocit celkové spokojenosti. Taková životní spokojenost se utváří v průběhu času a souvisí úzce s tím, zda se nám daří žít tak, jak opravdu chceme a považujeme za “dobré” či “správné”. Spokojenost tedy úzce souvisí právě s našimi hodnotami a s tím, nakolik se nám daří je v životě naplňovat. Někdy větší spokojenosti můžeme dosáhnout tak, že něco v našem životě změníme, a jindy naopak tím, že změníme svůj postoj k dané situaci.',
    downtext: 'Štěstí může uletět, ale spokojenost se dá chytit...'
  },
  section3: {
    backgroundImg: '//placehold.it/1920x330',
    text: 'Tento hodnotový žebříček je také součástí hry, kterou si můžeš v Eticia 21 <strong>zdarma zahrát</strong>',
    header: 'Výprava do světa hodnot',
    subtitle: 'Online dobrodružná hra',
    button: {
      text: 'Zjistit více'
    }
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'ikona_univerz.png',
    header: 'Co dělá <strong>spokojeným</strong> právě tebe?',
    subtitle: 'Pomocí Eticia 21 si můžeš sestavit speciální hodnotový žebříček, který ti pomůže si na tuto otázku opravdu podrobně odpovědět.',
    boxHeaderText: 'Moje spokojenost',
    box1: {
      text: 'Najdeš hodnoty, které nejvíc přispívají k tvé <strong>spokojenosti</strong>',
      img: '1_butt_s4.png',
      width: '4rem',
      height: '4rem'
    },
    box2: {
      text: 'Začneš si vytvářet svou <strong>osobní strategii</strong> pro její dosahování',
      img: '2_butt_s4.png',
      width: '4rem',
      height: '4rem'
    },
    box3: {
      text: 'Zjstíš, proč je užitečné se své vlastní spokojenosti pravidelně věnovat',
      img: '3_butt_s4.png',
      width: '4rem',
      height: '4rem'
    }
  },
  section5: {
    backgroundColor: 'radial-gradient(circle at top, #E7DCA5 0%, #D7C87F 100%)',
    header: 'Co tě čeká?',
    text1: 'Výběr z 42 hodnot, podobně jako v testu Moje hodnoty',
    text2: 'Několik zajímavých otázek ohledně tvé spokojenosti',
    text3: 'Podrobné vyhodnocení a informace k tématu',
    downtext: 'A také nahlédneš do statistik pod pokličku spokojenosti...',
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #EE8662 0%, #D47756 100%)',
    image: 'ikona_univerz.png',
    header: 'Jak se vyvíjí tvé <strong>hodnoty v čase</strong>?',
    subtitle: 'Naše hodnoty se mění. Naše indikátory spokojenosti také. Některé hodnoty nám zůstávají, jiné se proměňují. Díky unikátnímu algoritmu můžeš sledovat vývoj tvých hodnot i změny v grafech hodnotových preferencí.',
    image2: '//placehold.it/300x352',
    image2Mobile: '//placehold.it/300x352'
  },
  section7: {
    backgroundColor: 'radial-gradient(circle, #90A5BD 0%, #697B90 100%)',
    image: 'ikona_univerz.png',
    header: 'Tento žebříček můžeš také porovnat se svými hodnotami',
    link1: 'Moje spokojenost',
    link1Url: '',
    link2: 'Moje hodnoty',
    link2Url: '',
    subtitle: 'Každý žebříček je jiný a možná budeš překvapen, jak moc blízké či vzdálené jsou tvé hodnoty a jak se tvůj graf vykresluje jinak.',
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #53C5A0 0%, #2CA981 99.92%)',
    heading: 'Chceš o svých hodnotách zjistit víc?',
    subheading: 'Vyzkoušej další z sady speciálních hodnotových žebříčků Eticia Plus.',
    linkText: ''
  },
};

const Page18: PageProps = {
  section1: {
    header: 'Co mě dělá spokojeným?',
    backgroundImg: 'spokojenost.png',
    img: '//placehold.it/238x200',
    imgMobile: '//placehold.it/238x200',
    imgThinner: '//placehold.it/238x200',
    imgThinnerMobile: '//placehold.it/238x200',
    subtitle: 'string',
    button: {
      text: 'text'
    },
    text: 'Vytvořit žebříček',

    backgroundImgThinner: 'spokojenost.png',
    backgroundImg2: 'spokojenost2.png',
    backgroundImg2Mobile: 'spokojenost2Mobile.png',
    backgroundImg2Tablet: 'spokojenost2Tablet.png',
    gradient: {
      color1: 'rgba(7, 2, 8, 0.5)',
      color2: 'rgba(0, 23, 194, 0.5)',
      color3: 'rgba(7, 2, 8, 0.5)',
      color4: 'rgba(0, 23, 194, 0.5)',
      speed: '10s'
    },
  },
  section2: {
    bgColor: 'radial-gradient(circle at top, rgba(187,204,249,1) 0%, rgba(152,176,242,1) 100%)',
    backgroundImg: '//placehold.it/238x200',
    header: 'O spokojenosti',
    subheader: 'Chvilkový pocit <strong>štěstí je fajn</strong>, ale jak se říká, je to “muška jenom zlatá”.',
    subtitle: 'Z dlouhodobého hlediska je v našem životě mnohem důležitější pocit celkové spokojenosti. Taková životní spokojenost se utváří v průběhu času a souvisí úzce s tím, zda se nám daří žít tak, jak opravdu chceme a považujeme za “dobré” či “správné”. Spokojenost tedy úzce souvisí právě s našimi hodnotami a s tím, nakolik se nám daří je v životě naplňovat. Někdy větší spokojenosti můžeme dosáhnout tak, že něco v našem životě změníme, a jindy naopak tím, že změníme svůj postoj k dané situaci.',
    downtext: 'Štěstí může uletět, ale spokojenost se dá chytit...'
  },
  section3: {
    backgroundImg: '//placehold.it/1920x330',
    text: 'Tento hodnotový žebříček je také součástí hry, kterou si můžeš v Eticia 21 <strong>zdarma zahrát</strong>',
    header: 'Výprava do světa hodnot',
    subtitle: 'Online dobrodružná hra',
    button: {
      text: 'Zjistit více'
    }
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'ikona_univerz.png',
    header: 'Co dělá <strong>spokojeným</strong> právě tebe?',
    subtitle: 'Pomocí Eticia 21 si můžeš sestavit speciální hodnotový žebříček, který ti pomůže si na tuto otázku opravdu podrobně odpovědět.',
    boxHeaderText: 'Moje spokojenost',
    box1: {
      text: 'Najdeš hodnoty, které nejvíc přispívají k tvé <strong>spokojenosti</strong>',
      img: '1_butt_s4.png',
      width: '4rem',
      height: '4rem'
    },
    box2: {
      text: 'Začneš si vytvářet svou <strong>osobní strategii</strong> pro její dosahování',
      img: '2_butt_s4.png',
      width: '4rem',
      height: '4rem'
    },
    box3: {
      text: 'Zjstíš, proč je užitečné se své vlastní spokojenosti pravidelně věnovat',
      img: '3_butt_s4.png',
      width: '4rem',
      height: '4rem'
    }
  },
  section5: {
    backgroundColor: 'radial-gradient(circle at top, #E7DCA5 0%, #D7C87F 100%)',
    header: 'Co tě čeká?',
    text1: 'Výběr z 42 hodnot, podobně jako v testu Moje hodnoty',
    text2: 'Několik zajímavých otázek ohledně tvé spokojenosti',
    text3: 'Podrobné vyhodnocení a informace k tématu',
    downtext: 'A také nahlédneš do statistik pod pokličku spokojenosti...',
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #EE8662 0%, #D47756 100%)',
    image: 'ikona_univerz.png',
    header: 'Jak se vyvíjí tvé <strong>hodnoty v čase</strong>?',
    subtitle: 'Naše hodnoty se mění. Naše indikátory spokojenosti také. Některé hodnoty nám zůstávají, jiné se proměňují. Díky unikátnímu algoritmu můžeš sledovat vývoj tvých hodnot i změny v grafech hodnotových preferencí.',
    image2: '//placehold.it/300x352',
    image2Mobile: '//placehold.it/300x352'
  },
  section7: {
    backgroundColor: 'radial-gradient(circle, #90A5BD 0%, #697B90 100%)',
    image: 'ikona_univerz.png',
    header: 'Tento žebříček můžeš také porovnat se svými hodnotami',
    link1: 'Moje spokojenost',
    link1Url: '',
    link2: 'Moje hodnoty',
    link2Url: '',
    subtitle: 'Každý žebříček je jiný a možná budeš překvapen, jak moc blízké či vzdálené jsou tvé hodnoty a jak se tvůj graf vykresluje jinak.',
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #53C5A0 0%, #2CA981 99.92%)',
    heading: 'Chceš o svých hodnotách zjistit víc?',
    subheading: 'Vyzkoušej další z sady speciálních hodnotových žebříčků Eticia Plus.',
    linkText: ''
  },
};

const Page19: PageProps = {
  section1: {
    header: 'Čeho bych si přál více?',
    backgroundImg: 'prani_Back.jpg',
    backgroundImgThinner: 'prani_Back.jpg',
    backgroundImg2: 'love_Back.png',
    backgroundImg2Mobile: 'mob_700.png',
    backgroundImg2Tablet: 'mob_700.png',
    gradient: {
      color1: 'rgba(57, 1, 110, 0.4)',
      color2: 'rgba(21, 34, 133, 0.4)',
      color3: 'rgba(57, 1, 110, 0.4)',
      color4: 'rgba(21, 34, 133, 0.4)',
      speed: '15s'
    },
    img: 'prani_140x257.png',
    imgMobile: 'prani_mob_90x160.png',
    imgThinner: 'partner_thinner.png',
    imgThinnerMobile: 'partner_thinner_mobile_.png',
    subtitle: '',
    button: {
      text: 'Vytvořit žebříček',
      background: '#29B4EA',
      icon: 'ladder.png'
    },
    text: '',
    eticiaPlus: true,
  },
  section3: {
    backgroundImg: 'zeme1.jpg',
    text: '<strong><span style="color: #F8CEC3">Proč jsou naše hodnoty důležité a proč stojí za to s nimi pracovat?</br></span></strong>Protože hodnoty jsou všude kolem nás. Jsou to ukazateli našeho chování a jednání, nosíme je v sobě. Některé jsou pro nás natolik důležité, že spolehlivě  určují, co je pro přijatelné a co ne.<br></br> <strong><span style="color: #F8CEC3">Denně děláme desítky rozhodnutí, ve kterém hrají podstatnou roli naše hodnoty.</strong></span> Nejen, že se díky nim přesněji rozhodujeme, ale také jsou důležité pro naši komunikaci a chápání druhých. <br></br><strong><span style="color: #F8CEC3">Proč vede naplňování našich hodnot k vlastní spokojenosti?</span><br></br> Naše přání i cíle jsou olemovány také našimi hodnotami, které lze jednoduše vyjádřit <span style="color: #F8CEC3">pomocí hodnotových žebříčků.</span> Ty nám ukazují konkrétní důležitost pro dané životní situace a když jsou naše hodnoty v souladu s tím, co děláme, <span style="color: #F8CEC3">jsme celkově spokojenější.',
    header: 'Objevuj hodnoty s Eticia.',
    subtitle: '',
    button: {
      text: '<span style="color: #E54FA6"> &#9660; </span>'
    }
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'chemie_1.png',
    header: 'Není těžké zavřít oči a představit si hned několik věcí, které bychom rádi měli, získali, nebo zažili…',
    subtitle: '<span style="color: #000000">Svět se sice netočí dle našich přání, ale my rozhodně ano. Ať už po směru, nebo v protisměru, ale vždycky kolem nich. <br><br> <strong>Za vším hledej hodnoty</strong><br></br>Objevovat hodnoty, které nás přitahují, znamená zjistit, proč jsou naše přání taková, jaká jsou. V životě se snažíme dosahovat konkrétních cílů, ale to, co doopravdy hledáme jsou hodnoty, které se za nimi skrývají. Ty jsou totiž skutečným smyslem našeho snažení.</br></br></span>',
    boxHeaderText: '',
    box1: {
      text: 'Při sestavování tohoto žebříčku si uvědomíš,  </strong>co doopravdy chceš, a proč.</strong>',
      img: '1_butt_s4.png',
      width: '4rem',
      height: '4rem'
    },
    box2: {
      text: 'Zjistíš, že je důležité věnovat pozornost svým přáním,<strong> ať už jsou splnitelná, a nebo ne.</strong> ',
      img: '64x64.png',
      width: '4rem',
      height: '4rem'
    },
    box3: {
      text: '<strong>A najdeš možná i úplně nové cesty</strong>, které tě ke splnění těch správných přání dovedou',
      img: '3_butt_s4.png',
      width: '4rem',
      height: '4rem'
    },
    boxBottomText: '',
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #34A1C7 0%, #0E8FB3 100%)',
    image: 'vrana75.png',
    header: 'Jak se moje přání mění?',
    subtitle: '<strong>Co jsme si přáli kdysi, dnes už možná nechcem. A nebo ano?</strong> Podívat se na svá přání s odstupem času je hodně zajímavé. Když si hodnotový žebříček #má přání vytvoříš za nějakou dobu znovu a porovnáš svá přání kdysi a dnes mezi sebou, budeš možná překvapený.',
    image2: 'partner_graf_830.png',
    image2Mobile: 'grafy_PRESENTING640X.png'
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #A096B0 0%, #446A8C 99.92%)',
    heading: 'Chceš o svých hodnotách zjistit víc?',
    subheading: 'Vyzkoušej další ze sady speciálních hodnotových žebříčků Eticia Plus.',
    linkText: ''
  },
  section12: DefaultSection12,
  section13: DefaultSection13
};

const Page20: PageProps = {
  section1: {
    header: 'Co mě dělá spokojeným?',
    backgroundImg: 'spokojenost.png',
    img: '//placehold.it/238x200',
    imgMobile: '//placehold.it/238x200',
    imgThinner: '//placehold.it/238x200',
    imgThinnerMobile: '//placehold.it/238x200',
    subtitle: 'string',
    button: {
      text: 'text'
    },
    text: 'Vytvořit žebříček',

    backgroundImgThinner: 'spokojenost.png',
    backgroundImg2: 'spokojenost2.png',
    backgroundImg2Mobile: 'spokojenost2Mobile.png',
    backgroundImg2Tablet: 'spokojenost2Tablet.png',
    gradient: {
      color1: 'rgba(7, 2, 8, 0.5)',
      color2: 'rgba(0, 23, 194, 0.5)',
      color3: 'rgba(7, 2, 8, 0.5)',
      color4: 'rgba(0, 23, 194, 0.5)',
      speed: '10s'
    },
  },
  section2: {
    bgColor: 'radial-gradient(circle at top, rgba(187,204,249,1) 0%, rgba(152,176,242,1) 100%)',
    backgroundImg: '//placehold.it/238x200',
    header: 'O spokojenosti',
    subheader: 'Chvilkový pocit <strong>štěstí je fajn</strong>, ale jak se říká, je to “muška jenom zlatá”.',
    subtitle: 'Z dlouhodobého hlediska je v našem životě mnohem důležitější pocit celkové spokojenosti. Taková životní spokojenost se utváří v průběhu času a souvisí úzce s tím, zda se nám daří žít tak, jak opravdu chceme a považujeme za “dobré” či “správné”. Spokojenost tedy úzce souvisí právě s našimi hodnotami a s tím, nakolik se nám daří je v životě naplňovat. Někdy větší spokojenosti můžeme dosáhnout tak, že něco v našem životě změníme, a jindy naopak tím, že změníme svůj postoj k dané situaci.',
    downtext: 'Štěstí může uletět, ale spokojenost se dá chytit...'
  },
  section3: {
    backgroundImg: '//placehold.it/1920x330',
    text: 'Tento hodnotový žebříček je také součástí hry, kterou si můžeš v Eticia 21 <strong>zdarma zahrát</strong>',
    header: 'Výprava do světa hodnot',
    subtitle: 'Online dobrodružná hra',
    button: {
      text: 'Zjistit více'
    }
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'ikona_univerz.png',
    header: 'Co dělá <strong>spokojeným</strong> právě tebe?',
    subtitle: 'Pomocí Eticia 21 si můžeš sestavit speciální hodnotový žebříček, který ti pomůže si na tuto otázku opravdu podrobně odpovědět.',
    boxHeaderText: 'Moje spokojenost',
    box1: {
      text: 'Najdeš hodnoty, které nejvíc přispívají k tvé <strong>spokojenosti</strong>',
      img: '1_butt_s4.png',
      width: '4rem',
      height: '4rem'
    },
    box2: {
      text: 'Začneš si vytvářet svou <strong>osobní strategii</strong> pro její dosahování',
      img: '2_butt_s4.png',
      width: '4rem',
      height: '4rem'
    },
    box3: {
      text: 'Zjstíš, proč je užitečné se své vlastní spokojenosti pravidelně věnovat',
      img: '3_butt_s4.png',
      width: '4rem',
      height: '4rem'
    }
  },
  section5: {
    backgroundColor: 'radial-gradient(circle at top, #E7DCA5 0%, #D7C87F 100%)',
    header: 'Co tě čeká?',
    text1: 'Výběr z 42 hodnot, podobně jako v testu Moje hodnoty',
    text2: 'Několik zajímavých otázek ohledně tvé spokojenosti',
    text3: 'Podrobné vyhodnocení a informace k tématu',
    downtext: 'A také nahlédneš do statistik pod pokličku spokojenosti...',
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #EE8662 0%, #D47756 100%)',
    image: 'ikona_univerz.png',
    header: 'Jak se vyvíjí tvé <strong>hodnoty v čase</strong>?',
    subtitle: 'Naše hodnoty se mění. Naše indikátory spokojenosti také. Některé hodnoty nám zůstávají, jiné se proměňují. Díky unikátnímu algoritmu můžeš sledovat vývoj tvých hodnot i změny v grafech hodnotových preferencí.',
    image2: '//placehold.it/300x352',
    image2Mobile: '//placehold.it/300x352'
  },
  section7: {
    backgroundColor: 'radial-gradient(circle, #90A5BD 0%, #697B90 100%)',
    image: 'ikona_univerz.png',
    header: 'Tento žebříček můžeš také porovnat se svými hodnotami',
    link1: 'Moje spokojenost',
    link1Url: '',
    link2: 'Moje hodnoty',
    link2Url: '',
    subtitle: 'Každý žebříček je jiný a možná budeš překvapen, jak moc blízké či vzdálené jsou tvé hodnoty a jak se tvůj graf vykresluje jinak.',
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #53C5A0 0%, #2CA981 99.92%)',
    heading: 'Chceš o svých hodnotách zjistit víc?',
    subheading: 'Vyzkoušej další z sady speciálních hodnotových žebříčků Eticia Plus.',
    linkText: ''
  },
};

const Page21: PageProps = {
  section1: {
    header: 'Prázdná Presenting page',
    backgroundImg: 'love_partner.jpg',
    backgroundImgThinner: 'love_partner.jpg',
    backgroundImg2: 'love_Back.png',
    backgroundImg2Mobile: 'mob_700.png',
    backgroundImg2Tablet: 'mob_700.png',
    gradient: {
      color1: 'rgba(129, 30, 186, 0.6)',
      color2: 'rgba(232, 52, 136, 0.6)',
      color3: 'rgba(129, 30, 186, 0.6)',
      color4: 'rgba(232, 52, 136, 0.6)',
      speed: '30s'
    },
    img: 'ideal_142x142.png',
    imgMobile: 'partner_mobil_90x160.png',
    imgThinner: 'partner_thinner.png',
    imgThinnerMobile: 'partner_thinner_mobile_.png',
    subtitle: 'Nezpomínejme na to opravdu důležité',
    button: {
      text: 'Vytvořit žebříček',
      background: '#FFC7BA',
      icon: 'ladder.png'
    },
    text: '',
    eticiaPlus: true,
  },
  section2: {
    bgColor: 'radial-gradient(circle at top, rgba(187,204,249,1) 0%, rgba(152,176,242,1) 100%)',
    backgroundImg: '0_Me_hodnoty_o_hodnotach.png',
    header: 'Jak moc konkrétní roli hrají naše hodnoty',
    subheader: 'Hodnoty tě k němu povedou.',
    subtitle: 'Mít jasné cíle, vznešené ideály a pevný záměr je důležité. Ale samo o sobě to úspěch našeho konání ještě nezaručí. Abychom došli k jakémukoli cíli, potřebujeme znát cestu, mít určité schopností a získat dovedností, které nám pomohou překonat překážky, na které narazíme. Najdi si vhodné pomocníky mezi hodnotami a vyber ty, na které považuješ za dobré se na své cestě spolehnout.',
    downtext: 'Za vším hledej hodnoty.'
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'partner_chemik1.png',
    header: '<span style="color: #000000">Naše vztahy, <strong>fungující</span><span style="color: #C7129A"> chemie</span></strong><span style="color: #000000"> a sem tam pořádný konflikt hodnot…</span>',
    subtitle: '<span style="color: #000000">Je ti tohle povědomé? Každý z nás to někdy zažil. Lidé jsou k sobě přitahováni velkou silou přírody, ale také společnými zájmy, postoji a pohledy na život. A ty jsou neméně důležité! <strong> Hodnoty se mísí s chemií.</strong> Představu o svém „ideálním partnerovi“ si nosíme každý v sobě. <br><br> <strong><span style="color: #C7129A">Udělej si v tom jasno - speciálních 42 hodnot pro partnerství</span></strong><br></br>Tento žebříček, obsahující novou sadu hodnot, ti pomůže si ujasnit, co od koho vlastně čekáš a jaké hodnoty by měl naplňovat<strong> partner, se kterým se budeš cítit dobře.</strong> Je úplně jedno, jestli v současné chvíli partnera máš, a nebo hledáš.</br></span>',
    boxHeaderText: '',
    box1: {
      text: 'Při sestavování žebříčku sice vysněného partnera nepotkáš, <strong>ale zjistíš, co bys opravdu rád.</strong>',
      img: 'partner_banky.png',
      width: '6rem',
      height: '6rem'
    },
    box2: {
      text: 'Uvědomíš si,<strong>co je pro tebe opravdu u druhého podstatné</strong> a nad čím klidně i přimhouříš oko.',
      img: 'partner_bryle.png',
      width: '6rem',
      height: '6rem'
    },
    box3: {
      text: '<strong>Tvůj pravý "ideál" se skrývá v tvé hlavě.<strong> Skrze hodnoty jej objevíš.',
      img: 'partner_hlava.png',
      width: '6rem',
      height: '6rem'
    },
    boxBottomText: 'Tento žebříček můžeš získat ve Velké hodnotové hře, která je pro každého zdarma.',
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #3ECAD1 0%, #088F96 100%)',
    image: 'ikona_univerz.png',
    header: 'Co od svého “ideálního partnera” očekáváš?',
    subtitle: 'V jakých oblastech života doufáš, že si budete co nejvíce podobní, a v čem naopak uvítáš svůj pravý opak? Na tuto otázku můžeš najít odpověď porovnáním hodnotových žebříčků <strong>#moje hodnoty a #můj ideální partner.<strong> ',
    image2: 'partner_graf_830.png',
    image2Mobile: 'grafy_PRESENTING640X.png'
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #456C8A 0%, #2F4A62 99.92%)',
    heading: 'Chceš o svých hodnotách zjistit víc?',
    subheading: 'Vyzkoušej další ze sady speciálních hodnotových žebříčků',
    linkText: ''
  },
  section10: DefaultSection10,
  section12: DefaultSection12,
  section13: DefaultSection13
};

const Page22: PageProps = {
  section1: {
    header: 'Co je pro mě právě teď opravdu důležité?<br></br>',
    backgroundImg: 'prave_desktop.jpg',
    backgroundImgThinner: 'prave_desktop.jpg',
    backgroundImg2: 'love_Back.png',
    backgroundImg2Mobile: 'mob_700.png',
    backgroundImg2Tablet: 'mob_700.png',
    gradient: {
      color1: 'rgba(14, 100, 123, 0.5)',
      color2: 'rgba(38, 134, 150, 0.3)',
      color3: 'rgba(14, 100, 123, 0.5)',
      color4: 'rgba(38, 134, 150, 0.3)',
      speed: '20s'
    },
    img: 'prave_ted_deskto.png',
    imgMobile: 'prave_ted_mobil.png',
    imgThinner: 'partner_thinner.png',
    imgThinnerMobile: 'partner_thinner_mobile_.png',
    subtitle: 'Je přirozené, že se naše hodnoty mohou měnit každý den. Tento žebříček ti ukáže víc a hned. ',
    button: {
      text: 'Najít aktuální hodnoty',
      background: '#8ca0c7',
      icon: 'ladder.png',
      href: '/cs/app/hra-intro?t=3c132621-85d3-43e3-9dbe-aea269620486',
      redirectAfterRegistration: true    
    },
    text: '',
    eticiaPlus: true,
  },
  section3: {
    backgroundImg: 'zeme1.jpg',
    text: '<strong><span style="color: #F8CEC3">Proč stojí za to věnovat svůj čas přemýšlení o hodnotách?</br></span></strong>Jak a v čem nám mohou hodnoty pomoct? Každý z nás si v sobě nosí svůj vlastní hodnotový kompas, který určuje, kam a za čím se v životě vydáme. Jaká cesta je pro nás přijatelná a kudy rozhodně jít nechceme...<br></br> <strong><span style="color: #F8CEC3">Denně děláme desítky rozhodnutí, ve kterých hrají podstatnou roli naše hodnoty.</strong></span> Nejen, že se díky nim přesněji rozhodujeme, ale jsou důležité i pro naši komunikaci a chápání druhých. <br></br><strong><span style="color: #F8CEC3">Proč vede naplňování našich hodnot k vlastní spokojenosti?</span><br></br> Naše přání i cíle jsou olemovány také našimi hodnotami, které lze jednoduše vyjádřit <span style="color: #F8CEC3">pomocí hodnotových žebříčků.</span> Ty nám ukazují konkrétní důležitost pro dané životní situace a když jsou naše hodnoty v souladu s tím, co děláme, <span style="color: #F8CEC3">jsme celkově spokojenější.',
    header: 'Objevuj hodnoty s Eticia.',
    subtitle: '',
    button: {
      text: '<span style="color: #E54FA6"> &#9660; </span>'
    }
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'partner_chemik1.png',
    header: '<span style="color: #C7129A">Život je někdy pěkný kolotoč</span>',
    subtitle: '<span style="color: #000000">Zkus se na chvilku zastavit. Chvilku vše jen pozorovat. A pak z hodnot, které uvidíš před sebou, vybrat ty, které jsou pro tebe důležité právě dnes. Tady a teď. V konkrétní chvíli a situaci, ve které se právě nacházíš.<br></br> Když se na přítomnou chvíli podíváš skrze hodnoty, které právě v tento okamžik považuješ za nejvíc důležité, objevíš možná nečekané souvislosti. A nebo nové cesty, jak <strong>pro tebe důležité hodnoty</strong> naplnit...</span>',
    boxHeaderText: '',
    box1: {
      text: 'Tvorba tohoto žebříčku ti pomůže lépe vnímat <strong>tvé aktuální potřeby a priority</strong>.',
      img: 'partner_banky.png',
      width: '6rem',
      height: '6rem'
    },
    box2: {
      text: 'Můžeš si ho vytvořit, ať se právě cítíš jakkoliv. <strong>Tvé hodnoty jsou důležité vždy</strong>.',
      img: 'partner_bryle.png',
      width: '6rem',
      height: '6rem'
    },
    box3: {
      text: 'Najdeš třeba i úplně nový způsob, <strong>jak se dívat</strong> na své každodenní radosti i starosti.',
      img: 'partner_hlava.png',
      width: '6rem',
      height: '6rem'
    },
    boxBottomText: '',
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #36717D 0%, #36717D 100%)',
    image: 'ikona_univerz.png',
    header: 'Každý měsíc jiné hodnoty?',
    subtitle: 'Své aktuální žebříčky si můžeš utvářet kdykoli chceš a taky je mezi sebou porovnávat. V hodnotovém deníku najdeš tzv. porovnávač žebříčků. Ten ti ukáže nejen aktuální rozdíly, ale i hodnoty, které se ve tvých výběrech opakují.',
    image2: 'porovnavac221.png',
    image2Mobile: 'grafy_PRESENTING640X.png'
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #456C8A 0%, #2F4A62 99.92%)',
    heading: 'Chceš o svých hodnotách zjistit víc?',
    subheading: 'Vyzkoušej další ze sady speciálních hodnotových žebříčků',
    linkText: ''
  },
  section10: DefaultSection10,
  section12: DefaultSection12,
  section13: DefaultSection13
};


const Page23: PageProps = {
  section1: {
    header: 'Čeho bych si přál víc?<br></br>',
    backgroundImg: 'prani_pozadi_desk.jpg',
    backgroundImgThinner: 'prani_pozadi_desk.jpg',
    backgroundImg2: 'love_Back.png',
    backgroundImg2Mobile: 'mob_700.png',
    backgroundImg2Tablet: 'mob_700.png',
    gradient: {
      color1: 'rgba(14, 100, 123, 0.5)',
      color2: 'rgba(38, 134, 150, 0.3)',
      color3: 'rgba(14, 100, 123, 0.5)',
      color4: 'rgba(38, 134, 150, 0.3)',
      speed: '20s'
    },
    img: 'prani_PP.png',
    imgMobile: 'prani_110.png',
    imgThinner: 'partner_thinner.png',
    imgThinnerMobile: 'partner_thinner_mobile_.png',
    subtitle: 'Přání se mají plnit, která to však jsou?',
    button: {
      text: 'Vytvořit žebříček',
      background: '#5AA1AD',
      icon: 'ladder.png',
      href: '/cs/app/hra-intro?t=a4a379cf-a979-4f1f-82a6-039a647d6083',
      redirectAfterRegistration: true
    },
    text: '',
    eticiaPlus: true,
  },
  section3: {
    backgroundImg: 'zeme1.jpg',
    text: '<strong><span style="color: #F8CEC3">Proč stojí za to věnovat svůj čas přemýšlení o hodnotách?</br></span></strong>Jak a v čem nám mohou hodnoty pomoct? Každý z nás si v sobě nosí svůj vlastní hodnotový kompas, který určuje, kam a za čím se v životě vydáme. Jaká cesta je pro nás přijatelná a kudy rozhodně jít nechceme...<br></br> <strong><span style="color: #F8CEC3">Denně děláme desítky rozhodnutí, ve kterých hrají podstatnou roli naše hodnoty.</strong></span> Nejen, že se díky nim přesněji rozhodujeme, ale jsou důležité i pro naši komunikaci a chápání druhých. <br></br><strong><span style="color: #F8CEC3">Proč vede naplňování našich hodnot k vlastní spokojenosti?</span><br></br> Naše přání i cíle jsou olemovány také našimi hodnotami, které lze jednoduše vyjádřit <span style="color: #F8CEC3">pomocí hodnotových žebříčků.</span> Ty nám ukazují konkrétní důležitost pro dané životní situace a když jsou naše hodnoty v souladu s tím, co děláme, <span style="color: #F8CEC3">jsme celkově spokojenější.',
    header: 'Objevuj hodnoty s Eticia.',
    subtitle: '',
    button: {
      text: '<span style="color: #E54FA6"> &#9660; </span>'
    }
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'chemie_1.png',
    header: '<span style="color: #000000">Není těžké<span style="color: #C7129A"> zavřít oči a představit si</span> hned několik věcí, které bychom rádi měli, získali, nebo zažili…',
    subtitle: '<span style="color: #000000">Svět se sice netočí dle našich přání, ale my rozhodně ano. Ať už po směru, nebo v protisměru, ale vždycky kolem nich. <br></br><strong><span style="color: #C7129A">Za vším hledej hodnoty</strong></span><br></br><span style="color: #000000">Objevovat hodnoty, které nás přitahují, znamená zjistit, proč jsou naše přání taková, jaká jsou. V životě se snažíme dosahovat konkrétních cílů, ale to, co doopravdy hledáme, jsou hodnoty, které se za nimi skrývají. Ty jsou totiž skutečným smyslem našeho snažení.</br></br></span>',
    boxHeaderText: '',
    box1: {
      text: 'Při sestavování tohoto žebříčku si uvědomíš, <strong>co doopravdy chceš, a proč.</strong>',
      img: '1_butt_s4.png',
      width: '4rem',
      height: '4rem'
    },
    box2: {
      text: 'Zjistíš, že je důležité věnovat pozornost svým přáním,<strong> ať už jsou splnitelná, a nebo ne.</strong> ',
      img: '64x64.png',
      width: '4rem',
      height: '4rem'
    },
    box3: {
      text: '<strong>A možná najdeš i úplně nové cesty</strong>, které tě ke splnění těch správných přání dovedou',
      img: '3_butt_s4.png',
      width: '4rem',
      height: '4rem'
    },
    boxBottomText: '',
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #36717D 0%, #36717D 100%)',
    image: 'ikona_univerz.png',
    header: 'Jak se moje přání mění?',
    subtitle: '<strong>Co jsme si přáli kdysi, dnes už možná nechceme. A nebo ano?</strong> Podívat se na svá přání s odstupem času je hodně zajímavé. Když si hodnotový žebříček<strong><span style="color: #F8CEC3"> #má přání</span> vytvoříš za nějakou dobu znovu a porovnáš mezi sebou svá přání kdysi a dnes, budeš možná překvapený.',
    image2: 'partner_graf_830.png',
    image2Mobile: 'grafy_PRESENTING640X.png'
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #456C8A 0%, #2F4A62 99.92%)',
    heading: 'Chceš o svých hodnotách zjistit víc?',
    subheading: 'Vyzkoušej další ze sady speciálních hodnotových žebříčků',
    linkText: ''
  },
  section10: DefaultSection10,
  section12: DefaultSection12,
  section13: DefaultSection13
};

const Page24: PageProps = {
  doNotDuplicateFirstSection: true,
  section1: {
    header: 'Co tě dělá spokojeným?',
    backgroundImg: 'Spokojenost---midn.jpg',
    backgroundImgThinner: 'love_partner.jpg',
    backgroundImg2: 'love_Back.png',
    backgroundImg2Mobile: 'mob_700.png',
    backgroundImg2Tablet: 'mob_700.png',
    backgroundAnimatedOverlay: {
      imageUrl: 'stars-background.webp',
      opacity: 0.3,
      size: {
        phone: 'cover',
        desktop: 'contain'
      },
      repeat: {
        phone: 'no-repeat',
        desktop: 'repeat-x'
      }
    },
    gradient: {
      color1: 'rgba(14, 100, 123, 0.6)',
      color2: 'rgba(38, 134, 150, 0.5)',
      color3: 'rgba(14, 100, 123, 0.6)',
      color4: 'rgba(38, 134, 150, 0.5)',
      speed: '15s'
    },
    img: 'spokojenost_PP.png',
    imgMobile: 'spok_mob_110.png',
    imgThinner: 'partner_thinner.png',
    imgThinnerMobile: 'partner_thinner_mobile_.png',
    subtitle: 'Všichni chceme být spokojení,<br />ale každý na to jdeme jinak...',
    button: {
      text: 'Vytvořit žebříček',
      background: '#f1d2ae',
      icon: 'ladder.png',
      href: '/cs/app/hra-intro?t=beab7d9b-9cb1-4876-91cd-e6e5dcd5a85e',
      redirectAfterRegistration: true,
    },
    text: '',
    eticiaPlus: true,
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'Spokojenost_plaz.png',
    header: '<span style="color: #267687">Být opravdu </span><span style="color: #267687"> spokojený </span></strong><span style="color: #000000">je jednou z nejlepších věcí, které můžeš sám pro sebe i pro svět udělat!<br><br> <span style="color: #267687">Ale jak na to?</span>',
    subtitle: '<span style="color: #000000">Návodů a rad na spokojenost se dá najít pěkná sbírka. Jenže to není tak jednoduché. Každý z nás potřebuje ke své spokojenosti něco trošku jiného. Tvoje babička je třeba spokojená, když ti právě chutná její koláč a nic ji zrovna nebolí. Kamarád třeba rád chytá ryby a zas je pouští do vody. Co má jejich spokojenost společného?<br></br><span style="color: #267687"> Oběma se právě daří naplňovat určité hodnoty. Jaké to jsou?<br></br></span> <span style="color: #000000">S pomocí tohoto žebříčku můžeš objevovat hodnoty, které dělají spokojeným právě tebe. Koláč i ryba jsou projevy něčeho hlubšího. Když najdeš to, co se za nimi skrývá, můžeš pak snáze najít kousek spokojenosti i v obtížnějších situacích. Když se třeba koláč připálí a ryby právě neberou :)<br><br><strong><span style="color: #267687">Za vším hledej hodnoty.</strong></span>',
    boxHeaderText: '',
    box1: {
      text: 'V tomto žebříčku objevíš <strong> užitečný nástroj pro nalézání svých nejoblíbenějších hodnot.</strong>',
      img: 'spokojenost_uni.png',
      width: '6rem',
      height: '6rem'
    },
    box2: {
      text: 'Uvědomíš si lépe, co<strong> ke své spokojenosti doopravdy potřebuješ.</strong>',
      img: 'spokojenost_boxB.png',
      width: '6rem',
      height: '6rem'
    },
    box3: {
      text: 'A možná <strong>objevíš i úplně nové cesty<strong>, které tě k tvé větší spokojenosti mohou vést.',
      img: 'spokojenost_boxC.png',
      width: '6rem',
      height: '6rem'
    },
    boxBottomText: '',
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #724D85 0%, #856197 100%)',
    image: 'spokojenost_3_site.png',
    header: 'Štěstí může uletět, ale spokojenost se dá chytit…',
    subtitle: 'Potřeby, cíle i motivace se čas od času mění. A co přinášelo uspokojení před nějakou dobou, se dnes může zdát nepodstatné. Dobrý životní pocit se ale utváří v průběhu času, a tak je velmi užitečné znát hodnoty, které tě dělají spokojeným dlouhodobě. Když si žebříček <strong>#moje spokojenost</strong> vytvoříš za nějakou dobu znovu, můžeš najít rozdíl mezi tím, co jsi ke své spokojenosti potřeboval kdysi a co by tě udělalo spokojeným dnes.<br></br><span style="color: #ECDEAD">V Eticia můžeš porovnávat aktuální i starší žebříčky, které ti spokojenost přináší.</span>',
    image2: 'spokojenost_porovnani.png',
    image2Mobile: 'spokojenost_porovnani_mob.png'
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #456C8A 0%, #2F4A62 99.92%)',
    heading: 'Chceš o svých hodnotách zjistit víc?',
    subheading: 'Vyzkoušej další ze sady speciálních hodnotových žebříčků',
    linkText: ''
  },
  section10: DefaultSection10,
  section12: DefaultSection12,
  section13: DefaultSection13
};


const Page25: PageProps = {
  section1: {
    header: 'Existuje ideální partner? A&nbsp;jaký by měl vlastně být?',
    backgroundImg: 'ideal_fin.jpg',
    backgroundImgThinner: 'mob_fin.jpg',
    backgroundImg2: 'love_Back.png',
    backgroundImg2Mobile: 'mob_700.png',
    backgroundImg2Tablet: 'mob_700.png',
    backgroundAnimatedOverlay: {
      imageUrl: 'stars-background-2.gif',
      opacity: 1,
      size: {
        phone: 'cover',
        desktop: 'contain'
      },
      repeat: {
        phone: 'no-repeat',
        desktop: 'repeat-x'
      },
    },
    gradient: {
      color1: 'rgba(122,71,133, 0.5)',
      color2: 'rgba(171,112,176, 0.3)',
      color3: 'rgba(122,71,133, 0.5)',
      color4: 'rgba(171,112,176, 0.3)',
      speed: '22s'
    },
    img: 'ideal_142x142.png',
    imgMobile: 'idealni_vztah_110.png',
    imgThinner: 'ideal_142x142.png',
    imgThinnerMobile: 'idealni_vztah_110.png',
    subtitle: 'Abys mohl svůj ideál potkat,  je důležité o něm znát své představy… Vytvoř si praktický a velmi užitečný hodnotový žebříček, který ti pomůže objevit, co si přeješ. Tento čas se vyplatí!',
    button: {
      text: 'můj ideální partner',
      background: '#FFA4A3',
      icon: 'ladder.png',
      href: '/cs/app/hra-intro?t=c212ba8c-9fed-492a-9ba4-44af2ab5291d',
      redirectAfterRegistration: true
    },
    text: '',
    eticiaPlus: true,
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'chemie_P1.png',
    header: '<span style="color: #905895">Kde fungují chemie a hodnoty dohromady, tam se občas dějí věci :)</span>',
    subtitle: '<span style="color: #000000">Lidé jsou k sobě přitahováni neodolatelnou silou přírody. <strong>A vždy v ní hrají roli naše hodnoty.</strong> Ten kdo nás přitahuje fyzicky, nás nemusí přitahovat svým chováním. Najít tu správnou symbiozu a také vědět, co opravdu chceme a co nechceme, nám pomáhá o naše vztahy pečovat a  také lépe chápat jeden druhého.</span><br>',
    boxHeaderText: '<span style="color: #905895">Co díky tomuto žebříčku objevíš?</span>',
    box1: {
      text: 'Zjistíš, které hodnoty jsou pro tebe u partnera opravdu důležité',
      img: 'partner_banky.png',
      width: '6rem',
      height: '6rem'
    },
    box2: {
      text: 'Zamyšlíš se, nad kterými hodnotami bys v klidu „přimhouřil” oko',
      img: 'partner_bryle.png',
      width: '6rem',
      height: '6rem'
    },
    box3: {
      text: 'Poznáš víc svůj „pravý ideál” a také objevíš, kde by sis přál aby ti byl oporou',
      img: 'partner_hlava.png',
      width: '6rem',
      height: '6rem'
    },
    boxBottomText: '<span style="color: #A64263">Čas strávený nad hodnotami nikdy není ztracený. Za vším hledej hodnoty.<em><p text align="center"></span>',
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #9B70Ac 0%, #9B70Ac 100%)',
    image: 'vrana75.png',
    header: '<span style="color: #F9F5DC">A něco navíc! Soulad nebo protiklad?</span></br></br><span style="color: #FFA4A3">Porovnej své žebříčky a najdi odpověd</span>',
    subtitle: 'Tuto otázku můžeš vyřešit porovnáním žebříčků <strong>#mé hodnoty</strong> a <strong>#můj ideální partner.</strong> Algoritmus Eticia ti pomůže zjistit, jakou podobnost, či naopak rozdílnost v partnerství preferuješ. Za vším hledej hodnoty. ',
    image2: 'partner_graf_830.png',
    image2Mobile: 'grafy_PRESENTING640X.png'
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #384D78 0%, #384D78 99.92%)',
    heading: 'Chceš o svých hodnotách zjistit víc?',
    subheading: 'Vytvoř si speciální hodnotový žebříček pro různé oblasti života',
    linkText: ''
  },
  section10: DefaultSection10,
  section12: DefaultSection12,
  section13: DefaultSection13
};

const Page26: PageProps = {
  section1: {
    header: 'Můj báječný vztah<br></br>',
    backgroundImg: 'Partnerstvi---red.jpg',
    backgroundImgThinner: 'love_partner.jpg',
    backgroundImg2: 'love_Back.png',
    backgroundImg2Mobile: 'mob_700.png',
    backgroundImg2Tablet: 'mob_700.png',
    gradient: {
      color1: 'rgba(132, 38, 65, 0.5)',
      color2: 'rgba(173,73,111, 0.3)',
      color3: 'rgba(132, 38, 65, 0.5)',
      color4: 'rgba(173,73,111, 0.3)',
      speed: '20s'
    },
    img: 'bajecny_PP.png',
    imgMobile: 'bajecny_110.png',
    imgThinner: 'partner_thinner.png',
    imgThinnerMobile: 'partner_thinner_mobile_.png',
    subtitle: 'Jakou mám představu o svém vysněném vztahu? Dívat se na vztah taktéž skrze hodnoty je superužitečné',
    button: {
      text: 'Vytvořit žebříček',
      background: '#FFC7BA',
      icon: 'ladder.png',
      href: '/cs/app/hra-intro?t=9d69c0ad-9152-470b-9dff-cb8115ca609b',
      redirectAfterRegistration: true
    },
    text: '',
    eticiaPlus: true,
  },
  section3: {
    backgroundImg: 'zeme1.jpg',
    text: '<strong><span style="color: #F8CEC3">Proč stojí za to věnovat svůj čas přemýšlení o hodnotách?</br></span></strong>Jak a v čem nám mohou hodnoty pomoct? Každý z nás si v sobě nosí svůj vlastní hodnotový kompas, který určuje, kam a za čím se v životě vydáme. Jaká cesta je pro nás přijatelná a kudy rozhodně jít nechceme...<br></br> <strong><span style="color: #F8CEC3">Denně děláme desítky rozhodnutí, ve kterých hrají podstatnou roli naše hodnoty.</strong></span> Nejen, že se díky nim přesněji rozhodujeme, ale také jsou důležité pro naši komunikaci a chápání druhých. <br></br><strong><span style="color: #F8CEC3">Proč vede naplňování našich hodnot k vlastní spokojenosti?</span><br></br> Naše přání i cíle jsou olemovány také našimi hodnotami, které lze jednoduše vyjádřit <span style="color: #F8CEC3">pomocí hodnotových žebříčků.</span> Ty nám ukazují konkrétní důležitost pro dané životní situace a když jsou naše hodnoty v souladu s tím, co děláme, <span style="color: #F8CEC3">jsme celkově spokojenější.',
    header: 'Objevuj hodnoty s Eticia.',
    subtitle: '',
    button: {
      text: '<span style="color: #E54FA6"> &#9660; </span>'
    }
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'partner_chemik1.png',
    header: '<span style="color: #000000">Naše vztahy, <strong>fungující</span><span style="color: #C7129A"> chemie</span></strong><span style="color: #000000"> a sem tam pořádný konflikt hodnot…</span>',
    subtitle: '<span style="color: #000000">Je ti tohle povědomé? Každý z nás to někdy zažil. Lidé jsou k sobě přitahováni velkou silou přírody, ale také společnými zájmy, postoji a pohledy na život. A ty jsou neméně důležité! <strong> Hodnoty se mísí s chemií.</strong> Vědět, co od partnerství očekáváme a umět o tom komunikovat, je opravdu důležité. <br></br> Tenhle <strong><span style="color: #C7129A">speciální žebříček</span></strong> vytvořený právě na téma vztahů ti s tím může pomoct.<br></br> Nezáleží na tom, jestli jsi právě v partnerství, nebo single. Můžeš se víc zamyslet nad očekáváními, která máš ve svém současném vztahu, případně si udělat jasno v tom, jaké hodnoty bys jednou chtěl zažívat v budoucím partnerství. A nebo prostě jen tak... představit si a pojmenovat hodnoty, které se ti v blízkém vztahu s druhým jednoduše jen líbí a jsou pro tebe důležité.</br>',
    boxHeaderText: '',
    box1: {
      text: 'Sestavením žebříčku sice báječný vztah nevytvoříš, <strong>ale ujasníš si, co bys opravdu rád.</strong>',
      img: 'partner_banky.png',
      width: '6rem',
      height: '6rem'
    },
    box2: {
      text: 'Uvědomíš si,<strong> co je pro tebe ve vztahu opravdu podstatné</strong> a nad čím klidně i přimhouříš oko.',
      img: 'partner_bryle.png',
      width: '6rem',
      height: '6rem'
    },
    box3: {
      text: '<strong>Tvůj pravý "ideál" se skrývá v tvé hlavě.<strong> Skrze hodnoty jej objevíš.',
      img: 'partner_hlava.png',
      width: '6rem',
      height: '6rem'
    },
    boxBottomText: '<strong><span style="color: #C7129A">… kdybychom včas dovedli říct co chceme, nemuseli bychom později tak hlasitě volat, co nechceme.</strong></span><br></br>',
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #856197 0%, #856197 100%)',
    image: 'ikona_univerz.png',
    header: 'Vztahy tvoří nejen lidé, ale i jejich představy…',
    subtitle: 'V jakých oblastech života doufáš, že si budete co nejvíce podobní, a v čem naopak uvítáš svůj pravý opak? Na tuto otázku můžeš najít odpověď porovnáním hodnotových žebříčků <strong>#moje hodnoty a #můj ideální partner.<strong> ',
    image2: 'partner_graf_830.png',
    image2Mobile: 'grafy_PRESENTING640X.png'
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #456C8A 0%, #2F4A62 99.92%)',
    heading: 'Chceš o svých hodnotách zjistit víc?',
    subheading: 'Vytvoř si speciální hodnotový žebříček pro různé oblasti života.',
    linkText: ''
  },
  section10: DefaultSection10,
  section12: DefaultSection12,
  section13: DefaultSection13
};

const Page27: PageProps = {
  section1: {
    header: 'Co mi pomůže dosáhnout mého cíle?<br></br>',
    backgroundImg: 'Bliz---kids.jpg',
    backgroundImgThinner: 'Bliz---kids.jpg',
    backgroundImg2: 'love_Back.png',
    backgroundImg2Mobile: 'mob_700.png',
    backgroundImg2Tablet: 'mob_700.png',
    gradient: {
      color1: 'rgba(38, 96, 133, 0.5)',
      color2: 'rgba(52, 120, 154, 0.3)',
      color3: 'rgba(38, 96, 133, 0.5)',
      color4: 'rgba(52, 120, 154, 0.3)',
      speed: '20s'
    },
    img: 'bliz_desktop.png',
    imgMobile: 'bliz_mobil.png',
    imgThinner: 'partner_thinner.png',
    imgThinnerMobile: 'partner_thinner_mobile_.png',
    subtitle: '',
    button: {
      text: 'Vytvořit žebříček',
      background: '#73CBBD',
      icon: 'ladder.png',
      href: '/cs/app/hra-intro?t=e86b03f1-c148-491f-9bd8-799b8dbc918a',
      redirectAfterRegistration: true
    },
    text: '',
    eticiaPlus: true,
  },
  section3: {
    backgroundImg: 'zeme1.jpg',
    text: '<strong><span style="color: #F8CEC3">Proč stojí za to věnovat svůj čas přemýšlení o hodnotách?</br></span></strong>Jak a v čem nám mohou hodnoty pomoct? Každý z nás si v sobě nosí svůj vlastní hodnotový kompas, který určuje, kam a za čím se v životě vydáme. Jaká cesta je pro nás přijatelná a kudy rozhodně jít nechceme...<br></br> <strong><span style="color: #F8CEC3">Denně děláme desítky rozhodnutí, ve kterém hrají podstatnou roli naše hodnoty.</strong></span> Nejen, že se díky nim přesněji rozhodujeme, ale také jsou důležité pro naši komunikaci a chápání druhých. <br></br><strong><span style="color: #F8CEC3">Proč vede naplňování našich hodnot k vlastní spokojenosti?</span><br></br> Naše přání i cíle jsou olemovány také našimi hodnotami, které lze jednoduše vyjádřit <span style="color: #F8CEC3">pomocí hodnotových žebříčků.</span> Ty nám ukazují konkrétní důležitost pro dané životní situace a když jsou naše hodnoty v souladu s tím, co děláme, <span style="color: #F8CEC3">jsme celkově spokojenější.',
    header: 'Objevuj hodnoty s Eticia.',
    subtitle: '',
    button: {
      text: '<span style="color: #E54FA6"> &#9660; </span>'
    }
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'partner_chemik1.png',
    header: '<span style="color: #000000">Hledej hodnoty, které ti pomohou </span><strong><span style="color: #C7129A"> jít za svým snem</span></strong>',
    subtitle: '<span style="color: #000000">“Rád bych, ale nevím kudy. Necítím se na to a možná to nedokážu…“ <br></br>Kolikrát nás podobné věty napadají? Abychom se v životě k něčemu odhodlali a vydali se konečně na cestu za svým vytouženým cílem, musíme mít pevný záměr, potřebné schopnosti, hromadu štěstí… <br></br><strong><span style="color: #C7129A">…a také ty správné hodnoty!</span></strong>',
    boxHeaderText: '',
    box1: {
      text: 'Při sestavování tohoto žebříčku objevíš hodnoty,<strong> které podpoří tvůj záměr</strong>, a o které se můžeš nejlépe opřít.',
      img: 'partner_banky.png',
      width: '6rem',
      height: '6rem'
    },
    box2: {
      text: 'Zjistíš, že zapojení těch správných hodnot dovede tvou cestu nejen zpříjemnit, ale také ti pomůže <strong> lépe překonat leckteré nezdary</strong>.',
      img: 'partner_bryle.png',
      width: '6rem',
      height: '6rem'
    },
    box3: {
      text: 'A uvidíš, že prostředky, které k uskutečnění svých cílů volíš, jsou přinejmenším<strong> stejně důležité, jako samotný tvůj cíl.</strong>',
      img: 'partner_hlava.png',
      width: '6rem',
      height: '6rem'
    },
    boxBottomText: '',
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #497E9D 0%, #497E9D 100%)',
    image: 'ikona_univerz.png',
    header: 'Co od svého “ideálního partnera” očekáváš?',
    subtitle: 'V jakých oblastech života doufáš, že si budete co nejvíce podobní, a v čem naopak uvítáš svůj pravý opak? Na tuto otázku můžeš najít odpověď porovnáním hodnotových žebříčků <strong>#moje hodnoty a #můj ideální partner.<strong> ',
    image2: 'partner_graf_830.png',
    image2Mobile: 'grafy_PRESENTING640X.png'
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #456C8A 0%, #2F4A62 99.92%)',
    heading: 'Chceš o svých hodnotách zjistit víc?',
    subheading: 'Vyzkoušej další ze sady speciálních hodnotových žebříčků',
    linkText: ''
  },
  section10: DefaultSection10,
  section12: DefaultSection12,
  section13: DefaultSection13
};

const Page28: PageProps = {
  section1: {
    header: 'Práce mých snů? Jak by měla vypadat?<br></br>',
    backgroundImg: 'prace_FIN.jpg',
    backgroundImgThinner: 'prace_FIN.jpg',
    backgroundImg2: 'love_Back.png',
    backgroundImg2Mobile: 'mob_700.png',
    backgroundImg2Tablet: 'mob_700.png',
    gradient: {
      color1: 'rgba(87, 88, 109, 0.5)',
      color2: 'rgba(115, 116, 137, 0.3)',
      color3: 'rgba(87, 88, 109, 0.5)',
      color4: 'rgba(115, 116, 137, 0.3)',
      speed: '20s'
    },
    img: 'prac_140x246.png',
    imgMobile: 'prac_90x160.png',
    imgThinner: 'blue_prace_PP_1920.jpg',
    imgThinnerMobile: 'blue_prace_PP_1920.jpg',
    subtitle: 'Tím, že pracujeme skoro třetinu svého života, měla by stát za to? Vytvoř si žebříček, který tě k ní přiblíží.',
    button: {
      text: 'má vysněná práce',
      background: '#FFA4A3',
      icon: 'ladder.png',
      href: '/cs/app/hra-intro?t=d1ded0db-e903-42be-a5f3-eca4a02bbedc',
      redirectAfterRegistration: true
    },
    text: '',
    eticiaPlus: true,
  },
  section3: {
    backgroundImg: 'zeme1.jpg',
    text: '<strong><span style="color: #F8CEC3">Proč stojí za to věnovat svůj čas přemýšlení o hodnotách?</br></span></strong>Jak a v čem nám mohou hodnoty pomoct? Každý z nás si v sobě nosí svůj vlastní hodnotový kompas, který určuje, kam a za čím se v životě vydáme. Jaká cesta je pro nás přijatelná a kudy rozhodně jít nechceme...<br></br> <strong><span style="color: #F8CEC3">Denně děláme desítky rozhodnutí, ve kterém hrají podstatnou roli naše hodnoty.</strong></span> Nejen, že se díky nim přesněji rozhodujeme, ale také jsou důležité pro naši komunikaci a chápání druhých. <br></br><strong><span style="color: #F8CEC3">Proč vede naplňování našich hodnot k vlastní spokojenosti?</span><br></br> Naše přání i cíle jsou olemovány také našimi hodnotami, které lze jednoduše vyjádřit <span style="color: #F8CEC3">pomocí hodnotových žebříčků.</span> Ty nám ukazují konkrétní důležitost pro dané životní situace a když jsou naše hodnoty v souladu s tím, co děláme, <span style="color: #F8CEC3">jsme celkově spokojenější.',
    header: 'Objevuj hodnoty s Eticia.',
    subtitle: '',
    button: {
      text: '<span style="color: #E54FA6"> &#9660; </span>'
    }
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'partner_chemik1.png',
    header: '<span style="color: #000000">Naše vztahy, <strong>fungující</span><span style="color: #C7129A"> chemie</span></strong><span style="color: #000000"> a sem tam pořádný konflikt hodnot…</span>',
    subtitle: '<span style="color: #000000">Je ti tohle povědomé? Každý z nás to někdy zažil. Lidé jsou k sobě přitahováni velkou silou přírody, ale také společnými zájmy, postoji a pohledy na život. A ty jsou neméně důležité! <strong> Hodnoty se mísí s chemií.</strong> Představu o svém „ideálním partnerovi“ si nosíme každý v sobě. <br><br> <strong><span style="color: #C7129A">Udělej si v tom jasno - speciálních 42 hodnot pro partnerství</span></strong><br></br>Tento žebříček, obsahující novou sadu hodnot, ti pomůže si ujasnit, co od koho vlastně čekáš a jaké hodnoty by měl naplňovat<strong> partner, se kterým se budeš cítit dobře.</strong> Je úplně jedno, jestli v současné chvíli partnera máš, a nebo hledáš.</br></span>',
    boxHeaderText: '',
    box1: {
      text: 'Při sestavování žebříčku sice vysněného partnera nepotkáš, <strong>ale zjistíš, co bys opravdu rád.</strong>',
      img: 'partner_banky.png',
      width: '6rem',
      height: '6rem'
    },
    box2: {
      text: 'Uvědomíš si,<strong>co je pro tebe opravdu u druhého podstatné</strong> a nad čím klidně i přimhouříš oko.',
      img: 'partner_bryle.png',
      width: '6rem',
      height: '6rem'
    },
    box3: {
      text: '<strong>Tvůj pravý "ideál" se skrývá v tvé hlavě.<strong> Skrze hodnoty jej objevíš.',
      img: 'partner_hlava.png',
      width: '6rem',
      height: '6rem'
    },
    boxBottomText: '',
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #696880 0%, #696880 100%)',
    image: 'ikona_univerz.png',
    header: 'Co od svého “ideálního partnera” očekáváš?',
    subtitle: 'V jakých oblastech života doufáš, že si budete co nejvíce podobní, a v čem naopak uvítáš svůj pravý opak? Na tuto otázku můžeš najít odpověď porovnáním hodnotových žebříčků <strong>#moje hodnoty a #můj ideální partner.<strong> ',
    image2: 'partner_graf_830.png',
    image2Mobile: 'grafy_PRESENTING640X.png'
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #456C8A 0%, #2F4A62 99.92%)',
    heading: 'Chceš o svých hodnotách zjistit víc?',
    subheading: 'Vyzkoušej další ze sady speciálních hodnotových žebříčků',
    linkText: ''
  },
  section10: DefaultSection10,
  section12: DefaultSection12,
  section13: DefaultSection13
};

const Page29: PageProps = {
  section1: {
    header: 'Verze pro mladší - je ti méně než 16 let?<br></br>',
    backgroundImg: 'Bliz---kids.jpg',
    backgroundImgThinner: 'love_partner.jpg',
    backgroundImg2: 'love_Back.png',
    backgroundImg2Mobile: 'mob_700.png',
    backgroundImg2Tablet: 'mob_700.png',
    gradient: {
      color1: 'rgba(38, 96, 133, 0.5)',
      color2: 'rgba(52, 120, 154, 0.3)',
      color3: 'rgba(38, 96, 133, 0.5)',
      color4: 'rgba(52, 120, 154, 0.3)',
      speed: '20s'
    },
    img: 'ideal_142x142.png',
    imgMobile: 'partner_mobil_90x160.png',
    imgThinner: 'partner_thinner.png',
    imgThinnerMobile: 'partner_thinner_mobile_.png',
    subtitle: '',
    button: {
      text: 'Vytvořit žebříček',
      background: '#FFC7BA',
      icon: 'ladder.png',
      href: '/cs/registrace'
    },
    text: '',
    eticiaPlus: true,
  },

  section3: {
    backgroundImg: 'zeme1.jpg',
    text: '<strong><span style="color: #F8CEC3">Proč stojí za to věnovat svůj čas přemýšlení o hodnotách?</br></span></strong>Jak a v čem nám mohou hodnoty pomoct? Každý z nás si v sobě nosí svůj vlastní hodnotový kompas, který určuje, kam a za čím se v životě vydáme. Jaká cesta je pro nás přijatelná a kudy rozhodně jít nechceme...<br></br> <strong><span style="color: #F8CEC3">Denně děláme desítky rozhodnutí, ve kterém hrají podstatnou roli naše hodnoty.</strong></span> Nejen, že se díky nim přesněji rozhodujeme, ale také jsou důležité pro naši komunikaci a chápání druhých. <br></br><strong><span style="color: #F8CEC3">Proč vede naplňování našich hodnot k vlastní spokojenosti?</span><br></br> Naše přání i cíle jsou olemovány také našimi hodnotami, které lze jednoduše vyjádřit <span style="color: #F8CEC3">pomocí hodnotových žebříčků.</span> Ty nám ukazují konkrétní důležitost pro dané životní situace a když jsou naše hodnoty v souladu s tím, co děláme, <span style="color: #F8CEC3">jsme celkově spokojenější.',
    header: 'Objevuj hodnoty s Eticia.',
    subtitle: '',
    button: {
      text: '<span style="color: #E54FA6"> &#9660; </span>'
    }
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'partner_chemik1.png',
    header: '<span style="color: #000000">Naše vztahy, <strong>fungující</span><span style="color: #C7129A"> chemie</span></strong><span style="color: #000000"> a sem tam pořádný konflikt hodnot…</span>',
    subtitle: '<span style="color: #000000">Je ti tohle povědomé? Každý z nás to někdy zažil. Lidé jsou k sobě přitahováni velkou silou přírody, ale také společnými zájmy, postoji a pohledy na život. A ty jsou neméně důležité! <strong> Hodnoty se mísí s chemií.</strong> Představu o svém „ideálním partnerovi“ si nosíme každý v sobě. <br><br> <strong><span style="color: #C7129A">Udělej si v tom jasno - speciálních 42 hodnot pro partnerství</span></strong><br></br>Tento žebříček, obsahující novou sadu hodnot, ti pomůže si ujasnit, co od koho vlastně čekáš a jaké hodnoty by měl naplňovat<strong> partner, se kterým se budeš cítit dobře.</strong> Je úplně jedno, jestli v současné chvíli partnera máš, a nebo hledáš.</br></span>',
    boxHeaderText: '',
    box1: {
      text: 'Při sestavování žebříčku sice vysněného partnera nepotkáš, <strong>ale zjistíš, co bys opravdu rád.</strong>',
      img: 'partner_banky.png',
      width: '6rem',
      height: '6rem'
    },
    box2: {
      text: 'Uvědomíš si,<strong>co je pro tebe opravdu u druhého podstatné</strong> a nad čím klidně i přimhouříš oko.',
      img: 'partner_bryle.png',
      width: '6rem',
      height: '6rem'
    },
    box3: {
      text: '<strong>Tvůj pravý "ideál" se skrývá v tvé hlavě.<strong> Skrze hodnoty jej objevíš.',
      img: 'partner_hlava.png',
      width: '6rem',
      height: '6rem'
    },
    boxBottomText: 'Tento žebříček můžeš získat ve Velké hodnotové hře, která je pro každého zdarma.',
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #497E9D 0%, #497E9D 100%)',
    image: 'ikona_univerz.png',
    header: 'Co od svého “ideálního partnera” očekáváš?',
    subtitle: 'V jakých oblastech života doufáš, že si budete co nejvíce podobní, a v čem naopak uvítáš svůj pravý opak? Na tuto otázku můžeš najít odpověď porovnáním hodnotových žebříčků <strong>#moje hodnoty a #můj ideální partner.<strong> ',
    image2: 'partner_graf_830.png',
    image2Mobile: 'grafy_PRESENTING640X.png'
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #456C8A 0%, #2F4A62 99.92%)',
    heading: 'Chceš o svých hodnotách zjistit víc?',
    subheading: 'Vyzkoušej další ze sady speciálních hodnotových žebříčků',
    linkText: ''
  },
  section10: DefaultSection10,
  section12: DefaultSection12,
  section13: DefaultSection13
};

const Page30: PageProps = {
  section1: {
    header: 'Žebříčky pro rodiče a děti<br></br>',
    backgroundImg: 'Rodice_deti_lightkids.jpg',
    backgroundImgThinner: 'love_partner.jpg',
    backgroundImg2: 'love_Back.png',
    backgroundImg2Mobile: 'mob_700.png',
    backgroundImg2Tablet: 'mob_700.png',
    gradient: {
      color1: 'rgba(5, 130, 152, 0.5)',
      color2: 'rgba(18, 158, 175, 0.3)',
      color3: 'rgba(5, 130, 152, 0.5)',
      color4: 'rgba(18, 158, 175, 0.3)',
      speed: '20s'
    },
    img: 'ideal_142x142.png',
    imgMobile: 'partner_mobil_90x160.png',
    imgThinner: 'partner_thinner.png',
    imgThinnerMobile: 'partner_thinner_mobile_.png',
    subtitle: 'Jak nastavit žebříček pro dětského uživatele?',
    button: {
      text: 'Registrace dětské verze',
      background: '#FFC7BA',
      icon: 'ladder.png',
      href: '/cs/registrace?kids=true'
    },
    text: 'Rodiče mohou ve verzi Eticia plus nastavit dětský žebříček pro děti do 13 let.',
    eticiaPlus: true,
  },
  section2: {
    bgColor: 'radial-gradient(circle at top, rgba(187,204,249,1) 0%, rgba(152,176,242,1) 100%)',
    backgroundImg: '0_Me_hodnoty_o_hodnotach.png',
    header: 'Eticia je připravena i pro dětské uživatele',
    subheader: 'Pro děti nebo pro mladé do 16 let',
    subtitle: 'Dětská verze je uzpůsobna pro mladší 16 let. Hlavním rozdílem je to, že v základním žebříčku není obsažena hodnota Sex, která je nahrazena hodnotou Dobrodružštví. Vyhodnocení je pak i pro dětského diváka stejné, jako v žebříčku hlavním. Způsoby jak aktivovat dětskou verzi jsou dva. Pro uživatele od 14-16 let je možná registrace, ale jejich profily budou zahrnuty jako profily dětské. Druhá variantaje, která spadá pod Eticia plus, je určena pro rodiče. V sekci výběru žebříčků si mohou zvolit dětský žebříček, který si pak mohou společně s dětmi vytvořit. Eticia plus také umožňuje vzájemné porovnání žebříčků a také vyhodnocení společných důležitých hodnot.',
    downtext: 'Povídejte si se svými dětmi o hodnotách'
  },
  section3: {
    backgroundImg: 'zeme1.jpg',
    text: '<strong><span style="color: #F8CEC3">Proč stojí za to věnovat svůj čas přemýšlení o hodnotách?</br></span></strong>Jak a v čem nám mohou hodnoty pomoct? Každý z nás si v sobě nosí svůj vlastní hodnotový kompas, který určuje, kam a za čím se v životě vydáme. Jaká cesta je pro nás přijatelná a kudy rozhodně jít nechceme...<br></br> <strong><span style="color: #F8CEC3">Denně děláme desítky rozhodnutí, ve kterém hrají podstatnou roli naše hodnoty.</strong></span> Nejen, že se díky nim přesněji rozhodujeme, ale také jsou důležité pro naši komunikaci a chápání druhých. <br></br><strong><span style="color: #F8CEC3">Proč vede naplňování našich hodnot k vlastní spokojenosti?</span><br></br> Naše přání i cíle jsou olemovány také našimi hodnotami, které lze jednoduše vyjádřit <span style="color: #F8CEC3">pomocí hodnotových žebříčků.</span> Ty nám ukazují konkrétní důležitost pro dané životní situace a když jsou naše hodnoty v souladu s tím, co děláme, <span style="color: #F8CEC3">jsme celkově spokojenější.',
    header: 'Objevuj hodnoty s Eticia.',
    subtitle: '',
    button: {
      text: '<span style="color: #E54FA6"> &#9660; </span>'
    }
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'partner_chemik1.png',
    header: '<span style="color: #000000">Hodnoty <span style="color: #C7129A"> pro děti </span></strong><span style="color: #000000"> a rodiče </span>',
    subtitle: '<span style="color: #000000">Děti hodnoty milují. Vzájemně se podívat na vaše hodnoty a popovídat si o nich je nejen zajímavé jak pro rodiče a děti, ale také obohacující. Užijte si dětskou verzi.</span>',
    boxHeaderText: 'Co v dětské verzi najdete',
    box1: {
      text: 'Źebříček je přímo uzpůsoben <strong>dětskému uživateli</strong> (neobsahuje hodnotu Sex)',
      img: 'partner_banky.png',
      width: '6rem',
      height: '6rem'
    },
    box2: {
      text: '<strong>Vyhodncení a průběh je <strong>stejný jako v klasické Eticia </strong>',
      img: 'partner_bryle.png',
      width: '6rem',
      height: '6rem'
    },
    box3: {
      text: '<strong>Možnost spravovat žebříček vašeho dítětě . Porovnání a hledání vzájemých hodnot s vyhodnocením přímo v aplikaci (pro verzi Eticia Plus)',
      img: 'partner_hlava.png',
      width: '6rem',
      height: '6rem'
    },
    boxBottomText: '<span style="color: #000000">Rodiče i děti mohou ve verzi Eticia svůj žebříček porovnat.</span>',
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #497E9D 0%, #497E9D 100%)',
    image: 'ikona_univerz.png',
    header: 'Hledání společných hodnot',
    subtitle: 'Podívejte se na to, jaké jsou pro každého z vás důležité hodnoty? Které to jsou a které máte společné. Díky porovnání žebříčků se můžete podívat na hodnoty jako celá rodina.',
    image2: 'partner_graf_830.png',
    image2Mobile: 'grafy_PRESENTING640X.png'
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #456C8A 0%, #2F4A62 99.92%)',
    heading: 'Chceš o svých hodnotách zjistit víc?',
    subheading: 'Vyzkoušej další ze sady speciálních hodnotových žebříčků',
    linkText: ''
  },
  section10: DefaultSection10,
  section12: DefaultSection12,
  section13: DefaultSection13
};

const Page31: PageProps = {
  section1: {
    header: '<h3 text align="center">Objevuj své životní hodnoty</h3><br></br>',
    backgroundImg: 'Zivotni-modra.jpg',
    backgroundImgThinner: 'love_partner.jpg',
    backgroundImg2: 'love_Back.png',
    backgroundImg2Mobile: 'mob_700.png',
    backgroundImg2Tablet: 'mob_700.png',
    gradient: {
      color1: 'rgba(48, 81, 134, 0.5)',
      color2: 'rgba(68, 108, 159, 0.3)',
      color3: 'rgba(48, 81, 134, 0.5)',
      color4: 'rgba(68, 108, 159, 0.3)',
      speed: '20s'
    },
    img: 'ideal_142x142.png',
    imgMobile: 'partner_mobil_90x160.png',
    imgThinner: 'partner_thinner.png',
    imgThinnerMobile: 'partner_thinner_mobile_.png',
    subtitle: 'Jak na to? ',
    button: {
      text: 'Vytvořit žebříček',
      background: '#999',
      icon: 'ladder.png',
      href: '/cs/registrace'
    },
    text: 'Nejdřív je potřeba zjistit, které hodnoty jsou pro tebe důležité v současné chvíli a sestavit si svůj první hodnotový žebříček v Etica 21. Ještě ho nemáš? Vytvoř si ho klidně hned.',
    eticiaPlus: false,
    bottomText: 'Vývoj svých hodnot můžeš pak v Eticia sledovat každý rok zdarma'
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'partner_chemik1.png',
    header: '<span style="color: #000000">Životní hodnoty',
    subtitle: '<span style="color: #000000">Některé naše hodnoty se mění v závislosti na nejrůznějších situacích, které zažíváme, jiné nás provázejí po dlouhá období, avšak ty nejdůležitější, ty jsou s námi třeba i po celý život.<br><br>Bez znalosti toho, co je pro nás dlouhodobě důležité, bychom se v životě jen pohybovali od jedné zastávky k druhé a od jednoho dočasného cíle k dalšímu. </br>',
    boxHeaderText: 'Proč je potřebuješ znát?',
    box1: {
      text: 'Pomáhají ti utvářet tvůj životní příběh ',
      img: 'partner_banky.png',
      width: '6rem',
      height: '6rem'
    },
    box2: {
      text: 'Dávají smysl tomu, co děláš',
      img: 'partner_bryle.png',
      width: '6rem',
      height: '6rem'
    },
    box3: {
      text: 'A můžeš se o ně v životě opřít…',
      img: 'partner_hlava.png',
      width: '6rem',
      height: '6rem'
    },
    boxBottomText: 'Za vším hledej hodnoty',
  },
  section4a: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'hamlet_76.png',
    text: 'Přítomnost a naplňování životních hodnot nám umožňuje vnímat a pociťovat smysl naší existence. “Být nebo nebýt - to je otázka….”'
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #a26c9a 0%, #a26c9a 100%)',
    image: 'ikona_univerz.png',
    header: '<span style="color: #F5D742"> Jak to funguje? </span>',
    subtitle: 'Algortimus Eticia každý rok porovná tvůj předchozí hodnotový žebříček (nebo žebříčky za více let) s tvým současným. Ve vyhodnocení pak uvidíš vývoj tvých hodnot a budeš moct podrobně pozorovat změny, které se v tvých volbách odehrály.',
    image2: 'zebr_ziv_830.png',
    image2Mobile: 'grafy_PRESENTING640X.png'
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #456C8A 0%, #2F4A62 99.92%)',
    heading: 'Chceš o svých hodnotách zjistit víc?',
    subheading: 'Vyzkoušej další ze sady speciálních hodnotových žebříčků',
    linkText: ''
  },
  section12: DefaultSection12,
  section13: DefaultSection13
};

const Page32: PageProps = {
  section1: {
    header: 'Hodnoty pro školy a dětské kolektivy<br></br>',
    backgroundImg: 'Rodice_deti_lightkids.jpg',
    backgroundImgThinner: 'love_partner.jpg',
    backgroundImg2: 'love_Back.png',
    backgroundImg2Mobile: 'mob_700.png',
    backgroundImg2Tablet: 'mob_700.png',
    gradient: {
      color1: 'rgba(5, 130, 152, 0.5)',
      color2: 'rgba(18, 158, 175, 0.3)',
      color3: 'rgba(5, 130, 152, 0.5)',
      color4: 'rgba(18, 158, 175, 0.3)',
      speed: '20s'
    },
    img: 'ideal_142x142.png',
    imgMobile: 'partner_mobil_90x160.png',
    imgThinner: 'partner_thinner.png',
    imgThinnerMobile: 'partner_thinner_mobile_.png',
    subtitle: 'Nastavení dětského uživatele',
    button: {
      text: 'Vytvořit žebříček',
      background: '#FFC7BA',
      icon: 'ladder.png'
    },
    text: 'Rodiče mohou nastavit dětský žebříček pod jejich účtem v Eticia Plus',
    eticiaPlus: true,
  },
  section2: {
    bgColor: 'radial-gradient(circle at top, rgba(239, 238, 236,1) 0%, rgba(239, 238, 236,1) 100%)',
    backgroundImg: '0_Me_hodnoty_o_hodnotach.png',
    header: 'Jednoduché řešení pro školy a kolektivy',
    subheader: 'Vhodné pro etickou a společenskou výchovu',
    subtitle: 'Význam poznávání hodnot pro etickou výchovu a společenské předměty přináší nejen zajímavé rozhovory a diskuze, ale také vede k jejich naplňování i vyššímu vzájemnému respektu mezi studenty.',
    downtext: 'V projektu pro školy existují i varianty speciálních žebříčků ušitých přímo na míru. Základní žebříček pro jednotlivce je zdarma. Za nastavení žebříčků dle zadání školy a následné zpracování společných výsledků (až do 30 studentů) si Eticia účtuje jednorázový poplatek 2.400,- Kč (bez DPH). Pro studenty je cena Eticia plus na 1 rok zvýhodněná na 79,- Kč/osobu.'
  },
  section3: {
    backgroundImg: 'zeme1.jpg',
    text: '<strong><span style="color: #F8CEC3">Proč stojí za to věnovat svůj čas přemýšlení o hodnotách?</br></span></strong>Jak a v čem nám mohou hodnoty pomoct? Každý z nás si v sobě nosí svůj vlastní hodnotový kompas, který určuje, kam a za čím se v životě vydáme. Jaká cesta je pro nás přijatelná a kudy rozhodně jít nechceme...<br></br> <strong><span style="color: #F8CEC3">Denně děláme desítky rozhodnutí, ve kterém hrají podstatnou roli naše hodnoty.</strong></span> Nejen, že se díky nim přesněji rozhodujeme, ale také jsou důležité pro naši komunikaci a chápání druhých. <br></br><strong><span style="color: #F8CEC3">Proč vede naplňování našich hodnot k vlastní spokojenosti?</span><br></br> Naše přání i cíle jsou olemovány také našimi hodnotami, které lze jednoduše vyjádřit <span style="color: #F8CEC3">pomocí hodnotových žebříčků.</span> Ty nám ukazují konkrétní důležitost pro dané životní situace a když jsou naše hodnoty v souladu s tím, co děláme, <span style="color: #F8CEC3">jsme celkově spokojenější.',
    header: 'Objevuj hodnoty s Eticia.',
    subtitle: '',
    button: {
      text: '<span style="color: #E54FA6"> &#9660; </span>'
    }
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'partner_chemik1.png',
    header: '<span style="color: #000000">Vylaďte svoji třídu a </span><strong><span style="color: #C7129A"> studujte</span></strong><span style="color: #000000"> hodnoty společně</span>',
    subtitle: '<span style="color: #000000">Lorem ipsum - text aktualizovat pro školy a dětské kolektivy <strong> Hodnoty se mísí s chemií.</strong> Lorem ipsum <br><br> <strong><span style="color: #C7129A">Udělej si v tom jasno - speciálních 42 hodnot pro partnerství</span></strong><br></br>Tento žebříček, obsahující novou sadu hodnot, ti pomůže si ujasnit, co od koho vlastně čekáš a jaké hodnoty by měl naplňovat<strong> partner, se kterým se budeš cítit dobře.</strong> Je úplně jedno, jestli v současné chvíli partnera máš, a nebo hledáš.</br></span>',
    boxHeaderText: '',
    box1: {
      text: 'Možnost kompletního vyhodnocení<strong> pro jednotlivé třídy i celou školu/strong>',
      img: 'partner_banky.png',
      width: '6rem',
      height: '6rem'
    },
    box2: {
      text: '<strong>Každý </strong>student získá speciální odkaz k tvorbě žebříčku</strong>',
      img: 'partner_bryle.png',
      width: '6rem',
      height: '6rem'
    },
    box3: {
      text: '<strong>Každý uživatel může<strong> svůj žebříček porovnávat i v dalších letech',
      img: 'partner_hlava.png',
      width: '6rem',
      height: '6rem'
    },
    boxBottomText: 'Každý uživatel studentské skupiny si může zahrát hodnotovou hru i evidovat své hodnoty v čase',
  },
  section5: {
    backgroundColor: 'radial-gradient(circle at top, #847DA8 0%, #847DA8 100%)',
    header: 'Jak to funguje?',
    text1: '1. Učitel/škola zadá požadavek k tvorbě žebříčků',
    text2: '2. Eticia nastaví žebříček dle požadavku školy a učiteli zašle speciální odkaz ke spuštění žebříčků',
    text3: '3. Každý ze studentů získá své vlastní vyhodnocení a škola obdrží výstup společných hodnot dle původního zadání',
    downtext: 'V případě tvorby společného žebříčku si Eticia za zpracování účtuje 1.200,- Kč (do 30 studentů)',
    customIcon1: 'ikona_univerz.png',
    customIcon2: 'ikona_univerz.png',
    customIcon3: 'ikona_univerz.png'
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #497E9D 0%, #497E9D 100%)',
    image: 'ikona_univerz.png',
    header: 'Lorem ipsum - aktualizovat tex',
    subtitle: 'V jakých oblastech života doufáš, že si budete co nejvíce podobní, a v čem naopak uvítáš svůj pravý opak? Na tuto otázku můžeš najít odpověď porovnáním hodnotových žebříčků <strong>#moje hodnoty a #můj ideální partner.<strong> ',
    image2: 'partner_graf_830.png',
    image2Mobile: 'grafy_PRESENTING640X.png'
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #456C8A 0%, #2F4A62 99.92%)',
    heading: 'Chceš o svých hodnotách zjistit víc?',
    subheading: 'Vyzkoušej další ze sady speciálních hodnotových žebříčků',
    linkText: ''
  },
  section10: DefaultSection10,
  section12: DefaultSection12,
  section13: DefaultSection13
};

const Page33: PageProps = {
  section1: {
    header: 'Etické a společenské kodexy<br></br>',
    backgroundImg: 'Prace---gray.jpg',
    backgroundImgThinner: 'love_partner.jpg',
    backgroundImg2: 'love_Back.png',
    backgroundImg2Mobile: 'mob_700.png',
    backgroundImg2Tablet: 'mob_700.png',
    gradient: {
      color1: 'rgba(87, 88, 109, 0.5)',
      color2: 'rgba(115, 116, 137, 0.3)',
      color3: 'rgba(87, 88, 109, 0.5)',
      color4: 'rgba(115, 116, 137, 0.3)',
      speed: '20s'
    },
    img: 'ideal_142x142.png',
    imgMobile: 'partner_mobil_90x160.png',
    imgThinner: 'partner_thinner.png',
    imgThinnerMobile: 'partner_thinner_mobile_.png',
    subtitle: 'Pro firmy, NGO i kolektivy',
    button: {
      text: 'Napište nám',
      background: '#FFC7BA',
      icon: 'ladder.png'
    },
    text: '',
    eticiaPlus: true,
  },
  section2: {
    bgColor: 'radial-gradient(circle at top, rgba(239, 238, 236,1) 0%, rgba(239, 238, 236,1) 100%)',
    backgroundImg: '0_Me_hodnoty_o_hodnotach.png',
    header: 'Etický kodex jako velmi užitečný pomocník',
    subheader: 'Aktualizovat text pro etické kodexy firem',
    subtitle: 'Lorem ipsum....... aktualizovat text .......... a společenské předměty přináší nejen zajímavé rozhovory a diskuze, ale také vede k jejich naplňování, vyššímu respektu mezi jednotlivými studenty.',
    downtext: 'V projektu pro školy existují i varianty speciálních žebříčků přímo na míru. Základní služba je zdarma. Za koknrétní zpracování společných výsledků žebříčků včetně nastavení si Eticia účtuje jednorázový poplatek 1.200,- Kč (bez DPH). Platí do 30 studentů.'
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'partner_chemik1.png',
    header: '<span style="color: #000000">Aktualizovat text<strong>fungující</span><span style="color: #C7129A"> a studujte</span></strong><span style="color: #000000"> hodnoty společně</span>',
    subtitle: '<span style="color: #000000">Aktualizovat text ...... Každý z nás to někdy zažil. Lidé jsou k sobě přitahováni velkou silou přírody, ale také společnými zájmy, postoji a pohledy na život. A ty jsou neméně důležité! <strong> Hodnoty se mísí s chemií.</strong> Představu o svém „ideálním partnerovi“ si nosíme každý v sobě. <br><br> <strong><span style="color: #C7129A">Udělej si v tom jasno - speciálních 42 hodnot pro partnerství</span></strong><br></br>Tento žebříček, obsahující novou sadu hodnot, ti pomůže si ujasnit, co od koho vlastně čekáš a jaké hodnoty by měl naplňovat<strong> partner, se kterým se budeš cítit dobře.</strong> Je úplně jedno, jestli v současné chvíli partnera máš, a nebo hledáš.</br></span>',
    boxHeaderText: '',
    box1: {
      text: 'Možnost zaslání kompletního vyhodnocení<strong> pro jednotlivé třídy i celou školu/strong>',
      img: 'partner_banky.png',
      width: '6rem',
      height: '6rem'
    },
    box2: {
      text: '<strong>Každý </strong>student získá speciální odkaz k tvorbě žebříčku</strong>',
      img: 'partner_bryle.png',
      width: '6rem',
      height: '6rem'
    },
    box3: {
      text: '<strong>Možnost pro každého <strong> svůj žebříček porovnávat i v dalších letech',
      img: 'partner_hlava.png',
      width: '6rem',
      height: '6rem'
    },
    boxBottomText: 'Každý žebříček studenta umožňuje si zahrát hodnotovou hru i evidovat své hodnoty v čase',
  },
  section5: {
    backgroundColor: 'radial-gradient(circle at top, #847DA8 0%, #847DA8 100%)',
    header: 'Jak to funguje?',
    text1: '1. Učitel/škola zadá požadavek k tvorbě žebříčků',
    text2: '2. Eticia nastaví žebříček dle požadavků učitele a zašle speciální odkaz na tvorbu žebříčk',
    text3: '3. Každý ze studentů získá své vyhodnocení a dle požadavků školy připraví Eticia souhrn společných hodnot',
    downtext: 'V případě tvorby společného žebříčku si Eticia za zpracování účtuje 1.200,- Kč (do 30 studentů)',
    customIcon1: 'ikona_univerz.png',
    customIcon2: 'ikona_univerz.png',
    customIcon3: 'ikona_univerz.png'
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #435B8D 0%, #435B8D 100%)',
    image: 'ikona_univerz.png',
    header: 'Ukázka vstupní stránky a společného vyhodnocení',
    subtitle: 'Vše je velmi jednoduché. Pakliže máte zájem o vytvoření společného žebříčku a jeho vyhodnocení, <strong> kontaktuje nás.<strong> ',
    image2: 'partner_graf_830.png',
    image2Mobile: 'grafy_PRESENTING640X.png'
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #456C8A 0%, #2F4A62 99.92%)',
    heading: 'Jaké žebříčky lze vytvářet',
    subheading: 'Eticia nabízí žebříčky v oblasti motivací, partnerských vztahů i při hledání vhodné profese',
    linkText: ''
  },
  section10: DefaultSection10,
  section12: DefaultSection12,
  section13: DefaultSection13
};

const Page34: PageProps = {
  section1: {
    header: 'Pracovní týmy a práce s hodnotami<br></br>',
    backgroundImg: 'Prace---gray.jpg',
    backgroundImgThinner: 'love_partner.jpg',
    backgroundImg2: 'love_Back.png',
    backgroundImg2Mobile: 'mob_700.png',
    backgroundImg2Tablet: 'mob_700.png',
    gradient: {
      color1: 'rgba(87, 88, 109, 0.5)',
      color2: 'rgba(115, 116, 137, 0.3)',
      color3: 'rgba(87, 88, 109, 0.5)',
      color4: 'rgba(115, 116, 137, 0.3)',
      speed: '20s'
    },
    img: 'ideal_142x142.png',
    imgMobile: 'partner_mobil_90x160.png',
    imgThinner: 'partner_thinner.png',
    imgThinnerMobile: 'partner_thinner_mobile_.png',
    subtitle: 'Nesmírně užitečný nástroj, který posune vaši firmu mílovými kroky vpřed!',
    button: {
      text: 'Napište nám',
      background: '#FFC7BA',
      icon: 'ladder.png'
    },
    text: '',
    eticiaPlus: true,
  },
  section2: {
    bgColor: 'radial-gradient(circle at top, rgba(239, 238, 236,1) 0%, rgba(239, 238, 236,1) 100%)',
    backgroundImg: '0_Me_hodnoty_o_hodnotach.png',
    header: 'Jednoduchá řešení, jednoduše nastavitelné',
    subheader: 'Plnohodnotné výstupy, které pomůžou při hledání záměrů vaší firmy',
    subtitle: 'Význam poznávání hodnot pro etickou výchovu a společenské předměty přináší nejen zajímavé rozhovory a diskuze, ale také vede k jejich naplňování, vyššímu respektu mezi jednotlivými studenty.',
    downtext: 'Lorem ipsum'
  },
  section3: {
    backgroundImg: 'zeme1.jpg',
    text: '<strong><span style="color: #F8CEC3">Význam hodnot v pracovním prostředí</br></span></strong> Lorem... Každý z nás si v sobě nosí svůj vlastní hodnotový kompas, který určuje, kam a za čím se v životě vydáme. Jaká cesta je pro nás přijatelná a kudy rozhodně jít nechceme...<br></br> <strong><span style="color: #F8CEC3">Denně děláme desítky rozhodnutí, ve kterém hrají podstatnou roli naše hodnoty.</strong></span> Nejen, že se díky nim přesněji rozhodujeme, ale také jsou důležité pro naši komunikaci a chápání druhých. <br></br><strong><span style="color: #F8CEC3">Proč vede naplňování našich hodnot k vlastní spokojenosti?</span><br></br> Naše přání i cíle jsou olemovány také našimi hodnotami, které lze jednoduše vyjádřit <span style="color: #F8CEC3">pomocí hodnotových žebříčků.</span> Ty nám ukazují konkrétní důležitost pro dané životní situace a když jsou naše hodnoty v souladu s tím, co děláme, <span style="color: #F8CEC3">jsme celkově spokojenější.',
    header: 'Zahájit tvorbu kodexu',
    subtitle: '',
    button: {
      text: '<span style="color: #E54FA6"> &#9660; </span>'
    }
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'partner_chemik1.png',
    header: '<span style="color: #000000">Hodnoty jako cesta<strong>k cíli,/span></strong><span style="color: #000000"> který začnete vidět</span>',
    subtitle: '<span style="color: #000000">Hodnoty, vztahy, vize i motivace a způsoby chování,hrají v každé společnosti význmanou roli <strong> Hodnoty se mísí s chemií.</strong> Představu o svém „ideálním partnerovi“ si nosíme každý v sobě. <br><br> <strong><span style="color: #C7129A">Udělej si v tom jasno - speciálních 42 hodnot pro partnerství</span></strong><br></br>Tento žebříček, obsahující novou sadu hodnot, ti pomůže si ujasnit, co od koho vlastně čekáš a jaké hodnoty by měl naplňovat<strong> partner, s kterým se budeš cítit dobře.</strong> Je úplně jedno, jestli v současné chvíli partnera máš, a nebo hledáš.</br></span>',
    boxHeaderText: '',
    box1: {
      text: ' <strong> Tvobra etických kodexů</strong> na míru',
      img: 'partner_banky.png',
      width: '6rem',
      height: '6rem'
    },
    box2: {
      text: '<strong>Základní nastavení a vyplnění</strong>zabere každému 15 minut</strong>',
      img: 'partner_bryle.png',
      width: '6rem',
      height: '6rem'
    },
    box3: {
      text: '<strong>Pro týmy od 3 do 10 000 lidí<strong>',
      img: 'partner_hlava.png',
      width: '6rem',
      height: '6rem'
    },
    boxBottomText: 'Položte si víc otázek, hodnoty vám odpoví.',
  },
  section5: {
    backgroundColor: 'radial-gradient(circle at top, #847DA8 0%, #847DA8 100%)',
    header: 'Jak to funguje?',
    text1: '1. Kontaktuje nás. Základní etický kodex na míru vám spustíme do 24 hodin.',
    text2: '2. Vzájemná konzultace, ve které zjistíme, na které otázky byste rádi získali odpovědi a nastavíme webové prostředí pro vaši firmu',
    text3: '3. Výsledky i výstupy z doprovodných anket vyhodnotíme a vy získáte kompletní společné vyhodnocení.',
    downtext: 'Základní cena etického kodex pro skupinu do 30 účastníků od 19.999,- Kč ',
    customIcon1: 'ikona_univerz.png',
    customIcon2: 'ikona_univerz.png',
    customIcon3: 'ikona_univerz.png'
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #435B8D 0%, #435B8D 100%)',
    image: 'ikona_univerz.png',
    header: 'Jak vypadají výstupy a co získáte',
    subtitle: 'Kompletní souhrné výsledky celé skupiny, vyhodnocené doplňkové odpovědi a matice hodnotových vztahů mezi jednotlivými účastníky včetně doporučení, jak s hodnotami pracovat. <strong> kontaktuje nás.<strong> ',
    image2: 'partner_graf_830.png',
    image2Mobile: 'grafy_PRESENTING640X.png'
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #456C8A 0%, #2F4A62 99.92%)',
    heading: 'Jaké žebříčky lze pro firemní prostředí vytvářet',
    subheading: 'Klíčová je otázka!',
    linkText: ''
  },
  section12: DefaultSection12,
  section13: DefaultSection13
};

const Page35: PageProps = {
  section1: {
    header: 'Mé superschopnosti<br></br>',
    backgroundImg: 'Prace---gray.jpg',
    backgroundImgThinner: 'love_partner.jpg',
    backgroundImg2: 'love_Back.png',
    backgroundImg2Mobile: 'mob_700.png',
    backgroundImg2Tablet: 'mob_700.png',
    gradient: {
      color1: 'rgba(87, 88, 109, 0.5)',
      color2: 'rgba(115, 116, 137, 0.3)',
      color3: 'rgba(87, 88, 109, 0.5)',
      color4: 'rgba(115, 116, 137, 0.3)',
      speed: '20s'
    },
    img: 'ideal_142x142.png',
    imgMobile: 'partner_mobil_90x160.png',
    imgThinner: 'partner_thinner.png',
    imgThinnerMobile: 'partner_thinner_mobile_.png',
    subtitle: 'Každý má talent na něco jiného a to mu jde od ruky...',
    button: {
      text: 'Spustit žebříček',
      background: '#FFC7BA',
      icon: 'ladder.png',
      href: '/cs/app/hra-intro?t=399a3d53-ce8a-402d-8cd3-a877d782e924',
      redirectAfterRegistration: true,
    },
    text: '',
    eticiaPlus: true,
  },
  section2: {
    bgColor: 'radial-gradient(circle at top, rgba(187,204,249,1) 0%, rgba(152,176,242,1) 100%)',
    backgroundImg: '0_Me_hodnoty_o_hodnotach.png',
    header: 'Někdy o nich víme a někdy je nedokážeme správně interpretovat',
    subheader: 'Pojmenujte v týmu si jednoduše a rychle ty schopnosti, které jsou vám vlastní i blízké..',
    subtitle: 'Význam poznávání hodnot pro etickou výchovu a společenské předměty přináší nejen zajímavé rozhovory a diskuze, ale také vede k jejich naplňování, vyššímu respektu mezi jednotlivými studenty.',
    downtext: 'Lorem ipsum'
  },
  section3: {
    backgroundImg: 'zeme1.jpg',
    text: '<strong><span style="color: #F8CEC3">Souhra a souznění v směřování vašeho projektu či cíle vede k efektivitě a vyšší kreativitě</br></span></strong> Lorem... Každý z nás si v sobě nosí svůj vlastní hodnotový kompas, který určuje, kam a za čím se v životě vydáme. Jaká cesta je pro nás přijatelná a kudy rozhodně jít nechceme...<br></br> <strong><span style="color: #F8CEC3">Denně děláme desítky rozhodnutí, ve kterém hrají podstatnou roli naše hodnoty.</strong></span> Nejen, že se díky nim přesněji rozhodujeme, ale také jsou důležité pro naši komunikaci a chápání druhých. <br></br><strong><span style="color: #F8CEC3">Proč vede naplňování našich hodnot k vlastní spokojenosti?</span><br></br> Naše přání i cíle jsou olemovány také našimi hodnotami, které lze jednoduše vyjádřit <span style="color: #F8CEC3">pomocí hodnotových žebříčků.</span> Ty nám ukazují konkrétní důležitost pro dané životní situace a když jsou naše hodnoty v souladu s tím, co děláme, <span style="color: #F8CEC3">jsme celkově spokojenější.',
    header: 'Zahájit tvorbu kodexu',
    subtitle: '',
    button: {
      text: '<span style="color: #E54FA6"> &#9660; </span>'
    }
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'partner_chemik1.png',
    header: '<span style="color: #000000">Neprocházejte trnitou cestou<strong>když tu jsou</span><span style="color: #C7129A"> hodnoty/span></strong>',
    subtitle: '<span style="color: #000000">Hodnoty, vztahy, vize i motivace a způsoby chování,hrají v každé společnosti význmanou roli <strong> Hodnoty se mísí s chemií.</strong> Představu o svém „ideálním partnerovi“ si nosíme každý v sobě. <br><br> <strong><span style="color: #C7129A">Udělej si v tom jasno - speciálních 42 hodnot pro partnerství</span></strong><br></br>Tento žebříček, obsahující novou sadu hodnot, ti pomůže si ujasnit, co od koho vlastně čekáš a jaké hodnoty by měl naplňovat<strong> partner, se kterým se budeš cítit dobře.</strong> Je úplně jedno, jestli v současné chvíli partnera máš, a nebo hledáš.</br></span>',
    boxHeaderText: '',
    box1: {
      text: ' <strong>Nalezení společných hodnot</strong> klidně do hodiny',
      img: 'partner_banky.png',
      width: '6rem',
      height: '6rem'
    },
    box2: {
      text: '<strong>Základní nastavení celého procesu</strong>dle požadavků klienta </strong>',
      img: 'partner_bryle.png',
      width: '6rem',
      height: '6rem'
    },
    box3: {
      text: '<strong>Pro týmy od 3 do 10 000 lidí<strong>',
      img: 'partner_hlava.png',
      width: '6rem',
      height: '6rem'
    },
    boxBottomText: 'Položte si více otázek, hodnoty vám odpoví. ',
  },
  section5: {
    backgroundColor: 'radial-gradient(circle at top, #847DA8 0%, #847DA8 100%)',
    header: 'Jak to funguje?',
    text1: '1. Kontaktuje nás. Zašleme vám možnosti. Vyberete si se základního nastavení či vám uzpůsobíme žebříčky na míru',
    text2: '2. Připravíme vám webové rozhraní',
    text3: '3. Kompletní výsledky i výstupy včetně doprovodných anket Vám vyhodnotíme a zašleme na Váš email.',
    downtext: 'Základní cena hodnot vašeho projektu do 30 účastníků od 12.999,- Kč ',
    customIcon1: 'ikona_univerz.png',
    customIcon2: 'ikona_univerz.png',
    customIcon3: 'ikona_univerz.png'
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #3ECAD1 0%, #088F96 100%)',
    image: 'ikona_univerz.png',
    header: 'Jak vypadají výstupy a co získáte',
    subtitle: 'Kompletní souhrné výsledky celé skupiny, vyhodnocené doplňkové odpovědi a matice hodnotových vztahů mezi jednotlivými účastníky včetně doporučení, jak s hodnotami pracovat. <strong> kontaktuje nás.<strong> ',
    image2: 'partner_graf_830.png',
    image2Mobile: 'grafy_PRESENTING640X.png'
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #456C8A 0%, #2F4A62 99.92%)',
    heading: 'Jaké žebříčky lze pro firemní prostředí vytvářet',
    subheading: 'Klíčová je otázka!',
    linkText: ''
  },
  section12: DefaultSection12,
  section13: DefaultSection13
};

const Page36: PageProps = {
  section1: {
    header: 'Není žádná presenting page<br></br>',
    backgroundImg: 'love_partner.jpg',
    backgroundImgThinner: 'love_partner.jpg',
    backgroundImg2: 'love_Back.png',
    backgroundImg2Mobile: 'mob_700.png',
    backgroundImg2Tablet: 'mob_700.png',
    gradient: {
      color1: 'rgba(129, 30, 186, 0.6)',
      color2: 'rgba(232, 52, 136, 0.6)',
      color3: 'rgba(129, 30, 186, 0.6)',
      color4: 'rgba(232, 52, 136, 0.6)',
      speed: '30s'
    },
    img: 'ideal_142x142.png',
    imgMobile: 'partner_mobil_90x160.png',
    imgThinner: 'partner_thinner.png',
    imgThinnerMobile: 'partner_thinner_mobile_.png',
    subtitle: '',
    button: {
      text: 'Napište nám',
      background: '#FFC7BA',
      icon: 'ladder.png'
    },
    text: '',
    eticiaPlus: true,
  },
  section2: {
    bgColor: 'radial-gradient(circle at top, rgba(187,204,249,1) 0%, rgba(152,176,242,1) 100%)',
    backgroundImg: '0_Me_hodnoty_o_hodnotach.png',
    header: 'Hodnoty našeho projektu a přístup k jeho prezentaci',
    subheader: 'Pojmenujte v týmu si jednoduše a rychle nejdůležitější hodnoty vašeho projektu.',
    subtitle: 'Význam poznávání hodnot pro etickou výchovu a společenské předměty přináší nejen zajímavé rozhovory a diskuze, ale také vede k jejich naplňování, vyššímu respektu mezi jednotlivými studenty.',
    downtext: 'Lorem ipsum'
  },
  section3: {
    backgroundImg: 'zeme1.jpg',
    text: '<strong><span style="color: #F8CEC3">Souhra a souznění v směřování vašeho projektu či cíle vede k efektivitě a vyšší kreativitě</br></span></strong> Lorem... Každý z nás si v sobě nosí svůj vlastní hodnotový kompas, který určuje, kam a za čím se v životě vydáme. Jaká cesta je pro nás přijatelná a kudy rozhodně jít nechceme...<br></br> <strong><span style="color: #F8CEC3">Denně děláme desítky rozhodnutí, ve kterém hrají podstatnou roli naše hodnoty.</strong></span> Nejen, že se díky nim přesněji rozhodujeme, ale také jsou důležité pro naši komunikaci a chápání druhých. <br></br><strong><span style="color: #F8CEC3">Proč vede naplňování našich hodnot k vlastní spokojenosti?</span><br></br> Naše přání i cíle jsou olemovány také našimi hodnotami, které lze jednoduše vyjádřit <span style="color: #F8CEC3">pomocí hodnotových žebříčků.</span> Ty nám ukazují konkrétní důležitost pro dané životní situace a když jsou naše hodnoty v souladu s tím, co děláme, <span style="color: #F8CEC3">jsme celkově spokojenější.',
    header: 'Zahájit tvorbu kodexu',
    subtitle: '',
    button: {
      text: '<span style="color: #E54FA6"> &#9660; </span>'
    }
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'partner_chemik1.png',
    header: '<span style="color: #000000">Neprocházejte trnitou cestou<strong>když tu jsou</span><span style="color: #C7129A"> hodnoty/span></strong>',
    subtitle: '<span style="color: #000000">Hodnoty, vztahy, vize i motivace a způsoby chování,hrají v každé společnosti význmanou roli <strong> Hodnoty se mísí s chemií.</strong> Představu o svém „ideálním partnerovi“ si nosíme každý v sobě. <br><br> <strong><span style="color: #C7129A">Udělej si v tom jasno - speciálních 42 hodnot pro partnerství</span></strong><br></br>Tento žebříček, obsahující novou sadu hodnot, ti pomůže si ujasnit, co od koho vlastně čekáš a jaké hodnoty by měl naplňovat<strong> partner, se kterým se budeš cítit dobře.</strong> Je úplně jedno, jestli v současné chvíli partnera máš, a nebo hledáš.</br></span>',
    boxHeaderText: '',
    box1: {
      text: ' <strong>Nalezení společných hodnot</strong> klidně do hodiny',
      img: 'partner_banky.png',
      width: '6rem',
      height: '6rem'
    },
    box2: {
      text: '<strong>Základní nastavení celého procesu</strong>dle požadavků klienta </strong>',
      img: 'partner_bryle.png',
      width: '6rem',
      height: '6rem'
    },
    box3: {
      text: '<strong>Pro týmy od 3 do 10 000 lidí<strong>',
      img: 'partner_hlava.png',
      width: '6rem',
      height: '6rem'
    },
    boxBottomText: 'Položte si více otázek, hodnoty vám odpoví. ',
  },
  section5: {
    backgroundColor: 'radial-gradient(circle at top, #847DA8 0%, #847DA8 100%)',
    header: 'Jak to funguje?',
    text1: '1. Kontaktuje nás. Zašleme vám možnosti. Vyberete si se základního nastavení či vám uzpůsobíme žebříčky na míru',
    text2: '2. Připravíme vám webové rozhraní',
    text3: '3. Kompletní výsledky i výstupy včetně doprovodných anket Vám vyhodnotíme a zašleme na Váš email.',
    downtext: 'Základní cena hodnot vašeho projektu do 30 účastníků od 12.999,- Kč ',
    customIcon1: 'ikona_univerz.png',
    customIcon2: 'ikona_univerz.png',
    customIcon3: 'ikona_univerz.png'
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #3ECAD1 0%, #088F96 100%)',
    image: 'ikona_univerz.png',
    header: 'Jak vypadají výstupy a co získáte',
    subtitle: 'Kompletní souhrné výsledky celé skupiny, vyhodnocené doplňkové odpovědi a matice hodnotových vztahů mezi jednotlivými účastníky včetně doporučení, jak s hodnotami pracovat. <strong> kontaktuje nás.<strong> ',
    image2: 'partner_graf_830.png',
    image2Mobile: 'grafy_PRESENTING640X.png'
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #456C8A 0%, #2F4A62 99.92%)',
    heading: 'Jaké žebříčky lze pro firemní prostředí vytvářet',
    subheading: 'Klíčová je otázka!',
    linkText: ''
  },
  section12: DefaultSection12,
  section13: DefaultSection13
};

const Page37: PageProps = {
  section1: {
    header: 'Co na tobě oceňuji<br></br>',
    backgroundImg: 'Partnerstvi---red.jpg',
    backgroundImgThinner: 'love_partner.jpg',
    backgroundImg2: 'love_Back.png',
    backgroundImg2Mobile: 'mob_700.png',
    backgroundImg2Tablet: 'mob_700.png',
    gradient: {
      color1: 'rgba(132, 38, 65, 0.5)',
      color2: 'rgba(173,73,111, 0.3)',
      color3: 'rgba(132, 38, 65, 0.5)',
      color4: 'rgba(173,73,111, 0.3)',
      speed: '20s'
    },
    img: 'ocenuji_PP.png',
    imgMobile: 'ocenuji.png',
    imgThinner: 'partner_thinner.png',
    imgThinnerMobile: 'partner_thinner_mobile_.png',
    subtitle: 'Občas je těžké a přitom skvělé ocenit druhého. S hodnotami to jde příjemně a lehce',
    button: {
      text: 'Napište nám',
      background: '#FFC7BA',
      icon: 'ladder.png',
      href: '/cs/app/hra-intro?t=18c05bd1-e8f3-42ca-bcc2-a28ac1a2e852',
      redirectAfterRegistration: true,
    },
    text: '',
    eticiaPlus: true,
  },
  section3: {
    backgroundImg: 'zeme1.jpg',
    text: '<strong><span style="color: #F8CEC3">Souhra a souznění v směřování vašeho projektu či cíle vede k efektivitě a vyšší kreativitě</br></span></strong> Lorem... Každý z nás si v sobě nosí svůj vlastní hodnotový kompas, který určuje, kam a za čím se v životě vydáme. Jaká cesta je pro nás přijatelná a kudy rozhodně jít nechceme...<br></br> <strong><span style="color: #F8CEC3">Denně děláme desítky rozhodnutí, ve kterém hrají podstatnou roli naše hodnoty.</strong></span> Nejen, že se díky nim přesněji rozhodujeme, ale také jsou důležité pro naši komunikaci a chápání druhých. <br></br><strong><span style="color: #F8CEC3">Proč vede naplňování našich hodnot k vlastní spokojenosti?</span><br></br> Naše přání i cíle jsou olemovány také našimi hodnotami, které lze jednoduše vyjádřit <span style="color: #F8CEC3">pomocí hodnotových žebříčků.</span> Ty nám ukazují konkrétní důležitost pro dané životní situace a když jsou naše hodnoty v souladu s tím, co děláme, <span style="color: #F8CEC3">jsme celkově spokojenější.',
    header: 'Zahájit tvorbu kodexu',
    subtitle: '',
    button: {
      text: '<span style="color: #E54FA6"> &#9660; </span>'
    }
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'partner_chemik1.png',
    header: '<span style="color: #000000">Neprocházejte trnitou cestou, <strong>když tu jsou</span><span style="color: #C7129A"> hodnoty</span></strong>',
    subtitle: '<span style="color: #000000">Hodnoty, vztahy, vize i motivace a způsoby chování hrají v každé společnosti významnou roli. <strong> Lorem ipsum - změna textu: Hodnoty se mísí s chemií.</strong> Představu o svém „ideálním partnerovi“ si nosíme každý v sobě. <br><br> <strong><span style="color: #C7129A">Udělej si v tom jasno - speciálních 42 hodnot pro partnerství</span></strong><br></br>Tento žebříček, obsahující novou sadu hodnot, ti pomůže si ujasnit, co od koho vlastně čekáš a jaké hodnoty by měl naplňovat<strong> partner, se kterým se budeš cítit dobře.</strong> Je úplně jedno, jestli v současné chvíli partnera máš, a nebo hledáš.</br></span>',
    boxHeaderText: '',
    box1: {
      text: ' <strong>Nalezení společných hodnot</strong> klidně do hodiny',
      img: 'partner_banky.png',
      width: '6rem',
      height: '6rem'
    },
    box2: {
      text: '<strong>Základní nastavení celého procesu</strong> dle požadavků klienta </strong>',
      img: 'partner_bryle.png',
      width: '6rem',
      height: '6rem'
    },
    box3: {
      text: '<strong>Pro týmy od 3 do 10 000 lidí<strong>',
      img: 'partner_hlava.png',
      width: '6rem',
      height: '6rem'
    },
    boxBottomText: 'Položte si více otázek, hodnoty vám odpoví. ',
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #A64365 0%, #A64365 100%)',
    image: 'ikona_univerz.png',
    header: 'Jak vypadají výstupy a co získáte',
    subtitle: 'Kompletní souhrné výsledky celé skupiny, vyhodnocené doplňkové odpovědi a matice hodnotových vztahů mezi jednotlivými účastníky včetně doporučení jak s hodnotami pracovat. <strong> Kontaktuje nás.</strong> ',
    image2: 'partner_graf_830.png',
    image2Mobile: 'grafy_PRESENTING640X.png'
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #456C8A 0%, #2F4A62 99.92%)',
    heading: 'Jaké žebříčky lze pro firemní prostředí vytvářet',
    subheading: 'Klíčová je otázka!',
    linkText: ''
  },
  section12: DefaultSection12,
  section13: DefaultSection13
};

const Page38: PageProps = {
  section1: {
    header: 'Hodnoty mého projektu<br></br>',
    backgroundImg: 'Prace---gray.jpg',
    backgroundImgThinner: 'love_partner.jpg',
    backgroundImg2: 'love_Back.png',
    backgroundImg2Mobile: 'mob_700.png',
    backgroundImg2Tablet: 'mob_700.png',
    gradient: {
      color1: 'rgba(87, 88, 109, 0.5)',
      color2: 'rgba(115, 116, 137, 0.3)',
      color3: 'rgba(87, 88, 109, 0.5)',
      color4: 'rgba(115, 116, 137, 0.3)',
      speed: '20s'
    },
    img: 'ideal_142x142.png',
    imgMobile: 'partner_mobil_90x160.png',
    imgThinner: 'partner_thinner.png',
    imgThinnerMobile: 'partner_thinner_mobile_.png',
    subtitle: '',
    button: {
      text: 'Napište nám',
      background: '#FFC7BA',
      icon: 'ladder.png'
    },
    text: '',
    eticiaPlus: true,
  },
  section3: {
    backgroundImg: 'zeme1.jpg',
    text: '<strong><span style="color: #F8CEC3">Souhra a souznění ve směřování vašeho projektu či cíle vede k efektivitě a vyšší kreativitě</br></span></strong> Lorem... Každý z nás si v sobě nosí svůj vlastní hodnotový kompas, který určuje, kam a za čím se v životě vydáme. Jaká cesta je pro nás přijatelná a kudy rozhodně jít nechceme...<br></br> <strong><span style="color: #F8CEC3">Denně děláme desítky rozhodnutí, ve kterém hrají podstatnou roli naše hodnoty.</strong></span> Nejen, že se díky nim přesněji rozhodujeme, ale také jsou důležité pro naši komunikaci a chápání druhých. <br></br><strong><span style="color: #F8CEC3">Proč vede naplňování našich hodnot k vlastní spokojenosti?</span><br></br> Naše přání i cíle jsou olemovány také našimi hodnotami, které lze jednoduše vyjádřit <span style="color: #F8CEC3">pomocí hodnotových žebříčků.</span> Ty nám ukazují konkrétní důležitost pro dané životní situace a když jsou naše hodnoty v souladu s tím, co děláme, <span style="color: #F8CEC3">jsme celkově spokojenější.',
    header: 'Zahájit tvorbu kodexu',
    subtitle: '',
    button: {
      text: '<span style="color: #E54FA6"> &#9660; </span>'
    }
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'partner_chemik1.png',
    header: '<span style="color: #000000">Neprocházejte trnitou cestou, <strong>když tu jsou</span><span style="color: #C7129A"> hodnoty</span></strong>',
    subtitle: '<span style="color: #000000">Hodnoty, vztahy, vize i motivace a způsoby chování hrají v každé společnosti význmanou roli. <strong> Změna textu......Hodnoty se mísí s chemií.</strong> Představu o svém „ideálním partnerovi“ si nosíme každý v sobě. <br><br> <strong><span style="color: #C7129A">Udělej si v tom jasno - speciálních 42 hodnot pro partnerství</span></strong><br></br>Tento žebříček, obsahující novou sadu hodnot, ti pomůže si ujasnit, co od koho vlastně čekáš a jaké hodnoty by měl naplňovat<strong> partner, se kterým se budeš cítit dobře.</strong> Je úplně jedno, jestli v současné chvíli partnera máš, a nebo hledáš.</br></span>',
    boxHeaderText: '',
    box1: {
      text: ' <strong>Nalezení společných hodnot</strong> klidně do hodiny',
      img: 'partner_banky.png',
      width: '6rem',
      height: '6rem'
    },
    box2: {
      text: '<strong>Základní nastavení celého procesu</strong> dle požadavků klienta </strong>',
      img: 'partner_bryle.png',
      width: '6rem',
      height: '6rem'
    },
    box3: {
      text: '<strong>Pro týmy od 3 do 10 000 lidí<strong>',
      img: 'partner_hlava.png',
      width: '6rem',
      height: '6rem'
    },
    boxBottomText: 'Položte si více otázek, hodnoty vám odpoví. ',
  },
  section5: {
    backgroundColor: 'radial-gradient(circle at top, #847DA8 0%, #847DA8 100%)',
    header: 'Jak to funguje pro týmy?',
    text1: '1. Kontaktuje nás. Zašleme vám možnosti. Vyberete si ze základního nastavení, či vám uzpůsobíme žebříčky na míru',
    text2: '2. Připravíme vám webové rozhraní',
    text3: '3. Kompletní výsledky i výstupy včetně doprovodných anket Vám vyhodnotíme a zašleme na Váš email.',
    downtext: 'Základní cena hodnot vašeho projektu do 30 účastníků od 12.999,- Kč ',
    customIcon1: 'ikona_univerz.png',
    customIcon2: 'ikona_univerz.png',
    customIcon3: 'ikona_univerz.png'
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #696880 0%, #696880 100%)',
    image: 'ikona_univerz.png',
    header: 'Jak vypadají výstupy a co získáte',
    subtitle: 'Kompletní souhrnné výsledky celé skupiny, vyhodnocené doplňkové odpovědi a matice hodnotových vztahů mezi jednotlivými účastníky včetně doporučení, jak s hodnotami pracovat. <strong> kontaktuje nás.<strong> ',
    image2: 'partner_graf_830.png',
    image2Mobile: 'grafy_PRESENTING640X.png'
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #456C8A 0%, #2F4A62 99.92%)',
    heading: 'Jaké žebříčky lze pro firemní prostředí vytvářet',
    subheading: 'Klíčová je otázka!',
    linkText: ''
  },
  section12: DefaultSection12,
  section13: DefaultSection13
};

const Page39: PageProps = {
  section1: {
    header: 'Nejlepší parťák<br></br>',
    backgroundImg: 'Zivotni-modra.jpg',
    backgroundImgThinner: 'Zivotni-modra.jpg',
    backgroundImg2: 'love_Back.png',
    backgroundImg2Mobile: 'mob_700.png',
    backgroundImg2Tablet: 'mob_700.png',
    gradient: {
      color1: 'rgba(14, 100, 123, 0.5)',
      color2: 'rgba(38, 134, 150, 0.3)',
      color3: 'rgba(14, 100, 123, 0.5)',
      color4: 'rgba(38, 134, 150, 0.3)',
      speed: '20s'
    },
    img: 'partak_PP.png',
    imgMobile: 'partak_110.png',
    imgThinner: 'partner_thinner.png',
    imgThinnerMobile: 'partner_thinner_mobile_.png',
    subtitle: 'Ať už pro život, nebo na cesty. Jaké hodnoty by měl naplňovat?',
    button: {
      text: 'Vytvořit žebříček',
      background: '#78B3BF',
      icon: 'ladder.png',
      href: '/cs/app/hra-intro?t=1985206e-b0ea-49fd-abfc-d495357b6afd',
      redirectAfterRegistration: true,
    },
    text: '',
    eticiaPlus: true,
  },
  section3: {
    backgroundImg: 'zeme1.jpg',
    text: '<strong><span style="color: #F8CEC3">Změnit text - Parťák do nepohody...Souhra a souznění ve směřování vašeho projektu či cíle vede k efektivitě a vyšší kreativitě</br></span></strong> Lorem... Každý z nás si v sobě nosí svůj vlastní hodnotový kompas, který určuje, kam a za čím se v životě vydáme. Jaká cesta je pro nás přijatelná a kudy rozhodně jít nechceme...<br></br> <strong><span style="color: #F8CEC3">Denně děláme desítky rozhodnutí, ve kterém hrají podstatnou roli naše hodnoty.</strong></span> Nejen, že se díky nim přesněji rozhodujeme, ale také jsou důležité pro naši komunikaci a chápání druhých. <br></br><strong><span style="color: #F8CEC3">Proč vede naplňování našich hodnot k vlastní spokojenosti?</span><br></br> Naše přání i cíle jsou olemovány také našimi hodnotami, které lze jednoduše vyjádřit <span style="color: #F8CEC3">pomocí hodnotových žebříčků.</span> Ty nám ukazují konkrétní důležitost pro dané životní situace a když jsou naše hodnoty v souladu s tím, co děláme, <span style="color: #F8CEC3">jsme celkově spokojenější.',
    header: 'Zahájit tvorbu kodexu',
    subtitle: '',
    button: {
      text: '<span style="color: #E54FA6"> &#9660; </span>'
    }
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'partner_chemik1.png',
    header: '<span style="color: #000000">Neprocházejte trnitou cestou, <strong>když tu jsou</span><span style="color: #C7129A"> hodnoty</span></strong>',
    subtitle: '<span style="color: #000000">Hodnoty, vztahy, vize i motivace a způsoby chování,hrají v každé společnosti význmanou roli <strong> Hodnoty se mísí s chemií.</strong> Představu o svém „ideálním partnerovi“ si nosíme každý v sobě. <br><br> <strong><span style="color: #C7129A">Udělej si v tom jasno - speciálních 42 hodnot pro partnerství</span></strong><br></br>Tento žebříček, obsahující novou sadu hodnot, ti pomůže si ujasnit, co od koho vlastně čekáš a jaké hodnoty by měl naplňovat<strong> partner, se kterým se budeš cítit dobře.</strong> Je úplně jedno, jestli v současné chvíli partnera máš, a nebo hledáš.</br></span>',
    boxHeaderText: '',
    box1: {
      text: ' <strong>Nalezení společných hodnot</strong> klidně do hodiny',
      img: 'partner_banky.png',
      width: '6rem',
      height: '6rem'
    },
    box2: {
      text: '<strong>Základní nastavení celého procesu</strong> dle požadavků klienta </strong>',
      img: 'partner_bryle.png',
      width: '6rem',
      height: '6rem'
    },
    box3: {
      text: '<strong>Pro týmy od 3 do 10 000 lidí<strong>',
      img: 'partner_hlava.png',
      width: '6rem',
      height: '6rem'
    },
    boxBottomText: 'Položte si víc otázek, hodnoty vám odpoví. ',
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #367B66 0%, #367B66 100%)',
    image: 'ikona_univerz.png',
    header: 'Jak vypadají výstupy a co získáte',
    subtitle: 'Kompletní souhrnné výsledky celé skupiny, vyhodnocené doplňkové odpovědi a matice hodnotových vztahů mezi jednotlivými účastníky včetně doporučení, jak s hodnotami pracovat. <strong> kontaktuje nás.<strong> ',
    image2: 'partner_graf_830.png',
    image2Mobile: 'grafy_PRESENTING640X.png'
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #456C8A 0%, #2F4A62 99.92%)',
    heading: 'Jaké žebříčky lze pro firemní prostředí vytvářet',
    subheading: 'Klíčová je otázka!',
    linkText: ''
  },
  section12: DefaultSection12,
  section13: DefaultSection13
};

const Page40: PageProps = {
  section1: {
    header: 'Nepoužívat - připraveno pro seznamku<br></br>',
    backgroundImg: 'love_partner.jpg',
    backgroundImgThinner: 'love_partner.jpg',
    backgroundImg2: 'love_Back.png',
    backgroundImg2Mobile: 'mob_700.png',
    backgroundImg2Tablet: 'mob_700.png',
    gradient: {
      color1: 'rgba(129, 30, 186, 0.6)',
      color2: 'rgba(232, 52, 136, 0.6)',
      color3: 'rgba(129, 30, 186, 0.6)',
      color4: 'rgba(232, 52, 136, 0.6)',
      speed: '30s'
    },
    img: 'ideal_142x142.png',
    imgMobile: 'partner_mobil_90x160.png',
    imgThinner: 'partner_thinner.png',
    imgThinnerMobile: 'partner_thinner_mobile_.png',
    subtitle: '',
    button: {
      text: 'Napište nám',
      background: '#FFC7BA',
      icon: 'ladder.png',
      href: '/cs/app/hra-intro?t=1985206e-b0ea-49fd-abfc-d495357b6afd',
      redirectAfterRegistration: true,
    },
    text: '',
    eticiaPlus: true,
  },
  section2: {
    bgColor: 'radial-gradient(circle at top, rgba(187,204,249,1) 0%, rgba(152,176,242,1) 100%)',
    backgroundImg: '0_Me_hodnoty_o_hodnotach.png',
    header: 'Hodnoty našeho projektu a přístup k jeho prezentaci',
    subheader: 'Pojmenujte v týmu si jednoduše a rychle nejdůležitější hodnoty vašeho projektu.',
    subtitle: 'Význam poznávání hodnot pro etickou výchovu a společenské předměty přináší nejen zajímavé rozhovory a diskuze, ale také vede k jejich naplňování, vyššímu respektu mezi jednotlivými studenty.',
    downtext: 'Lorem ipsum'
  },
  section3: {
    backgroundImg: 'zeme1.jpg',
    text: '<strong><span style="color: #F8CEC3">Souhra a souznění v směřování vašeho projektu či cíle vede k efektivitě a vyšší kreativitě</br></span></strong> Lorem... Každý z nás si v sobě nosí svůj vlastní hodnotový kompas, který určuje, kam a za čím se v životě vydáme. Jaká cesta je pro nás přijatelná a kudy rozhodně jít nechceme...<br></br> <strong><span style="color: #F8CEC3">Denně děláme desítky rozhodnutí, ve kterém hrají podstatnou roli naše hodnoty.</strong></span> Nejen, že se díky nim přesněji rozhodujeme, ale také jsou důležité pro naši komunikaci a chápání druhých. <br></br><strong><span style="color: #F8CEC3">Proč vede naplňování našich hodnot k vlastní spokojenosti?</span><br></br> Naše přání i cíle jsou olemovány také našimi hodnotami, které lze jednoduše vyjádřit <span style="color: #F8CEC3">pomocí hodnotových žebříčků.</span> Ty nám ukazují konkrétní důležitost pro dané životní situace a když jsou naše hodnoty v souladu s tím, co děláme, <span style="color: #F8CEC3">jsme celkově spokojenější.',
    header: 'Zahájit tvorbu kodexu',
    subtitle: '',
    button: {
      text: '<span style="color: #E54FA6"> &#9660; </span>'
    }
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'partner_chemik1.png',
    header: '<span style="color: #000000">Neprocházejte trnitou cestou<strong>když tu jsou</span><span style="color: #C7129A"> hodnoty/span></strong>',
    subtitle: '<span style="color: #000000">Hodnoty, vztahy, vize i motivace a způsoby chování,hrají v každé společnosti význmanou roli <strong> Hodnoty se mísí s chemií.</strong> Představu o svém „ideálním partnerovi“ si nosíme každý v sobě. <br><br> <strong><span style="color: #C7129A">Udělej si v tom jasno - speciálních 42 hodnot pro partnerství</span></strong><br></br>Tento žebříček, obsahující novou sadu hodnot, ti pomůže si ujasnit, co od koho vlastně čekáš a jaké hodnoty by měl naplňovat<strong> partner, se kterým se budeš cítit dobře.</strong> Je úplně jedno, jestli v současné chvíli partnera máš, a nebo hledáš.</br></span>',
    boxHeaderText: '',
    box1: {
      text: ' <strong>Nalezení společných hodnot</strong> klidně do hodiny',
      img: 'partner_banky.png',
      width: '6rem',
      height: '6rem'
    },
    box2: {
      text: '<strong>Základní nastavení celého procesu</strong>dle požadavků klienta </strong>',
      img: 'partner_bryle.png',
      width: '6rem',
      height: '6rem'
    },
    box3: {
      text: '<strong>Pro týmy od 3 do 10 000 lidí<strong>',
      img: 'partner_hlava.png',
      width: '6rem',
      height: '6rem'
    },
    boxBottomText: 'Položte si více otázek, hodnoty vám odpoví. ',
  },
  section5: {
    backgroundColor: 'radial-gradient(circle at top, #847DA8 0%, #847DA8 100%)',
    header: 'Jak to funguje?',
    text1: '1. Kontaktuje nás. Zašleme vám možnosti. Vyberete si se základního nastavení či vám uzpůsobíme žebříčky na míru',
    text2: '2. Připravíme vám webové rozhraní',
    text3: '3. Kompletní výsledky i výstupy včetně doprovodných anket Vám vyhodnotíme a zašleme na Váš email.',
    downtext: 'xxx',
    customIcon1: 'ikona_univerz.png',
    customIcon2: 'ikona_univerz.png',
    customIcon3: 'ikona_univerz.png'
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #696880 0%, #696880 100%)',
    image: 'ikona_univerz.png',
    header: 'Jak vypadají výstupy a co získáte',
    subtitle: 'Kompletní souhrné výsledky celé skupiny, vyhodnocené doplňkové odpovědi a matice hodnotových vztahů mezi jednotlivými účastníky včetně doporučení, jak s hodnotami pracovat. <strong> kontaktuje nás.<strong> ',
    image2: 'partner_graf_830.png',
    image2Mobile: 'grafy_PRESENTING640X.png'
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #456C8A 0%, #2F4A62 99.92%)',
    heading: 'Jaké žebříčky lze pro firemní prostředí vytvářet',
    subheading: 'Klíčová je otázka!',
    linkText: ''
  },
  section12: DefaultSection12,
  section13: DefaultSection13
};

const Page41: PageProps = {
  section1: {
    header: 'Nepoužívat - Superschopnosti - hra<br></br>',
    backgroundImg: 'love_partner.jpg',
    backgroundImgThinner: 'love_partner.jpg',
    backgroundImg2: 'love_Back.png',
    backgroundImg2Mobile: 'mob_700.png',
    backgroundImg2Tablet: 'mob_700.png',
    gradient: {
      color1: 'rgba(129, 30, 186, 0.6)',
      color2: 'rgba(232, 52, 136, 0.6)',
      color3: 'rgba(129, 30, 186, 0.6)',
      color4: 'rgba(232, 52, 136, 0.6)',
      speed: '30s'
    },
    img: 'ideal_142x142.png',
    imgMobile: 'partner_mobil_90x160.png',
    imgThinner: 'partner_thinner.png',
    imgThinnerMobile: 'partner_thinner_mobile_.png',
    subtitle: 'Je čas si přiznat, v čem jsem dobrý',
    button: {
      text: 'Napište nám',
      background: '#FFC7BA',
      icon: 'ladder.png'
    },
    text: '',
    eticiaPlus: true,
  },
  section3: {
    backgroundImg: 'zeme1.jpg',
    text: '<strong><span style="color: #F8CEC3">Souhra a souznění v směřování vašeho projektu či cíle vede k efektivitě a vyšší kreativitě</br></span></strong> Lorem... Každý z nás si v sobě nosí svůj vlastní hodnotový kompas, který určuje, kam a za čím se v životě vydáme. Jaká cesta je pro nás přijatelná a kudy rozhodně jít nechceme...<br></br> <strong><span style="color: #F8CEC3">Denně děláme desítky rozhodnutí, ve kterém hrají podstatnou roli naše hodnoty.</strong></span> Nejen, že se díky nim přesněji rozhodujeme, ale také jsou důležité pro naši komunikaci a chápání druhých. <br></br><strong><span style="color: #F8CEC3">Proč vede naplňování našich hodnot k vlastní spokojenosti?</span><br></br> Naše přání i cíle jsou olemovány také našimi hodnotami, které lze jednoduše vyjádřit <span style="color: #F8CEC3">pomocí hodnotových žebříčků.</span> Ty nám ukazují konkrétní důležitost pro dané životní situace a když jsou naše hodnoty v souladu s tím, co děláme, <span style="color: #F8CEC3">jsme celkově spokojenější.',
    header: 'Zahájit tvorbu kodexu',
    subtitle: '',
    button: {
      text: '<span style="color: #E54FA6"> &#9660; </span>'
    }
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'partner_chemik1.png',
    header: '<span style="color: #000000">Neprocházejte trnitou cestou<strong>když tu jsou</span><span style="color: #C7129A"> hodnoty/span></strong>',
    subtitle: '<span style="color: #000000">Hodnoty, vztahy, vize i motivace a způsoby chování,hrají v každé společnosti význmanou roli <strong> Hodnoty se mísí s chemií.</strong> Představu o svém „ideálním partnerovi“ si nosíme každý v sobě. <br><br> <strong><span style="color: #C7129A">Udělej si v tom jasno - speciálních 42 hodnot pro partnerství</span></strong><br></br>Tento žebříček, obsahující novou sadu hodnot, ti pomůže si ujasnit, co od koho vlastně čekáš a jaké hodnoty by měl naplňovat<strong> partner, se kterým se budeš cítit dobře.</strong> Je úplně jedno, jestli v současné chvíli partnera máš, a nebo hledáš.</br></span>',
    boxHeaderText: '',
    box1: {
      text: ' <strong>Nalezení společných hodnot</strong> klidně do hodiny',
      img: 'partner_banky.png',
      width: '6rem',
      height: '6rem'
    },
    box2: {
      text: '<strong>Základní nastavení celého procesu</strong>dle požadavků klienta </strong>',
      img: 'partner_bryle.png',
      width: '6rem',
      height: '6rem'
    },
    box3: {
      text: '<strong>Pro týmy od 3 do 10 000 lidí<strong>',
      img: 'partner_hlava.png',
      width: '6rem',
      height: '6rem'
    },
    boxBottomText: 'Položte si více otázek, hodnoty vám odpoví. ',
  },
  section5: {
    backgroundColor: 'radial-gradient(circle at top, #847DA8 0%, #847DA8 100%)',
    header: 'Jak s tímto výsledkem pracovat?',
    text1: '1. Určení priorit a pojmenování svých silných stránek vede k lepší orientaci chápání svých superstránek',
    text2: '2. Lze využít do svého životopisu',
    text3: '3. Žebříček pomáhá nasměřovat vlastní FLOWW',
    downtext: 'xxxxx',
    customIcon1: 'ikona_univerz.png',
    customIcon2: 'ikona_univerz.png',
    customIcon3: 'ikona_univerz.png'
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #696880 0%, #696880 100%)',
    image: 'ikona_univerz.png',
    header: 'Jak vypadají výstupy a co získáte',
    subtitle: 'Kompletní souhrné výsledky celé skupiny, vyhodnocené doplňkové odpovědi a matice hodnotových vztahů mezi jednotlivými účastníky včetně doporučení, jak s hodnotami pracovat. <strong> kontaktuje nás.<strong> ',
    image2: 'partner_graf_830.png',
    image2Mobile: 'grafy_PRESENTING640X.png'
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #456C8A 0%, #2F4A62 99.92%)',
    heading: 'Jaké žebříčky lze pro firemní prostředí vytvářet',
    subheading: 'Klíčová je otázka!',
    linkText: ''
  },
  section12: DefaultSection12,
  section13: DefaultSection13
};

const Page42: PageProps = {
  doNotDuplicateFirstSection: true,
  section1: {
    header: 'Objevuj své životní hodnoty<br></br>',
    backgroundImg: 'Partnerstvi---red.jpg',
    backgroundImgThinner: 'love_partner.jpg',
    backgroundImg2: 'love_Back.png',
    backgroundImg2Mobile: 'mob_700.png',
    backgroundImg2Tablet: 'mob_700.png',
    gradient: {
      color1: 'rgba(132, 38, 65, 0.5)',
      color2: 'rgba(173,73,111, 0.3)',
      color3: 'rgba(132, 38, 65, 0.5)',
      color4: 'rgba(173,73,111, 0.3)',
      speed: '20s'
    },
    img: 'ideal_142x142.png',
    imgMobile: 'partner_mobil_90x160.png',
    imgThinner: 'partner_thinner.png',
    imgThinnerMobile: 'partner_thinner_mobile_.png',
    subtitle: 'Jak na to?',
    text: 'Sleduj dlouhodobý vývoj svých hodnot. Možnost každoroční aktualizace hodnotového žebříčku je v Eticia zdarma.',
    eticiaPlus: false,
    bottomText: ''
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'partner_chemik1.png',
    header: '<span style="color: #000000">Životní hodnoty</span>',
    subtitle: '<span style="color: #000000">Některé naše priority se mění v závislosti na nejrůznějších situacích, které zažíváme, jiné nás provázejí po dlouhá období.</span> <br>',
    boxHeaderText: 'Proč je potřebuješ znát',
    box1: {
      text: ' <strong>Pomohou ti najít to, na čem opravdu záleží</strong>',
      img: 'partner_banky.png',
      width: '6rem',
      height: '6rem'
    },
    box2: {
      text: '<strong>Zjistíš díky nim mnoho sám o sobě</strong>',
      img: 'partner_bryle.png',
      width: '6rem',
      height: '6rem'
    },
    box3: {
      text: '<strong>A když o ně budeš pečovat, budou ti dělat radost<strong>',
      img: 'partner_hlava.png',
      width: '6rem',
      height: '6rem'
    },
    boxBottomText: 'Bez znalosti toho, co je pro nás dlouhodobě důležité, bychom se v životě jen pohybovali od jedné zastávky k druhé a od jednoho dočasného cíle k dalšímu…',
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #a26c9a 0%, #a26c9a 100%)',
    image: 'ikona_univerz.png',
    header: '<span style="color: #E6E5cf"> Jak to funguje? </span>',
    subtitle: 'Algortimus Eticia každý rok porovná tvůj předchozí hodnotový žebříček (nebo žebříčky za více let) s tvým současným. Ve vyhodnocení pak uvidíš vývoj tvých hodnot a budeš moct podrobně pozorovat změny, které se v tvých volbách odehrály.',
    image2: 'zebr_ziv_830.png',
    image2Mobile: 'grafy_PRESENTING640X.png'
  },
};

const Page43: PageProps = {
  section1: {
    header: 'Strom společných hodnot<br></br>',
    backgroundImg: 'Strom_hodnot_green.jpg',
    backgroundImgThinner: 'love_partner.jpg',
    backgroundImg2: 'love_Back.png',
    backgroundImg2Mobile: 'mob_700.png',
    backgroundImg2Tablet: 'mob_700.png',
    gradient: {
      color1: 'rgba(13, 109, 95, 0.5)',
      color2: 'rgba(31,132,114, 0.3)',
      color3: 'rgba(13, 109, 95, 0.5)',
      color4: 'rgba(31,132,114, 0.3)',
      speed: '20s'
    },
    img: 'ideal_142x142.png',
    imgMobile: 'partner_mobil_90x160.png',
    imgThinner: 'partner_thinner.png',
    imgThinnerMobile: 'partner_thinner_mobile_.png',
    subtitle: '',
    button: {
      text: 'Napište nám',
      background: '#FFC7BA',
      icon: 'ladder.png'
    },
    text: '',
    eticiaPlus: true,
  },
  section2: {
    bgColor: 'radial-gradient(circle at top, rgba(187,204,249,1) 0%, rgba(152,176,242,1) 100%)',
    backgroundImg: '0_Me_hodnoty_o_hodnotach.png',
    header: 'Hodnoty našeho projektu a přístup k jeho prezentaci',
    subheader: 'Pojmenujte v týmu si jednoduše a rychle nejdůležitější hodnoty vašeho projektu.',
    subtitle: 'Význam poznávání hodnot pro etickou výchovu a společenské předměty přináší nejen zajímavé rozhovory a diskuze, ale také vede k jejich naplňování, vyššímu respektu mezi jednotlivými studenty.',
    downtext: 'Lorem ipsum'
  },
  section3: {
    backgroundImg: 'zeme1.jpg',
    text: '<strong><span style="color: #F8CEC3">Souhra a souznění v směřování vašeho projektu či cíle vede k efektivitě a vyšší kreativitě</br></span></strong> Lorem... Každý z nás si v sobě nosí svůj vlastní hodnotový kompas, který určuje, kam a za čím se v životě vydáme. Jaká cesta je pro nás přijatelná a kudy rozhodně jít nechceme...<br></br> <strong><span style="color: #F8CEC3">Denně děláme desítky rozhodnutí, ve kterém hrají podstatnou roli naše hodnoty.</strong></span> Nejen, že se díky nim přesněji rozhodujeme, ale také jsou důležité pro naši komunikaci a chápání druhých. <br></br><strong><span style="color: #F8CEC3">Proč vede naplňování našich hodnot k vlastní spokojenosti?</span><br></br> Naše přání i cíle jsou olemovány také našimi hodnotami, které lze jednoduše vyjádřit <span style="color: #F8CEC3">pomocí hodnotových žebříčků.</span> Ty nám ukazují konkrétní důležitost pro dané životní situace a když jsou naše hodnoty v souladu s tím, co děláme, <span style="color: #F8CEC3">jsme celkově spokojenější.',
    header: 'Zahájit tvorbu kodexu',
    subtitle: '',
    button: {
      text: '<span style="color: #E54FA6"> &#9660; </span>'
    }
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'partner_chemik1.png',
    header: '<span style="color: #000000">Neprocházejte trnitou cestou<strong>když tu jsou</span><span style="color: #C7129A"> hodnoty/span></strong>',
    subtitle: '<span style="color: #000000">Hodnoty, vztahy, vize i motivace a způsoby chování,hrají v každé společnosti význmanou roli <strong> Hodnoty se mísí s chemií.</strong> Představu o svém „ideálním partnerovi“ si nosíme každý v sobě. <br><br> <strong><span style="color: #C7129A">Udělej si v tom jasno - speciálních 42 hodnot pro partnerství</span></strong><br></br>Tento žebříček, obsahující novou sadu hodnot, ti pomůže si ujasnit, co od koho vlastně čekáš a jaké hodnoty by měl naplňovat<strong> partner, se kterým se budeš cítit dobře.</strong> Je úplně jedno, jestli v současné chvíli partnera máš, a nebo hledáš.</br></span>',
    boxHeaderText: '',
    box1: {
      text: ' <strong>Nalezení společných hodnot</strong> klidně do hodiny',
      img: 'partner_banky.png',
      width: '6rem',
      height: '6rem'
    },
    box2: {
      text: '<strong>Základní nastavení celého procesu</strong>dle požadavků klienta </strong>',
      img: 'partner_bryle.png',
      width: '6rem',
      height: '6rem'
    },
    box3: {
      text: '<strong>Pro týmy od 3 do 10 000 lidí<strong>',
      img: 'partner_hlava.png',
      width: '6rem',
      height: '6rem'
    },
    boxBottomText: 'Položte si více otázek, hodnoty vám odpoví. ',
  },
  section5: {
    backgroundColor: 'radial-gradient(circle at top, #E7DCA5 0%, #D7C87F 100%)',
    header: 'Jak to funguje?',
    text1: '1. Kontaktuje nás. Zašleme vám možnosti. Vyberete si se základního nastavení či vám uzpůsobíme žebříčky na míru',
    text2: '2. Připravíme vám webové rozhraní',
    text3: '3. Kompletní výsledky i výstupy včetně doprovodných anket Vám vyhodnotíme a zašleme na Váš email.',
    downtext: 'Základní cena hodnot vašeho projektu do 30 účastníků od 12.999,- Kč ',
    customIcon1: 'ikona_univerz.png',
    customIcon2: 'ikona_univerz.png',
    customIcon3: 'ikona_univerz.png'
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #3ECAD1 0%, #088F96 100%)',
    image: 'ikona_univerz.png',
    header: 'Jak vypadají výstupy a co získáte',
    subtitle: 'Kompletní souhrné výsledky celé skupiny, vyhodnocené doplňkové odpovědi a matice hodnotových vztahů mezi jednotlivými účastníky včetně doporučení, jak s hodnotami pracovat. <strong> kontaktuje nás.<strong> ',
    image2: 'partner_graf_830.png',
    image2Mobile: 'grafy_PRESENTING640X.png'
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #456C8A 0%, #2F4A62 99.92%)',
    heading: 'Jaké žebříčky lze pro firemní prostředí vytvářet',
    subheading: 'Klíčová je otázka!',
    linkText: ''
  },
  section12: DefaultSection12,
  section13: DefaultSection13
};

const Page44: PageProps = {
  section1: {
    header: 'Přo školy a kolektivy<br></br>',
    backgroundImg: 'Rodice_deti_lightkids.jpg',
    backgroundImgThinner: 'love_partner.jpg',
    backgroundImg2: 'love_Back.png',
    backgroundImg2Mobile: 'mob_700.png',
    backgroundImg2Tablet: 'mob_700.png',
    gradient: {
      color1: 'rgba(5, 130, 152, 0.5)',
      color2: 'rgba(18, 158, 175, 0.3)',
      color3: 'rgba(5, 130, 152, 0.5)',
      color4: 'rgba(18, 158, 175, 0.3)',
      speed: '20s'
    },
    img: 'ideal_142x142.png',
    imgMobile: 'partner_mobil_90x160.png',
    imgThinner: 'partner_thinner.png',
    imgThinnerMobile: 'partner_thinner_mobile_.png',
    subtitle: '',
    button: {
      text: 'Napište nám',
      background: '#FFC7BA',
      icon: 'ladder.png'
    },
    text: '',
    eticiaPlus: true,
  },
  section2: {
    bgColor: 'radial-gradient(circle at top, rgba(187,204,249,1) 0%, rgba(152,176,242,1) 100%)',
    backgroundImg: '0_Me_hodnoty_o_hodnotach.png',
    header: 'Vytvořte si společný hodnototový žebříček ve vaši třídě, skupině atp.',
    subheader: 'Speciální přístup do Eticia pro každého uživatele + kompletní vyhodnocení pro celý tým',
    subtitle: 'Eticia je tu i pro školy. Díky speciálnímu nastavení umožňuje hledání společních hodnot, přičemž, každý uživatel si spravuje své žebříčky pod svým účtem.',
    downtext: ''
  },
  section3: {
    backgroundImg: 'zeme1.jpg',
    text: '<strong><span style="color: #F8CEC3">Souhra a souznění v směřování vašeho projektu či cíle vede k efektivitě a vyšší kreativitě</br></span></strong> Lorem... Každý z nás si v sobě nosí svůj vlastní hodnotový kompas, který určuje, kam a za čím se v životě vydáme. Jaká cesta je pro nás přijatelná a kudy rozhodně jít nechceme...<br></br> <strong><span style="color: #F8CEC3">Denně děláme desítky rozhodnutí, ve kterém hrají podstatnou roli naše hodnoty.</strong></span> Nejen, že se díky nim přesněji rozhodujeme, ale také jsou důležité pro naši komunikaci a chápání druhých. <br></br><strong><span style="color: #F8CEC3">Proč vede naplňování našich hodnot k vlastní spokojenosti?</span><br></br> Naše přání i cíle jsou olemovány také našimi hodnotami, které lze jednoduše vyjádřit <span style="color: #F8CEC3">pomocí hodnotových žebříčků.</span> Ty nám ukazují konkrétní důležitost pro dané životní situace a když jsou naše hodnoty v souladu s tím, co děláme, <span style="color: #F8CEC3">jsme celkově spokojenější.',
    header: 'Zahájit tvorbu kodexu',
    subtitle: '',
    button: {
      text: '<span style="color: #E54FA6"> &#9660; </span>'
    }
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'partner_chemik1.png',
    header: 'Speciální verze pro děti, školy a kolektivy',
    subtitle: 'Chcete se ve své třídě pobavit o hodnotách? Které jsou ve vašem kolektivu v popředí a které naopak upozaděny. Díky unikátnímu skupinovému vyhodnocení vytvoříme společné žebříčky vaší třídy, školy atp.',
    boxHeaderText: '',
    box1: {
      text: 'Každý uživatel či student získá vlastní přístup do Eticia',
      img: 'partner_banky.png',
      width: '6rem',
      height: '6rem'
    },
    box2: {
      text: 'Z výběru uživatelů vyvoříme skupinový žebříček (základ Etického kodexu)',
      img: 'partner_bryle.png',
      width: '6rem',
      height: '6rem'
    },
    box3: {
      text: 'Žebříčky můžeme doplnit o přídavné otázky dle potřeby školy',
      img: 'partner_hlava.png',
      width: '6rem',
      height: '6rem'
    },
    boxBottomText: 'Diskuze a poznávání hodnot přispívá k lepšímu prostředí v dětských kolektivech.',
  },
  section5: {
    backgroundColor: 'radial-gradient(circle at top, #E7DCA5 0%, #D7C87F 100%)',
    header: 'Jak to funguje?',
    text1: '1. Kontaktuje nás. Zašleme vám možnosti nastavení. Vyberete si se základního nastavení či vám uzpůsobíme žebříčky na míru',
    text2: '2. Připravíme vám webové rozhraní přímo pro vaši školy',
    text3: '3. Kompletní výsledky i výstupy včetně doprovodných anket Vám zpravujeme a zašleme na Váš email.',
    downtext: '',
    customIcon1: 'ikona_univerz.png',
    customIcon2: 'ikona_univerz.png',
    customIcon3: 'ikona_univerz.png'
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #3ECAD1 0%, #088F96 100%)',
    image: 'ikona_univerz.png',
    header: 'Jak vypadají výstupy a co získáte',
    subtitle: 'Kompletní souhrné výsledky celé skupiny, vyhodnocené doplňkové odpovědi a matice hodnotových vztahů mezi jednotlivými účastníky včetně doporučení, jak s hodnotami pracovat. <strong> kontaktuje nás.<strong> ',
    image2: 'partner_graf_830.png',
    image2Mobile: 'grafy_PRESENTING640X.png'
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #456C8A 0%, #2F4A62 99.92%)',
    heading: 'Jaké žebříčky lze pro firemní prostředí vytvářet',
    subheading: 'Klíčová je otázka!',
    linkText: ''
  },
  section12: DefaultSection12,
  section13: DefaultSection13
};

const Page45: PageProps = {
  section1: {
    header: 'Pro mladší 16 let<br></br>',
    backgroundImg: 'Rodice_deti_lightkids.jpg',
    backgroundImgThinner: 'love_partner.jpg',
    backgroundImg2: 'love_Back.png',
    backgroundImg2Mobile: 'mob_700.png',
    backgroundImg2Tablet: 'mob_700.png',
    gradient: {
      color1: 'rgba(5, 130, 152, 0.5)',
      color2: 'rgba(18, 158, 175, 0.3)',
      color3: 'rgba(5, 130, 152, 0.5)',
      color4: 'rgba(18, 158, 175, 0.3)',
      speed: '20s'
    },
    img: 'ideal_142x142.png',
    imgMobile: 'partner_mobil_90x160.png',
    imgThinner: 'partner_thinner.png',
    imgThinnerMobile: 'partner_thinner_mobile_.png',
    subtitle: 'Jestliže ti je mezi 14-16 let, je určena tato stránka přímo tobě.',
    button: {
      text: 'Spustit verzi do 16 let',
      background: '#FFC7BA',
      icon: 'ladder.png'
    },
    text: '',
    eticiaPlus: true,
  },
  section2: {
    bgColor: 'radial-gradient(circle at top, rgba(187,204,249,1) 0%, rgba(152,176,242,1) 100%)',
    backgroundImg: '0_Me_hodnoty_o_hodnotach.png',
    header: 'Chceš si vytvořit žebříček a jsi mladší 16 let?',
    subheader: 'Samostatný přístup do Eticia 21 se souhlasem rodičů pro mladé od 14-16 let:',
    subtitle: 'Současná zákoná legislativa neumožňuje mladším 16 let se přihlásit do internetových aplikací a my to plně respektujeme. Pro ty, kteří se nachází v rozmezí 14-16 let se mohou do Eticia registrovat se souhlasem svých rodičů.',
    downtext: 'Je li ti méně než 14 let, požádej své rodiče, aby ti aktivovali Eticia 21'
  },
  section3: {
    backgroundImg: 'zeme1.jpg',
    text: '<strong><span style="color: #F8CEC3">Souhra a souznění v směřování vašeho projektu či cíle vede k efektivitě a vyšší kreativitě</br></span></strong> Lorem... Každý z nás si v sobě nosí svůj vlastní hodnotový kompas, který určuje, kam a za čím se v životě vydáme. Jaká cesta je pro nás přijatelná a kudy rozhodně jít nechceme...<br></br> <strong><span style="color: #F8CEC3">Denně děláme desítky rozhodnutí, ve kterém hrají podstatnou roli naše hodnoty.</strong></span> Nejen, že se díky nim přesněji rozhodujeme, ale také jsou důležité pro naši komunikaci a chápání druhých. <br></br><strong><span style="color: #F8CEC3">Proč vede naplňování našich hodnot k vlastní spokojenosti?</span><br></br> Naše přání i cíle jsou olemovány také našimi hodnotami, které lze jednoduše vyjádřit <span style="color: #F8CEC3">pomocí hodnotových žebříčků.</span> Ty nám ukazují konkrétní důležitost pro dané životní situace a když jsou naše hodnoty v souladu s tím, co děláme, <span style="color: #F8CEC3">jsme celkově spokojenější.',
    header: 'Zahájit tvorbu kodexu',
    subtitle: '',
    button: {
      text: '<span style="color: #E54FA6"> &#9660; </span>'
    }
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'partner_chemik1.png',
    header: '<span style="color: #000000">Neprocházejte trnitou cestou<strong>když tu jsou</span><span style="color: #C7129A"> hodnoty/span></strong>',
    subtitle: '<span style="color: #000000">Hodnoty, vztahy, vize i motivace a způsoby chování,hrají v každé společnosti význmanou roli <strong> Hodnoty se mísí s chemií.</strong> Představu o svém „ideálním partnerovi“ si nosíme každý v sobě. <br><br> <strong><span style="color: #C7129A">Udělej si v tom jasno - speciálních 42 hodnot pro partnerství</span></strong><br></br>Tento žebříček, obsahující novou sadu hodnot, ti pomůže si ujasnit, co od koho vlastně čekáš a jaké hodnoty by měl naplňovat<strong> partner, se kterým se budeš cítit dobře.</strong> Je úplně jedno, jestli v současné chvíli partnera máš, a nebo hledáš.</br></span>',
    boxHeaderText: '',
    box1: {
      text: ' <strong>Nalezení společných hodnot</strong> klidně do hodiny',
      img: 'partner_banky.png',
      width: '6rem',
      height: '6rem'
    },
    box2: {
      text: '<strong>Základní nastavení celého procesu</strong>dle požadavků klienta </strong>',
      img: 'partner_bryle.png',
      width: '6rem',
      height: '6rem'
    },
    box3: {
      text: '<strong>Pro týmy od 3 do 10 000 lidí<strong>',
      img: 'partner_hlava.png',
      width: '6rem',
      height: '6rem'
    },
    boxBottomText: 'Položte si více otázek, hodnoty vám odpoví. ',
  },
  section5: {
    backgroundColor: 'radial-gradient(circle at top, #E7DCA5 0%, #D7C87F 100%)',
    header: 'Jak to funguje?',
    text1: '1. Kontaktuje nás. Zašleme vám možnosti. Vyberete si se základního nastavení či vám uzpůsobíme žebříčky na míru',
    text2: '2. Připravíme vám webové rozhraní',
    text3: '3. Kompletní výsledky i výstupy včetně doprovodných anket Vám vyhodnotíme a zašleme na Váš email.',
    downtext: 'Základní cena hodnot vašeho projektu do 30 účastníků od 12.999,- Kč ',
    customIcon1: 'ikona_univerz.png',
    customIcon2: 'ikona_univerz.png',
    customIcon3: 'ikona_univerz.png'
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #3ECAD1 0%, #088F96 100%)',
    image: 'ikona_univerz.png',
    header: 'Jak vypadají výstupy a co získáte',
    subtitle: 'Kompletní souhrné výsledky celé skupiny, vyhodnocené doplňkové odpovědi a matice hodnotových vztahů mezi jednotlivými účastníky včetně doporučení, jak s hodnotami pracovat. <strong> kontaktuje nás.<strong> ',
    image2: 'partner_graf_830.png',
    image2Mobile: 'grafy_PRESENTING640X.png'
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #456C8A 0%, #2F4A62 99.92%)',
    heading: 'Jaké žebříčky lze pro firemní prostředí vytvářet',
    subheading: 'Klíčová je otázka!',
    linkText: ''
  },
  section12: DefaultSection12,
  section13: DefaultSection13
};

const Page46: PageProps = {
  section1: {
    header: 'Etické kodexy<br></br>',
    backgroundImg: 'Prace---gray.jpg',
    backgroundImgThinner: 'love_partner.jpg',
    backgroundImg2: 'love_Back.png',
    backgroundImg2Mobile: 'mob_700.png',
    backgroundImg2Tablet: 'mob_700.png',
    gradient: {
      color1: 'rgba(87, 88, 109, 0.5)',
      color2: 'rgba(115, 116, 137, 0.3)',
      color3: 'rgba(87, 88, 109, 0.5)',
      color4: 'rgba(115, 116, 137, 0.3)',
      speed: '20s'
    },
    img: 'ideal_142x142.png',
    imgMobile: 'partner_mobil_90x160.png',
    imgThinner: 'partner_thinner.png',
    imgThinnerMobile: 'partner_thinner_mobile_.png',
    subtitle: '',
    button: {
      text: 'Napište nám',
      background: '#FFC7BA',
      icon: 'ladder.png'
    },
    text: '',
    eticiaPlus: true,
  },
  section2: {
    bgColor: 'radial-gradient(circle at top, rgba(187,204,249,1) 0%, rgba(152,176,242,1) 100%)',
    backgroundImg: '0_Me_hodnoty_o_hodnotach.png',
    header: 'Hodnoty našeho projektu a přístup k jeho prezentaci',
    subheader: 'Pojmenujte v týmu si jednoduše a rychle nejdůležitější hodnoty vašeho projektu.',
    subtitle: 'Význam poznávání hodnot pro etickou výchovu a společenské předměty přináší nejen zajímavé rozhovory a diskuze, ale také vede k jejich naplňování, vyššímu respektu mezi jednotlivými studenty.',
    downtext: 'Lorem ipsum'
  },
  section3: {
    backgroundImg: 'zeme1.jpg',
    text: '<strong><span style="color: #F8CEC3">Souhra a souznění v směřování vašeho projektu či cíle vede k efektivitě a vyšší kreativitě</br></span></strong> Lorem... Každý z nás si v sobě nosí svůj vlastní hodnotový kompas, který určuje, kam a za čím se v životě vydáme. Jaká cesta je pro nás přijatelná a kudy rozhodně jít nechceme...<br></br> <strong><span style="color: #F8CEC3">Denně děláme desítky rozhodnutí, ve kterém hrají podstatnou roli naše hodnoty.</strong></span> Nejen, že se díky nim přesněji rozhodujeme, ale také jsou důležité pro naši komunikaci a chápání druhých. <br></br><strong><span style="color: #F8CEC3">Proč vede naplňování našich hodnot k vlastní spokojenosti?</span><br></br> Naše přání i cíle jsou olemovány také našimi hodnotami, které lze jednoduše vyjádřit <span style="color: #F8CEC3">pomocí hodnotových žebříčků.</span> Ty nám ukazují konkrétní důležitost pro dané životní situace a když jsou naše hodnoty v souladu s tím, co děláme, <span style="color: #F8CEC3">jsme celkově spokojenější.',
    header: 'Zahájit tvorbu kodexu',
    subtitle: '',
    button: {
      text: '<span style="color: #E54FA6"> &#9660; </span>'
    }
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'partner_chemik1.png',
    header: '<span style="color: #000000">Neprocházejte trnitou cestou<strong>když tu jsou</span><span style="color: #C7129A"> hodnoty/span></strong>',
    subtitle: '<span style="color: #000000">Hodnoty, vztahy, vize i motivace a způsoby chování,hrají v každé společnosti význmanou roli <strong> Hodnoty se mísí s chemií.</strong> Představu o svém „ideálním partnerovi“ si nosíme každý v sobě. <br><br> <strong><span style="color: #C7129A">Udělej si v tom jasno - speciálních 42 hodnot pro partnerství</span></strong><br></br>Tento žebříček, obsahující novou sadu hodnot, ti pomůže si ujasnit, co od koho vlastně čekáš a jaké hodnoty by měl naplňovat<strong> partner, se kterým se budeš cítit dobře.</strong> Je úplně jedno, jestli v současné chvíli partnera máš, a nebo hledáš.</br></span>',
    boxHeaderText: '',
    box1: {
      text: ' <strong>Nalezení společných hodnot</strong> klidně do hodiny',
      img: 'partner_banky.png',
      width: '6rem',
      height: '6rem'
    },
    box2: {
      text: '<strong>Základní nastavení celého procesu</strong>dle požadavků klienta </strong>',
      img: 'partner_bryle.png',
      width: '6rem',
      height: '6rem'
    },
    box3: {
      text: '<strong>Pro týmy od 3 do 10 000 lidí<strong>',
      img: 'partner_hlava.png',
      width: '6rem',
      height: '6rem'
    },
    boxBottomText: 'Položte si více otázek, hodnoty vám odpoví. ',
  },
  section5: {
    backgroundColor: 'radial-gradient(circle at top, #E7DCA5 0%, #D7C87F 100%)',
    header: 'Jak to funguje?',
    text1: '1. Kontaktuje nás. Zašleme vám možnosti. Vyberete si se základního nastavení či vám uzpůsobíme žebříčky na míru',
    text2: '2. Připravíme vám webové rozhraní',
    text3: '3. Kompletní výsledky i výstupy včetně doprovodných anket Vám vyhodnotíme a zašleme na Váš email.',
    downtext: 'Základní cena hodnot vašeho projektu do 30 účastníků od 12.999,- Kč ',
    customIcon1: 'ikona_univerz.png',
    customIcon2: 'ikona_univerz.png',
    customIcon3: 'ikona_univerz.png'
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #3ECAD1 0%, #088F96 100%)',
    image: 'ikona_univerz.png',
    header: 'Jak vypadají výstupy a co získáte',
    subtitle: 'Kompletní souhrné výsledky celé skupiny, vyhodnocené doplňkové odpovědi a matice hodnotových vztahů mezi jednotlivými účastníky včetně doporučení, jak s hodnotami pracovat. <strong> kontaktuje nás.<strong> ',
    image2: 'partner_graf_830.png',
    image2Mobile: 'grafy_PRESENTING640X.png'
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #456C8A 0%, #2F4A62 99.92%)',
    heading: 'Jaké žebříčky lze pro firemní prostředí vytvářet',
    subheading: 'Klíčová je otázka!',
    linkText: ''
  },
  section12: DefaultSection12,
  section13: DefaultSection13
};

const Page47: PageProps = {
  section1: {
    header: 'Pracovní týmy<br></br>',
    backgroundImg: 'Prace---gray.jpg',
    backgroundImgThinner: 'love_partner.jpg',
    backgroundImg2: 'love_Back.png',
    backgroundImg2Mobile: 'mob_700.png',
    backgroundImg2Tablet: 'mob_700.png',
    gradient: {
      color1: 'rgba(87, 88, 109, 0.5)',
      color2: 'rgba(115, 116, 137, 0.3)',
      color3: 'rgba(87, 88, 109, 0.5)',
      color4: 'rgba(115, 116, 137, 0.3)',
      speed: '20s'
    },
    img: 'ideal_142x142.png',
    imgMobile: 'partner_mobil_90x160.png',
    imgThinner: 'partner_thinner.png',
    imgThinnerMobile: 'partner_thinner_mobile_.png',
    subtitle: '',
    button: {
      text: 'Napište nám',
      background: '#FFC7BA',
      icon: 'ladder.png'
    },
    text: '',
    eticiaPlus: true,
  },
  section2: {
    bgColor: 'radial-gradient(circle at top, rgba(187,204,249,1) 0%, rgba(152,176,242,1) 100%)',
    backgroundImg: '0_Me_hodnoty_o_hodnotach.png',
    header: 'Hodnoty našeho projektu a přístup k jeho prezentaci',
    subheader: 'Pojmenujte v týmu si jednoduše a rychle nejdůležitější hodnoty vašeho projektu.',
    subtitle: 'Význam poznávání hodnot pro etickou výchovu a společenské předměty přináší nejen zajímavé rozhovory a diskuze, ale také vede k jejich naplňování, vyššímu respektu mezi jednotlivými studenty.',
    downtext: 'Lorem ipsum'
  },
  section3: {
    backgroundImg: 'zeme1.jpg',
    text: '<strong><span style="color: #F8CEC3">Souhra a souznění v směřování vašeho projektu či cíle vede k efektivitě a vyšší kreativitě</br></span></strong> Lorem... Každý z nás si v sobě nosí svůj vlastní hodnotový kompas, který určuje, kam a za čím se v životě vydáme. Jaká cesta je pro nás přijatelná a kudy rozhodně jít nechceme...<br></br> <strong><span style="color: #F8CEC3">Denně děláme desítky rozhodnutí, ve kterém hrají podstatnou roli naše hodnoty.</strong></span> Nejen, že se díky nim přesněji rozhodujeme, ale také jsou důležité pro naši komunikaci a chápání druhých. <br></br><strong><span style="color: #F8CEC3">Proč vede naplňování našich hodnot k vlastní spokojenosti?</span><br></br> Naše přání i cíle jsou olemovány také našimi hodnotami, které lze jednoduše vyjádřit <span style="color: #F8CEC3">pomocí hodnotových žebříčků.</span> Ty nám ukazují konkrétní důležitost pro dané životní situace a když jsou naše hodnoty v souladu s tím, co děláme, <span style="color: #F8CEC3">jsme celkově spokojenější.',
    header: 'Zahájit tvorbu kodexu',
    subtitle: '',
    button: {
      text: '<span style="color: #E54FA6"> &#9660; </span>'
    }
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'partner_chemik1.png',
    header: '<span style="color: #000000">Neprocházejte trnitou cestou<strong>když tu jsou</span><span style="color: #C7129A"> hodnoty/span></strong>',
    subtitle: '<span style="color: #000000">Hodnoty, vztahy, vize i motivace a způsoby chování,hrají v každé společnosti význmanou roli <strong> Hodnoty se mísí s chemií.</strong> Představu o svém „ideálním partnerovi“ si nosíme každý v sobě. <br><br> <strong><span style="color: #C7129A">Udělej si v tom jasno - speciálních 42 hodnot pro partnerství</span></strong><br></br>Tento žebříček, obsahující novou sadu hodnot, ti pomůže si ujasnit, co od koho vlastně čekáš a jaké hodnoty by měl naplňovat<strong> partner, se kterým se budeš cítit dobře.</strong> Je úplně jedno, jestli v současné chvíli partnera máš, a nebo hledáš.</br></span>',
    boxHeaderText: '',
    box1: {
      text: ' <strong>Nalezení společných hodnot</strong> klidně do hodiny',
      img: 'partner_banky.png',
      width: '6rem',
      height: '6rem'
    },
    box2: {
      text: '<strong>Základní nastavení celého procesu</strong>dle požadavků klienta </strong>',
      img: 'partner_bryle.png',
      width: '6rem',
      height: '6rem'
    },
    box3: {
      text: '<strong>Pro týmy od 3 do 10 000 lidí<strong>',
      img: 'partner_hlava.png',
      width: '6rem',
      height: '6rem'
    },
    boxBottomText: 'Položte si více otázek, hodnoty vám odpoví. ',
  },
  section5: {
    backgroundColor: 'radial-gradient(circle at top, #E7DCA5 0%, #D7C87F 100%)',
    header: 'Jak to funguje?',
    text1: '1. Kontaktuje nás. Zašleme vám možnosti. Vyberete si se základního nastavení či vám uzpůsobíme žebříčky na míru',
    text2: '2. Připravíme vám webové rozhraní',
    text3: '3. Kompletní výsledky i výstupy včetně doprovodných anket Vám vyhodnotíme a zašleme na Váš email.',
    downtext: 'Základní cena hodnot vašeho projektu do 30 účastníků od 12.999,- Kč ',
    customIcon1: 'ikona_univerz.png',
    customIcon2: 'ikona_univerz.png',
    customIcon3: 'ikona_univerz.png'
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #3ECAD1 0%, #088F96 100%)',
    image: 'ikona_univerz.png',
    header: 'Jak vypadají výstupy a co získáte',
    subtitle: 'Kompletní souhrné výsledky celé skupiny, vyhodnocené doplňkové odpovědi a matice hodnotových vztahů mezi jednotlivými účastníky včetně doporučení, jak s hodnotami pracovat. <strong> kontaktuje nás.<strong> ',
    image2: 'partner_graf_830.png',
    image2Mobile: 'grafy_PRESENTING640X.png'
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #456C8A 0%, #2F4A62 99.92%)',
    heading: 'Jaké žebříčky lze pro firemní prostředí vytvářet',
    subheading: 'Klíčová je otázka!',
    linkText: ''
  },
  section12: DefaultSection12,
  section13: DefaultSection13
};

const Page48: PageProps = {
  section1: {
    header: 'Hodnoty pro skupiny<br></br>',
    backgroundImg: 'Prace---gray.jpg',
    backgroundImgThinner: 'love_partner.jpg',
    backgroundImg2: 'love_Back.png',
    backgroundImg2Mobile: 'mob_700.png',
    backgroundImg2Tablet: 'mob_700.png',
    gradient: {
      color1: 'rgba(87, 88, 109, 0.5)',
      color2: 'rgba(115, 116, 137, 0.3)',
      color3: 'rgba(87, 88, 109, 0.5)',
      color4: 'rgba(115, 116, 137, 0.3)',
      speed: '20s'
    },
    img: 'ideal_142x142.png',
    imgMobile: 'partner_mobil_90x160.png',
    imgThinner: 'partner_thinner.png',
    imgThinnerMobile: 'partner_thinner_mobile_.png',
    subtitle: '',
    button: {
      text: 'Napište nám',
      background: '#FFC7BA',
      icon: 'ladder.png'
    },
    text: '',
    eticiaPlus: true,
  },
  section2: {
    bgColor: 'radial-gradient(circle at top, rgba(187,204,249,1) 0%, rgba(152,176,242,1) 100%)',
    backgroundImg: '0_Me_hodnoty_o_hodnotach.png',
    header: 'Hodnoty našeho projektu a přístup k jeho prezentaci',
    subheader: 'Pojmenujte v týmu si jednoduše a rychle nejdůležitější hodnoty vašeho projektu.',
    subtitle: 'Význam poznávání hodnot pro etickou výchovu a společenské předměty přináší nejen zajímavé rozhovory a diskuze, ale také vede k jejich naplňování, vyššímu respektu mezi jednotlivými studenty.',
    downtext: 'Lorem ipsum'
  },
  section3: {
    backgroundImg: 'zeme1.jpg',
    text: '<strong><span style="color: #F8CEC3">Souhra a souznění v směřování vašeho projektu či cíle vede k efektivitě a vyšší kreativitě</br></span></strong> Lorem... Každý z nás si v sobě nosí svůj vlastní hodnotový kompas, který určuje, kam a za čím se v životě vydáme. Jaká cesta je pro nás přijatelná a kudy rozhodně jít nechceme...<br></br> <strong><span style="color: #F8CEC3">Denně děláme desítky rozhodnutí, ve kterém hrají podstatnou roli naše hodnoty.</strong></span> Nejen, že se díky nim přesněji rozhodujeme, ale také jsou důležité pro naši komunikaci a chápání druhých. <br></br><strong><span style="color: #F8CEC3">Proč vede naplňování našich hodnot k vlastní spokojenosti?</span><br></br> Naše přání i cíle jsou olemovány také našimi hodnotami, které lze jednoduše vyjádřit <span style="color: #F8CEC3">pomocí hodnotových žebříčků.</span> Ty nám ukazují konkrétní důležitost pro dané životní situace a když jsou naše hodnoty v souladu s tím, co děláme, <span style="color: #F8CEC3">jsme celkově spokojenější.',
    header: 'Zahájit tvorbu kodexu',
    subtitle: '',
    button: {
      text: '<span style="color: #E54FA6"> &#9660; </span>'
    }
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'partner_chemik1.png',
    header: '<span style="color: #000000">Neprocházejte trnitou cestou<strong>když tu jsou</span><span style="color: #C7129A"> hodnoty/span></strong>',
    subtitle: '<span style="color: #000000">Hodnoty, vztahy, vize i motivace a způsoby chování,hrají v každé společnosti význmanou roli <strong> Hodnoty se mísí s chemií.</strong> Představu o svém „ideálním partnerovi“ si nosíme každý v sobě. <br><br> <strong><span style="color: #C7129A">Udělej si v tom jasno - speciálních 42 hodnot pro partnerství</span></strong><br></br>Tento žebříček, obsahující novou sadu hodnot, ti pomůže si ujasnit, co od koho vlastně čekáš a jaké hodnoty by měl naplňovat<strong> partner, se kterým se budeš cítit dobře.</strong> Je úplně jedno, jestli v současné chvíli partnera máš, a nebo hledáš.</br></span>',
    boxHeaderText: '',
    box1: {
      text: ' <strong>Nalezení společných hodnot</strong> klidně do hodiny',
      img: 'partner_banky.png',
      width: '6rem',
      height: '6rem'
    },
    box2: {
      text: '<strong>Základní nastavení celého procesu</strong>dle požadavků klienta </strong>',
      img: 'partner_bryle.png',
      width: '6rem',
      height: '6rem'
    },
    box3: {
      text: '<strong>Pro týmy od 3 do 10 000 lidí<strong>',
      img: 'partner_hlava.png',
      width: '6rem',
      height: '6rem'
    },
    boxBottomText: 'Položte si více otázek, hodnoty vám odpoví. ',
  },
  section5: {
    backgroundColor: 'radial-gradient(circle at top, #E7DCA5 0%, #D7C87F 100%)',
    header: 'Jak to funguje?',
    text1: '1. Kontaktuje nás. Zašleme vám možnosti. Vyberete si se základního nastavení či vám uzpůsobíme žebříčky na míru',
    text2: '2. Připravíme vám webové rozhraní',
    text3: '3. Kompletní výsledky i výstupy včetně doprovodných anket Vám vyhodnotíme a zašleme na Váš email.',
    downtext: 'Základní cena hodnot vašeho projektu do 30 účastníků od 12.999,- Kč ',
    customIcon1: 'ikona_univerz.png',
    customIcon2: 'ikona_univerz.png',
    customIcon3: 'ikona_univerz.png'
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #3ECAD1 0%, #088F96 100%)',
    image: 'ikona_univerz.png',
    header: 'Jak vypadají výstupy a co získáte',
    subtitle: 'Kompletní souhrné výsledky celé skupiny, vyhodnocené doplňkové odpovědi a matice hodnotových vztahů mezi jednotlivými účastníky včetně doporučení, jak s hodnotami pracovat. <strong> kontaktuje nás.<strong> ',
    image2: 'partner_graf_830.png',
    image2Mobile: 'grafy_PRESENTING640X.png'
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #456C8A 0%, #2F4A62 99.92%)',
    heading: 'Jaké žebříčky lze pro firemní prostředí vytvářet',
    subheading: 'Klíčová je otázka!',
    linkText: ''
  },
  section12: DefaultSection12,
  section13: DefaultSection13
};

const Page49: PageProps = {
  section1: {
    header: 'Jsi přihlášen v dětské verzi Eticia<br></br>',
    backgroundImg: 'love_partner.jpg',
    backgroundImgThinner: 'love_partner.jpg',
    backgroundImg2: 'love_Back.png',
    backgroundImg2Mobile: 'mob_700.png',
    backgroundImg2Tablet: 'mob_700.png',
    gradient: {
      color1: 'rgba(87, 88, 109, 0.5)',
      color2: 'rgba(115, 116, 137, 0.3)',
      color3: 'rgba(87, 88, 109, 0.5)',
      color4: 'rgba(115, 116, 137, 0.3)',
      speed: '20s'
    },
    img: 'ideal_142x142.png',
    imgMobile: 'partner_mobil_90x160.png',
    imgThinner: 'partner_thinner.png',
    imgThinnerMobile: 'partner_thinner_mobile_.png',
    subtitle: '',
  },
  section2: {
    bgColor: 'radial-gradient(circle at top, rgba(187,204,249,1) 0%, rgba(152,176,242,1) 100%)',
    backgroundImg: '0_Me_hodnoty_o_hodnotach.png',
    header: 'Jak to funguje?',
    subheader: 'Úplně stejné jako u klasické verze. ',
    subtitle: 'Můžeš sledovat vývoj svých hodnot v čase, zahrát si hodnotovou hru a využívat všechny funkcionality, které Eticia nabízí.',
    downtext: 'Lorem ipsum'
  },
  section3: {
    backgroundImg: 'zeme1.jpg',
    text: '<strong><span style="color: #F8CEC3">Souhra a souznění v směřování vašeho projektu či cíle vede k efektivitě a vyšší kreativitě</br></span></strong> Lorem... Každý z nás si v sobě nosí svůj vlastní hodnotový kompas, který určuje, kam a za čím se v životě vydáme. Jaká cesta je pro nás přijatelná a kudy rozhodně jít nechceme...<br></br> <strong><span style="color: #F8CEC3">Denně děláme desítky rozhodnutí, ve kterém hrají podstatnou roli naše hodnoty.</strong></span> Nejen, že se díky nim přesněji rozhodujeme, ale také jsou důležité pro naši komunikaci a chápání druhých. <br></br><strong><span style="color: #F8CEC3">Proč vede naplňování našich hodnot k vlastní spokojenosti?</span><br></br> Naše přání i cíle jsou olemovány také našimi hodnotami, které lze jednoduše vyjádřit <span style="color: #F8CEC3">pomocí hodnotových žebříčků.</span> Ty nám ukazují konkrétní důležitost pro dané životní situace a když jsou naše hodnoty v souladu s tím, co děláme, <span style="color: #F8CEC3">jsme celkově spokojenější.',
    header: 'Zahájit tvorbu kodexu',
    subtitle: '',
    button: {
      text: '<span style="color: #E54FA6"> &#9660; </span>'
    }
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'partner_chemik1.png',
    header: '<span style="color: #000000">Neprocházejte trnitou cestou<strong>když tu jsou</span><span style="color: #C7129A"> hodnoty/span></strong>',
    subtitle: '<span style="color: #000000">Hodnoty, vztahy, vize i motivace a způsoby chování,hrají v každé společnosti význmanou roli <strong> Hodnoty se mísí s chemií.</strong> Představu o svém „ideálním partnerovi“ si nosíme každý v sobě. <br><br> <strong><span style="color: #C7129A">Udělej si v tom jasno - speciálních 42 hodnot pro partnerství</span></strong><br></br>Tento žebříček, obsahující novou sadu hodnot, ti pomůže si ujasnit, co od koho vlastně čekáš a jaké hodnoty by měl naplňovat<strong> partner, se kterým se budeš cítit dobře.</strong> Je úplně jedno, jestli v současné chvíli partnera máš, a nebo hledáš.</br></span>',
    boxHeaderText: '',
    box1: {
      text: ' <strong>Nalezení společných hodnot</strong> klidně do hodiny',
      img: 'partner_banky.png',
      width: '6rem',
      height: '6rem'
    },
    box2: {
      text: '<strong>Základní nastavení celého procesu</strong>dle požadavků klienta </strong>',
      img: 'partner_bryle.png',
      width: '6rem',
      height: '6rem'
    },
    box3: {
      text: '<strong>Pro týmy od 3 do 10 000 lidí<strong>',
      img: 'partner_hlava.png',
      width: '6rem',
      height: '6rem'
    },
    boxBottomText: 'Položte si více otázek, hodnoty vám odpoví. ',
  },
  section5: {
    backgroundColor: 'radial-gradient(circle at top, #E7DCA5 0%, #D7C87F 100%)',
    header: 'Jak to funguje?',
    text1: '1. Kontaktuje nás. Zašleme vám možnosti. Vyberete si se základního nastavení či vám uzpůsobíme žebříčky na míru',
    text2: '2. Připravíme vám webové rozhraní',
    text3: '3. Kompletní výsledky i výstupy včetně doprovodných anket Vám vyhodnotíme a zašleme na Váš email.',
    downtext: 'Základní cena hodnot vašeho projektu do 30 účastníků od 12.999,- Kč ',
    customIcon1: 'ikona_univerz.png',
    customIcon2: 'ikona_univerz.png',
    customIcon3: 'ikona_univerz.png'
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #3ECAD1 0%, #088F96 100%)',
    image: 'ikona_univerz.png',
    header: 'Jak vypadají výstupy a co získáte',
    subtitle: 'Kompletní souhrné výsledky celé skupiny, vyhodnocené doplňkové odpovědi a matice hodnotových vztahů mezi jednotlivými účastníky včetně doporučení, jak s hodnotami pracovat. <strong> kontaktuje nás.<strong> ',
    image2: 'partner_graf_830.png',
    image2Mobile: 'grafy_PRESENTING640X.png'
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #456C8A 0%, #2F4A62 99.92%)',
    heading: 'Jaké žebříčky lze pro firemní prostředí vytvářet',
    subheading: 'Klíčová je otázka!',
    linkText: ''
  },
  section12: DefaultSection12,
  section13: DefaultSection13
};

const Page50: PageProps = {
  section1: {
    header: 'Hodnoty a pracovní týmy<br></br>',
    backgroundImg: 'love_partner.jpg',
    backgroundImgThinner: 'love_partner.jpg',
    backgroundImg2: 'love_Back.png',
    backgroundImg2Mobile: 'mob_700.png',
    backgroundImg2Tablet: 'mob_700.png',
    gradient: {
      color1: 'rgba(87, 88, 109, 0.5)',
      color2: 'rgba(115, 116, 137, 0.3)',
      color3: 'rgba(87, 88, 109, 0.5)',
      color4: 'rgba(115, 116, 137, 0.3)',
      speed: '20s'
    },
    img: 'ideal_142x142.png',
    imgMobile: 'partner_mobil_90x160.png',
    imgThinner: 'partner_thinner.png',
    imgThinnerMobile: 'partner_thinner_mobile_.png',
    subtitle: '',
    button: {
      text: 'Napište nám',
      background: '#FFC7BA',
      icon: 'ladder.png'
    },
    text: '',
    eticiaPlus: true,
  },
  section2: {
    bgColor: 'radial-gradient(circle at top, rgba(187,204,249,1) 0%, rgba(152,176,242,1) 100%)',
    backgroundImg: '0_Me_hodnoty_o_hodnotach.png',
    header: 'Hodnoty našeho projektu a přístup k jeho prezentaci',
    subheader: 'Pojmenujte v týmu si jednoduše a rychle nejdůležitější hodnoty vašeho projektu.',
    subtitle: 'Význam poznávání hodnot pro etickou výchovu a společenské předměty přináší nejen zajímavé rozhovory a diskuze, ale také vede k jejich naplňování, vyššímu respektu mezi jednotlivými studenty.',
    downtext: 'Lorem ipsum'
  },
  section3: {
    backgroundImg: 'zeme1.jpg',
    text: '<strong><span style="color: #F8CEC3">Souhra a souznění v směřování vašeho projektu či cíle vede k efektivitě a vyšší kreativitě</br></span></strong> Lorem... Každý z nás si v sobě nosí svůj vlastní hodnotový kompas, který určuje, kam a za čím se v životě vydáme. Jaká cesta je pro nás přijatelná a kudy rozhodně jít nechceme...<br></br> <strong><span style="color: #F8CEC3">Denně děláme desítky rozhodnutí, ve kterém hrají podstatnou roli naše hodnoty.</strong></span> Nejen, že se díky nim přesněji rozhodujeme, ale také jsou důležité pro naši komunikaci a chápání druhých. <br></br><strong><span style="color: #F8CEC3">Proč vede naplňování našich hodnot k vlastní spokojenosti?</span><br></br> Naše přání i cíle jsou olemovány také našimi hodnotami, které lze jednoduše vyjádřit <span style="color: #F8CEC3">pomocí hodnotových žebříčků.</span> Ty nám ukazují konkrétní důležitost pro dané životní situace a když jsou naše hodnoty v souladu s tím, co děláme, <span style="color: #F8CEC3">jsme celkově spokojenější.',
    header: 'Zahájit tvorbu kodexu',
    subtitle: '',
    button: {
      text: '<span style="color: #E54FA6"> &#9660; </span>'
    }
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'partner_chemik1.png',
    header: '<span style="color: #000000">Neprocházejte trnitou cestou<strong>když tu jsou</span><span style="color: #C7129A"> hodnoty/span></strong>',
    subtitle: '<span style="color: #000000">Hodnoty, vztahy, vize i motivace a způsoby chování,hrají v každé společnosti význmanou roli <strong> Hodnoty se mísí s chemií.</strong> Představu o svém „ideálním partnerovi“ si nosíme každý v sobě. <br><br> <strong><span style="color: #C7129A">Udělej si v tom jasno - speciálních 42 hodnot pro partnerství</span></strong><br></br>Tento žebříček, obsahující novou sadu hodnot, ti pomůže si ujasnit, co od koho vlastně čekáš a jaké hodnoty by měl naplňovat<strong> partner, se kterým se budeš cítit dobře.</strong> Je úplně jedno, jestli v současné chvíli partnera máš, a nebo hledáš.</br></span>',
    boxHeaderText: '',
    box1: {
      text: ' <strong>Nalezení společných hodnot</strong> klidně do hodiny',
      img: 'partner_banky.png',
      width: '6rem',
      height: '6rem'
    },
    box2: {
      text: '<strong>Základní nastavení celého procesu</strong>dle požadavků klienta </strong>',
      img: 'partner_bryle.png',
      width: '6rem',
      height: '6rem'
    },
    box3: {
      text: '<strong>Pro týmy od 3 do 10 000 lidí<strong>',
      img: 'partner_hlava.png',
      width: '6rem',
      height: '6rem'
    },
    boxBottomText: 'Položte si více otázek, hodnoty vám odpoví. ',
  },
  section5: {
    backgroundColor: 'radial-gradient(circle at top, #E7DCA5 0%, #D7C87F 100%)',
    header: 'Jak to funguje?',
    text1: '1. Kontaktuje nás. Zašleme vám možnosti. Vyberete si se základního nastavení či vám uzpůsobíme žebříčky na míru',
    text2: '2. Připravíme vám webové rozhraní',
    text3: '3. Kompletní výsledky i výstupy včetně doprovodných anket Vám vyhodnotíme a zašleme na Váš email.',
    downtext: 'Základní cena hodnot vašeho projektu do 30 účastníků od 12.999,- Kč ',
    customIcon1: 'ikona_univerz.png',
    customIcon2: 'ikona_univerz.png',
    customIcon3: 'ikona_univerz.png'
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #3ECAD1 0%, #088F96 100%)',
    image: 'ikona_univerz.png',
    header: 'Jak vypadají výstupy a co získáte',
    subtitle: 'Kompletní souhrné výsledky celé skupiny, vyhodnocené doplňkové odpovědi a matice hodnotových vztahů mezi jednotlivými účastníky včetně doporučení, jak s hodnotami pracovat. <strong> kontaktuje nás.<strong> ',
    image2: 'partner_graf_830.png',
    image2Mobile: 'grafy_PRESENTING640X.png'
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #456C8A 0%, #2F4A62 99.92%)',
    heading: 'Jaké žebříčky lze pro firemní prostředí vytvářet',
    subheading: 'Klíčová je otázka!',
    linkText: ''
  },
  section12: DefaultSection12,
  section13: DefaultSection13
};

const Page51: PageProps = {
  section1: {
    header: 'Hodnotová hra<br></br>',
    backgroundImg: 'Strom_hodnot_green.jpg',
    backgroundImgThinner: 'love_partner.jpg',
    backgroundImg2: 'love_Back.png',
    backgroundImg2Mobile: 'mob_700.png',
    backgroundImg2Tablet: 'mob_700.png',
    gradient: {
      color1: 'rgba(13, 109, 95, 0.5)',
      color2: 'rgba(31,132,114, 0.3)',
      color3: 'rgba(13, 109, 95, 0.5)',
      color4: 'rgba(31,132,114, 0.3)',
      speed: '20s'
    },
    img: 'ideal_142x142.png',
    imgMobile: 'partner_mobil_90x160.png',
    imgThinner: 'partner_thinner.png',
    imgThinnerMobile: 'partner_thinner_mobile_.png',
    subtitle: '',
    button: {
      text: 'Napište nám',
      background: '#FFC7BA',
      icon: 'ladder.png'
    },
    text: '',
    eticiaPlus: true,
  },
  section2: {
    bgColor: 'radial-gradient(circle at top, rgba(187,204,249,1) 0%, rgba(152,176,242,1) 100%)',
    backgroundImg: '0_Me_hodnoty_o_hodnotach.png',
    header: 'Hodnoty našeho projektu a přístup k jeho prezentaci',
    subheader: 'Pojmenujte v týmu si jednoduše a rychle nejdůležitější hodnoty vašeho projektu.',
    subtitle: 'Význam poznávání hodnot pro etickou výchovu a společenské předměty přináší nejen zajímavé rozhovory a diskuze, ale také vede k jejich naplňování, vyššímu respektu mezi jednotlivými studenty.',
    downtext: 'Lorem ipsum'
  },
  section3: {
    backgroundImg: 'zeme1.jpg',
    text: '<strong><span style="color: #F8CEC3">Souhra a souznění v směřování vašeho projektu či cíle vede k efektivitě a vyšší kreativitě</br></span></strong> Lorem... Každý z nás si v sobě nosí svůj vlastní hodnotový kompas, který určuje, kam a za čím se v životě vydáme. Jaká cesta je pro nás přijatelná a kudy rozhodně jít nechceme...<br></br> <strong><span style="color: #F8CEC3">Denně děláme desítky rozhodnutí, ve kterém hrají podstatnou roli naše hodnoty.</strong></span> Nejen, že se díky nim přesněji rozhodujeme, ale také jsou důležité pro naši komunikaci a chápání druhých. <br></br><strong><span style="color: #F8CEC3">Proč vede naplňování našich hodnot k vlastní spokojenosti?</span><br></br> Naše přání i cíle jsou olemovány také našimi hodnotami, které lze jednoduše vyjádřit <span style="color: #F8CEC3">pomocí hodnotových žebříčků.</span> Ty nám ukazují konkrétní důležitost pro dané životní situace a když jsou naše hodnoty v souladu s tím, co děláme, <span style="color: #F8CEC3">jsme celkově spokojenější.',
    header: 'Zahájit tvorbu kodexu',
    subtitle: '',
    button: {
      text: '<span style="color: #E54FA6"> &#9660; </span>'
    }
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'partner_chemik1.png',
    header: '<span style="color: #000000">Neprocházejte trnitou cestou<strong>když tu jsou</span><span style="color: #C7129A"> hodnoty/span></strong>',
    subtitle: '<span style="color: #000000">Hodnoty, vztahy, vize i motivace a způsoby chování,hrají v každé společnosti význmanou roli <strong> Hodnoty se mísí s chemií.</strong> Představu o svém „ideálním partnerovi“ si nosíme každý v sobě. <br><br> <strong><span style="color: #C7129A">Udělej si v tom jasno - speciálních 42 hodnot pro partnerství</span></strong><br></br>Tento žebříček, obsahující novou sadu hodnot, ti pomůže si ujasnit, co od koho vlastně čekáš a jaké hodnoty by měl naplňovat<strong> partner, se kterým se budeš cítit dobře.</strong> Je úplně jedno, jestli v současné chvíli partnera máš, a nebo hledáš.</br></span>',
    boxHeaderText: '',
    box1: {
      text: ' <strong>Nalezení společných hodnot</strong> klidně do hodiny',
      img: 'partner_banky.png',
      width: '6rem',
      height: '6rem'
    },
    box2: {
      text: '<strong>Základní nastavení celého procesu</strong>dle požadavků klienta </strong>',
      img: 'partner_bryle.png',
      width: '6rem',
      height: '6rem'
    },
    box3: {
      text: '<strong>Pro týmy od 3 do 10 000 lidí<strong>',
      img: 'partner_hlava.png',
      width: '6rem',
      height: '6rem'
    },
    boxBottomText: 'Položte si více otázek, hodnoty vám odpoví. ',
  },
  section5: {
    backgroundColor: 'radial-gradient(circle at top, #E7DCA5 0%, #D7C87F 100%)',
    header: 'Jak to funguje?',
    text1: '1. Kontaktuje nás. Zašleme vám možnosti. Vyberete si se základního nastavení či vám uzpůsobíme žebříčky na míru',
    text2: '2. Připravíme vám webové rozhraní',
    text3: '3. Kompletní výsledky i výstupy včetně doprovodných anket Vám vyhodnotíme a zašleme na Váš email.',
    downtext: 'Základní cena hodnot vašeho projektu do 30 účastníků od 12.999,- Kč ',
    customIcon1: 'ikona_univerz.png',
    customIcon2: 'ikona_univerz.png',
    customIcon3: 'ikona_univerz.png'
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #3ECAD1 0%, #088F96 100%)',
    image: 'ikona_univerz.png',
    header: 'Jak vypadají výstupy a co získáte',
    subtitle: 'Kompletní souhrné výsledky celé skupiny, vyhodnocené doplňkové odpovědi a matice hodnotových vztahů mezi jednotlivými účastníky včetně doporučení, jak s hodnotami pracovat. <strong> kontaktuje nás.<strong> ',
    image2: 'partner_graf_830.png',
    image2Mobile: 'grafy_PRESENTING640X.png'
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #456C8A 0%, #2F4A62 99.92%)',
    heading: 'Jaké žebříčky lze pro firemní prostředí vytvářet',
    subheading: 'Klíčová je otázka!',
    linkText: ''
  },
  section12: DefaultSection12,
  section13: DefaultSection13
};

const Page52: PageProps = {
  section1: {
    header: 'EticiaMeter<br></br>',
    backgroundImg: 'Eticia_meter.jpg',
    backgroundImgThinner: 'love_partner.jpg',
    backgroundImg2: 'love_Back.png',
    backgroundImg2Mobile: 'mob_700.png',
    backgroundImg2Tablet: 'mob_700.png',
    gradient: {
      color1: 'rgba(5, 132, 154, 0.5)',
      color2: 'rgba(17,160, 176, 0.3)',
      color3: 'rgba(5, 132, 154, 0.5)',
      color4: 'rgba(17,160, 176, 0.3)',
      speed: '20s'
    },
    img: 'ideal_142x142.png',
    imgMobile: 'partner_mobil_90x160.png',
    imgThinner: 'partner_thinner.png',
    imgThinnerMobile: 'partner_thinner_mobile_.png',
    subtitle: '',
    button: {
      text: 'Napište nám',
      background: '#FFC7BA',
      icon: 'ladder.png'
    },
    text: 'Pokus',
    eticiaPlus: true,
  },
  section2: {
    bgColor: 'radial-gradient(circle at top, rgba(187,204,249,1) 0%, rgba(152,176,242,1) 100%)',
    backgroundImg: '0_Me_hodnoty_o_hodnotach.png',
    header: 'Hodnoty našeho projektu a přístup k jeho prezentaci',
    subheader: 'Pojmenujte v týmu si jednoduše a rychle nejdůležitější hodnoty vašeho projektu.',
    subtitle: 'Význam poznávání hodnot pro etickou výchovu a společenské předměty přináší nejen zajímavé rozhovory a diskuze, ale také vede k jejich naplňování, vyššímu respektu mezi jednotlivými studenty.',
    downtext: 'Lorem ipsum'
  },
  section3: {
    backgroundImg: 'zeme1.jpg',
    text: '<strong><span style="color: #F8CEC3">Souhra a souznění v směřování vašeho projektu či cíle vede k efektivitě a vyšší kreativitě</br></span></strong> Lorem... Každý z nás si v sobě nosí svůj vlastní hodnotový kompas, který určuje, kam a za čím se v životě vydáme. Jaká cesta je pro nás přijatelná a kudy rozhodně jít nechceme...<br></br> <strong><span style="color: #F8CEC3">Denně děláme desítky rozhodnutí, ve kterém hrají podstatnou roli naše hodnoty.</strong></span> Nejen, že se díky nim přesněji rozhodujeme, ale také jsou důležité pro naši komunikaci a chápání druhých. <br></br><strong><span style="color: #F8CEC3">Proč vede naplňování našich hodnot k vlastní spokojenosti?</span><br></br> Naše přání i cíle jsou olemovány také našimi hodnotami, které lze jednoduše vyjádřit <span style="color: #F8CEC3">pomocí hodnotových žebříčků.</span> Ty nám ukazují konkrétní důležitost pro dané životní situace a když jsou naše hodnoty v souladu s tím, co děláme, <span style="color: #F8CEC3">jsme celkově spokojenější.',
    header: 'Zahájit tvorbu kodexu',
    subtitle: '',
    button: {
      text: '<span style="color: #E54FA6"> &#9660; </span>'
    }
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'partner_chemik1.png',
    header: '<span style="color: #000000">Neprocházejte trnitou cestou<strong>když tu jsou</span><span style="color: #C7129A"> hodnoty/span></strong>',
    subtitle: '<span style="color: #000000">Hodnoty, vztahy, vize i motivace a způsoby chování,hrají v každé společnosti význmanou roli <strong> Hodnoty se mísí s chemií.</strong> Představu o svém „ideálním partnerovi“ si nosíme každý v sobě. <br><br> <strong><span style="color: #C7129A">Udělej si v tom jasno - speciálních 42 hodnot pro partnerství</span></strong><br></br>Tento žebříček, obsahující novou sadu hodnot, ti pomůže si ujasnit, co od koho vlastně čekáš a jaké hodnoty by měl naplňovat<strong> partner, se kterým se budeš cítit dobře.</strong> Je úplně jedno, jestli v současné chvíli partnera máš, a nebo hledáš.</br></span>',
    boxHeaderText: '',
    box1: {
      text: ' <strong>Nalezení společných hodnot</strong> klidně do hodiny',
      img: 'partner_banky.png',
      width: '6rem',
      height: '6rem'
    },
    box2: {
      text: '<strong>Základní nastavení celého procesu</strong>dle požadavků klienta </strong>',
      img: 'partner_bryle.png',
      width: '6rem',
      height: '6rem'
    },
    box3: {
      text: '<strong>Pro týmy od 3 do 10 000 lidí<strong>',
      img: 'partner_hlava.png',
      width: '6rem',
      height: '6rem'
    },
    boxBottomText: 'Položte si více otázek, hodnoty vám odpoví. ',
  },
  section5: {
    backgroundColor: 'radial-gradient(circle at top, #E7DCA5 0%, #D7C87F 100%)',
    header: 'Jak to funguje?',
    text1: '1. Kontaktuje nás. Zašleme vám možnosti. Vyberete si se základního nastavení či vám uzpůsobíme žebříčky na míru',
    text2: '2. Připravíme vám webové rozhraní',
    text3: '3. Kompletní výsledky i výstupy včetně doprovodných anket Vám vyhodnotíme a zašleme na Váš email.',
    downtext: 'Základní cena hodnot vašeho projektu do 30 účastníků od 12.999,- Kč ',
    customIcon1: 'ikona_univerz.png',
    customIcon2: 'ikona_univerz.png',
    customIcon3: 'ikona_univerz.png'
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #3ECAD1 0%, #088F96 100%)',
    image: 'ikona_univerz.png',
    header: 'Jak vypadají výstupy a co získáte',
    subtitle: 'Kompletní souhrné výsledky celé skupiny, vyhodnocené doplňkové odpovědi a matice hodnotových vztahů mezi jednotlivými účastníky včetně doporučení, jak s hodnotami pracovat. <strong> kontaktuje nás.<strong> ',
    image2: 'partner_graf_830.png',
    image2Mobile: 'grafy_PRESENTING640X.png'
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #456C8A 0%, #2F4A62 99.92%)',
    heading: 'Jaké žebříčky lze pro firemní prostředí vytvářet',
    subheading: 'Klíčová je otázka!',
    linkText: ''
  },
  section12: DefaultSection12,
  section13: DefaultSection13
};

const Page53: PageProps = {
  section1: {
    header: 'Hodnoty a pracovní týmy<br></br>',
    backgroundImg: 'love_partner.jpg',
    backgroundImgThinner: 'love_partner.jpg',
    backgroundImg2: 'love_Back.png',
    backgroundImg2Mobile: 'mob_700.png',
    backgroundImg2Tablet: 'mob_700.png',
    gradient: {
      color1: 'rgba(87, 88, 109, 0.5)',
      color2: 'rgba(115, 116, 137, 0.3)',
      color3: 'rgba(87, 88, 109, 0.5)',
      color4: 'rgba(115, 116, 137, 0.3)',
      speed: '20s'
    },
    img: 'ideal_142x142.png',
    imgMobile: 'partner_mobil_90x160.png',
    imgThinner: 'partner_thinner.png',
    imgThinnerMobile: 'partner_thinner_mobile_.png',
    subtitle: '',
    button: {
      text: 'Napište nám',
      background: '#FFC7BA',
      icon: 'ladder.png'
    },
    text: '',
    eticiaPlus: true,
  },
  section2: {
    bgColor: 'radial-gradient(circle at top, rgba(187,204,249,1) 0%, rgba(152,176,242,1) 100%)',
    backgroundImg: '0_Me_hodnoty_o_hodnotach.png',
    header: 'Hodnoty našeho projektu a přístup k jeho prezentaci',
    subheader: 'Pojmenujte v týmu si jednoduše a rychle nejdůležitější hodnoty vašeho projektu.',
    subtitle: 'Význam poznávání hodnot pro etickou výchovu a společenské předměty přináší nejen zajímavé rozhovory a diskuze, ale také vede k jejich naplňování, vyššímu respektu mezi jednotlivými studenty.',
    downtext: 'Lorem ipsum'
  },
  section3: {
    backgroundImg: 'zeme1.jpg',
    text: '<strong><span style="color: #F8CEC3">Souhra a souznění v směřování vašeho projektu či cíle vede k efektivitě a vyšší kreativitě</br></span></strong> Lorem... Každý z nás si v sobě nosí svůj vlastní hodnotový kompas, který určuje, kam a za čím se v životě vydáme. Jaká cesta je pro nás přijatelná a kudy rozhodně jít nechceme...<br></br> <strong><span style="color: #F8CEC3">Denně děláme desítky rozhodnutí, ve kterém hrají podstatnou roli naše hodnoty.</strong></span> Nejen, že se díky nim přesněji rozhodujeme, ale také jsou důležité pro naši komunikaci a chápání druhých. <br></br><strong><span style="color: #F8CEC3">Proč vede naplňování našich hodnot k vlastní spokojenosti?</span><br></br> Naše přání i cíle jsou olemovány také našimi hodnotami, které lze jednoduše vyjádřit <span style="color: #F8CEC3">pomocí hodnotových žebříčků.</span> Ty nám ukazují konkrétní důležitost pro dané životní situace a když jsou naše hodnoty v souladu s tím, co děláme, <span style="color: #F8CEC3">jsme celkově spokojenější.',
    header: 'Zahájit tvorbu kodexu',
    subtitle: '',
    button: {
      text: '<span style="color: #E54FA6"> &#9660; </span>'
    }
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'partner_chemik1.png',
    header: '<span style="color: #000000">Neprocházejte trnitou cestou<strong>když tu jsou</span><span style="color: #C7129A"> hodnoty/span></strong>',
    subtitle: '<span style="color: #000000">Hodnoty, vztahy, vize i motivace a způsoby chování,hrají v každé společnosti význmanou roli <strong> Hodnoty se mísí s chemií.</strong> Představu o svém „ideálním partnerovi“ si nosíme každý v sobě. <br><br> <strong><span style="color: #C7129A">Udělej si v tom jasno - speciálních 42 hodnot pro partnerství</span></strong><br></br>Tento žebříček, obsahující novou sadu hodnot, ti pomůže si ujasnit, co od koho vlastně čekáš a jaké hodnoty by měl naplňovat<strong> partner, se kterým se budeš cítit dobře.</strong> Je úplně jedno, jestli v současné chvíli partnera máš, a nebo hledáš.</br></span>',
    boxHeaderText: '',
    box1: {
      text: ' <strong>Nalezení společných hodnot</strong> klidně do hodiny',
      img: 'partner_banky.png',
      width: '6rem',
      height: '6rem'
    },
    box2: {
      text: '<strong>Základní nastavení celého procesu</strong>dle požadavků klienta </strong>',
      img: 'partner_bryle.png',
      width: '6rem',
      height: '6rem'
    },
    box3: {
      text: '<strong>Pro týmy od 3 do 10 000 lidí<strong>',
      img: 'partner_hlava.png',
      width: '6rem',
      height: '6rem'
    },
    boxBottomText: 'Položte si více otázek, hodnoty vám odpoví. ',
  },
  section5: {
    backgroundColor: 'radial-gradient(circle at top, #E7DCA5 0%, #D7C87F 100%)',
    header: 'Jak to funguje?',
    text1: '1. Kontaktuje nás. Zašleme vám možnosti. Vyberete si se základního nastavení či vám uzpůsobíme žebříčky na míru',
    text2: '2. Připravíme vám webové rozhraní',
    text3: '3. Kompletní výsledky i výstupy včetně doprovodných anket Vám vyhodnotíme a zašleme na Váš email.',
    downtext: 'Základní cena hodnot vašeho projektu do 30 účastníků od 12.999,- Kč ',
    customIcon1: 'ikona_univerz.png',
    customIcon2: 'ikona_univerz.png',
    customIcon3: 'ikona_univerz.png'
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #3ECAD1 0%, #088F96 100%)',
    image: 'ikona_univerz.png',
    header: 'Jak vypadají výstupy a co získáte',
    subtitle: 'Kompletní souhrné výsledky celé skupiny, vyhodnocené doplňkové odpovědi a matice hodnotových vztahů mezi jednotlivými účastníky včetně doporučení, jak s hodnotami pracovat. <strong> kontaktuje nás.<strong> ',
    image2: 'partner_graf_830.png',
    image2Mobile: 'grafy_PRESENTING640X.png'
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #456C8A 0%, #2F4A62 99.92%)',
    heading: 'Jaké žebříčky lze pro firemní prostředí vytvářet',
    subheading: 'Klíčová je otázka!',
    linkText: ''
  },
  section12: DefaultSection12,
  section13: DefaultSection13
};

const Page54: PageProps = {
  section1: {
    header: 'Eticia Plus jako firemní dárek<br></br>',
    backgroundImg: 'Partnerstvi---red.jpg',
    backgroundImgThinner: 'love_partner.jpg',
    backgroundImg2: 'love_Back.png',
    backgroundImg2Mobile: 'mob_700.png',
    backgroundImg2Tablet: 'mob_700.png',
    gradient: {
      color1: 'rgba(132, 38, 65, 0.5)',
      color2: 'rgba(173,73,111, 0.3)',
      color3: 'rgba(132, 38, 65, 0.5)',
      color4: 'rgba(173,73,111, 0.3)',
      speed: '20s'
    },
    img: 'ideal_142x142.png',
    imgMobile: 'partner_mobil_90x160.png',
    imgThinner: 'partner_thinner.png',
    imgThinnerMobile: 'partner_thinner_mobile_.png',
    subtitle: '',
    button: {
      text: 'Napište nám',
      background: '#FFC7BA',
      icon: 'ladder.png'
    },
    text: '',
    eticiaPlus: true,
  },
  section2: {
    bgColor: 'radial-gradient(circle at top, rgba(187,204,249,1) 0%, rgba(152,176,242,1) 100%)',
    backgroundImg: '0_Me_hodnoty_o_hodnotach.png',
    header: 'Hodnoty našeho projektu a přístup k jeho prezentaci',
    subheader: 'Pojmenujte v týmu si jednoduše a rychle nejdůležitější hodnoty vašeho projektu.',
    subtitle: 'Význam poznávání hodnot pro etickou výchovu a společenské předměty přináší nejen zajímavé rozhovory a diskuze, ale také vede k jejich naplňování, vyššímu respektu mezi jednotlivými studenty.',
    downtext: 'Lorem ipsum'
  },
  section3: {
    backgroundImg: 'zeme1.jpg',
    text: '<strong><span style="color: #F8CEC3">Souhra a souznění v směřování vašeho projektu či cíle vede k efektivitě a vyšší kreativitě</br></span></strong> Lorem... Každý z nás si v sobě nosí svůj vlastní hodnotový kompas, který určuje, kam a za čím se v životě vydáme. Jaká cesta je pro nás přijatelná a kudy rozhodně jít nechceme...<br></br> <strong><span style="color: #F8CEC3">Denně děláme desítky rozhodnutí, ve kterém hrají podstatnou roli naše hodnoty.</strong></span> Nejen, že se díky nim přesněji rozhodujeme, ale také jsou důležité pro naši komunikaci a chápání druhých. <br></br><strong><span style="color: #F8CEC3">Proč vede naplňování našich hodnot k vlastní spokojenosti?</span><br></br> Naše přání i cíle jsou olemovány také našimi hodnotami, které lze jednoduše vyjádřit <span style="color: #F8CEC3">pomocí hodnotových žebříčků.</span> Ty nám ukazují konkrétní důležitost pro dané životní situace a když jsou naše hodnoty v souladu s tím, co děláme, <span style="color: #F8CEC3">jsme celkově spokojenější.',
    header: 'Zahájit tvorbu kodexu',
    subtitle: '',
    button: {
      text: '<span style="color: #E54FA6"> &#9660; </span>'
    }
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'partner_chemik1.png',
    header: '<span style="color: #000000">Neprocházejte trnitou cestou<strong>když tu jsou</span><span style="color: #C7129A"> hodnoty/span></strong>',
    subtitle: '<span style="color: #000000">Hodnoty, vztahy, vize i motivace a způsoby chování,hrají v každé společnosti význmanou roli <strong> Hodnoty se mísí s chemií.</strong> Představu o svém „ideálním partnerovi“ si nosíme každý v sobě. <br><br> <strong><span style="color: #C7129A">Udělej si v tom jasno - speciálních 42 hodnot pro partnerství</span></strong><br></br>Tento žebříček, obsahující novou sadu hodnot, ti pomůže si ujasnit, co od koho vlastně čekáš a jaké hodnoty by měl naplňovat<strong> partner, se kterým se budeš cítit dobře.</strong> Je úplně jedno, jestli v současné chvíli partnera máš, a nebo hledáš.</br></span>',
    boxHeaderText: '',
    box1: {
      text: ' <strong>Nalezení společných hodnot</strong> klidně do hodiny',
      img: 'partner_banky.png',
      width: '6rem',
      height: '6rem'
    },
    box2: {
      text: '<strong>Základní nastavení celého procesu</strong>dle požadavků klienta </strong>',
      img: 'partner_bryle.png',
      width: '6rem',
      height: '6rem'
    },
    box3: {
      text: '<strong>Pro týmy od 3 do 10 000 lidí<strong>',
      img: 'partner_hlava.png',
      width: '6rem',
      height: '6rem'
    },
    boxBottomText: 'Položte si více otázek, hodnoty vám odpoví. ',
  },
  section5: {
    backgroundColor: 'radial-gradient(circle at top, #E7DCA5 0%, #D7C87F 100%)',
    header: 'Jak to funguje?',
    text1: '1. Kontaktuje nás. Zašleme vám možnosti. Vyberete si se základního nastavení či vám uzpůsobíme žebříčky na míru',
    text2: '2. Připravíme vám webové rozhraní',
    text3: '3. Kompletní výsledky i výstupy včetně doprovodných anket Vám vyhodnotíme a zašleme na Váš email.',
    downtext: 'Základní cena hodnot vašeho projektu do 30 účastníků od 12.999,- Kč ',
    customIcon1: 'ikona_univerz.png',
    customIcon2: 'ikona_univerz.png',
    customIcon3: 'ikona_univerz.png'
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #3ECAD1 0%, #088F96 100%)',
    image: 'ikona_univerz.png',
    header: 'Jak vypadají výstupy a co získáte',
    subtitle: 'Kompletní souhrné výsledky celé skupiny, vyhodnocené doplňkové odpovědi a matice hodnotových vztahů mezi jednotlivými účastníky včetně doporučení, jak s hodnotami pracovat. <strong> kontaktuje nás.<strong> ',
    image2: 'partner_graf_830.png',
    image2Mobile: 'grafy_PRESENTING640X.png'
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #456C8A 0%, #2F4A62 99.92%)',
    heading: 'Jaké žebříčky lze pro firemní prostředí vytvářet',
    subheading: 'Klíčová je otázka!',
    linkText: ''
  },
  section12: DefaultSection12,
  section13: DefaultSection13
};

const Page55: PageProps = {
  section1: {
    header: 'Eticia pomáhá<br></br>',
    backgroundImg: 'Bliz---kids.jpg',
    backgroundImgThinner: 'love_partner.jpg',
    backgroundImg2: 'love_Back.png',
    backgroundImg2Mobile: 'mob_700.png',
    backgroundImg2Tablet: 'mob_700.png',
    gradient: {
      color1: 'rgba(38, 96, 133, 0.5)',
      color2: 'rgba(52, 120, 154, 0.3)',
      color3: 'rgba(38, 96, 133, 0.5)',
      color4: 'rgba(52, 120, 154, 0.3)',
      speed: '20s'
    },
    img: 'ideal_142x142.png',
    imgMobile: 'partner_mobil_90x160.png',
    imgThinner: 'partner_thinner.png',
    imgThinnerMobile: 'partner_thinner_mobile_.png',
    subtitle: '',
    button: {
      text: 'Napište nám',
      background: '#FFC7BA',
      icon: 'ladder.png'
    },
    text: '',
    eticiaPlus: true,
  },
  section2: {
    bgColor: 'radial-gradient(circle at top, rgba(187,204,249,1) 0%, rgba(152,176,242,1) 100%)',
    backgroundImg: '0_Me_hodnoty_o_hodnotach.png',
    header: 'Hodnoty našeho projektu a přístup k jeho prezentaci',
    subheader: 'Pojmenujte v týmu si jednoduše a rychle nejdůležitější hodnoty vašeho projektu.',
    subtitle: 'Význam poznávání hodnot pro etickou výchovu a společenské předměty přináší nejen zajímavé rozhovory a diskuze, ale také vede k jejich naplňování, vyššímu respektu mezi jednotlivými studenty.',
    downtext: 'Lorem ipsum'
  },
  section3: {
    backgroundImg: 'zeme1.jpg',
    text: '<strong><span style="color: #F8CEC3">Souhra a souznění v směřování vašeho projektu či cíle vede k efektivitě a vyšší kreativitě</br></span></strong> Lorem... Každý z nás si v sobě nosí svůj vlastní hodnotový kompas, který určuje, kam a za čím se v životě vydáme. Jaká cesta je pro nás přijatelná a kudy rozhodně jít nechceme...<br></br> <strong><span style="color: #F8CEC3">Denně děláme desítky rozhodnutí, ve kterém hrají podstatnou roli naše hodnoty.</strong></span> Nejen, že se díky nim přesněji rozhodujeme, ale také jsou důležité pro naši komunikaci a chápání druhých. <br></br><strong><span style="color: #F8CEC3">Proč vede naplňování našich hodnot k vlastní spokojenosti?</span><br></br> Naše přání i cíle jsou olemovány také našimi hodnotami, které lze jednoduše vyjádřit <span style="color: #F8CEC3">pomocí hodnotových žebříčků.</span> Ty nám ukazují konkrétní důležitost pro dané životní situace a když jsou naše hodnoty v souladu s tím, co děláme, <span style="color: #F8CEC3">jsme celkově spokojenější.',
    header: 'Zahájit tvorbu kodexu',
    subtitle: '',
    button: {
      text: '<span style="color: #E54FA6"> &#9660; </span>'
    }
  },
  section4: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: 'partner_chemik1.png',
    header: '<span style="color: #000000">Neprocházejte trnitou cestou<strong>když tu jsou</span><span style="color: #C7129A"> hodnoty/span></strong>',
    subtitle: '<span style="color: #000000">Hodnoty, vztahy, vize i motivace a způsoby chování,hrají v každé společnosti význmanou roli <strong> Hodnoty se mísí s chemií.</strong> Představu o svém „ideálním partnerovi“ si nosíme každý v sobě. <br><br> <strong><span style="color: #C7129A">Udělej si v tom jasno - speciálních 42 hodnot pro partnerství</span></strong><br></br>Tento žebříček, obsahující novou sadu hodnot, ti pomůže si ujasnit, co od koho vlastně čekáš a jaké hodnoty by měl naplňovat<strong> partner, se kterým se budeš cítit dobře.</strong> Je úplně jedno, jestli v současné chvíli partnera máš, a nebo hledáš.</br></span>',
    boxHeaderText: '',
    box1: {
      text: ' <strong>Nalezení společných hodnot</strong> klidně do hodiny',
      img: 'partner_banky.png',
      width: '6rem',
      height: '6rem'
    },
    box2: {
      text: '<strong>Základní nastavení celého procesu</strong>dle požadavků klienta </strong>',
      img: 'partner_bryle.png',
      width: '6rem',
      height: '6rem'
    },
    box3: {
      text: '<strong>Pro týmy od 3 do 10 000 lidí<strong>',
      img: 'partner_hlava.png',
      width: '6rem',
      height: '6rem'
    },
    boxBottomText: 'Položte si více otázek, hodnoty vám odpoví. ',
  },
  section5: {
    backgroundColor: 'radial-gradient(circle at top, #E7DCA5 0%, #D7C87F 100%)',
    header: 'Jak to funguje?',
    text1: '1. Kontaktuje nás. Zašleme vám možnosti. Vyberete si se základního nastavení či vám uzpůsobíme žebříčky na míru',
    text2: '2. Připravíme vám webové rozhraní',
    text3: '3. Kompletní výsledky i výstupy včetně doprovodných anket Vám vyhodnotíme a zašleme na Váš email.',
    downtext: 'Základní cena hodnot vašeho projektu do 30 účastníků od 12.999,- Kč ',
    customIcon1: 'ikona_univerz.png',
    customIcon2: 'ikona_univerz.png',
    customIcon3: 'ikona_univerz.png'
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #3ECAD1 0%, #088F96 100%)',
    image: 'ikona_univerz.png',
    header: 'Jak vypadají výstupy a co získáte',
    subtitle: 'Kompletní souhrné výsledky celé skupiny, vyhodnocené doplňkové odpovědi a matice hodnotových vztahů mezi jednotlivými účastníky včetně doporučení, jak s hodnotami pracovat. <strong> kontaktuje nás.<strong> ',
    image2: 'partner_graf_830.png',
    image2Mobile: 'grafy_PRESENTING640X.png'
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #456C8A 0%, #2F4A62 99.92%)',
    heading: 'Jaké žebříčky lze pro firemní prostředí vytvářet',
    subheading: 'Klíčová je otázka!',
    linkText: ''
  },
  section12: DefaultSection12,
  section13: DefaultSection13
};

const Page200: PageProps = {
  section1: {
    header: '<h3 textAlign="center">Jsem radost</h3><br></br>',
    backgroundImg: 'joy_O.jpg',
    backgroundImgThinner: '',
    backgroundImg2: 'Presenting_hodnoty_bareeevnost.png',
    backgroundImg2Mobile: 'radost.jpg',
    backgroundImg2Tablet: 'radost.jpg',
    gradient: {
      color1: 'rgba(153, 115, 191, 0.5)',
      color2: 'rgba(84, 50, 117, 0.5)',
      color3: 'rgba(153, 115, 191, 0.5)',
      color4: 'rgba(84, 50, 117, 0.5)',
      speed: '14s'
    },
    img: 'rad142.png',
    imgMobile: 'rad142.png',
    imgThinner: 'joy_O.jpg',
    imgThinnerMobile: 'joy_O.jpg',
    subtitle: '... a ráda na tebe působím na tebe všemi smysly',
    button: {
      text: 'Napište nám',
      background: '#FFC7BA',
      icon: 'ladder.png'
    },
    text: '',
    eticiaPlus: true,
  },
  section2: {
    bgColor: 'radial-gradient(circle at top, rgba(134,128,169,1) 0%, rgba(134,128,169,1) 100%)',
    backgroundImg: '0_Me_hodnoty_o_hodnotach.png',
    header: '<span style="color: #F9F5DC"></span>',
    subheader: '<span style="color: #F9F5DC">Naše radost je s námi už o narození</h1><em><p text align="center"></span>',
    subtitle: '<span style="color: #F9F5DC">Mít jasné cíle, vznešené ideály a pevný záměr je důležité. Ale samo o sobě to úspěch našeho konání ještě nezaručí. Abychom došli k jakémukoli cíli, potřebujeme znát cestu, mít určité schopností a získat dovedností, které nám pomohou překonat překážky, na které narazíme. Najdi si vhodné pomocníky mezi hodnotami a vyber ty, na které považuješ za dobré se na své cestě spolehnout.</span>',
    downtext: ''
  },
  section4a: {
    backgroundColor: 'radial-gradient(circle at top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    image: '',
    text: '<span style="color: #F9F5DC">Přirozeností člověka je v hledání radosti. Jan Werich</span>'
  },
  section6: {
    backgroundColor: 'radial-gradient(circle, #746D9C 0%, #746D9C 100%)',
    image: 'ikona_univerz.png',
    header: 'Několik zajímavostí o radosti',
    subtitle: '<span style="color: #F9F5DC">Každá z hodnot má své. O radosti se můžeš z těchto krátkých příspěvků dozvědět něco nového a inspirativního.</strong>',
    image2: '800_cvicna_radost.png',
    image2Mobile: '800_cvicna_radost.png'
  },
  section8: {
    backgroundColor: 'radial-gradient(circle, #4d6AA1 0%, #4d6AA1 100%)',
    heading: 'Chceš o svých hodnotách zjistit víc?',
    subheading: 'Vytvoř si některý z 12 žebříčků a zjisti co je pro tebe opravdu důležité',
    linkText: ''
  },
  section9: DefaultSection9,
  section10: DefaultSection10,
  section12: DefaultSection12,
  section13: DefaultSection13
};


export const Pages: PageProps[] = [
  Page0, Page1, Page2, Page3, Page4, Page5, Page6, Page7, Page8, Page9, Page10, Page11, Page12, Page13, Page14,
  Page15, Page16, Page17, Page18, Page19, Page20, Page21, Page22, Page23, Page24, Page25, Page26, Page27, Page28,
  Page29, Page30, Page31, Page32, Page33, Page34, Page35, Page36, Page37, Page38, Page39, Page40, Page41, Page42,
  Page43, Page44, Page45, Page46,  Page47, Page48, Page49, Page50, Page51, Page52, Page53, Page54, Page55, Page200
];

