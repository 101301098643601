export interface ProductProps {
  length: string;
  price: number;
  ladders: string;
  tree: boolean;
  text1: string;
  text2?: string;
  link: string;
  color: string;
  backgroundColor: string;
  prepare?: boolean;
  disabledFor?: string;
}

export const products: ProductProps[] = [
  {
    length: 'na 1 rok',
    price: 121,
    ladders: '+ 1 žebříček',
    tree: false,
    text1: '+1x životní hodnoty s kompletním vyhodnocením',
    text2: 'něco dalšího navíc',
    link: '1rok',
    color: '#d3a5c9',
    backgroundColor: 'rgb(112, 105, 154)',
    disabledFor: 'navzdy'
  },
  {
    length: 'na 3 roky',
    price: 249,
    ladders: '+ 3 žebříčky',
    tree: false,
    text1: '+3x životní hodnoty s kompletním vyhodnocením',
    text2: 'něco dalšího navíc',
    link: '3roky',
    color: '#86d5b6',
    backgroundColor: 'rgb(103,96,143)',
    disabledFor: 'navzdy'
  },
  {
    length: 'navždy',
    price: 777,
    ladders: '+ 6 žebříčků',
    tree: true,
    text1: '+6x životní hodnoty s kompletním vyhodnocením',
    link: 'navzdy',
    color: '#f4ae7c',
    backgroundColor: 'rgb(126, 118, 171)',
    prepare: true
  }
];