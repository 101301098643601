import styled, { css } from 'styled-components';
import { darkBaseRGB, darkenGreen, gray, primaryGreen, violet, darkenViolet } from '../../../../theme/colors';

export const Card = styled.div<{ color: 'green' | 'violet' }>`
  margin-bottom: 3rem;
  padding: 1.5rem;
  border-radius: 0.4rem;
  box-shadow: 0 5px 10px 0 rgba(${darkBaseRGB}, 0.4);
  color: ${gray};
  text-align: center;

  ${props =>
    props.color === 'green' &&
    css`
      background: radial-gradient(circle at top, ${primaryGreen} 0%, ${darkenGreen} 100%);
    `};

  ${props =>
    props.color === 'violet' &&
    css`
      background: radial-gradient(circle at top, ${violet} 0%, ${darkenViolet} 100%);

      svg {
        path {
          fill: #fff;
        }
      }
    `};

  img {
    max-width: 8rem;
    margin: 3rem auto 1rem;
  }

  strong {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    min-height: 3.6rem;

    span {
      font-size: 1.8rem;
      line-height: 1.8rem;
    }
  }

  span {
    display: block;
    font-size: 1.4rem;
    line-height: 1.7rem;
  }
`;
