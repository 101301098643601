import * as React from 'react';
import { Col, Grid, Row } from 'react-styled-flexboxgrid';
import Fade from 'react-reveal/Fade';
import { Heading2 } from '../../../../atoms/Headings';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { StyledProfilePage } from '../styles';
import { FormInput } from '../../../../atoms/Form/FormInput';
import { SecondaryButtonButton } from '../../../../atoms/Buttons';
import Form from '../../../../molecules/Form';
import { StyledLinkBorder } from '../../../../atoms/Buttons/styles';
import { createUrl } from '../../../routes';
import { useState } from 'react';
import withUser from 'components/molecules/withRedux/withUser';
import { IWithUser } from '../../../../molecules/withRedux/withUser';
import { changeUser, changeUserData } from './actions';
import { fetchUserInfo } from 'store/User/UserActions';

const Nick: React.FC<IWithUser> = ({ user }) => {
  const [nick, setNick] = useState<string>('');
  const [appState, setAppState] = useState<{
    state: 'default' | 'error' | 'succes';
  }>({
    state: (localStorage.getItem(user.id + 'nick') as 'default' | 'error' | 'succes') || 'default',
  });
  localStorage.removeItem(user.id + 'nick');

  return (
    <StyledProfilePage>
      <Grid>
        <Fade bottom ssrReveal duration={1000} distance={'5rem'}>
          <div>
            <Heading2>
              <FormattedMessage id='profile.settings.nick' />
            </Heading2>
          </div>

          <Row>
            <Col xs={12} md={4} mdOffset={4}>
              {appState.state === 'succes' ? (
                <FormattedMessage id='profile.settings.nick.changed' />
              ) : (
                <Form
                  onSubmit={e => {
                    e.preventDefault();
                    changeUserData(
                      changeUser(user, nick, 'nick'),
                      () => {
                        setAppState({ state: 'succes' });
                        fetchUserInfo();
                        localStorage.setItem(user.id + 'nick', 'succes');
                        location.reload();
                      },
                      () => setAppState({ state: 'error' }),
                    );
                  }}
                >
                  <FormattedMessage id='settings.error.general'>
                    {errorMessage => (
                      <FormattedMessage id='settings.nickname.new'>
                        {message => (
                          <FormInput
                            type='text'
                            text={message as string}
                            id='nick'
                            name='nick'
                            required={true}
                            onChange={value => setNick(value.target.value)}
                            error={appState.state === 'error' ? errorMessage : undefined}
                          />
                        )}
                      </FormattedMessage>
                    )}
                  </FormattedMessage>

                  <SecondaryButtonButton>
                    <FormattedHTMLMessage id='settings.nickname.change.btn' />
                  </SecondaryButtonButton>
                </Form>
              )}
            </Col>
          </Row>

          <StyledLinkBorder className='back' to={createUrl('app.profile')}>
            <FormattedMessage id='profile.settings.password.back' />
          </StyledLinkBorder>
        </Fade>
      </Grid>
    </StyledProfilePage>
  );
};

export default withUser<{}>(Nick);
