const adFormId = 1937301;

export const adformRegisterUrl =
  process.env.NODE_ENV === 'development'
    ? 'http://example.com'
    : 'https://track.adform.net/Serving/TrackPoint/' +
      '?pm=' +
      adFormId +
      '&ADFPageName=' +
      encodeURIComponent('Eticia 21 konverze (registrace)') +
      '&ADFdivider=' +
      encodeURIComponent('|') +
      '&ADFtpmode=4' +
      '&itm=' +
      JSON.stringify({ sales: '' });

export const adformVoucherUrl = (a: number) =>
  process.env.NODE_ENV === 'development'
    ? 'http://example.com'
    : 'https://track.adform.net/Serving/TrackPoint/' +
      '?pm=' +
      adFormId +
      '&ADFPageName=' +
      encodeURIComponent('Eticia 21 konverze (voucher)') +
      '&ADFdivider=' +
      encodeURIComponent('|') +
      '&ADFtpmode=4' +
      '&itm=' +
      JSON.stringify({ sales: '' && a });
