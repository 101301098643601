import styled, { css } from 'styled-components';
import { darkBaseRGB, primaryBlue, yellow, white, gray, darkBaseHEX } from '../../../theme/colors';
import {
  mediaLandscapePhone,
  mediaLgDefault,
  mediaLgMax,
  mediaMdDefault,
  mediaSmDefault,
  mediaSmMax,
} from '../../../theme/grid';
import { StyledShowMenuButton } from '../../ShowMenuButton/style';
import StyledNavigationMenu from '../../../atoms/Nav/styles';
import { bold, Fox } from '../../../theme/fonts';
import { StyledLogo } from '../../../atoms/Logo/styled';

export const Flex = styled.div`
  display: flex;
  flex-direction: column;

  ${mediaSmDefault} {
    justify-content: space-between;
  }
`;

export const BurgerFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div {
    display: flex;
    align-items: center;
  }
`;

const StyledHeader = styled.section<{ opened: boolean; inTest: boolean; themeBackground?: boolean }>`
  flex: 0 0 31rem;
  width: 31rem;
  height: calc(100vh);
  top: 0;
  //overflow-y: scroll;
  transition: margin 0.3s;
  overflow: hidden;
  position: relative;

  @media (max-width: 1620px) {
    position: fixed;
    z-index: 1002;
  }

  & > div {
    position: fixed;
    background-color: #1d1c21;
    z-index: 1001;
    width: 31rem;
    height: 100%;
    padding: 8rem 2rem 2rem;
    overflow-y: auto;

    ${mediaMdDefault} {
      z-index: 80;
      padding-top: 10rem;
     
    }
  }

  ${props =>
    !props.opened &&
    css`
      margin-left: -31rem;
    `};

  ${props =>
    props.inTest &&
    css`
      position: absolute;

      & > div {
        background: ${darkBaseHEX};
        padding-left: 2rem;
        padding-right: 2rem;
      }
    `};

  ${BurgerFlex} {
    position: absolute;
    display: none;
    top: 0;
    height: 6rem;
    align-items: center;

    ${props =>
    !props.inTest &&
      css`
        display: flex;
      `};
  }

  ul {
    li {
      a {
        &.active {
          opacity: 1;
          font-weight: ${bold};
          color: #fff;

          svg {
            path {
              fill: ${yellow};
            }
          }
        }
      }
    }
  }

  .deco {
    /* display: none;

    ${mediaSmDefault} { */
      display: flex;
      position: absolute;
      justify-content: center;
      align-items: center;
      right: 0;
      top: 50%;
      width: 4rem;
      height: 8rem;
      background: ${props => props.theme.primary};
      transform: translateY(-50%);
      z-index: 1000;
      border-bottom-left-radius: 4rem;
      border-top-left-radius: 4rem;
      transition: background 0.3s, opacity 0.3s;
      cursor: pointer;
      opacity: 0.5;

      svg {
        height: 2.6rem;
      }
    /* } */

    &:hover {
      background: ${props => props.theme.darken};
      opacity: 1;
    }
  }
`;

export const Eticoins = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 1rem 1rem;
  margin-top: 3rem;
  border: 2px solid ${primaryBlue};
  border-radius: 3.4rem;

  ${mediaLgDefault} {
    padding: 1rem 3rem;

    &:hover {
      .hover {
        opacity: 1;
      }
    }
  }

  span {
    display: inline-block;
    margin-right: 0.8rem;
    font-size: 1.5rem;
  }

  img {
    vertical-align: top;
  }

  .hover {
    position: absolute;
    margin-top: 5rem;
    left: 0;
    width: 20rem;
    text-align: center;
    font-size: 1.6rem;
    line-height: 2rem;
    opacity: 0;
    pointer-events: none;
    padding: 0.5rem 1.5rem;
    background: rgba(25, 29, 44, 0.6);
    border-radius: 4px;
    transition: opacity 300ms ease 0s;
  }
`;

export default StyledHeader;

export const StyledUnloggedUserHeader = styled.header`
  position: fixed;
  display: block;
  width: 100%;
  z-index: 100;
  background-color: rgba(${darkBaseRGB}, 0.95);
  transition: background 0.3s, transform 0.3s;

  .content {
    display: flex;
    align-items: center;
    max-width: 192rem;
    height: 6rem;
    min-height: 6rem;
    margin: 0 auto;
    padding: 0 1.5rem;
    text-align: center;

    ${mediaMdDefault} {
      padding: 0 1.5rem;
    }
  }

  &.opened {
    background-color: ${darkBaseHEX};

    .drop {
      height: calc(100vh - 6rem);

      ${mediaMdDefault} {
        height: auto;
      }
    }
  }

  ${StyledLogo} {
    img {
      ${mediaSmMax} {
        width: 8.2rem;
      }
    }

    .tag {
      position: relative;
    }
  }

  ${StyledShowMenuButton} {
    display: none;
  }

  .left-align,
  .right-align {
    display: inline-block;
    width: auto;

    ${mediaLgMax} {
      width: auto;
    }
  }

  .left-align {
    width: 100%;
    text-align: left;

    ${mediaMdDefault} {
      float: left;
      width: 30rem;
      min-width: 20rem;
    }

    ${mediaLandscapePhone} {
      ${StyledShowMenuButton} {
        display: none;
      }
    }
  }

  .right-align {
    text-align: center;
    float: none;
    display: block;
    width: 100%;
    color: #fff;

    ${mediaMdDefault} {
      float: right;
      text-align: right;
      min-height: 6rem;
      justify-content: flex-end;

      a {
        display: inline-flex;
        align-items: center;
        height: 5rem !important;
        min-height: 5rem !important;
        font-size: 1.8rem;

        &:first-child {
          font-size: 1.5rem;
        }
      }
    }
  }

  .drop {
    position: absolute;
    top: 6rem;
    width: 100%;
    left: 0;
    height: 0;
    text-align: center;
    background-color: ${darkBaseHEX};
    overflow: hidden;
    transition: height 0.3s;

    .login {
      display: block;
      margin: 0 1.5rem;

      ${mediaMdDefault} {
        margin: auto;
      }

      a {
        margin-top: 2rem;
        display: block;
      }

      .nav-link {
        padding: 0;
        font-size: 1.5rem;

        ${mediaMdDefault} {
          padding: 1.5rem 0;
        }
      }
    }

    .right-align {
      margin-top: 6rem;

      ${mediaLandscapePhone} {
        bottom: 1rem;
        display: flex;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        align-content: center;

        .eticoins {
          width: auto;
        }
      }
    }

    ${mediaMdDefault} {
      position: static;
      background-color: transparent;
      height: auto;
      display: inline-block;
      width: auto;
      float: right;
      overflow: visible;

      .login {
        display: flex;
        margin: auto;
        flex-direction: row;
        align-items: center;

        a {
          margin-top: 0;
          display: inline-flex;
        }

        .nav-link {
          font-size: 1.5rem;
        }
      }

      .right-align {
        margin-top: 0;
        width: auto;
        position: static;
        display: flex;
        align-items: center;
        height: 6rem;
      }
    }

    ${mediaMdDefault} {
      width: calc(100% - 20rem);
      overflow: visible;
    }
  }

  .lang-switch {
    position: relative;
    padding: 1.05rem 1.5rem 0.75rem;
    margin: 1rem 1.5rem 0;
    border: 2px solid ${yellow};
    border-radius: 35px;

    ${mediaSmDefault} {
      margin-left: 1rem;
      margin-right: auto;
    }

    ${mediaMdDefault} {
      margin-top: 0;
    }

    svg {
      display: inline-block;
      margin-top: -3px;
      margin-left: 0.8rem;
      width: 1rem;
      height: 0.6rem;
      vertical-align: middle;
      transition: transform;
    }

    .now {
      display: block;
      cursor: pointer;
    }

    ul {
      position: absolute;
      display: block;
      padding: 1.5rem;
      margin-top: -1.5rem;
      left: 50%;
      bottom: 4.5rem;
      width: 50%;
      opacity: 0;
      color: black;
      background: #fff;
      transform: translateX(-50%);
      pointer-events: none;
      transition: opacity 0.3s;

      ${mediaMdDefault} {
        margin-top: 1.5rem;
        bottom: auto;
        width: auto;
      }
    }

    &.visible {
      ul {
        opacity: 1;
        pointer-events: auto;
      }

      svg {
        transform: rotate(180deg);
      }
    }
  }

  ${StyledNavigationMenu} {
    display: inline-block;
    margin-top: 0;

    ${mediaSmDefault} {
      margin-top: 3rem;
    }

    ${mediaMdDefault} {
      margin-top: 0;
    }

    li {
      display: block;
      margin: 0 2.5rem;

      ${mediaMdDefault} {
        display: inline-block;
      }

      svg {
        display: none;
      }

      a {
        position: relative;
        display: block;
        padding: 1rem 0;
        font-family: ${Fox};
        font-weight: 600;

        ${mediaMdDefault} {
          display: flex;
        }

        ${mediaMdDefault} {
          padding: 2.2rem 0;
        }

        &.active {
          background-image: url("/img/ico/Polygon.svg");
          background-repeat: no-repeat;
          background-size: 4rem 4rem;
          background-position: center;
          opacity: 1;
          color: ${white};

          ${mediaMdDefault} {
            &:after {
              content: "";
              position: absolute;
              bottom: 0.2rem;
              height: 0.2rem;
              width: 5rem;
              left: 50%;
              transform: translateX(-50%);
              border-bottom: 2px solid ${gray};
            }
          }
        }
      }
    }
  }

  .login {
    display: flex;
    flex-direction: row;
    align-items: center;

    ${mediaMdDefault} {
      margin-top: 0;

      & > a {
        &:first-child {
          margin-right: 1.5rem;
        }
      }
    }
  }
`;

export const OldBurger = styled.div<{ isOpen: boolean }>`
  display: inline-block;
  position: absolute;
  vertical-align: middle;
  top: 2.2rem;
  right: 1.5rem;

  &:before {
    content: "Menu";
    display: block;
    position: absolute;
    margin-left: -4.5rem;
    font-size: 1.4rem;
    line-height: 1.7rem;
  }

  ${mediaMdDefault} {
    display: none;
  }

  span {
    display: block;
    margin-top: 0.5rem;
    width: 2rem;
    height: 0.2rem;
    border-radius: 0.2rem;
    background: #fff;
    transition: margin 0.3s;

    &:first-child {
      margin-top: 0;
    }

    ${props =>
    props.isOpen &&
      css`
        margin-top: 0.7rem;

        &:first-child {
          margin-top: -0.3rem;
        }
      `};
  }
`;
