import * as React from 'react';
import { IPopupState } from '../../../store/Popup/index';
import { StyledPopupBackground } from '../../atoms/Popups/styles';
import popupContext from './popupContext';

interface IMainPopup {
  popup: IPopupState;
  close: () => unknown;
}

const MainPopup: React.FC<IMainPopup> = ({ popup: { popup: Popup, resolve }, close }) => {
  return (
    <>
      {Popup && (
        <popupContext.Provider value={close}>
          <StyledPopupBackground>
            <Popup
              resolve={(result?: string) => {
                resolve && resolve(result);
                close();
              }}
            />
          </StyledPopupBackground>
        </popupContext.Provider>
      )}
    </>
  );
};

export default MainPopup;
