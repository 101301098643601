import { DropTarget, DropTargetConnector, DropTargetMonitor, DropTargetSpec } from 'react-dnd';
import { ICardValue } from '../../data';
import CardPlaceholder, { IProps } from './component';
import { GAME_CARD } from '../DraggableCard';

const cardTarget: DropTargetSpec<IProps> = {
  drop: (props: IProps, monitor: DropTargetMonitor) =>
    props.selectValue((monitor.getItem().value as ICardValue).value.id, props.index),
};

const collect = (connect: DropTargetConnector, monitor: DropTargetMonitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  isOverCurrent: monitor.isOver({ shallow: true }),
  canDrop: monitor.canDrop(),
  itemType: monitor.getItemType(),
});

export default DropTarget(GAME_CARD, cardTarget, collect)(CardPlaceholder);
