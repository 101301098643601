import * as React from 'react';
import styled from 'styled-components';
import { Col } from 'react-styled-flexboxgrid';
import { StyledTestInfoCard } from '../../../atoms/Cards/styles';
import { FormattedHTMLMessage } from 'react-intl';
import { mediaLandscapePhoneAndMd } from '../../../theme/grid';
import BasePopup from '../../../atoms/Popups/FAQPopup/BasePopup';
import FAQPopup from '../../../atoms/Popups/FAQPopup';
import WithChildPopup from '../../../atoms/Popups/FAQPopup/WithChildPopup';
import ImgSlider from '../../../organisms/Slider';

export interface ITestInfoCard {
  icon: string;
  textBottom: string;
  textTop?: string;
  color?: 'red' | 'blue' | 'green' | 'yellow' | 'black';
}

export enum TestInfoCardState {
  def = 'def',
  big = 'big',
  simple = 'simple',
}

const StyledInfoCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  @media ${mediaLandscapePhoneAndMd} {
    margin-bottom: -3.2rem;
  }

  .main-popup-button {
    margin-top: 0;
    height: 100%;
  }

  ${Col as any} {
    align-items: stretch;
    margin-bottom: 1.5rem;

    &:nth-child(odd) {
      padding-right: 0.8rem;
    }

    &:nth-child(even) {
      padding-left: 0.8rem;
    }

    @media ${mediaLandscapePhoneAndMd} {
      margin-bottom: 3.2rem;

      &:nth-child(odd) {
        padding-right: 1.6rem;
      }

      &:nth-child(even) {
        padding-left: 1.6rem;
      }
    }
  }
`;

interface ICards {
  state: TestInfoCardState;
  cards: ITestInfoCard[];
  faq?: boolean;
}

const TestInfoCard: React.FC<ICards> = ({ cards, state, faq }) => {
  const renderedInfoCard = cards.map((card, i) => (
    <Col xs={6} sm={6} md={3} lg={6} key={i}>
      {faq ? (
        <FAQPopup
          popup={() => (
            <BasePopup color={card.color}>
              {card.color === 'green' ? (
                <WithChildPopup
                  img={'/img/ico/tests/' + card.icon}
                  name={'game.cards.' + card.color}
                  texts={1}
                  position={1}
                >
                  <ImgSlider
                    imgUrls={['/img/result/anketa_1.png', '/img/result/anketa_2.png', '/img/result/anketa_3.png']}
                  />
                </WithChildPopup>
              ) : (
                <WithChildPopup
                  img={'/img/ico/tests/' + card.icon}
                  name={'game.cards.' + card.color}
                  texts={1}
                  position={0}
                />
              )}
            </BasePopup>
          )}
        >
          <StyledTestInfoCard key={i} className={state} color={card.color}>
            {card.textTop && (
              <span className='text-top'>
                <FormattedHTMLMessage id={card.textTop} />
              </span>
            )}
            <img src={'/img/ico/tests/' + card.icon} />
            <span className='text-bottom'>
              <FormattedHTMLMessage id={card.textBottom} />
            </span>
          </StyledTestInfoCard>
        </FAQPopup>
      ) : (
        <StyledTestInfoCard key={i} className={state} color={card.color}>
          {card.textTop && (
            <span className='text-top'>
              <FormattedHTMLMessage id={card.textTop} />
            </span>
          )}
          <img src={'/img/ico/tests/' + card.icon} />
          <span className='text-bottom'>
            <FormattedHTMLMessage id={card.textBottom} />
          </span>
        </StyledTestInfoCard>
      )}
    </Col>
  ));
  return <StyledInfoCards>{renderedInfoCard}</StyledInfoCards>;
};

export default TestInfoCard;
