import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Footer } from '../../../../organisms/Layout/Footer';
import { Row, Col, Grid } from 'react-styled-flexboxgrid';
import { StyledSupport } from './styles';
import { IThemeProps, useTheme } from '../../../../templates/General/ThemeContext';
import Fade from 'react-reveal/Fade';
import { Heading1 } from '../../../../atoms/Headings';
import ShirtForm from '../Join/ShirtForm';

interface ISupportState {
  slideIndex: number;
}

interface ISlide {
  img: string;
  head: string;
}

const slides: ISlide[] = [
  {
    img: '/img/merch/cerne.jpg',
    head: 'support.slide1.head',
  },
  {
    img: '/img/merch/modre.jpg',
    head: 'support.slide2.head',
  },
];

class Support extends React.Component<IThemeProps, ISupportState> {
  public state: ISupportState = {
    slideIndex: Math.floor(Math.random() * 2),
  };

  private timer: NodeJS.Timeout | undefined;

  public componentDidMount() {
    this.timer = setInterval(
      () =>
        this.setState({
          slideIndex: (this.state.slideIndex + 1) % slides.length,
        }),
      5000,
    );
  }

  public componentWillUnmount() {
    clearInterval(this.timer!);
  }

  public render() {
    return (
      <StyledSupport>
        <Grid>
          <Row>
            <Col xs={12} md={4}>
              <Heading1 className={'only-mobile'}>
                <FormattedMessage id={'support.heading.step1'} />
              </Heading1>

              <div className={'slider-box'}>
                <Fade ssrReveal bottom duration={1000} distance={'2rem'} spy={this.state.slideIndex}>
                  <div className='slide'>
                    <strong>
                      <FormattedMessage id={slides[this.state.slideIndex].head} />
                    </strong>
                    <img src={slides[this.state.slideIndex].img} alt='' />
                  </div>
                </Fade>

                <div className='dots'>
                  <ul>
                    {slides.map((_, i) => (
                      <li className={this.state.slideIndex === i ? 'active' : ''} key={i}>
                        <button onClick={() => this.onClick(i)} />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </Col>

            <Col xs={12} md={8} lg={7} lgOffset={1}>
              <ShirtForm
                updateSlider={(type: number) => {
                  clearInterval(this.timer!);
                  this.setState({ slideIndex: type });
                }}
              />
            </Col>
          </Row>
        </Grid>

        <Footer />
      </StyledSupport>
    );
  }

  private onClick = (i: number) => {
    this.setState({ slideIndex: i });
    clearInterval(this.timer!);
    this.timer = setInterval(
      () =>
        this.setState({
          slideIndex: (this.state.slideIndex + 1) % slides.length,
        }),
      5000,
    );
  };
}

export default useTheme(Support);
