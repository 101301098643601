import * as React from 'react';
import { createUrl } from '../routes';
import {  } from '../../atoms/Headings';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { StyledLink } from '../../atoms/Buttons/styles';
import wrapIntl, { IWrapIntl } from '../../atoms/wrapIntl';
import { sendEmail } from './actions';
import { Box, Flex, Img, Heading1, Typography, Link } from '@xcorejs/ui';
import InlineFormInput from 'components/atoms/InlineForm/InlineFormInput';
import { PrimaryButton } from 'components/components/Button';

interface IForgottenPasswordProps {
  addInfo: (msg: string) => any;
}

interface IForgottenPasswordState {
  email: string;
  error: string;
  [key: string]: any;
}

const FoPassword: React.FC<IForgottenPasswordProps & IWrapIntl> = (props) => {

  const [state, setState] = React.useState<IForgottenPasswordState>({
    email: '',
    error: '',
  });


  const handleInputChange = (e: any) => {
    setState(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const displayError = (error: string) => {
    setState({ ...state, error });
  };

  const onEmailSent = () => {
    setState({
      ...state,
      email: '',
      error: '',
    });
    props.addInfo(props.formatMsg('forgottenPassword.emailSent'));
  };

  const clickEmail = (e: any) => {
    e.preventDefault();
    sendEmail(state.email, onEmailSent, displayError);
  };

  return (
    <Box
      bg="url('/img/global.jpg')"
      backgroundPosition='center bottom'
      backgroundSize='cover'
      {...{ backgroundAttachment: 'fixed' }}
      minHeight='100vh'
      top={0}
      pt='6rem'
      pb='3.5rem'
      mt='-6rem'
      mb='-3.5rem'
    >
      <Flex flexDirection='column' mx='auto'>
        <Img src='/img/logo-big.svg' className='logo' height='40px' alt='Eticia' mb='3rem' /> 

        <div className='heading'>
          <Heading1 fontSize='3.2rem' lineHeight='3.9rem' fontWeight={400} textAlign='center'>
            <FormattedMessage id={'login.forgottenPassword'} />
          </Heading1>

          <Typography mt='1.6rem'>
            <img
              src='/img/ico/help-small.svg'
              alt='pomoc'
              style={{
                position: 'relative',
                top: '20px',
                right: '10px'
              }} />
            <FormattedHTMLMessage id={'forgottenPassword.help'} />
          </Typography>
        </div>

        <Box maxWidth='41.6rem' mx='auto'>
          <form onSubmit={clickEmail} style={{ width: '416px' }}>
            <Flex flexDirection='column' alignItems='center' mt='40px' style={{ gap: '30px' }}>
              <label style={{ width: '100%' }}>
                <Typography mb='0.8rem' textAlign='center' color='rgba(255, 255, 255, 0.6)'>
                    E-mail
                </Typography>
                <InlineFormInput
                  width='100%'
                  name='email'
                  value={state.email}
                  onChange={handleInputChange}
                  required
                />
              </label>                
                
              <PrimaryButton 
                type='submit' 
                onClick={clickEmail} 
                bg='rgba(12, 107, 75, 0.9)'
                color='#fff'
                _hover={{ color: '#000' }}
                width='fit-content'
              >                    
                <FormattedMessage id={'restorePassword'} />
              </PrimaryButton>                  

              <StyledLink to={createUrl('login')}>
                <Link fontSize='1.4rem' v='underline'>
                  <FormattedMessage id={'backToLogin'} />
                </Link>
              </StyledLink>
            </Flex>
          </form>
        </Box>
      </Flex>
    </Box>
  );
};

class ForgottenPassword extends React.Component<IForgottenPasswordProps & IWrapIntl, IForgottenPasswordState> {
  public state: IForgottenPasswordState = {
    email: '',
    error: '',
  };

  public render() {
    return (
      <Box
        bg="url('/img/global.jpg')"
        backgroundPosition='center bottom'
        backgroundSize='cover'
        {...{ backgroundAttachment: 'fixed' }}
        minHeight='100vh'
        top={0}
        pt='6rem'
        pb='3.5rem'
        mt='-6rem'
        mb='-3.5rem'
      >
        <Flex flexDirection='column' mx='auto'>
          <Img src='/img/logo-big.svg' className='logo' height='40px' alt='Eticia' mb='3rem' /> 

          <div className='heading'>
            <Heading1 fontSize='3.2rem' lineHeight='3.9rem' fontWeight={400} textAlign='center'>
              <FormattedMessage id={'login.forgottenPassword'} />
            </Heading1>

            <Typography mt='1.6rem'>
              <img
                src='/img/ico/help-small.svg'
                alt='pomoc'
                style={{
                  position: 'relative',
                  top: '20px',
                  right: '10px'
                }} />
              <FormattedHTMLMessage id={'forgottenPassword.help'} />
            </Typography>
          </div>

          <Box maxWidth='41.6rem' mx='auto'>
            <form onSubmit={this.sendEmail} style={{ width: '416px' }}>
              <Flex flexDirection='column' alignItems='center' mt='40px' style={{ gap: '30px' }}>
                <label style={{ width: '100%' }}>
                  <Typography mb='0.8rem' textAlign='center' color='rgba(255, 255, 255, 0.6)'>
                      E-mail
                  </Typography>
                  <InlineFormInput
                    width='100%'
                    name='email'
                    value={this.state.email}
                    onChange={this.handleInputChange}
                    required
                  />
                </label>                
                  
                <PrimaryButton 
                  type='submit' 
                  onClick={this.sendEmail} 
                  bg='rgba(12, 107, 75, 0.9)'
                  color='#fff'
                  _hover={{ color: '#000' }}
                  width='fit-content'
                >                    
                  <FormattedMessage id={'restorePassword'} />
                </PrimaryButton>                  

                <StyledLink to={createUrl('login')}>
                  <Link fontSize='1.4rem' v='underline'>
                    <FormattedMessage id={'backToLogin'} />
                  </Link>
                </StyledLink>
              </Flex>
            </form>
          </Box>
        </Flex>
      </Box>
    );
  }

  private sendEmail = (e: any) => {
    e.preventDefault();
    sendEmail(this.state.email, this.onEmailSent, this.displayError);
  };

  private handleInputChange = (e: any) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  private displayError = (error: string) => {
    this.setState({ ...this.state, error });
  };

  private onEmailSent = () => {
    this.setState({
      ...this.state,
      email: '',
      error: '',
    });
    this.props.addInfo(this.props.formatMsg('forgottenPassword.emailSent'));
  };
}

export default wrapIntl(ForgottenPassword);
