import { CompareConfigMatrix } from 'components/components/Compare/data';
import { IStatistic, IValueWithName } from 'components/molecules/StatisticsSlider/data';
import { ISurveyResult } from 'components/pages/App/Journal/Activity/component';
import { ShopSummaryResponse } from 'components/pages/App/Shop/data';
import { IJournal, ITestResultResponse } from 'components/pages/App/TestIntro/data';
import { GAME_STATUS, IGameStatus } from 'config/api/game';
import { SURVEY_RESULTS } from 'config/api/surveyInternal';
import { IValueTestResultDetail, VALUE_TEST_RESULTS_TRIBAL } from 'config/api/valueTestResult';
import { COMPARE_MATRIX, CURRENT_PROFILE, CURRENT_PROFILE_DETAIL, JOURNAL, LIFE_VALUES, LIFE_VALUES_DETAIL, SEMAPHORE, SHOP_SUMMARY, STATISTICS_GROUP_MATCH_PROFILE, STATISTICS_GROUP_MATCH_TEST, STATISTICS_LIFE_VALUES, TESTS_RESULTS_DETAIL } from 'config/apiRoutes';
import { ILifeValue, ILifeValueDetail } from 'store/Entities/LifeValues';
import { IUserInfo } from 'store/User';
import { queryKeys } from './queryKeys';
import { useQueryFetch } from './useQueryFetch';

export const useFetchLifeValueDetail = (value: string, enabled = true) => {
  return useQueryFetch<ILifeValueDetail>({
    queryKey: [queryKeys.LIFE_VALUES_DETAIL, value],
    path: LIFE_VALUES_DETAIL(value),
    enabled,
  });
};

export const useFetchGameStatus = (enabled = true) => {
  return useQueryFetch<IGameStatus>({
    queryKey: [queryKeys.GAME_STATUS],
    path: GAME_STATUS,
    enabled,
  });
};

export const useFetchJournal = (enabled = true) => {
  return useQueryFetch<IJournal>({
    queryKey: [queryKeys.JOURNAL],
    path: JOURNAL,
    enabled,
  });
};

export const useFetchSurvey = (id: string, enabled = true) => {
  return useQueryFetch<ISurveyResult[]>({
    queryKey: [queryKeys.SURVEY_RESULTS, id],
    path: `${SURVEY_RESULTS}?surveyId=${id}`,
    enabled,
  });
};

export const useFetchStatLifeValues = (enabled = true) => {
  return useQueryFetch<IValueWithName[]>({
    queryKey: [queryKeys.STATISTICS_LIFE_VALUES],
    path: STATISTICS_LIFE_VALUES,
    enabled,
  });
};

export const useFetchStatGroupMatchTest = (resultId: string, enabled = true) => {
  return useQueryFetch<IStatistic[]>({
    queryKey: [queryKeys.STATISTICS_GROUP_MATCH_TEST, resultId],
    path: STATISTICS_GROUP_MATCH_TEST(resultId),
    enabled,
  });
};

export const useFetchStatGroupMatchProfile = (enabled = true) => {
  return useQueryFetch<IStatistic[]>({
    queryKey: [queryKeys.STATISTICS_GROUP_MATCH_PROFILE],
    path: STATISTICS_GROUP_MATCH_PROFILE,
    enabled,
  });
};

export const useFetchUserProfile = (enabled = true) => {
  return useQueryFetch<IUserInfo>({
    queryKey: [queryKeys.CURRENT_PROFILE],
    path: CURRENT_PROFILE,
    enabled,
  });
};

export const useFetchUserProfileDetail = (enabled = true) => {
  return useQueryFetch<IUserInfo>({
    queryKey: [queryKeys.CURRENT_PROFILE_DETAIL],
    path: CURRENT_PROFILE_DETAIL,
    enabled,
  });
};

export const useFetchTestResultDetail = (id: string, enabled = true) => {
  return useQueryFetch<ITestResultResponse>({
    queryKey: [queryKeys.TEST_RESULT_DETAIL, id],
    path: TESTS_RESULTS_DETAIL(id),
    enabled,
  });
};

export const useFetchCompareMatrix = (enabled = true) => {
  return useQueryFetch<CompareConfigMatrix[]>({
    queryKey: [queryKeys.COMPARE_MATRIX],
    path: COMPARE_MATRIX,
    enabled,
  });
};

export const useFetchShopSummary = (enabled = true) => {
  return useQueryFetch<ShopSummaryResponse>({
    queryKey: [queryKeys.SHOP_SUMMARY],
    path: SHOP_SUMMARY,
    enabled,
  });
};

export const useFetchSemaphore = (testResultId: string, enabled = true) => {
  return useQueryFetch<number[]>({
    queryKey: [queryKeys.SEMAPHORE, testResultId],
    path: SEMAPHORE(testResultId),
    enabled,
  });
};

export const useFetchTribalValueTestResults = (enabled = true) => {
  return useQueryFetch<IValueTestResultDetail>({
    queryKey: [queryKeys.VALUE_TEST_RESULTS_TRIBAL],
    path: VALUE_TEST_RESULTS_TRIBAL,
    enabled,
  });
};

export const useFetchLifeValues = (enabled = true) => {
  return useQueryFetch<ILifeValue[]>({
    queryKey: [queryKeys.LIFE_VALUES],
    path: LIFE_VALUES,
    enabled,
  });
};