import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { PrimaryButton } from '../../Buttons';
import popupContext from '../../../organisms/MainPopup/popupContext';
import withPopups, { IWithPopups } from '../../../molecules/withRedux/withPopups';
import { PopupComponent } from '../../../../store/Popup';
import { ClickableText } from './data';
import ExternalSVG from '../../ExternalSVG';
import { ReactElement } from 'react';

interface Props {
  img?: string;
  svg?: ReactElement<{}>;
  name: string;
  texts: number;
  label?: string;
  nextPopup?: PopupComponent;
}

const MultiStatePopup: React.FC<Props & IWithPopups> = ({ img, svg, name, texts, label, nextPopup, popups }) => {
  const close = React.useContext(popupContext);
  return (
    <div>
      {img && (
        <span key='img'>
          <ExternalSVG src={img} />
        </span>
      )}

      {svg && (
        <span key='img'>
          <div className={'external-svg'}>{svg}</div>
        </span>
      )}

      <h2>
        <FormattedMessage id={'faq.' + name + '.title'} />
      </h2>

      {Array(texts)
        .fill(null)
        .map((_, index) => (
          <ClickableText key={index} name={name} index={index} />
        ))}

      {label && (
        <div className='faq-next'>
          <h3>
            <FormattedMessage id={'faq.next'} />
          </h3>

          <PrimaryButton
            onClick={() => {
              close() && popups.custom(nextPopup!);
            }}
          >
            <FormattedMessage id={'faq.' + label + '.btn'} />
          </PrimaryButton>
        </div>
      )}
    </div>
  );
};

export default withPopups(MultiStatePopup);
