import * as React from 'react';

const SVGgoogle: React.FC = () => {
  return (
    <svg width='31px' height='32px' viewBox='0 0 31 32' version='1.1'>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' transform='translate(-1050.000000, -320.000000)'>
        <g transform='translate(535.000000, 301.000000)' fillRule='nonzero'>
          <g transform='translate(440.000000, 0.000000)'>
            <g transform='translate(75.000000, 19.000000)'>
              <path
                d='M6.48181818,16 C6.48181818,14.9838222 6.64879544,14.0096 6.94681816,13.0958222 L1.73036364,9.06951113 C0.713704544,11.1559111 0.140909091,13.5068445 0.140909091,16 C0.140909091,18.4910222 0.713,20.8405333 1.72825,22.9255111 L6.94188639,18.8913778 C6.64668184,17.9818667 6.48181818,17.0112 6.48181818,16'
                fill='#FBBC05'
              />
              <path
                d='M15.6409091,6.75555553 C17.825,6.75555553 19.7977273,7.5377778 21.3477273,8.8177778 L25.8568182,4.26666667 C23.1090909,1.84888889 19.5863636,0.355555555 15.6409091,0.355555555 C9.51559089,0.355555555 4.25122727,3.89617778 1.73036364,9.06951113 L6.94681816,13.0958222 C8.14877272,9.408 11.5749773,6.75555553 15.6409091,6.75555553'
                fill='#EB4335'
              />
              <path
                d='M15.6409091,25.2444445 C11.5749773,25.2444445 8.14877272,22.592 6.94681816,18.9041778 L1.73036364,22.9297778 C4.25122727,28.1038222 9.51559089,31.6444445 15.6409091,31.6444445 C19.4215,31.6444445 23.0308864,30.2876445 25.7398636,27.7454222 L20.7883182,23.8762667 C19.3912046,24.7658667 17.6319546,25.2444445 15.6409091,25.2444445'
                fill='#34A853'
              />
              <path
                d='M30.4363636,16 C30.4363636,15.0755555 30.2954546,14.08 30.0840909,13.1555555 L15.6409091,13.1555555 L15.6409091,19.2 L23.9545454,19.2 C23.5388636,21.2608 22.4073636,22.8451555 20.7883182,23.8762667 L25.7398636,27.7454222 C28.5855227,25.0759111 30.4363636,21.0993778 30.4363636,16'
                fill='#4285F4'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SVGgoogle;
