import { BlueTheme, PinkTheme, MidnightTheme, GreenTheme, VioletTheme, KidsTheme, GrayTheme, EmeraldTheme } from 'components/theme/colors';

export type InterestingCardProp = {
  i18nId: string;
  backgroundColor: string;
  groupId: string;
  icon: string;
}

export type InterestingCardsProps = {
  'personal': InterestingCardProp[];
  'multiple': InterestingCardProp[];
};

export const interestingCards: InterestingCardsProps = {
  'personal': [
    {
      i18nId: 'personalValues.valuesInTime',
      backgroundColor: 'rgb(103, 128, 175)',
      groupId: '1',
      icon: '/shared/compare/4471db8e-a8d4-405d-b5ff-a50cebe7212c.png'
    },
    {
      i18nId: 'personalValues.oppositeAttraction',
      backgroundColor: PinkTheme.primary,
      groupId: '2',
      icon: '/shared/compare/4471db8e-a8d4-405d-b5ff-a50cebe7212c.png'
    },
    {
      i18nId: 'personalValues.wellbeing',
      backgroundColor: MidnightTheme.primary,
      groupId: '3',
      icon: '/shared/compare/4471db8e-a8d4-405d-b5ff-a50cebe7212c.png'
    },
    {
      i18nId: 'personalValues.valuesNow',
      backgroundColor: GreenTheme.primary,
      groupId: '4',
      icon: '/shared/compare/4471db8e-a8d4-405d-b5ff-a50cebe7212c.png'
    },
    {
      i18nId: 'personalValues.actualWishes',
      backgroundColor: VioletTheme.primary,
      groupId: '5',
      icon: '/shared/compare/4471db8e-a8d4-405d-b5ff-a50cebe7212c.png'
    },
  ],
  'multiple': [
    {
      i18nId: 'valuesForTwo.commonValues',
      backgroundColor: PinkTheme.primary,
      groupId: '1',
      icon: '/shared/compare/4471db8e-a8d4-405d-b5ff-a50cebe7212c.png'
    },
    {
      i18nId: 'valuesForTwo.parentsAndChildren',
      backgroundColor: KidsTheme.primary,
      groupId: '2',
      icon: '/shared/compare/4471db8e-a8d4-405d-b5ff-a50cebe7212c.png'
    },
    {
      i18nId: 'valuesForTwo.whatAppreciate',
      backgroundColor: VioletTheme.primary,
      groupId: '3',
      icon: '/shared/compare/4471db8e-a8d4-405d-b5ff-a50cebe7212c.png'
    },
    {
      i18nId: 'valuesForTwo.ourWellbeing',
      backgroundColor: MidnightTheme.primary,
      groupId: '4',
      icon: '/shared/compare/4471db8e-a8d4-405d-b5ff-a50cebe7212c.png'
    },
    {
      i18nId: 'valuesForTwo.ourSuperpowers',
      backgroundColor: GrayTheme.primary,
      groupId: '5',
      icon: '/shared/compare/4471db8e-a8d4-405d-b5ff-a50cebe7212c.png'
    },
    {
      i18nId: 'valuesForTwo.multipleCard6',
      backgroundColor: EmeraldTheme.primary,
      groupId: '6',
      icon: '/shared/compare/4471db8e-a8d4-405d-b5ff-a50cebe7212c.png'
    },
  ],
};