import * as React from 'react';
import BasePopup from '../BasePopup';
import MultiStatePopup from '../MultiStatePopup';
import WithChildPopup from '../WithChildPopup';
import ImgSlider from '../../../../organisms/Slider';
import ExternalSVG from '../../../ExternalSVG';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import ImgPopup from '../ImgPopup';
import SVGmenuStatistiky from '../../../SVGIcons/menu/SVGmenuStatistiky';
import SVGinfo from '../../../SVGIcons/SVGinfo';
import WithStepsPopup from '../WithStepsPopup';
import { Box } from '@xcorejs/ui';
import { Heading2, Heading3 } from '../../../Headings';
import { SectionText } from '../../../Text';
import { StyledGamesForTwoVisualPopup } from '../../../../organisms/VisualValuesComparison/style';

export const FAQLadder = () => (
  <BasePopup button='close'>
    <MultiStatePopup
      img={'/img/ico/tests/hp-zebricek.svg'}
      name={'valuesLadder1'}
      texts={3}
      label={'valuesLadder1'}
      nextPopup={FAQValues}
    />
  </BasePopup>
);

export const FAQValues = () => (
  <BasePopup button='close'>
    <MultiStatePopup
      img={'/img/ico/tests/people-circle.svg'}
      name={'values1'}
      texts={5}
      label={'values1'}
      nextPopup={FAQLadder}
    />
  </BasePopup>
);

export const TestRound = () => (
  <BasePopup button='ok'>
    <MultiStatePopup
      img={'/img/popup/double-arrow.svg'}
      name={'testRound'}
      texts={1}
    />
  </BasePopup>
);

export const TestFinalRound = () => (
  <BasePopup button='ok'>
    <MultiStatePopup img={'/img/popup/double-arrow.svg'} name={'testFinalRound'} texts={1} />
  </BasePopup>
);

export const HowChooseValues = () => (
  <BasePopup button='ok'>
    <MultiStatePopup
      img={'/img/ico/tests/hp-zebricek.svg'}
      name={'howChooseValues'}
      texts={3}
      label={'howChooseValues'}
      nextPopup={FAQLadder}
    />
  </BasePopup>
);

export const HowToReadResults = () => (
  <BasePopup button='ok'>
    <MultiStatePopup svg={<SVGmenuStatistiky />} name={'howToReadResults'} texts={3} />
  </BasePopup>
);

export const EticonIntensity = () => (
  <BasePopup button='ok'>
    <MultiStatePopup svg={<SVGinfo />} name={'eticonIntensity'} texts={1} />
  </BasePopup>
);

export const ValueTestResultGraph = () => (
  <BasePopup button='ok'>
    <WithChildPopup img={'/img/ico/tests/hp-zebricek.svg'} name={'valueTestResult.graph'} texts={3} position={2}>
      <img
        src='/img/result/graf-preference.png'
        srcSet='/img/result/graf-preference.png, /img/result/graf-preference@2x.png 2x'
        alt=''
      />
    </WithChildPopup>
  </BasePopup>
);

export const MainValuesProfile = () => (
  <BasePopup button='close'>
    <MultiStatePopup img={'/img/ico/tests/hp-zebricek.svg'} name={'mainValuesProfile'} texts={1} />
  </BasePopup>
);

export const ValuesDetail = () => (
  <BasePopup button='ok'>
    <WithChildPopup img={'/img/ico/tests/hp-zebricek.svg'} name={'valuesDetail'} texts={1} position={1}>
      <ImgSlider imgUrls={['/img/result/anketa_1.png', '/img/result/anketa_2.png', '/img/result/anketa_3.png']} />
    </WithChildPopup>
  </BasePopup>
);

export const MatchIndex = () => (
  <BasePopup button='close'>
    <WithChildPopup img={'/img/popup/results-stats.svg'} name={'matchIndex'} texts={2} position={1}>
      <img src='/img/popup/index.png' srcSet='/img/popup/index.png, /img/popup/index@2x.png 2x' alt='' />
    </WithChildPopup>
  </BasePopup>
);

export const StatisticsResult = () => (
  <ImgPopup button='close'>
    <picture>
      <source srcSet='/img/result/statistiky_desktop.jpg' media='(min-width: 768px)' />
      <source srcSet='/img/result/statistiky_mobil.png' media='(min-width: 1px)' />
      <img src='/img/result/statistiky_desktop.jpg' alt='' />
    </picture>
  </ImgPopup>
);

export const WhatNext = () => (
  <BasePopup button='ok'>
    <div className='icons'>
      <ExternalSVG src={'/img/hh.svg'} />

      <h2>
        <FormattedMessage id={'faq.whatNext.title'} />
      </h2>

      <p>
        <FormattedHTMLMessage id={'faq.whatNext.text'} />
      </p>

      <ExternalSVG src={'/img/ico/tests/hp-zebricek.svg'} />

      <h3>
        <FormattedMessage id={'faq.whatNext.subtitle.0'} />
      </h3>

      <p>
        <FormattedHTMLMessage id={'faq.whatNext.text.0'} />
      </p>

      <ExternalSVG src={'/img/popup/results-scope.svg'} />

      <h3>
        <FormattedMessage id={'faq.whatNext.subtitle.1'} />
      </h3>

      <p>
        <FormattedHTMLMessage id={'faq.whatNext.text.1'} />
      </p>

      <ExternalSVG src={'/img/result/results-time.svg'} />

      <h3>
        <FormattedMessage id={'faq.whatNext.subtitle.2'} />
      </h3>

      <p>
        <FormattedHTMLMessage id={'faq.whatNext.text.2'} />
      </p>

      <ExternalSVG src={'/img/popup/faq-star.svg'} />

      <h3>
        <FormattedMessage id={'faq.whatNext.subtitle.3'} />
      </h3>

      <p>
        <FormattedHTMLMessage id={'faq.whatNext.text.3'} />
      </p>

      <ExternalSVG src={'/img/popup/results-stats.svg'} />

      <h3>
        <FormattedMessage id={'faq.whatNext.subtitle.4'} />
      </h3>

      <p>
        <FormattedHTMLMessage id={'faq.whatNext.text.4'} />
      </p>

      <ExternalSVG src={'/img/popup/faq-group.svg'} />

      <h3>
        <FormattedMessage id={'faq.whatNext.subtitle.5'} />
      </h3>

      <p>
        <FormattedHTMLMessage id={'faq.whatNext.text.5'} />
      </p>

      <div className='green'>
        <p>
          <FormattedHTMLMessage id={'faq.whatNext.green'} />
        </p>
      </div>
    </div>
  </BasePopup>
);

export const FAQ1 = () => (
  <BasePopup button='ok'>
    <WithStepsPopup img={'/img/faq/game.svg'} name={'faq1'} texts={3} />
  </BasePopup>
);

export const FAQ2 = () => (
  <BasePopup button='ok'>
    <WithStepsPopup img={'/img/faq/ladder.svg'} name={'faq2'} texts={3} />
  </BasePopup>
);

export const FAQ3 = () => (
  <BasePopup button='ok'>
    <WithStepsPopup img={'/img/faq/main-profile.svg'} name={'faq3'} texts={3} />
  </BasePopup>
);

export const FAQ4 = () => (
  <BasePopup button='ok'>
    <WithStepsPopup img={'/img/faq/diary.svg'} name={'faq4'} texts={3} />
  </BasePopup>
);

export const FAQ5 = () => (
  <BasePopup button='ok'>
    <WithStepsPopup img={'/img/faq/scope.svg'} name={'faq5'} texts={3} />
  </BasePopup>
);

export const FAQ6 = () => (
  <BasePopup button='ok'>
    <WithStepsPopup img={'/img/faq/values-2.svg'} name={'faq6'} texts={3} />
  </BasePopup>
);

export const FAQ7 = () => (
  <BasePopup button='ok'>
    <WithStepsPopup img={'/img/faq/stats.svg'} name={'faq7'} texts={3} />
  </BasePopup>
);

export const FAQ8 = () => (
  <BasePopup button='ok'>
    <WithStepsPopup img={'/img/faq/premium.svg'} name={'faq8'} texts={3} />
  </BasePopup>
);

export const FAQ9 = () => (
  <BasePopup button='ok'>
    <WithStepsPopup img={'/img/faq/about-values.svg'} name={'faq9'} texts={5} />
  </BasePopup>
);

export const FAQ10 = () => (
  <BasePopup button='ok'>
    <WithStepsPopup img={'/img/faq/values-work.svg'} name={'faq10'} texts={4} />
  </BasePopup>
);

export const FAQ11 = () => (
  <BasePopup button='ok'>
    <WithStepsPopup img={'/img/faq/about-eticia.svg'} name={'faq11'} texts={5} />
  </BasePopup>
);

export const FAQ12 = () => (
  <BasePopup button='ok'>
    <WithStepsPopup img={'/img/faq/user.svg'} name={'faq12'} texts={5} />
  </BasePopup>
);

export const AboutValueGame = () => (
  <BasePopup button='close' background='green' width={90}>
    <div className='min-height'>
      <picture>
        <source srcSet='/img/popup/Pop_hra.png' media='(min-width: 768px)' />
        <source srcSet='/img/popup/Pop_hra_mob.png' media='(min-width: 1px)' />
        <img src='/img/popup/Pop_hra.png' alt='' />
      </picture>
    </div>
  </BasePopup>
);

export const EPremium = () => (
  <BasePopup button='close' width={90} background='premium'>
    <div className='min-height'>
      <picture>
        <source srcSet='/img/premium/premium.png' media='(min-width: 768px)' />
        <source srcSet='/img/premium/premium_mobile.png' media='(min-width: 1px)' />
        <img src='/img/premium/premium.png' alt='' />
      </picture>
    </div>
  </BasePopup>
);

export const Christmas = () => (
  <BasePopup button='close' width={90} background='valuesForTwo' border='valuesForTwo'>
    <div className='min-height'>
      <picture>
        <source srcSet='/img/popup/Pop_Vanoce.png' media='(min-width: 768px)' />
        <source srcSet='/img/popup/Pop_Vanoce_mob.png' media='(min-width: 1px)' />
        <img src='/img/popup/Pop_Vanoce.png' alt='' />
      </picture>
    </div>
  </BasePopup>
);

export const GamesForTwoVisualPopup = () => (
  <BasePopup button='close' width={90} background='valuesForTwo'>
    <StyledGamesForTwoVisualPopup>
      <Heading2>
        <FormattedMessage id={'faq.gamesForTwoVisual.heading'} />
      </Heading2>

      <SectionText color='#fff'>
        <FormattedMessage id={'faq.gamesForTwoVisual.text'} />
      </SectionText>

      <Box mt='2rem'>
        <Box className='img red'>
          <img src={'/img/ico/tests/vlc1.png'} alt='' />
        </Box>

        <Heading3>
          <FormattedMessage id={'faq.gamesForTwoVisual.subtitle.1'} />
        </Heading3>

        <SectionText color='#fff'>
          <FormattedHTMLMessage id={'faq.gamesForTwoVisual.text.1'} />
        </SectionText>

        <SectionText color='rgba(255, 255, 255, 0.7)'>
          <FormattedHTMLMessage id={'faq.gamesForTwoVisual.text.1.1'} />
        </SectionText>
      </Box>

      <Box mt='4rem'>
        <Box className='img green'>
          <img src={'/img/ico/tests/vlc2.png'} alt='' />
        </Box>

        <Heading3>
          <FormattedMessage id={'faq.gamesForTwoVisual.subtitle.2'} />
        </Heading3>

        <SectionText color='#fff'>
          <FormattedHTMLMessage id={'faq.gamesForTwoVisual.text.2'} />
        </SectionText>

        <SectionText color='rgba(255, 255, 255, 0.7)'>
          <FormattedHTMLMessage id={'faq.gamesForTwoVisual.text.2.2'} />
        </SectionText>
      </Box>

      <Box mt='4rem'>
        <Box className='img blue'>
          <img src={'/img/ico/tests/vlc3.png'} alt='' />
        </Box>

        <Heading3>
          <FormattedMessage id={'faq.gamesForTwoVisual.subtitle.3'} />
        </Heading3>

        <SectionText color='#fff'>
          <FormattedHTMLMessage id={'faq.gamesForTwoVisual.text.3'} />
        </SectionText>

        <SectionText color='rgba(255, 255, 255, 0.7)'>
          <FormattedHTMLMessage id={'faq.gamesForTwoVisual.text.3.3'} />
        </SectionText>
      </Box>

      <Box mt='4rem'>
        <Box className='img yellow'>
          <img src={'/img/ico/tests/vlc4.png'} alt='' />
        </Box>

        <Heading3>
          <FormattedMessage id={'faq.gamesForTwoVisual.subtitle.4'} />
        </Heading3>

        <SectionText color='#fff'>
          <FormattedHTMLMessage id={'faq.gamesForTwoVisual.text.4'} />
        </SectionText>

        <SectionText color='rgba(255, 255, 255, 0.7)'>
          <FormattedHTMLMessage id={'faq.gamesForTwoVisual.text.4.4'} />
        </SectionText>
      </Box>
    </StyledGamesForTwoVisualPopup>
  </BasePopup>
);

export const TableValuesConnection = () => (
  <BasePopup button='close'>
    <ExternalSVG src={'/img/popup/faq-group.svg'} />

    <h2>
      <FormattedMessage id={'faq.tableValuesConnection.title'} />
    </h2>

    <p>
      <FormattedHTMLMessage id={'faq.tableValuesConnection.text'} />
    </p>

    <Box mt='5rem'>
      <h3>
        <FormattedMessage id={'faq.tableValuesConnection.subtitle.0'} />
      </h3>

      <p>
        <FormattedHTMLMessage id={'faq.tableValuesConnection.text.0'} />
      </p>
    </Box>

    <Box mt='5rem'>
      <h3>
        <FormattedMessage id={'faq.tableValuesConnection.subtitle.1'} />
      </h3>

      <p>
        <FormattedHTMLMessage id={'faq.tableValuesConnection.text.1'} />
      </p>
    </Box>

    <Box mt='5rem' mb='2rem'>
      <h3>
        <FormattedMessage id={'faq.tableValuesConnection.subtitle.2'} />
      </h3>

      <p>
        <FormattedHTMLMessage id={'faq.tableValuesConnection.text.2'} />
      </p>
    </Box>

    <img src='/img/popup/grafy_hodn_spojeni.png' alt='' style={{ maxWidth: '32rem' }} />
  </BasePopup>
);
