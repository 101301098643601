import { jsonFetch } from 'utils/fetch';
import { SHOP_BUY, SHOP_CLAIM_VOUCHER } from '../../../../config/apiRoutes';

export enum ProductType {
  EticiaPlus1Year,
  EticiaPlus3Years,
  EticiaPlusForever,
  EticiaPlus1YearVoucher,
  EticiaPlus3YearsVoucher,
  EticiaPlusForeverVoucher,
  TreeVoucher,
  ThreeLadders
}

export interface ShopSummaryResponse {
  eticiaPlusValidTo: string | null;
  availableMagicTokenCount: number;
  mains: Array<ShopSummaryItemResponse>;
  trees: Array<ShopSummaryItemResponse>;
}

interface ShopSummaryItemResponse {
  id: string;
  createdAt: number;
  title: string;
  paid: boolean;
  used: boolean;
  voucher: boolean;
  amIBuyer: boolean;
  amIOwner: boolean;
  secondNick: string | null;
}

export const getShopRedirectLink = async (
  type: ProductType
): Promise<string> => {
  return (
    await jsonFetch<{ url: string }>(SHOP_BUY, {
      method: 'POST',
      body: JSON.stringify({ type })
    })
  ).url;
};

export const claimVoucher = (code: string) =>
  jsonFetch(SHOP_CLAIM_VOUCHER, {
    method: 'POST',
    body: JSON.stringify({ code }),
  });
