import React, { FC } from 'react';
import { Flex, Heading2, Paragraph } from '@xcorejs/ui';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { StyledTag } from './styles';
import { RedTheme } from 'components/theme/colors';
import { ExtendedMembershipProps } from './Footer';
import { memberText } from './memberText';

const Intro: FC<ExtendedMembershipProps & {forever?: boolean}> = ({ free, plus, forever }) => {
  const text = memberText({ plus, free, forever });

  const textStyle = {
    mt: '2rem',
    maxWidth: '50rem',
  };
  
  return ( 
    <Flex 
      as='section'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      backgroundColor='#fafafa'
    >
      <StyledTag
        bg={RedTheme.primary} 
        borderRadius='0.5rem'
        marginBottom={{ _: '1rem !important', md:'2rem !important' }}
      >
        Eticia Plus
      </StyledTag>

      <Heading2 color='#222' {...textStyle}>
        <FormattedHTMLMessage id={`eticiaPlus.intro.${text}title`} />
      </Heading2>

      <Paragraph color='#666' {...textStyle}>
        <FormattedMessage id={`eticiaPlus.intro.${text}text`} />
      </Paragraph>
    </Flex>
  );
};
 
export default Intro;