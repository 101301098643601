import styled, { css } from 'styled-components';
import { darkBaseHEX, whiteFade } from '../../theme/colors';
import { bold, OliMedium, OliRegular } from '../../theme/fonts';
import { mediaLgDefault, mediaMdDefault, mediaSmDefault } from '../../theme/grid';
import { BurgerFlex } from '../../organisms/Layout/Header/styles';
import { StyledLogo } from '../Logo/styled';

const StyledSubnav = styled.div<{
  sideMenuIsOpen?: boolean;
  stepsOnMobile?: boolean;
  black?: boolean;
  withoutBackground?: boolean;
}>`
  position: fixed;
  top: 0;
  left: 0;
  background-color: #1d1c21;
  width: 100%;
  z-index: 1003;
  height: 5rem;

  ${mediaMdDefault} {
    background: ${props => props.black ? '#1d1c21' : 'rgba(11, 12, 28, 0.3)'};
    height: 6rem;
  }

  ${props => props.withoutBackground && css`
    background: ${props.black ? '#1d1c21' : 'transparent !important'};
  `};
  
  ul {
    display: none;
    position: relative;
    width: 100%;
    text-align: center;
    transition: padding 0.3s;

    ${mediaMdDefault} {
      display: block;
      text-align: right;
    }

    ${mediaLgDefault} {
      text-align: center;
    }

    @media (max-width: 1620px) {
      padding-left: 0;
    }

    @media (max-width: 1621px) {
      padding-left: 0;
    }

    ${props =>
    props.sideMenuIsOpen &&
      css`
        @media (max-width: 1620px) {
          padding-left: 0;
        }

        @media (min-width: 1621px) {
          padding-left: 31rem;
        }
      `};

    li {
      display: inline-block;
      margin: 0 2rem;
      user-select: none;

      a {
        position: relative;
        display: inline-block;
        padding: 2rem 0;
        font-size: 1.5rem;
        opacity: 0.8;
        line-height: 1.9rem;
        color: ${whiteFade};
        transition: opacity 300ms ease;
        font-family: ${OliMedium};

        &:hover {
          opacity: 1;
        }

        &.active {
          opacity: 1;
          font-weight: ${bold};
          color: #eeedde;

          ${mediaMdDefault} {
            &:after {
              content: "";
              position: absolute;
              bottom: -1px;
              left: 50%;
              margin-left: -2.5rem;
              width: 5rem;
              height: 0.2rem;
              border-radius: 0.2rem;
              background-color: ${darkBaseHEX};
            }
          }
        }
      }
    }
  }

  ${BurgerFlex} {
    position: absolute;
    display: inline-flex;
    height: 100%;
    left: 0;
    padding-left: 2rem;
    z-index: 100;
    opacity: 1;
    transition: opacity 0.3s;
    justify-content: flex-start;

    ${mediaSmDefault} {
      width: 31rem;
    }

    & > span,
    & > div {
      z-index: 1;
    }

    &:before {
      position: absolute;
      content: "";
      left: -100%;
      width: 100%;
      height: 6rem;
      top: 0;
      background: #1d1c21;
      transition: left 0.3s;
    }

    ${props =>
    props.sideMenuIsOpen &&
      css`
        &:before {
          left: 0;
        }

        ${StyledLogo} {
          opacity: 1;
        }
      `};
  }

  select {
    position: relative;
    display: inline-block;
    padding-right: 2rem;
    font-size: 1.3rem;
    line-height: 2.4rem;
    font-weight: 400;
    height: 5rem;
    color: #fff;
    font-family: ${OliRegular};
    opacity: 1;
    appearance: none;
    -webkit-appearance: none;
    background: transparent;
    border: 0;

    &:focus {
      outline: 0;
    }

    ${mediaSmDefault} {
      font-size: 1.6rem;
      line-height: 1.9rem;
      font-weight: 600;
    }
  }

  ${props =>
    props.stepsOnMobile &&
    css`
      select {
        padding-right: 0 !important;
        font-weight: 400;

        @media (min-width: 360px) {
          font-size: 1.4rem;
        }
      }

      svg {
        display: none;
      }
    `};
`;

export default StyledSubnav;

export const FlexDiv = styled.div`
  position: relative;
  margin-right: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  svg {
    position: absolute;
    right: 0;
    max-width: 1rem;
    max-height: 1rem;
    margin-left: 1.5rem;
    transform: rotate(-90deg);
    pointer-events: none;
  }

  ${mediaMdDefault} {
    display: none;
  }
`;
