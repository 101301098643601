import NavItem from 'components/atoms/Nav/NavItem';
import Subnav from 'components/atoms/Subnav';
import ValuePage from 'components/organisms/InfoSlides/ValuePage';
import { createUrl } from 'components/pages/routes';
import * as React from 'react';
import { useFetchGameStatus } from 'utils/useQuery/queryFetchHelpers';
import LoadingSpinner from '../../../atoms/Animations/LoadingSpinner';


const ValuesEticons: React.FC = () => {
  const baseUrl = createUrl('app.valueWord');
  const gameStatusQuery = useFetchGameStatus();
  
  return (
    <>
      <Subnav>
        <NavItem to={baseUrl} label={'valuesEticons.heading'} exact />
      </Subnav>

      {gameStatusQuery.data ? 
        <ValuePage gameStatus={gameStatusQuery.data} />
        : gameStatusQuery.isLoading ? <LoadingSpinner /> 
          : null}
    </>
  );
};

export default ValuesEticons;
