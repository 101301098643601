import React, { FC, useEffect, useState, Fragment } from 'react';
import { Modal, Img, Paragraph, Flex, Grid, Box, Button, Heading3 } from '@xcorejs/ui';
import { IUserInfo } from 'store/User';
import useFetch from 'components/atoms/Fetch/useFetch';
import { VALUE_TEST_RESULTS_TRIBAL, IValueTestResultDetail } from 'config/api/valueTestResult';
import LoadingSpinner from 'components/atoms/Animations/LoadingSpinner';
import { gameLevels } from 'components/pages/App/ValueCenter/Game/data';

interface Level34Props {
  onExit: () => unknown;
}

const Level34: FC<Level34Props> = ({ onExit }) => {
  const { result, state } = useFetch<IValueTestResultDetail>(VALUE_TEST_RESULTS_TRIBAL);
  const [selected, setSelected] = useState<number>();
  const [destroyed, setDestroyed] = useState<boolean>(false);
  const [secondOpacity, setSecondOpacity] = useState<number>(0);

  if (state === 'fetching') return <Modal {...ModalProps}><LoadingSpinner /></Modal>;

  if (state === 'fail' || !result) return (
    <Modal {...ModalProps}>
      <Heading3>Vyskytla se chyba</Heading3>
    </Modal>
  );

  const results = result.lifeValues.sort((a, b) => a.userRank - b.userRank).slice(0, 10);
  const destroyValue = () => {
    setDestroyed(true);
    setTimeout(() => setSecondOpacity(1), 500);
  };

  return ( 
    <Modal {...ModalProps}>
      <Flex flexDirection='column' justifyContent='center' opacity={destroyed ? 0 : 1} transition='opacity 500ms'>
        <Img marginX='auto' src='/img/level34.png' alt='level34' />

        <Paragraph textAlign='center' mt='2rem'>
        Jestli chceš nasednout, zřekni se jedné ze svých hodnot.<br />
        To je drahá vstupenka na naši loď.
        </Paragraph>

        <Grid columns='3fr 2fr' rows='auto' gap='0.5rem 1rem' alignItems='center' marginTop='4rem'>
          {results.map((r, i) => (
            <Fragment key={i}>
              <Paragraph justifySelf='end' lineHeight='1'>{r.name}</Paragraph>

              <Flex
                justifySelf='start'
                alignItems='center'
                justifyContent='center'
                onClick={() => setSelected(i)}
                cursor='pointer'
              >
                <Flex
                  alignItems='center'
                  justifyContent='center'
                  width='2.4rem'
                  height='2.4rem'
                  border='2px solid yellow'
                  borderRadius='50%'
                  opacity={selected === i ? 1 : 0.5}
                  transition='opacity 300ms'
                >
                  <Box
                    width='1.6rem'
                    minWidth='1.6rem'
                    height='1.6rem'
                    bg='yellow'
                    borderRadius='50%'
                    opacity={selected === i ? 1 : 0}
                    transition='opacity 300ms'
                  />
                </Flex>
              </Flex>
            </Fragment>
          ))}
        </Grid>

        <Button
          variant='primary'
          marginTop='4rem'
          marginX='auto'
          // width='100%'
          transition='opacity 300ms'
          disabled={selected === undefined}
          onClick={() => {
            if (selected === undefined) return;
            destroyValue();
          }}
        >Zříci se</Button>
      </Flex>

      {destroyed && (
        <Flex flexDirection='column' justifyContent='center' alignItems='center' position='absolute' top={0} left={0} width='100%' height='100%' opacity={secondOpacity} transition='opacity 500ms' paddingX='2rem'>
          <Img src='/img/level34-2.png' alt='level34' />

          <Paragraph textAlign='center' mt='2rem'>
            <span
              style={{ 
                color: 'yellow', 
                textDecoration: 'underline', 
                textDecorationThickness: '0.2rem', 
                textUnderlineOffset: '0.4rem' 
              }}
            >{results[selected!].name}</span> se nám bude hodit.<br />
            Vstupenka je tvá. Vítej na palubě.
          </Paragraph>

          <Button
            variant='primary'
            mt='4rem'
            onClick={() => onExit()}
          >Vstoupit</Button>
        </Flex>
      )}
    </Modal>
  );
};
 
export default Level34;

const ModalProps = {
  background: gameLevels[34].theme.primary || 'darkred',
  width: '37.5rem',
  maxHeight: '65rem',
  padding: { _: '3rem', sm: '6rem 3rem 3rem' },
};
