import { Box, Grid } from '@xcorejs/ui';
import CompareBox, { CompareBoxProps } from 'components/components/EticiameterComponents/CompareBoxes/CompareBox';
import { BlueTheme } from 'components/theme/colors';
import React, { FC, Fragment } from 'react';

const ValueCardsBox: FC = () => {
  const groups: Omit<CompareBoxProps, '_flex'>[] = [
    {
      title: 'Your Similar Values',
      group1: {
        title: 'Most Similar in the World',
        subtitle: 'Most common values',
        image: 'https://placehold.it/150x150',
        plus: true,
        group: { gender: 'Sex', years: 'age', country: 'country' }
      },
      group2: {
        title: 'Most Different',
        subtitle: 'Least common values',
        image: 'https://placehold.it/150x150',
        plus: true,
        group:  { gender: 'Sex', years: 'age', country: 'country' }
      }
    },
    {
      title: 'In Your Country',
      group1: {
        title: 'Most Similar in the World',
        subtitle: 'Most common values',
        image: 'https://placehold.it/150x150',
        flag: 'germany',
        group: {
          gender: 'Men', country: 'Germany', years: '(25-29 years)' }
      },
      group2: {
        title: 'Most Different',
        subtitle: 'Least common values',
        image: 'https://placehold.it/150x150',
        free: true,
        group: { gender:'Women', country: 'Italy', years: '(30-34 years)' }
      },
    },
    {
      title: 'Continent',
      group1: {
        title: 'Most Similar in the World',
        subtitle: 'Most common values',
        image: 'https://placehold.it/150x150',
        flag: 'jamaica',
        group: { gender:'Men', country: 'Jamaica', years:'(50-54 years)' }
      },
      group2: {
        title: 'Most Different',
        subtitle: 'Least common values',
        image: 'https://placehold.it/150x150',
        flag: 'japan',
        group: { gender:'Men', country: 'Japan', years: '(55-59 years)' }
      }
    }
  ];  
  
  return (
    <Fragment>
      <Grid columns={{ _: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} rows='auto' gap={{ _: '3rem 0.5rem' }} marginY={{ _: '4rem' }} justifyItems='center'>
        {groups.map((g, i) => (
          <CompareBox
            key={i}
            title={g.title}
            group1={{ ...g.group1 }}
            group2={g.group2 && { ...g.group2 }}
            flexDirection='column'
            maxWidth='34rem'
            backgroundColor='transparent'
            justifySelf={{ _: 'center', md: i % 2 !== 0 ? 'start' : 'end' }}
          />
        ))}
      </Grid>
      <Box height={{ _: '10rem' }} background={`linear-gradient(0deg, ${BlueTheme.extraDark}, transparent)`} />
    </Fragment>
  );
};
 
export default ValueCardsBox;
