import { css } from 'styled-components';

export const ScrollbarStyles = css`
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.2);
    transition: background 0.3s;
  }

  ::-webkit-scrollbar-track:hover {
    background: rgba(255, 255, 255, 0.3);
  }

  ::-webkit-scrollbar-thumb {
    background-color: #191d2c;
    background: rgba(25, 29, 44, 0.3);
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgba(25, 29, 44, 0.5);
  }
`;
