import styled, { css } from 'styled-components';
import { mediaLgDefault, mediaSmDefault } from '../../../theme/grid';
import {
  GreenTheme,
  gray,
  yellow,
  primaryBlueRGB,
  white,
  blue,
  yellowRGB,
  darkBaseRGB,
  grayRGB,
  textGreen,
  textGreenRGB,
  blueRGB, BlueTheme,
} from '../../../theme/colors';
import { Col, Row } from 'react-styled-flexboxgrid';
import { CustomText } from '../../../atoms/Text';
import { StyledPrimaryButtonLink, StyledPrimaryButton } from '../../../atoms/Buttons/styles';
import { ScrollbarStyles } from '../../../templates/General/styles';
import { StyledValuesGraph } from '../../../molecules/Results/styles';
import { bold } from '../../../theme/fonts';
import { StyledPieChart } from '../../../atoms/Pie';

export const FinalMatch = styled.div`
  margin-top: 4rem;
  display: block;
  text-align: left;

  & > div {
    margin-top: 3rem;
  }

  .heading {
    color: rgba(255, 255, 255, 0.7);
    font-size: 2rem;
    line-height: 2.5rem;
    font-weight: 400;
  }

  p {
    margin-top: 1rem;
    color: #f4f4f6;
    font-size: 1.6rem;
    line-height: 2rem;

    &.i0 {
      strong {
        color: ${yellow};
      }
    }

    &.i1 {
      strong {
        color: ${textGreen};
      }
    }

    &.i2 {
      strong {
        color: ${blue};
      }
    }
  }

  ${StyledPieChart} {
    margin-right: 3rem;
  }
`;

export const GraphLegendDescription = styled.div`
  display: inline-block;
  margin-top: 3rem;
  color: ${white};
  font-size: 1.6rem;
  line-height: 2.1rem;
  text-align: left;

  & > div {
    margin-bottom: 2rem;
    display: flex;
    align-items: center;

    span {
      font-weight: 500;
    }

    p {
      opacity: 0.8;
      font-size: 1.4rem;
      line-height: 1.7rem;

      span {
        font-weight: 400;
      }
    }
  }

  .symbol {
    display: inline-flex;
    margin-right: 1rem;
    align-items: center;
    justify-content: center;
    width: 3rem;
    min-width: 3rem;
    height: 3rem;
    border: 0.2rem solid rgba(255, 255, 255, 0.5);
    border-radius: 50%;
  }
`;
export const ContentSwitchStyle = styled.div<{ visibility?: 'both' | 'left' | 'right' }>`
  margin: 3rem auto 4rem;

  h1 {
    max-width: calc(100% - 6rem);
    font-size: 2.4rem;
    letter-spacing: 0.5px;
    line-height: 2.9rem;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.5);
    text-align: center;
  }

  svg {
    width: 1.3rem;
    height: 2.4rem;
    opacity: 0.5;
    transition: opacity 0.3s;
    cursor: pointer;
  }

  a {
    &:last-child {
      svg {
        transform: rotate(-180deg);
      }
    }

    &:hover {
      svg {
        opacity: 1;
      }
    }
  }

  ${props =>
    props.visibility === 'left' &&
    css`
      a {
        &:last-child {
          opacity: 0;
          visibility: hidden;
        }
      }
    `};

  ${props =>
    props.visibility === 'right' &&
    css`
      a {
        &:first-child {
          opacity: 0;
          visibility: hidden;
        }
      }
    `};

  ${CustomText} {
    margin-top: 4rem;
  }

  .content-heading {
    margin-top: 4rem;
    color: ${yellow};
    font-size: 4rem;
    letter-spacing: 0.5px;
    line-height: 5.1rem;
    font-weight: ${bold};
  }

  .content-subheading {
    margin-top: 1rem;
    color: #fff;
    font-size: 24px;
    letter-spacing: 0.5px;
    line-height: 29px;
  }

  .test-type {
    margin-top: 4rem;
    color: rgba(${grayRGB}, 0.7);
    font-size: 1.6rem;
    line-height: 2rem;
  }
`;

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${mediaSmDefault} {
    max-width: calc(100% - 22rem);
    margin-left: auto;
    margin-right: auto;
  }
`;

export const CardList = styled.div`
  display: block;

  ${Row as any} {
    margin: 0 -0.2rem;

    & > ${Col as any} {
      padding: 0 0.2rem;

      &:last-child {
        margin-bottom: 0;
      }

      ${mediaSmDefault} {
        margin-bottom: 0;
      }
    }
  }
`;

export const BoxActions = styled.div<{ isInverted?: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.3s;
  user-select: none;

  svg {
    width: 3rem;
    height: 3rem;
    max-width: 3rem;
    max-height: 3rem;
    margin-right: 1.5rem;
    transition: transform 0.3s;

    path {
      fill: ${gray};
    }
  }

  span {
    display: inline-block;
    margin-top: 0.3rem;
    color: ${gray};
    font-size: 1.6rem;
    line-height: 2rem;
    white-space: nowrap;
  }

  &:hover {
    opacity: 1;
  }

  &.arrow {
    svg {
      width: 2rem;
      height: 2.4rem;
      min-width: 2rem;
    }
  }

  &.more-about-values {
    svg {
      width: 2.4rem;
      height: 2.4rem;
    }
  }

  ${props =>
    props.isInverted &&
    css`
      svg {
        transform: rotate(-180deg);
      }
    `};
`;

export const GraphLegend = styled.div`
  margin-bottom: 3rem;

  ${BoxActions} {
    opacity: 1;

    &:first-child {
      margin-right: 3rem;
    }

    span {
      color: #fff;
      opacity: 0.8;
      font-size: 1.8rem;
      line-height: 2.2rem;
    }
  }
`;

export const GraphLegendCircle = styled.div<{ color?: 'green' | 'blue' | 'yellow' | 'white' | 'black' }>`
  height: 1.6rem;
  width: 1.6rem;
  margin-right: 1.5rem;
  border-radius: 50%;

  ${props =>
    props.color === 'green' &&
    css`
      border: 2px solid ${textGreen};
      background-color: rgba(${textGreenRGB}, 0.25);
    `};

  ${props =>
    props.color === 'blue' &&
    css`
      border: 2px solid ${blue};
      background-color: rgba(${blueRGB}, 0.25);
    `};

  ${props =>
    props.color === 'yellow' &&
    css`
      width: 1.2rem;
      height: 1.2rem;
      background-color: ${yellow};
      box-shadow: 0 0 0 3px rgba(${yellowRGB}, 0.26);
    `};

  ${props =>
    props.color === 'white' &&
    css`
      width: 1.2rem;
      height: 1.2rem;
      background-color: ${gray};
      box-shadow: 0 0 0 3px rgba(244, 244, 246, 0.26);
    `};

  ${props =>
    props.color === 'black' &&
    css`
      width: 2.4rem;
      height: 2.4rem;
    `};
`;

const cornerPadding = '3rem';

export const StyledColorBox = styled.div<{ color?: 'green' | 'blue' }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 3rem 2rem;
  border-radius: 0.4rem;
  box-shadow: 0 0.5rem 1rem 0 rgba(25, 29, 44, 0.4);

  ${mediaLgDefault} {
    min-height: 43.6rem;
  }

  &:after {
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(${darkBaseRGB}, 0.15);
  }

  ${props =>
    props.color === 'green' &&
    css`
      background: radial-gradient(circle at top, ${GreenTheme.primary} 0%, ${GreenTheme.darken} 100%);
    `};

  ${props =>
    props.color === 'blue' &&
    css`
      background: radial-gradient(circle at top, ${BlueTheme.primary} 0%, ${BlueTheme.darken} 100%);
    `};

  &.eticon {
    margin: 0 auto;
    padding: 2rem;
    height: auto;
    max-width: 22rem;
    min-height: 0;

    ${mediaSmDefault} {
      max-width: 30rem;
    }

    & > div {
      position: static;
      top: 0;
      transform: none;

      img {
        max-width: 16rem;
        max-height: 16rem;
      }

      span {
        margin-bottom: 0;
      }
    }
  }

  ${CustomText} {
    height: calc(100% - 5.5rem);
    overflow-y: auto;
    max-height: 32rem;
    text-align: center;
    ${ScrollbarStyles};

    p {
      font-size: 1.6rem;
      line-height: 2rem;
      max-height: 100%;
    }
  }

  span {
    font-size: 1.8rem;
    line-height: 2.2rem;

    ${mediaLgDefault} {
      font-size: 2rem;
      line-height: 2.5rem;
    }
  }

  ${StyledValuesGraph} {
    .actual-graph {
      padding: 4.5rem;
    }

    .letters-wrapp {
      .letter-wrapper {
        min-width: 4.1rem;
        min-height: 4.1rem;

        .letter {
          font-size: 1.8rem;
          line-height: 2.3rem;
          min-width: 4.1rem;
          min-height: 4.1rem;
        }

        &.i,
        &.v {
          ${mediaSmDefault} {
            left: 3rem;
          }
        }

        &.e,
        &.s {
          ${mediaSmDefault} {
            right: ${cornerPadding};
          }
        }

        &.i,
        &.e {
          ${mediaSmDefault} {
            top: 5rem;
          }
        }

        &.v,
        &.s {
          ${mediaSmDefault} {
            bottom: 5rem;
          }
        }
      }
    }
  }
`;

export const ColorBoxName = styled.span`
  display: block;
  margin-bottom: 3rem;
  text-align: center;
  font-weight: 800;
  font-size: 2rem;
  letter-spacing: 0.5px;
  line-height: 2.6rem;
`;

export const ImgWrapper = styled.div`
  display: block;
  margin: 0 0 2rem;

  img {
    display: block;
    max-width: 16rem;
    margin: 0 auto;
  }
`;

export const WaitingForResult = styled.div`
  margin-top: 4rem;

  ${CustomText} {
    margin-top: 4rem;
    text-align: center;
  }

  ${StyledPrimaryButtonLink} {
    margin-top: 3rem;
  }

  .btn-wrap {
    margin: 3rem 0;

    a {
      margin-top: 3rem;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      &:first-child {
        margin-top: 0;
      }
    }

    ${mediaSmDefault} {
      margin: 3rem 1.5rem;
    }
  }
`;

export const FlexDivMargin = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${mediaSmDefault} {
    flex-direction: row;
  }

  ${StyledPrimaryButton}, ${StyledPrimaryButtonLink} {
    margin: 3rem 0 0;

    ${mediaSmDefault} {
      margin: 0 3rem;
    }
  }

  ${BoxActions} {
    display: flex;

    &:first-child {
      margin-top: 0;
    }

    ${mediaSmDefault} {
      margin-top: 0;
    }
  }
`;

export const CharacteristicMatches = styled.div<{ backgroundColor?: 'blue' | 'black' }>`
  padding: 3rem 3rem 3rem 6rem;
  border-radius: 0.4rem;

  & > div {
    margin-bottom: 2rem;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: 4.8rem;
      height: 4.8rem;
      left: -4.8rem;
      top: 50%;
      transform: translateY(-50%);
      background: url("/img/check_yellow.svg") center center no-repeat;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  p {
    color: ${gray};
    font-size: 1.6rem;
    line-height: 2rem;
  }

  ${mediaSmDefault} {
    padding: 3rem 11rem;

    & > div {
      &:before {
        left: -7.8rem;
      }
    }
  }

  ${props =>
    props.backgroundColor === 'blue' &&
    css`
      background-color: rgba(${primaryBlueRGB}, 0.4);

      & > div {
        &:before {
          background: url("/img/check_white.svg") center center no-repeat;
        }
      }

      p {
        color: #fff;
      }
    `};

  ${props =>
    props.backgroundColor === 'black' &&
    css`
      background-color: rgba(${darkBaseRGB}, 0.2);
    `};
`;
