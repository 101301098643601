import { Flex, Img, useModal } from '@xcorejs/ui';
import PresentingPageModal from 'components/components/modals/PresentingPageModal';
import * as React from 'react';
import { StyledMenuUser } from './styles';
import { StyledLink } from '../../../atoms/Buttons/styles';
import { createUrl } from '../../../pages/routes';
import withUser from '../../../molecules/withRedux/withUser';
import { IWithUser } from '../../../molecules/withRedux/withUser';
import ExternalSVG from '../../../atoms/ExternalSVG';
import { FC, Fragment } from 'react';

const MenuUser: FC<IWithUser> = ({ user }) =>  {
  const [openModal] = useModal(PresentingPageModal);

  return (
    <StyledMenuUser>
      <div className='userInfo'>
        <StyledLink to={createUrl('app.profile')} className='userImage'>
          <Fragment>
            {user.avatar.endsWith('.svg') ? (
              <ExternalSVG src={user.avatar} className='user-icon' />
            ) : (
              <img src={user.avatar || '/img/default-avatar.png'} className='user-icon' alt='' />
            )}
            {user.child && (
              <Flex
                p='0.2rem'
                bg='kids'
                height='2rem'
                width='2rem'
                position='absolute'
                top='0'
                left='100%'
                transform='translateX(-50%) translateY(-50%)'
                borderRadius='2px'
                onClick={() => openModal({ pageId: 49 })}
              >
                <Img src='/img/child_16x16_sun.png' minWidth='1.6rem !important' minHeight='1.6rem !important' alt='' />
              </Flex>
            )}
          </Fragment>
        </StyledLink>

        <div className='userText'>
          <Flex>
            <StyledLink to={createUrl('app.profile')} className={'userName'}>
              {user.nick !== '' ? user.nick : user.fullname}
            </StyledLink>
          </Flex>

          <StyledLink to={createUrl('app.profile')} className={'userTitle'}>
            {user.eticonName ? user.eticonName : 'Bez etikonu'}
          </StyledLink>
        </div>
      </div>
    </StyledMenuUser>
  );
};

export default withUser<{}>(MenuUser);
