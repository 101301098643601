import { Box } from '@xcorejs/ui';
import { EticiaMeterTheme, white, yellow } from 'components/theme/colors';
import { mediaMdDefault } from 'components/theme/grid';
import styled from 'styled-components';

export type ElementProps = {
  fill?: string;
  strokeWidth?: number;
}

export type CountryProps = ElementProps & {
  _hover?: ElementProps;
}

export const StyledCountry = styled.g`
  stop {
    transition: stop-color 1s ease;
  }

  path {
    transition: fill 1s ease, stroke 1s ease, stroke-width 1s ease;
  }
`;


export const MarkerStyle = styled.g`
  transition: r 0.5s ease, opacity 1s ease, fontSize 0.5s ease;
  pointer-events: none;
`;

export const BoxTransformStyle = styled(Box)`
  width: 100%;
  position: relative;
  overflow: hidden;

  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, ${EticiaMeterTheme.primary} 0%, transparent 10%, transparent 90%, ${EticiaMeterTheme.primary} 100%);
    z-index: 1;
    pointer-events: none;

    ${mediaMdDefault} {
      box-shadow: 0 0 2rem 4rem ${EticiaMeterTheme.primary} inset;
      background: none;
    }
  }

  & > .react-transform-component {
    height: 50rem;
    width: 100%;

    & > .react-transform-element {
      width: 100%;
    }

    ${mediaMdDefault} {
      height: unset;
    }
  }

  ${mediaMdDefault} {
    width: 120rem;
  }
`;

export const SelectStyle = styled.div`
  .Select {
    &.Select-control.Select-multi-value-wrapper.Select-value {
      &.Select-value-label {

      }
    }
    &.is-open .Select-input {
      caret-color: auto;
    }
    
    .Select-input {
      opacity: 1;
      caret-color: transparent;
    }
  }
`;

type StyledForeignObjectProps = {
  selected: boolean;
  visible: boolean;
}

export const StyledForeignObject = styled.foreignObject<StyledForeignObjectProps>`
  &, & > * {
    pointer-events: none;
  }

  --marker-color: ${({ selected }) => selected ? yellow : white};

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: color 1500ms ease, text-decoration 1500ms ease;

    .map-pin {
      width: 10px;
      height: 10px;
      aspect-ratio: 1 / 1;
      margin-bottom: 0.2rem;
      color: var(--marker-color);
    }

    .country-name, .country-value {
      pointer-events: ${({ visible }) => visible ? 'all' : 'none'};
    }

    .country-name {
      font-size: 0.4rem;
      color: var(--marker-color);
      font-weight: ${({ selected }) => selected ? 'bold' : 'normal'};
    }

    .country-value {
      font-size: 0.3rem;
      color: var(--marker-color);
      text-decoration: underline;
      text-decoration-color: var(--marker-color);
    }
  }
`;
