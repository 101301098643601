import { adformRegisterUrl } from 'config/adform';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import Demographic from '../../organisms/Demographic';
import { createUrl } from '../routes';

class RegistrationComplete extends React.Component<RouteComponentProps<any>> {
  public render() {
    const redirect = this.props.location.state && this.props.location.state.redirect;

    return (
      <>
        <Demographic
          afterRegister={() => location.href = redirect || createUrl('app.gameIntro') + '?t=welcome'}
        />
        <iframe src={adformRegisterUrl} style={{ display: 'none' }}></iframe>
      </>
    );
  }
}

export default withRouter(RegistrationComplete);
