import React, { FC } from 'react';
import { SectionHomepage } from 'components/pages/Homepage/styles';
import { VioletTheme, textGreen, blue, yellow, darkenBlue, lightBlue, whiteFade } from 'components/theme/colors';
import Fade from 'react-reveal/Fade';
import { Heading3, Paragraph, Img, Heading2 } from '@xcorejs/ui';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { OliLight } from 'components/theme/fonts';
import styled from 'styled-components';

const Timeline: FC = () => {
  return (
    <Fade ssrReveal bottom duration={1000} distance={'5rem'}>
      <SectionHomepage backgroundColor={VioletTheme.darken} display='flex'>
        <Heading2 color={yellow}>
          <FormattedMessage id={'about-project.history'} />
        </Heading2>

        <Paragraph marginTop='2rem' maxWidth='50rem' color='#FFF' fontFamily={OliLight}>
          <FormattedMessage id={'about-project.history.text'} />
        </Paragraph>

        <Heading2 color='#bdd2f0' marginTop='4rem'>
          <FormattedMessage id={'about-project.now'} />
        </Heading2>

        <Paragraph marginTop='2rem' maxWidth='50rem' color='#FFF' fontFamily={OliLight}>
          <FormattedMessage id={'about-project.now.text'} />
        </Paragraph>

        <Heading2 color={textGreen} marginTop='4rem'>
          <FormattedMessage id={'about-project.future'} />
        </Heading2>

        <Paragraph marginTop='2rem' maxWidth='50rem' color='#FFF' fontFamily={OliLight}>
          <FormattedMessage id={'about-project.future.text'} />
        </Paragraph>

        <StyledQuote>
          <Paragraph marginTop='4rem' fontFamily={OliLight} maxWidth='35rem' color={whiteFade}>
            <FormattedHTMLMessage id={'about-project.quote'} />
          </Paragraph>
        </StyledQuote>
        
        <Img src='/img/eticiam.png' srcSet='/img/eticiam.png, /img/eticiam@2x.png 2x' alt='[etic I am]' marginTop='3rem' />
      </SectionHomepage>
    </Fade>
  );
};
 
export default Timeline;

const StyledQuote = styled.div`
  em {
    display: block;
    line-height: 1.2;
    margin-bottom: 0.5rem;
  }
`;