import React, { FC } from 'react';
import Subnav from 'components/atoms/Subnav';
import NavItem from 'components/atoms/Nav/NavItem';
import { createUrl } from 'components/pages/routes';
import { Route } from 'react-router-dom';
import InterestingComparisonLayout from './InterestingComparisonLayout';

const InterestingComparison: FC = () => {
  const valuesForTwo = createUrl('app.valuesForTwo');
  const personalValues = createUrl('app.personalValues');
  
  return ( 
    <>
      <Subnav>
        <NavItem to={valuesForTwo} label={'valuesForTwo'} exact />
        <NavItem to={personalValues} label={'personalValues'} />
      </Subnav>

      <>
        <Route
          path={valuesForTwo} 
          exact={true}
          render={() => (
            <InterestingComparisonLayout group='multiple' />
          )} />
          
        <Route
          path={personalValues}
          exact={true}
          render={() => (
            <InterestingComparisonLayout group='personal' />
          )} />
      </>
    </>
  );
};
 
export default InterestingComparison;
