import styled from 'styled-components';
import { Heading2, Heading3 } from '../../atoms/Headings';
import { mediaMdDefault, mediaSmDefault } from '../../theme/grid';
import {
  blue,
  darkBaseHEX,
  grayFooter,
  primaryBlue,
  yellow,
  primaryGreen,
  selectColor,
  whiteFade,
  violet,
  primaryGreenOverlay,
  darkenGreenOverlay,
} from '../../theme/colors';
import { BiggerText } from '../../atoms/Text';
import { Col, Grid, Row } from 'react-styled-flexboxgrid';
import { StyledPrimaryButtonLink } from '../../atoms/Buttons';
import { StyledSecondaryButtonLink } from '../../atoms/Buttons/styles';
import { StyledPersonBox } from '../../molecules/PersonBox/style';

interface IStyledTextBox {
  background?: string;
}

export const StyledTextBox = styled.section<IStyledTextBox>`
  display: block;
  text-align: center;
  padding-bottom: 4rem;

  ${mediaMdDefault} {
    padding-bottom: 10rem;
  }

  ${Heading2} {
    padding-top: 4rem;
    font-size: 2.4rem;
    line-height: 3.1rem;
    //font-size: 3.2rem;
    //line-height: 4.1rem;

    ${mediaMdDefault} {
      font-size: 4.4rem;
      line-height: 4.4rem;
    }

    ${mediaMdDefault} {
      padding-top: 10rem;
    }
  }

  ${BiggerText} {
    max-width: 85rem;
    margin: 3rem auto 0;
    text-align: center;

    ${mediaMdDefault} {
      //margin: 6rem auto 0;
    }
  }

  .list-wrapper {
    display: inline-block;

    ${mediaSmDefault} {
      padding-left: 3rem;
    }

    .smaller {
      display: block;
      font-size: 1.6rem;
    }
  }

  li {
    margin-top: 2rem;
    color: #191d2c;
    font-size: 2rem;
    line-height: 2.6rem;
    display: flex;
    align-items: center;
    text-align: left;

    ${mediaSmDefault} {
      margin-top: 3rem;
    }

    ${mediaMdDefault} {
      text-align: center;
    }

    span {
      position: relative;
    }

    p {
      text-align: left;

      .small {
        font-size: 1.6rem;
      }
    }

    .list {
      min-width: 4rem;
      max-width: 4rem;
      height: 4rem;
      vertical-align: middle;
      border-radius: 50%;
      margin-right: 2rem;
      display: inline-block;
      text-align: center;

      &:before {
        content: "";
        display: inline-block;
        height: 1.5rem;
        width: 0.8rem;
        border-top: 2px solid #ffde71;
        border-left: 2px solid #ffde71;
        transform: rotate(-140deg) translateY(-50%);
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -1.7rem;
        margin-left: 0.1rem;
      }
    }
  }

  &.green {
    background: ${props => props.theme.primary};

    ${Heading2} {
      color: #fff;
    }

    ${BiggerText} {
      color: #fff;
    }

    ${mediaMdDefault} {
      background: ${props => props.theme.primary}
        ${props => (props.background ? 'url("/img/backgrounds/' + props.background + '.jpg")' : 'transparent')} center
        top no-repeat;
      background-attachment: fixed;
      background-size: cover;
    }
  }

  &.dark-green {
    background: ${props => props.theme.primary};
    background: radial-gradient(circle at top, ${primaryGreenOverlay} 0%, ${darkenGreenOverlay} 100%);

    ${Heading2} {
      color: #fff;

      ${mediaSmDefault} {
        padding-top: 12rem;
      }
    }

    ${BiggerText} {
      color: #fff;
    }
  }

  &.greenText {
    background: ${props => props.theme.primary};

    ${mediaMdDefault} {
      background: #fff
        ${props => (props.background ? 'url("/img/backgrounds/' + props.background + '.jpg")' : 'transparent')} center
        top no-repeat;
      background-attachment: fixed;
      background-size: cover;

      ${Heading2} {
        color: ${primaryGreen};
      }

      ${BiggerText} {
        color: ${darkBaseHEX};
      }
    }
  }

  &.blue {
    background: radial-gradient(circle at top, #247bcb 0%, #1769b4 100%);

    *::selection {
      color: #fff;
      background-color: ${primaryBlue};
    }

    ${Heading2} {
      color: #fff;
    }

    ${BiggerText} {
      color: #fff;
    }

    ${mediaMdDefault} {
      background: #1769b4
        ${props => (props.background ? 'url("/img/backgrounds/' + props.background + '.jpg")' : 'transparent')} center
        top no-repeat;
      background-attachment: fixed;
      background-size: cover;
    }

    ${StyledPersonBox} {
      margin-top: 3rem;

      ${mediaMdDefault} {
        margin-top: 6rem;
      }
    }
  }

  &.blueText {
    background: #fff;

    *::selection {
      color: #fff;
      background-color: ${primaryBlue};
    }

    ${Heading2} {
      color: ${primaryBlue};
    }

    ${BiggerText} {
      color: ${primaryBlue};
    }

    .list {
      background: ${primaryGreen};

      &:before {
        border-color: #fff;
      }
    }
  }

  &.white {
    background: linear-gradient(180deg, #252b42 0%, #363e5b 100%);

    ${Heading2} {
      color: #fff;
    }

    ${BiggerText} {
      color: #fff;

      a {
        color: ${whiteFade};

        span {
          transition: border 0.3s;
          border-bottom: 1px solid ${whiteFade};
        }

        &:hover {
          span {
            border-bottom: 1px solid transparent;
          }
        }
      }
    }

    ${mediaMdDefault} {
      background: #f4f4f6
        ${props => (props.background ? 'url("/img/backgrounds/' + props.background + '.jpg")' : 'transparent')} center
        top no-repeat;
      background-attachment: fixed;
      background-size: cover;
    }
  }

  &.light-white {
    background: #fff;
    text-align: center;

    ${mediaMdDefault} {
      text-align: left;
    }

    ${Heading2} {
      line-height: 4.8rem;

      ${mediaMdDefault} {
        margin-bottom: 4rem;
        text-align: center;
      }
    }

    ${mediaMdDefault} {
      background: #fff
        ${props => (props.background ? 'url("/img/backgrounds/' + props.background + '.jpg")' : 'transparent')} center
        top no-repeat;
      background-attachment: fixed;
      background-size: cover;
    }

    .text-green {
      margin-bottom: 2rem;

      ${mediaMdDefault} {
        margin-bottom: 0;
      }

      ${Heading2} {
        color: ${primaryGreen};
      }

      .list {
        background: ${primaryGreen};
      }
    }

    .text-blue {
      ${Heading2} {
        color: ${primaryBlue};
      }

      .list {
        background: ${primaryBlue};
      }
    }
  }

  &.partners {
    background: #fff;
    padding-bottom: 0;

    a {
      & > div {
        ${mediaMdDefault} {
          margin-top: 3rem;
        }
      }
    }

    ${Heading2} {
      color: ${primaryGreen};
    }

    ${Heading3} {
      margin-top: 4rem;
      color: ${darkBaseHEX};
      font-weight: 500;

      ${mediaMdDefault} {
        margin-top: 8rem;
      }
    }

    .institut {
      margin-top: 3rem;

      ${mediaMdDefault} {
        margin-top: 8rem;
      }
    }

    .partners-others {
      ${Row as any} {
        justify-content: center;
      }

      ${Col as any} {
        min-height: 8.8rem;
        align-items: center;
        justify-content: center;
        display: flex;

        ${mediaSmDefault} {
          min-height: 16.6rem;
        }
      }
    }

    img {
      display: block;
      margin: 0 auto;
      max-width: 85%;

      ${mediaSmDefault} {
        max-width: none;
      }
    }
  }

  &.partners-hp {
    a {
      display: block;

      span {
        margin-top: 3rem;
        display: block;
        color: ${selectColor};
        font-size: 2rem;
        line-height: 2.5rem;

        ${mediaSmDefault} {
          margin-top: 7rem;
        }
      }
    }

    img {
      display: block;
      margin: 0 auto;
      max-width: 85%;

      ${mediaSmDefault} {
        max-width: none;
        height: 6rem;
        max-height: 8.2rem;
      }

      ${mediaMdDefault} {
        height: 8.2rem;
      }
    }

    .partners-logo {
      padding-top: 4rem;

      ${mediaSmDefault} {
        padding-top: 7rem;
        margin-bottom: 3rem;
      }

      ${Col as any} {
        margin-top: 6rem;

        ${mediaSmDefault} {
          margin-top: 0;
        }

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

  &.game {
    background: #fff;
    padding-bottom: 3rem;

    ${mediaSmDefault} {
      padding-bottom: 6rem;
    }

    ${Heading2} {
      color: ${primaryGreen};
    }

    .heading3 {
      margin-top: 3rem;
      color: ${primaryGreen};
      font-weight: 500;
    }

    ${BiggerText} {
      color: ${darkBaseHEX};

      a {
        color: ${primaryGreen};

        span {
          transition: border 0.3s;
          border-bottom: 1px solid ${primaryGreen};
        }

        &:hover {
          span {
            border-bottom: 1px solid transparent;
          }
        }
      }
    }

    ${StyledPrimaryButtonLink} {
      margin-top: 2rem;
      display: block;

      ${mediaSmDefault} {
        display: inline-block;
      }

      ${mediaMdDefault} {
        margin-top: 4rem;
        margin-bottom: 4rem;
      }
    }
  }

  &.purple {
    background: radial-gradient(circle at top, #6624cb 0%, #5617b6 100%);

    ${mediaMdDefault} {
      ${Grid as any} {
        width: 100%;
      }
    }

    ${Heading2} {
      margin-bottom: 6rem;
      color: #fff;
    }
  }

  &.violet {
    background: radial-gradient(circle at top, #6624cb 0%, #5617b6 100%);

    *::selection {
      color: #fff;
      background-color: ${violet};
    }
  }

  &.black {
    background: linear-gradient(180deg, #252b42 0%, #363e5b 100%);

    *::selection {
      color: #fff;
      background-color: #9898ab;
    }

    ${Heading2} {
      margin-bottom: 3rem;
      color: #fff;
    }

    ${Heading3} {
      margin-bottom: 2rem;
      font-size: 2.6rem;
      letter-spacing: 0.5px;
      line-height: 3.3rem;
      text-align: center;
      font-weight: 500;
      color: ${yellow};

      &.blue {
        color: ${blue};
      }
    }

    ${BiggerText} {
      color: #fff;
      margin-top: 0;
      margin-bottom: 4rem;

      a {
        color: ${whiteFade};

        span {
          transition: border 0.3s;
          border-bottom: 1px solid ${whiteFade};
        }

        &:hover {
          span {
            border-bottom: 1px solid transparent;
          }
        }
      }
    }

    ${StyledSecondaryButtonLink} {
      margin-bottom: 6rem;
    }

    ${mediaMdDefault} {
      background: #252b42
        ${props => (props.background ? 'url("/img/backgrounds/' + props.background + '.jpg")' : 'transparent')} center
        top no-repeat;
      background-attachment: fixed;
      background-size: cover;
    }
  }

  &.dark-blue {
    background-color: #141b49;
  }

  &.superDarkBlue {
    background-color: #130f28;
  }

  .supports {
    color: ${grayFooter};

    strong {
      color: ${darkBaseHEX};
    }
  }

  .videos {
    ${Col} {
      cursor: pointer;

      img {
        border-radius: 0.4rem;
        opacity: 0.6;
        transition: opacity 300ms;
      }

      &:hover {
        img {
          opacity: 1;
        }
      }
    }
  }
`;
