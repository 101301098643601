import * as React from 'react';
import { IInfoPopup } from '.';
import { IRawInfoPopup } from './index';
import { _switch } from 'utils/option';
import { connect } from 'react-redux';
import { openHtmlInfoPopup, openTextInfoPopup, openRawInfoPopup } from '../../../../store/Popup/infoPopups';

interface IButton {
  onClick: () => any;
}

const Button: React.FC<IButton> = ({ onClick, children }) => (
  <span className={'main-popup-button'} onClick={() => onClick()}>
    {children}
  </span>
);

interface IPopupButton {
  type: 'html' | 'text' | 'raw';
}

const mapDispatchToPropsButton = (dispatch: any, props: IPopupButton & (IInfoPopup | IRawInfoPopup)) => ({
  onClick: () =>
    _switch({
      html: openHtmlInfoPopup(props as IInfoPopup),
      text: openTextInfoPopup(props as IInfoPopup),
      raw: openRawInfoPopup(props as IRawInfoPopup),
    })()(props.type)(dispatch),
});

export const InfoPopupButton = connect(null, mapDispatchToPropsButton)(Button);
