import * as React from 'react';
import { FC } from 'react';
import { StyledFaqBox } from './styles';
import { Heading3 } from '../../../../atoms/Headings';

export interface IFaqBox {
  iconColor?: 'green' | 'blue' | 'gray' | '';
  heading?: string;
  icon?: string;
}

const FaqBox: FC<IFaqBox> = ({ iconColor, heading, icon, children }) => {
  return (
    <StyledFaqBox iconColor={iconColor}>
      <img src={icon} alt={heading} />

      <Heading3>{heading}</Heading3>

      {children}
    </StyledFaqBox>
  );
};

export default FaqBox;
