import { Link } from 'react-router-dom';
import React, { FC } from 'react';
import { createUrl } from 'components/pages/routes';
import { Flex, Img, Heading3, Paragraph } from '@xcorejs/ui';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { yellowWhite, VioletTheme } from 'components/theme/colors';

interface GiftEticiaPlusProps {
  noMargin?: boolean;
  linkTo?: string;
  lightText?: boolean;
}

const GiftEticiaPlus: FC<GiftEticiaPlusProps> = ({ noMargin, linkTo, lightText }) => {
  return ( 
    <Link to={linkTo || createUrl('eticiaPlus')}>
      <Flex 
        justifyContent='center'
        alignItems='center'
        mt={noMargin ? 'unset' : '4rem'}
      >
        <Img
          src='/img/homepage/dárek.png'
          alt='Dárek'
          height='6rem'
          width='6rem'
        />

        <Flex
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          ml='1rem'
        >
          <Heading3Styled
            color={lightText ? yellowWhite :'black'}
            lineHeight='1.25'
            fontSize={{ _: '1.5rem', md: '2rem' }}
          >
            <FormattedHTMLMessage id='giftEticia.heading' />
            <FormattedHTMLMessage id='giftEticia.price' />
          </Heading3Styled>
          
          <Paragraph
            color={lightText ? VioletTheme.text :'#e5097f'}
            mt='0.5rem'
            fontSize={{ _: '1.2rem', md: '1.5rem' }}
          >
            <FormattedMessage id='giftEticia.text' />
          </Paragraph>
        </Flex>
      </Flex>
    </Link>
  );
};
 
export default GiftEticiaPlus;

const Heading3Styled = styled(Heading3)`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
