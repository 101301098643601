import * as React from 'react';
import { ReactNode, useState } from 'react';
import SVGarrow from '../../atoms/SVGIcons/SVGarrow';
import { StyledImgSlider } from './styles';
import { StyledFakeLinkBorder } from '../../atoms/Buttons/styles';
import { FormattedMessage } from 'react-intl';

type ImgSliderProps = {
  imgUrls?: string[];
  content?: ReactNode[];
  customNextButton?: ReactNode;
  hideNextButton?: boolean;
}

const ImgSlider: React.FC<ImgSliderProps> = ({
  imgUrls,
  customNextButton,
  hideNextButton,
  content,
}) => {
  const [state, setState] = useState<{ index: number }>({
    index: 0,
  });

  const length = imgUrls ? imgUrls.length : content ? content.length : 0;

  return (
    <StyledImgSlider>
      <div>
        <span
          className={'before'}
          onClick={() =>
            setState({
              index: state.index - 1 < 0 ? length - 1 : state.index - 1,
            })
          }
        >
          <SVGarrow />
        </span>

        <div className='slide'>
          {content ? content[state.index] : imgUrls ? <img src={imgUrls[state.index]} alt='' /> : <></>}
        </div>

        <span
          className={'after'}
          onClick={() =>
            setState({
              index: state.index + 1 >= length ? 0 : state.index + 1,
            })
          }
        >
          <SVGarrow />
        </span>
      </div>

      {!hideNextButton && (
        customNextButton ? (
          <span
            onClick={() =>
              setState({
                index: state.index + 1 >= length ? 0 : state.index + 1,
              })
            }
          >
            {customNextButton}
          </span>
        ) : (
          <StyledFakeLinkBorder
            color='blue'
            onClick={() =>
              setState({
                index: state.index + 1 >= length ? 0 : state.index + 1,
              })
            }
          >
            <FormattedMessage id={'imgSlider.next'} />
          </StyledFakeLinkBorder>
        )
      )}
    </StyledImgSlider>
  );
};

export default ImgSlider;
