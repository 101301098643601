import { jsonFetch } from '../../../utils/fetch';
import { LOGIN } from 'config/apiRoutes';

export interface ILoginState {
  email: string;
  password: string;
  rememberPassword: boolean;
  error: string | null;
}

export const login = (userInfo: ILoginState) =>
  jsonFetch(LOGIN, {
    method: 'POST',
    credentials: 'same-origin',
    body: JSON.stringify({ ...userInfo }),
  });

export const _login = async (userInfo: ILoginState, onSuccess: () => any, onError: (e: string) => any) => {
  try {
    await jsonFetch(LOGIN, {
      method: 'POST',
      credentials: 'same-origin',
      body: JSON.stringify({ ...userInfo }),
    });
    onSuccess();
  } catch (e) {
    const json = await e.data.json();
    if (json.error) {
      onError(json.error);
    }
  }
};

export const loginSocial = (url: string) =>
  jsonFetch<any>(url, { method: 'POST' }).then(data => {
    if (!data.redirect) {
      throw data;
    }
    return data.redirect as string;
  });
