import { IStringMap } from 'utils';
import { fetchTask, normalize } from 'utils/helpers';
import { ETICONS } from 'config/apiRoutes';
import { IRescource, rescource, createRescourceActions } from 'utils/rescource';
import { createReducer } from 'redux-act';

export interface IEticon {
  id: string;
  name: string;
  icon: string;
  avatar: string;
  text: string;
  text2: string;
  figurine1Icon: string | null;
  figurine1Description: string | null;
  figurine2Icon: string | null;
  figurine2Description: string | null;
  totalCountUsage: number;
  totalPercentageUsage: number;
  malePercentageUsage: number;
  femalePercentageUsage: number;
  commonGroup: string;
}

export const eticonsInitState: IRescource<IStringMap<IEticon>> = rescource<IStringMap<IEticon>>('init', null);

const actions = createRescourceActions<IEticon[]>('eticons');

export const fetchEticons = () => fetchTask<IEticon[]>(ETICONS, actions.fetching, actions.success, actions.fail);

export const eticonsReducer = createReducer(
  {
    [actions.fetching.getType()]: state => ({
      ...state,
      ...rescource('fetching', null),
    }),
    [actions.success.getType()]: (state, payload: IEticon[]) => ({
      ...state,
      ...rescource(
        'success',
        normalize(payload, itm => itm.id),
      ),
    }),
    [actions.fail.getType()]: state => ({
      ...state,
      ...rescource('fail', null),
    }),
  },
  eticonsInitState,
);
