import { Box, Button as XButton, ButtonProps, ExtendedButtonProps, Flex, FlexProps, Icon, IconProps, Tag, useModal } from '@xcorejs/ui';
import * as React from 'react';
import { FC, Fragment, ReactNode } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import PlusIcon from '../components/icons/12/plus.svg';
import { PlusTheme } from '../theme/colors';
import EticiaPlusModalNew from './modals/EticiaPlusModalNew';

export type EticiaButtonProps = ExtendedButtonProps & {
  plusTag?: boolean;
  _buttonBox?: FlexProps;
}

export const PrimaryButton: FC<EticiaButtonProps> = ({ plusTag, ...props }) => {
  const [debounced] = useDebouncedCallback(props.onClick || (() => undefined), 100);

  return (
    plusTag ? (
      <Box position='relative' display='flex' alignItems='center'>
        <XButton {...props} onClick={(e:any) => debounced(e)} />
        <Tag
          position='relative'
          display='inline-flex'
          bg={PlusTheme.primary}
          marginLeft='-4.6rem'
          transform='translateY(-100%)'
        >
          Eticia Plus
        </Tag>
      </Box>
    ) : (
      <XButton {...props} onClick={(e:any) => debounced(e)} />
    )
  );
};

export const SecondaryButton: FC<EticiaButtonProps> = ({ ...props }) => {
  return (
    <XButton variant='outline' {...props} />
  );
};

export const PremiumButton: FC<ExtendedButtonProps & { _icon?: IconProps; customChildren?: ReactNode }> = (
  {
    _icon,
    customChildren,
    ...props
  }
) => {
  // const [open] = useModal(EticiaPlusModal);
  const [open] = useModal(EticiaPlusModalNew);

  return (
    <XButton
      background={PlusTheme.primary}
      borderRadius='3.5rem'
      border='0.2rem solid'
      borderColor='#d8aaba'
      px='2.4rem'
      py='1.1rem'
      height='4.2rem'
      color='rgba(255, 255, 255, 1)'
      fontSize='1.5rem'
      _hover={{
        bg: PlusTheme.darken
      }}
      _focus={{
        outline: 0,
        bg: PlusTheme.darken
      }}
      onClick={open}
      {...props}
    >
      {customChildren ? customChildren : (
        <Fragment>
          <Flex alignItems='center' mr='0.9rem'>
            <Icon svg={<PlusIcon />} {..._icon} />
          </Flex>

          Eticia Plus
        </Fragment>
      )}
    </XButton>
  );
};

export const IconButton: FC<ButtonProps & { icon?: ReactNode; _icon?: IconProps}> = ({ icon, _icon, ...props }) => {
  return (
    <XButton
      padding='0'
      width='3rem'
      height='3rem'
      bg='rgba(11, 12, 28, 0.5)'
      {...props}
    >
      <Icon svg={icon} {..._icon} />
    </XButton>
  );
};
