import * as React from 'react';
import { StyledAbout } from '../../styles';
import { Footer } from '../../../organisms/Layout/Footer';
import PartnersWithInfo from '../../../organisms/InfoSlides/Partners/PartnersWithInfo';
import Team from '../../../organisms/InfoSlides/Team/component';
import { useTheme, IThemeProps } from '../../../templates/General/ThemeContext';
import Subnav from '../../../atoms/Subnav';
import Timeline from 'components/pages/About/Components/Timeline';
import AboutEticia from 'components/pages/About/Components/AboutEticia';

const About: React.FC<IThemeProps> = ({ theme }) => (
  <>
    <Subnav />

    <StyledAbout>
      <AboutEticia />
      <Timeline />
      <Team />
      <PartnersWithInfo />
      <Footer />
    </StyledAbout>
  </>
);

export default useTheme(About);
