import * as React from 'react';
import { FC, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Paragraph, TypographyProps } from '@xcorejs/ui';
import { remainingDayTime } from './ValuesAfterYear/remainingDaysCount';

type TribalTimeProps = {
  doneAt: number;
} & TypographyProps;

const TribalTime: FC<TribalTimeProps> = ({ doneAt, ...props }) => {

  const time = remainingDayTime(doneAt);
  
  return (
    <Paragraph display='flex' alignItems='center' justifyContent='center' color='#fff' {...props}>
      {time >= 0 ? (
        <Fragment>
          <FormattedMessage id={'valueCenter.count.toCompare'} /> {' '}&nbsp;
          <Paragraph
            display='inline-block'
            color='yellow'
          >
            <FormattedMessage id={'valueCenter.count.toCompare2'} />{' '}
            <FormattedMessage
              id={'valueCenter.count.days'}
              values={{
                count: time,
              }}
            />
          </Paragraph>
        </Fragment>
      ) : (
        <Fragment>
          <FormattedMessage id={'valueCenter.count.toCompare.doItAgain'} />&nbsp;
          <Paragraph as={'span' as any} color='yellow'>
            <FormattedMessage id={'valueCenter.count.toCompare.doItAgain2'} />
          </Paragraph>
        </Fragment>
      )}
    </Paragraph>
  );
};

export default TribalTime;
