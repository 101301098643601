import { Box, Flex, Heading2, Heading3, Icon, Img, Link, Paragraph, Tag, Typography, useModal } from '@xcorejs/ui';
import { GameContext } from 'components/App';
import ValueRatingModal from 'components/pages/App/Journal/Journal/ValueRatingModal';
import { AllValuesModal } from 'components/pages/App/Result/PartnerTestResult/DefaultPages/DefaultValues';
import { ITestTemplate } from 'components/pages/Sandbox/TestFactory';
import { ColoredThemes } from 'components/theme/colors';
import { IValueTestResultDetail } from 'config/api/valueTestResult';
import * as React from 'react';
import { useContext } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import Fade from 'react-reveal/Fade';
import { Col, Grid, Row } from 'react-styled-flexboxgrid';
import { ILifeValue } from '../../../../../store/Entities/LifeValues';
import { IStringMap } from '../../../../../utils';
import { PrimaryButtonLink } from '../../../../atoms/Buttons';
import { StyledLinkBorder } from '../../../../atoms/Buttons/styles';
import { PremiumButton } from '../../../../components/Button';
import { SemaforIcon } from '../../../../components/icons/16/semafor.svg';
import MyValues from '../../../../organisms/MyValues';
import TestResult from '../../../../templates/TestResult';
import { createUrl } from '../../../routes';
import SimilarValues from './components/SimilarValues';
import { StyledTestResultValues } from './styles';

interface ITestResultValues {
  result: IValueTestResultDetail;
  values: IStringMap<ILifeValue>;
  state: any;
  template: ITestTemplate;
  resultId: string;
}

const TestResultValues: React.FC<ITestResultValues> = ({ result, state, values, template, resultId }) => {
  const { eticiaPlus } = useContext(GameContext);
  const [openAllValueModal] = useModal(AllValuesModal);
  const [close] = useModal();
  const [openValueRating] = useModal(ValueRatingModal);
  const plusOrVisible = eticiaPlus || template.info.resultFullWithoutEticiaPlusVisible;

  const theme = template.theme !== 'blue' ? ColoredThemes[template.theme] : ColoredThemes.middleStatisticsTheme;

  return (
    <TestResult>
      <Grid>
        <StyledTestResultValues>
          <Row style={{ justifyContent: 'center' }}>
            <Col xs={12}>
              <Fade ssrReveal bottom duration={1000} distance={'5rem'}>
                <Flex flexDirection='column' alignItems='center' mb='3rem'>
                  <Tag
                    display='block'
                    mx='auto'
                    bg='transparent'
                    border='2px solid rgba(231, 230, 207, 0.5)'
                    py='0.4rem'
                    width='24rem'
                    justifyContent='center'
                    whiteSpace='nowrap'
                    overflow='hidden'
                    textOverflow='ellipsis'
                    textAlign='center'
                  >
                    {template.name}
                  </Tag>
                </Flex>

                <Heading2 mt='2rem' textAlign='center' fontWeight={400}>
                  {template.info.resultValuesHeader}
                </Heading2>

                <Paragraph mt='1rem' color='rgba(255, 255, 255, 0.7)' textAlign='center'>
                  {template.info.resultValuesDescription}
                </Paragraph>
              </Fade>
            </Col>

            <Col
              xs={12}
              md={7}
              style={{
                paddingLeft: 0,
                paddingRight: 0,
              }}>
              <Fade ssrReveal bottom duration={1000} distance={'5rem'}>
                <Heading3 mt='4rem' textAlign='center'>
                  <FormattedMessage id={'test.results.values.heading3'} />
                </Heading3>
              </Fade>

              <Flex justifyContent='center' className='my-values' mt='2rem' width='100%'>
                <MyValues
                  theme={ColoredThemes[template.theme]}
                  result={result}
                  cols={2}
                  rows={5}
                />

                {/*
                <Fade ssrReveal bottom duration={1000} distance='5rem' delay={400}>
                  <FAQPopup popup={ValuesDetail} className='main-popup-faq-2'>
                    <ExternalSVG src='/img/ico/game-question.svg' />
                    <FormattedMessage id='test.results.values.faq' />
                  </FAQPopup>
                </Fade>
                */}
              </Flex>

              <Fade ssrReveal bottom duration={1000} distance='5rem'>
                {!template.info.resultValuesShowOnlyFirstTen ? (
                  <Box mb='3rem'>
                    {plusOrVisible ? (
                      <Box textAlign='center' mt='2rem'>
                        <Link
                          variant='underline'
                          onClick={() => openAllValueModal({
                            values: result,
                            mapValues: values,
                            onClose: close,
                            customVisible: template.info.resultFullWithoutEticiaPlusVisible,
                            theme: theme,
                          })}
                        >
                          <FormattedMessage id='test.results.values.showAll' />
                        </Link>
                      </Box>
                    ) : (
                      <Box textAlign='center' mt='2rem'>
                        <Paragraph>
                          <FormattedHTMLMessage id='test.results.values.showAllPremium' />
                        </Paragraph>

                        <PremiumButton mt='2rem' whiteSpace='nowrap' />
                      </Box>
                    )}
                  </Box>
                ) : (
                  <Typography mt='2rem' mb='3rem'>
                    {template.info.resultValuesWhyOnlyTenDescription}
                  </Typography>
                )}
              </Fade>
            </Col>

            {(template.info.resultValuesImage || template.info.semaphoreVisible) && (
              <Col xs={12} md={5}>
                <Box mb='3rem'>
                  {template.info.resultValuesImage && (
                    <Flex justifyContent='center'>
                      <Img src={template.info.resultValuesImage} alt='' />
                    </Flex>
                  )}

                  {template.info.semaphoreVisible && (
                    <Flex
                      onClick={() =>
                        openValueRating({
                          id: resultId,
                          onAction: close,
                        })
                      }
                      flexDirection='column'
                      alignItems='center'
                      justifyContent='center'
                      mt='3rem'
                      cursor='pointer'
                    >
                      <Icon svg={<SemaforIcon />} fill='#fff'  />

                      <Link v='underline' color='yellow' borderColor='yellow' mt='1rem'>
                        {template.info.semaphoreHeader}
                      </Link>
                    </Flex>
                  )}
                </Box>
              </Col>
            )}
          </Row>

          {template.info.resultStatisticsVisible && (
            <Fade ssrReveal bottom duration={1000} distance='5rem'>
              <Row>
                <Col xs={12}>
                  <Flex justifyContent='center' flexDirection='column' mt='6rem'>
                    <Heading2 textAlign='center' fontWeight={400}>
                      {template.info.resultStatisticsHeader}
                    </Heading2>

                    <Paragraph mt='1rem' textAlign='center'>
                      {template.info.resultStatisticsDescription}
                    </Paragraph>
                  </Flex>
                </Col>
                <Col
                  xs={12}
                  style={{
                    paddingLeft: 0,
                    paddingRight: 0,
                  }}>
                  <Flex maxWidth='85rem' alignItems='flex-start' mx='auto' justifyContent='center' mt='3rem'>
                    <Flex justifyContent='center'>
                      <SimilarValues resultId={result.id} />
                    </Flex>

                    {/* <Flex justifyContent='center' mx='0.5rem'>
                      <Fetch url={STATISTICS_GROUP_MATCH_TEST(result.id)}>
                        {_switch({
                          success: (statistics: IStatistic[]) =>
                            statistics.length > 0 ? (
                              <StatisticsCard
                                heading={<FormattedHTMLMessage id='statistics.site.funny.result5.head1' />}
                                paragraph={<FormattedHTMLMessage id='statistics.site.funny.result5.text1' />}
                                _heading={{ color: 'yellow', fontWeight: 800 }}
                                noSubheading
                                modalComponent={() => <SelfDestructionModal />}
                                onlyPlusOrGame
                                minGameLevelToSee={8}
                              >
                                <Flex alignItems='flex-start' justifyContent='space-evenly' mt='2rem' {...{ style: { gap: '2rem' } }}>
                                  <CircleGroup
                                    mostCommon
                                    name={statistics.filter(s => s.groupCategory !== null && s.groupCategory.name === '2 hodiny týdně navíc').reduce((max, stat) => max.value > stat.value ? max : stat).title
                                  + (statistics.filter(s => s.groupCategory !== null && s.groupCategory.name === '2 hodiny týdně navíc').reduce((max, stat) => max.value > stat.value ? max : stat).perex !== null
                                    ? ', ' + statistics.filter(s => s.groupCategory !== null && s.groupCategory.name === '2 hodiny týdně navíc').reduce((max, stat) => max.value > stat.value ? max : stat).perex : '')}
                                    colorScheme='green'
                                    img={statistics.filter(s => s.groupCategory !== null && s.groupCategory.name === '2 hodiny týdně navíc').reduce((max, stat) => max.value > stat.value ? max : stat).image}
                                    onlyPlusOrGame
                                  />
                                  <CircleGroup
                                    leastCommon
                                    name={statistics.filter(s => s.groupCategory !== null && s.groupCategory.name === '2 hodiny týdně navíc').reduce((max, stat) => max.value < stat.value ? max : stat).title
                                  + (statistics.filter(s => s.groupCategory !== null && s.groupCategory.name === '2 hodiny týdně navíc').reduce((max, stat) => max.value < stat.value ? max : stat).perex !== null
                                    ? ', ' + statistics.filter(s => s.groupCategory !== null && s.groupCategory.name === '2 hodiny týdně navíc').reduce((max, stat) => max.value < stat.value ? max : stat).perex : '')}
                                    colorScheme='gray'
                                    img={statistics.filter(s => s.groupCategory !== null && s.groupCategory.name === '2 hodiny týdně navíc').reduce((max, stat) => max.value < stat.value ? max : stat).image}
                                    onlyInterestGroup
                                  />
                                </Flex>
                              </StatisticsCard>
                            ) : <Fragment />
                          ,
                          fetching: () => (<LoadingSpinnerLocal />),
                        })()}
                      </Fetch>
                    </Flex> */}
                  </Flex>
                </Col>
                <Col xs={12}>
                  <Paragraph mt='2rem' color='rgba(255, 255, 255, 0.7)' textAlign='center'>
                    <FormattedMessage id='test.results.values.statisticsDescription' />
                  </Paragraph>

                </Col>
              </Row>
            </Fade>
          )}
        </StyledTestResultValues>

        <div className='btn-wrap'>
          <Fade ssrReveal top duration={1000} delay={400} distance='5rem'>
            <PrimaryButtonLink
              to={{
                pathname: createUrl('app.testResult', { id: resultId }) + '/summary',
                state,
              }}
            >
              <FormattedMessage id='results.next' />
            </PrimaryButtonLink>

            <div>
              <StyledLinkBorder
                to={{
                  pathname: createUrl('app.testResult', { id: resultId }) + '/detail',
                  state,
                }}
              >
                <FormattedMessage id={'test.result.back'} />
              </StyledLinkBorder>
            </div>
          </Fade>
        </div>
      </Grid>
    </TestResult>
  );
};

export default TestResultValues;
