import { openPopup } from '../Popup';
import {
  htmlInfoPopup,
  textInfoPopup,
  rawInfoPopup,
  IRawInfoPopup,
  IInfoPopup,
} from 'components/organisms/MainPopup/InfoPopup';

export const openHtmlInfoPopup = (data: IInfoPopup) => openPopup(htmlInfoPopup(data));

export const openTextInfoPopup = (data: IInfoPopup) => openPopup(textInfoPopup(data));

export const openRawInfoPopup = (data: IRawInfoPopup) => openPopup(rawInfoPopup(data));
