import { VALUE_TEST_CHANGE_NOTE } from 'config/api/valueTestResult';
import { jsonFetch } from 'utils/fetch';

export const putNote = (valueTestResultId: string, note: string) =>
  jsonFetch(VALUE_TEST_CHANGE_NOTE(valueTestResultId), {
    method: 'PUT',
    body: JSON.stringify({ note }),
  });

// export const loadSemaphore = (testResultId: string) =>
//   jsonFetch<number[]>(SEMAPHORE(testResultId), {
//     method: 'GET',
//   });

// export const saveSemaphore = (testResultId: string, values: number[]) =>
//   jsonFetch(SEMAPHORE(testResultId), {
//     method: 'POST',
//     body: JSON.stringify(values)
//   });
