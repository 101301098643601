import * as React from 'react';
import SVGclose from '../../SVGIcons/SVGclose';
import { LevelUp4 } from './styles';
import { Heading2 } from '../../Headings';
import { SecondaryButton } from '../../Buttons';
import { CustomText } from '../../Text';
import { FormattedMessage } from 'react-intl';
import QuestObjective from '../../../organisms/QuestLog/QuestObjective';
import { IWrapIntl } from '../../wrapIntl';
import wrapIntl from '../../wrapIntl';
import { PopupComponent } from 'store/Popup';

const Level4: PopupComponent<IWrapIntl> = ({ formatMsg, resolve }) => {
  return (
    <>
      <LevelUp4>
        <div className='content'>
          <a className='close' onClick={() => resolve()}>
            <SVGclose />
          </a>

          <img src='/img/popup/hra-lvl-4.png' alt='' />
          <Heading2>
            <FormattedMessage id='popupLvl.4.heading' />
          </Heading2>

          <CustomText>
            <FormattedMessage id='popupLvl.4.text' />
          </CustomText>

          <CustomText>
            <strong>
              <FormattedMessage id={'popupLvl.next.4'} />
            </strong>
          </CustomText>

          <div className='next'>
            <QuestObjective
              icon={'/img/ico/tests/surveys.svg'}
              color='transparent'
              title={formatMsg('questlog.surveys.title')}
              text={formatMsg('questlog.surveys.text')}
              icoBackground={'green'}
            />
          </div>

          <SecondaryButton onClick={() => resolve()}>
            <FormattedMessage id={'popupLvl.btn'} />
          </SecondaryButton>
        </div>
      </LevelUp4>
    </>
  );
};

export default wrapIntl(Level4);
