import * as React from 'react';
import { FC, useState } from 'react';
import { Box, Paragraph, Heading1, Flex } from '@xcorejs/ui';
import { FormattedMessage } from 'react-intl';
import { lightGrayBG } from 'components/theme/colors';
import Fade from 'react-reveal/Fade';
import { SectionHomepage, Button } from '../styles';
import styled from 'styled-components';
import { mediaMdDefault } from 'components/theme/grid';
import { valuesData as data } from './Carousel/data';
import FiveItemCarousel from 'components/components/Carousel/fiveItemCarousel';
import { useHistory, Link } from 'react-router-dom';
import GiftEticiaPlus from 'components/atoms/GiftEticiaPlus/GiftEticiaPlus';

const CarouselHP: FC = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [videoVisible, setVideoVisible] = useState(false);

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  const handleButtonClick = () => {
    setVideoVisible((prev) => !prev);
  };

  return ( 
    <SectionHomepage backgroundColor={lightGrayBG}>
      <Fade ssrReveal bottom duration={1000} distance={'5rem'}>
        <Heading1
          color='#675f93'
          fontWeight={400}
          textAlign='center'
          fontSize={{ _:'3rem', md: '3.8rem' }}
          px='2rem'
          mx='auto'
        >
          <FormattedMessage id='homepage.carousel.title' />
        </Heading1>
        <Paragraph
          color='#7c7b7e'
          fontSize={{ _:'2rem', md:'2.4rem' }}
          mt={{ _: '2rem', md: '2.5rem' }}
          px='3rem'
          fontFamily='Oli Grotesk Light'
        >
          <FormattedMessage id='homepage.carousel.text' />
        </Paragraph>
        <Box mt={{ _: '2rem', md: '6rem' }} width='100%'>
          <FiveItemCarousel incData={data} />
        </Box>
        <Flex
          flexDirection='column'
          alignItems='center'
          justifyContent='end'
          mt='2rem'
        >
          <RollDown isVisible={videoVisible}>
            {videoVisible ? (
              <iframe
                src='https://player.vimeo.com/video/314753998?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
                frameBorder='0'
                allow='autoplay; fullscreen; picture-in-picture'
                allowFullScreen                
                title='Eticia 21 - Hodnotová hra'></iframe>
            )
              :
              <></>
            }
          </RollDown>
          
          <Button 
            backgroundColor='#fff'
            borderColor='#79729f'
            color='#79729f'
            changeColor
            mt='2rem'
            onClick={handleButtonClick}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}>
            <PlayButton
              mr='1rem'
              color='#79729f'
              mouseOver='#fff'
              isHovered={isHovered}
              height={16}
              width={16} /> {videoVisible ? 'Zavřít video' : 'Přehrát video'}
          </Button>
          
          <GiftEticiaPlus />
        </Flex>
      </Fade>
    </SectionHomepage>
  );
};
 
export default CarouselHP;

interface RollDownProps {
  isVisible?: boolean;
}

const RollDown = styled.div<RollDownProps>`
  width: 100vw;
  height: ${props => props.isVisible ? '220px' : '0px'}
  overflow-y: hidden;
  background-color: #000;
  transition: height 2000ms ease;

  iframe {
    position: relative;
    width: 104vw;
    height: 22rem;
    left: -0.7rem;
    bottom: 0;
    background-color: #000;
  }

  ${mediaMdDefault} {
    width: 854px;
    height: ${props => props.isVisible ? '480px' : '0px'}

    iframe {
      width: 854px;
      height: 480px;
      left: 0;
    }
  }
`;

interface PlayButtonProps {
  color: string;
  mouseOver?: string;
  isHovered: boolean;
  height?: number;
  width?: number;
  mr?: string;
}

const PlayButton = (
  { 
    color, 
    mouseOver = color, 
    isHovered,
    height,
    width,
    mr 
  } : PlayButtonProps) => {
  
  const svgStyles = {
    fill: isHovered ? mouseOver : color,
    stroke: isHovered ? mouseOver : color,
    strokeWidth: 2,
    width: width || 64, 
    height: height || 64,
    marginRight: mr || 0
  };

  return (
    <svg 
      xmlns='http://www.w3.org/2000/svg'        
      viewBox='0 0 24 24' 
      style={svgStyles} 
      strokeLinecap='round' 
      strokeLinejoin='round' 
      className='feather feather-play'>
      <polygon 
        points='5 3 19 12 5 21 5 3'>
      </polygon>
    </svg>
      
  );
};
