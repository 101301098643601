import { GameContext } from 'components/App';
import { useContext } from 'react';
import * as React from 'react';
import { StyledTestWrap } from '../RoundStep/styles';
import TestHeading from './TestHeading';
import { ICurrentRound } from '../data';
import QuestionIntro from './QuestionIntro';
import { ITestTemplate } from 'components/pages/Sandbox/TestFactory';
import { ColoredThemes, IColorTheme } from 'components/theme/colors';
import { Box } from '@xcorejs/ui';
import BackgroundWrapper from 'components/organisms/Test/TestLayout/BackgroundWrapper';

interface ITestLayout {
  round: ICurrentRound;
  inRound: boolean;
  template?: ITestTemplate;
  customColorTheme?: string;
}

const TestLayout: React.FC<ITestLayout> = (
  {
    round,
    children,
    inRound,
    template,
    customColorTheme
  }
) => {
  const { meeticia } = useContext(GameContext);

  return (
    <BackgroundWrapper
      round={round}
      customTheme={template ? template.theme : undefined}
      customColorTheme={customColorTheme}
    >
      <QuestionIntro data={round.survey} test={round.valueTestResultId !== null} meeticia={meeticia}>
        <StyledTestWrap>
          <TestHeading
            template={template}
            round={round}
            inRound={inRound}
          />
          {children}
        </StyledTestWrap>
      </QuestionIntro>
    </BackgroundWrapper>
  );
};

export default TestLayout;
