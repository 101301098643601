import * as React from 'react';
import { IFlatRoute } from '../../../utils/router';
import { IThemeData } from '../../pages/routes';

export interface ITheme {
  original: IFlatRoute<IThemeData> | undefined;
  data: IThemeData;
}

export interface IThemeActions {
  setBackground: (background: string) => void;
  setLayout: (layout: string) => void;
  toggleMenu: (state?: boolean) => void;
  toggleScroll: (scroll?: boolean) => void;
  setHint: (hint: string) => void;
  setTheme: (theme: Partial<IThemeData>) => void;
}

export type Theme = ITheme & IThemeActions;

export interface IThemeProps {
  theme: Theme;
}

export const defaultData: IThemeData = {
  authUser: false,
  background: 'green',
  layout: '',
  enableMenu: false,
  enableScroll: true,
  legacyMenu: false,
};

export const getTheme = (
  route: IFlatRoute<IThemeData> | undefined,
  actions: IThemeActions,
  data: IThemeData,
): Theme => ({
  ...actions,
  original: route,
  data: {
    authUser: getVal(
      data.authUser,
      routeVal(route, () => route!.data!.authUser),
      defaultData.authUser,
    ),
    background: getVal(
      data.background,
      routeVal(route, () => route!.data!.background),
      defaultData.background,
    ),
    layout: getVal(
      data.layout,
      routeVal(route, () => route!.data!.layout),
      defaultData.layout,
    ),
    enableMenu: getVal(
      data.enableMenu,
      routeVal(route, () => route!.data!.enableMenu),
      defaultData.enableMenu,
    ),
    dataMenu: data.enableMenu,
    enableScroll: getVal(
      data.enableScroll,
      routeVal(route, () => route!.data!.enableScroll),
      defaultData.enableScroll,
    ),
    legacyMenu: getVal(
      data.legacyMenu,
      routeVal(route, () => route!.data!.legacyMenu),
      defaultData.legacyMenu,
    ),
  },
});

const getVal = <Da, Ro, De>(data: Da, route: Ro, def: De) =>
  data !== undefined ? data : route !== undefined ? route : def;

const routeVal = <T extends {}, TD extends {}>(route: IFlatRoute<TD> | undefined, val: () => T | undefined) =>
  route !== undefined && route.data !== undefined ? val() : undefined;

const context = React.createContext<Theme | null>(null);

export const ThemeContext = context.Provider;
export const ThemeContextConsumer = context.Consumer;

export const useTheme = <T extends {}>(Component: React.ComponentType<T & IThemeProps>): React.FC<T> => (props: T) => {
  return <context.Consumer>{theme => <Component {...props} theme={theme!} />}</context.Consumer>;
};
