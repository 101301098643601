import { useEffect } from 'react';

function useIframeModalLinkClickHandler(dependencies: any[]) {
  useEffect(() => {
    if (window.top !== window.self) {    
      const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement> | any) => {
        const url = e.currentTarget.href ? new URL(e.currentTarget.href) : null;
        if (url && window.top !== window.self) {
          e.preventDefault();
          window.top.postMessage({ type :'closeRedirect_' + url }, '*');
        }
      };
  
      const aTags = document.getElementsByTagName('a');
      for (let i = 0; i < aTags.length; i++) {
        aTags[i].addEventListener('click', handleLinkClick);
      }
  
      return () => {
      // Clean up event listeners
        for (let i = 0; i < aTags.length; i++) {
          aTags[i].removeEventListener('click', handleLinkClick);
        }
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
}

export default useIframeModalLinkClickHandler;