import { Heading3 } from '@xcorejs/ui';
import { yellow } from 'components/theme/colors';
import styled from 'styled-components';

export const Heading3Em = styled(Heading3)<{ color?: string }>`
  
  span {
    color: ${({ color }) => color || yellow};
    font-size: calc(inherit + 1rem);
  }
`;