import { GameContext } from 'components/App';
import * as React from 'react';
import ShowMenuButton from '../../ShowMenuButton';
import { CustomText } from '../../../atoms/Text';
import { ProgressLine } from '../../../molecules/ValueTest/ProgressLine';
import wrapIntl, { IWrapIntl } from '../../../atoms/wrapIntl';
import { BurgerFlex } from '../../Layout/Header/styles';
import Logo from '../../../atoms/Logo';
import { ICurrentRound } from '../data';
import { StyledTestHeading } from './styles';
import { FormattedMessage } from 'react-intl';
import { ProgressBarLine } from '../../../molecules/ValueTest/ProgressLine/styles';
import { Box } from '@xcorejs/ui';
import { ITestTemplate } from 'components/pages/Sandbox/TestFactory';
import { FC, useContext } from 'react';

interface ITestHeader {
  round: ICurrentRound;
  inRound: boolean;
  template?: ITestTemplate;
}

const TestHeading: FC<ITestHeader & IWrapIntl> = (
  {
    formatMsg,
    round,
    inRound,
    template
  }
) => {
  const { meeticia } = useContext(GameContext);
  const firstTime = localStorage.getItem('firstTime');

  return (
    <StyledTestHeading>
      <BurgerFlex>
        {!meeticia && (
          <div>
            {firstTime ? <Logo app={false} noLink /> : (
              <>
                <ShowMenuButton />
                <Logo app />
              </>
            )}
          </div>
        )}

        {inRound && (
          <div className='progress'>
            <FormattedMessage id={'test.step'} /> &nbsp; {round.globalStep}
            {' / '}
            {round.totalSteps}
          </div>
        )}
      </BurgerFlex>

      <div className='progress-line-mobile'>
        <ProgressBarLine progress={round.globalStep / round.totalSteps} />
      </div>

      {inRound && (
        <>
          <Box>
            {template && (
              <CustomText className={round.additionalQuestion && 'question'}>
                {round.type === 'classic' ?
                  template.info.flowClassicHeader
                  :
                  round.type === 'final' ?
                    round.step === 1 ? template.info.flowFinal1Header : template.info.flowFinal2Header
                    : ''}
              </CustomText>
            )}
          </Box>

          <ProgressLine
            progress={round.globalStep / round.totalSteps}
            desc={formatMsg('test.step') + ' ' + round.globalStep + ' / ' + round.totalSteps}
          />
        </>
      )}
    </StyledTestHeading>
  );
};

export default wrapIntl(TestHeading);
