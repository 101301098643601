import * as React from 'react';

const SVGGame2: React.FC = () => {
  return (
    <svg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <g id='MVP-Tuning-–-Mobile' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Landing' transform='translate(-49.000000, -841.000000)' fill='#009966'>
          <g id='Why' transform='translate(0.000000, 647.000000)'>
            <g id='block' transform='translate(49.000000, 158.000000)'>
              <path
                d='M10.3549268,53.6132906 C10.7238718,53.6217121 11.1804792,54.0848971 11.1568484,54.4294149 C11.1255948,54.9017871 10.5653169,55.6145584 10.2276256,55.6145584 C9.7915998,55.6160872 8.9058271,54.8688665 8.932507,54.5228175 C8.96071146,54.164519 9.84038591,53.6018066 10.3549268,53.6132906 Z M15.4080238,45.4631483 C16.1512496,45.5037249 16.9226799,45.545067 17.7291752,45.5887059 C17.1452665,41.8694451 16.4073767,39.3973387 12.0120536,37.8699765 C7.25998213,36.218588 4.3053737,38.6110726 2.25559504,43.1456921 C2.13667891,43.7765424 1.9544933,44.743489 1.75172605,45.8145565 C3.52327146,45.8076661 5.05241092,45.802307 6.58155037,45.7969478 C8.8729727,45.7885262 11.164395,45.7992446 13.4542928,45.758668 C14.0694551,45.7471841 14.6815682,45.5779876 15.4080238,45.4631483 Z M9.05941988e-14,47.0731948 C0.442886352,45.0979595 0.647940447,43.4549925 1.18534988,41.9345207 C2.6314005,37.8447119 6.71190074,35.4039948 11.239861,36.125951 C14.6518392,36.6695235 16.7252486,38.7358646 18.4441965,41.332763 C19.2003811,42.4757964 19.3337806,44.0490943 19.7286432,45.4340557 C19.8254531,45.7739799 19.8368873,46.1391688 19.9024437,46.600057 C18.865739,47.4284309 17.5835792,46.9897449 16.4127127,47.0249623 C13.888794,47.1022874 11.3587772,46.9981664 8.83180943,47.010416 C6.83539057,47.0196031 4.839734,47.1045842 2.84331514,47.13138 C1.94763275,47.1428639 1.05042581,47.0969282 9.05941988e-14,47.0731948 Z'
                id='ico/game-2'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SVGGame2;
