import styled, { css } from 'styled-components';
import { Tag, Flex } from '@xcorejs/ui';
import { OliLight } from 'components/theme/fonts';
import { mediaMdDefault, mediaXsDefault } from 'components/theme/grid';

export const Purple = {
  background: {
    light: '#7e76ab',
    dark: '#67608f',
  }
};

export const StyledEticiaPlus = styled.div`
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  
  section {
    padding-top: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 2rem;

    & > h2 {
      font-size: 2.2rem;
      line-height: 2.6rem;
      margin-top: 1rem;
    }
  }

  ${mediaMdDefault} {
    section {
      padding-top: 4rem;
      padding-left: 2rem;
      padding-right: 2rem;
      padding-bottom: 4rem;

      & > h2 {
        font-size: 2.6rem;
        line-height: 3rem;
        margin-top: 0;
      }
    }
  }
`;

interface EticiaPlusGridProps {
  backgroundColor?: string;
}

export const EticiaPlusGrid = styled.section<EticiaPlusGridProps>`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1rem;
  align-items: stretch;
  justify-items: center;

  background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};

  ${mediaMdDefault} {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 4rem;
    /* max-width: 90%; */
    margin: auto;
    /* max-width: 960px; */
  }
`;

interface ProductProps  {
  borderColor?: string;
  backgroundColor?: string;
  opacity?: number;
}

export const Product = styled.div<ProductProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 250px;
  max-width: 330px;
  border: 1px solid ${({ borderColor }) => borderColor || '#ccc'};
  border-radius: 15px;
  padding: 2rem;
  background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
  opacity: ${({ opacity }) => opacity || 1};

  h1 {
    font-size: 2.6rem;
  }
`;

export const DynamicWrapper = styled.div<{expanded: boolean}>`
  display: grid;
  grid-template-rows: ${({ expanded }) => expanded ? '1fr' : '0fr'};
  transition: grid-template-rows 0.5s ease;
`;

export const StyledTag = styled(Tag)<{bg: string}>`
  background: ${({ bg }) => bg};
  margin-bottom: 0.4rem;
  font-family: ${OliLight};
  font-size: 1.2rem;
  line-height: 1.4rem;
  padding: 0.4rem 0.8rem;
  position: relative;
`;

export const TablePlus = styled.table<{noMargin?: boolean}>`
  ${({ noMargin }) => !noMargin && css`margin-top: 2rem;`}
  border-collapse: collapse;
  border: 0;
  width: 100%;
  max-width: 45rem;
  table-layout: fixed;
  flex-grow: 1;
  
  thead {
    th {
      padding: 1rem 0;
      position: relative;
    }

    th:first-child {
      width: 44%;
    }

    th:not(:first-child) {
      width: 28%;
    }
  }
  
  td {
    padding: 1.3rem 1rem;
    position: relative;
    background-clip: padding-box;
  }
  
  tr:not(:last-child) {
    border-bottom: 2px solid #FFF3;
  }
  
  tr {
    td:first-child {
      font-size: 1.4rem;
      line-height: 1.8rem;
    }
    
    td:nth-child(2) {
      /* background-color: #903a5a; */
      background-color: rgba(11, 12, 28, 0.2);
      border-right: 2px solid #FFF3;
    }
    
    td:nth-child(3) {
      background-color: rgba(11, 12, 28, 0.4);
      /* background-color: #6e2f4a; */
    }
  }

  .tree-coming-soon {
    position: relative;

    td:first-child span:last-child {
      color: #222;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #0000003a;
    }
  }

  ${mediaMdDefault} {
    max-width: 80rem;
  }
`;

export const StyledList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  justify-items: center;

  li {
    height: 3.2rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const SmallLine = styled.div`
  margin: 2rem auto 1rem;
  width: 60px;
  height: 1px;
  background-color: #ae4a84;
`;

export const FlexScrollMargin = styled(Flex)`
  scroll-margin-top: 5rem;

  ${mediaMdDefault} {
    scroll-margin-top: 6rem;
  }
`;

export interface ShopButtonProps {
  color?: string;
  borderColor?: string;
  backgroundColor?: string;
  smallPadding?: boolean;
  disabled?: boolean;
  blackHover?: boolean;
  smallMargin?: boolean;
  smallerMargin?: boolean;
}

export const ShopButton = styled.button<ShopButtonProps>`
  position: relative;
  display: flex;
  align-items: center;
  padding: ${({ smallPadding }) => smallPadding ? '0.8rem 1.5rem' : '1rem 3rem'}
  border: 2px solid ${({ borderColor }) => borderColor || '#fff'};
  background-color: ${({ backgroundColor }) => backgroundColor || '#333'};
  border-radius: 10rem;
  color: ${({ color }) => color || 'white'};
  font-family: 'Oli Grotesk Regular';
  font-size: ${({ smallPadding }) => smallPadding ? '1.1rem' : '1.4rem'};
  line-height: 1;
  transition: background-color 300ms ease;
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  margin-top: ${({ smallerMargin, smallMargin }) => smallerMargin ? '0.5rem' : smallMargin ? '1rem' : '2rem'};
  width: fit-content;
  opacity: ${({ disabled }) => disabled ? 0.6 : 1};

  ${({ disabled, borderColor, blackHover }) => !disabled && css`
    &:hover {
      background-color: ${blackHover ? 'rgba(11,12,28,1)' : borderColor || '#333'};
    }

    &:active {
      background-color: #FFA4A3;
      color: rgba(11,12,28,1);
    }
  `}
  
  ${mediaXsDefault} {
    font-size: ${({ smallerMargin }) => smallerMargin ? '1.4rem' : '1.6rem'};
  }

  ${mediaMdDefault} {
    ${({ smallPadding }) => smallPadding ? css`
      padding: 0.7rem 2rem;
      font-size: 1.4rem;
    ` : css`
      padding: 1.2rem 4rem;
      font-size: 1.6rem;
    `}
  }
`;
