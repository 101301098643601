import htmlToImage from 'html-to-image';
import React, { Children, cloneElement, ReactElement, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { StyledSmallButton } from '../../atoms/Buttons/styles';
import { StyledValueMap } from './styles';
import { Flex, Icon } from '@xcorejs/ui';
import { IColorTheme } from 'components/theme/colors';
import FacebookIcon from '../../components/icons/24/fb.svg';
import { darkenPlus, darkenBlue, lightPlus, lightBlue, primaryPlus, primaryBlue } from 'components/theme/colors';
export type ValueMapTheme = 'normal' | 'afterYear' | 'forTwo';

interface Props {
  imagePath: string;
  theme: ValueMapTheme;
  customColorTheme?: IColorTheme;
}

const ValueMap: React.FC<Props> = (
  {
    children,
    imagePath,
    theme,
    customColorTheme
  }
) => {
  const node = useRef<HTMLDivElement>(null!);
  const clickLink = (data: string)=> {
    const link = document.createElement('a');
    link.download = 'my-values.png';
    link.href = data;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <StyledValueMap theme={theme} customColorTheme={customColorTheme}>
        <Icon
          svg={<FacebookIcon />}
          onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${imagePath}`)}
          position='absolute'
          right='2rem'
          top='2rem'
          cursor='pointer'
          opacity={0.8}
          _hover={{
            opacity: 1
          }}
          transition='opacity 300ms'
        />
        {children}
      </StyledValueMap>
      {theme != 'forTwo' && (
        <>
          <Flex
            position='absolute'
            left='50%'
            mt='-2.6rem'
            width='100%'
            transform='translateX(-50%)'
            display='flex'
            alignItems='center'
            justifyContent='center'
          >
            {theme === 'afterYear' ? (
              <StyledSmallButton
                borderColor={customColorTheme ? (customColorTheme.light) : (lightPlus)}
                bgColorHEX={customColorTheme ? (customColorTheme.darken) : (darkenPlus)}
                hoverColorHEX={customColorTheme ? (customColorTheme.primary) : (primaryPlus)}
                style={{
                  fontSize: '1.5rem',
                  paddingLeft: '3rem',
                  paddingRight: '3rem',
                }}
                onClick={() =>
                  htmlToImage.toPng(node.current, { width: node.current.clientWidth }).then(clickLink)
                }
              >
                <FormattedMessage id='valuemap.save' />
              </StyledSmallButton>
            ) : (
              <StyledSmallButton
                borderColor={customColorTheme ? (customColorTheme.light) : (lightBlue)}
                bgColorHEX={customColorTheme ? (customColorTheme.darken) : (darkenBlue)}
                hoverColorHEX={customColorTheme ? (customColorTheme.primary) : (primaryBlue)}
                style={{
                  fontSize: '1.5rem',
                  paddingLeft: '3rem',
                  paddingRight: '3rem',
                }}
                onClick={() =>
                  htmlToImage.toPng(node.current, { width: node.current.clientWidth }).then(clickLink)
                }
              >
                <FormattedMessage id='valuemap.save' />
              </StyledSmallButton>
            )}
          </Flex>

          <div style={{ position: 'absolute', top: '-2000px', left: '-2000px' }}>
            <div ref={node} style={{ padding: '10px' }}>
              <StyledValueMap theme={theme}>
                {cloneElement(Children.only(children) as ReactElement)}
              </StyledValueMap>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ValueMap;
