import { useState, useEffect, useCallback } from 'react';

export const useWindowWidth = ( breakpoint: number = 1023 ) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [isDesktop, setForDesktop] = useState<boolean>(window.innerWidth > breakpoint);
  const [isMobile, setForMobile] = useState<boolean>(window.innerWidth <= breakpoint);

  const handleWindowSetter = useCallback(() => {
    if (width > breakpoint) {
      setForDesktop(true);
      setForMobile(false);
    } else {
      setForDesktop(false);
      setForMobile(true);
    }
  }, [breakpoint, width]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWidth(window.innerWidth);
      handleWindowSetter();
    };
    window.addEventListener('resize', handleWindowResize);
    
    return () => window.removeEventListener('resize', handleWindowResize);
  }, [handleWindowSetter]);

  return { width, isDesktop, isMobile };
};