import styled, { css } from 'styled-components';
import {
  white,
  yellow,
  yellowLighter,
  darkBaseHEX,
  gray,
  textGreen,
  orange,
  violet,
  blue,
  darkBaseRGB,
  grayRGB,
  primaryBlue,
  premiumBlue,
  peaGreen,
} from '../../theme/colors';
import { bold, Fox } from '../../theme/fonts';
import { Link } from 'react-router-dom';
import { mediaSmDefault } from '../../theme/grid';

const buttonStyle = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 5rem;
  border-radius: 3.5rem;
  text-align: center;
  transition: background-color 300ms ease, color 0.3s, opacity 0.3s;
  cursor: pointer;
  font-weight: ${bold};
  color: ${darkBaseHEX};
  font-size: 1.6rem;
  line-height: 2.1rem;
  font-family: ${Fox};
  user-select: none;

  ${mediaSmDefault} {
    font-size: 2rem;
    line-height: 2.6rem;
    height: 6rem;
  }
`;

const primaryButtonStyle = css`
  ${buttonStyle} padding: 1.5rem 3.5rem;
  background-color: ${yellow};
  border: 0;

  ${mediaSmDefault} {
    padding: 1.5rem 4.5rem;
  }

  &:hover {
    background-color: ${yellowLighter};
  }

  &.small {
    padding: 1.4rem 3rem;
    font-size: 1.8rem;
    line-height: 2.3rem;
    border-radius: 2.5rem;
    height: 5rem;
  }
  
  &.disabled {
    cursor: not-allowed;
  }

  &:focus {
    outline: 0;
  }
`;

export const StyledPrimaryButtonLink = styled(Link)`
  ${primaryButtonStyle};
`;

export const StyledPrimaryButtonButton = styled.button`
  ${primaryButtonStyle};
`;

export const StyledLink = styled(Link)``;

const LinkBorder = css`
  color: rgba(${grayRGB}, 0.8);
  font-size: 1.6rem;
  line-height: 2rem;
  display: inline-block;
  cursor: pointer;

  ${mediaSmDefault} {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  span {
    border-bottom: 1px solid ${gray};
    transition: border 0.3s;
  }

  &:after {
    content: "";
    display: block;
    margin-top: -2px;
    height: 1px;
    transition: background 0.3s;
    background: ${gray};
  }

  &:hover {
    span {
      border-bottom: 1px solid transparent;
    }

    &:after {
      background: transparent;
    }
  }

  svg {
    vertical-align: middle;
    margin-right: 1.4rem;
  }
`;

export const StyledExternalLink = styled.a<{ color?: 'yellow' | 'blue' }>`
  ${LinkBorder};
  ${props =>
    props.color === 'yellow' &&
    css`
      color: ${yellow};

      span {
        color: ${yellow};
        border-color: ${yellow};
      }

      &:after {
        background: ${yellow};
      }
    `};

  ${props =>
    props.color === 'blue' &&
    css`
      color: ${blue};

      span {
        color: ${blue};
        border-color: ${blue};
      }

      &:after {
        background: ${blue};
      }
    `};
`;

export const StyledLinkBorder = styled(Link)<{ color?: 'yellow' | 'blue'; withoutAfterBorder?: boolean }>`
  ${LinkBorder};
  ${props =>
    props.color === 'yellow' &&
    css`
      color: ${yellow};

      &:after {
        background: ${yellow};
      }

      span {
        color: ${yellow};
        border-color: ${yellow};
      }
    `};

  ${props =>
    props.color === 'blue' &&
    css`
      color: ${blue};

      &:after {
        background: ${blue};
      }

      span {
        color: ${blue};
        border-color: ${blue};
      }
    `};

  ${props =>
    props.withoutAfterBorder &&
    css`
      &:after {
        display: none;
      }
    `};
`;

export const StyledFakeLinkBorder = styled.span<{ color?: 'yellow' | 'blue'; withoutAfterBorder?: boolean }>`
  ${LinkBorder};

  ${props =>
    props.color === 'yellow' &&
    css`
      color: ${yellow};

      span {
        color: ${yellow};
        border-color: ${yellow};
      }

      &:after {
        background: ${yellow};
      }
    `};

  ${props =>
    props.color === 'blue' &&
    css`
      color: ${primaryBlue};

      span {
        color: ${primaryBlue};
        border-color: ${primaryBlue};
      }

      &:after {
        background: ${primaryBlue};
      }
    `};

  ${props =>
    props.withoutAfterBorder &&
    css`
      &:after {
        display: none;
      }
    `};
`;

export const StyledPrimaryButton = styled.a<{ isUnvisible?: boolean }>`
  ${primaryButtonStyle};

  ${props =>
    props.isUnvisible &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

const secondaryButtonStyle = css`
  ${buttonStyle}
  padding: 1.5rem 4rem;
  color: ${white};
  border-radius: 3rem;
  border: 2px solid ${yellow};
  background: transparent;
  //line-height: 2rem;
  transition: background-color 300ms ease, color 300ms ease;
  font-family: ${Fox};

  &:hover {
    color: ${darkBaseHEX};
    background-color: ${yellow};
  }

  &:focus {
    outline: none;
  }

  &.disabled {
    cursor: not-allowed;
  }
`;

export const StyledSecondaryButton = styled.a`
  ${secondaryButtonStyle};
`;

export const StyledSecondaryButtonButton = styled.button`
  ${secondaryButtonStyle};
`;

export const StyledSecondaryButtonLink = styled(Link)`
  ${secondaryButtonStyle};
`;

export const StyledGreenStrokeLink = styled(Link)`
  ${secondaryButtonStyle};

  background: transparent;
  border-color: ${textGreen};

  &:hover {
    background: ${textGreen};
    color: #fff;
  }
`;

export const StyledSecondaryButtonLinkBigger = styled(Link)`
  ${buttonStyle};
  padding: 1rem 4.5rem;
  border: 2px solid ${yellow};
  background: transparent;
  color: ${white};
  transition: background-color 300ms ease, color 300ms ease;
  font-family: ${Fox};

  &:hover {
    color: ${darkBaseHEX};
    background-color: ${yellow};
  }

  &:focus {
    outline: none;
  }
`;

export const StyledPlayButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  border-radius: 3.5rem;
  text-align: center;
  transition: background-color 300ms ease, color 0.3s;
  cursor: pointer;
  font-weight: ${bold};
  /* font-size: 1.6rem; */
  font-size: 1.4rem;
  line-height: 2.1rem;
  font-family: ${Fox};
  /* padding: 1rem 4.5rem; */
  padding: 1rem 4rem;
  background-color: transparent;
  border: 2px solid ${peaGreen};
  color: #fff;

  &:hover {
    background-color: ${peaGreen};

    svg {
      g {
        fill: #fff;
      }
    }
  }

  &:focus {
    outline: 0;
  }

  svg {
    margin-right: 1.5rem;

    g {
      transition: fill 0.3s;
    }

    ${mediaSmDefault} {
      margin-right: 1.85rem;
    }
  }
`;

const StyledGiftCss = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  height: 5rem;
  border-radius: 3.5rem;
  text-align: center;
  transition: background-color 300ms ease, color 0.3s;
  cursor: pointer;
  font-weight: ${bold};
  color: #fff;
  font-size: 1.6rem;
  line-height: 2.1rem;
  font-family: ${Fox};
  padding: 1rem 3.2rem;
  background-color: rgba(25, 29, 44, 0.5);
  border: 2px solid ${orange};

  ${mediaSmDefault} {
    padding: 1rem 4.5rem;
    font-size: 2rem;
    line-height: 2.6rem;
    height: 6rem;
  }

  &:hover {
    background-color: ${orange};

    svg {
      g {
        fill: #fff;
      }
    }
  }

  &:focus {
    outline: 0;
  }

  svg {
    margin-right: 2rem;
    min-width: 2.2rem;

    g {
      transition: fill 0.3s;
      fill: ${orange};
    }
  }
`;

export const StyledGiftButton = styled.button<{
  inverted?: boolean;
  variant?: 'blue';
}>`
  ${StyledGiftCss};

  ${props =>
    props.inverted === true &&
    css`
      background: ${orange};

      svg {
        path {
          fill: ${violet};
        }
      }

      &:hover {
        background: transparent;
        color: ${orange};
      }
    `};

  ${props =>
    props.variant === 'blue' &&
    css`
      background: ${premiumBlue};
      border: 0;
    `};
`;

export const StyledGiftButtonLink = styled(Link)<{
  inverted?: boolean;
  variant?: 'blue';
}>`
  ${StyledGiftCss};

  ${props =>
    props.inverted === true &&
    css`
      background: ${orange};

      svg {
        path {
          fill: ${violet};
        }
      }

      &:hover {
        background: transparent;
        color: ${orange};
      }
    `};

  ${props =>
    props.variant === 'blue' &&
    css`
      background: ${premiumBlue};
    `};
`;

const StyledSmallCss = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 4.2rem;
  padding: 0 1.1rem;
  border-radius: 3.5rem;
  text-align: center;
  cursor: pointer;
  font-weight: ${bold};
  font-family: ${Fox};
  background-color: rgba(${darkBaseRGB}, 0.5);
  border: 2px solid ${textGreen};
  color: #fff;
  transition: background-color 300ms ease, color 0.3s;

  ${mediaSmDefault} {
    font-size: 2rem;
    line-height: 2.6rem;
  }

  span {
    font-size: 1.6rem;
    line-height: 2.1rem;
  }

  &:hover {
    background-color: rgba(${darkBaseRGB}, 1);
  }

  &:focus {
    outline: 0;
  }

  svg {
    margin-right: 1.5rem;
  }
`;

export const StyledSmallButton = styled.button<{
  borderColor?: string;
  hoverColorHEX?: string;
  bgColorHEX?: string;
}>`
  ${StyledSmallCss};
  
  ${props =>
    props.bgColorHEX &&
    css`
      background: ${props.bgColorHEX};
    `}
  
  ${props =>
    props.hoverColorHEX &&
    css`
      &:hover {
        background: ${props.hoverColorHEX};
      }
    `}

  ${props =>
    props.borderColor &&
    css`
      border: 2px solid ${props.borderColor};
    `}

`;

export const StyledSmallButtonLink = styled(Link)<{
  borderColor?: 'green' | 'blue' | 'yellow';
}>`
  ${StyledSmallCss};

  ${props =>
    props.borderColor === 'blue' &&
    css`
      border: 2px solid ${blue};
    `}

  ${props =>
    props.borderColor === 'yellow' &&
    css`
      border: 2px solid ${yellow};
    `};
`;
