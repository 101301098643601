import * as React from 'react';
import TextBox from '../../TextBox';
import { Heading2, Heading3 } from '../../../atoms/Headings';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { BiggerText } from '../../../atoms/Text';
import Fade from 'react-reveal/Fade';
import { Row, Col } from 'react-styled-flexboxgrid';
import PersonBox from '../../../molecules/PersonBox';
import { Box } from '@xcorejs/ui';
import { BlueTheme } from 'components/theme/colors';
import styled from 'styled-components';
import { StyledPersonBox } from 'components/molecules/PersonBox/style';

const Team: React.FC = () => (
  <StyledBox backgroundColor={BlueTheme.darken}>
    <TextBox className='blue'>
      <Fade ssrReveal bottom duration={1000} distance={'5rem'}>
        <Heading2>
          <FormattedMessage id={'about-project.team.heading'} />
        </Heading2>
        <BiggerText>
          <FormattedHTMLMessage id={'about-project.team.text'} />
        </BiggerText>
      </Fade>

      <Fade ssrReveal bottom duration={1000} distance={'5rem'}>
        <Row>
          <Col xs={12} md={4}>
            <PersonBox
              img={'/img/team/honza.jpg'}
              translatedName={'about-project.team.member3.name'}
              text={'about-project.team.member3.text'}
            />
          </Col>
          <Col xs={12} md={4}>
            <PersonBox
              img={'/img/team/klau.jpg'}
              translatedName={'about-project.team.member4.name'}
              text={'about-project.team.member4.text'}
            />
          </Col>
          <Col xs={12} md={4}>
            <PersonBox
              img={'/img/team/zuzka.jpg'}
              translatedName={'about-project.team.member5.name'}
              text={'about-project.team.member5.text'}
            />
          </Col>
        </Row>
      </Fade>

      {/* <Fade ssrReveal bottom duration={1000} distance={'5rem'}>
        <Row>
          <Col xs={12} md={4}>
            <PersonBox
              img={'/img/team/alex.jpg'}
              translatedName={'about-project.team.member1.name'}
              text={'about-project.team.member1.text'}
            />
          </Col>
          <Col xs={12} md={4}>
            <PersonBox
              img={'/img/team/art.jpg'}
              translatedName={'about-project.team.member2.name'}
              text={'about-project.team.member2.text'}
            />
          </Col>
          <Col xs={12} md={4}>
            <PersonBox
              img={'/img/team/tom.jpg'}
              translatedName={'about-project.team.member6.name'}
              text={'about-project.team.member6.text'}
            />
          </Col>
        </Row>
      </Fade> */}

      <Fade ssrReveal bottom duration={1000} distance={'5rem'}>
        <Box>
          <Heading3>
            <FormattedMessage id={'about-project.team.external'} />
          </Heading3>

          <BiggerText>
            <FormattedHTMLMessage id={'about-project.team.external.text'} />
          </BiggerText>
        </Box>
      </Fade>
      <Fade ssrReveal bottom duration={1000} distance={'5rem'}>
        <Box>
          <Heading3>
            <FormattedMessage id={'about-project.team.specialThanks'} />
          </Heading3>

          <BiggerText>
            <FormattedHTMLMessage id={'about-project.team.specialThanks.text'} />
          </BiggerText>
        </Box>
      </Fade>
    </TextBox>
  </StyledBox>
);

export default Team;

const StyledBox = styled(Box)`
  ${StyledPersonBox} {
    margin-bottom: 2rem;
  }

  img {
    max-height: 40rem;
  }

  .react-reveal:nth-child(n+3) {
    margin-top: 6rem;

    h3 {
      color: #FFF;
  }}
`;
