import * as React from 'react';
import { StyledPersonBox } from './style';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

interface IPersonBox {
  img?: string;
  name?: string;
  translatedName?: string;
  text?: string;
  link?: string;
  linkText?: string;
}

export const PersonBox: React.FC<IPersonBox> = ({ img, name, translatedName, text, link, linkText }) => {
  return (
    <StyledPersonBox>
      <img src={img} alt={name} />

      {name && <h3>{name}</h3>}
      {translatedName && (
        <h3>
          <FormattedMessage id={translatedName} />
        </h3>
      )}

      <p>
        <FormattedHTMLMessage id={text!} />
      </p>

      {link && (
        <a href={link} color={'yellow'} target='_blank' rel='noopener noreferrer'>
          <span>{linkText}</span>
        </a>
      )}
    </StyledPersonBox>
  );
};

export default PersonBox;
