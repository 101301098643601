import * as React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { StyledCheckboxInput } from '../../../../../atoms/FormItems/styles';
import Popup from './Popup';
import { IStep2Data } from './actions';
import { IStringMap } from '../../../../../../utils';
import wrapIntl, { IWrapIntl } from '../../../../../atoms/wrapIntl/index';
import { Heading1 } from '../../../../../atoms/Headings/index';
import { BiggerText } from '../../../../../atoms/Text/index';
import { PrimaryButton } from '../../../../../atoms/Buttons/index';
import InlineFormInput from '../../../../../atoms/InlineForm/InlineFormInput';

interface IStep2 {
  data: IStep2Data;
  handleInput: (e: React.ChangeEvent<HTMLInputElement>) => any;
  submit: () => any;
  terms: boolean;
  acceptTerms: () => any;
  popup: (body: React.ComponentType, button?: string) => any;
}

interface IStep2State {
  errors: IStringMap<string | undefined>;
}

const isDisabled = (data: IStep2Data) =>
  !!data.name && !!data.surname && !!data.email && !!data.city && !!data.street && !!data.zip;
class Step2 extends React.Component<IWrapIntl & IStep2, IStep2State> {
  public state: IStep2State = { errors: {} };

  public render() {
    const { data, handleInput, popup, terms, acceptTerms } = this.props;
    const { errors } = this.state;

    return (
      <>
        <Heading1 className={'not-mobile'}>
          <FormattedMessage id={'support.heading.step2'} />
        </Heading1>
        <BiggerText>
          <FormattedHTMLMessage id={'support.text.step2'} />
        </BiggerText>
        <div className='form-inline-wrapper step2'>
          <div className='form-inline'>
            <FormattedMessage id={'support.tshirt.myname'} />{' '}
            <InlineFormInput
              type='text'
              name='name'
              error={errors.name}
              value={data.name}
              onChange={handleInput}
              placeholder={'Jméno'}
            />
            <InlineFormInput
              type='text'
              name='surname'
              error={errors.surname}
              value={data.surname}
              onChange={handleInput}
              placeholder={'Příjmení'}
            />
          </div>

          <div className='form-inline'>
            <FormattedMessage id={'support.tshirt.email'} />{' '}
            <InlineFormInput
              type='text'
              name='email'
              error={errors.email}
              value={data.email}
              onChange={handleInput}
              placeholder={'E-mail'}
            />
          </div>

          <div className='form-inline address'>
            <FormattedMessage id={'support.tshirt.address'} />{' '}
            <InlineFormInput
              type='text'
              name='street'
              error={errors.street}
              value={data.street}
              onChange={handleInput}
              placeholder={'Ulice a č.p.'}
            />
            <InlineFormInput
              type='text'
              name='zip'
              error={errors.zip}
              value={data.zip}
              onChange={handleInput}
              placeholder={'PSČ'}
            />
            <InlineFormInput
              type='text'
              name='city'
              error={errors.city}
              value={data.city}
              onChange={handleInput}
              placeholder={'Město'}
            />
          </div>

          <StyledCheckboxInput>
            <input
              type='checkbox'
              id={'agree'}
              name='agree'
              value={terms ? 'checked' : ''}
              onChange={acceptTerms}
              required
            />
            <label htmlFor={'agree'}>
              <span className={'fake-checkbox'} />
              <FormattedMessage id={'register.agree'} />
            </label>

            <span className='terms' onClick={() => popup(() => <Popup />)}>
              <FormattedMessage id={'register.agree.terms'} />
            </span>
            {errors.agree && <span className='error'>{errors.agree}</span>}
          </StyledCheckboxInput>
        </div>
        <PrimaryButton onClick={this.submit}>
          <FormattedMessage id={'support.tshirt.donate.buy.final'} />
        </PrimaryButton>
      </>
    );
  }

  private submit = () => {
    const { data, terms, formatMsg } = this.props;
    this.setState({
      errors: {
        name: !data.name ? formatMsg('support.tshirt.errors.name') : undefined,
        surname: !data.surname ? formatMsg('support.tshirt.errors.surname') : undefined,
        email: !data.email ? formatMsg('support.tshirt.errors.email') : undefined,
        street: !data.street ? formatMsg('support.tshirt.errors.street') : undefined,
        zip: !data.zip ? formatMsg('support.tshirt.errors.zip') : undefined,
        city: !data.city ? formatMsg('support.tshirt.errors.city') : undefined,
        agree: !terms ? formatMsg('support.tshirt.errors.agree') : undefined,
      },
    });
    return isDisabled(data) && terms && this.props.submit();
  };
}

export default wrapIntl(Step2);
