import * as React from 'react';
import { StyledShowMenuButton } from './style';
import { IThemeProps, useTheme } from '../../templates/General/ThemeContext';

interface IShowMenuButton {
  sideMenuIsOpen?: boolean;
}

const ShowMenuButton: React.FC<IShowMenuButton & IThemeProps> = ({ theme, sideMenuIsOpen }) => (
  <StyledShowMenuButton onClick={() => theme.toggleMenu()} sideMenuIsOpen={sideMenuIsOpen}>
    <span />
    <span />
    <span />
  </StyledShowMenuButton>
);

export default useTheme(ShowMenuButton);
