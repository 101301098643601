import * as React from 'react';
import { IProfile, IValueTestCompare, TogetherValuesSetting } from '../data';
import { Col, Row } from 'react-styled-flexboxgrid';
import { SectionText } from '../../../../../atoms/Text';
import { FormattedMessage } from 'react-intl';
import { CardList } from '../../../../../organisms/PartnerTest/Result/styles';
import { ILifeValue } from '../../../../../../store/Entities/LifeValues';
import { IStringMap } from '../../../../../../utils';
import SimpleCard from '../../../../../atoms/Cards/SimpleCard';
import { StyledResultBoxes } from 'components/molecules/TestResult/AnswerBox/styles';
import PopupIcon from '../../../../../organisms/MainPopup/PopupIcon';
import { PrimaryButton } from '../../../../../atoms/Buttons';
import { Box, Flex, Heading2, Heading3, Paragraph, useModal } from '@xcorejs/ui';
import Fade from 'react-reveal/Fade';
import SVGresultGame from '../../../../../atoms/SVGIcons/result/SVGresultGame';
import { IValueTestResultDetail, IValueResult } from 'config/api/valueTestResult';
import { FC, Fragment } from 'react';
import Modal from '../../../../../components/Modal';
import { MidnightTheme } from '../../../../../theme/colors';
import { ETICIAPLUS } from '../../../../../../config/config';
import { PremiumButton } from '../../../../../components/Button';
import { FindPositions } from './TogetherPrimalAgreement';
import SVGresultTwo from '../../../../../atoms/SVGIcons/result/SVGresultTwo';

interface IData {
  valueTestCompare: IValueTestCompare;
  mapValues: IStringMap<ILifeValue>;
  values: IValueResult[];
  allLifeValues: IValueResult[];
  profiles: IStringMap<IProfile>;
  valueTestResults: IStringMap<IValueTestResultDetail>;
}

const TogetherDisagreements: React.FC<IData> = (
  {
    mapValues,
    valueTestCompare,
    values,
    profiles,
    valueTestResults
  }
) => {
  const valueMatchDList = valueTestCompare.valueMatchDList;

  const [openTogetherValueModal] = useModal(TogetherValuesModal);
  const [close] = useModal();

  return (
    <>
      <StyledResultBoxes>
        <Row>
          <Col xs={12} md={8} mdOffset={2} lg={6} lgOffset={3}>
            <Fade bottom ssrReveal duration={1000} distance={'5rem'}>
              {valueMatchDList.length > 0 ? (
                <CardList>
                  {valueMatchDList.map((value, i) => (
                    <>
                      {mapValues[value] !== undefined ? (
                        <Fade bottom ssrReveal duration={1000} distance={'5rem'} delay={200 * i}>
                          <SimpleCard
                            color='gray'
                            name={mapValues[value].name}
                            svg={mapValues[value].iconSvg}
                            withIndex={true}
                          />
                        </Fade>
                      ) : (
                        <SimpleCard name={'ERROR: ValueID not found'} color={'yellow'} />
                      )}
                    </>
                  ))}
                </CardList>
              ) : (
                <SectionText>
                  <Box className='disagreement-svg' my='2rem'>
                    <SVGresultGame />
                  </Box>

                  <strong>
                    <FormattedMessage id={'results.partners.together.disagreement.none'} />
                  </strong>
                </SectionText>
              )}

              <Box mt='10rem' mb='3rem'>
                <Box
                  onClick={() => openTogetherValueModal({
                    values: values,
                    mapValues: mapValues,
                    valueTestCompare: valueTestCompare,
                    profiles: profiles,
                    valueTestResults: valueTestResults,
                    onClose: close
                  })}>
                  <PrimaryButton className='button-together-dis'>
                    <FormattedMessage id='results.partners.together.disagreement.button.all' />
                  </PrimaryButton>
                </Box>
              </Box>
            </Fade>
          </Col>
        </Row>
      </StyledResultBoxes>

      <PopupIcon text={'partnerResult.helpPopup.disagreement'} />
    </>
  );
};

export default TogetherDisagreements;

const TogetherValuesModal: FC<{
  values: IValueResult[];
  mapValues: IStringMap<ILifeValue>;
  valueTestCompare: IValueTestCompare;
  profiles: IStringMap<IProfile>;
  valueTestResults: IStringMap<IValueTestResultDetail>;
  onClose: () => unknown;
}> = (
  {
    values,
    mapValues,
    valueTestCompare,
    profiles,
    valueTestResults,
    onClose
  }
) => {
  const sortedValues = values.sort((a, b) => a.rank - b.rank);

  return (
    <Modal
      buttonText={<FormattedMessage id='popup.btn.close' />}
      _button={{ bg: 'transparent', border: '2px solid yellow', color: '#fff', onClick: onClose }}
      bg={MidnightTheme.primary}
      width='37.5rem'
      px={{ _: '2rem', md: '2rem' }}
      pt='3rem'
    >
      <>
        <>
          <Flex justifyContent='center'>
            <SVGresultTwo />
          </Flex>

          <Heading2 fontSize='2.4rem' lineHeight='3.2rem' fontWeight={400} textAlign='center' mt='2rem'>Hodnoty pro dva</Heading2>

          <Flex justifyContent='space-around' mt='2rem'>
            {Object.keys(profiles).map((profile, index) => {
              return (
                <Paragraph key={index} fontWeight={500} color={index === 0 ? 'textGreen' : 'blue'}>
                  {profiles[profile].nick ?
                    profiles[profile].nick : profiles[profile].fullname && profiles[profile].fullname}
                </Paragraph>
              );
            })}
          </Flex>

          <Paragraph fontWeight={500} mt='4rem'>
            Společné, nejdůležitější hodnoty
          </Paragraph>
          <Box mt='2rem'>
            {valueTestCompare.valueMatchAList.map((value, index,) =>
              mapValues[value] !== undefined ? (
                <Fade bottom ssrReveal duration={1000} distance={'5rem'} delay={200 * index} key={index}>
                  <SimpleCard
                    key={value}
                    color='yellow'
                    size='small'
                    name={<Paragraph fontWeight={500} color='darkBaseHEX'>{mapValues[value].name}</Paragraph>}
                    position={(
                      <Fragment>
                        <Flex
                          alignItems='center'
                          justifyContent='center'
                          border='1px solid #fff'
                          borderRadius='50%'
                          width='3.2rem'
                          height='3.2rem'
                          position='absolute'
                          left='0.9rem'
                          mt='0.1rem'
                          fontSize='1.4rem'
                        >
                          {index + 1}.
                        </Flex>
                        <FindPositions values={valueTestResults} value={value} />
                      </Fragment>
                    )}
                  />
                </Fade>
              ) : (
                <SimpleCard name={'ERROR: ValueID not found'} color={'yellow'} />
              ),
            )}
          </Box>

          <Paragraph fontWeight={500} mt='3rem' color='blue'>
            Další důležité hodnoty
          </Paragraph>

          <Box mt='2rem'>
            {TogetherValuesSetting.colors.map((setting, index) =>
              index < 1 && (
                <Box key={index}>
                  {sortedValues
                    .slice(
                      index === 0
                        ? valueTestCompare.valueMatchAList.length
                        : TogetherValuesSetting.colors[index - 1].rangeTo,
                      setting.rangeTo,
                    )
                    .map((value, i) => (
                      <React.Fragment key={i}>
                        {mapValues[value.valueId] !== undefined ? (
                          <Fade bottom ssrReveal duration={1000} distance={'5rem'} delay={200 * index} key={index}>
                            <SimpleCard
                              position={(
                                <Fragment>
                                  <Flex
                                    alignItems='center'
                                    justifyContent='center'
                                    border='1px solid #fff'
                                    borderRadius='50%'
                                    width='3.2rem'
                                    height='3.2rem'
                                    position='absolute'
                                    left='0.9rem'
                                    mt='0.1rem'
                                    fontSize='1.4rem'
                                  >
                                    {
                                      i +
                                      1 +
                                      (index === 0
                                        ? valueTestCompare.valueMatchAList.length
                                        : TogetherValuesSetting.colors[index - 1].rangeTo)}.
                                  </Flex>

                                  <FindPositions values={valueTestResults} value={value.valueId} />
                                </Fragment>
                              )}
                              key={mapValues[value.valueId].id}
                              name={<Paragraph fontWeight={500} color='#fff'>{mapValues[value.valueId].name}</Paragraph>}
                              color={setting.color}
                              size='small'
                            />
                          </Fade>
                        ) : (
                          <SimpleCard name={'ERROR: ValueID not found'} color={'yellow'} />
                        )}
                      </React.Fragment>
                    )
                    )}
                  <br />
                </Box>
              )
            )}
          </Box>

          {ETICIAPLUS && (
            <Fragment>
              <Paragraph mt='4.4rem' color='rgba(255, 255, 255, 0.7)'>
                Ostatní hodnoty
              </Paragraph>

              <Box mt='2rem'>
                {TogetherValuesSetting.colors.map((setting, index) =>
                  index >= 1 && (
                    <Box key={index}>
                      {sortedValues
                        .slice(
                          index === 0
                            ? valueTestCompare.valueMatchAList.length
                            : TogetherValuesSetting.colors[index - 1].rangeTo,
                          setting.rangeTo,
                        )
                        .map((value, i) => (
                          <React.Fragment key={i}>
                            {mapValues[value.valueId] !== undefined ? (
                              <Fade bottom ssrReveal duration={1000} distance={'5rem'} delay={200 * index} key={index}>
                                <SimpleCard
                                  position={(
                                    <Fragment>
                                      <Flex
                                        alignItems='center'
                                        justifyContent='center'
                                        border='1px solid #fff'
                                        borderRadius='50%'
                                        width='3.2rem'
                                        height='3.2rem'
                                        position='absolute'
                                        left='0.9rem'
                                        mt='0.1rem'
                                        fontSize='1.4rem'
                                      >
                                        {
                                          i +
                                          1 +
                                          (index === 0
                                            ? valueTestCompare.valueMatchAList.length
                                            : TogetherValuesSetting.colors[index - 1].rangeTo)}.
                                      </Flex>
                                      <FindPositions values={valueTestResults} value={value.valueId} />
                                    </Fragment>
                                  )}
                                  key={mapValues[value.valueId].id}
                                  name={<Paragraph fontWeight={500} color='#fff'>{mapValues[value.valueId].name}</Paragraph>}
                                  color={setting.color}
                                  size='small'
                                />
                              </Fade>
                            ) : (
                              <SimpleCard name={'ERROR: ValueID not found'} color={'yellow'} />
                            )}
                          </React.Fragment>
                        )
                        )}
                      <br />
                    </Box>
                  )
                )}
              </Box>
            </Fragment>
          )}
        </>

        {!ETICIAPLUS && (
          <Flex flexDirection='column' mt='2.4rem' alignItems='center'>
            <Paragraph color='#fff' fontWeight={500}>
              Chcete vidět celý svůj žebříček?
            </Paragraph>

            <Paragraph mt='2rem' color='rgba(255, 255, 255, 0.7)' textAlign='center'>
              Vyzkoušej verzi Plus a získej mnoho výhod
              a statistik
            </Paragraph>

            <Box mt='2rem'>
              <PremiumButton />
            </Box>

            <Paragraph mt='1rem' color='#fff'>
              od 121 Kč
            </Paragraph>
          </Flex>
        )}
      </>
    </Modal>
  );
};
