import LoadingSpinner from 'components/atoms/Animations/LoadingSpinner';
import Card from 'components/atoms/Cards';
import CardBody from 'components/atoms/Cards/CardBody';
import SimpleCard from 'components/atoms/Cards/SimpleCard';
import { CompareDetail } from 'components/components/Compare/data';
import Block1Match from 'components/components/modals/LaddersCompareTestModal/Block1Match';
import Block2Graphs from 'components/components/modals/LaddersCompareTestModal/Block2Graphs';
import Block3Values from 'components/components/modals/LaddersCompareTestModal/Block3Values';
import ValueMap from 'components/molecules/ValueMap';
import ValueMapFull from 'components/molecules/ValueMap/types/ValueMapFull';
import withLifeValues, { IWithLifeValues } from 'components/molecules/withRedux/withLifeValues';
import { COMPARE } from 'config/apiRoutes';
import * as React from 'react';
import {
  Box,
  Button,
  Flex,
  Heading2,
  Heading4,
  Img,
  Link,
  Modal,
  Paragraph,
  Tag,
  Typography
} from '@xcorejs/ui';
import { BlueTheme, GrayTheme } from 'components/theme/colors';
import { FC, Fragment, useEffect, useState } from 'react';
import Fade from 'react-reveal/Fade';

interface LaddersCompareTestModalProps {
  templateA: string;
  templateB: string;
  onClose?: () => void;
}

const LaddersCompareTestModal: FC<LaddersCompareTestModalProps & IWithLifeValues> = (
  { templateA, templateB, lifeValues, onClose }
) => {
  const [comparison, setComparison] = useState<CompareDetail | undefined>(undefined);
  const [allValuesVisible, setAllValuesVisible] = useState<boolean>(false);

  const compareTest = async (templateA: string, templateB: string) => {
    const response = await fetch(`${COMPARE(templateA, templateB)}`,
      {
        method: 'GET',
      }).then(r => r.json());

    if (!response.ok) {
      console.log('error');
    }

    console.log(response);
    setComparison(response);
  };

  useEffect(() => {
    compareTest(templateA, templateB);
  }, [templateA, templateB]);

  return (
    <Modal
      background={BlueTheme.primary}
      overflowX='hidden'
      p={{ _: '4rem 0', sm: '4rem 2.4rem' }}
      maxWidth='63rem'
    >
      {comparison ?
        (
          <Fragment>
            <Box px='1.6rem'>
              <Img src='/img/80x80_porovnání.png' display='block' mx='auto' alt='' />

              <Heading2 mt='1rem'>
                {comparison.compareConfig.mainHeader ?? 'Porovnávač žebříčků'}
              </Heading2>

              <Tag
                mt='4rem'
                display='block'
                mx='auto'
                bg='primaryGreen'
                border='2px solid'
                borderColor='primaryGreen'
                py='0.4rem'
                width='24rem'
                justifyContent='center'
                whiteSpace='nowrap'
                overflow='hidden'
                textOverflow='ellipsis'
                textAlign='center'
                borderRadius='0.5rem'
              >
                {comparison.compareConfig.templateA.name}
              </Tag>

              <Typography mt='1rem'>
                {new Date(comparison.valueTestResultA.doneAt * 1000).toLocaleDateString()}
              </Typography>

              <Tag
                mt='2rem'
                display='block'
                mx='auto'
                bg='#2B406B'
                border='2px solid'
                borderColor='#2B406B'
                py='0.4rem'
                width='24rem'
                justifyContent='center'
                whiteSpace='nowrap'
                overflow='hidden'
                textOverflow='ellipsis'
                textAlign='center'
                borderRadius='0.5rem'
              >
                {comparison.compareConfig.templateB.name}
              </Tag>

              <Typography mt='1rem'>
                {new Date(comparison.valueTestResultB.doneAt * 1000).toLocaleDateString()}
              </Typography>

              {comparison.compareConfig.mainDescription && (
                <Fragment>
                  <Heading4 mt='4rem' color='accentGreen' fontFamily='OliRegular'>
                    Co přináší toto porovnání?
                  </Heading4>

                  <Typography mt='1rem' color='#fff'>
                    {comparison.compareConfig.mainDescription}
                  </Typography>
                </Fragment>
              )}

              {comparison.compareConfig.cloudMapVisible && (
                <Flex flexDirection='column' alignItems='center' mt='4rem'>
                  <Box>
                    <ValueMap theme='afterYear' customColorTheme={GrayTheme} imagePath=''>
                      <Box>
                        <ValueMapFull
                          theme='normal'
                          lifeValues={comparison.valuesMerged}
                        />
                      </Box>
                    </ValueMap>
                  </Box>
                  
                  {!allValuesVisible ? (
                    <Link
                      v='underline'
                      color='rgba(249, 245, 220, 0.8)'
                      borderColor='rgba(249, 245, 220, 0.8)'
                      mt='5rem'
                      onClick={() => setAllValuesVisible(true)}
                    >
                      Zobrazit všechny hodnoty
                    </Link>
                  ) : (
                    <Flex width='100%' flexDirection='column' mt='3rem'>
                      <Fade bottom ssrReveal duration={1000} distance={'5rem'}>
                        {comparison.valuesMerged.map((value, i) => (
                          <React.Fragment key={i}>
                            {lifeValues[value.valueId] !== undefined ? (
                              <Fade bottom ssrReveal duration={1000} distance={'5rem'} delay={100 * i} key={i}>
                                <SimpleCard
                                  position={(
                                    <Flex
                                      alignItems='center'
                                      justifyContent='center'
                                      border='1px solid #fff'
                                      borderRadius='50%'
                                      width='3.2rem'
                                      height='3.2rem'
                                      position='absolute'
                                      left='0.9rem'
                                      mt='0.1rem'
                                      fontSize='1.4rem'
                                    >
                                      {i + 1}.
                                    </Flex>
                                  )}
                                  key={lifeValues[value.valueId].id}
                                  name={<Paragraph fontWeight={500}>{lifeValues[value.valueId].name}</Paragraph>}
                                  size='small'
                                />
                              </Fade>
                            ) : (
                              <Card color='yellow' size='small'>
                                <CardBody name={'ERROR: ValueID not found'} />
                              </Card>
                            )}
                          </React.Fragment>
                        ))}
                      </Fade>
                    </Flex>
                  )}
                </Flex>
              )}
            </Box>

            <Flex
              bg='rgba(11, 12, 28, 0.15)'
              px={{ _: '1.6rem', sm: '4rem' }}
              py='6rem'
              mt='6rem'
              mx={{ _: '0', sm: '-2.4rem' }}
              mb={{ _: '-4rem', sm: '0' }}
              flexDirection='column'
            >
              <Block1Match valueMatch={comparison.valueMatch} mt={0} />
              <Block2Graphs
                valueTestResultA={comparison.valueTestResultA}
                valueTestResultB={comparison.valueTestResultB}
                compareConfig={comparison.compareConfig}
                visible
              />

              <Block3Values
                valueTestResultA={comparison.valueTestResultA}
                valueTestResultB={comparison.valueTestResultB}
                compareConfig={comparison.compareConfig}
                visible
              />

              <Heading4 mt='4rem' color='#c0f3d6'>
                Závěrem
              </Heading4>

              <Typography mt='1rem'>
                Toto porovnání ti přináší vše podstatné. Ukazuje to, co je pro tebe opravdu dlouhodobě užitečné.
                Ve svých hodnotách můžeš spatřovat své osobní přání a cíle, své důležité životní postoje a zásady,
                které jsou pro tebe přirozené.
              </Typography>

              <Button s='xs' mt='4rem' onClick={onClose}>
                Zavřít okno
              </Button>
            </Flex>
          </Fragment>
        ) : (
          <LoadingSpinner />
        )}


      {console.log(comparison)}
    </Modal>
  );
};

export default withLifeValues(LaddersCompareTestModal);