import React, { useState, useEffect, FC } from 'react';
import Accordion, { AccordionProps } from './Accordion';
import { FlexProps } from '@xcorejs/ui';

interface DelayedAccordionProps extends Pick<AccordionProps, 'wrapperProps'>, FlexProps {
delay?: number;
}

const DelayedAccordion: FC<DelayedAccordionProps> = ({
  children,
  wrapperProps,
  delay = 1000,
  ...props
}) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!show) {
      const timeout = setTimeout(() => {
        setShow(true);
      }, delay);
      return () => clearTimeout(timeout);
    }
  }, [show, delay]);

  return ( 
    <Accordion
      expanded={show}
      wrapperProps={wrapperProps}
      {...props}      
    >
      {children}
    </Accordion> 
  );
};
 
export default DelayedAccordion;
