import { colors, lightColorTheme } from '@xcorejs/ui';
import { yellowWhite, primaryGreen, darkenKids } from '../../../components/theme/colors';
export const colorsTheme = colors(
  lightColorTheme, {
    yellow: '#ffde71',
    darkBaseHEX: '#191d2c',
    textGreen: '#00e58d',
    blue: '#47a7ff',
    orange: '#f2ad7c',
    presentingBlack: '#0b0c1c',
    yellowWhite: yellowWhite,
    primaryGreen: primaryGreen,
    kids: darkenKids,
    plus: '#ab5371',
    error: '#a64263',
    pink: '#ffa4a3',
    accentGreen: '#C0F3D6',
    soft: '#f9f5dc'
  }
);