import { Box, Flex } from '@xcorejs/ui';
import SVGmenuSvetHodnot from 'components/atoms/SVGIcons/menu/SVGmenuSvetHodnot';
import ValueCard from 'components/organisms/InfoSlides/ValuePage/ValueCard/ValueCard';
import { IColorTheme } from 'components/theme/colors';
import * as React from 'react';
import { FC, Fragment, useState } from 'react';
import Fade from 'react-reveal/Fade';
import { Col, Row } from 'react-styled-flexboxgrid';
import { ILifeValue } from '../../../store/Entities/LifeValues/index';
import { Card, CardColor } from '../../atoms/Cards';
import CardBody from '../../atoms/Cards/CardBody';
import { CardList } from '../PartnerTest/Result/styles';

interface IValues {
  items: ILifeValueItem[];
  cols: number;
  rows: number;
  color: CardColor | ((item: ILifeValueItem) => CardColor);
  badge: string | ((itm: ILifeValueItem) => string | undefined);
  action?: React.ComponentType<ILifeValueItem>;
  changeBehavior?: (detail: string) => void;
  cardAction?: React.ReactNode;
  theme?: IColorTheme;
}

export interface ILifeValueItem {
  value: ILifeValue;
  top: boolean | null;
  position?: number;
}

const Values: FC<IValues> = (
  {
    items,
    cols,
    rows,
    color,
    badge,
    action: Action,
    changeBehavior,
    cardAction,
    theme
  }
) => {
  const [valueCardDetail, setValueCardDetail] = useState<{ 
    detail: string | undefined;
    primary: boolean; 
    index: number; 
  }>(
    { detail: undefined, primary: true, index: 0 }
  );

  return (
    <Fragment>
      <Row style={{ width: '100%' }}>
        {Array(cols)
          .fill(null)
          .map((_, i) => (
            <Col
              xs={12}
              sm={12 / cols}
              key={i}
              style={{
                paddingLeft: 0,
                paddingRight: 0,
              }}>
              <Fade bottom ssrReveal duration={1000} distance='5rem'>
                <CardList>
                  {items.slice(i * rows, i * rows + rows).map((v, index) => (
                    <Card
                      key={v.value.id}
                      color={typeof color === 'function' ? color(v) : color as CardColor}
                      theme={theme}
                      withIndex={true}
                    >
                      <CardBody
                        name={v.value.name}
                        img={v.value.iconSvg}
                        svg={v.value.iconSvg}
                        onClick={changeBehavior ? () => changeBehavior(v.value.id) : undefined}
                        badge={typeof badge === 'function' ? badge(v) : badge as string}
                        position={
                          v.position && (
                            <Flex
                              width='2.4rem'
                              height='2.4rem'
                              alignItems='center'
                              justifyContent='center'
                              className='green'
                              paddingTop='0.2rem'
                            >
                              {v.position}
                            </Flex>
                          )
                        }
                        index={i*5 + (index + 1)}
                      />
                      {Action && <Action {...v} />}
                      {cardAction && cardAction}

                      {!v.value.infoCardHidden && (
                        <Box
                          onClick={() => setValueCardDetail({ detail: v.value.id, primary: v.value.type === 'main', index: i*5 + (index + 1) })}
                          position='absolute'
                          zIndex={10}
                          right='1rem'
                          opacity={0.25}
                          _hover={{
                            opacity: 0.5
                          }}
                          transition='opacity 300ms'
                          cursor='pointer'
                          transform='scale(0.8)'
                          width='3rem'
                          height='3rem'
                        >
                          <SVGmenuSvetHodnot />
                        </Box>
                      )}
                    </Card>
                  ))}
                </CardList>
              </Fade>
            </Col>
          ))}
      </Row>

      {valueCardDetail.detail && (
        <ValueCard
          value={valueCardDetail.detail}
          primary={valueCardDetail.primary}
          onClose={() => setValueCardDetail({ detail: undefined, primary: true, index: 0 })}
          lifeValues={valueCardDetail.index}
        />
      )}
    </Fragment>
  );
};

export default Values;
