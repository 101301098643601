import React, { FC } from 'react';
import { Modal, Paragraph, Flex, Heading2, Icon, Img, Button } from '@xcorejs/ui';
import { BlueTheme, yellow } from 'components/theme/colors';
import { SemaforIcon } from '../../icons/16/semafor.svg';

const SemaphoreModal: FC<{close: () => void}> = ({ close }) => {
  const ParagraphStyle = {
    fontSize: { _: '1.4rem', sm: '1.6rem' },
    lineHeight: { _: '1.6rem', sm: '2rem' }
  };
  
  return ( 
    <Modal
      background={BlueTheme.primary}
      p={{ _: '4rem 1.6rem', sm: '4rem 4rem' }}
      overflowX='hidden'
      alignItems='center'
    >
      <Heading2 color={yellow}>Semafor žebříčku</Heading2>

      <Flex flexDirection='column' alignItems='center' marginTop='2rem'>
        <Paragraph
          color='rgb(42, 56, 89)'
          textAlign='center'
          {...ParagraphStyle}
        >
            V hodnotovém deníku klikni u&nbsp;žebříčků na ikonu<br />
            (pozor, ne všechny žebříčky semafor mají)
        </Paragraph>

        <Icon
          display='flex'
          alignItems='center'
          justifyContent='center'
          svg={<SemaforIcon />}
          width='5rem'
          height='5rem'
          borderRadius='50%'
          fill='#fff'
          marginTop='1rem'
          background='rgba(12, 14, 15, 0.5)'
        />
      </Flex>

      <Paragraph
        marginTop='2rem'
        {...ParagraphStyle}>
      Díky této unikátní funkci můžeš snadno sledovat zda a jak se tvé hodnoty mění, nebo objevovat
vzájemné souvislosti mezi tvými hodnotami v nejrůznějších oblastech života
      </Paragraph>

      <Paragraph
        marginTop='1rem'
        {...ParagraphStyle}>
      Díky této unikátní funkci můžeš snadno sledovat zda a jak se tvé hodnoty mění, nebo objevovat
vzájemné souvislosti mezi tvými hodnotami v nejrůznějších oblastech života
      </Paragraph>

      <Img src='/img/helperModals/semafor.png' alt='Semafor' width='100%'  marginTop='2rem' />

      <Button onClick={close} marginTop='2rem'>Zavřít</Button>
    </Modal>
  );
};
 
export default SemaphoreModal;