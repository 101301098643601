import * as React from 'react';
import { StyledCard } from './styles';
import { IColorTheme } from 'components/theme/colors';

export type CardColor = 'blue' | 'green' | 'yellow' | 'gray' | 'violet' | 'bordered';

export interface ICard {
  color?: CardColor;
  isSelected?: boolean;
  withIndex?: boolean;
  size?: 'small' | '' | false;
  afterOpacity?: number;
  theme?: IColorTheme;
}

export const Card: React.FC<ICard> = (
  {
    color,
    isSelected,
    size,
    withIndex,
    children,
    afterOpacity,
    theme
  }
) => {
  return (
    <StyledCard
      color={color}
      isSelected={isSelected}
      size={size}
      withIndex={withIndex}
      afterOpacity={afterOpacity}
      theme={theme}
    >
      {children}
    </StyledCard>
  );
};

export default Card;
