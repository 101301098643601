import styled, { css } from 'styled-components';
import {
  yellow,
  darkBaseRGB,
  primaryBlue,
  primaryGreen,
  blue,
  darkenGreen,
  darkenBlue,
  grayRGB,
  primaryBlueRGB,
  textGreen,
} from '../../../theme/colors';
import { mediaSmDefault, mediaMdDefault, mediaLandscapePhone } from '../../../theme/grid';
import { Heading2, Heading3 } from '../../../atoms/Headings';
import { Col } from 'react-styled-flexboxgrid';
import { CustomText } from '../../../atoms/Text';
import { CardList, StyledColorBox } from '../../../organisms/PartnerTest/Result/styles';
import { UL1 } from '../../../organisms/VisualValuesComparison/style';
import { StyledValuesGraph } from '../../Results/styles';
import { bold } from '../../../theme/fonts';

export const StyledAnswerBox = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 0.4rem;
  background-color: rgba(11, 12, 28, 0.1);
  border: 2px solid rgba(231, 230, 207, 0.15);
`;

export const BoxHeading = styled.div`
  display: flex;
  padding: 3rem 2rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: ${bold};
`;

export const BoxBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3rem 3rem;
  text-align: center;
  height: 100%;
  
  ${mediaMdDefault} {
    padding: 3rem 3rem 7rem;
  }

  p {
    img {
      display: block;
      margin: 0 auto 0;
      width: 100%;

      &:first-child {
        margin-top: 1rem;
      }
    }
  }

  ul {
    margin-top: 1.7rem;
    list-style: none;

    li {
      margin-top: 0.4rem;
      font-size: 1.6rem;
      line-height: 2rem;
    }

    strong {
      color: ${yellow};
    }
  }

  .range {
    position: relative;
    margin-top: 1rem;
    margin-bottom: -2.5rem;
    width: 100%;

    & > div {
      padding: 3.6rem 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      & > span {
        &:first-child,
        &:last-child {
          display: inline-block;
          width: 6rem;
          min-width: 6rem;
        }

        &:first-child {
          padding-right: 1rem;
        }

        &:last-child {
          padding-left: 1rem;
        }
      }

      div {
        position: relative;
        width: 100%;
        height: 0.8rem;
        border-radius: 0.6rem;
        background-color: #d8d8d8;
      }

      &.description {
        padding-top: 0;
        color: rgba(244, 244, 246, 0.7);

        & > span {
          &:first-child,
          &:last-child {
            display: inline-block;
            width: 40%;
            min-width: 10rem;
          }

          &:first-child {
            text-align: left;
          }

          &:last-child {
            text-align: right;
          }
        }
      }
    }
  }

  .others {
    strong {
      color: ${yellow};
    }

    ${CustomText} {
      text-align: center;
    }

    & > div {
      margin-top: 2rem;

      svg {
        width: 4.2rem;
      }

      span {
        display: block;
        margin-top: 1rem;
        color: rgba(255, 255, 255, 0.5);
        font-size: 1.4rem;
        line-height: 1.7rem;
      }
    }

    a,
    .main-popup-button {
      display: inline-block;
      margin-top: 2rem;
      opacity: 0.8;
      color: ${blue};
      font-size: 1.6rem;
      line-height: 1.8rem;
      cursor: pointer;

      span {
        border-bottom: 1px solid ${blue};
        transition: border 0.3s;
      }

      &:hover {
        span {
          border-bottom: 1px solid transparent;
        }
      }
    }
  }
`;

export const Ball = styled.span<{ left: number; background?: 'blue' | 'green'; backgroundString?: string }>`
  display: inline-block;
  width: 4.9rem;
  height: 3rem;
  line-height: 3rem;
  font-size: 1.5rem;
  border-radius: 8px;
  position: absolute;
  left: ${props => props.left}%;
  transform: translateX(-1.4rem);

  ${props =>
    props.background === 'green' &&
    css`
      top: -3.6rem;
      background: ${primaryGreen};
    `};

  ${props =>
    props.background === 'blue' &&
    css`
      top: 1.6rem;
      background: ${primaryBlue};
    `};

  ${props =>
    props.backgroundString &&
          css`
      background: ${props.backgroundString};
    `};
`;

export const BoxName = styled.div<{ color: 'green' | 'blue' }>`
  display: block;
  font-size: 2rem;
  line-height: 2.5rem;
  text-align: center;

  ${props =>
    props.color === 'green' &&
    css`
      color: ${textGreen};
    `};

  ${props =>
    props.color === 'blue' &&
    css`
      margin-top: 3rem;
      color: ${blue};
    `};
`;

export const StyledResultBoxes = styled.div`
  margin: 0 auto;
  
  &.compliance {
    ${Heading2} {
      margin-top: 3rem;
      margin-bottom: 3rem;
    }
      
    ${mediaSmDefault} {        
      .actual-graph {
        margin-top: 3rem;
      }
    }
    
    ${UL1} {
      li {
        span {
          color: #fff;
        }
      }
    }
    
    ${StyledValuesGraph} {
      .actual-graph {
        padding: 4.5rem;
        
        .letter-wrapper {
          margin-top: 0;
        }
      }
    }
  }
  
  &.intro { 
    ${StyledColorBox} {
      width: 25rem;  
      height: 25rem;
    }
  }
  
  &.together { 
    ${mediaSmDefault} {
      margin-top: 2rem;
    }
    
    ${Heading2} {
      font-size: 2rem;
      line-height: 2.5rem;
    }
    
    ${CustomText} {
      color: rgba(${grayRGB}, 0.7);
      font-size: 1.6rem;
      line-height: 2rem;
    }
    
    ${Heading2} ~ ${CustomText} {
      ${mediaSmDefault} {
        margin-bottom: 4rem;
      }
    }
    
    .slider {
      width: 288px;
      
      @media (min-width: 360px) {
        width: 100%;
      }
    }
  }
  
  .result-boxes {
    justify-content: center;
    
    ${mediaLandscapePhone} {
      & > ${Col} {
        max-width: 50%;
        flex-basis: 50%;
      }
    }
  }
  
  .survey-name {
    display: block;
    margin-top: 2rem;
    margin-bottom: 1rem;
    text-align: center;
    
    img {
      max-width: 20rem;
    }
    
    svg {
      width: 20rem;
      height: 20rem;
      
      path {
        fill: #fff;
      }
    }
    
    h2 {
      margin-top: 2rem;
      color: #fff;
      font-size: 2.4rem;
      letter-spacing: 0.5px;
      line-height: 2.9rem;
      font-weight: 400;
    }
  }
  
  .center{
    text-align: center;
  }

  ${Col} {
    margin-bottom: 3rem;

    ${CustomText} {
      margin-top: 2rem;

      &:first-child {
        margin-top: 0;
      }
    }
    
    &.inner-col {
      margin-bottom: 0;
    }
  }

  .avatar {
    opacity: 0.5;
    
    svg {
      width: 100%;
      height: 100%;
    }
    
    &.i0 {
      svg {
        path {
          fill: ${textGreen} !important;
        }
      }
    }
    
    &.i1 {
      svg {
        path {
          fill: #47A7FF !important;
        }
      }
    }
  }
  ${Heading2} {
    font-weight: 400 !important;
  }

  ${Heading2} ~ ${CustomText} {
    margin-bottom: 2rem;
  }
  
  ${Heading2} ~ ${StyledColorBox} {
    margin-top: 2rem;
  }
  
  ${Heading2} ~ ${CardList} {
    margin-top: 2rem;
  }
  
  ${CustomText} {
    max-width: 41rem;
    margin-left: auto;
    margin-right: auto;
    
    em {
      display: block;
      margin: 2rem 0;
    }
  }
  
  .result-boxes-heading {
    margin-bottom: -2rem;
    
    ${mediaMdDefault} {
      margin-bottom: -5rem;
    }
    
    ${Heading3} {
      opacity: 0.8;
      color: #fff;
      font-size: 1.8rem;
      line-height: 2.2rem;
    }
    
    ${Col} {
      margin-bottom: 0;
    }
  }
  
  .reversed-row {
    flex-wrap: wrap-reverse;
  }
  
  .graph-partner-result {
    .letter-wrapper {
      .type, .percentage {
        display: none;
      }
    }
  }
  
  .button-together-dis {
    border: 2px solid rgba(${primaryBlueRGB}, 0.5);
    border-radius: 3.5rem;
    background-color: rgba(${primaryBlueRGB}, 0.25);
    color: rgba(255, 255, 255, 0.7);
    font-size: 1.8rem;
    letter-spacing: 0.5px;
    line-height: 2.2rem;
    font-weight: 400;
    
    ${mediaSmDefault} {
      font-size: 2.2rem;
      line-height: 2.7rem;
    }
    
    &:hover {
      background-color: rgba(${primaryBlueRGB}, 0.5);
    }
  }
  
  .disagreement-svg {
    svg {
      width: 9.2rem;
      height: 9.6rem;
      
      path {
        fill: rgba(255, 255, 255, 0.5);
      }
    }
  }
  
  .custom-order {
    &:first-child {
      order: 1;
    }
    
    &:nth-child(2) {
      order: 1;
    }
  }
`;

export const StyledPositions = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 2rem;

  & > div {
    font-size: 1.4rem;
    line-height: 1.7rem;
    border-radius: 50%;
  }

  .green {
    margin-right: 0.4rem;
    background: radial-gradient(circle at top, ${primaryGreen} 0%, ${darkenGreen} 100%);
  }

  .blue {
    background: radial-gradient(circle at top, ${primaryBlue} 0%, ${darkenBlue} 100%);
  }
`;
