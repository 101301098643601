import Navigation from 'components/atoms/HPNav';
import InteractiveMap from 'components/components/InteractiveMap/InteractiveMap';
import React from 'react';


const Playground = () => {


  return (
    <>
      <Navigation />

      <InteractiveMap />
    </>
  );
};

export default Playground;
