import { Box, BoxProps, Flex, Heading2, Heading4, Paragraph, Typography } from '@xcorejs/ui';
import { PlusTag } from 'components/atoms/Logo';
import React, { FC, useState } from 'react';
import styled from 'styled-components';

type Placement = 'left' | 'right' | 'center' | 'left-top' | 'right-top' | 'center-top';

const CloudMapCarousel: FC = () => {
  const [selected, setSelected] = useState<string>('current');
  const [selectHistory, setSelectHistory] = useState<string[]>(['current']);

  const handleSelect = (placement: string) => {
    setSelected(placement);
    setSelectHistory(prev => [...prev, placement]);
  };

  const handleBack = () => {
    if (selectHistory.length > 1) {
      setSelected(selectHistory[selectHistory.length - 2]);
      setSelectHistory(prev => prev.slice(0, -1));
    }
  };

  const handlePlacement = (placement: Placement, position: number, selected: string): Placement => {
    if (selected.includes(placement)) {
      const selNum = Number(selected.split('-')[1]);
      if (selNum < position) {
        if (selNum + 1 === position) {
          return `${placement}-top` as Placement;
        } else {
          return placement;
        }
      } else if (selNum > position) {
        return 'center';
      } else {
        return 'center-top';
      }
    } else {
      if (position === 1) {
        return `${placement}-top` as Placement;
      } else {
        return placement;
      }
    }
  };
  

  return ( 
    <Flex
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      overflow='hidden'
      marginTop={{ _: '4rem' }}>
      <Flex position='relative' alignItems='center' justifyContent='center'>
        {selectHistory.length > 1 && <Paragraph position='absolute' top='0' right='1rem' onClick={handleBack} zIndex={99} cursor='pointer'>Back</Paragraph>}
        <CloudMap placement={selected === 'current' ? 'center-top' : 'center'} />

        {Array.from({ length: 5 }).map((_, i) => (
          <CloudMap
            key={i}
            placement={handlePlacement('left', i + 1, selected)}
            onClick={() => handleSelect(`left-${i + 1}`)}
          />
        ))}

        {/* <CloudMap placement={handlePlacement('right', 1, selected)} onClick={() => handleSelect('right-1')} /> */}
        {Array.from({ length: 2 }).map((_, i) => (
          <CloudMap
            key={i}
            placement={handlePlacement('right', i + 1, selected)}
            onClick={() => handleSelect(`right-${i + 1}`)}
          />
        ))}
      </Flex>
    </Flex>
  );
};
 
export default CloudMapCarousel;


interface CloudMapProps extends BoxProps {
  placement?: Placement;
}

const CloudMap: FC<CloudMapProps> = ({ placement, ...props }) => {
  if (placement) {
    if (placement.includes('left')) {
      props = { ...props, position: 'absolute', transform: { _: 'translateX(-96%) scale(0.7)', md: 'translateX(-105%) scale(0.6)' } };
      if (placement && placement.includes('top')) {
        props = { ...props, zIndex: 1, _hover: { transform:{ _: 'translateX(-80%) scale(0.85)', md: 'translateX(-95%) scale(0.8)' } } };
      }
    } else if (placement.includes('right')) {
      props = { ...props, position: 'absolute', transform: { _:'translateX(96%) scale(0.7)', md: 'translateX(105%) scale(0.6)'  } };
      if (placement && placement.includes('top')) {
        props = { ...props, zIndex: 1, _hover: { transform: { _:'translateX(80%) scale(0.85)', md: 'translateX(95%) scale(0.8)' } } };
      }
    } else if (placement.includes('center')) {
      props = { ...props, position: 'absolute', transform: 'translateX(0) scale(1)' };
      if (placement && placement.includes('top')) {
        props = { ...props, position: 'relative', zIndex: 1 };
      }
    }
  }
  
  return (
    <CloudMapWrapper {...props} visible={placement && placement.includes('top')} >
      <Heading2>Current</Heading2>
      <Flex flexDirection='column' maxWidth={{ _: '30rem' }} width={{ _: '70vw' }} backgroundColor='gray' borderRadius='0.5rem' alignItems='center' padding={{ _: '2rem 1rem' }} marginTop={{ _: '1rem' }}>
        <Heading4>Global world values</Heading4>
        <Paragraph fontSize={{ _: '1.1rem' }}>of Eticia users</Paragraph>

        <Typography>Láska Radost</Typography>
        <Typography>Spolupráce</Typography>
        <Typography>Soucit Rodina</Typography>
        <Typography>Štědrost, laskavost</Typography>
        <Typography>Moudrost</Typography>
        <Typography>Zodpovědnost</Typography>

        <Typography>See full list of values</Typography>
        <PlusTag>Try Eticia plus</PlusTag>
      </Flex>
    </CloudMapWrapper>
  );
};

const CloudMapWrapper = styled(Box)<{visible?: boolean}>`
  transition: transform 500ms ease, z-index 500ms ease;

  h2 {
    opacity: ${({ visible }) => visible ? 1 : 0};
    transition: opacity 500ms ease;
  }
`;