import { ProductType } from 'components/pages/App/Shop/data';
import { Route } from 'react-router';
import * as React from 'react';
import { FC } from 'react';
import { createUrl } from 'components/pages/routes';
import Buy from 'components/pages/App/Shop/Buy';
import Success from 'components/pages/App/Shop/Buy/Success';


const Shop: FC = () => {
  const baseUrl = createUrl('shop');
  const buyUrl = createUrl('shop') + '/koupit';
  const successUrl = createUrl('shop') + '/success';

  return (
    <>
      <div>
        <Route path={buyUrl + '/voucher-1rok'} render={() => <Buy v={ProductType.EticiaPlus1YearVoucher} />} />
        <Route path={buyUrl + '/voucher-3roky'} render={() => <Buy v={ProductType.EticiaPlus3YearsVoucher} />} />
        <Route path={buyUrl + '/voucher-navzdy'} render={() => <Buy v={ProductType.EticiaPlusForeverVoucher} />} />
        <Route path={buyUrl + '/ladders'} render={() => <Buy v={ProductType.ThreeLadders} />} />
        <Route path={buyUrl + '/tree'} render={() => <Buy v={ProductType.TreeVoucher} />} />

        <Route path={successUrl} exact render={() => <Success noApp />} />
      </div>
    </>
  );
};

export default Shop;
