import React, { useState, FC, Dispatch, SetStateAction } from 'react';
import { IUserInfo } from 'store/User';
import { Box } from '@xcorejs/ui';

export const useTextFreePlusForever = (user: IUserInfo) => {

  const [isPlus, setIsPlus] = useState<boolean>(user.eticiaPlus);
  const [isForever, setIsForever] = useState<boolean>(user.eticiaPlusForever);

  return { isPlus, isForever, setIsPlus, setIsForever };
};

interface SwitchMembershipButtonsProps {
  isPlus: boolean;
  isForever: boolean;
  setIsPlus: Dispatch<SetStateAction<boolean>>;
  setIsForever: Dispatch<SetStateAction<boolean>>;
}

const SwitchMembershipButtons: FC<SwitchMembershipButtonsProps> = ({
  isPlus,
  isForever,
  setIsPlus,
  setIsForever,
}) => {
  return ( 
    <Box position='fixed' bottom='0' zIndex={9999}>
      <button 
        onClick={() => setIsPlus(prev => !prev)} 
        style={{
          backgroundColor: isPlus ? 'green' : 'red',
          color: 'white',
          border: 'none',
        }}
      >Změnit na {isPlus ? 'free' : 'plus'} verzi</button>
      {isPlus && (
        <button
          onClick={() => setIsForever(prev => !prev)}
          style={{
            backgroundColor: isForever ? 'purple' : 'orange',
            color: 'white',
            border: 'none',
          }}
        >Změnit na {isForever ? '1/3 roky' : 'navždy'} verzi</button>
      )}
    </Box>
  );
};
 
export default SwitchMembershipButtons;