import * as React from 'react';
import wrapIntl, { IWrapIntl } from '../../atoms/wrapIntl';
import { withRouter, RouteComponentProps } from 'react-router';
import { parseQueryParams } from '../../../utils/helpers';
import { Option } from 'react-select';
import { FormattedMessage } from 'react-intl';
import withUser, { IWithUser } from '../../molecules/withRedux/withUser';
import { IStringMap } from '../../../utils/index';
import InlineFormSelect from '../../atoms/InlineForm/InlineFormSelect';
import InlineFormInput from '../../atoms/InlineForm/InlineFormInput';
import { IDemoUserInfo } from '../../../store/User/index';
import { IProfileData, editProfile } from './data';
import { _switch, pick } from '../../../utils/option';
import { createUrl } from '../../pages/routes';
import Fetch from '../../atoms/Fetch';
import LoadingSpinner from '../../atoms/Animations/LoadingSpinner';
import { RequestContext } from 'components/atoms/RequestContext';
import { Box, Flex, Typography } from '@xcorejs/ui';
import { jsonFetch } from 'utils/fetch';
import { COUNTRIES } from 'config/apiRoutes';

interface IProfileEdit {
  updateUser: (data: IDemoUserInfo) => unknown;
  insideQuest?: boolean;
}

interface IProfileEditState {
  user: Partial<IProfileData>;
  errors: IStringMap<string[]>;
  countryData: {
    value: string;
    label: string;
  }[];
}

interface ICountry {
  id: string;
  name: string;
}

interface IRegion {
  id: string;
  name: string;
}

type Props =
  & IProfileEdit
  & IWrapIntl
  & RouteComponentProps<any>
  & IWithUser
  & {
  insideQuest?: boolean;
};

class AddDemographic extends React.Component<Props, IProfileEditState> {
  public state: IProfileEditState  = { user: {}, errors: {}, countryData: [] };

  componentDidMount() {
    this.fetchCountryData();
  }

  public render() {
    const { formatMsg, children, user: { child : isChild } } = this.props;
    const errors = this.state.errors;
    const user = {
      ...this.props.user,
      ...this.state.user,
      regionId: this.state.user!.regionId || (this.props.user.region ? this.props.user.region.id : undefined),
    };

    return (
      <RequestContext>
        {({ locale }) => (
          <form onSubmit={this.onSubmit}>
            <Box maxWidth='41.6rem' mx='auto'>
              <Flex flexDirection='column' mt='3.2rem'>
                <Typography mb='0.8rem' textAlign='center' color='rgba(255, 255, 255, 0.6)'>
                  <FormattedMessage id={'demoInline.born'} />{' '}<br />
                </Typography>

                <InlineFormInput
                  type='number'
                  name='yearOfBirth'
                  required
                  value={user.yearOfBirth === 0 ? '' : user.yearOfBirth}
                  error={
                    errors.YearOfBirth && errors.YearOfBirth[0] ||
                    this.props.user.yearOfBirth === 0 &&
                    user.yearOfBirth !== 0 &&
                    (new Date(Date.now()).getFullYear() - 13 < user.yearOfBirth ? 
                      formatMsg('demo.yearOfBirth.error2') : 
                      // eslint-disable-next-line no-extra-parens
                      (!isChild && new Date(Date.now()).getFullYear() - 16 < user.yearOfBirth) ? 
                        formatMsg('demo.yearOfBirth.error3') :
                        user.yearOfBirth < 1910 ? formatMsg('demo.yearOfBirth.error') : '') ||
                      undefined
                  } 
                  onChange={this.handleInput}
                  placeholder={formatMsg('demo.year.placeholder')}
                  disabled={this.props.user.yearOfBirth !== 0}
                />
              </Flex>

              {/* PŘÍPRAVA NA ZMĚNU ZEMĚ */}
              <Flex flexDirection='column' mt='2.4rem'>
                <Typography mb='0.8rem' textAlign='center' color='rgba(255, 255, 255, 0.6)'>
                  <FormattedMessage id='demoInline.country' />{' '}
                </Typography>

                <InlineFormSelect
                  name='countryId'
                  required
                  placeholder={formatMsg('demoInline.country.placeholder')}
                  error={errors.Country && errors.Country[0]}
                  value={user.countryId || user.country && user.country.id}
                  onChange={this.handleSelect('countryId') as any}
                  searchable
                  options={[
                    {
                      value: 0,
                      label: formatMsg('demoInline.country.placeholder'),
                      disabled: true,
                    },
                    ...this.state.countryData]}
                />
              </Flex>
              <Typography color='lightcoral'>Zatím neukládá změnu</Typography>

              {locale === 'cs' && (
                <Flex flexDirection='column' mt='2.4rem'>
                  <Typography mb='0.8rem' textAlign='center' color='rgba(255, 255, 255, 0.6)'>
                    <FormattedMessage id={'demoInline.region'} />{' '}
                  </Typography>

                  <Fetch url={'/api/v1/utils/regions'}>
                    {_switch({
                      success: (result: IRegion[]) => (
                        <InlineFormSelect
                          name='regionId'
                          required
                          placeholder={formatMsg('demoInline.region.placeholder')}
                          error={errors.Region && errors.Region[0]}
                          value={user.regionId || user.region && user.region.id}
                          onChange={this.handleSelect('regionId') as any}
                          options={[
                            {
                              value: 0,
                              label: formatMsg('demoInline.region.placeholder'),
                              disabled: true,
                            },
                            ...result.map(x => ({
                              value: x.id,
                              label: x.name,
                            })),
                          ]}
                        />
                      ),
                      fetching: () => <LoadingSpinner />,
                      reload: () => <LoadingSpinner />,
                      fail: () => <></>,
                    })()}
                  </Fetch>
                </Flex>
              )}
              <Flex flexDirection='column' mt='2.4rem'>
                <Typography mb='0.8rem' textAlign='center' color='rgba(255, 255, 255, 0.6)'>
                  <FormattedMessage id={'demoInline.andiam'} />
                </Typography>

                <InlineFormSelect
                  name='maritalStatus'
                  required
                  placeholder={formatMsg('demo.maritalStatus.placeholder')}
                  error={errors.MaritalStatus && errors.MaritalStatus[0]}
                  value={user.maritalStatus}
                  onChange={this.handleSelect('maritalStatus') as any}
                  options={[
                    {
                      value: 0,
                      label: formatMsg('demo.maritalStatus.placeholder'),
                      disabled: true,
                    },
                    {
                      value: 1,
                      label: formatMsg('demo.maritalStatus.single'),
                    },
                    {
                      value: 2,
                      label: formatMsg('demo.maritalStatus.married'),
                    },
                  ]}
                />
              </Flex>
              <Flex flexDirection='column' mt='2.4rem'>
                <Typography mb='0.8rem' textAlign='center' color='rgba(255, 255, 255, 0.6)'>
                  <FormattedMessage id={'demoInline.work'} />{' '}
                </Typography>

                <InlineFormSelect
                  name='workType'
                  required
                  placeholder={formatMsg('demo.work.placeholder')}
                  error={errors.WorkType && errors.WorkType[0]}
                  value={user.workType}
                  onChange={this.handleSelect('workType') as any}
                  options={[
                    {
                      value: 0,
                      label: formatMsg('demo.work.placeholder'),
                      disabled: true,
                    },
                    {
                      value: 1,
                      label: formatMsg('demo.work.head'),
                    },
                    {
                      value: 2,
                      label: formatMsg('demo.work.hands'),
                    },
                  ]}
                />
              </Flex>

              {children}
            </Box>
          </form>
        )}
      </RequestContext>
    );
  }

  private todayYear = new Date(Date.now()).getFullYear();

  private fetchCountryData = () => {
    jsonFetch<ICountry[]>(COUNTRIES)
      .then((result) => {
        this.setState({
          countryData: result.map(x => ({
            value: x.id,
            label: x.name,
          })),
        });
      })
      .catch((e) => console.log(e));
  }

  private handleInput = (e: React.ChangeEvent<any>) =>
    this.setState({
      user: {
        ...this.state.user,
        [e.target.name]: e.target.value,
      },
    });

  private handleSelect = (name: string) => (option: Option) =>
    this.setState({
      user: {
        ...this.state.user,
        [name]: option.value,
      },
    });

  private printYearData = () => {
    if (this.state.user && this.state.user.yearOfBirth) {
      console.log('this.state.user.yearOfBirth:', this.state.user.yearOfBirth);
      console.log('this.todayYear - this.state.user.yearOfBirth < 13:', this.todayYear - this.state.user.yearOfBirth < 13);
      console.log('this.props.user.child:', this.props.user.child);
      console.log('this.todayYear - this.state.user.yearOfBirth < 16 && !this.props.user.child:', this.todayYear - this.state.user.yearOfBirth < 16 && !this.props.user.child);
      console.log('this.state.user.yearOfBirth < 1910:', this.state.user.yearOfBirth <= 1909);
    }
  }

  private onSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    const intro = parseQueryParams(this.props.location.search).intro !== undefined;
    const afterTest = this.props.location.state !== undefined && this.props.location.state.afterTest;
    const redirect = this.props.location.state && this.props.location.state.redirect;

    if (
      this.state.user &&
      this.state.user.yearOfBirth &&
      (this.todayYear - this.state.user.yearOfBirth < 13 || // Condition A
      // eslint-disable-next-line no-extra-parens
      (this.todayYear - this.state.user.yearOfBirth < 16 && !this.props.user.child) || // Condition B
      this.state.user.yearOfBirth < 1910) // Condition C
    ) {
      // The user does not meet one of the conditions
      console.log('%cUser does not meet the age requirements.', 'color: red');
      return;
    } else {
      // All conditions are met
      console.log('%cUser meets the age requirements.', 'color: green');
    }
    

    editProfile(
      pick(
        {
          ...this.props.user,
          ...this.state.user,
          regionId: this.state.user!.regionId || (this.props.user.region ? this.props.user.region.id : undefined),
        },
        ['workType', 'yearOfBirth', 'maritalStatus', 'countryId', 'regionId', 'sex' as any, 'education'],
      ),
    )
      .then(result => {
        this.props.updateUser(result);
      })
      .then(() => {
        redirect ? this.props.history.push(redirect) :
          this.props.insideQuest ? this.props.history.push(createUrl('app') + '/hra')
            : afterTest
              ? this.props.history.push(createUrl('app.testResult', { id: afterTest }))
              : this.props.history.goBack()
        ;
      })
      .catch(({ data }) => data.json().then((e: any) => this.setState({ errors: e })));
  };
}

export default withUser<IProfileEdit>(withRouter(wrapIntl(AddDemographic)));
