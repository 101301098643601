import React, { FC } from 'react';
import { EticiaPlusGrid, Purple, FlexScrollMargin } from './styles';
import { Heading2, FlexProps } from '@xcorejs/ui';
import { products } from './data';
import ProductCard from './Shop/ProductCard';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

export interface ShopProps {
  free?: boolean;
  extend?: boolean;
  gift?: boolean;
  both?: boolean;
  inApp?: boolean;
}

const Shop: FC<ShopProps & FlexProps> = ({ extend, gift, both, inApp, free, ...props }) => {
  const VISIBLE_FROM_START = 1;

  return (
    <FlexScrollMargin
      as='section'
      padding='0 !important'
      paddingTop={extend || gift || free ? '4rem !important' : '0'}
      flexDirection='column'
      backgroundColor={Purple.background.light}
      id='shop'
      {...props}
    >
      {!both &&
        extend && (
        <Heading2>
          <FormattedMessage id='eticiaPlus.shop.mainHeading.extend' />
        </Heading2>
      ) ||
        gift && (
          <Heading2>
            <FormattedMessage id='eticiaPlus.shop.mainHeading.gift' />
          </Heading2>
        ) ||
        free && (
          <Heading2>
            <FormattedHTMLMessage id='eticiaPlus.shop.mainHeading.free' />
          </Heading2>
        )
      }

      <EticiaPlusGrid
        backgroundColor={Purple.background.light}
      >
        {products.map((product, i) => (
          <ProductCard 
            key={product.link}
            show={i === VISIBLE_FROM_START}
            gift={gift}
            inApp={inApp}
            both={both}
            {...product}
          />
        ))}
      </EticiaPlusGrid>
    </FlexScrollMargin>
  );
};
 
export default Shop;

