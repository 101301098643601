export interface ISliderItem {
  icon?: string;
  iconText?: string;
  text: string;
  text2?: string;
  percentage: number | null;
  textBottom: string;
  color?: 'blue' | 'green' | 'yellow' | 'gray';
  locked?: boolean;
  tag?: string;
  code?: string;
  topTenValueResults?: IValue[];
  lowestTenValueResults?: IValue[];
  visible?: number;
  top10?: number;
  plus?: boolean;
  title?: string;
  perex?: string;
  sex?: number;
  ageFrom?: number;
  ageTo?: number;
  global?: boolean;
  value?: number;
  inGroup?: boolean;
}

interface GroupCategory {
  id: string;
  name: string;
}

export interface IStatistic {
  code: string;
  title: string;
  perex: string;
  image: string | null;
  sex: number;
  ageFrom: number;
  ageTo: number;
  global: boolean;
  value: number;
  inGroup: boolean;
  ageGroup: boolean;
  groupCategory: GroupCategory | null;
  topTenValueResults: IValueWithName[];
  lowestTenValueResults: IValueWithName[];
  maritalStatus: number;
}

export interface IValue {
  valueId: string;
  points: number;
  rank: number;
}

export interface IValueWithName extends IValue {
  name: string;
}

const icons = ['/img/stats-group-all.svg', '/img/stats-men.svg', '/img/stats-women.svg'];
const colors = ['yellow', 'green', 'blue'];

export const defaultItems: ISliderItem[] = [
  {
    // Přátelé.
    iconText: 'statistics.slider.item.9.iconText',
    text: '',
    text2: '',
    color: 'gray',
    percentage: null,
    textBottom: 'statistics.slider.item.9.textBottom',
    locked: true,
  },
  {
    iconText: 'statistics.slider.item.2.iconText',
    text: '',
    text2: '',
    color: 'gray',
    percentage: null,
    textBottom: 'statistics.slider.item.2.textBottom',
    tag: 'INGROUP',
    visible: 3,
    top10: 3,
  },
  {
    icon: '/img/stats-women.svg',
    text: '',
    text2: '',
    color: 'gray',
    percentage: null,
    textBottom: 'statistics.slider.item.3.textBottom',
    tag: 'WOMEN',
    visible: 3,
    top10: 3,
  },
  {
    icon: '/img/stats-men.svg',
    text: '',
    text2: '',
    color: 'green',
    percentage: null,
    textBottom: 'statistics.slider.item.4.textBottom',
    tag: 'MAX',
    locked: true,
  },
  {
    icon: '/img/stats-group-all.svg',
    text: 'statistics.slider.item.5.text',
    text2: '',
    color: 'yellow',
    percentage: 0,
    textBottom: 'statistics.slider.item.5.textBottom',
    tag: 'ALL',
    visible: 0,
    top10: 0,
  },
  {
    icon: '/img/stats-women.svg',
    text: 'statistics.slider.item.6.text',
    text2: 'statistics.slider.item.6.text2',
    color: 'blue',
    percentage: null,
    textBottom: 'statistics.slider.item.6.textBottom',
    tag: 'MIN',
    visible: 0,
    top10: 3,
  },
  {
    icon: '/img/stats-men.svg',
    text: '',
    text2: '',
    color: 'gray',
    percentage: null,
    textBottom: 'statistics.slider.item.7.textBottom',
    tag: 'MAN',
    visible: 3,
    top10: 3,
  },
  {
    // Vlastní nastvení.
    iconText: 'statistics.slider.item.8.iconText',
    text: '',
    text2: '',
    color: 'gray',
    percentage: null,
    textBottom: 'statistics.slider.item.8.textBottom',
    locked: true,
  },
];

const setTag = (stat: IStatistic, values: number[], StatisticsLevel: number) =>
  stat.global && stat.code === 'ALL'
    ? 'ALL'
    : stat.code === 'M-ALL'
      ? StatisticsLevel > 2
        ? 'MAN'
        : undefined
      : stat.code === 'F-ALL'
        ? StatisticsLevel > 2
          ? 'WOMEN'
          : undefined
        : stat.inGroup && StatisticsLevel > 2
          ? 'INGROUP'
          : !stat.global && stat.value === Math.min(...values)
            ? 'MIN'
            : !stat.global && stat.value === Math.max(...values)
              ? StatisticsLevel > 3
                ? 'MAX'
                : undefined
              : undefined;

const addTags = (stats: IStatistic[], values: number[], StatisticsLevel: number) =>
  stats.map(x => {
    return { ...x, tag: setTag(x, values, StatisticsLevel) };
  });

const findStat = (stats: Array<IStatistic & { tag?: string }>, tag: string) => stats.find(x => x.tag === tag);

export const mapResult = (stats: IStatistic[], StatisticsLevel: number) => {
  const values = stats.filter(x => !x.global).map(x => x.value);
  const newStats = addTags(stats, values, StatisticsLevel);
  return defaultItems.map(x => {
    const stat = x.tag ? findStat(newStats, x.tag) : undefined;
    return {
      ...x,
      ...stat,
      text2: stat ? stat.title : x.text,
      text: stat ? stat.perex : x.text,
      color: stat ? colors[stat.sex] : x.color,
      percentage: stat ? stat.value.toFixed(1) : x.percentage,
      icon: stat ? icons[stat.sex] : x.icon,
      code: stat ? stat.code : undefined,
      topTenValueResults: stat ? stat.topTenValueResults : undefined,
      lowestTenValueResults: stat ? stat.lowestTenValueResults : undefined,
    } as ISliderItem;
  });
};
