import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import withUser, { IWithUser } from 'components/molecules/withRedux/withUser';
import { StyledValueComparisonSection, FlexColumn, StyledSuccessVoucher } from '../Comparisons/styles';
import { Heading1 } from 'components/atoms/Headings';
import SVGvoucher from 'components/atoms/SVGIcons/SVGvoucher';
import { SmallerText } from 'components/organisms/VisualValuesComparison/style';
import { isReadable } from 'utils/rescource';
import { PrimaryButtonLink } from 'components/atoms/Buttons';
import { createUrl } from 'components/pages/routes';

interface Props {
  delivery: boolean;
}

const SuccessGift: React.FC<Props & IWithUser> = ({ rescource, delivery }) => (
  <StyledValueComparisonSection>
    <FlexColumn>
      <StyledSuccessVoucher>
        <Heading1>
          <FormattedMessage id={'gift.success'} />
        </Heading1>

        <SVGvoucher />

        <SmallerText>
          <FormattedMessage id={delivery ? 'gift.success.text.delivery' : 'gift.success.text'} />
        </SmallerText>

        {isReadable(rescource.user) && (
          <PrimaryButtonLink to={createUrl('app.comparison')}>
            <FormattedMessage id={'gift.success.btn'} />
          </PrimaryButtonLink>
        )}
      </StyledSuccessVoucher>
    </FlexColumn>
  </StyledValueComparisonSection>
);

export default withUser<Props>(SuccessGift, false);
