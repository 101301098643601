import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Heading1 } from '../../../../../atoms/Headings/index';
import { BiggerText } from '../../../../../atoms/Text/index';

const Done: React.FC = () => (
  <div>
    <Heading1 className={'not-mobile'}>
      <FormattedMessage id={'support.heading.step3'} />
    </Heading1>
    <BiggerText>
      <FormattedMessage id={'support.text.step3'} />
    </BiggerText>
  </div>
);

export default Done;
