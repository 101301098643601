import * as React from 'react';
import { FC } from 'react';
import Subnav from 'components/atoms/Subnav';
import { createUrl } from 'components/pages/routes';
import { Route } from 'react-router';
import Code from 'components/pages/App/Activation/Code';
import Success from 'components/pages/App/Activation/Success';

const Activation: FC = () => {
  const baseUrl = createUrl('app.activation');

  return (
    <>
      <Subnav />

      <div>
        <Route
          path={baseUrl}
          exact={true}
          render={() => <Code />}
        />

        <Route path={baseUrl + '/plus-1rok'} render={() => <Success v='plus-1rok' />} />
        <Route path={baseUrl + '/plus-3roky'} render={() => <Success v='plus-3roky' />} />
        <Route path={baseUrl + '/plus-navzdy'} render={() => <Success v='plus-navzdy' />} />
      </div>
    </>
  );
};

export default Activation;
