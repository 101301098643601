import { GraphLegendDescription } from './styles';
import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

export const GraphLegend = (
  <GraphLegendDescription>
    <div>
      <span className={'symbol'}>A</span>
      <div>
        <FormattedHTMLMessage id={'test.result.graph.legend.a'} />
        <p>
          <FormattedHTMLMessage id={'test.result.graph.legend.a.text'} />
        </p>
      </div>
    </div>
    <div>
      <span className={'symbol'}>B</span>
      <div>
        <FormattedHTMLMessage id={'test.result.graph.legend.b'} />
        <p>
          <FormattedHTMLMessage id={'test.result.graph.legend.b.text'} />
        </p>
      </div>
    </div>
    <div>
      <span className={'symbol'}>C</span>
      <div>
        <FormattedHTMLMessage id={'test.result.graph.legend.c'} />
        <p>
          <FormattedHTMLMessage id={'test.result.graph.legend.c.text'} />
        </p>
      </div>
    </div>
    <div>
      <span className={'symbol'}>D</span>
      <div>
        <FormattedHTMLMessage id={'test.result.graph.legend.d'} />
        <p>
          <FormattedHTMLMessage id={'test.result.graph.legend.d.text'} />
        </p>
      </div>
    </div>
    <div>
      <span className={'symbol'}>E</span>
      <div>
        <FormattedHTMLMessage id={'test.result.graph.legend.e'} />
        <p>
          <FormattedHTMLMessage id={'test.result.graph.legend.e.text'} />
        </p>
      </div>
    </div>
    <div>
      <span className={'symbol'}>F</span>
      <div>
        <FormattedHTMLMessage id={'test.result.graph.legend.f'} />
        <p>
          <FormattedHTMLMessage id={'test.result.graph.legend.f.text'} />
        </p>
      </div>
    </div>
    <div>
      <span className={'symbol'}>G</span>
      <div>
        <FormattedHTMLMessage id={'test.result.graph.legend.g'} />
        <p>
          <FormattedHTMLMessage id={'test.result.graph.legend.g.text'} />
        </p>
      </div>
    </div>
    <div>
      <span className={'symbol'}>H</span>
      <div>
        <FormattedHTMLMessage id={'test.result.graph.legend.h'} />
        <p>
          <FormattedHTMLMessage id={'test.result.graph.legend.h.text'} />
        </p>
      </div>
    </div>
  </GraphLegendDescription>
);
