import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, Heading2, Typography, Button, Paragraph, Flex, useModal, Link, Heading1 } from '@xcorejs/ui';
import { GreenTheme, gray } from 'components/theme/colors';
import { sendFeedback } from 'components/pages/App/Feedback/actions';
import styled from 'styled-components';
import { fetchUserInfo } from 'store/User/UserActions';
import { connect } from 'react-redux';
import withUser, { IWithUser } from 'components/molecules/withRedux/withUser';
import LoadingSpinner from 'components/atoms/Animations/LoadingSpinner';

interface ISettingsProps {
  getProfile?: () => any;
  charity?: boolean;
}

const FeedbackModal: React.FC<ISettingsProps & IWithUser> = ({
  user, charity, ...props
}) => {
  const [close] = useModal();

  const [page, setPage] = useState<number>(1);

  const [feedbackText, setFeedbackText] = useState('');
  const [feedbackEmail, setFeedbackEmail] = useState(user.email ? user.email : '');
  const [symbolCount, setSymbolCount] = useState<number>(0);
  const MAXIMUM_CHARACTERS = 700;

  const submit = () => {
    if (feedbackText.trim().length > 0 && feedbackText.length <= MAXIMUM_CHARACTERS)
      sendFeedback(charity ? 'charity' : 'feedback')(`${feedbackText}. E-mail: ${feedbackEmail}`).then(()=>setPage(2));
  };


  const showPage = ():JSX.Element => {
    switch (page) {
    case 2:
      return (
        <Modal 
          background={GreenTheme.primary}
          height='30rem'
        >
          <Flex 
            height='100%' 
            width='100%' 
            flexDirection='column' 
            justifyContent='center' 
            alignItems='center'
          >
            <Heading1 position='relative' bottom='10px'>
              <FormattedMessage id='feedback.thanks' />
            </Heading1>
    
            <Button onClick={() => close()} mt='3rem'>
              <FormattedMessage id='feedback.close.modal' />
            </Button>
          </Flex>
        </Modal>
      );
    case 1:
      return (
        <Modal background={GreenTheme.primary}>
          <StyledBeta>
            <Flex flexDirection='column' alignItems='center'>
              <Heading2>
                <FormattedMessage id='feedback.bug' />
              </Heading2>

              <Typography
                mt='3rem'
                fontSize={{ _: '1.4rem', sm: '1.6rem' }}
                lineHeight={{ _: '2rem', sm: '2.2rem' }}
              >
                <FormattedMessage id='feedback.bug.text' />
              </Typography>
                    
              <Flex flexDirection='column' width='100%' alignItems='center'>
                <section>
                  <textarea
                    value={feedbackText}
                    onChange={e => {
                      setFeedbackText(e.target.value);
                      setSymbolCount(e.target.value.length);
                    }}
                    name='feedback'
                  />
                  <p style={{ color: symbolCount <= MAXIMUM_CHARACTERS ? 'gray' : 'red' }}>
                    {symbolCount}/{MAXIMUM_CHARACTERS}
                  </p>
                </section>

                <input
                  value={feedbackEmail}
                  onChange={e => setFeedbackEmail(e.target.value)}
                  placeholder='E-mail'
                />
              </Flex>

              <Paragraph mt='3rem'>
                <Button onClick={submit}>
                  <FormattedMessage id='results.finish' />
                </Button>
              </Paragraph>

              <Link onClick={close} mt='3rem'>
                <FormattedMessage id='feedback.close.modal' />
              </Link>

            </Flex>
          </StyledBeta>
        </Modal>
      );
      
    default:
      return <LoadingSpinner />;
    }
  };

  return (
    <>
      { showPage() }
    </>
    
  );
};

// redux connect
const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch: any) => ({
  getProfile: () => {
    dispatch(fetchUserInfo());
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(withUser(FeedbackModal));

const StyledBeta = styled.section`
  textarea, input {
    width: 100%;
    border-radius: 1.2rem;
    background-color: #ffffff;
    color: #191d2c;
    font-size: 1.8rem;
    line-height: 2.2rem;
    padding: 1.4rem 1rem;
    border: none;
    height: 14rem;
    resize: none;
    margin-top: 2rem;
    outline: none;

    &:focus {
      outline: none;
    }
  }

  input {
    height: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    text-align: center;
    color: rgb(100, 100, 100);

    &:focus {
      text-align: left;
    }
  }

  section {
    position: relative;
    width: 100%;
  }

  section p {
    position: absolute;
    right: 10px;
    bottom: 5px;
    font-size: 10px;
  }

  a {
    opacity: 0.8;
    color: ${gray};
    font-size: 1.8rem;
    line-height: 2.2rem;
    display: inline-block;
    border-bottom: 1px solid ${gray};
    transition: border 0.3s;
    
    &:hover {
      border-bottom: 1px solid transparent;
    }
  }
`;