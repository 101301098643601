import { Box, Container, Flex, Heading1, Heading3, Link, Typography } from '@xcorejs/ui';
import { RequestContext } from 'components/atoms/RequestContext';
import SVGgoogle from 'components/atoms/SVGIcons/SVGgoogle';
import { PrimaryButton } from 'components/components/Button';
import { editProfile } from 'components/molecules/DemographicForm/data';
import { changeDatingPhoto } from 'components/pages/App/Profile/Settings/actions';
import KidsSwitch from 'components/pages/Registration/KidsSwitch';
import MeeticiaForm from 'components/pages/Registration/MeeticiaForm';
import * as React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import Fade from 'react-reveal/Fade';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IFetchException } from '../../../utils/exceptions';
import LoadingSpinner from '../../atoms/Animations/LoadingSpinner';
import { StyledLink } from '../../atoms/Buttons/styles';
import wrapIntl, { IWrapIntl } from '../../atoms/wrapIntl';
import { createUrl } from '../routes';
import { register, registerDating } from './actions';
import AgeCheckPopup from './Popups/AgeCheckPopup';
import RegisterForm from './RegisterForm';

interface IRegistrationProps {
  popup: (body: React.ComponentType, title?: string, button?: string) => any;
  popupParents: () => any;
}

export interface IRegistrationState {
  step: number;
  errors: IRegisterUserErrors;
  knownUserInfo: IRegisterUserVm | null;
  child: boolean;
}

export interface IRegisterUserVm {
  name?: string;
  nick: string;
  email: string;
  password: string;
  femalePronouns?: boolean | string;
  child: boolean;
  countryId?: string;
}

export interface IRegisterUserErrors {
  Nick?: string[];
  Email?: string[];
  Password?: string[];
  Name?: string[];
  agree?: string;
  sex?: string;
  countryId?: string;
  file?: string;
}

export interface IRegisterMeeticiaUserVm extends IRegisterUserVm {
  yearOfBirth?: number;
  interestSex?: boolean | string;
  interestType?: number;
  interestAgeFrom?: number;
  interestAgeTo?: number;
  description?: string;
  file?: File;
}

export interface IRegisterMeeticiaUserErrors extends IRegisterUserErrors {
  yearOfBirth?: string[];
  interestSex?: string[];
  interestType?: string[];
  interestAgeFrom?: string[];
  interestAgeTo?: string[];
  description?: string[];
}

type Props = IRegistrationProps & IWrapIntl & RouteComponentProps<any>;

class Registration extends React.Component<Props, IRegistrationState> {
  public state: IRegistrationState = {
    step: 0,
    errors: {},
    knownUserInfo: null,
    child: false
  };

  private country =  new URLSearchParams(this.props.location.search).get('c');

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const kids = query.get('kids');

    if (kids === 'true') {
      const kidsSwitch = document.getElementById('kidsSwitch');
      kidsSwitch && kidsSwitch.click();
    }
  }
  
  public render() {
    const { popup, location, history } = this.props;
    const { step } = this.state;

    if (step === 1) {
      return <LoadingSpinner />;
    }

    return (
      <>          
        {location.pathname.includes('seznamka') ? (
          <RequestContext>
            {({ locale }) => (
              <MeeticiaForm
                sendRegistration={user => this.sendRegistration(user, locale, true)}
                errors={this.state.errors}
                knownUserInfo={this.state.knownUserInfo}
                popup={popup}
                popups={{} as any}
              />
            )}
          </RequestContext>
        ) : (
          <Box
            bg="url('/img/global.jpg')"
            backgroundPosition='center bottom'
            backgroundSize='cover'
            {...{ backgroundAttachment: 'fixed' }}
            minHeight='100vh'
            top={0}
            pt='6rem'
            pb='3.5rem'
            mt='-6rem'
            mb='-3.5rem'
          >
            <Container flexDirection='column'>
              <Fade ssrReveal bottom duration={1000} distance={'5rem'}>
                <div>
                  <div className='heading'>
                    <Heading1 fontSize='3.2rem' lineHeight='3.9rem' fontWeight={400} textAlign='center'>
                      <FormattedHTMLMessage id={'registrationInline'} />
                    </Heading1>
                    {this.state.child ? (
                      <Heading3 mt='0' textAlign='center' color='#ffa4a3'>
                        <FormattedMessage id={'registrationInline.forKids'} />
                      </Heading3>
                    ):null}

                    <Typography mt='2.4rem'>
                      <FormattedHTMLMessage id={'registration.text'} />

                      {!this.state.child && (
                        <>
                          <FormattedHTMLMessage id={'registration.text.under16'} />
                          <KidsSwitch onAgreement={() => this.setState({ ...this.state, child: true })} />
                        </>
                      )}
                    </Typography>

                    <Typography mt='1.6rem'>
                      <FormattedMessage id={'login.alreadyAccount'} />{' '}
                      <StyledLink
                        to={{
                          pathname: createUrl('login'),
                          state: this.props.location.state,
                        }}
                      >
                        <Link v='underline' color='yellow' borderColor='yellow'>
                          <FormattedMessage id={'login.alreadyAccount.login'} />
                        </Link>
                      </StyledLink>
                    </Typography>
                  </div>
                </div>
              </Fade>

              <Fade ssrReveal bottom duration={1000} delay={400} distance={'5rem'}>
                <Flex
                  justifyContent='center'
                  maxWidth='41.6rem'
                  mx='auto'
                  borderBottom='1px solid rgba(216, 216, 216, 0.18)'
                  mt='3.2rem'
                  pb='3.2rem'
                >
                  <PrimaryButton as='a' href='/login/google' bg='#f4f4f6' _hover={{ bg: '#fff' }}>
                    <SVGgoogle />
                    <span style={{ marginLeft: '1.6rem' }}>
                      <FormattedMessage id={'login.google'} />
                    </span>
                  </PrimaryButton>
                </Flex>
              </Fade>

              <RequestContext>
                {({ locale }) => (
                  <RegisterForm
                    sendRegistration={user => this.sendRegistration(user, locale)}
                    popup={popup}
                    errors={this.state.errors}
                    knownUserInfo={this.state.knownUserInfo}
                    popups={{} as any}
                    child={this.state.child}
                    urlCountry={this.country}
                  />
                )}
              </RequestContext>
            </Container>
          </Box>
        )}

      </>
    );
  }

  private sendRegistration = (user: IRegisterUserVm, locale: string, meeticia?: boolean) => {
    // Check users birth year
    const year = Number.parseInt('1990', 10);
    const now = new Date().getFullYear();

    this.setState({ knownUserInfo: user });

    if (now - 16 < year) {
      this.props.popupParents();
      return;
    }

    if (now - 16 === year) {
      this.props.popup(() => <AgeCheckPopup agree={() => this.register(user, locale)} />, undefined);
      return;
    }

    if (user.femalePronouns === undefined) {
      this.setState({
        step: this.state.step,
        errors: {
          ...this.state.errors,
          sex: this.props.formatMsg('registration.empty'),
        },
      });
      return;
    }

    meeticia ? 
      this.registerMeeticia(user, locale)
      : 
      this.register(user, locale);
  };

  private register = (user: IRegisterUserVm, locale: string) => {
    this.setState({ step: 1 });
    register({
      ...user,
      registrationLocale: locale,
    })
      .then(() => {
        editProfile({ ...user, countryId: user.countryId });})
      .then(() => {
        localStorage.setItem('firstTime', 'true');
      })
      .then(() => {
        this.props.history.push(createUrl('register.profile'), this.props.location.state);
        // location.href = createUrl("register.profile");
      })
      .catch((e: IFetchException) =>
        e.data.json().then(errors =>
          this.setState({
            step: 0,
            errors: { ...errors, agree: this.state.errors.agree },
          }),
        ),
      );
  };
  
  private registerMeeticia = (user: IRegisterMeeticiaUserVm, locale: string) => {

    if (user.file === undefined) {
      this.setState({
        errors: {
          ...this.state.errors,
          file: 'Fotka je povinná.',
        },
      });
      return;
    }

    const formData = new FormData();
    user.file && formData.set('file', user.file);

    const req = {
      method: 'POST',
      body: formData,
    };

    console.log(req);

    this.setState({ step: 1 });
    register({
      ...user,
      name: '',
      registrationLocale: locale,
      femalePronouns: user.femalePronouns === '1'
    })
      .then(() => {
        registerDating({ ...user, interestSex: user.interestSex === '1' })
          .then(() => {
            changeDatingPhoto(req, () => console.log('success'), () => console.log('fail'));
          })
          .then(() => {
            editProfile({ ...user, yearOfBirth: user.yearOfBirth });
          })
          .then(() => {
            localStorage.setItem('firstTime', 'true');
          })
          .then(() => setTimeout(
            () => { location.href = createUrl('app.gameIntro') + '?t=9834e2a8-2600-4abd-ba8d-3ef784023cb1';
            }, 2000)
          );
      })
      .catch((e: IFetchException) =>
        e.data.json().then(errors =>
          this.setState({
            step: 0,
            errors: { ...errors, agree: this.state.errors.agree },
          }),
        ),
      );
  };

  private disabledRegistation = () => this.props.history.push(createUrl('registrationFull'), this.props.location.state);
}

export default withRouter(wrapIntl(Registration));
