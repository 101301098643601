import { connect } from 'react-redux';
import Component from './component';
import { addInfo } from 'store/FlashMessages';

const mapDispatchToProps = (dispatch: any) => ({
  addInfo: (msg: string) => {
    dispatch(addInfo(msg));
  },
});

export default connect(null, mapDispatchToProps)(Component);
