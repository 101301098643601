import * as React from 'react';
import { IInterest } from '../../../../store/Entities/LifeValues';
import ValueDefinitionCard from './ValueDefinitionCard';
import Vote from '../Vote';
import wrapIntl, { IWrapIntl } from '../../../atoms/wrapIntl';

interface ITriviaCard {
  trivia: IInterest;
  valueId: string;
  onVote: (id: string, type: string, text?: string) => any;
}

const TriviaCard: React.FC<IWrapIntl & ITriviaCard> = ({ trivia, formatMsg, valueId, onVote }) => (
  <ValueDefinitionCard
    color={'blue'}
    label={formatMsg('valueDefinition.trivia')}
    img={trivia.image}
    name={trivia.name}
    text={trivia.content}
  >
    <Vote
      id={valueId}
      defId={trivia.id}
      type='trivia'
      onVote={(id: string, type: string, text?: string) => onVote(id, type, text)}
    />
  </ValueDefinitionCard>
);

export default wrapIntl(TriviaCard);
