import * as React from 'react';
import { CardsGridTheme } from '../../../theme/grid';
import { ThemeProvider } from 'styled-components';
import ValueDefinition from '../../../organisms/ValueDefinition';
import Test from '../../../organisms/Test';
import { RouteComponentProps, withRouter } from 'react-router';
import { addTestRecord, skipTestRecord } from './data';
import { TEST_CURRENT_STEP } from 'config/apiRoutes';
import useFetch from 'components/atoms/Fetch/useFetch';
import { ICurrentRound } from '../../../organisms/Test/data';
import withPopups, { IWithPopups } from 'components/molecules/withRedux/withPopups';
import { createUrl } from '../../routes';

const PartnerTest: React.FC<IWithPopups & RouteComponentProps<{ id: string }>> = ({ match, popups }) => {
  const [detail, setDetail] = React.useState<string | null>(null);
  const [wasShown, setShown] = React.useState<boolean>(false);
  const round = useFetch<ICurrentRound>(TEST_CURRENT_STEP(match.params.id));

  React.useEffect(() => {
    if (round.state === 'success' && !wasShown && round.result!.globalStep === 1) {
      popups.html({
        title: 'popup.partnerTest.title',
        content: 'popup.partnerTest',
        button: 'popup.partnerTest.button',
      });
      setShown(true);
    }
  });

  return (
    <>
      <ThemeProvider theme={CardsGridTheme}>
        <Test
          id={match.params.id}
          round={round}
          openValueDefinition={setDetail}
          sendData={addTestRecord}
          skip={skipTestRecord}
          onFinish={() =>
            createUrl('app.partnerResult', {
              id: round.result!.testConfigId as string,
            }) + '/default'
          }
          partner={true}
        />
      </ThemeProvider>
      {detail !== null && <ValueDefinition value={detail} onClose={() => setDetail(null)} inTest={false} />}
    </>
  );
};

export default withPopups(withRouter(PartnerTest));
