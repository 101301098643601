import styled, { css } from 'styled-components';
import { SecondaryButton } from '../../atoms/Buttons';
import {
  darkBaseRGB,
  white,
  yellow,
  darkBaseHEX,
  yellowLighter,
  darkenViolet,
  violet,
  primaryGreen,
  GrayTheme,
  darkenGreen,
  darkenBlue,
  blue,
} from '../../theme/colors';
import { mediaLgDefault, mediaMdDefault, mediaSmDefault } from '../../theme/grid';
import { bold } from '../../theme/fonts';

export const StyledQuestLog = styled.div`
  display: inline-block;
  position: relative;
  margin-top: 3rem;
  max-width: 100%;
  width: 100%;

  & > a,
  & > span {
    margin-top: 1rem;
    display: inline-block;
    cursor: pointer;

    &:first-child {
      margin-top: 0;
    }
  }

  ${SecondaryButton} {
    margin-top: 4rem;
  }
`;

export const StyledQuestObjective = styled.span<{ done: string; icoBackground?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
  border-radius: 6.5rem;
  transition: background 300ms ease;
  width: 30rem;
  max-width: 100%;

  ${mediaSmDefault} {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  
  ${mediaMdDefault} {
    display: inline-flex;
    justify-content: flex-start;
    margin-left: auto;
    margin-right: auto;
  }

  .ico-wrap {
    display: none;
    width: 6rem;
    min-width: 6rem;
    height: 6rem;
    text-align: center;
    background-color: ${white};
    border-radius: 50%;

    &:before {
      content: "";
      display: inline-block;
      height: 100%;
      vertical-align: middle;
    }

    img,
    .external-svg {
      max-width: 55%;
      max-height: 55%;
      display: inline-block;
      vertical-align: middle;
    }

    svg {
      width: 3.3rem;
      height: 3.3rem;
    }

    ${mediaLgDefault} {
      display: inline-block;
    }
  }

  .text-wrap {
    display: inline-block;
    padding: 1rem;
    vertical-align: top;

    .main-text {
      font-size: 1.6rem;
      line-height: 2.1rem;
      font-weight: ${bold};
      transition: color 300ms ease;
    }

    .desc {
      font-size: 1.4rem;
      line-height: 1.7rem;
      opacity: 0.8;
      transition: color 300ms ease;
    }

    ${mediaLgDefault} {
      padding: 1rem 1rem 1rem 1.5rem;
    }
  }

  &:hover {
    background-color: ${yellow};

    .main-text {
      color: ${darkBaseHEX} !important;
    }

    .desc {
      color: ${props => props.theme.darken};
      opacity: 1;
    }
  }

  &.yellow {
    background-color: ${yellow};

    .main-text {
      color: ${darkBaseHEX};
    }

    .desc {
      color: ${props => props.theme.darken};
      opacity: 1;
    }

    &:hover {
      background-color: ${yellowLighter};
    }
  }

  &.green {
    background-color: rgba(${darkBaseRGB}, 0.3);

    .main-text {
      color: ${yellow};
    }

    .desc {
      color: ${white};
      opacity: 1;
    }

    &:hover {
      background-color: ${yellow};
    }
  }

  &.greenDisabled {
    background-color: rgba(${darkBaseRGB}, 0.2);

    .main-text {
      opacity: 0.5;
      color: ${white};
    }

    .desc {
      color: ${white};
      opacity: 1;
    }

    &:hover {
      background-color: rgba(${darkBaseRGB}, 0.2);

      .main-text {
        color: ${white} !important;
      }
    }
  }

  &.violet {
    background: radial-gradient(circle at top, ${violet} 0%, ${darkenViolet} 100%);

    .main-text {
      color: ${yellow};
    }

    .desc {
      color: ${white};
      opacity: 1;
    }

    &:hover {
      background: ${yellow};
    }
  }

  &.grey {
    background: radial-gradient(circle at top, #9f9fb3 0%, #80809d 100%);

    .main-text {
      color: ${white};
    }

    .desc {
      color: ${white};
      opacity: 1;
    }

    &:hover {
      background: ${yellow};
    }
  }

  &.transparent {
    background: transparent;

    &:hover {
      background: transparent !important;

      .main-text {
        color: ${white} !important;
      }

      .desc {
        color: ${white} !important;
        opacity: 1;
      }
    }

    .ico-wrap {
      background-color: rgba(${darkBaseRGB}, 0.2);
    }

    .main-text {
      color: ${white};
    }

    .desc {
      color: ${white};
      opacity: 1;
    }

    &:hover {
      background: ${white};
    }
  }

  ${props =>
    props.done === 'done' &&
    css`
      background: rgba(${darkBaseRGB}, 0.2) !important;
      cursor: not-allowed;

      &:hover {
        background: rgba(${darkBaseRGB}, 0.2) !important;

        .text-wrap {
          .main-text {
            color: #fff !important;
          }

          .desc {
            color: #fff;
          }
        }
      }

      .ico-wrap {
        background: ${primaryGreen};
      }

      .text-wrap {
        .main-text {
          color: #fff;
        }

        .desc {
          color: #fff;
        }
      }
    `}
  
  ${props =>
    props.done === 'disabled' &&
    css`
      cursor: default;
    `}

  ${props =>
    props.icoBackground === 'grey' &&
    css`
      .ico-wrap {
        background: radial-gradient(circle at top, ${GrayTheme.primary} 0%, ${GrayTheme.darken} 100%);
      }
    `};

  ${props =>
    props.icoBackground === 'violet' &&
    css`
      .ico-wrap {
        background: radial-gradient(circle at top, ${violet} 0%, ${darkenViolet} 100%);
      }
    `};
  
  ${props =>
    props.icoBackground === 'green' &&
    css`
      .ico-wrap {
        background: radial-gradient(circle at top, ${primaryGreen} 0%, ${darkenGreen} 100%);
      }
    `};
  
  ${props =>
    props.icoBackground === 'blue' &&
    css`
      .ico-wrap {
        background: radial-gradient(circle at top, ${blue} 0%, ${darkenBlue} 100%);
      }
    `};
`;
