import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { StyledContacts, StyledContactsProps } from './style';

const Contacts: FC<Partial<StyledContactsProps>> = ({ theme = 'light' }) => {
  return ( 
    <StyledContacts theme={theme}>
      <span>
          &copy; 2020 <strong>Eticia 21</strong>
      </span>
      <a href='mailto:info@eticia21.com'>info@eticia21.cz</a>
      <a href='/Prohlaseni.pdf'>
        <FormattedMessage id='eticiaCompany.gdpr' />
      </a>
      <a href='/V%C5%A1eobecn%C3%A9%20obchodn%C3%AD%20podm%C3%ADnky%20spole%C4%8Dnosti%20Eticia%2021.pdf'>
        <FormattedMessage id='eticiaCompany.vop' />
      </a>
      <span>tel.: <a href='tel:+420608672113'>+420 608 672 113</a></span>
    </StyledContacts>
  );
};
 
export default Contacts;