import { mediaSmDefault } from 'components/theme/grid';
import styled from 'styled-components';

export const PaymentInfoPrice = styled.div`
  color: #fff;
  font-size: 1.8rem;
  line-height: 2.5rem;

  ${mediaSmDefault} {
    font-size: 2rem;
  }
`;
