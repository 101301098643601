import * as React from 'react';
import StyledHeader, { Flex } from './styles';
import { Flex as CoreFlex } from '@xcorejs/ui';
import { Theme } from '../../../templates/General/ThemeContext';
import Nav from '../../../atoms/Nav';
import MenuUser from '../MenuUser';
import { createUrl } from '../../../pages/routes';
import NavItem  from '../../../atoms/Nav/NavItem';
import { VALUE_COMPARISON_ACTIVE } from 'config/config';
import SVGarrow from '../../../atoms/SVGIcons/SVGarrow';
import { FormattedMessage } from 'react-intl';
import { useContext, useCallback, useState } from 'react';
import { GameContext } from 'components/App';
import { Link, useModal } from '@xcorejs/ui';
import { Link as RouterLink } from 'react-router-dom';
import FeedbackModal from 'components/components/modals/FeedbackModal';
import PresentingPageModal from 'components/components/modals/PresentingPageModal';
import FullScreenAd from 'components/components/Advertisement/FullScreenAd';

interface IMenu {
  theme: Theme;
  location: string;
}

const isInTest = (path: string) =>
  path.includes(createUrl('app.game', { id: '' })) ||
  path.includes(createUrl('app.partnerTest', { id: '' })) ||
  path.includes(createUrl('app.survey', { id: '' })) ||
  path.includes(createUrl('app.gameIntro', { id: '' })) ||
  path.includes(createUrl('app') + '/hra');

const Menu: React.FC<IMenu> = ({ theme, location }) => {
  const container = React.useRef<HTMLDivElement>(null);
  const [onPhone, setOnPhone] = React.useState<boolean>(false);
  const [inTest, setInTest] = React.useState<boolean>(false);
  const [wasChanged, setChanged] = React.useState<boolean>(false);
  const { eticiaPlus, setEticiaPlus, meeticia } = useContext(GameContext);
  const [openBetaModal] = useModal(FeedbackModal);
  const [openPresentingPageModal] = useModal(PresentingPageModal);
  const [showAd, setShowAd] = useState<boolean>(false);

  React.useEffect(() => {
    const handleTest = () => {
      if (isInTest(location)) {
        if (!inTest) {
          setInTest(true);
          setChanged(true);
        }
      } else {
        if (inTest) {
          setInTest(false);
          setChanged(false);
        }
      }
    };
    const handleResize = () => {
      if (window.innerHeight > window.innerWidth && 768 > window.innerWidth) {
        if (!onPhone) {
          setOnPhone(true);
        }
      } else {
        if (onPhone) {
          setOnPhone(false);
        }
      }
    };

    handleTest();
    handleResize();
    window.addEventListener('redirect', handleTest);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('redirect', handleTest);
      window.removeEventListener('resize', handleResize);
    };
  });
  {
    /*
  React.useEffect(() => {
    const handler = (e: Event) =>
      theme.data.enableMenu &&
      !container.current.contains(e.target as Node) &&
      theme.toggleMenu(false);
    document.addEventListener("click", handler, false);
    return () => {
      document.removeEventListener("click", handler, false);
    };
  });*/
  }

  const hide = () => {
    if (inTest && wasChanged) {
      theme.toggleMenu(false);
      setChanged(false);
    }
    return true;
  };

  const showAdvert = useCallback (() => {
    if (showAd) return;
    setShowAd(true);
  }, [showAd]);

  const hideAdvert = useCallback (() => {
    setShowAd(false);
  }, []);

  return (
    meeticia ? <div /> : (
      <StyledHeader opened={hide() && !!theme.data.enableMenu} ref={container} inTest={theme.data.layout === 'test'}>
        <Flex>
          {showAd ? <FullScreenAd onClick={hideAdvert} /> : null}
          <div>
            <span onClick={onPhone ? () => theme.toggleMenu() : undefined}>
              <Nav>
                <NavItem
                  to={createUrl('app')}
                  label='sidebar.menu.game'
                  exact={true}
                  icon='home'
                  active={[createUrl('app.game', { id: '' })]}
                  onClick={() => showAdvert()}
                />

                <NavItem to={createUrl('app.journal')} label='sidebar.menu.diary' icon='journal' onClick={() => showAdvert()} />

                <NavItem to={createUrl('app.valueWord')} label='sidebar.menu.valueWord' icon='values' onClick={() => showAdvert()} />

                <NavItem to={createUrl('app.gameGame')} label='sidebar.menu.game2' icon='game' />

                <NavItem to={createUrl('app.stats')} label='sidebar.menu.statistics' icon='stats' onClick={() => showAdvert()} />

                {/*
              <NavItem
                to={createUrl("app.about.join")}
                label="sidebar.menu.join"
                icon="join"
                exact={true}

              />

              <NavItem to={createUrl("app.profile")} label="sidebar.menu.profile" icon="profile" />
              */}

                {/*
              <NavItem to={createUrl('app.presenting2') + '?id=12'} label='sidebar.menu.plus' icon='shop' />
              */}


                {/* <li> */}
                {/*wtf bug, nejde zapsat jako NavItem, ale musí se to takhle obejít*/}
                {/* eslint-disable-next-line no-extra-parens */}
                {/* <NavLink className='nav-link' to={`/${(global as any).requestContext.locale}/app/presenting?id=12`}>
                    <Icon svg={<PlusIcon />} />

                    <FormattedMessage id={'sidebar.menu.plus'} />
                  </NavLink> */}
                {/* </li> */}

                <NavItem to={createUrl('app.eticiaPlus')} label='sidebar.menu.plus' icon='plus' />

                <NavItem to={createUrl('app.shop')} label='sidebar.menu.shop' icon='shop' />

                {VALUE_COMPARISON_ACTIVE && (
                  <NavItem to={createUrl('app.comparison')} label='sidebar.menu.comparison' icon='comparison' />
                )}
              </Nav>
            </span>
          </div>

          <Nav className='bottom'>
            <li className='bottom-item--white'>
              <RouterLink to={createUrl('app.valuesForTwo')} className='light-link'>
                <FormattedMessage id='sidebar.menu.valuesForTwo' />
              </RouterLink>
            </li>
            
            <li className='bottom-item--blue' onClick={() => openPresentingPageModal({ pageId: 30, loggedUser: true })}>
              <FormattedMessage id='sidebar.menu.children' />
            </li>
            
            <li className='write-us' onClick={() => openBetaModal({})}>
              <FormattedMessage id='sidebar.menu.writeUs' />
            </li>

            <CoreFlex
              flexDirection='row'
              alignItems='flex-end'
              justifyContent='space-between'
            >
              <span onClick={onPhone ? () => theme.toggleMenu() : undefined}>
                <MenuUser />
              </span>
            
              <Link variant='underline' onClick={() => setEticiaPlus(!eticiaPlus)}>
              change version
              </Link>
            </CoreFlex>
          </Nav>
          
          <span className='deco' onClick={() => theme.toggleMenu()}>
            <SVGarrow />
          </span>
        </Flex>
      </StyledHeader>
    )
  );
};

export default Menu;
