import * as React from 'react';
import { DragLayer as DragLayerHoc, DragLayerCollector, XYCoord } from 'react-dnd';
import ValueCard, { ValueCardState } from '../RoundStep/Cards/ValueCard';
import { ICardValue } from '../RoundStep/data';

interface IProps {
  wide: boolean;
}

interface IInnerProps {
  item: { value: ICardValue };
  currentOffset: any;
  isDragging: boolean;
}

const layerStyles = (isDragging: boolean): Partial<React.CSSProperties> => ({
  display: isDragging ? 'block' : 'none',
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
});

const getItemStyles = (offset: XYCoord, wide: boolean, isDragging: boolean) =>
  !!offset && isDragging
    ? {
      width: wide ? '18%' : '13%',
      height: '75%',
      transform: `translate(${offset.x}px, ${offset.y /* - 100*/}px)`,
      WebkitTransform: `translate(${offset.x}px, ${offset.y /* - 100*/}px)`,
    }
    : {
      display: 'none',
    };

class DragLayer extends React.Component<IProps & IInnerProps> {
  public render() {
    const { currentOffset, item, isDragging } = this.props;

    return (
      <div style={layerStyles(isDragging)}>
        <div style={getItemStyles(currentOffset, this.props.wide, isDragging)}>
          {item !== null && item.value !== undefined && (
            <ValueCard state={ValueCardState.def} lifeValue={item.value.value} />
          )}
        </div>
      </div>
    );
  }
}

const collect: DragLayerCollector<any, any> = monitor => ({
  item: monitor.getItem(),
  currentOffset: monitor.getSourceClientOffset(),
  isDragging: monitor.isDragging(),
});

export default DragLayerHoc<IProps, IInnerProps>(collect)(DragLayer);
