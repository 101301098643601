import { Flex, Heading3, Img, Paragraph } from '@xcorejs/ui';
import { OliLight } from 'components/theme/fonts';
import React, { FC } from 'react';
import { IPerson } from 'store/Entities/LifeValues';
import CreateLink, { fontSize } from '../Link';

type SponsorProps = Pick<IPerson, 'name' | 'profession' | 'image' | 'content' | 'life'>;

const SingleSponsor: FC<{sponsor: SponsorProps}> = ({ sponsor }) => {
  return (
    <Flex
      mt='2rem'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'>
      <Heading3
        mt={{ _: '0 !important', md: '1.5rem' }}
        color='rgb(255,255,255)'
        fontSize={fontSize.heading}
        maxWidth='40rem'>
        {sponsor.name}
      </Heading3>
      <Paragraph
        mt='1rem'
        fontSize={fontSize.paragraph}
        fontFamily={OliLight}
        maxWidth='30rem'
      >
        {sponsor.profession}
      </Paragraph>

      <Img src={sponsor.image} display='block' mx='auto' mt='3rem' alt='' />

      <Paragraph
        mt='1rem'
        fontSize={fontSize.paragraph}
        fontFamily={OliLight}
        maxWidth='30rem'
      >
        {sponsor.content}
      </Paragraph>

      <Flex justifyContent='center'>
        <CreateLink string={sponsor.life} />
      </Flex>
    </Flex>
  );
};

export default SingleSponsor;
