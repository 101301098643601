import { ExtendedTypographyProps, Heading2, Icon, Img, Paragraph } from '@xcorejs/ui';
import Accordion from 'components/atoms/Accordion/Accordion';
import LoadingSpinner from 'components/atoms/Animations/LoadingSpinner';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { CompareIcon } from '../../../icons/16/compare.svg';
import { helperFilterFunction, helperFindFunction } from '../../LaddersCompareMatrixModal/helpers';
import { useGetCompareMatrix } from '../../LaddersCompareMatrixModal/hooks';
import { SingleComparisonDataProps } from './data';

interface FetchComparisonTextProps {
  data: SingleComparisonDataProps;
}

const FetchedComparisonText: FC<FetchComparisonTextProps> = ({ data }) => {
  type CompareText = {
    short: string; 
    detailed: string;
  }

  const compareMatrix = useGetCompareMatrix();
  const [compareText, setCompareText] = useState<CompareText>({ short: 'Načítání textu', detailed: '' });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  useEffect(() => {
    const defaultText = 'Toto porovnání nemá text';

    setIsLoading(true);
  
    if (
      compareMatrix && 
      data.comparison.left && 
      data.comparison.right && 
      data.comparison.left.id && 
      data.comparison.right.id
    ) {
      const filteredMatrix = helperFilterFunction(compareMatrix, data.comparison.left.id);
      const comparison = filteredMatrix && helperFindFunction(filteredMatrix, data.comparison.right.id);

      if (comparison) {
        console.log('comparison:', comparison);

        setCompareText({
          short:comparison.mainDescription,
          detailed: comparison.summaryDescription
        });
      } else {
        setCompareText(prev => ({ ...prev, short: defaultText }));
      }
    } else {
      setCompareText(prev => ({ ...prev, short: defaultText }));
    }

    setIsLoading(false);
  }, [compareMatrix, data.comparison.left, data.comparison.right]);

  useEffect(() => {
    if (compareMatrix !== undefined && isLoading === false) {
      const timeout = setTimeout(() => {
        setIsExpanded(true);
      }, 100);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [compareMatrix, isLoading]);

  if (isLoading || !compareMatrix) {
    return (
      <LoadingSpinner />
    );
  }

  const ParagraphStyle: ExtendedTypographyProps = {
    fontSize: { _:'1.3rem', sm: '1.6rem' },
    lineHeight: '1.2',
    color: 'rgba(255, 255, 255, 0.7)',
  };

  return ( 
    <Accordion expanded={isExpanded} flexDirection='column' alignItems='center'>
      <Paragraph
        marginTop='2rem'
        {...ParagraphStyle}
      >{compareText.short}</Paragraph>

      <Heading2 as='h3' marginTop='2rem'>Co vše zde objevíš?</Heading2>

      <Paragraph
        marginTop='1rem'
        {...ParagraphStyle}
      >{compareText.detailed}</Paragraph>

      <Img
        src='/img/comparison/full_png.png' 
        alt=''
        maxWidth={{ _: '35rem', sm: '45rem' }} 
        marginTop='2rem'
      />

      <Paragraph
        marginTop='1rem'
        {...ParagraphStyle}
        color='rgba(0, 0, 0, 0.8)'
        
      >
        <FormattedMessage
          id='comparison.journal.start'
          values={{
            ikona: (...chunks: ReactNode[]) => (
              <Icon 
                svg={<CompareIcon />} 
                border='1px solid rgba(0, 0, 0, 0.8)'
                borderRadius='9999px'
                height='2rem'
                width='2rem'
                fill='rgba(0, 0, 0, 0.8)'
              />
            )
          }}
        />
      </Paragraph>
    </Accordion>
  );
};
 
export default FetchedComparisonText;
