import * as React from 'react';
import TextBox from '../../TextBox';
import Fade from 'react-reveal/Fade';
import { Heading2, Heading3 } from '../../../atoms/Headings';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import { BiggerText } from '../../../atoms/Text';

const PartnersWithInfo: React.FC = () => (
  <div className={'bg-white'}>
    <TextBox className='partners'>
      {/* <Fade ssrReveal bottom duration={1000} distance={'5rem'}>
        <Heading2>
          <FormattedMessage id={'homepage.partners.mainPartner'} />
        </Heading2>
      </Fade>
      <Fade ssrReveal bottom duration={1000} distance={'5rem'}>
        <div>
          <a href='https://www.instituteticia.org/' target='_blank' rel='noopener noreferrer'>
            <img
              className='institut'
              src='/img/partners/institut.png'
              srcSet='/img/partners/institut.png, /img/partners/institut@2x.png 2x, /img/partners/institut@3x.png 3x'
              alt='Institut Eticia'
            />
          </a>
        </div>
      </Fade> */}
      <Fade ssrReveal bottom duration={1000} distance={'5rem'}>
        <Heading3>
          {/* <FormattedMessage id={'homepage.partners.otherPartners'} /> */}
          <FormattedMessage id={'homepage.partners.partners'} />
        </Heading3>
      </Fade>

      <Fade ssrReveal bottom duration={1000} distance={'5rem'}>
        <div className='partners-others'>
          <Grid>
            <Row>
              <Col xs={6} sm={4} md={3}>
                <a href='https://appio.cz/' target='_blank' rel='noopener noreferrer'>
                  <img src='/img/partners/appio.svg' alt='Appio' />
                </a>
              </Col>
              <Col xs={6} sm={4} md={3}>
                <a href='https://www.instituteticia.org/' target='_blank' rel='noopener noreferrer'>
                  <img
                    src='/img/partners/hodnoty.png'
                    srcSet='/img/partners/hodnoty.png, /img/partners/hodnoty@2x.png 2x, /img/partners/hodnoty@3x.png 3x'
                    alt='Institut Eticia'
                  />
                </a>
              </Col>
              <Col xs={6} sm={4} md={3}>
                <a href='http://maxmediapr.cz/' target='_blank' rel='noopener noreferrer'>
                  <img src='/img/partners/maxmedia.svg' alt='maXmedia - PR agentura, Public Relations' />
                </a>
              </Col>
              <Col xs={6} sm={4} md={3}>
                <a href='https://www.nadacekj.cz/' target='_blank' rel='noopener noreferrer'>
                  <img
                    src='/img/partners/nadace-kj.png'
                    srcSet='/img/partners/nadace-kj.png, /img/partners/nadace-kj@2x.png 2x, /img/partners/nadace-kj@3x.png 3x'
                    alt='Nadace Karla Janečka'
                  />
                </a>
              </Col>
              <Col xs={6} sm={4} md={3}>
                <a href='http://www.festivalevolution.cz/' target='_blank' rel='noopener noreferrer'>
                  <img
                    src='/img/partners/evolution.png'
                    srcSet='/img/partners/evolution.png, ../img/partners/evolution@2x.png 2x, ../img/partners/evolution@3x.png 3x'
                    alt='Festival Evolution'
                  />
                </a>
              </Col>

              {/* <Col xs={6} sm={4} md={3}>
                <a href='http://www.proregiony.cz/' target='_blank' rel='noopener noreferrer'>
                  <img src='/img/partners/proregiony.svg' alt='ProRegiony.cz' />
                </a>
              </Col> */}
            </Row>
          </Grid>
        </div>
      </Fade>

      <Fade ssrReveal bottom duration={1000} distance={'5rem'}>
        <Heading3>
          <FormattedMessage id={'homepage.footer.supports'} />
        </Heading3>
      </Fade>

      <Fade ssrReveal bottom duration={1000} distance={'5rem'}>
        <BiggerText className={'supports'}>
          <FormattedMessage id={'supports'} />
          <br />
          <br />
          <FormattedHTMLMessage id={'eticiaCompany'} />
          <br />
          <br />
          <FormattedHTMLMessage id={'eticiaCompany.ico'} /> <FormattedHTMLMessage id={'eticiaCompant.noDPH'} />
          <br />
          <br />
          <FormattedMessage id={'eticiaCompany.legal'} />
        </BiggerText>
      </Fade>
    </TextBox>
  </div>
);

export default PartnersWithInfo;
