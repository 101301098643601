import * as React from 'react';
import { ThemeProvider } from 'styled-components';
import { ColoredThemes } from '../../theme/colors';
import { DefaultGridTheme } from '../../theme/grid';
import { ThemeContext, getTheme } from './ThemeContext';
import { IThemeData } from '../../pages/routes';
import { withRouter, RouteComponentProps } from 'react-router';
import { IFlatRoute, matchRoute } from '../../../utils/router';
import Layout from '../../organisms/Layout';

interface IGeneralProps {
  routes: Array<IFlatRoute<IThemeData>>;
}

interface IGeneralState {
  theme: IThemeData;
  error: string | null;
}

class General extends React.Component<IGeneralProps & RouteComponentProps<any>, IGeneralState> {
  public state: IGeneralState = {
    error: null,
    theme: {},
  };

  public componentDidUpdate(prevProps: IGeneralProps & RouteComponentProps<any>) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
      this.setState({
        theme: {
          enableMenu: this.state.theme.enableMenu,
        },
      });
    }
  }

  public render() {
    const { background, layout, enableScroll } = this.getTheme().data;
    const theme = {
      ...(background ? ColoredThemes[background] : {}),
      ...DefaultGridTheme,
    };

    return (
      <ThemeProvider theme={theme}>
        <ThemeContext value={this.getTheme()}>
          <Layout layout={layout} background={background} scroll={enableScroll}>
            {this.props.children}
          </Layout>
        </ThemeContext>
      </ThemeProvider>
    );
  }

  private getTheme = () =>
    getTheme(
      matchRoute(this.props.location.pathname, this.props.routes),
      {
        setBackground: (background: string) => this.setState({ theme: { ...this.state.theme, background } }),
        setLayout: (layout: string) => this.setState({ theme: { ...this.state.theme, layout } }),
        toggleMenu: (state?: boolean) =>
          this.setState({
            theme: {
              ...this.state.theme,
              enableMenu: state === undefined ? !this.state.theme.enableMenu : state,
            },
          }),
        toggleScroll: (state?: boolean) =>
          this.setState({
            theme: {
              ...this.state.theme,
              enableScroll: state === undefined ? !this.state.theme.enableScroll : state,
            },
          }),
        setHint: (hint: string) => this.setState({ theme: { ...this.state.theme, hint } }),
        setTheme: (theme: Partial<IThemeData>) => this.setState({ theme: { ...this.state.theme, ...theme } }),
      },
      this.state.theme,
    );
}

export default withRouter(General);
