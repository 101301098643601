import * as React from 'react';
import { useDebouncedCallback } from 'use-debounce';

import {
  StyledPrimaryButton,
  StyledPrimaryButtonButton,
  StyledPrimaryButtonLink,
  StyledSecondaryButton,
  StyledSecondaryButtonLink,
  StyledSecondaryButtonButton,
  StyledPlayButton,
  StyledSecondaryButtonLinkBigger,
  StyledGiftButton,
} from './styles';
import { LinkProps } from 'react-router-dom';

export { StyledPrimaryButtonLink };

export const PrimaryButton: React.FC<{
  as?: keyof JSX.IntrinsicElements;
  isUnvisible?: boolean;
} & React.AnchorHTMLAttributes<HTMLAnchorElement>> = props => {
  const [debounced] = useDebouncedCallback(props.onClick || (() => undefined), 100);
  return <StyledPrimaryButton {...(props as any)} onClick={e => debounced(e)} />;
};

export const PrimaryButtonButton = StyledPrimaryButtonButton;

export const SecondaryButton = StyledSecondaryButton;
export const SecondaryButtonLink = StyledSecondaryButtonLink;
export const SecondaryButtonLinkBigger = StyledSecondaryButtonLinkBigger;
export const SecondaryButtonButton = StyledSecondaryButtonButton;

export const PlayButton = StyledPlayButton;
export const GiftButton = StyledGiftButton;

export const PrimaryButtonLink: React.FC<LinkProps> = props => <StyledPrimaryButtonLink {...props} />;