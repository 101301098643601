import { jsonFetch } from '../../../utils/fetch';
import { PASSWORD_FORGOTTEN } from 'config/apiRoutes';

export const sendEmail = (email: string, onSuccess: () => any, onError: (e: string) => any) => {
  jsonFetch(PASSWORD_FORGOTTEN, {
    method: 'POST',
    body: JSON.stringify({ email }),
  })
    .then(() => onSuccess())
    .catch(e =>
      e.data.json().then((json: any) => {
        if (json.Email) {
          onError(json.Email);
        } else if (json.error) {
          onError(json.error);
        }
      }),
    );
};
