import styled from 'styled-components';

export const LoadingDot = styled.div`
  display:inline-block;
  width:25px;
  height:4px;
  background: radial-gradient(circle closest-side,#FFFFFFB3 90%,#0000) 0 0/33% 100% space;
  clip-path: inset(0 100% 0 0);
  animation:d1 1s steps(4) infinite;

  @keyframes d1 {
    to{
      clip-path: inset(0 -34% 0 0)
    }
  }
`;
