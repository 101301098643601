import * as React from 'react';
import { StyledBackground, StyledModal } from './styles';
import { ILifeValueDetail, IQuote, IPerson, IInterest, IDefinition } from '../../../store/Entities/LifeValues';
import SVGclosegray from '../../atoms/SVGIcons/SVGclosegray';
import wrapIntl, { IWrapIntl } from '../../atoms/wrapIntl';
import LoadingSpinnerLocal from '../../atoms/Animations/LoadingSpinnerLocal';
import { useTheme, IThemeProps } from '../../templates/General/ThemeContext';
import Fetch from '../../atoms/Fetch';
import { LIFE_VALUES_DETAIL } from 'config/apiRoutes';
import { _switch } from '../../../utils/option';
import DataCollector from './DataCollector';

export const filter = <T extends IQuote | IPerson | IInterest>(c: T[]) =>
  c.sort((a: T, b: T) => b.priority - a.priority).sort((a: T, b: T) => a.voteNumber - b.voteNumber);

export const getQuotes = (detail: ILifeValueDetail) => filter(detail.quotes);
export const getAuthors = (detail: ILifeValueDetail) => filter(detail.persons);
export const getTrivia = (detail: ILifeValueDetail) => filter(detail.interests);
export const getDefinitions = (detail: ILifeValueDetail) =>
  detail.definitions.sort((a: IDefinition, b: IDefinition) => b.createdAt - a.createdAt);

interface IValueDefinition {
  value: string;
  inTest: boolean;
  onClose: () => any;
}

class ValueDefinition extends React.Component<IValueDefinition & IWrapIntl & IThemeProps> {
  public componentDidMount() {
    this.props.theme.toggleScroll(false);
  }

  public componentWillUnmount() {
    this.props.theme.toggleScroll(true);
  }

  public render() {
    const { onClose, value, inTest } = this.props;

    return (
      <StyledBackground>
        <StyledModal>
          <Fetch url={LIFE_VALUES_DETAIL(value)}>
            {_switch({
              success: (detail: ILifeValueDetail) => (
                <>
                  <a className='close' onClick={() => onClose()}>
                    <SVGclosegray />
                  </a>
                  <div className={'flex'}>
                    <DataCollector inTest={inTest} detail={detail} />
                    {/*this.props.cookies.values.hideValueDefinitionPopup !==
                      "1" && (
                      <GreatAdvice
                        inTest={inTest}
                        close={() =>
                          this.props.cookies.set(
                            "hideValueDefinitionPopup",
                            "1"
                          )
                        }
                      />
                    )*/}
                  </div>
                </>
              ),
              fetching: () => <LoadingSpinnerLocal />,
            })()}
          </Fetch>
        </StyledModal>
      </StyledBackground>
    );
  }
}

export default useTheme<IValueDefinition>(wrapIntl(ValueDefinition));
