import { Flex, Heading1, Img } from '@xcorejs/ui';
import Contacts from 'components/molecules/Contacts';
import React, { FC } from 'react';
import { SectionHomepage } from '../styles';

const Supporters: FC = () => {
  return ( 
    <SectionHomepage backgroundColor='#f8f8f8' display='flex'>
      <Heading1 color='#6f6697' maxWidth='50rem'>
        Kdo se na projektu podílí
      </Heading1>

      <Flex mt={{ _:'6rem', md: '10rem' }} flexDirection={{ _: 'column', md: 'row' }} style={{ gap: '4rem' }}>
        <Img src='/img/logo/logo@2x.png' alt='Eticia 21' filter='invert(1)' width='160px' height='40px' m='auto' />

        <Img src='/img/partners/appio.svg' alt='Appio' width='160px' filter='hue-rotate(62deg) saturate(200%)' />

        <Img src='/img/logo/institut-white.png' alt='Institut' width='160px' filter='invert(1)' />
      </Flex>

      <Contacts />
    </SectionHomepage>
  );
};
 
export default Supporters;
