import styled from 'styled-components';
import { darkBaseHEX, grayFooter, primaryGreen } from '../../../theme/colors';
import { mediaSmDefault, mediaLgDefault } from '../../../theme/grid';

const StyledFooter = styled.section`
  footer {
    padding-top: 4.3rem;
    padding-bottom: 3.7rem;
    display: block;
    text-align: center;
    background: #fff;

    p {
      color: ${darkBaseHEX};
      font-size: 1.6rem;
      line-height: 2rem;

      ${mediaSmDefault} {
        font-size: 1.8rem;
      }
    }

    .copy {
      margin-bottom: 1rem;
      display: block;
      color: ${darkBaseHEX};

      ${mediaLgDefault} {
        margin-bottom: 0;
        display: inline-block;
      }
    }

    a {
      margin-top: 1rem;
      display: block;
      color: ${primaryGreen};

      ${mediaSmDefault} {
        display: inline-block;
        margin-top: 0;
      }

      ${mediaSmDefault} {
        &:before {
          content: " ";
          display: inline-block;
          vertical-align: middle;
          margin-left: 2rem;
          margin-right: 2rem;
          border-radius: 50%;
          width: 0.4rem;
          height: 0.4rem;
          background: ${grayFooter};
        }
      }

      &:first-of-type {
        &:before {
          display: none;
        }

        &:before {
          margin-left: 0;
          margin-right: 0;
        }

        ${mediaLgDefault} {
          &:before {
            content: " ";
            display: inline-block;
            vertical-align: middle;
            margin-left: 2rem;
            margin-right: 2rem;
            border-radius: 50%;
            width: 0.4rem;
            height: 0.4rem;
            background: ${grayFooter};
          }
        }
      }

      span {
        transition: border 0.3s;
        border-bottom: 1px solid ${primaryGreen};
      }

      &:hover {
        span {
          border-bottom: 1px solid transparent;
        }
      }
    }
  }
`;

export default StyledFooter;
