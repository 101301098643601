import * as React from 'react';
import { IPerson } from '../../../../store/Entities/LifeValues';
import ValueDefinitionCard from './ValueDefinitionCard';
import Vote from '../Vote';
import wrapIntl, { IWrapIntl } from '../../../atoms/wrapIntl';

interface IAuthorCard {
  author: IPerson;
  valueId: string;
  onVote: (id: string, type: string, text?: string) => any;
}

const AuthorCard: React.FC<IWrapIntl & IAuthorCard> = ({ author, formatMsg, valueId, onVote }) => (
  <ValueDefinitionCard
    color={'purple'}
    label={formatMsg('valueDefinition.person')}
    img={author.image}
    name={author.name}
    job={author.profession}
    year={author.life}
    text={author.content}
  >
    <Vote
      id={valueId}
      defId={author.id}
      type='author'
      onVote={(id: string, type: string, text?: string) => onVote(id, type, text)}
    />
  </ValueDefinitionCard>
);

export default wrapIntl(AuthorCard);
