import { createScales, createTheme, fonts } from '@xcorejs/ui';
import React from 'react';
import { breakpointsTheme } from './theme/breakpoints';
import { containerTheme } from './theme/container';
import { typographyTheme } from './theme/typography';
import { textTheme } from './theme/text';
import { colorsTheme } from './theme/colors';
import { buttonTheme } from './theme/button';
import { modalTheme } from './theme/modal';
import { tagTheme } from './theme/tag';
import { linkTheme } from './theme/link';

const baseTheme = createTheme({
  name: 'Eticia 21',
  ...containerTheme,
  ...typographyTheme,
  ...textTheme,
  ...buttonTheme,
  ...modalTheme,
  ...tagTheme,
  ...linkTheme,
  ...createScales({
    ...breakpointsTheme,
    ...colorsTheme,
    ...fonts({
      OpenSans: '\'Open Sans\', sans-serif',
      Fox: '\'Fox Grotesque\', sans-serif',
      heading: '"Oli Grotesk Medium", sans-serif',
      text: '"Oli Grotesk Light", sans-serif',
      OliMedium: '"Oli Grotesk Medium", sans-serif',
      OliLight: '"Oli Grotesk Light", sans-serif',
      OliRegular: '"Oli Grotesk Regular", sans-serif'
    })
  })
});

export const theme = {
  ...baseTheme
};
