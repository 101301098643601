import * as React from 'react';
import CountUp from 'react-countup';
import { StyledCounterUp } from './styles';
import { FormattedMessage } from 'react-intl';
import useFetch from '../Fetch/useFetch';
import { STATISTICS_GLOBAL_INFO } from 'config/apiRoutes';
import LoadingSpinnerLocal from '../Animations/LoadingSpinnerLocal';
import { LoadingDot } from '../Animations/LoadingDots';

interface CounterUpProps {
  numberOnly?: boolean;
  end?: React.Dispatch<React.SetStateAction<boolean>>;
}

const CounterUp: React.FC<CounterUpProps> = ({ numberOnly, end }) => {
  const result = useFetch<{ totalProfileCount: number }>(STATISTICS_GLOBAL_INFO);
  const [num, setNum] = React.useState<number>(0);

  React.useEffect(() => {
    result.result && setNum(Math.ceil(result.result.totalProfileCount * 1.15));
  }, [result]);
    
  if (result.state !== 'success') {
    return numberOnly ? <LoadingDot /> : <LoadingSpinnerLocal />;
  }

  return numberOnly ? 
    end ? (
      <CountUp 
        start={num / 3} 
        end={num} 
        delay={1}
        duration={3}
        onEnd={() => {
          end(true);
          // setTimeout(() => end(false), 500);
        }}
      />
    ) :
      <CountUp start={num / 3} end={num} delay={1} />
    : (
      <StyledCounterUp>
        <FormattedMessage id={'homepage.visual.counterUp'} />{' '}
        <strong>
          <CountUp start={num / 3} end={num} delay={1} />
        </strong>{' '}
        <FormattedMessage id={'homepage.visual.counterUp2'} />
      </StyledCounterUp>
    );
};

export default CounterUp;
