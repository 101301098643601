import * as React from 'react';
import { IValueTestCompare } from '../data';
import { Col, Row } from 'react-styled-flexboxgrid';
import { CardList } from '../../../../../organisms/PartnerTest/Result/styles';
import Card from '../../../../../atoms/Cards';
import { CustomText } from '../../../../../atoms/Text';
import { IStringMap } from '../../../../../../utils';
import { ILifeValue } from '../../../../../../store/Entities/LifeValues';
import { FormattedMessage } from 'react-intl';
import { Heading2 } from '../../../../../atoms/Headings';
import CardBody from '../../../../../atoms/Cards/CardBody';
import { StyledPositions, StyledResultBoxes } from '../../../../../molecules/TestResult/AnswerBox/styles';
import { IValueTestResultDetail, IValueResult } from 'config/api/valueTestResult';
import PopupIcon from '../../../../../organisms/MainPopup/PopupIcon';
import { FC, Fragment } from 'react';
import { Flex } from '@xcorejs/ui';
import SimpleCard from '../../../../../atoms/Cards/SimpleCard';
import Fade from 'react-reveal/Fade';

interface IData {
  valueTestCompare: IValueTestCompare;
  values: IValueResult[];
  mapValues: IStringMap<ILifeValue>;
}

export const FindPositions:FC<{ values: IStringMap<IValueTestResultDetail>; value: string }> = ({ values, value }) => (
  <StyledPositions>
    <Flex
      width='2.4rem'
      height='2.4rem'
      alignItems='center'
      justifyContent='center'
      className='green'
      paddingTop='0.2rem'
    >
      {(values[Object.keys(values)[0]].lifeValues.find(lv => lv.valueId === value) || { rank: 0 }).rank + '. '}
    </Flex>
    <Flex
      width='2.4rem'
      height='2.4rem'
      alignItems='center'
      justifyContent='center'
      className='blue'
      paddingTop='0.2rem'
    >
      {(values[Object.keys(values)[1]].lifeValues.find(lv => lv.valueId === value) || { rank: 0 }).rank + '.'}
    </Flex>
  </StyledPositions>
);

const TogetherPrimalAgreement: React.FC<IData> = ({ mapValues, valueTestCompare, values }) => {
  const { valueMatchAList } = valueTestCompare;
  return (
    <>
      <StyledResultBoxes className='together'>
        <Row>
          <Col xs={12} md={5} mdOffset={1} lg={4} lgOffset={2}>
            <Fade bottom ssrReveal duration={1000} distance={'5rem'}>
              <div>
                <Heading2 color='yellow'>
                  <FormattedMessage id={'results.partners.final.subheading.mainValues'} />
                </Heading2>

                <CustomText>
                  {valueMatchAList.length > 0 ? (
                    <Fragment>
                      <FormattedMessage
                        id={
                          (valueMatchAList.length <= 10
                            ? 'results.partners.together.togetherPrimalValues' + valueMatchAList.length
                            : 'results.partners.together.togetherPrimalValues10') + '.part1'
                        }
                      />{' '}
                      {valueMatchAList.length}{' '}
                      <FormattedMessage
                        id={
                          (valueMatchAList.length <= 10
                            ? 'results.partners.together.togetherPrimalValues' + valueMatchAList.length
                            : 'results.partners.together.togetherPrimalValues10') + '.part2'
                        }
                      />
                    </Fragment>
                  ) : (
                    <FormattedMessage id={'results.partners.together.none'} />
                  )}
                </CustomText>
              </div>
              <CardList>
                {valueMatchAList.map((value, index) =>
                  mapValues[value] !== undefined ? (
                    <Fade bottom ssrReveal duration={1000} distance={'5rem'} delay={200 * index} key={index}>
                      <SimpleCard
                        key={value}
                        color='yellow'
                        index={index + 1}
                        name={mapValues[value].name}
                        svg={mapValues[value].iconSvg}
                      />
                    </Fade>
                  ) : (
                    <Card color={'yellow'}>
                      <CardBody name={'ERROR: ValueID not found'} />
                    </Card>
                  ),
                )}
              </CardList>
            </Fade>
          </Col>
          <Col xs={12} md={5} lg={4}>
            <Fade bottom ssrReveal duration={1000} distance={'5rem'}>
              <div>
                <Heading2 color='blue'>
                  <FormattedMessage id={'results.partners.final.subheading.importantValues'} />
                </Heading2>
                <CustomText>
                  <FormattedMessage
                    id={
                      'results.partners.together.yourMainValues' +
                      (10 - valueMatchAList.length + 1 - (10 - valueMatchAList.length) % 3)
                    }
                  />
                </CustomText>
              </div>
              <CardList>
                <Fade bottom ssrReveal duration={1000} distance={'5rem'}>
                  {values.slice(valueMatchAList.length, 10).map((value, i) => (
                    <React.Fragment key={i}>
                      {mapValues[value.valueId] !== undefined ? (
                        <Fade bottom ssrReveal duration={1000} distance={'5rem'} delay={200 * i}>
                          <SimpleCard
                            key={value.valueId}
                            color='bordered'
                            index={valueMatchAList.length + i + 1}
                            name={mapValues[value.valueId].name}
                            size='small'
                          />
                        </Fade>
                      ) : (
                        <Card color='yellow' size='small'>
                          <CardBody name={'ERROR: ValueID not found'} />
                        </Card>
                      )}
                    </React.Fragment>
                  ))}
                </Fade>
              </CardList>
            </Fade>
          </Col>
        </Row>
      </StyledResultBoxes>

      <PopupIcon text={'partnerResult.helpPopup.agreement'} />
    </>
  );
};

export default TogetherPrimalAgreement;
