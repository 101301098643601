import { Box, Button, Flex, Modal, ModalProps, Tag, Typography } from '@xcorejs/ui';
import LoadingSpinnerLocal from 'components/atoms/Animations/LoadingSpinnerLocal';
import withLifeValues, { IWithLifeValues } from 'components/molecules/withRedux/withLifeValues';
import { BlueTheme } from 'components/theme/colors';
import { OliLight } from 'components/theme/fonts';
import { SEMAPHORE } from 'config/apiRoutes';
import * as React from 'react';
import { FC, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useFetchSemaphore, useFetchTestResultDetail } from 'utils/useQuery/queryFetchHelpers';
import { queryKeys } from 'utils/useQuery/queryKeys';
import { useMutationFetch } from 'utils/useQuery/useMutationFetch';
import { ChoiceStyle, CircleFlexStyle } from './styles';

type ValueRatingModalProps = {
  id: string;
  _modal?: ModalProps;
  onAction?: () => unknown;
  header?: string;
  insideGame?: boolean;
}

const ValueRatingModal: FC<ValueRatingModalProps & IWithLifeValues> = ({
  id,
  lifeValues,
  _modal,
  onAction,
  insideGame 
}) => {
  const [state, setState] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [saved, setSaved] = useState(false);
  const semaphoreFetch = useFetchSemaphore(id);
  useEffect(() => {
    if (semaphoreFetch.isSuccess) {
      setState(semaphoreFetch.data);
    }
  }, [semaphoreFetch.data, semaphoreFetch.isSuccess]);

  const testResultFetch = useFetchTestResultDetail(id);
  const result = useMemo(() => {
    return testResultFetch.isSuccess ? testResultFetch.data : null;
  }, [testResultFetch.data, testResultFetch.isSuccess]);

  const mutation = useMutationFetch({
    path: SEMAPHORE(id),
    body: JSON.stringify(state),
    onSuccess: () => {
      setSaved(true);
      onAction ? 
        onAction() :
        setTimeout(() => {
          setSaved(false);
        }, 5000);
    },
    queryKey: [queryKeys.SEMAPHORE, id],
  });

  const onSave = () => mutation.mutate();

  return (
    <Modal
      background={BlueTheme.primary}
      padding={{ _: '3rem', sm: '6rem 3rem 3rem' }}
      {..._modal}
    >
      {testResultFetch.isFetching && <LoadingSpinnerLocal />}
      {testResultFetch.isError && <></>}
      {testResultFetch.isSuccess && result && (
        <Box>
          {saved && (
            <Typography mb='2rem' color='#fff'>
              <FormattedMessage id='journal.valueRating.saved' />
            </Typography>
          )}

          <Flex flexDirection='column' alignItems='center'>
            <Tag
              bg='rgba(11, 12, 28, 0.1)'
              fontSize='2rem'
              lineHeight='2.6rem'
              letterSpacing='0.5px'
              px='5rem'
              py='1rem'
              borderRadius='23.5rem'
            >
              {result.testConfig.template.info.semaphoreHeader}
            </Tag>

            {result.testConfig.template.info.semaphoreDescription && (
              <Typography mt='1rem'>
                {result.testConfig.template.info.semaphoreDescription}
              </Typography>
            )}

            <Box mt='3rem' width='100%' maxWidth='48rem'>
              <Flex flexWrap='wrap' justifyContent='flex-end' mb='1rem'>
                <Typography {...ChoiceStyle(insideGame)}>
                  {result.testConfig.template.info.semaphoreChoiceA}
                </Typography>
                <Typography {...ChoiceStyle(insideGame)}>
                  {result.testConfig.template.info.semaphoreChoiceB}
                </Typography>
                <Typography {...ChoiceStyle(insideGame)}>
                  {result.testConfig.template.info.semaphoreChoiceC}
                </Typography>
              </Flex>


              {result.valueTestResult.allLifeValues
                .sort((a, b) => a.rank - b.rank)
                .slice(0, 10)
                .map((v, i) => (
                  <Flex key={i} borderBottom='2px solid rgba(216, 216, 216, 0.2)' minHeight='4rem' alignItems='center'>
                    <Typography
                      width='100%'
                      textAlign='left'
                      position='relative'
                      paddingLeft='2rem'
                      fontSize={insideGame ? '1.3rem' : { _: '1.3rem', sm: '1.5rem' }}
                      fontFamily={OliLight}
                      display='flex'
                      alignItems='center'
                    >
                      <Box as='span' position='absolute' left='0'>
                        {i + 1}.
                      </Box>

                      {lifeValues[v.valueId].name}
                    </Typography>

                    <Flex
                      {...CircleFlexStyle(insideGame)}
                      bg={state[i] === 1 ? 'rgba(11, 12, 28, 0.25)' : 'rgba(11, 12, 28, 0.1)'}
                      transition='background 300ms'
                      onClick={() => {
                        let newArray = [...state];
                        newArray[i] = 1;
                        setState(newArray);
                      }}
                    >
                      <Flex
                        alignItems='center'
                        justifyContent='center'
                        width='2.4rem'
                        height='2.4rem'
                        border='2px solid yellow'
                        borderRadius='50%'
                        opacity={state[i] === 1 ? 1 : 0.5}
                        transition='opacity 300ms'
                      >
                        <Box
                          width='1.6rem'
                          minWidth='1.6rem'
                          height='1.6rem'
                          bg='yellow'
                          borderRadius='50%'
                          opacity={state[i] === 1 ? 1 : 0}
                          transition='opacity 300ms'
                        />
                      </Flex>
                    </Flex>

                    <Flex
                      ml='0.2rem'
                      {...CircleFlexStyle(insideGame)}
                      bg={state[i] === 2 ? 'rgba(11, 12, 28, 0.25)' : 'rgba(11, 12, 28, 0.1)'}
                      transition='background 300ms'
                      onClick={() => {
                        let newArray = [...state];
                        newArray[i] = 2 as any;
                        setState(newArray);
                      }}
                    >
                      <Flex
                        alignItems='center'
                        justifyContent='center'
                        width='2.4rem'
                        height='2.4rem'
                        border='2px solid yellow'
                        borderRadius='50%'
                        opacity={state[i] === 2 ? 1 : 0.5}
                        transition='opacity 300ms'
                      >
                        <Box
                          width='1.6rem'
                          minWidth='1.6rem'
                          height='1.6rem'
                          bg='yellow'
                          borderRadius='50%'
                          opacity={state[i] === 2 ? 1 : 0}
                          transition='opacity 300ms'
                        />
                      </Flex>
                    </Flex>

                    <Flex
                      ml='0.2rem'
                      {...CircleFlexStyle(insideGame)}
                      bg={state[i] === 3 ? 'rgba(11, 12, 28, 0.25)' : 'rgba(11, 12, 28, 0.1)'}
                      transition='background 300ms'
                      onClick={() => {
                        let newArray = [...state];
                        newArray[i] = 3 as any;
                        setState(newArray);
                      }}
                    >
                      <Flex
                        alignItems='center'
                        justifyContent='center'
                        width='2.4rem'
                        height='2.4rem'
                        border='2px solid yellow'
                        borderRadius='50%'
                        opacity={state[i] === 3 ? 1 : 0.5}
                        transition='opacity 300ms'
                      >
                        <Box
                          width='1.6rem'
                          minWidth='1.6rem'
                          height='1.6rem'
                          bg='yellow'
                          borderRadius='50%'
                          opacity={state[i] === 3 ? 1 : 0}
                          transition='opacity 300ms'
                        />
                      </Flex>
                    </Flex>
                  </Flex>
                ))}
            </Box>

            {result.testConfig.template.info.semaphoreAdditionalNote && (
              <Typography mt='3rem' color='rgba(255, 255, 255, 0.7)'>
                {result.testConfig.template.info.semaphoreAdditionalNote}
              </Typography>
            )}

            <Button size='sm' mt='3rem' onClick={onSave}>
              <FormattedMessage id='journal.valueRating.save' />
            </Button>
          </Flex>
        </Box>
      )}
      
    </Modal>
  );
};

export default withLifeValues(ValueRatingModal);
