import * as React from 'react';
import { Col, Grid, Row } from 'react-styled-flexboxgrid';
import { Heading1 } from '../atoms/Headings';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { StyledErrorPage } from './styles';
import { BiggerText } from '../atoms/Text';
import SVGcamel from '../atoms/SVGIcons/SVGcamel';

const Error404: React.FC = () => {
  return (
    <StyledErrorPage>
      <Grid>
        <div className='intro-wrapper'>
          <Row>
            <Col xs={12} lg={7}>
              <Heading1>
                <FormattedMessage id={'error404.heading'} />
              </Heading1>

              <span className='subtext'>
                <FormattedMessage id={'error404.info'} />
              </span>

              <SVGcamel />

              <BiggerText>
                <FormattedHTMLMessage id={'error404.text'} />
              </BiggerText>
            </Col>
          </Row>
        </div>
      </Grid>
    </StyledErrorPage>
  );
};

export default Error404;
