import React, { FC } from 'react';
import { Flex, Paragraph, Img } from '@xcorejs/ui';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { OliLight } from 'components/theme/fonts';
import { SmallLine } from './styles';
import AnimatedArrow from 'components/atoms/Icons/AnimatedArrow';
import { memberText } from './memberText';

export interface ExtendedMembershipProps {
  plus?: boolean;
  free?: boolean;
}

const EticiaPlusFooter: FC<ExtendedMembershipProps> = ({ free, plus }) => {
  const textStyle = {
    mt: '2rem',
    maxWidth: '30rem',
    fontFamily: OliLight,
    color: '#222',
  };

  const paragraphStyle = {
    ...textStyle,
    fontSize: '1.8rem',
    lineHeight: '2.4rem',
    fontWeight: 700,
  };

  const footnoteStyle = {
    ...textStyle,
    color: '#666',
    fontSize: '1.3rem',
    lineHeight: '1.8rem',
    fontWeight: 400,
  };

  const text = memberText({ plus, free });

  return (
    <Flex 
      as='section'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      backgroundColor='#eee'
    >
      <Img mt='1rem' src='https://placehold.it/100x100' alt='' />
      
      <Paragraph
        {...paragraphStyle}
        color='#ae4a84'
        mt='3rem'
      >
        <FormattedMessage id={`eticiaPlus.footer.${text}text1`} />
      </Paragraph>

      <Paragraph
        {...paragraphStyle}
      >
        <FormattedMessage id={`eticiaPlus.footer.${text}text2`} />
      </Paragraph>

      <SmallLine />

      <Paragraph
        {...footnoteStyle}
      >
        <FormattedHTMLMessage id='eticiaPlus.footer.footnote' />
      </Paragraph>

      <Flex
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        width='100%'
        onClick={() => {
          document.documentElement.scrollTo({
            top: 0,
            behavior: 'smooth' });
        }}
      >
        <AnimatedArrow
          transform='rotate(270deg) scale(0.5)'
          color='#999'
          position='relative'
          top='15px'
          left='2px'
          cursor='pointer'
        />
        <Paragraph
          color='#ae4a84'
          textDecoration='underline'
          mt='0'
          fontSize='1.6rem'
          cursor='pointer'
        >
          <FormattedMessage id='eticiaPlus.footer.backToTop' />
        </Paragraph>
      </Flex>
    </Flex>
  );
};
 
export default EticiaPlusFooter;


