import React, { FC } from 'react';
import { Box } from '@xcorejs/ui';
import styled from 'styled-components';

interface FadeInProps {
  setStatus: React.Dispatch<React.SetStateAction<number>>;
  fadeType: 'in' | 'out';
  color?: string;
  duration?: number;
  delay?: number;
}

const Fade: FC<FadeInProps> = ({ setStatus, fadeType, color = 'black', duration = 5, delay = 0 }) => {
  const animationDuration = `${duration}s ease-in-out`;
  const fadeTypeStyle = fadeType === 'in' ? 'fadeIn' : 'fadeOut';

  const fadeBoxProps = {
    animation: `${fadeTypeStyle} ${animationDuration}`,
    opacity: fadeType === 'in' ? 0 : 1,
    onAnimationEnd: delay > 0 ? 
      () => setTimeout(() => setStatus(prev => prev + 1), delay * 1000) : 
      () => setStatus((prev) => prev + 1),
    backgroundColor: color,
  };

  return <FadeInBox width='100%' height='100%' {...fadeBoxProps} />;
};

export default Fade;

const FadeInBox = styled(Box)`
  @keyframes fadeIn {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`;