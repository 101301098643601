import { Box, Flex, Heading2, Heading3, Img, Paragraph } from '@xcorejs/ui';
import LoadingSpinnerLocal from 'components/atoms/Animations/LoadingSpinnerLocal';
import CreateValuesButton from 'components/components/EticiameterComponents/CreateValuesButton';
import ValueMap from 'components/molecules/ValueMap';
import ValueMapFull from 'components/molecules/ValueMap/types/ValueMapFull';
import { EticiaMeterTheme, yellow } from 'components/theme/colors';
import { OliLight } from 'components/theme/fonts';
import React, { FC } from 'react';
import { useFetchGameStatus } from 'utils/useQuery/queryFetchHelpers';

interface Props {
}

const Statistics: FC<Props> = () => {  
  return ( 
    <Flex as='section' flexDirection='column' alignItems='center' marginY={{ _: '4rem' }} paddingX={{ _: '2rem' }}>
      <Heading3 color={EticiaMeterTheme.text}>Yours & Others Statistics</Heading3>
      <Heading2 color={yellow} marginTop={{ _:'1rem' }}>Your Values</Heading2>
      {/* eslint-disable-next-line max-len */}
      <Paragraph color={EticiaMeterTheme.text} marginTop={{ _: '2rem' }} fontFamily={OliLight}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione neque praesentium excepturi doloribus modi, ab a, tempora et alias blanditiis laudantium dicta illo? Exercitationem eveniet deleniti suscipit eum saepe. Aut!</Paragraph>

      <CloudMap />
    </Flex>
  );
};
 
export default Statistics;

const CloudMap: FC = () => {
  const gameQuery = useFetchGameStatus();

  if (gameQuery.isLoading) {
    return (
      <Flex marginY={{ _ :'2rem' }} justifyContent='center' alignItems='center'>
        <Img src='/img/cloud-map-disabled.png' alt='' />
        
        <Box position='absolute'>
          <LoadingSpinnerLocal />
        </Box>
      
      </Flex>
    );
  }

  let gameStatus = null;
  if (gameQuery.isError) {
    console.log(gameQuery.error);
  }
  if (gameQuery.isSuccess) {
    gameStatus = gameQuery.data!;
  }

  return (
    <Box marginY={{ _: '2rem' }} width={{ _: '30rem' }} maxWidth={{ _:'80%' }}>
      {gameStatus ? (
        <ValueMap theme='normal' imagePath=''>
          <ValueMapFull lifeValues={gameStatus.tribalTestResult.allLifeValues} theme='normal' />
        </ValueMap>
      ) : (
        <Flex justifyContent='center' alignItems='center'>
          <Img src='/img/cloud-map-disabled.png' alt='' />

          <CreateValuesButton position='absolute'>Create your values FREE</CreateValuesButton>
        </Flex>
      )}
    </Box>
  );
};
