import React, { FC } from 'react';
import styled from 'styled-components';
import { Box, Flex, FlexProps } from '@xcorejs/ui';
import { mediaMdDefault } from 'components/theme/grid';

export interface AccordionProps {
  children: React.ReactNode;
  expanded: boolean;
  onlyPhone?: boolean;
  onlyDesktop?: boolean;
  wrapperProps?: FlexProps;
  additionalTransition?: string;
}

const Accordion: FC<AccordionProps & FlexProps> = ({ 
  children, 
  expanded, 
  onlyDesktop, 
  onlyPhone, 
  wrapperProps, 
  additionalTransition,
  ...props 
}) => {
  return ( 
    <DynamicWrapper 
      expanded={expanded}
      onlyDesktop={onlyDesktop}
      onlyPhone={onlyPhone}
      {...wrapperProps}
    >
      <Flex overflow='hidden' {...props} >
        {children}
      </Flex>
    </DynamicWrapper>
  );
};
 
export default Accordion;

export const DynamicWrapper = styled(Box)<Omit<AccordionProps, 'children'>>`
  display: ${({ onlyDesktop }) => onlyDesktop ? 'none' : 'grid'};
  grid-template-rows: ${({ expanded }) => expanded ? '1fr' : '0fr'};
  transition: grid-template-rows 0.5s ease !important;

  ${mediaMdDefault} {
    display: ${({ onlyPhone }) => onlyPhone ? 'none' : 'grid'};
  }
`;
