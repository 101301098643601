import * as React from 'react';
import { BoxName, Ball } from './styles';
import { IProfile, SurveyQuestion } from '../../../pages/App/Result/PartnerTestResult/data';
import { IStringMap } from '../../../../utils';
import { FormattedMessage } from 'react-intl';
import { Fragment } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';

interface IMultipleAnswers {
  question: SurveyQuestion;
  profiles: IStringMap<IProfile>;
}

const MultipleAnswers: React.FC<IMultipleAnswers> = ({ profiles, question }) => (
  <>
    {question.answerType === 'value' ? (
      <>
        <a>
          <div className={'range'}>
            <div>
              {question.skipped ? (
                <FormattedMessage id={'results.partners.oneDontAnswer'} />
              ) : (
                <>
                  <p>{question.minValue}</p>
                  <div>
                    {Object.keys(profiles).map((k, index) => (
                      <>
                        <Ball
                          key={index}
                          left={
                            ((parseInt(question.answers[k][0].value!, 10) - question.minValue) /
                              (question.maxValue - question.minValue)) *
                            100
                          }
                          background={index === 0 ? 'green' : 'blue'}
                        >
                          {parseInt(question.answers[k][0].value!, 10)}
                        </Ball>
                      </>
                    ))}
                  </div>
                  <p>{question.maxValue}</p>
                </>
              )}
            </div>

            <div className={'description'}>
              <span>{question.minText}</span>
              <span>{question.maxText}</span>
            </div>
          </div>
        </a>
      </>
    ) : (
      <>
        {question.skipped ? (
          <FormattedMessage id={'results.partners.oneDontAnswer'} />
        ) : (
          <>
            {question.answerType === 'image' ? (
              <Row>
                {Object.keys(profiles).map((key, index) => (
                  <Fragment key={index}>
                    {index === 0 ? (
                      <Col xs={6} className='inner-col'>
                        <BoxName color={'green'}>
                          {profiles[key].nick == null ? profiles[key].fullname : profiles[key].nick}
                        </BoxName>
                        <p style={{ marginLeft: '-2rem', marginRight: '-2rem' }}>
                          {question.answers[key].map((answer, i) => (
                            <img src={answer.image} key={i} alt='' />
                          ))}
                        </p>
                      </Col>
                    ) : (
                      <Col xs={6} className='inner-col'>
                        <BoxName color={'blue'} style={{ marginTop: 0 }}>
                          {profiles[key].nick == null ? profiles[key].fullname : profiles[key].nick}
                        </BoxName>
                        <p style={{ marginLeft: '-2rem', marginRight: '-2rem' }}>
                          {question.answers[key].map((answer, i) => (
                            <img src={answer.image} key={i} alt='' />
                          ))}
                        </p>
                      </Col>
                    )}
                  </Fragment>
                ))}
              </Row>
            ) : (
              Object.keys(profiles).map((key, index) => (
                <div key={key}>
                  <BoxName color={index === 0 ? 'green' : 'blue'}>
                    {profiles[key].nick == null ? profiles[key].fullname : profiles[key].nick}
                  </BoxName>
                  <div>
                    {question.answerType === 'text' && (
                      <>
                        {question.answers[key].map(answerA => (
                          <p key={answerA.id}>
                            {/* Find same answers */}
                            {Object.keys(question.answers)
                              // Do not check same profile
                              .filter(k => k !== key)
                              // Find array containing same answer
                              .some(k =>
                                // Find same answer in array
                                question.answers[k].some(answerB => answerA.id === answerB.id),
                              ) ? (
                                answerA.text
                              ) : (
                                <>{answerA.text}</>
                              )}
                          </p>
                        ))}
                      </>
                    )}

                    {question.answerType === 'free' && (
                      <>
                        {question.answers[key].map((answer, i) => (
                          <p key={i}>{answer.value}</p>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              ))
            )}
          </>
        )}
      </>
    )}
  </>
);

export default MultipleAnswers;
