import * as React from 'react';
import Table from 'components/atoms/Table';
import { FormattedMessage } from 'react-intl';
import { createUrl } from '../../../routes';
import { withRouter, RouteComponentProps } from 'react-router';
import { Box, Flex, Icon, Link as UILink, Paragraph, Stack, Tag, Typography } from '@xcorejs/ui';
import { RequestContext } from 'components/atoms/RequestContext';
import { FC, Fragment } from 'react';
import { NoteIcon } from '../../../../components/icons/16/note.svg';
import { Link } from 'react-router-dom';
import withUser, { IWithUser } from 'components/molecules/withRedux/withUser';
import { TREE_TEST_STATE, ITreeTestResultPreviewResponse } from '../../TestIntro/data';

interface IRenderTable {
  items: ITreeTestResultPreviewResponse[];
}

const ComparisonTable: FC<IRenderTable & RouteComponentProps<{}> & IWithUser> = (
  {
    items,
    user,
    history
  }
) => {
  function renderButton(item: ITreeTestResultPreviewResponse) {
    switch (item.state) {
    case TREE_TEST_STATE.done:
      return done(item);
    case TREE_TEST_STATE.waitingForInvited:
      return waitingForInvited();
    case TREE_TEST_STATE.waitingForOwner:
      return waitingForOwner(item);
    case TREE_TEST_STATE.waitingForOwnerStart:
      return waitingForOwnerStart(item);
    case TREE_TEST_STATE.waitingForPayment:
      return waitingForPayment(item);
    case TREE_TEST_STATE.waitingForResult:
      return waitingForResult();
    case TREE_TEST_STATE.waitingForSetup:
      return waitingForSetup(item);
    default:
      return done(item);
    }
  }

  return (
    <Table className='profileResult'>
      <tbody>
        {items
          .map((item, i) => (
            <Fragment key={i}>
              <tr>
                <td>
                  <Flex flexDirection={{ _: 'column', sm: 'row' }}>
                    <Box display='inline-block' mt='0.2rem' mr={{ _: 0, sm: '2rem' }}>
                      <RequestContext>
                        {({ locale }) => (
                          <Paragraph color='rgba(244, 244, 246, 0.8)' textAlign='left'>
                            {new Date(item.createdAt * 1000).toLocaleDateString(locale, {
                              year: 'numeric',
                              month: '2-digit',
                              day: '2-digit',
                            })}
                          </Paragraph>
                        )}
                      </RequestContext>

                    </Box>

                    <Flex display={{ _: 'flex', sm: 'inline-flex' }} justifyContent={{ _: 'center', sm: 'flex-start' }} alignItems='center'>

                      <Typography ml='2rem' color='rgba(244, 244, 246, 0.5)' textAlign='left'>
                        {item.amIOwner ? (
                          <Fragment>
                            {item.secondNick !== null && (
                              <Fragment>
                                <Icon
                                  svg={<NoteIcon />}
                                  fillHover='#fff'
                                  mr='1rem'
                                  opacity={0.3}
                                />
                              S uživatelem:  <Typography as={'span' as any} fontWeight={800}>{item.secondNick}</Typography>
                              </Fragment>
                            )}
                          </Fragment>
                        ) : (
                          <Fragment>
                            <Icon
                              svg={<NoteIcon />}
                              fillHover='#fff'
                              mr='1rem'
                              opacity={0.3}
                            />
                          Od uživatele: <Typography as={'span' as any} fontWeight={800}>{item.secondNick}</Typography>
                          </Fragment>
                        )}
                      </Typography>
                    </Flex>
                  </Flex>
                </td>

                <td>
                  <Box textAlign='center'>
                    <Tag bg='rgba(12, 70, 163, 1)' borderRadius='15.5px'>
                    Hodnoty pro dva
                    </Tag>
                  </Box>
                </td>

                <td>
                  <Stack
                    direction={{ _: 'column', sm: 'row' }}
                    gap={{ _: '1rem', md: '4rem' }}
                    flexWrap='wrap'
                    justifyContent={{ _: 'center', md: 'flex-end' }}
                    alignItems='center'
                  >
                    {item.canISendInvitation && (
                      <UILink as='span' v='underline' fontSize='1.6rem'>
                        <Link
                          to={{
                            pathname: createUrl('app.comparison.invitation'),
                            state: {
                              testConfigId: item.testConfigId,
                              // itemId: item.id,
                            },
                          }}

                        >
                          <FormattedMessage id={'mycomparison.invite'} />
                        </Link>
                      </UILink>
                    )}
                    <Box>
                      {renderButton(item)}
                    </Box>
                  </Stack>
                </td>
              </tr>
            </Fragment>
          ))}
      </tbody>
    </Table>
  );

  function waitingForPayment(item: ITreeTestResultPreviewResponse) {
    return (
      <Typography
        fontWeight={800}
        onClick={() => item.orderNumber && (window.location.href = '/gopay/create/' + item.orderNumber)}
        alignItems='center'
      >
        <Icon svg={<NoteIcon />} opacity={0.3} mr='1rem' />
        <FormattedMessage id={'voucher.tags.waitingForPayment'} />
      </Typography>
    );
  }

  function waitingForSetup(item: ITreeTestResultPreviewResponse) {
    return (
      <UILink
        fontWeight={800}
        onClick={() => history.push(createUrl('app.comparison.intro', { id: item.magicTokenId || '' }))}
        color='yellow'
        v='underline'
        borderColor='yellow'
      >
        <FormattedMessage id={'voucher.tags.waitingForSetup'} />
      </UILink>
    );
  }

  function waitingForOwnerStart(item: ITreeTestResultPreviewResponse) {
    return (
      <Typography
        fontWeight={800}
        onClick={() => history.push(createUrl('app.comparison.startTest', { id: item.testConfigId! }))}
        alignItems='center'
      >
        <FormattedMessage id={'voucher.tags.waitingForOwnerStart'} />
      </Typography>
    );
  }

  function waitingForOwner(item: ITreeTestResultPreviewResponse) {
    return (
      <UILink
        fontWeight={800}
        onClick={() => history.push(createUrl('app.partnerTest', { id: item.testResultId! }))}
        color='yellow'
        v='underline'
        borderColor='yellow'
      >
        <FormattedMessage id={'voucher.tags.waitingForOwner'} />
      </UILink>
    );
  }

  function waitingForInvited() {
    return (
      <Typography fontWeight={800} alignItems='center'>
        <Icon svg={<NoteIcon />} opacity={0.3} mr='1rem' />
        <FormattedMessage id={'voucher.tags.waitingForInvited'} />
      </Typography>
    );
  }

  function waitingForResult() {
    return (
      <Typography fontWeight={800}>
        <FormattedMessage id={'voucher.tags.waitingForResult'} />
      </Typography>
    );
  }

  function done(item: ITreeTestResultPreviewResponse) {
    return (
      <UILink
        fontWeight={800}
        onClick={() => history.push(createUrl('app.partnerResult', { id: item.testConfigId! }))}
        color='yellow'
        v='underline'
        borderColor='yellow'
      >
        <FormattedMessage id={'voucher.tags.done'} />
      </UILink>
    );
  }
};

export default withRouter(withUser(ComparisonTable));
