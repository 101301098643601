import { connect } from 'react-redux';
import AddDemographic from './component';
import { userActions } from '../../../store/User/UserActions';
import { IDemoUserInfo } from '../../../store/User/index';

const mapDispatch = (dispatch: any) => ({
  updateUser: (data: IDemoUserInfo) => dispatch(userActions.success(data)),
});

export default connect(null, mapDispatch)(AddDemographic);
