import { tag } from '@xcorejs/ui';

export const tagTheme = tag({
  default: {
    borderRadius: '1.5rem',
    border: 0,
    px: '0.8rem',
    py: '0.2rem',
    fontFamily: 'OliMedium',
    fontSize: '1.5rem',
    fontWeight: 500,
    lineHeight: '2rem',
    display: 'inline-flex'
  },
  variants: {
    solid: {
      bg: 'rgba(0,153,102,1)',
      color: '#fff',
    },
    outline: {
      color: '#5E6062',
      py: '0.8rem'
    },
    clear: {
      borderColor: 'transparent'
    }
  }
});
