import { connect } from 'react-redux';
import FlashMessages from './component';
import { IApplicationState } from '../../../store/store';
import { markAsRead } from 'store/FlashMessages';

const mapStateToProps = (state: IApplicationState) => ({
  messages: state.flashMessages.flashMessages,
});

const mapDispatchToProps = (dispatch: any) => ({
  markAsRead: (index: number) => dispatch(markAsRead(index)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FlashMessages);
