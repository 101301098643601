import { ComponentType, FC, Fragment, useContext, useEffect, useState } from 'react';
import Fade from 'react-reveal/Fade';
import { Box, Flex, Icon, Img, Link, Tag, Typography, useModal, Paragraph } from '@xcorejs/ui';
import SVGlevelTag from 'components/atoms/SVGIcons/SVGlevelTag';
import TypedParagraph from 'components/pages/App/ValueCenter/Game/components/TypedParagraph';
import { PrimaryButton } from 'components/components/Button';
import * as React from 'react';
import { gameLevels as data, IModal } from 'components/pages/App/ValueCenter/Game/data';
import { createUrl } from 'components/pages/routes';
import { startSurvey } from 'config/api/surveyInternal';
import { useHistory } from 'react-router';
import { GameContext } from 'components/App';
import Zoom from 'react-reveal/Zoom';
import { Link as RLink } from 'react-router-dom';
import StatisticsModal from 'components/pages/App/Statistics/components/StatisticModal';
import { GradientStyle } from 'components/pages/Presenting/pages';
import GameIcon from '../../../../../components/icons/32/game.svg';
import styled from 'styled-components';
import { HigherZIndex } from 'components/components/modals/LaddersCompareMatrixModal/style';
import withUser, { IWithUser } from 'components/molecules/withRedux/withUser';
import ResultsImg from './ResultsImg';
import Inventory from '../Inventory';
import { FormattedHTMLMessage } from 'react-intl';

export type ModalState = 'intro' | 'exit' | 'summary' | 'preIntro'

type ModalProps = {
  onAction?: () => unknown;
  onIntroAction?: () => unknown;
  onExitAction?: () => unknown;
  surveyResultId?: string;
  state: ModalState;
  statisticsModal?: ComponentType;
}

const Modal: FC<ModalProps & IWithUser> = (
  {
    onAction,
    onIntroAction,
    onExitAction,
    surveyResultId,
    state,
    statisticsModal,
    user
  }
) => {
  const { 
    insideQuest, userLvl, questVersion,
    questLvl, setInsideQuest, setQuestLvl,
    setUserLvl, setQuestVersion, setGamePopup, 
    inventory, setInventory
  } = useContext(GameContext);
  const levelData = data[questLvl];
  const levelDataState = levelData[state] as IModal[];
  const history = useHistory();
  const [textRendered, setTextRendered] = useState(false);
  const [visible, setVisible] = useState(false);
  const [contentVisible, setContentVisible] = useState(false);
  const [runInventoryAnimation, setRunInventoryAnimation] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setVisible(true), 200);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const data = levelDataState;

    const timeout = setTimeout(() => setContentVisible(true),
      data && data[questVersion] ? data[questVersion].gifLength : 5000
    );

    return () => clearTimeout(timeout);
  }, [levelDataState, questVersion, state]);  
  
  return (
    visible ? (
      <Fragment>
        {(state === 'summary' || state === 'exit') && (
          <Box
            background={levelData.theme.primary}
            width='100%'
            height='100%'
            position='absolute'
            opacity={0.2}
            transition='background 300ms'
          />
        )}
        <Fade ssrReveal top duration={300} distance='5rem' delay={500}>
          <Box
            position='relative'
            width='100%'
            height='100%'
            top={0}
            display='block'
            background={levelData.theme.primary}
            zIndex={1200}
          >

            {/* HEADER HRY */}
            <Fade ssrReveal top duration={500} distance='5rem' delay={800}>
              <Flex
                alignItems='center'
                bg='rgba(11, 12, 28, 0.15)'
                height='4.5rem'
                width='100%'
              >
                <Box>
                  <Fade ssrReveal top duration={300} distance='5rem' delay={1100}>
                    <Tag
                      padding='0'
                      borderRadius='0'
                      width='2.4rem'
                      height='4.5rem'
                      ml='1.5rem'
                      mr='0.1rem'
                      justifyContent='center'
                    >
                      <Box position='absolute' top={0}>
                        <SVGlevelTag color={levelData.theme.primary} />
                      </Box>
                      <Box position='relative'>
                        {questLvl}
                      </Box>
                    </Tag>
                  </Fade>
                </Box>

                <Typography width='100%' fontSize='2rem' lineHeight='2.5rem'>
                  <FormattedHTMLMessage
                    id={(levelData[state]![questVersion].head ?
                    levelData[state]![questVersion].head :
                      levelData.name) as string} />
                </Typography>

                <Flex justifyContent='center' width='4.5rem' minWidth='4.5rem'>
                  <Icon
                    svg={<GameIcon />}
                    fill='rgba(255, 255, 255, 0.7)'
                    width='2.4rem'
                    height='2.4rem'
                    _icon={{ width: '2.4rem', height: '2.4rem' }}
                    transform={runInventoryAnimation ? 'scale(1.4)' : 'scale(1)'}
                    transition='transform 500ms'
                    {...{ style: { transitionDelay: '300ms' } }}
                    onClick={() => setInventory(!inventory)}
                  />
                </Flex>
              </Flex>
            </Fade>

            {/* OBRÁZEK HRY */}
            <Fade ssrReveal top duration={300} delay={800}>
              <Box position='relative'>
                <Box position='relative'>
                  {levelData.gradient && (
                    <GradientStyle
                      color1={levelData.gradient.color1}
                      color2={levelData.gradient.color2}
                      color3={levelData.gradient.color3}
                      color4={levelData.gradient.color4}
                      speed={levelData.gradient.speed}
                    />
                  )}
                  <Img
                    src={`/img/game/${levelDataState.length > 1 ? levelDataState[questVersion].asset ? levelDataState[questVersion].asset : '' :
                      levelDataState[0].asset}`}
                    display='block'
                    position='relative'
                    zIndex={1}
                    alt=''
                  />
                </Box>

                {/* OBRÁZEK NA STATISTIKY A ANKETY */}
                {contentVisible && textRendered && (state === 'exit' || state === 'summary') && (
                  <ResultsImg 
                    state={state}
                    surveyResultId={surveyResultId}
                    onAction={onAction}
                  />
                )}

                {/* OBRÁZEK DO INVENTÁŘE */}
                {(state === 'exit' || state === 'summary') && levelData && textRendered && (
                  levelData.exit[questVersion].inventoryImage && (
                    <InventoryImgStyle
                      mt='1.2rem'
                      mx='auto'
                      display='block'
                      position='absolute'
                      right={runInventoryAnimation ? 0 : '2rem'}
                      bottom={runInventoryAnimation ? '100%' : '2rem'}
                      zIndex={100}
                      opacity={runInventoryAnimation ? 0 : 1}
                      transition='opacity 1000ms, right 1000ms, bottom 1000ms'
                    >
                      <Zoom ssrReveal delay={400}>
                        <Img
                          src={`/img/game/${levelDataState[questVersion].inventoryImage}`}
                          mx='auto'
                          display='block'
                          animation='imgFlash 1000ms'
                          alt=''
                        />
                      </Zoom>
                    </InventoryImgStyle>
                  )
                )}
              </Box>
            </Fade>
          </Box>
        </Fade>

        {/* OBSAH HRY */}
        <Flex
          flexDirection='column'
          alignItems='center'
          p='2rem 3.2rem'
          overflow='auto'
          height='100%'
          justifyContent='flex-start'
        >
          {/* TEXTY HRY */}
          {contentVisible && (
            <ModalContent
              onFinish={() => setTextRendered(true)}
              surveyResultId={surveyResultId}
              state={state}
              statisticsModal={statisticsModal}
              onExitAction={onExitAction}
            />
          )}
          {/* HLAVNÍ TLAČÍTKO VE HŘE */}
          {textRendered && (
            <Box>
              <Fade ssrReveal duration={2000} delay={4400}>
                <Flex justifyContent='center'>
                  {state === 'preIntro' ? (
                    <PrimaryButton
                      mt='2rem'
                      bg={levelData.preIntro ? levelData.preIntro[questVersion].buttonBg ? levelData.preIntro[questVersion].buttonBg : 'textGreen' : 'textGreen'}
                      color={levelData.preIntro ? levelData.preIntro[questVersion].buttonColor ? levelData.preIntro[questVersion].buttonColor : '#191d2c' : '#191d2c'}
                      onClick={onAction}
                      position='relative'
                      border={0}
                      overflow='hidden'
                    >
                      {levelData.preIntro && levelData.preIntro[questVersion].buttonGradient &&
                      <GradientStyle {...levelData.preIntro[questVersion].buttonGradient!} />}

                      <span style={{ position: 'relative', zIndex: 1 }}>
                        <FormattedHTMLMessage id={(levelData.preIntro && levelData.preIntro[questVersion].buttonText ? levelData.preIntro[questVersion].buttonText : 'Zahájit hru') as string} />
                      </span>
                    </PrimaryButton>
                  ) : state === 'intro' ?
                  // eslint-disable-next-line no-extra-parens
                    (levelData.intro[questVersion].surveyID) ? (
                      <PrimaryButton
                        mt='2rem'
                        bg={levelData.intro[questVersion].buttonBg ? levelData.intro[questVersion].buttonBg : 'textGreen'}
                        color={levelData.intro[questVersion].buttonColor ? levelData.intro[questVersion].buttonColor : '#191d2c'}
                        position='relative'
                        border={0}
                        overflow='hidden'
                        onClick={ () =>
                          startSurvey(levelData.intro[questVersion].surveyID as string, null)
                            .then(r => history.push(createUrl('app.survey', { id: r.id })))
                        }
                      >
                        {levelData.intro[questVersion].buttonGradient &&
                        <GradientStyle {...levelData.intro[questVersion].buttonGradient!} />}

                        <span style={{ position: 'relative', zIndex: 1 }}>
                          <FormattedHTMLMessage id={(levelData.intro[questVersion].buttonText ? levelData.intro[questVersion].buttonText : 'Zahájit hru') as string} />
                          {/*
                            {levelData.intro[questVersion].buttonText ? levelData.intro[questVersion].buttonText : 'Zahájit hru'}
                          */}
                        </span>
                      </PrimaryButton>
                    ) : (
                      <PrimaryButton
                        mt='2rem'
                        bg={levelData.intro[questVersion].buttonBg ? levelData.intro[questVersion].buttonBg : 'textGreen'}
                        color={levelData.intro[questVersion].buttonColor ? levelData.intro[questVersion].buttonColor : '#191d2c'}
                        onClick={onIntroAction || onAction}
                        position='relative'
                        border={0}
                        overflow='hidden'
                      >
                        {levelData.intro[questVersion].buttonGradient &&
                          <GradientStyle {...levelData.intro[questVersion].buttonGradient!} />}

                        <span style={{ position: 'relative', zIndex: 1 }}>
                          <FormattedHTMLMessage id={(levelData.intro[questVersion].buttonText ? levelData.intro[questVersion].buttonText : 'Zahájit hru') as string} />
                          {/* 
                            {levelData.intro[questVersion].buttonText ? levelData.intro[questVersion].buttonText : 'Zahájit hru'}
                          */}
                        </span>
                      </PrimaryButton>
                    )
                    : state === 'exit' ? (
                      <Fragment>
                        {/*
                        {levelData.surveyID && (
                          <Fetch url={SURVEY_RESULT(levelData.surveyID)}>
                            {_switch({
                              success: (result: ISurveyCompare) => (
                                <Fragment>
                                  <Link
                                    to={createUrl('app.surveyResult', {
                                      id: result.surveyResultId,
                                    })}
                                  >
                                    link
                                  </Link>

                                </Fragment>
                              ),
                              fetching: () => <LoadingSpinnerLocal />,
                            })()}
                          </Fetch>
                        )}
                        */}

                        <PrimaryButton
                          mt='2rem'
                          position='relative'
                          bg={levelData.exit[questVersion].buttonBg ? levelData.exit[questVersion].buttonBg : 'textGreen'}
                          color={levelData.exit[questVersion].buttonColor ? levelData.exit[questVersion].buttonColor : '#191d2c'}
                          border={0}
                          overflow='hidden'
                          onClick={
                            levelData.popup ? () => {
                              levelData.exit[questVersion].inventoryImage && setRunInventoryAnimation(true);
                              setTimeout(() => {
                                setGamePopup(true);
                                setInsideQuest(false);
                              }, levelData.exit[questVersion].inventoryImage ? 1100 : 200);
                            } : () => {
                              levelData.exit[questVersion].inventoryImage && setRunInventoryAnimation(true);
                              setTimeout(() => {
                                setQuestVersion(0);
                                setInsideQuest(false);
                              }, levelData.exit[questVersion].inventoryImage ? 1200 : 300);
                              setTimeout(() => {
                                setUserLvl(userLvl + 1);
                                setQuestLvl(questLvl + 1);
                              }, levelData.exit[questVersion].inventoryImage ? 2100 : 1200);
                            }
                          }
                        >
                          {levelData.exit[questVersion].buttonGradient &&
                            <GradientStyle {...levelData.exit[questVersion].buttonGradient!} />}

                          <span style={{ position: 'relative', zIndex: 1 }}>
                            <FormattedHTMLMessage id={(levelData.exit[questVersion].buttonText ? levelData.exit[questVersion].buttonText : 'Do mapy') as string} />
                            {/*
                              {levelData.exit[questVersion].buttonText ? levelData.exit[questVersion].buttonText : 'Do mapy'}
                            */}
                          </span>
                        </PrimaryButton>
                      </Fragment>
                    ) : (
                      <PrimaryButton
                        mt='2rem'
                        position='relative'
                        bg={levelData.summary[questVersion].buttonBg ? levelData.summary[questVersion].buttonBg : 'textGreen'}
                        color={levelData.summary[questVersion].buttonColor ? levelData.summary[questVersion].buttonColor : '#191d2c'}
                        onClick={() => setInsideQuest(false)}
                        border={0}
                        overflow='hidden'
                      >
                        {levelData.summary[questVersion].buttonGradient &&
                          <GradientStyle {...levelData.summary[questVersion].buttonGradient!} />}

                        <span style={{ position: 'relative', zIndex: 1 }}>
                          <FormattedHTMLMessage id={(levelData.summary[questVersion].buttonText ? levelData.summary[questVersion].buttonText : 'Do mapy') as string} />
                          {/*
                            {levelData.summary[questVersion].buttonText ? levelData.summary[questVersion].buttonText : 'Do mapy'}
                          */}
                        </span>
                      </PrimaryButton>
                    )}
                </Flex>
              </Fade>
            </Box>
          )}

          <Link variant='underline' onClick={() => setInsideQuest(false)} position='absolute' right='0' bottom='0'>
            X
          </Link>
        </Flex>

        <Inventory insideLevel />
      </Fragment>
    ) : <></>
  );
};

export default withUser(Modal);

type IModalTextContent = 'text' | 'text2' | 'text3' | 'text4' | 'buttonText' | 'text2Statistics' | 'testText' | 'head' | 'testButtonText' | 'linkText';

type ModalContentProps = {
  onFinish: () => unknown;
} & Pick<ModalProps, 'state' | 'surveyResultId' | 'statisticsModal' | 'onExitAction'>;

const ModalContent: FC<ModalContentProps> = (
  {
    onFinish,
    state,
    surveyResultId,
    statisticsModal,
    onExitAction
  }
) => {
  const { questVersion, questLvl } = useContext(GameContext);
  // @ts-ignore
  const content = data[questLvl][state][questVersion];
  const [typewriterDone, setTypeWriterDone] = useState(state === 'summary');
  const [openModal] = useModal(statisticsModal ? statisticsModal : StatisticsModal);

  useEffect(() => {
    typewriterDone && onFinish();
  }, [onFinish, typewriterDone]);

  const iModal = (t: IModalTextContent) => `game.level${questLvl}.${state}${questVersion}.${t}`;

  return (
    content ? (
      <HigherZIndex>
        <Fade
          ssrReveal
          duration={100}
          delay={100}
          style={{ width: '100%' }}
        >
          <Box width='100%'>
            {/* NADPIS */}
            <FormattedHTMLMessage id={iModal('text')}>
              {t => (
                <TypedParagraph
                  setTypeWriterDone={() => setTypeWriterDone(true)}
                  state={typewriterDone}
                  text={t ? t.toString() : ''}
                  delay={100}
                  _text={{
                    fontWeight: 400,
                    color: state === 'intro' ? 'textGreen' : 'yellow',
                    fontSize: '1.8rem'
                  }}
                />
              )}
            </FormattedHTMLMessage>
            {/* <TypedParagraph
              setTypeWriterDone={() => setTypeWriterDone(true)}
              state={typewriterDone}
              text={content && content.text ? content.text : ''}
              delay={100}
              _text={{
                fontWeight: 400,
                color: state === 'intro' ? 'textGreen' : 'yellow',
                fontSize: '1.8rem'
              }}
            /> */}

            {typewriterDone && (
              <Box>
                {/* PODNADPIS */}
                {content.text2 && (
                  <Box mt='0.5rem'>
                    <Fade
                      ssrReveal
                      duration={1000}
                      delay={500}
                    >
                      <Typography
                        color={state === 'intro' ? 'yellow' : 'textGreen'}
                        fontSize='1.4rem'
                        lineHeight='1.8rem'
                      >
                        {/* <div dangerouslySetInnerHTML={{ __html: content.text2 }} /> */}
                        <FormattedHTMLMessage id={iModal('text2')} />
                      </Typography>
                    </Fade>
                  </Box>
                )}

                {content.divider && (
                  <Fade
                    ssrReveal
                    duration={100}
                    delay={600}
                  >
                    <Box width='4rem' height='0.1rem' bg='gray' mx='auto' my='1.2rem' />
                  </Fade>
                )}

                <Fade
                  ssrReveal
                  duration={3500}
                  delay={1500}
                >
                  <Box mt={content.divider ? '0' : '1.8rem'}>
                    {/* HLAVNÍ TEXT */}
                    {content.text3 && (
                      <Typography
                        color='rgba(255, 255, 255, 0.7)'
                        fontSize='1.4rem'
                        lineHeight='1.8rem'
                      >
                        {/* <div dangerouslySetInnerHTML={{ __html: content.text3 }} /> */}
                        <FormattedHTMLMessage id={iModal('text3')} />
                      </Typography>
                    )}
                    
                    {/* OBRÁZEK */}
                    {content.image2 && (
                      <Zoom ssrReveal delay={400}>
                        <Box mt='1.2rem' mx='auto' display='block'>
                          <Img src={`/img/game/${content.image2}`} mx='auto' display='block' alt='' />
                        </Box>
                      </Zoom>
                    )}                 

                    {/* OBRÁZEK STATISTIKY A TEXT*/}
                    {content.image2Statistics && (
                      <Zoom ssrReveal delay={400}>
                        {statisticsModal && (
                          <Flex
                            alignItems='center'
                            justifyContent='center'
                            mt='1.2rem'
                          >
                            <Paragraph
                              display='inline-flex'
                              alignItems='center'
                              justifyContent='center'
                              border={`0.1rem solid ${content.buttonBg}`}
                              borderRadius='3.5rem'
                              paddingX='1.2rem'
                              paddingY='0.2rem'
                              onClick={openModal}
                              cursor='pointer'
                              _hover={{
                                backgroundColor: content.buttonBg,
                              }}
                              transition='background-color 300ms'
                            >
                              <Img
                                src={`/img/game/${content.image2Statistics}`}
                                alt=''
                                height='2.8rem'
                              />
                              {/* {content.text2Statistics} */}
                              <FormattedHTMLMessage id={iModal('text2Statistics')} />
                            </Paragraph>
                          </Flex>
                        )}
                      </Zoom>
                    )}

                    {/* TEXT NAD TLAČÍTKEM */}
                    {content.text4 && (state === 'intro' || state === 'preIntro') && (
                      <Flex justifyContent='center' mt='1.2rem'>
                        {
                          surveyResultId ? (
                            <RLink
                              to={createUrl('app.surveyResult', {
                                id: surveyResultId
                              })}
                            >
                              <Typography
                                fontWeight={400}
                                color='#3187de'
                                fontSize='1.4rem'
                                lineHeight='1.8rem'
                                display='inline-flex'
                              >
                                {/* <div dangerouslySetInnerHTML={{ __html: content.text4 }} /> */}
                                <FormattedHTMLMessage id={iModal('text4')} />
                              </Typography>
                            </RLink>
                          ) : (
                            <Typography
                              fontWeight={400}
                              color='#3187de'
                              fontSize='1.4rem'
                              lineHeight='1.8rem'
                            >
                              {/* <div dangerouslySetInnerHTML={{ __html: content.text4 }} /> */}
                              <FormattedHTMLMessage id={iModal('text4')} />
                            </Typography>
                          )
                        }

                      </Flex>
                    )}

                    {/* ODKAZ NA TEST V SUMMARY/EXIT */}
                    {content.testText && (state === 'exit' || state === 'summary') && (
                      <Flex alignItems='center' flexDirection='column' mt='0.6rem'>
                        <Paragraph
                          fontSize='1.4rem'
                          lineHeight='1.8rem'
                          display='inline-flex'
                        >
                          {/* <div dangerouslySetInnerHTML={{ __html: content.testText }} /> */}
                          <FormattedHTMLMessage id={iModal('testText')} />
                        </Paragraph>

                        <Link
                          v='underline'
                          onClick={onExitAction}
                        >
                          {/* {content.testButtonText} */}
                          <FormattedHTMLMessage id={iModal('testButtonText')} />
                        </Link>
                      </Flex>
                    )}
                  </Box>
                </Fade>
              </Box>
            )}
          </Box>
        </Fade>
      </HigherZIndex>
    ) : <></>
  );
};

const InventoryImgStyle = styled(Box)`
  @keyframes imgFlash {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.4);
    }
    100% {
      transform: scale(1);
    }
  }
`;
