import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { createUrl } from '../routes';
import { StyledLogin } from '../styles';
import { Heading1 } from '../../atoms/Headings';
import { FormattedMessage } from 'react-intl';
import { Col, Row, Grid } from 'react-styled-flexboxgrid';
import { PrimaryButtonButton } from '../../atoms/Buttons';
import wrapIntl, { IWrapIntl } from '../../atoms/wrapIntl';
import { parseQueryParams } from '../../../utils/helpers';
import { IForgottenPasswordChangeState, changePassword } from './actions';
import { FormInput } from '../../atoms/Form/FormInput';

interface IForgottenPasswordChangeProps {
  addInfo: (msg: string) => any;
}

class ForgottenPasswordChange extends React.Component<
  IForgottenPasswordChangeProps & IWrapIntl & RouteComponentProps<any>,
  IForgottenPasswordChangeState
> {
  constructor(props: IForgottenPasswordChangeProps & IWrapIntl & RouteComponentProps<any>) {
    super(props);
    const params = parseQueryParams(this.props.location.search);
    this.state = {
      password: '',
      passwordConfirm: '',
      userId: params.userId ? params.userId : '',
      token: params.token ? params.token : '',
      error: null,
    };
  }

  public render() {
    const formatMsg = this.props.formatMsg;
    return (
      <StyledLogin>
        <Grid>
          <Row>
            <Col xs={12}>
              <img src={'/img/logo-big.svg'} className={'logo'} alt='Eticia' />
              <div className='heading'>
                <Heading1>
                  <FormattedMessage id={'settings.password.change'} />
                </Heading1>
              </div>
            </Col>
          </Row>
        </Grid>

        <Grid>
          <Row>
            <Col xs={12} md={4} mdOffset={4}>
              <form onSubmit={this.changePassword}>
                <FormInput
                  type='password'
                  name='password'
                  text={formatMsg('settings.password.new')}
                  id='password'
                  required={true}
                  error={this.state.error && this.state.error.Password ? this.state.error.Password : ''}
                  onChange={this.handleInputChange}
                  value={this.state.password}
                />
                <FormInput
                  type='password'
                  name='passwordConfirm'
                  text={formatMsg('settings.password.confirm')}
                  id='passwordConfirm'
                  required={true}
                  error={this.state.error && this.state.error.PasswordConfirm ? this.state.error.PasswordConfirm : ''}
                  onChange={this.handleInputChange}
                  value={this.state.passwordConfirm}
                />

                <div>
                  <PrimaryButtonButton type='submit' onClick={this.changePassword}>
                    <FormattedMessage id={'settings.password.change.btn'} />
                  </PrimaryButtonButton>
                </div>
              </form>
            </Col>
          </Row>
        </Grid>
      </StyledLogin>
    );
  }

  private changePassword = (e: any) => {
    e.preventDefault();
    changePassword(this.state, this.onPasswordChanged, this.displayError);
  };

  private handleInputChange = (e: any) => {
    this.setState({
      [e.target.name]: e.target.value,
    } as any);
  };

  private displayError = (o: any) => {
    this.setState({
      ...this.state,
      error: o,
    });
    if (o.Token) {
      this.props.addInfo(o.Token);
    }
    if (o.UserId) {
      this.props.addInfo(o.UserId);
    }
    if (o.error) {
      this.props.addInfo(o.error);
    }
  };

  private onPasswordChanged = () => {
    this.setState({
      ...this.state,
      password: '',
      passwordConfirm: '',
      error: null,
    });
    this.props.addInfo(this.props.formatMsg('settings.password.changed'));
    this.props.history.push(createUrl('login'));
  };
}

export default withRouter(wrapIntl(ForgottenPasswordChange));
