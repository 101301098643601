import * as React from 'react';
import { FC } from 'react';
import { Document, Page, Text, Image, StyleSheet, View, Font } from '@react-pdf/renderer';
import { ITestResultResponse } from 'components/pages/App/TestIntro/data';
import { IUserInfo } from 'store/User';

interface PdfResultIdProps {
  result: ITestResultResponse;
  user: IUserInfo;
}

const PdfResultId: FC<PdfResultIdProps> = ({ result, user }) => {
  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <View style={{ position: 'relative' }}>
          <Image src='/img/pdf/resultId/pdf_1.jpg' />

          <Text
            style={{ position: 'absolute', top: '180px', width: '100%', textAlign: 'center', color: '#fff' }}
          >
            {result.testConfig.template.name}
          </Text>

          <Text
            style={{ position: 'absolute', top: '200px', width: '100%', textAlign: 'center', color: '#fff', fontSize: '10px' }}
          >
            {user.nick ?? user.fullname} {new Date(result.valueTestResult.doneAt).toLocaleDateString()}
          </Text>

          <Text
            style={{ position: 'absolute', top: '240px', left: '120px', color: '#7488b2', fontSize: '360px' }}
          >
            <Image src={`https://beta.eticia21.com/utils/share/one/${result.valueTestResult.id}.png`} />
          </Text>

          <Text
            style={{ position: 'absolute', top: '625px', left: '340px', color: '#fff' }}
          >
            {user.nick ?? user.fullname}
          </Text>
        </View>
      </Page>

      <Page size='A4' style={styles.page}>
        <Image src='/img/pdf/resultId/pdf_2.jpg' />
      </Page>
      <Page size='A4' style={styles.page}>
        <Image src='/img/pdf/resultId/pdf_3.jpg' />
      </Page>
      <Page size='A4' style={styles.page}>
        <Image src='/img/pdf/resultId/pdf_4.jpg' />
      </Page>
      <Page size='A4' style={styles.page}>
        <Image src='/img/pdf/resultId/pdf_5.jpg' />
        <Text
          style={{ position: 'absolute', top: '180px', width: '100%', textAlign: 'center', color: '#000' }}
        >
          {result.testConfig.template.name}
        </Text>

        <Text
          style={{ position: 'absolute', top: '200px', width: '100%', textAlign: 'center', color: '#999', fontSize: '10px' }}
        >
          {user.nick ?? user.fullname} {new Date(result.valueTestResult.doneAt).toLocaleDateString()}
        </Text>

        <Text
          style={{ position: 'absolute', top: '270px', left: '242px', color: '#000', fontSize: '123px' }}
        >
          <Image src={`https://beta.eticia21.com${result.valueTestResult.eticon.icon}`} />
        </Text>

        <Text
          style={{ position: 'absolute', top: '405px', width: '100%', textAlign: 'center', color: '#000' }}
        >
          {result.valueTestResult.eticonName}
        </Text>

        <Text
          style={{
            position: 'absolute',
            top: '455px',
            width: '60%',
            marginLeft: '20%',
            marginRight: '20%y',
            textAlign: 'center',
            color: '#000',
            fontSize: '8px',
            fontFamily: 'Oli'
          }}
        >
          {result.valueTestResult.eticon.text.replace(/(<([^>]+)>)/ig, '').replace(/(\r\n|\n|\r)/gm, '\n')}
        </Text>
      </Page>
      <Page size='A4' style={styles.page}>
        <View>
          <Image src='/img/pdf/resultId/pdf_6.jpg' />

          <Text
            style={{ position: 'absolute', top: '180px', width: '100%', textAlign: 'center', color: '#000' }}
          >
            {result.testConfig.template.name}
          </Text>

          <Text
            style={{ position: 'absolute', top: '200px', width: '100%', textAlign: 'center', color: '#999', fontSize: '13px' }}
          >
            {user.nick ?? user.fullname} {new Date(result.valueTestResult.doneAt).toLocaleDateString()}
          </Text>

          {result.valueTestResult.allLifeValues.slice(0, 5).map((value, i) => (
            <Text
              style={{ position: 'absolute', top: `${i * 26 + 267}px `, left: '150px', textAlign: 'left', color: '#fff', fontSize: '8px', height: '26px' }}
              key={value.valueId}
            >
              {value.name}
            </Text>
          )
          )}

          {result.valueTestResult.allLifeValues.slice(5, 10).map((value, i) => (
            <Text
              style={{ position: 'absolute', top: `${i * 26 + 267}px `, left: '328px', textAlign: 'left', color: '#fff', fontSize: '8px', height: '26px' }}
              key={value.valueId}
            >
              {value.name}
            </Text>
          )
          )}
        </View>
      </Page>
      <Page size='A4' style={styles.page}>
        <Image src='/img/pdf/resultId/pdf_7.jpg' />
      </Page>
      <Page size='A4' style={styles.page}>
        <Image src='/img/pdf/resultId/pdf_8.jpg' />
      </Page>
      <Page size='A4' style={styles.page}>
        <Image src='/img/pdf/resultId/pdf_9.jpg' />
      </Page>
      <Page size='A4' style={styles.page}>
        <Image src='/img/pdf/resultId/pdf_10.jpg' />
      </Page>
      <Page size='A4' style={styles.page}>
        <Image src='/img/pdf/resultId/pdf_11.jpg' />
      </Page>
      <Page size='A4' style={styles.page}>
        <Image src='/img/pdf/resultId/pdf_12.jpg' />
      </Page>
      <Page size='A4' style={styles.page}>
        <Image src='/img/pdf/resultId/pdf_13.jpg' />
      </Page>
      <Page size='A4' style={styles.page}>
        <Image src='/img/pdf/resultId/pdf_14.jpg' />
      </Page>
      <Page size='A4' style={styles.page}>
        <Image src='/img/pdf/resultId/pdf_15.jpg' />
      </Page>
      <Page size='A4' style={styles.page}>
        <Image src='/img/pdf/resultId/pdf_16.jpg' />
      </Page>
      <Page size='A4' style={styles.page}>
        <Image src='/img/pdf/resultId/pdf_17.jpg' />
      </Page>
      <Page size='A4' style={styles.page}>
        <Image src='/img/pdf/resultId/pdf_18.jpg' />
      </Page>
      <Page size='A4' style={styles.page}>
        <Image src='/img/pdf/resultId/pdf_19.jpg' />
      </Page>
    </Document>
  );
};

export default PdfResultId;

Font.register({
  family: 'Oli',
  format: 'truetype',
  src: '/fonts/oli.ttf'
});

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#fff',
    fontFamily: 'Oli'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  pageBackground: {
    position: 'absolute',
  },
});
