import * as React from 'react';

import { StyledResultsContent } from '../pages/styles';

interface ITestResultState {
  popup: boolean;
}

class TestResult extends React.Component<{}, ITestResultState> {
  public constructor(props: any) {
    super(props);
    this.state = { popup: true };
  }

  public render() {
    return <StyledResultsContent>{this.props.children}</StyledResultsContent>;
  }
}

export default TestResult;
