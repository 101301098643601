import { Icon } from '@xcorejs/ui';
import { white, yellow } from 'components/theme/colors';
import React, { FC } from 'react';
import MapPinSVG from '../../icons/24/map-pin.svg';
import { MarkerStyle, StyledForeignObject } from '../styles';
import { Marker } from '../types';

interface MapPinProps {
  marker: Marker;
  focusCountry: (country: string) => void;
  markerOpacity: (scale: number, minScale: number) => number;
  markerScale: number;
  country: string | null;
  mapScale: number;
}

const MapPin: FC<MapPinProps> = ({
  marker,
  focusCountry,
  markerOpacity,
  markerScale,
  country,
  mapScale
}) => {
  const sameName = country === marker.NAME || country === marker.country;
  const mOpacity = markerOpacity(mapScale, marker.minScale);
  
  return ( 
    <MarkerStyle opacity={sameName ? 1 : mOpacity}>
      <StyledForeignObject
        x={marker.x}
        y={marker.y}
        width='7%'
        height='5%'
        selected={sameName}
        visible={sameName || !!mOpacity}
      >                      
        <div style={{ transform: `scale(${markerScale})` }}>
          <div className='map-pin' style={{ color: sameName ? yellow : white }}>
            <Icon svg={<MapPinSVG />} />
          </div>

          <p
            onClick={() => focusCountry(marker.NAME)}
            className='country-name'
            // color={country === marker.NAME || country === marker.country ? yellow : white}
            // fontWeight={country === marker.NAME || country === marker.country ? 'bold' : 'normal'}
          >{marker.country || marker.NAME}</p>
          <p
            onClick={() => focusCountry(marker.NAME)}
            className='country-value'
          >{marker.value}</p>
        </div>
      </StyledForeignObject>
    </MarkerStyle>
  );
};
 
export default MapPin;