import { Box, Img } from '@xcorejs/ui';
import Countdown from 'components/atoms/Countdown/Countdown';
import Fetch from 'components/atoms/Fetch';
import BasePopup from 'components/atoms/Popups/FAQPopup/BasePopup';
import FullScreenAd from 'components/components/Advertisement/FullScreenAd';
import Slideshow from 'components/components/Slideshow';
import { ONE_ROUND_TEST_ID } from 'components/pages/App/TestIntro/data';
import { ITestConfig } from 'components/pages/App/ValueComparison/StartTest/data';
import { yellow } from 'components/theme/colors';
import { OliLight } from 'components/theme/fonts';
import { TEST_GET_CONFIG } from 'config/apiRoutes';
import * as React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import { chain, _switch } from '../../../utils/option';
import LoadingSpinner from '../../atoms/Animations/LoadingSpinner';
import LoadingSpinnerLocal from '../../atoms/Animations/LoadingSpinnerLocal';
import { IUseFetch } from '../../atoms/Fetch/useFetch';
import { Heading2 } from '../../atoms/Headings';
import FAQPopup from '../../atoms/Popups/FAQPopup';
import { TestRound } from '../../atoms/Popups/FAQPopup/popups';
import { HelpLink } from '../../atoms/Popups/HelpLink';
import { CustomText } from '../../atoms/Text';
import wrapIntl, { IWrapIntl } from '../../atoms/wrapIntl';
import withPopups, { IWithPopups } from '../../molecules/withRedux/withPopups';
import { createUrl } from '../../pages/routes';
import { InfoPopupButton } from '../MainPopup/InfoPopup/InfoPopupButton';
import { IAddRecord, ICurrentRound } from './data';
import { Question } from './Question';
import RoundStep from './RoundStep';
import TestLayout from './TestLayout';
import { StyledLoadingText, StyledTestResultLoading } from './TestLayout/styles';

interface IClassicTest {
  id: string;
  round: IUseFetch<ICurrentRound>;
  onFinish: () => string;
  openValueDefinition: (id: string) => any;
  sendData: (testId: string, data: IAddRecord<{}>) => Promise<unknown>;
  skip?: (testId: string) => Promise<unknown>;
  partner?: boolean;
  stateOnRedirect?: any;
}

interface IClassicTestState {
  fetchingResponse: boolean;
  popupShowed: boolean;
  popupShowed2: boolean;
  timeout: boolean;
  adPopupShowed: boolean;
  adShow: boolean;
}

class Test extends React.Component<
  IClassicTest & RouteComponentProps<{ id: string }> & IWrapIntl & IWithPopups,
  IClassicTestState
> {
  public state: IClassicTestState = {
    fetchingResponse: false,
    popupShowed: false,
    popupShowed2: false,
    timeout: true,
    adPopupShowed: false,
    adShow: false,
  };

  public componentDidMount() {
    if (this.props.round.state === 'fail') {
      this.props.history.push(createUrl('app'));
    }
  }

  public componentDidUpdate(prev: IClassicTest & RouteComponentProps<{ id: string }> & IWrapIntl & IWithPopups) {
    if (this.props.round.state === 'fail') {
      this.props.history.push(createUrl('app'));
    }

    if (this.props.round.state === 'success') {
      if (this.props.partner) {
        window.onpopstate = () => {
          alert(this.props.formatMsg('partnerTest.canNotBack'));
          this.props.history.push(this.props.location.pathname);
        };
      }
    }

    if (!this.state.adShow &&
      !this.state.adPopupShowed &&
      this.props.round.result &&
      this.advertisementSteps.includes(this.props.round.result.globalStep)) {
      this.setState({ adShow: true });
    }

    if (this.props.round.result && prev.round.result && 
      this.props.round.result.globalStep !== prev.round.result.globalStep) {
      this.setState({ adPopupShowed: false });
    }
  }

  public render() {
    const { openValueDefinition, keyExists, skip, popups } = this.props;
    const { result: round, state } = this.props.round;

    if (state !== 'success') {
      return <LoadingSpinner />;
    }

    if (
      this.props.round &&
      !this.state.popupShowed &&
      this.props.round.result!.type !== 'final' &&
      this.props.round.result!.globalStep === 1 &&
      this.props.round.result!.step === 1
    ) {
      this.setState({ popupShowed: true });
      this.props.round.result && this.props.round.result.testConfigId === ONE_ROUND_TEST_ID && popups.custom(TestRound);
    }

    if (
      this.props.round &&
      !this.state.popupShowed2 &&
      this.props.round.result!.type === 'final' &&
      this.props.round.result!.step === 1
    ) {
      this.setState({ popupShowed2: true });
      //popups.custom(TestFinalRound);
    }

    return (
      <React.Fragment>
        {this.state.adShow && (
          <FullScreenAd
            onClick={() => this.setState({ adPopupShowed: true, adShow: false })} />
        )}
        {round && round.type === 'question' ? (
          <TestLayout
            round={round!}
            customColorTheme={round.theme}
            inRound={!this.state.fetchingResponse && this.state.timeout}
          >
            {!this.state.fetchingResponse && this.state.timeout ? (
              <Box pt='5rem'>
                {_switch({
                  question: () => (
                    <>
                      <Question round={round!} submit={this.submit} skip={skip && this.skip} />
                      {chain(
                        'popup.test.question.' + round!.additionalQuestion.type + '.' + round!.additionalQuestion.layout,
                      ).then(
                        hint =>
                          keyExists(hint) && (
                            <InfoPopupButton
                              type='html'
                              content={
                                'popup.test.question.' +
                                round!.additionalQuestion.type +
                                '.' +
                                round!.additionalQuestion.layout
                              }
                            >
                              <HelpLink size={3} />
                            </InfoPopupButton>
                          ),
                      )}
                    </>
                  ),
                })()(round!.type)()}
              </Box>
            )
              : this.props.round!.result!.globalStep === this.props.round!.result!.totalSteps ? (
                <Box
                  bg='url(/img/meeticia/pozadi_seznamka_.jpg)'
                  backgroundPosition='center bottom'
                  backgroundSize='cover'
                  {...{ backgroundAttachment: 'fixed' }}
                  minHeight='100vh'
                  width='100vw'
                  top={0}
                  ml={{ _: 0, sm: '-3rem' }}
                  pt='6rem'
                  pb='3.5rem'
                  mt='-6rem'
                  mb='-3rem'
                >
                  <StyledTestResultLoading>
                    <StyledLoadingText>
                      <Heading2>
                        <FormattedHTMLMessage id={'test.posting.waiting'} />
                      </Heading2>

                      <CustomText>
                        <FormattedHTMLMessage id={'test.posting.waiting2'} />
                      </CustomText>
                    </StyledLoadingText>

                    <LoadingSpinnerLocal />

                    {/*
              <Banner>
                <img src={GetC2A(C2ANames.forTwo)} alt='Hodnoty pro dva' />
              </Banner>
              */}
                  </StyledTestResultLoading>
                </Box>
              ) :
                <LoadingSpinner />
            }
          </TestLayout>
        ) : (
          <Fetch url={TEST_GET_CONFIG(round!.testConfigId as string)}>
            {_switch({
              success: (testConfig: ITestConfig) => (
                <TestLayout
                  round={round!}
                  template={testConfig.template}
                  inRound={!this.state.fetchingResponse && this.state.timeout}
                >
                  {!this.state.fetchingResponse && this.state.timeout ? (
                    <Box>
                      {_switch({
                        classic: () => (
                          <>
                            <RoundStep
                              template={testConfig.template}
                              round={round!}
                              size={this.props.round.result!.requiredValuesCount}
                              steps={1}
                              submit={this.submit}
                              openValueDefinition={openValueDefinition}
                            />

                            <FAQPopup
                              showOnLoad='RoundStep1'
                              delay={1500}
                              showEveryTime={true}
                              popup={() => (
                                <BasePopup button='ok' background={testConfig.template.theme}>
                                  {testConfig.template.info.popup1Image && (
                                    <div className={'external-svg'}>
                                      <Img src={testConfig.template.info.popup1Image} alt='' />
                                    </div>
                                  )}

                                  {testConfig.template.info.popup1Header && (
                                    <h3>
                                      {testConfig.template.info.popup1Header}
                                    </h3>
                                  )}

                                  {testConfig.template.info.popup1Instruction && (
                                    <p id='line1'>
                                      {testConfig.template.info.popup1Instruction}
                                    </p>
                                  )}
                                  {testConfig.template.info.popup1Description && (
                                    <p id='line2'>
                                      {testConfig.template.info.popup1Description}
                                    </p>
                                  )}
                                  {testConfig.template.info.popup1AdditionalNote && (
                                    <p id='line3'>
                                      {testConfig.template.info.popup1AdditionalNote}
                                    </p>
                                  )}
                                </BasePopup>
                              )}
                            >
                              <span className='help-link'>
                                <HelpLink size={3} />
                              </span>
                            </FAQPopup>
                          </>
                        ),
                        final: () => (
                          <>
                            <RoundStep
                              round={round!}
                              size={this.props.round.result!.requiredValuesCount}
                              steps={2}
                              submit={this.submit}
                              template={testConfig.template}
                              openValueDefinition={openValueDefinition}
                            />

                            <FAQPopup
                              showOnLoad='RoundStep2'
                              delay={1500}
                              showEveryTime={true}
                              popup={() => (
                                <BasePopup button='ok' background={testConfig.template.theme}>
                                  {testConfig.template.info.popup1Image && (
                                    <div className={'external-svg'}>
                                      <Img src={testConfig.template.info.popup1Image} alt='' />
                                    </div>
                                  )}
                                  {testConfig.template.info.popup2Header && (
                                    <h3>
                                      {testConfig.template.info.popup2Header}
                                    </h3>
                                  )}

                                  {testConfig.template.info.popup2Instruction && (
                                    <p id='line1final'>
                                      {testConfig.template.info.popup2Instruction}
                                    </p>
                                  )}
                                </BasePopup>
                              )}
                            >
                              <span className='help-link'>
                                <HelpLink size={3} />
                              </span>
                            </FAQPopup>
                          </>
                        ),
                      })()(round!.type)()}
                    </Box>
                  )
                    : this.props.round!.result!.globalStep === this.props.round!.result!.totalSteps ? (
                      <Box
                        bg='url(/img/meeticia/pozadi_seznamka_.jpg)'
                        backgroundPosition='center bottom'
                        backgroundSize='cover'
                        {...{ backgroundAttachment: 'fixed' }}
                        minHeight='100vh'
                        width='100vw'
                        top={0}
                        ml={{ _: 0, sm: '-3rem' }}
                        pt={{ _: '3rem', sm:'6rem' }}
                        pb='3.5rem'
                        mt='-6rem'
                        mb='-3rem'
                      >
                        <StyledTestResultLoading>
                          <StyledLoadingText>
                            <Heading2>
                              <FormattedMessage id={'test.posting.waiting'} />
                            </Heading2>

                            <CustomText>
                              <FormattedMessage id={'test.posting.waiting2'} />
                            </CustomText>
                          </StyledLoadingText>

                          <Countdown timeInSeconds={70} justifyContent='center' alignItems='center' _paragraphProps={{ color: yellow, fontFamily: OliLight }} />

                          <LoadingSpinnerLocal />
        
                          <Slideshow />
                          {/*
              <Banner>
                <img src={GetC2A(C2ANames.forTwo)} alt='Hodnoty pro dva' />
              </Banner>
              */}
                        </StyledTestResultLoading>
                      </Box>
                    ) :
                      <LoadingSpinner />
                  }
                </TestLayout>
              )
              ,
              fetching: () => <LoadingSpinnerLocal />,
            })()}
          </Fetch>
        )}
      </React.Fragment>
    );
  }

  private submit = (data: IAddRecord<{}>) => {
    this.setState({ fetchingResponse: true });
    const timeout = setTimeout(() => this.setState({ timeout: true }), 5000);
    if (!(this.props.round!.result!.globalStep === this.props.round!.result!.totalSteps)) {
      clearTimeout(timeout);
    } else {
      this.setState({ timeout: false });
    }
    return this.props
      .sendData(this.props.id, data)
      .then(() => {
        this.setState({ fetchingResponse: false });
        if (this.state.timeout) {
          this.reloadOrRedirect();
        } else {
          clearTimeout(timeout);
          setTimeout(() => {
            this.reloadOrRedirect();
            this.setState({ timeout: true });
          }, 5000);
        }
      })
      .catch(reason => console.log(reason));
  };

  private skip = () => {
    if (this.props.skip !== undefined) {
      this.setState({ fetchingResponse: true });
      return this.props.skip(this.props.id).then(() => {
        this.setState({ fetchingResponse: false });
        this.reloadOrRedirect();
      });
    }
  };

  private reloadOrRedirect = () =>
    this.props.round.result!.globalStep === this.props.round.result!.totalSteps
      ? this.props.history.push(this.props.onFinish(), this.props.stateOnRedirect)
      : this.props.round.reload('', 'true');

  // při kterých krocích se má zobrazit reklama
  private advertisementSteps = [5, 9, 13];
}

export default withRouter(wrapIntl(withPopups(Test)));
