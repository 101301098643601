import Fade from 'react-reveal/Fade';
import wrapIntl, { IWrapIntl } from 'components/atoms/wrapIntl';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import InlineFormSelect from 'components/atoms/InlineForm/InlineFormSelect';
import InlineFormInput from 'components/atoms/InlineForm/InlineFormInput';
import Popup from '../Popup';
import { StyledLink } from 'components/atoms/Buttons/styles';
import { createUrl } from 'components/pages/routes';
import { Option } from 'react-select';
import { IRegisterUserVm, IRegisterUserErrors } from './RegisterAfterGoogle';
import withPopups, { IWithPopups } from '../../../molecules/withRedux/withPopups';
import { Box, Flex, Link, Typography } from '@xcorejs/ui';
import { CheckboxInputStyle, CheckedIconStyle } from 'components/pages/App/Shop/Buy';
import { PrimaryButton } from 'components/components/Button';

interface IRegisterForm {
  errors: IRegisterUserErrors;
  sendRegistration: (user: IRegisterUserVm) => unknown;
  popup: (body: React.ComponentType, title: string, button?: string) => any;
  knownUserInfo: IRegisterUserVm | null;
}

const RegisterAfterGoogleForm: React.FC<IWrapIntl & IRegisterForm & IWithPopups> = ({
  popups,
  formatMsg,
  sendRegistration,
  popup,
  errors,
  knownUserInfo,
}) => {
  const [user, setUser] = React.useState<IRegisterUserVm>({
    nick: knownUserInfo && knownUserInfo.nick ? errors.Nick ? '' : knownUserInfo.nick : '',
    femalePronouns: knownUserInfo && knownUserInfo.femalePronouns ? knownUserInfo.femalePronouns : undefined,
    countryId: knownUserInfo && knownUserInfo.countryId ? knownUserInfo.countryId : '561962a8-5959-4321-ac01-d5e2e7d06cf9'
  });
  const [agree, setAgree] = React.useState<boolean | 'error'>(false);

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        agree ? sendRegistration(user) : setAgree('error');
      }}
    >
      <Fade ssrReveal bottom duration={1000} distance='5rem'>
        <Box>
          <Box maxWidth='41.6rem' mx='auto'>
            <Flex flexDirection='column' mt='3.2rem'>
              <Typography mb='0.8rem' textAlign='center' color='rgba(255, 255, 255, 0.6)'>
                Země
              </Typography>

              <InlineFormSelect
                name='countryId'
                error={errors.countryId}
                value={user.countryId}
                options={[
                  { label: formatMsg('registration.choose'), value: -1 },
                  {
                    value: '561962a8-5959-4321-ac01-d5e2e7d06cf9',
                    label: 'Česká Republika'
                  },
                  {
                    value: '23f0acf4-81b6-4553-8bf4-46bac3e6485a',
                    label: 'Slovenská Republika'
                  },
                ]}
                disabled={[-1]}
                onChange={opt =>
                  setUser({
                    ...user,
                    countryId: (opt as Option).value as string,
                  })
                }
              />
            </Flex>

            <Flex flexDirection='column' mt='3.2rem'>
              <Typography mb='0.8rem' textAlign='center' color='rgba(255, 255, 255, 0.6)'>
                <FormattedMessage id={'registrationInline.nick'} />{' '}
              </Typography>

              <InlineFormInput
                name='nick'
                placeholder={formatMsg('registration.nick')}
                error={errors.Nick && errors.Nick[0]}
                value={user.nick}
                input={nick => setUser({ ...user, nick })}
              />
            </Flex>

            <Flex flexDirection='column' mt='3.2rem'>
              <Typography mb='0.8rem' textAlign='center' color='rgba(255, 255, 255, 0.6)'>
                <FormattedMessage id={'registrationInline.useFemalePronouns'} />{' '}
              </Typography>

              <InlineFormSelect
                name='name'
                error={errors.sex}
                value={user.femalePronouns === undefined ? -1 : user.femalePronouns ? 0 : 1}
                options={[
                  ...[formatMsg('registration.woman'), formatMsg('registration.man')].map((label, value) => ({
                    label,
                    value,
                  })),
                  { label: formatMsg('registration.choose'), value: -1 },
                ]}
                disabled={[-1]}
                onChange={opt =>
                  setUser({
                    ...user,
                    // eslint-disable-next-line no-extra-parens
                    femalePronouns: (opt as Option).value === 0,
                  })
                }
                required
              />
            </Flex>

            <Flex alignItems='center' as='label'>
              <Box position='relative'>
                <CheckboxInputStyle type='checkbox' id='agree' onChange={(e) => setAgree(e.target.checked)} />
                <CheckedIconStyle className='checkbox-icon-checked'  />
              </Box>

              <Box ml='1.6rem'>
                <Typography color='rgba(255, 255, 255, 0.7)' fontSize='1.6rem' textAlign='left'>
                  <FormattedMessage id={'register.agree'} />
                  &nbsp;
                  <Link
                    v='underline'
                    color='yellow'
                    borderColor='yellow'
                    onClick={() => popups.custom(() => (<Popup />))}
                  >
                    <FormattedMessage id={'register.agree.terms'} />
                  </Link>
                </Typography>

                <Typography color='rgba(255, 255, 255, 0.7)' fontSize='1.6rem' textAlign='left'>
                  <FormattedMessage id={'register.gdpr'} />
                  &nbsp;
                  <Link
                    v='underline'
                    color='yellow'
                    borderColor='yellow'
                    href='/Prohlaseni.pdf'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <FormattedMessage id={'register.gdpr.terms'} />
                  </Link>
                </Typography>
              </Box>
            </Flex>

            {agree === 'error' && <Typography color='red'><FormattedMessage id='registration.mustAgree' /></Typography>}

            <Flex justifyContent='center' mt='3.2rem'>
              <PrimaryButton bg='rgba(12, 107, 75, 0.9)' type='submit' color='#fff' _hover={{ color: '#000' }}>
                <FormattedMessage id={'register.finish'} />
              </PrimaryButton>
            </Flex>

            <Flex justifyContent='center' mt='2.4rem'>
              <StyledLink to={createUrl('')}>
                <Link fontSize='1.4rem' v='underline'>
                  <FormattedMessage id={'backToIntro'} />
                </Link>
              </StyledLink>
            </Flex>
          </Box>
        </Box>
      </Fade>
    </form>
  );
};

export default withPopups(wrapIntl(RegisterAfterGoogleForm));
