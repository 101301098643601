import * as React from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { FormattedMessage } from 'react-intl';
import { Heading2 } from '../../../../../atoms/Headings';
import { StyledResultBoxes } from '../../../../../molecules/TestResult/AnswerBox/styles';
import { IStringMap } from 'utils';
import { IValueTestResultDetail } from 'config/api/valueTestResult';
import { IProfile } from '../data';
import StatisticsSlider from '../../../../../molecules/StatisticsSlider';
import { Box } from '@xcorejs/ui';
import { CustomText, SectionText } from '../../../../../atoms/Text';
import FAQPopup from '../../../../../atoms/Popups/FAQPopup';
import { MatchIndex } from '../../../../../atoms/Popups/FAQPopup/popups';
import Fade from 'react-reveal/Fade';

interface IData {
  results: IStringMap<IValueTestResultDetail>;
  profiles: IStringMap<IProfile>;
}

const StatisticResult: React.FC<IData> = ({ results, profiles }) => {
  return (
    <StyledResultBoxes className='together'>
      <Row>
        {Object.keys(profiles).map((key, index) => (
          <Col key={index} xs={12} md={6} lg={5} lgOffset={index === 0 ? 1 : 0}>
            <Fade bottom ssrReveal duration={1000} distance={'5rem'}>
              <div>
                <Heading2 color={index === 0 ? 'green' : 'blue'}>
                  {profiles[key].fullname == null ? profiles[key].nick : profiles[key].fullname}
                </Heading2>

                <CustomText>
                  <strong
                    style={{
                      display: 'block',
                      marginBottom: '1rem',
                      color: '#fff',
                    }}
                  >
                    <FormattedMessage id='results.partners.statistics.mainStatistics' />
                  </strong>

                  <FormattedMessage id='results.partners.statistics.mainStatistics.text' />
                </CustomText>
              </div>

              <Box mt='3rem' mb='3rem' className='slider'>
                <StatisticsSlider testID={results[key].id} skipStatisticLevel={true} />
              </Box>
            </Fade>
          </Col>
        ))}
      </Row>

      <Box mt='3rem' mb='4rem' mx='auto' display='block'>
        <Fade bottom ssrReveal duration={1000} distance={'5rem'}>
          <FAQPopup popup={MatchIndex}>
            <SectionText color='#00E58D'>
              <FormattedMessage id={'results.partners.statistics.mainStatistics.popup'} />
            </SectionText>
          </FAQPopup>
        </Fade>
      </Box>
    </StyledResultBoxes>
  );
};

export default StatisticResult;
