import { Flex, Heading3 } from '@xcorejs/ui';
import { EticiaMeterTheme } from 'components/theme/colors';
import React, { FC } from 'react';
import EticonCarousel from '../Homepage/components/Carousel/EticonCarousel';


const YourEticon: FC = () => {
  return (
    <Flex flexDirection='column' alignItems='center' marginY={{ _: '2rem' }}>
      <Heading3 color={EticiaMeterTheme.text}>What is your Eticon</Heading3>
  
      <EticonCarousel
        _carousel={{ marginTop: { _:'2rem' } }}
        _eticonList={{
          bgEven: EticiaMeterTheme.darken,
          bgOdd: EticiaMeterTheme.light
        }}
      />
    </Flex>
  );
};

export default YourEticon;