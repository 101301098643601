import * as React from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@xcorejs/ui';
import styled from 'styled-components';

const Banner: React.FC<{ to?: string }> = ({ children, to, ...props }) => {
  return to ? (
    <Link to={to}>
      <StyledBanner {...props}>{children}</StyledBanner>
    </Link>
  ) : (
    <StyledBanner {...props}>{children}</StyledBanner>
  );
};

export default Banner;


export const StyledBanner = styled(Box)`
  display: block;
  border-radius: 0.4rem;
  background: radial-gradient(circle at top, #1c3389 0%, #0e2370 100%);
  box-shadow: 0 10px 20px 0 rgba(3, 12, 46, 0.5);
  overflow: hidden;
  max-width: 30rem;
  margin: 0 auto;

  img {
    display: block;
  }
`;