import * as React from 'react';
import Fetch from '.';
import { FetchState, FetchReload } from './index';

export interface IWithFetch<T> {
  state: FetchState;
  result: T;
  reload: FetchReload<T>;
}

export default <TOutterProps extends {}>(url: string) => <TResult extends {}>(
  Component: React.ComponentType<IWithFetch<TResult> & TOutterProps>,
) => {
  const fetchResult = (state: FetchState, props: TOutterProps) => (
    result: TResult | null,
    reload: FetchReload<TResult>,
  ) => <Component state={state} result={result!} reload={reload} {...props} />;

  return (props: TOutterProps) => <Fetch url={url}>{(state: FetchState) => fetchResult(state, props)}</Fetch>;
};
