import { XcoreProvider } from '@xcorejs/ui';
import { CURRENT_PROFILE } from 'config/apiRoutes';
import * as React from 'react';
import { createContext, useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { IUserInfo } from 'store/User';
import { createGlobalStyle } from 'styled-components';
import { IFetchException } from 'utils/exceptions';
import { jsonFetch } from 'utils/fetch';
import { theme } from '../config/design-system/theme';
import { IFlatRoute } from '../utils/router';
import HeadBase from './atoms/HeadBase/HeadBase';
import { useRequest } from './atoms/RequestContext';
import Page from './organisms/Layout/Page';
import NotificationFetcher from './organisms/NotificationFetcher';
import { createFlatRoutes, createUrl } from './pages/routes';
import General from './templates/General';


const GlobalStyle = createGlobalStyle`  
  html {
    font-size: 10px;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0); 
    color: #fff;
    
    @media (min-width: 1024px) and (max-height: 840px) {
        font-size: 9px;
    }
    
    @media (min-width: 1024px) and (max-height: 750px) {
        font-size: 8px;
    }
  }
  
  *::-webkit-scrollbar {
    width: 8px;
  }

  *::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.2);
    transition: background 0.3s;
  }

  *::-webkit-scrollbar-track:hover {
    background: rgba(255, 255, 255, 0.3);
  }

  *::-webkit-scrollbar-thumb {
    background-color: #191d2c;
    background: rgba(25, 29, 44, 0.3);
    border-radius: 4px;
  }

  *::-webkit-scrollbar-thumb:hover {
    background: rgba(25, 29, 44, 0.5);
  }
`;

export const GameContext = createContext({
  insideQuest: false,
  setInsideQuest: (insideQuest: boolean) => {},
  questLvl: 0,
  setQuestLvl: (questLbl: number) => {},
  userLvl: 0,
  setUserLvl: (userLvl: number) => {},
  questVersion: 0,
  setQuestVersion: (questVersion: number) => {},
  eticiaPlus: false,
  setEticiaPlus: (eticiaPlus: boolean) => {},
  gamePopup: false,
  setGamePopup: (gamePopup: boolean) => {},
  customString: '',
  setCustomString: (customString: string) => {},
  inventory: false,
  setInventory: (inventory: boolean) => {},
  testMode: false,
  setTestMode: (testMode: boolean) => {},
  meeticia: false,
  setMeeticia: (meeticia: boolean) => {},
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 60 * 24,
    }
  }
});

const App: React.FC = () => {
  const { locale } = useRequest();
  const [insideQuest, setInsideQuest] = useState(false);
  const [userLvl, setUserLvl] = useState(0);
  const [questLvl, setQuestLvl] = useState(0);
  const [questVersion, setQuestVersion] = useState(0);
  const [eticiaPlus, setEticiaPlus] = useState(false);
  const [gamePopup, setGamePopup] = useState(false);
  const [customString, setCustomString] = useState('');
  const [inventory, setInventory] = useState(false);
  const [testMode, setTestMode] = useState(false);
  const [meeticia, setMeeticia] = useState(false);
  const history = useHistory();

  const getRoute = (r: IFlatRoute<{}>) => {
    const route = { ...r };
    const Component = r.component;
    delete route.component;
    return (
      <Route
        {...route}
        key={r.path}
        render={() => (
          <Page id={route.id!}>
            <Component />
          </Page>
        )}
      />
    );
  };

  useEffect(() => {
    jsonFetch<IUserInfo>(CURRENT_PROFILE)
      .then(user => {
        setEticiaPlus(user.eticiaPlus || user.eticiaPlusForever);
        user.datingInfo && setMeeticia(true);
        setUserLvl(user.level);
        setQuestLvl(user.level);
        console.log(user);
      })
      .catch((e: IFetchException) => console.log('error'));
  }, []);

  // odkaz uvnitř modálu zavře modál a přesměruje na danou stránku
  useEffect(() => {
    const redirect = (event: MessageEvent) => {
      const windowOrigin = window.location.origin;

      if (event.origin === windowOrigin && event.data.type && event.data.type.startsWith('closeRedirect')) {
        const [ , url] = event.data.type.split('_');
        window.location.href = url;
      }
    };
    
    window.addEventListener('message', redirect);
    return () => {
      window.removeEventListener('message', redirect);
    };
  }, [history]);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <GameContext.Provider
          value={{
            insideQuest: insideQuest,
            setInsideQuest: setInsideQuest,
            questLvl: questLvl,
            setQuestLvl: setQuestLvl,
            userLvl: userLvl,
            setUserLvl: setUserLvl,
            questVersion: questVersion,
            setQuestVersion: setQuestVersion,
            eticiaPlus: eticiaPlus,
            setEticiaPlus: setEticiaPlus,
            gamePopup: gamePopup,
            setGamePopup: setGamePopup,
            customString: customString,
            setCustomString: setCustomString,
            inventory: inventory,
            setInventory: setInventory,
            testMode: testMode,
            setTestMode: setTestMode,
            meeticia: meeticia,
            setMeeticia: setMeeticia
          }}
        >
          <XcoreProvider theme={theme}>
            <GlobalStyle />
            <HeadBase />

            <General routes={createFlatRoutes(locale)}>
              <NotificationFetcher>
                <Switch>
                  {createFlatRoutes(locale).map(x => getRoute(x))}
                  <Route>
                    <Redirect to={createUrl('e404')} />
                  </Route>
                </Switch>
              </NotificationFetcher>
            </General>
          </XcoreProvider>
        </GameContext.Provider>
      </QueryClientProvider>
    </>
  );
};

export default App;
