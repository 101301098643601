import { Img } from '@xcorejs/ui';
import LoadingSpinnerLocal from 'components/atoms/Animations/LoadingSpinnerLocal';
import { useUser } from 'components/atoms/hooks/useUser';
import Ladders from 'components/pages/App/Statistics/components/Ladders';
import StatisticsModal, { StatisticsModalProps } from 'components/pages/App/Statistics/components/StatisticModal';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useFetchStatGroupMatchProfile } from 'utils/useQuery/queryFetchHelpers';
import { ModalContentStyle, StatisticsModalStyle } from '../style';

const YoungOldModal: FC<StatisticsModalProps> = () => {  
  const user = useUser();
  const statisticsProfileQuery = useFetchStatGroupMatchProfile();

  if (statisticsProfileQuery.isLoading)
    return <LoadingSpinnerLocal />;

  if (statisticsProfileQuery.isError)
    return <></>;

  const statistics = statisticsProfileQuery.data!;
  
  return (
  
    <StatisticsModal
      {...StatisticsModalStyle({ minHeightInput:'65.5rem', minMobileHeightInput:'65.5rem', colorSchemeInput:'gray' })}
      content={
        [
          {
            heading: <FormattedMessage id='statistics.site.other.result2.head1' />,
            tag: <FormattedMessage id='statistics.youngOld.content1.tag' />,
            _tag: {
              bg: 'rgba(72, 73, 91, 1)',
            },
            text: <FormattedMessage id='statistics.youngOld.content1.text' />,
            box1: <Img src='//placehold.it/180x273' alt='' />,
            text2: <FormattedMessage id='statistics.youngOld.content1.text2' />,
            ...ModalContentStyle
          },
          {
            heading: <FormattedMessage id='statistics.site.other.result2.head1' />,
            tag: <FormattedMessage id='statistics.youngOld.content2.tag' />,
            text: <FormattedMessage id='statistics.youngOld.content2.text' />,
            circle: {
              name: statistics.filter(s => s.code === 'All 16-19')[0].title,
              img: statistics.filter(s => s.code === 'All 16-19')[0].image,
              circleSize: 'large'
            },
            text2: <FormattedMessage id='statistics.youngOld.content2.text2' />,
            box2: <Ladders user={user} values={statistics.filter(s => s.code === 'All 16-19')[0].topTenValueResults} />,
            ...ModalContentStyle
          },
          {
            heading: <FormattedMessage id='statistics.site.other.result2.head1' />,
            tag: <FormattedMessage id='statistics.youngOld.content3.tag' />,
            _tag: {
              bg: 'rgba(10, 65, 116, 1)'
            },
            text: <FormattedMessage id='statistics.youngOld.content3.text' />,
            circle: {
              name: statistics.filter(s => s.code === 'All 65+')[0].title,
              img: statistics.filter(s => s.code === 'All 65+')[0].image,
              circleSize: 'large'
            },
            text2: <FormattedMessage id='statistics.youngOld.content3.text2' />,
            box2: <Ladders user={user} values={statistics.filter(s => s.code === 'All 65+')[0].topTenValueResults} />,
            ...ModalContentStyle
          },
        ]
      }
    />
  );
};

export default YoungOldModal;
