import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Heading1 } from '../../../../../atoms/Headings';
import { SmallerText } from '../../../../../organisms/VisualValuesComparison/style';
import { GameConfig, StyledGamesConfiguration } from '../style';
import { PrimaryButton } from '../../../../../atoms/Buttons';
import { StyledFakeLinkBorder } from '../../../../../atoms/Buttons/styles';
import SVGpartnerType from '../../../../../atoms/SVGIcons/SVGpartnerType';
import SVGpartnerQuestion from '../../../../../atoms/SVGIcons/SVGpartnerQuestion';
import { ITestTemplate } from '../../../../Sandbox/TestFactory';
import { Col } from 'react-styled-flexboxgrid';
import { FormInput } from '../../../../../atoms/Form/FormInput';
import { StyledCheckboxInput } from '../../../../../atoms/FormItems/styles';
import wrapIntl, { IWrapIntl } from '../../../../../atoms/wrapIntl';
import { BackgroundedText, SectionText } from '../../../../../atoms/Text';
import { Box } from '@xcorejs/ui';
import Fetch from '../../../../../atoms/Fetch';
import { TEST_TEMPLATE } from '../../../../../../config/apiRoutes';
import { TREE_TEST_ID } from '../../../TestIntro/data';
import LoadingSpinner from '../../../../../atoms/Animations/LoadingSpinner';
import { _switch } from '../../../../../../utils/option';

interface IConfirmation {
  surveys: string[];
  submit: (note: string) => any;
  back: () => any;
}

interface IConfirmationState {
  note: string;
  checkbox: boolean;
  accept: boolean;
  copied: boolean;
}

class Confirmation extends React.Component<IConfirmation & IWrapIntl, IConfirmationState> {
  public state: IConfirmationState = {
    note: '',
    checkbox: false,
    accept: false,
    copied: false,
  };

  public render() {
    const { surveys, formatHtml } = this.props;
    return (
      <Fetch url={TEST_TEMPLATE(TREE_TEST_ID)}>
        {_switch({
          success: (template: ITestTemplate) => (
            <Col xs={12} md={6}>
              <StyledGamesConfiguration>
                <Heading1>
                  <FormattedMessage id={'config.confirm.heading'} />
                </Heading1>

                <SmallerText>
                  <FormattedMessage id={'config.confirm.text'} />
                </SmallerText>

                <GameConfig>
                  <div>
                    <SVGpartnerType />
                    {template.name}
                  </div>
                  <div>
                    <SVGpartnerQuestion />
                    {template.surveys
                      .filter(s => surveys.includes(s.id))
                      .map(s => s.name)
                      .join(', ')}
                  </div>
                </GameConfig>

                <Box className={'back'} opacity={0.7}>
                  <FormattedMessage id={'config.invitation.bad'} />{' '}
                  <StyledFakeLinkBorder color='yellow' onClick={this.props.back}>
                    <FormattedMessage id={'config.confirm.back'} />
                  </StyledFakeLinkBorder>
                </Box>

                <BackgroundedText className={'confirmation-invite'}>
                  <FormInput
                    type='text'
                    text={formatHtml('config.confirm.note')}
                    value={this.state.note}
                    required={true}
                    onChange={e => this.setState({ note: e.target.value })}
                    id='note'
                    className={'note'}
                  />
                  <strong className={'link'}>
                    <FormattedMessage id={'config.invitation.heading'} />
                  </strong>

                  <Box mt='2rem'>
                    <SectionText color='#00E58D'>
                      <strong>
                        <FormattedMessage id={'config.invitation.warning'} />
                      </strong>
                    </SectionText>
                  </Box>

                  <Box opacity={0.7}>
                    <SmallerText>
                      <FormattedMessage id={'config.invitation.text'} />
                    </SmallerText>
                  </Box>

                  <div className={'acceptInvitation'}>
                    <StyledCheckboxInput>
                      <input
                        type='checkbox'
                        id='agree'
                        name='agree'
                        checked={this.state.checkbox}
                        onChange={e => this.setState({ checkbox: e.target.checked })}
                        required
                      />
                      <label htmlFor={'agree'}>
                        <span className={'fake-checkbox'} />
                        <FormattedMessage id={'config.invitation.checkbox'} />
                      </label>
                    </StyledCheckboxInput>
                  </div>
                </BackgroundedText>

                <PrimaryButton onClick={() => this.props.submit(this.state.note)} isUnvisible={!this.state.checkbox}>
                  <FormattedMessage id={'config.confirm.finish'} />
                </PrimaryButton>

                <SmallerText>
                  <FormattedMessage id={'config.confirm.continue'} />
                </SmallerText>
              </StyledGamesConfiguration>
            </Col>
          ),
          fetching: () => <LoadingSpinner />,
        })()}
      </Fetch>
    );
  }
}

export default wrapIntl(Confirmation);
