import { Box, Container, Flex, Grid, Heading2, Img, Link as UILink, Typography, useModal } from '@xcorejs/ui';
import LoadingSpinnerLocal from 'components/atoms/Animations/LoadingSpinnerLocal';
import Fetch from 'components/atoms/Fetch';
import { remainingDayTime } from 'components/atoms/ValuesAfterYear/remainingDaysCount';
import LifeValuesPresentingModal from 'components/components/modals/LifeValuesPresentingModal';
import { IWithLifeValues } from 'components/molecules/withRedux/withLifeValues';
import { createUrl } from 'components/pages/routes';
import { IValueTestResult, VALUE_TEST_RESULTS_TRIBAL } from 'config/api/valueTestResult';
import React, { FC, Fragment, ReactNodeArray, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';
import { _switch } from 'utils/option';
import { useFetchJournal } from 'utils/useQuery/queryFetchHelpers';
import { useFetchUser } from '../useFetchUser';
import ValueMapBox from './ValueMapBox';

interface MyValuesProps extends Pick<IWithLifeValues, 'lifeValues'> {
  randomState: number | undefined;
}

const MyValues: FC<MyValuesProps> = ({ randomState, lifeValues }) => {
  const user = useFetchUser();
  const [openLifeValuesPresentingModal] = useModal(LifeValuesPresentingModal);

  const journalFetch = useFetchJournal();

  const remainingDayTimeValue = useMemo(() => {
    if (journalFetch.isSuccess && journalFetch.data.mains.length > 0) {
      return remainingDayTime(journalFetch.data.mains[0].doneAt);
    }
    return -1;
  }, [journalFetch]);

  const journal = useMemo(() => {
    if (journalFetch.isSuccess) {
      return journalFetch.data;
    }
    return null;
  }, [journalFetch]);
    
  return ( 
    <Box
      pt={{ _: '4rem', sm: '8rem' }}
      pb={{ _: '6rem', sm: '8rem' }}
      key={randomState}
    >
      <Fade bottom ssrReveal duration={1000} distance='5rem'>
        <Container flexDirection='column' alignItems='center'>

          {journal ? (
            <Grid
              columns={{ _: 'auto', sm: '30rem auto' }}
              rows='auto'
              gap={{ _: '4rem', sm: '8.5rem' }}
              width='100%'
              maxWidth='79.5rem'
              alignItems='center'
            >
              <Flex flexDirection='column' alignItems='center'>
                <Img src='/img/my-values2.png' width='8rem' height='8rem' alt='' display={{ _: 'none', sm: 'flex' }} />

                {journal.myLifeValues ? (
                  <>
                    <Heading2 as='h1' mt={{ sm: '2rem' }}>
                      <FormattedMessage id='valueCenter.strip1.heading.lifeValues' />
                    </Heading2>

                    <Typography mt='1rem' textAlign='center' color='#fff' opacity={0.7}>
                      <FormattedMessage 
                        id='valueCenter.strip1.sub.lifeValues'
                        values={{
                          'odkaz': (...chunks: ReactNodeArray) => (
                            <UILink v='underline' onClick={openLifeValuesPresentingModal}>
                              {chunks}
                            </UILink>
                          ),
                        }}
                      />
                    </Typography>
                  </>
                ) :
                  !journal.mains.length || remainingDayTimeValue >= 0 ? (
                    <>
                      <Heading2 as='h1' mt={{ sm: '2rem' }}>
                        <FormattedMessage id='valueCenter.strip1.heading' />
                        {journal.mains.length > 0 && ' ' + new Date(journal.mains[0].doneAt * 1000).getFullYear()}
                      </Heading2>

                      {journal.mains.length > 0 ? (
                        <Typography mt='1rem' textAlign='center' color='#fff' opacity={0.7}>
                          <FormattedMessage id='valueCenter.strip1.sub.first' />
                          <br />

                          <UILink v='underline' onClick={openLifeValuesPresentingModal}>
                            <FormattedMessage id='valueCenter.strip1.sub2.first' />
                          </UILink>
                        </Typography>
                      ) : (
                        <Typography mt='1rem' textAlign='center' color='#fff' opacity={0.7}>
                          <FormattedMessage id='valueCenter.strip1.sub' />
                          <br />

                          <UILink v='underline' onClick={openLifeValuesPresentingModal}>
                            <FormattedMessage id='valueCenter.strip1.sub2' />
                          </UILink>
                        </Typography>
                      )}
                    </>
                  ) : (
                    <>
                      <Heading2 as='h1' mt={{ sm: '2rem' }}>
                        <FormattedMessage id='valueCenter.strip1.heading2' />
                      </Heading2>

                      <Typography mt='1rem' textAlign='center' color='#fff' opacity={0.7}>
                        <FormattedMessage id='valueCenter.strip1.sub.0days' />
                        <br />

                        <UILink v='underline' onClick={openLifeValuesPresentingModal}>
                          <FormattedMessage id='valueCenter.strip1.sub.0days2' />
                        </UILink>
                      </Typography>
                    </>
                  )
                }

                <Flex flexDirection='column' alignItems='center' mt='4rem' display={{ _: 'none', sm: 'flex' }}>
                  <Img src='/img/time.svg' width='6rem' height='6rem' mx='auto' alt='' />

                  <Box mt='1rem'>
                    {user && user.eticonName !== null ? (
                      <Fragment>
                        <Typography color='yellowWhite' textAlign='center'>
                          <FormattedMessage id='valueCenter.strip1.timeLeft' />
                        </Typography>

                        <Typography fontSize='2rem' lineHeight='3rem' color='yellow'>
                          {remainingDayTimeValue >= 0 ?
                            remainingDayTimeValue
                            : '0'
                          }
                          {' '}
                          <FormattedMessage id='valueCenter.strip1.timeLeftDays' />
                        </Typography>


                        {/* eslint-disable-next-line max-len */}
                        {/*
                              {Math.floor(365 - (Date.now() - journal.mains[0].doneAt * 1000) / (24 * 60 * 60 * 1000)) >= 0 ? '' : (
                                <Flex justifyContent='center' mt='1rem'>
                                  <Button as='a' href={`/cs/app/hra-intro?t=${ONE_ROUND_TEST_ID}&redirect=0`}>
                                    Vytvořit žebříček pro tento rok
                                  </Button>
                                </Flex>
                              )}
                              */}

                        <Box mt='4rem'>
                          <Link to={createUrl('app.journal')}>
                            <Typography color='#e7e6cf' opacity={0.7}>
                              <FormattedMessage id='valueCenter.strip1.journal' />
                              <UILink v='underline'>
                                <FormattedMessage id='valueCenter.strip1.journal2' />
                              </UILink>
                            </Typography>
                          </Link>
                        </Box>
                      </Fragment>
                    ) : (
                      <Typography color='yellowWhite' textAlign='center'>
                        <FormattedMessage id='valueCenter.strip1.afterYear' />
                      </Typography>
                    )}
                  </Box>
                </Flex>
              </Flex>

              <Flex flexDirection='column'>
                <ValueMapBox journal={journal} lifeValues={lifeValues} remainingDayTimeValue={remainingDayTimeValue} />

                <Flex flexDirection='column' alignItems='center' mt='4rem' display={{ _: 'flex', sm: 'none' }}>
                  <Img src='/img/20_zivotni.png' width='6rem' height='6rem' mx='auto' alt='' />

                  <Box mt='1rem'>
                    {user && user.eticonName !== null ? (
                      // TODO: přepsat do react-query
                      <Fetch url={VALUE_TEST_RESULTS_TRIBAL}>
                        {_switch({
                          success: (tribal: IValueTestResult) => {
                            const remainingTribalDayTimeValue = remainingDayTime(tribal.doneAt);
                                    
                            return (
                              <Fragment>
                                <Typography color='yellowWhite' textAlign='center'>
                                  <FormattedMessage id='valueCenter.strip1.timeLeft' />
                                </Typography>

                                <Typography fontSize='2rem' lineHeight='3rem' color='yellow'>
                                  {/* eslint-disable-next-line max-len */}
                                  {remainingTribalDayTimeValue >= 0 
                                    ? remainingTribalDayTimeValue
                                    : '0'
                                  }
                                  {' '}
                                  <FormattedMessage id='valueCenter.strip1.timeLeftDays' />
                                </Typography>

                                <Box mt='4rem'>
                                  <Link to={createUrl('app.journal')}>
                                    <Typography color='#e7e6cf' opacity={0.7}>
                                      <FormattedMessage id='valueCenter.strip1.journal' />
                                      <UILink v='underline'>
                                        <FormattedMessage id='valueCenter.strip1.journal2' />
                                      </UILink>
                                    </Typography>
                                  </Link>
                                </Box>
                              </Fragment>
                            );},
                          fetching: () => <LoadingSpinnerLocal />,
                        })()}
                      </Fetch>
                    ) : (
                      <Typography color='yellowWhite' textAlign='center'>
                        <FormattedMessage id='valueCenter.strip1.afterYear' />
                      </Typography>
                    )}
                  </Box>
                </Flex>
              </Flex>
            </Grid>
          ) : <LoadingSpinnerLocal />}

        </Container>
      </Fade>
    </Box>
  );
};
 
export default MyValues;
