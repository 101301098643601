import * as React from 'react';
import HelpPopupBody from '../../../atoms/Popups/HelpPopup/HelpPopupBody';
import HelpPopupFooter from '../../../atoms/Popups/HelpPopup/HelpPopupFooter';
import HelpPopup from 'components/atoms/Popups/HelpPopup';
import { SecondaryButton } from 'components/atoms/Buttons';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { PopupComponent } from '../../../../store/Popup/index';

interface InfoPopup {
  title?: string;
  button?: string | null;
  resolve?: () => unknown;
  className?: string;
}

const InfoPopup: React.FC<InfoPopup> = ({ children, title, button, resolve, className }) => {
  return (
    <HelpPopup title={title} onClose={resolve} className={className}>
      <HelpPopupBody>{children}</HelpPopupBody>
      {button !== null && (
        <HelpPopupFooter>
          <SecondaryButton onClick={resolve}>
            <FormattedMessage id={button ? button : 'popup.default.button'} />
          </SecondaryButton>
        </HelpPopupFooter>
      )}
    </HelpPopup>
  );
};

export interface IInfoPopup {
  content: string;
  title?: string;
  button?: string | null;
}

export const htmlInfoPopup = (data: IInfoPopup): PopupComponent => ({ resolve }) => (
  <InfoPopup title={data.title} button={data.button} resolve={resolve}>
    <FormattedHTMLMessage id={data.content} />
  </InfoPopup>
);

export const textInfoPopup = (data: IInfoPopup): PopupComponent => ({ resolve }) => (
  <InfoPopup title={data.title} button={data.button} resolve={resolve}>
    <FormattedMessage id={data.content} />
  </InfoPopup>
);

export interface IRawInfoPopup {
  content: React.ComponentType;
  title?: string;
  button?: string | null;
  className?: string;
}

export const rawInfoPopup = (data: IRawInfoPopup): PopupComponent => ({ resolve }) => (
  <InfoPopup title={data.title} button={data.button} resolve={resolve} className={data.className}>
    <data.content />
  </InfoPopup>
);
