import { IRescource, rescource, isReadable } from 'utils/rescource';

export interface IDatingInfo {
  interestSex: 0 | 1; // man = 0, woman = 1
  interestType: 0 | 1 | 2; // relation = 0, friendship = 1, other = 2
  interestAgeFrom: number;
  interestAgeTo: number;
  description: string | null;
  photo: string | null;
}

export interface IUserInfo {
  id: string;
  email: string;
  yearOfBirth: number;
  city: string;
  sex: number;
  workType: number;
  education: number;
  maritalStatus: number;
  eticoinsCount: number;
  eticonId: string;
  eticonName: string;
  tribalTestId: string;
  fullname: string;
  nick: string;
  internalId: number;
  avatar: string;
  level: number;
  xp: number;
  infoLevel: number;
  country: {
    id: string;
    name: string;
  };
  region: {
    id: string;
    name: string;
  };
  loginEmail: string;
  groups: string[];
  eticiaPlus: boolean;
  eticiaPlusForever: boolean;
  datingInfo: IDatingInfo | null;
  child: boolean;
}

export interface IDemoUserInfo {
  id: string;
  email: string;
  yearOfBirth: number;
  city: string;
  sex: number;
  workType: number;
  education: number;
  maritalStatus: number;
  eticoinsCount: number;
  eticonId: string;
  eticonName: string;
  tribalTestId: string;
  fullname: string;
  nick: string;
  internalId: number;
  avatar: string;
  level: number;
  xp: number;
  infoLevel: number;
  country: {
    id: string;
    name: string;
  };
  work: number;
  region: {
    id: string;
    name: string;
  };
  loginEmail: string;
  groups: string[];
  eticiaPlus: boolean;
  eticiaPlusForever: boolean;
  child: boolean;
  datingInfo: IDatingInfo | null;
}

export interface IProfileErrors {
  Sex?: string[];
  YearOfBirth?: string[];
  City?: string[];
  MaritalStatus?: string[];
  Education?: string[];
  Country?: string[];
}

export interface IDemoProfileErrors {
  YearOfBirth?: string[];
  City?: string[];
  MaritalStatus?: string[];
  Country?: string[];
  Region?: string[];
  Work?: string[];
}

export interface IUserState extends IRescource<IUserInfo> {
  isAuthed: boolean;
  fullProfile: boolean;
}

export const userInitState: IUserState = {
  ...rescource('init', null),
  isAuthed: false,
  fullProfile: false,
};

export const isAuthed = (res: IUserState) => isReadable(res) && res.isAuthed;
