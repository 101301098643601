import { Box, Modal } from '@xcorejs/ui';
import { createUrl } from 'components/pages/routes';
import { BlueTheme } from 'components/theme/colors';
import * as React from 'react';
import { FC } from 'react';
import styled from 'styled-components';
  
const GameGuideModal: FC = () => {

  const link = createUrl('valueGame');
  
  return (
    <Modal
      background={BlueTheme.primary}
      p={{ _: '0', sm: '0' }}
      overflowX='hidden'
      height='auto'
      _close={{
        zIndex: 100,
        position: 'absolute'
      }}
      maxWidth='45rem'
    >
      <BoxStyle>
        <iframe
          src={link}
          width='100%'
          height='100%'
          frameBorder='0'
        />
      </BoxStyle>
    </Modal>
  );
};
  
export default GameGuideModal;
  
const BoxStyle = styled(Box)`
  iframe {
    margin-top: -6rem;
    margin-bottom: -0.4rem;
    height: 100vh;
    max-height: calc(100vh - 6rem);
    /* padding: 0; */
  }
`;