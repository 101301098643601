import styled from 'styled-components';
import { yellow, gray } from '../../../theme/colors';
import { mediaLandscapePhone, mediaMdDefault } from '../../../theme/grid';

export const ProgressLine = styled.div`
  width: 100%;
  text-align: left;
  margin-top: 0;

  ${mediaMdDefault} {
    max-width: 30rem;
  }
`;

export const ProgressLineText = styled.div`
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: ${gray};
  line-height: 1.8rem;
  font-size: 1.6rem;
  text-align: center;

  ${mediaLandscapePhone} {
    font-size: 1.4rem;
    line-height: 1.7rem;
  }

  ${mediaMdDefault} {
    font-size: 1.8rem;
  }
`;

export const ProgressBar = styled.div`
  display: none;

  ${mediaMdDefault} {
    position: relative;
    display: block;
    width: 100%;
    height: 0.8rem;
    border-radius: 0.6rem;
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

interface IProgressLine {
  progress: number;
}

export const ProgressBarLine = styled.div<IProgressLine>`
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: ${props => props.progress * 100}%;
  height: 0.8rem;
  border-radius: 0.6rem;
  background-color: ${yellow};
`;

interface IProgressPoint {
  progress: number;
  passed: boolean;
}

export const ProgressPoint = styled.span<IProgressPoint>`
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  background-color: white;
  opacity: ${props => (props.passed ? 1 : 0.5)};
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  left: ${props => props.progress * 100}%;

  &:before {
    content: "";
    position: absolute;
    top: -0.8rem;
    left: 0.3rem;
    display: block;
    width: 0.2rem;
    height: 2.4rem;
    background-color: white;
  }
`;
