import * as React from 'react';

interface IAnchorLink {
  duration: number;
  offset?: number | (() => number);
}

type AnchorProps = React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>;

const ease = (t: number) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t);

class AnchorLink extends React.Component<AnchorProps & IAnchorLink> {
  public render() {
    return (
      <a {...this.props} className={'anchor-link'} onClick={this.smoothScroll}>
        <span>{this.props.children}</span>
      </a>
    );
  }
  private smoothScroll = (e: React.MouseEvent<HTMLAnchorElement>) => {
    const { offset, duration } = this.props;
    e.preventDefault();

    offset === undefined ? 0 : typeof offset === 'function' ? offset() : offset;

    const el = document.querySelector<HTMLElement>(this.props.href!);
    const pos = window.pageYOffset;
    const target = el!.getBoundingClientRect().top;

    Array(duration / 10)
      .fill(null)
      .map((_, i) =>
        setTimeout(() => {
          window.scrollTo(0, pos + target * ease(i / (duration / 10)));
        }, 10 * i),
      );

    if (this.props.onClick) {
      this.props.onClick(e);
    }
  };
}

export default AnchorLink;
