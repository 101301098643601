import { Button, Flex, Grid, Heading2, Icon, Img, Modal, Paragraph, Tag, Typography } from '@xcorejs/ui';
import { GameContext } from 'components/App';
import DelayedAccordion from 'components/atoms/Accordion/DelayedAccordion';
import CustomSelect from 'components/atoms/Select';
import { CompareConfig } from 'components/components/Compare/data';
import ComparisonMoreInfo from 'components/organisms/Comparison/ComparisonMoreInfo';
import { BlueTheme, PlusTheme, yellow, yellowLighter } from 'components/theme/colors';
import { OliLight, OliMedium, OliRegular } from 'components/theme/fonts';
import * as React from 'react';
import { FC, Fragment, useContext, useState } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import Fade from 'react-reveal/Fade';
import { Option } from 'react-select';
import { CompareIcon } from '../../icons/16/compare.svg';
import { helperFilterFunction } from './helpers';
import { useGetCompareMatrix, useGetDebouncedTarget, useGetFilteredMatrix, useHandleFirstRender, useSetDefaultLadder } from './hooks';
import { HigherZIndex, SelectStyle, StyledFade } from './style';

export interface LaddersCompareMatrixModalProps {
  mainLadderId?: string;
  secondaryLadderId?: string;
  close: () => void;
}

const LaddersCompareMatrixModal: FC<LaddersCompareMatrixModalProps> = ({
  mainLadderId, 
  secondaryLadderId,
  close,
}) => {
  const { eticiaPlus } = useContext(GameContext);
  const [active, setActive] = useState<Option>();
  const [activeTarget, setActiveTarget] = useState<CompareConfig>();
  const compareMatrix = useGetCompareMatrix();
  const filteredMatrix = useGetFilteredMatrix(compareMatrix, active);
  const { changingActiveTarget, delayedActiveTarget } = useGetDebouncedTarget(activeTarget);
  useHandleFirstRender({ filteredMatrix, secondaryLadderId, setActiveTarget });
  useSetDefaultLadder({ compareMatrix, mainLadderId, setActive });

  const selectLadder = (option: Option) => {
    if (!compareMatrix) return;

    setActive(option);
    setActiveTarget(helperFilterFunction(compareMatrix, option.value).targets[0]);
  };

  return (
    <Modal
      background={BlueTheme.primary}
      overflowX='hidden'
      p={{ _: '4rem 1.6rem', sm: '4rem 4rem' }}
      maxWidth='63rem'
      minHeight='550px'
      _close={{
        zIndex: 9999,
      }}
    >
      {!eticiaPlus && (
        <DelayedAccordion
          delay={2000}
          wrapperProps={{
            width: { _: 'calc(100% + 3.2rem)', sm: 'calc(100% + 8rem)' },
            position: 'relative',
            top: '-4rem',
            left: { _: '-1.6rem', sm: '-4rem' },
            backgroundColor: PlusTheme.primary,
          }}
          justifyContent='center'
          alignItems='center'        
        >
          <Paragraph color={PlusTheme.text} paddingY='3rem'>
            <FormattedHTMLMessage id='compareMatrix.modal.eticiaPlus' />
          </Paragraph>
        </DelayedAccordion>
      )}

      <Flex justifyContent='center' alignItems='center' position='relative'>
        <Heading2>
          <FormattedMessage id='compareMatrix.modal.title' />
        </Heading2>
      </Flex>

      {/* kvůli Fade komponentě tu musí být HigherZIndex, jinak se zobrazuje select pod obrázky o pár řádků níž */}
      <HigherZIndex>
        <Fade delay={500} position='relative' zIndex={9999}> 
          <SelectStyle maxWidth='34.4rem' mx='auto' marginTop='1rem' width='100%' cursor='pointer'>
            <CustomSelect
              onChange={(option) => option && selectLadder(option)}
              placeholder={active ? active.label : 'Vyberte...'} //TODO: placeholder text podle lokalizace
              searchable={false}
              options={compareMatrix ? compareMatrix
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((c) => {
                  return {
                    value: c.id,
                    label: c.name,
                  };
                }) : [{ id: '', label: '' }]}
              required
            />
          </SelectStyle>
        </Fade>
      </HigherZIndex>

      {compareMatrix && (
        <Fragment>
          <Fade delay={1000}>
            <Paragraph mt='4rem' color='#fff'>
              <FormattedMessage id='compareMatrix.modal.compare.title' />
            </Paragraph>
          </Fade>

          <Fade delay={1500}>
            <Flex flexWrap='wrap' justifyContent='center' mt={{ _:'1rem' }} style={{ gap: '1rem' }}>
              {filteredMatrix && filteredMatrix.targets.map((t, index) => (
                <Grid
                  key={index}
                  columns='1fr'
                  rows='5rem 1fr'
                  gap='0.5rem 0'
                  px={{ _: '1.2rem', sm:'1.6rem' }}
                  py='1rem'
                  border='2px solid'
                  borderRadius='4px'
                  borderColor={activeTarget === t ? 'yellow' : '#fff'}
                  boxShadow={activeTarget === t ? 'inset 0px 0px 5px 2px yellow' : 'none'}
                  flexDirection='column'
                  alignItems='start'
                  justifyItems='center'
                  width={{ _: '10rem', sm: '12rem' }}
                  onClick={() => setActiveTarget(t)}
                  cursor='pointer'
                  _hover={{
                    borderColor: 'yellow'
                  }}
                >
                  {t.templateB.icon && <Img src={t.templateB.icon} mb='0.5rem' width={50} height={50} alt='' />}
                  <Typography color='#fff' fontSize='1.3rem' lineHeight='1.8rem' fontFamily={OliLight}>
                    {t.templateB.name}
                  </Typography>
                </Grid>
              ))}
            </Flex>
          </Fade>
        </Fragment>
      )}
      
      {activeTarget && delayedActiveTarget && (
        <StyledFade delay={1500}>
          <Flex
            as='section'
            mt='4rem'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
          >
            <Tag
              color='rgba(255, 255, 255, 0.7)'
              border='1px solid rgba(255, 255, 255, 0.3)'
              borderRadius='0.5rem'
              background={BlueTheme.extraDark}
              fontFamily={OliRegular}
              padding='0.7rem 1.5rem'
              lineHeight='1'
              opacity={changingActiveTarget ? 0.7 : 1}
              transition='opacity 0.5s ease-in-out'
            >
              <FormattedMessage id='compareMatrix.modal.compare.subtitle' />
            </Tag>
          
            <Grid
              columns='1fr 4rem 1fr'
              rows='auto'
              gap='1rem'
              marginTop={{ _: '1rem', sm:'2rem' }}
              alignItems='center'
              justifyItems='center'
              opacity={changingActiveTarget ? 0 : 1}
              transition='opacity 0.5s ease-in-out'
            >
              <Paragraph 
                color={yellow}
                textAlign='right'
                justifySelf='end'
                fontFamily={OliMedium}>
                {delayedActiveTarget.templateA.name}
              </Paragraph>

              <Icon
                svg={<CompareIcon />}
                fill={yellowLighter}
                width='2.5rem'
                height='2.5rem'
                alignSelf='center'
                border={'1px solid ' + yellowLighter}
                borderRadius='9999px'
                display='inline-flex'
                alignItems='center'
                justifyContent='center' />

              <Paragraph 
                color={yellow}
                textAlign='left'
                justifySelf='start'
                fontFamily={OliMedium}>
                {delayedActiveTarget.templateB.name}
              </Paragraph>
            </Grid>

            <Paragraph
              as='div'
              mt='1rem'
              color='#fff'
              opacity={changingActiveTarget ? 0 : 1}
              transition='opacity 0.5s ease-in-out'
              textAlign='center'
              fontSize={{ _: '1.3rem', sm: '1.5rem' }}
              lineHeight={{ _: '1.5rem', sm: '2rem' }}
            >
              {/* //! než bude běhat info o linku z backu tak se nastavuje odkaz uvnitř popisku
                  //! jednodušší než vysvětlovat Honzovi jak se dá rozdělit string a tahat si z něj link */}
              <p dangerouslySetInnerHTML={{ __html: delayedActiveTarget.mainDescription }} />
              {/* {delayedActiveTarget.mainDescription} */}
            </Paragraph>

            <ComparisonMoreInfo 
              summaryDescription={delayedActiveTarget.chartDescription}
              summaryHeader={delayedActiveTarget.chartHeader}
              changingTarget={changingActiveTarget}
            />

            <Button
              onClick={() => close()}
              marginTop='2rem'
              height='auto'
            >
              <FormattedMessage id='compareMatrix.modal.close' />  
            </Button>
          </Flex>
        </StyledFade>
      )}
    </Modal>
  );
};

export default LaddersCompareMatrixModal;


