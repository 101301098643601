import * as React from 'react';
import { FinalMatch } from '../../../../../organisms/PartnerTest/Result/styles';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { IProfile, IValueTestCompare } from '../data';
import { Col, Row } from 'react-styled-flexboxgrid';
import { CustomText, SectionText } from '../../../../../atoms/Text';
import { Heading2 } from '../../../../../atoms/Headings';
import { StyledResultBoxes } from '../../../../../molecules/TestResult/AnswerBox/styles';
import PopupIcon from '../../../../../organisms/MainPopup/PopupIcon';
import { IValueResult } from 'config/api/valueTestResult';
import ValueMap from '../../../../../molecules/ValueMap';
import { Box } from '@xcorejs/ui';
import FAQPopup from '../../../../../atoms/Popups/FAQPopup';
import { TableValuesConnection, WhatNext } from '../../../../../atoms/Popups/FAQPopup/popups';
import PieChart from '../../../../../atoms/Pie';
import { Flex } from '@xcorejs/ui';
import Fade from 'react-reveal/Fade';
import { StyledFakeLinkBorder } from '../../../../../atoms/Buttons/styles';
import { createUrl } from '../../../../routes';
import { Link } from 'react-router-dom';
import { IStringMap } from '../../../../../../utils';
import withPopups, { IWithPopups } from '../../../../../molecules/withRedux/withPopups';
import ValueMapFull from 'components/molecules/ValueMap/types/ValueMapFull';

interface IData {
  valueCompare: IValueTestCompare;
  allLifeValues: IValueResult[];
  profiles: IStringMap<IProfile>;
}

const TestResultFinal: React.FC<IData & IWithPopups> = ({ valueCompare, allLifeValues, profiles }) => {
  const matches = [valueCompare.valueMatch, valueCompare.characterMatch, valueCompare.practicalMatch];
  return (
    <>
      <StyledResultBoxes className='together'>
        <Row>
          <Col xs={12} md={6} lg={5} lgOffset={1}>
            <Fade bottom ssrReveal duration={1000} distance={'5rem'}>
              <div>
                <Heading2 color='yellow'>
                  <FormattedMessage id={'results.partners.final.yourValues'} />
                </Heading2>

                <CustomText>
                  <FormattedMessage id={'results.partners.final.mostImportantValues'} />
                </CustomText>

                <Box position='relative' id='capture' mt='3rem'>
                  <ValueMap theme='forTwo' imagePath=''>
                    <ValueMapFull
                      theme='forTwo'
                      lifeValues={[
                        ...valueCompare.valueMatchAList,
                        ...valueCompare.valueMatchBList.slice(valueCompare.valueMatchAList.length, 10),
                      ].map((valueId, rank) => ({
                        ...allLifeValues.find(value => value.valueId === valueId)!,
                        rank,
                      }
                      ))}
                      profiles={profiles}/>
                  </ValueMap>
                  {/*
                  <Box position="absolute" left="50%" mt="-2.6rem" width="100%" transform="translateX(-50%)">
                    <StyledSmallButton
                      borderColor="blue"
                      bgColorHEX="#191D2C"
                      hoverColorHEX="#247BCB"
                      style={{
                        fontSize: "1.6rem",
                        paddingLeft: "5rem",
                        paddingRight: "5rem",
                      }}
                    >
                      <FormattedMessage id="valuemap.save" />
                    </StyledSmallButton>
                  </Box>
                  */}
                </Box>
              </div>
            </Fade>
          </Col>

          <Col xs={12} md={6} lg={5}>
            <Fade bottom ssrReveal duration={1000} distance={'5rem'} delay={200}>
              <Box>
                <Heading2 color='blue'>
                  <FormattedMessage id={'results.partners.final.characters'} />
                </Heading2>

                <CustomText>
                  <p>
                    <FormattedMessage id={'results.partners.final.characters.text'} />
                  </p>
                </CustomText>
              </Box>

              <FinalMatch>
                {matches
                  .map((value, index) => ({ value, index }))
                  .sort((a, b) => b.value - a.value)
                  .map((match, index) => (
                    <Flex key={index}>
                      <PieChart
                        number={match.value}
                        color={
                          index === 0
                            ? 'rgba(227, 210, 64, 0.25)'
                            : index === 1
                              ? 'rgba(0, 153, 102, 0.25)'
                              : 'rgba(36, 123, 203, 0.25)'
                        }
                        colorInside={index === 0 ? '#ffde71' : index === 1 ? '#009966' : '#247BCB'}
                      />

                      <div>
                        <strong className='heading'>
                          {index + 1 + '. '}
                          <FormattedMessage id={'results.partners.final.characters.subheading' + (match.index + 1)} />
                        </strong>

                        <p className={'i' + index}>
                          {match.value <= 55 ? (
                            <FormattedHTMLMessage
                              id={'results.partners.final.characters.text' + (match.index + 1) + '.low'}
                              values={{ percantage: match.value.toFixed(1) }}
                            />
                          ) : match.value <= 65 ? (
                            <FormattedHTMLMessage
                              id={'results.partners.final.characters.text' + (match.index + 1) + '.mid'}
                              values={{ percantage: match.value.toFixed(1) }}
                            />
                          ) : match.value <= 75 ? (
                            <FormattedHTMLMessage
                              id={'results.partners.final.characters.text' + (match.index + 1) + '.high'}
                              values={{ percantage: match.value.toFixed(1) }}
                            />
                          ) : (
                            <FormattedHTMLMessage
                              id={'results.partners.final.characters.text' + (match.index + 1) + '.superHigh'}
                              values={{ percantage: match.value.toFixed(1) }}
                            />
                          )}
                        </p>
                      </div>
                    </Flex>
                  ))}
              </FinalMatch>

              <Box mt='4rem'>
                <FAQPopup popup={TableValuesConnection}>
                  <StyledFakeLinkBorder color='yellow'>
                    <FormattedMessage id={'results.partners.final.characters.table'} />
                  </StyledFakeLinkBorder>
                </FAQPopup>
              </Box>
            </Fade>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={6} mdOffset={3}>
            <Fade bottom ssrReveal duration={1000} distance={'5rem'}>
              <Box mt='2rem'>
                <Heading2 color='green'>
                  <FormattedMessage id='results.partners.final.whatElse.heading' />
                </Heading2>
              </Box>

              <Box my='2rem'>
                <CustomText style={{ maxWidth: 'none' }}>
                  <FormattedHTMLMessage id='results.partners.final.whatElse.text' />
                </CustomText>
              </Box>

              <Box mx='auto' my='2rem' display='block'>
                <FAQPopup popup={WhatNext}>
                  <SectionText color='rgba(227, 210, 64, 0.8)'>
                    <FormattedMessage id='results.partners.final.whatElse.modal1' />
                  </SectionText>
                </FAQPopup>
                <br />

                <Link to={createUrl('app.comparison')}>
                  <SectionText color='rgba(227, 210, 64, 0.8)' style={{ marginTop: '1rem' }}>
                    <FormattedMessage id='results.partners.final.whatElse.modal2' />
                  </SectionText>
                </Link>
              </Box>
            </Fade>
          </Col>
        </Row>
      </StyledResultBoxes>

      <PopupIcon text={'partnerResult.helpPopup.final'} />
    </>
  );
};

export default withPopups(TestResultFinal);
