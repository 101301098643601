import * as React from 'react';
import { useTheme, IThemeProps } from '../../../templates/General/ThemeContext';
import { RouteComponentProps, withRouter } from 'react-router';
import LegacyMenu from './LegacyMenu';
import Menu from './Menu';

const Header: React.FC<IThemeProps & RouteComponentProps<any>> = ({ theme, location }) =>
  theme.data.legacyMenu ? <LegacyMenu /> : <Menu theme={theme} location={location.pathname} />;

export default withRouter(useTheme(Header));
