import * as React from 'react';
import { Heading2 } from '../../../atoms/Headings';
import TextBox from '../../../organisms/TextBox';
import { FormattedMessage } from 'react-intl';
import Fade from 'react-reveal/Fade';
import { StyledDiscover } from './styles';
import { Col, Row } from 'react-styled-flexboxgrid';
import { SectionText } from '../../../atoms/Text';

const Discover: React.FC = () => (
  <StyledDiscover>
    <TextBox className='dark-blue'>
      <Fade ssrReveal bottom duration={1000} distance='5rem'>
        <Heading2>
          <FormattedMessage id='vlc.discover.heading' />
        </Heading2>

        <SectionText color='rgba(255, 255, 255, 0.4)'>
          <FormattedMessage id='vlc.discover.text' />
        </SectionText>

        <Row className='boxes'>
          {[...Array(6)].map((i, index) => (
            <Col xs={12} sm={4} lg={2} key={i}>
              <img src={'/img/vlc/discover/' + index + '.svg'} alt='' />
              <FormattedMessage id={'vlc.discover.box.' + index + '.text'} />
            </Col>
          ))}
        </Row>
      </Fade>
    </TextBox>
  </StyledDiscover>
);

export default Discover;
