import * as React from 'react';
import { ICurrentRound } from '../data';
import { useTheme, IThemeProps } from '../../../templates/General/ThemeContext';
import { _switch } from '../../../../utils/option';

interface IBackgroundWrapper {
  round: ICurrentRound;
  customTheme: string | undefined;
  customColorTheme?: string;
}

const colors = ['blue', 'green', 'violet'];
const randomColor = () => colors[Math.floor(Math.random() * 3)];

class BackgroundWrapper extends React.Component<IBackgroundWrapper & IThemeProps> {

  public componentDidMount() {
    this.props.theme.setTheme(
      this.props.customColorTheme ? { background: this.props.customColorTheme } : this.props.customTheme
        ? { background: this.props.customTheme }
        :
        _switch({
          values: {
            background:
            this.props.round.type === 'final'
              ? this.props.round.step === 2
                ? 'violet'
                : 'blue'
              : randomColor(),
          },
          friend: { background: 'green' },
          partner: { background: 'red' },
          dating: { background: 'darlingTheme' }
        })()(this.props.round.theme)
    );
  }

  public render() {
    return React.Children.only(this.props.children);
  }
}

export default useTheme(BackgroundWrapper);
