import * as React from 'react';
import Placeholder from './Cards/Placeholder';

interface ICardWrapper {
  item: any;
  label: string;
  color: string;
}

const CardWrapper: React.FC<ICardWrapper> = ({ item, label, children, color }) =>
  !item ? <Placeholder label={label} color={color} /> : <>{children}</>;

export default CardWrapper;
