/* eslint-disable no-extra-parens */
import { useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import * as React from 'react';
import wrapIntl, { IWrapIntl } from '../../atoms/wrapIntl';
import { FormattedMessage } from 'react-intl';
import { StyledLink } from '../../atoms/Buttons/styles';
import { createUrl } from '../routes';
import { IRegisterUserErrors, IRegisterUserVm } from './component';
import InlineFormInput from '../../atoms/InlineForm/InlineFormInput';
import InlineFormSelect from '../../atoms/InlineForm/InlineFormSelect';
import { Option } from 'react-select';
import Popup from './Popup';
import withPopups, { IWithPopups } from '../../molecules/withRedux/withPopups';
import { Box, Flex, Link, Typography } from '@xcorejs/ui';
import { PrimaryButton } from 'components/components/Button';
import { CheckboxInputStyle, CheckedIconStyle } from 'components/pages/App/Shop/Buy';

interface IRegisterForm {
  errors: IRegisterUserErrors;
  sendRegistration: (user: IRegisterUserVm) => unknown;
  popup: (body: React.ComponentType, title: string, button?: string) => unknown;
  knownUserInfo: IRegisterUserVm | null;
  child: boolean;
  urlCountry?: string | null;
}

const RegisterForm: React.FC<IWrapIntl & IRegisterForm & IWithPopups> = ({
  formatMsg,
  sendRegistration,
  popups,
  errors,
  knownUserInfo,
  child,
  urlCountry,
}) => {
  const [user, setUser] = React.useState<IRegisterUserVm>({
    name: knownUserInfo && knownUserInfo.name ? errors.Name ? '' : knownUserInfo.name : '',
    nick: knownUserInfo && knownUserInfo.nick ? errors.Nick ? '' : knownUserInfo.nick : '',
    email: knownUserInfo && knownUserInfo.email ? errors.Email ? '' : knownUserInfo.email : '',
    password: knownUserInfo && knownUserInfo.password ? errors.Password ? '' : knownUserInfo.password : '',
    femalePronouns: knownUserInfo && knownUserInfo.femalePronouns ? knownUserInfo.femalePronouns : undefined,
    child: knownUserInfo && knownUserInfo.child ? knownUserInfo.child : false,
    countryId: urlCountry || (knownUserInfo && knownUserInfo.countryId ? knownUserInfo.countryId : '561962a8-5959-4321-ac01-d5e2e7d06cf9'),
  });
  const [agree, setAgree] = React.useState<boolean | 'error'>(false);

  useEffect(() => {
    setUser({ ...user, child });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [child]);

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        agree ? sendRegistration(user) : setAgree('error');
      }}
    >
      <Fade ssrReveal bottom duration={1000} distance={'5rem'}>
        <Box>
          <Box maxWidth='41.6rem' mx='auto'>
            {/*
            <Flex flexDirection='column' mt='3.2rem'>
              <Typography mb='0.8rem' textAlign='center' color='rgba(255, 255, 255, 0.6)'>
                <FormattedMessage id='registrationInline.name' />
              </Typography>

              <InlineFormInput
                name='name'
                placeholder={formatMsg('registration.name')}
                error={errors.Name && errors.Name[0]}
                value={user.name}
                input={name => setUser({ ...user, name })}
              />
            </Flex>
            */}

            <Flex flexDirection='column' mt='3.2rem'>
              <Typography mb='0.8rem' textAlign='center' color='rgba(255, 255, 255, 0.6)'>
                Země
              </Typography>

              <InlineFormSelect
                name='countryId'
                error={errors.countryId}
                value={user.countryId}
                options={[
                  { label: formatMsg('registration.choose'), value: -1 },
                  {
                    value: '561962a8-5959-4321-ac01-d5e2e7d06cf9',
                    label: 'Česká Republika'
                  },
                  {
                    value: '23f0acf4-81b6-4553-8bf4-46bac3e6485a',
                    label: 'Slovenská Republika'
                  },
                ]}
                disabled={[-1]}
                onChange={opt =>
                  setUser({
                    ...user,
                    countryId: (opt as Option).value as string,
                  })
                }
              />
            </Flex>

            <Flex flexDirection='column' mt='3.2rem'>
              <Typography mb='0.8rem' textAlign='center' color='rgba(255, 255, 255, 0.6)'>
                <FormattedMessage id='registrationInline.nick' />
              </Typography>

              <InlineFormInput
                required
                name='nick'
                placeholder={formatMsg('registration.nick')}
                error={errors.Nick && errors.Nick[0]}
                value={user.nick}
                input={nick => setUser({ ...user, nick })}
              />

              <Typography color='red' mt='0.5rem' textAlign='center'>{errors.Nick && errors.Nick[0]}</Typography>
            </Flex>

            <Flex flexDirection='column' mt='3.2rem'>
              <Typography mb='0.8rem' textAlign='center' color='rgba(255, 255, 255, 0.6)'>
                <FormattedMessage id='registrationInline.email' />
              </Typography>

              <InlineFormInput
                required
                type='email'
                name='email'
                placeholder={formatMsg('registration.email')}
                error={errors.Email && errors.Email[0]}
                value={user.email}
                input={email => setUser({ ...user, email })}
              />
            </Flex>

            <Flex flexDirection='column' mt='3.2rem'>
              <Typography mb='0.8rem' textAlign='center' color='rgba(255, 255, 255, 0.6)'>
                <FormattedMessage id='registrationInline.password' />
              </Typography>

              <InlineFormInput
                required
                type='password'
                name='password'
                placeholder={formatMsg('registration.password')}
                error={errors.Password && errors.Password[0]}
                value={user.password}
                input={password => setUser({ ...user, password })}
              />
            </Flex>

            <Flex flexDirection='column' mt='3.2rem'>
              <Typography mb='0.8rem' textAlign='center' color='rgba(255, 255, 255, 0.6)'>
                <FormattedMessage id='registrationInline.useFemalePronouns' />
              </Typography>

              <InlineFormSelect
                name='name'
                error={errors.sex}
                value={user.femalePronouns === undefined ? -1 : user.femalePronouns ? 0 : 1}
                options={[
                  { label: formatMsg('registration.choose'), value: -1 },
                  ...[formatMsg('registration.woman'), formatMsg('registration.man')].map((label, value) => ({
                    label,
                    value,
                  })),
                ]}
                disabled={[-1]}
                onChange={opt =>
                  setUser({
                    ...user,
                    femalePronouns: (opt as Option).value === 0,
                  })
                }
                required
              />
            </Flex>

            <Box>
              <Flex justifyContent='center' mt='3.2rem'>
                <Flex alignItems='center' as='label'>
                  <Box position='relative'>
                    <CheckboxInputStyle type='checkbox' id='agree' onChange={(e) => setAgree(e.target.checked)} />
                    <CheckedIconStyle className='checkbox-icon-checked'  />
                  </Box>

                  <Box ml='1.6rem'>
                    <Typography color='rgba(255, 255, 255, 0.7)' fontSize='1.6rem' textAlign='left'>
                      <FormattedMessage id={'register.agree'} />
                      &nbsp;
                      <Link
                        v='underline'
                        color='yellow'
                        borderColor='yellow'
                        onClick={() => popups.custom(() => (<Popup />))}
                      >
                        <FormattedMessage id={'register.agree.terms'} />
                      </Link>
                    </Typography>

                    <Typography color='rgba(255, 255, 255, 0.7)' fontSize='1.6rem' textAlign='left'>
                      <FormattedMessage id={'register.gdpr'} />
                      &nbsp;
                      <Link
                        v='underline'
                        color='yellow'
                        borderColor='yellow'
                        href='/Prohlaseni.pdf'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <FormattedMessage id={'register.gdpr.terms'} />
                      </Link>
                    </Typography>
                  </Box>
                </Flex>
              </Flex>

              {agree === 'error' && <Typography color='#e3d240'><FormattedMessage id='registration.mustAgree' /></Typography>}

              <Flex justifyContent='center' mt='3.2rem'>
                <PrimaryButton bg='rgba(12, 107, 75, 0.9)' type='submit' color='#fff' _hover={{ color: '#000' }}>
                  <FormattedMessage id={'register.send'} />
                </PrimaryButton>
              </Flex>

              <Flex justifyContent='center' mt='2.4rem'>
                <StyledLink to={createUrl('')}>
                  <Link fontSize='1.4rem' v='underline'>
                    <FormattedMessage id={'backToIntro'} />
                  </Link>
                </StyledLink>
              </Flex>
            </Box>
          </Box>
        </Box>
      </Fade>
    </form>
  );
};

export default withPopups(wrapIntl(RegisterForm));
