import * as React from 'react';
import { Request } from 'express';
import cookies from 'js-cookie';
import { parse } from 'url';

export interface RequestContext {
  cookies: Record<string, string>;
  locale: 'cs' | 'en';
  feedbackSurveyId: string;
  isDev: boolean;
  registrationStatus: 'regionDisabled' | 'globalDisabled' | 'allowed';
}

interface RequestContextMutations {
  setCookie: (key: string, value: string | object, options?: cookies.CookieAttributes) => unknown;
  forceUpdateCookies: () => unknown;
}

const defaultValue = {
  cookies: {},
  locale: 'en' as 'en',
  feedbackSurveyId: 'b67daca3-d92c-4568-bfff-bd10ccad1ac8',
  isDev: false,
  registrationStatus: 'allowed' as 'allowed',
  setCookie: () => undefined,
  forceUpdateCookies: () => undefined,
};

const _RequestContext = React.createContext<RequestContext & RequestContextMutations>(defaultValue);

export const RequestContext = _RequestContext.Consumer;

export const useRequest = () => React.useContext(_RequestContext);

export const RequestContextProvider: React.FC<{ context: RequestContext }> = ({ context, children }) => {
  const [current, setCurrent] = React.useState(context);
  return (
    <_RequestContext.Provider
      value={{
        ...current,
        setCookie: (key: string, value: string | object, options?: cookies.CookieAttributes) => {
          cookies.set(key, value, options);
          setCurrent({ ...current, cookies: cookies.get() });
        },
        forceUpdateCookies: () => setCurrent({ ...current, cookies: cookies.get() }),
      }}
    >
      {children}
    </_RequestContext.Provider>
  );
};

export const getRequestContext = (request: Request): RequestContext => {
  const { pathname } = parse(request.url);
  return {
    ...defaultValue,
    cookies: {},
    locale: pathname && pathname.startsWith('/en') ? 'en' : 'cs',
    isDev: process.env.NODE_ENV === 'development',
  };
};

declare const window: Window & { __REQUEST_CONTEXT__: RequestContext };

export const hydrateRequestContext = () => window.__REQUEST_CONTEXT__;
