import * as React from 'react';
import wrapIntl, { IWrapIntl } from '../../../atoms/wrapIntl';
import FeedbackForm from '../../../organisms/FeedbackForm';
import { sendFeedback } from './actions';

export const SuggestValue: React.FC<IWrapIntl> = ({ formatMsg }) => {
  console.log(typeof formatMsg);
  console.log(formatMsg);
    
  return (
    <FeedbackForm
      title={formatMsg('suggestValue.heading')}
      text={formatMsg('suggestValue.text')}
      submit={sendFeedback('suggest-value')}
    />
  );};

export default wrapIntl(SuggestValue);
