import { IStringMap } from '.';
import { Action } from 'redux';
import { jsonFetch } from './fetch';
import { IFetchException } from './exceptions';
import { ThunkAction } from './types';

/**
 * Takes array of object and returns normalized object
 *
 * @param arr      array to be normalized
 * @param keys     array of keys in objects used as keys in result
 * @param clutch   if more keys provided, use this string as a clutch
 *
 * @returns        normalized object
 * @example
 * ```typescript
 * interface IPerson {
 *   name: string;
 *   eticoins: number;
 *   [key: string]: string;
 * }
 *
 * let people: IPerson[] = [{ "name": "Jarda", "eticoins": "1000" },
 *                          { "name": "Alfonz", "eticoins": "100" }];
 *
 * let normalized = normalize<IPerson>(people, ["name"]);
 * // normalized == {
 * //    "jarda": {
 * //        "name": jarda,
 * //        "eticoins": "1000"
 * //     },
 * //     "Alfonz": {
 * //         "name": "Alfonz",
 * //         "eticoins": "100"
 * //      }
 * // };
 * ```
 */
export const normalize = <T extends {}>(arr: T[], keys: string[] | ((itm: T) => string), clutch = '.'): IStringMap<T> =>
  _normalize(arr, typeof keys === 'function' ? keys : (itm: T) => keys.map(k => (itm as any)[k]).join(clutch));

export const _normalize = <T extends {}>(arr: T[], keys: (itm: T) => string): IStringMap<T> =>
  arr.reduce((res: IStringMap<T>, obj: T) => {
    res[keys(obj)] = obj;
    return res;
  }, {});

export const denormalize = <T>(obj: IStringMap<T>): T[] => Object.keys(obj).map(k => obj[k]);

/**
 * Returns action creator for redux
 *
 * Many times, when there is a need to fetch some data
 * from server, redux store action creators follows
 * the same pattern. This function enforces DRY principle.
 */

interface IFetchTaskRequest {
  url: string;
  init?: RequestInit;
}

export const fetchTask = <T, E = {}>(
  url: string | IFetchTaskRequest,
  start: () => Action | ThunkAction<any, any>,
  success: (data: T) => Action | ThunkAction<any, any>,
  fail: (error: E) => Action | ThunkAction<any, any>,
) => (dispatch: any) => _fetchTask(typeof url === 'string' ? { url } : url, start, success, fail)(dispatch);

const _fetchTask = <T, E>(
  request: IFetchTaskRequest,
  start: () => Action | ThunkAction<any, any>,
  success: (data: T) => Action | ThunkAction<any, any>,
  fail: (error: E) => Action | ThunkAction<any, any>,
) => (dispatch: any): Promise<T> => {
    dispatch(start());
    return jsonFetch<T>(request.url, request.init)
      .then(data => {
        dispatch(success(data));
        return data;
      })
      .catch((e: IFetchException) => {
        if (!e.data) {
          throw e;
        }
        e.data.json().then((ex: E) => dispatch(fail(ex)));
        throw e;
      });
  };

export const parseQueryParams = (query: string) => {
  const paramsHash: IStringMap<string> = {};
  let match;
  const search = /([^&=]+)=?([^&]*)/g;
  const q = query.substring(1);

  while ((match = search.exec(q))) {
    paramsHash[decodeURIComponent(match[1])] = decodeURIComponent(match[2]);
  }
  return paramsHash;
};

export const guid = () => {
  const s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
};
