import * as React from 'react';
import { IQuestionHelp, ISurveySimpleQuestion } from '../../../data';
import { Row, Col } from 'react-styled-flexboxgrid';
import Checkbox from '../../../../../../atoms/FormItems/PartnerTest/Checkbox';
import Radio from '../../../../../../atoms/FormItems/PartnerTest/Radio';
import { Answers } from '../../../styles';

export interface ILayoutA {
  data: ISurveySimpleQuestion;
  help: IQuestionHelp;
  selected: string[];
  onChange: (id: string, val: boolean) => void;
}

const LayoutA: React.FC<ILayoutA> = ({ data, help, onChange, selected }) => {
  return (
    <Row>
      <Col xs={12}>
        <h1 dangerouslySetInnerHTML={{ __html: data.text as string }} />
        
        {data.description !== null && <p dangerouslySetInnerHTML={{ __html: data.description as string }} />}

        {data.image && (
          <img src={data.image} alt='' className='question-img desktop' />
        )}

        {data.mobileImage && (
          <img src={data.mobileImage} alt='' className='question-img mobile' />
        )}

        <Answers>
          <div className='checkbox-wrapper'>
            {data.answers
              .sort((a, b) => a.priority - b.priority)
              .map(a =>
                data.maxAnswersCount !== 1 ? (
                  <Checkbox
                    label={a.text}
                    form='questionForm'
                    id={a.id}
                    key={a.id}
                    checked={selected.includes(a.id)}
                    disabled={
                      data.maxAnswersCount === selected.length && !selected.includes(a.id) && data.maxAnswersCount !== 1
                    }
                    onChange={e => onChange(a.id, e.target.checked)}
                  />
                ) : (
                  <Radio
                    label={a.text}
                    form='questionForm'
                    id={a.id}
                    key={a.id}
                    checked={selected.includes(a.id)}
                    disabled={
                      data.maxAnswersCount === selected.length && !selected.includes(a.id) && data.maxAnswersCount !== 1
                    }
                    onChange={e => onChange(a.id, e.target.checked)}
                  />
                ),
              )}
          </div>
        </Answers>
      </Col>
    </Row>
  );
};

export default LayoutA;
