import {
  DragSource,
  DragSourceConnector,
  DragSourceMonitor,
  DragSourceSpec,
  ConnectDragSource,
  ConnectDragPreview,
} from 'react-dnd';
import DraggableCard, { IDraggableCard } from './component';
import { ICardValue } from '../../data';

export const GAME_CARD = 'GAME_CARD';

export interface IDragInfo {
  connectDragSource?: ConnectDragSource;
  connectDragPreview?: ConnectDragPreview;
  isDragging?: boolean;
}

const source: DragSourceSpec<IDraggableCard, IDraggableCardObject> = {
  beginDrag: props => ({
    value: props.card,
  }),
};

const collect = (connect: DragSourceConnector, monitor: DragSourceMonitor): IDragInfo => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
});

interface IDraggableCardObject {
  value: ICardValue;
}

export default DragSource('GAME_CARD', source, collect)(DraggableCard);
