import { IStringMap } from '../../../utils/index';
import { IRescource, getData } from '../../../utils/rescource';
import withRedux from '.';
import { mergeRes } from './index';
import { IEticon, fetchEticons } from '../../../store/Entities/Eticons/index';

export interface IWithEticons {
  eticons: IStringMap<IEticon>;
  rescource: { eticons: IRescource<IStringMap<IEticon>> };
}

export default withRedux(
  state => state.entities.eticons,
  dispatch => () => dispatch(fetchEticons()),
  (props): IWithEticons => ({
    eticons: getData(props.rescource),
    rescource: { ...mergeRes(props), eticons: props.rescource },
  }),
);
