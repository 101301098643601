import * as React from 'react';
import { createUrl } from '../../routes';
import Subnav from '../../../atoms/Subnav';
import { Route } from 'react-router';
import Center from './Center';
import Process from './Process';
import { IThemeProps, useTheme } from '../../../templates/General/ThemeContext';
import Game from './Game';
import NavItem from 'components/atoms/Nav/NavItem';

interface IValueCenterState {
  detail: string | null;
}

class ValueCenter extends React.Component<IThemeProps, IValueCenterState> {
  public state: IValueCenterState = { detail: null };

  public componentDidMount() {
    if (window.innerHeight > window.innerWidth && 768 > window.innerWidth) {
      this.props.theme.toggleMenu(false);
    }
  }

  public componentDidUpdate() {
    if (this.props.theme.data.dataMenu === undefined) {
      this.props.theme.toggleMenu(true);
    }
  }

  public render() {
    const baseUrl = createUrl('app');
    const gameUrl = createUrl('app.gameGame');
    const isInGame = location.pathname.includes('hra');


    return (
      <>
        <Subnav black={isInGame}>
          <NavItem to={baseUrl} label={'valueCenter.heading'} exact />
        </Subnav>

        <div>
          <Route path={baseUrl} exact={true} render={() => <Center />} />
          <Route path={baseUrl + '/prubeh-hry'} exact={true} render={() => <Process />} />
          <Route path={gameUrl} exact={true} render={() => <Game />} />
        </div>
      </>
    );
  }
}

export default useTheme(ValueCenter);
