import styled from 'styled-components';
import { Flex, Box } from '@xcorejs/ui';
import { mediaMdDefault } from 'components/theme/grid';

export const animation = (delay: number) => '500ms ease ' + delay + 'ms forwards fadeInGameIntro';

export const SectionWrapper = styled.section`
--fade-in-duration: 500ms;

display: flex;
flex-direction: column;
align-items: center;
justify-content: center;

h2 {
  opacity: 0;
  
  animation: var(--fade-in-duration) ease 0ms forwards fadeInGameIntro;
  -webkit-animation: var(--fade-in-duration) ease 0ms forwards fadeInGameIntro;
}

.start-play-button {
  opacity: 0;

  animation: var(--fade-in-duration) ease 500ms forwards fadeInGameIntro;
  -webkit-animation: var(--fade-in-duration) ease 500ms forwards fadeInGameIntro;
}

p {
  opacity: 0;

  animation: var(--fade-in-duration) ease 1000ms forwards fadeInGameIntro;
  -webkit-animation: var(--fade-in-duration) ease 1000ms forwards fadeInGameIntro;
}

@keyframes fadeInGameIntro {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeInGameIntro {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
`;

export const Darken = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.25);
`;

export const BackgroundWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

export const StyledBackground = styled.div<{color: string; delayed?: boolean}>`
  --animation-duration: 10s;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ color }) => color};
  box-shadow: 0 0 30px 10px ${({ color }) => color.indexOf('rgb') !== -1 ? color.replace('rgb', 'rgba').replace(')', ', 0.7)') : color + 'b3'};
  ${({ delayed }) => delayed ? 
    'animation: var(--animation-duration) linear infinite alternate fade; -webkit-animation: var(--animation-duration) linear infinite alternate fade;' : 
    'animation: var(--animation-duration) linear infinite alternate fade2; -webkit-animation: var(--animation-duration) linear infinite alternate fade2;'}  

  @keyframes fade {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes fade2 {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fade {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @-webkit-keyframes fade2 {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const GameFlex = styled(Flex)`
  aspect-ratio: 0.63 / 1;
`;

export const CarouselBox = styled(Box)`
  aspect-ratio: 0.79 / 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
