import React from 'react';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import { Card } from './style';
import { FormattedMessage } from 'react-intl';
import { _switch } from '../../../../../utils/option';
import LoadingSpinner from 'components/atoms/Animations/LoadingSpinner';
import withLifeValues, { IWithLifeValues } from 'components/molecules/withRedux/withLifeValues';
import { createUrl } from '../../../routes';
import { Link } from 'react-router-dom';
import { SURVEY_RESULTS } from 'config/api/surveyInternal';
import { PrimaryButtonLink } from '../../../../atoms/Buttons';
import { Box, Heading2, Paragraph } from '@xcorejs/ui';
import useFetch from 'components/atoms/Fetch/useFetch';

export interface ISurveyResult {
  surveyId: string;
  surveyName: string;
  type: string;
  image: string;
  theme: string | null;
  lifeValueId: string;
  finished: boolean;
  updatedAt: string;
  surveyResultId: string;
}

const Activity: React.FC<IWithLifeValues> = (props) => {
  const { state, result } = useFetch<ISurveyResult[]>(SURVEY_RESULTS);
  
  if (state == 'fetching') return <LoadingSpinner />;
  if (state == 'fail') return <Paragraph>Nepodařilo se načíst data</Paragraph>;

  const results: {
    main: ISurveyResult[];
    others: ISurveyResult[];
  } = {
    main: [],
    others: [],
  };

  result && result.forEach((item) => {
    if (item.finished && item.type === 'identified' && item.lifeValueId) {
      results.main.push(item);
    } else if (item.finished && (item.type === 'identified' || item.theme === 'values') && !item.lifeValueId) {
      results.others.push(item);
    }
  });

  return (
    <Box py={{ _: '2rem', sm: '4rem' }}>
      <Grid>
        <Row center='sm'>
          <Col xs={12}>
            <Heading2 mb='3rem'>
              <FormattedMessage id={'journal.activity.values'} />
            </Heading2>
          </Col>
        </Row>

        <Row center='sm'>
          {results.main
            .sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
            .map((result, i) => (
              <Col xs={12} sm={4} md={3} lg={2} key={i}>
                <Link
                  to={createUrl('app.surveyResult', {
                    id: result.surveyResultId,
                  })}
                >
                  <Card color={'green'}>
                    <img src={props.lifeValues[result.lifeValueId].icon} alt='' />
                    <strong>
                      <span>{result.surveyName}</span>
                    </strong>
                    <span>
                      {new Date(result.updatedAt).toLocaleDateString('cs', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      })}
                    </span>
                  </Card>
                </Link>
              </Col>
            )
            )}
        </Row>

        <Row center='sm'>
          <Col xs={12}>
            <Heading2 mb='3rem' mt='2rem'>
              <FormattedMessage id={'journal.activity.others'} />
            </Heading2>
          </Col>
        </Row>

        <Row center='sm'>
          {results.others
            .sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
            .map((result, i) => (
              <Col xs={12} sm={4} md={3} lg={2} key={i}>
                {/* {console.log(result)} */}
                <Link
                  to={createUrl('app.surveyResult', {
                    id: result.surveyResultId,
                  })}
                >
                  <Card color={'violet'}>
                    <img src='/img/ico/feedback.svg' alt='' />
                    <strong>
                      <span>{result.surveyName}</span>
                    </strong>
                    <span>
                      {new Date(result.updatedAt).toLocaleDateString('cs', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      })}
                    </span>
                  </Card>
                </Link>
              </Col>
            )
            )}
        </Row>

        <Row center='sm'>
          <PrimaryButtonLink to={createUrl('app')}>
            <FormattedMessage id={'sidebar.menu.game'} />
          </PrimaryButtonLink>
        </Row>
      </Grid>
    </Box>
  );
};

export default withLifeValues<{}>(Activity);
