import * as React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Heading3, Img, Paragraph, Link, Flex } from '@xcorejs/ui';
import { OliLight } from 'components/theme/fonts';
import { textGreen } from 'components/theme/colors';

interface IPersonBox {
  img: string;
  name?: string;
  translatedName?: string;
  text?: string;
  link?: string;
  popup?: () => void;
  linkText?: string;
}

export const PersonBox: React.FC<IPersonBox> = ({ img, name, translatedName, text, link, popup, linkText }) => {
  return (
    <Flex flexDirection='column' justifyContent='center' alignItems='center'>
      <Img src={img} alt={name || ''} maxHeight='8rem' />

      {name && <Heading3 marginTop={{ _: '1rem', sm: '2rem' }} color={textGreen}>{name}</Heading3>}
      {translatedName && (
        <Heading3 marginTop={{ _: '1rem', sm: '2rem' }} color={textGreen}>
          <FormattedMessage id={translatedName} />
        </Heading3>
      )}

      <Paragraph marginTop='1rem' maxWidth='30rem' fontFamily={OliLight}>
        <FormattedHTMLMessage id={text!} />
      </Paragraph>

      {link && (
        <Link color='yellow' borderColor='yellow' v='underline' _hover={{ color:'yellow', borderColor: 'transparent' }} marginTop='0.5rem'>
          <a href={link} target='_blank' rel='noopener noreferrer'>
            {linkText}
          </a>
        </Link>

      )}
      {popup && (
        <Link color='yellow' borderColor='yellow' v='underline' _hover={{ color:'yellow', borderColor: 'transparent' }} onClick={() => popup()} marginTop='0.5rem'>
          {linkText ? <FormattedMessage id={linkText} /> : 'missing text'}
        </Link>
      )}
    </Flex>
  );
};

export default PersonBox;
