import * as React from 'react';
import { StyledHelpLink } from './styles';

export interface IPopupLink {
  size?: number;
  variant?: 'outline' | 'normal';
  mdSize?: number;
}

export const HelpLink: React.FC<IPopupLink> = ({ size, variant = 'normal' }) => <StyledHelpLink className='help-link' variant={variant} size={size} />;
