import * as React from 'react';
import { StyledFormGroupInline } from './styles';

export interface IInlineFormGroup {
  className?: string;
  error?: string;
}

export const InlineFormGroup: React.FC<IInlineFormGroup> = ({ className, error, children }) => (
  <StyledFormGroupInline className={className}>
    {children}
    {error ? <span className={'error'}>{error}</span> : ''}
  </StyledFormGroupInline>
);

export default InlineFormGroup;
