import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Location } from 'history';
import { FormattedMessage } from 'react-intl';
import { MenuNotification } from './styles';
import { match } from 'react-router';
import { _switch } from '../../../utils/option';
import SVGmenuPorovnani from '../SVGIcons/menu/SVGmenuPorovnani';
import { normalizeUrl } from '../../../utils/router';
import { Icon } from '@xcorejs/ui';
import HomeIcon from '../../components/icons/32/home.svg';
import GameIcon from '../../components/icons/32/game.svg';
import JournalIcon from '../../components/icons/32/journal.svg';
import PlusIcon from '../../components/icons/32/plus.svg';
import ShopIcon from '../../components/icons/32/shop.svg';
import StatsIcon from '../../components/icons/32/stats.svg';
import ValuesIcon from '../../components/icons/32/values.svg';

export interface INavItem {
  to: string;
  label: string;
  icon?: string;
  exact?: boolean;
  active?: string[];
  notifications?: number;
  state?: string;
  onClick?: () => void;
}

const NavItem: React.FC<INavItem> = ({ to, label, active, exact, notifications, icon, state, onClick }) => (
  <li>
    <NavLink className='nav-link' to={{ pathname: to, state }} exact={exact} isActive={isActive(to, active, exact)} onClick={onClick}>
      {icon &&
        _switch({
          home: <Icon svg={<HomeIcon />} />,
          game: <Icon svg={<GameIcon />} />,
          journal: <Icon svg={<JournalIcon />} />,
          plus: <Icon svg={<PlusIcon />} />,
          shop: <Icon svg={<ShopIcon />} />,
          stats: <Icon svg={<StatsIcon />} />,
          values: <Icon svg={<ValuesIcon />} />,
          comparison: <SVGmenuPorovnani />
        })()(icon)
      }

      <FormattedMessage id={label} />
      {notifications && <MenuNotification>{notifications}</MenuNotification>}
    </NavLink>
  </li>
);

export default NavItem;

export const isActive = <P extends {}>(to: string, list?: string[], exact?: boolean) => (
  _: match<P>,
  location: Location,
) =>
  [to, ...list === undefined ? [] : list].filter(
    (route: string) => !!normalizeUrl(location.pathname).match('^' + route + (exact ? '$' : '')),
  ).length > 0;
