import styled from 'styled-components';
import { gray, darkBaseRGB, darkBaseHEX } from '../../../theme/colors';
import {
  mediaMdDefault,
  mediaLandscapePhone,
  mediaHonzaDefault,
  mediaSmDefault,
  mediaMdMax,
  mediaLgDefault,
} from '../../../theme/grid';
import { StyledQuestionLayout } from '../Question/styles';
import { BurgerFlex } from '../../Layout/Header/styles';
import { CustomText } from '../../../atoms/Text';
import { StyledHelpLink } from '../../../atoms/Popups/styles';
import { StyledLogo } from '../../../atoms/Logo/styled';
import { ProgressBarLine, ProgressLine } from '../../../molecules/ValueTest/ProgressLine/styles';
import { StyledShowMenuButton } from '../../ShowMenuButton/style';
import { Heading2 } from '../../../atoms/Headings';
import { StyledSpinnerLocal } from '../../../atoms/Animations/LoadingSpinnerLocal';

export const StyledBurger = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.5rem 3rem;
  min-height: 5rem;

  ${BurgerFlex} {
    position: fixed;
    top: 0;
    left: 0;
    padding: 0.5rem 3rem;
    width: 100%;
    min-width: 25rem;
    min-height: 5rem;
    background-color: rgba(${darkBaseRGB}, 0.2);
    z-index: 1002;

    ${mediaMdDefault} {
      position: static;
      padding: 0.5rem 0;
      width: auto;
      min-width: 30rem;
      background: transparent;
    }

    ${mediaLandscapePhone} {
      display: none;
    }
  }

  ${mediaSmDefault} {
    min-height: 6rem;

    ${BurgerFlex} {
      min-height: 6rem;
    }
  }
`;

export const StyledQuestionIntro = styled(StyledQuestionLayout)`
  padding-top: 4rem;
  position: relative;
  height: calc(100% - 5rem);
  text-align: center;

  ${mediaSmDefault} {
    height: calc(100% - 6rem);
  }

  ${mediaMdDefault} {
    height: calc(100% - 7rem);
  }

  &:after {
    opacity: 0.8;
  }

  ${mediaMdDefault} {
    padding-top: 12rem;
    text-align: left;
  }

  small {
    opacity: 0.8;
    color: ${gray};
    font-size: 2rem;
    letter-spacing: 0.5px;
    line-height: 2.5rem;
  }
`;

export const StyledTestHeading = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 5rem;
  align-items: center;
  min-height: 5rem;

  ${mediaLandscapePhone} {
    padding-top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 0;
  }

  ${mediaMdDefault} {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0;
    height: 6rem;
  }

  ${BurgerFlex} {
    position: fixed;
    top: 0;
    padding: 0.5rem 2rem;
    width: 100%;
    min-width: 25rem;
    min-height: 5rem;
    background: ${darkBaseHEX};
    z-index: 1002;

    ${mediaMdMax} {
      ${StyledShowMenuButton} {
        margin-right: 1rem;

        span {
          margin-top: 0.4rem;
          width: 2rem;
          background: #fff;

          &:first-child {
            margin-top: 0;
          }
        }
      }
    }

    ${mediaMdDefault} {
      position: static;
      padding: 0.5rem 0;
      width: auto;
      min-width: 30rem;
      background: transparent;
    }

    ${mediaLandscapePhone} {
      display: none;
    }

    ${StyledLogo} {
      img {
        width: 8.2rem;

        ${mediaMdDefault} {
          width: 13.6rem;
        }
      }
    }
  }

  ${CustomText} {
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 0 1.5rem;
    font-size: 1.5rem;
    letter-spacing: 0.5px;
    line-height: 2.4rem;
    font-weight: 400;
    text-align: center;
    color: #fff;
    width: 100%;

    @media (min-width: 360px) {
      font-size: 1.6rem;
    }

    ${mediaHonzaDefault} {
      font-size: 2rem;
      line-height: 2.4rem;
    }

    ${mediaMdDefault} {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 0;
      margin-bottom: 0;
      min-height: 6rem;
      font-size: 2.2rem;
    }

    ${mediaLgDefault} {
      font-size: 2.4rem;
    }

    &.question {
      display: none;

      ${mediaMdDefault} {
        display: flex;
      }
    }
  }

  ${mediaLandscapePhone} {
    ${StyledHelpLink} {
      width: 3rem;
      height: 3rem;
    }
  }

  .progress {
    margin-top: 0.3rem;
    font-size: 1.4rem;
    line-height: 2.4rem;

    ${mediaMdDefault} {
      display: none;
    }
  }

  ${ProgressLine} {
    display: none;

    ${mediaMdDefault} {
      display: block;
    }
  }

  .progress-line-mobile {
    position: fixed;
    width: 100%;
    height: 3px;
    background-color: rgba(${darkBaseRGB}, 0.25);
    z-index: 1;

    ${ProgressBarLine} {
      position: static;
      height: 0.3rem;
      border-radius: 0;
    }

    ${mediaMdDefault} {
      display: none;
    }
  }
`;

export const StyledTestResultLoading = styled.div`
  padding-top: 6rem;
  text-align: center;

  ${StyledSpinnerLocal} {
    margin-top: 6rem;
    margin-bottom: 8rem;
  }

  ${CustomText} {
    text-align: center;
  }

  .banner {
    display: inline-block;
    border-radius: 0.4rem;
    max-width: 28rem;
    background: radial-gradient(circle at top, #1c3389 0%, #0e2370 100%);
    box-shadow: 0 1rem 2rem 0 rgba(3, 12, 46, 0.5);
    overflow: hidden;
    margin: 0 auto;

    img {
      display: block;
    }
  }
`;

export const StyledLoadingText = styled.div`
  ${Heading2} {
    font-weight: 400;
  }
`;
