import { Flex, Heading2, Img, Modal, Typography, Link as UILink, Button, Box, useModal, Paragraph, Heading3, Icon } from '@xcorejs/ui';
import { CheckboxInputStyle, CheckedIconStyle } from 'components/pages/App/Shop/Buy';
import { KidsTheme, yellowWhite } from 'components/theme/colors';
import { FC, useState } from 'react';
import * as React from 'react';
import Accordion from 'components/atoms/Accordion/Accordion';
import ExternalSVG from '../icons/24/external-link.svg';
import { createPresentingUrl } from 'components/pages/routes';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { OliLight } from 'components/theme/fonts';

type MoreInfoProps = {
  kids: boolean;
  parents: boolean;
  schools: boolean;
};

interface KidsModalProps {
  onAgreement: () => unknown;
}

const KidsModal: FC<KidsModalProps> = ({ onAgreement }) => {
  const [close] = useModal(null);
  const [agreement, setAgreement] = useState(false);
  const [moreInfo, setMoreInfo] = useState<MoreInfoProps>({
    kids: false,
    parents: false,
    schools: false,
  });

  const onAction = () => {
    onAgreement();
    close();
  };

  return (
    <Modal
      background={KidsTheme.primary}
      overflowX='hidden'
      p={{ _: '4rem 3.2rem', sm: '4rem 3.2rem' }}
      maxWidth='63rem'
    >
      <Flex flexDirection='column' alignItems='center'>
        <Img src='/img/sun_kids_registration.png' alt='' />

        <Heading2 mt='2rem'>
          <FormattedMessage id='kidsModal.title' />
        </Heading2>

        <Typography mt='2rem' maxWidth='32rem' mx='auto' color='#fff'>
          <FormattedMessage id='kidsModal.text' />
        </Typography>

        <Typography mt='2rem'>
          <FormattedHTMLMessage id='kidsModal.text2' />
        </Typography>

        {/* více info pro děti ZAČÁTEK */}
        <UILink
          color='yellow'
          mt='4rem'
          onClick={() => setMoreInfo(prev => ({ ...prev, kids: !prev.kids }))}
        >
          <FormattedMessage id='kidsModal.moreInfo.kids' />
        </UILink>

        <Accordion
          expanded={moreInfo.kids}
          flexDirection='column'
          {...AccordionStyle.wrapper}
        >
          <Heading3 mt='2rem'>
            <FormattedHTMLMessage id='kidsModal.moreInfo.kids.title' />
          </Heading3>

          <Paragraph {...AccordionStyle.paragraph}>
            <FormattedHTMLMessage id='kidsModal.moreInfo.kids.text' />
          </Paragraph>

          <Paragraph {...AccordionStyle.paragraph}>
            <FormattedHTMLMessage id='kidsModal.moreInfo.kids.text2' />
          </Paragraph>

          <Img src='/img/presenting/0_Me_hodnoty_o_hodnotach.png' alt=''  mt='1rem' height='100px' width='100px' />

          <Paragraph mb='2rem' {...AccordionStyle.paragraph}>
            <FormattedHTMLMessage id='kidsModal.moreInfo.kids.text3' />
          </Paragraph>
        </Accordion>
        {/* více info pro děti KONEC */}

        {/* více info pro rodiče ZAČÁTEK */}
        <UILink
          color='yellow'
          mt='1rem'
          onClick={() => setMoreInfo(prev => ({ ...prev, parents: !prev.parents }))}
        >
          <FormattedMessage id='kidsModal.moreInfo.parents' />
        </UILink>
        
        <Accordion
          expanded={moreInfo.parents}
          flexDirection='column'
          {...AccordionStyle.wrapper}
        >
          <Heading3 mt='2rem'>
            <FormattedHTMLMessage id='kidsModal.moreInfo.parents.title' />
          </Heading3>

          <Paragraph {...AccordionStyle.paragraph}>
            <FormattedHTMLMessage id='kidsModal.moreInfo.parents.text' />
          </Paragraph>

          <Paragraph {...AccordionStyle.paragraph} fontFamily={OliLight}> 
            <FormattedHTMLMessage id='kidsModal.moreInfo.parents.text2' />

            <UILink
              href={createPresentingUrl(30)}
              target='_blank'
              color='yellow'
              mt='1rem'
            >
              <FormattedMessage id='kidsModal.moreInfo.parents.link' />
            </UILink>
            <Icon svg={<ExternalSVG />} ml='0.5rem' height='1.6rem' position='relative' bottom='3px' />
          </Paragraph>
        </Accordion>
        {/* více info pro rodiče KONEC */}
        
        <Box>
          <UILink
            href={createPresentingUrl(44)}
            // target='_blank'
            color='yellow'
            mt='1rem'
          >
            <FormattedMessage id='kidsModal.moreInfo.schools' />
          </UILink>
          <Icon svg={<ExternalSVG />} ml='0.5rem' height='1.6rem' position='relative' bottom='3px' />
        </Box>

        <Flex alignItems='center' mt='4rem'>
          <Button
            leftElement={<Img src='/img/sun_kids_registration16.png' alt='' mr='1rem' />}
            height='4rem'
            disabled={!agreement}
            onClick={onAction}
          >
            <FormattedMessage id='kidsModal.button' />
          </Button>

          <Button
            v='outline'
            ml='3rem'
            color='accentGreen'
            borderColor='accentGreen'
            display={{ _: 'none', sm: 'flex' }}
            onClick={close}
          >
            <FormattedMessage id='kidsModal.back' />
          </Button>
        </Flex>
        
        <Flex flexDirection='column' alignItems='center' mt='3rem' background='rgba(14,15,16,0.3)' padding='1rem 2rem'>
          <Flex alignItems='center' as='label'>
            <Box position='relative'>
              <CheckboxInputStyle type='checkbox' id='agree' onChange={(e) => setAgreement(e.target.checked)} />
              <CheckedIconStyle className='checkbox-icon-checked'  />
            </Box>

            <Typography ml='1rem' color={yellowWhite}>
              <FormattedMessage id='kidsModal.agree' />
            </Typography>
          </Flex>

          <Typography mt='2rem' fontSize='1.3rem' lineHeight='1.8rem' color={yellowWhite}>
            <FormattedMessage id='kidsModal.agree.text' />
          </Typography>
        </Flex>
        
        <Button
          onClick={close}
          v='outline'
          mt='2rem'
          color='accentGreen'
          borderColor='accentGreen'
          display={{ _: 'flex', sm: 'none' }}
        >
          <FormattedMessage id='kidsModal.back' />
        </Button>

      </Flex>
    </Modal>
  );
};

export default KidsModal;

const AccordionStyle = {
  wrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '40rem',
  },
  paragraph: {
    marginTop: '1rem',
    fontSize: '1.4rem',
  }
};
