import React, { ReactNodeArray } from 'react';
import { Paragraph, Icon, Heading2 } from '@xcorejs/ui';
import { yellowWhite, BlueTheme } from 'components/theme/colors';
import { SVGFillColor } from './styles';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { createUrl } from 'components/pages/routes';
import CompareSVG from '../../../components/icons/16/compare.svg';

const ComparisonHowTo = () => {
  return (  
    <>
      <Heading2 mt='6rem' color={BlueTheme.text}>
        <FormattedMessage id='interestingComparison.howto.heading' />
      </Heading2> <Paragraph
        mt='2rem'
        color={yellowWhite}
        maxWidth='70rem'
        textAlign={{ _: 'center', xs: 'center' }}
      >
        <SVGFillColor color='white'>
          <FormattedMessage 
            id='interestingComparison.howto.text' 
            values={{
              'odkaz': (...chunks: ReactNodeArray) => (
                <Link to={createUrl('app.shop')} style={{ color: BlueTheme.text }}>
                  {chunks}
                </Link>
              ),
              'ikona': () => (
                <Icon svg={<CompareSVG />} color='white' /> 
              ),
              'nbsp': () => <>&nbsp;</>
            }}
          />
        </SVGFillColor>
      </Paragraph>
    </>
  );
};
 
export default ComparisonHowTo;