import { combineReducers, Reducer } from 'redux';
import { IRescource } from '../../utils/rescource';
import { IStringMap } from '../../utils/index';

import { ILifeValue } from './LifeValues';
import { lifeValuesReducer } from './LifeValues/LifeValuesReducer';

import { statisticsReducer } from './Statistics';
import { IValueResult } from 'config/api/valueTestResult';

import { IEticon, eticonsReducer } from './Eticons';

import { INotification, notificationsReducer } from './Notifications';

export interface IEntitiesState {
  lifeValues: IRescource<IStringMap<ILifeValue>>;
  eticons: IRescource<IStringMap<IEticon>>;
  statistics: IRescource<IStringMap<IValueResult>>;
  notifications: IRescource<IStringMap<INotification>>;
}
export const entityReducer: Reducer<IEntitiesState> = combineReducers({
  lifeValues: lifeValuesReducer,
  statistics: statisticsReducer,
  eticons: eticonsReducer,
  notifications: notificationsReducer,
});
