/**
 * Fills an array with a value up to a specified length
 * @param inputArray The array to fill
 * @param value The value to fill the array with
 * @param length The length to fill the array to
 * @returns The filled array or the original array if the length is already greater than or equal to the specified length
 */

export function fillArray<T>(inputArray: T[], value: T, length: number): T[] {
  if (inputArray.length >= length) return inputArray;

  const fill: T[] = Array
    .from<T>({ length: length - inputArray.length })
    .fill(value);
  return inputArray.concat(fill);
}