import { TEST_CREATE_FROM_TEMPLATE } from 'config/apiRoutes';
import { jsonFetch } from 'utils/fetch';

import { ITestResultPreviewResponse } from '../../TestIntro/data';

export interface ITestTemplateRequest {
  testTemplateId?: string;
  surveyIds?: string[];
  note?: string;
}

export const saveConfiguration = (request: ITestTemplateRequest) =>
  jsonFetch<ITestResultPreviewResponse>(TEST_CREATE_FROM_TEMPLATE, {
    method: 'POST',
    body: JSON.stringify(request),
  });
