import * as React from 'react';
import wrapIntl, { IWrapIntl } from '../../atoms/wrapIntl';
import { withRouter, RouteComponentProps } from 'react-router';
import { Option } from 'react-select';
import { FormattedMessage } from 'react-intl';
import { IStringMap } from '../../../utils';
import InlineFormSelect from '../../atoms/InlineForm/InlineFormSelect';
import InlineFormInput from '../../atoms/InlineForm/InlineFormInput';
import { IProfileData, editProfile } from './data';
import { _switch } from '../../../utils/option';
import Fetch from '../../atoms/Fetch';
import LoadingSpinner from '../../atoms/Animations/LoadingSpinner';
import { RequestContext } from 'components/atoms/RequestContext';
import { Box, Flex, Typography } from '@xcorejs/ui';

interface IProfileEditState {
  user: Partial<IProfileData>;
  errors: IStringMap<string[]>;
}

interface ICountry {
  id: string;
  name: string;
}

interface IRegion {
  id: string;
  name: string;
}

interface IProps {
  onSubmit: () => void;
}

type Props = IProps & IWrapIntl & RouteComponentProps<any> & { child?: boolean };

class AddDemographicWithoutUser extends React.Component<Props, IProfileEditState> {
  public state: IProfileEditState = { user: {}, errors: {} };

  public render() {
    const { formatMsg, children, child } = this.props;
    const errors = this.state.errors;
    const user = this.state.user;

    return (
      <RequestContext>
        {({ locale }) => (
          <form onSubmit={this.onSubmit}>
            <Box maxWidth='41.6rem' mx='auto'>
              <Flex flexDirection='column' mt='3.2rem'>
                <Typography mb='0.8rem' textAlign='center' color='rgba(255, 255, 255, 0.6)'>
                  <FormattedMessage id={'demoInline.born'} />{' '}<br />
                </Typography>

                {/* <FormattedMessage id={'demo.yearOfBirth.error'}>
                  {message => ( */}
                <InlineFormInput
                  type='number'
                  name='yearOfBirth'
                  required
                  value={user.yearOfBirth === 0 ? '' : user.yearOfBirth}
                  error={
                    errors.YearOfBirth && errors.YearOfBirth[0] ||
                        user.yearOfBirth &&
                          user.yearOfBirth !== 0 &&
                          (new Date(Date.now()).getFullYear() - 13 < user.yearOfBirth ? 
                            formatMsg('demo.yearOfBirth.error2') :
                            new Date(Date.now()).getFullYear() - 16 < user.yearOfBirth ? 
                              formatMsg('demo.yearOfBirth.error3') :
                              user.yearOfBirth < 1910 ? formatMsg('demo.yearOfBirth.error') : '') ||
                        undefined
                  }
                  onChange={this.handleInput}
                  placeholder={formatMsg('demo.year.placeholder')}
                />
              </Flex>

              {locale === 'cs' && (
                <Flex flexDirection='column' mt='2.4rem'>
                  <Typography mb='0.8rem' textAlign='center' color='rgba(255, 255, 255, 0.6)'>
                    <FormattedMessage id={'demoInline.region'} />{' '}
                  </Typography>

                  <Fetch url={'/api/v1/utils/regions'}>
                    {_switch({
                      success: (result: IRegion[]) => (
                        <InlineFormSelect
                          name='regionId'
                          required
                          placeholder={formatMsg('demoInline.region.placeholder')}
                          error={errors.Region && errors.Region[0]}
                          value={user.regionId}
                          onChange={this.handleSelect('regionId') as any}
                          options={[
                            {
                              value: 0,
                              label: formatMsg('demoInline.region.placeholder'),
                              disabled: true,
                            },
                            ...result.map(x => ({
                              value: x.id,
                              label: x.name,
                            })),
                          ]}
                        />
                      ),
                      fetching: () => <LoadingSpinner />,
                      reload: () => <LoadingSpinner />,
                      fail: () => <></>,
                    })()}
                  </Fetch>
                </Flex>
              )}

              <Flex flexDirection='column' mt='2.4rem'>
                <Typography mb='0.8rem' textAlign='center' color='rgba(255, 255, 255, 0.6)'>
                  <FormattedMessage id={'demoInline.andiam'} />
                </Typography>

                <InlineFormSelect
                  name='maritalStatus'
                  required
                  placeholder={formatMsg('demo.maritalStatus.placeholder')}
                  error={errors.MaritalStatus && errors.MaritalStatus[0]}
                  value={user.maritalStatus}
                  onChange={this.handleSelect('maritalStatus') as any}
                  options={[
                    {
                      value: 0,
                      label: formatMsg('demo.maritalStatus.placeholder'),
                      disabled: true,
                    },
                    {
                      value: 1,
                      label: formatMsg('demo.maritalStatus.single'),
                    },
                    {
                      value: 2,
                      label: formatMsg('demo.maritalStatus.married'),
                    },
                  ]}
                />
              </Flex>

              <Flex flexDirection='column' mt='2.4rem'>
                <Typography mb='0.8rem' textAlign='center' color='rgba(255, 255, 255, 0.6)'>
                  <FormattedMessage id={'demoInline.work'} />{' '}
                </Typography>

                <InlineFormSelect
                  name='workType'
                  required
                  placeholder={formatMsg('demo.work.placeholder')}
                  error={errors.Work && errors.Work[0]}
                  value={user.workType}
                  onChange={this.handleSelect('workType') as any}
                  options={[
                    {
                      value: 0,
                      label: formatMsg('demo.work.placeholder'),
                      disabled: true,
                    },
                    {
                      value: 1,
                      label: formatMsg('demo.work.head'),
                    },
                    {
                      value: 2,
                      label: formatMsg('demo.work.hands'),
                    },
                  ]}
                />
              </Flex>

              {children}
            </Box>
          </form>
        )}
      </RequestContext>
    );
  }

  private handleInput = (e: React.ChangeEvent<any>) =>
    this.setState({
      user: {
        ...this.state.user,
        [e.target.name]: e.target.value,
      },
    });

  private handleSelect = (name: string) => (option: Option) =>
    this.setState({
      user: {
        ...this.state.user,
        [name]: option.value,
      },
    });

  private onSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();

    if (
      this.state.user &&
      this.state.user.yearOfBirth &&
      new Date(Date.now()).getFullYear() - (this.props.child ? 13 : 16)  < this.state.user.yearOfBirth
    ) {
      return;
    }

    editProfile(this.state.user!)
      .then(() => this.props.onSubmit())
      .catch(({ data }) => data.json().then((e: any) => this.setState({ errors: e })));
  };
}

export default withRouter(wrapIntl(AddDemographicWithoutUser));
