import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { BoxProps, Box } from '@xcorejs/ui';
import { mediaMdDefault } from 'components/theme/grid';

interface AnimatedArrowProps extends BoxProps {
  color?: string;
  innerColor?: string;
  outerColor?: string;
  onlyInner?: boolean;
}

const AnimatedArrow: FC<AnimatedArrowProps> = ({ ...props }) => {
  return ( 
    <ArrowStyle
      {...props}
    >
      <div className='inner arrow'>
        <ArrowSVG />
      </div>
      <div className='outer arrow'>
        <ArrowSVG />
      </div>
    </ArrowStyle>
  );
};
 
export default AnimatedArrow;

const ArrowSVG: FC = () => {

  return (
    <svg viewBox='0 0 8 24' width='24' height='64' fill='none' strokeWidth='2' stroke='currentColor' strokeLinecap='round'>
      <polyline points='2 2, 6 12, 2 22' />
    </svg>
  );
};

const ArrowStyle = styled(Box)<AnimatedArrowProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  .arrow {
    color: ${({ color }) => color ? color : '#FFF'};
  }

  .outer {
    ${({ outerColor }) => outerColor ? css`color: ${outerColor};` : ''}
    ${({ onlyInner }) => onlyInner ? css`opacity: 0;` : ''}
    transform: translateX(-30%);
  }

  .inner {
    ${({ innerColor }) => innerColor ? css`color: ${innerColor};` : ''}
    animation: move 5s infinite ease;
    -moz-animation: move 5s infinite ease;
    -webkit-animation: move 5s infinite ease;
  
    @keyframes move {
      0% {
        transform: translateX(15%);
        opacity: 1;
      }
      38% {
        transform: translateX(30%);
        opacity: 0.6;
      }
      42% {
        transform: translateX(30%);
        opacity: 0.6;
      }
      80% {
        transform: translateX(15%);
        opacity: 1;
      }
      100% {
        transform: translateX(15%);
        opacity: 1;
      }
    }

    @-moz-keyframes move {
      0% {
        transform: translateX(15%);
        opacity: 1;
      }
      38% {
        transform: translateX(30%);
        opacity: 0.6;
      }
      42% {
        transform: translateX(30%);
        opacity: 0.6;
      }
      80% {
        transform: translateX(15%);
        opacity: 1;
      }
      100% {
        transform: translateX(15%);
        opacity: 1;
      }
    }

    @-webkit-keyframes move {
      0% {
        transform: translateX(15%);
        opacity: 1;
      }
      38% {
        transform: translateX(30%);
        opacity: 0.6;
      }
      42% {
        transform: translateX(30%);
        opacity: 0.6;
      }
      80% {
        transform: translateX(15%);
        opacity: 1;
      }
      100% {
        transform: translateX(15%);
        opacity: 1;
      }
    }
  }
`;