import React, { FC, useState, useEffect, useMemo } from 'react';
import Ladders from 'components/pages/App/Statistics/components/Ladders';
import Groups from 'components/pages/App/Statistics/components/Groups';
import { GAME_STATUS, IGameStatus } from 'config/api/game';
import { _switch } from 'utils/option';
import { STATISTICS_GROUP_MATCH_TEST } from 'config/apiRoutes';
import { IStatistic } from 'components/molecules/StatisticsSlider/data';
import LoadingSpinnerLocal from 'components/atoms/Animations/LoadingSpinnerLocal';
import StatisticsModal, { StatisticsModalProps } from 'components/pages/App/Statistics/components/StatisticModal';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { ModalContentStyle, StatisticsModalStyle } from '../style';
import { Flex, Paragraph, Box, Img, Tag } from '@xcorejs/ui';
import { IUserInfo } from 'store/User';
import { OliLight } from 'components/theme/fonts';
import { jsonFetch } from 'utils/fetch';

interface TopValuesModalProps extends StatisticsModalProps {
  resultId?: string;
  user: IUserInfo | null;
}

const TopValuesModal: FC<TopValuesModalProps> = ({ resultId, user }) => {
  const [gameStatusResult, setGameStatusResult] = useState<IGameStatus | null>();
  const [statisticsResult, setStatisticsResult] = useState<IStatistic[] | null>();

  useEffect(() => {
    const jsonFetchStatistics = async (id: string) => {
      return jsonFetch<IStatistic[]>(STATISTICS_GROUP_MATCH_TEST(id))
        .then(statistics => {
          setStatisticsResult(statistics);
        });
    };
    
    if (!resultId) {
      jsonFetch<IGameStatus>(GAME_STATUS)
        .then(gameStatus => {
          setGameStatusResult(gameStatus);
          return gameStatus;
        })
        .then(gameStatus => {
          jsonFetchStatistics(gameStatus.tribalTestResult.id);
        })
        .catch(err => console.log(err));
    } else {
      jsonFetchStatistics(resultId)
        .catch(err => console.log(err));
    }
  }, [resultId]);

  const filteredStatistics = useMemo(() => {
    if (!statisticsResult) return null;
    const shortStatistics = statisticsResult.filter(s => s.ageGroup);
    const common = shortStatistics
      .reduce((max, stat) => max.value > stat.value ? max : stat);
    const uncommon = shortStatistics
      .reduce((max, stat) => max.value < stat.value ? max : stat);
    return { common, uncommon };
  }, [statisticsResult]);

  if (!statisticsResult || !filteredStatistics) {
    return <LoadingSpinnerLocal />;
  }
    
  return (
    <StatisticsModal
      colorScheme='rgb(71, 95, 150)'
      {...StatisticsModalStyle({ minHeightInput:'67.5rem', minMobileHeightInput:'66.5rem', colorSchemeInput:'blue' })}
      content={
        [
          {
            heading: <FormattedMessage id='statistics.topValues.content.heading' />,
            tag: <FormattedMessage id='statistics.topValues.content1.tag' />,
            text: <FormattedMessage id='statistics.topValues.content1.text' />,
            circle: {
              name: filteredStatistics.common.title + (filteredStatistics.common.perex && ', ' + filteredStatistics.common.perex),
              onlyPlusOrGame: true,
              img: filteredStatistics.common.image
            },
            box2: <Ladders values={filteredStatistics.common.topTenValueResults} user={user} />,
            ...ModalContentStyle
          },
          {
            heading: <FormattedMessage id='statistics.topValues.content.heading' />,
            tag: <FormattedMessage id='statistics.topValues.content2.tag' />,
            text: <FormattedMessage id='statistics.topValues.content2.text' />,
            circle: {
              name: filteredStatistics.uncommon.title + ', ' + filteredStatistics.uncommon.perex,
              onlyInterestGroup: true,
              img: filteredStatistics.uncommon.image
            },
            box2: <Ladders values={filteredStatistics.uncommon.topTenValueResults} user={user} />,
            ...ModalContentStyle
          },
          {
            heading: <FormattedMessage id='statistics.topValues.content.heading' />,
            tag: <FormattedMessage id='statistics.topValues.content3.tag' />,
            text: <FormattedMessage id='statistics.topValues.content3.text' />,
            box1: <Groups statistics={statisticsResult.filter(s => s.ageGroup)} />,
            box2: <SimilarFooter />,
            ...ModalContentStyle
          }
        ]
      }
    />
  );
};

export default TopValuesModal;

const SimilarFooter: FC = () => {
  return (
    <Flex
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      marginTop={{ _: '4rem', md: '4rem' }}
    >
      <Paragraph fontFamily={OliLight} maxWidth='30rem' fontSize='1.3rem'>
        <FormattedHTMLMessage id='statistics.topValues.similarFooter.text' />
      </Paragraph>

      <Flex marginTop='2rem' justifyContent='center' position='relative'>
        <Img src='/img/atlas/eticiameter.svg' alt='' width='80%' />
        <Tag
          borderRadius='0.5rem'
          bg='black'
          position='absolute' 
          transform='scale(0.8)'
          right='0'
          top='-1.5rem'>Připravujeme</Tag>
      </Flex>
    </Flex>
  );
};
