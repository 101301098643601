import * as React from 'react';

const SVGstar: React.FC = () => {
  return (
    <svg width='22px' height='20px' viewBox='0 0 22 20'>
      <g id='MVP-Tuning' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='Hodnoty-2-–-Landing-Page'
          transform='translate(-1179.000000, -865.000000)'
          fill='#ffde71'
          fillRule='nonzero'
        >
          <g id='premium' transform='translate(0.000000, 800.000000)'>
            <g transform='translate(541.000000, 50.000000)' id='c2a-small'>
              <g transform='translate(588.000000, 0.000000)'>
                <g id='inside' transform='translate(50.000000, 15.000000)'>
                  <path
                    d='M7.24682697,6.03786441 L10.104381,0.530273253 C10.4712164,-0.176757751 11.5309603,-0.176757751 11.8977957,0.530273253 L14.7553497,6.03786441 L21.1457165,6.92649346 C21.9656312,7.04050882 22.2924147,7.99934305 21.6988211,8.54938968 L17.0756101,12.8334351 L18.166697,18.8856889 C18.3068155,19.6629258 17.4492666,20.2555968 16.7156371,19.8885486 L11.0010883,17.029456 L5.28653961,19.8885486 C4.55291004,20.2555968 3.69536119,19.6629258 3.83547972,18.8856889 L4.92656655,12.8334351 L0.303355535,8.54938968 C-0.290237994,7.99934305 0.0365454473,7.04050882 0.856460124,6.92649346 L7.24682697,6.03786441 Z M11.0010883,3.10077064 L8.80779568,7.32807803 C8.66225594,7.60858835 8.38107073,7.80310563 8.05571654,7.8483486 L3.1495,8.53059528 L6.69882113,11.819533 C6.93469487,12.0381026 7.0423575,12.3531323 6.98669695,12.6618811 L6.14926913,17.3070896 L10.5356371,15.11251 C10.8270199,14.9667258 11.1751567,14.9667258 11.4665396,15.11251 L15.8529075,17.3070896 L15.0154797,12.6618811 C14.9598192,12.3531323 15.0674818,12.0381026 15.3033555,11.819533 L18.8526767,8.53059528 L13.9464601,7.8483486 C13.6211059,7.80310563 13.3399207,7.60858835 13.194381,7.32807803 L11.0010883,3.10077064 Z'
                    id='ico/star'
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SVGstar;
