import { API_URL } from '../apiRoutes';
import { jsonFetch } from 'utils/fetch';

export const INVITATIONS = API_URL + '/friends';

export interface IInvitation {
  id: string;
  status: string;
  createdAt: number;
  updatedAt: number;
  profile: {
    id: string;
    fullname: string;
    nick: string;
    sex: string;
  };
}

export interface IInvitations {
  acceptedCount: number;
  availableCount: number;
  monthLimitCount: number;
  invitations: IInvitation[];
}

export const sendInvitation = (email: string, text?: string) =>
  jsonFetch(INVITATIONS, {
    method: 'POST',
    body: JSON.stringify(text ? { email, text } : { email }),
  });
