import * as React from 'react';
import { StyledFormGroup } from './styles';

interface IFormGroup {
  className?: string;
  error?: React.ReactNode;
}

export const FormGroup: React.FC<IFormGroup> = ({ className, error, children }) => {
  return (
    <StyledFormGroup className={className}>
      {children}
      {error ? <span className={'error'}>{error}</span> : ''}
    </StyledFormGroup>
  );
};

export default FormGroup;
