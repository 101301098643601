import React, { FC, useCallback, useState } from 'react';
import {
  COMPARE,
  COMPARE_MATRIX,
  COMPARE_TESTS,
  TESTS_RESULTS
} from '../../../config/apiRoutes';
import {
  CompareConfigMatrix,
  CompareConfigTestResultPreview,
  CompareDetail
} from './data';
import LoadingSpinnerLocal from '../../atoms/Animations/LoadingSpinnerLocal';
import useFetch from '../../atoms/Fetch/useFetch';
import { ITestResultPreviewResponse } from '../../pages/App/TestIntro/data';
import { toDate } from '../../pages/App/ValueComparison/StartTest/Intro';

interface CompareSelectProps {
  testResultId: string;
  onSelect: (testResultId: string) => void;
}

const CompareSelect: FC<CompareSelectProps> = ({ testResultId, onSelect }) => {
  const { result: testResults } = useFetch<CompareConfigTestResultPreview[]>(
    COMPARE_TESTS(testResultId)
  );

  if (testResults === null) {
    return <LoadingSpinnerLocal />;
  }

  return (
    <>
      <strong>{testResultId}</strong>
      <ul>
        {testResults.map((testResult, i) => (
          <li key={i}>
            {testResult.template.name} {toDate(testResult.createdAt)}
            <button onClick={() => onSelect(testResult.id)}>select</button>
          </li>
        ))}
      </ul>
    </>
  );
};

interface CompareDetailProps {
  testResultIdA: string;
  testResultIdB: string;
}

const CompareDetail: FC<CompareDetailProps> = ({
  testResultIdA,
  testResultIdB
}) => {
  const { result } = useFetch<CompareDetail>(
    COMPARE(testResultIdA, testResultIdB)
  );

  if (result === null) {
    return <LoadingSpinnerLocal />;
  }

  return (
    <>
      {result.compareConfig.mainHeader}
      {result.valueTestResultA.eticon.name}
      {result.valueTestResultB.eticon.name}
    </>
  );
};

const Compare: FC = () => {
  const [testResultIdA, setTestResultIdA] = useState<string | null>(null);
  const [testResultIdB, setTestResultIdB] = useState<string | null>(null);

  const { result: testResults } = useFetch<ITestResultPreviewResponse[]>(
    TESTS_RESULTS
  );

  const { result: compareConfig } = useFetch<CompareConfigMatrix[]>(
    COMPARE_MATRIX
  );

  const onSelect = useCallback(
    (testResultId: string) => {
      setTestResultIdB(testResultId);
    },
    [setTestResultIdB]
  );

  if (compareConfig === null || testResults === null) {
    return <LoadingSpinnerLocal />;
  }

  return (
    <>
      <h1>Matrix</h1>
      <hr />
      <ul>
        {compareConfig.map((config, i) => (
          <li key={i}>
            <h3>{config.name}</h3>
            <ul>
              {config.targets.map((target, i) => (
                <li key={i}>{target.templateB.name}</li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
      <hr />
      <h1>Test results</h1>
      <ul>
        {testResults
          .filter(testResult => testResult.doneAt !== 0)
          .map((testResult, i) => (
            <li key={i}>
              {testResult.valueTestResultId}
              <button onClick={() => setTestResultIdA(testResult.id)}>
                set
              </button>
            </li>
          ))}
      </ul>
      <hr />
      <h1>Select test to compare</h1>
      {!!testResultIdA && (
        <CompareSelect
          key={testResultIdA}
          testResultId={testResultIdA}
          onSelect={onSelect}
        />
      )}
      <hr />
      <h1>Compare</h1>
      {!!testResultIdA && !!testResultIdB && (
        <CompareDetail
          key={`${testResultIdA}_${testResultIdB}`}
          testResultIdA={testResultIdA}
          testResultIdB={testResultIdB}
        />
      )}
    </>
  );
};

export default Compare;
