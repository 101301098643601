import { GameContext } from 'components/App';
import useFetch from 'components/atoms/Fetch/useFetch';
import { ICurrentRound } from 'components/organisms/Test/data';
import { SURVEY_CURRENT_STEP } from 'config/api/surveyInternal';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { ThemeProvider } from 'styled-components';
import Test from '../../../organisms/Test';
import ValueDefinition from '../../../organisms/ValueDefinition';
import { CardsGridTheme } from '../../../theme/grid';
import { createUrl } from '../../routes';
import { addSurveyRecord, skipSurvey } from './data';

const Survey: React.FC<RouteComponentProps<{ id: string }>> = ({ match }) => {
  const [detail, setDetail] = React.useState<string | null>(null);
  const round = useFetch<ICurrentRound>(SURVEY_CURRENT_STEP(match.params.id));
  const { insideQuest } = useContext(GameContext);
  const [surveySkip, setSurveySkip] = useState(false);

  useEffect(() => {
    setSurveySkip(round.result ? round.result.survey.skipLastScreenWithResults : false);
  }, [round.result]);

  return (
    <>
      <ThemeProvider theme={CardsGridTheme}>
        <Test
          id={match.params.id}
          round={round}
          openValueDefinition={setDetail}
          sendData={addSurveyRecord}
          onFinish={surveySkip ? insideQuest ? () => createUrl('app') + '/hra' : () => createUrl('app') : () =>
            createUrl('app.surveyResult', {
              id: match.params.id,
            })
          }
          skip={skipSurvey}
        />
      </ThemeProvider>
      {detail !== null && <ValueDefinition value={detail} onClose={() => setDetail(null)} inTest={false} />}
    </>
  );
};

export default withRouter(Survey);
