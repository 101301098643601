import { Button, Flex, Grid, Icon, Img, Link, Paragraph, Tag, Typography } from '@xcorejs/ui';
import DelayedAccordion from 'components/atoms/Accordion/DelayedAccordion';
import LoadingSpinner from 'components/atoms/Animations/LoadingSpinner';
import { CompareConfigMatrix } from 'components/components/Compare/data';
import { createUrl } from 'components/pages/routes';
import { BlueTheme, RedTheme, yellow, yellowLighter, yellowWhite } from 'components/theme/colors';
import { OliLight, OliMedium, OliRegular } from 'components/theme/fonts';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CompareIcon } from '../../icons/16/compare.svg';
import { useGetCompareMatrix } from '../LaddersCompareMatrixModal/hooks';
import { closeModal, LaddersCompareModalProps } from './LaddersCompareModal';

const CompareWithoutTest: FC<LaddersCompareModalProps> = ({ result, children }) => {
  type ActiveCompareProps = {
    index: number;
    leftTitle: string;
    rightTitle: string;
    description: string;
  }

  const compareMatrix = useGetCompareMatrix();
  const [filteredMatrix, setFilteredMatrix] = useState<CompareConfigMatrix>();
  const [activeCompare, setActiveCompare] = useState<ActiveCompareProps | null>();
  const history = useHistory();

  useEffect(() => {
    if (compareMatrix) {
      compareMatrix.find(object => result.name.includes(object.name) && setFilteredMatrix(object));
    }
  }, [compareMatrix, result.name]);
   
  const isPicked = (index: number): boolean => {
    return activeCompare ? index === activeCompare.index : false;
  };

  useEffect(() => {
    console.log('filteredMatrix', filteredMatrix);
  }, [filteredMatrix]);
  
  return (
    <>
      <Typography
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        px='4rem'
        py='0.5rem'
        color='#555'
        fontSize='1.3rem'
        lineHeight='1.2'
        borderRadius='0.4rem'
        background='rgb(200, 200, 210)'
        maxWidth='32rem'
        width='100%'
        mx='auto'
      >
        Prozatím nemáš s čím tento žebříček porovnat.
      </Typography>

      {compareMatrix ? (
        <>
          <Flex flexDirection='column' alignItems='center' mt={{ _:'1rem' }} style={{ gap: '1rem' }}>
            {filteredMatrix ? (
              <>
                <Paragraph color='white' marginTop='2rem'>
                  Výběr z různých kombinací porovnání tohoto žebříčku
                </Paragraph>

                <Flex flexWrap='wrap' justifyContent='center' style={{ gap: '1rem' }}>
                  {filteredMatrix.targets.map((t, index) => (
                    <Grid
                      key={index}
                      columns='1fr'
                      rows='5rem 1fr'
                      gap='0.5rem 0'
                      px={{ _: '1.2rem', sm:'1.6rem' }}
                      py='1rem'
                      border='2px solid'
                      borderRadius='4px'
                      borderColor={isPicked(index) ? yellow : '#fff'}
                      flexDirection='column'
                      alignItems='start'
                      justifyItems='center'
                      width={{ _: '10rem', sm: '12rem' }}
                      cursor='pointer'
                      position='relative'
                      transition='border-color 0.3s ease-in-out'
                      _after={{
                        content: '""',
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        right: '0',
                        bottom: '0',
                        zIndex: 1,
                        opacity: isPicked(index) ? 1 : 0,
                        transition: 'opacity 0.3s ease-in-out',
                        boxShadow: 'inset 0 0 5px 2px ' + yellow,
                      }}
                      _hover={{
                        borderColor: yellow,
                      }}
                      onClick={() => setActiveCompare({ 
                        index,
                        leftTitle: t.templateA.name,
                        rightTitle: t.templateB.name,
                        description: t.mainDescription,
                      })}
                    >
                      {t.templateB.icon && <Img src={t.templateB.icon} mb='0.5rem' width={50} height={50} alt='' />}

                      <Typography color='#fff' fontSize='1.3rem' lineHeight='1.8rem' fontFamily={OliLight}>
                        {t.templateB.name}
                      </Typography>
                    </Grid>
                  ))}
                </Flex>

                {activeCompare ? (
                  <DelayedAccordion
                    delay={100}
                    flexDirection='column'
                    justifyContent='center'
                    alignItems='center'>
                    <Tag
                      marginTop='2rem'
                      color='rgba(255, 255, 255, 0.7)'
                      boxShadow='0 0 5px 2px rgba(0, 0, 0, 0.45)'
                      borderRadius='999rem'
                      background={BlueTheme.extraDark}
                      fontFamily={OliRegular}
                      padding='0.7rem 1.5rem'
                      lineHeight='1'
                      transition='opacity 0.5s ease-in-out'
                    >
                      Co ti toto porovnání přinese?
                    </Tag>
                    
                    <Grid
                      columns='1fr 4rem 1fr'
                      rows='auto'
                      gap='1rem'
                      marginTop='2rem'
                      alignItems='center'
                      justifyItems='center'
                      transition='opacity 0.5s ease-in-out'
                    >
                      <Paragraph 
                        color={yellow}
                        textAlign='right'
                        justifySelf='end'
                        fontFamily={OliMedium}>
                        {activeCompare.leftTitle}
                      </Paragraph>

                      <Icon
                        svg={<CompareIcon />}
                        fill={yellowLighter}
                        width='2.5rem'
                        height='2.5rem'
                        alignSelf='center'
                        border={'1px solid ' + yellowLighter}
                        borderRadius='9999px'
                        display='inline-flex'
                        alignItems='center'
                        justifyContent='center' />

                      <Paragraph 
                        color={yellow}
                        textAlign='left'
                        justifySelf='start'
                        fontFamily={OliMedium}>
                        {activeCompare.rightTitle}
                      </Paragraph>
                    </Grid>

                    <Typography
                      mt='1rem'
                      color='#fff'
                      transition='opacity 0.5s ease-in-out'
                    >
                      {activeCompare.description}
                    </Typography>

                    <Link
                      v='underline'
                      alignSelf='center'
                      marginTop='2rem'
                      color={yellow}
                      borderColor={yellow}
                      _hover={{
                        color: yellow
                      }}
                      onClick={() => closeModal(() => history.push(createUrl('app.journal') + '#compare-matrix'))}>
                        Přehled všech porovnání
                    </Link>
                  </DelayedAccordion>
                ) : null}
              </>
            ) : (
              <>
                <Paragraph color={yellow} marginTop='4rem'>
                  Nevadí! Připravili jsme pro tebe celou řadu zajímavých porovnání
                </Paragraph>

                <Button
                  marginTop='1rem'
                  alignSelf='center'
                  backgroundColor={RedTheme.primary}
                  color={RedTheme.text}
                  height='auto'
                  fontSize='1.4rem'
                  textDecoration='underline'
                  _hover={{
                    color: '#444',
                  }}
                  onClick={() => closeModal(() => history.push(createUrl('app.journal') + '#compare-matrix'))}>
                  Přečti si o všech porovnáních
                </Button>
              </>
            )}
          </Flex>
        </>
      ) : <LoadingSpinner />}

      {children}

      <Button
        onClick={() => closeModal()}
        alignSelf='center'
        height='4rem'
        backgroundColor='rgba(0, 0, 0, 0.2)'
        color='#fff'
        borderColor={yellowWhite}
        marginTop='1rem'
      >
        Zavřít
      </Button>
    </>
  );
};

export default CompareWithoutTest;
