import styled from 'styled-components';
import { yellow, gray } from '../../../../../theme/colors';
import {
  mediaHonzaDefault,
  mediaLandscapePhone,
  mediaLgDefault,
  mediaMdDefault,
  mediaSmDefault,
  mediaXlDefault,
} from '../../../../../theme/grid';
import { OliMedium } from 'components/theme/fonts';

export const StyledCard = styled.a`
  position: relative;
  width: 100%;
  min-height: 4rem;
  padding: 0.5rem 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: nowrap;
  border-radius: 1.2rem;
  background: ${props => props.theme.primary};
  background: radial-gradient(180deg, ${props => props.theme.primary}, ${props => props.theme.darken});
  box-shadow: 0 5px 15px 0 rgba(11, 12, 28, 0.2);
  transition: box-shadow 500ms ease, opacity 500ms ease;
  cursor: pointer;
  opacity: 1;
  z-index: 1;
  font-weight: 500;

  ${mediaSmDefault} {
    min-height: 11rem;
    padding: 1rem 1.5rem;
  }

  ${mediaMdDefault} {
    padding: 2rem 1.5rem;
    height: ${props => props.theme.ValueCard.height};
    font-weight: 400;
    min-height: 29rem;
    //max-height: 41rem;
    //max-height: 55rem;
    max-height: 100%;
  }

  ${mediaLandscapePhone} {
    min-height: 3.4rem;
  }

  &.disabled {
    opacity: 0.5;
  }

  .value-img {
    display: block;
    margin: 0 auto 0.7rem;
    width: 5.5rem;
    height: 5.5rem;

    ${mediaMdDefault} {
      display: block;
      max-width: 70%;
      margin-bottom: 0;
      width: auto;
      height: auto;
    }

    ${mediaLgDefault} {
      max-width: 60%;
      width: 17rem;
      position: relative;

      img {
        position: absolute;
        width: 100%;
        height: 100%;
      }

      &:after {
        content: " ";
        display: block;
        padding-bottom: 100%;
      }
    }
  }

  .rotate-ico {
    position: absolute;
    right: 0.3rem;
    top: 0.3rem;
    opacity: 0.25;
    transition: opacity 300ms ease;
    z-index: 1;
    transform: scale(0.8);

    ${mediaSmDefault} {
      right: 2rem;
      top: 2rem;
      width: auto;
      height: auto;
      transform: scale(1);
    }

    ${mediaMdDefault} {
      opacity: 0.5;
    }
  }

  .value-name {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    text-align: center;
    width: 100%;
    color: ${gray};
    font-size: 1.3rem;
    line-height: 1.6rem;
    letter-spacing: 0.5px;
    font-family: ${OliMedium};

    ${mediaSmDefault} {
      font-size: 1.6rem;
      line-height: 2rem;
    }

    ${mediaMdDefault} {
      min-height: 4rem;
      margin-top: 3rem;
      font-weight: 500;
    }

    ${mediaHonzaDefault} {
      margin-top: 1rem;
      font-size: 1.8rem;
    }
  }

  &:hover {
    text-decoration: none;
    box-shadow: 0 5px 10px 0 rgba(255, 255, 255, 0.3);

    .rotate-ico {
      opacity: 1;
    }
  }
`;

export const StyledValueCard = styled.div`
  display: flex;
  padding: 0 0 1rem;
  width: 100%;
  font-size: 1.4rem;
  vertical-align: top;

  ${mediaLandscapePhone} {
    padding-bottom: 0.6rem;
  }

  & > div {
    display: flex;
    width: 100%;
  }

  &.dragg {
    ${StyledCard} {
      box-shadow: 0 10px 20px 0 rgba(255, 255, 255, 0.25);
      cursor: grabbing;

      &:hover {
        box-shadow: 0 5px 10px 0 rgba(255, 255, 255, 0.3);
      }
    }
  }

  &.disabled {
    ${StyledCard} {
      cursor: default;
      opacity: 0.5;

      &:hover {
        box-shadow: 0 5px 10px 0 rgba(45, 49, 66, 0.3);
      }
    }
  }

  &.selected {
    width: 100%;

    ${StyledCard} {
      height: 100%;
      border: 2px solid ${yellow};

      .value-img {
        display: none;

        ${mediaHonzaDefault} {
          width: 7rem;
          height: 7rem;
        }

        ${mediaLandscapePhone} {
          display: none;
        }

        ${mediaMdDefault} {
          position: relative;
          width: 6rem;
          min-height: 6rem;
          height: 6rem;

          img {
            position: absolute;
            width: 100%;
            height: 100%;
          }
        }

        ${mediaLgDefault} {
          width: 8rem;
          min-height: 8rem;
          height: 8rem;
        }
        
        @media only screen and (max-height: 48em) {
          width: 6rem;
          height: 6rem;
          min-height: 6rem;
        }

        ${mediaXlDefault} {
          width: 10rem;
          height: 10rem;
          /* height: 90%;
          aspect-ratio: 1/1; */
        }
      }

      ${mediaMdDefault} {
        border: none;

        .value-img {
          display: flex;
          flex: 0 0 auto;
        }
      }

      ${mediaLgDefault} {
        .value-name {
          margin-top: 1rem;
          min-height: 2rem;
        }
      }
    }
  }
`;

export const StyledClickableCard = styled.div`
  width: 100%;
`;
