import { IStringMap } from './index';
import { remove } from './option';
import { matchPath } from 'react-router';

export interface IRoute<T> extends IFlatRoute<T> {
  children?: IStringMap<IRoute<T>>;
}

export interface IFlatRoute<T> {
  id?: string;
  path: string;
  component: any;
  exact?: boolean;
  data?: T;
}

export const processRoutes = (
  route: IRoute<{}>,
  source: IStringMap<IStringMap<string>>,
  locale: string,
  parent?: IFlatRoute<{}>,
  key?: string,
): Array<IFlatRoute<{}>> => {
  const flatRoute: IFlatRoute<{}> = {
    ...remove(route, 'children'),
    path: (parent !== undefined ? parent.path : '') + source[locale][route.path],
    id:
      (parent !== undefined && parent.id !== 'homepage' ? parent.id + '.' : '') +
      (key !== undefined ? key : 'homepage'),
  };
  return [
    ...route.children
      ? Object.keys(route.children).reduce(
        (acc, k) => [...acc, ...processRoutes(route.children![k], source, locale, flatRoute, k)],
          [] as Array<IFlatRoute<{}>>,
      )
      : [],
    flatRoute,
  ];
};

interface IRouteUrlPair<T> {
  route: IRoute<T> | undefined;
  url: string;
}

export const createUrlFactory = <T extends {}>(
  route: IRoute<T>,
  source: IStringMap<IStringMap<string>>,
  locale: () => string,
) => (url: string, args: IStringMap<string> = {}) =>
    applyUrlArgs(
      (url + (url !== '' ? '.' : ''))
        .split('.')
        .reduce((acc, val) => createRouteUrlPair(acc, val, source[locale()]), {
          route: route as IRoute<T> | undefined,
          url: '',
        }).url,
      args,
    );

const applyUrlArgs = (url: string, args: IStringMap<string> = {}) =>
  Object.keys(args).reduce((acc, k) => acc.replace(':' + k, args[k]), url);

const createRouteUrlPair = <T extends {}>(
  pair: IRouteUrlPair<T>, 
  val: string, source: IStringMap<string>
) =>
    pair.route
      ? {
        route: pair.route.children && pair.route.children[val],
        url: pair.url + (source[pair.route.path] || pair.route.path),
      }
      : { route: undefined, url: '/en/404' };

export const matchRoute = (path: string, routes: Array<IFlatRoute<{}>>) =>
  routes.find(r => matchPath(path, r) !== null);

export const normalizeUrl = (path: string) => path.replace(/\/$/, '');
