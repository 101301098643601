import * as React from 'react';

const SVGlink: React.FC = () => {
  return (
    <svg width='22px' height='22px' viewBox='0 0 22 22' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <g id='MVP-FInal' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='Proces-porovnání-–-Okdaz'
          transform='translate(-1281.000000, -239.000000)'
          fill='#F4F4F6'
          fillRule='nonzero'
        >
          <g id='CONTENT' transform='translate(310.000000, 0.000000)'>
            <g id='link' transform='translate(490.000000, 224.000000)'>
              <g transform='translate(127.000000, 15.000000)' id='ico/link'>
                <path d='M362.184386,12.6086951 C361.851825,12.1637573 361.942647,11.5332626 362.387242,11.2004456 C362.831837,10.8676287 363.461846,10.9585206 363.794407,11.4034583 C364.493257,12.33846 365.563438,12.9236002 366.727194,13.0070104 C367.890949,13.0904205 369.03353,12.6638761 369.858516,11.8379544 L372.862176,8.83219473 C374.386102,7.25314175 374.364307,4.74315796 372.813192,3.19084685 C371.262076,1.63853575 368.754026,1.61672467 367.186574,3.13164065 L365.457455,4.85203176 C365.063717,5.243782 364.427198,5.24192483 364.035749,4.84788365 C363.644301,4.45384247 363.646157,3.81683266 364.039894,3.42508242 L365.779399,1.6945069 C368.146154,-0.593143703 371.90823,-0.560427082 374.234903,1.76803958 C376.561576,4.09650624 376.594268,7.86148192 374.296133,10.2425317 L371.280357,13.2606327 C370.04307,14.4993217 368.3292,15.1391384 366.583566,15.0140232 C364.837933,14.888908 363.232661,14.0111976 362.184386,12.6086951 Z M367.815614,9.39130495 C368.148175,9.83624268 368.057353,10.4667374 367.612758,10.7995544 C367.168163,11.1323713 366.538154,11.0414794 366.205593,10.5965417 C365.506743,9.66153999 364.436562,9.07639975 363.272806,8.99298963 C362.109051,8.9095795 360.96647,9.33612392 360.141484,10.1620456 L357.137824,13.1678053 C355.613898,14.7468583 355.635693,17.256842 357.186808,18.8091531 C358.737924,20.3614643 361.245974,20.3832753 362.811351,18.8704304 L364.530417,17.1500393 C364.923011,16.7571419 365.559533,16.7571419 365.952128,17.1500393 C366.344723,17.5429367 366.344723,18.1799492 365.952128,18.5728465 L364.220601,20.3054931 C361.853846,22.5931437 358.09177,22.5604271 355.765097,20.2319604 C353.438424,17.9034938 353.405732,14.1385181 355.703867,11.7574683 L358.719643,8.73936726 C359.95693,7.50067827 361.6708,6.86086164 363.416434,6.98597683 C365.162067,7.11109202 366.767339,7.98880237 367.815614,9.39130495 Z' />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SVGlink;
