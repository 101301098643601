import React from 'react';
import { Heading1, Typography, Paragraph, Heading3 } from '@xcorejs/ui';
import { FormattedMessage } from 'react-intl';
import { Button } from '../styles';
import Fade from 'react-reveal/Fade';
import { useHistory } from 'react-router-dom';
import { createUrl } from 'components/pages/routes';
import Parallax from 'components/components/Parallax';

const ValueGame = () => {
  const history = useHistory();
  return ( 
    <Parallax backgroundImage='/img/homepage/HRA_ETICIA_hp.jpg'>
      <Fade ssrReveal bottom duration={1000} distance={'15rem'}>
        <Heading1 textAlign='center'>
          <FormattedMessage id='homepage.box.valueGame.title' />
        </Heading1>

        <Typography fontFamily='Oli Grotesk Light' mt='2rem' color='#c0a79c' fontSize={{ _: '1.5rem', md: '1.8rem' }}>
          <FormattedMessage id='homepage.box.valueGame.info' />
        </Typography>
        
        <Heading3 mt='6rem'>
          <FormattedMessage id='homepage.box.valueGame.subtitle' />
        </Heading3>
        
        <Paragraph fontFamily='Oli Grotesk Light' mt='4rem' fontSize={{ _: '1.5rem', md: '1.8rem' }}>
          <FormattedMessage id='homepage.box.valueGame.paragraph' />
        </Paragraph>

        <Button
          mt='4rem'
          backgroundColor='#000a'
          borderColor='#3d8c71'
          color='#d2fee9'
          onClick={() => history.push(createUrl('valueGame'))}
        >
          O hodnotové hře
        </Button>
      </Fade>
    </Parallax>
  );
};
 
export default ValueGame;
