import { connect } from 'react-redux';
import { fetchUserInfo, logout } from '../../../../../store/User/UserActions';
import Settings from './component';
import { addError, addInfo } from 'store/FlashMessages';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: any) => ({
  getProfile: () => {
    dispatch(fetchUserInfo());
  },
  addError: (msg: string) => {
    dispatch(addError(msg));
  },
  addInfo: (msg: string) => {
    dispatch(addInfo(msg));
  },
  logout: () => logout(),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
