import styled, { css } from 'styled-components';
import { darkBaseRGB, yellow, gray, grayRGB } from '../../../theme/colors';
import { mediaLandscapePhoneAndSm, mediaSmDefault, mediaMdDefault, mediaLandscapePhone } from '../../../theme/grid';

export const FakeCheckboxMark = styled.span`
  width: 3rem;
  height: 3rem;
  display: inline-block;
  box-sizing: border-box;
  position: absolute;
  margin-top: -0.2rem;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.25);
  border: 2px solid ${gray};
  transition: background 0.3s, border-color 0.3s ease, opacity 0.3s;
  opacity: 0.5;

  &:before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background: url(/img/ico/check-white.svg);
    background-size: cover;
    opacity: 0;
    transition: opacity 300ms ease;
    width: 1.6rem;
    height: 1.1rem;
  }
`;

export const StyledQuestionInput = styled.div`
  position: relative;
  text-align: left;
  margin-top: 2rem;

  &:first-child {
    margin-top: 0;
  }

  &:hover {
    ${FakeCheckboxMark} {
      opacity: 1;
    }
  }

  input[type="checkbox"],
  input[type="radio"] {
    display: none;
    opacity: 0;
    position: absolute;

    & + label {
      display: inline-flex;
      align-items: center;
      margin-left: 0;
      padding-left: 4.5rem;
      min-height: 3rem;
      font-size: 1.3rem;
      line-height: 1.7rem;
      transition: text-shadow 0.3s;

      &:before,
      &:after {
        display: none;
      }

      ${mediaSmDefault} {
        font-size: 1.8rem;
        line-height: 2.2rem;
        padding-left: 5.2rem;
      }
    }

    &:checked + label {
      text-shadow: 0 0 1px #fff;

      ${FakeCheckboxMark} {
        opacity: 1;
      }

      &:before {
        background-color: rgba(25, 29, 44, 0.25);
      }

      &:after {
        opacity: 1;
      }
    }

    &:hover {
      & + label {
        text-shadow: 0 0 1px #fff;

        ${FakeCheckboxMark} {
          opacity: 1;
          border-color: rgba(255, 255, 255, 0.8);
        }
      }
    }

    &:checked + label {
      ${FakeCheckboxMark} {
        background-color: rgba(25, 29, 44, 0.25);
        border-color: ${yellow};

        &:before {
          opacity: 1;
        }
      }
    }
  }

  label {
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const StyledQuestionRadio = styled(StyledQuestionInput)`
  ${FakeCheckboxMark} {
    border-radius: 50%;

    &:before {
      background: #fff;
      width: 1.6rem;
      height: 1.6rem;
      border-radius: 50%;
    }
  }
`;

export const StyledQuestionCheckbox = styled(StyledQuestionInput)``;

export const Scaler = styled.div`
  margin-top: 1rem;
  margin-bottom: -3rem;
  display: flex;
  flex-direction: column;
  width: 100%;

  ${mediaSmDefault} {
    margin-top: 0;
    margin-bottom: 0;
    width: auto;
  }
`;

export const Scale = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &.doubleColumn {
    flex-direction: row;
    flex-wrap: wrap;

    & > div {
      flex: 0 0 40%;

      & > span {
        ${mediaSmDefault} {
          margin-left: 1.5rem;
        }

        ${mediaMdDefault} {
          margin-left: 2.5rem;
        }
      }
    }

    ${mediaSmDefault} {
      flex-wrap: nowrap;
      flex-direction: row;

      & > div {
        flex: 0 0 auto;
      }
    }

    ${StyledQuestionRadio} {
      ${mediaLandscapePhone} {
        margin-left: 5rem;

        &:after {
          width: 9rem;
        }
      }

      ${mediaSmDefault} {
        margin-left: 2rem;

        &:after {
          width: 2rem;
        }
      }

      ${mediaMdDefault} {
        margin-left: 5rem;

        &:after {
          width: 5rem;
        }
      }
    }
  }

  ${mediaLandscapePhone} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    & > div {
      &:first-child {
        ${StyledQuestionRadio} {
          margin-left: 0;

          &:after {
            display: none;
          }
        }
      }
    }
  }

  ${mediaSmDefault} {
    flex-wrap: nowrap;
    flex-direction: row;
  }

  ${StyledQuestionRadio} {
    margin-top: 0;
    height: 3rem;

    ${mediaLandscapePhone} {
      margin-left: 5rem;
    }

    ${mediaSmDefault} {
      margin-left: 7rem;
      height: auto;

      &:after {
        opacity: 0.5;
        background-color: #d8d8d8;
        content: "";
        display: block;
        width: 3rem;
        height: 2px;
        position: absolute;
        top: 50%;
        right: 100%;
        margin-top: -1px;

        ${mediaLandscapePhoneAndSm} {
          width: 9rem;
        }

        ${mediaSmDefault} {
          width: 7rem;
        }

        ${mediaMdDefault} {
          width: 11rem;
        }
      }
    }

    ${mediaMdDefault} {
      margin-left: 11rem;
    }

    ${mediaSmDefault} {
      &:first-child {
        margin-left: 0;

        &:after {
          content: none;
        }
      }
    }

    input[type="checkbox"],
    input[type="radio"] {
      display: none;
      opacity: 0;
      position: absolute;

      & + label {
        padding: 0;
        min-width: 3rem;
        min-height: 3rem;

        ${FakeCheckboxMark} {
          border-radius: 50%;
          width: 3rem;
          height: 3rem;

          &:before {
            width: 60%;
            height: 60%;
            border-radius: 50%;
            background: url(/img/ico/check-white.svg) center center no-repeat;
            background-size: contain;
          }
        }

        ${mediaSmDefault} {
          min-width: 5rem;
          min-height: 5rem;

          ${FakeCheckboxMark} {
            border-radius: 50%;
            width: 5rem;
            height: 5rem;
          }
        }
      }
    }
  }

  & > div {
    position: relative;
    padding-top: 2rem;
    display: flex;
    align-items: center;
    width: 100%;

    &:first-child {
      padding-top: 0;
    }

    ${mediaSmDefault} {
      padding-top: 1rem;
    }

    ${mediaLandscapePhone} {
      display: block;
      padding-top: 4rem;
      width: auto;

      &:first-child {
        padding-top: 4rem;
      }

      &:nth-child(even) {
        & > span {
          top: 11.3rem;
        }
      }
    }

    ${mediaLandscapePhoneAndSm} {
      display: block;
      padding-top: 4rem;
      width: auto;

      &:first-child {
        padding-top: 4rem;
      }
    }

    ${mediaSmDefault} {
      &:nth-child(even) {
        & > span {
          top: 15.3rem;
        }
      }
    }

    &:first-child {
      & > span {
        margin-left: 0;
      }

      ${StyledQuestionRadio} {
        ${mediaSmDefault} {
          margin-left: 0;

          &:after {
            content: none;
          }
        }
      }
    }

    & > span {
      min-width: 50%;
      margin-right: 2rem;
      font-size: 1.3rem;
      line-height: 1.7rem;
      font-weight: 800;
      text-align: right;

      ${mediaSmDefault} {
        font-size: 1.6rem;
        line-height: 2.1rem;
      }

      ${mediaLandscapePhoneAndSm} {
        position: absolute;
        margin-top: -4rem;
        margin-left: 2rem;
        margin-right: 0;
        left: 50%;
        min-width: auto;
        text-align: center;
        transform: translateX(-50%);
        white-space: nowrap;
      }

      ${mediaLandscapePhone} {
        margin-top: -3rem;
        margin-left: 2.5rem;
      }

      ${mediaSmDefault} {
        margin-left: 3.5rem;
      }

      ${mediaMdDefault} {
        margin-left: 5.5rem;
      }
    }
  }
`;

export const ScalerImgs = styled.div`
  margin-bottom: 3rem;

  ${mediaSmDefault} {
    margin-bottom: 6rem;
  }

  img {
    display: block;
    margin: 0 auto;
  }
`;

export const QuestionBox = styled.div<{
  isSelected?: boolean;
  isDisabled?: boolean;
  textOnly?: boolean;
  withHelpModal?: boolean;
}>`
  position: relative;
  display: inline-flex;
  padding: 0.75rem;
  padding-bottom: ${props => props.withHelpModal ? 'calc(0.75rem + 4rem)' : '0.75rem'};
  flex: 0 1 50%;

  ${props =>
    props.textOnly &&
    css`
      flex: 0 1 100%;
      padding: 0.5rem 0.75rem;
      padding-bottom: ${props.withHelpModal ? 'calc(0.75rem + 4rem)' : '0.75rem'};
    `};

  ${mediaSmDefault} {
    padding-bottom: ${props => props.withHelpModal ? 'calc(18% + 2.8rem)' : '18%'};
    flex: 0 1 25%;
  }

  & > div {
    width: 100%;
    height: 100%;
    min-height: 10rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s, background 0.3s, border 0.3s;
    border: 2px solid rgba(${grayRGB}, 0.5);
    background-color: rgba(0, 0, 0, 0.08);
    cursor: pointer;

    ${mediaSmDefault} {
      position: absolute;
      width: calc(100% - 3rem);
      height: ${props => props.withHelpModal ? 'calc(100% - 5.8rem)' : 'calc(100% - 3rem)'}
    }

    ${props =>
    props.textOnly &&
      css`
        min-height: 0;

        ${mediaSmDefault} {
          min-height: 10rem;
        }
      `}
  }

  p {
    padding: 1.5rem 2rem;
    color: ${gray};
    font-size: 1.3rem;
    line-height: 1.7rem;

    ${mediaSmDefault} {
      padding: 1rem;
      font-size: 1.4rem;
      line-height: 2.1rem;
    }

    ${mediaMdDefault} {
      font-size: 1.8rem;
      line-height: 2.7rem;
    }
  }

  img {
    width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
  }

  &:hover {
    & > div {
      border: 2px solid rgba(${grayRGB}, 1);
      background-color: rgba(255, 255, 255, 0.25);
    }
  }

  ${props =>
    props.isSelected &&
    css`
      & > div {
        border: 2px solid ${yellow};
        background-color: rgba(${darkBaseRGB}, 0.25);
      }

      &:hover {
        & > div {
          border: 2px solid ${yellow};
          background-color: rgba(${darkBaseRGB}, 0.25);
        }
      }

      p {
        text-shadow: 0 0 1px #fff;
      }

      img {
        opacity: 1;
      }
    `};

  ${props =>
    props.isDisabled &&
    css`
      & > div {
        img {
          opacity: 0.4;
        }
      }

      &:hover {
        & > div {
          cursor: not-allowed;

          &:first-child {
            border: 2px solid rgba(${grayRGB}, 0.5);
            background-color: rgba(255, 255, 255, 0.125);
          }

          img {
            opacity: 0.4;
          }
        }
      }
    `};
`;

export const QuestionFlexBox = styled.div<{ grid?: string }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 132rem;
  margin: 2rem -1.8rem -3.75rem;

  ${mediaSmDefault} {
    margin: 4.5rem auto -1.5rem;
  }

  ${props =>
    props.grid === 'big2Small' &&
    css`
      & > ${QuestionBox} {
        &:last-child {
          span {
            ${QuestionBox} {
              padding-bottom: 35%;
            }
          }
        }
      }

      span {
        position: absolute;
        width: 100%;
        left: 0;

        ${QuestionBox} {
          width: 100%;
          padding-bottom: 74%;
        }
      }
    `};

  ${mediaSmDefault} {
    ${props =>
    props.grid === 'small' &&
      css`
        max-width: 66rem;

        ${QuestionBox} {
          flex: 0 1 50%;
          padding-bottom: 36%;
        }
      `};

    ${props =>
    props.grid === 'images' &&
      css`
        ${QuestionBox} {
          flex: 0 1 50%;
          padding-bottom: 36%;
        }
      `};

    ${props =>
    props.grid === '3row' &&
      css`
        max-width: 99rem;

        ${QuestionBox} {
          flex: 0 1 33.33%;
          padding-bottom: 23%;
        }
      `};

    ${props =>
    props.grid === 'big2Small' &&
      css`
        max-width: 99rem;

        & > ${QuestionBox} {
          &:first-child {
            flex: 0 0 66.67%;
            padding-bottom: calc(48% + 0.7rem);

            ${QuestionBox} {
              width: 100%;
              height: calc(100% + 1.5rem);
            }
          }

          &:last-child {
            flex: 0 1 33.33%;
            padding-bottom: calc(48% + 0.7rem);

            span {
              ${QuestionBox} {
                padding-bottom: 73%;
                width: 100%;
              }
            }
          }

          & > span {
            position: absolute;
            width: 100%;
            height: 100%;
          }
        }

        ${QuestionBox} {
          flex: 0 1 33.33%;
          padding-bottom: 23%;
        }
      `};
  }
`;
