import styled from 'styled-components';
import { darkBaseRGB, yellow, gray, textGreen } from '../../../../theme/colors';
import { CustomText, SectionText } from '../../../../atoms/Text';
import { mediaMdDefault, mediaSmDefault } from '../../../../theme/grid';
import {
  StyledFakeLinkBorder,
  StyledLinkBorder,
  StyledPrimaryButton,
  StyledPrimaryButtonLink,
} from '../../../../atoms/Buttons/styles';
import { Circle, CircleDescription } from '../../../ValueComparisonLanding/components/styles';
import { Row, Col } from 'react-styled-flexboxgrid';
import { StyledCheckboxInput, StyledTextArea } from '../../../../atoms/FormItems/styles';
import { ColoredBoxLink } from '../LandingPage/style';
import { Heading1, Heading2 } from '../../../../atoms/Headings';
import { StyledFormGroup } from '../../../../atoms/Form/styles';
import { StyledFormGroupInline } from '../../../../atoms/InlineForm/styles';
import { SmallerText } from '../../../../organisms/VisualValuesComparison/style';

export const StyledGamesConfiguration = styled.div`
  display: block;
  padding-bottom: 3rem;

  ${ColoredBoxLink} {
    flex-direction: column;
    cursor: pointer;
    height: calc(100% - 4rem);

    img {
      max-width: 14rem;
      margin: 0 auto;
    }

    ${CustomText} {
      margin-top: 2rem;
    }
  }

  .boxes {
    margin-top: 3rem;s

    &.topics {
      ${Row as any} {
        justify-content: center;

        ${Col as any} {
          ${Circle}, ${CircleDescription} {
            cursor: pointer;

            &.isDisabled {
              cursor: not-allowed;
            }
          }

          &:hover {
            ${Circle} {
              box-shadow: 0 0.5rem 1rem 0 rgba(${darkBaseRGB}, 0.8);

              &.isSelected,
              &.isDisabled {
                box-shadow: 0 0.5rem 1rem 0 rgba(${darkBaseRGB}, 0.4);
              }
            }
          }
        }
      }

      ${mediaMdDefault} {
        ${Row as any} {
          margin-bottom: -3rem;
          
          .back {
            margin-top: 3rem;
          }
        }
      }
    }
    
    .friends {
      margin-top: 3rem;
      
      ${mediaSmDefault} {
        margin-top: 0;
      }
    }
  }

  ${StyledPrimaryButton} {
    margin-top: 4rem;
  }
  
  ${Heading1} ~ .back,
  ${StyledPrimaryButtonLink} ~ .back,
  ${StyledPrimaryButton} ~ .back {
    margin-top: 3rem;
  }

  ${StyledLinkBorder} {
    opacity: 1;
    color: ${yellow};

    span {
      border-color: ${yellow};
    }

    &:hover {
      span {
        border-color: transparent;
      }
    }
  }

  ${Circle} {
    border: 2px solid transparent;

    &.isSelected {
      border: 2px solid ${yellow};
      cursor: pointer;
    }

    &.isDisabled {
      opacity: 0.5;
    }
  }

  ${CircleDescription} {
    &.isDisabled {
      opacity: 0.5;
    }

    ${mediaMdDefault} {
      margin-bottom: 3rem;
    }
  }

  ${StyledFormGroup} {
    &.note {
      max-width: 41rem;
      margin-top: 4rem;
      margin-bottom: 3rem;
      margin-left: auto;
      margin-right: auto;

      label {
        text-align: center;
      }
    }
  }

  ${StyledCheckboxInput} {
    display: inline-block;
  }

  .acceptInvitation {
    margin-top: 2.5rem;
    margin-bottom: 1.5rem;
  }
  
  .copyLink {
    margin-bottom: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.8;
    transition: opacity .3s;
    
    &:hover {
      opacity: 1;
    }
    
    span {
      margin-top: 0.3rem;
      display: inline-block;      
      opacity: 0.8;
      color: ${gray};
      font-size: 1.6rem;
      line-height: 2rem;
    }
    
    img {
      margin-right: 2rem;
    }
  }
  
  form {
    ${StyledTextArea} {
      display: block;
      margin: 6rem auto 0;
      max-width: 52rem;
      font-size: 1.6rem;
      line-height: 2rem;
    }
  }
  
  .form-inline-wrapper {
    text-align: center;
    display: inline-block;
    
    ${mediaSmDefault} {
      text-align: left;
    }
    
    .form-inline {
      font-size: 2rem;
      line-height: 2.5rem;
      margin-top: 4rem;
  
      ${mediaMdDefault} {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    
    ${StyledFormGroupInline} {
      input {
        margin: 0;
        width: 100%;
        border-bottom-color: rgba(126, 68, 218, 0.8);

        &:hover,
        &:focus {
          border-bottom-color: rgba(126, 68, 218, 1);
        }
        
        ${mediaSmDefault} {
          margin: 0 0.5rem;
        }
      }

      ${mediaSmDefault} {
        display: inline-block;
      }
    }
  }
  
  .back {
    ${StyledFakeLinkBorder} {
      font-size: 1.6rem;
      line-height: 2rem;
    }
  }
  
  .confirmation-invite {
    margin-top: 4rem;
    
    ${StyledFormGroup} {
      margin-top: 1rem;
    }
    
    .link {
      color: ${yellow};
      font-size: 1.8rem;
      line-height: 2rem;
    }
    
    ${SmallerText} {
      margin-top: 2rem;
      font-size: 1.4rem;
      line-height: 1.8rem;
    }
  }
  
  .welcome-heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${textGreen};
    
    ${mediaSmDefault} {
      flex-direction: row;
      
      svg {
        margin-right: 2rem;
      }
    }
  }
`;

export const GameConfig = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;

  & > div {
    margin: 0 1.5rem;
    display: flex;
    align-items: center;
    text-align: left;

    svg {
      margin-right: 1.5rem;
      width: 2.2rem;
      min-width: 2.2rem;
      height: 2.2rem;
    }

    span {
      opacity: 0.8;
      color: ${gray};
    }
  }
`;

export const StyledComparisonSetupPopup = styled.div`
  img {
    display: block;
    max-width: 14rem;
    margin: 0 auto;
  }

  ${Heading2} {
    color: #fff;
  }

  ${SectionText} {
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: rgba(255, 255, 255, 0.7);
  }

  ${CustomText} {
    margin-top: 2rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align: center;
  }
`;
