import { IStringMap } from '../../../utils/index';
import { IRescource, getData } from '../../../utils/rescource';
import withRedux from '.';
import { mergeRes } from './index';
import { fetchStatistics } from '../../../store/Entities/Statistics/index';
import { IValueResult } from 'config/api/valueTestResult';

export interface IWithStatistics {
  statistics: IStringMap<IValueResult>;
  rescource: { statistics: IRescource<IStringMap<IValueResult>> };
}

export default withRedux(
  state => state.entities.statistics,
  dispatch => () => dispatch(fetchStatistics()),
  (props): IWithStatistics => ({
    statistics: getData(props.rescource),
    rescource: { ...mergeRes(props), statistics: props.rescource },
  }),
);
