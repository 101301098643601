import * as React from 'react';
import { StyledQuestionWrapper, StyledPartnerQuestion } from './styles';
import { Grid } from 'react-styled-flexboxgrid';
import Simple from './Types/Simple';
import { _switch } from '../../../../utils/option';
import { PrimaryButton } from '../../../atoms/Buttons';
import { FormattedMessage } from 'react-intl';
import { IResponse } from './data';
import {
  IAdditionalQuestion,
  ISurveyBaseQuestion,
  ISurveySimpleQuestion,
  ISurveyDiscreteRatingQuestion,
  ISurveyContinousRatingQuestion,
} from './data';
import { IStringMap } from '../../../../utils/index';
import Discrete from './Types/Discrete';
import Continuous from './Types/Continuous';
import { DefaultGridTheme } from '../../../theme/grid';
import { ThemeProvider } from 'styled-components';
import Free from './Types/Free';
import WithoutAnswer from './Types/WithoutAnswer';
import { ICurrentRound, addRecordData, IAddRecord } from '../data';
import { StyledFakeLinkBorder } from '../../../atoms/Buttons/styles';
import { Box } from '@xcorejs/ui';

interface IQuestion {
  round: ICurrentRound;
  submit: (data: IAddRecord<{}>) => unknown;
  skip?: () => unknown;
}

interface IQuestionState {
  response: IResponse<IStringMap<boolean>> | IResponse<number>;
  responseMinus: IResponse<IStringMap<boolean>> | IResponse<number>;
}

export class Question extends React.Component<IQuestion, IQuestionState> {
  public state: IQuestionState = {
    response: { data: null, submitable: false },
    responseMinus: { data: null, submitable: false },
  };

  public render() {
    const { round, skip } = this.props;
    const withoutAnswer = round.additionalQuestion.type === 'withoutanswer';


    // @ts-ignore
    return (
      <ThemeProvider theme={DefaultGridTheme}>
        <StyledQuestionWrapper>
          <Grid>
            <Box pb={round.additionalQuestion.type === 'd21plus' || round.additionalQuestion.type === 'd21minus' ? { _: '16.7rem', sm: '5.2rem' }: 0}>
              <span className='pre-title'>{round.headerText}</span>

              <StyledPartnerQuestion decorationUrl={round.survey.icon}>
                {this.renderQuestion(round.additionalQuestion, round.theme)}
              </StyledPartnerQuestion>

              {
                // @ts-ignore
                round.additionalQuestion.data.minAnswersCount === 0
                // @ts-ignore  
                && round.additionalQuestion.data.maxAnswersCount === 0 ? (
                    <PrimaryButton onClick={skip}>
                      <FormattedMessage id={'question.next'} />
                    </PrimaryButton>
                  ) : (
                    <PrimaryButton
                      onClick={round.additionalQuestion.type === 'withoutanswer' ? skip : this.submit}
                      isUnvisible={!this.state.response.submitable && !withoutAnswer}
                    >
                      <FormattedMessage id={'question.next'} />
                    </PrimaryButton>
                  )}

              {skip && !round.additionalQuestion.required && !withoutAnswer && (
                <div className={'back'}>
                  <StyledFakeLinkBorder onClick={skip}>
                    <FormattedMessage id={'question.skip'} />
                  </StyledFakeLinkBorder>
                </div>
              )}
            </Box>
          </Grid>
        </StyledQuestionWrapper>
      </ThemeProvider>
    );
  }

  private onChange = (response: IResponse<{}>) => this.setState({ response: response });
  private onChangeMinus = (responseMinus: IResponse<{}>) => this.setState({ responseMinus: responseMinus });

  private renderQuestion = (survey: IAdditionalQuestion, theme: string) =>
    _switch({
      simple: (data: IAdditionalQuestion<ISurveySimpleQuestion>) => (
        <Simple
          question={data}
          onChange={this.onChange}
          response={this.state.response as IResponse<IStringMap<boolean>>}
          theme={theme}
        />
      ),
      discreterating: (data: IAdditionalQuestion<ISurveyDiscreteRatingQuestion>) => (
        <Discrete
          question={data}
          onChange={this.onChange}
          response={this.state.response as IResponse<IStringMap<boolean>>}
        />
      ),
      continuousrating: (data: IAdditionalQuestion<ISurveyContinousRatingQuestion>) => (
        <Continuous question={data} onChange={this.onChange} response={this.state.response as IResponse<number>} />
      ),
      free: (data: IAdditionalQuestion<ISurveyBaseQuestion>) => (
        <Free
          question={data}
          onChange={this.onChange}
          theme={theme}
        />
      ),
      withoutanswer: (data: IAdditionalQuestion) => (
        <WithoutAnswer question={data} theme={theme} />
      ),
      d21plus: (data: IAdditionalQuestion<ISurveySimpleQuestion>) => (
        <Simple
          question={data}
          onChange={this.onChange}
          response={this.state.response as IResponse<IStringMap<boolean>>}
          type='d21plus'
          theme={theme}
        />
      ),
      d21minus: (data: IAdditionalQuestion<ISurveySimpleQuestion>) => (
        <Simple
          question={data}
          onChange={this.onChange}
          onChangeMinus={this.onChangeMinus}
          response={this.state.response as IResponse<IStringMap<boolean>>}
          responseMinus={this.state.responseMinus as IResponse<IStringMap<boolean>>}
          type='d21minus'
          theme={theme}
        />
      ),
    })(() => <div />)(survey.type)(survey);

  private submit = () =>
    this.props.submit(
      addRecordData(
        this.props.round,
        ['continuousrating', 'free', 'withoutanswer'].includes(this.props.round.additionalQuestion.type)
          ? {
            value: this.state.response.data,
            ids: [],
          } : ['d21plus'].includes(this.props.round.additionalQuestion.type) ? {
            // @ts-ignore
            ids: Object.keys(this.state.response.data!).filter(k => this.state.response.data as IStringMap<boolean>[k]),
          } : {
            // @ts-ignore
            ids: Object.keys(this.state.response.data!).filter(k => this.state.response.data as IStringMap<boolean>[k]),
            minusIds: this.state.responseMinus.data ? Object.keys(this.state.responseMinus.data!)
              // @ts-ignore
              .filter(k => this.state.responseMinus.data as IStringMap<boolean>[k]) : []
          },
      ),
    );
}
