import SurveyHelpModal from 'components/organisms/Test/Question/SurveyHelpModal';
import * as React from 'react';
import { ISurveySimpleQuestion, IAnswer, IQuestionHelp } from '../../data';
import { QuestionBox } from '../../../../../atoms/FormItems/PartnerTest/styles';
import { Box, Flex, Link, useModal } from '@xcorejs/ui';

interface ISimpleItem {
  data: ISurveySimpleQuestion;
  selected: string[];
  selectedMinus?: string[];
  onChange: (id: string, val: boolean) => void;
  onChangeMinus?: (id: string, val: boolean) => void;
  a: IAnswer;
  type?: 'd21minus' | 'd21plus';
  theme: string;
  help: IQuestionHelp;
}

const SimpleItem: React.FC<ISimpleItem> = (
  {
    data,
    selected,
    selectedMinus,
    onChange,
    onChangeMinus,
    a,
    type,
    theme,
    help
  }
) => {
  const isDisabled = (id: string) => data.maxAnswersCount === selected.length &&
    !selected.includes(id) && selectedMinus && !selectedMinus.includes(id);
  const [openSurveyHelpModal] = useModal(SurveyHelpModal);

  const answerHelp = a.help.title !== null || a.help.content !== null || a.help.image !== null;

  return (
    <>
      <QuestionBox
        isSelected={selected.includes(a.id) || selectedMinus && selectedMinus.includes(a.id)}
        isDisabled={type !== 'd21minus' && isDisabled(a.id)}
        onClick={type !== 'd21minus' ? () =>
          (data.maxAnswersCount > selected.length || selected.includes(a.id) || data.maxAnswersCount === 1) &&
          onChange(a.id, !selected.find(x => x === a.id)) : undefined
        }
        textOnly={!a.image}
        withHelpModal={answerHelp}
      >
        <div>
          {type === 'd21minus' && onChangeMinus && selectedMinus && (
            <Flex justifyContent='space-between' as='span' position='absolute' zIndex={10} width='100%' p='1.5rem' top='0'>
              <Box
                position='relative'
                display='flex'
                alignItems='center'
                justifyContent='center'
                as='span'
                width={{ _: '2.4rem', sm: '3rem' }}
                height={{ _: '2.4rem', sm: '3rem' }}
                borderRadius='50%'
                onClick={!selectedMinus.includes(a.id) ? () =>
                  (data.maxAnswersCount > selected.length || selected.includes(a.id) || data.maxAnswersCount === 1) &&
                  onChange(a.id, !selected.find(x => x === a.id)) : undefined
                }
                bg={selected.includes(a.id) ? '#009966' : 'transparent'}
                border={selected.includes(a.id) ? '2px solid #009966' : '2px solid #fff'}
                opacity={selected.includes(a.id) ? 1 : 0.5}
                _hover={{ opacity: 1 }}
                transition='opacity 300ms'
              >
                <Box
                  display='span'
                  width={{ _: '1.4rem', sm: '1.6rem' }}
                  height={{ _: '0.15rem', sm: '0.2rem' }}
                  bg='#fff'
                />
                <Box
                  display='span'
                  width={{ _: '1.4rem', sm: '1.6rem' }}
                  height={{ _: '0.15rem', sm: '0.2rem' }}
                  bg='#fff'
                  transform='rotate(90deg)'
                  position='absolute'
                />
              </Box>
              <Box
                position='relative'
                alignItems='center'
                justifyContent='center'
                display='flex'
                as='span'
                width={{ _: '2.4rem', sm: '3rem' }}
                height={{ _: '2.4rem', sm: '3rem' }}
                borderRadius='50%'
                background={selectedMinus.includes(a.id) ? '#f64747' : 'transparent'}
                border={selectedMinus.includes(a.id) ? '2px solid #f64747' : '2px solid #fff'}
                onClick={!selected.includes(a.id) ? () =>
                  (selectedMinus.length < 1 || selectedMinus.includes(a.id)) &&
                  onChangeMinus(a.id, !selectedMinus.find(x => x === a.id)) : undefined
                }
                opacity={selectedMinus.includes(a.id) ? 1 : 0.5}
                _hover={{ opacity: 1 }}
                transition='opacity 300ms'
              >
                <Box
                  display='span'
                  width={{ _: '1.4rem', sm: '1.6rem' }}
                  height={{ _: '0.15rem', sm: '0.2rem' }}
                  bg='#fff'
                />
              </Box>
            </Flex>
          )}

          {a.image === null ? <p dangerouslySetInnerHTML={{ __html: a.text ?? '' }} /> : <img src={a.image} />}
        </div>

        {answerHelp && (
          <Link
            onClick={() => openSurveyHelpModal({ help: a.help, theme: theme })}
            color='#fff'
            borderColor='#fff'
            v='underline'
            mt='-1.8rem'
            position='absolute'
            top='100%'
            transform='translateX(-50%) translateY(-100%)'
            left='50%'
          >
            Více info
          </Link>
        )}
      </QuestionBox>
    </>
  );
};

export default SimpleItem;
