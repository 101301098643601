import React, { FC, useContext, useState } from 'react';
import { SelectStyle, StyledLink } from './style';
import CustomSelect from 'components/atoms/Select';
import Accordion from 'components/atoms/Accordion/Accordion';
import { Tag, Paragraph, Icon, Typography, Grid, Button, useModal, Box } from '@xcorejs/ui';
import { BlueTheme, yellow, yellowLighter } from 'components/theme/colors';
import { OliRegular, OliMedium } from 'components/theme/fonts';
import { CompareIcon } from '../../icons/16/compare.svg';
import { Option } from 'react-select';
import { CompareConfigTestResultPreview } from 'components/components/Compare/data';
import { useMatrixData } from './hooks';
import { ITestResultPreviewResponse } from 'components/pages/App/TestIntro/data';
import { PlusTag } from 'components/atoms/Logo';
import LaddersCompareTestModal from '../LaddersCompareTestModal';
import { GameContext } from 'components/App';
import { createUrl } from 'components/pages/routes';

interface CompareWithTestProps {
  selectOptions: CompareConfigTestResultPreview[];
  result: ITestResultPreviewResponse;
}

const CompareWithTest: FC<CompareWithTestProps> = ({
  selectOptions,
  result,
  children
}) => {
  const { eticiaPlus } = useContext(GameContext);
  const [active, setActive] = useState<Option>();
  const { description, compareNames } = useMatrixData(result, active);
  const [openLaddersCompareTest] = useModal(LaddersCompareTestModal);
  const [close] = useModal();
  
  return (
    <>
      <SelectStyle maxWidth='34.4rem' mx='auto' width='100%' cursor='pointer'>
        <CustomSelect
          onChange={(option) => option && setActive(option)}
          placeholder={active ? active.label : 'Vyberte žebříček...'}
          options={
            selectOptions
              .sort((a, b) => b.createdAt - a.createdAt)
              .map(option => ({ 
                value: option.id, 
                label: (
                  <>
                    <div className='Select-option--left'>{option.template.name}</div>
                    <div className='Select-option--right'>{new Date(option.createdAt*1000).toLocaleDateString()}</div>
                  </>) as any // podle ts nechce brát jsx ale zvládne to, zobrazení textu vlevo a data vpravo
              }))
          }
          searchable={false}
          required
        />
      </SelectStyle>
              
      <Accordion
        expanded={description.trim().length > 0}
        flexDirection='column'
        justifyContent='center'
        alignItems='center'>
        <Tag
          marginTop='4rem'
          color='rgba(255, 255, 255, 0.7)'
          boxShadow='0 0 5px 2px rgba(0, 0, 0, 0.45)'
          borderRadius='999rem'
          background={BlueTheme.extraDark}
          fontFamily={OliRegular}
          padding='0.7rem 1.5rem'
          lineHeight='1'
          transition='opacity 0.5s ease-in-out'
        >
            Co ti toto porovnání přinese?
        </Tag>
          
        <Grid
          columns='1fr 4rem 1fr'
          rows='auto'
          gap='1rem'
          marginTop='2rem'
          alignItems='center'
          justifyItems='center'
          transition='opacity 0.5s ease-in-out'
        >
          <Paragraph 
            color={yellow}
            textAlign='right'
            justifySelf='end'
            fontFamily={OliMedium}>
            {compareNames.main}
          </Paragraph>

          <Icon
            svg={<CompareIcon />}
            fill={yellowLighter}
            width='2.5rem'
            height='2.5rem'
            alignSelf='center'
            border={'1px solid ' + yellowLighter}
            borderRadius='9999px'
            display='inline-flex'
            alignItems='center'
            justifyContent='center' />

          <Paragraph 
            color={yellow}
            textAlign='left'
            justifySelf='start'
            fontFamily={OliMedium}>
            {compareNames.compared}
          </Paragraph>
        </Grid>

        <Typography
          mt='1rem'
          color='#fff'
          transition='opacity 0.5s ease-in-out'
        >
          {description}
        </Typography>
      </Accordion>
      
      <StyledLink
        to={createUrl('app.journal') + '#compare-matrix'}
        onClick={close}
      >
        Podobnosti o tomto i dalších porovnání se dozvíš zde
      </StyledLink>

      {children}

      <Box
        alignSelf='center'
        position='relative'>
        <Button
          mt='1rem'
          disabled={!active || !eticiaPlus}
          onClick={active ? () => {
            close();
            openLaddersCompareTest({ templateA: result.id, templateB: active!.value as string, onClose: close });
          }: null}
        >
        Spustit porovnání
        </Button>
        {!eticiaPlus ? <PlusTag position='absolute' bottom='-1.5rem' right='0' /> : null}
      </Box>
    </>
  );
};
 
export default CompareWithTest;
