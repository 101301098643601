import * as React from 'react';
import { Col, Row, Grid } from 'react-styled-flexboxgrid';
import Subnav from '../../../../atoms/Subnav';
import { _switch } from '../../../../../utils/option';
import LoadingSpinnerLocal from '../../../../atoms/Animations/LoadingSpinnerLocal';
import { RouteComponentProps, withRouter } from 'react-router';
import { ISurveyAnswer, ISurveyQuestion } from '../PartnerTestResult/data';
import AnswerBox from '../../../../molecules/TestResult/AnswerBox';
import SingleAnswer from '../../../../molecules/TestResult/AnswerBox/SingleAnswer';
import { StyledResultBoxes } from '../../../../molecules/TestResult/AnswerBox/styles';
import { createUrl } from '../../../routes';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import NavItem from '../../../../atoms/Nav/NavItem';
import { PrimaryButtonLink } from '../../../../atoms/Buttons';
import { SURVEY_RESULT } from 'config/api/surveyInternal';
import {
  Box,
  Heading2 as XHeading2,
  Heading3,
  Typography,
  Flex,
  Img,
  BoxProps,
  Button,
  Link as UILink,
  Heading2,
  Grid as XGrid,
  Tag
} from '@xcorejs/ui';
import { Fragment, FC, useContext } from 'react';
import { Link } from 'react-router-dom';
import { SecondaryButton } from 'components/components/Button';
import VimeoPopup from 'components/atoms/Popups/VimeoPopup';
import SVGplayBlue from 'components/atoms/SVGIcons/SVGplayBlue';
import withPopups, { IWithPopups } from 'components/molecules/withRedux/withPopups';
import { GameContext } from 'components/App';
import { PlusTag } from 'components/atoms/Logo';
import { ColoredThemes } from 'components/theme/colors';
import { OliLight, OliMedium } from 'components/theme/fonts';
import useFetch from 'components/atoms/Fetch/useFetch';

type SingleSurveyQuestion = ISurveyQuestion<ISurveyAnswer[]>;

interface ISurveyCompare {
  surveyId: string;
  surveyName: string;
  questions: SingleSurveyQuestion[];
  type: string;
  image: string;
  theme: string | null;
}

const SurveyResult: React.FC<RouteComponentProps<{ id: string }> & IWithPopups> = ({ match, popups }) => {
  const id = match.params.id;
  const baseUrl = createUrl('app.surveyResult', { id });
  const { insideQuest, eticiaPlus } = useContext(GameContext);
  const { result, state } = useFetch<ISurveyCompare>(SURVEY_RESULT(id));

  console.log('result', result);
  

  if (state === 'fail') return (
    <>
      <Subnav black>
        <NavItem to={baseUrl} label={'survey.result'} exact />
      </Subnav>

      <Heading2 color='red' marginTop='5rem' fontFamily={OliMedium}>
        Anketa s tímto ID neexistuje
      </Heading2>
    </>
  );

  if (!result) return (
    <>
      <Subnav black>
        <NavItem to={baseUrl} label={'survey.result'} exact />
      </Subnav>

      <LoadingSpinnerLocal />
    </>
  );

  return (
    <>
      <Subnav black>
        <NavItem to={baseUrl} label={'survey.result'} exact />
      </Subnav>

      <Box
        //bg='url(/img/game/hra-bg.jpg)'
        background={result.theme ? `${ColoredThemes[result.theme].primary}` : 'transparent'}
        backgroundPosition='center bottom'
        backgroundSize='cover'
        {...{ backgroundAttachment: 'fixed' }}
        minHeight={{ _: 'calc(100vh - 5rem)', sm: 'calc(100vh - 6rem)' }}
        py={{ _: '5rem', sm: '6rem' }}
      >
        <Grid>
          <>
            {result.questions.map((q, k) => q.answerType === 'd21plus' || q.answerType === 'd21minus' && (
              <Flex justifyContent='center' flexDirection='column' py='2rem' key={k}>
                <XHeading2 fontSize='2.4rem' lineHeight='2.9rem' letterSpacing='0.5px' fontWeight={500}>
                  {result.surveyName}
                </XHeading2>

                <Typography color='rgba(255, 255, 255, 0.5)' mt='0.5rem'>
                  <FormattedMessage id='surveyResult.d21.subtitle' />
                </Typography>

                <Box mt='4.5rem'>
                  <Row>
                    <Col xs={12} sm={6} md={4}>
                      <Heading3 color='textGreen' fontWeight={400}>
                        <FormattedMessage id='surveyResult.d21.myChoice' />
                      </Heading3>
                      <Typography color='rgba(255, 255, 255, 0.5)'>
                        <FormattedMessage id='surveyResult.d21.others3' />
                      </Typography>

                      <Box
                        mt='2rem'
                        borderRadius='0.4rem'
                        bg='rgba(0, 153, 102, 0.2)'
                        p='2rem'
                        maxWidth='35rem'
                        mx='auto'
                      >
                        <Flex mb='2rem' justifyContent='center' alignItems='center'>
                          {PositiveIcon}
                          <Typography>
                            <FormattedMessage id='surveyResult.d21.plus' />
                          </Typography>
                        </Flex>

                        {result.questions.map((question, key) =>
                          question.answerType === 'd21plus' ?
                            question.answers.map((a, key) => (
                              <Typography fontWeight={700} mt='1rem' key={key}>
                                {a.text}
                              </Typography>
                            )) :
                            question.answers.filter(a => !a.d21Minus).map((a, key) => (
                              <Typography fontWeight={700} mt='1rem' key={key}>
                                {a.text}
                              </Typography>
                            )
                            )
                        )}
                      </Box>

                      {q.answerType === 'd21minus' && (
                        <Box
                          mt='2rem'
                          borderRadius='0.4rem'
                          bg='rgba(246, 71, 71, 0.2)'
                          p='2rem'
                          maxWidth='35rem'
                          mx='auto'
                        >
                          <Flex mb='2rem' justifyContent='center' alignItems='center'>
                            {NegativeIcon}
                            <Typography>
                              <FormattedMessage id='surveyResult.d21.minus' />
                            </Typography>
                          </Flex>

                          {result.questions.map((question, key) =>{
                            const answers = question.answers.filter(a => a.d21Minus);

                            return question.answerType === 'd21minus' &&
                            answers.length ?
                              answers.map((a, key) => (
                                <Typography fontWeight={700} mt='1rem' key={key}>
                                  {a.text}
                                </Typography>
                              )) : (
                                <Typography fontWeight={700} mt='1rem'>
                                  <FormattedMessage id='surveyResult.d21.minusEmpty' />
                                </Typography>
                              );
                          })}
                        </Box>
                      )}

                      <InstituteBox popups={popups} display={{ _:'none', sm: 'flex' }} />
                    </Col>

                    <Col xs={12} sm={6} md={4}>
                      <Heading3 color='blue' fontWeight={400} mt={{ _: '5.5rem', sm: '0' }}>
                        <FormattedMessage id='surveyResult.d21.others' />
                      </Heading3>
                      <Typography color='rgba(255, 255, 255, 0.5)'>
                        <FormattedMessage id='surveyResult.d21.others2' />
                      </Typography>

                      <Box maxWidth='35rem' mx='auto' mt='3rem'>
                        {result.questions.map((question, key) => (
                          <Fragment key={key}>
                            {question.publicResults.slice(0, 3).map((r, key) => (
                              <Flex px='3rem' width='100%' mt='1rem' key={key}>
                                <Flex alignItems='center' width='100%'>

                                  <Typography color='rgba(255, 255, 255, 0.5)' alignItems='center'>
                                    {question.answers.map((a, i) =>
                                      Object.values(a).includes(r.text) && a.d21Minus).includes(true) && (
                                      <Flex alignItems='center'>
                                        <strong style={{ color: '#fff', marginRight: '1rem' }}>{r.text}</strong>
                                        {NegativeIcon}
                                      </Flex>
                                    )
                                    }

                                    {question.answers.map((a, i) =>
                                      Object
                                        .values(a)
                                        .includes(r.text) && a.d21Minus === false)
                                      .includes(true) && (
                                      <Flex alignItems='center'>
                                        <strong style={{ color: '#fff', marginRight: '1rem' }}>{r.text}</strong>
                                        {PositiveIcon}
                                      </Flex>
                                    )
                                    }

                                    {question.answers.map((a, i) =>
                                      Object.values(a).includes(r.text)).includes(true) ? (
                                        <></>
                                      )
                                      :
                                      r.text
                                    }
                                  </Typography>
                                </Flex>

                                <Typography fontWeight={400} color='rgba(244, 244, 246, 0.7)' flex='0 0 auto'>
                                  {question.answers.map((a, i) =>
                                    Object.values(a).includes(r.text)).includes(true) ? (
                                      <strong style={{ color: '#fff', fontWeight: 700 }}>{r.popularity} %</strong>
                                    )
                                    :
                                    <>{r.popularity} %</>
                                  }
                                </Typography>
                              </Flex>
                            ))}
                          </Fragment>
                        ))}
                      </Box>

                      <Box maxWidth='35rem' mx='auto' borderTop='2px solid rgba(244, 244, 246, 0.25)' mt='2rem' pt='1rem'>
                        {result.questions.map((question, key) => (
                          <Fragment key={key}>
                            {question.publicResults.slice(3, question.publicResults.length).map((r, key) => (
                              <Flex px='3rem' width='100%' mt='1rem' key={key}>
                                <Flex alignItems='center' width='100%'>
                                  <Typography color='rgba(255, 255, 255, 0.5)' alignItems='center'>
                                    {question.answers.map((a, i) =>
                                      Object.values(a).includes(r.text) && a.d21Minus).includes(true) && (
                                      <Flex alignItems='center'>
                                        <strong style={{ color: '#fff', marginRight: '1rem' }}>{r.text}</strong>
                                        {NegativeIcon}
                                      </Flex>
                                    )
                                    }

                                    {question.answers.map((a, i) =>
                                      Object
                                        .values(a)
                                        .includes(r.text) && a.d21Minus === false)
                                      .includes(true) && (
                                      <Flex alignItems='center'>
                                        <strong style={{ color: '#fff', marginRight: '1rem' }}>{r.text}</strong>
                                        {PositiveIcon}
                                      </Flex>
                                    )
                                    }

                                    {question.answers.map((a, i) =>
                                      Object.values(a).includes(r.text)).includes(true) ? <></> : r.text
                                    }
                                  </Typography>
                                </Flex>

                                <Typography fontWeight={400} color='rgba(244, 244, 246, 0.7)' flex='0 0 auto'>
                                  {question.answers.map((a, i) =>
                                    Object.values(a).includes(r.text)).includes(true) ? (
                                      <strong style={{ color: '#fff', fontWeight: 700 }}>{r.popularity} %</strong>
                                    )
                                    :
                                    <>{r.popularity} %</>
                                  }
                                </Typography>
                              </Flex>
                            ))}
                          </Fragment>
                        ))}
                      </Box>
                    </Col>

                    <Col xs={12} sm={12} md={4}>
                      <Flex justifyContent='center' width='100%' mt={{ _: '5.5rem', md: '0' }}>
                        {q.answerType === 'd21minus' ? (
                          <Box width='100%'>
                            <Heading3 color='blue' fontWeight={400}>
                              <FormattedHTMLMessage id={'surveyResult.d21.minusInfo'} />
                            </Heading3>

                            <Typography color='rgba(255, 255, 255, 0.5)'>
                              <FormattedHTMLMessage id={'surveyResult.d21.minusInfo2'} />
                            </Typography>

                            <Flex justifyContent='space-between' maxWidth='31rem' mx='auto' mt='3rem'>
                              {PositiveIcon}{NegativeIcon}
                            </Flex>

                            {result.questions.map((question, key) => (
                              <>
                                <Box key={key} mt='1rem' maxWidth='35rem' mx='auto'>
                                  {question.publicResults.map((r, key) => (
                                    <Flex key={key} width='100%' justifyContent='space-between' mb='1.2rem'>
                                      <Box
                                        width='6.4rem'
                                        height='2.4rem'
                                        borderRadius='0.4rem'
                                        bg='rgba(0, 153, 102, 0.2)'
                                        overflow='hidden'
                                        position='relative'
                                      >
                                        <Flex
                                          alignItems='center'
                                          justifyContent='center'
                                          width={`${r.plus}%`}
                                          height='100%'
                                          bg='rgba(0, 153, 102, 0.8)'
                                          color='#fff'
                                        />

                                        <Typography
                                          position='absolute'
                                          left={0}
                                          top='50%'
                                          transform='translateY(-50%)'
                                          width='100%'
                                          fontSize='1.4rem'
                                          lineHeight='2.4rem'
                                        >
                                          {r.plus} %
                                        </Typography>
                                      </Box>

                                      <Typography px='2rem' color='rgba(255, 255, 255, 0.7)'>
                                        {question.answers.map((a, i) =>
                                          Object.values(a).includes(r.text)).includes(true) ? (
                                            <strong style={{ color: '#fff' }}>{r.text}</strong>)
                                          :
                                          r.text
                                        }
                                      </Typography>

                                      <Box
                                        width='6.4rem'
                                        height='2.4rem'
                                        borderRadius='0.4rem'
                                        bg='rgba(246, 71, 71, 0.2)'
                                        overflow='hidden'
                                        position='relative'
                                      >
                                        <Flex
                                          alignItems='center'
                                          justifyContent='center'
                                          width={`${Math.abs(r.minus)}%`}
                                          height='100%'
                                          bg='rgba(246, 71, 71, 0.8)'
                                          color='#fff'
                                        />

                                        <Typography
                                          position='absolute'
                                          left={0}
                                          top='50%'
                                          transform='translateY(-50%)'
                                          width='100%'
                                          fontSize='1.4rem'
                                          lineHeight='2.4rem'
                                        >
                                          {r.minus} %
                                        </Typography>
                                      </Box>
                                    </Flex>
                                  ))}
                                </Box>
                              </>
                            ))}
                          </Box>
                        ) : (
                          <Img src='/img/d21.png' width={300} alt='' />
                        )}
                      </Flex>
                    </Col>
                  </Row>
                </Box>


                <InstituteBox display={{ _: 'flex', sm: 'none' }} />

                <InstituteBox2 />

                {/* <Flex justifyContent='center' mt={{ _: '6rem', md: '3rem' }}>
                  <Link
                    to={result.type === 'identified' ? createUrl('app') : createUrl('app.journal') + '/aktivita'}
                  >
                    <SecondaryButton borderColor='textGreen'>
                      <FormattedMessage id={'survey.finish'} />
                    </SecondaryButton>
                  </Link>
                </Flex> */}
              </Flex>
            ))}

            {result.questions.filter(q => q.answerType !== 'd21plus' && q.answerType !== 'd21minus').length > 0 && (
              <Box>
                <StyledResultBoxes>
                  <Row>
                    <Col xs={12}>
                      <Flex
                        alignItems='center'
                        justifyContent='center'
                        flexDirection={{ _: 'column', sm: 'row' }}
                      >
                        {result.image && (
                          <Img
                            src={result.image}
                            alt=''
                            mr={{ _: '0', sm: '3rem' }}
                            mb={{ _: '1rem', sm: 0 }}
                            height='10rem'
                            opacity={0.5}
                          />
                        )}
                        <Heading2>{result.surveyName}</Heading2>
                      </Flex>
                    </Col>
                  </Row>

                  <XGrid columns={{ _: '1fr', sm: 'repeat(3, 1fr)' }} rows='auto' gap={{ _: '1.6rem', sm: '3.2rem' }}>
                    {result.questions.map((question, key) =>
                      !question.skipped && (
                        <AnswerBox key={question.id} question={question}>
                          <Flex alignItems='center' justifyContent='center'>
                            <SingleAnswer question={question} />
                          </Flex>

                          {result.surveyName !== 'Zpětná vazba' && (
                            <Box mt='4rem'>
                              <Tag
                                fontSize={{ _: '1.8rem', sm: '2rem' }}
                                background='rgba(12, 14, 15, 0.3)'
                                paddingX='3rem'
                                paddingY='1rem'
                                fontFamily={OliLight}
                                color='rgba(255, 255, 255, 0.7)'
                              >
                                <FormattedMessage id='survey.howOthers' />
                              </Tag>

                              {console.log(question)}

                              <Flex alignItems='flex-start' mt='2rem' flexDirection='column' display='inline-flex' width={{ _:'100%', md:'auto' }}>
                                {eticiaPlus ?
                                  question.publicResults.map((result, i) => (
                                    <Flex key={i} alignItems='flex-start' mt={i <= 0 ? '0' : '1rem'}>
                                      <Box
                                        width='2rem'
                                        height='2rem'
                                        mr='1rem'
                                        borderRadius='50%'
                                        border='2px solid'
                                        borderColor={i > 2 ? 'rgba(231, 230, 207, 0.1)' : 'transparent'}
                                        color={i > 2 ? 'rgba(255, 255, 255, 0.25)' : 'rgba(11, 12, 28, 0.5)'}
                                        bg={i === 0 ? 'rgba(255, 255, 255, 0.75)' :
                                          i === 1 ? 'rgba(255, 255, 255, 0.35)' :
                                            i === 2 ? 'rgba(255, 255, 255, 0.15)' : 'transparent'
                                        }
                                        flex='0 0 auto'
                                        fontSize='1.5rem'
                                      >
                                        {i + 1}
                                      </Box>

                                      <Flex alignItems='center'>
                                        <Typography color='rgba(255, 255, 255, 0.8)' textAlign='left'>
                                          {result.text ?? ''}
                                          {result.popularity >= 0 && (
                                            <Typography display='inline' color='rgba(231, 230, 207, 0.4)' ml='0.5rem' flex='0 0 auto' whiteSpace='nowrap'>
                                                    ({result.popularity} %)
                                            </Typography>
                                          )}
                                        </Typography>


                                      </Flex>
                                    </Flex>
                                  ))
                                  : (
                                    <Flex flexDirection='column' alignItems='center' position='relative'>
                                      {question.publicResults.length > 2 && (
                                        <Flex alignItems='center' mt='1rem'>
                                          <Box
                                            width='2rem'
                                            height='2rem'
                                            mr='1rem'
                                            borderRadius='50%'
                                            border='2px solid'
                                            borderColor='rgba(231, 230, 207, 0.25)'
                                            color='rgba(255, 255, 255, 0.5)'
                                            bg='transparent'
                                            flex='0 0 auto'
                                            fontSize='1.5rem'
                                          >
                                                  1
                                          </Box>

                                          <Flex alignItems='center'>
                                            <Typography color='rgba(255, 255, 255, 0.8)' textAlign='left'>
                                              {question.publicResults[0].text ?? ''}

                                              {question.publicResults[0].popularity >= 0 && (
                                                <Typography display='inline' color='rgba(231, 230, 207, 0.4)' ml='0.5rem' flex='0 0 auto'>
                                                        ({question.publicResults[0].popularity} %)
                                                </Typography>
                                              )}
                                            </Typography>
                                          </Flex>
                                        </Flex>
                                      )}

                                      <Flex
                                        alignItems='center'
                                        justifyContent='center'
                                        minHeight='9.2rem'
                                        position='relative'
                                        mt='3rem'
                                      >
                                        <Img src='/img/blurred/answers.png' alt='' position='absolute' />

                                        <Box>
                                          <Typography color='rgba(255, 255, 255, 0.5)'>
                                                  Pro zobrazení grafu a všech výsledků anket
                                                  si aktivuj členství <strong>Eticia Plus</strong>
                                          </Typography>

                                          {key === 0 && (
                                            <PlusTag mt='2rem'>
                                                    Eticia Plus
                                            </PlusTag>
                                          )}
                                        </Box>
                                      </Flex>
                                    </Flex>
                                  )}
                              </Flex>
                            </Box>
                          )}
                        </AnswerBox>
                      )
                    )}
                  </XGrid>

                </StyledResultBoxes>
              </Box>
            )}
          </>
          <Flex justifyContent='center' mt='4rem'>
            {insideQuest ? (
              <PrimaryButtonLink
                to={createUrl('app') + '/hra'}
              >
                <FormattedMessage id={'survey.finish'} />
              </PrimaryButtonLink>
            ) : (
              <PrimaryButtonLink
                to={result.type === 'identified' ? createUrl('app') : createUrl('app.journal') + '/aktivita'}
              >
                <FormattedMessage id={'survey.finish'} />
              </PrimaryButtonLink>
            )}
          </Flex>
        </Grid>
      </Box>
    </>
  );
};

export default withRouter(withPopups(SurveyResult));

const PositiveIcon = (
  <Typography
    width='2rem'
    height='2rem'
    borderRadius='50%'
    lineHeight='2rem'
    bg='rgba(0, 153, 102)'
    color='#fff'
    mr='1rem'
  >
  +
  </Typography>
);

const NegativeIcon = (
  <Typography
    width='2rem'
    height='2rem'
    borderRadius='50%'
    lineHeight='2rem'
    bg='rgba(246, 71, 71)'
    color='#fff'
    mr='1rem'
  >
    -
  </Typography>
);


const InstituteBox: FC<Partial<IWithPopups> & BoxProps> = ({ popups, ...props }) => {
  return (
    <Box display='flex' flexDirection='column' justifyContent='center' mt='7rem' alignItems='center' {...props}>
      <FormattedMessage id={'surveyResult.d21.institute.link'}>
        {message => (
          <UILink href={message as string} target='_blank'>
            <Img src='/img/institute.png' width='178' height='43' alt='' />
          </UILink>
        )}
      </FormattedMessage>


      <Typography mt='1rem' color='rgba(255, 255, 255, 0.5)'>
        <FormattedHTMLMessage id={'surveyResult.d21.institute.text'} />
      </Typography>

      {popups && (
        <FormattedMessage id={'surveyResult.d21.institute.vimeoId'}>
          {message => (
            <Button
              mt='1rem'
              s='sm'
              pt='1rem'
              pb='1rem'
              borderColor='transparent'
              color='blue'
              background='transparent'
              _hover={{
                bg: 'transparent',
                borderColor: 'blue'
              }}
              onClick={() => popups.custom(() => <VimeoPopup id={message as string} />)}
              leftIcon={<SVGplayBlue />}
            >
              <FormattedHTMLMessage id={'surveyResult.d21.institute.button'} />
            </Button>
          )}
        </FormattedMessage>
      )}
    </Box>
  );
};

const InstituteBox2: FC = () => {
  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      borderRadius='2rem'
      border={{ _: '1px solid #ffde71', md: 'transparent' }}
      maxWidth='85rem'
      mt='3rem'
      mx='auto'
      p='3rem'
    >
      <Img src='/img/d21plusminus.png' width='65' height='38' alt='' />

      <Typography color='blue' fontWeight={700} mt='1rem'>
        <FormattedHTMLMessage id='surveyResult.d21.introHeading' />
      </Typography>

      <Typography mt='1rem' fontSize='1.5rem' lineHeight='1.8rem' color='rgba(255, 255, 255, 0.5)'>
        <FormattedHTMLMessage id='surveyResult.d21.intro' />
      </Typography>
    </Box>
  );
};
