import React, { FC } from 'react';
import { Box, Icon } from '@xcorejs/ui';
import StartGameIcon from '../../../../public/img/hodnotova-hra-presenting/zacit-hrat.svg';
import styled from 'styled-components';

export const SalmonColor = '#c25b63';

interface StartPlayProps {
  top?: string;
  onClick?: () => void;
  marginTop?: string;
  opacity?: number;
  animation?: string;
  onAnimationEnd?: () => void;
}

const StartPlay: FC<StartPlayProps> = ({ top = 0, onClick, marginTop, opacity = 1, animation, onAnimationEnd }) => {  
  return ( 
    <AnimatedBox
      mt={marginTop ? marginTop : { _: '4rem', md: '6rem' }}
      padding='1rem 3rem'
      cursor='pointer'
      onClick={onClick}
      position='relative'
      zIndex={2}
      top={top}
      opacity={opacity}
      animation={animation}
      onAnimationEnd={onAnimationEnd}
    >
      <div className='background' />
      <div className='border' />
      <Icon svg={<StartGameIcon />} fill={SalmonColor} transform={{ _: 'translateY(-10%)' }} />
    </AnimatedBox>
  );
};
 
export default StartPlay;

const AnimatedBox = styled(Box)`
  --salmon: #ff8977;
  --salmon-off: #c25b63;
  --border-run-animation-duration: 10s;

  .background {
    width: 100%;
    height: 100%;
    background-color: #0b0c1c;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 9999px;
  }
    
  .border {
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    background-color: var(--salmon-off);
    position: absolute;
    overflow: hidden;
    top: -2px;
    left: -2px;
    z-index: -1;
    border-radius: 9999px;
    
    &::before {
      content: '';
      position: absolute;
      top: 14px;
      left: -5px;
      height: 20px;
      width: 20px;
      background: radial-gradient(circle, var(--salmon) 0%, var(--salmon-off) 100%);
      transform: translate(-10px, 0);
      animation: borderRotate var(--border-run-animation-duration) infinite linear forwards;
      -webkit-animation: borderRotate var(--border-run-animation-duration) infinite linear forwards;
      
      @keyframes borderRotate {
        0% {
          transform: translate(-10px, 0);
        }
        
        8% {
          transform: translate(15px, -20px);
        }
        
        42% {transform: translate(140px, -20px);
        }

        50% {
            transform: translate(155px, 0px);
        }

        58% {
            transform: translate(140px, 20px);
        }

        92% {
            transform: translate(15px, 20px);
        }

        100% {
            transform: translate(-10px, 0);
        }
      }
    }

    @-webkit-keyframes borderRotate {
      0% {
        transform: translate(-10px, 0);
      }
      
      8% {
        transform: translate(15px, -20px);
      }
      
      42% {
        transform: translate(140px, -20px);
      }

      50% {
          transform: translate(155px, 0px);
      }

      58% {
          transform: translate(140px, 20px);
      }

      92% {
          transform: translate(15px, 20px);
      }

      100% {
          transform: translate(-10px, 0);
      }
    }
  }
`;
