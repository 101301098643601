import * as React from 'react';
import Card, { ICard } from './index';
import CardBody from './CardBody';
import { ReactNode } from 'react';

interface ISimpleCard extends ICard {
  name: string | ReactNode;
  img?: string;
  svg?: string;
  index?: number;
  position?: ReactNode;
  afterOpacity?: number;
}

const SimpleCard: React.FC<ISimpleCard> = (
  {
    color,
    isSelected,
    size,
    name,
    img,
    svg,
    index,
    position,
    afterOpacity,
    ...props
  }
) => (
  <Card
    color={color}
    isSelected={isSelected}
    size={size}
    withIndex={index !== undefined}
    afterOpacity={afterOpacity}
    {...props}
  >
    <CardBody name={name} img={img} svg={svg} index={index} position={position} />
  </Card>
);

export default SimpleCard;
