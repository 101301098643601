import styled from 'styled-components';
import { IColorTheme } from '../../../theme/colors';

interface IBackgroundGradientProps {
  coloredTheme: IColorTheme;
  opacity?: number;
}

export const BackgroundGradient = styled.div<IBackgroundGradientProps>`
  position: fixed;
  width: 100vw;
  height: 100vh;
  opacity: 1;
  top: 0;
  left: 0;
  transition: opacity 1s;
  background: ${props => props.coloredTheme.primary};
  
  &.fade-enter-active {
    opacity: 1;
  }

  &.fade-enter-done {
    opacity: 1;
  }

  &.fade-exit-active {
    opacity: 0;
  }

  &.fade-exit-done {
    opacity: 0;
  }
`;
