import React from 'react';
import { Flex, Img } from '@xcorejs/ui';
import styled from 'styled-components';

const Coffee = () => {
  return ( 
    <Flex as='section' backgroundColor='whitesmoke' justifyContent='center' alignItems='center'>
      <AspectImg
        src='/img/plus-black@2x.png'
        alt='Eticia Plus' 
        // width='50%'
        // height='50%'
        maxWidth='35rem'
        filter='invert(1)'
        aspectRatio={1.12/1} />
    </Flex>
  );
};
 
export default Coffee;

export const AspectImg = styled(Img)<{ aspectRatio: number }>`
  aspect-ratio: ${({ aspectRatio }) => aspectRatio};
`;