import * as React from 'react';
import {
  ProgressLineText,
  ProgressLine as StyledProgressLine,
  ProgressBarLine,
  ProgressPoint,
  ProgressBar,
} from './styles';

interface IProgressLine {
  desc: string;
  progress: number;
  points?: number[];
}

export const ProgressLine: React.FC<IProgressLine> = ({ desc, progress, points = [] }) => (
  <StyledProgressLine>
    <ProgressLineText>{desc}</ProgressLineText>

    <ProgressBar>
      <ProgressBarLine progress={progress} />

      {points.map((value, key) => (
        <ProgressPoint key={key} progress={value} passed={progress > value} />
      ))}
    </ProgressBar>
  </StyledProgressLine>
);
