import { Box } from '@xcorejs/ui';
import React, { Fragment } from 'react';
import GeographyMap, { GeographyMapProps } from './GeographyMap';

const InteractiveMap = () => {
  const defaultValues: {phone: GeographyMapProps; pc: GeographyMapProps} = {
    phone: {
      initialX: -640,
      initialY: -40,
      initialScale: 5,
      minScale: 3,
      maxScale: 26,
      device: 'phone'
    },
    pc: {
      initialX: 0,
      initialY: 0,
      initialScale: 1,
      // initialX: -410,
      // initialY: -250,
      // initialScale: 2,
      minScale: 1,
      maxScale: 14,
      device: 'pc'
    }
  };

  return (
    <Fragment>
      <Box display={{ _: 'block', md: 'none' }}>
        <GeographyMap {...defaultValues.phone} />
      </Box>
      <Box display={{ _: 'none', md: 'block' }}>
        <GeographyMap {...defaultValues.pc} />
      </Box>
    </Fragment> 
  );
};
 
export default InteractiveMap;
