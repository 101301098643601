import styled, { css } from 'styled-components';
import { blue, darkBaseHEX, textGreen, white, yellow } from '../../theme/colors';
import { mediaMdDefault, mediaSmMax } from '../../theme/grid';
import { bold, OliMedium } from '../../theme/fonts';
import { SectionText } from '../Text';

interface IHeading {
  colorBlack?: boolean;
}

const StyledHeading1 = styled.h1<{ color?: string }>`
  color: ${white};
  font-size: 3.2rem;
  line-height: 4.1rem;
  font-weight: ${bold};
  text-align: center;
  font-family: ${OliMedium};

  ${mediaMdDefault} {
    font-size: 4rem;
    line-height: 5.1rem;
    text-align: left;
  }
  
  ${props =>
    props.color === 'green' &&
    css`
      color: ${textGreen};
    `}

  ${props =>
    props.color === 'blue' &&
    css`
      color: ${blue};
    `}
  
  ${props =>
    props.color === 'yellow' &&
    css`
      color: ${yellow};
    `}
`;

const StyledHeading2 = styled.h2<{ color?: string }>`
  color: ${white};
  font-size: 2rem;
  line-height: 2.6rem;
  font-weight: ${bold};
  letter-spacing: 0.5px;
  font-family: ${OliMedium};

  ${mediaSmMax} {
    text-align: center;
  }

  & ~ ${SectionText} {
    margin-top: 2rem;
  }
  
  ${props =>
    props.color === 'green' &&
    css`
      color: ${textGreen};
    `}

  ${props =>
    props.color === 'blue' &&
    css`
      color: ${blue};
    `}
  
  ${props =>
    props.color === 'yellow' &&
    css`
      color: ${yellow};
    `}
`;

const StyledHeading3 = styled.h3<IHeading>`
  font-family: ${OliMedium};
  color: ${props => (props.colorBlack ? darkBaseHEX : props.theme.text)};
  font-size: 2.4rem;
  line-height: 3.1rem;
  ${mediaSmMax} {
    text-align: center;
  }
`;

export const Heading1 = StyledHeading1;
export const Heading2 = StyledHeading2;
export const Heading3 = StyledHeading3;
