import { normalize } from '../../../utils/helpers';
import { createReducer } from 'redux-act';
import { lifeValueActions } from './LiveValuesActions';
import { ILifeValue, lifeValuesInitState } from '../LifeValues';
import { rescource } from '../../../utils/rescource';

export const lifeValuesReducer = createReducer(
  {
    [lifeValueActions.fetching.getType()]: state => ({
      ...state,
      ...rescource('fetching', null),
    }),
    [lifeValueActions.success.getType()]: (state, payload: ILifeValue[]) => ({
      ...state,
      ...rescource(
        'success',
        normalize(payload, itm => itm.id),
      ),
    }),
    [lifeValueActions.fail.getType()]: state => ({
      ...state,
      ...rescource('fail', null),
    }),
  },
  lifeValuesInitState,
);
