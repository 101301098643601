import * as React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { StyledFaqPopupLink } from './styles';

interface ClickableProps {
  name: string;
  index: number;
  step?: string;
}

export const ClickableText: React.FC<ClickableProps> = ({ name, index, step, children }) => {
  const [state, setState] = React.useState(index === 0);
  const [state2, setState2] = React.useState(index === -1);

  return (
    <>
      {step ? (
        step !== index.toString() &&
        (state2 ? (
          <>
            <>
              <h3 onClick={() => setState2(false)} className='clicked'>
                <FormattedHTMLMessage id={'faq.' + name + '.subtitle.' + index} />
              </h3>
              <p>
                <FormattedHTMLMessage id={'faq.' + name + '.text.' + index} />
              </p>
            </>

            {children}
          </>
        ) : (
          <>
            <StyledFaqPopupLink onClick={() => setState2(true)}>
              <FormattedHTMLMessage id={'faq.' + name + '.subtitle.' + index} />
            </StyledFaqPopupLink>
          </>
        ))
      ) : state ? (
        <>
          {index > 0 ? (
            <h3 onClick={() => setState(false)} className='clicked'>
              <FormattedHTMLMessage id={'faq.' + name + '.subtitle.' + index} />
            </h3>
          ) : (
            <h3>
              <FormattedHTMLMessage id={'faq.' + name + '.subtitle.' + index} />
            </h3>
          )}
          <p>
            <FormattedHTMLMessage id={'faq.' + name + '.text.' + index} />
          </p>
          {children}
        </>
      ) : (
        <>
          <StyledFaqPopupLink onClick={() => setState(true)}>
            <FormattedHTMLMessage id={'faq.' + name + '.subtitle.' + index} />
          </StyledFaqPopupLink>
        </>
      )}
    </>
  );
};
