import * as React from 'react';
import { StyledForm } from './styles';

interface IForm {
  children?: any;
  className?: string;
  action?: string;
  onSubmit?: (e: any) => any;
}

export const Form: React.FC<IForm> = ({ children, className, onSubmit }) => {
  return (
    <StyledForm className={className} onSubmit={onSubmit}>
      {children}
    </StyledForm>
  );
};

export default Form;
