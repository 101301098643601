import { IStringMap } from '../../../utils';
import { IRescource, rescource } from '../../../utils/rescource';

export interface ILifeValue {
  id: string;
  valueId: string;
  name: string;
  icon: string;
  iconSvg: string;
  mainDefinition: string;
  topUsage: number;
  type: string;
  frontHidden: boolean;
  infoCardHidden: boolean;
}

interface IOtherDefinition {
  content: string;
  priority: number;
}

export interface IDefinition {
  id: string;
  content: string;
  createdAt: number;
}

interface IKind {
  name: string;
  content: string;
  priority: number;
}

export interface IQuote {
  id: string;
  author: string;
  life: string;
  profession: string;
  content: string;
  priority: number;
  voted: boolean;
  voteNumber: number;
}

export interface IPerson {
  id: string;
  name: string;
  image: string;
  life: string;
  profession: string;
  content: string;
  priority: number;
  voted: boolean;
  voteNumber: number;
}

export interface IInterest {
  id: string;
  name: string;
  image: string;
  content: string;
  priority: number;
  voted: boolean;
  voteNumber: number;
}

interface IPatron {
  profileId: string;
  content: string;
}

export interface ILifeValueDetail extends ILifeValue {
  otherDefinitions: IOtherDefinition[];
  interests: IInterest[];
  kinds: IKind[];
  quotes: IQuote[];
  persons: IPerson[];
  patrons: IPatron[];
  definitions: IDefinition[];
}

export const lifeValuesInitState: IRescource<IStringMap<ILifeValue>> = rescource<IStringMap<ILifeValue>>('init', null);
