import styled from 'styled-components';
import { mediaSmDefault } from 'components/theme/grid';

export const TestTip = styled.div<{ backgroundColor: string }>`
  width: 90%;
  max-width: 35rem;
  min-height: 30rem;
  background-color: ${({ backgroundColor }) => backgroundColor};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  padding: 2rem 0;

  ${mediaSmDefault} {
    width: 30%;
    max-width: 30rem;
    min-width: 22rem;
  }
`;

export const SVGFillColor = styled.p<{ color: string }>`
  svg { 
    width: 2rem;
    height: 2rem;
    border: 1px solid white;
    border-radius: 9999px;
    padding: 0.1rem;
    margin-right: 0.1rem;

    path {
      fill: ${({ color }) => color};
  }}
`;