import { useRef, useState, Children } from 'react';
import Carousel from 'react-multi-carousel';
import { getOriginalIndexLookupTableByClones } from 'react-multi-carousel/lib/utils/clones';

export const useCarousel = () => {
  const ref = useRef<Carousel>(null);
  const [index, setIndex] = useState(0);

  const go = (n: number) => {
    // @ts-ignore
    ref.current.goToSlide(ref.current.state.currentSlide + n);
    // @ts-ignore
    // eslint-disable-next-line max-len
    const table = getOriginalIndexLookupTableByClones(ref.current.state.slidesToShow, Children.toArray(ref.current.props.children));
    // @ts-ignore
    setIndex(table[ref.current.state.currentSlide]);
  };

  return {
    ref,
    index,
    go,
    next: () => go(1),
    prev: () => go(-1)
  };
};
