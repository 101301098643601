import * as React from 'react';
import Fade from 'react-reveal/Fade';
import TextBox from '../../TextBox';
import { Heading2, Heading3 } from '../../../atoms/Headings';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { BiggerText } from '../../../atoms/Text';
import { StyledTextArea } from '../../../atoms/FormItems/styles';
import { SecondaryButton } from '../../../atoms/Buttons';
import SVGarrowRight from '../../../atoms/SVGIcons/SVGarrow-right';
import { sendFeedback } from './actions';
import LoadingSpinnerLocal from '../../../atoms/Animations/LoadingSpinnerLocal';
import { FormInput } from '../../../atoms/Form/FormInput';

interface IFeedbackState {
  email: string;
  text: string;
  step: number;
}

class Feedback extends React.Component<{}, IFeedbackState> {
  public state: IFeedbackState = { step: 0, email: '', text: '' };

  public render() {
    return (
      <div className={'bg-purple'}>
        <TextBox className='purple'>
          <Fade ssrReveal bottom duration={1000} distance={'5rem'}>
            <Heading2>
              <FormattedHTMLMessage id={'join.idea.heading'} />
            </Heading2>

            <BiggerText>
              <FormattedHTMLMessage id={'join.idea.text'} />
            </BiggerText>

            {this.getState()}

            <div className={'institut-info'}>
              <BiggerText>
                <FormattedMessage id={'join.idea.or'} />

                <div className={'email'}>
                  <SVGarrowRight />
                  <FormattedMessage id={'join.idea.email'} />
                </div>
              </BiggerText>

              <Heading3>
                <FormattedMessage id={'join.institut'} />
              </Heading3>

              <BiggerText>
                <FormattedMessage id={'join.address'} />
                <FormattedHTMLMessage id={'institut.ico'} />
              </BiggerText>
            </div>
          </Fade>
        </TextBox>
      </div>
    );
  }

  public getState = () => {
    switch (this.state.step) {
    case 0:
      return (
        <form onSubmit={this.submit}>
          <label htmlFor={'email'} className={'label'}>
            <FormattedMessage id={'join.email'} />
          </label>
          <FormInput
            type='email'
            name='email'
            id='email'
            value={this.state.email}
            onChange={e => this.setState({ email: e.target.value })}
          />

          <label htmlFor={'idea'} className={'label'}>
            <FormattedMessage id={'join.idea.textarea.label'} />
          </label>

          <StyledTextArea
            id={'idea'}
            name={'textNote'}
            value={this.state.text}
            onChange={(e: any) => this.setState({ text: e.target.value })}
          />

          <SecondaryButton as='button'>
            <FormattedMessage id={'join.email.send'} />
          </SecondaryButton>
        </form>
      );
    case 1:
      return <LoadingSpinnerLocal />;
    case 2:
      return (
        <div>
          <h2>
            <FormattedMessage id='feedback.thanks' />
          </h2>
        </div>
      );
    }
  };

  private submit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!!this.state.email || !!this.state.text) {
      this.setState({ step: 1 });
      sendFeedback(this.state.email, this.state.text).then(() => this.setState({ step: 2 }));
    }
  };
}

export default Feedback;
