import Subnav from 'components/atoms/Subnav';
import NavItem from 'components/atoms/Nav/NavItem';
import { Route } from 'react-router';
import * as React from 'react';
import { FC } from 'react';
import { createUrl } from 'components/pages/routes';
import Offers from 'components/pages/App/Shop/Offers';
import Account from 'components/pages/App/Shop/Account';
import Buy from 'components/pages/App/Shop/Buy';
import { ProductType } from './data';
import Success from 'components/pages/App/Shop/Buy/Success';
import Ladders from 'components/pages/App/Shop/Buy/Success/Ladders';

const Shop: FC = () => {
  const baseUrl = createUrl('app.shop');
  const buyUrl = createUrl('app.shop') + '/koupit';
  const successUrl = createUrl('app.shop') + '/success';

  return (
    <>
      <Subnav>
        <NavItem to={baseUrl} label='shop.menu.offers' exact />
        <NavItem to={baseUrl + '/moje'} label='shop.menu.account' exact />
      </Subnav>

      <div>
        <Route path={baseUrl} exact={true} render={() => <Offers />} />
        <Route path={baseUrl + '/moje'} exact={true} render={() => <Account />} />

        <Route path={buyUrl + '/plus-1rok'} render={() => <Buy v={ProductType.EticiaPlus1Year} />} />
        <Route path={buyUrl + '/plus-3roky'} render={() => <Buy v={ProductType.EticiaPlus3Years} />} />
        <Route path={buyUrl + '/plus-navzdy'} render={() => <Buy v={ProductType.EticiaPlusForever} />} />
        <Route path={buyUrl + '/voucher-1rok'} render={() => <Buy v={ProductType.EticiaPlus1YearVoucher} />} />
        <Route path={buyUrl + '/voucher-3roky'} render={() => <Buy v={ProductType.EticiaPlus3YearsVoucher} />} />
        <Route path={buyUrl + '/voucher-navzdy'} render={() => <Buy v={ProductType.EticiaPlusForeverVoucher} />} />
        <Route path={buyUrl + '/ladders'} render={() => <Buy v={ProductType.ThreeLadders} />} />
        <Route path={buyUrl + '/tree'} render={() => <Buy v={ProductType.TreeVoucher} />} />

        <Route path={successUrl} exact render={() => <Success />} />
        <Route path={successUrl + '/ladders'} render={() => <Ladders />} />
      </div>
    </>
  );
};

export default Shop;
