import { Flex, FlexProps, Heading3 } from '@xcorejs/ui';
import React, { FC } from 'react';
import Group, { GroupProps } from './Group';

export interface CompareBoxProps extends FlexProps {
  title: string;
  group1: GroupProps;
  group2?: GroupProps;
}

const CompareBox: FC<CompareBoxProps> = ({ title, group1, group2, ...props }) => {
  return ( 
    <Flex {...props}>
      <Heading3 color='white' fontSize={{ _: '2.2rem' }}>{title}</Heading3>
      <Flex
        alignItems='flex-start'
        justifyContent='space-evenly'
        marginTop={{ _: '1rem' }} 
        border='1px solid rgba(255,255,255,0.35)'
        borderRadius='1.5rem'
        padding={{ _: '1rem 2rem' }}
      >
        <Group {...group1} />
        {group2 && <Group {...group2} marginLeft={{ _: '1rem' }} />}
      </Flex>
    </Flex>
  );
};
 
export default CompareBox;
