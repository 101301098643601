import React, { FC, useContext } from 'react';
import { ModalState } from './Modal';
import { GameContext } from 'components/App';
import { Img, Box, Flex } from '@xcorejs/ui';
import Zoom from 'react-reveal/Zoom';
import { gameLevels as data } from 'components/pages/App/ValueCenter/Game/data';
import { useHistory } from 'react-router-dom';
import { createUrl } from 'components/pages/routes';
import { startSurvey } from 'config/api/surveyInternal';

type ResultsImgProps = {
  state: ModalState;
  surveyResultId?: string;
  onAction?: () => unknown;
};

const ResultsImg: FC<ResultsImgProps> = ({ state, surveyResultId, onAction }) => {
  const { questVersion, questLvl } = useContext(GameContext);
  const level = data[questLvl][state];
  const content = level ? level[questVersion] : null;
  const history = useHistory();
  
  if (!content || !content.text4Image)
    return null;

  return (
    <Flex
      mt='1.2rem'
      mx='auto'
      position='absolute'
      left='2rem'
      bottom='2rem'
      zIndex={100}
    >
      {content.text4Image && (
        <Box
          onClick={() => 
            content.repeatAction ? 
              onAction && onAction()
              : content.repeatSurvey ?
                startSurvey((data[questLvl].intro[questVersion] 
                  ? data[questLvl].intro[questVersion].surveyID : 
                  data[questLvl].intro[0].surveyID) as string, null) // možnost kdyby náhodou bylo jenom jedno intro ale více exit/summary verzí
                  .then(r => history.push(createUrl('app.survey', { id: r.id }))) 
                : history.push(surveyResultId ? createUrl('app.surveyResult', {
                  id: surveyResultId,
                }) : '#')}
          flexGrow={1}
          cursor='pointer'
        >
          <Zoom ssrReveal>
            <Img
              src={`/img/game/${content.text4Image}`}
              alt={content.text4 || ''}
              mx='auto'
              display='block'
            />
          </Zoom>
        </Box>
      )}
    </Flex>
  );
};
 
export default ResultsImg;