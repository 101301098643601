export const option = <T>(condition: boolean) =>
  fakePromise<T>((resolve, reject) => condition ? resolve() : reject());

export const _throw = <T>(e: T) => {
  throw e;
};

export const chain = <T>(arg: T) => ({
  then: <R>(callback: (arg: T) => R) => callback(arg),
});

export const _switch = <T, TDefault>(cases: Partial<T>) => (defaultCase?: TDefault) => (key: string | any) =>
  cases.hasOwnProperty(key) ? (cases as any)[key] : defaultCase;

type PromiseResolve<T> = (value?: T | PromiseLike<T>) => void;
type PromiseReject = (reason?: any) => void;

type PromiseExecutor<T> = (resolve: PromiseResolve<T>, reject: PromiseReject) => void;

export const promise = <T>(executor: PromiseExecutor<T>) => new Promise(executor);

export const remove = <T extends {}, TProp extends string>(obj: T, prop: TProp | TProp[]): Omit<T, TProp> =>
  typeof prop === 'string'
    ? remove(obj, [prop])
    : Object.keys(obj).reduce((acc, val) => {
      if (!prop.includes(val as any)) {
        acc[val as keyof T] = obj[val as keyof T];
      }
      return acc;
    }, {} as T);

// Poorly made fake promise to bypass "Unhandled promise rejection" warning and made specialy for option function
// DO NOT EXPORT
const fakePromise = <T>(executor: PromiseExecutor<T>) => {
  const p = promise(executor);

  return {
    then: (callback: PromiseResolve<T>, fail?: PromiseReject) =>
      p.then(callback, reason => fail ? fail(reason) : undefined),
  };
};

export const pick = <T, K extends keyof T>(obj: T, keys: K[]): Pick<T, K> => {
  return keys.reduce((acc, k) => ({ ...acc, [k]: obj[k] }), {}) as Pick<T, K>;
};
