import * as React from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import TopBarContentSwitch from 'components/molecules/TestResult/TopBarContentSwitch';
import { ITestCompare } from '../data';
import { FormattedMessage } from 'react-intl';
import { createUrl } from 'components/pages/routes';
import { IStringMap } from 'utils';
import { ILifeValue } from 'store/Entities/LifeValues';
import { IPage } from './data';
import { StyledGreenStrokeLink, StyledLinkBorder } from '../../../../../atoms/Buttons/styles';
import Fade from 'react-reveal/Fade';
import { Box } from '@xcorejs/ui';

interface IBase {
  result: ITestCompare;
  lifeValues: IStringMap<ILifeValue>;
  pages: IPage[];
  url: { page: string; subpage: number };
  id: string;
}

const BasePage: React.FC<IBase> = ({ result, lifeValues, pages: basePages, url, id }) => {
  const pages = basePages.map(x => {
    return {
      ...x,
      surveyPages: x.containSurvey ? result.surveyResults.length - 1 : undefined,
    };
  });
  const page = pages.find(x => url.page === x.name) || pages[0];
  const baseUrl = createUrl('app.partnerResult', { id });
  const prevPage =
    url.subpage > 0
      ? '/' + url.page + '/' + (url.subpage - 1)
      : pages[page.id! - 1]
        ? pages[page.id! - 1].containSurvey
          ? '/' + pages[page.id! - 1].name + '/' + pages[page.id! - 1].surveyPages
          : pages[page.id! - 1].subpage
            ? '/' + pages[page.id! - 1].name + '/' + (pages[page.id! - 1].subpage!.length - 1)
            : pages[page.id! - 1].to
        : undefined;
  const nextPage = page.containSurvey
    ? url.subpage < page.surveyPages!
      ? '/' + url.page + '/' + (url.subpage + 1)
      : pages[page.id! + 1]
        ? pages[page.id! + 1].to
        : undefined
    : page.subpage && page.subpage!.length - 1 > url.subpage
      ? '/' + url.page + '/' + (url.subpage + 1)
      : pages[page.id! + 1]
        ? pages[page.id! + 1].to
        : undefined;

  return (
    <Row style={{ alignItems: 'flex-start' }}>
      <Col xs={12} md={8} mdOffset={2}>
        <Fade bottom ssrReveal duration={1000} distance={'5rem'}>
          <TopBarContentSwitch
            next={nextPage && baseUrl + nextPage}
            back={prevPage && baseUrl + prevPage}
            title={
              page.subpage
                ? page.subpage[url.subpage] && page.subpage[url.subpage].label
                : page.containSurvey
                  ? result.surveyResults[url.subpage].surveyName
                  : page.label
            }
          >
            {page.subpage
              ? page.subpage[url.subpage] &&
                page.subpage[url.subpage].header &&
                page.subpage[url.subpage].header!(result)
              : page.generate && page.header && page.header(result)}
          </TopBarContentSwitch>
        </Fade>
      </Col>

      {page.subpage
        ? page.subpage[url.subpage] && page.subpage[url.subpage].generate(result, lifeValues)
        : page.generate && page.generate(result, lifeValues, url.subpage)}

      <Col xs={12} md={8} mdOffset={2}>
        {nextPage ? (
          <Fade top ssrReveal duration={1000} distance={'5rem'} delay={400} spy={nextPage}>
            <StyledGreenStrokeLink to={baseUrl + nextPage}>
              <FormattedMessage id='test.next' />
            </StyledGreenStrokeLink>
          </Fade>
        ) : (
          <Fade top ssrReveal duration={1000} distance={'5rem'} delay={400} spy={nextPage}>
            <StyledGreenStrokeLink to={createUrl('app.journal')} className='finish-button'>
              <FormattedMessage id={'test.finish'} />
            </StyledGreenStrokeLink>
          </Fade>
        )}
        <Box mt='3rem'>
          {prevPage && (
            <StyledLinkBorder to={baseUrl + prevPage} color='yellow'>
              <FormattedMessage id='test.back' />
            </StyledLinkBorder>
          )}
        </Box>
      </Col>

      {page.subpage
        ? page.subpage[url.subpage] && page.subpage[url.subpage].footer && page.subpage[url.subpage].footer!(result)
        : page.generate && page.footer && page.footer(result)}
    </Row>
  );
};

export default BasePage;
