import * as React from 'react';
import { IProfile, IValueTestCompare } from '../data';
import { Col, Row } from 'react-styled-flexboxgrid';
import { CustomText, SectionText } from '../../../../../atoms/Text';
import { CardList, GraphLegendCircle } from '../../../../../organisms/PartnerTest/Result/styles';
import ValuesGraph from '../../../../../molecules/Results/Graph/ValuesGraph';
import { FormattedMessage } from 'react-intl';
import { IStringMap } from '../../../../../../utils';
import { Heading2 } from '../../../../../atoms/Headings';
import { StyledResultBoxes } from '../../../../../molecules/TestResult/AnswerBox/styles';
import { IValueTestResultDetail } from 'config/api/valueTestResult';
import PopupIcon from '../../../../../organisms/MainPopup/PopupIcon';
import { primaryBlue, blue } from '../../../../../theme/colors';
import SimpleCard from '../../../../../atoms/Cards/SimpleCard';
import { Box } from '@xcorejs/ui';
import { Flex } from '@xcorejs/ui';
import FAQPopup from '../../../../../atoms/Popups/FAQPopup';
import { ValueTestResultGraph } from '../../../../../atoms/Popups/FAQPopup/popups';
import Fade from 'react-reveal/Fade';
import { GraphLegend } from '../../../../../organisms/PartnerTest/Result/graphLegend';

interface IData {
  characterMatch: IValueTestCompare;
  profiles: IStringMap<IProfile>;
  graphCharacters: IStringMap<IValueTestResultDetail>;
}

const TogetherCharacterMatch: React.FC<IData> = ({ characterMatch, profiles: mainProfiles, graphCharacters }) => {
  const [state, setState] = React.useState<{ character: boolean }>({
    character: true,
  });

  const characterCharacteristics = characterMatch.characterCharacteristics;
  const profiles = Object.keys(mainProfiles);
  return (
    <>
      <StyledResultBoxes className='together'>
        <Row className='custom-order'>
          <Col xs={12} md={5} mdOffset={1} lg={4} lgOffset={2}>
            {state.character ? (
              <Fade bottom ssrReveal duration={1000} distance={'5rem'}>
                <div>
                  <Heading2 color='yellow'>
                    <FormattedMessage id={'results.partners.final.heading.characteristic'} />
                  </Heading2>

                  <CustomText>
                    <FormattedMessage
                      id={
                        (characterCharacteristics.length <= 10
                          ? 'results.partners.together.characterMatch' + characterCharacteristics.length
                          : 'results.partners.together.characterMatch10') + '.part1'
                      }
                    />{' '}
                    {characterCharacteristics.length}{' '}
                    <FormattedMessage
                      id={
                        (characterCharacteristics.length <= 10
                          ? 'results.partners.together.characterMatch' + characterCharacteristics.length
                          : 'results.partners.together.characterMatch10') + '.partBold2'
                      }
                    />{' '}
                    <FormattedMessage
                      id={
                        (characterCharacteristics.length <= 10
                          ? 'results.partners.together.characterMatch' + characterCharacteristics.length
                          : 'results.partners.together.characterMatch10') + '.part3'
                      }
                    />
                  </CustomText>
                </div>

                <div>
                  {characterCharacteristics.some(p => p.priority < 5) && (
                    <>
                      <CardList>
                        {characterCharacteristics.map((value, i) => (
                          <Fade bottom ssrReveal duration={1000} distance={'5rem'} delay={200 * i} key={i}>
                            <Box mb='1rem'>
                              <SimpleCard color='green' name={value.text} size='small' />
                            </Box>
                          </Fade>
                        ))}
                      </CardList>
                    </>
                  )}
                </div>
              </Fade>
            ) : (
              <Fade bottom ssrReveal duration={1000} distance={'5rem'}>
                {GraphLegend}
              </Fade>
            )}
          </Col>
          <Col xs={12} md={5} lg={4}>
            <Fade bottom ssrReveal duration={1000} distance={'5rem'}>
              <Flex alignItems='center' justifyContent='center'>
                {profiles.map((profile, index) => (
                  <Flex key={index} mb='3rem' mr={index === 0 ? '3rem' : 0} alignItems='center'>
                    <GraphLegendCircle color={index === 0 ? 'green' : 'blue'} />

                    <span style={{ color: index === 0 ? '#00e58d' : '#47a7ff' }}>
                      {mainProfiles[profile].fullname ? mainProfiles[profile].fullname : mainProfiles[profile].nick}
                    </span>
                  </Flex>
                ))}
              </Flex>

              <ValuesGraph
                points={[
                  {
                    points: graphCharacters[profiles[0]].characters.map(x => x.percentage),
                    fill: 'rgba(0,229,141,0.25)',
                    fillOpacity: '0.6',
                    stroke: '#00e58d',
                    strokeWidth: '3px',
                  },
                  {
                    points: graphCharacters[profiles[1]].characters.map(x => x.percentage),
                    fill: primaryBlue,
                    fillOpacity: '0.6',
                    stroke: blue,
                    strokeWidth: '3px',
                  },
                ]}
                backgroundIndex={0}
                pointToCircle={() => {
                  return {
                    index: 0,
                    additions: [],
                  };
                }}
                letterColor='both'
              />

              <Flex mt='2rem' mb='2rem' alignItems='center' justifyContent='center'>
                <GraphLegendCircle color='yellow' />

                <span style={{ color: 'rgba(244, 244, 246, 0.5)' }}>
                  <FormattedMessage id='results.partners.together.characterMatch.together' />
                </span>
              </Flex>

              <Box mt='2rem'>
                <SectionText color='#00e58d' onClick={() => setState({ character: !state.character })}>
                  {state.character ? (
                    <FormattedMessage id={'partnerResult.graph.faq'} />
                  ) : (
                    <FormattedMessage id={'results.graph.together'} />
                  )}
                </SectionText>
              </Box>
            </Fade>
          </Col>
        </Row>
      </StyledResultBoxes>

      <Box mt='2rem'>
        <FAQPopup popup={ValueTestResultGraph}>
          <PopupIcon text={'partnerResult.helpPopup.characterMatch'} />
        </FAQPopup>
      </Box>
    </>
  );
};

export default TogetherCharacterMatch;
