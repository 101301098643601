import styled from 'styled-components';
import { white } from '../../theme/colors';
import { bold } from '../../theme/fonts';
import { mediaMdDefault } from '../../theme/grid';

export const StyledTestDivider = styled.div`
  padding: 2rem 0;
  width: 100%;
  height: 100%;
  text-align: center;
  position: relative;
  display: none;

  ${mediaMdDefault} {
    display: block;
  }

  img {
    display: inline-block;
    vertical-align: middle;
    user-select: none;
  }

  p {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    color: ${white};
    user-select: none;
  }

  .divider-wrap {
    height: 100%;
    position: relative;
  }

  .divider-middle {
    padding: 4.5rem 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    text-align: center;
    user-select: none;

    p {
      width: 100%;
      opacity: 0.5;
      font-size: 1.4rem;
      line-height: 2.5rem;
      margin-top: 1.5rem;
    }

    img {
      max-width: 6.4rem;
    }
  }

  .next,
  .prev {
    cursor: pointer;
    position: absolute;
    left: 0;
    opacity: 0.75;
    transition: opacity 300ms ease;

    p {
      font-size: 2rem;
      line-height: 2.2rem;
      font-weight: ${bold};
    }

    img {
      margin: 0 1.5rem;
      vertical-align: middle;
    }

    &:hover {
      opacity: 1;
    }
  }

  .prev {
    top: 0;
  }

  .next {
    bottom: 0;
  }
`;
