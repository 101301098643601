import * as React from 'react';
import LayoutA from './A';
import { IResponse } from '../../data';
import { IAdditionalQuestion, ISurveySimpleQuestion } from '../../data';
import LayoutB from './B';
import LayoutC from './C';
import { IStringMap } from 'utils';
import { _switch } from 'utils/option';

interface ISimple {
  question: IAdditionalQuestion<ISurveySimpleQuestion>;
  response: IResponse<IStringMap<boolean>>;
  responseMinus?: IResponse<IStringMap<boolean>>;
  onChange: (response: IResponse<{}>) => void;
  onChangeMinus?: (response: IResponse<{}>) => void;
  type?: 'd21minus' | 'd21plus';
  theme: string;
}

class Simple extends React.Component<ISimple> {
  public render() {
    const { question, response, responseMinus, type, theme } = this.props;
    return _switch({
      a: () => (
        <LayoutA
          data={question.data}
          help={question.help}
          onChange={this.onChange}
          selected={this.getSelected(response.data!)}
        />
      ),
      b: () => (
        <LayoutB
          data={question.data}
          help={question.help}
          onChange={this.onChange}
          onChangeMinus={this.onChangeMinus}
          selected={this.getSelected(response.data!)}
          selectedMinus={responseMinus ? this.getSelectedMinus(responseMinus.data!) : undefined}
          type={type}
          theme={theme}

        />
      ),
      c: () => (
        <LayoutC
          data={question.data}
          help={question.help}
          onChange={this.onChange}
          selected={this.getSelected(response.data!)}
          theme={theme}
        />
      ),
    })()(question.layout)();
  }

  private onChange = (id: string, val: boolean) =>
    this.props.onChange(
      this.getData(
        {
          ...this.props.question.data.maxAnswersCount === 1 ? {} : this.props.response.data,
          [id]: val,
        },
        this.props.question.data.minAnswersCount,
      ),
    );


  private onChangeMinus = (id: string, val: boolean) =>
    // @ts-ignore
    this.props.onChangeMinus(
      this.getData(
        {
          // @ts-ignore
          ...this.props.question.data.maxAnswersCount === 1 ? {} : this.props.responseMinus.data,
          [id]: val,
        },
        this.props.question.data.minAnswersCount,
      ),
    );

  private getData = (data: IStringMap<boolean>, min: number) => ({
    data,
    submitable: this.getSelected(data).length + this.getSelectedMinus(data).length >= min,
  });

  private getSelected = (data: IStringMap<boolean>) => data === null ? [] : Object.keys(data).filter(k => data[k]);
  private getSelectedMinus = (dataMinus: IStringMap<boolean>) => dataMinus === null ?
    [] : Object.keys(dataMinus).filter(k => dataMinus[k]);
}

export default Simple;
