import * as React from 'react';
import { FC, Fragment } from 'react';
import { Box, Flex, Img, Modal, Typography } from '@xcorejs/ui';
import { BlueTheme } from 'components/theme/colors';
import { PrimaryButton } from 'components/components/Button';
import { IUserInfo } from 'store/User';
import Fade from 'react-reveal/Fade';
import { FormattedMessage } from 'react-intl';

type WelcomeProps = {
  user: IUserInfo;
  close?: () => unknown;
}

const Welcome: FC<WelcomeProps> = ({ user, close }) => {
  return (
    <Box
      background={BlueTheme.primary}
      maxWidth='90%'
      height='calc(100% - 6rem)'
      borderRadius='1rem'
      marginTop='3rem'
      marginLeft='5%'
      overflow='auto'
      padding='4.3rem 1.6rem 3rem'
      position='relative'
    >
      {user.eticonId ? (
        <Fade ssrReveal duration={1000} distance={'5rem'}>
          <FormattedMessage id='game.welcomeModal.withEticon.text1'>
            {message => (
              <Typography fontWeight={800}>
                {message}
              </Typography>
            )}
          </FormattedMessage>

          <FormattedMessage id='game.welcomeModal.withEticon.text2'>
            {message => (
              <Typography fontSize='1.4rem' fontWeight={500}>
                {message}
              </Typography>
            )}
          </FormattedMessage>

          <Img src='chyse_320x120.png' mt='1.2rem' display='block' mx='auto' alt='' />

          <Box mt='1.2rem' p='1.6rem' bg='rgba(11, 12, 28, 0.5)' borderRadius='0.4rem'>
            <Typography
              fontWeight={800}
              color='yellow'
            >
              <FormattedMessage id='game.welcomeModal.withEticon.text3'>
                {message => message}
              </FormattedMessage>
            </Typography>

            <Typography fontSize='1.4rem'>
              <FormattedMessage id='game.welcomeModal.withEticon.text4'>
                {message => message}
              </FormattedMessage>
            </Typography>

            <Box width='12rem' height='0.3rem' bg='blue' my='1.2rem' mx='auto' />

            <Typography fontSize='1.4rem'>
              <FormattedMessage id='game.welcomeModal.withEticon.text5'>
                {message => message}
              </FormattedMessage>
            </Typography>
          </Box>

          <Flex justifyContent='center' mt='3.2rem'>
            <PrimaryButton
              size='sm'
              onClick={close}
            >
              <FormattedMessage id='game.welcomeModal.withEticon.text6'>
                {message => message}
              </FormattedMessage>
            </PrimaryButton>
          </Flex>
        </Fade>
      ) : (
        <Fade ssrReveal duration={1000} distance={'5rem'}>
          <Img src='chyse_320x120.png' display='block' mx='auto' alt='' />

          <Box mt='1.2rem' p='1.6rem' bg='rgba(11, 12, 28, 0.5)' borderRadius='0.4rem'>
            <Typography fontWeight={800} mt='1.2rem'>
              <FormattedMessage id='game.welcomeModal.withoutEticon.text1'>
                {message => message}
              </FormattedMessage>
            </Typography>

            <Typography fontSize='1.4rem' fontWeight={500}>
              <FormattedMessage id='game.welcomeModal.withoutEticon.text2'>
                {message => message}
              </FormattedMessage>
            </Typography>

            <Box mt='1.2rem'>
              <Typography fontWeight={700} color='yellow'>
                <FormattedMessage id='game.welcomeModal.withoutEticon.text3'>
                  {message => message}
                </FormattedMessage>
              </Typography>
              <Typography fontSize='1.4rem'>
                <FormattedMessage id='game.welcomeModal.withoutEticon.text4'>
                  {message => message}
                </FormattedMessage>
              </Typography>

              <Box width='12rem' height='0.3rem' bg='blue' my='1.2rem' mx='auto' />

              <Typography fontSize='1.4rem'>
                <FormattedMessage id='game.welcomeModal.withoutEticon.text5'>
                  {message => message}
                </FormattedMessage>
              </Typography>
            </Box>
          </Box>

          <Flex mt='3.2rem' justifyContent='center'>
            <PrimaryButton onClick={close}>
              <FormattedMessage id='game.welcomeModal.withoutEticon.text6'>
                {message => message}
              </FormattedMessage>
            </PrimaryButton>
          </Flex>
        </Fade>
      )}
    </Box>
  );
};

export default Welcome;
