import styled from 'styled-components';
import { blue, yellow, gray, textGreenRGB } from '../../theme/colors';
import { Fox } from '../../theme/fonts';
import { mediaMdDefault } from '../../theme/grid';
import { StyledLink } from '../Buttons/styles';
import { StyledCustomSelect } from '../Select/styles';

export const StyledFormGroupInline = styled.div`
  display: block;
  text-align: center;
  position: relative;

  ${mediaMdDefault} {
    display: inline-block;
    margin: 0;
    width: 100%;
  }

  input {
    margin: 0 0.5rem;
    padding: 0 0.5rem;
    width: 19rem;
    border: 0;
    border-bottom: 0.3rem solid rgba(${textGreenRGB}, 0.8);
    background: transparent;
    font-family: ${Fox};
    text-align: center;
    color: ${gray};
    transition: border 0.3s;

    &[type="number"] {
      width: 6rem;
    }

    ${mediaMdDefault} {
      text-align: left;
    }

    &:focus,
    &:hover {
      border-bottom: 0.3rem solid rgba(${textGreenRGB}, 1);
    }

    &[disabled] {
      opacity: 0.5;
    }

    &::-webkit-input-placeholder {
      opacity: 0.6;
      color: ${gray};
    }
    ::-moz-placeholder {
      opacity: 0.6;
      color: ${gray};
    }
    :-ms-input-placeholder {
      opacity: 0.6;
      color: ${gray};
    }
    :-moz-placeholder {
      opacity: 0.6;
      color: ${gray};
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      transition: background-color 10000s ease-in-out 0s;
      -webkit-box-shadow: transparent;
      -webkit-text-fill-color: ${gray} !important;
    }
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  .error {
    margin-bottom: 0;
    margin-top: 1rem;
    width: 100%;
    text-align: center;
    font-size: 1.6rem;
    line-height: 1.8rem;
    display: block;
    color: ${yellow};
  }

  .notRequired {
    display: inline-block;
    float: right;
    margin-top: 3px;

    span {
      font-size: 2rem;
    }
  }

  ${StyledLink} {
    display: block;
    margin-top: 0.3rem;

    span {
      display: inline-block;
    }

    ${mediaMdDefault} {
      display: inline-block;
      float: right;
    }
  }

  ${StyledCustomSelect} {
    .Select {
      margin: 0 0.5rem;
      border-bottom: 0.3rem solid rgba(0, 229, 141, 0.8);
      background: transparent;
    }

    .Select-control {
      padding: 0.5rem;
      padding-bottom: 0;
      padding-top: 0;
      width: 20rem;
      border-radius: 0;
      background: transparent;
    }

    .Select-placeholder,
    .Select--single > .Select-control .Select-value {
      margin-top: 0;
      padding: 0 0.5rem;
      color: ${gray};

      &:focus {
        outline: 0;
      }
    }

    .Select-placeholder {
      opacity: 0.6;
    }

    .Select.is-focused > .Select-control {
      background: transparent;
    }

    .Select-value:focus {
      outline: 0;
    }

    .Select-menu-outer {
      margin-top: 1rem;
    }

    .Select-input input {
      &:focus {
        border-bottom: 0;
      }
    }

    .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
      color: ${gray};
    }

    .Select.has-value.Select--single > .Select-control .Select-value .Select-value-label {
      color: ${gray};
    }
  }
` as any;
