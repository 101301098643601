import * as React from 'react';
import { connect } from 'react-redux';
import { openDialogPopup } from '../../../store/Popup/dialogPopups';
import { IInfoPopup, IRawInfoPopup } from '../../organisms/MainPopup/InfoPopup/index';
import { openHtmlInfoPopup, openTextInfoPopup, openRawInfoPopup } from '../../../store/Popup/infoPopups';
import { openCustomPopup } from 'store/Popup/customPopups';
import { PopupComponent } from '../../../store/Popup/index';

export interface IWithPopups {
  popups: {
    html: (data: IInfoPopup) => Promise<{}>;
    text: (data: IInfoPopup) => Promise<{}>;
    raw: (data: IRawInfoPopup) => Promise<{}>;
    dialog: (data: IInfoPopup) => Promise<string>;
    custom: (content: PopupComponent) => Promise<{}>;
  };
}

export default <TOutterProps extends {}>(component: React.ComponentType<TOutterProps & IWithPopups>) =>
  connect(
    null,
    (dispatch: any, props: TOutterProps) => ({
      ...props,
      popups: {
        html: (data: IInfoPopup) => openHtmlInfoPopup(data)(dispatch),
        text: (data: IInfoPopup) => openTextInfoPopup(data)(dispatch),
        raw: (data: IRawInfoPopup) => openRawInfoPopup(data)(dispatch),
        dialog: (data: IInfoPopup) => openDialogPopup(data)(dispatch),
        custom: (content: PopupComponent) => openCustomPopup(content)(dispatch),
      },
    }),
    (_: any, dispatchProps): TOutterProps & IWithPopups => dispatchProps,
  )(component as any);
