import * as React from 'react';
import { IDefinition, ILifeValueDetail } from '../../../store/Entities/LifeValues';
import ExternalSVG from '../../atoms/ExternalSVG';
import { FormattedMessage } from 'react-intl';
import { PrimaryButton } from '../../atoms/Buttons';
import { TransitionGroup } from 'react-transition-group';
import CustomFade from '../../atoms/Animations/CustomFade';
import { StyledTransitionWrapper } from './styles';
import All from './Tabs/All';
import Quotes from './Tabs/Quotes';
import Chars from './Tabs/Chars';
import Trivia from './Tabs/Trivia';
import Def from './Tabs/Def';
import wrapIntl, { IWrapIntl } from '../../atoms/wrapIntl';
import { getDefinitions } from './index';

enum Tabs {
  all = 'all',
  quotes = 'quotes',
  chars = 'chars',
  trivia = 'trivia',
  def = 'def',
}

interface IDataCollector {
  inTest: boolean;
  detail: ILifeValueDetail;
}

interface IValueDataCollector {
  tab: Tabs;
  detail: ILifeValueDetail;
  shouldShowThank: boolean;
}

class DataCollector extends React.Component<IDataCollector & IWrapIntl, IValueDataCollector> {
  public state = {
    tab: Tabs.all,
    detail: this.props.detail,
    shouldShowThank: false,
  };

  public render() {
    const { inTest } = this.props;
    return (
      <>
        <div className={'value-wrapper'}>
          <div className={'value'}>
            <ExternalSVG src={this.state.detail.iconSvg} />
            <h2>{this.state.detail.name}</h2>
            <span className={'picked'}>
              <FormattedMessage id={'slider.values.pickedBy'} /> {Math.round(this.state.detail.topUsage)}%{' '}
              <FormattedMessage id={'slider.values.players'} />
            </span>

            {/*
                    <p>{detail.mainDefinition}</p>
                    */}

            {this.state.tab === Tabs.def ? (
              ''
            ) : (
              <PrimaryButton onClick={this.tabClick(Tabs.def)}>
                <FormattedMessage id={'valueDefinition.add'} />
              </PrimaryButton>
            )}
          </div>
        </div>
        <div className={'detail-wrapper'}>
          <div className={'submenu'}>
            <ul>
              <li className={this.state.tab === Tabs.all ? 'active' : ''} onClick={this.tabClick(Tabs.all)}>
                <FormattedMessage id={'valueDefinition.tabs.all'} />
              </li>
              {/*/}
              <li
                className={
                  this.state.tab === Tabs.quotes ? "active" : ""
                }
                onClick={this.tabClick(Tabs.quotes)}
              >
                <FormattedMessage
                  id={"valueDefinition.tabs.quotes"}
                />
              </li>
              <li
                className={
                  this.state.tab === Tabs.chars ? "active" : ""
                }
                onClick={this.tabClick(Tabs.chars)}
              >
                <FormattedMessage
                  id={"valueDefinition.tabs.chars"}
                />
              </li>
              <li
                className={
                  this.state.tab === Tabs.trivia ? "active" : ""
                }
                onClick={this.tabClick(Tabs.trivia)}
              >
                <FormattedMessage
                  id={"valueDefinition.tabs.trivia"}
                />
              </li>
              {/**/}
              <li className={this.state.tab === Tabs.def ? 'active' : ''} onClick={this.tabClick(Tabs.def)}>
                <FormattedMessage id={'valueDefinition.tabs.def'} />
              </li>
            </ul>
          </div>
          <div className={'tab'}>
            <TransitionGroup className='transition-group'>
              <CustomFade key={this.state.tab}>
                <StyledTransitionWrapper>{this.getTab(this.state.detail, inTest)}</StyledTransitionWrapper>
              </CustomFade>
            </TransitionGroup>
          </div>
        </div>
      </>
    );
  }
  public getTab = (detail: ILifeValueDetail, inTest: boolean) => {
    switch (this.state.tab) {
    case 'all':
      return (
        <All
          detail={detail}
          inTest={inTest}
          onVote={(id: string, type: string, text?: string) => this.VoteUpdate(id, type, text)}
        />
      );
    case 'quotes':
      return (
        <Quotes
          detail={detail}
          inTest={inTest}
          onVote={(id: string, type: string, text?: string) => this.VoteUpdate(id, type, text)}
        />
      );
    case 'chars':
      return (
        <Chars
          detail={detail}
          inTest={inTest}
          onVote={(id: string, type: string, text?: string) => this.VoteUpdate(id, type, text)}
        />
      );
    case 'trivia':
      return (
        <Trivia
          detail={detail}
          inTest={inTest}
          onVote={(id: string, type: string, text?: string) => this.VoteUpdate(id, type, text)}
        />
      );
    case 'def':
      return (
        <Def
          detail={detail}
          inTest={inTest}
          onSubmit={(id: string, type: string, text?: string) => this.VoteUpdate(id, type, text)}
          key={'def'}
          shouldShowThank={this.state.shouldShowThank}
        />
      );
    }
  };

  private tabClick = (tab: Tabs) => () => this.setState({ tab, shouldShowThank: false });

  private VoteUpdate(id: string, type: string, text?: string) {
    const data = this.state.detail;
    let index = 0;
    switch (type) {
    case 'author':
      index = data.persons.findIndex(x => x.id === id);
      data.persons[index].voteNumber++;
      break;
    case 'quote':
      index = data.quotes.findIndex(x => x.id === id);
      data.quotes[index].voteNumber++;
      break;
    case 'trivia':
      index = data.interests.findIndex(x => x.id === id);
      data.interests[index].voteNumber++;
      break;
    case 'def':
      const definition: IDefinition = {
        id,
        content: text !== undefined ? text : '',
        createdAt: data.definitions.length > 0 ? getDefinitions(data)[0].createdAt + 1 : 1,
      };
      data.definitions.push(definition);
      break;
    default:
      return;
    }
    this.setState({ detail: data, shouldShowThank: true });
  }
}

export default wrapIntl(DataCollector);
