import * as React from 'react';
import { Row, Col } from 'react-styled-flexboxgrid';
import CardPlaceholder from './Cards/CardPlaceholder';
import { IStringMap } from '../../../../utils';
import { IRoundStepData } from './data';
import { ValueCardState } from './Cards/ValueCard';
import wrapIntl, { IWrapIntl } from '../../../atoms/wrapIntl';

interface ISelected {
  round: IRoundStepData;
  size: IStringMap<number>;
  selected: Array<string | null>;
  selectValue: (value: string, index: number) => any;
  resetValue: (value: string) => any;
  requiredValuesCount?: number;
}

const Selected: React.FC<ISelected & IWrapIntl> = (
  {
    round,
    selectValue,
    resetValue,
    size,
    selected,
    formatMsg,
    requiredValuesCount
  }
) => (
  <Row>
    {selected.map((id, key) => (
      <Col key={key} {...size}>
        <CardPlaceholder
          index={key}
          card={round.values[id!]}
          selectValue={selectValue}
          resetValue={resetValue}
          state={ValueCardState.selected}
          showNumber={round.round !== 3 || round.step !== 2}
          requiredValuesCount={requiredValuesCount}
          text={
            (round.round !== 3 || round.step !== 2) &&
            (key === 0
              ? formatMsg('test.selected.most')
              : key === selected.length - 1
                ? formatMsg('test.selected.least')
                : undefined)
          }
        />
      </Col>
    ))}
  </Row>
);

export default wrapIntl(Selected);
