import { text } from '@xcorejs/ui';

export const textTheme = text({
  default: {
    fontFamily: 'OliRegular',
    fontSize: '1.5rem',
    lineHeight: '1.8rem',
    transition: 'color 300ms',
    color: 'rgba(244, 244, 246, 1)'
  },
  variants: {
    span: {

    },
    strong: {
      color: '#1e1e1e'
    }
  }
});
