import { PrimaryButton } from 'components/components/Button';
import { createUrl } from 'components/pages/routes';
import React, { FC, useState } from 'react';
import { useLocation } from 'react-router-dom';
import NavItem from '../Nav/NavItem';
import { DesktopMenu, Logo, MobileMenu, StyledLink, StyledNavigation } from './styles';

interface NavigationProps {
    children?: React.ReactNode[] | React.ReactNode;
}

const Navigation:FC<NavigationProps> = ({ children }) => {
  const location = useLocation();
  // const [showLogo, setShowLogo] = useState(false);

  // useEffect(() => {
  //   const handleScroll = () => {      
  //     const scrollThreshold = 100; // Adjust this value to control when the logo appears (in pixels)
  //     setShowLogo(window.scrollY > scrollThreshold);
  //   };

  //   window.addEventListener('scroll', handleScroll);
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  const defaultLinks = [
    { to: 'newPage', label: 'homepage.menu.homepage', exact: true },
    { to: 'valueGame', label: 'homepage.menu.valueGame', exact: true },
    { to: 'eticiaPlus', label: 'homepage.menu.eticiaPlus', exact: true },
    { to: 'about', label: 'homepage.menu.about', exact: true },
  ];

  return ( 
    <StyledNavigation>
      <Logo src='/img/logo/logo@2x.png' alt='logo' showLogo />
      <Mobile location={location.pathname}>
        {defaultLinks.map(createNavItem())}
        {children}
        <NavItem to={createUrl('register')} label='Registrovat'  />
      </Mobile>
      <Desktop location={location.pathname}>
        {defaultLinks.map(createNavItem())}
        {children}
        <StyledLink to={createUrl('register')}>
          <PrimaryButton height='unset' >
          Registrovat
          </PrimaryButton>
        </StyledLink>
      </Desktop>
    </StyledNavigation>
  );
};
 
export default Navigation;

interface NavPropWithLocation extends NavigationProps {
  children: NonNullable<NavigationProps['children']>;
  location: string;
}

const Mobile:FC<NavPropWithLocation> = ({ children, location }) => {
  const [isMobileOpen, setIsMobileOpen] = useState<boolean>(false);

  return (
    <MobileMenu isMobileOpen={isMobileOpen}>
      <div onClick={() => setIsMobileOpen(prev => !prev)}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      {/* nemám lepší nápad jak to menu zavřít */}
      <ul onClick={() => setIsMobileOpen(false)}>
        {setActiveChild(children, location)}
      </ul>
    </MobileMenu>
  );
};

const Desktop:FC<NavPropWithLocation> = ({ children, location }) => {
  return (
    <DesktopMenu>
      {setActiveChild(children, location)}
    </DesktopMenu>
  );
};

type childrenType = 
  | React.ReactNode
  | React.ReactNode[] 
  | (React.ReactNode[] & string) 
  | (React.ReactNode[] & number) 
  | (React.ReactNode[] & false) 
  | (React.ReactNode[] & true) 
  | (React.ReactNode[] & React.ReactNodeArray) 
  | (React.ReactNode[] & React.ReactPortal) 
  | (React.ReactNode[] & React.ReactElement<any, 
    | string 
    | ((props: any) => React.ReactElement<any, 
      | string 
      | any 
      | (new (props: any) => React.Component<any, any, any>)> 
      | null) 
    | (new (props: any) => React.Component<any, any, any>)>);

interface ValueProp {
  to: string;
  label: string;
  exact: boolean;
}

function createNavItem(): (value: ValueProp, index: number, array: ValueProp[]) => JSX.Element {
  return (item, index) => (<NavItem key={index} to={createUrl(item.to)} label={item.label} exact={item.exact} />);
}

function setActiveChild(children: childrenType, location: string): React.ReactNode {
  return React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      const isActive = child.props.to === location;
      return React.cloneElement(child, { activeClassName: isActive ? 'activeLink' : '' });
    }
    return child;
  });
}
