import * as React from 'react';
import { Box, Heading1, Heading2, Flex, Img } from '@xcorejs/ui';
import { VioletTheme } from 'components/theme/colors';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { SectionHomepage } from '../styles';
import Fade from 'react-reveal/Fade';

const Explain = () => {
  return ( 
    <SectionHomepage backgroundColor={VioletTheme.darken}>
      <StyledContainer>
        <Fade ssrReveal bottom duration={1000} distance={'5rem'}>
          <Heading1 color={VioletTheme.text} textAlign='center'>
            <FormattedMessage id='homepage.box.explain.heading' />
          </Heading1>
        </Fade>

        <Flex flexDirection={{ _: 'column', md: 'row' }} justifyContent='center' alignItems='center'>
          <Fade ssrReveal bottom duration={1000} distance={'5rem'}>
            <Box maxWidth={{ _: '330px' }}>
              <Heading2 color='#2e2b46' position='relative'>
                <FormattedMessage id='homepage.box.explain.heading2' />
                <Img
                  src='/img/homepage/hp-doodle-1.png'
                  alt=''
                  position='absolute'
                  left='0'
                  top='0' 
                  transform={{ 
                    _: 'translate(-40%, -100%)',
                    xs: 'translate(-100%, -50%)',
                    md: 'translate(-90%, -110%)'
                  }} />
              </Heading2>
              <CustomList>
                <li><FormattedMessage id='homepage.box.explain.green.1' /></li>
                <li><FormattedMessage id='homepage.box.explain.green.2' /></li>
                <li><FormattedMessage id='homepage.box.explain.green.3' /></li>
                <li><FormattedMessage id='homepage.box.explain.green.4' /></li>
              </CustomList>
            </Box>
          </Fade>

          <Fade ssrReveal bottom duration={1000} distance={'5rem'}>
            <Box maxWidth={{ _: '330px' }} mt={{ _: '6rem', md: '0' }} ml={{ _: '0', md: '12rem' }}>
              <Heading2 color='#2e2b46' position='relative'>
                <FormattedMessage id='homepage.box.explain.heading3' />
                <Img
                  src='/img/homepage/hp-doodle-2.png'
                  alt=''
                  position='absolute'
                  left='0'
                  top='0' 
                  transform={{ 
                    _: 'translate(-30%, 100%)',
                    xs: 'translate(-100%, 40%)',
                    md: 'translate(-120%, 70%)'
                  }} />
                <Img
                  src='/img/homepage/hp-doodle-3.png'
                  alt=''
                  position='absolute'
                  right='0'
                  top='0' 
                  transform={{ 
                    _: 'translate(20%, -80%)',
                    md: 'translate(90%, -70%)'
                  }} />
              </Heading2>
              <CustomList>
                <li><FormattedMessage id='homepage.box.explain.blue.1' /></li>
                <li><FormattedMessage id='homepage.box.explain.blue.2' /></li>
                <li><FormattedMessage id='homepage.box.explain.blue.3' /></li>
                <li><FormattedMessage id='homepage.box.explain.blue.4' /></li>
              </CustomList>
            </Box>
          </Fade>
        </Flex>
      </StyledContainer>
    </SectionHomepage>
  );
};
 
export default Explain;

const StyledContainer = styled.div`
  h1 {
    color: ${VioletTheme.text}
    margin-bottom: 4rem;
  }

  h2 {
    color: #2e2b46;
    margin-bottom: 3rem;
    max-width: 300px;
  }

  ul {
    font-size: 14px;

    li + li {
      margin-top: 1.5rem;
    }
  }
  
  .centered-list {
    text-align: center;
  }
`;

const CustomList = styled.ul`
  list-style-type: none;
  padding-left: 2rem;

  li {
    position: relative;
    padding-left: 3rem;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  li:nth-child(even):before {
    background-image: url('/img/homepage/hp-point-2.png');
    background-repeat: no-repeat;
    background-size: contain;
  }

  li:nth-child(odd):before {
    background-image: url('/img/homepage/hp-point-1.png');
    background-repeat: no-repeat;
    background-size: contain;
  }
`;