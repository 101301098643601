import * as React from 'react';
import StyledFooter from './styles';
import { FormattedMessage } from 'react-intl';

export const Footer = () => (
  <StyledFooter>
    <footer>
      <p>
        <span className='copy'>
          <span>&copy; 2020</span> Eticia 21
        </span>
        <a href='mailto:info@eticia.cz' target='_blank' rel='noopener noreferrer'>
          <span>info@eticia21.com</span>
        </a>
        <a href='/Prohlaseni.pdf' target='_blank' rel='noopener noreferrer'>
          <FormattedMessage id={'eticiaCompany.gdpr'} />
        </a>
        <a href='/Všeobecné obchodní podmínky společnosti Eticia 21.pdf' target='_blank' rel='noopener noreferrer'>
          <FormattedMessage id={'eticiaCompany.vop'} />
        </a>
        <a href='tel:+420608672113' target='_blank' rel='noopener noreferrer'>
          <FormattedMessage id={'eticiaCompany.tel'} />
        </a>
      </p>
    </footer>
  </StyledFooter>
);
