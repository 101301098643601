import { IUserInfo, userInitState } from '../User';
import { createReducer } from 'redux-act';
import { rescource } from '../../utils/rescource';
import { userActions } from './UserActions';

export const userReducer = createReducer(
  {
    [userActions.fetching.getType()]: state => ({
      ...state,
      ...rescource('fetching', null),
      data: state.data as any,
    }),
    [userActions.success.getType()]: (state, info: IUserInfo) => ({
      ...state,
      ...rescource('success', info),
      fullProfile: !!info.region && !!info.sex && !!info.maritalStatus,
      isAuthed: true,
    }),
    [userActions.fail.getType()]: state => ({
      ...state,
      ...rescource('fail'),
    }),
  },
  userInitState,
);
