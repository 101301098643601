import { IStringMap } from '.';
import { RequestContext } from '../components/atoms/RequestContext';
import canUseDOM from './canUseDOM';
import { fetchException } from './exceptions';
import { chain, _throw } from './option';

interface IRequestInit extends RequestInit {
  headers?: IStringMap<string>;
}

const cookiesToString = (cookies: Record<string, string>) =>
  cookies !== null
    ? Object.keys(cookies).reduce((res: string, key: string) => res += key + '=' + cookies[key] + ';', '')
    : '';

export const wrappedFetch = (url: RequestInfo, init?: IRequestInit): Promise<Response> => {
  const ssr = !canUseDOM;
  const { cookies, locale }: RequestContext = (ssr ? global as any : window as any).requestContext;
  init = init !== undefined ? init : {};
  // Enhance the request configuration with cache control
  const config: IRequestInit = {
    ...init,
    headers: {
      ...init.headers ? init.headers : {},
      ['accept-language']: locale,
      Cookie: ssr ? cookiesToString(cookies) : '',
    },
    credentials: !ssr ? 'same-origin' : undefined,
  };
  return fetch(url, config);
};


let lastId: string | null = null;
let fetchCallback: FetchCallback | null = null;
export type FetchCallback = (response: Response) => unknown;
export const setFetchCallback = (callback: FetchCallback) => {
  fetchCallback = response => {
    if (response.headers.has('Last-Notification')) {
      const val = response.headers.get('Last-Notification');
      if (val !== lastId) {
        lastId = val;
        callback(response);
      }
    }
  };
};

export const jsonFetch = <T>(url: RequestInfo, init?: RequestInit): Promise<T> =>
  wrappedFetch(url, {
    ...init ? init : {},
    headers: {
      'content-type': 'application/json',
    },
  })
    // Check if response has OK status (2xx)
    .then<Response>(response => {
      if (fetchCallback) {
        fetchCallback(response);
      }
      return response.ok ? response : _throw(fetchException(response));
    })
    // Parse response accordingly to its content type
    .then(response =>
      chain(response.headers.get('content-type')).then(contentType =>
        contentType && contentType.indexOf('application/json') !== -1 ? response.json() : response.text(),
      ),
    );
