import { BUY_OWN_VOUCHER, BUY_GIFT_VOUCHER, BUY_GIFT_PACKAGE } from 'config/apiRoutes';
export const shopConfig: IShopConfig = {
  items: [
    {
      name: 'comparison',
      price: [
        { price: 199, limit: 1 },
        { price: 145, limit: 3 },
        { price: 135, limit: 5 },
        { price: 125, limit: 10 },
      ],
      buyLimit: 100,
      sendTo: BUY_OWN_VOUCHER,
    },
    {
      name: 'voucher',
      price: [
        { price: 199, limit: 1 },
        { price: 145, limit: 3 },
        { price: 135, limit: 5 },
        { price: 125, limit: 10 },
      ],
      buyLimit: 100,
      sendTo: BUY_GIFT_VOUCHER,
    },
    {
      name: 'gift',
      price: { price: 289, limit: -1 },
      buyLimit: 100,
      sendTo: BUY_GIFT_PACKAGE,
      usePhone: true,
      isGift: true,
    },
  ],
  postage: 89,
  delivery: 69,
};

export const GetShopItem = (name: 'comparison' | 'voucher' | 'gift') => shopConfig.items.find(x => x.name === name)!;
export const GetShopPrice = (name: 'comparison' | 'voucher' | 'gift', amount: number) => {
  const item = GetShopItem(name);
  if (Array.isArray(item.price)) {
    const prices = item.price;
    return (
      prices.find((x, i) => x.limit <= amount && prices[i + 1] && prices[i + 1].limit > amount) ||
      prices[prices.length - 1]
    ).price;
  } else {
    return item.price.price;
  }
};

export interface IShopConfig {
  items: ShopItem[];
  postage: number;
  delivery: number;
  item?: ShopItem;
}

export interface ShopItem {
  name: string;
  price: IPrice[] | IPrice;
  buyLimit?: number;
  sendTo: string;
  usePhone?: boolean;
  isGift?: boolean;
}

export interface IPrice {
  price: number;
  limit: number; // If less then 0 then count as infinity;
}
