import React from 'react';
import { Flex, Paragraph, Heading4, Grid, Icon, Img } from '@xcorejs/ui';
import { products } from './data';
import { StyledTag, Purple, ShopButton } from './styles';
import StarIcon from '../../components/icons/24/star.svg';
import { Link } from 'react-router-dom';
import { createUrl } from 'components/pages/routes';
import { FormattedMessage } from 'react-intl';

const ExtendShop = () => {
  const heading4Style = {
    fontSize: { _: '1.3rem', md: '2rem' },
    lineHeight: { _: '2rem', md: '2.6rem' },
  };  

  return ( 
    <Flex
      as='section'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      pt='0 !important'
    >
      <Grid columns={{ _: 'repeat(3, 1fr)' }} rows='auto' gap={{ _:'0', xxs: '1rem', md: '2rem' }} justifyContent='center'>
        {products.map((product, index) => (
          <Flex
            key={index}
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
            backgroundColor={product.backgroundColor}
            border={`1px solid ${product.color}`}
            padding={{ _:'1rem 0.3rem', md: '1.5rem 1rem' }}
            borderRadius='0.5rem'
          >
            <Flex
              position='relative'
              alignItems='center'
              justifyContent='center'
            >
              <Icon 
                svg={<StarIcon />}
                height='2rem'
                width='2rem'
                color={product.color}
              />
              <Heading4 {...heading4Style} ml='0.3rem'>Eticia Plus</Heading4>
            </Flex>

            <Heading4 {...heading4Style} ><strong>{product.length}</strong></Heading4>

            <StyledTag
              bg='transparent'
              border={`1px solid ${product.color}`}
              width='5.5rem'
              justifyContent='center'
              mt='1rem'
              padding='unset !important'
            >
              <Paragraph mr='0.4rem'>{product.ladders.slice(0, 3)}</Paragraph>
              <Img src='/img/ladder.svg' alt='' />
            </StyledTag>

            <Paragraph mt='0.5rem'>{product.price},- Kč</Paragraph>

            {product.prepare ? (
              <ShopButton
                borderColor={product.color}
                backgroundColor={Purple.background.dark}
                smallPadding
                smallerMargin
                blackHover
                disabled
              >
                <FormattedMessage id='eticiaPlus.extend.coming' />
              </ShopButton>
            ) : (
              <Link to={`${createUrl('app.shop')}/koupit/plus-${product.link}`}>
                <ShopButton
                  borderColor={product.color}
                  backgroundColor={Purple.background.dark}
                  smallPadding
                  smallerMargin
                  blackHover
                >
                  <FormattedMessage id='eticiaPlus.extend.buy' />
                </ShopButton>
              </Link>
            )}
          </Flex>
        ))}
      </Grid>
    </Flex> 
  );
};
 
export default ExtendShop;
