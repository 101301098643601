import * as React from 'react';
import { StyledEmptyCard } from './styles';

export interface IEmptyCard {
  index: string;
  text: React.ReactNode;
  requiredValuesCount?: number;
}

const EmptyCard: React.FC<IEmptyCard> = ({ index, text, requiredValuesCount }) => (
  <StyledEmptyCard className='def' requiredValuesCount={requiredValuesCount}>
    <div className='empty-card'>
      <span className='index'>{index}</span>
      <p>{text}</p>
    </div>
  </StyledEmptyCard>
);
export default EmptyCard;
