import { FC, useContext, useState } from 'react';
import Fade from 'react-reveal/Fade';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { StyledProfilePage } from 'components/pages/App/Profile/styles';
import * as React from 'react';
import useFetch from 'components/atoms/Fetch/useFetch';
import { INTEREST_GROUP_CATEGORIES } from 'config/apiRoutes';
import { Box, Heading3, Heading2, Img, Grid as XGrid, Typography, Button, Flex } from '@xcorejs/ui';
import { Grid } from 'react-styled-flexboxgrid';
import { _switch } from 'utils/option';
import { changeProfileGroups } from 'components/pages/App/Profile/Settings/actions';
import { HelpLink } from 'components/atoms/Popups/HelpLink';
import { createUrl } from 'components/pages/routes';
import { GameContext } from 'components/App';
import withUser, { IWithUser } from 'components/molecules/withRedux/withUser';

type GroupProps = {
  id: string;
  name: string;
  image: string;
};

type InterestGroupProps = {
  id: string;
  name: string;
  groups: GroupProps[];
};

const InterestsGroup: FC<IWithUser> = ({ user }) => {
  const result = useFetch<InterestGroupProps[]>(INTEREST_GROUP_CATEGORIES);
  const [pickedGroups, setPickedGroups] = useState<string[]>(user.groups || []);
  const { insideQuest } = useContext(GameContext);

  const changePickedGroups = (id: string) => {
    pickedGroups.includes(id) ? setPickedGroups([...pickedGroups.filter(e => e !== id)])
      : setPickedGroups([...pickedGroups, id]);
  };

  return (
    <StyledProfilePage>
      <Grid>
        <Fade bottom ssrReveal duration={1000} distance={'5rem'}>
          <div>
            <Heading2 textAlign='center' fontWeight={400} fontSize='3.2rem' lineHeight='3.9rem'>
              <FormattedMessage id='profile.interests.group' />
            </Heading2>

            <Typography mt='1.5rem'>
              <FormattedHTMLMessage id='profile.interests.subheading' />
            </Typography>

            <Flex alignItems='center' justifyContent='center' mt='1.5rem'>
              <HelpLink size={3} />

              <Typography fontSize='1.8rem' color='yellow' ml='1rem'>
                <FormattedMessage id='profile.interests.groups.help' />
              </Typography>
            </Flex>
          </div>

          <div>
            <Box>
              {result.result && result.result.map((c, i) => (
                <Box key={i} mt={i === 0 ? '8rem' : '9.6rem'}>
                  <Heading3 fontSize='2.4rem' lineHeight='2.9rem' fontWeight={400}>
                    {c.name && c.name}
                  </Heading3>

                  <Typography mt='1rem'>
                    <FormattedMessage id={`profile.interests.groups.subheading.${i}`} />
                  </Typography>

                  <XGrid
                    columns={{ _: 'repeat(3, minmax(0, 1fr))', sm: 'repeat(6, 1fr)' }}
                    rows='auto'
                    gap={{ _: '1.6rem', sm: '3.2rem' }}
                    mt='3.2rem'
                  >
                    {c.groups.map((group, i) => (
                      <Box
                        key={i}
                        border='2px solid'
                        borderColor={pickedGroups.includes(group.id) ? 'yellow' : 'rgba(255, 255, 255, 0.5)'}
                        _hover={{
                          borderColor: pickedGroups.includes(group.id) ? 'yellow' : '#fff'
                        }}
                        padding={{ _: '0.5rem', sm: '3.2rem' }}
                        onClick={() => changePickedGroups(group.id)}
                        transition='border 300ms'
                        bg={pickedGroups.includes(group.id) ? 'rgba(0, 0, 0, 0.1)' : 'transparent'}
                        cursor='pointer'
                      >
                        <Img src={group.image} alt={group.name} />
                        <Typography
                          mt='1rem'
                          fontSize={{ _: '1.4rem', sm: '1.5rem' }}
                          lineHeight={{ _: '1.6rem', sm: '2.2rem' }}
                        >
                          {group.name}
                        </Typography>
                      </Box>
                    ))}
                  </XGrid>
                </Box>
              ))}

              <Button
                mt='3rem'
                onClick={() =>
                  changeProfileGroups(pickedGroups,
                    () => console.log('success'),
                    () => console.log('fail')
                  ).then(
                    insideQuest ?
                      () => window.location.href = createUrl('app') + '/hra' :
                      () => window.location.href = createUrl('app')
                  )
                }
              >
                <FormattedMessage id='profile.interests.groups.save' />
              </Button>
            </Box>
          </div>

          <Typography mt='4.8rem' color='rgba(244,244,246,0.7)' maxWidth='85rem' mx='auto'>
            <FormattedMessage id='profile.interests.groups.note' />
          </Typography>
        </Fade>
      </Grid>
    </StyledProfilePage>
  );
};

export default withUser(InterestsGroup);
