import { Box, Flex, Heading1, Img, Paragraph, Typography } from '@xcorejs/ui';
import Navigation from 'components/atoms/HPNav';
import { IconJump } from 'components/atoms/Icons/IconJump';
import GameAnimationIntro from 'components/components/Animations/GameAnimationIntro';
import FiveItemCarousel, { ReducedCarouselProps } from 'components/components/Carousel/fiveItemCarousel';
import GameInfoCarousel from 'components/components/Carousel/GameInfoCarousel';
import Parallax from 'components/components/Parallax';
import { animation } from 'components/organisms/ValueGame/styles';
import ValueGamePresenting from 'components/organisms/ValueGame/ValueGamePresenting';
import { OliLight } from 'components/theme/fonts';
import { mediaMdDefault } from 'components/theme/grid';
import React, { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import StartPlay from '../../atoms/StartPlay/StartPlay';
import DownIcon from '../../components/icons/16/down.svg';
import { createUrl } from '../routes';
import { BoxWrapper, Darken, StyledBox } from './styles';

const data:ReducedCarouselProps[] = [
  {
    img: '240x228.png',
    imgActive: '240x228.png',
    heading: {
      cs: 'Hlavička 1',
      en: 'Heading 1',
    },
    boxText: {
      cs: 'Box Text 1',
      en: 'Box Text 1',
    },
    link: {
      text: {
        cs: 'Odkaz Text 1',
        en: 'Link Text 1',
      },
      href: 'https://example.com/link1',
    },
    isPlus: false,
  },
  {
    img: '240x228.png',
    imgActive: '240x228.png',
    heading: {
      cs: 'Hlavička 2',
      en: 'Heading 2',
    },
    boxText: {
      cs: 'Box Text 2',
      en: 'Box Text 2',
    },
    link: {
      text: {
        cs: 'Odkaz Text 2',
        en: 'Link Text 2',
      },
      href: 'https://example.com/link2',
    },
    isPlus: false,
  },
  {
    img: '240x228.png',
    imgActive: '240x228.png',
    heading: {
      cs: 'Hlavička 3',
      en: 'Heading 3',
    },
    boxText: {
      cs: 'Box Text 3<br />dva<br />tri',
      en: 'Box Text 3<br />two<br />three',
    },
    link: {
      text: {
        cs: 'Odkaz Text 3',
        en: 'Link Text 3',
      },
      href: 'https://example.com/link3',
    },
    isPlus: false,
  },
  {
    img: '240x228.png',
    imgActive: '240x228.png',
    heading: {
      cs: 'Hlavička 4',
      en: 'Heading 4',
    },
    boxText: {
      cs: 'Box Text 4',
      en: 'Box Text 4',
    },
    link: {
      text: {
        cs: 'Odkaz Text 4',
        en: 'Link Text 4',
      },
      href: 'https://example.com/link4',
    },
    isPlus: false,
  },
  {
    img: '240x228.png',
    imgActive: '240x228.png',
    heading: {
      cs: 'Hlavička 5',
      en: 'Heading 5',
    },
    boxText: {
      cs: 'Box Text 5',
      en: 'Box Text 5',
    },
    link: {
      text: {
        cs: 'Odkaz Text 5',
        en: 'Link Text 5',
      },
      href: 'https://example.com/link5',
    },
    isPlus: false,
  },
]; 

const ValueGame: FC = () => {
  const [animationStatus, setAnimationStatus] = useState<number>(0);
  const [iconJumpOpacity, setIconJumpOpacity] = useState<number>(0);
  const history = useHistory();

  const handleClick = () => {
    const iframe = window.top !== window.self;
    const iframeCondition = location.pathname.includes(createUrl('valueGame'));

    console.log('iframe', iframe);
    console.log('location.pathname.includes(\'/app/\'):', location.pathname.includes('/app'));

    window.sessionStorage.setItem('hasClickedStart', 'true');
    window.sessionStorage.setItem('hasPlayedSession', 'true');
    window.dispatchEvent(new Event('storage'));

    if (location.pathname.includes('/app') || iframe) {
      if (iframeCondition) {
        window.top.postMessage({ type :'closeRedirect_' + new URL(window.location.origin + createUrl('app.gameGame')) }, '*');
      }
    } else {
      history.push({
        pathname: createUrl('register'),
        state: { redirect: createUrl('app.gameGame') },
      });
    }
  };
  
  return (
    <>
      <Navigation />

      <Flex
        background='url(/img/hodnotova-hra-presenting/forest.jpg) no-repeat'
        backgroundSize='cover'
        flexDirection='column'
      >
        <BlurredBox position='relative'>
          {/* Mobile START */}
          <Box as='section' display={{ _: 'block', md: 'none' }}>
            <Box position='relative' minHeight='calc(100svh - 5rem)'>
              <Flex 
                justifyContent='center'
                alignItems='end'
                position='absolute'
                zIndex={1}          
                width='100%'
                height='100%'
                overflow='hidden'
              >
                <Img
                  width='100%'
                  src='/img/hodnotova-hra-presenting/Eticiagamegif_6.gif'
                  maxWidth='50rem'
                  alt='hodnotová hra'
                  objectFit='cover'
                  style={{ objectPosition: 'bottom' }}
                />
              </Flex>
                
              {animationStatus === 0 ? <GameAnimationIntro setStatus={setAnimationStatus} /> : (
                <BoxWrapper>
                  <Flex
                    paddingX={{ _: '2.5rem', md: '4rem' }}
                    paddingBottom={{ _: '6rem', md: '4rem' }}
                    flexDirection='column'
                    justifyContent='space-evenly'
                    alignItems='center'
                    position='relative'
                    zIndex={2}
                    animation='fadeInDarkness 500ms ease'
                    background='linear-gradient(180deg, rgba(0,0,0,0.7), rgba(0,0,0,0.2))'
                    mx='auto'
                    maxWidth={{ _: '50rem', md: '100%' }}
                    height='100%'
                  >
                    <Heading1
                      mt='4rem'
                      color='white'
                      fontSize='3rem'
                      opacity={0}
                      animation={animation(0)}>
                      <FormattedMessage id='valueGame.presenting.heading' />
                    </Heading1>

                    <Paragraph
                      mt='1rem' 
                      color='white'
                      fontSize='1.4rem'
                      fontFamily={OliLight}
                      opacity={0}
                      animation={animation(500)}>
                      <FormattedMessage id='valueGame.presenting.paragraph' />
                    </Paragraph>
          
                    <StyledBox
                      mt={{ _: '1rem', md: '6rem' }}
                      width='100%'
                      opacity={0}
                      animation={animation(1000)}>
                      <FiveItemCarousel incData={data} arrowColor='white' />
                    </StyledBox>

                    <StartPlay
                      top='-35px' 
                      opacity={0}
                      animation={animation(1500)}
                      onAnimationEnd={() => setIconJumpOpacity(1)}
                      onClick={handleClick}
                    />
                  </Flex>
                
                  <Flex
                    justifyContent='center'
                    width='100%'
                    height='4rem'
                    position='relative'
                    bottom='5rem'
                    opacity={iconJumpOpacity}
                    transition='opacity 500ms'
                  >
                    <IconJump 
                      svg={<DownIcon />}
                      duration='3s'
                      scale={1.2}
                      pos='absolute'
                      zIndex={2}
                      overflow='visible'
                      cursor='pointer'
                      onClick={() => window.scrollBy({ top: document.body.scrollHeight - window.innerHeight, behavior: 'smooth' })}
                    />
                  </Flex>
                </BoxWrapper>
              )}
            </Box>

            <Box
              backgroundColor='#1d1c21'
              margin='auto'
              width='100%'
              maxWidth='50rem'
              paddingTop={{ _: '2rem' }} 
            >
              <GameInfoCarousel onlyInnerArrow />

              <Typography fontSize={{ _: '12px' }} paddingBottom={{ _: '2rem' }} color='#888888'>
          &copy; 2017 - {new Date().getFullYear()} Eticia 21
              </Typography>
            </Box>
          </Box>
          {/* Mobile END */}

          {/* Desktop START */}
          <Box as='section' display={{ _: 'none', md: 'block' }}>
            <ValueGamePresenting smaller afterRegistration />
            <Darken direction='bottom' darker />
          </Box>
          {/* Desktop END */}
        </BlurredBox>
      </Flex> 
          
      {/* Desktop START */}
      <Box as='section' display={{ _:'none', md: 'block' }}>
        <Parallax 
          backgroundImage='/img/homepage/HRA_ETICIA_hp.jpg' 
          maxWidth='100%' 
          fitToScreen 
          noPadding 
          justifyContent='start'
        >
          <Darken direction='top' />
          <Flex 
            flexDirection='column' 
            alignItems='center'
            maxWidth='60rem'
          >
            <Heading1>
              <FormattedMessage id='valueGame.presenting.heading' />
            </Heading1>

            <Paragraph mt='2rem' maxWidth='30rem'>
              <FormattedMessage id='valueGame.presenting.paragraph' />
            </Paragraph>
            <StyledBox mt={{ _: '2rem', md: '6rem' }} width='100%' className='carousel-wrapper-box'>
              <FiveItemCarousel incData={data} arrowColor='white' />
            </StyledBox>

            {/* <IconJump
              svg={<DownIcon />}
              pos='relative'
              duration='3s'
              bottom='-14rem'
              scale={1.5}
              cursor='pointer'
              onClick={() => window.scrollBy({ top: document.body.scrollHeight - window.innerHeight, behavior: 'smooth' })}
            /> */}
          </Flex>
        </Parallax>
        <Box backgroundColor='rgb(0,0,0)' height='2px' />
      </Box>
      {/* Desktop END */}            
    </>
  );
};
 
export default ValueGame;

export const BlurredBox = styled(Box)`
  backdrop-filter: none;

  ${mediaMdDefault} {
    backdrop-filter: blur(4px);
  }
`;
