import { useEffect, useState } from 'react';
import { IUserInfo } from 'store/User';
import { useFetchUserProfileDetail } from 'utils/useQuery/queryFetchHelpers';

export const useUser = () => {
  const [user, setUser] = useState<IUserInfo | null>(null);

  const userQuery = useFetchUserProfileDetail();

  useEffect(() => {
    if (userQuery.isSuccess) {
      setUser(userQuery.data);
    }
  }, [userQuery.data, userQuery.isSuccess]);

  return user;
};