import styled from 'styled-components';

export const StyledCardPlaceholder = styled.div`
  position: relative;

  .card {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% + 1px);
    height: 100%;
  }
`;
