import React, { FC } from 'react';
import { Link as XcoreLink } from '@xcorejs/ui';

interface LinkProps {
  string: string;
  textSize?: {
    paragraph: { _: string; md: string };
    heading: { _: string; md: string };
  };
}

const Link: FC<LinkProps> = ({ 
  string, 
  textSize = fontSize
}) => {
  const createLink = (string: string | null) => {
    let text = 'není připojeno';
    let link = '';

    if (string) {
      const [cText, cLink] = string.split(';');
      text = cText;
      link = cLink || cText;
    }
    return <XcoreLink v='underline' href={link} target='_blank' rel='noopener noreferrer'mt='1rem' mb='0.1rem' fontSize={textSize.paragraph}>{text}</XcoreLink>;
  };

  return createLink(string);
};
 
export default Link;

export const fontSize =  {
  paragraph: { _: '1.4rem', md: '1.6rem' },
  heading: { _: '1.8rem', md: '2rem' }
};