import styled from 'styled-components';

import { Box, Flex } from '@xcorejs/ui';
import { mediaMdDefault } from 'components/theme/grid';

const BASE = '& > div > div';
const TEXT_DIV = BASE + ' > div:nth-child(2) > div';
const HEADING = 'p > div';
const PARAGRAPH = 'div > div:first-child > div > p';
const ARROWS = 'div > div:last-child > div';
const PLUS_TAG = '#plus-tag-wrapper div';

export const StyledBox = styled(Box)`
  ${TEXT_DIV} {
    & > ${HEADING} {
      color: #fff;

      ${mediaMdDefault} {
        margin-top: 4rem;
      }
    }

    & > ${PARAGRAPH} {
      height: 6rem;

      div {
      color: #fff;
      }
    }

    ${mediaMdDefault} {
      margin-top: 2rem;
    }
  }

  & > ${ARROWS} {
    margin-top: 2rem;

    ${mediaMdDefault} {
      margin-top: 0;
    }
  }

`;

type DirectionProps = 'bottom' | 'top' | 'left' | 'right';

interface FadeInFlexProps {
  duration?: number;
  distance?: string;
  delay?: number;
  direction?: DirectionProps;
}

export const FadeInFlex = styled(Flex)<FadeInFlexProps>`
  animation: fadeIn ${({ duration }) => duration || 1000}ms ease-in-out ${({ delay }) => delay || 0}ms forwards;
  opacity: 0;
  transform: ${({ direction, distance }) => {
    switch (direction) {
    case 'bottom':
      return `translateY(${distance || '10px'})`;
    case 'top':
      return `translateY(-${distance || '10px'})`;
    case 'left':
      return `translateX(-${distance || '10px'})`;
    case 'right':
      return `translateX(${distance || '10px'})`;
    default:
      return `translateY(${distance || '10px'})`;
    }
  }};
  will-change: opacity, transform;
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: ${({ direction, distance }) => {
    switch (direction) {
    case 'bottom':
      return `translateY(${distance || '10px'})`;
    case 'top':
      return `translateY(-${distance || '10px'})`;
    case 'left':
      return `translateX(-${distance || '10px'})`;
    case 'right':
      return `translateX(${distance || '10px'})`;
    default:
      return `translateY(${distance || '10px'})`;
    }
  }};

    }
    100% {
      opacity: 1;
      transform: translate(0);
    }
  }
`;


interface DarkenProps {
  direction?: 'top' | 'bottom';
  darker?: boolean;
}

export const Darken = styled.div<DarkenProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${({ direction, darker }) => direction === 'top' ?
    darker ? 'background: linear-gradient(180deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.4) 80%, rgba(0,0,0,1) 100%);' :
      'background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 95%, rgba(0,0,0,1) 100%);' :
    direction === 'bottom' ?
      darker ? 'background: linear-gradient(0deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.4) 80%, rgba(0,0,0,1) 100%);' :
        'background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 95%, rgba(0,0,0,1) 100%);' :
      'unset;'}
`;

export const BoxWrapper = styled(Box)`
  height: 100%;
  
  @-webkit-keyframes fadeInGameIntro {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeInGameIntro {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeInDarkness {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeInDarkness {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;