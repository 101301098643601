import * as React from 'react';

const SVGlevelTag: React.FC<{ color?: string }> = ({ color }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='52' viewBox='0 0 24 52'>
      <polygon fill={color} fillRule='evenodd' points='0 0 24 0 24 52 12 46 0 52' />
    </svg>
  );
};

export default SVGlevelTag;
