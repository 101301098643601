import { JOURNAL } from 'config/apiRoutes';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import Fade from 'react-reveal/Fade';
import { RouteComponentProps, withRouter } from 'react-router';
import { Col, Grid, Row } from 'react-styled-flexboxgrid';
import { _switch } from 'utils/option';

import LoadingSpinner from '../../../../atoms/Animations/LoadingSpinner';
import { PrimaryButtonLink } from '../../../../atoms/Buttons';
import { StyledLinkBorder } from '../../../../atoms/Buttons/styles';
import Fetch from '../../../../atoms/Fetch';
import { Heading1 } from '../../../../atoms/Headings';
import SVGpayBank from '../../../../atoms/SVGIcons/SVGpayBank';
import SVGpayCard from '../../../../atoms/SVGIcons/SVGpayCard';
import SVGpayMobile from '../../../../atoms/SVGIcons/SVGpayMobile';
import { CustomText } from '../../../../atoms/Text';
import { createUrl } from '../../../routes';
import ComparisonTable from './ComparisonTable';
import { FlexColumn, PaymentMethod, StyledValueComparisonSection } from './styles';
import { Box } from '@xcorejs/ui';
import { IJournal } from '../../TestIntro/data';

class Comparisons extends React.Component<RouteComponentProps<{}>> {
  public render() {
    return (
      <Fetch url={JOURNAL}>
        {_switch({
          success: (journal: IJournal) => {
            const hasOwn = journal.trees.find(x => x.amIOwner) !== undefined;
            const hasGift = journal.trees.find(x => !x.amIOwner) !== undefined;
            return (
              <StyledValueComparisonSection>
                <FlexColumn>
                  <Fade bottom ssrReveal duration={1000} distance={'5rem'}>
                    <Grid>
                      <Row>
                        {hasOwn || hasGift ? (
                          <Col xs={12}>
                            {hasOwn && (
                              <>
                                <Heading1>
                                  <FormattedMessage id={'mycomparison.heading.own'} />
                                </Heading1>

                                <ComparisonTable items={journal.trees.filter(x => x.amIOwner)} />
                              </>
                            )}

                            <Box mt='4rem'>
                              <Heading1>
                                <FormattedMessage id={'mycomparison.heading.gift'} />
                              </Heading1>
                            </Box>

                            {hasGift ? (
                              <ComparisonTable items={journal.trees.filter(x => !x.amIOwner)} />
                            ) : (
                              <Box mt='3rem' opacity={0.7}>
                                <FormattedMessage id={'mycomparison.no.invites'} />
                              </Box>
                            )}
                          </Col>
                        ) : (
                          <Col xs={12} md={6} mdOffset={3}>
                            <Heading1>
                              <FormattedMessage id={'mycomparison.no.heading'} />
                            </Heading1>

                            <CustomText>
                              <FormattedMessage id={'voucher.no.text'} />
                            </CustomText>

                            <PrimaryButtonLink to={createUrl('app.shop')}>
                              <FormattedMessage id={'voucher.buy'} />
                            </PrimaryButtonLink>

                            <PaymentMethod>
                              <FormattedMessage id={'voucher.methods'} />
                              <SVGpayCard />
                              <SVGpayBank />
                              <SVGpayMobile />
                            </PaymentMethod>

                            <Box mt='6rem'>
                              <CustomText>
                                <strong>
                                  <FormattedMessage id={'voucher.insert'} />
                                </strong>

                                <Box mt='2rem' mb='3rem'>
                                  <StyledLinkBorder
                                    to={createUrl('app.comparison') + '/darkovy-balicek/pridat'}
                                    style={{ fontSize: 'inherit' }}
                                    color='yellow'
                                  >
                                    <FormattedMessage id={'voucher.insertCode'} />
                                  </StyledLinkBorder>
                                </Box>
                              </CustomText>
                            </Box>

                            <div className={'lighter'}>
                              <CustomText>
                                <FormattedMessage id={'voucher.no.text.2'} />
                              </CustomText>
                            </div>
                          </Col>
                        )}
                      </Row>
                    </Grid>
                  </Fade>
                </FlexColumn>
              </StyledValueComparisonSection>
            );
          },
          fetching: () => <LoadingSpinner />,
        })()}
      </Fetch>
    );
  }
}

export default withRouter(Comparisons);
