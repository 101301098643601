import React, { FC, useState, useEffect } from 'react';
import { MovingRectangle } from 'components/atoms/Animations/MovingRectangle';
import { Heading3, Flex, } from '@xcorejs/ui';
import { OliRegular } from 'components/theme/fonts';
import styled from 'styled-components';

const rectangles = [
  {
    backgroundImageUrl: '/img/gif/most-start_200_64.gif',
    text: 'Velká hra právě začíná',
  },
  {
    backgroundImageUrl: '/img/gif/170_4barvy.gif',
    text: 'Bude to dlouhá cesta',
  },
  {
    backgroundImageUrl: '/img/gif/most-start_200_64.gif',
    text: 'Plná sebeobjevování',
  },
  {
    backgroundImageUrl: '/img/gif/170_4barvy.gif',
    text: 'Za chvíli můžeš hrát',
  },
  // {
  //   backgroundImageUrl: '/img/gif/most-start_200_64.gif',
  //   text: 'Pátý text',
  // },
];

interface RectanglesProps {
    setStatus: React.Dispatch<React.SetStateAction<number>>;
}

const Rectangles: FC<RectanglesProps> = ({ setStatus }) => {
  const DELAY = 2.5;
  
  const [animationStatus, setAnimationStatus] = useState<number>(0);
  const [headingText, setHeadingText] = useState<string>(rectangles[0].text);

  useEffect(() => {
    if (animationStatus === rectangles.length) {
      setStatus(prev => prev + 1);
    }
  }, [animationStatus, setStatus]);

  useEffect(() => {
    if (animationStatus >= 0 && animationStatus < rectangles.length) {
      setHeadingText(rectangles[animationStatus].text);
    }
  }, [animationStatus]);

  return (
    <>
      <Flex
        position='absolute'
        bottom='60%'
        flexDirection='column'
        justifyContent='end'
        alignItems='center'
      >
        <Heading3Styled
          color='#333'
          fontFamily={OliRegular}
          mt='1rem'
          duration={DELAY}
          fontSize='2.5rem'
          lineHeight='3rem'
          maxWidth='70%'
        >
          {headingText}
        </Heading3Styled>
      </Flex>

      {rectangles.map((rectangle, index) => (
        <MovingRectangle
          key={index}
          backgroundImageUrl={animationStatus >= index ? rectangle.backgroundImageUrl : ''}
          delay={DELAY * index}
          onAnimationEnd={() => setAnimationStatus(prev => prev + 1)}
          bottom='14rem'
        />
      ))}
    </>
  );
};


export default Rectangles;

const Heading3Styled = styled(Heading3)<{duration: number}>`
  text-align: center;
  animation: pulse-text ${({ duration }) => duration}s linear infinite;
  -webkit-animation: pulse-text ${({ duration }) => duration}s linear infinite;

  @keyframes pulse-text {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    70% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @-webkit-keyframes pulse-text {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    70% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;