import { Heading2 } from 'components/atoms/Headings';
import { TEST_CAN_RUN, TEST_GET_CONFIG } from 'config/apiRoutes';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import Fade from 'react-reveal/Fade';
import { RouteComponentProps, withRouter } from 'react-router';
import { Col, Grid, Row } from 'react-styled-flexboxgrid';
import { _switch } from 'utils/option';
import { isReadable } from 'utils/rescource';

import { isAuthed, IUserState } from '../../../../../store/User';
import LoadingSpinner from '../../../../atoms/Animations/LoadingSpinner';
import { PrimaryButtonLink } from '../../../../atoms/Buttons';
import Fetch from '../../../../atoms/Fetch';
import withUser, { IWithUser } from '../../../../molecules/withRedux/withUser';
import { createUrl } from '../../../routes';
import { StyledValueComparisonSection } from '../Comparisons/styles';
import { createTestFromConfig, ITestConfig } from './data';
import Intro from './Intro';

class StartTest extends React.Component<RouteComponentProps<{ id: string }> & IWithUser> {
  public componentDidMount() {
    if (!isAuthed(this.props.rescource.user as IUserState)) {
      this.props.history.push(createUrl('register'), {
        redirect: createUrl('acceptInvite', { id: this.props.match.params.id }),
      });
    }
  }

  public render() {
    if (!isReadable(this.props.rescource.user)) {
      return <LoadingSpinner />;
    }
    return (
      <Fetch url={TEST_CAN_RUN(this.props.match.params.id)}>
        {_switch({
          success: () => (
            <Fetch url={TEST_GET_CONFIG(this.props.match.params.id)}>
              {_switch({
                success: (result: ITestConfig) => (
                  <Intro
                    config={result}
                    submit={(id: string | null) =>
                      createTestFromConfig(this.props.match.params.id, id).then(response =>
                        this.props.history.push(
                          createUrl('app.partnerTest', {
                            id: response.id,
                          }),
                        ),
                      )
                    }
                    back={undefined}
                  />
                ),
                fetching: () => <LoadingSpinner />,
              })()}
            </Fetch>
          ),
          fail: (error: { errorCode: string }) => (
            <StyledValueComparisonSection>
              <Grid>
                <Row>
                  <Col xs={12}>
                    <Fade bottom ssrReveal duration={1000} distance={'5rem'}>
                      <Heading2>
                        {parseInt(error.errorCode, 10) === 1002 ? (
                          <FormattedMessage id='gamesForTwo.intro.error.sameUser' />
                        ) : (
                          <>
                            {parseInt(error.errorCode, 10) === 1001 ? (
                              <FormattedMessage id='gamesForTwo.intro.error.used' />
                            ) : (
                              <FormattedMessage id='gamesForTwo.intro.error.default' />
                            )}
                          </>
                        )}
                      </Heading2>

                      <PrimaryButtonLink to={createUrl('app.comparison') + '/moje-porovnani'}>
                        <FormattedMessage id='gamesForTwo.intro.error.button' />
                      </PrimaryButtonLink>
                    </Fade>
                  </Col>
                </Row>
              </Grid>
            </StyledValueComparisonSection>
          ),
        })()}
      </Fetch>
    );
  }
}

export default withUser<{}>(withRouter(StartTest), false);
