import { Box, Flex, Icon, Img, Link as UILink, Paragraph, Stack, Tag, Typography, useModal } from '@xcorejs/ui';
import { RequestContext } from 'components/atoms/RequestContext';
import TribalTime from 'components/atoms/TribalTime';
import LaddersCompareModal from 'components/components/modals/LaddersCompareModal/LaddersCompareModal';
import CommentModal from 'components/pages/App/Journal/Journal/CommentModal';
import ValueRatingModal from 'components/pages/App/Journal/Journal/ValueRatingModal';
import { ITestResultPreviewResponse } from 'components/pages/App/TestIntro/data';
import { IValueTestResult } from 'config/api/valueTestResult';
import * as React from 'react';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import wrapIntl from '../../../../atoms/wrapIntl';
import { CompareIcon } from '../../../../components/icons/16/compare.svg';
import { NoteIcon } from '../../../../components/icons/16/note_new.svg';
import { SemaforIcon } from '../../../../components/icons/16/semafor.svg';
import { createUrl } from '../../../routes';
import { useFetchUser } from '../../ValueCenter/Center/useFetchUser';
import PDFButton from './PDFButton';
import { TableStyle } from './styles';

interface IValueTable {
  main: boolean;
  results: ITestResultPreviewResponse[];
  onSelect?: (result: IValueTestResult[]) => unknown;
  onReload?: () => unknown;
  tribalTime?: boolean;
}

const ValueTestResultTable: FC<IValueTable> = ({
  main,
  results,
  onReload,
  tribalTime
}) => {
  const [openValueRating] = useModal(ValueRatingModal);
  const [openComment] = useModal(CommentModal);
  const [openLaddersCompareModal] = useModal(LaddersCompareModal);
  const [close] = useModal();

  const user = useFetchUser();

  return (
    <TableStyle>
      <tbody>
        <>
          {results.map(result => (
            <tr className={main ? 'active' : ''} key={result.id}>
              <td>
                <Flex flexDirection='row' justifyContent={{ _: 'center', sm: 'flex-start' }}>
                  <Box
                    display='inline-block'
                    mt='0.2rem'
                  >
                    <RequestContext>
                      {({ locale }) => (
                        <Paragraph
                          color='rgba(244, 244, 246, 0.8)'
                          textAlign={{ _: 'center', sm: 'left' }}
                        >
                          {new Date(result.doneAt * 1000).toLocaleDateString(
                            locale,
                            {
                              year: 'numeric',
                              month: '2-digit',
                              day: '2-digit'
                            }
                          )}
                        </Paragraph>
                      )}
                    </RequestContext>
                  </Box>

                  {main && (
                    <Flex
                      display={{ _: 'flex', sm: 'inline-flex' }}
                      justifyContent={{ _: 'center', sm: 'flex-start' }}
                      alignItems='center'
                      ml='3rem'
                    >
                      <Typography fontFamily='OliMedium' color='#fff'>
                        {result.eticonIcon && (
                          <Img src={result.eticonIcon} alt='' width='3rem' height='3rem' mr='2rem' filter='grayscale(1)' />
                        )}
                        {result.eticonName}
                      </Typography>
                    </Flex>
                  )}
                </Flex>
              </td>

              <td>
                <Box textAlign='center'>
                  <Tag
                    display='block'
                    mx='auto'
                    bg={main ? tribalTime ? '#3e8f74' : 'rgba(11,12,28,0.2)' : 'transparent'}
                    border={main ? tribalTime ? '2px solid #3e8f74' : '2px solid rgba(11, 12, 28, 0.2)' : '2px solid rgba(231,230,207,0.5)'}
                    py='0.4rem'
                    width='24rem'
                    justifyContent='center'
                    whiteSpace='nowrap'
                    overflow='hidden'
                    textOverflow='ellipsis'
                  >
                    {result.name}
                  </Tag>
                </Box>

                {tribalTime && (
                  <Box mt='2rem'>
                    <TribalTime doneAt={result.doneAt} />
                  </Box>
                )}
              </td>

              <td>
                <Flex
                  flexDirection={{ _: 'column', sm: 'row' }}
                  justifyContent='flex-end'
                  alignItems='center'
                >
                  <Stack
                    gap='1rem'
                    alignItems='center'
                  >
                    <Icon
                      {...{ as: 'div' }}
                      display='flex'
                      flex='0 0 auto'
                      alignItems='center'
                      justifyContent='center'
                      svg={<NoteIcon />}
                      width='3rem'
                      height='3rem'
                      borderRadius='50%'
                      mr='1rem'
                      fill={result.valueTestNote ? result.valueTestNote.trim() && 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.4)'}
                      p='0.3rem'
                      cursor='pointer'
                      onClick={() =>
                        openComment({
                          valueTestResultId: result.valueTestResultId ?? '',
                          defaultText: result.valueTestNote ?? '',
                          onClose: close,
                          onReload: onReload,
                          doneAt: result.doneAt,
                          eticonName: result.eticonName ?? ''
                        })
                      }
                    />

                    {result.semaphoreVisible && (
                      <Icon
                        {...{ as: 'div' }}
                        display='flex'
                        flex='0 0 auto'
                        alignItems='center'
                        justifyContent='center'
                        svg={<SemaforIcon />}
                        width='3rem'
                        height='3rem'
                        bg='rgba(11, 12, 28, 0.5)'
                        borderRadius='50%'
                        mr='1rem'
                        fill='#fefefe'
                        p='0.3rem'
                        cursor='pointer'
                        onClick={() =>
                          openValueRating({
                            id: result.id,
                            onAction: close,
                          })
                        }
                      />
                    )}

                    <Icon
                      {...{ as: 'div' }}
                      display='flex'
                      flex='0 0 auto'
                      alignItems='center'
                      justifyContent='center'
                      svg={<CompareIcon />}
                      width='3rem'
                      height='3rem'
                      bg='rgb(137,71,105)'
                      borderRadius='50%'
                      mr='1rem'
                      fill='#fefefe'
                      p='0.3rem'
                      cursor='pointer'
                      onClick={() => openLaddersCompareModal({ result })}
                    />

                    {user && main && (
                      <PDFButton resultId={result.id} user={user} />
                    )}
                  </Stack>

                  <Link
                    to={{
                      pathname: createUrl('app.testResult', {
                        id: result.id
                      }),
                      state: {
                        doNotShowFinal: true
                      }
                    }}
                  >
                    <UILink
                      as='span'
                      v='underline'
                      ml={{ _: 0, sm: '2rem', lg: '3rem' }}
                      mt={{ _: '2rem', sm: 0 }}
                    >
                      <FormattedMessage id={'profile.results.detail'} />
                    </UILink>
                  </Link>
                </Flex>
              </td>
            </tr>
          ))}
        </>
      </tbody>
    </TableStyle>
  );
};

export default wrapIntl(ValueTestResultTable);

