import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { mediaMdDefault } from 'components/theme/grid';
import { PropObjectSizing, setStyleValue } from 'utils/setStyleValue';


interface ParallaxProps {
  children: ReactNode[] | ReactNode;
  backgroundImage: string;
  maxWidth?: string | PropObjectSizing;
  fitToScreen?: boolean;
  noPadding?: boolean;
  justifyContent?: 'start' | 'center';
}

const Parallax: FC<ParallaxProps> = ({ 
  backgroundImage, 
  children,
  maxWidth, 
  fitToScreen, 
  noPadding, 
  justifyContent 
}) => {
  return ( 
    <ParallaxContainer fitToScreen={fitToScreen}>
      <BackgroundImage backgroundImage={backgroundImage} />
      <ContentContainer maxWidth={maxWidth} noPadding={noPadding} justifyContent={justifyContent}>
        {children}
      </ContentContainer>
    </ParallaxContainer>
  );
};
 
export default Parallax;

const ParallaxContainer = styled.div<Pick<ParallaxProps, 'fitToScreen'>>`
  position: relative;
  height: fit-content;
  overflow: hidden;
  background-color: #000;
  height: ${({ fitToScreen }) => fitToScreen ? '100vh' : 'auto' };
`;

const BackgroundImage = styled.div<Pick<ParallaxProps, 'backgroundImage'>>`
  position: absolute;
  top: 0;
  left: 0;
  width: 105%;
  height: 105%;
  background-image: url('${props => props.backgroundImage}');
  background-color: #000;
  background-size: cover;
  background-position: center;
  background-attachment: scroll;
  background-repeat: no-repeat;
  opacity: 0.75;

  ${mediaMdDefault} {
    background-attachment: fixed;
  }
`;

const ContentContainer = styled.div<Pick<ParallaxProps, 'maxWidth' | 'noPadding' | 'justifyContent'>>`
  position: relative;
  z-index: 1;
  padding-bottom: ${({ noPadding }) => noPadding ? '0' : '6rem' };
  padding-top: ${({ noPadding, justifyContent }) => justifyContent === 'start' ? '10rem' : noPadding ? '0' : '6rem' };
  padding-left: 3.6rem;
  padding-right: 3.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent ? justifyContent : 'center' };
  height: 100%;
  max-width: ${({ maxWidth }) => setStyleValue(maxWidth, '50rem', '_') };
  margin: auto;

  ${mediaMdDefault} {
    max-width: ${({ maxWidth }) => setStyleValue(maxWidth, '40rem', 'md')};
  }
`;


