import React from 'react';
import { DefaultSection10 as data } from 'components/pages/Presenting/data';
import { Flex, Container, Heading2, Typography, Img, Grid } from '@xcorejs/ui';
import { GradientStyle } from 'components/pages/Presenting/pages';
import { PremiumButton } from 'components/components/Button';
import Fade from 'react-reveal/Fade';

const Find = () => {
  return ( 
    <Flex
      as='section'
      py='7rem'
      background={`url(/img/presenting/${data.backgroundImage}), ${data.backgroundColor}`}
      backgroundPosition='center'
      backgroundSize='cover'
      position='relative'
      flexDirection='column'
    >
      {data.gradient && (
        <GradientStyle
          color1={data.gradient.color1}
          color2={data.gradient.color2}
          color3={data.gradient.color3}
          color4={data.gradient.color4}
          speed={data.gradient.speed}
        />
      )}
      <Container
        flexDirection='column'
        alignItems='center'
        maxWidth='85rem'
        width='85%'
        position='relative'
        zIndex={1}
      >
        <Flex justifyContent='center' >
          <PremiumButton px='3rem' />
        </Flex>
  
        <Heading2 {...Heading2Style} mt='3rem'>
          <div dangerouslySetInnerHTML={{ __html: data.heading }} />
        </Heading2>
  
        <Typography {...TypographyStyle} mt='1.5rem'>
          <div dangerouslySetInnerHTML={{ __html: data.text.slice(0, data.text.indexOf('Navíc')) }} />
        </Typography>
  
        <Typography
          mt={{ _: '4rem', sm: '6rem' }}
          fontSize={{ _: '1.8rem', sm: '2.4rem' }}
          lineHeight={{ _: '2.1rem', sm: '2.9rem' }}
        >
          <div dangerouslySetInnerHTML={{ __html: data.plusHeading }} />
        </Typography>
      </Container>
  
      <Container position='relative' justifyContent='center' zIndex={1}>
        <Grid
          columns={{ _: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)', lg: 'repeat(6, 1fr)' }}
          rows={{ _: 'auto' }}
          justifyContent='center'>
          {data.list.map((i, k) => (
            <Flex flexDirection='column' key={k} alignItems='center' mx='1rem' width={{ _: '14rem', sm: '16rem' }} mt='4rem'>
              <Fade delay={600*k} key={k}>
                <Flex
                  position='relative'
                  width={{ _: '10rem', sm: '12rem' }}
                  height={{ _: '10rem', sm: '12rem' }}
                  display='flex'
                  mx='auto'
                  alignItems='center'
                  justifyContent='center'
                  borderRadius='50%'
                  border='2px solid rgba(255, 255, 255, 0.4)'
                  backgroundColor='rgba(11, 12, 28, 0.1)'
                  overflow='hidden'
                >
                  <Img
                    src={`/img/presenting/${i[1]}`}
                    width={{ _: '6rem', sm: '8rem' } as any}
                    height={{ _: '6rem', sm: '8rem' } as any}
                    alt={i[0]}
                  />
  
                  <GradientStyle
                    color1='rgba(255, 255, 255, 0.05)'
                    color2='rgba(255, 255, 255, 0.25)'
                    color3='rgba(255, 255, 255, 0.25)'
                    color4='rgba(255, 255, 255, 0.05)'
                    speed='8s'
                    rotation={10*k + 'deg'}
                    delay={1000*k + 'ms'}
                  />
                </Flex>
              </Fade>
  
              <Fade delay={600*k + 150}>
                <Typography {...TypographyStyle} fontSize='1.5rem' lineHeight='1.8rem' mt='2rem'>
                  <div dangerouslySetInnerHTML={{ __html: i[0] }} />
                </Typography>
              </Fade>
            </Flex>
          ))}
        </Grid>
      </Container>
      <Container
        flexDirection='column'
        alignItems='center'
        maxWidth='85rem'
        position='relative'
        zIndex={1}
      >
        <Typography {...TypographyStyle} mt='4rem'>
          <div dangerouslySetInnerHTML={{ __html: data.plusOther }} />
        </Typography>
      </Container>
    </Flex>
  );
};
 
export default Find;

const TypographyStyle = {
  fontSize: { _: '1.5rem', sm: '1.8rem' },
  lineHeight: { _: '2.1rem', sm: '2.7rem' }
};

const Heading2Style = {
  mt: 0,
  fontSize: { _: '2.2rem', sm: '3.2rem' },
  letterSpacing: 0,
  lineHeight: { _: '2.6rem', sm: '4.1rem' },
  fontWeight: 400
};