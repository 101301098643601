import { Box, Flex, Img, Link as UILink, Typography, useModal } from '@xcorejs/ui';
import { GameContext } from 'components/App';
import LoadingSpinnerLocal from 'components/atoms/Animations/LoadingSpinnerLocal';
import { PlusTag } from 'components/atoms/Logo';
import { PrimaryButton } from 'components/components/Button';
import LifeValuesModal from 'components/components/modals/LifeValuesModal';
import ValueMap from 'components/molecules/ValueMap';
import ValueMapFull from 'components/molecules/ValueMap/types/ValueMapFull';
import { IWithLifeValues } from 'components/molecules/withRedux/withLifeValues';
import { AllValuesModal } from 'components/pages/App/Result/PartnerTestResult/DefaultPages/DefaultValues';
import { IJournal, ONE_ROUND_TEST_ID } from 'components/pages/App/TestIntro/data';
import { createUrl } from 'components/pages/routes';
import React, { FC, Fragment, useContext } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { useFetchGameStatus } from 'utils/useQuery/queryFetchHelpers';

interface ValueMapBoxProps extends Pick<IWithLifeValues, 'lifeValues'>{
  journal: IJournal;
  remainingDayTimeValue: number;
}

const ValueMapBox: FC<ValueMapBoxProps> = ({
  journal,
  remainingDayTimeValue,
  lifeValues,

}) => {
  const { eticiaPlus } = useContext(GameContext);
  const gameStatusFetch = useFetchGameStatus();

  const [openLifeValuesModal] = useModal(LifeValuesModal);
  const [openAllValueModal] = useModal(AllValuesModal);

  if (gameStatusFetch.isLoading)
    return <LoadingSpinnerLocal />;
  
  if (gameStatusFetch.isError) {
    console.log(gameStatusFetch.error);
    return <></>;
  }

  const detail = gameStatusFetch.data!;
  
  return ( 
    <Box position='relative'>
      {detail.tribalTestResult ? (
        <Fragment>
          {journal.myLifeValues 
            ? (
              <>
                <ValueMap theme='afterYear' imagePath=''>
                  <Box>
                    <Img src='/img/ziv_cloud.png' mx='auto' alt='' />

                    <Typography mt='2rem'>
                      <FormattedHTMLMessage id='valueCenter.strip2.heading.lifeValues' />
                      {journal.myLifeValues.yearFrom} – {journal.myLifeValues.yearTo + ')'} 
                    </Typography>

                    <ValueMapFull
                      theme='normal'
                      lifeValues={journal.myLifeValues.lifeValues}
                    />
                  </Box>
                </ValueMap>
                <Flex flexDirection='column' alignItems='Center' mt='5rem'>
                  <UILink v='underline' onClick={openLifeValuesModal} mb='1rem'>
                    <FormattedMessage id={`valueCenter.strip1.detail${eticiaPlus ? 'Plus' : 'Free'}`} />
                  </UILink>
                  {!eticiaPlus && (
                    <PlusTag
                      mt={{ _: '1rem', sm: 0 }}
                      ml={{ _: 0, sm: '1rem' }}
                    >
                            Eticia plus
                    </PlusTag>
                  )}
                </Flex>
              </>
            ) :  remainingDayTimeValue >= 0 
              ? (
                <>
                  <ValueMap theme='normal' imagePath=''>
                    <ValueMapFull theme='normal' lifeValues={detail.tribalTestResult.allLifeValues} eticon={detail.tribalTestResult.eticon} />
                  </ValueMap>
                  <Flex
                    flexDirection={{ _: 'column', sm: 'row' }}
                    alignItems='center'
                    justifyContent='center'
                    mt='5rem'
                  >
                    <UILink
                      v='underline'
                      color='yellowWhite'
                      onClick={journal.myLifeValues 
                        ? openLifeValuesModal 
                        : detail.tribalTestResult 
                          ? () => openAllValueModal({
                            values: detail.tribalTestResult,
                            mapValues: lifeValues,
                            onClose: close
                          }) 
                          : undefined}
                      position='relative'
                    >
                      {journal.myLifeValues 
                        ? (
                          <FormattedMessage id='valueCenter.strip1.allValues' />
                        ) : (
                          <FormattedMessage id='valueCenter.strip1.all' />
                        )
                      }
                    </UILink>
                  </Flex>

                  {!eticiaPlus && (
                    <Flex flexDirection='column' alignItems='center'>
                      <Typography mt='1rem'>
                        <FormattedMessage id='valueCenter.strip1.allValuesText' />
                      </Typography>
                      <PlusTag
                        mt={{ _: '1rem', sm: 0 }}
                        ml={{ _: 0, sm: '1rem' }}
                      >
                              Eticia plus
                      </PlusTag>
                    </Flex>
                  )}
                </>
              ) : (
                <Flex flexDirection='column' alignItems='center' justifyContent='center' minHeight='40rem'>
                  <Img src='/img/cloud-map-disabled-red.png' alt='' position='absolute' />

                  <Box textAlign='center' position='relative' maxWidth='30rem' mx='auto'>
                    <Typography color='yellowWhite'>
                      <FormattedHTMLMessage id='valueCenter.tribal.0days' />
                    </Typography>

                    <Link to={`${createUrl('app.gameIntro')}?t=${ONE_ROUND_TEST_ID}&redirect=0`}>
                      <PrimaryButton mt='3rem'>
                        <FormattedMessage id='valueCenter.tribal.noTribal.button' />
                      </PrimaryButton>
                    </Link>
                  </Box>
                </Flex>
              )
                  
          }
        </Fragment>
      ) : (
        <Flex flexDirection='column' alignItems='center' justifyContent='center' minHeight='40rem'>
          <Img src='/img/cloud-map-disabled2.png' alt='' position='absolute' />

          <Link to={createUrl('app.gameIntro') + '?t=welcome'}>
            <Box textAlign='center' position='relative' maxWidth='30rem' mx='auto'>
              <Typography color='yellowWhite'>
                <FormattedHTMLMessage id='valueCenter.tribal.noTribal' />
              </Typography>

              <Link to={createUrl('app.gameIntro') + '?t=welcome'}>
                <PrimaryButton mt='3rem'>
                  <FormattedMessage id='valueCenter.tribal.noTribal.button' />
                </PrimaryButton>
              </Link>
            </Box>
          </Link>
        </Flex>
      )}
    </Box>
  );
};
 
export default ValueMapBox;