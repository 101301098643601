
import { modal } from '@xcorejs/ui';
import * as React from 'react';

export const modalTheme = modal({
  default: {
    _overlay: {
      background: 'rgba(0, 0, 0, 0.7)',
      zIndex: 1004
    },
    _close: {
      _icon: {
        width: '1.7rem',
        height: '1.7rem',
        svg: <svg width='17px' height='17px' viewBox='0 0 17 17' version='1.1' xmlns='http://www.w3.org/2000/svg'><g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' opacity='0.796037946'><g transform='translate(-537.000000, -725.000000)' fill='#FFFFFF' fillRule='nonzero'><g transform='translate(60.000000, 135.000000)'><g transform='translate(450.000000, 153.000000)'><g><g transform='translate(15.000000, 425.000000)'><g><polygon points='28.9705627 27.5563492 27.5563492 28.9705627 20.4852814 21.8994949 13.4142136 28.9705627 12 27.5563492 19.0710678 20.4852814 12 13.4142136 13.4142136 12 20.4852814 19.0710678 27.5563492 12 28.9705627 13.4142136 21.8994949 20.4852814'></polygon></g></g></g></g></g></g></g></svg>
      },
      position: 'absolute',
      top: { _: '1.5rem', sm: '1.5rem' },
      right: { _: '1.5rem', sm: '1.5rem' },
      borderRadius: '50%',
      border: '2px solid rgb(227, 210, 64)',
      width: '4rem',
      height: '4rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      opacity: 0.4,
      transition: 'opacity 300ms',
      _hover: {
        opacity: 0.7
      }
    },
    bg: '#fff',
    padding: { _: '3rem', sm: '6rem 10rem 3rem' },
    overflow: 'auto',
    width: '100%',
    borderRadius: 0,
    maxHeight: 'calc(100vh - 5rem)',
    top: '0'
  },
  sizes: {
    sm: {
      maxWidth: '63rem'
    },
    md: {
      maxWidth: '63rem'
    },
    lg: {
      maxWidth: '63rem'
    },
    full: {
      maxWidth: 'none'
    }
  }
});
