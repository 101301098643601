import { button } from '@xcorejs/ui';

export const buttonTheme = button({
  default: {
    fontWeight: 500,
    fontFamily: 'OliMedium',
    fontSize: '1.5rem',
    lineHeight: '2.4rem',
    borderRadius: 0,
    color: '#fff',
    justifyContent: 'center',
    cursor: 'pointer',

    _leftIcon: {
      mr: '1.5rem'
    },
    _rightIcon: {
      ml: '1.5rem'
    }
  },
  sizes: {
    xs: {
      pt: '0.6rem',
      pb: '0.5rem',
      px: '1rem',
      fontSize: '1.4rem',
      lineHeight: '1.6rem',
      height: '3rem',
    },
    sm: {
      px: '3rem',
      py: '1.1rem',
      fontSize: '1.5rem',
      lineHeight: '2rem',
      height: '4rem',
    },
    md: {
      px: '3.2rem',
      py: '1.1rem',
      fontSize: '1.5rem',
      lineHeight: '2rem',
      height: '4rem',
    },
    lg: {
      px: '3rem',
      py: '1.2rem',
      fontSize: '2rem',
      lineHeight: '3.4rem',
      height: { _: '5rem', md: '6rem' },
    }
  },
  variants: {
    solid: {
      bg: '#ffde71',
      color: '#191d2c',
      borderRadius: '3.5rem',
      height: '5rem',
      _hover: {
        bg: '#FFA4A3',
      },
      _active: {
        bg: '#FFA4A3',
      },
      _focus: {
        bg: '#FFA4A3',
        outline: 'none',
      },
      _disabled: {
        opacity: 0.5
      }
    },
    outline: {
      bg: 'transparent',
      border: '0.2rem solid #ffde71',
      borderColor: '#ffde71',
      borderRadius: '3.5rem',
      color: '#fff',

      _hover: {
        bg: '#FFA4A3',
        color: '#191d2c',
        borderColor: '#FFA4A3'
      },
      _active: {
        bg: '#FFA4A3',
        color: '#191d2c',
        borderColor: '#FFA4A3'
      },
      _focus: {
        bg: '#FFA4A3',
        color: '#191d2c',
        borderColor: '#FFA4A3',
        outline: 'none',
      },
      _disabled: {
        opacity: 0.5
      }
    }
  }
});
