import { IAdditionalQuestion, ISurveyBaseQuestion } from './Question/data';

export interface ICurrentRound<T extends ISurveyBaseQuestion = ISurveyBaseQuestion> {
  type: string;
  theme: string;
  testResultId: string;
  valueTestResultId: string;
  testConfigId: string | null;
  round: number;
  step: number;
  globalStep: number;
  totalSteps: number;
  headerText: string;
  // Classic
  values: ITestValue[];
  requiredValuesCount: number;
  firstTimeTest: boolean;
  // Question
  additionalQuestion: IAdditionalQuestion<T>;
  survey: IIntroSurvey;
}

export interface IIntroSurvey {
  id: string;
  name: string;
  text: string;
  icon: string;
  skipLastScreenWithResults: boolean;
  showIntro: boolean;
}

export interface ITestValue {
  id: string;
  valueId: string;
}

export interface IAddRecord<TData> {
  type: string;
  round: number;
  step: number;
  values: TData;
}

export const addRecordData = <T>(round: ICurrentRound, values: T): IAddRecord<T> => ({
  type: round.type,
  round: round.round,
  step: round.step,
  values,
});

export enum TestTypeEnum {
  INTRO = 'welcome',
  MOJE_HODNOTY = 'fa385f22-7954-4029-8e18-06dc3b92f40f',
  MOJE_SUPERSCHOPNOSTI_HRA = '964842d5-3175-409c-af29-ff7692228599',
  IDEALNI_PARTAK_HRA = 'b6da8458-e11b-433a-8311-e5c111f6c8f5',
  MOJE_SPOKOJENOST = 'beab7d9b-9cb1-4876-91cd-e6e5dcd5a85e',
  HODNOTY_PRAVE_TED = '3c132621-85d3-43e3-9dbe-aea269620486',
  IDEALNI_PARTNER = 'c212ba8c-9fed-492a-9ba4-44af2ab5291d',
  NEJLEPSI_PARTAK = '1985206e-b0ea-49fd-abfc-d495357b6afd',
  CO_NA_TOBE_OCENUJI = '18c05bd1-e8f3-42ca-bcc2-a28ac1a2e852',
  MA_VYSNENA_PRACE = 'd1ded0db-e903-42be-a5f3-eca4a02bbedc',
  SUPERSCHOPNOSTI = '399a3d53-ce8a-402d-8cd3-a877d782e924',
}
