import React, { FC } from 'react';
import { SectionHomepage } from 'components/pages/Homepage/styles';
import { Heading1, Flex } from '@xcorejs/ui';
import PersonBox from './PersonBox';
import VimeoPopup from 'components/atoms/Popups/VimeoPopup';
import { FormattedMessage } from 'react-intl';
import withPopups, { IWithPopups } from 'components/molecules/withRedux/withPopups';
import { SecondaryButton } from 'components/components/Button';
import { useHistory } from 'react-router-dom';
import { createUrl } from 'components/pages/routes';
import Fade from 'react-reveal/Fade';

type AboutEticiaProps = {
  insideApp?: boolean;
}

const AboutEticia: FC<AboutEticiaProps & IWithPopups> = ({ popups, insideApp }) => {
  const history = useHistory();

  return (
    <Fade ssrReveal bottom duration={1000} distance={'5rem'}>
      <SectionHomepage
        backgroundColor='#0a1119'
        display='flex'
        backgroundImage='/img/homepage/planetCrop.jpg'
        backgroundSize={{ _: '125%', xs: 'auto' }}
        minHeight='80svh'
      >
        <Heading1>
        Eticia 21 - testovací provoz
        </Heading1>

        <Flex flexDirection={{ _: 'column', sm: 'row' }} style={{ gap:'4rem 1rem' }} marginTop='4rem'>
          <PersonBox
            img={'/img/logo/eticia220s@2x.png'}
            name={'Eticia 21'}
            text={'about-project.creators1.text'}
            popup={() => popups.custom(() => <VimeoPopup id={'376328496'} />)}
            linkText={'vlc.video.play'}
          />        
        
          <PersonBox
            img={'/img/logo/institut220s@2x.png'}
            name={'Institut Eticia'}
            text={'about-project.creators2.text'}
            // link={'https://www.instituteticia.org/'}
            // linkText={'www.instituteticia.org'}
          />
        
          <PersonBox
            img={'/img/logo/hodnoty220s@2x.png'}
            name={'Hodnoty 21'}
            text={'about-project.creators3.text'}
            link={'https://www.hodnoty21.com/'}
            linkText={'www.hodnoty21.com'}
          />
        </Flex>

        <SecondaryButton marginTop={{ _:'4rem', md: '12rem' }} onClick={() => history.push(insideApp ? createUrl('app') : createUrl(''))}>
          <FormattedMessage id={'presentation.menu.homepageBtn'} />
        </SecondaryButton>
      </SectionHomepage>
    </Fade>
  );
};
 
export default withPopups(AboutEticia);