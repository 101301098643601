// Prefix
export const API_URL = '/api/v1';

// Application
export const PRIVATE_INFO = API_URL + '/application/private';

// Auth
export const AUTH = API_URL + '/auth';
export const REGISTRATION = AUTH + '/register';
export const LOGIN = AUTH + '/login';
export const LOGIN_FACEBOOK = LOGIN + '/facebook';
export const LOGIN_GOOGLE = LOGIN + '/google';
export const LOGOUT = AUTH + '/logout';

// Auth password
export const PASSWORD_FORGOTTEN_CHANGE = API_URL + '/auth/password/change-forgotten';
export const PASSWORD_FORGOTTEN = API_URL + '/auth/password/request-forgotten';
export const PASSWORD_CHANGE = API_URL + '/auth/password/change';

// Eticoin
export const ETICOINS = API_URL + '/eticoins';

// Eticon
export const ETICONS = API_URL + '/eticons';

// Feedback
export const FEEDBACK = API_URL + '/feedbacks';

// Friends
export const FRIENDS = API_URL + '/friends';

// Life value
export const LIFE_VALUES = API_URL + '/life-values';
export const LIFE_VALUES_DETAIL = (id: string) => LIFE_VALUES + '/' + id;
export const LIFE_VALUES_DEF = (id: string) => LIFE_VALUES_DETAIL(id) + '/definition';
export const LIFE_VALUES_VOTE = (id: string) => LIFE_VALUES_DETAIL(id) + '/vote';

// Notification
export const NOTIFICATIONS = API_URL + '/notifications';
export const NOTIFICATIONS_UNREAD = NOTIFICATIONS + '/unread';
export const NOTIFICATIONS_MARK = (id: string) => NOTIFICATIONS + `/${id}/mark-as-read`;

// Profile
export const PROFILES = API_URL + '/profiles';
export const CURRENT_PROFILE = PROFILES + '/current';
export const AVATAR = CURRENT_PROFILE + '/avatar';
export const DATING = CURRENT_PROFILE + '/dating';
export const DATING_PHOTO = CURRENT_PROFILE + '/dating-photo';
export const CURRENT_PROFILE_DETAIL = CURRENT_PROFILE + '/detail';
export const EDIT_PROFILE = CURRENT_PROFILE + '/edit';
export const EDIT_PROFILE_GROUPS = CURRENT_PROFILE + '/groups';
export const CURRENT_STATE = CURRENT_PROFILE + '/variables';
export const DELETE_PROFILE = PROFILES + '/current';

// Shop + vouchers
const SHOP = API_URL + '/shop';
export const SHOP_BUY = SHOP + '/buy';
export const SHOP_SUMMARY = SHOP + '/summary';
export const SHOP_CLAIM_VOUCHER = SHOP + '/claim-voucher';

/** @deprecated - uz neexistuje */
export const VOUCHERS_INVITE = SHOP + '/invitation';

export const VOUCHERS_CLAIM = SHOP + '/claim-voucher';


/** @deprecated - uz neexistuje */
export const SUBMIT_SHIRT = SHOP + '/t-shirt';
/** @deprecated - uz neexistuje */
export const BUY_OWN_VOUCHER = SHOP + '/own-voucher';
/** @deprecated - uz neexistuje */
export const BUY_GIFT_VOUCHER = SHOP + '/gift-voucher';
/** @deprecated - uz neexistuje */
export const BUY_GIFT_PACKAGE = SHOP + '/gift-package';

export const JOURNAL = API_URL + '/journal';

// semaphore
export const SEMAPHORE = (testResultId: string) => `${API_URL}/semaphore/${testResultId}`;

// Tests
export const TESTS_RESULTS = API_URL + '/test-results';
export const TESTS_RESULTS_DETAIL = (id: string) => TESTS_RESULTS + '/' + id;
export const TESTS_RESULTS_SEND_MAIL = (id: string) => TESTS_RESULTS + '/' + id + '/send-mail';

// Test config
const TESTS = API_URL + '/tests'; // TODO remove
export const TEST_CURRENTLY_RUNNING = TESTS + '/currently-running';
export const TEST_TEMPLATES = TESTS + '/templates';
export const TEST_TEMPLATE = (id: string) => TEST_TEMPLATES + '/' + id;
export const TEST_GET_CONFIG = (id: string) => TESTS + '/configs/' + id;
export const TEST_CAN_RUN = (id: string) => TEST_GET_CONFIG(id) + '/can-run';
export const TEST_CREATE_FROM_TEMPLATE = TESTS + '/create-from-template';
export const TEST_CREATE_FROM_CONFIG = TESTS + '/create-from-config';

// Test flow
export const TEST_FLOW = (id: string) => API_URL + '/test-flow/' + id;
export const TEST_CURRENT_STEP = (id: string) => TEST_FLOW(id) + '/current-step';
export const TEST_ADD_RECORD = (id: string) => TEST_FLOW(id) + '/add-record';
export const TEST_SKIP = (id: string) => TEST_FLOW(id) + '/skip';

// compare
export const COMPARE_MATRIX = API_URL + '/compare/matrix';
export const COMPARE_TESTS = (testResultId: string) => API_URL + '/compare/' + testResultId;
export const COMPARE = (testResultIdA: string, testResultIdB: string) => `${API_URL}/compare?testResultIdA=${testResultIdA}&testResultIdB=${testResultIdB}`;

// Partner test results
export const PARTNER_TEST_RESULT = API_URL + '/test-result-compare';
export const PARTNER_TEST_RESULT_FUNC = (id: string) => PARTNER_TEST_RESULT + '/' + id;

// Statistics
export const STATISTICS = API_URL + '/statistics';
export const STATISTICS_LIFE_VALUES = STATISTICS + '/life-values';
export const STATISTICS_GLOBAL_INFO = STATISTICS + '/global-info';
export const STATISTICS_GROUP_MATCH_PROFILE = STATISTICS + '/group-match/profile';
export const STATISTICS_GROUP_MATCH_TEST = (id: string) => STATISTICS + '/group-match/test/' + id;

// Utils
export const INTEREST_GROUP_CATEGORIES = API_URL + '/utils/interest-group-categories';
export const COUNTRIES = API_URL + '/utils/countries';

// Deprecated
export const CELEBRITIES = API_URL + '/celebrities';
