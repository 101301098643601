import { Grid } from 'react-styled-flexboxgrid';
import Fade from 'react-reveal/Fade';
import { Heading2 } from '../../../../atoms/Headings';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { SectionText } from '../../../../atoms/Text';
import { StyledProfilePage } from '../styles';
import * as React from 'react';
import useFetch from 'components/atoms/Fetch/useFetch';
import { INotification } from 'store/Entities/Notifications';
import { NOTIFICATIONS, NOTIFICATIONS_MARK } from 'config/apiRoutes';
import LoadingSpinnerLocal from 'components/atoms/Animations/LoadingSpinnerLocal';
import { jsonFetch } from 'utils/fetch';
import MessagesTable from './MessagesTable';

export const markAsReadNotification = async (id: string, onSuccess: () => any, onError: (error: string) => any) => {
  try {
    await jsonFetch(NOTIFICATIONS_MARK(id), {
      method: 'POST',
    });
    onSuccess();
  } catch (e) {
    const json = await e.data.json();
    json && onError(json.error);
  }
};

const Messages: React.FC = () => {
  const result = useFetch<INotification[]>(NOTIFICATIONS);
  const markAsRead = (id: string) =>
    markAsReadNotification(
      id,
      () => result.reload(),
      e => console.log(e),
    );

  return (
    <StyledProfilePage>
      <Grid>
        <Fade bottom ssrReveal duration={1000} distance={'5rem'}>
          <div>
            <Heading2>
              <FormattedMessage id='profile.messages.heading' />
            </Heading2>

            <SectionText>
              <FormattedHTMLMessage id='profile.messages.text' />
            </SectionText>
          </div>

          {result.state === 'fetching' ? (
            <LoadingSpinnerLocal />
          ) : (
            result.result && <MessagesTable data={result.result} markAsReaded={(id: string) => markAsRead(id)} />
          )}
        </Fade>
      </Grid>
    </StyledProfilePage>
  );
};

export default Messages;
