import { jsonFetch } from '../../../../utils/fetch';
import { FEEDBACK } from 'config/apiRoutes';

export const sendFeedback = (email: string, text: string) =>
  jsonFetch(FEEDBACK + '/public', {
    method: 'POST',
    body: JSON.stringify(
      email
        ? {
          email,
          text,
          type: 'feedback',
        }
        : {
          text,
          type: 'feedback',
        },
    ),
  });
