import { useEffect } from 'react';
import { EticiaInfoModalProps } from 'components/components/modals/EticiaInfoModal';

export const useInfoModalTimeout = (openEticiaInfo: (props: EticiaInfoModalProps) => unknown, close: () => unknown) => {
  useEffect(() => {
    const infoModal = localStorage.getItem('openInfoModal');

    if (infoModal === 'true') {
      const timeout = setTimeout(() => {
        localStorage.removeItem('openInfoModal');
        openEticiaInfo({ onClose: close });
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [openEticiaInfo, close]);
};