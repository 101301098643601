import { useState, useEffect } from 'react';

export const useGetVisibleArrow = (position: number) => { 
  const [visibleArrow, setVisibleArrow] = useState<'left' | 'both' | 'right'>('right');
  
  useEffect(() => {
    switch (position) {
    case 0:
      setVisibleArrow('right');
      break;
    case 100:
      setVisibleArrow('left');
      break;
    default:
      setVisibleArrow('both');
      break;
    }
  }, [position]);

  return visibleArrow;
};