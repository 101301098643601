import { Box, Flex, Icon, Img, Tag, Typography } from '@xcorejs/ui';
import SVGlevelTag from 'components/atoms/SVGIcons/SVGlevelTag';
import * as React from 'react';
import { PinStyle } from 'components/pages/App/ValueCenter/Game/index';
import { FC, useContext } from 'react';
import { gameLevels as data } from 'components/pages/App/ValueCenter/Game/data';
import { GameContext } from 'components/App';
import GameIcon from '../../../../components/icons/32/game.svg';
import { FormattedHTMLMessage } from 'react-intl';


const UI: FC = () => {
  const levels = data;
  const {
    insideQuest, userLvl, questLvl, setInsideQuest, setQuestLvl, inventory, setInventory
  } = useContext(GameContext);

  return (
    <Box
      position='relative'
      width='100%'
      maxWidth='37.5rem'
      mx='auto'
      height='100%'
      overflow='auto'
      overflowX='hidden'
    >
      <Box position='fixed' width='100%' zIndex={10} top={0} pointerEvents='none'>
        <Img src='/img/game/mlha_pokus.png' width='100%' alt='' />

        <Box
          width='100%'
          height='100%'
          position='absolute'
          left={0}
          top={0}
          zIndex={1}
          background={`radial-gradient(closest-corner, ${levels[userLvl].theme.primary} -0%, rgba(255, 255, 255, 0) 70%)`}
          marginTop='-50%'
        />
      </Box>

      <Flex
        alignItems='center'
        bg='rgba(11, 12, 28, 0.8)'
        background={`radial-gradient(circle at top, ${levels[userLvl].theme.primary} 0%, ${levels[userLvl].theme.darken} 100%)`}
        height='4.6rem'
        width='100%'
        maxWidth='37.5rem'
        position='fixed'
        left='auto'
        right='auto'
        zIndex={100}
      >
        <Box>
          <Tag
            padding='0'
            borderRadius='0'
            width='2.4rem'
            height='4rem'
            background={`radial-gradient(circle at top, ${levels[userLvl].theme.primary} 0%, ${levels[userLvl].theme.darken} 100%)`}
            ml='1.5rem'
            mr='0.1rem'
            justifyContent='center'
          >
            <Box position='absolute' top={0}>
              <SVGlevelTag color={levels[userLvl].theme.primary} />
            </Box>
            <Box position='relative'>
              {userLvl}
            </Box>
          </Tag>
        </Box>

        <Typography
          width='100%'
          fontSize='2rem'
          lineHeight='2.5rem'
        >
          <FormattedHTMLMessage id={levels[userLvl].name} />
        </Typography>

        <Flex
          alignItems='center'
          justifyContent='center'
          width='4.5rem'
          minWidth='4.5rem'
          height='4.5rem'
          onClick={() => setInventory(!inventory)}
        >
          <Icon
            svg={<GameIcon />}
            fill='rgba(255, 255, 255, 0.7)'
            width='2.4rem'
            height='2.4rem'
            _icon={{ width: '2.4rem', height: '2.4rem' }}
          />
        </Flex>
      </Flex>

      <Box
        position='absolute'
        bottom={levels[userLvl].map.mapBottom + 'rem'}
        transition='bottom 300ms ease'
      >
        <Img src='/img/game/plan.jpg' display='block' alt='' />

        {levels.map((level, i) => {
          return (
            <Box
              key={i}
              position='absolute'
              bottom={level.map.playerBottom + 'rem'}
              left={level.map.playerLeft + '%'}
              opacity={i <= userLvl ? 1 : 0}
              pointerEvents={i <= userLvl ? 'auto' : 'none'}
              transition='opacity 300ms ease'
              cursor='pointer'
            >
              {userLvl === i ? (
                <PinStyle key={i}>
                  <Flex
                    flexDirection='column'
                    alignItems='center'
                    position='relative'
                    onClick={
                      () => {
                        setQuestLvl(i);
                        setInsideQuest(true);
                      }
                    }
                  >
                    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='32' viewBox='0 0 24 32'>
                      <g fill='none'>
                        <path fill='#0B0C1C' stroke={levels[userLvl].theme.primary} d='M12,0.5 C15.1704089,0.5 18.0378389,1.92473886 20.1145276,4.21983153 C22.2084157,6.53393241 23.5,9.73175985 23.5,13.2620319 C23.5,17.6394027 21.3425718,21.9581817 17.8128096,26.0758881 C16.5797326,27.5143557 15.2610219,28.8258807 13.9421051,29.9865832 C13.4807322,30.3926108 13.0517326,30.7512781 12.6747399,31.0523523 C12.3800358,31.2767507 12.1615209,31.4079135 11.9838009,31.4559301 L11.9838009,31.4559301 L11.0981031,30.8688895 C10.7757219,30.606211 10.4271711,30.3115619 10.0578949,29.9865832 C8.73897811,28.8258807 7.42026735,27.5143557 6.18719043,26.0758881 C2.65742823,21.9581817 0.5,17.6394027 0.5,13.2620319 C0.5,9.73175985 1.79158428,6.53393241 3.88547236,4.21983153 C5.96216109,1.92473886 8.82959111,0.5 12,0.5 Z' />
                        <circle cx='12' cy='12' r='6' fill={levels[userLvl].theme.primary} />
                      </g>
                    </svg>

                    <Flex
                      mt='-1rem'
                      borderRadius='50%'
                      width='6rem'
                      height='6rem'
                      border={`2px solid ${levels[userLvl].theme.primary}`}
                      bg='rgba(11, 12, 28, 0.5)'
                      boxShadow='0 0 1.5rem 0 #0b0c1c'
                      alignItems='center'
                      justifyContent='center'
                      transform='scaleY(0.5)'

                    >
                      <Box
                        width='3rem'
                        height='3rem'
                        border='2px dashed #fff'
                        bg='rgba(11, 12, 28, 0.5)'
                        boxShadow='0 0 1.5rem 0 #0b0c1c'
                        borderRadius='50%'
                        animation='spin 10s linear infinite'
                      />
                    </Flex>
                    {userLvl === 0 && !insideQuest && (
                      <Typography
                        color='#fff'
                        fontWeight={800}
                        position='absolute'
                        top='100%'
                        width='11rem'
                      >
                        <FormattedHTMLMessage id='game.startQuest' />
                      </Typography>
                    )}
                  </Flex>
                </PinStyle>
              ) : (
                <Flex
                  borderRadius='50%'
                  width='5rem'
                  height='5rem'
                  border='2px solid #77BEFF'
                  bg='rgba(11, 12, 28, 0.5)'
                  boxShadow='0 0 1.5rem 0 #0B0C1C'
                  alignItems='center'
                  justifyContent='center'
                  transform='scaleY(0.5) translateY(-0.5rem)'
                  onClick={
                    () => {
                      setQuestLvl(i);
                      setInsideQuest(true);
                    }
                  }
                >
                  <Box
                    width='2.5rem'
                    height='2.5rem'
                    border='2px solid #77BEFF'
                    bg='rgba(11, 12, 28, 0.5)'
                    boxShadow='0 0 1.5rem 0 #0B0C1C'
                    borderRadius='50%'
                  />
                </Flex>
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default UI;
