import React, { FC } from 'react';
import { LadderCardProps } from 'components/components/modals/AllLaddersModal';
import { useModal, Flex, Box, Img, Heading4, Typography, Link as UILink } from '@xcorejs/ui';
import PresentingPageModal from 'components/components/modals/PresentingPageModal';
import { PlusTag, FreeTag } from 'components/atoms/Logo';
import { FormattedMessage } from 'react-intl';
import { useQuery } from 'components/atoms/hooks/useQuery';
import { createUrl } from 'components/pages/routes';

interface ExtendedLadderCardProps extends LadderCardProps {
  loggedIn: boolean;
}

const LadderCard: FC<ExtendedLadderCardProps> = ({ ladder, loggedIn, ...props }) => {
  const [openPresentingPageModal] = useModal(PresentingPageModal);
  
  // magie na neotevření modálu v modálu
  const insideModal = useQuery().get('modal');
  const { origin } = window.location;  
  const handleClick = (id: number) => {
    if (insideModal) {  
      const url = new URL(origin + createUrl(loggedIn ? 'app.presenting' : 'presenting') + '?id=' + id);
      window.top.postMessage({ type: 'closeRedirect_' + url }, '*');
    } else {
      openPresentingPageModal({ pageId: id, loggedUser: loggedIn === true });
    }
  };
  
  return (
    <Flex
      position='relative'
      flexDirection='column'
      alignItems='center'
      px='1.6rem'
      py='3rem'
      bg={ladder.background}
      {...props}
    >
      <Box position='absolute' left='1rem' top='1rem'>
        {ladder.plus ? (
          <PlusTag />
        ) : (
          <FreeTag />
        )}
      </Box>
  
      <Img src={ladder.icon} alt='' position='relative' />
  
      <Heading4 mt='1rem' fontSize='1.8rem' lineHeight='2.4rem' mx='-0.6rem'>
        {ladder.heading}
      </Heading4>
  
      <Typography fontSize='1.3rem' lineHeight='1.8rem' mt='1rem'>
        {ladder.text}
        {ladder.hasOwnProperty('mailto') && <a href={`mailto:${ladder.mailto}`}>{ladder.mailto}</a> }
      </Typography>
  
      {ladder.presentingId && (
        <UILink
          mt='2rem'
          fontSize='1.3rem'
          lineHeight='1.8rem'
          color='rgba(255, 255, 255, 0.5)'
          onClick={() => handleClick(parseInt(ladder.presentingId))}
        >
          <FormattedMessage id='eticiaPlus.ladderCard.more' />
        </UILink>
      )}
    </Flex>
  );
};

export default LadderCard;
