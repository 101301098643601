import * as React from 'react';

interface IErrorBoundary {
  error: (stack: string) => any;
}

interface IErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<IErrorBoundary, IErrorBoundaryState> {
  public state: IErrorBoundaryState = { hasError: false };
  public componentDidCatch(error: Error, info: React.ErrorInfo) {
    this.props.error(`Exception:\n${JSON.stringify(error)}\nStack trace: ${info.componentStack}`);
    this.setState({ hasError: true });
  }

  public render() {
    if (this.state.hasError) {
      return null;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
