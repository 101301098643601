import styled from 'styled-components';
import { darkBaseRGB, yellow } from '../../theme/colors';
import { mediaSmDefault } from '../../theme/grid';

export const StyledTable = styled.table`
  width: 100%;
  border-spacing: 0;
  table-layout: fixed;
  border-collapse: collapse;

  &.table {
   tr {
    td {
      width: 65%;
      padding: 1.5rem 0;
      border-bottom: 1px solid rgba(${darkBaseRGB}, 0.25);

      span,
      strong {
        display: block;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:first-child {
        text-align: left;
      }

      &:last-child {
        width: 35%;
        text-align: left;
        font-size: 1.6rem;
        line-height: 2rem;
        color: #fff;

        strong {
          color: ${yellow};
        }
      }
    }

    &:last-child {
      td {
        border-bottom: 0;
      }
    }
  }   
  }
  
  &.profileResult {
    table-layout: fixed;
 
    td {
    
      &:first-child, &:last-child {
        width: 100%;
      }
        
             
      ${mediaSmDefault} {
        width: 16%;
        
        &:first-child, &:last-child {
          width: 37%;
        }
      }
    }
  }
` as any;
