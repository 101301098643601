import React, { FC } from 'react';
import RouterLinkButton from './RouterLinkButton';
import { ShopButton } from '../styles';
import { FormattedMessage } from 'react-intl';
import { ProductCardProps } from './ProductCard';
import withUser, { IWithUser } from 'components/molecules/withRedux/withUser';

interface ProductRouterLinksProps extends Pick<ProductCardProps, 'disabledFor' | 'inApp' | 'prepare' | 'both' | 'color' | 'link' | 'gift'> {}

const ProductRouterLinks: FC<ProductRouterLinksProps> = ({
  inApp,
  ...props
}) => {
  if (inApp) return <RouterLinkWithUser {...props} inApp />;
  return (<RouterLinkNoUser {...props} />);
};
 
export default ProductRouterLinks;

const RouterLinkNoUser: FC<Omit<ProductRouterLinksProps, 'inApp' | 'both' | 'disabledFor'>> = ({ prepare, ...props }) => {
  return (
    <>
      {prepare ? (
        <ShopButton borderColor={props.color} backgroundColor='transparent' disabled>
          <FormattedMessage id='eticiaPlus.shop.coming' />
        </ShopButton>
      ) : <RouterLinkButton {...props} />}
    </>
  );
};

const LinkWithUser: FC<ProductRouterLinksProps & IWithUser> = ({
  user,
  disabledFor,
  gift,
  prepare,
  both,
  ...props
}) => {

  const DISABLED = 
    disabledFor === 'navzdy' && user.eticiaPlusForever ||
    disabledFor === 'plus' && user.eticiaPlus ||
    disabledFor === 'free' && !user.eticiaPlus;

  return (
    <>{!prepare ? 
      both ? (
        <>
          <RouterLinkButton
            {...props}
            disabled={DISABLED}
            extend={user.eticiaPlus}
          />
          <RouterLinkButton 
            {...props} 
            gift
            smallMargin
            smallPadding
            opacityBackgroundColor
          />
        </>
      ) : (
        <RouterLinkButton 
          {...props} 
          gift={gift}
          disabled={gift ? false : DISABLED}
        />
      ) : (
        <ShopButton
          borderColor={props.color}
          backgroundColor='transparent'
          disabled
        >
          <FormattedMessage id='eticiaPlus.shop.coming' />
        </ShopButton>
      )}</>
  );
};

const RouterLinkWithUser = withUser(LinkWithUser);

