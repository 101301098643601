import * as React from 'react';
import TestResult from '../../../../templates/TestResult';
import { Row, Col, Grid } from 'react-styled-flexboxgrid';
import { StyledTestResultGraph, StyledTestResultsDetailed } from './styles';
import { PrimaryButtonLink } from '../../../../atoms/Buttons';
import { FormattedMessage } from 'react-intl';
import Fade from 'react-reveal/Fade';
import { createUrl } from '../../../routes';
import { IValueTestResultDetail } from 'config/api/valueTestResult';
import { StyledLinkBorder } from '../../../../atoms/Buttons/styles';
import {
  Box,
  Flex,
  Heading2,
  Paragraph,
  Stack,
  Tag,
  Typography,
  Grid as XGrid,
  Img,
  ListItem,
  List
} from '@xcorejs/ui';
import ResultGraph from './components/ResultGraph';
import { ITestTemplate } from 'components/pages/Sandbox/TestFactory';
import { ColoredThemes } from 'components/theme/colors';
import { Fragment, useContext } from 'react';
import { GameContext } from 'components/App';
import { PremiumButton } from 'components/components/Button';

interface ITestResultDetailed {
  result: IValueTestResultDetail;
  state: any;
  template: ITestTemplate;
  resultId: string;
}
const chars = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h'];

const TestResultDetailed: React.FC<ITestResultDetailed> = ({ result, state, template, resultId }) => {
  const { eticiaPlus } = useContext(GameContext);
  const plusOrVisible = eticiaPlus || template.info.resultFullWithoutEticiaPlusVisible;

  const characters = result.characters.sort((a, b) => a.index - b.index);
  const top = characters
    .map(x => x.percentage)
    .map((p, i) => ({ i, p }))
    .sort((a, b) => b.p - a.p)
    .map(p => p.p)
    .slice(0, 3);
  const length = characters.length;

  return (
    <TestResult>
      <Grid>
        <StyledTestResultsDetailed>
          <Fade ssrReveal bottom duration={1000} distance={'5rem'}>
            <Row>
              <Col xs={12}>
                <Flex flexDirection='column' alignItems='center' mb='3rem'>
                  <Tag
                    display='block'
                    mx='auto'
                    bg='transparent'
                    border='2px solid rgba(231, 230, 207, 0.5)'
                    py='0.4rem'
                    width='24rem'
                    justifyContent='center'
                    whiteSpace='nowrap'
                    overflow='hidden'
                    textOverflow='ellipsis'
                    textAlign='center'
                  >
                    {template.name}
                  </Tag>
                </Flex>
              </Col>

              {(template.info.resultCharacteristicsVisible
                || template.info.resultFigurineVisible) && (
                <Col xs={12} md={6}>
                  <Heading2 mt='2rem'>
                    {template.info.resultCharacteristicsHeader}
                  </Heading2>

                  {template.info.resultCharacteristicsDescription && (
                    <Typography mt='1rem'>
                      {template.info.resultCharacteristicsDescription}
                    </Typography>
                  )}

                  {template.info.resultFigurineVisible && (
                    <Flex justifyContent='center' {...{ style: { gap: '3rem' } }} mt='3rem'>
                      {(result.eticon.figurine1Icon || result.eticon.figurine1Icon) && (
                        <Flex
                          flexDirection='column'
                          alignItems='center'
                          justifyContent='center'
                        >
                          {result.eticon.figurine1Icon && <Img src={result.eticon.figurine1Icon} mx='auto' alt='' />}

                          {result.eticon.figurine1Description && (
                            <Typography mt='1rem'>
                              {result.eticon.figurine1Description}
                            </Typography>
                          )}
                        </Flex>
                      )}

                      {(result.eticon.figurine1Icon || result.eticon.figurine1Icon) && (
                        <Flex
                          flexDirection='column'
                          alignItems='center'
                          justifyContent='center'
                        >
                          {result.eticon.figurine2Icon && <Img src={result.eticon.figurine2Icon} mx='auto' alt='' />}

                          {result.eticon.figurine2Description && (
                            <Typography mt='1rem'>
                              {result.eticon.figurine2Description}
                            </Typography>
                          )}
                        </Flex>
                      )}
                    </Flex>
                  )}

                  {template.info.resultCharacteristicsVisible && result.bulletCharacteristics && (
                    <Flex justifyContent='center' maxWidth='39rem' mx='auto'>
                      <List
                        mt='2rem'
                        _bullet={{
                          color: '#fff',
                          content: 'url(/img/check_yellow.svg)',
                        }}
                      >
                        {plusOrVisible ?
                          result.bulletCharacteristics.map(char => (
                            <ListItem key={char} mt='2rem'>
                              <Paragraph color='#fff' textAlign='left'>
                                {char}
                              </Paragraph>
                            </ListItem>
                          ))
                          : (
                            <Fragment>
                              <ListItem mt='2rem'>
                                <Paragraph color='#fff' textAlign='left'>
                                  {result.bulletCharacteristics[0]}
                                </Paragraph>
                              </ListItem>

                              <Box position='relative' mt='3rem'>
                                <Img src='/img/orazky_plus.png' alt='' />

                                <Flex
                                  flexDirection='column'
                                  alignItems='center'
                                  justifyContent='center'
                                  position='absolute'
                                  top='50%'
                                  left='50%'
                                  transform='translateX(-50%) translateY(-50%)'
                                  width='100%'
                                >
                                  <Paragraph>
                                    Odrážky je možné zobrazit<br />
                                    <strong>pouze ve verzi Eticia Plus</strong>
                                  </Paragraph>

                                  <PremiumButton mt='2rem' whiteSpace='nowrap' />
                                </Flex>
                              </Box>
                            </Fragment>
                          )}
                      </List>
                    </Flex>
                  )}

                  {template.info.resultCharacteristicsChartVisible && !template.info.resultCharacteristicsVisible &&  (
                    <Box mt='6rem'>
                      <ResultGraph
                        heading={template.info.resultCharacteristicsChartTitle}
                        description={template.info.resultCharacteristicsChartDescription}
                        theme={ColoredThemes[template.theme]}
                        videoAboutGraph={template.info.resultVideoAboutChartVisible}
                        result={result}
                        plusOrVisible={plusOrVisible}
                      />
                    </Box>
                  )}
                </Col>
              )}

              {template.info.resultCharacteristicsChartVisible && template.info.resultCharacteristicsVisible && (
                <Col xs={12} md={6}>
                  <ResultGraph
                    heading={template.info.resultCharacteristicsChartTitle}
                    description={template.info.resultCharacteristicsChartDescription}
                    theme={ColoredThemes[template.theme]}
                    videoAboutGraph={template.info.resultVideoAboutChartVisible}
                    result={result} />
                </Col>
              )}
              <Col
                xs={12}
                md={
                  template.info.resultCharacteristicsVisible ? 12 : 6
                }
              >
                <Fade ssrReveal bottom duration={1000} distance={'5rem'}>
                  <StyledTestResultGraph>
                    <Box
                      className='graph-legend'
                      maxWidth='85rem'
                      mx='auto'
                      mt={template.info.resultCharacteristicsVisible || template.info.resultFigurineVisible ? { _: '6rem', sm: '4rem' } : '0'}
                    >
                      <Heading2 textAlign='center'>
                        {template.info.resultCharacteristicsChartInfoTitle}
                      </Heading2>

                      <Paragraph mt='1rem' textAlign='center'>
                        {template.info.resultCharacteristicsChartInfoDescription}
                      </Paragraph>

                      <XGrid
                        columns={{ _: '1', sm: template.info.resultCharacteristicsVisible ? 'repeat(2, 1fr)' : '1' }}
                        rows='auto'
                        gap={{ _: '2rem', sm: '3rem' }}
                        mt='3rem'
                      >
                        {chars.map((char, index) => (
                          <div key={index}>
                            <span
                              className={
                                plusOrVisible ? top.includes(characters[(index + length - 1) % length].percentage)
                                  ? 'symbol active'
                                  : 'symbol' : 'symbol'
                              }
                            >
                              {char.toUpperCase()}
                            </span>

                            {result.characters[(index + length - 1) % length].chartName}
                            {/*<FormattedHTMLMessage id={'test.result.graph.legend.' + char} />*/}

                            {plusOrVisible && (
                              top.includes(characters[(index + length - 1) % length].percentage) && (
                                <span className='percentage'>
                                  {characters[(index + length - 1) % length].percentage.toFixed(1).replace('.', ',') + ' %'}
                                </span>
                              )
                            )}

                            <Typography textAlign={{ _: 'left', sm: 'center' }}>
                              {result.characters[(index + length - 1) % length].chartDescription}
                              {/*<FormattedHTMLMessage id={'test.result.graph.legend.' + char + '.text'} />*/}
                            </Typography>
                          </div>
                        ))}
                      </XGrid>
                    </Box>
                  </StyledTestResultGraph>
                </Fade>
              </Col>
            </Row>
          </Fade>
        </StyledTestResultsDetailed>

        <Fade top ssrReveal duration={1000} distance={'5rem'} delay={200}>
          <div className='btn-wrap'>
            <PrimaryButtonLink
              to={{
                pathname: createUrl('app.testResult', { id: resultId }) + '/values',
                state,
              }}
            >
              <FormattedMessage id='results.next' />
            </PrimaryButtonLink>

            <div>
              <StyledLinkBorder
                to={{
                  pathname: createUrl('app.testResult', { id: resultId }) + '/detail',
                  state,
                }}
              >
                <FormattedMessage id={'test.result.back'} />
              </StyledLinkBorder>
            </div>
          </div>
        </Fade>
      </Grid>
    </TestResult>
  );
};

export default TestResultDetailed;
