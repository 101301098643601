import styled, { css } from 'styled-components';
import {
  white,
  darkBaseRGB,
  yellow,
  GreenTheme,
  darkBaseHEX,
  GrayTheme,
  RedTheme,
  BlueTheme,
  grayRGB,
  primaryBlueRGB, GoldTheme, VioletTheme, IColorTheme,
} from '../../theme/colors';
import { bold } from '../../theme/fonts';
import { mediaLgDefault, mediaMdDefault, mediaSmDefault } from '../../theme/grid';

export const SurveyButton = styled.span`
  cursor: pointer;
`;

// Test info card
export const StyledTestInfoCard = styled.div<{ color?: string }>`
  display: block;
  margin: 0 auto;
  position: relative;
  padding: 2rem 2rem;
  text-align: center;
  font-size: 1.6rem;
  line-height: 2.4rem;
  border-radius: 0.4rem;
  background-color: rgba(${darkBaseRGB}, 0.5);
  color: ${white};

  ${mediaSmDefault} {
    padding: 2rem 2.5rem;
  }

  .text-top,
  .text-bottom {
    display: inline-block;
    width: 100%;
  }

  .text-top {
    font-weight: ${bold};
    font-size: 2.4rem;
    margin-bottom: 2rem;
  }

  .text-bottom {
    margin-top: 1rem;

    ${mediaMdDefault} {
      min-height: 4.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  ${props =>
    props.color &&
    css`
      box-shadow: 0 0.5rem 1rem 0 rgba(${darkBaseRGB}, 0.4);
      padding: 2rem 1rem;
      height: 100%;

      img {
        max-height: 6.4rem;
      }

      ${mediaSmDefault} {
        padding: 2rem 1rem;
      }

      .text-top {
        font-size: 1.3rem;
        line-height: 1.7rem;

        ${mediaSmDefault} {
          font-size: 1.6rem;
          line-height: 2.1rem;
        }
      }

      .text-bottom {
        color: rgba(${grayRGB}, 0.8);
        font-size: 1.3rem;
        line-height: 1.6rem;

        ${mediaSmDefault} {
          font-size: 1.6rem;
          line-height: 2rem;
        }
      }
    `}

  ${props =>
    props.color === 'red' &&
    css`
      background: radial-gradient(circle at top, ${RedTheme.primary} 0%, ${RedTheme.darken} 100%);
    `};

  ${props =>
    props.color === 'green' &&
    css`
      background: radial-gradient(circle at top, ${GreenTheme.primary} 0%, ${GreenTheme.darken} 100%);
    `};

  ${props =>
    props.color === 'blue' &&
    css`
      background: radial-gradient(circle at top, ${BlueTheme.primary} 0%, ${BlueTheme.darken} 100%);
    `};

  ${props =>
    props.color === 'yellow' &&
    css`
      background: radial-gradient(circle at top, ${GoldTheme.primary} 0%, ${GoldTheme.darken} 100%);
    `};

  ${props =>
    props.color === 'black' &&
    css`
      background: rgba(${darkBaseRGB}, 0.7);
    `};
`;

export const StyledCard = styled.div<{
  color?: 'blue' | 'green' | 'yellow' | 'gray' | 'violet' | 'bordered';
  afterOpacity?: number;
  isSelected?: boolean;
  withCheckbox?: boolean;
  withIndex?: boolean;
  size?: 'small' | '' | false;
  theme: IColorTheme;
}>`
  display: flex;
  align-items: center;
  position: relative;
  padding: 1.4rem 7.2rem;
  margin: 0 auto 0.4rem;
  justify-content: center;
  height: 6rem;
  min-height: 6rem;
  max-width: 41rem;
  //background: radial-gradient(circle at top, ${GreenTheme.primary} 0%, ${GreenTheme.darken} 100%);
  background: linear-gradient(0deg, ${GreenTheme.primary} 0%, ${GreenTheme.darken} 100%);
  text-align: center;
  border-radius: 0.4rem;
  box-shadow: 0 2px 5px rgba(${darkBaseRGB}, 0.4);
  overflow: hidden;

  ${mediaLgDefault} {
    height: 8rem;
    min-height: 8rem;
  }

  &:hover {
    img,
    .external-svg {
      opacity: 1;
    }

    ${SurveyButton}, .main-popup-button {
      img {
        width: 2.6rem;
        height: 3.2rem;
      }
    }
  }

  {/*
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(${darkBaseRGB}, ${props => props.afterOpacity ? props.afterOpacity : 0.15});
  }
  */}

  .main-popup-button {
    margin-top: 0 !important;
  }

  ${SurveyButton}, .main-popup-button {
    position: absolute;
    right: 2rem;
    width: 2.1rem;
    height: 2.4rem;

    ${mediaLgDefault} {
      right: 3rem;
      width: 2.4rem;
      height: 2.6rem;
    }

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 100%;
      height: 100%;
      transition: width 0.3s, height 0.3s;
      transform: translateX(-50%) translateY(-50%);

      &.done {
        opacity: 1;
      }
    }
  }

  .main-popup-faq {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;

    img {
      position: absolute;
      width: 2.4rem;
      height: 2.6rem;
      left: calc(100% - 3rem);
    }
  }

  .index {
    position: absolute;
    left: 1.6rem;
    top: 50%;
    transform: translateY(-50%); 
    font-size: 1.4rem;
    line-height: 1.7rem;
    color: rgba(255, 255, 255, 0.7);
  }

  img,
  .external-svg {
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: 3.2rem;
    max-height: 3.2rem;
    left: 4rem;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0.7;
    z-index: 1;
    transition: opacity 0.3s;

    ${mediaLgDefault} {
      max-width: 3.8rem;
      max-height: 3.8rem;
      left: 2.2rem;
    }
  }

  svg {
    width: 100% !important;
    height: 100% !important;

    path {
      fill: #fff !important;
    }
  }

  span {
    position: relative;
    font-weight: 800;
    font-size: 1.6rem;
    line-height: 2.1rem;
    z-index: 1;

    ${mediaLgDefault} {
      font-size: 1.8rem;
      line-height: 2.3rem;
    }
  }

  .top {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 2.1rem;
    bottom: 0.8rem;
    width: 4rem;
    height: 2rem;
    color: ${darkBaseHEX};
    font-size: 1.2rem;
    line-height: 2.2rem;
    border-radius: 1rem;
    background-color: ${yellow};
    z-index: 1;
    font-weight: 500;
    text-transform: uppercase;

    ${mediaLgDefault} {
      left: 2.8rem;
    }
  }

  ${props =>
    props.isSelected &&
    css`
      border: 2px solid ${yellow};
    `};

  ${props =>
    props.color === 'blue' &&
    css`
      //background: radial-gradient(circle at top, ${BlueTheme.primary} 0%, ${BlueTheme.darken} 100%);
      background: linear-gradient(180deg, ${BlueTheme.primary} 0%, ${BlueTheme.darken} 100%);
    `};

  ${props =>
    props.color === 'bordered' &&
    css`
      background: transparent;
      border: 2px solid rgba(${primaryBlueRGB}, 0.5);
      border-radius: 0.4rem;
    `};

  ${props =>
    props.color === 'yellow' &&
    css`
      // background: radial-gradient(circle at top, ${GoldTheme.primary} 0%, #cf9d1c 100%);
      background: linear-gradient(180deg, ${GoldTheme.primary} 0%, ${GoldTheme.darken} 100%);

      span {
        color: ${darkBaseHEX};
      }

      svg {
        path {
          fill: ${darkBaseHEX};
        }
      }
    `};

  ${props =>
    props.color === 'gray' &&
    css`
      // background: radial-gradient(circle at top, ${GrayTheme.primary} 0%, ${GrayTheme.darken} 100%);
      background: linear-gradient(180deg, ${GrayTheme.primary} 0%, ${GrayTheme.darken} 100%);

      span {
        color: ${darkBaseHEX};
      }

      svg {
        path {
          fill: ${darkBaseHEX};
        }
      }
    `};
  ${props =>
    props.color === 'violet' &&
    css`
      //background: radial-gradient(circle at top, ${VioletTheme.primary} 0%, ${VioletTheme.darken} 100%);
      background: linear-gradient(180deg, ${VioletTheme.primary} 0%, ${VioletTheme.darken} 100%);
    `};


  ${props =>
    props.theme && css`
      //background: radial-gradient(circle at top, ${props.theme.primary} 0%, ${props.theme.darken} 100%);
      background: linear-gradient(180deg, ${props.theme.primary} 0%, ${props.theme.darken} 100%);
    `};

  ${props =>
    props.withIndex &&
    css`
      padding: 1.4rem 10rem;

      img,
      .external-svg {
        left: 4rem;
        max-width: 3.2rem;
        max-height: 3.2rem;
        
        ${mediaLgDefault} {
          max-width: 3.8rem;
          max-height: 3.8rem;
        }
      }
    `}

  ${props =>
    props.size === 'small' &&
    css`
      padding-left: 1rem;
      padding-right: 1rem;
      height: auto;
      min-height: 5.6rem;
      font-size: 1.6rem;
      font-weight: 400;

      ${mediaLgDefault} {
        height: auto;
        min-height: 6rem;
      }

      span {
        font-size: 1.6rem;
        line-height: 2rem;
        font-weight: 400;
      }
    `};
`;

export const StyledCheckboxCard = styled(StyledCard)`
  &:after {
    content: "";
    position: absolute;
    width: 4.8rem;
    height: 4.8rem;
    left: 2.4rem;
    top: 50%;
    transform: translateY(-50%);
    background: url("/img/check_yellow.svg") center center no-repeat;
  }

  span {
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 400;
  }
`;
