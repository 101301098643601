import * as React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Col, Grid, Row } from 'react-styled-flexboxgrid';
import { PrimaryButtonLink } from '../../../../atoms/Buttons';
import { Heading2 } from '../../../../atoms/Headings';
import { SectionText } from '../../../../atoms/Text';
import withUser, { IWithUser } from '../../../../molecules/withRedux/withUser';
import { FlexDivMargin } from '../../../../organisms/PartnerTest/Result/styles';
import { createUrl } from '../../../routes';
import StyledProcess from './styles';
import FAQPopup from '../../../../atoms/Popups/FAQPopup';
import { StyledFakeLinkBorder } from '../../../../atoms/Buttons/styles';
import { Box } from '@xcorejs/ui';
import { AboutValueGame } from '../../../../atoms/Popups/FAQPopup/popups';
import { HelpLink } from '../../../../atoms/Popups/HelpLink';
import { LevelPopups } from 'components/organisms/NotificationFetcher/component';

class Process extends React.Component<IWithUser> {
  public render() {
    {/* todo: check and delete, if unused */}
    const { user } = this.props;
    return (
      <StyledProcess>
        <Grid>
          <Row>
            <Col xs={12} md={6} mdOffset={3}>
              <Heading2>
                <FormattedHTMLMessage id={'valueCenter.game.level.' + (user.level > 5 ? '5' : user.level)} />
              </Heading2>

              <SectionText>
                <FormattedHTMLMessage id={'valueCenter.game.intro.level' + (user.level > 5 ? '5' : user.level)} />
              </SectionText>

              <Box mt='3rem' mb='3rem' style={{ textAlign: 'center' }}>
                <FAQPopup popup={AboutValueGame}>
                  <StyledFakeLinkBorder color='yellow' style={{ fontSize: 'inherit' }}>
                    <FormattedMessage id='valueCenter.game.popup.about' />
                  </StyledFakeLinkBorder>
                </FAQPopup>
              </Box>

              <SectionText color='#ffde71'>
                <strong>
                  <FormattedHTMLMessage id={'valueCenter.game.step.' + (user.level > 5 ? '5' : user.level)} />
                </strong>
              </SectionText>

              <SectionText>
                <FormattedHTMLMessage
                  id={'valueCenter.game.level.level' + (user.level > 5 ? '5' : user.level) + '.text2'}
                />
              </SectionText>

              <img src={'/img/intro/lvl-' + (user.level > 5 ? '5' : user.level) + '.png'} className={'lvl-img'} />

              <FlexDivMargin>
                <PrimaryButtonLink to={createUrl('app')}>
                  <FormattedMessage id={'valueCenter.game.back'} />
                </PrimaryButtonLink>
              </FlexDivMargin>
            </Col>
          </Row>
        </Grid>

        <Box position='fixed' right='3rem' bottom='3rem'>
          <FAQPopup popup={LevelPopups[user.level < LevelPopups.length ? user.level - 1 : LevelPopups.length - 1]}>
            <HelpLink size={3} />
          </FAQPopup>
        </Box>
      </StyledProcess>
    );
  }
}

export default withUser<{}>(Process);
