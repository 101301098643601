import * as React from 'react';
import { TransitionGroup } from 'react-transition-group';
import { Col } from 'react-styled-flexboxgrid';
import Placeholder from './Cards/Placeholder';
import CustomFade from '../../atoms/Animations/CustomFade';

export interface ITabCard {
  id: string;
}

interface ITabWrapper {
  collection: ITabCard[];
  label: string;
  color: string;
  inTest: boolean;
  children: (itm: any) => React.ReactNode;
}

class TabWrapper extends React.Component<ITabWrapper> {
  public render() {
    const { collection, inTest, children, label, color } = this.props;

    return (
      <>
        {collection
          .filter((_, index) => index < 3)
          .map((s, key) => (
            <Col key={key} xs={inTest ? 24 : 12} sm={inTest ? 24 : 12} md={inTest ? 8 : 4}>
              <TransitionGroup className={'transition-group'}>
                <CustomFade key={s !== undefined ? s.id : 'c2a'}>
                  {s !== undefined ? children(s) : <Placeholder label={label} color={color} />}
                </CustomFade>
              </TransitionGroup>
            </Col>
          ))}
      </>
    );
  }
}

export default TabWrapper;
