import { link } from '@xcorejs/ui';

export const linkTheme = link({
  default: {
    lineHeight: 'initial',
    fontFamily: 'inherit',
    fontSize: '1.5rem',
    textDecoration: 'none',
  },
  variants: {
    underline: {
      color: 'rgb(209, 211, 218)',
      borderColor: 'rgb(209, 211, 218)',

      _hover: {
        color: 'rgb(209, 211, 218)',
        borderColor: 'transparent'
      }
    },
    simple: {
      borderBottom: 0,

      _hover: {
        color: 'rgb(209, 211, 218)',
        borderBottom: 0
      }
    }
  }
});
