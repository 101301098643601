import { API_URL } from '../apiRoutes';
// HACK: Import from src
import { IEticon } from 'store/Entities/Eticons';

export const VALUE_TEST_RESULTS = API_URL + '/value-test-results';
export const VALUE_TEST_RESULTS_TRIBAL = VALUE_TEST_RESULTS + '/tribal';
export const VALUE_TEST_RESULTS_DETAIL = (id: string) => VALUE_TEST_RESULTS + '/' + id;
export const VALUE_TEST_MARK_AS_TRIBAL = (id: string) => VALUE_TEST_RESULTS + `/${id}/mark-as-tribal`;
export const VALUE_TEST_CHANGE_NOTE = (id: string) => VALUE_TEST_RESULTS + `/${id}/note`;

export interface IValueTestResult {
  id: string;
  status: number;
  eticonName: string;
  eticonAvatar: string;
  createdAt: number;
  updatedAt: number;
  doneAt: number;
  note: string;
}

export interface IValueResult {
  valueId: string;
  name: string;
  points: number;
  rank: number; // rank vypocteny
  userRank: number; // rank podle toho, jak to uzivatel naklikal
}

export interface ICharacter {
  name: string;
  chartName: string;
  chartDescription: string;
  percentage: number;
  index: number;
}

export interface IValueTestResultDetail extends IValueTestResult {
  eticon: IEticon;
  lifeValues: IValueResult[];
  allLifeValues: IValueResult[];
  characters: ICharacter[];
  bulletCharacteristics: string[];
  secondaryCharacteristic: string;
  note: string;
}

export const getTestCount = (results: IValueTestResult[]) => results.filter(result => result.status === 3).length;
