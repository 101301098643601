import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Heading1 } from '../../../../atoms/Headings';
import { SmallerText } from '../../../../organisms/VisualValuesComparison/style';
import { StyledLinkBorder, StyledLink } from '../../../../atoms/Buttons/styles';
import { StyledGamesConfiguration } from '../ComparisonSetup/style';
import { RouteComponentProps, withRouter } from 'react-router';
import canUseDOM from '../../../../../utils/canUseDOM';
import { Row, Col, Grid } from 'react-styled-flexboxgrid';
import { PrimaryButtonLink } from '../../../../atoms/Buttons/index';
import { createUrl } from '../../../routes';
import { StyledValueComparisonSection, InvitationLink } from '../Comparisons/styles';
import SVGlink from '../../../../atoms/SVGIcons/SVGlink';
import clipboard from '../../../../../utils/clipboard';

interface ILocationState {
  testConfigId?: string;
  voucherId?: string;
  button?: boolean;
}

interface IInvitationState {
  copied: boolean;
}

class Invitation extends React.Component<RouteComponentProps<{}>, IInvitationState> {
  public state: IInvitationState = {
    copied: false,
  };
  public render() {
    const state: ILocationState = this.props.location.state;
    if (state === undefined || !state.testConfigId) {
      this.props.history.push(createUrl('app.comparison'));
      return <div />;
    }

    let url = '';
    if (canUseDOM) {
      const l = window.location;
      url =
        l.protocol +
        '//' +
        l.host +
        createUrl('acceptInvite', {
          id: state.testConfigId,
        });
    }
    return (
      <Grid>
        <Row>
          <Col xs={12} md={6} mdOffset={3}>
            <StyledValueComparisonSection>
              <StyledGamesConfiguration>
                <Heading1>
                  <FormattedMessage id={'config.invitationLink.heading'} />
                </Heading1>

                <SmallerText>
                  <FormattedMessage id={'config.invitationLink.text'} />
                </SmallerText>

                <InvitationLink
                  isCopied={this.state.copied}
                  onClick={() => {
                    clipboard(url);
                    this.setState({ copied: true });
                  }}
                >
                  <span>{url}</span>
                  <SVGlink />
                </InvitationLink>

                <StyledLink
                  className={'copyLink'}
                  to={createUrl('app.comparison.sendInvitation', {
                    id: state.voucherId!,
                  })}
                >
                  <img src={'/img/send-mail.svg'} alt='' />
                  <FormattedMessage id={'config.invitationLink.copy'} />
                </StyledLink>

                {state.button && (
                  <PrimaryButtonLink
                    to={createUrl('app.comparison.startTest', {
                      id: state.testConfigId,
                    })}
                  >
                    <FormattedMessage id={'config.start'} />
                  </PrimaryButtonLink>
                )}

                <div className={'back'}>
                  <StyledLinkBorder to={createUrl('app.comparison') + '/moje-porovnani'}>
                    <FormattedMessage id={'config.back'} />
                  </StyledLinkBorder>
                </div>
              </StyledGamesConfiguration>
            </StyledValueComparisonSection>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default withRouter(Invitation);
