import * as React from 'react';
import { FC } from 'react';
import styled from 'styled-components';
import { Box, Container, Flex, Heading1, Paragraph, Typography } from '@xcorejs/ui';
import Fade from 'react-reveal/Fade';
import { BlueTheme, yellowWhite, yellow } from 'components/theme/colors';
import { InlineFormSelect } from 'components/atoms/InlineForm/InlineFormSelect';
import { Option } from 'react-select';
import { mediaMdDefault } from 'components/theme/grid';
import { createUrl } from 'components/pages/routes';
import CounterUp from 'components/atoms/CounterUp';
import { FormattedMessage } from 'react-intl';
import AnimatedArrow from 'components/atoms/Icons/AnimatedArrow';

const Main: FC = () => {
  const [countEnd, setCountEnd] = React.useState<boolean>(false);
  const [animationEnd, setAnimationEnd] = React.useState<boolean>(false);
  const [country, setCountry] = React.useState<string>('561962a8-5959-4321-ac01-d5e2e7d06cf9');

  return (
    <Box position='relative' mt={{ _: '-5rem', md: '-6rem' }}>
      <Box position='absolute' top={{ _: 0, md: '30%' }} left='50%' transform='translate(-50%)' zIndex={10} width={{ _: '100%', md: 'auto' }} alignItems={{ _: 'flex-start', md: 'center' }}>
        <Container justifyContent={{ _: 'center', md: 'flex-start' }}>
          <Fade ssrReveal bottom duration={1000} distance={'5rem'} delay={750}>
            <FlexSVH
              flexDirection={{ _: 'column' }}
              mt={{ _: '10vh', md: '0' }}
              justifyContent={{ _: 'space-between' }}
              width={{ _: '100%', md: 'auto' }}
            >
              <section>
                <Heading1
                  position='relative'
                  textAlign='center'
                  color='#fff'
                  fontSize={{ _: '4rem', md: '5.5rem' }}
                  lineHeight={{ _: '4.2rem', md: '6.5rem' }}
                >
                Vstup<br />
                do Eticia 21
                </Heading1>
                <Paragraph 
                  mt={{ _: '1rem' }}
                  fontSize={{ _: '1.5rem', md: '2.2rem' }}
                  color='yellowWhite'
                  fontFamily='OliLight'
                >
                Sestav si svůj hodnotový žebříček <br />
                snadno a rychle
                </Paragraph>
              </section>

              <FlexContainer>
                <Typography
                  textAlign='center'
                  maxWidth={{ _: '300px', sm: 'none' }}
                  margin={{ _: '0 auto', sm: '3.2rem 0 0' }}
                  fontSize={{ _: '1.3rem', md: '1.6rem' }}
                  fontFamily='OliLight'
                >
                Vyber si svou zemi a objevuj hodnoty ze světa
                </Typography>
                <InlineFormSelect
                  name='countryId'
                  value={country}
                  options={[
                    {
                      value: '561962a8-5959-4321-ac01-d5e2e7d06cf9',
                      label: 'Česká Republika'
                    },
                    {
                      value: '23f0acf4-81b6-4553-8bf4-46bac3e6485a',
                      label: 'Slovenská Republika'
                    },
                  ]}
                  disabled={[-1]}
                  // eslint-disable-next-line no-extra-parens
                  onChange={opt => setCountry((opt as Option).value as string)}
                  selectStyle={{
                    bg: 'transparent',
                    paddingTop: '0',
                    paddingBottom: '0',
                  }}
                />
                <SecondaryButton href={`${createUrl('register')}?c=${country}`} >
                  Sestavit žebříček zdarma
                </SecondaryButton>
                <Typography 
                  fontSize={{ _:'1.3rem', md:'1.6rem' }}
                  fontFamily='OliLight'
                >
                  <FormattedMessage id={'homepage.visual.counterUp'} />{' '}
                  <LightUp
                    light={countEnd}
                    onAnimationEnd={() => setAnimationEnd(true)}
                    onAnimationStart={() => setAnimationEnd(false)}
                    fontWeight={animationEnd && countEnd ? 'bold' : 'normal'}
                  >
                    <CounterUp numberOnly end={setCountEnd} />
                  </LightUp>{' '}
                  <FormattedMessage id={'homepage.visual.counterUp2'} />
                </Typography>
              </FlexContainer>
            </FlexSVH>
          </Fade>
        </Container>
      </Box>

      <Flex
        justifyContent='center'
        width='100%'
        position='absolute'
        bottom={{ _: '-1rem', md: '1rem' }}
        left='0'
        zIndex={10}
        onClick={() => window.scrollTo({ top: window.innerHeight - 50, behavior: 'smooth' })}
      >
        <AnimatedArrow
          color='#aaa'
          transform={{ _: 'scale(0.4) rotate(90deg)', md: 'scale(0.55) rotate(90deg)' }}
          cursor='pointer'
        />
      </Flex>

      <IframeDesktopStyle
        width='100vw'
        position='relative'
        overflow='hidden'
        display={{ _: 'none', md: 'block' }}
      >
        <iframe
          src='https://player.vimeo.com/video/661451515?h=87cb75ab1e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;muted=1&amp;autoplay=1&amp;loop=1&amp;background=1&amp;autoplay=1&amp;loop=1&amp;byline=0'
          frameBorder='0'
          allow='autoplay; fullscreen; picture-in-picture'
          allowFullScreen
          title='Eticia 21'
        />
      </IframeDesktopStyle>

      <IframeMobileStyle
        width='100vw'
        position='relative'
        overflow='hidden'
        display={{ _: 'block', md: 'none' }}
      >
        <iframe
          src='https://player.vimeo.com/video/852436561?&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;muted=1&amp;autoplay=1&amp;loop=1&amp;background=1&amp;autoplay=1&amp;loop=1&amp;byline=0'
          frameBorder='0'
          allow='autoplay; fullscreen; picture-in-picture'
          allowFullScreen
          title='Eticia 21'
        />
      </IframeMobileStyle>
    </Box>
  );
};

export default Main;

const FlexSVH = styled(Flex)`
  height: calc(100vh - 13rem);
  height: calc(100svh - 13rem);

  ${mediaMdDefault} {
    height: auto;
  }
`;

const IframeDesktopStyle = styled(Box)`
  background: #000;
  height: 100vh;
  
  iframe {
    width: 100vw;
    height: 27.3vw;
    min-height: 100vh;
    min-width: 366vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const IframeMobileStyle = styled(Box)`
  background: #000;
  height: 100vh;
  height: 100svh;
  
  iframe {
    width: 100vw;
    height: 83.1vw;
    min-height: 100vh;
    min-width: 120vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-49.5%, -50%);
  }
`;

const SecondaryButton = styled.a`
  align-self: center;
  margin-top: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.0rem;
  border-radius: 3rem;
  border: 2px solid ${BlueTheme.darken};
  color: #7791c1;
  padding: 1.5rem 3rem;
  transition: background-color 300ms ease,color 300ms ease;

  &:hover {
    background-color: ${BlueTheme.darken};
    color: #fff;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  gap: 1rem;

  ${mediaMdDefault} {
    gap: 3rem;
  }
`;

const LightUp = styled.span<{light: boolean; fontWeight: string}>`
  span {
    color: rgba(255,255,255,0.7);
    font-weight: ${({ fontWeight }) => fontWeight};
    -webkit-animation: ${({ light }) => light ? 'lightUp 500ms ease forwards' : 'unset'};
    animation: ${({ light }) => light ? 'lightUp 500ms ease forwards' : 'unset'};

    @-webkit-keyframes lightUp {
      0% {
        color: rgba(255,255,255,0.7);
        text-shadow: unset;
      }

      50% {
        color: rgba(255,255,255,1);
        text-shadow: lightblue 0 0 10px, lightblue 2px 2px 10px;
      }

      100% {
        color: ${yellow};
        text-shadow: unset;
      }
    }

    @keyframes lightUp {
      0% {
        color: rgba(255,255,255,0.7);
        text-shadow: unset;
      }

      50% {
        color: rgba(255,255,255,1);
        text-shadow: lightblue 0 0 10px, lightblue 2px 2px 10px;
      }

      100% {
        color: ${yellow};
        text-shadow: unset;
      }
    }    
  }
`;
