import styled, { css } from 'styled-components';
import { Heading2, Heading1 } from '../../../atoms/Headings';
import { StyledPrimaryButton, StyledPrimaryButtonButton } from '../../../atoms/Buttons/styles';
import { CustomText } from '../../../atoms/Text';
import { gray, yellow, darkBaseRGB, primaryGreen, darkenGreen, darkBaseHEX } from '../../../theme/colors';
import { mediaLgDefault, mediaMdDefault, mediaSmDefault } from '../../../theme/grid';
import { StyledCustomSelect } from '../../../atoms/Select/styles';
import { bold } from '../../../theme/fonts';
import { GameConfig } from '../../../pages/App/ValueComparison/ComparisonSetup/style';
import { StyledTextArea } from '../../../atoms/FormItems/styles';

export const StyledQuestionWrapper = styled.section`
  text-align: center;
  padding-bottom: 3rem;

  .pre-title {
    display: inline-block;
    margin-top: -4rem;
    font-size: 1.8rem;
    letter-spacing: 0.1rem;
    opacity: 0.8;
  }

  ${Heading2} {
    margin-top: 3rem;
  }

  ${StyledPrimaryButtonButton} {
    margin-top: 3rem;
  }

  ${StyledPrimaryButton} {
    margin-top: 3rem;
    height: 4rem;

    ${mediaSmDefault} {
      height: 5rem;
    }
  }

  ${StyledPrimaryButton} ~ .back {
    margin-top: 2rem;
  }
`;

export const StyledQuestionLayout = styled.section<{ decorationUrl?: string }>`
  padding: 0 1.5rem 3rem;

  & > div {
    position: relative;
    z-index: 1;
  }

  &:after {
    content: "";
    display: inline-block;
    position: fixed;
    right: 0;
    bottom: 0;
    opacity: 0.1;
    background: url(${props => props.decorationUrl}) bottom right no-repeat;
    background-size: contain;
    max-width: 35%;
    max-height: 65%;
    width: 100%;
    height: 100%;
  }

  h1 {
    font-size: 1.6rem;
    line-height: 2.4rem;
    letter-spacing: 0.5px;
    font-weight: 400;
    color: #fff;

    ${mediaSmDefault} {
      font-weight: 800;
      font-size: 2.4rem;
      line-height: 3.1rem;
    }
  }

  h1 ~ p {
    margin-top: 1rem;
    font-size: 1.3rem;
    line-height: 1.6rem;
    color: rgba(255, 255, 255, 0.8);

    ${mediaSmDefault} {
      margin-top: 3rem;
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }

  .question-img {
    display: block;
    margin: 3rem auto 0;

    &.desktop {
      display: none;
    }

    &.mobile {
      display: block;
    }

    ${mediaSmDefault} {
      max-width: 100%;

      &.desktop {
        display: block;
      }

      &.mobile {
        display: none;
      }
    }
  }

  .question-img ~ p {
    margin-top: 3rem;
  }

  ${CustomText} {
    margin-top: 6rem;
    color: ${gray};
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  ${StyledPrimaryButton} {
    margin-top: 6rem;
  }
`;

export const StyledPartnerQuestion = styled(StyledQuestionLayout)`
  padding-top: 2.3rem;
  text-align: center;

  ${mediaSmDefault} {
    padding-top: 6rem;
  }
`;

export const StyledGameForTwoIntro = styled(StyledQuestionLayout)<{ img?: 'pratelsky' | 'partnersky' }>`
  padding-top: 6rem;
  text-align: center;

  ${props =>
    props.img === 'pratelsky' &&
    css`
      &:after {
        background-image: url("/img/backgrounds/pratelsky@3x.png");
        right: 5rem;
        bottom: 5rem;
      }
    `};

  ${props =>
    props.img === 'partnersky' &&
    css`
      &:after {
        background-image: url("/img/backgrounds/partnersky@3x.png");
      }
    `};

  ${Heading1} {
    color: ${yellow} !important;
    font-size: 3.2rem !important;
    letter-spacing: 0.5px !important;
    line-height: 4rem !important;
    text-align: center !important;

    ${mediaSmDefault} {
      font-size: 4rem !important;
    }
  }

  .test-note {
    margin-top: 1rem;
    opacity: 0.5;
    color: #fff;
    font-size: 2.4rem;
    letter-spacing: 0.5px;
    line-height: 2.9rem;
  }

  ${CustomText} {
    margin-top: 4rem;
    text-align: center;
  }

  ${StyledCustomSelect} {
    margin-top: 3rem;
    margin-left: auto;
    margin-right: auto;

    ${mediaSmDefault} {
      max-width: 41rem;
    }

    .Select-menu-outer {
      .Select-option {
        &:first-child {
          font-weight: ${bold};
          color: ${primaryGreen} !important;

          &:hover {
            color: ${darkenGreen} !important;
          }
        }

        &:nth-child(2) {
          font-weight: ${bold};
          color: ${darkBaseHEX};
        }
      }
    }
  }

  .back {
    margin-top: 3rem;
  }

  ${GameConfig} {
    flex-direction: column;

    & > div {
      margin-top: 3rem;

      &:first-child {
        margin-top: 0;
      }

      ${mediaSmDefault} {
        margin-top: 0;
      }
    }

    svg {
      width: 3rem;
      height: 3rem;
    }

    span {
      opacity: 1;
      font-weight: ${bold};
    }

    ${mediaSmDefault} {
      flex-direction: row;
    }
  }

  ${StyledPrimaryButton} {
    margin-top: 5rem;
    letter-spacing: 0.5px;
  }

  .select-text {
    margin-top: 5rem;
  }
`;

export const Answers = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  width: 100%;
  max-width: 168rem;

  ${mediaSmDefault} {
    margin-top: 4rem;
  }

  ${mediaMdDefault} {
    margin-top: 6rem;
  }

  ${StyledPrimaryButton} {
    margin-top: 4rem;
  }

  img {
    width: 100%;
    max-width: 24rem;

    ${mediaSmDefault} {
      max-width: 41rem;
    }
  }

  ${CustomText} {
    text-align: center;
    justify-content: center;
  }

  .checkbox-wrapper {
    width: 70%;
    text-align: left;
    margin: 0 auto -3rem;

    ${mediaSmDefault} {
      margin-bottom: 0;
      width: auto;
      padding-top: 1.3rem;
      text-align: center;
    }
  }

  ${StyledTextArea} {
    margin-bottom: -3rem;

    ${mediaSmDefault} {
      margin-bottom: 0;
    }
  }
`;

export const RangeDiv = styled.div`
  display: block;
  margin-bottom: -3rem;
  width: 95%;

  ${mediaSmDefault} {
    margin-bottom: 0;
  }

  ${CustomText} {
    margin-top: 0;
    margin-bottom: 6rem;
    color: ${gray};
    font-size: 1.8rem;
    line-height: 2.7rem;
    text-align: center;
  }

  img {
    max-width: 24rem;
    margin-bottom: 2rem;

    ${mediaSmDefault} {
      margin-bottom: 6rem;
    }
  }

  .value {
    font-size: 1.6rem;
    line-height: 2.1rem;
    font-weight: 800;
    display: block;

    ${mediaSmDefault} {
      margin-top: 3rem;
    }
  }

  .flex {
    display: flex;
    flex-direction: column-reverse;

    &:first-child {
      margin-top: 6.5rem;
      margin-bottom: 12.5rem;
    }

    ${mediaSmDefault} {
      flex-direction: column;
    }

    & > div {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2rem;

      span {
        display: flex;
        align-items: flex-end;
        max-width: 12rem;
        font-size: 1.3rem;
        line-height: 1.7rem;
        font-weight: 800;

        @media (min-width: 360px) {
          max-width: 15rem;
        }

        ${mediaSmDefault} {
          font-size: 1.6rem;
          line-height: 2.1rem;
        }

        ${mediaLgDefault} {
          &:first-child {
            transform: translateX(-50%);
          }

          &:last-child {
            transform: translateX(50%);
          }
        }
      }
    }
  }
`;

const RangeButton = css`
  height: 1.8rem;
  width: 1.8rem;
  border-radius: 50%;
  background: #d8d8d8;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -0.75rem;
  border: 0.6rem #fff;
  box-shadow: 0 0 0 1rem #fff, 1rem 0.5rem 1rem 0 rgba(25, 29, 44, 0.4);

  ${mediaSmDefault} {
    margin-top: -1.25rem;
    height: 3rem;
    width: 3rem;
    border: 2rem #fff;
  }
`;

const RangeLine = css`
  width: 100%;
  height: 0.6rem;
  cursor: pointer;
  background: rgba(${darkBaseRGB}, 0.5);
  border-radius: 0.65rem;
`;

export const InputRange = styled.input`
  -webkit-appearance: none;
  width: 100%;
  margin: 2rem 0;
  background: transparent;

  &::-webkit-slider-runnable-track {
    ${RangeLine};
  }

  &::-moz-range-track {
    ${RangeLine};
  }

  &::-ms-track {
    ${RangeLine};
  }

  &::-webkit-slider-thumb {
    ${RangeButton};
  }

  &::-moz-range-thumb {
    ${RangeButton};
  }

  &::-ms-thumb {
    ${RangeButton};
  }

  &::-moz-focus-outer {
    border: 0;
  }

  &:focus {
    outline: none;

    &::-webkit-slider-runnable-track {
      background: rgba(${darkBaseRGB}, 0.5);
    }

    &::-moz-range-track {
      background: rgba(${darkBaseRGB}, 0.5);
    }

    &::-ms-track {
      background: rgba(${darkBaseRGB}, 0.5);
    }
  }
`;
