import React, { FC } from 'react';
import { Paragraph } from '@xcorejs/ui';
import { FormattedMessage } from 'react-intl';
import { ProductCardProps } from './ProductCard';

interface ProductInfoProps extends Pick<ProductCardProps, 'ladders' | 'tree' | 'text1' | 'text2' | 'color'> {}

const ProductInfo: FC<ProductInfoProps> = ({
  ladders,
  tree,
  text1,
  text2,
  color
}) => {
  return ( 
    <>
      <Paragraph color={color} fontWeight='bold' mt='2rem'>
        {ladders}
      </Paragraph>
      <Paragraph fontWeight='bold'>
        <FormattedMessage id='eticiaPlus.shop.ladders' />
      </Paragraph>
  
      {tree ? (
        <Paragraph fontWeight='bold' mt='2rem'>
          <FormattedMessage id='eticiaPlus.shop.tree' />
        </Paragraph>
      ) : null}
  
      <Paragraph mt={tree ? '0' : '2rem'}>
        {text1}
      </Paragraph>
      {text2 ? (
        <Paragraph>
          {text2}
        </Paragraph>
      ) : null}
    </>
  );
};
 
export default ProductInfo;