import * as React from 'react';
import { StyledValueDefinitionCard } from '../styles';

interface IValueDefinitionCard {
  color: string;
  label: string;
  img: string;
  name: string;
  text: string;
  job?: string;
  year?: string;
}

export const ValueDefinitionCard: React.FC<IValueDefinitionCard> = ({
  color,
  label,
  img,
  name,
  job,
  year,
  text,
  children,
}) => {
  return (
    <StyledValueDefinitionCard data-color={color}>
      <div className={'img-wrapper'}>
        {img && <img src={img} alt='' />}
        <span className='label'>{label}</span>

        <div className={'img-bottom'}>
          <strong>{name}</strong>
          {
            <span>
              {job}
              {job && year && ','}
              {year}
            </span>
          }
        </div>
      </div>

      <p>{text}</p>

      {children}
    </StyledValueDefinitionCard>
  );
};

export default ValueDefinitionCard;
