import * as React from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { IProfile, ISurveyCompare } from '../data';
import { IStringMap } from '../../../../../../utils';
import AnswerBox from '../../../../../molecules/TestResult/AnswerBox';
import MultipleAnswers from '../../../../../molecules/TestResult/AnswerBox/MultipleAnswers';
import { StyledResultBoxes } from '../../../../../molecules/TestResult/AnswerBox/styles';
import Fade from 'react-reveal/Fade';

interface IData {
  profiles: IStringMap<IProfile>;
  surveyResults: ISurveyCompare[];
  currentPage: number;
}

const TestResultThemes: React.FC<IData> = ({ profiles, surveyResults, currentPage }) => {
  const surveyResult = surveyResults[currentPage];

  return (
    <StyledResultBoxes>
      <Fade bottom ssrReveal duration={1000} distance={'5rem'}>
        <div>
          {Array(Math.round(surveyResult.questions.length / 3))
            .fill(0)
            .map((_, index) => (
              <Row key={index}>
                {surveyResult.questions.slice(index * 3, index * 3 + 3).map((question, number) => (
                  <Col xs={12} md={4} key={number}>
                    <AnswerBox question={question}>
                      <MultipleAnswers profiles={profiles} question={question} />
                    </AnswerBox>
                  </Col>
                ))}
              </Row>
            ))}
        </div>
      </Fade>
    </StyledResultBoxes>
  );
};

export default TestResultThemes;
