import { IPage } from '../ValuesForTwoResult/data';
import * as React from 'react';
import StatisticResult from './StatisticResult';
import { FormattedHTMLMessage } from 'react-intl';

export const statisticConfig: IPage = {
  label: 'results.partners.menu.statistic',
  name: 'statistic',
  header: () => (
    <div className='test-type'>
      <FormattedHTMLMessage id='results.partners.final.statistics.text' />
    </div>
  ),
  generate: result => <StatisticResult results={result.valueTestResults} profiles={result.profiles} />,
};
