import { ILifeValue } from '../../../../store/Entities/LifeValues/index';
import { IStringMap } from '../../../../utils/index';
import { normalize } from '../../../../utils/helpers';
import { ICurrentRound } from '../data';

export interface IRoundStepData {
  type: string;
  theme: string;
  round: number;
  step: number;
  globalStep: number;
  totalSteps: number;
  headerText: string;
  // Classic
  values: IStringMap<ICardValue>;
  firstTimeTest: boolean;
}

export interface ICardValue {
  valueId: string;
  value: ILifeValue;
}

export const getRoundStep = (round: ICurrentRound, lifeValues: IStringMap<ILifeValue>): IRoundStepData => ({
  ...round,
  values: normalize(
    round.values.map(v => ({
      valueId: v.id,
      value: lifeValues[v.valueId],
    })),
    x => x.value.id,
  ),
});

export interface ISelectedValue {
  duration: number;
  id: string;
}

export const getData = (round: ICurrentRound, selected: Array<string | null>): ISelectedValue[] =>
  selected.map(s => ({
    duration: 0,
    id: round.values.find(v => v.valueId === s)!.id,
  }));
