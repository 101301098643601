import * as React from 'react';
import Fade from 'react-reveal/Fade';
import { StyledJoin } from '../../../styles';
import { Heading2, Heading3 } from '../../../../atoms/Headings';
import TextBox from '../../../../organisms/TextBox';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Footer } from '../../../../organisms/Layout/Footer';
import { Row, Col } from 'react-styled-flexboxgrid';
import { SecondaryButtonLinkBigger } from '../../../../atoms/Buttons';
import { BiggerText } from '../../../../atoms/Text';
import Feedback from '../../../../organisms/InfoSlides/Feedback';
import { createUrl } from '../../../routes';
import { useTheme, IThemeProps } from '../../../../templates/General/ThemeContext';
import Subnav from '../../../../atoms/Subnav';
import Fetch from 'components/atoms/Fetch';
import { _switch } from '../../../../../utils/option';
import { VALUE_TEST_RESULTS, IValueTestResult } from 'config/api/valueTestResult';

class Join extends React.Component<IThemeProps> {
  public render() {
    return (
      <>
        {!this.props.theme.data.legacyMenu && <Subnav />}
        <StyledJoin>
          {/* Mluvit a přemýšlet o hodnotách */}
          <div className={'bg-green margined-after-header'}>
            <TextBox className='green'>
              <Fade ssrReveal bottom duration={1000} distance={'5rem'}>
                <Heading2>
                  <FormattedHTMLMessage id={'join.heading'} />
                </Heading2>

                <BiggerText>
                  <FormattedHTMLMessage id={'join.text'} />
                </BiggerText>

                <div className={'choose'}>
                  <Row>
                    <Fetch url={VALUE_TEST_RESULTS}>
                      {_switch({
                        success: (results: IValueTestResult[]) => (
                          <>
                            {results.some(x => x.status === 3) && (
                              <Col xs={12} md={6}>
                                <Heading3>
                                  <FormattedHTMLMessage id={'join.values.box1.heading'} />
                                </Heading3>

                                <p>
                                  <FormattedMessage id={'join.values.box1.text'} />
                                </p>

                                <SecondaryButtonLinkBigger to={createUrl('about.feedback')}>
                                  <FormattedMessage id={'join.btn.feedback'} />
                                </SecondaryButtonLinkBigger>
                              </Col>
                            )}
                          </>
                        ),
                      })()}
                    </Fetch>
                    <Col xs={12} md={6}>
                      <Heading3>
                        <FormattedHTMLMessage id={'join.values.box2.heading'} />
                      </Heading3>

                      <p>
                        <FormattedMessage id={'join.values.box2.text'} />
                      </p>

                      <SecondaryButtonLinkBigger to={createUrl('about.suggest')}>
                        <FormattedMessage id={'join.btn.tellUsValue'} />
                      </SecondaryButtonLinkBigger>
                    </Col>
                  </Row>
                </div>
              </Fade>
            </TextBox>
          </div>

          <div className={'bg-white'}>
            <TextBox className='light-white'>
              <Fade ssrReveal bottom duration={1000} distance={'5rem'}>
                <div className={'cards'}>
                  <Row>
                    <Col xs={12} md={6} mdOffset={3}>
                      <a href='https://www.instituteticia.org/podporte.html' className={'card blue'}>
                        <strong>
                          <FormattedMessage id={'join.cards.card2.heading'} />
                        </strong>
                        <div className={'img-box'}>
                          <picture className='logo'>
                            <source
                              media='(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)'
                              srcSet='/img/logo/institut-white300@2x.png'
                            />
                            <img src={'/img/logo/institut-white300.png'} alt={'Eticia logo'} />
                          </picture>
                        </div>
                        <p>
                          <FormattedMessage id={'join.cards.card2.text'} />
                        </p>
                      </a>
                    </Col>
                  </Row>
                </div>
              </Fade>
            </TextBox>
          </div>

          <Feedback />

          <Footer />
        </StyledJoin>
      </>
    );
  }
}

export default useTheme(Join);
