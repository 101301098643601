import styled, { css } from 'styled-components';
import { grayRGB, gray, darkBaseRGB } from '../../../../../theme/colors';
import { mediaLandscapePhone, mediaMdDefault, mediaSmDefault } from '../../../../../theme/grid';

export const StyledEmptyCard = styled.div<{ requiredValuesCount?: number }>`
  display: inline-block;
  //padding: 5px 0;
  padding: 0 0 1.1rem;
  width: 100%;
  height: 100%;
  vertical-align: top;
  
  ${mediaLandscapePhone} {
    padding: 0 0 .8rem;
    height: 100%;
  }

  .empty-card {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 4rem;
    padding: 1rem;
    border: 1px dashed rgba(${grayRGB}, 0.3);
    border-radius: 1.2rem;
    background-color: rgba(${darkBaseRGB}, 0.1);

    ${mediaSmDefault} {
      min-height: 11rem;
    }
    
    ${mediaLandscapePhone} {
      min-height: 3.4rem;
      height: 100%;
    }
    
    ${mediaMdDefault} {
      height: 100%;
      //height: ${props => props.theme.EmptySpace.height};
      min-height: ${props => props.theme.EmptySpace.minHeight};
      max-height: ${props => props.theme.EmptySpace.maxHeight};
    }
    
    ${mediaMdDefault} {
      min-height: 20rem;
    }

    .index {
      position: relative;
      display: inline-block;
      color: #fff;
      margin-top: -1.5rem;
      margin-bottom: -1.5rem;
      font-size: 1.8rem;
      line-height: 3rem;
      text-align: center;
      user-select: none;

      ${mediaMdDefault} {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 3.6rem;
        line-height: 3.6rem;
        color: ${gray};
      }
    }

    p {
      display: none;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 1.5rem;
      color: ${gray};
      font-weight: normal;
      font-size: 1.4rem;
      line-height: 1.7rem;
      text-align: center;
      user-select: none;
      
      ${mediaMdDefault} {
        display: block;
        bottom: 2rem;
        font-size: 1.8rem;
        line-height: 2.2rem;
      
        opacity: 0.5;
      }
    }
  }

  &.dragg {
    .empty-card {
      background-color: rgba(${darkBaseRGB}, 0.15);
    }
  }

  &.focused {
    .empty-card {
      border: 1px dashed rgba(${grayRGB}, 1);
      background-color: rgba(${darkBaseRGB}, 0.3);
    }
  }
  
  &.filled {
    position: absolute;
    z-index: 3;
    pointer-events: none;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    .empty-card {
      border: none;
      background: none;
      align-items: flex-start;
      justify-content: center;

      p {
        display: none;
        visibility: hidden;
      }

      .index {
        position: absolute;
        top: 10rem;
        font-size: 14rem;
        opacity: 0.5;
        color: ${gray};
        z-index: 10;
      }
    }
  }
`;
