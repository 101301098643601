import * as React from 'react';
import LayoutA from './A';
import { IAdditionalQuestion, ISurveyDiscreteRatingQuestion } from '../../data';
import { IResponse } from '../../data';
import { IStringMap } from '../../../../../../utils/index';

interface IDiscrete {
  question: IAdditionalQuestion<ISurveyDiscreteRatingQuestion>;
  response: IResponse<IStringMap<boolean>>;
  onChange: (response: IResponse<IStringMap<boolean>>) => void;
  theme?: string;
}

class Discrete extends React.Component<IDiscrete> {
  public render() {
    const { question, response, theme } = this.props;

    return question.layout === 'a' || question.layout === 'b' ? (
      <LayoutA
        layout={question.layout}
        data={question.data}
        help={question.help}
        onChange={this.onChange}
        selected={this.getSelected(response.data)}
        theme={theme}
      />
    ) : (
      <div />
    );
  }

  private onChange = (id: string) =>
    this.props.onChange({
      data: {
        [id]: true,
      },
      submitable: true,
    });

  private getSelected = (data: IStringMap<boolean> | null) => data && Object.keys(data)[0];
}

export default Discrete;
