import * as React from 'react';
import { createUrl } from '../routes';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { StyledLink } from '../../atoms/Buttons/styles';
import wrapIntl, { IWrapIntl } from '../../atoms/wrapIntl';
import Fade from 'react-reveal/Fade';
import { ILoginState, login } from './actions';
import { IFetchException } from '../../../utils/exceptions';
import { withRouter, RouteComponentProps } from 'react-router';
import SVGgoogle from '../../atoms/SVGIcons/SVGgoogle';
import { PrimaryButton } from 'components/components/Button';
import { Box, Container, Flex, Heading1, Link, Typography } from '@xcorejs/ui';
import InlineFormInput from 'components/atoms/InlineForm/InlineFormInput';

interface ILoginProps {
  addInfo: (msg: string) => any;
}

const Login: React.FC<ILoginProps & IWrapIntl & RouteComponentProps<{}>> = ({location, formatMsg}) => {
  const [state, setState] = React.useState<ILoginState>({
    email: '',
    password: '',
    rememberPassword: false,
    error: null
  });

  const enterLogin = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    clickLogin();
  };

  const clickLogin = () => {
    login(state)
      .then(onLoggedIn)
      .catch((ex: IFetchException) => ex.data.json().then((error: any) => displayError(error.error)));
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, type, value, checked } = event.target;
    setState(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const displayError = (error: string) => {
    setState({ ...state, error });
  };

  const onLoggedIn = () => {
    window.location.href =
      location.state && location.state.redirect
        ? location.state.redirect
        : createUrl('app');
  };

  return (
    <Box
      bg="url('/img/global.jpg')"
      backgroundPosition='center bottom'
      backgroundSize='cover'
      {...{ backgroundAttachment: 'fixed' }}
      minHeight='100vh'
      top={0}
      pt='6rem'
      pb='3.5rem'
      mt='-6rem'
      mb='-3.5rem'
    >
      <Container flexDirection='column'>
        <Fade ssrReveal bottom duration={1000} distance={'5rem'}>
          <div>
            <div className='heading'>
              <Heading1 fontSize='3.2rem' lineHeight='3.9rem' fontWeight={400} textAlign='center'>
                <FormattedHTMLMessage id='login.entrance' />
              </Heading1>

              <Typography mt='1.6rem'>
                <FormattedMessage id='login.noAccount' />{' '}
                <StyledLink
                  to={{
                    pathname: createUrl('register'),
                    state: location.state,
                  }}
                >
                  <Link v='underline' color='yellow' borderColor='yellow'>
                    <FormattedMessage id={'login.noAccount.register'} />
                  </Link>
                </StyledLink>
              </Typography>
            </div>
          </div>
        </Fade>

        <Fade ssrReveal bottom duration={1000} delay={400} distance={'5rem'}>
          <Flex
            justifyContent='center'
            maxWidth='41.6rem'
            mx='auto'
            borderBottom='1px solid rgba(216, 216, 216, 0.18)'
            mt='3.2rem'
            pb='3.2rem'
          >
            <PrimaryButton as='a' href='/login/google' bg='#f4f4f6' _hover={{ bg: '#fff' }}>
              <SVGgoogle />
              <span style={{ marginLeft: '1.6rem' }}>
                <FormattedMessage id={'login.google'} />
              </span>
            </PrimaryButton>
          </Flex>
        </Fade>

        <Fade ssrReveal bottom duration={1000} delay={400} distance={'5rem'}>
          <Box maxWidth='41.6rem' mx='auto'>
            <form onSubmit={enterLogin}>
              <Flex flexDirection='column' mt='3.2rem'>
                <Typography mb='0.8rem' textAlign='center' color='rgba(255, 255, 255, 0.6)'>
                    E-mail
                </Typography>

                <InlineFormInput
                  name='email'
                  value={state.email}
                  onChange={handleInputChange}
                  required
                />
              </Flex>

              <Flex flexDirection='column' mt='3.2rem'>
                <Typography mb='0.8rem' textAlign='center' color='rgba(255, 255, 255, 0.6)'>
                  <FormattedMessage id='login.password' />
                </Typography>

                <InlineFormInput
                  name='password'
                  type='password'
                  value={state.password}
                  onChange={handleInputChange}
                  required
                />

                <Flex justifyContent='center' mt='0.8rem'>
                  <StyledLink to={createUrl('forgPass')}>
                    <Link fontSize='1.4rem' v='underline'>
                      <span>{formatMsg('login.forgottenPassword')}</span>
                    </Link>
                  </StyledLink>
                </Flex>
              </Flex>

              <Typography mt='3.2rem' textAlign='center' color='yellow'>
                {state.error !== null ? state.error : ''}
              </Typography>

              {/*
                <StyledCheckboxInput>
                  <input
                    type='checkbox'
                    id='remember-password'
                    name='rememberPassword'
                    onChange={handleInputChange}
                    checked={state.rememberPassword}
                  />
                  <label htmlFor={'remember-password'}>
                    <span className={'fake-checkbox'} />
                    <FormattedMessage id={'password.remember'} />
                  </label>
                </StyledCheckboxInput>

                */}

              <Flex justifyContent='center' mt='3.2rem'>
                <PrimaryButton
                  bg='rgba(12, 107, 75, 0.9)'
                  type='submit'
                  color='#fff'
                  _hover={{ color: '#000' }}
                  onClick={clickLogin}
                >
                  <FormattedMessage id='login' />
                </PrimaryButton>
              </Flex>

              <Flex justifyContent='center' mt='2.4rem'>
                <StyledLink to={createUrl('')}>
                  <Link fontSize='1.4rem' v='underline'>
                    <FormattedMessage id={'backToIntro'} />
                  </Link>
                </StyledLink>
              </Flex>
            </form>
          </Box>
        </Fade>
      </Container>
    </Box>
  );
};

export default withRouter(wrapIntl(Login));
