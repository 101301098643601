import * as React from 'react';
import NavItem from '../../atoms/Nav/NavItem';
import Subnav from '../../atoms/Subnav';
import { createUrl } from '../routes';
import { Route } from 'react-router';
import { Grid } from 'react-styled-flexboxgrid';
import LifeValueSurvey from './LifeValueSurvery';
import Templates from './Templates';
import Compare from '../../components/Compare';

class Sandbox extends React.Component {
  public render() {
    const baseUrl = createUrl('sandbox');
    return (
      <>
        <Subnav>
          <NavItem to={baseUrl} label={'Hodnoty'} exact />
          <NavItem to={baseUrl + '/templates'} label={'Šablony'} exact />
          <NavItem to={baseUrl + '/compare'} label={'Porovnání'} exact />
        </Subnav>
        <Grid>
          <Route exact path={baseUrl} render={() => <LifeValueSurvey />}  />
          <Route exact path={baseUrl + '/templates'} render={() => <Templates />} />
          <Route exact path={baseUrl + '/compare'} render={() => <Compare />} />
        </Grid>
      </>
    );
  }
}

export default Sandbox;
