import React, { FC } from 'react';
import { Box, Flex, Typography } from '@xcorejs/ui';
import { IStatistic } from 'components/molecules/StatisticsSlider/data';

interface GroupsProps {
  statistics: IStatistic[];
}

const Groups: FC<GroupsProps> = ({ statistics }) => {
  return (
    <Box>
      <Typography mb='1rem' fontSize='1.4rem' lineHeight='1.8rem'>
        První tři nejbližší skupiny
      </Typography>
      {statistics.sort((a, b) => a.value < b.value ? 1 : -1).slice(0, 3).map((v, i) => (
        <Flex
          mt='0.2rem'
          px='1.1rem'
          alignItems='center'
          justifyContent='center'
          borderRadius='2px'
          // bg={'rgba(0, 101, 69, 1)'}
          bg={'rgb(63, 143, 116)'}
          minHeight='3.2rem'
          key={i}
        >
          <Typography fontSize='1.2rem' lineHeight='1.5rem' color='rgba(255, 255, 255, 1)'>
            {i + 1}.
          </Typography>
          <Typography fontSize='1.3rem' lineHeight='1.6rem' width='100%' color='rgba(255, 255, 255, 1)'>
            {v.perex ?  `${v.title}, ${v.perex}` : v.title}
          </Typography>
        </Flex>
      )
      )}

      <Typography mt='2rem' mb='1rem' fontSize='1.4rem' lineHeight='1.8rem'>
        Nejvzdálenější skupiny
      </Typography>

      {statistics.sort((a, b) => a.value > b.value ? 1 : -1).slice(0, 3).map((v, i) => (
        <Flex
          mt='0.2rem'
          px='1.1rem'
          alignItems='center'
          justifyContent='center'
          borderRadius='2px'
          bg='rgba(10, 65, 116, 1)'
          minHeight='3.2rem'
          key={i}
        >
          <Typography fontSize='1.2rem' lineHeight='1.5rem' color='rgba(255, 255, 255, 0.5)'>
            {i + 1}.
          </Typography>
          <Typography fontSize='1.3rem' lineHeight='1.6rem' width='100%'>
            {v.perex ? `${v.title}, ${v.perex}` : v.title}
          </Typography>
        </Flex>
      )
      )}

    </Box>
  );
};

export default Groups;
