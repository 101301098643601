import * as React from 'react';

const SVGok: React.FC = () => {
  return (
    <svg width='17px' height='13px' viewBox='0 0 17 13' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' opacity='0.797600446'>
        <g transform='translate(-877.000000, -727.000000)' fill='#FFFFFF' fillRule='nonzero'>
          <g transform='translate(60.000000, 135.000000)'>
            <g transform='translate(450.000000, 153.000000)'>
              <g>
                <g transform='translate(15.000000, 425.000000)'>
                  <g transform='translate(340.000000, 0.000000)'>
                    <polygon points='17.4852814 26.8994949 16.0710678 25.4852814 12 21.4142136 13.4142136 20 17.4852814 24.0710678 27.5563492 14 28.9705627 15.4142136 18.8994949 25.4852814' />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SVGok;
