import React, { FC } from 'react';
import { Box, Button, Flex, Heading2, Typography } from '@xcorejs/ui';
import { PremiumButton } from 'components/components/Button';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { createUrl } from 'components/pages/routes';

const SuccessLadders: FC = () => {
  return (
    <Flex flexDirection='column' alignItems='center' py='8rem'>
      <PremiumButton />

      <Heading2
        as='h1'
        textAlign='center'
        mt='3rem'
        maxWidth='64rem'
      >
        <FormattedMessage id='shop.buy.successLadders.congrats' />{' '}
      </Heading2>

      <Box maxWidth='63rem' mx='auto'>
        <Typography color='#f4f4f6' mt='6rem'>
          <FormattedMessage id='shop.buy.successLadders.text1' />
        </Typography>
      </Box>

      <Flex justifyContent='center' mt='4rem'>
        <Link to={createUrl('app.journal')}>
          <Button
            as='span'
            s='lg'
            fontSize={{ _: '2rem', sm: '2.2rem' }}
            lineHeight={{ _: '2.2rem', sm: '2.8rem' }}
            letterSpacing='0.5px'
            px='5rem'
            py='1.9rem'
            height='6rem'
          >
            <FormattedMessage id='shop.buy.successLadders.button' />
          </Button>
        </Link>
      </Flex>
    </Flex>
  );
};

export default SuccessLadders;
