import { IStringMap } from '../../../../../utils';
import { IValueTestResultDetail, ICharacter, IValueResult } from 'config/api/valueTestResult';
import { ISurvey, ITestTemplate } from '../../../Sandbox/TestFactory';
import { CardColor } from 'components/atoms/Cards';
import { IThemeProps } from 'components/templates/General/ThemeContext';
import { IColorTheme, BlueTheme, MidnightTheme, GrayTheme, LeastStatisticsTheme, MiddleStatisticsTheme } from 'components/theme/colors';

export interface ITestCompare {
  testConfig: ITestConfig;
  profiles: IStringMap<IProfile>;
  valueTestCompares: IValueTestCompare;
  valueTestResults: IStringMap<IValueTestResultDetail>;
  surveyResults: ISurveyCompare[];
  valuesMerged: IValueResult[];
  finishedAt: number;
}

interface IBullet {
  text: string;
  priority: number;
}

export interface IValueTestCompare {
  valueMatchAList: string[];
  valueMatchBList: string[];
  valueMatchCList: string[];
  valueMatchDList: string[];
  characterCharacteristics: IBullet[];
  practicalCharacteristics: IBullet[];
  characterResults: ICharacter[];
  practicalResults: ICharacter[];
  valueMatch: number;
  characterMatch: number;
  practicalMatch: number;
}

export interface IProfile {
  id: string;
  fullname: string;
  nick: string;
  sex: number;
  internalId: number;
  avatar: string;
  country: ICountry;
  eticon: IEticon;
}

export interface ITestConfig {
  id: string;
  template: ITestTemplate;
  surveys: ISurvey[];
  invitedFrom?: string;
  note: string;
}

interface IEticon {
  id: string;
  name: string;
  icon: string;
  avatar: string;
}

interface ICountry {
  id: string;
  name: string;
}

export interface ISurveyCompare {
  surveyId: string;
  surveyName: string;
  priority: number;
  questions: SurveyQuestion[];
}

export interface ISurveyAnswer {
  id: string;
  text?: string;
  image?: string;
  value?: string;
  d21Minus?: boolean;
}

export type SurveyQuestion = ISurveyQuestion<IStringMap<ISurveyAnswer[]>>;

interface PublicSurveyResult {
  id: string;
  text: string;
  popularity: number;
  plus: number;
  minus: number;
}

export interface ISurveyQuestion<T> {
  id: string;
  surveyId: string;
  text: string;
  answerType: 'text' | 'image' | 'value' | 'free' | 'd21plus' | 'd21minus';
  answers: T;
  value: string;
  minValue: number;
  minText: string;
  maxValue: number;
  maxText: string;
  priority: number;
  skipped: boolean;
  publicResults: PublicSurveyResult[];
}

export type AllValuesSettingProps = {
  defaultColor: string;
  colors: {
      rangeTo: number;
      color: CardColor;
      visibilityWithoutPlus: boolean;
      afterOpacity?: number;
  }[];
}

export const AllValuesSetting: AllValuesSettingProps = {
  defaultColor: 'green',
  colors: [
    { rangeTo: 10, color: 'green' as CardColor, visibilityWithoutPlus: true },
    {
      rangeTo: 20,
      color: 'blue' as CardColor,
      afterOpacity: 0.25,
      visibilityWithoutPlus: false
    },
    {
      rangeTo: 30,
      color: 'blue' as CardColor,
      afterOpacity: 0.15,
      visibilityWithoutPlus: false
    },
    {
      rangeTo: 42,
      color: 'blue' as CardColor,
      afterOpacity: 0,
      visibilityWithoutPlus: false
    },
  ],
};

export type AllValuesSettingBetaProps = {
  defaultColor: string;
  colors: {
      rangeTo: number;
      theme: IColorTheme;
      visibilityWithoutPlus: boolean;
      afterOpacity?: number;
  }[];
}

export const AllValuesSettingsBeta = (theme: IColorTheme): AllValuesSettingBetaProps => ({
  defaultColor: 'green',
  colors: [
    { rangeTo: 10, theme: theme, visibilityWithoutPlus: true },
    {
      rangeTo: 20,
      theme: { ...BlueTheme, text: 'rgba(255, 255, 255, 0.7)' },
      afterOpacity: 0.25,
      visibilityWithoutPlus: false
    },
    {
      rangeTo: 30,
      theme: { ...BlueTheme, text: 'rgba(255, 255, 255, 0.7)' },
      afterOpacity: 0.15,
      visibilityWithoutPlus: false
    },
    {
      rangeTo: 42,
      theme: LeastStatisticsTheme,
      afterOpacity: 0,
      visibilityWithoutPlus: false
    },
  ],
});

export const TogetherValuesSetting = {
  defaultColor: 'blue',
  colors: [
    { rangeTo: 10, color: 'bordered' as CardColor },
    { rangeTo: 20, color: 'blue' as CardColor },
    { rangeTo: 30, color: 'blue' as CardColor },
    { rangeTo: 42, color: 'blue' as CardColor },
  ],
};
