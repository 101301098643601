import * as React from 'react';
import { StyledClickableCard } from '../ValueCard/styles';

interface IClickableCard {
  onClick: () => any;
  preventClick?: boolean;
}

interface IState {
  touchMoving: boolean;
}

class ClickableCard extends React.Component<IClickableCard, IState> {
  public state: IState = { touchMoving: true };
  public render() {
    const { onClick, preventClick, children } = this.props;

    return (
      <StyledClickableCard
        onTouchStart={() => this.setState({ touchMoving: false })}
        onTouchEnd={() => !preventClick && !this.state.touchMoving && onClick()}
        onTouchMove={() => this.setState({ touchMoving: true })}
        onDoubleClick={onClick}
      >
        {children}
      </StyledClickableCard>
    );
  }
}

export default ClickableCard;
