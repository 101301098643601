import { IQuestionHelp } from 'components/organisms/Test/Question/data';
import { ColoredThemes, GreenTheme } from 'components/theme/colors';
import * as React from 'react';
import { Box, Button, Flex, Heading3, Img, Link, Modal, Typography, useModal } from '@xcorejs/ui';
import { FC } from 'react';

interface SurveyHelpModalProps {
  help: IQuestionHelp;
  theme: string;
}

const SurveyHelpModal: FC<SurveyHelpModalProps> = ({ help, theme }) => {
  const [close] = useModal(null);

  return (
    <Modal
      padding={0}
      width='41rem'
      background={ColoredThemes[theme] ? ColoredThemes[theme].primary : GreenTheme.primary}
    >
      {help.image && <Img src={help.image} alt='' /> }

      <Box p='3rem' pt='2rem'>
        {help.title && (
          <Heading3>
            {help.title}
          </Heading3>
        )}

        {help.content && (
          <Typography mt='1rem'>
            {help.content}
          </Typography>
        )}

        <Flex justifyContent='center'>
          {help.linkUrl && help.linkText && (
            <Link
              href={help.linkUrl}
              v='underline'
              color='yellow'
              borderColor='yellow'
              mt='2rem'
              target='_blank'
            >
              {help.linkText}
            </Link>
          )}
        </Flex>

        <Flex mt='2rem' justifyContent='center'>
          <Button s='xs' onClick={close}>
            Zavřít okno
          </Button>
        </Flex>
      </Box>
    </Modal>
  );
};

export default SurveyHelpModal;