import * as React from 'react';
import { Scale, Scaler as StyledScaler, ScalerImgs } from './styles';
import Radio from './Radio';
import { IAnswer } from '../../../pages/App/Survey/actions';

interface IScaler {
  dynamicImg: boolean;
  image: string;
  values: IAnswer[];
  selected: string | null;
  onChange: (id: string) => any;
}

const Scaler: React.FC<IScaler> = ({ image, values, onChange, selected, dynamicImg }) => {
  const s = values.find(v => v.id === selected);

  return (
    <StyledScaler>
      {image && (
        <ScalerImgs>
          <img src={dynamicImg && s !== undefined ? s.image! : image} alt='' />
        </ScalerImgs>
      )}

      <Scale className={values.length === 10 ? 'doubleColumn' : undefined}>
        {values.map(v => (
          <div key={v.id}>
            <span>{v.text}</span>
            <Radio form='scaler' id={v.id} onChange={() => onChange(v.id)} checked={selected === v.id} />
          </div>
        ))}
      </Scale>
    </StyledScaler>
  );
};

export default Scaler;
