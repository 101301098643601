import React, { FC, useState, useEffect } from 'react';
import { useCarousel } from 'components/components/Carousel/data';
import { Flex, Typography, Img, Box } from '@xcorejs/ui';
import { Swipeable } from 'react-swipeable';
import SVGarrow from 'components/atoms/SVGIcons/SVGarrow';
import { PlusTag } from 'components/atoms/Logo';
import MultiCarousel from 'react-multi-carousel';
import { CarouselProps, CarouselStyle } from 'components/components/Carousel';
import { RequestContext } from 'components/atoms/RequestContext';

const arrowStyle = {
  cursor: 'pointer',
  opacity: 0.5,
  _hover: {
    opacity: 0.8
  },
  height: '8rem',
  transition: 'opacity 300ms',
};

export type ReducedCarouselProps = Pick<CarouselProps, 'img' | 'imgActive' | 'heading' | 'boxText' | 'isPlus' | 'link'>;

export interface FiveCarouselProps {
  incData: ReducedCarouselProps[];
  arrowColor?: string;
}

interface _FiveCarouselProps extends FiveCarouselProps {
  locale: 'cs' | 'en';
}


const FiveItemCarousel: FC<{} & FiveCarouselProps> = ({ incData, arrowColor = 'black', ...props }) => {
  return (
    <RequestContext>
      {({ locale }) => (
        <>
          <FiveCarouselMobile incData={incData} arrowColor={arrowColor} locale={locale} />
          <FiveCarouselComputer incData={incData} arrowColor={arrowColor} locale={locale} />
        </>
      )}
    </RequestContext>
  );
};

export default FiveItemCarousel;

const FiveCarouselMobile: FC<{} & _FiveCarouselProps> = ({ incData, arrowColor, locale, ...props }) => {
  const topCarousel = useCarousel();
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    topCarousel.ref.current && topCarousel.ref.current.goToSlide(2);
  }, [topCarousel.ref]);

  const data = [...incData.slice(1), ...incData.slice(0, 1)];
  
  const updateActiveIndex = (current: number): void => {
    const dLen = data.length;
    const IndexError = 1;

    setActiveIndex(() => current % dLen + IndexError >= dLen ?
      current % dLen + IndexError - dLen === 0 ? 
        0 
        : 
        1 
      : 
      current % dLen + IndexError);
  };

  const moveCarousel = (direction: 'next' | 'prev'): void => {
    topCarousel[direction]();
  };

  const moveCarouselLogic = (index: number): void =>  {
    const lastIndex = data.length - 1;

    if (activeIndex === lastIndex && index === 0) {
      moveCarousel('next');
    }
    else if (activeIndex === 0 && index === lastIndex) {
      moveCarousel('prev');
    }
    else if (activeIndex > index) {
      moveCarousel('prev');     
    }
    else if (index > activeIndex) {
      moveCarousel('next');
    }
  };  

  const responsive = {
    sm: {
      breakpoint: { max: 1023, min: 480 },
      items: 3,
    },
    xs: {
      breakpoint: { max: 479, min: 0 },
      items: 3,
    }
  };

  return (
    <Flex
      justifyContent='center'
      alignItems='center'
      position='relative'
      width='100%'
      maxWidth='102.4rem'
      mx='auto'
      flexDirection='column'
      display={{ _: 'flex', md: 'none' }}
    >            
      <Swipeable
        onSwipedLeft={() => {
          topCarousel.next();
        }}
        onSwipedRight={() => {
          topCarousel.prev();
        }}
        style={{ width: '100%' }}
      >
        <CarouselStyle maxWidth='68rem' mx='auto' {...props}>
          <MultiCarousel
            ssr
            swipeable={false}
            draggable={false}
            keyBoardControl={false}
            responsive={responsive}
            ref={topCarousel.ref}
            containerClass='react-multi-carousel-list-desktop'
            infinite
            afterChange={(previousSlide, { currentSlide }) => {
              updateActiveIndex(currentSlide);
            }}
          >
            {data.map((d, index) => (
              <Flex
                alignItems='center'
                justifyContent='center'
                width='100%'
                key={index}
                onClick={() => {
                  moveCarouselLogic(index);
                }}
              >
                {index === activeIndex ? 
                  (
                    <Img
                      src={`/img/presenting/${d.imgActive}`}
                      display='block'
                      width={{ _: '10.4rem', sm: '11.1rem' } as any}
                      mx='auto'
                      alt={d.heading[locale]}
                    />
                  ) : (
                    <Img
                      src={`/img/presenting/${d.img}`}
                      display='block'
                      width={{ _: '7.8rem', sm: '8.4rem' } as any}
                      mx='auto'
                      alt={d.heading[locale]}
                    />
                  )}
              </Flex>
            )
            )}
          </MultiCarousel>
        </CarouselStyle>

        <Flex alignItems='center' position='relative' width='100%' mt='4rem'>
          <Flex flexDirection='column' alignItems='center' justifyContent='center' width='100%'>
            <Typography fontSize={{ _: '2.4rem', md: '2.8rem' }} lineHeight='3rem' textAlign='center' fontFamily='Oli Grotesk Light'>
              <div dangerouslySetInnerHTML={{ __html: data[activeIndex].heading[locale] }} />
            </Typography>

            <Box position='relative' mt='1rem' width={{ _: '100%', sm: 'auto' }} px='4rem'>
              <Box width='100%' mx='auto'>
                <Box
                  width='100%'
                  maxWidth='45rem'
                  mx='auto'
                >
                  <Typography
                    color={{ _: '#3C3C3C' } as any}
                    fontSize={{ _: '1.4rem', sm: '1.5rem' }}
                    height='110px'
                  >
                    <div dangerouslySetInnerHTML={{ __html: data[activeIndex].boxText[locale] }} />
                  </Typography>
                </Box>
              </Box>

              <Flex
                position='absolute'
                width={{ _: 'calc(100% + 2rem)', sm: 'calc(100% + 12rem + 8.6rem)' }}
                top='50%'
                left='50%'
                transform='translateX(-50%) translateY(-50%)'
                alignItems='center'
                justifyContent='space-between'
              >
                <Box
                  onClick={() => moveCarousel('prev')}
                  {...arrowStyle}
                >
                  <SVGarrow {...{ style: { height: '100%' } } as any} stroke={arrowColor} />
                </Box>

                <Box
                  onClick={() => moveCarousel('next')}
                  transform='rotate(180deg)'
                  {...arrowStyle}
                >
                  <SVGarrow {...{ style: { height: '100%' } } as any} stroke={arrowColor} />
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Flex>

        <Flex mt={{ _: '3rem', sm: '3rem' }} justifyContent='center'>
          <Box position='relative' id='plus-tag-wrapper'>

            {data[activeIndex].isPlus && (
              <PlusTag position='absolute' transform='translateX(-50%) translateY(-30%)'>
                Eticia Plus
              </PlusTag>
            )}
          </Box>
        </Flex>
      </Swipeable>
    </Flex>
  );
};


const FiveCarouselComputer: FC<{} & _FiveCarouselProps> = ({ incData, arrowColor, locale, ...props }) => {
  const topCarousel = useCarousel();
  const [activeIndex, setActiveIndex] = useState(0);
  const data = incData;
    
  const updateActiveIndex = (current: number): void => {
    const dLen = data.length;
    const IndexError = 2;
  
    setActiveIndex(() => current % dLen + IndexError >= dLen ?
      current % dLen + IndexError - dLen === 0 ? 
        0 
        : 
        1 
      : 
      current % dLen + IndexError);
  };
  
  const moveCarousel = (direction: 'next' | 'prev', amount: 1 | 2 = 1): void => {
    topCarousel[direction]();
    amount === 2 && setTimeout(() => topCarousel[direction](), 500);
  };
  
  const moveCarouselLogic = (index: number): void =>  {
    const lastIndex = data.length - 1;
  
    if (activeIndex === data.length - 2 && index === 0 || activeIndex === lastIndex && index === 1) {
      moveCarousel('next', 2);
    }
    else if (activeIndex === lastIndex && index === 0) {
      moveCarousel('next');
    }
    else if (activeIndex === 0 && index === data.length - 2 || activeIndex === 1 && index === lastIndex) {
      moveCarousel('prev', 2);
    }
    else if (activeIndex === 0 && index === lastIndex) {
      moveCarousel('prev');
    }
    else if (activeIndex > index) {
      activeIndex - 1 === index ? moveCarousel('prev') :  moveCarousel('prev', 2);      
    }
    else if (index > activeIndex) {
      activeIndex + 1 === index ? moveCarousel('next') : moveCarousel('next', 2);
    }
  };
  
  useEffect(() => {
    topCarousel.ref.current && topCarousel.ref.current.goToSlide(2);
  }, [topCarousel.ref]);
  
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
  };
  
  return (
    <Flex
      justifyContent='center'
      alignItems='center'
      position='relative'
      width='100%'
      maxWidth='102.4rem'
      mx='auto'
      flexDirection='column'
      display={{ _: 'none', md: 'flex' }}
    >            
      <Swipeable
        onSwipedLeft={() => {
          topCarousel.next();
        }}
        onSwipedRight={() => {
          topCarousel.prev();
        }}
        style={{ width: '100%' }}
      >
        <CarouselStyle maxWidth='68rem' mx='auto' {...props}>
          <MultiCarousel
            ssr
            swipeable={false}
            draggable={false}
            keyBoardControl={false}
            responsive={responsive}
            ref={topCarousel.ref}
            containerClass='react-multi-carousel-list-desktop'
            infinite
            afterChange={(previousSlide, { currentSlide }) => {
              updateActiveIndex(currentSlide);
            }}
          >
            {data.map((d, index) => (
              <Flex
                alignItems='center'
                justifyContent='center'
                width='100%'
                key={index}
                onClick={() => {
                  moveCarouselLogic(index);
                }}
              >
                {index === activeIndex ? 
                  (
                    <Img
                      src={`/img/presenting/${d.imgActive}`}
                      display='block'
                      width={{ _: '10.4rem', sm: '11.1rem' } as any}
                      mx='auto'
                      alt={d.heading[locale]}
                    />
                  ) : (
                    <Img
                      src={`/img/presenting/${d.img}`}
                      display='block'
                      width={{ _: '7.8rem', sm: '8.4rem' } as any}
                      mx='auto'
                      alt={d.heading[locale]}
                    />
                  )}
              </Flex>
            )
            )}
          </MultiCarousel>
        </CarouselStyle>
  
        <Flex alignItems='center' position='relative' width='100%'>
          <Flex flexDirection='column' alignItems='center' justifyContent='center' width='100%'>
            <Typography fontSize={{ _: '1.8rem', sm: '2rem' }} lineHeight='3rem' textAlign='center'>
              <div dangerouslySetInnerHTML={{ __html: data[activeIndex].heading[locale] }} />
            </Typography>
  
            <Box position='relative' mt='3rem' width={{ _: '100%', sm: 'auto' }} px='4rem'>
              <Box width='100%' mx='auto'>
                <Box
                  width='100%'
                  maxWidth='45rem'
                  mx='auto'
                >
                  <Typography
                    color={{ _: '#3C3C3C' } as any}
                    fontSize={{ _: '1.4rem', sm: '1.5rem' }}
                    minHeight='66px'
                  >
                    <div dangerouslySetInnerHTML={{ __html: data[activeIndex].boxText[locale] }} />
                  </Typography>
                </Box>
              </Box>
  
              <Flex
                position='absolute'
                width={{ _: 'calc(100% + 2rem)', sm: 'calc(100% + 12rem + 8.6rem)' }}
                top='50%'
                left='50%'
                transform='translateX(-50%) translateY(-50%)'
                alignItems='center'
                justifyContent='space-between'
              >
                <Box
                  onClick={() => moveCarousel('prev')}
                  {...arrowStyle}
                >
                  <SVGarrow {...{ style: { height: '100%' } } as any} stroke={arrowColor} />
                </Box>
  
                <Box
                  onClick={() => moveCarousel('next')}
                  transform='rotate(180deg)'
                  {...arrowStyle}
                >
                  <SVGarrow {...{ style: { height: '100%' } } as any} stroke={arrowColor} />
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Flex>
  
        <Flex mt={{ _: '3rem', sm: '3rem' }} justifyContent='center'>
          <Box position='relative' id='plus-tag-wrapper'>
  
            {data[activeIndex].isPlus && (
              <PlusTag position='absolute' transform='translateX(-50%) translateY(-110%)'>
                  Eticia Plus
              </PlusTag>
            )}
          </Box>
        </Flex>
      </Swipeable>
    </Flex>
  );
};

  