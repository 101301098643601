import styled, { css } from 'styled-components';
import { whiteFade } from '../../theme/colors';
import { mediaMdMax } from '../../theme/grid';

export const StyledShowMenuButton = styled.span<{ sideMenuIsOpen?: boolean }>`
  display: inline-block;
  vertical-align: middle;
  margin-top: 0;
  margin-right: 2rem;
  cursor: pointer;

  span {
    display: block;
    margin-top: 0.6rem;
    width: 3rem;
    height: 0.2rem;
    border-radius: 0.2rem;
    background: ${whiteFade};
    transition: margin-top 0.3s;

    &:first-child {
      margin-top: 0;
    }
  }

  ${mediaMdMax} {
    margin-right: 1rem;

    span {
      margin-top: 0.4rem;
      width: 2rem;
      background: #fff;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  ${props =>
    props.sideMenuIsOpen &&
    css`
      span {
        margin-top: 0.7rem;

        &:first-child {
          margin-top: 0;
        }

        ${mediaMdMax} {
          margin-top: 0.5rem;

          &:first-child {
            margin-top: 0;
          }
        }
      }
    `}
`;
