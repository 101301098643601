import * as React from 'react';
import withUser, { IWithUser } from '../withRedux/withUser';
import { FC } from 'react';
import FAQPopup from '../../atoms/Popups/FAQPopup';
import { EPremium } from '../../atoms/Popups/FAQPopup/popups';
import { StyledStatisticsItemBadge } from './styles';
import { LevelPopups } from '../../organisms/NotificationFetcher/component';

interface StatisticsItemBadgeProps {
  variant: 'level' | 'premium';
  level?: number;
}

const StatisticsItemBadge: FC<StatisticsItemBadgeProps & IWithUser> = ({ variant, user, level }) =>
  variant === 'level' ? (
    <StyledStatisticsItemBadge>
      <FAQPopup popup={LevelPopups[user.level < LevelPopups.length ? user.level - 1 : LevelPopups.length - 1]}>
        Level {level}
      </FAQPopup>
    </StyledStatisticsItemBadge>
  ) : (
    <StyledStatisticsItemBadge premium={true}>
      <FAQPopup popup={EPremium}>Premium</FAQPopup>
    </StyledStatisticsItemBadge>
  );

export default withUser(StatisticsItemBadge);
