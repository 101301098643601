import {
  Box,
  Modal,
} from '@xcorejs/ui';
import { BlueTheme } from 'components/theme/colors';
import { FC } from 'react';
import * as React from 'react';
import styled from 'styled-components';

const LifeValuesPresentingModal: FC = () => {

  return (
    <Modal
      background={BlueTheme.primary}
      p={{ _: '0', sm: '0' }}
      overflowX='hidden'
      height='auto'
      _close={{
        zIndex: 100,
        position: 'absolute'
      }}
    >
      <BoxStyle>
        <iframe
          src='/cs/presenting?id=42'
          width='100%'
          height='100%'
          frameBorder='0'
        />
      </BoxStyle>
    </Modal>
  );
};

export default LifeValuesPresentingModal;

const BoxStyle = styled(Box)`
  iframe {
    margin-top: -6rem;
    height: 100vh;
    max-height: calc(100vh - 6rem);
  }
`;