import { Button, Flex, FlexProps, Heading2, Heading3, Heading4, Img, Link, Link as UILink, Modal, Typography, useModal } from '@xcorejs/ui';
import { GameContext } from 'components/App';
import LoadingSpinnerLocal from 'components/atoms/Animations/LoadingSpinnerLocal';
import useFetch from 'components/atoms/Fetch/useFetch';
import { FreeTag, PlusTag } from 'components/atoms/Logo';
import { RequestContext } from 'components/atoms/RequestContext';
import { TestTypeEnum } from 'components/organisms/Test/data';
import { IJournal } from 'components/pages/App/TestIntro/data';
import { createUrl } from 'components/pages/routes';
import { BlueTheme } from 'components/theme/colors';
import { OliLight } from 'components/theme/fonts';
import { JOURNAL } from 'config/apiRoutes';
import * as React from 'react';
import { FC, Fragment, useCallback } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Modify } from 'utils/modify';
import { LocalizedString } from '../Carousel';
import EticiaPlusModalNew from './EticiaPlusModalNew';
import PresentingPageModal from './PresentingPageModal';

interface AllLaddersDataProps {
  heading: LocalizedString;
  background: string;
  ladders: LadderProps[];
}

interface LadderProps {
  background: string;
  icon: string;
  heading: LocalizedString;
  text: LocalizedString;
  presentingId: string;
  plus: boolean;
  mailto?: string;
  testLink?: string;
  presentingTestLink?: string;
}

export const AllLaddersData: AllLaddersDataProps[] = [
  {
    heading: {
      cs: 'Hodnoty & Já',
      en: 'Values & Me'
    },
    background: 'rgba(77, 106, 161, 1)',
    ladders: [
      {
        background: 'rgba(255, 255, 255, 0.1)',
        icon: '/img/presenting/zivot_80.png',
        heading: {
          cs: 'Životní hodnoty',
          en: 'Life values'
        },
        text: {
          cs: 'Svět se mění a my s ním. Sleduj vývoj svých osobních hodnot dlouhodobě. Každoroční aktualizaci svého hodnotového žebříčku získáš v Eticia zdarma.',
          en: 'The world is changing and so are we. Monitor the development of your personal values in the long term. You will get an annual update of your value ladder for free in Eticia.'
        },
        presentingId: '31',
        presentingTestLink: TestTypeEnum.INTRO,
        plus: false
      },
      {
        background: 'transparent',
        icon: '/img/presenting/spokojenost_80.png',
        heading: {
          cs: 'Moje spokojenost',
          en: 'My satisfaction',
        },
        text: {
          cs: 'Jak být spokojený? Může to být překvapivě jednoduché. Tento žebříček ti pomůže najít hodnoty, které jsou pro tebe zdrojem štěstí a pohody.',
          en: 'How to be satisfied? It can be surprisingly simple. This ladder will help you find values that are a source of happiness and comfort for you.'
        },
        presentingId: '24',
        testLink: TestTypeEnum.MOJE_SPOKOJENOST,
        plus: true
      },
      {
        background: 'rgba(11, 12, 28, 0.15)',
        icon: '/img/presenting/prave_ted_80.png',
        heading: {
          cs: 'Hodnoty právě teď',
          en: 'Values right now'
        },
        text: {
          cs: 'Co je teď podstatné? Každá chvíle a situace si žádá své. Najdi hodnoty, které jsou pro tebe důležité právě v tomto okamžiku. Ať už je jaký chce…',
          en: 'What is essential now? Every moment and situation requires its own. Find the values that are important to you right now. No matter what…'
        },
        presentingId: '22',
        testLink: TestTypeEnum.HODNOTY_PRAVE_TED,
        plus: true
      },
      {
        background: 'rgba(11, 12, 28, 0.3)',
        icon: '/img/presenting/ma_prani_80.png',
        heading: {
          cs: 'Má přání',
          en: 'My wishes'
        },
        text: {
          cs: 'Čeho si přeješ v životě víc? Všechna tvá přání jsou důležitá. Ať už jsou splnitelná, a nebo ne. Tento žebříček ti pomůže zjistit, co doopravdy chceš a proč.',
          en: 'What do you wish for more in life? All your wishes are important. Whether they are achievable or not. This ladder will help you find out what you really want and why.'
        },
        presentingId: '23',
        plus: true
      }
    ]
  },
  {
    heading: {
      cs: 'Vztahy a partnerství',
      en: 'Relationships and partnerships'
    },
    background: 'rgba(166, 66, 99, 1)',
    ladders: [
      {
        background: 'rgba(255, 255, 255, 0.1)',
        icon: '/img/presenting/ideal_partner80.png',
        heading: {
          cs: 'Ideální partner',
          en: 'Ideal partner'
        },
        text: {
          cs: 'Jaké hodnoty by měl podle tebe mít? Abys mohl svůj ideál potkat, musíš nejdřív své představy o něm správně pojmenovat.',
          en: 'What values should he have according to you? In order to meet your ideal, you must first name your ideas about him correctly.'
        },
        presentingId: '25',
        testLink: TestTypeEnum.IDEALNI_PARTNER,
        plus: true
      },
      {
        background: 'transparent',
        icon: '/img/presenting/partak_80.png',
        heading: {
          cs: 'Nejlepší parťák',
          en: 'Best buddy'
        },
        text: {
          cs: 'Jaké hodnoty by měl mít člověk, se kterým se pustíš do veliké věci? Volba správného parťáka je někdy náročný úkol a “krok vedle” může mít nedozírné následky.',
          en: 'What values should a person have with whom you embark on a great thing? Choosing the right partner is sometimes a difficult task and a "step aside" can have unforeseen consequences.'
        },
        presentingId: '39',
        testLink: TestTypeEnum.NEJLEPSI_PARTAK,
        plus: true
      },
      {
        background: 'rgba(11, 12, 28, 0.15)',
        icon: '/img/presenting/ocenuji_80.png',
        heading: {
          cs: 'Co na tobě oceňuji',
          en: 'What I appreciate about you'
        },
        text: {
          cs: 'Ukaž druhým, co máš na nich rád a proč je ti s nimi dobře. Tento žebříček ti pomůže si dobré stránky svých bližních lépe uvědomit. A když budeš chtít, můžeš své “ocenění” dotyčné osobě i poslat.',
          en: 'Show others what you like about them and why you feel good with them. This ladder will help you better realize the good sides of your loved ones. And if you want, you can also send your "appreciation" to the person concerned.'
        },
        presentingId: '37',
        testLink: TestTypeEnum.CO_NA_TOBE_OCENUJI,
        plus: true
      },
      {
        background: 'rgba(11, 12, 28, 0.3)',
        icon: '/img/presenting/baj_vztah80.png',
        heading: {
          cs: 'Báječný vztah',
          en: 'Wonderful relationship'
        },
        text: {
          cs: 'Co očekáváš od partnerství? Vědět, jaké vztahy toužíme budovat, je tím prvním krokem k tomu, aby se nám to dařilo.',
          en: 'What do you expect from a partnership? Knowing what relationships we want to build is the first step to making it work.'
        },
        presentingId: '26',
        plus: true
      }
    ]
  },
  {
    heading: {
      cs: 'Práce, spolupráce',
      en: 'Work, cooperation'
    },
    background: 'rgba(114, 113, 138, 1)',
    ladders: [
      {
        background: 'rgba(255, 255, 255, 0.1)',
        icon: '/img/presenting/vysnena_prace_80.png',
        heading: {
          cs: 'Má vysněná práce',
          en: 'My dream job'
        },
        text: {
          cs: 'Jaké hodnoty by měla naplňovat práce, kterou doopravdy miluješ? Prací trávíme hromadu času. A měla by nás bavit.',
          en: 'What values should the work you really love fulfill? We spend a lot of time working. And it should entertain us.'
        },
        presentingId: '28',
        testLink: TestTypeEnum.MA_VYSNENA_PRACE,
        plus: true
      },
      {
        background: 'transparent',
        icon: '/img/presenting/superschopnosti_80.png',
        heading: {
          cs: 'Superschopnosti',
          en: 'Superpowers'
        },
        text: {
          cs: 'Je na čase objevit své skryté vlohy. Každý v něčem vyniká. Znáš dobře své silné stránky? Tohle není klasický hodnotový žebříček, ale ocenit občas sám sebe je velmi důležité!',
          en: 'It is time to discover your hidden talents. Everyone excels in something. Do you know your strengths well? This is not a classic value ladder, but appreciating yourself occasionally is very important!'
        },
        presentingId: '35',
        testLink: TestTypeEnum.MOJE_SUPERSCHOPNOSTI_HRA,
        plus: true
      },
      {
        background: 'rgba(11, 12, 28, 0.15)',
        icon: '/img/presenting/bliz_k_cili_80.png',
        heading: {
          cs: 'Blíž k cíli',
          en: 'Closer to the goal'
        },
        text: {
          cs: 'Jaké hodnoty ti pomohou dosáhnout tvých cílů? Objevuj ty, o které se můžeš na cestě za svým snem opřít. Vhodné i pro týmy.',
          en: 'What values will help you achieve your goals? Discover those that you can rely on in your dream journey. Suitable for teams too.'
        },
        presentingId: '27',
        plus: true
      },
      {
        background: 'rgba(11, 12, 28, 0.3)',
        icon: '/img/presenting/hodnoty_projektu80.png',
        heading: {
          cs: 'Hodnoty projektu',
          en: 'Project values'
        },
        text: {
          cs: 'Nech svůj projekt vyrůst na těch správných základech. Hodnoty, které v něm budou hrát nejdůležitější roli, můžeš objevovat sám za sebe, nebo je hledat společně s těmi, kteří se na něm podílejí.',
          en: 'Let your project grow on the right foundations. You can discover the values that will play the most important role in it yourself, or look for them together with those who are involved in it.'
        },
        presentingId: '38',
        plus: true
      }
    ]
  },
  {
    heading: {
      cs: 'Děti a rodiče',
      en: 'Children and parents'
    },
    background: 'rgba(62, 131, 143, 1)',
    ladders: [
      {
        background: 'rgba(255, 255, 255, 0.1)',
        icon: '/img/presenting/deti_80.png',
        heading: {
          cs: 'Hodnoty pro děti',
          en: 'Values for children'
        },
        text: {
          cs: 'Děti hodnoty milují. Pro uživatele do 16 let nabízí Eticia speciální, upravenou verzi žebříčku. Své hodnoty mohou děti objevovat samostatně nebo v rámci školní výuky apod.',
          en: 'Children love values. Eticia offers a special, adapted version of the ladder for users under 16. Children can discover their values independently or as part of school education, etc.'
        },
        presentingId: '30',
        plus: false
      },
      {
        background: 'transparent',
        icon: '/img/presenting/deti_rodice_80.png',
        heading: {
          cs: 'Eticia s rodiči',
          en: 'Eticia with parents'
        },
        text: {
          cs: 'Objevujte hodnoty společně s vašimi dětmi. Vytvoření dětského žebříčku pod účtem rodiče v Eticia Plus nabízí navíc možnost vzájemného porovnání, které vás jistě inspiruje k zajímavým rozhovorům.',
          en: 'Discover values together with your children. Creating a child ladder under a parent account in Eticia Plus also offers the possibility of mutual comparison, which will surely inspire you to interesting conversations.'
        },
        presentingId: '30',
        plus: true
      }
    ]
  },
  {
    heading: {
      cs: 'Skupinové hodnoty',
      en: 'Group values'
    },
    background: 'rgba(83, 80, 116, 1)',
    ladders: [
      {
        background: 'rgba(255, 255, 255, 0.1)',
        icon: '/img/presenting/vize_p.png',
        heading: {
          cs: 'Ideje a vize',
          en: 'Ideas and visions'
        },
        text: {
          cs: 'Chcete se sladit ve vašich myšlenkách, ve směřování produktu či vizích? Připravíme Vám teamové míru řešení. Kontaktujte nás na ',
          en: 'Do you want to harmonize your thoughts, product direction or visions? We will prepare a team solution for you. Contact us at '
        },
        mailto: 'info@eticia21.com',
        presentingId: '',
        plus: true
      },
      {
        background: 'transparent',
        icon: '/img/presenting/skup_hod.png',
        heading: {
          cs: 'Etické kodexy',
          en: 'Ethical codes'
        },
        text: {
          cs: 'Velmi efektivní a užitečný nástroj, jak začít pracovat s vaším Etickým kodexem. Vhodné pro firmy, týmy, kolektivy. Chcete vědět více? Kontaktujte nás na ',
          en: 'A very effective and useful tool to start working with your Ethical Code. Suitable for companies, teams, collectives. Do you want to know more? Contact us at '
        },
        mailto: 'info@eticia21.com',
        presentingId: '',
        plus: true
      }
    ]
  }
];

const AllLaddersModalBeta: FC = () => {
  const [close] = useModal();
  const history = useHistory();
  const [openPresentingPageModal] = useModal(PresentingPageModal);

  const inApp = window.top.location.href.includes('/app');

  const { result, state } = useFetch<IJournal>(JOURNAL);

  const testRedirect = useCallback((testLink: string) => {
    close();
    history.push(testLink);
  }, [close, history]);

  const infoOnClick = useCallback((pageId: number) => {
    openPresentingPageModal({ pageId, loggedUser: inApp });
  }, [inApp, openPresentingPageModal]);

  if (state == 'fetching') {
    return (
      <Modal
        background={BlueTheme.primary}
        maxWidth={{ _: '37.5rem', sm: '75.4rem' }}
        p={{ _: '6rem 3.6rem', sm: '6rem 3.6rem' }}
        overflowX='hidden'
      >
        <LoadingSpinnerLocal />
      </Modal>
    );
  }

  const hasMainLadder = result ? !!result.mains.length : false;

  return (
    <Modal
      background={BlueTheme.primary}
      maxWidth={{ _: '37.5rem', sm: '75.4rem' }}
      p={{ _: '6rem 3.6rem', sm: '6rem 3.6rem' }}
      overflowX='hidden'
    >
      <Heading2>
        <FormattedHTMLMessage id='allLaddersModal.heading' />
      </Heading2>
  
      <Typography mt='3rem' color='#fff'>
        <FormattedHTMLMessage id='allLaddersModal.text' />
      </Typography>

      <Fragment>
        {AllLaddersData.map((data, i) => (
          <Fragment key={i}>
            <RequestContext>
              {({ locale }) => (
                <Fragment>
                  <Heading3 mt={i !== 0 ? '7rem' : '4rem'}>
                    {data.heading[locale]}
                  </Heading3>          
                  <Flex
                    mx={data.ladders.length === 2 ? { _: '-3.6rem', sm: 'auto' } : '-3.6rem'}
                    width={data.ladders.length === 2 ? { _: 'calc(100% + 7.2rem)', sm: 'calc(50% + 3.6rem)' } : 'calc(100% + 7.2rem)'}
                    mt='3rem'
                    flexWrap='wrap'
                    bg={data.background}
                  >
                    {data.ladders.map(ladder => (
                      <LadderCard
                        ladder={{ 
                          ...ladder,
                          heading: ladder.heading[locale],
                          text: ladder.text[locale],
                          testLink: ladder.testLink ? `${createUrl('app.gameIntro')}?t=${ladder.testLink}&redirect=1` : undefined
                        }}
                        width={{ _: '50%', sm: data.ladders.length === 2 ? '50%' : '25%' }}
                        key={ladder.heading[locale]}
                        testRedirect={testRedirect}
                        infoOnclick={() => infoOnClick(parseInt(ladder.presentingId))}
                        inApp={inApp}
                        hasMainLadder={hasMainLadder}
                      />
                    ))}
                  </Flex>
                </Fragment>
              )}
            </RequestContext>
  
          </Fragment>
        ))}
      </Fragment>;
      <Typography mt='6rem' color='rgba(255, 255, 255, 0.8)'>
        <FormattedHTMLMessage id='allLaddersModal.longTerm' />
      </Typography>
  
      <Flex justifyContent='center'>
        <Button mt='6rem' v='outline' onClick={close}>
          <FormattedMessage id='allLaddersModal.close' />
        </Button>
      </Flex>
    </Modal>
  );
};

export default AllLaddersModalBeta;

interface LadderCardProps extends FlexProps {
  ladder: Modify<LadderProps, { heading: string; text: string}>;
  testRedirect: (testLink: string) => void;
  infoOnclick: () => void;
  inApp?: boolean;
  hasMainLadder: boolean;
}

export const LadderCard: FC<LadderCardProps> = ({
  ladder,
  testRedirect,
  infoOnclick,
  inApp,
  hasMainLadder, 
  ...props }) => {
  const { eticiaPlus } = React.useContext(GameContext);
  const [openPlusModal] = useModal(EticiaPlusModalNew);
  
  return (
    <Flex
      position='relative'
      flexDirection='column'
      alignItems='center'
      px='1.6rem'
      py='3rem'
      bg={ladder.background}
      {...props}
    >          
      <Img
        src={ladder.icon}
        alt=''
        position='relative'
        cursor='pointer'
        onClick={() => {
          infoOnclick && infoOnclick();
        }} />

      <Heading4 mt='1rem' fontSize='1.8rem' lineHeight='2.4rem' mx='-0.6rem'>
        {ladder.heading}
      </Heading4>

      <Typography fontSize='1.3rem' lineHeight='1.8rem' mt='1rem'>
        {ladder.text}
        {ladder.hasOwnProperty('mailto') && <Link v='underline' href={`mailto:${ladder.mailto}`}>{ladder.mailto}</Link> }
      </Typography>

      {ladder.presentingId && (
        <UILink
          v='underline'
          mt='2rem'
          fontSize='1.3rem'
          lineHeight='1.8rem'
          color='rgba(255, 255, 255, 0.5)'
          onClick={() => {
            infoOnclick && infoOnclick();
          }}
        >
          <FormattedMessage id='allLaddersModal.moreInfo' />
        </UILink>
      )}
      {ladder.testLink && (
        <Flex
          flexGrow={1}
          alignItems='flex-end'
          position='relative'
        >
          <Button
            disabled={ladder.plus && inApp && !eticiaPlus}
            fontSize='1.3rem'
            fontFamily={OliLight}
            color='rgba(255, 255, 255, 0.7)'
            background='rgba(11, 12, 28, 0.3)'
            border='2px solid #000D'
            height='auto'
            padding='0.5rem 1.5rem'
            marginTop='2rem'
            onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
              e.preventDefault();
              testRedirect(ladder.testLink!);
            }}
          >
            <FormattedMessage id='allLaddersModal.runTest' />
          </Button>

          {(!eticiaPlus || !inApp) && (
            <PlusTag position='absolute' bottom='-1.5rem' right='-0.5rem' transform='scale(0.8)' onClick={() => openPlusModal({ inApp })}>
            Plus
            </PlusTag>
          )}
        </Flex>
      )}

      {/* 
        zobrazit button v případě:
          uživatel není v aplikaci  
          uživatel je v aplikaci ale nemá hlavní žebříček
      */}
      {ladder.presentingTestLink && (!inApp || !hasMainLadder) && (
        <Flex
          flexGrow={1}
          alignItems='flex-end'
          position='relative'
        >
          <Button
            fontSize='1.3rem'
            fontFamily={OliLight}
            color='rgba(255, 255, 255, 0.7)'
            background='rgba(11, 12, 28, 0.3)'
            border='2px solid #000D'
            height='auto'
            padding='0.5rem 1.5rem'
            marginTop='2rem'
            onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
              e.preventDefault();
              window.top.location.href = inApp ? createUrl('app.gameIntro') + `?t=${ladder.presentingTestLink}` : createUrl('register');
            }}
          >
            <FormattedMessage id='allLaddersModal.runTest' />
          </Button>

          <FreeTag position='absolute' bottom='-1.5rem' right='-0.5rem' transform='scale(0.8)'>
            Free
          </FreeTag>
        </Flex>
      )}
    </Flex>
  );
};
