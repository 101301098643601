import { IValueTestResultDetail } from 'config/api/valueTestResult';
import * as React from 'react';
import ValueDecs from '../../../../../molecules/Results/ValueDecs';
import { Heading3, Paragraph } from '@xcorejs/ui';
import { FC } from 'react';

interface ResultEticon {
  result: IValueTestResultDetail;
  heading: string | null;
  description: string | null;
}

const ResultEticon: FC<ResultEticon> = ({ result, heading, description }) => {
  return (
    <React.Fragment>
      {heading && (
        <Heading3 mt='2rem' textAlign='center'>
          {heading}
        </Heading3>
      )}

      {description && (
        <Paragraph mt='1rem' textAlign='center'>
          {description}
        </Paragraph>
      )}

      <ValueDecs eticon={result.eticon} heading={heading} />
    </React.Fragment>
  );
};

export default ResultEticon;
