import React, { FC } from 'react';
import { Flex, Typography, Tag } from '@xcorejs/ui';
import { Link } from 'react-router-dom';
import { createUrl } from 'components/pages/routes';

interface TribalTestMissingProps {
  lighter?: boolean;
  buttonBackgroundColor: string;
}

const TribalTestMissing: FC<TribalTestMissingProps> = ({
  lighter,
  buttonBackgroundColor,
}) => {
  return ( 
    <Flex
      p='2rem'
      bg={lighter ? 'rgba(11, 12, 28, 0.2)' : 'rgba(11, 12, 28, 0.5)'}
      borderRadius='4px'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      mt='2rem'
    >
      <Typography fontSize='1.6rem' lineHeight='2rem' color='rgba(255, 255, 255, 0.8)'>
        Pro zobrazení statistik musíte mít hotový žebříček.
      </Typography>

      <Link to={createUrl('app.gameIntro') + '?t=welcome'}>
        <Tag
          mt='2rem'
          display='block'
          mx='auto'
          bg={buttonBackgroundColor}
          border={`2px solid ${buttonBackgroundColor}`}
          // bg='rgb(80, 153, 129)'
          // border='2px solid rgb(80, 153, 129)'
          py='0.4rem'
          width='24rem'
          justifyContent='center'
          whiteSpace='nowrap'
          overflow='hidden'
          textOverflow='ellipsis'
          textAlign='center'
        >
          Spustit či dokončit žebříček
        </Tag>
      </Link>
    </Flex>
  );
};
 
export default TribalTestMissing;
