import * as React from 'react';
import { Link } from 'react-router-dom';
import { createUrl } from 'components/pages/routes';
import { SecondaryButton } from '../../Buttons';
import { FormattedMessage } from 'react-intl';
import Fade from 'react-reveal/Fade';

interface IHelpPopupFooter {
  beta?: boolean;
}

const HelpPopupFooter: React.FC<IHelpPopupFooter> = ({ beta, children }) => (
  <Fade ssrReveal bottom duration={1000} distance={'5rem'}>
    {!beta ? (
      <div className='btn-wrap'>{children}</div>
    ) : (
      <div className='btn-wrap'>
        <Link to={createUrl('app.feedback.bug')}>
          <div className='btn-wrap'>
            <SecondaryButton>
              <FormattedMessage id='popup.beta.bottom.button' />
            </SecondaryButton>
          </div>
        </Link>
      </div>
    )}
  </Fade>
);

export default HelpPopupFooter;

// {!beta ? (
//   button !== "none" ? (
//     button !== null && (
//       <div className="btn-wrap" onClick={() => close()}>
//         <SecondaryButton>
//           {!button ? (
//             <FormattedMessage id="popup.default.button" />
//           ) : (
//             button
//           )}
//         </SecondaryButton>
//       </div>
//     )
//   ) : (
//     ""
//   )
// ) : (
//   <div>
//     <Link to={createUrl("app.feedback.bug")}>
//       <div className="btn-wrap" onClick={() => close()}>
//         <SecondaryButton>
//           <FormattedMessage id="popup.beta.bottom.button" />
//         </SecondaryButton>
//       </div>
//     </Link>
//   </div>
// )}
