import { IValueTestResultDetail } from 'config/api/valueTestResult';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import Fade from 'react-reveal/Fade';
import { Col, Grid, Row } from 'react-styled-flexboxgrid';
import { ILifeValue } from '../../../../../store/Entities/LifeValues';
import { IStringMap } from '../../../../../utils';
import { PrimaryButtonLink } from '../../../../atoms/Buttons';
import TestResult from '../../../../templates/TestResult';
import { createUrl } from '../../../routes';
import ResultEticon from './components/ResultEticon';
import { EticonText, StyledTestResultDefault } from './styles';
import { Flex, Tag, Typography } from '@xcorejs/ui';
import { ITestTemplate } from 'components/pages/Sandbox/TestFactory';
import { ONE_ROUND_TEST_ID } from 'components/pages/App/TestIntro/data';

interface ITestResultDefault {
  result: IValueTestResultDetail;
  values: IStringMap<ILifeValue>;
  state: any;
  template: ITestTemplate;
  resultId: string;
}

const TestResultDefault: React.FC<ITestResultDefault> = ({ result, state, template, resultId }) => {
  const nextLink = template.info.resultCharacteristicsVisible
  || template.info.resultCharacteristicsChartVisible
  || template.info.resultFigurineVisible ? '/detail' : '/values';

  return (
    <TestResult>
      <StyledTestResultDefault>
        <Fade ssrReveal bottom duration={1000} distance={'5rem'}>
          <Grid>
            <Row>
              <Col xs={12}>
                <Flex flexDirection='column' alignItems='center' mb='3rem'>
                  <Tag
                    display='block'
                    mx='auto'
                    bg='transparent'
                    border='2px solid rgba(231, 230, 207, 0.5)'
                    py='0.4rem'
                    width='24rem'
                    justifyContent='center'
                    whiteSpace='nowrap'
                    overflow='hidden'
                    textOverflow='ellipsis'
                    textAlign='center'
                  >
                    {template.name}
                  </Tag>
                </Flex>
              </Col>

              <Col xs={12} md={6}>
                <ResultEticon
                  heading={template.info.resultEticonHeader}
                  description={template.info.resultEticonDescription}
                  result={result}
                />
              </Col>

              <Col xs={12} md={6}>
                <EticonText>
                  <Typography textAlign={{ _: 'center', md: 'left' }}>
                    <div dangerouslySetInnerHTML={{ __html: result.eticon.text }} />
                  </Typography>
                </EticonText>

                {template.id === ONE_ROUND_TEST_ID ? (
                  <div className='statistics background'>
                    <strong>
                      <FormattedMessage id={'profile.default.eticon'} />
                    </strong>
                    <div className='people'>
                      {result.eticon.totalPercentageUsage.toFixed(1)}
                      {' % '}
                      <FormattedMessage id='profile.default.people' />
                    </div>

                    <div>
                      <FormattedMessage id='profile.default.ratio' />{' '}
                      <em>
                        <FormattedMessage id='profile.default.women' /> {result.eticon.femalePercentageUsage.toFixed(1)}
                        {' % '}
                        {' / '}
                        <FormattedMessage id='profile.default.men' /> {result.eticon.malePercentageUsage.toFixed(1)} %
                      </em>
                    </div>

                    <Fade ssrReveal bottom duration={1000} distance={'5rem'}>
                      <div className='btn-wrap u--mobile'>
                        <PrimaryButtonLink
                          to={{
                            pathname: createUrl('app.testResult', { id: result.id }) + nextLink,
                            state,
                          }}
                        >
                          <FormattedMessage id='results.next' />
                        </PrimaryButtonLink>
                      </div>
                    </Fade>
                  </div>
                ) : (
                  <div className='btn-wrap u--mobile'>
                    <PrimaryButtonLink
                      to={{
                        pathname: createUrl('app.testResult', { id: result.id }) + nextLink,
                        state,
                      }}
                    >
                      <FormattedMessage id='results.next' />
                    </PrimaryButtonLink>
                  </div>
                )}
              </Col>
            </Row>

            <div>
              <Row>
                <Col xs={12}>
                  <Fade ssrReveal top duration={1000} distance={'5rem'}>
                    <div className='btn-wrap u--desktop'>
                      <PrimaryButtonLink
                        to={{
                          pathname: createUrl('app.testResult', { id: resultId }) + nextLink,
                          state,
                        }}
                      >
                        <FormattedMessage id='results.next' />
                      </PrimaryButtonLink>
                    </div>
                  </Fade>
                </Col>
              </Row>
            </div>
          </Grid>
        </Fade>
      </StyledTestResultDefault>

      {/*
      <FAQPopup showOnLoad={'HowToReadResults'} popup={HowToReadResults} className={'faq-popup-button'} delay={4000}>
          <HelpLink size={3} />
        </FAQPopup>
      */}
    </TestResult>
  );
};

export default TestResultDefault;
