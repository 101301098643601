import * as React from 'react';
import { FC, ReactNode } from 'react';
import { ButtonProps, Modal as XcoreModal, ModalProps as XcoreModalProps, useModal } from '@xcorejs/ui';
import { PrimaryButton } from './Button';

type ModalProps = {
  buttonText?: string | ReactNode;
  _button?: ButtonProps;
} & XcoreModalProps;

const Modal: FC<ModalProps> = ({ buttonText, _button, children, ...props }) => {
  return (
    <XcoreModal {...props}>
      {children}

      {buttonText && (
        <PrimaryButton mt='3rem' {..._button}>
          {buttonText}
        </PrimaryButton>
      )}
    </XcoreModal>
  );
};

export default Modal;