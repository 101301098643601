import * as React from 'react';
import { IColorTheme } from '../../../theme/colors';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { BackgroundGradient } from './styles';
import { IStringMap } from '../../../../utils';

interface IBackground {
  themes: IStringMap<IColorTheme>;
  active?: string;
}

class Background extends React.Component<IBackground> {
  public render() {
    const { themes, active } = this.props;
    return (
      <TransitionGroup>
        <CSSTransition key={active} classNames='fade' timeout={1000}>
          <BackgroundGradient coloredTheme={themes[active ? active : 'blue']} />
        </CSSTransition>
      </TransitionGroup>
    );
  }
}

export default Background;
