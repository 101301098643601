import * as React from 'react';
import { FC } from 'react';
import TextBox from '../../../organisms/TextBox';
import Fade from 'react-reveal/Fade';
import { Heading2, Heading3 } from '../../../atoms/Headings';
import { FormattedMessage } from 'react-intl';
import { StyledLadders } from './styles';
import { UL1 } from '../../../organisms/VisualValuesComparison/style';
import { GiftButton, PrimaryButton } from '../../../atoms/Buttons';
import SVGgift from '../../../atoms/SVGIcons/SVGgift';
import { SectionText } from '../../../atoms/Text';
import { Box } from '@xcorejs/ui';
import FAQPopup from '../../../atoms/Popups/FAQPopup';

const Ladders: FC = () => (
  <StyledLadders>
    <Fade ssrReveal bottom duration={1000} distance='5rem'>
      <TextBox>
        <Heading2 style={{ color: '#ffde71' }}>
          <FormattedMessage id='vlc.ladders.heading' />
        </Heading2>

        <Heading2>
          <FormattedMessage id='vlc.ladders.heading2' />
        </Heading2>

        <Heading3>
          <FormattedMessage id='vlc.ladders.subheading' />
        </Heading3>

        <div className='img'>
          {/*
          <picture>
            <source media="(min-width: 768px)" srcSet="/img/vlc/vlc-ladders_desktop.png" />
            <img src="/img/vlc/vlc-ladders_mobile.png" alt="Eticia logo" />
          </picture>
          */}

          <img src='/img/osoby.png' alt='' />
        </div>

        <div className='knowledge'>
          <Heading3>
            <FormattedMessage id='vlc.ladders.knowledge' />
          </Heading3>

          <UL1>
            {[...Array(5)].map((i, index) => (
              <li className='white' key={i}>
                <span>
                  <FormattedMessage id={'vlc.ladders.list.' + index} />
                </span>
              </li>
            ))}
          </UL1>

          <div className='btn-wrap'>
            {/*
            <PrimaryButton>
              <FAQPopup popup={Voucher}>
                <FormattedMessage id='vlc.ladders.btn1' />
              </FAQPopup>
            </PrimaryButton>

            <GiftButton variant='blue'>
              <FAQPopup popup={Package}>
                <SVGgift />
                <FormattedMessage id='vlc.ladders.btn2' />
              </FAQPopup>
            </GiftButton>
            */}
          </div>

          <Box mt='2rem'>
            <SectionText opacity={true}>
              <FormattedMessage id='vlc.ladders.bottom.text' />
            </SectionText>
          </Box>
        </div>
      </TextBox>
    </Fade>
  </StyledLadders>
);

export default Ladders;
