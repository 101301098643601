import '@formatjs/intl-relativetimeformat/polyfill';
import 'intl-pluralrules';
import 'whatwg-fetch';
import '@babel/polyfill';

import React from 'react';
import { hydrate } from 'react-dom';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import configureStore from 'store/configureStore';

import App from './components/App';
import { IApplicationState } from 'store/store';
import { hydrateRequestContext, RequestContextProvider } from 'components/atoms/RequestContext';

import messagesCs from 'config/translations/locales/cs.json';
import routesCs from 'config/translations/locales/routes.cs.json';
import messagesEn from 'config/translations/locales/en.json';
import routesEn from 'config/translations/locales/routes.en.json';

const initialState = (window as any).__PRELOADED_STATE__ as IApplicationState;
const store = configureStore(initialState);

const requestContext = hydrateRequestContext();
(window as any).requestContext = requestContext;

const messages = {
  cs: {
    ...messagesCs,
    ...routesCs,
  },
  en: {
    ...messagesEn,
    ...routesEn,
  },
};

hydrate(
  <RequestContextProvider context={requestContext}>
    <Provider store={store}>
      <IntlProvider locale={requestContext.locale} messages={messages[requestContext.locale]}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </IntlProvider>
    </Provider>
  </RequestContextProvider>,
  document.getElementById('root'),
);

declare const module: NodeModule & { hot: any };

if (module.hot) {
  module.hot.accept();
}
