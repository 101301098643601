import styled, { css } from 'styled-components';
import {
  blue,
  darkBaseRGB,
  darkenBlue,
  darkenGreen,
  grayFooter,
  grayFooterRGB,
  GrayTheme,
  lightGrayBG,
  primaryBlue,
  primaryGreen,
  textGreen,
  textGreenRGB,
  yellow,
} from '../../theme/colors';
import { StyledSmallButtonLink } from '../../atoms/Buttons/styles';
import { mediaSmDefault, mediaSmMax } from '../../theme/grid';

export const StyledCircle = styled.div<{
  size?: 'big' | 'small';
  color?: 'green' | 'gray' | 'yellow' | 'blue';
}>`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 12rem;
  width: 12rem;
  border-radius: 50%;
  transition: margin 0.3s, width 0.3s, height 0.3s, border 0.3s, background 0.3s, transform 0.3s;
  overflow: hidden;
  background: transparent;

  svg {
    margin-bottom: 1rem;
    height: 4.2rem;
  }

  img {
    margin-bottom: 0.3rem;
  }

  .top {
    transition: color 0.3s;
  }

  ${props =>
    props.size === 'small' &&
    css`
      width: 9rem;
      height: 9rem;
      margin-bottom: 1.5rem;

      .small-percantage {
        margin-bottom: 0.3rem;
      }
    `};

  ${props =>
    props.size === 'big' &&
    css`
      .number {
        font-size: 2.4rem;
        line-height: 2.4rem;
      }

      .total {
        max-width: 9rem;
        margin-left: auto;
        margin-right: auto;
        color: rgba(${darkBaseRGB}, 0.8);
        font-size: 1.3rem;
        text-align: center;
        font-weight: 500;
      }

      & > div {
        position: relative;
        z-index: 1;
      }

      svg {
        g {
          opacity: 1;
        }
      }

      .description {
        font-weight: 500;
      }
    `};

  .locked {
    svg {
      height: 2.2rem;
      display: block;
      margin-bottom: 0;

      path {
        fill: rgba(0, 0, 0, 0.7);
      }
    }
  }
`;

export const StatisticItem = styled.div<{ color?: 'green' | 'gray' | 'yellow' | 'blue'; size?: 'big' | 'small'; locked?: boolean }>`
  position: relative;
  color: #fff;
  font-size: 1.3rem;
  line-height: 1.5rem;
  text-align: center;
  
  .description {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 4.5rem;
  }
  
  .iconText {
    display: block;
    color: ${grayFooter};
    max-width: 7rem;
    margin-bottom: 0.5rem;
  }
  
  .svg-bg {
    position: absolute;
    width: 10rem;
    height: 9rem;
    
    &:after {
      content: "";
      position: absolute;
      right: 1rem;
      top: 50%;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0.9rem 0 0.9rem 0.7rem;
      border-color: transparent transparent transparent ${yellow};
      transform: translateY(-50%);
      transition: left .3s, right .3s;
    }
    
    svg {
      width: 100%;
      height: 100%;
      transition: transform .3s;
      
      path {
        transition: stroke .3s;
      }
    }
  }
  
  ${props =>
    props.size === 'small' &&
    css`
      margin: 1.5rem 0.7rem;
      width: 9rem;
      height: 9rem;

      ${mediaSmDefault} {
        margin: 1.5rem;
      }
    `};
  
  ${props =>
    props.size === 'big' &&
    css`
      width: 12rem;

      .iconText {
        margin-top: 0.5rem;
        max-width: 9rem;
      }

      .icon-top {
        height: 4.2rem;

        svg {
          height: 3.2rem;
        }
      }

      ${StyledStatisticsItemBadge} {
        bottom: 7rem;
      }
    `};
  
  ${props =>
    props.locked &&
    css`
      .description {
        color: ${grayFooter};
      }

      &.big {
        ${StyledCircle} {
          position: relative;

          &:after {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            opacity: 0.5;
            background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMiIgdmlld0JveD0iMCAwIDMwIDMyIj48cGF0aCBkPSJNNCA5VjZhNiA2IDAgMSAxIDEyIDB2M2gxYTMgMyAwIDAgMSAzIDN2N2EzIDMgMCAwIDEtMyAzSDNhMyAzIDAgMCAxLTMtM3YtN2EzIDMgMCAwIDEgMy0zaDF6bTIgMGg4VjZhNCA0IDAgMSAwLTggMHYzem0tMyAyYTEgMSAwIDAgMC0xIDF2N2ExIDEgMCAwIDAgMSAxaDE0YTEgMSAwIDAgMCAxLTF2LTdhMSAxIDAgMCAwLTEtMUgzeiIgZmlsbD0iI2Y0ZjRmNiIgZmlsbC1ydWxlPSJub256ZXJvIiBvcGFjaXR5PSIuNDk1Ii8+PC9zdmc+);
          }
        }
      }
    `}
  
  ${props =>
    props.color === 'green' &&
    css`
      ${StyledCircle} {
        border: 0.3rem solid rgba(${textGreenRGB}, 0.5);

        ${props.size === 'big' &&
          css`
            background: radial-gradient(circle at top, ${primaryGreen} 0%, ${darkenGreen} 100%);

            .total {
              color: #fff;
            }
          `};

        .number {
          color: #fff;
        }
      }

      .top {
        color: ${textGreen};
      }

      .description {
        strong {
          color: ${textGreen};
        }
      }

      .small-percantage {
        color: ${textGreen};
      }

      .svg-bg {
        svg {
          path {
            stroke: rgba(${textGreenRGB}, 0.5);
          }
        }
      }
    `}
  
  ${props =>
    props.color === 'gray' &&
    css`
      ${StyledCircle} {
        border: 0.3rem solid rgba(${grayFooterRGB}, 0.25);

        ${props.size === 'big' &&
          css`
            background: radial-gradient(circle at top, ${grayFooter} 0%, ${lightGrayBG} 100%);

            .iconText {
              color: #000;
            }
          `};
      }

      .top {
        color: ${grayFooter};
      }

      .number {
        color: #000;
      }

      .small-percantage {
        color: ${grayFooter};
      }

      .svg-bg {
        svg {
          path {
            stroke: rgba(${grayFooterRGB}, 0.25);
          }
        }
      }
    `}
  
  ${props =>
    props.color === 'yellow' &&
    css`
      ${StyledCircle} {
        border: 0.3rem solid ${yellow};

        ${props.size === 'big' &&
          css`
            background: radial-gradient(circle at top, #cbcb24 0%, #b4a418 100%);
          `};

        .number {
          color: ${primaryBlue};
        }
      }

      .top {
        color: ${yellow};
      }

      .small-percantage {
        color: ${yellow};
      }

      .svg-bg {
        svg {
          path {
            stroke: ${yellow};
          }
        }
      }
    `}
  
  ${props =>
    props.color === 'blue' &&
    css`
      ${StyledCircle} {
        border: 0.3rem solid ${blue};

        ${props.size === 'big' &&
          css`
            background: radial-gradient(circle at top, ${primaryBlue} 0%, ${darkenBlue} 100%);

            .total {
              color: #fff;
            }
          `};

        .number {
          color: ${yellow};
        }
      }

      .top {
        color: #fff;
      }

      .description {
        strong {
          color: ${blue};
        }
      }

      .small-percantage {
        color: ${blue};
      }

      .svg-bg {
        svg {
          path {
            stroke: ${blue};
          }
        }
      }
    `}
`;

export const StyledStatisticsSlider = styled.div<{
  big?: boolean;
  geoInfo?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  overflow: hidden;
  user-select: none;

  .slide {
    display: flex;
    align-items: flex-start;
  }

  .before,
  .after {
    opacity: 0.5;
    transition: opacity 0.3s;
    cursor: pointer;
    margin-top: 3rem;

    svg {
      display: block;
    }

    &:hover {
      opacity: 1;
    }
  }

  .before {
    ${mediaSmDefault} {
      margin-right: 2.5rem;
    }
  }

  .after {
    transform: rotate(180deg);

    ${mediaSmDefault} {
      margin-left: 2.5rem;
    }
  }

  .big {
    margin: 0 0.75rem;

    ${mediaSmDefault} {
      margin: 0 1.5rem;
    }
  }

  .pointer {
    cursor: pointer;

    &.left {
      .svg-bg {
        svg {
          transform: rotate(180deg) translateX(10px);
        }

        &:after {
          transform: rotate(180deg);
          margin-top: -0.9rem;
          left: 0;
        }
      }
    }

    &:hover {
      &:last-child {
        .svg-bg {
          svg {
            transform: translateX(10px);
          }

          &:after {
            right: 0;
          }
        }

        ${StyledCircle} {
          transform: translateX(10px);
        }
      }

      &.left {
        &:first-child {
          .svg-bg {
            svg {
              transform: rotate(180deg) translateX(20px);
            }

            &:after {
              left: -1rem;
            }
          }

          ${StyledCircle} {
            transform: translateX(-1rem);
          }
        }
      }
    }

    ${mediaSmMax} {
      &.inBigSlider {
        display: none;
      }

      ${StyledCircle} {
        border-color: transparent;
      }
    }

    ${mediaSmDefault} {
      .svg-bg {
        display: none;
      }

      &:first-child,
      &:last-child {
        ${StyledCircle} {
          border-color: transparent;
        }

        .svg-bg {
          display: block;
        }
      }
    }
  }

  ${props =>
    props.geoInfo &&
    css`
      .slide,
      .before,
      .after {
        filter: blur(0.2rem);

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          opacity: 0.2;
        }
      }
    `}
`;

export const StyledEmptyGeoInfo = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 11;
  left: 0;
  top: 0;

  ${StyledSmallButtonLink} {
    position: absolute;
    padding: 0 4rem;
    left: 50%;
    transform: translateX(-50%);
    bottom: 3rem;
    background-color: rgba(${darkBaseRGB}, 1);
    min-width: 20rem;
    font-size: inherit;
    display: flex;
    flex-direction: column;

    span {
      &:nth-child(2) {
        margin-top: -5px;
        font-size: 1.3rem;
        opacity: 0.6;
      }
    }
  }

  p {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0rem;
  }
`;

export const StyledStatisticsItemBadge = styled.div<{ premium?: boolean }>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  height: 3rem;
  width: 10rem;
  border: 2px solid #ffde71;
  border-radius: 3.5rem;
  background: radial-gradient(circle at top, ${GrayTheme.primary} 0%, ${GrayTheme.darken} 100%);
  overflow: hidden;
  z-index: 10;

  & > .main-popup-button {
    display: block;
    position: relative;
    font-size: 1.4rem;
    line-height: 1.2rem;
    font-weight: 500;
    z-index: 1;
    margin: 0 !important;
    width: 100%;
    color: #fff !important;
  }

  &:after {
    position: absolute;
    content: "";
    background-color: rgba(25, 29, 44, 0.25);
    display: block;
    width: 100%;
    height: 100%;
  }

  ${props =>
    props.premium &&
    css`
      background: linear-gradient(180deg, #b31ce7 0%, #c56e33 100%);

      &:after {
        display: none;
      }
    `}
`;
