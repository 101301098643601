import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import canUseDOM from '../utils/canUseDOM';
import { IApplicationState, rootReducer } from './store';

const configureStore = (initialState?: IApplicationState) => {
  const composeEnhancers =
    canUseDOM && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose;

  const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(thunk)));

  return store;
};

export default configureStore;
