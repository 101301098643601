import * as React from 'react';
import { Row, Col } from 'react-styled-flexboxgrid';
import Fade from 'react-reveal/Fade';
import { ValueCardState } from '../Cards/ValueCard';
import DraggableCard from '../Cards/DraggableCard';
import { IRoundStepData } from '../data';
import { denormalize } from '../../../../../utils/helpers';
import { ConnectDropTarget } from 'react-dnd';
import ClickableCard from '../Cards/ClickableCard';

export interface IOptions {
  connectDropTarget: ConnectDropTarget;
  round: IRoundStepData;
  size: any;
  rowOffset?: number;
  selected: Array<string | null>;
  isDragging: boolean;
  remains: number;

  selectValue: (value: string, position?: number) => any;
  resetValue: (value: string) => any;
  openValueDefinition: (id: string) => any;
}
interface IState {
  preventClick: boolean;
}

const getDelay = (i: number, length: number) => (length > 6 ? (i < 20 ? i : i % 4) * 40 : 100 * i);

class Options extends React.Component<IOptions, IState> {
  public state: IState = { preventClick: false };
  public render() {
    const {
      round,
      selectValue,
      size,
      rowOffset,
      selected,
      connectDropTarget,
      remains,
      isDragging,
      openValueDefinition,
    } = this.props;

    const values = denormalize(round.values);

    return connectDropTarget(
      <div className='scrollableContainer'>
        <Row>
          {values.map((value, i) => (
            <Col key={value.valueId} {...size.options}>
              <Fade ssrReveal bottom duration={1000} distance={'5rem'} delay={getDelay(i, values.length)}>
                <div
                  className='movable'
                  style={{
                    position: 'relative',
                    top: -(rowOffset || 0) * 100 + '%',
                  }}
                >
                  {!selected.includes(value.value.id) && (
                    <ClickableCard onClick={() => selectValue(value.value.id)} preventClick={this.state.preventClick}>
                      <DraggableCard
                        card={value}
                        state={
                          isDragging
                            ? ValueCardState.draged
                            : remains === 0
                              ? ValueCardState.disabled
                              : ValueCardState.def
                        }
                        preventClick={(arg: boolean) => this.setState({ preventClick: arg })}
                        openClick={() => openValueDefinition(value.value.id)}
                      />
                    </ClickableCard>
                  )}
                </div>
              </Fade>
            </Col>
          ))}
        </Row>
      </div>,
    );
  }
}

export default Options;
