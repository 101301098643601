import * as React from 'react';
import popupContext from '../../organisms/MainPopup/popupContext';
import { FormattedMessage } from 'react-intl';
import SVGclosegray from '../SVGIcons/SVGclosegray';
import { PrimaryButton } from '../Buttons/index';
import { StyledBasePopup, StyledCloseButton } from './styles';

interface Props {
  button?: string;
  color?: 'red' | 'blue' | 'yellow' | 'green' | 'purple';
  width?: string;
  maxWidth?: string;
  disableClose?: boolean;
}

const PopupBase: React.FC<Props> = ({ children, button, color, width, maxWidth, disableClose, ...props }) => {
  const close = React.useContext(popupContext);

  return (
    <StyledBasePopup color={color} width={width} maxWidth={maxWidth} {...props}>
      {!disableClose && (
        <StyledCloseButton onClick={() => close()}>
          <SVGclosegray />
        </StyledCloseButton>
      )}

      <div>{children}</div>

      {button && (
        <span onClick={() => close()}>
          <PrimaryButton>
            <FormattedMessage id={button} />
          </PrimaryButton>
        </span>
      )}
    </StyledBasePopup>
  );
};

export default PopupBase;
