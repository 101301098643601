import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { SecondaryButtonLink } from '../../../../atoms/Buttons';
import wrapIntl, { IWrapIntl } from '../../../../atoms/wrapIntl';
import Fade from 'react-reveal/Fade';
import { changeAvatar, useEticon, deleteAccount, changeUserData, changeUser } from './actions';
import withUser from '../../../../molecules/withRedux/withUser';
import { IWithUser } from '../../../../molecules/withRedux/withUser';
import { StyledFakeLinkBorder, StyledLinkBorder } from '../../../../atoms/Buttons/styles';
import { createUrl } from '../../../routes';
import SVGlocked from '../../../../atoms/SVGIcons/SVGlocked';
import { useContext, useState } from 'react';
import ExternalSVG from '../../../../atoms/ExternalSVG';
import { withRouter, RouteComponentProps } from 'react-router';
import { GameContext } from 'components/App';
import { Box, Flex, Paragraph, Img, Typography, Heading1, Heading2, useModal, Icon } from '@xcorejs/ui';
import { PlusTag, FreeTag } from 'components/atoms/Logo';
import Accordion from 'components/atoms/Accordion/Accordion';
import { OliRegular, OliLight } from 'components/theme/fonts';
import { yellowWhite } from 'components/theme/colors';
import styled from 'styled-components';
import { mediaSmDefault } from 'components/theme/grid';
import KidsModal from 'components/components/modals/KidsModal';

interface ISettingsProps {
  getProfile: () => any;
  addError: (msg: string) => any;
  addInfo: (msg: string) => any;
  logout: () => any;
}

interface ISettingsState {
  oldPass: string;
  newPass: string;
  newPassAgain: string;
  avatar: File | null;
  error: {
    oldPass: string;
    newPass: string;
    newPassAgain: string;
  };
}

type OpenBlockProps = {
  [key: string]: boolean;
  image: boolean;
  emailInfo: boolean;
};

const Settings: React.FC<ISettingsProps & RouteComponentProps & IWrapIntl & IWithUser> = ({
  user,
  formatMsg,
  logout,
  addError,
  getProfile,
  addInfo,
  history,
}) => {
  const [state, setState] = useState<ISettingsState>({
    oldPass: '',
    newPass: '',
    newPassAgain: '',
    error: {
      oldPass: '',
      newPass: '',
      newPassAgain: '',
    },
    avatar: null,
  });
  const [openBlock, setOpenBlock] = React.useState<OpenBlockProps>({
    image: false,
    emailInfo: false,
  });
  const { eticiaPlus } = useContext(GameContext);
  const [openKidsModal] = useModal(KidsModal);

  // ! smazat po spuštění
  const [isChild, setIsChild] = React.useState<boolean>(user.child);

  const handleClick = (key: string) => {
    setOpenBlock(prev => ({ ...prev, [key]: !prev[key] }));
  };

  const changeLocalAvatar = (e: any) => {
    if (state.avatar) {
      const formData = new FormData();
      formData.append('file', state.avatar);
      const req = {
        method: 'POST',
        body: formData,
      };

      changeAvatar(req, onChangeAvatarSuccess, onChangeAvatarFailure);
      e.preventDefault();
    }
  };

  const onChangeAvatarSuccess = () => {
    addError(formatMsg('settings.profile.picture.upload.success'));
    setState({
      ...state,
      avatar: null,
    });
    getProfile();
    location.reload();
  };

  const onChangeAvatarFailure = () => {
    addError(formatMsg('settings.profile.picture.upload.failure'));
  };

  const useLocalEticon = (e: any) => {
    e.preventDefault();
    useEticon(() => {
      getProfile();
      addInfo(formatMsg('settings.profile.picture.upload.success'));
    });
  };

  const handleInputChange = (e: any) => {
    setState({
      [e.target.name]:
        e.target.type === 'checkbox' ? e.target.checked : e.target.type === 'file' ? e.target.files[0] : e.target.value,
    } as any);
  };

  const changeToKids = () => {
    changeUserData(
      changeUser(user, true, 'child'),
      () => {console.log('yay');},
      () => console.log('fail'),
    );
  };

  return (
    <>
      <Box as='section'>
        <Box position='fixed' bottom='5px' right='5px' p='1rem' zIndex={9999} backgroundColor={isChild ? 'blue' : 'purple'} onClick={() => setIsChild(prev => !prev)}>
        Změnit na {isChild ? 'dospělý' : 'dětský'} profil
        </Box>
      
        <Fade bottom ssrReveal duration={1000} delay={200} distance='5rem'>
          <Flex 
            {...FlexColumn}
            flexDirection='column'
            width='80%'
            margin='0 auto'
            maxWidth={{ _: '30rem', sm: '65rem' }}
            py={{ _: '4rem', md: '6rem' }}
          >
            <Flex {...FlexColumn} flexDirection='column'>
              <Heading1 {...HeadingStyle} >
                <FormattedMessage id='settings.heading' />
              </Heading1>

              <Paragraph {...ParagraphStyle} fontSize='1.6rem'>
                <FormattedMessage id='settings.text' />
              </Paragraph>
            </Flex>

            <Flex
              as='form'
              {...FlexColumn} 
              flexDirection='column'
              marginTop={{ _: '0', sm: '4rem' }}
            >
              <ItemGrid>
                <Heading2 {...Heading2Style}>
                  <FormattedMessage id='settings.form.nick' />
                </Heading2>

                <Flex className='item__second' alignItems='center'>
                  <Paragraph {...ParagraphStyle} wordBreak='break-all'>
                    {user.nick.length > 50 ? user.nick.slice(0, 50) + '...' : user.nick}
                  </Paragraph>

                  <StyledLinkBorder style={{ ...StyledLinkStyle }} color='yellow' to={createUrl('app.profile') + '/nick'}>
                    <FormattedMessage id='settings.form.edit' />
                  </StyledLinkBorder>
                </Flex>
              </ItemGrid>

              <ItemGrid>
                <Heading2 {...Heading2Style} position='relative'>
                  <FormattedMessage id='settings.form.status.heading' />

                  {eticiaPlus ? 
                    <PlusTag position='absolute' top='-1rem' transform='scale(0.7)' /> :
                    <FreeTag position='absolute' top='-1rem' transform='scale(0.7)' />}
                </Heading2>

                <Flex className='item__second' flexDirection='column' alignItems='center'>
                  <Flex flexDirection={{ _: 'column', sm: 'row' }}  alignItems='center'>
                    {isChild ? (
                      <Img src='/img/settings/child.png' alt='' {...StatusImgStyle} />
                    ) : (
                      <Img src='/img/settings/adult.png' alt='' {...StatusImgStyle} />
                    )}

                    <Paragraph {...ParagraphStyle} marginLeft={{ _: '0', sm: '2rem' }}>
                      <FormattedMessage id={`settings.form.status.text.${isChild ? 'child' : 'adult'}`} />
                    </Paragraph>
                  </Flex>
                </Flex>

                {!isChild ? (
                  <Typography 
                    color='salmon'
                    fontSize='1.3rem'
                    onClick={() => {
                      openKidsModal({ onAgreement: changeToKids });
                    }}
                    column={{ _: '', sm: '2/3' }}
                    justifySelf={{ _: '', sm: 'start !important' }}
                    marginLeft={{ _: '0', sm: '7rem' }}
                    textDecoration='underline'
                  >
                    <FormattedMessage id='settings.form.status.howToActivate' />
                  </Typography>
                ) : null}
              </ItemGrid>

              <ItemGrid>
                <Heading2 {...Heading2Style}>
                  <FormattedMessage id='settings.form.avatar' />
                </Heading2>

                <Flex alignItems='center' className='item__second'>
                  <Flex
                    {...FlexColumn}
                    flexDirection='column'
                    width='5rem'
                    height='5rem'
                    position='relative'
                  >
                    <Flex
                      {...FlexColumn}
                      flexDirection='column'
                      borderRadius='50%'
                      overflow='hidden'
                      color='white'
                    >
                      {user.avatar.endsWith('.svg') ? (
                        <ExternalSVG src={user.avatar} />
                      ) : (
                        <Img src={user.avatar || '/img/default-avatar.png'} alt='' />
                      )}
                    </Flex>
                  </Flex>

                  {!state.avatar ? (
                    <StyledFakeLinkBorder style={{ ...StyledLinkStyle, marginTop: 'unset' }} color='yellow' onClick={() => handleClick('image')} withoutAfterBorder={openBlock.image} >
                      <FormattedMessage id='settings.profile.picture.choose' />
                    </StyledFakeLinkBorder>
                  ) : (
                    <StyledFakeLinkBorder style={{ ...StyledLinkStyle, marginTop: 'unset' }} color='yellow' onClick={changeLocalAvatar}>
                      <FormattedMessage id='settings.profile.picture.upload' />
                    </StyledFakeLinkBorder>
                  )}
                </Flex>
              
                <Accordion 
                  expanded={openBlock.image}
                  wrapperProps={{ 
                    column:{ _: '1', sm: '2/3' }, 
                    justifySelf: { _: 'inherit', sm: 'start !important' }
                  }}>
                  <Flex 
                    {...FlexColumn}
                    flexDirection='column'
                    alignItems={{ _: 'center', sm:'flex-start' }}
                    marginLeft={{ _: '0', sm: '5rem' }}
                  >
                    <form onSubmit={changeLocalAvatar} style={{ position: 'absolute', left: '-10000px' }}>
                      <input type='file' name='avatar' id='avatar' onChange={handleInputChange} />
                    </form>

                    <StyledFakeLinkBorder style={{ ...StyledLinkStyle }} color='yellow'>
                      <label htmlFor='avatar' style={{ cursor: 'pointer' }}>
                        <FormattedMessage id='settings.profile.picture.upload' />
                      </label>
                    </StyledFakeLinkBorder>

                    {user.eticonId ? (
                      <StyledFakeLinkBorder style={{ ...StyledLinkStyle }} color='yellow' onClick={useLocalEticon}>
                        <FormattedMessage id='settings.profile.picture.useEticon' />
                      </StyledFakeLinkBorder>
                    ) : null}
                  </Flex>
                </Accordion>
              </ItemGrid>

              <ItemGrid>
                <Heading2 {...Heading2Style}>
                  <FormattedMessage id='settings.form.email1' />
                </Heading2>

                <Flex className='item__second' alignItems='center' opacity={0.5}>
                  <Paragraph {...ParagraphStyle} marginRight='1rem' wordBreak='break-all'>
                    {user.loginEmail}
                  </Paragraph>

                  <Icon svg={<SVGlocked />} position='relative' top='2px' />
                </Flex>
              </ItemGrid>

              <ItemGrid>
                <Box position='relative'>
                  <Heading2 {...Heading2Style}>
                    <FormattedMessage id='settings.form.email2' />                
                  </Heading2>

                  <Flex justifyContent='center' position='absolute' bottom={{ _:'1rem', md: '2rem' }} right='-2.5rem' height='1.8rem' width='1.8rem' border='1px solid #ffde71' p='0.2rem' borderRadius='50%' fontSize='1.2rem' cursor='pointer' onClick={() => handleClick('emailInfo')}>
                      i
                  </Flex>
                </Box>

                <Accordion
                  expanded={openBlock.emailInfo}
                  wrapperProps={{ 
                    column:{ _: '1', sm: '1/3' }, 
                    justifySelf: { _: 'inherit', sm: 'center !important' }
                  }}
                >
                  <Paragraph {...ParagraphStyle} fontSize='1.3rem' color='#ffde71'>
                    <FormattedMessage id='settings.form.emailInfo' />
                  </Paragraph>
                </Accordion>

                <Flex 
                  alignItems='center'
                  className='item__second'
                  row={{ _: '3', sm: '1' }}
                  column={{ _: '1', sm: '2' }}
                >
                  <Paragraph {...ParagraphStyle} wordBreak='break-all'>
                    {user.email}
                  </Paragraph>
                  <StyledLinkBorder style={{ ...StyledLinkStyle }} color='yellow' to={createUrl('app.profile') + '/email'}>
                    <FormattedMessage id='settings.form.edit' />
                  </StyledLinkBorder>
                </Flex>
              </ItemGrid>
            </Flex>
          </Flex>

          <Flex
            {...FlexColumn}
            flexDirection='column'
            background='rgba(0,0,0,0.2)'
            pb={{ _: '4rem', sm: '6rem' }}
            paddingTop={{ _: '2rem', sm: '4rem' }}
          >
            <Flex {...FlexColumn} flexDirection='column'>
              <Heading2 {...Heading2Style}>
                <FormattedMessage id='settings.form.geo' />
              </Heading2>

              <Flex flexDirection='column' alignItems='center' marginTop='1rem'>
                <SecondaryButtonLink to={createUrl('app.profile') + '/profile-info'}>
                  <FormattedMessage id='settings.form.edit2' />
                </SecondaryButtonLink>
              </Flex>
            </Flex>

            <Flex {...FlexColumn} flexDirection='column' marginTop='4rem'>
              <Heading2 {...Heading2Style} marginTop='1rem'>
                <FormattedMessage id='settings.form.password' />
              </Heading2>

              <Flex alignItems='center' marginTop='1rem'>
                <SecondaryButtonLink to={createUrl('app.profile') + '/heslo'}>
                  <FormattedMessage id='settings.form.edit' />
                </SecondaryButtonLink>
              </Flex>
            </Flex>

            <Flex {...FlexColumn} flexDirection='column' marginTop='6rem'>
              <StyledFakeLinkBorder className='back' onClick={() => logout()}>
                <FormattedMessage id='profile.default.logout' />
              </StyledFakeLinkBorder>
            </Flex>

            <Flex {...FlexColumn} flexDirection='column' marginTop='6rem'>
              <StyledFakeLinkBorder
                onClick={() => 
                  confirm(formatMsg('profile.default.delete.confirm'))
                    ? deleteAccount(
                      () => history.push(createUrl('')),
                      e => console.log(e),
                    )
                    : undefined
                }>
                <FormattedMessage id='profile.default.delete' />
              </StyledFakeLinkBorder>
            </Flex>
          </Flex>
        </Fade>
      </Box>
    </>
  );
};

export default withRouter(wrapIntl(withUser(Settings)));


const BaseStyle = {
  color: 'white',
};

const HeadingStyle = {
  ...BaseStyle,
  fontFamily: OliRegular,
};

const Heading2Style = {
  ...HeadingStyle,
  marginTop: { _: '4rem', sm: '1rem' },
  fontSize: '2rem',
  color: yellowWhite,
};

const ParagraphStyle = {
  ...BaseStyle,
  fontFamily: OliLight,
  marginTop: '1rem',
  fontSize: '1.8rem',
  maxWidth: '40rem',
};

const StyledLinkStyle = {
  marginTop: '1rem',
  marginLeft: '2rem',
  fontFamily: OliLight,
  fontSize: '1.3rem',
};

const StatusImgStyle = {
  width: '5rem',
  height: '5rem',
  marginTop: '1rem'
};

const FlexColumn = {
  alignItems: 'center',
  justifyContent: 'center',
};


const ItemGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  align-items: center;
  justify-items: center;
  width: 100%;

  .external-svg {
    display: block;
    width: 100%;
    height: 100%;

    svg {
      width: 100%;
      height: 100%;

      path {
        fill: #fff !important;
      }
    }
  }

  ${mediaSmDefault} {
    grid-template-columns: 1fr 1fr;
    column-gap: 5rem;
    margin-top: 2rem;

    & > * {
      justify-self: end;
    }

    .item__second {
      justify-self: start;
    }
  }
`;
