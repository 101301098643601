import * as React from 'react';
import { StyledFeedback } from './styles';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import Fade from 'react-reveal/Fade';
import { Heading1 } from '../../atoms/Headings';
import { FormattedMessage } from 'react-intl';
import { BiggerText } from '../../atoms/Text';
import { PrimaryButtonButton, SecondaryButtonLink } from '../../atoms/Buttons';
import wrapIntl, { IWrapIntl } from '../../atoms/wrapIntl';
import { createUrl } from '../../pages/routes';
import { StyledLink } from '../../atoms/Buttons/styles';
import { _switch } from '../../../utils/option';
import LoadingSpinnerLocal from '../../atoms/Animations/LoadingSpinnerLocal';
import Subnav from 'components/atoms/Subnav';
import { Flex } from '@xcorejs/ui';

interface IFeedback {
  title: string;
  text: string;
  submit: (text: string) => Promise<any>;
}

interface IFeedbackState {
  text: string;
  step: number;
  email?: string;
}

class FeedbackForm extends React.Component<IFeedback & IWrapIntl, IFeedbackState> {
  public state: IFeedbackState = { text: '', step: 0, email: '' };

  public render() {
    const { formatMsg, title, text } = this.props;
    return (
      <>
        <Subnav />
        <StyledFeedback>
          <Grid>
            <Fade bottom ssrReveal duration={1000} distance={'5rem'}>
              <div className='intro-wrapper'>
                <Row>
                  <Col xs={12} lg={8} lgOffset={2}>
                    <Heading1>{title}</Heading1>

                    <BiggerText>{text}</BiggerText>
                    {_switch({
                      0: (
                        <>
                          <Flex flexDirection='column' alignItems='center'>
                            <textarea
                              value={this.state.text}
                              onChange={e => this.setState({ text: e.target.value })}
                              name='feedback'
                            />

                            <input
                              value={this.state.email}
                              onChange={e => this.setState({ email: e.target.value })}
                              placeholder='E-mail'
                            />
                          </Flex>
                          <div>
                            <PrimaryButtonButton onClick={this.submit}>
                              {formatMsg('results.finish')}
                            </PrimaryButtonButton>
                          </div>

                          <br />
                          <StyledLink to={createUrl('app')}>
                            <FormattedMessage id={'feedback.bug.backToValueCenter'} />
                          </StyledLink>
                        </>
                      ),
                      1: <LoadingSpinnerLocal />,
                      2: (
                        <div>
                          <BiggerText className={'feedback-thanks'}>
                            <FormattedMessage id='feedback.thanks' />
                          </BiggerText>
                          <SecondaryButtonLink to={createUrl('app')}>
                            <FormattedMessage id={'feedback.bug.backToValueCenter'} />
                          </SecondaryButtonLink>
                        </div>
                      ),
                    })()(this.state.step)}
                  </Col>
                </Row>
              </div>
            </Fade>
          </Grid>
        </StyledFeedback>
      </>
    );
  }

  private submit = () => {
    this.setState({ step: 1 });
    this.props.submit(this.state.text + '. E-mail: ' + this.state.email).then(() => this.setState({ step: 2 }));
  };
}

export default wrapIntl(FeedbackForm);
