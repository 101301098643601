import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { PrimaryButtonButton } from '../../../atoms/Buttons';

interface IAgeCheckPopup {
  agree: () => any;
}

const AgeCheckPopup: React.FC<IAgeCheckPopup> = ({ agree }) => (
  <div>
    <p>
      <FormattedMessage id='terms.ageCheck' />
    </p>
    <PrimaryButtonButton type='submit' onClick={agree}>
      <FormattedMessage id={'register.agree'} />
    </PrimaryButtonButton>
  </div>
);

export default AgeCheckPopup;
