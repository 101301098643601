import * as React from 'react';
import Fetch from '../../atoms/Fetch';
import { _switch } from '../../../utils/option';
import { PrimaryButton } from '../../atoms/Buttons/index';
import withLifeValues, { IWithLifeValues } from '../../molecules/withRedux/withLifeValues';
import { Row, Col } from 'react-styled-flexboxgrid';
import { withRouter, RouteComponentProps } from 'react-router';
import { startSurvey, SURVEYS_LIFEVALUE } from 'config/api/surveyInternal';
import { createUrl } from '../routes';

interface ILifeValueSurvey {
  id: string;
  name: string;
  text: string;
  description: string;
  image: string;
}

const LifeValueSurvey: React.FC<IWithLifeValues & RouteComponentProps<{}>> = ({ lifeValues, history }) => {
  const runTest = (id: string, lv: string) =>
    startSurvey(id, lv).then(r => history.push(createUrl('app.survey', { id: r.id })));

  return (
    <>
      {Object.keys(lifeValues)
        .map(k => lifeValues[k])
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((lv, key) => (
          <div key={key}>
            <Fetch url={SURVEYS_LIFEVALUE(lv.id)}>
              {_switch({
                success: (result: ILifeValueSurvey[]) => (
                  <>
                    {result.length > 0 && (
                      <>
                        <h2>{lv.name}</h2>
                        <Row>
                          {result.map(s => (
                            <Col key={s.id}>
                              <PrimaryButton onClick={() => runTest(s.id, lv.id)}>{s.name}</PrimaryButton>
                            </Col>
                          ))}
                        </Row>
                      </>
                    )}
                  </>
                ),
                fetching: () => (
                  <>
                    <h2>{lv.name}</h2>
                    <p>Loading...</p>
                  </>
                ),
              })()}
            </Fetch>
          </div>
        ))}
    </>
  );
};

export default withLifeValues<{}>(withRouter(LifeValueSurvey));
