import { Box, Modal } from '@xcorejs/ui';
import { createUrl } from 'components/pages/routes';
import { PlusTheme } from 'components/theme/colors';
import React, { FC } from 'react';
import styled from 'styled-components';

interface EticiaPlusModalNewProps {
  inApp?: boolean;
}

const EticiaPlusModalNew: FC<EticiaPlusModalNewProps> = ({ inApp }) => {
  const path = inApp ? 'app.eticiaPlus' : 'eticiaPlus';
  
  return ( 
    <Modal
      background={PlusTheme.primary}
      p={{ _: '0', sm: '0' }}
      overflowX='hidden'
      height='auto'
      _close={{
        backgroundColor: '#444',
      }}
    >
      <BoxStyle>
        <iframe
          src={createUrl(path) + '?modal=true'}
          width='100%'
          height='100%'
          frameBorder='0'
        />
      </BoxStyle>
    </Modal>
  );
};
 
export default EticiaPlusModalNew;

const BoxStyle = styled(Box)`
  iframe {
    margin-top: -6rem;
    height: 100vh;
    max-height: calc(100vh - 6rem);
  }
`;
