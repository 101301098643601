import { useEffect, useState } from 'react';
import { IUserInfo } from 'store/User';
import { useFetchUserProfile } from 'utils/useQuery/queryFetchHelpers';

export const useFetchUser = () => {
  const [data, setData] = useState<IUserInfo | null>(null);

  const profileFetch = useFetchUserProfile();

  useEffect(() => {
    if (profileFetch.isSuccess) {
      setData(profileFetch.data);
    }
  }, [profileFetch.data, profileFetch.isSuccess]);

  return data;
};