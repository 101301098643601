import { IPage } from '../ValuesForTwoResult/data';
import * as React from 'react';
import DefaultIntroduction from './DefaultIntroduction';
import DefaultValues from './DefaultValues';
import { FormattedMessage, FormattedDate, FormattedHTMLMessage } from 'react-intl';
import DefaultEticon from './DefaultEticon';
import DefaultCharacters from './DefaultCharacters';

export const defaultConfig: IPage = {
  label: 'results.partners.menu.base',
  name: 'default',
  subpage: [
    {
      label: 'results.partners.default.introduction',
      name: 'default',
      generate: data => (
        <DefaultIntroduction
          profiles={data.profiles}
          finishedAt={data.finishedAt}
          eticons={data.valueTestResults}
          surveys={data.surveyResults}
          partner={data.testConfig.template.theme}
          surveysConfig={data.testConfig.surveys}
        />
      ),
      footer: result => {
        const date = new Date(result.finishedAt * 1000);
        return (
          <div className='content-date'>
            <FormattedMessage id='results.partners.final.intro.date' />{' '}
            <span>
              <FormattedDate value={date} day='numeric' month='numeric' year='numeric' />
            </span>
          </div>
        );
      },
    },
    {
      label: 'results.partners.default.values',
      name: 'mainValues',
      header: () => (
        <div className='test-type'>
          <FormattedHTMLMessage id='results.partners.final.valuesOrder.text' />
        </div>
      ),
      generate: (data, values) => (
        <DefaultValues
          profiles={data.profiles}
          values={data.valueTestResults}
          mapValues={values}
          allLifeValues={data.valueTestResults[Object.keys(data.valueTestResults)[0]].allLifeValues}
        />
      ),
    },
    {
      label: 'results.partners.default.eticon',
      name: 'etikon',
      header: () => (
        <div className='test-type'>
          <FormattedHTMLMessage id='results.partners.final.eticon.text' />
        </div>
      ),
      generate: data => <DefaultEticon profiles={data.profiles} eticons={data.valueTestResults} />,
    },
    {
      label: 'results.partners.default.characters',
      name: 'characters',
      header: () => (
        <div className='test-type'>
          <FormattedHTMLMessage id={'results.partners.final.intro.heading2'} />
        </div>
      ),
      generate: data => (
        <DefaultCharacters
          profiles={data.profiles}
          characteristics={data.valueTestResults}
          eticons={data.valueTestResults}
        />
      ),
    },
  ],
};
