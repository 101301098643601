import * as React from 'react';
import { FC } from 'react';
import styled, { css } from 'styled-components';

interface PieChartProps {
  number: number;
  color: string;
  colorInside: string;
}

export const StyledPieChart = styled.div<PieChartProps>`
  width: 8rem;
  min-width: 8rem;
  height: 8rem;
  border-radius: 50%;
  transform: rotate(-180deg);

  ${props =>
    props.color &&
    css`
      background: ${props.colorInside};
      border: 1px solid ${props.colorInside};
    `}

  ${props =>
    props.colorInside &&
    css`
      background: linear-gradient(to left, ${props.colorInside} 50%, ${props.color} 0);
    `}
  
  &:before {
    content: "";
    display: block;
    margin-left: 50%;
    height: 100%;
    border-radius: 0 100% 100% 0 / 50%;
    background-color: inherit;
    transform-origin: left;

    ${props =>
    props.number &&
      css`
        transform: rotate(${props.number}deg);
      `}

    ${props =>
    props.color &&
      css`
        background: ${props.colorInside};
      `}
  }
`;

const PieChart: FC<PieChartProps> = ({ number, color, colorInside }) => {
  return <StyledPieChart number={180 - (360 / 100) * number} colorInside={colorInside} color={color} />;
};

export default PieChart;
