import { TEST_TEMPLATES } from 'config/apiRoutes';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { IFetchException } from 'utils/exceptions';

import { _switch } from '../../../utils/option';
import Fetch from '../../atoms/Fetch';
import { startValueTest } from '../App/TestIntro/data';
import { createUrl } from '../routes';
import { ITestTemplate } from './TestFactory';
import { Flex } from '@xcorejs/ui';
import { Box } from '@xcorejs/ui';
import { NavLink } from 'react-router-dom';

// interface ITestTemplate {
//   testTemplateId: string;
//   voucherId: string;
//   surveyIds: string[];
//   note: string;
// }

const Templates: React.FC<RouteComponentProps<{}>> = ({ history }) => {
  const runTest = (id: string) =>
    startValueTest(id, 'sandbox')
      .then(result => history.push(createUrl('app.game', { id: result.id })))
      .catch((ex: IFetchException) =>
      // TODO better message
        ex.data.json().then((error: any) => alert(error.error)),
      );

  const runTestIntro = (id: string) =>
    history.push(createUrl('app.gameIntro', { query: id }));

  return (
    <>
      <Fetch url={TEST_TEMPLATES}>
        {_switch({
          success: (result: ITestTemplate[]) => (
            <>
              {console.log(result)}
              {result.map(r => (
                <Flex key={r.id}>
                  <Box>
                    {r.name}
                    <button onClick={() => runTest(r.id)}>Run</button>

                    {/* eslint-disable-next-line no-extra-parens */}
                    <NavLink to={`/${(global as any).requestContext.locale}/app/hra-intro?t=${r.id}&redirect=0`}>
                      Run TestIntro
                    </NavLink>
                  </Box>
                </Flex>
              ))}
            </>
          ),
          fetching: () =>
            <p>Loading...</p>
          ,
        })()}
      </Fetch>
    </>
  );
};

export default withRouter(Templates);
