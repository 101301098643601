import { IAddRecord } from '../../../organisms/Test/data';
import { jsonFetch } from 'utils/fetch';
import { TEST_ADD_RECORD, TEST_SKIP } from 'config/apiRoutes';

export const addTestRecord = <TData>(testId: string, data: IAddRecord<TData>) =>
  jsonFetch(TEST_ADD_RECORD(testId), {
    method: 'post',
    body: JSON.stringify(data),
  });

export const skipTestRecord = (testId: string) => jsonFetch(TEST_SKIP(testId), { method: 'POST' });
