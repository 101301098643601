import { TEST_CREATE_FROM_CONFIG } from 'config/apiRoutes';
import { jsonFetch } from 'utils/fetch';

import { ISurvey, ITestTemplate } from '../../../Sandbox/TestFactory';
import { ITestResultPreviewResponse } from '../../TestIntro/data';

export interface ITestConfig {
  id: 'string';
  template: ITestTemplate;
  surveys: ISurvey[];
  invitedFrom?: string;
  note?: string;
}

export const createTestFromConfig = (configId: string, valueTestResultId: string | null) =>
  jsonFetch<ITestResultPreviewResponse>(TEST_CREATE_FROM_CONFIG, {
    method: 'post',
    body: JSON.stringify({ configId, valueTestResultId }),
  });
