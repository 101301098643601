import styled from 'styled-components';

export type StyledContactsProps = {
  theme: 'light' | 'dark';
};

export const StyledContacts = styled.div<StyledContactsProps>`
  --text-color: ${({ theme }) => theme === 'light' ? '#000' : '#fff'};
  --anchor-color: ${({ theme }) => theme === 'light' ? '#506ba2' : '#2b61cc'};
  --anchor-hover-color: ${({ theme }) => theme === 'light' ? '#222' : '#3579ff'};

  margin-top: 6rem;
  display: flex;
  color: var(--text-color);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;

  a {
    color: var(--anchor-color);
    text-decoration: none;
    transition: text-decoration 300ms, color 300ms;

    &:hover {
      color: var(--anchor-hover-color);
      text-decoration: underline;
    }
  }

  @media screen and (min-width: 1024px) {
    margin-top: 10rem;
    flex-direction: row;
    gap: 3rem;
  }
`;