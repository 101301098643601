import * as React from 'react';
import { Footer } from '../../organisms/Layout/Footer';
import { StyledValueComparisonLanding } from '../styles';
import Topics from './components/Topics';
import HowItWorks from './components/HowItWorks';
import wrapIntl, { IWrapIntl } from '../../atoms/wrapIntl';
import PremiumStrip from './components/PremiumStrip';
import VisualValuesComparison from '../../organisms/VisualValuesComparison';
import Discover from './components/Discover';
import Ladders from './components/Ladders';
import Variants from './components/Variants';
import { createUrl } from '../routes';
import { RouteComponentProps, withRouter } from 'react-router';
import ValuePageMerged from '../../organisms/InfoSlides/ValuePageMerged';

const isNotInApp = (path: string) => !path.includes(createUrl('app', { id: '' }));

class ValueComparisonLanding extends React.Component<IWrapIntl & RouteComponentProps<any>> {
  public render() {
    const { location } = this.props;
    return (
      <>
        <StyledValueComparisonLanding isNotInApp={isNotInApp(location.pathname)}>
          <VisualValuesComparison />
          <PremiumStrip />
          <Discover />

          <div className='value-page-merged'>
            <ValuePageMerged result={null} eticonOnly={true} />
          </div>

          <Ladders />
          <HowItWorks />
          <Variants />
          <Topics />
          <Footer />
        </StyledValueComparisonLanding>
      </>
    );
  }
}

export default wrapIntl<{}>(withRouter(ValueComparisonLanding));
