import MainPopup from './component';
import { IApplicationState } from '../../../store/store';
import { connect } from 'react-redux';
import { closePopup } from 'store/Popup';

const mapStateToProps = (state: IApplicationState) => ({
  popup: state.popup,
});

const mapDispatchToProps = (dispatch: any) => ({
  close: () => dispatch(closePopup()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainPopup);
