import * as React from 'react';

const SVGpayMobile: React.FC = () => {
  return (
    <svg width='14px' height='24px' viewBox='0 0 14 24' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <g id='MVP-FInal' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' opacity='0.503115699'>
        <g
          id='Hodnotové-porovnání-–-Vouchery-(0)'
          transform='translate(-1214.000000, -314.000000)'
          fill='#FFFFFF'
          fillRule='nonzero'
        >
          <g id='CONTENT' transform='translate(310.000000, 0.000000)'>
            <g id='pay-methods' transform='translate(693.000000, 314.000000)'>
              <path
                d='M224,19 L224,5 L212,5 L212,19 L224,19 Z M224,20 L212,20 L212,21.5 C212,22.327 212.673,23 213.5,23 L222.5,23 C223.327,23 224,22.327 224,21.5 L224,20 Z M224,4 L224,2.5 C224,1.673 223.327,1 222.5,1 L213.5,1 C212.673,1 212,1.673 212,2.5 L212,4 L224,4 Z M222.5,24 L213.5,24 C212.122,24 211,22.878 211,21.5 L211,2.5 C211,1.122 212.122,0 213.5,0 L222.5,0 C223.878,0 225,1.122 225,2.5 L225,21.5 C225,22.878 223.878,24 222.5,24 Z M218.5,3 L217.5,3 C217.224,3 217,2.776 217,2.5 C217,2.224 217.224,2 217.5,2 L218.5,2 C218.776,2 219,2.224 219,2.5 C219,2.776 218.776,3 218.5,3 Z M221.52,3 C221.244,3 221.015,2.776 221.015,2.5 C221.015,2.224 221.234,2 221.51,2 L221.52,2 C221.796,2 222.02,2.224 222.02,2.5 C222.02,2.776 221.796,3 221.52,3 Z M215.5,22 L213.5,22 C213.224,22 213,21.776 213,21.5 C213,21.224 213.224,21 213.5,21 L215.5,21 C215.776,21 216,21.224 216,21.5 C216,21.776 215.776,22 215.5,22 Z M222.5,22 L220.5,22 C220.224,22 220,21.776 220,21.5 C220,21.224 220.224,21 220.5,21 L222.5,21 C222.776,21 223,21.224 223,21.5 C223,21.776 222.776,22 222.5,22 Z'
                id='ico/pay-mobile'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SVGpayMobile;
