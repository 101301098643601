import styled from 'styled-components';
import { Heading2 } from '../../../../atoms/Headings';
import { StyledValuesGraph } from '../../../../molecules/Results/styles';
import { CustomText } from '../../../../atoms/Text';
import { yellow, white, darkBaseRGB, blue, darkBaseHEX } from '../../../../theme/colors';
import { StyledPrimaryButtonLink } from '../../../../atoms/Buttons';
import { StyledHelpLink } from '../../../../atoms/Popups/styles';
import { StyledPlayButton, StyledPrimaryButton, StyledSecondaryButton } from '../../../../atoms/Buttons/styles';
import {
  mediaHonzaDefault,
  mediaLandscapePhone,
  mediaLgDefault,
  mediaMdDefault,
  mediaSmDefault,
  mediaSmMax,
} from '../../../../theme/grid';
import { Row, Col } from 'react-styled-flexboxgrid';
import { UL1 } from '../../../../organisms/VisualValuesComparison/style';
import { StyledCard } from '../../../../atoms/Cards/styles';
import { StyledStatisticsSlider } from '../../../../molecules/StatisticsSlider/styles';
import { StyledGamesForTwoAdverb } from '../../../Homepage/styles';
import { StyledValueMap } from '../../../../molecules/ValueMap/styles';
import { StyledBanner } from '../../../../atoms/Banner';

export const EticonText = styled(CustomText)`
  white-space: pre-line;
  text-align: center;
  
  p {
    color: #fff;
  }
  
  p em {
    font-weight: 400;
    color: rgba(255, 255, 255, 0.7);
  }
`;

export const StyledTestResultStatistics = styled.section`
  text-align: center;

  ${Row as any} {
    & > ${Col as any} {
      ${mediaLandscapePhone} {
        flex-basis: 50%;
        max-width: 50%;
      }
    }
  }

  ${mediaMdDefault} {
    text-align: left;
  }

  ${StyledStatisticsSlider} {
    margin-top: 3rem;

    ${mediaSmDefault} {
      margin-bottom: 1rem;
    }
  }

  .stats-link {
    margin-bottom: 3rem;

    ${CustomText} {
      margin-bottom: 2rem;
    }

    ${mediaMdDefault} {
      max-width: 63rem;
      margin: 0 auto 6rem;
      text-align: center;

      img {
        margin-right: 2rem;
        opacity: 0.7;
        transition: opacity 0.3s;

        &:first-child {
          width: 4.8rem;
        }
      }

      ${CustomText} {
        margin-top: 2rem;
        margin-bottom: 2rem;
        text-align: center;
        transition: color 0.3s;
      }

      &:hover {
        img {
          opacity: 1;
        }

        ${CustomText} {
          color: #fff;
        }
      }
    }
  }

  .desktopOnly {
    display: none;

    ${mediaMdDefault} {
      display: flex;
    }
  }

  .mobileOnly {
    margin-bottom: 3rem;

    ${mediaMdDefault} {
      display: none;
    }
  }

  .main-popup-button {
    margin-bottom: 3rem;

    ${mediaSmDefault} {
      margin-bottom: 6rem;
    }
  }
`;

export const StyledTestResultGraph = styled.section`
  position: relative;

  ${Row as any} {
    & > ${Col as any} {
      ${mediaLandscapePhone} {
        flex-basis: 50%;
        max-width: 50%;
      }
    }
  }

  ${StyledHelpLink} {
    position: absolute;
    right: -3rem;
    top: -8.5rem;
  }

  .graph-legend {
    color: ${white};
    text-align: left;

    @media (min-width: 360px) {
      padding: 0 1.4rem;
    }

    ${mediaMdDefault} {
      text-align: center;
    }

    div {
      

      ${mediaHonzaDefault} {
        margin-bottom: 1rem;
      }

      span {
        font-weight: 500;
      }

      p {
        color: rgba(255, 255, 255, 0.7);
        padding-left: 4rem;
        
        ${mediaMdDefault} {
          padding-left: 0;
        }

        span {
          font-weight: 400;
        }
      }
    }

    .percentage {
      color: rgba(255, 255, 255, 0.7);
      margin-left: 0.5rem;
      font-weight: 400;
    }
  }

  .symbol {
    display: inline-flex;
    margin-right: 1rem;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    border: 0.2rem solid rgba(255, 255, 255, 0.5);
    border-radius: 50%;

    &.active {
      background: ${yellow};
      border: none;
      color: ${darkBaseHEX};
    }
  }

  ${StyledValuesGraph} {
    ${mediaMdDefault} {
      margin-bottom: 0;
      max-width: 90%;
      margin-left: auto;
      margin-right: auto;
    }

    ${mediaHonzaDefault} {
      max-width: 70%;
    }
  }

  .main-popup-button {
    margin-bottom: 3rem;

    ${mediaMdDefault} {
      margin-bottom: 0;
    }
  }

  ${StyledPlayButton} {
    display: flex;
    margin: 2rem auto 3rem;
    padding-left: 1rem;
    padding-right: 1rem;
    height: 5rem;
    width: 28rem;
    border: 2px solid ${yellow};
    border-radius: 3.5rem;
    background-color: rgba(${darkBaseRGB}, 0.5);
    font-size: 1.6rem;

    svg {
      margin-right: 1.3rem;
      width: 2.4rem;
      height: 2rem;

      path {
        fill: ${yellow};
      }
    }

    ${mediaMdDefault} {
      margin-bottom: 0;
    }

    &:hover {
      background-color: rgba(${darkBaseRGB}, 1);
    }

    span {
      font-size: 1.6rem;
      line-height: 2.1rem;
    }
  }
`;

export const StyledTestResultsDetailed = styled.section`
  text-align: center;

  ${Row} {
    & > ${Col} {
      ${mediaLandscapePhone} {
        flex-basis: 50%;
        max-width: 50%;
      }
    }
  }

  .secondary-char {
    margin-top: 3rem;

    ${CustomText} {
      margin-top: 3rem;
    }
  }

  ${CustomText} {
    margin-top: 2rem;
    text-align: center;
  }

  .before-title {
    margin-bottom: 2rem;
    display: none;

    ${mediaMdDefault} {
      display: block;
    }
  }

  .desc-title {
    color: rgba(255, 255, 255, 0.7);
    display: none;

    ${mediaSmDefault} {
      display: block;
    }
  }

  ${UL1} {
    padding: 0 2.4rem;
    text-align: left;
    margin-bottom: 1rem;

    &:before {
      content: " ";
      background: url("/img/check_yellow.svg");
      background-size: cover;
    }

    li {
      span {
        font-size: 1.6rem;
        line-height: 2rem;
      }
    }
  }

  .eticon-img {
    img {
      ${mediaSmMax} {
        max-width: 60%;
        margin: 0 auto;
      }
    }
  }
`;

export const StyledTestResultDefault = styled.section`
  text-align: center;

  ${Row as any} {
    & > ${Col as any} {
      ${mediaLandscapePhone} {
        flex-basis: 50%;
        max-width: 50%;
      }
    }
  }

  ${mediaMdDefault} {
    text-align: left;
  }

  ${CustomText} {
    margin-top: 2rem;

    ${mediaMdDefault} {
      text-align: left;
    }
  }

  .char {
    margin-top: 1rem;
    color: rgba(255, 255, 255, 0.7);
    font-size: 1.6rem;
    line-height: 2.4rem;

    ${mediaMdDefault} {
      text-align: center;
    }
  }
  
  .statistics {
    text-align: center;

    .people {
      margin-bottom: 2rem;
      display: block;
      color: ${yellow};
      font-size: 2.4rem;
      line-height: 3.2rem;
      font-weight: 400;
    }

    em {
      color: ${blue};
      font-style: normal;
      display: block;
      margin-top: 0.5rem;
    }

    .btn-wrap {
      margin-top: 5rem;
    }
  }

  ${EticonText} {
    ${mediaMdDefault} {
      margin-top: 0;
      padding-top: 2rem;
    }
  }

  .eticonIntensity {
    text-align: center;
    margin-top: 2rem;
    font-size: 1.6rem;
    line-height: 2.4rem;

    em {
      color: ${yellow};
    }
  }
`;

export const StyledTestResultValues = styled.section`
  .my-values {
    min-height: 32rem;

    ${mediaLgDefault} {
      min-height: 42rem;
    }

    ${mediaMdDefault} {
      margin-bottom: 3rem;
    }

    ${Col} {
      &:first-child {
        ${StyledCard} {
          &:nth-child(1) {
            &:after {
              background-color: rgba(${darkBaseRGB}, 0.13);
            }
          }

          &:nth-child(2) {
            &:after {
              background-color: rgba(${darkBaseRGB}, 0.16);
            }
          }

          &:nth-child(3) {
            &:after {
              background-color: rgba(${darkBaseRGB}, 0.19);
            }
          }

          &:nth-child(4) {
            &:after {
              background-color: rgba(${darkBaseRGB}, 0.22);
            }
          }

          &:nth-child(5) {
            &:after {
              background-color: rgba(${darkBaseRGB}, 0.25);
            }
          }
        }
      }

      &:nth-child(2) {
        ${StyledCard} {
          &:nth-child(1) {
            &:after {
              background-color: rgba(${darkBaseRGB}, 0.28);
            }
          }

          &:nth-child(2) {
            &:after {
              background-color: rgba(${darkBaseRGB}, 0.31);
            }
          }

          &:nth-child(3) {
            &:after {
              background-color: rgba(${darkBaseRGB}, 0.34);
            }
          }

          &:nth-child(4) {
            &:after {
              background-color: rgba(${darkBaseRGB}, 0.37);
            }
          }

          &:nth-child(5) {
            &:after {
              background-color: rgba(${darkBaseRGB}, 0.4);
            }
          }
        }
      }
    }

    .main-popup-faq-2 {
      svg {
        margin-right: 1rem;
        width: 2.4rem;
        height: 2.6rem;

        path {
          fill: ${yellow};
        }
      }
    }
  }

  .ul-box {
    ${mediaLgDefault} {
      margin-top: 5rem;
      margin-bottom: 3rem;
    }
  }

  ${UL1} {
    margin-top: 3rem;
    margin-bottom: 0;
    padding: 0 2rem;

    li {
      text-align: left;
    }

    ${mediaMdDefault} {
      li {
        display: flex;
        align-items: center;

        span {
          font-size: 1.6rem;
          line-height: 2.1rem;
        }
      }
    }
  }

  .main-popup-button {
    ${mediaMdDefault} {
      margin-top: 2rem;
    }
  }

  .top {
    display: none;
  }
`;

export const StyledTestResultSummary = styled.section`
  margin: 0 auto;
  max-width: 85rem;
  text-align: center;

  ${CustomText} {
    strong {
      color: ${yellow};
    }
  }

  ${StyledPrimaryButtonLink} {
    display: block;
    margin: 3rem auto 0;
    padding-left: 3rem;
    padding-right: 3rem;
    max-width: 30rem;
  }

  ${StyledSecondaryButton} {
    margin-top: 3rem;
  }

  .block {
    margin-top: 3rem;
    display: block;

    & > div {
      display: inline-flex;
    }
  }

  .back {
    margin-top: 3rem;
    text-align: center;

    ${StyledPrimaryButton} {
      ${mediaSmMax} {
        width: 28rem;
      }
    }

    ${mediaSmDefault} {
      margin-top: 3rem;
    }
  }

  .time-left {
    margin-top: 3rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .days {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-radius: 50%;
      width: 9rem;
      height: 9rem;
      font-size: 1.3rem;
      line-height: 1.3rem;

      strong {
        font-size: 2.4rem;
        line-height: 2.4rem;
      }
    }
  }

  ${StyledBanner} {
    margin-top: 3rem;
    max-width: 30rem;
  }

  .eticon {
    margin-top: 2rem;
    max-width: 16rem;
  }

  h2 {
    &:not(:first-child) {
      margin-top: 6rem;
    }
  }

  ${CustomText} {
    img {
      display: block;
      margin: 4rem auto 2rem;
    }

    strong {
      color: ${yellow};
    }

    &.summary {
      margin-bottom: 3rem;

      .main-popup-button {
        span {
          border-bottom: 1px solid ${yellow};
          transition: border 0.3s;
        }

        &:hover {
          span {
            border-bottom: 1px solid transparent;
          }
        }
      }
    }
  }

  .react-reveal {
    width: 100%;
  }

  ${StyledGamesForTwoAdverb} {
    width: 100%;
    padding: 0 3rem;
  }

  .u--desktop {
    width: 100%;
  }

  .main-popup-button {
    margin-top: 0;

    ${mediaSmDefault} {
      display: inline-block;
    }
  }

  .value-map-bg {
    margin-top: 6.5rem;
    padding-bottom: 5.5rem;

    ${mediaSmDefault} {
      margin-top: 0;
      background: transparent;
      padding-bottom: 0;
    }

    ${StyledValueMap} {
      box-shadow: 0 0.5rem 1rem 0 rgba(${darkBaseRGB}, 0.8);
    }
  }
`;
