import * as React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

const Popup = () => (
  <div>
    <FormattedHTMLMessage id={'support.tshirt.popup'} />
  </div>
);

export default Popup;
