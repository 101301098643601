import * as React from 'react';

const SVGcloses: React.FC = () => {
  return (
    <svg width='16px' height='16px' viewBox='0 0 16 16' version='1.1'>
      <g
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
        transform='translate(-689.000000, -698.000000)'
        opacity='0.498980978'
      >
        <g transform='translate(315.000000, 170.000000)' fill='#C4D8DC'>
          <g transform='translate(0.000000, 84.000000)'>
            <g transform='translate(20.000000, 435.000000)'>
              <path d='M362.695652,17 L370,24.3043478 L369.304348,25 L362,17.6956522 L354.695652,25 L354,24.3043478 L361.304348,17 L354,9.69565217 L354.695652,9 L362,16.3043478 L369.304348,9 L370,9.69565217 L362.695652,17 Z' />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SVGcloses;
