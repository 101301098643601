import { Tag, TagProps, useModal } from '@xcorejs/ui';
import { GameContext } from 'components/App';
import EticiaPlusModalNew from 'components/components/modals/EticiaPlusModalNew';
import * as React from 'react';
import { FC, useContext } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import popupContext from '../../organisms/MainPopup/popupContext';
import { createUrl } from '../../pages/routes';
import { PlusTheme } from '../../theme/colors';
import { SecondaryButton } from '../Buttons';
import { StyledBetaPopup } from '../Popups/styles';
import { CustomText } from '../Text';
import { StyledLogo } from './styled';

interface ILogo {
  app: boolean;
  noLink?: boolean;
}

export const BetaPopup: React.FC = () => {
  const close = React.useContext(popupContext);
  const onClose = () => {
    close();
  };
  return (
    <StyledBetaPopup>
      <CustomText>
        <FormattedHTMLMessage id={'popup.beta.hint'} />
      </CustomText>

      <Link to={createUrl('app.feedback.bug')}>
        <SecondaryButton onClick={() => onClose()}>
          <FormattedMessage id={'popup.beta.button'} />
        </SecondaryButton>
      </Link>
    </StyledBetaPopup>
  );
};

const Logo: React.FC<ILogo> = ({ app, noLink }) => {
  const { eticiaPlus } = useContext(GameContext);
  return (
    <StyledLogo>
      {noLink ? <img src='/img/logo/eticia-logo.svg' width='12.3rem' alt='Eticia logo' /> : (
        <Link to={createUrl(app ? 'app' : '')}>
          <img src='/img/logo/eticia-logo.svg' width='12.3rem' alt='Eticia logo' />
        </Link>
      )}

      {noLink ? null : (
        <>
          {eticiaPlus ? (
            <PlusTag
              ml={{ _: '0.5rem', sm: '1.5rem' }}
              transform={{ _: 'scale(0.8)', sm: 'none' }}
              { ...{ style: { transformOrigin: 'top left' } } }
            />
          ) : (
            <FreeTag
              ml={{ _: '0.5rem', sm: '1.5rem' }}
              transform={{ _: 'scale(0.8)', sm: 'none' }}
              { ...{ style: { transformOrigin: 'top left' } } }
            />
          )}
        </>
      )}
    </StyledLogo>
  );
};

export default Logo;


export const PlusTag: FC<TagProps> = ({ children, ...props }) => {
  // const [openModal] = useModal(EticiaPlusModal);
  const [openModal] = useModal(EticiaPlusModalNew);

  return (
    <Tag
      display='inline-flex'
      borderRadius='0.5rem'
      bg={PlusTheme.primary}
      onClick={(e) => {
        e.stopPropagation();
        openModal({ inApp: location.pathname.includes('/app') });
      }}
      {...props}
    >
      {children ?? 'Plus'}
    </Tag>
  );
};

export const FreeTag: FC<TagProps> = ({ children, ...props }) => {
  // const [openModal] = useModal(EticiaPlusModal);
  const [openModal] = useModal(EticiaPlusModalNew);

  return (
    <Tag
      display='inline-flex'
      borderRadius='0.5rem'
      bg='#179269'
      onClick={() => openModal({ inApp: location.pathname.includes('/app') })}
      {...props}
    >
      {children ?? 'Free'}
    </Tag>
  );
};

export const KidsTag: FC<TagProps> = ({ ...props }) => {
  return (
    <Tag
      bg='kids'
      px='0.8rem'
      py='0.2rem'
      borderRadius='0.8rem'
      {...props}
    >
      Pro děti
    </Tag>
  );
};


const Label = ({ htmlFor, text }:{htmlFor: string; text: string}) => {
  return <label htmlFor={htmlFor}>{text}</label>;
};