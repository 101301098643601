import styled from 'styled-components';
import { yellow, gray, grayRGB } from '../../../../theme/colors';
import { mediaSmDefault, mediaMdDefault } from '../../../../theme/grid';

export const StyledSlider = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;

  .slider {
    width: calc(100% - 3.2rem);
    min-height: 17rem;
    margin-left: 1.6rem;
    max-width: 100%;
    overflow: hidden;
    position: relative;

    ${mediaSmDefault} {
      width: 100%;
      margin-left: 0;
      min-height: 24rem;
    }
  }

  .items {
    display: flex;
    transition: left 0.3s;
    position: absolute;
    min-height: 24rem;
    width: 100%;
    left: 0;

    &:nth-child(2) {
      top: 24rem;
    }

    .plate {
      position: relative;
      transition: 300ms left;
      padding: 2rem 0.8rem 3rem 0.8rem;
      min-width: 100%;
      text-align: center;
      white-space: nowrap;
      display: inline-block;
      min-height: 16rem;
      max-height: 24rem;
      user-select: none;

      @media (min-width: 360px) {
        min-width: 33.33%;
        max-width: 33.33%;
      }
      

      @media (min-width: 1024px) {
        min-width: 20%;
        max-width: 20%;
        padding: 5rem 2.5rem 4rem 2.5rem;
      }

      @media (min-width: 1200px) {
        min-width: 16.666666%;
        max-width: 16.666666%;
      }

      img {
        max-width: 10rem;
        width: 80%;
        max-height: 10rem;
        margin: 0 auto;
        display: block;
      }

      .name {
        margin-top: 1.5rem;
        display: block;
        position: relative;
        color: ${gray};
        font-size: 1.4rem;
        line-height: 1.7rem;
        text-align: center;
        font-weight: 500;
        word-break: break-word;
        white-space: normal;
        z-index: 5;

        @media (min-width: 1024px) {
          font-size: 1.8rem;
          line-height: 2.6rem;
          margin-top: 3rem;
        }
      }

      .rotate-ico-wrapper {
        display: block;
        position: absolute;
        z-index: 1;
        top: 0.8rem;
        right: 0.8rem;
        
        opacity: 0.5;
        cursor: pointer;
        transition: opacity 0.3s;

        &:hover {
          opacity: 1;
        }
        @media (min-width: 1024px) {
          top: 2rem;
          right: 2rem;
        }
      }

      .game-question-wrapper {
        position: absolute;
        top: 2rem;
        left: 2rem;
        z-index: 1;
        cursor: pointer;
        opacity: 0.5;
        transition: opacity 0.3s;

        &:hover {
          opacity: 1;
        }

        &.done {
          opacity: 0.5;
          /* cursor: default; */
        }
      }

      .game-question-wrapper,
      .rotate-ico-wrapper {
        svg,
        img {
          width: 1.6rem;
          height: 1.6rem;
          
          ${mediaSmDefault} {
            width: 2.2rem;
            height: 2.2rem;
          }
        }
      }

      &:after {
        content: "";
        left: 0;
        top: 0;
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(25, 29, 44, 0.6);
        opacity: 0;
        transition: opacity 0.3s;
      }

      .hoverText {
        position: absolute;
        padding: 0 1.5rem;
        vertical-align: middle;
        top: 50%;
        left: 0;
        margin-top: 0;
        width: 100%;
        line-height: 3rem;
        transform: translateY(-50%);
        font-weight: 400;
        z-index: 1;
        opacity: 0;
        transition: opacity 0.3s;
        white-space: normal;
        background: transparent !important;

        span {
          color: rgba(${grayRGB}, 0.8);
        }
      }
    }
  }

  .arr-before,
  .arr-after {
    position: absolute;
    height: 100%;
    display: flex;
    width: 3rem;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 3;

    svg {
      width: 1.2rem;
      height: 2.4rem;
      transition: margin 0.3s;
    }

    ${mediaSmDefault} {
      width: 14rem;

      svg {
        width: auto;
        height: auto;
      }
    }

    @media all and (-ms-high-contrast: none) {
      svg {
        width: 2.8rem;
        height: 5.2rem;
      }
    }
  }

  .arr-before {
    left: 0;

    ${mediaSmDefault} {
      svg {
        margin-left: -7rem;
      }

      &:hover {
        svg {
          margin-left: -8rem;
        }
      }
    }
  }

  .arr-after {
    right: 0;

    ${mediaSmDefault} {
      svg {
        margin-right: -7rem;
      }

      &:hover {
        svg {
          margin-right: -8rem;
        }
      }
    }

    svg {
      transform: rotate(-180deg);
    }
  }
`;

export const StyledSliderValues = styled.div`

  .plate {
    .hoverText {
      color: rgba(${grayRGB}, 0.8);
    }
  }

  .items {
    .plate {
      /* background: radial-gradient(circle at top, #076E4F 0%, #02523D 100%); */
      background: #509981;

      ${mediaSmDefault} {
        &:nth-child(odd) {
          // background: radial-gradient(circle at top, #05865C 0%, #006545 100%);
          background: #377c67;

        }

        &:nth-child(even) {
          /* background: radial-gradient(circle at top, #076E4F 0%, #02523D 100%); */
          background: #509981;

        }
      }
    }

    &:first-child {
      .plate {
        &:nth-child(odd) {
          /* background: radial-gradient(circle at top, #05865C 0%, #006545 100%); */
          background: #377c67;
        }
        
        ${mediaSmDefault} {
          &:nth-child(odd) {
            /* background: radial-gradient(circle at top, #076E4F 0%, #02523D 100%); */
            background: #377c67;
          }

          &:nth-child(even) {
            /* background: radial-gradient(circle at top, #05865C 0%, #006545 100%); */
            background: #509981;

          }
        }
      }
    }
  }

  .arr-before {
    ${mediaSmDefault} {
      /* background: linear-gradient(270deg, rgba(4, 96, 67, 0) 0%, #046043 100%); */
      background: linear-gradient(270deg, rgba(4, 96, 67, 0) 0%, #377c67 100%);
    }
  }

  .arr-after {
    right: 0;

    ${mediaSmDefault} {
      /* background: linear-gradient(90deg, rgba(4, 96, 67, 0) 0%, #046043 100%); */
      background: linear-gradient(90deg, rgba(4, 96, 67, 0) 0%, #377c67 100%);
    }
  }
`;

export const StyledSliderTypes = styled.div`
  background: radial-gradient(circle at top, #247bcb 0%, #1769b4 100%);

  .slider {
    width: calc(100% - 8rem);
    min-height: 32rem;
    margin-left: 4rem;

    ${mediaSmDefault} {
      width: 100%;
      margin-left: 0;
    }
  }

  .items {
    min-height: 32rem;
    top: 0;

    .plate {
      padding-top: 4rem;
      min-height: 32rem;
      max-height: 32rem;
      background: #1c5893;
      position: relative;

      ${mediaSmDefault} {
        &:nth-child(odd) {
          background: radial-gradient(circle at top, #247bcb 0%, #1769b4 100%) !important;
        }

        &:nth-child(even) {
          background-color: rgba(25, 29, 44, 0.3) !important;
        }
      }

      &:hover {
        &:after,
        .hoverText {
          opacity: 1;
        }
      }

      &:after {
        content: "";
        left: 0;
        top: 0;
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(25, 29, 44, 0.6);
        opacity: 0;
        transition: opacity 0.3s;
      }

      .hoverText {
        position: absolute;
        padding: 0 1.5rem;
        vertical-align: middle;
        top: 50%;
        left: 0;
        margin-top: 0;
        width: 100%;
        line-height: 3rem;
        transform: translateY(-50%);
        font-weight: 400;
        z-index: 1;
        opacity: 0;
        transition: opacity 0.3s;
        white-space: normal;
        background: transparent !important;

        span {
          color: rgba(${grayRGB}, 0.8);
        }
      }

      .pickedBy {
        display: block;
        position: absolute;
        margin-top: 0;
        top: 4rem;
        left: 0;
        width: 100%;
        z-index: 5;
        color: rgba(${grayRGB}, 0.8);
        opacity: 0;
        transition: opacity 0.3s;
      }

      @media (min-width: 480px) {
        min-width: 50%;
        max-width: 50%;
      }

      @media (min-width: 768px) {
        min-width: 33.33%;
        max-width: 33.33%;
      }

      @media (min-width: 1024px) {
        min-width: 25%;
        max-width: 25%;
      }

      @media (min-width: 1200px) {
        min-width: 16.666666%;
        max-width: 16.666666%;
      }

      img {
        width: 20rem;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }

      span {
        margin-top: 2rem;
      }
    }
  }

  .arr-before,
  .arr-after {
    background: radial-gradient(circle at top, #247bcb 0%, #1769b4 100%);

    ${mediaSmDefault} {
      background: linear-gradient(90deg, rgba(26, 62, 102, 0) 0%, #1a3e66 100%);
    }
  }

  .arr-before {
    ${mediaSmDefault} {
      background: linear-gradient(270deg, rgba(26, 62, 102, 0) 0%, #1a3e66 100%);
    }
  }
`;
