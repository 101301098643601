import { Box, Button, useModal } from '@xcorejs/ui';
import { GameContext } from 'components/App';
import Level34 from 'components/components/modals/InsideQuestModals/Level34';
import { IWithUser } from 'components/molecules/withRedux/withUser';
import { TestTypeEnum } from 'components/organisms/Test/data';
import ValueRatingModal from 'components/pages/App/Journal/Journal/ValueRatingModal';
import PositiveModal from 'components/pages/App/Statistics/modals/PositiveModal';
import RelationshipModal from 'components/pages/App/Statistics/modals/RelationshipModal';
import TopValuesModal from 'components/pages/App/Statistics/modals/TopValuesModal';
import YoungOldModal from 'components/pages/App/Statistics/modals/YoungOldModal';
import { startValueTest } from 'components/pages/App/TestIntro/data';
import Modal, { ModalState } from 'components/pages/App/ValueCenter/Game/components/Modal';
import { gameLevels as data } from 'components/pages/App/ValueCenter/Game/data';
import { createUrl } from 'components/pages/routes';
import { IValueResult } from 'config/api/valueTestResult';
import * as React from 'react';
import { FC, Fragment, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { IFetchException } from 'utils/exceptions';
import { useFetchGameStatus, useFetchSurvey } from 'utils/useQuery/queryFetchHelpers';
import GenderModal from '../../Statistics/modals/GenderModal';
import Level5 from './CustomLevelModals/Level5';

const GameLevelModal: FC<Pick<IWithUser, 'user'>> = ({ user }) => {
  const { setInsideQuest, setUserLvl, userLvl,
    setQuestVersion, questLvl, setQuestLvl, questVersion,
    insideQuest, customString, setCustomString, testMode } = useContext(GameContext);

  const history = useHistory();

  {/* handle na preIntro */}
  const [introRan, setIntroRan] = useState(false);
  useEffect(() => {
    setIntroRan(false);
  }, [questLvl]);

  {/* TEST MODE */}
  const [customTestState, setCustomTestState] = useState<ModalState>('intro');

  {/* Custom Level 1 */}
  const eticonDone = user.eticonId ? true : false;

  {/* Custom Level 4 */}
  const [introVisited, setIntroVisited] = useState(userLvl > 4);
  const [animal, setAnimal] = useState(0);
  const findHighestRank = (arr: IValueResult[]): number => {
    const valueIds = {
      FINANCE_ID: 'f393ada5-298b-4f75-9505-ba55e5876cfb',
      TOUHA_ID: '08d9eacd-19c4-4d7b-a5ec-91044fd223fa',
      ROZVAZNOST_ID: '9a6b5bc3-a553-4212-9c3a-0fae8495111b',
    };
    
    const sortedArr = arr.sort((a, b) => b.rank - a.rank);
    for (const value of sortedArr) {
      if (value.valueId === valueIds.FINANCE_ID || 
        value.valueId === valueIds.TOUHA_ID || 
        value.valueId === valueIds.ROZVAZNOST_ID) {
        switch (value.valueId) {
        case valueIds.TOUHA_ID:
          return 0;
        case valueIds.ROZVAZNOST_ID:
          return 1;
        case valueIds.FINANCE_ID:
          return 2;
        }
      }
    }

    return 0;
  };

  const statusQuery = useFetchGameStatus();

  useEffect(() => {
    if (statusQuery.isSuccess) {
      const animalValue = statusQuery.data.tribalTestResult && 
        findHighestRank(statusQuery.data.tribalTestResult.lifeValues);
      setAnimal(animalValue);
    }

    if (statusQuery.isError) {
      setAnimal(0);
    }
  }, [statusQuery.data, statusQuery.isError, statusQuery.isSuccess]);

  {/* Custom Level 5 */}
  const [openValueRating] = useModal(ValueRatingModal);
  const [close] = useModal();
  const [level5, setLevel5] = useState(false);

  {/* Custom Level 5 */}
  const [levelDone, setLevelDone] = useState(questLvl < user.level);
  const [surveyResultId, setSurveyResultId] = useState('');

  {/* Custom Level 7 */}
  const level7condition = user.maritalStatus && user.yearOfBirth && user.region && user.workType;

  {/* Custom Level 8 */}
  const level8condition = user.groups.length > 0;

  const runTest = (id:string) => {
    startValueTest(id, '')
      .then(result => history.push(createUrl('app.game', { id: result.id })))
      .catch((ex: IFetchException) =>
        // TODO better message
        ex.data.json().then((error: any) => alert(error.error)).catch(e => console.log(e)), // SyntaxError: Unexpected end of JSON input
      );
  };

  // trochu psycho, questVersion se mění trochu pozdě, takže trochu divná podmínka na enable query
  const surveyQuery = useFetchSurvey(data[questLvl].intro[questVersion] && data[questLvl].intro[questVersion].surveyID || '', 
    !!(data[questLvl].intro[questVersion] && data[questLvl].intro[questVersion].surveyID));
  useEffect(() => {
    if (surveyQuery.isSuccess) {
      const firstSurveyResult = surveyQuery.data.find(_ => true);
      if (firstSurveyResult) {
        setLevelDone(true);
        setSurveyResultId(firstSurveyResult.surveyResultId);
      }
    }

    if (surveyQuery.isError) {
      setLevelDone(false);
    }
  }, [surveyQuery.data, surveyQuery.isError, surveyQuery.isSuccess]);



  {/* Custom Level 34 */}
  const [openLevel34Modal] = useModal(Level34);


  return (
    <Fragment>
      {testMode ? (
        <Fragment>
          {setQuestVersion(0)}
          {setCustomString('')}

          <Modal
            surveyResultId={surveyResultId}
            state={customTestState}
          />

          <Box position='absolute' left='0' bottom='0' zIndex={100000}>
            {data[questLvl] && data[questLvl].preIntro && (
              <Button
                size='xs'
                onClick={() => setCustomTestState('preIntro')}
                bg='transparent'
                color='#fff'
                opacity={0.1}
              >
                preIntro
              </Button>
            )}
            <Button
              size='xs'
              onClick={() => setCustomTestState('intro')}
              bg='transparent'
              color='#fff'
              opacity={0.1}
            >
              i
            </Button>

            <Button
              size='xs'
              onClick={() => setCustomTestState('exit')}
              bg='transparent'
              color='#fff'
              opacity={0.1}
            >
              e
            </Button>

            <Button
              size='xs'
              onClick={() => setCustomTestState('summary')}
              bg='transparent'
              color='#fff'
              opacity={0.1}
            >
              s
            </Button>
          </Box>
        </Fragment>
      ) :
        questLvl === 0 ? (
          <Fragment>
            {eticonDone ?
              setQuestVersion(0)
              : setQuestVersion(1)
            }

            <Modal
              onAction={
                questVersion === 1 ? () => {
                  // runTest(TestTypeEnum.MOJE_HODNOTY);
                  history.push(`${createUrl('app.gameIntro')}?t=${TestTypeEnum.INTRO}`); //? workaround, mám 500 bez odpovědi z backu
                  setCustomString('exit');
                }
                  :
                  () => {
                    setInsideQuest(false);
                    setTimeout( () => setUserLvl(userLvl + 1), 500);
                    setTimeout( () => setQuestLvl(questLvl + 1), 500);
                    setTimeout( () => setQuestVersion(1), 500);
                  }
              }
              state={userLvl !== questLvl ? 'summary' : eticonDone && customString === 'exit' ? 'exit' : 'intro'}
            />
          </Fragment>
        ) : questLvl === 4 ? (
          <Fragment>
            {setQuestVersion(0)}
            {setCustomString('')}

            {introVisited || levelDone ? (
              <Fragment>
                {setQuestVersion(animal)}
                <Modal
                  surveyResultId={surveyResultId}
                  state={userLvl !== questLvl ? 'summary' : levelDone ? 'exit' : 'intro'}
                />
              </Fragment>
            ) : (
              <Fragment>
                {setQuestVersion(0)}
                <Modal
                  onAction={
                    () => {
                      setIntroVisited(true);
                      setQuestVersion(animal);
                    }
                  }
                  state='preIntro'
                />
              </Fragment>
            )}
          </Fragment>
        ) : questLvl === 5 ? (
          <Fragment>
            {setQuestVersion(0)}
            {setCustomString('')}

            <Level5 action={() => setLevel5(true)} close={close} level5={level5} />
            
            {/* <Fetch url={JOURNAL}>
              {_switch({
                success: (result: IJournal, reload: FetchReload) => {
                  const tribalTestResult = result.mains.slice(0, 1);

                  return (
                    <Modal
                      onAction={() =>
                        openValueRating({
                          id: tribalTestResult ? tribalTestResult[0].id : '',
                          _modal: {
                            maxWidth: '37.5rem',
                            maxHeight: '60rem',
                            padding: '6rem 2rem 3rem'
                          },
                          insideGame: true,
                          onAction: () => {
                            close();
                            setTimeout(() => setLevel5(true), 1000);
                          }
                        })
                      }
                      state={userLvl !== questLvl ?  'summary' : level5 ? 'exit' : 'intro' }
                    />
                  );},
                fetching: () => <LoadingSpinnerLocal />,
              })()}
            </Fetch> */}
          </Fragment>
        ) : questLvl === 7 ? (
          <Fragment>
            {setQuestVersion(0)}
            {setCustomString(level7condition ? 'profileInfoDone' : '')}

            <Modal
              onAction={
                () => {
                  history.push(createUrl('app.profile') + '/profile-info');
                  setCustomString('profileInfoDone');
                }
              }
              state={userLvl !== questLvl ? 'summary' : levelDone ? 'exit' : customString === 'profileInfoDone' ? 'intro' : 'preIntro'}
              statisticsModal={() => <TopValuesModal user={user || null} />}
            />
          </Fragment>
        ) : questLvl === 8 ? (
          <Fragment>
            {setQuestVersion(0)}
            {setCustomString(level8condition ? 'exit' : '')}

            <Modal
              onAction={
                () => {
                  history.push(createUrl('app.profile') + '/zajmove-skupiny');
                  setCustomString('exit');
                }
              }
              state={userLvl !== questLvl ? 'summary' : customString === 'exit' ? 'exit' : 'intro'}
              statisticsModal={() => <PositiveModal />}
            />
          </Fragment>
        ) : questLvl === 15 ? (
          <Fragment>
            {setQuestVersion(0)}
            {setCustomString('')}

            <Modal
              state={userLvl !== questLvl ? 'summary' : levelDone ? 'exit' : 'preIntro'}
              surveyResultId={surveyResultId}
            />
          </Fragment>
        ) : questLvl === 16 ? (
          <Fragment>
            {setQuestVersion(0)}

            <Modal
              state={userLvl !== questLvl ? 'summary' : customString === 'exit' ? 'exit' : introRan ? 'intro' : 'preIntro'}
              onAction={() => {
                setIntroRan(true);
              }}
              onIntroAction={() => {
                runTest(TestTypeEnum.IDEALNI_PARTAK_HRA);
                setTimeout(() => setCustomString('exit'), 500);
              }}
            />
          </Fragment>
        ) : questLvl === 20 ? (
          <Fragment>
            {setQuestVersion(0)}
            {setCustomString('')}

            <Modal
              state={userLvl !== questLvl ? 'summary' : levelDone ? 'exit' : 'intro'}
              statisticsModal={() => userLvl === questLvl && 
                !levelDone ? <GenderModal /> : <RelationshipModal />}
              surveyResultId={surveyResultId}
            />
          </Fragment> 
        ) : questLvl === 22 ? (
          <Fragment>
            {setQuestVersion(0)}
            {setCustomString('')}

            <Modal
              state={userLvl !== questLvl ? 'summary' : levelDone ? 'exit' : 'intro'}
              statisticsModal={() => <YoungOldModal />}
              surveyResultId={surveyResultId}
            />
          </Fragment>
        ) : questLvl === 34 ? (
          <Fragment>
            {setQuestVersion(0)}
            {setCustomString('')}

            <Modal
              state={userLvl !== questLvl ? 'summary' : levelDone ? 'exit' : 'intro'}
              onAction={() => openLevel34Modal({
                onExit: () => {
                  setLevelDone(true);
                  setTimeout(() => close(), 200);
                }
              })}

            />
          </Fragment>
        ) : questLvl === 35 ? (
          <Fragment>
            {setQuestVersion(0)}

            <Modal
              state={userLvl !== questLvl ? 'summary' : customString === 'exit' ? 'exit' : 'intro'}
              onAction={() => {
                runTest(TestTypeEnum.MOJE_SUPERSCHOPNOSTI_HRA);
                setTimeout(() => setCustomString('exit'), 500);
              }}
            />
          </Fragment>
        ) : data[questLvl].preIntro ? (
          <Fragment>
            {setQuestVersion(0)}
            {setCustomString('')}

            <Modal
              state={userLvl !== questLvl ? 'summary' : levelDone ? 'exit' : introRan ? 'intro' : 'preIntro'}
              surveyResultId={surveyResultId}
              onAction={() => setIntroRan(true)}
            />
          </Fragment>
        ) : (
          <Fragment>
            {setQuestVersion(0)}
            {setCustomString('')}

            <Modal
              surveyResultId={surveyResultId}
              state={userLvl !== questLvl ? 'summary' : levelDone ? 'exit' : 'intro'}
            />
          </Fragment>
        )
      }
    </Fragment>

  );
};

export default GameLevelModal;
