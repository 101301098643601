import styled from 'styled-components';
import { BackgroundedBox } from '../ValueComparison/Comparisons/styles';
import { StyledFakeLinkBorder, StyledLinkBorder, StyledSecondaryButtonButton } from '../../../atoms/Buttons/styles';
import { Grid, Row } from 'react-styled-flexboxgrid';
import { StyledTable } from '../../../atoms/Table/styles';
import { StyledGeneralPage } from '../../styles';
import { StyledFormGroup } from '../../../atoms/Form/styles';
import { StyledForm } from '../../../molecules/Form/styles';
import { gray, grayRGB } from '../../../theme/colors';
import { SecondaryButtonButton, SecondaryButtonLink } from '../../../atoms/Buttons';
import { mediaSmDefault } from '../../../theme/grid';

export const StyledProfilePage = styled(StyledGeneralPage)`
  ${BackgroundedBox} {
    font-size: 1.6rem;
    line-height: 2rem;
    width: 100%;

    ${StyledFakeLinkBorder} {
      margin-top: 3rem;
      font-size: 1.6rem;
      line-height: 2rem;
    }
  }

  & > ${Grid} > div > ${Row} {
    margin-top: 4rem;
  }

  .link-break {
    word-break: break-all;
  }

  ${StyledTable} {
    margin-top: 4rem;
  }

  .back {
    margin-top: 4rem;
    margin-left: 0 !important;
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  ${StyledForm} {
    ${StyledFormGroup} {
      margin-top: 3rem;

      &:first-child {
        margin-top: 0;
      }
    }

    ${StyledSecondaryButtonButton} {
      margin-top: 4rem;
    }
  }
`;

export const StyledSettingsCenter = styled.form`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 2rem;

  & > div {
    margin-top: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    ${mediaSmDefault} {
      margin-top: 0;
      flex-direction: row;
      min-height: 7rem;
    }

    & > div {
      font-size: 2rem;
      line-height: 2.6rem;
      text-align: center;

      &:first-child {
        margin-bottom: 1rem;
        color: ${gray};
        font-weight: 500;
      }

      ${mediaSmDefault} {
        text-align: left;
        width: 50%;
        padding: 0 2.5rem;

        &:first-child {
          margin-bottom: 0;
          text-align: right;
        }
      }
    }
  }

  .locked {
    color: rgba(${grayRGB}, 0.5);

    svg {
      margin-left: 2rem;
      vertical-align: middle;

      path {
        fill: rgba(255, 255, 255, 0.5);
      }
    }
  }

  ${StyledFakeLinkBorder}, ${StyledLinkBorder} {
    margin-left: 2rem;
    font-size: 1.6rem;
    line-height: 2rem;
  }

  .user {
    display: flex;
    align-items: center;
  }

  .user-img {
    display: inline-flex;
    overflow: hidden;
    width: 5rem;
    height: 5rem;
    align-items: center;
    justify-content: center;
    background-color: rgba(25, 29, 44, 0.25);
    border-radius: 50%;
  }

  .external-svg {
    display: block;
    width: 100%;
    height: 100%;

    svg {
      width: 100%;
      height: 100%;

      path {
        fill: #fff !important;
      }
    }
  }

  ${SecondaryButtonButton}, ${SecondaryButtonLink} {
    height: 5rem;
    min-height: 5rem;
  }
`;
