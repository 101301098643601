import { Box, Container, Flex, Grid, Heading2, Icon, Paragraph } from '@xcorejs/ui';
import { useUser } from 'components/atoms/hooks/useUser';
import CircleGroup from 'components/pages/App/Statistics/components/CircleGroup';
import Ladders from 'components/pages/App/Statistics/components/Ladders';
import StatisticsCard from 'components/pages/App/Statistics/components/StatisticsCard';
import StatisticsPage from 'components/pages/App/Statistics/index';
import GenderModal from 'components/pages/App/Statistics/modals/GenderModal';
import RelationshipModal from 'components/pages/App/Statistics/modals/RelationshipModal';
import YoungOldModal from 'components/pages/App/Statistics/modals/YoungOldModal';
import React, { FC } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { useFetchStatLifeValues } from 'utils/useQuery/queryFetchHelpers';
import SvetHodnotIcon from '../../../../components/icons/48/svethodnot.svg';

const General: FC = () => {
  const user = useUser();
  const lifeValuesQuery = useFetchStatLifeValues();

  return (
    <StatisticsPage>
      <Container flexDirection='column' maxWidth='85rem'>
        <Flex flexDirection='column' alignItems='center' pt='4rem'>
          <Flex alignItems='center' flexDirection={{ _: 'column', sm: 'row' }}>
            <Icon svg={<SvetHodnotIcon />} />

            <Heading2
              as='h1'
              ml={{ _: 0, sm: '2rem' }}
              mt={{ _: '2rem', sm: 0 }}
            >
              <FormattedHTMLMessage id='statistics.site2.other.head1' />
            </Heading2>
          </Flex>

          <Paragraph mt='1rem'>
            <FormattedHTMLMessage id='statistics.site2.other.text' />
          </Paragraph>

          <Flex justifyContent='center' mt='3rem'>
            <FormattedHTMLMessage id='statistics.site.other.result1.img'>
              {imgUrl => (
                <StatisticsCard
                  heading={<FormattedHTMLMessage id='statistics.site.other.result1.head1' />}
                  _heading={{ color: 'yellow', fontWeight: 800 }}
                  paragraph={<FormattedHTMLMessage id='statistics.site.other.result1.text1' />}
                  imgUrl={imgUrl as string}
                  noSubheading
                  noLink
                  narrowPadding
                >
                  {lifeValuesQuery.isSuccess && (
                    <Box>
                      <Ladders values={lifeValuesQuery.data} user={user} />
                    </Box>
                  )}
                </StatisticsCard>
              )}
            </FormattedHTMLMessage>
          </Flex>

          <Heading2 mt='6rem'>
            <FormattedHTMLMessage id='statistics.site2.other.head2' />
          </Heading2>

          <Paragraph mt='1rem'>
            <FormattedHTMLMessage id='statistics.site2.other.text2' />
          </Paragraph>

          <Grid gap='3rem' columns={{ _: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }} rows='auto' mt='3rem'>
            <StatisticsCard
              heading={<FormattedHTMLMessage id='statistics.site.other.result2.head1' />}
              paragraph={<FormattedHTMLMessage id='statistics.site.other.result2.text1' />}
              modalComponent={() => <YoungOldModal />}
              onlyPlusOrGame
              minGameLevelToSee={23}
            >
              <Flex alignItems='flex-start' justifyContent='space-evenly' mt='2rem'>
                <FormattedMessage id='statistics.site.other.result2.image1'>
                  {message => (
                    <CircleGroup
                      name={<FormattedHTMLMessage id='statistics.site.other.result2.head1' />}
                      colorScheme='green'
                      img={message as string}
                      onlyPlusOrGame
                      minGameLevelToSee={23}
                    />
                  )}
                </FormattedMessage>
              </Flex>
            </StatisticsCard>

            <StatisticsCard
              heading={<FormattedHTMLMessage id='statistics.site.other.result3.head1' />}
              paragraph={<FormattedHTMLMessage id='statistics.site.other.result3.text1' />}
              modalComponent={() => <RelationshipModal />}
              onlyPlusOrGame
              minGameLevelToSee={21}
            >
              <Flex alignItems='flex-start' justifyContent='space-evenly' mt='2rem'>
                <FormattedHTMLMessage id='statistics.site.other.result3.text1'>
                  {message => (
                    <CircleGroup
                      name={<FormattedHTMLMessage id='statistics.site.other.result3.head1' />}
                      colorScheme='green'
                      img={message as string}
                      onlyPlusOrGame
                      minGameLevelToSee={21}
                    />
                  )}
                </FormattedHTMLMessage>
              </Flex>
            </StatisticsCard>
          </Grid>

          <Heading2 mt='6rem'>
            <FormattedHTMLMessage id='statistics.site2.other.head3' />
          </Heading2>

          <Paragraph mt='1rem'>
            <FormattedHTMLMessage id='statistics.site2.other.text3' />
          </Paragraph>

          <Grid gap='3rem' columns={{ _: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' }} rows='auto' mt='3rem'>
            <StatisticsCard
              heading={<FormattedHTMLMessage id='statistics.site.other.result4.head1' />}
              paragraph={<FormattedHTMLMessage id='statistics.site.other.result4.text1' />}
              modalComponent={() => <GenderModal />}
              onlyPlusOrGame
              minGameLevelToSee={21}
            >
              <Flex alignItems='flex-start' justifyContent='space-evenly' mt='2rem' {...{ style: { gap: '2rem' } }}>
                <FormattedMessage id='statistics.site.other.result4.image1'>
                  {message => (
                    <CircleGroup
                      name={<FormattedHTMLMessage id='statistics.site.other.result4.circle1' />}
                      colorScheme='green'
                      img={message as string}
                      onlyPlusOrGame
                      minGameLevelToSee={21}
                    />
                  )}
                </FormattedMessage>

                <FormattedMessage id='statistics.site.other.result4.image2'>
                  {message => (
                    <CircleGroup
                      name={<FormattedHTMLMessage id='statistics.site.other.result4.circle2' />}
                      colorScheme='gray'
                      img={message as string}
                      onlyPlusOrGame
                      minGameLevelToSee={21}
                    />
                  )}
                </FormattedMessage>
              </Flex>
            </StatisticsCard>

            {/*
            <StatisticsCard
              heading={<FormattedMessage id='statistics.site.other.result5.head1' />}
              paragraph={<FormattedMessage id='statistics.site.other.result5.text1' />}
              subheading={<FormattedMessage id='statistics.site.other.result5.subheading' />}
              linkText={<FormattedMessage id='statistics.site.other.result5.linkText' />}
              modalComponent={() => <AchValuesModal />}
            >
              <Flex alignItems='flex-start' justifyContent='space-evenly' mt='2rem'>
                <FormattedMessage id='statistics.site.other.result5.image1'>
                  {message => (
                    <CircleGroup
                      name={<FormattedHTMLMessage id='statistics.site.other.result5.circle1' />}
                      colorScheme='green'
                      img={message as string}
                    />
                  )}
                </FormattedMessage>

                <FormattedMessage id='statistics.site.other.result5.image2'>
                  {message => (
                    <CircleGroup
                      name={<FormattedHTMLMessage id='statistics.site.other.result5.circle2' />}
                      colorScheme='green'
                      img={message as string}
                    />
                  )}
                </FormattedMessage>

                <FormattedMessage id='statistics.site.other.result5.image3'>
                  {message => (
                    <CircleGroup
                      name={<FormattedHTMLMessage id='statistics.site.other.result5.circle3' />}
                      colorScheme='green'
                      img={message as string}
                    />
                  )}
                </FormattedMessage>
              </Flex>
            </StatisticsCard>
            */}
          </Grid>
        </Flex>
      </Container>
    </StatisticsPage>
  );
};

export default General;
