import * as React from 'react';
import { Fragment } from 'react';

export interface IPoint {
  points: number[];
  animate?: AnimationSetting;
  fill: string;
  fillOpacity: string;
  stroke: string;
  strokeWidth: string;
}

export interface AnimationSetting {
  duration: string;
  from: string;
  fill?: string;
}

const x = (p: number, i: number, count: number) =>
  Math.round(205 + (((205 * p) / 100) * Math.cos((Math.PI / (count / 2)) * (-i + 1)) * 100) / 80);
const y = (p: number, i: number, count: number) =>
  Math.round(205 - (((205 * p) / 100) * Math.sin((Math.PI / (count / 2)) * (-i + 1)) * 100) / 80);

export const pointsToString = (points: number[]) =>
  points.map((p, i) => x(p, i, points.length) + ',' + y(p, i, points.length)).join(' ');

export const mergeTopLetter = (points: IPoint[]) =>
  points
    .map(x =>
      x.points
        .map((p, i) => ({ i, p }))
        .sort((a, b) => b.p - a.p)
        .map(p => p.i)
        .slice(0, 3),
    )
    .map((p, i) => ({ valueIndex: i, topValues: p }))
    .reduce((prev, curr) => {
      const result = [...prev];
      curr.topValues.forEach(x =>
        result.some(a => a.index === x)
          ? result.find(a => a.index === x)!.pointPropperty.push(curr.valueIndex)
          : result.push({ index: x, pointPropperty: [curr.valueIndex] }),
      );
      return result;
    }, [] as ILetterPropperty[]);

interface ILetterPropperty {
  index: number;
  pointPropperty: number[];
}

// eslint-disable-next-line
const wrapLetterColor = (points: IPoint[], letterColor?: "green" | "blue" | "both") =>
  letterColor === 'both'
    ? mergeTopLetter(points).map(x => {
      return {
        index: x.index,
        color: x.pointPropperty.length > 1 ? 'both' : x.pointPropperty[0] === 0 ? 'green' : 'blue',
      };
    })
    : undefined;

const pointsWrapper = (points1: number[], points2: number[]) =>
  points1.map((value, index) => Math.max(value, points2[index]));

const practicalIndexWrapper = (points1: number[], points2: number[], practical: number[]) =>
  pointsWrapper(points1, points2)
    .map((value, index) => ({ value, index }))
    .filter((_, index) => practical[index] > PracticalPercents[index])
    .map(value => value.index);

const characterIndexWrapper = (points1: number[], points2: number[]) =>
  pointsWrapper(points1, points2)
    .map((value, index) => ({
      value,
      index,
      point1: points1[index],
      point2: points2[index],
    }))
    .filter(value => Math.max(value.point1, value.point2) * characterPercent < Math.min(value.point1, value.point2))
    .map(x => x.index);

const indexWrappers = [
  (points: IPoint[], _: number[]) => characterIndexWrapper(points[0].points, points[1].points), // eslint-disable-line
  (points: IPoint[], practical: number[]) => practicalIndexWrapper(points[0].points, points[1].points, practical),
];
export const PointsToIndexWrapper = (data: { index: number; additions: number[] }) => (points: IPoint[]) =>
  indexWrappers[data.index](points, data.additions);

const practicalWrapper = (points1: number[], points2: number[], practical: number[]) =>
  pointsWrapper(points1, points2)
    .map((value, index) => ({ value, index }))
    .filter((_, index) => practical[index] > PracticalPercents[index])
    .map(value => ({
      x: x(value.value, value.index, points1.length),
      y: y(value.value, value.index, points1.length),
    }));

const characterWrapper = (points1: number[], points2: number[]) =>
  pointsWrapper(points1, points2)
    .map((value, index) => ({
      value,
      index,
      point1: points1[index],
      point2: points2[index],
    }))
    .filter(value => Math.max(value.point1, value.point2) * characterPercent < Math.min(value.point1, value.point2))
    .map(value => ({
      x: x(value.value, value.index, points1.length),
      y: y(value.value, value.index, points1.length),
      important: value.value > characterMinPercent,
    }));

const wrappers = [
  (points: IPoint[], _: number[]) => // eslint-disable-line
    characterWrapper(points[0].points, points[1].points).map((point, key) => (
      <Fragment key={key}>
        <circle
          cx={point.x}
          cy={point.y}
          r='16'
          stroke={point.important ? 'rgba(227,210,64,0.26)' : 'rgba(227,210,64,0.26)'}
          fill='transparent'
          strokeWidth='6px'
        />
        <circle cx={point.x} cy={point.y} r='12' fill={point.important ? '#ffde71' : '#ffde71'} />
      </Fragment>
    )),
  (points: IPoint[], practical: number[]) =>
    practicalWrapper(points[0].points, points[1].points, practical).map((point, key) => (
      <Fragment key={key}>
        <circle cx={point.x} cy={point.y} r='16' fill='transparent' stroke='rgba(244,244,246,0.25)' strokeWidth='6px' />
        <circle cx={point.x} cy={point.y} r='12' fill='white' />
      </Fragment>
    )),
];

export const PointsToCircleWrapper = (data: { index: number; additions: number[] }) => (points: IPoint[]) =>
  wrappers[data.index](points, data.additions);

const characterPercent = 0.701;
const characterMinPercent = 38.3;

// percents are ordere correspond to letters
const PracticalPercents = [93, 80, 80, 80, 80, 93, 93, 80];

// Letter are ordered to correspong with order of points
export const letters = [
  {
    letter: 'B',
    class: 'e',
    type: 'Otevřený',
    percentage: '38,2 %',
    tooltip: 'Otevřený',
    index: 0,
  },
  {
    letter: 'C',
    class: 'r',
    type: 'Racio',
    percentage: '38,2 %',
    tooltip: 'Racionální',
    index: 1,
  },
  {
    letter: 'D',
    class: 's',
    type: 'Plánovací',
    percentage: '38,2 %',
    tooltip: 'Plánovací',
    index: 2,
  },
  {
    letter: 'E',
    class: 'c',
    type: 'Sociální',
    percentage: '38,2 %',
    tooltip: 'Sociální',
    index: 3,
  },
  {
    letter: 'F',
    class: 'v',
    type: 'Hedonický',
    percentage: '38,2 %',
    tooltip: 'Hedonický',
    index: 4,
  },
  {
    letter: 'G',
    class: 'm',
    type: 'Empatie',
    percentage: '38,2 %',
    tooltip: 'Empatický',
    index: 5,
  },
  {
    letter: 'H',
    class: 'i',
    type: 'Introvertní',
    percentage: '38,2 %',
    tooltip: 'Introvertní',
    index: 6,
  },
  {
    letter: 'A',
    class: 'f',
    type: 'Rodinný',
    percentage: '38,2 %',
    tooltip: 'Rodinný',
    index: 7,
  },
];

export const backgroundImage = [
  <img key={0} className='axes' src={'/img/backgrounds/graph-bg.svg'} alt='graph ico' />,
  <img key={1} className='axes' src={'/img/backgrounds/graph-bg-practical.svg'} alt='graph ico' />,
];
