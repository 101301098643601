import LoadingSpinnerLocal from 'components/atoms/Animations/LoadingSpinnerLocal';
import { IValueResult } from 'config/api/valueTestResult';
import React, { FC } from 'react';
import { normalize } from 'utils/helpers';
import { useFetchLifeValues } from 'utils/useQuery/queryFetchHelpers';


interface Props {
  allLifeValues: IValueResult[];
}

const OrderedValues: FC<Props> = ({ allLifeValues }) => {
  const lifeValuesQuery = useFetchLifeValues();

  if (lifeValuesQuery.isLoading) {
    return <LoadingSpinnerLocal />;
  }

  if (lifeValuesQuery.isError) {
    console.log(lifeValuesQuery.error);
    return <></>;
  }

  const lifeValues = normalize(lifeValuesQuery.data!, ['id']);
  
  return (
    <div className='values'>
      {allLifeValues
        .sort((a, b) => a.rank - b.rank)
        .slice(0, 10)
        .map(l => lifeValues[l.valueId] ? lifeValues[l.valueId].name : null)
        .map((l, i) =>
          <span key={i}>{l}</span>
        )}
    </div>
  );};
export default OrderedValues;
