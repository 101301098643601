import { API_URL } from '../apiRoutes';
import { IValueTestResultDetail } from './valueTestResult';

const GAME = API_URL + '/game';

export const GAME_STATUS = GAME + '/status';

export interface ISimpleSurveyVm {
  id: string;
  lifeValueId: string;
  done: boolean;
  activeResultId: string | null;
}

export interface IGameStatus {
  tribalTestResult: IValueTestResultDetail;
  availableSurveys: {
    lifeValues: ISimpleSurveyVm[];
    feedback: ISimpleSurveyVm;
    ethical: ISimpleSurveyVm;
    tribalTestHighestLifeValues: ISimpleSurveyVm[];
    tribalTestLowestLifeValues: ISimpleSurveyVm[];
  };
  invitationsSentCount: number;
}
