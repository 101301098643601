import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import Fade from 'react-reveal/Fade';
import SVGclosegray from '../../SVGIcons/SVGclosegray';
import { StyledHelpPopup } from './styles';

interface IPopup {
  onClose?: () => void;
  title?: string;
  className?: string;
}

const HelpPopup: React.FC<IPopup> = ({ onClose, children, title, className }) => {
  return (
    <StyledHelpPopup className={className}>
      <Fade ssrReveal bottom duration={1000} distance={'5rem'}>
        <div className='content'>
          <a className='close' onClick={onClose}>
            <SVGclosegray />
          </a>
          <h2 className='popup-title'>
            {title !== null && <FormattedMessage id={title ? title : 'popup.default.title'} />}
          </h2>
          {children}
        </div>
      </Fade>
    </StyledHelpPopup>
  );
};

export default HelpPopup;
