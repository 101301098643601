import { CarouselProps } from 'components/components/Carousel';

type HPCarouselProps = Pick<CarouselProps, 'img' | 'imgActive' | 'heading' | 'boxText' | 'isPlus'>;

export const valuesData: HPCarouselProps[] = [
  {
    img: '20_Superschopnosti_fin.png',
    imgActive: 'Superschopnosti.png',
    heading: {
      cs: '<span style="color: #4d6aa1">Superschopnosti</span>',
      en: '<span style="color: #4d6aa1">Superpowers</span>',
    },
    boxText: {
      cs: 'Je na čase objevit své skryté vlohy. Každý v něčem vyniká. Znáš dobře své silné stránky? Tohle není klasický hodnotový žebříček, ale ocenit občas sám sebe je velmi důležité!',
      en: 'It is time to discover your hidden talents. Everyone excels at something. Do you know your strengths well? This is not a classic value ladder, but appreciating yourself is very important!',
    },
    isPlus: true,
  },
  {
    img: '20_partner_fin.png',
    imgActive: 'Idealni_partner.png',
    heading: {
      cs: '<span style="color: #4d6aa1">Ideální partner</span>',
      en: '<span style="color: #4d6aa1">Ideal partner</span>',
    },
    boxText: {
      cs: 'Jaké hodnoty by měl podle tebe mít? Abys mohl svůj ideál potkat, musíš nejdřív své představy o něm správně pojmenovat.',
      en: 'What values should he have according to you? To be able to meet your ideal, you must first name your ideas about him correctly.',
    },
    isPlus: true,
  },
  {
    img: '20_zivot_fin.png',
    imgActive: 'zivotni_i.png',
    heading: {
      cs: '<span style="color: #4d6aa1">Životní hodnoty</span>',
      en: '<span style="color: #4d6aa1">Life values</span>',
    },
    boxText: {
      cs: 'Svět se mění a my s ním. Sleduj vývoj svých osobních hodnot dlouhodobě. Každoroční aktualizaci svého hodnotového žebříčku získáš v Eticia <span style="color: #4d6aa1"><strong>zdarma.</strong></span>',
      en: 'The world is changing and so are we. Monitor the development of your personal values in the long term. You will get an annual update of your value ladder in Eticia <span style="color: #4d6aa1"><strong>for free.</strong></span>',
    },
  },
  {
    img: '50_spokojenost.png',
    imgActive: 'yel_spokojenost.png',
    heading: {
      cs: '<span style="color: #4d6aa1">Moje spokojenost</span>',
      en: '<span style="color: #4d6aa1">My satisfaction</span>',
    },
    boxText: {
      cs: 'Jak být spokojený? Může to být překvapivě jednoduché. Tento žebříček ti pomůže najít hodnoty, které jsou pro tebe zdrojem štěstí a pohody.',
      en: 'How to be satisfied? It can be surprisingly simple. This ladder will help you find values that are a source of happiness and comfort for you.',
    },
    isPlus: true,
  },
  {
    img: '20_deti_fin.png',
    imgActive: 'rodice_deti.png',
    heading: {
      cs: '<span style="color: #4d6aa1">Hodnoty pro děti</span>',
      en: '<span style="color: #4d6aa1">Values for children</span>',
    },
    boxText: {
      cs: 'Děti hodnoty milují. Pro uživatele do 16 let nabízí Eticia speciální, upravenou verzi žebříčku. Své hodnoty mohou děti objevovat samostatně nebo v rámci školní výuky apod.',
      en: 'Children love values. Eticia offers a special, modified version of the ladder for users up to 16 years of age. Children can discover their values independently or as part of school education, etc.',
    },
    isPlus: false,
  },
];

type EticonCarouselProps = {
  name: string;
  values: string;
  image: string;
};

export const eticonData: EticonCarouselProps[] = [{
  name: 'Fenix',
  values: 'Mythical bird',
  image: 'fenix.png',
},
{
  name: 'Kapka',
  values: 'Water drop',
  image: 'kapka.png',
},
{
  name: 'Kompas',
  values: 'Navigation tool',
  image: 'kompas.png',
},
{
  name: 'Lyra',
  values: 'Musical instrument',
  image: 'lyra.png',
},
{
  name: 'Most',
  values: 'Bridge',
  image: 'most.png',
},
{
  name: 'Paleta',
  values: 'Paint palette',
  image: 'paleta.png',
},
{
  name: 'Planeta',
  values: 'Planet',
  image: 'planeta.png',
},
{
  name: 'Srdce',
  values: 'Heart',
  image: 'srdce.png',
},
{
  name: 'Trojlistek',
  values: 'Clover',
  image: 'trojlistek.png',
},
{
  name: 'Vlna',
  values: 'Wave',
  image: 'vlna.png',
},
{
  name: 'Zvon',
  values: 'Bell',
  image: 'zvon.png',
},
{
  name: 'Hrad',
  values: 'Castle',
  image: 'hrad.png',
},
{
  name: 'Klic',
  values: 'Key',
  image: 'klic.png',
},
{
  name: 'Kormidlo',
  values: 'Rudder',
  image: 'kormidlo.png',
},
{
  name: 'Majak',
  values: 'Lighthouse',
  image: 'majak.png',
},
{
  name: 'Nebe',
  values: 'Sky',
  image: 'nebe.png',
},
{
  name: 'Pegas',
  values: 'Pegasus',
  image: 'pegas.png',
},
{
  name: 'Pristav',
  values: 'Harbor',
  image: 'pristav.png',
},
{
  name: 'Strom',
  values: 'Tree',
  image: 'strom.png',
},
{
  name: 'Vaha',
  values: 'Scales',
  image: 'vaha.png',
},
{
  name: 'Vlocka',
  values: 'Snowflake',
  image: 'vlocka.png',
},
{
  name: 'Hvezda',
  values: 'Star',
  image: 'hvezda.png',
},
{
  name: 'Kometa',
  values: 'Comet',
  image: 'kometa.png',
},
{
  name: 'Krb',
  values: 'Fireplace',
  image: 'krb.png',
},
{
  name: 'Mesic',
  values: 'Moon',
  image: 'mesic.png',
},
{
  name: 'Nota',
  values: 'Musical note',
  image: 'nota.png',
},
{
  name: 'Plamen',
  values: 'Flame',
  image: 'plamen.png',
},
{
  name: 'Slunce',
  values: 'Sun',
  image: 'slunce.png',
},
{
  name: 'Svetlo',
  values: 'Light',
  image: 'svetlo.png',
},
{
  name: 'Vez',
  values: 'Tower',
  image: 'vez.png',
},
{
  name: 'Zeme',
  values: 'Earth',
  image: 'zeme.png',
}, ];
