import * as React from 'react';
import Step1 from './Step1';
import Step2 from './Step2';
import LoadingSpinner from '../../../../../atoms/Animations/LoadingSpinner';
import { IStep1Data, IStep2Data, submitShirt } from './actions';
import Done from './Done';
import withUser from '../../../../../molecules/withRedux/withUser';
import { IWithUser } from '../../../../../molecules/withRedux/withUser';

interface IShirtForm {
  popup: (body: React.ComponentType, button?: string) => any;
  updateSlider: (type: number) => any;
}

interface IShirtFormState {
  step: number;
  terms: boolean;
  step1: IStep1Data;
  step2: IStep2Data;
}

class ShirtForm extends React.Component<IShirtForm & IWithUser, IShirtFormState> {
  public state: IShirtFormState = {
    step: 1,
    terms: false,
    step1: {
      color: '',
      size: '',
      price: '',
    },
    step2: {
      name: '',
      surname: '',
      email: this.props.user !== null ? this.props.user.email : '',
      street: '',
      zip: '',
      city: '',
    },
  };

  public render() {
    switch (this.state.step) {
    case 1:
      return (
        <Step1
          data={this.state.step1}
          handleInput={this.handleStep1}
          setState={(step1: IStep1Data) => this.setState({ step1 })}
          submit={() => this.setState({ step: 2 })}
        />
      );
    case 2:
      return (
        <Step2
          data={this.state.step2}
          handleInput={this.handleStep2}
          submit={this.submit}
          terms={this.state.terms}
          acceptTerms={() => this.setState({ terms: !this.state.terms })}
          popup={this.props.popup}
        />
      );
    case 3:
      return <LoadingSpinner />;
    case 4:
      return <Done />;
    }
  }

  private handleStep1 = (name: string, value: string) => {
    if (name === 'color') {
      this.props.updateSlider(Number.parseInt(value, 10));
    }
    this.setState({
      step1: {
        ...this.state.step1,
        [name]: value,
      },
    });
  };

  private handleStep2 = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({
      step2: {
        ...this.state.step2,
        [e.target.name]: e.target.value,
      },
    });

  private submit = () => {
    this.setState({ step: 3 });
    submitShirt({
      ...this.state.step1,
      ...this.state.step2,
      currency: 'CZK',
    })
      .then(() => this.setState({ step: 4 }))
      .catch(() => this.setState({ step: 2 }));
  };
}

export default withUser(ShirtForm);
