import * as React from 'react';
import { IAdditionalQuestion, ISurveyContinousRatingQuestion } from '../../data';
import { IResponse } from '../../data';
import { Row, Col } from 'react-styled-flexboxgrid';
import { Answers, RangeDiv, InputRange } from '../../styles';
import { FormattedMessage } from 'react-intl';

interface IContinuous {
  question: IAdditionalQuestion<ISurveyContinousRatingQuestion>;
  response: IResponse<number>;
  onChange: (response: IResponse<{}>) => void;
  theme?: string;
}

class Continuous extends React.Component<IContinuous> {
  public render() {
    const { question, response } = this.props;
    return (
      <Row>
        <Col xs={12}>
          {question.data.text && <h1>{question.data.text}</h1>}
          {question.help.title !== null && <p>{question.help.title}</p>}

          <Answers>
            <RangeDiv>
              {question.data.image && <img src={question.data.image} alt='' />}

              <div className='flex'>
                <div>
                  <span>{question.data.ratingFromText}</span>
                  <span>{question.data.ratingMiddleText}</span>
                  <span>{question.data.ratingToText}</span>
                </div>

                <InputRange
                  min={question.data.ratingFromValue}
                  max={question.data.ratingToValue}
                  value={
                    response.data === null
                      ? (question.data.ratingFromValue + question.data.ratingToValue) / 2
                      : response.data
                  }
                  step={1}
                  type='range'
                  onChange={this.onClick}
                />
              </div>

              <span className={'value'}>
                {response.data ? (
                  <>
                    <FormattedMessage id={'test.continous.chosen'} />{' '}
                  </>
                ) : (
                  <></>
                )}
                {response.data}
              </span>
            </RangeDiv>
          </Answers>
        </Col>
      </Row>
    );
  }

  private onClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onChange({ data: e.target.value, submitable: true });
  };
}

export default Continuous;
