import styled, { css } from 'styled-components';
import {
  blue,
  darkBaseHEX,
  darkBaseRGB,
  yellow,
  yellowLighter,
  primaryBlue,
  primaryGreen,
  RedTheme,
  GreenTheme,
  BlueTheme,
  violet,
  darkenViolet,
  GoldTheme,
  helpIcon,
  helpIconHover
} from '../../theme/colors';
import { CustomText } from '../Text';
import { StyledSecondaryButton, StyledPrimaryButton } from '../Buttons/styles';
import { mediaSmDefault, mediaSmMax } from '../../theme/grid';
import { bold } from '../../theme/fonts';
import { Heading2 } from '../Headings';
import { IPopupLink } from './HelpLink';

export const StyledHelpLink = styled.a<IPopupLink>`
  position: relative;
  display: inline-block;
  width: ${props => props.size ? props.size + 'rem' : '5rem'};
  height: ${props => props.size ? props.size + 'rem' : '5rem'};
  border-radius: 50%;
  text-align: center;
  transition: background-color 300ms ease;
  flex: 0 0 auto;

  &:before {
    content: "";
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 50%;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  &:hover {
    background-color: rgba(${helpIconHover}, 0.5);
  }
  
  ${props => props.variant === 'normal' && css`
    background: rgba(${helpIcon}, 0.5);
    
    &:before {
      background-image: url(/img/ico/info.svg);
    }
  `}
  
  ${props => props.variant === 'outline' && css`
    background: transparent;
    border: 1px solid ${yellow};
    
    &:before {
      background-image: url(/img/ico/infoWhite.svg);
    }
  `}

  ${({ mdSize }) => mdSize && css`
    ${mediaSmDefault} {
      width: ${mdSize}rem;
      height: ${mdSize}rem;
    }
  `}
`;

export const StyledPopupBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(${darkBaseRGB}, 0.5);
  z-index: 1004;
  text-align: center;
  white-space: nowrap;
  touch-action: none;

  &:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: 0;
  }
`;

export const StyledBetaPopup = styled.div`
  ${CustomText} {
    text-align: center;
    color: ${darkBaseHEX};
  }

  ${StyledSecondaryButton} {
    margin-top: 3rem;
    color: ${props => props.theme.primary};
  }
`;

export const StyledMobilePopup = styled.div`
  max-height: 100%;
  overflow-y: scroll;

  img {
    display: block;
    margin: 2rem auto;
  }

  .textBlue {
    color: ${blue};
  }

  .footer {
    margin-top: 2rem;
    font-size: 1.3rem;
    line-height: 1.6rem;
  }
`;

export const StyledBasePopup = styled.div<{
  color?: 'red' | 'blue' | 'yellow' | 'green' | 'purple';
  width?: string;
  maxWidth?: string;
}>`
  padding: 3rem 2rem 4rem;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 4rem);
  max-width: 65rem;
  max-height: calc(100vh - 4rem);
  margin: 0 auto;
  white-space: normal;
  overflow: auto;

  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}

  ${props =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth};
    `};

  ${mediaSmDefault} {
    padding: 3rem 6rem 4rem;
  }

  ${StyledPrimaryButton} {
    margin-top: 3rem;
    background: transparent;
    border: 2px solid ${yellow};
    color: #fff;
    width: 100%;
    max-width: 28rem;
    padding-left: 2rem;
    padding-right: 2rem;

    ${mediaSmMax} {
      margin-top: 1rem;
    }

    &:hover {
      background: ${yellow};
      color: ${darkBaseHEX};
    }
  }

  .external-svg {
    svg {
      display: block;
      width: 4.8rem;
      height: 4.8rem;
      max-height: 4.8rem;
      margin: 0 auto;

      path {
        fill: ${primaryBlue};
      }
    }
  }

  ${Heading2} {
    margin-top: 2rem;
    color: ${primaryBlue};
    font-size: 2.4rem;
    line-height: 3.2rem;

    ${mediaSmMax} {
      margin-top: 1rem;
    }
  }

  h3 {
    margin-top: 3rem;
    color: ${darkBaseHEX};
    font-size: 1.8rem;
    line-height: 2.4rem;
    font-weight: ${bold};

    ${mediaSmMax} {
      margin-top: 2rem;
    }

    &.clicked {
      color: ${primaryBlue};
      cursor: pointer;
    }
  }

  p {
    margin-top: 2rem;
    color: ${darkBaseHEX};
    font-size: 1.6rem;
    line-height: 2.4rem;

    ${mediaSmMax} {
      margin-top: 1rem;
    }
  }

  .faq-next {
    margin-top: 5rem;

    ${mediaSmMax} {
      margin-top: 3rem;
    }

    h3 {
      margin-top: 0;

      ${mediaSmMax} {
        display: none;
      }
    }

    ${StyledPrimaryButton} {
      margin-top: 1.5rem;
      margin-bottom: -1.5rem;
      border: transparent;
      padding: 0;
      height: auto;
      color: ${primaryBlue};

      span {
        border-bottom: 1px solid ${primaryBlue};
      }

      &:hover {
        background: transparent;

        span {
          border-bottom: 1px solid transparent;
        }
      }

      ${mediaSmMax} {
        margin-top: 0;
        margin-bottom: 1rem;
      }
    }
  }

  .green {
    margin-top: 4rem;

    p {
      color: ${primaryGreen};
      font-size: 2.4rem;
      line-height: 3.2rem;
    }
  }

  .icons {
    .external-svg:not(:first-child) {
      margin-top: 4rem;
    }

    h3 {
      margin-top: 1rem;
    }
  }

  ${props =>
    props.color === 'red' &&
    css`
      background: radial-gradient(circle at top, ${RedTheme.primary} 0%, ${RedTheme.darken} 100%);
    `};

  ${props =>
    props.color === 'green' &&
    css`
      background: radial-gradient(circle at top, ${GreenTheme.primary} 0%, ${GreenTheme.darken} 100%);
    `};

  ${props =>
    props.color === 'blue' &&
    css`
      background: radial-gradient(circle at top, ${BlueTheme.primary} 0%, ${BlueTheme.darken} 100%);
    `};

  ${props =>
    props.color === 'yellow' &&
    css`
      background: radial-gradient(circle at top, ${GoldTheme.primary} 0%, ${GoldTheme.darken} 100%);
    `};

  ${props =>
    props.color === 'purple' &&
    css`
      background: radial-gradient(circle at top, ${violet} 0%, ${darkenViolet} 100%);

      ${Heading2} {
        color: #fff;
      }
    `};
`;

export const StyledCloseButton = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 4rem;
  height: 4rem;
  top: 1.5rem;
  right: 1.5rem;
  border: 2px solid ${yellow};
  border-radius: 50%;
  cursor: pointer;

  svg {
    width: 1.7rem;
    height: 1.7rem;
  }
`;

export const StyledPopupButton = styled.span`
  cursor: pointer;
`;
