import styled from 'styled-components';
import { mediaSmDefault } from '../../theme/grid';
import { StyledFakeLinkBorder } from '../../atoms/Buttons/styles';

export const StyledImgSlider = styled.div`
  user-select: none;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .slide {
    width: 29rem;
    margin: 0 1rem;
    
    @media (min-width: 480px) {
      width: 32rem;
    }
  }

  .after {
    svg {
      transform: rotate(180deg);
    }

    &:hover {
      transform: scale(1.1) rotate(180deg);
    }
  }

  .before,
  .after {
    cursor: pointer;
    transition: transform 0.3s;

    svg {
      width: 2rem;

      ${mediaSmDefault} {
        width: 2.4rem;
      }
    }

    &:hover {
      transform: scale(1.2);
    }
  }

  ${StyledFakeLinkBorder} {
    font-size: 1.5rem;
  }
`;
