import { typography } from '@xcorejs/ui';

export const typographyTheme = typography({
  default: {
    fontFamily: 'OliLight',
    m: 0,
    textAlign: 'center'
  },
  variants: {
    p: {
      fontSize: '1.5rem',
      lineHeight: '2.2rem',
      color: 'rgba(255, 255, 255, 0.7)',
      fontFamily: 'OliRegular'
    },
    h1: {
      fontSize: { _: '3.2rem', md: '4rem' },
      fontWeight: 400,
      lineHeight: { _: '4.1rem', md: '5.1rem' },
      textAlign: { _: 'center', md: 'left' },
      color: '#e7e6cf'
    },
    h2: {
      fontSize: { _: '2.5rem', md: '3rem' },
      fontWeight: 400,
      lineHeight: { _: '2.7rem', md: '4.5rem' },
      color: '#e7e6cf'
    },
    h3: {
      fontSize: { _: '2rem' },
      lineHeight: { _: '2.2rem' },
      fontWeight: 400,
      color: '#e7e6cf',
      textAlign: 'center'
    },
    h4: {
      fontSize: '2rem',
      lineHeight: '3rem',
      fontWeight: 400,
      color: '#e7e6cf'
    },
    lead: {
      color: '#fff',
      fontSize: { _: '1.8rem', sm: '2.4rem' },
      lineHeight: { _: '2.7rem', sm: '3.6rem' }
    }
  }
});
