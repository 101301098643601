import React, { FC } from 'react';
import TestTipCard, { TestTipCardProps } from './TestTipCard';
import { Flex, Heading1, Heading3, Heading2, Button } from '@xcorejs/ui';
import { BlueTheme } from 'components/theme/colors';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import ComparisonHowTo from './ComparisonHowTo';
import { interestingCards } from './data';
import { useHistory } from 'react-router-dom';

enum PersonalMultiple {
    personal = 'personalValues',
    multiple = 'valuesForTwo'
  }
  
const InterestingComparisonLayout: FC<Pick<TestTipCardProps, 'group'>> = ({ group }) => {
  const history = useHistory();

  return ( 
    <Flex
      flexDirection='column'
      alignItems='center'
      justifyContent='start'
      padding={{ _: '3rem', md: '5rem 5rem 8rem' }}
      mx='auto'
    >
      <Heading1 maxWidth='40rem' textAlign='center' color={BlueTheme.text}>
        <FormattedMessage id={`${PersonalMultiple[group]}.heading1`} />
      </Heading1>
  
      <Heading3
        mt='2rem'
        maxWidth='75rem'
        fontSize={{ _: '1.6rem', md: '2rem' }}
      >
        <FormattedHTMLMessage id={`${PersonalMultiple[group]}.text1`} />
      </Heading3>
  
      <ComparisonHowTo />
  
      <Heading2 mt='6rem' color={BlueTheme.text}>
        <FormattedMessage id={`${PersonalMultiple[group]}.heading2`} />
      </Heading2>
  
      <Flex
        flexDirection={{ _: 'column', sm: 'row' }} 
        justifyContent={{ _:'center' }}
        flexWrap='wrap'
        alignItems='center'
        width='100%'
        maxWidth='90rem'
        mt='2rem'
        style={{ 
          columnGap: '4rem',
          rowGap: '4rem'
        }}
      >
        {interestingCards[group]
          .slice(0, 5)
          .map((card, index) => (
            <TestTipCard key={index} card={card} group={group} />
          ))
        }
      </Flex>

      <Button
        onClick={() => history.goBack()}
        marginTop='4rem'
        color='white'
        backgroundColor='rgba(12, 14, 15, 0.3)'
        _hover={{ backgroundColor: 'rgba(12, 14, 15, 0.5) !important' }}
      >
        Zpět
      </Button>
    </Flex> 
  );
};

export default InterestingComparisonLayout;
  