import * as React from 'react';
import { StyledValuesGraph } from '../styles';
import { backgroundImage, pointsToString, letters, IPoint, PointsToCircleWrapper, PointsToIndexWrapper } from './data';
import CountUp from 'react-countup';
import { ICharacter } from 'config/api/valueTestResult';

interface IValuesGraph {
  points: IPoint[];
  backgroundIndex: number;
  pointToCircle?: () => { index: number; additions: number[] };
  includeMaxPercentage?: boolean;
  letterColor?: 'green' | 'blue' | 'both';
  onlyTopPercentage?: boolean;
  characters?: ICharacter[];
}

const ValuesGraph: React.FC<IValuesGraph> = ({
  points,
  backgroundIndex,
  pointToCircle,
  includeMaxPercentage,
  letterColor,
  onlyTopPercentage,
  characters
}) => {
  const top = points[0].points
    .map((p, i) => ({ i, p }))
    .sort((a, b) => b.p - a.p)
    .map(p => p.p)
    .slice(0, 3);

  const wrapLetterColor = pointToCircle && PointsToIndexWrapper(pointToCircle())(points);
  // const scaledPoints = points.map(group => group.points.map(p => p * 1.23));

  return (
    <StyledValuesGraph>
      <div className='actual-graph'>
        {backgroundImage[backgroundIndex > backgroundImage.length ? 0 : backgroundIndex]}
        <svg className='result' width='410' height='410' viewBox='0 0 410 410'>
          {points.map((p, index) => (
            <polygon
              key={index}
              points={p.animate ? '' : pointsToString(p.points)}
              fill={p.fill}
              fillOpacity={p.fillOpacity}
              stroke={p.stroke}
              strokeWidth={p.strokeWidth}
            >
              {p.animate && (
                <animate
                  attributeName='points'
                  dur={p.animate.duration}
                  fill={p.animate.fill}
                  from={Array(8)
                    .fill('')
                    .map(() =>
                      Array(2)
                        .fill('')
                        .map(() => p.animate!.from)
                        .join(','),
                    )
                    .join(',')}
                  to={pointsToString(p.points)}
                />
              )}
            </polygon>
          ))}
          {pointToCircle && PointsToCircleWrapper(pointToCircle())(points)}
        </svg>


        <div className='letters-wrapp'>
          {letters.map((l, i) => (
            <span
              key={i}
              className={
                points.length === 1
                  ? top.includes(points[0].points[i])
                    ? 'letter-wrapper ' + l.class + ' active ' + letterColor
                    : 'letter-wrapper ' + l.class + ' ' + letterColor
                  : wrapLetterColor &&
                      wrapLetterColor.some(x => x === i) &&
                      'letter-wrapper ' + l.class + ' active both' ||
                    'letter-wrapper ' + l.class + ' ' + letterColor
              }
            >
              <span className='letter'>{l.letter}</span>
              <span className='type'>{characters ? characters[i].name : l.type}</span>

              {(points.length === 1 && !onlyTopPercentage ||
                points.length === 1 && top.includes(points[0].points[i]) ||
                includeMaxPercentage) && (
                <span className='percentage'>
                  <CountUp
                    start={0}
                    end={(includeMaxPercentage
                      ? Math.max(...points.map(x => x.points[i]))
                      : points[0].points[i]
                    ).toFixed(1)}
                    decimals={1}
                    duration={3}
                    decimal=','
                  />
                  {' %'}
                </span>
              )}
            </span>
          ))}
        </div>
      </div>
    </StyledValuesGraph>
  );
};

export default ValuesGraph;
