import * as React from 'react';
import ValueCard, { ValueCardState } from '../ValueCard';
import { IDragInfo } from './index';
import { ICardValue } from '../../data';
import canUseDOM from '../../../../../../utils/canUseDOM';
import { getEmptyImage } from 'react-dnd-html5-backend';

export interface IDraggableCard {
  id?: string;
  card: ICardValue;
  state?: ValueCardState;
  openClick?: (text: string) => any;
  preventClick?: (arg: boolean) => any;
}

interface IDraggableCardState {
  touchMoving: boolean;
}

class DraggableCard extends React.Component<IDraggableCard & IDragInfo, IDraggableCardState> {
  public state: IDraggableCardState = {
    touchMoving: false,
  };

  public render() {
    const { card, state, openClick, connectDragPreview, connectDragSource, isDragging, preventClick } = this.props;

    const style = {
      opacity: isDragging ? 0 : 1,
      display: 'flex',
      width: '100%',
      height: '100%',
      '@media screen and (minWidth: 64em)': {
        display: 'block',
      },
    };

    if (canUseDOM) {
      connectDragPreview!(getEmptyImage());
    }

    return connectDragSource!(
      <div style={style}>
        <ValueCard
          state={state ? state : ValueCardState.def}
          lifeValue={card.value}
          openClick={openClick}
          preventClick={preventClick}
        />
      </div>,
    );
  }
}

export default DraggableCard;
