import { LIFE_VALUES } from 'config/apiRoutes';
import { createAction } from 'redux-act';
import { fetchTask } from 'utils/helpers';
import { createRescourceActions } from 'utils/rescource';

import { ILifeValue, ILifeValueDetail } from '../LifeValues';

export const lifeValueActions = createRescourceActions<ILifeValue[]>('life values');

export const fetchDetailSuccess = createAction('life values detail success', (data: ILifeValueDetail) => data);

export const fetchLifeValues = () =>
  fetchTask<ILifeValue[]>(LIFE_VALUES, lifeValueActions.fetching, lifeValueActions.success, lifeValueActions.fail);
