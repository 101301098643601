import { Box, Flex, Grid, Heading2, Heading3, Icon, Img, Link, Modal, Paragraph } from '@xcorejs/ui';
import { GameContext } from 'components/App';
import LoadingSpinnerLocal from 'components/atoms/Animations/LoadingSpinnerLocal';
import ExternalSVG from 'components/atoms/ExternalSVG';
import { PlusTag } from 'components/atoms/Logo';
import QuestionCircle from 'components/atoms/QuestionCircle';
import { PrimaryButton } from 'components/components/Button';
import { createUrl } from 'components/pages/routes';
import { yellow, yellowWhite } from 'components/theme/colors';
import { OliLight } from 'components/theme/fonts';
import { IGameStatus, ISimpleSurveyVm } from 'config/api/game';
import { startSurvey } from 'config/api/surveyInternal';
import { IValueResult } from 'config/api/valueTestResult';
import * as React from 'react';
import { FC, Fragment, useContext, useEffect, useState } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import { AlertSVG } from '../../../../../../public/img/24/alert-circle.svg';
import AtlasCarousel from '../Carousel/AtlasCarousel';
import { useGetGameStatus, useGetLifeValueDetail, useGetResultId } from '../hooks';
import { EticiaCell, IconStyle, NumberSpan, StyledSection, StyledSpan, StyledTag } from '../styles';
import ResultLink from './ResultLink';
import SingleSponsor from './SingleSponsor';

export interface ValueCardProps {
  value: string;
  onClose: any;
  primary?: boolean;
  lifeValues?: IValueResult[] | number | null;
}

const ValueCard: FC<ValueCardProps & RouteComponentProps<{}>> = ({ value, onClose, primary, history, lifeValues }) => {
  const { eticiaPlus } = useContext(GameContext);
  
  const resultId = useGetResultId();
  
  const detail = useGetLifeValueDetail(value);
  
  const [survey, setSurvey] = useState<ISimpleSurveyVm | undefined | null>(null);
  const firstTime = localStorage.getItem('firstTime');
  const status = useGetGameStatus(firstTime === null);
  const findLifeValues = (status: IGameStatus, value: string): ISimpleSurveyVm | undefined => 
    status.availableSurveys.lifeValues.find(s => s.lifeValueId === value);
  useEffect(() => {
    if (status) {
      setSurvey(findLifeValues(status, value));
    }
  }, [status, value]);

  
  return (
    <Modal
      maxWidth={primary ? '129rem' : '63rem'}
      maxHeight='90vh'
      background='#3e8f74'
      borderRadius='0.4rem'
      boxShadow='0 10px 20px 0 rgba(25, 29, 44, 0.5)'
      p={primary ? { _: '3.2rem 1.6rem', sm: '3rem' } : { _: '3.2rem 1.6rem', sm: '5rem 3rem' }}
      onClose={onClose}
    >
      {detail ? (
        <Fragment>
          {primary ? (
            <>
              <Box textAlign='center'>
                <IconStyle>
                  <ExternalSVG src={detail.iconSvg} />
                </IconStyle>

                <Heading2 mt={{ _: '1rem', sm: '2rem' }}>
                  {detail.name}
                </Heading2>

                <FormattedHTMLMessage id={'valuePage.valueCard.yourLadder'}>
                  {message => (
                    <Paragraph fontSize='1.4rem' lineHeight='2rem' color={yellowWhite} mt='1rem'>
                      {lifeValues ? 
                        eticiaPlus ? (
                          <>
                            {/* eslint-disable-next-line no-extra-parens */}
                            <NumberSpan>{typeof lifeValues === 'number' ? lifeValues : (lifeValues.find(e => e.name === detail.name) as IValueResult).rank}.</NumberSpan> { message }
                          </>
                        )
                          : (
                            <>
                              <PlusTag /> {message}
                            </>
                          )                              
                        : 
                        'Nemáš hotový hodnotový žebříček'
                      }
                    </Paragraph>
                  )}
                </FormattedHTMLMessage>

                <FormattedMessage id={'valuePage.valueCard.quote'}>
                  {message =>
                    detail.quotes.length > 0 && (
                      <Paragraph mt='1rem' fontFamily={OliLight} fontSize={{ _: '1.3rem', md: '1.5rem' }} color='rgba(255,255,255,1)'>
                        <StyledSpan>{message}{': '}</StyledSpan>
                        {detail.quotes[0].content}
                      </Paragraph>
                    )
                  }
                </FormattedMessage>
              </Box>

              <Grid
                gap={{ _: '1.6rem', md: '3rem' }}
                rows='auto'
                columns={{ _: '1', md: 'repeat(3, 1fr)' }}
                mt='3rem'
              >
                <EticiaCell bg='#529e83' order={2}>
                  <Heading3 fontWeight={400} mt='2rem'>
                    <FormattedMessage id='valuePage.valueCard.heading' />
                  </Heading3>

                  <Paragraph
                    color='rgba(255, 255, 255, 0.7)'
                    mt='1rem'
                    fontSize='1.4rem'
                    lineHeight='2rem'
                  >
                    <FormattedMessage id='valuePage.valueCard.text' />
                  </Paragraph>

                  <Img
                    src='/img/atlas/scope.svg'
                    display='block'
                    mx='auto'
                    width='5.4rem'
                    opacity={0.8}
                    alt=''
                    mt='3rem'
                  />

                  {firstTime === 'true' ? (
                    <Flex flexDirection='column' alignItems='center' justifyContent='center' marginTop='5rem'>                            
                      <PrimaryButton
                        height='4rem'
                        px='3rem'
                        disabled
                      >
                        <FormattedHTMLMessage id='valuePage.valueCard.start' />
                      </PrimaryButton>

                      <Paragraph
                        marginTop='1rem !important'
                        color={`${yellow} !important`}
                      >
                        <Icon svg={<AlertSVG />} marginRight='0.5rem' />

                        <FormattedHTMLMessage id='valuePage.valueCard.results.firstTime' />

                        <Link 
                          v='underline'
                          onClick={
                            resultId ? 
                              () => history.push(createUrl('app.testResult', { id: resultId })) : 
                              onClose
                          }>
                          <FormattedMessage id='valuePage.valueCard.results.firstTimeLink' />
                        </Link>
                      </Paragraph>
                    </Flex>
                  ) : (
                    <>
                      {survey ? 
                        survey.done ? (
                          <>
                            <Flex justifyContent='center' mt='3rem'>
                              <ResultLink id={survey!.id} />
                            </Flex>
                          </>
                        ) : (
                          <>
                            <Flex justifyContent='center' my='5rem'>
                              {survey && (
                                <PrimaryButton
                                  height='4rem'
                                  px='3rem'
                                  onClick={() => startSurvey(survey.id, value).then(r => history.push(createUrl('app.survey', { id: r.id })))}
                                >
                                  <FormattedHTMLMessage id='valuePage.valueCard.start' />
                                </PrimaryButton>
                              )}
                            </Flex>

                            <Paragraph
                              color='rgba(255, 255, 255, 0.5)'
                              mt='2rem'
                              fontSize='1.4rem'
                              lineHeight='2rem'
                              // mx='auto'
                              // maxWidth='24rem'
                            >
                              <FormattedHTMLMessage id='valuePage.valueCard.results' />
                            </Paragraph>
                          </>
                        )
                        : <LoadingSpinnerLocal />}
                    </>
                  )}
                  
                </EticiaCell>

                <EticiaCell bg='#6492a8' position='relative'>                      
                  <Img 
                    position='absolute'
                    top='0'
                    left='0' 
                    src='/img/atlas/eticiameter-bg.png' 
                    display='block'
                    width='100%'
                    height='fit-content'
                    opacity={0.2} 
                    alt='' 
                    transform='translateY(50%)'
                  />    

                  <StyledSection position='relative'>
                    <Heading3 fontWeight={400} mt='2rem'>
                      <FormattedMessage id='valuePage.valueCard.heading2' />
                    </Heading3>

                    <Paragraph
                      color='rgba(255, 255, 255, 0.7)'
                      mt='1rem'
                      fontSize={{ _: '1.3rem', md: '1.4rem' }}
                      lineHeight='2rem'
                    >
                      <FormattedMessage id='valuePage.valueCard.text2' />
                    </Paragraph>

                    <Flex
                      flexDirection='column'
                      justifyContent='center'
                      alignItems='center'
                      width='100%'
                      height='200px'
                    >
                      {eticiaPlus ? (
                        <>
                          <Img src={detail.persons[0].image} alt={detail.persons[0].name} height='100px' />
                          <Paragraph color='rgba(255, 255, 255, 0.9)' mt='1rem'>{detail.persons[0].name + ' ' + detail.persons[0].life}</Paragraph>
                        </>
                      ) : (
                        <>
                          <QuestionCircle circleSize='sm' />
                          <Paragraph color='rgba(255, 255, 255, 0.9)'>
                            <FormattedMessage
                              id='valuePage.valueCard.eticiaPlus'
                              values={{
                                PlusTag: <PlusTag />,
                              }} />
                          </Paragraph>
                        </>
                      )}
                    </Flex>

                    <StyledSection position='relative'>
                      <Img src='/img/atlas/eticiameter.svg' alt='eticia meter' display='block' mx='auto' />
                
                      <Flex 
                        position='absolute'
                        justifyContent='center'
                        alignItems='center'
                        mt='2rem'
                        bg='#72718a'
                        borderRadius='1rem' 
                        width='fit-content'
                        left='65%'
                        top='-60%'
                      >
                        <StyledTag>
                          <FormattedMessage id='valuePage.valueCard.rootFor' />
                        </StyledTag>
                      </Flex>
                    </StyledSection>

                  </StyledSection>
              
                
                </EticiaCell>
            
                <EticiaCell bg='#367b66' order={-1} minHeight={{ _: detail.persons.length === 3 ? '10rem' : '35rem' }}>
                  <Heading3 fontWeight={400} mt='2rem'>
                    <FormattedMessage id='valuePage.valueCard.heading3' />
                  </Heading3>
                  <Box position='relative' mt='2rem' margin='auto' maxWidth='fit-content'>
                    {detail.persons.length > 2 ? (
                      <AtlasCarousel projects={detail.persons.slice(1)} color='#367b66' />
                    ) : detail.persons.length == 2 ? (
                      <SingleSponsor sponsor={detail.persons[1]} />
                    ) : (
                      <SingleSponsor
                        sponsor={{
                          name: 'Připravujeme',
                          profession: 'Tuto hodnotu prozatím nikdo nesponzoruje. Můžeš to změnit ty!',
                          image: '/img/logo/eticia-logo.png',
                          content: 'Napište nám na naši emailovou adresu a my vám rádi poradíme jak na to.',
                          life: 'info@eticia.cz',
                        }} />
                    )}                      
                  </Box>
                  {/* <Paragraph
                color='rgba(255, 255, 255, 0.7)'
                mt='1rem'
                fontSize='1.4rem'
                lineHeight='2rem'
              >
                Etica 21 chce podporovat a ukazovat to dobré, smrtelně nejsou měli nevratné proužkem s chudáci
                pokroucených zvané. Spolu po nenavrtávat.
              </Paragraph>

              <Img src='/img/znesnaze_small.png' display='block' mx='auto' mt='3rem' alt='' />

              <Paragraph
                color='rgba(255, 255, 255, 0.5)'
                mt='2rem'
                fontSize='1.4rem'
                lineHeight='2rem'
              >
                Dál ně klientela a soky, pár uchu i dnešní účty. Vážně osmi až hospůdky, přinášíme velké žlutě
                jeho indickým nad elektromagnetického snímků dva...
              </Paragraph>

              <Flex justifyContent='center'>
                <Link v='underline' href='https://znesnaze.cz' mt='1rem' color='rgba(255, 255, 255, 0.7)'>
                  www.znesnaze.cz
                </Link>
              </Flex> */}
                </EticiaCell>
              </Grid>
            </>
          ) : (
            <>
              <Flex flexDirection='column' justifyContent='center' alignItems='center'>
                <Box>
                  <IconStyle>
                    <ExternalSVG src={detail.iconSvg} />
                  </IconStyle>
                </Box>
            
                <Heading3 mt='3rem' fontSize={{ _: '2rem', md: '2.4rem' }} lineHeight={{ _: '2.5rem', md: '2.8rem' }}>
                  {detail.name}
                </Heading3>

                <Paragraph mt='3rem' fontSize={{ _:'1.3rem', md: '1.6rem' }} lineHeight={{ _:'1.9rem', md: '2.2rem' }} maxWidth={{ _:'100%', md: '50rem' }}>
                  <div dangerouslySetInnerHTML={{ __html: detail.mainDefinition }} />
                </Paragraph>

                <PrimaryButton size='sm' onClick={onClose} mt='5rem' backgroundColor='#c0f3d6' color='#000c'>
                  <FormattedHTMLMessage id='valuePage.close' />
                </PrimaryButton>
              </Flex>
            </>
          )}
        </Fragment>
      ) : (
        <LoadingSpinnerLocal />
      )}

    </Modal>
  );
};

export default withRouter(ValueCard);

