import { Box, Button, Container, Flex, Heading2, Heading3, Icon, Typography } from '@xcorejs/ui';
import Fetch from 'components/atoms/Fetch';
import { RequestContext } from 'components/atoms/RequestContext';
import Subnav from 'components/atoms/Subnav';
import { PremiumButton } from 'components/components/Button';
import { AllLaddersData, LadderCard } from 'components/components/modals/AllLaddersModalBeta';
import { ShopSummaryResponse } from 'components/pages/App/Shop/data';
import { IJournal } from 'components/pages/App/TestIntro/data';
import { createUrl } from 'components/pages/routes';
import { JOURNAL, SHOP_SUMMARY } from 'config/apiRoutes';
import * as React from 'react';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import { _switch } from 'utils/option';
import LadderIcon from '../../../components/icons/24/ladder.svg';

const WelcomePlus: FC = () => {
  const history = useHistory();
  
  const testRedirect = (id: string) => {
    history.push(id);
  };
  
  return (
    <>
      <Subnav />

      <Box>
        <Fetch url={JOURNAL}>
          {_switch({
            success: (journal: IJournal) => (
              <>
                <Container flexDirection='column' alignItems='center' maxWidth='88.2rem' mx='auto' pt='6rem' pb='8rem'>
                  <PremiumButton />

                  <Heading2 mt='3rem'>
                    <FormattedMessage id='welcomePlus.title' />
                  </Heading2>
                  
                  <Typography color='rgba(249, 245, 220, 0.7)' mt='1rem'>
                    <FormattedMessage id='welcomePlus.description' />
                  </Typography>

                  <Box mt='4rem'>
                    <Heading3 color='#fff'>
                      <FormattedMessage id='welcomePlus.yourMembership' />
                    </Heading3>

                    <Fetch url={SHOP_SUMMARY}>
                      {_switch({
                        success: (summary: ShopSummaryResponse) => (
                          <>
                            <Flex mt='2rem' maxWidth='41rem' width='100%' p='4rem' bg='rgba(11, 12, 28, 0.3)' flexDirection='column'>
                              {summary.eticiaPlusValidTo && (
                                <Typography color='pink'>
                                  <FormattedMessage id='welcomePlus.validTo' />
                                  <Box display={{ _: 'block', sm: 'inline' }} color='#fff'>
                                    {Math.round(Math.abs(new Date(summary.eticiaPlusValidTo).getTime()
                                      - new Date(Date.now()).getTime()) / (24 * 60 * 60 * 1000)) > 10950 ? (
                                        <FormattedMessage id='welcomePlus.validTo.forever' /> ) : (
                                        <>
                                          {Math.round(Math.abs(new Date(summary.eticiaPlusValidTo).getTime()
                                          - new Date(Date.now()).getTime()) / (24 * 60 * 60 * 1000))}
                                          <FormattedMessage 
                                            id='welcomePlus.validTo.days' 
                                            values={{
                                              count: Math.round(
                                                Math.abs(
                                                  new Date(summary.eticiaPlusValidTo).getTime()
                                                  - new Date(Date.now()).getTime()
                                                ) / (24 * 60 * 60 * 1000)
                                              )
                                            }} />
                                          ({new Date(summary.eticiaPlusValidTo).toLocaleDateString()})
                                        </>
                                      )}
                                  </Box>
                                </Typography>
                              )}

                              <Flex alignItems='center' justifyContent='center' mt='2.4rem' flexDirection={{ _: 'column', sm: 'row' }}>
                                <Typography color='#e7e6cf'>
                                  <FormattedMessage id='welcomePlus.availableMagicTokens' />
                                </Typography>

                                <Typography fontFamily='OliMedium' color='yellow' mx='1rem' my={{ _: '1rem', sm: '0' }}>
                                  {summary.availableMagicTokenCount}
                                  <Icon svg={<LadderIcon />} ml='0.2rem' fill='yellow' />
                                </Typography>

                                <Typography color='#e7e6cf'>
                                  <FormattedMessage id='welcomePlus.numberOfLadders' values={{ count: summary.availableMagicTokenCount }} />
                                </Typography>
                              </Flex>
                            </Flex>
                          </>
                        ),
                        fetching: () => <></>,
                      })()}
                    </Fetch>
                  </Box>

                  {journal.mains.length > 0 ? (
                    <Link to={createUrl('app')}>
                      <Button mt='6rem' as='span'>
                        <FormattedMessage id='welcomePlus.center' />
                      </Button>
                    </Link>
                  ) : (
                    <>
                      <Heading3 mt='4rem' color='#fff'>
                        <FormattedMessage id='welcomePlus.howToStart' />
                      </Heading3>

                      <Typography color='rgba(249, 245, 220, 0.7)' mt='2rem'>
                        <FormattedMessage id='welcomePlus.howToStart.description' />
                      </Typography>

                      <Link to={createUrl('app.gameIntro') + '?t=welcome'}>
                        <Button mt='3rem' as='span'>
                          <FormattedMessage id='welcomePlus.findMyValues' />
                        </Button>
                      </Link>
                    </>
                  )}
                </Container>

                {/* {journal.mains.length === 0 && ( */}
                <Box bg='rgba(11, 12, 28, 0.3)' py='8rem'>
                  <Container flexDirection='column'>
                    <Heading2>
                      <FormattedMessage id='welcomePlus.allLadders' />
                    </Heading2>

                    {AllLaddersData.map((data, i) => (
                      <RequestContext key={i}>
                        {({ locale }) => (
                          <Flex
                            flexDirection='column'
                            maxWidth='75.2rem'
                            mx='auto'
                          >
                            <Heading3
                              mt={i !== 0 ? '7rem' : '4rem'}
                              fontSize='2.4rem'
                              lineHeight='3rem'
                              color='soft'
                            >
                              {data.heading[locale]}
                            </Heading3>

                            <Flex
                              mx={data.ladders.length === 2 ? { _: '-1.6rem', sm: 'auto' } : '-1.6rem'}
                              width={data.ladders.length === 2 ? { _: 'calc(100% + 3.2rem)', sm: 'calc(50% + 1.6rem)' } : 'calc(100% + 3.2rem)'}
                              mt='3rem'
                              flexWrap='wrap'
                              bg={data.background}
                            >
                              {data.ladders.map(ladder => (
                                <LadderCard
                                  ladder={{
                                    ...ladder, 
                                    heading: ladder.heading[locale],
                                    text: ladder.text[locale],
                                  }}
                                  width={{ _: '50%', sm: data.ladders.length === 2 ? '50%' : '25%' }}
                                  key={ladder.heading[locale]}
                                  infoOnclick={() => `${createUrl('app.presenting', { id: ladder.presentingId })}`}
                                  testRedirect={testRedirect}
                                  hasMainLadder={false}
                                />
                              ))}
                            </Flex>
                          </Flex>
                        )}
                      </RequestContext>
                    ))}
                  </Container>
                </Box>
                {/* )} */}
              </>
            ),
            fetching: () => <></>,
          })()}
        </Fetch>
      </Box>
    </>
  );
};

export default WelcomePlus;
