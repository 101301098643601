import { Row, Col } from 'react-styled-flexboxgrid';
import styled, { css } from 'styled-components';
import { white } from '../../../theme/colors';
import {
  mediaMdDefault,
  mediaHonzaDefault,
  mediaSmDefault,
  mediaLandscapePhone,
  mediaLandscapePhoneAndMd,
} from '../../../theme/grid';
import { StyledHelpLink } from '../../../atoms/Popups/styles';
import { StyledCard } from './Cards/ValueCard/styles';
import { OliMedium } from 'components/theme/fonts';

export const StyledRoundProgress = styled.section`
  text-align: center;
  min-height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;

  ${mediaMdDefault} {
    min-height: 9rem;
  }

  .pick-more {
    color: ${white};
    font-size: 1.5rem;
    letter-spacing: 0.5px;
    line-height: 2.3rem;
    font-weight: 500;
    z-index: 2;
    font-family: ${OliMedium};

    ${mediaMdDefault} {
      font-size: 1.8rem;
    }

    ${mediaMdDefault} {
      font-size: 2.4rem;
      line-height: 31px;
    }

    ${mediaLandscapePhone} {
      font-size: 1.2rem;
    }
  }

  a {
    height: 4rem;

    ${mediaSmDefault} {
      height: 5rem;
    }
  }
`;

export const DropTargetBox = styled.div`
  margin-top: 1rem;

  ${mediaLandscapePhone} {
    margin-top: 0;

    ${Row as any} {
      ${Col as any} {
        flex-basis: 100%;
        max-width: 100%;
        padding-left: 0.3rem;
        padding-right: 0.3rem;
      }
    }
  }

  ${mediaSmDefault} {
    & > div {
      margin: 0;
      height: 100%;
    }
  }

  ${mediaLandscapePhone} {
    display: flex;
    margin-top: 0;
    overflow: visible;
    height: 100%;

    & > div {
      width: 100%;
      height: 100%;

      ${Col as any} {
        & > div {
          width: 100%;
          height: 100%;

          div {
            height: 100%;
          }
        }
      }
    }
  }

  ${mediaMdDefault} {
    margin-top: 0;
    max-height: 100%;
    //overflow: auto;
  }

  ${StyledCard} {
    ${mediaSmDefault} {
      min-height: 11rem;
    }

    ${mediaMdDefault} {
      min-height: 19rem;
    }
  }
`;

export const StyledTestWrap = styled.section`
  height: 100%;
  user-select: none;
  display: flex;
  flex-direction: column;

  ${mediaMdDefault} {
    padding: 0 3rem;
  }

  ${StyledHelpLink} {
    display: block;
    position: absolute;
    right: 1.5rem;
    bottom: 1.5rem;
    width: 3rem;
    height: 3rem;
    z-index: 2;

    ${mediaMdDefault} {
      position: fixed;
      display: block;
      right: 3rem;
      bottom: 3rem;
      width: 5rem;
      height: 5rem;
    }
  }

  ${Col as any} {
    &:first-child {
      ${Col as any} {
        display: flex;
        width: 100%;

        ${mediaLandscapePhoneAndMd} {
          display: flex;
          height: 50%;
        }

        & > div {
          display: flex;
          width: 100%;

          ${mediaMdDefault} {
            ${Col as any} {
              display: inline-block;
            }
          }
        }
      }
    }
  }

  .portrait-popup {
    ${mediaMdDefault} {
      display: none;
    }
  }
`;

export const StyledTestScreen = styled.section<{ isFinalStep?: boolean }>`
  ${mediaLandscapePhone} {
    height: calc(100% - 5rem);
  }

  ${mediaMdDefault} {
    width: 100%;
    max-width: 180rem;
    height: calc(100vh - 6rem);
    min-height: 63rem;
    max-height: 96rem;
    display: flex;
    align-items: center;
    margin: 0 auto;

    ${mediaHonzaDefault} {
      min-height: 65rem;
    }
  }

  ${StyledRoundProgress} {
    padding-top: 1rem;

    ${mediaLandscapePhone} {
      padding-top: 0;
    }
  }

  & > ${Row as any} {
    display: flex;
    margin: 0;
    width: 100%;
    max-height: 100%;

    ${mediaMdDefault} {
      height: 100%;

      & > ${Col as any} {
        height: 100%;
        display: flex;

        &:last-child {
          height: 0;
        }
      }
    }

    ${mediaLandscapePhone} {
      height: 95%;

      & > ${Col as any} {
        width: 0;
        padding-left: 0;
        padding-right: 0;
        flex-basis: 0;
        max-width: 0;

        &:first-child {
          padding-right: 0.5rem;
          padding-left: 0.5rem;
          width: 65%;
          flex-basis: 65%;
          max-width: 65%;

          ${Col as any} {
            width: 33.33%;
            flex-basis: 33.33%;
            max-width: 33.33%;
            padding-left: 0.3rem;
            padding-right: 0.3rem;
          }
        }

        &:nth-child(3) {
          padding-right: 0.5rem;
          padding-left: 0.5rem;
          width: 35%;
          max-width: 35%;
          flex-basis: 35%;
        }
      }
    }
  }

  .scrollableContainer {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    .movable {
      transition: top 500ms ease;
    }

    & > ${Row as any} {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      display: flex;
      flex-wrap: wrap;
      height: 100%;

      ${mediaLandscapePhone} {
        overflow: auto;
      }

      ${Col as any} {
        min-height: 9.8rem;

        &:nth-child(n + 13) {
          ${StyledCard} {
            &:after {
              content: "";
              position: absolute;
              width: 100%;
              height: 100%;
              left: 0;
              background-color: rgba(25, 29, 44, 0.25);
            }
          }
        }
      }
    }
  }

  .flex-vertical {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;

    @media (max-width: 1023px) {
      justify-content: flex-start;
    }
  }

  ${props =>
    props.isFinalStep &&
    css`
      height: calc(100vh - 11rem);

      ${mediaLandscapePhone} {
        height: calc(100vh - 8rem);
      }

      ${mediaMdDefault} {
        height: calc(100vh - 6rem);
      }

      .scrollableContainer {
        overflow-y: auto;

        ${mediaMdDefault} {
          overflow: hidden;
        }

        & > ${Row as any} {
          ${Col as any} {
            min-height: 0;
          }
        }
      }

      ${Col as any} {
        @media (max-width: 1023px) {
          width: 50%;
          flex-basis: 50%;
          max-width: 50%;
          max-height: 100%;
          height: calc(100% - 14.5rem);
          overflow-y: auto;

          ${Col as any} {
            display: flex;
            width: 100%;
            flex-basis: 100%;
            max-width: 100%;
            height: auto;

            &:nth-child(2) {
              display: flex;
            }

            &:last-child {
              display: flex;
            }
          }

          &:nth-child(2) {
            display: none;
          }

          &:last-child {
            display: none;
          }
        }

        &:first-child {
          ${Col as any} {
            height: auto;

            ${mediaLandscapePhone} {
              height: 33.333%;
            }

            ${mediaMdDefault} {
              flex-basis: 25%;
              max-width: 25%;
              height: 33.333%;
            }
          }
        }
      }

      ${StyledRoundProgress} {
        padding-top: 1rem;

        a {
          height: 4rem;
          font-size: 1.6rem;
          line-height: 2rem;
          padding: 1.2rem 2rem;
        }
      }

      & > ${Row as any} {
        height: 100%;

        & > ${Col as any} {
          height: 100%;

          &:last-child {
            height: 0;
          }
        }
      }

      .empty-card {
        ${mediaSmDefault} {
          min-height: 5rem;
        }

        ${mediaMdDefault} {
          min-height: 11rem;
          height: 12vh;
        }
      }

      .filled-space {
        width: 100%;
        display: flex;
        height: 100%;

        .empty-card {
          height: 100%;
        }
      }

      ${DropTargetBox} {
        display: flex;
        justify-content: center;
        margin-top: 0;
        overflow-y: auto;

        & > div {
          width: 100%;
          height: 100%;

          ${Col as any} {
            & > div {
              width: 100%;
              height: 100%;

              div {
                height: 100%;
              }
            }
          }
        }

        ${mediaLandscapePhone} {
          height: calc(100% - 5rem);
        }

        ${mediaMdDefault} {
          height: 100%;
        }

        .actual-card {
          ${mediaSmDefault} {
            min-height: 5rem;
          }

          ${mediaMdDefault} {
            min-height: 11rem;
          }
        }

        .empty-card {
          ${mediaMdDefault} {
            min-height: 10rem;
          }
          
          @media only screen and (min-width: 64em) and (max-height: 48em) {
            min-height: 9rem;
          }
        }
      }

      .react-reveal {
        height: 100%;

        @media (max-width: 767px) {
          opacity: 1 !important;
          animation: none !important;
        }
      }

      ${StyledCard} {
        height: 100%;

        ${mediaSmDefault} {
          min-height: 5rem;
        }

        ${mediaMdDefault} {
          min-height: 11rem;
          height: 100%;
        }

        .rotate-ico {
          display: none;

          ${mediaSmDefault} {
            display: inline-block;
          }
        }

        .value-img {
          display: none;
        }

        ${mediaMdDefault} {
          .value-img {
            display: block;
          }
        }
      }

      .selected {
        .value-name {
          min-height: 2rem;
        }

        ${mediaMdDefault} {
          .value-img {
            display: block;
            position: relative;

            img {
              position: absolute;
              height: 100%;
            }
          }

          .value-name {
            margin-top: 2rem;
          }
        }
      }
    `};
`;
