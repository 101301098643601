import React from 'react';
import { Heading1, Heading2, Paragraph, Flex } from '@xcorejs/ui';
import { PlusTheme } from 'components/theme/colors';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { SectionHomepage, Button } from '../styles';
import Fade from 'react-reveal/Fade';
import { useHistory } from 'react-router-dom';
import { createUrl } from 'components/pages/routes';

type dataProps = {
	title: string;
	text: string;
};

const data: dataProps[] = [
  { 
    title: 'Životní hodnoty', 
    text:'Dlouhodobé sledování a aktualizace tvého hodnotového žebříčku každý rok zdarma.' 
  },
  { 
    title: 'Hodnoty dopodrobna', 
    text:'Co pro tebe jednotlivé hodnoty znamenají? Proveď sis svůj osobní průzkum.' 
  },
  { 
    title: '12 speciálních žebříčků', 
    text:'Hodnoty pro nejrůznější situace. Hodnoty ve tvých vztazích, práci a další.' 
  },
  { 
    title: 'Tvé hodnotové statistiky', 
    text:'Jak vnímáš hodnoty ty a jak druzí? Zajímavé i zábavné statistiky.' 
  },
  { 
    title: 'Eticia pro děti', 
    text:'Děti hodnoty milují. S dětskou verzí Eticia můžete objevovat hodnoty společně.' 
  },
  { 
    title: 'Eticia Plus', 
    text:'Dlouhodobé členství s mnoha výhodami.' 
  },
]; 

const WhatNext = () => {
  const history = useHistory();

  return ( 
    <SectionHomepage backgroundColor={PlusTheme.darken}>
      <Fade ssrReveal bottom duration={1000} distance={'5rem'}>
        <Heading1 textAlign='center' color={PlusTheme.text}>
          <FormattedMessage id='homepage.box.whatnext.title' />
        </Heading1>
      </Fade>
      <Fade ssrReveal bottom duration={1000} distance={'5rem'}>
        <GridList>
          {
            data.map((d, i) => (
              <li key={i}>
                <Heading2>{d.title}</Heading2>
                <Paragraph fontFamily='Oli Grotesk Light'>{d.text}</Paragraph>
              </li>
            ))
          }
        </GridList>
      </Fade>
      <Flex justifyContent='center'>
        <Button
          borderColor='#c85c66'
          backgroundColor='#873754'
          onClick={() => history.push(createUrl('register'))}
        >
        Sestavit žebříček zdarma
        </Button>
      </Flex>
    </SectionHomepage>
  );
}; 

export default WhatNext;

const GridList = styled.ul`
  margin-top: 5rem;
  margin-bottom: 5rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 150rem;

  display: grid;
  grid-template-columns: minmax(300px, 1fr);
  list-style: none;
  gap: 3rem;
  justify-items: center;

  li {
    max-width: 40rem;

    p {
      margin-top: 1rem;
    }
  }

  @media screen and (min-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 4rem;
  }

  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 5rem;

    li {
      p {
        font-size: 1.8rem;
      }
    }
  }
`;
