import React, { FC } from 'react';
import { ProductCardProps } from './ProductCard';
import { ShopButtonProps, ShopButton } from '../styles';
import { FormattedMessage } from 'react-intl';
import { createUrl } from 'components/pages/routes';
import { alphaToHex } from 'components/atoms/alphaToHex';
import { Link } from 'react-router-dom';
import { Icon } from '@xcorejs/ui';
import GiftIcon from '../../../components/icons/24/gift.svg';

interface RouterLinkButtonProps extends Pick<ProductCardProps, 'inApp' | 'link' | 'color' | 'gift'>, Omit<ShopButtonProps, 'color'> {
    opacityBackgroundColor?: boolean;
    extend?: boolean;
  }
    
const RouterLinkButton: FC<RouterLinkButtonProps> = ({ 
  color, 
  link, 
  gift, 
  inApp,
  opacityBackgroundColor,
  disabled,
  extend,
  ...props
}) => {
  if (disabled) {
    return (
      <ShopButton
        borderColor={color}
        backgroundColor='transparent'
        disabled
      >
        <FormattedMessage id={`eticiaPlus.shop.${inApp ? 'activate' : 'buy'}`} />
      </ShopButton>
    );
  }
  const url = createUrl(`${inApp ? 'app.shop' : 'shop'}`);
  const path = `/koupit/${gift ? 'voucher' : 'plus'}-${link}`;
  const to = `${url}${path}`;
    
  const alpha = 0.5;
  const backgroundColor = opacityBackgroundColor ? color.indexOf('rgb') !== -1 ? color.replace('rgb', 'rgba').replace(')', `,${alpha})'`) : color + alphaToHex(alpha) : '#4c4868';
  
  const buttonText = `eticiaPlus.shop.${extend ? 
    'extend' : 
    inApp ? 
      gift ? 
        'buy.gift' :
        'activate' : 
      `buy${gift ? '.gift' : ''}`
  }`;
      
  return (
    <Link to={to}>
      <ShopButton 
        borderColor={color}
        backgroundColor={backgroundColor}        
        blackHover={!gift}
        type='button'
        {...props}
      >
        {gift ? (
          <Icon 
            svg={<GiftIcon />}
            transform='scale(0.8)'
            position='relative'
            left='-7px'
            top='-2px'
          />
        ) : null}
        <FormattedMessage id={buttonText} />
      </ShopButton>
    </Link>
  );
};

export default RouterLinkButton;