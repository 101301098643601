import * as React from 'react';

const SVGlocked: React.FC = () => {
  return (
    <svg width='20px' height='22px' viewBox='0 0 20 22' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <g id='MVP-FInal' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Statistiky' transform='translate(-877.000000, -1276.000000)' fill='#F4F4F6' fillRule='nonzero'>
          <g id='CONTENT' transform='translate(310.000000, 0.000000)'>
            <g id='stats-advanced' transform='translate(534.000000, 1257.000000)'>
              <g id='sex'>
                <g id='Group' transform='translate(13.000000, 0.000000)'>
                  <path
                    d='M24,28 L24,25 C24,21.6862915 26.6862915,19 30,19 C33.3137085,19 36,21.6862915 36,25 L36,28 L37,28 C38.6568542,28 40,29.3431458 40,31 L40,38 C40,39.6568542 38.6568542,41 37,41 L23,41 C21.3431458,41 20,39.6568542 20,38 L20,31 C20,29.3431458 21.3431458,28 23,28 L24,28 Z M26,28 L34,28 L34,25 C34,22.790861 32.209139,21 30,21 C27.790861,21 26,22.790861 26,25 L26,28 Z M23,30 C22.4477153,30 22,30.4477153 22,31 L22,38 C22,38.5522847 22.4477153,39 23,39 L37,39 C37.5522847,39 38,38.5522847 38,38 L38,31 C38,30.4477153 37.5522847,30 37,30 L23,30 Z'
                    id='ico/game-locked'
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SVGlocked;
