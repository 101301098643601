import styled from 'styled-components';
import { mediaSmDefault } from 'components/theme/grid';
import { BlueTheme, RedTheme, yellow, BlackTheme } from 'components/theme/colors';

export const StyledMain = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;

  p strong {
    display: block;
  }

  span.slashed {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 3px;
      background-color: ${BlackTheme.primary};
      bottom: 0;
      left: 0;
      transform: rotate(-13deg) translate(4px, -13px);
      opacity: 0.9;
    }
  }
`;

export const StyledGrid = styled.section`
  display: grid;
  width: 100%;
  max-width: min(calc(600px + 1rem), calc(60rem + 1rem));
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: min(100px, 10rem);
  gap: 1rem;
  padding: 2rem 0;
  grid-auto-flow: dense;

  .flip-card {
    cursor: pointer;
    position: relative;
    perspective: 1000px;
    -webkit-perspective: 1000px;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    transition: transform 500ms;
    
    &__inner {
      width: 100%;
      height: 100%;
      transform-style: preserve-3d;
      -webkit-transform-style: preserve-3d;
    }
    
    &__front,
    &__back {
      backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
      position: absolute;
      width: 100%;
      height: 100%;
      overflow: hidden;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        -webkit-animation: littleMove 10s infinite var(--flip-card--animation-delay, 0) ease;
        animation: littleMove 10s infinite var(--flip-card--animation-delay, 0) ease;

        @-webkit-keyframes littleMove {
          0% {
            transform: translate(0, 0) scale(1.);
          }
          25% {
            transform: translate(0, 1%) scale(1.05);
          }
          50% {
            transform: translate(1%, 1%) scale(1.1);
          }
          75% {
            transform: translate(1%, 0) scale(1.05);
          }
          100% {
            transform: translate(0, 0) scale(1.);
          }
        }

        @keyframes littleMove {
          0% {
            transform: translate(0, 0) scale(1.);
          }
          25% {
            transform: translate(0, 1%) scale(1.05);
          }
          50% {
            transform: translate(1%, 1%) scale(1.1);
          }
          75% {
            transform: translate(1%, 0) scale(1.05);
          }
          100% {
            transform: translate(0, 0) scale(1.);
          }
        }
      }
    }

    &__front {
      background-color: ${BlueTheme.light};
    }

    &__back {
      background-color: ${RedTheme.primary};
      transform: rotateY(180deg);
      padding: 2rem 1rem;
    }
  }

  .normal {
    grid-row: span 2;
    
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  
  .higher {
    grid-row: span 3;

    img {
      height: 100%;
      max-width: unset;
    }
  }
  
  .wider {
    grid-row: span 2;
    grid-column: span 2;

    img {
      width: 100%;
    
    }
  }
  
  .big {
    grid-row: span 3;
    grid-column: span 2;
  }

  .flipped {
    transform: rotateY(180deg);
  }
    
  ${mediaSmDefault} {
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: min(200px, 20rem);
    max-width: min(calc(800px + 1rem), calc(80rem + 1rem));

    .normal {
      grid-row: span 1;
      
      img {
        width: unset;
        height: 100%;
      }
    }

    .higher {
      grid-row: span 2;
    }

    .wider {
      grid-row: span 1;
    }

    .flip-card {
      &__back {
        padding: 2rem 2rem
      }
    }
  }
`;

export const TimeNameProps = {
  background: BlueTheme.text,
  color: BlackTheme.primary,
  width: '100%',
  padding:'0.5rem 0',
  fontWeight: 700,
  textTransform: 'uppercase',
  lineHeight: '1',
  fontSize:{ _: '1.2rem', sm: '1.4rem' }
};

export const TimeNumberProps = {
  color: BlueTheme.text,
  padding: '1rem 0.5rem 0.2rem',
  lineHeight: '1',
  width: '100%',
};

export const TimeFlexProps = {
  background: 'rgba(12, 14, 15, 0.5)',
  borderRadius: '0.5rem',
  width: { _: '5rem', sm: '7rem' },
};
