import * as React from 'react';
import { Fragment } from 'react';
import { Col, Grid, Row } from 'react-styled-flexboxgrid';
import { Heading2, Heading3 } from '../../../../atoms/Headings';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { SectionText } from '../../../../atoms/Text';
import Table from '../../../../atoms/Table';
import Fade from 'react-reveal/Fade';
import Fetch from 'components/atoms/Fetch';
import { INVITATIONS, IInvitation } from 'config/api/invitation';
import { _switch } from '../../../../../utils/option';
import LoadingSpinnerLocal from '../../../../atoms/Animations/LoadingSpinnerLocal';
import { BackgroundedBox } from '../../ValueComparison/Comparisons/styles';
import { StyledFakeLinkBorder } from '../../../../atoms/Buttons/styles';
import { StyledProfilePage } from '../styles';
import withUser from 'components/molecules/withRedux/withUser';
import { IWithUser } from '../../../../molecules/withRedux/withUser';
import Banner from 'components/atoms/Banner';
import { GetRandomC2A, GetC2ALink, GetC2A } from '../../../../atoms/C2A/index';
import { Box } from '@xcorejs/ui';
import { createUrl } from 'components/pages/routes';

const createLink = (id: string) => 'https://eticia21.com' + createUrl('intro') + '/' + id;

const CopyToClipboard = (text: string) =>
  navigator && navigator.clipboard // Windows.
    ? navigator.clipboard.writeText(text)
    : window && (window as any).clipboardData // IE.
      ? (window as any).clipboardData.setData('text', text)
      : document.execCommand(text); // Mac.

const C2A = GetRandomC2A();

const Friends: React.FC<IWithUser> = ({ user }) => (
  <StyledProfilePage>
    <Grid>
      <Fade bottom ssrReveal duration={1000} distance={'5rem'}>
        <div>
          <Heading2>
            <FormattedMessage id='profile.friends.heading' />
          </Heading2>

          <SectionText>
            <FormattedHTMLMessage id='profile.friends.text' />
          </SectionText>
        </div>

        <Row>
          <Col xs={12} md={6} lg={5} lgOffset={1}>
            <BackgroundedBox>
              <Heading3>
                <FormattedMessage id='profile.friends.myInvite' />
              </Heading3>

              <SectionText>
                <FormattedMessage id='profile.friends.myInvite.text' />
              </SectionText>

              <StyledFakeLinkBorder
                color='yellow'
                withoutAfterBorder={true}
                className='link-break'
                onClick={() => CopyToClipboard(createLink(user.id))}
              >
                <span>
                  <strong>{createLink(user.id)}</strong>
                </span>
              </StyledFakeLinkBorder>

              <StyledFakeLinkBorder onClick={() => CopyToClipboard(createLink(user.id))}>
                <FormattedMessage id='profile.friends.myInvite.copy' />
              </StyledFakeLinkBorder>
            </BackgroundedBox>
          </Col>

          <Col xs={12} md={5} mdOffset={1} lgOffset={1}>
            <Heading3>
              <FormattedMessage id='profile.friends.list.heading' />
            </Heading3>

            <Fetch url={INVITATIONS}>
              {_switch({
                success: (invitations: IInvitation[]) => (
                  <Fragment>
                    {invitations.length === 0 ||
                    invitations.filter(x => x.profile && x.profile.nick && x.profile.nick !== '').length === 0 ? (
                        <SectionText opacity>
                          <FormattedMessage id='profile.friends.noFriends' />
                        </SectionText>
                      ) : (
                        <Table className='table'>
                          <tbody>
                            {invitations
                              .filter(x => x.profile && x.profile.nick && x.profile.nick !== '')
                              .map((invitation: IInvitation, index) => (
                                <tr key={index}>
                                  <td>
                                    <span>{invitation.profile.nick}</span>
                                  </td>

                                  {invitation.status === 'accepted' && (
                                    <td>
                                      <FormattedMessage id='profile.friends.list.registered' />
                                    </td>
                                  )}
                                  {invitation.status !== 'accepted' && (
                                    <td>
                                      <strong>
                                        <FormattedMessage id='profile.friends.list.filled' />
                                      </strong>
                                    </td>
                                  )}
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      )}
                  </Fragment>
                ),
                fetching: () => <LoadingSpinnerLocal />,
              })()}
            </Fetch>
            <Box mt='3rem'>
              <Banner to={GetC2ALink(C2A)()}>
                <img src={GetC2A(C2A)} alt='Hodnoty pro dva' />
              </Banner>
            </Box>
          </Col>
        </Row>
      </Fade>
    </Grid>
  </StyledProfilePage>
);

export default withUser<{}>(Friends);
