import * as React from 'react';
import { BoxBody, StyledAnswerBox } from './styles';
import { SurveyQuestion, ISurveyQuestion, ISurveyAnswer } from '../../../pages/App/Result/PartnerTestResult/data';
import { Heading3 } from '@xcorejs/ui';

interface IAnswerBox {
  question: SurveyQuestion | ISurveyQuestion<ISurveyAnswer[]>;
}

const AnswerBox: React.FC<IAnswerBox> = ({ question, children }) => (
  <StyledAnswerBox>
    <Heading3
      display='flex'
      alignItems='center'
      justifyContent='center'
      px='3rem'
      py='2rem'
      bg='rgba(11, 12, 28, 0.2)'
      minHeight='10rem'
      fontSize={{ _: '1.8rem', sm: '2rem' }}
    >
      {question.text}
    </Heading3>

    <BoxBody>{children}</BoxBody>
  </StyledAnswerBox>
);

export default AnswerBox;
