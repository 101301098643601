import Journal from './component';

export const journalRoutes = {
  journal: {
    path: 'route.journal',
    exact: false,
    component: Journal,
    data: {
      background: 'blue',
      authUser: true,
    },
  },
};
