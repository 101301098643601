import styled, { css } from 'styled-components';
import { yellow } from 'components/theme/colors';
import { OliRegular, OliMedium } from 'components/theme/fonts';

export type StyledGridProps = {
  singleColumn?: boolean;
}

export const StyledGrid = styled.div<StyledGridProps>`
  display: grid;
  grid-template-columns: ${({ singleColumn }) => singleColumn ? '1fr' : '1fr 1.5rem 1.5rem 1fr'};
  grid-template-rows: 8rem auto;
  ${({ singleColumn }) => !singleColumn ? css`
    grid-template-areas: 
      'leftIcon compare compare rightIcon'
      'leftText leftText rightText rightText';
  ` : ''}
  margin-top: 4rem;
  width: 100%;
  justify-content: center;
  justify-items: center;
  align-items: center;

  .image {
    height: 8rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 3px 1px rgba(255, 255, 255, 0.8);
    
    &__left {
      grid-area: leftIcon;
    }

    &__right {
      grid-area: rightIcon;
    }

    &__middle {
      ${({ singleColumn }) => !singleColumn ? css`
        grid-column: leftIcon / rightIcon;
      ` : ''}
    }
  }

  .compare-icon {
    grid-area: compare;

    span {
      height: 2.5rem;
      width: 2.5rem;
      border-radius: 50%;
      border: 0.1rem solid white;
      padding: 0.1rem;
    }
  }

  .text-block {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__left, &__right {
      place-self: start;
      justify-self: center;
      text-align: center;
    }
    
    &__left {
      grid-area: leftText;
      padding-right: 1rem;
    }

    &__right {
      grid-area: rightText;
      padding-left: 1rem;
    }

    &__middle {
      ${({ singleColumn }) => !singleColumn ? css`
        grid-column: leftText / rightText;
      ` : ''}        
    }
  }

  .ladder-name {
    color: ${yellow};
    font-family: ${OliMedium};
    margin-top: 1rem;
  }

  button {
    padding: 0.5rem 2rem;
    font-size: 1.3rem;
    line-height: 1;
    height: auto;
    margin-top: 1rem;
    position: relative;
    font-family: ${OliRegular};
    place-self: end center;
    border: 0.2rem solid rgb(12, 14, 15);
    background-color: rgba(12, 14, 15, 0.3);
    color: white;

    &:hover {
      background-color: rgba(12, 14, 15, 0.8);
    }

    & > div {
      position: absolute;
      right: -2rem;
      bottom: -1.3rem;
      font-size: 1.2rem;
      line-height: 1.4rem;
      cursor: default;
    }
  }
`;
