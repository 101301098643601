import { Typography } from '@xcorejs/ui';
import Navigation from 'components/atoms/HPNav';
import { Pages as data } from 'components/pages/Presenting/data';
import Page from 'components/pages/Presenting/pages';
import { createUrl } from 'components/pages/routes';
import React, { FC } from 'react';
import { Route } from 'react-router-dom';

const Presenting: FC = () => {
  const baseUrl = createUrl('presenting');

  const authResult = new URLSearchParams(window.location.search);
  const code = authResult.get('id');
  const header = authResult.get('header');

  return (
    <>
      <Navigation />
    
      <Route
        path={baseUrl}
        render={({ match }) => (
          <>
            {!isNaN(Number(code)) && Number(code) < data.length ? (
              <Page
                page={data[Number(code)]}
              />
            ) : (
              <Typography>
              Wrong page
              </Typography>
            )}
          </>
        )}
      />
    </>
  );
};

export default Presenting;

