import { useModal } from '@xcorejs/ui';
import { GameContext } from 'components/App';
import LoadingSpinnerLocal from 'components/atoms/Animations/LoadingSpinnerLocal';
import ValueRatingModal from 'components/pages/App/Journal/Journal/ValueRatingModal';
import React, { FC, useContext } from 'react';
import { useFetchJournal } from 'utils/useQuery/queryFetchHelpers';
import Modal from '../components/Modal';

interface Level5Props {
  action: () => void;
  close: () => void;
  level5: boolean;
}

const Level5: FC<Level5Props> = ({ action, close, level5 }) => {
  const journalQuery = useFetchJournal();
  const [openValueRating] = useModal(ValueRatingModal);
  const { userLvl, questLvl } = useContext(GameContext);

  if (journalQuery.isLoading) {
    return <LoadingSpinnerLocal />;
  }

  if (journalQuery.isError) {
    return <></>;
  }

  const journal = journalQuery.data!;
  const tribalTestResult = journal.mains.slice(0, 1);
    
  return ( 
    <Modal
      onAction={() => {
        openValueRating({
          id: tribalTestResult[0].id || '',
          _modal: {
            maxWidth: '37.5rem',
            maxHeight: '60rem',
            padding: '6rem 2rem 3rem'
          },
          insideGame: true,
          onAction: () => {
            close();
            setTimeout(() => action(), 1000);
          }
        });
      }}
      state={userLvl !== questLvl ? 'summary' : level5 ? 'exit' : 'intro'}
    />
  );
};
 
export default Level5;