import * as React from 'react';
import popupContext from '../../../organisms/MainPopup/popupContext';
import SVGclosegray from '../../SVGIcons/SVGclosegray';
import { PrimaryButton } from '../../Buttons';
import { FormattedMessage } from 'react-intl';
import { StyledFaqCloseButton, StyledFaqPopup } from './styles';

interface Props {
  color?: 'red' | 'yellow' | 'blue' | 'green' | 'black';
  background?: string;
  button?: string;
  width?: number;
  withoutCloseButton?: boolean;
  fullScreen?: boolean;
  border?: 'valuesForTwo';
}

const BasePopup: React.FC<Props> = ({
  color,
  children,
  button,
  background,
  width,
  withoutCloseButton,
  fullScreen,
  border,
}) => {
  const close = React.useContext(popupContext);

  return (
    <StyledFaqPopup color={color} background={background} width={width} fullScreen={fullScreen} border={border}>
      {!withoutCloseButton && (
        <StyledFaqCloseButton onClick={() => close()}>
          <SVGclosegray />
        </StyledFaqCloseButton>
      )}

      <div>{children}</div>

      {button && (
        <span onClick={() => close()}>
          <PrimaryButton>
            <FormattedMessage id={'faq.btn.' + button} />
          </PrimaryButton>
        </span>
      )}
    </StyledFaqPopup>
  );
};

export default BasePopup;
