import * as React from 'react';
import { IProfile, AllValuesSetting, AllValuesSettingsBeta } from '../data';
import { Col, Row } from 'react-styled-flexboxgrid';
import { IStringMap } from '../../../../../../utils';
import { ILifeValue } from '../../../../../../store/Entities/LifeValues';
import { CardList } from '../../../../../organisms/PartnerTest/Result/styles';
import { FormattedMessage } from 'react-intl';
import SimpleCard from '../../../../../atoms/Cards/SimpleCard';
import { StyledResultBoxes } from 'components/molecules/TestResult/AnswerBox/styles';
import { IValueTestResultDetail, IValueResult } from 'config/api/valueTestResult';
import { SectionText } from '../../../../../atoms/Text';
import { Box, Flex, Heading2, Paragraph, useModal } from '@xcorejs/ui';
import { textGreen, blue, BlueTheme, IColorTheme } from '../../../../../theme/colors';
import Fade from 'react-reveal/Fade';
import { FAQLadder } from '../../../../../atoms/Popups/FAQPopup/popups';
import { HelpLink } from '../../../../../atoms/Popups/HelpLink';
import FAQPopup from '../../../../../atoms/Popups/FAQPopup';
import Modal from '../../../../../components/Modal';
import { PremiumButton } from '../../../../../components/Button';
import { FC, useContext } from 'react';
import ValueMap from '../../../../../molecules/ValueMap';
import OrderedValues from '../../../../../molecules/ValueMap/OrderedValues';
import { toDate } from '../../../ValueComparison/StartTest/Intro';
import { GameContext } from 'components/App';

interface IData {
  profiles: IStringMap<IProfile>;
  values: IStringMap<IValueTestResultDetail>;
  mapValues: IStringMap<ILifeValue>;
  allLifeValues: IValueResult[];
}

const DefaultValues: FC<IData> = ({ profiles, mapValues, values }) => {
  const [openAllValueModal] = useModal(AllValuesModal);
  const { eticiaPlus } = useContext(GameContext);
  const [close] = useModal();

  return (
    <>
      <StyledResultBoxes>
        <Row>
          {Object.keys(profiles).map((key, index) => (
            <Col xs={12} md={5} mdOffset={index === 0 ? 1 : 0} lg={4} lgOffset={index === 0 ? 2 : 0} key={index}>
              <Fade bottom ssrReveal duration={1000} distance={'5rem'}>
                <div>
                  <Heading2>{profiles[key].fullname == null ? profiles[key].nick : profiles[key].fullname}</Heading2>

                  <CardList>
                    <>
                      {values[key].lifeValues
                        .sort((a, b) => a.rank - b.rank)
                        .filter(rank => rank.rank <= 5)
                        .map((value, i) => (
                          <React.Fragment key={i}>
                            {mapValues[value.valueId] !== undefined ? (
                              <>
                                <Fade bottom ssrReveal duration={1000} distance={'5rem'} delay={200 * i}>
                                  <SimpleCard
                                    index={i + 1}
                                    key={mapValues[value.valueId].id}
                                    name={mapValues[value.valueId].name}
                                    svg={mapValues[value.valueId].iconSvg}
                                    color={index === 0 ? 'green' : 'blue'}
                                  />
                                </Fade>
                              </>
                            ) : (
                              <SimpleCard name={'ERROR: ValueID not found'} color={'yellow'} />
                            )}
                          </React.Fragment>
                        ))}
                    </>
                  </CardList>
                </div>

                <Box mt='2rem'>
                  <SectionText
                    color={index === 0 ? textGreen : blue}
                    onClick={() => openAllValueModal({
                      values: values[key], mapValues: mapValues, onClose: close
                    })}
                  >
                    <FormattedMessage id={'partnerResult.cards.next'} />
                  </SectionText>
                </Box>
              </Fade>
            </Col>
          ))}
        </Row>

        <FAQPopup popup={FAQLadder} className={'faq-popup-button'}>
          <HelpLink size={3} />
        </FAQPopup>
      </StyledResultBoxes>
    </>
  );
};

export default DefaultValues;


export const AllValuesModal: FC<{
  values: IValueTestResultDetail;
  mapValues: IStringMap<ILifeValue>;
  onClose: () => unknown;
  customVisible?: boolean;
  theme?: IColorTheme;
}> = (
  {
    values,
    mapValues,
    onClose,
    customVisible,
    theme
  }
) => {
  const sortedValues = values.lifeValues.sort((a, b) => a.rank - b.rank);
  const { eticiaPlus } = useContext(GameContext);
  const visible = eticiaPlus || customVisible;

  return (
    <Modal
      buttonText={<FormattedMessage id='popup.btn.close' />}
      _button={{ bg: 'transparent', border: '2px solid yellow', color: '#fff', onClick: onClose }}
      bg={BlueTheme.primary}
      width='37.5rem'
      height='90vh'
      top={0}
      px={{ _: '2rem', md: '2rem' }}
    >
      <>
        <Heading2 fontSize='2.4rem' lineHeight='3.2rem' fontWeight={400} textAlign='center'>Můj žebříček hodnot</Heading2>

        <Paragraph mt='2rem' color='rgba(255, 255, 255, 0.7)'>
          Vytvořeno {toDate(values.createdAt)}
        </Paragraph>

        <Box position='relative' mt='3rem'>
          <ValueMap theme='normal' imagePath='' customColorTheme={theme}>
            <OrderedValues allLifeValues={values.allLifeValues} />
          </ValueMap>
        </Box>

        <Box mt='6rem'>
          {theme ? AllValuesSettingsBeta(theme).colors.map((setting, index) => (
            <>
              <Box key={index} display={visible? 'block' : AllValuesSettingsBeta(theme).colors[index].visibilityWithoutPlus ? 'block' : 'none'}>
                {sortedValues
                  .slice(index === 0 ? 0 : AllValuesSettingsBeta(theme).colors[index - 1].rangeTo, setting.rangeTo)
                  .map((value, i) => (
                    <React.Fragment key={i}>
                      {(setting.visibilityWithoutPlus || visible) && (
                        mapValues[value.valueId] !== undefined ? (
                          <Fade bottom ssrReveal duration={1000} distance={'5rem'} delay={200 * index} key={index}>
                            <SimpleCard
                              position={(
                                <Flex
                                  alignItems='center'
                                  justifyContent='center'
                                  border='1px solid #fff'
                                  borderRadius='50%'
                                  width='3.2rem'
                                  height='3.2rem'
                                  position='absolute'
                                  left='0.9rem'
                                  mt='0.1rem'
                                  fontSize='1.4rem'
                                >
                                  {i + 1 + (index === 0 ? 0 : AllValuesSettingsBeta(theme).colors[index - 1].rangeTo)}.
                                </Flex>
                              )}
                              key={mapValues[value.valueId].id}
                              name={<Paragraph 
                                fontWeight={500}
                                color={setting.theme.text}>
                                {mapValues[value.valueId].name}
                              </Paragraph>}
                              size='small'
                              theme={setting.theme}
                              afterOpacity={setting.afterOpacity}
                            />
                          </Fade>
                        ) : (
                          <SimpleCard name={'ERROR: ValueID not found'} color={'yellow'} />
                        )
                      )}
                    </React.Fragment>
                  )
                  )}
                <br />
              </Box>
            </>
          )) : AllValuesSetting.colors.map((setting, index) => (
            <>
              <Box key={index} display={visible? 'block' : AllValuesSetting.colors[index].visibilityWithoutPlus ? 'block' : 'none'}>
                {sortedValues
                  .slice(index === 0 ? 0 : AllValuesSetting.colors[index - 1].rangeTo, setting.rangeTo)
                  .map((value, i) => (
                    <React.Fragment key={i}>
                      {(setting.visibilityWithoutPlus || visible) && (
                        mapValues[value.valueId] !== undefined ? (
                          <Fade bottom ssrReveal duration={1000} distance={'5rem'} delay={200 * index} key={index}>
                            <SimpleCard
                              position={(
                                <Flex
                                  alignItems='center'
                                  justifyContent='center'
                                  border='1px solid #fff'
                                  borderRadius='50%'
                                  width='3.2rem'
                                  height='3.2rem'
                                  position='absolute'
                                  left='0.9rem'
                                  mt='0.1rem'
                                  fontSize='1.4rem'
                                >
                                  {i + 1 + (index === 0 ? 0 : AllValuesSetting.colors[index - 1].rangeTo)}.
                                </Flex>
                              )}
                              key={mapValues[value.valueId].id}
                              name={<Paragraph fontWeight={500}>{mapValues[value.valueId].name}</Paragraph>}
                              size='small'
                              color={setting.color}
                              afterOpacity={setting.afterOpacity}
                            />
                          </Fade>
                        ) : (
                          <SimpleCard name={'ERROR: ValueID not found'} color={'yellow'} />
                        )
                      )}
                    </React.Fragment>
                  )
                  )}
                <br />
              </Box>
            </>
          ))
          }
        </Box>

        {!visible && (
          <Flex flexDirection='column' mt='2.4rem' alignItems='center'>
            <Paragraph color='#fff' fontWeight={500}>
              Chcete vidět celý svůj žebříček?
            </Paragraph>

            <Paragraph mt='2rem' color='rgba(255, 255, 255, 0.7)' textAlign='center'>
              Vyzkoušej verzi Plus a získej mnoho výhod
              a statistik
            </Paragraph>

            <Box mt='2rem'>
              <PremiumButton />
            </Box>

            <Paragraph mt='1rem' color='#fff'>
              od 121 Kč
            </Paragraph>
          </Flex>
        )}
      </>
    </Modal>
  );
};
