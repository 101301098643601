import { Box, Button, Flex, Heading2, Heading3, Img, Link as UILink, Paragraph, Stack, Tag, Typography, useModal } from '@xcorejs/ui';
import { GameContext } from 'components/App';
import LoadingSpinnerLocal from 'components/atoms/Animations/LoadingSpinnerLocal';
import { PlusTag } from 'components/atoms/Logo';
import { PrimaryButton } from 'components/components/Button';
import LifeValuesModal from 'components/components/modals/LifeValuesModal';
import LifeValuesPresentingModal from 'components/components/modals/LifeValuesPresentingModal';
import ValueMapFull from 'components/molecules/ValueMap/types/ValueMapFull';
import withLifeValues, { IWithLifeValues } from 'components/molecules/withRedux/withLifeValues';
import { ONE_ROUND_TEST_ID } from 'components/pages/App/TestIntro/data';
import { DefaultSection10 } from 'components/pages/Presenting/data';
import { Section10 } from 'components/pages/Presenting/pages';
import { ITestTemplate } from 'components/pages/Sandbox/TestFactory';
import { IValueTestResultDetail } from 'config/api/valueTestResult';
import * as React from 'react';
import { useContext } from 'react';
import CountUp from 'react-countup';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import { Link } from 'react-router-dom';
import { Col, Grid, Row } from 'react-styled-flexboxgrid';
import { useFetchGameStatus, useFetchJournal } from 'utils/useQuery/queryFetchHelpers';
import { ILifeValue } from '../../../../../store/Entities/LifeValues/index';
import { IStringMap } from '../../../../../utils';
import { PrimaryButtonLink } from '../../../../atoms/Buttons';
import { StyledLinkBorder } from '../../../../atoms/Buttons/styles';
import ValueMap from '../../../../molecules/ValueMap';
import withUser, { IWithUser } from '../../../../molecules/withRedux/withUser';
import TestResult from '../../../../templates/TestResult';
import { BlueTheme, ColoredThemes, PlusTheme } from '../../../../theme/colors';
import { createUrl } from '../../../routes';
import { AllValuesModal } from '../PartnerTestResult/DefaultPages/DefaultValues';
import { EticonText, StyledTestResultSummary } from './styles';

interface ITestResultSummary {
  result: IValueTestResultDetail;
  values: IStringMap<ILifeValue>;
  state: any;
  submit: () => any | any;
  template: ITestTemplate;
  resultId: string;
}

const TestResultSummary: React.FC<ITestResultSummary & IWithUser & IWithLifeValues> = (
  {
    result,
    state,
    user,
    template,
    resultId,
    submit,
    lifeValues,
  }
) => {
  const { eticiaPlus, insideQuest } = useContext(GameContext);
  const [openLifeValuesModal] = useModal(LifeValuesModal);
  const [openLifeValuesPresentingModal] = useModal(LifeValuesPresentingModal);
  const plusOrVisible = eticiaPlus || template.info.resultFullWithoutEticiaPlusVisible;
  const [openAllValueModal] = useModal(AllValuesModal);

  const journalFetch = useFetchJournal();
  const gameStatusFetch = useFetchGameStatus();
  const journal = journalFetch.data;
  const detail = gameStatusFetch.data;
  
  const [showModal, setShowModal] = React.useState(false);
  
  const cleanLocalStorage = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    if (journal && journal.mains.length > 0) {
      window.location.href = insideQuest ? () => createUrl('app') + '/hra' : submit();
      localStorage.removeItem('firstTime');
      localStorage.setItem('openInfoModal', 'true');
      localStorage.setItem('infoModalGlow', 'true');
    } else {
      setShowModal(true);
    }
  };

  return (
    <TestResult>
      <Grid>
        <StyledTestResultSummary>
          <Row>
            <Fade ssrReveal bottom duration={1000} distance={'5rem'}>
              <Col xs={12}>
                <Flex flexDirection='column' alignItems='center' mb='3rem'>
                  <Tag
                    display='block'
                    mx='auto'
                    bg='transparent'
                    border='2px solid rgba(231, 230, 207, 0.5)'
                    py='0.4rem'
                    width='24rem'
                    justifyContent='center'
                    whiteSpace='nowrap'
                    overflow='hidden'
                    textOverflow='ellipsis'
                    textAlign='center'
                  >
                    {template.name}
                  </Tag>
                </Flex>
              </Col>
            </Fade>
          </Row>

          <Row>
            <Col
              xs={12}
              md={6}
              style={{
                paddingLeft: '0',
                paddingRight: '0',
              }}
            >
              <Zoom ssrReveal delay={400}>
                <Box mt='2rem' position='relative' pb='3rem'>
                  <ValueMap
                    customColorTheme={ColoredThemes[template.theme] ?? BlueTheme}
                    theme='normal'
                    imagePath={`https://www.eticia21.com/utils/share/one/${result.id}.png`}
                  >
                    <ValueMapFull theme='normal' lifeValues={result.allLifeValues} eticon={result.eticon} />
                  </ValueMap>

                  <Flex
                    flexDirection='column'
                    alignItems='center'
                    justifyContent='center'
                    mt='5rem'
                  >
                    {journal && detail ? (
                      <UILink
                        v='underline'
                        color='yellowWhite'
                        onClick={journal.myLifeValues 
                          ? openLifeValuesModal 
                          : detail.tribalTestResult 
                            ? () => openAllValueModal({
                              values: detail.tribalTestResult,
                              mapValues: lifeValues,
                              onClose: close
                            }) 
                            : undefined}
                        position='relative'
                      >
                        {journal.myLifeValues
                          ? (
                            <FormattedMessage id='valueCenter.strip1.allValues' />
                          ) : (
                            <FormattedMessage id='valueCenter.strip1.all' />
                          )
                        }
                      </UILink>
                    ) : <LoadingSpinnerLocal />}

                    {!eticiaPlus && (
                      <Flex flexDirection='column' alignItems='center'>
                        <Typography mt='1rem'>
                          <FormattedMessage id='valueCenter.strip1.allValuesText' />
                        </Typography>
                        <PlusTag
                          mt={{ _: '1rem', sm: 0 }}
                          ml={{ _: 0, sm: '1rem' }}
                        >
                          Eticia plus
                        </PlusTag>
                      </Flex>
                    )}
                    
                  </Flex>
                </Box>
              </Zoom>
            </Col>

            <Col xs={12} md={6}>
              {template.id === ONE_ROUND_TEST_ID ? (
                <Fade ssrReveal bottom duration={1000} distance={'5rem'}>
                  <div>
                    {journal ? (
                      <>
                        {journal.mains.length > 1 ? (
                          <Box>
                            <Heading2 mt='2rem' fontWeight={400}>
                              {template.info.resultSummaryHeader}
                            </Heading2>


                            {template.info.resultSummaryDescription && (
                              <EticonText>
                                <Typography mt='2rem'>
                                  <div
                                    dangerouslySetInnerHTML={{ __html: template.info.resultSummaryDescription }}
                                  />
                                </Typography>
                              </EticonText>
                            )}

                            {template.info.resultSummaryImage && (
                              <Img src={template.info.resultSummaryImage} alt='' mx='auto' mt='3rem' display='block' />
                            )}

                            {template.info.introAdditionalNote && (
                              <Typography mt='3rem'>
                                {template.info.introAdditionalNote}
                              </Typography>
                            )}

                            {template.info.resultSummaryLinkText && (
                              <Flex mt='2rem' flexDirection='column' alignItems='center'>
                                <UILink
                                  onClick={openLifeValuesPresentingModal}
                                  variant='underline'
                                  color='yellow'
                                  borderColor='yellow'
                                  mt='1rem'
                                  target='_blank'
                                >
                                  {template.info.resultSummaryLinkText}
                                </UILink>
                              </Flex>
                            )}

                            {journal.myLifeValues && (
                              <Button
                                onClick={() => openLifeValuesModal({ customVisible: plusOrVisible })}
                                bg='plus'
                                mt='3rem'
                                color='#fff'
                              >
                                    Prohlédni si své životní hodnoty
                              </Button>
                            )}
                          </Box>
                        ) : (
                          <Box>
                            <Heading3 mt='6rem' textAlign='center' color='yellow'>
                              <FormattedHTMLMessage id='test.result.final.afterYear.heading' />
                            </Heading3>
                                
                            <Paragraph mt='1rem' color='#fff'>
                              <FormattedHTMLMessage id='test.result.final.afterYear.text' />
                            </Paragraph>

                            <Paragraph color='rgba(255, 255, 255, 0.7)' mt='2rem'>
                              <FormattedHTMLMessage id='test.result.final.afterYear.text2' />
                            </Paragraph>

                            <Paragraph color='rgba(255, 255, 255, 0.7)' mt='2rem' fontSize='1.3rem' fontStyle='italic'>
                              <FormattedHTMLMessage id='test.result.final.afterYear.text3' />
                            </Paragraph>

                            <Box className='time-left'>
                              <img src='/img/result/409x90_zivotnu.png' className='deco1' alt='' />

                              <Box className='days'>
                                <strong>
                                  <CountUp
                                    start={0}
                                    end={
                                      Date.now() - result.doneAt * 1000 >= 0
                                      // eslint-disable-next-line max-len
                                        ? Math.floor(365 - (Date.now() - result.doneAt * 1000) / (24 * 60 * 60 * 1000))
                                        : 0
                                    }
                                    duration={3}
                                  />
                                </strong>
                                <FormattedMessage id={'result.days'} />
                              </Box>
                            </Box>

                            <Paragraph color='rgba(255, 255, 255, 1)' mt={{ _: 0, sm: '1rem' }} fontFamily='OliMedium'>
                                  zbývá do tvého dalšího žebříčku
                            </Paragraph>

                            {template.info.resultSummaryLinkText && (
                              <Flex mt={{ _: 0, sm: '2rem' }} flexDirection='column' alignItems='center'>
                                <UILink
                                  href={template.info.resultSummaryLinkUrl}
                                  variant='underline'
                                  color='yellow'
                                  borderColor='yellow'
                                  mt='3rem'
                                  target='_blank'
                                >
                                  {template.info.resultSummaryLinkText}
                                </UILink>
                              </Flex>
                            )}
                          </Box>
                        )}
                      </>
                    ) : <LoadingSpinnerLocal />}
                  </div>
                </Fade>
              ) : (
                <Fade ssrReveal bottom duration={1000} distance={'5rem'}>
                  <Box>
                    <Heading2 mt='2rem' fontWeight={400}>
                      {template.info.resultSummaryHeader}
                    </Heading2>

                    {template.info.resultSummaryDescription && (
                      <EticonText>
                        <Typography mt='2rem'>
                          <div
                            dangerouslySetInnerHTML={{ __html: template.info.resultSummaryDescription }}
                          />
                        </Typography>
                      </EticonText>
                    )}

                    {template.info.resultSummaryImage && (
                      <Img src={template.info.resultSummaryImage} alt='' mx='auto' mt='3rem' display='block' />
                    )}

                    {template.info.introAdditionalNote && (
                      <Typography mt='3rem'>
                        {template.info.introAdditionalNote}
                      </Typography>
                    )}

                    {template.info.resultSummaryLinkText && (
                      <Flex mt='2rem' flexDirection='column' alignItems='center'>
                        <UILink
                          href={template.info.resultSummaryLinkUrl}
                          variant='underline'
                          color='yellow'
                          borderColor='yellow'
                          mt='1rem'
                          target='_blank'
                        >
                          {template.info.resultSummaryLinkText}
                        </UILink>
                      </Flex>
                    )}
                  </Box>
                </Fade>
              )}
            </Col>
          </Row>

          {template.id === ONE_ROUND_TEST_ID && (
            <Row>
              <Col xs={12}>
                <Fade ssrReveal bottom duration={1000} distance={'5rem'}>
                  <Stack direction='column' gap='1rem' mt='6rem' wrapItems>
                    <Paragraph>
                      <FormattedMessage id='test.result.final.email.text' />
                    </Paragraph>

                    <Paragraph color='yellow' fontFamily='OliMedium'>
                      {user.email}
                    </Paragraph>

                    <Link to={createUrl('app.profile') + '/email'}>
                      <Button size='xs' v='outline' mt='1rem'>
                        <FormattedMessage id='settings.form.edit' />
                      </Button>
                    </Link>
                  </Stack>

                  <Box mt='3rem'>
                    {/*
                    <Heading2 mt='2rem' fontWeight={400}>
                      {template.info.resultSummaryHeader}
                    </Heading2>

                    <Typography mt='2rem'>
                      {template.info.resultSummaryDescription}
                    </Typography>
                    */}

                    {template.info.resultSummaryImage && (
                      <Img src={template.info.resultSummaryImage} alt='' mx='auto' mt='3rem' display='block' />
                    )}

                    {template.info.introAdditionalNote && (
                      <Typography mt='3rem'>
                        {template.info.introAdditionalNote}
                      </Typography>
                    )}
                  </Box>
                </Fade>
              </Col>
            </Row>
          )}
        </StyledTestResultSummary>
      </Grid>

      {!plusOrVisible && (
        <Box mt='4rem'>
          <Section10 data={DefaultSection10} />
        </Box>
      )}

      <Box mt='4rem'>
        <Grid>
          <StyledTestResultSummary>
            {template.id === '9834e2a8-2600-4abd-ba8d-3ef784023cb1' ? (
              <PrimaryButton as='a' href={createUrl('app.gameIntro') + '?t=1985206e-b0ea-49fd-abfc-d495357b6afd'}>
                <FormattedMessage id={'test.result.final.app'} />
              </PrimaryButton>
            ) :
              template.id === '1985206e-b0ea-49fd-abfc-d495357b6afd' ?
                (
                  <PrimaryButton as='a' href='https://betatest.meeticia.cz/home-success-waiting-fin'>
                    <FormattedMessage id={'test.result.final.app'} />
                  </PrimaryButton>
                ) : (
                  <PrimaryButtonLink
                    onClick={cleanLocalStorage}
                    to={insideQuest ? () => createUrl('app') + '/hra' : submit()}
                  >
                    <FormattedMessage id={'test.result.final.app'} />
                  </PrimaryButtonLink>
                )}
            <div>
              <StyledLinkBorder
                to={{
                  pathname: createUrl('app.testResult', { id: resultId }) + '/values',
                  state,
                }}
              >
                <FormattedMessage id={'test.result.back'} />
              </StyledLinkBorder>
            </div>

            {/*
            <Banner to={GetC2ALink(C2A)()}>
              <img src={GetC2A(C2A)} alt='Hodnoty pro dva' />
            </Banner>
            */}
          </StyledTestResultSummary>
        </Grid>
      </Box>

      {showModal && (
        <Flex
          position='fixed'
          top='0'
          left='0'
          right='0'
          bottom='0'
          background='rgba(0, 0, 0, 0.5)'
          zIndex={9999}
          justifyContent='center'
          alignItems='center'
        >
          <Flex
            minWidth='20rem'
            minHeight='30rem'
            background={PlusTheme.primary}
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            paddingX={{ _: '2rem', sm:'4rem' }}
            paddingY={{ _: '4rem', sm:'6rem' }}
          >
            <Heading2><FormattedMessage id='test.result.modal.heading' /></Heading2>

            <Paragraph marginTop='2rem' maxWidth='45rem'>
              <FormattedMessage id='test.result.modal.text' />
            </Paragraph>

            <Paragraph marginTop='1rem' maxWidth='45rem'>
              <FormattedMessage id='test.result.modal.text2' />
            </Paragraph>

            <PrimaryButton onClick={() => location.href = createUrl('app')} marginTop='6rem'>
              <FormattedMessage id='test.result.modal.button' />
            </PrimaryButton>
          </Flex>

        </Flex>
      )}
    </TestResult>
  );
};

export default withLifeValues(withUser(TestResultSummary));
