import * as React from 'react';
import { ConnectDropTarget } from 'react-dnd';
import { ICardValue } from '../../data';
import EmptyCard from '../EmptyCard';
import DraggableCard from '../DraggableCard';
import { StyledCardPlaceholder } from './styles';
import ClickableCard from '../ClickableCard';
import { ValueCardState } from '../ValueCard/index';

export interface IProps {
  connectDropTarget: ConnectDropTarget;
  selectValue: (value: string, index: number) => any;
  resetValue: (value: string) => any;
  index: number;
  state: ValueCardState;
  card?: ICardValue;
  text: React.ReactNode;
  showNumber: boolean;
  requiredValuesCount?: number;
}

interface IState {
  touchMoving: boolean;
}

class CardPlaceholder extends React.Component<IProps, IState> {
  public readonly state: IState = { touchMoving: true };

  public render() {
    const { showNumber, connectDropTarget, card, index, state, text, requiredValuesCount } = this.props;

    return connectDropTarget(
      <div>
        <StyledCardPlaceholder>
          <ClickableCard onClick={this.resetValue}>
            <EmptyCard index={showNumber ? index + 1 + '.' : ''} text={text} requiredValuesCount={requiredValuesCount} />

            <div className={'card'}>{!card ? null : <DraggableCard card={card} state={state} />}</div>
          </ClickableCard>
        </StyledCardPlaceholder>
      </div>,
    );
  }

  private resetValue = () => !!this.props.card && this.props.resetValue(this.props.card.value.id);
}

export default CardPlaceholder;
