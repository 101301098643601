import { Box } from '@xcorejs/ui';
import * as React from 'react';
import { Fragment, useEffect, useState } from 'react';
import { matchPath } from 'react-router';
import { Route, RouteComponentProps, withRouter } from 'react-router-dom';
import { useFetchTestResultDetail } from 'utils/useQuery/queryFetchHelpers';
import LoadingSpinnerLocal from '../../../../atoms/Animations/LoadingSpinnerLocal';
import NavItem from '../../../../atoms/Nav/NavItem';
import Subnav from '../../../../atoms/Subnav';
import withLifeValues, { IWithLifeValues } from '../../../../molecules/withRedux/withLifeValues';
import { createUrl } from '../../../routes';
import TestResultDefault from './TestResultDefault';
import TestResultDetailed from './TestResultDetailed';
import TestResultSummary from './TestResultSummary';
import TestResultValues from './TestResultValues';

interface IResultState {
  wasHandled: boolean;
}

const ValueTestResult: React.FC<RouteComponentProps<any> & IWithLifeValues & IResultState> = (props) => {
  const [wasHandled, setWasHandled] = useState<boolean>(false);

  const testResultFetch = useFetchTestResultDetail(props.match.params.id);

  useEffect(() => {
    setWasHandled(false);
  }, [props.match.params.id]);

  const { lifeValues, location } = props;

  const baseUrl = createUrl('app.testResult', {
    id: props.match.params.id,
  });

  if (testResultFetch.isFetching) {
    return <LoadingSpinnerLocal />;
  }

  if (testResultFetch.isError) {
    console.log(testResultFetch.error);
    return <Fragment>Error</Fragment>;
  }

  const result = testResultFetch.data!;

  return (
    <Box>
      {matchPath(location.pathname, {
        path: createUrl('test-result') + '/final',
        exact: true,
      }) === null && (
        result.testConfig.template.info.resultCharacteristicsVisible ||
                    result.testConfig.template.info.resultCharacteristicsChartVisible ||
                    result.testConfig.template.info.resultFigurineVisible ? (
            <Subnav stepsOnMobile={true}>
              <NavItem to={baseUrl} label={'results.menu.default'} exact />
              <NavItem to={baseUrl + '/detail'} state={location.state} label={'results.menu.detail'} />
              <NavItem to={baseUrl + '/values'} state={location.state} label={'results.menu.values'} />
              <NavItem to={baseUrl + '/summary'} state={location.state} label={'results.menu.summary'} />
            </Subnav>
          ) : (
            <Subnav stepsOnMobile={true}>
              <NavItem to={baseUrl} label={'results.menu.default'} exact />
              <NavItem to={baseUrl + '/values'} state={location.state} label={'results.menu.values'} />
              <NavItem to={baseUrl + '/summary'} state={location.state} label={'results.menu.summary'} />
            </Subnav>
          )
      )}
      <div>
        <Route
          path={baseUrl}
          exact={true}
          render={() => (
            <TestResultDefault
              template={result.testConfig.template}
              result={result.valueTestResult}
              values={lifeValues}
              state={location.state}
              resultId={result.id}
            />
          )}
        />
        <Route
          path={baseUrl + '/detail'}
          exact={true}
          render={() => (
            <TestResultDetailed
              template={result.testConfig.template}
              result={result.valueTestResult}
              state={location.state}
              resultId={result.id}
            />
          )}
        />
        <Route
          path={baseUrl + '/values'}
          exact={true}
          render={() => (
            <TestResultValues
              template={result.testConfig.template}
              result={result.valueTestResult}
              values={lifeValues}
              state={location.state}
              resultId={result.id}
            />
          )}
        />
        <Route
          path={baseUrl + '/summary'}
          exact={true}
          render={() => (
            <TestResultSummary
              template={result.testConfig.template}
              result={result.valueTestResult}
              values={lifeValues}
              state={location.state}
              //submit={() => createUrl('app.journal')}
              submit={() => createUrl('app')}
              resultId={result.id}
            />
          )}
        />

        {console.log(result.testConfig)}
      </div>
    </Box>
  );
};

export default withLifeValues(withRouter(ValueTestResult));