import { IStringMap } from '../../../utils';
import { rescource, IRescource, createRescourceActions } from '../../../utils/rescource';
import { fetchTask, normalize } from '../../../utils/helpers';
import { createReducer } from 'redux-act';
import { NOTIFICATIONS_UNREAD, NOTIFICATIONS_MARK } from 'config/apiRoutes';

export const notificationsInitState: IRescource<IStringMap<INotification>> = rescource<IStringMap<INotification>>(
  'init',
  null,
);

export interface INotification {
  id: string;
  title: string;
  message: string;
  createdAt: number;
  read: boolean;
  status: string;
  type: string;
}

const actions = createRescourceActions('notifications');

export const fetchNotifications = () =>
  fetchTask<INotification[]>(NOTIFICATIONS_UNREAD, actions.fetching, actions.success, actions.fail);

export const markAsReadNotification = (id: string): any =>
  fetchTask<INotification[]>(
    { url: NOTIFICATIONS_MARK(id), init: { method: 'POST' } },
    actions.fetching,
    actions.success,
    actions.fail,
  );

export const notificationsReducer = createReducer<IRescource<IStringMap<INotification>>>(
  {
    [actions.fetching.getType()]: state => ({
      ...state,
      ...rescource('fetching', null),
    }),
    [actions.success.getType()]: (state, payload: INotification[]) => ({
      ...state,
      ...rescource(
        'success',
        normalize(payload, itm => itm.id),
      ),
    }),
    [actions.fail.getType()]: state => ({
      ...state,
      ...rescource('fail', null),
    }),
  },
  notificationsInitState,
);
