import styled, { css } from 'styled-components';
import { mediaLgDefault, mediaMdDefault, mediaSmDefault, mediaXlDefault, mediaXsDefault } from '../../theme/grid';
import { Row } from 'react-styled-flexboxgrid';
import { Heading2 } from '../../atoms/Headings';
import { darkBaseHEX, BlueTheme } from '../../theme/colors';
import { setStyleValue, PropObjectSizing } from 'utils/setStyleValue';
import { BoxProps } from '@xcorejs/ui';

export const StyledGamesForTwoAdverb = styled.section`
  position: relative;
  //background: radial-gradient(circle at top, #1c3389 0%, #0e2370 100%);
  background: url("/img/homepage/strip/bg.png") center center no-repeat;
  background-size: cover;

  a {
    display: block;
    height: 56rem;
    margin: 0 auto;
    background: url("/img/homepage/strip/HP_mobile.png") center center no-repeat;
    background-size: contain;

    ${mediaSmDefault} {
      height: 175px;
      background: url("/img/homepage/strip/HP_768.png") center center no-repeat;
      background-size: contain;
    }

    ${mediaMdDefault} {
      height: 233px;
      background: url("/img/homepage/strip/HP_1024.png") center center no-repeat;
      background-size: contain;
    }

    ${mediaLgDefault} {
      height: 291px;
      background: url("/img/homepage/strip/HP_1280.png") center center no-repeat;
      background-size: contain;
    }

    ${mediaXlDefault} {
      height: 291px;
      background: url("/img/homepage/strip/HP_1440.png") center center no-repeat;
      background-size: contain;
    }
  }
`;

export const StyledVisualCards = styled.div`
  padding-top: 3rem;
  padding-bottom: 1.5rem;
  display: block;
  background: radial-gradient(circle at top, #009966 0%, #008358 100%);

  .info-cards {
    & > ${Row} {
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }

    ${Heading2} {
      margin-bottom: 2rem;
      font-weight: 400;
      color: #fff;
    }
  }

  ${mediaMdDefault} {
    display: none;
  }
`;

export const StyledDayValue = styled.div`
  text-align: center;

  .external-svg {
    margin-top: 3rem;

    svg {
      width: 4.8rem;
      height: 4.8rem;
    }
  }

  strong {
    color: ${darkBaseHEX};
  }

  p {
    margin-top: 2rem;
    color: #fff;
    font-size: 1.6rem;
    line-height: 1.6rem;
  }

  small {
    display: block;
    margin: 1rem 0;
    font-size: 1.3rem;
    line-height: 1.3rem;
    color: rgba(255, 255, 255, 0.8);
  }
`;

interface SectionHomepageProps {
  backgroundColor?: string;
  backgroundImage?: string;
  display?: 'block' | 'flex' | 'grid';
  pb?: string | PropObjectSizing;
  backgroundSize?: string | PropObjectSizing;
  backgroundPosition?: string | PropObjectSizing;
  minHeight?: string;
}

export const SectionHomepage = styled.section<SectionHomepageProps>`
  position: relative;
  background-color: ${props => props.backgroundColor || 'transparent'};
  background-image: ${props => props.backgroundImage ? `url(${props.backgroundImage})` : 'none'};
  background-size: ${props => setStyleValue(props.backgroundSize, 'cover')};
  background-position: ${props => setStyleValue(props.backgroundPosition, 'center')};
  background-repeat: no-repeat;
  display: ${props => props.display || 'block'};
  padding-bottom: ${props => setStyleValue(props.pb, '6rem')};
  padding-top: 6rem;
  padding-left: 3rem;
  padding-right: 3rem;
  ${({ minHeight }) => minHeight && `min-height: ${minHeight};`}

  ${props => {
    if (props.display === 'flex') {
      return css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      `;
    }

    if (props.display === 'grid') {
      return css`
        display: grid;
        place-items: center;
      `;
    }

    return '';
  }};

  ${mediaXsDefault} {
    background-size: ${props => setStyleValue(props.backgroundSize, 'cover', 'xs')};
    background-position: ${props => setStyleValue(props.backgroundPosition, 'center', 'xs')};
  }

  ${mediaSmDefault} {
    background-size: ${props => setStyleValue(props.backgroundSize, 'cover', 'sm')};
    background-position: ${props => setStyleValue(props.backgroundPosition, 'center', 'sm')};
  }

  ${mediaMdDefault} {
    padding-bottom: ${props => typeof props.pb === 'object' && props.pb.md};
    background-size: ${props => setStyleValue(props.backgroundSize, 'cover', 'md')};
    background-position: ${props => setStyleValue(props.backgroundPosition, 'center', 'md')};
  }
`;

interface ButtonProps {
  color?: string;
  changeColor?: boolean;
  borderColor?: string;
  backgroundColor?: string;
  mt?: string;
  position?: 'static' | 'absolute' | 'fixed' | 'relative' | 'sticky';
  narrow?: boolean;
  disabled?: boolean;
  opacity?: number;
}

export const Button = styled.button<ButtonProps>`
  position: ${props => props.position || 'static'};
  display: flex;
  align-items: center;
  padding: 1rem ${({ narrow }) => narrow ? '2rem' : '3rem'}
  border: 2px solid ${props => props.borderColor || '#fff'};
  background-color: ${props => props.backgroundColor || '#333'};
  border-radius: 10rem;
  color: ${props => props.color || 'white'};
  font-family: 'Oli Grotesk Regular';
  font-size: 1.2rem;
  line-height: 1;
  transition: background-color 300ms ease ${(props) => props.changeColor && ',color 300ms ease '};
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  margin-top: ${props => props.mt || '0'};
  width: fit-content;
  opacity: ${({ opacity }) => opacity || 1};

  ${({ disabled, borderColor, changeColor }) => !disabled && `
    &:hover {
      background-color: ${borderColor || '#333'};
      ${changeColor && 'color: #fff;'}
    }
  `}


  ${mediaMdDefault} {
    padding: 1.2rem 4rem;
    font-size: 1.6rem;
  }
`;