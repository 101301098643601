import styled from 'styled-components';
import { mediaSmDefault, mediaSmMax } from '../../../theme/grid';
import {
  blue,
  BlueTheme,
  darkBaseRGB,
  darkenGreen,
  gray, PlusTheme,
  primaryGreen,
  violet,
  darkenViolet,
  yellow,
} from '../../../theme/colors';
import { Heading2 } from '../../Headings';
import { CustomText } from '../../Text';
import { StyledLinkBorder } from '../../Buttons/styles';
import { StyledQuestObjective } from 'components/organisms/QuestLog/styles';

export const LevelUp = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 85rem;
  max-width: 85vw;
  margin: 0 auto;
  color: #fff;
  border-radius: 0.4rem;
  white-space: normal;
  overflow: auto;
  box-shadow: 0 1rem 2rem 0 rgba(${darkBaseRGB}, 0.5);

  .content {
    padding: 3rem 4rem 3rem 3rem;
    max-height: 90vh;

    ${mediaSmDefault} {
      padding-left: 10rem;
      padding-right: 10rem;
    }

    img {
      height: 13rem;
    }
  }

  .close {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 4rem;
    height: 4rem;
    top: 1.5rem;
    right: 1.5rem;
    border: 2px solid ${yellow};
    border-radius: 50%;

    ${mediaSmDefault} {
      top: 3rem;
      right: 3rem;
    }
  }

  ${mediaSmMax} {
    max-width: 100vw;
    max-height: 90vh;

    .close {
      right: 0.5rem;
      top: 0.5rem;
    }
  }

  ${Heading2} {
    margin-top: 2rem;
    font-size: 1.8rem;
    line-height: 2.7rem;
  }

  ${CustomText} {
    margin-top: 2rem;
    color: #fff;
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align: center;
  }

  small {
    margin-top: 4rem;
    margin-bottom: 3rem;
    display: block;
    font-size: 1.4rem;
    line-height: 1.7rem;
    text-align: center;
    color: ${gray};

    ${mediaSmDefault} {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    svg {
      margin-right: 0.8rem;
      width: 1.6rem;
      height: 1.6rem;
      min-width: 1.6rem;

      path {
        fill: ${gray};
      }
    }

    ${StyledLinkBorder} {
      font-size: 1.4rem;
      line-height: 1.7rem;
    }
  }

  ${StyledQuestObjective} {
    text-align: left;

    .ico-wrap {
      display: block;

      svg {
        path {
          fill: #fff;
        }
      }
    }

    ${mediaSmDefault} {
      width: auto;
    }
  }

  .next {
    margin-top: 1rem;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    ${mediaSmDefault} {
      flex-direction: row;
    }
  }
`;

export const LevelUp1 = styled(LevelUp)`
  background: radial-gradient(circle at top, ${primaryGreen} 0%, ${darkenGreen} 100%);

  ${Heading2} {
    color: ${yellow};
  }

  *::selection {
    color: ${primaryGreen};
    background: #fff;
  }
`;

export const LevelUp2 = styled(LevelUp)`
  background: radial-gradient(circle at top, ${violet} 0%, ${darkenViolet} 100%);

  ${Heading2} {
    color: ${blue};
  }

  *::selection {
    color: ${violet};
    background: #fff;
  }
`;

export const LevelUp3 = styled(LevelUp)`
  background: radial-gradient(circle at top, #b5b416 0%, #a29212 100%);

  ${Heading2} {
    color: #5d1dbf;
  }

  *::selection {
    color: #b5b416;
    background: #fff;
  }
`;

export const LevelUp4 = styled(LevelUp)`
  background: radial-gradient(circle at top, ${PlusTheme.primary} 0%, ${PlusTheme.darken} 100%);

  ${Heading2} {
    color: #fff;
  }

  *::selection {
    color: ${PlusTheme.primary};
    background: #fff;
  }
`;

export const LevelUp5 = styled(LevelUp)`
  background: radial-gradient(circle at top, ${BlueTheme.primary} 0%, ${BlueTheme.darken} 100%);

  ${Heading2} {
    color: #fff;
  }

  *::selection {
    color: #b5b416;
    background: #fff;
  }
`;
