import * as React from 'react';
import { StyledValueDesc } from './styles';
import Zoom from 'react-reveal/Zoom';
import { IEticon } from '../../../store/Entities/Eticons';
import { Heading2 } from '@xcorejs/ui';

interface IValueDesc {
  eticon: IEticon;
  heading: string | null;
}
class ValueDecs extends React.Component<IValueDesc> {
  public render() {
    const { eticon, heading } = this.props;

    return (
      <StyledValueDesc {...this.props}>
        {heading && <Heading2 mt='2rem' color='#fff'>{eticon ? eticon.name : ''}</Heading2>}

        <Zoom delay={400}>
          <picture className='eticon-img'>
            <source
              media={'(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)'}
              srcSet={eticon ? eticon.icon : ''}
            />
            <img src={eticon ? eticon.icon : ''} alt={eticon ? eticon.name : ''} />
          </picture>
        </Zoom>
      </StyledValueDesc>
    );
  }
}

export default ValueDecs;
