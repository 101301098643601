import React, { FC, useEffect, useCallback } from 'react';
import { IPerson } from 'store/Entities/LifeValues';
import { Flex, Img, Paragraph, Link, Heading3, Box } from '@xcorejs/ui';
import styled from 'styled-components';
import SVGarrow from 'components/atoms/SVGIcons/SVGarrow';
import { StyledGrid } from '../styles';
import { OliLight } from 'components/theme/fonts';
import { useShowArrow, useGetBoxHeight, useHandleProjectChange } from './customHook';
import CreateLink, { fontSize } from '../Link';

interface AtlasCarouselProps {
    projects: IPerson[];
    color?: string;
}

const AtlasCarousel: FC<AtlasCarouselProps> = ({ projects, color }) => {
  const PROJECT_OFFSET = 4;
  const DEFAULT_GRID_HEIGHT = { _: projects.length > 3 ? '33rem' : '16rem', md: projects.length > 3 ? '35.5rem' : '20rem' };

  const [infoCardVisible, setInfoCardVisible] = React.useState(false);  
  const infoBoxRef = React.useRef<HTMLDivElement>(null);
  
  const { getBoxHeight, infoBoxHeight } = useGetBoxHeight(infoBoxRef);
  const { cursor, display } = useShowArrow({ projects, infoCardVisible });
  const { currentProjectInfo, handleProjectChange, currentProjectIndex, projectsPage } = useHandleProjectChange({ 
    projects, 
    infoCardVisible, 
    setInfoCardVisible, 
    getBoxHeight, 
    offset: PROJECT_OFFSET
  });
    
  const offsetProjects = () => projects
    .slice(projectsPage * PROJECT_OFFSET, projectsPage * PROJECT_OFFSET + PROJECT_OFFSET);

  return (
    <Box
      height={{ 
        _: infoCardVisible ? infoBoxHeight : DEFAULT_GRID_HEIGHT._, 
        md: infoCardVisible ? infoBoxHeight : DEFAULT_GRID_HEIGHT.md
      }}
      transition='height 1.5s ease'
    >
      <Paragraph fontSize={{ _: '1.4rem', md: '1.6rem' }}>text<br />druhý řádek</Paragraph>

      <StyledGrid>
        {offsetProjects().map((project) => (
          <CubeFlex 
            key={project.id}
            justifyContent='flex-start'
            alignItems='center'
            maxWidth='20rem'
            cursor='pointer'
            onClick={() => handleProjectChange({ index: projects.findIndex(el => el.id === project.id), open: true })}
          >
            <Img src={project.image}  alt='img' />
          </CubeFlex>
        ))}
      </StyledGrid>

      <Flex
        position='absolute'
        bottom='0'
        left='0'
        width='100%'
        overflowY='hidden'
        height={ infoCardVisible ? infoBoxHeight : '0px' }
        transition='height 1.5s ease'
        justifyContent='center'
        alignItems='start'
      >
        <Flex
          position='relative'
          backgroundColor={ color || 'transparent' }
          width='100%'
          height='fit-content'
          minHeight='35.5rem'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          ref={ infoBoxRef }
        >
          <Flex
            width='100%'
            height={{ _: '3rem', md: '3.5rem' }}
            justifyContent='center'
            alignItems='center'
            onClick={() => setInfoCardVisible(false)} 
            cursor='pointer'>
            <Box
              position='relative'
              top='0'
              transform='rotate(270deg) scale(0.3)'
              _hover={{ top: '2px' }}
              transition='top 300ms ease'            
            >
              <SVGarrow />
            </Box>
          </Flex>

          <Heading3 
            mt={{ _: '0 !important', md: '1.5rem' }}
            color='rgb(255,255,255)'
            fontSize={fontSize.heading}
            maxWidth='40rem'
          >
            {currentProjectInfo.name}
          </Heading3>

          <Paragraph
            mt='1rem'
            fontSize={fontSize.paragraph}
            fontFamily={OliLight}
            maxWidth='30rem'
          >
            {currentProjectInfo.profession}
          </Paragraph>

          <Img src={currentProjectInfo.image} alt='img'  mt='1rem' />

          <Paragraph
            mt='1rem'
            fontSize={fontSize.paragraph}
            fontFamily={OliLight}
            maxWidth='30rem'
          >
            {currentProjectInfo.content}
          </Paragraph>

          <CreateLink string={currentProjectInfo.life} />
        </Flex>
      </Flex>

      <Box
        opacity={projects.length <= 4 && !infoCardVisible ? 0 : 1}
        position='absolute'
        left={{ _:'-26px', sm: '-35px' }}
        top='145px'
        transform={{ _:'rotate(0deg) scale(0.5)', sm: 'rotate(0deg) scale(0.7)' }}
        cursor={cursor}
        display={display}
        _hover={{ left: { _:'-28px', sm: '-37px' } }}
        transition='left 300ms ease, opacity 300ms ease'
        onClick={() => handleProjectChange({ index: currentProjectIndex - 1 < 0 ? projects.length - 1 : currentProjectIndex - 1, direction: 'left' })}
      >
        <SVGarrow />
      </Box>

      <Box
        opacity={projects.length <= 4 && !infoCardVisible ? 0 : 1}
        position='absolute'
        right={{ _:'-26px', sm: '-35px' }}
        top='145px'
        transform={{ _:'rotate(180deg) scale(0.5)', sm: 'rotate(180deg) scale(0.7)' }}
        cursor={cursor}
        display={display}
        _hover={{ right: { _:'-28px', sm: '-37px' } }}
        transition='right 300ms ease, opacity 300ms ease'
        onClick={() => handleProjectChange({ index: currentProjectIndex + 1 > projects.length - 1 ? 0 : currentProjectIndex + 1, direction: 'right' })}
      >
        <SVGarrow />
      </Box>
    </Box>
  );
};
 
export default AtlasCarousel;

const CubeFlex = styled(Flex)`
  aspect-ratio: 6/5;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    background: url('/img/atlas/eye.svg') no-repeat center;
    background-size: contain;
    right: 3px;
    top: 3px;
    width: 18px;
    height: 18px;
  }
`;
