import * as React from 'react';
import { IProfile } from '../data';
import { IStringMap } from '../../../../../../utils';
import { Col, Row } from 'react-styled-flexboxgrid';
import { CardList } from '../../../../../organisms/PartnerTest/Result/styles';
import { Heading2 } from '../../../../../atoms/Headings';
import { StyledResultBoxes } from '../../../../../molecules/TestResult/AnswerBox/styles';
import { IValueTestResultDetail } from 'config/api/valueTestResult';
import ValuesGraph from '../../../../../molecules/Results/Graph/ValuesGraph';
import SimpleCard from '../../../../../atoms/Cards/SimpleCard';
import { blue, textGreen } from '../../../../../theme/colors';
import { Box } from '@xcorejs/ui';
import { Flex } from '@xcorejs/ui';
import { SectionText } from '../../../../../atoms/Text';
import { FormattedMessage } from 'react-intl';
import FAQPopup from '../../../../../atoms/Popups/FAQPopup';
import Fade from 'react-reveal/Fade';
import { GraphLegendStyle, StyledValueComparisonSection } from '../../../ValueComparison/Comparisons/styles';
import PopupBase from '../../../../../atoms/Popups/PopupBase';
import { GraphLegend } from '../../../../../organisms/PartnerTest/Result/graphLegend';
import VimeoPopup from '../../../../../atoms/Popups/VimeoPopup';
import withPopups, { IWithPopups } from '../../../../../molecules/withRedux/withPopups';
import { ValueTestResultGraph } from '../../../../../atoms/Popups/FAQPopup/popups';
import { HelpLink } from '../../../../../atoms/Popups/HelpLink';
import ExternalSVG from '../../../../../atoms/ExternalSVG';

interface IData {
  profiles: IStringMap<IProfile>;
  characteristics: IStringMap<IValueTestResultDetail>;
  eticons: IStringMap<IValueTestResultDetail>;
}

export const GraphLegendPopup = () => (
  <PopupBase color={'blue'} button={'popup.btn.close'}>
    <GraphLegendStyle>
      <Heading2>Popis grafu</Heading2>
      {GraphLegend}
    </GraphLegendStyle>
  </PopupBase>
);

const DefaultCharacters: React.FC<IData & IWithPopups> = ({ profiles, characteristics, eticons, popups }) => (
  <StyledValueComparisonSection>
    <StyledResultBoxes>
      <Row>
        {Object.keys(profiles).map((key, index) => (
          <Col xs={12} md={5} mdOffset={index === 0 ? 1 : 0} lg={4} lgOffset={index === 0 ? 2 : 0} key={index}>
            <Fade bottom ssrReveal duration={1000} distance={'5rem'}>
              <Flex alignItems='center' justifyContent='center'>
                <Box minWidth='6rem' minHeight='6rem' width='6rem' height='6rem' mr='1rem'>
                  <ExternalSVG src={eticons[key].eticon.avatar} className={`avatar i${index}`} />
                </Box>

                <Heading2 key={profiles[key].id} color={index === 0 ? 'green' : 'blue'}>
                  {profiles[key].fullname == null ? profiles[key].nick : profiles[key].fullname}
                </Heading2>
              </Flex>

              <Box mt='3rem'>
                <ValuesGraph
                  points={[
                    {
                      points: characteristics[key].characters.map(x => x.percentage),
                      fill: index === 0 ? 'rgba(0,229,141,0.25)' : 'rgba(71,167,255,0.25)',
                      fillOpacity: '40',
                      stroke: index === 0 ? '#00E58D' : '#47A7FF',
                      strokeWidth: '3px',
                    },
                  ]}
                  letterColor={index === 0 ? 'green' : 'blue'}
                  backgroundIndex={0}
                />
              </Box>
              <Box mt='2rem'>
                {index === 0 ? (
                  <FAQPopup popup={GraphLegendPopup}>
                    <SectionText color={textGreen}>
                      <FormattedMessage id={'partnerResult.graph.faq1'} />
                    </SectionText>
                  </FAQPopup>
                ) : (
                  <SectionText color={blue} onClick={() => popups.custom(() => <VimeoPopup id={'376328496'} />)}>
                    <FormattedMessage id={'partnerResult.graph.faq2'} />
                  </SectionText>
                )}
              </Box>

              <Box mt='3rem'>
                <CardList>
                  {characteristics[key].bulletCharacteristics.length !== 0 ? (
                    characteristics[key].bulletCharacteristics.map((characteristic, i) => (
                      <Fade bottom ssrReveal duration={1000} distance={'5rem'} delay={200 * i} key={i}>
                        <Box mb='1rem'>
                          <SimpleCard color={index === 0 ? 'green' : 'blue'} name={characteristic} size='small' />
                        </Box>
                      </Fade>
                    ))
                  ) : (
                    <SimpleCard color={index === 0 ? 'green' : 'blue'} name='empty' size='small' />
                  )}
                </CardList>
              </Box>
            </Fade>
          </Col>
        ))}
      </Row>
    </StyledResultBoxes>

    <FAQPopup popup={ValueTestResultGraph} className={'faq-popup-button'}>
      <HelpLink size={3} />
    </FAQPopup>
  </StyledValueComparisonSection>
);

export default withPopups(DefaultCharacters);
