import * as React from 'react';
import { ISurveyDiscreteRatingQuestion, IQuestionHelp } from '../../../data';
import { Row, Col } from 'react-styled-flexboxgrid';
import Scaler from '../../../../../../atoms/FormItems/PartnerTest/Scaler';
import { Answers } from '../../../styles';

export interface ILayoutA {
  data: ISurveyDiscreteRatingQuestion;
  help: IQuestionHelp;
  onChange: (id: string) => void;
  selected: string | null;
  layout: 'a' | 'b';
  theme?: string;
}

const LayoutA: React.FC<ILayoutA> = ({ data, help, selected, onChange, layout, theme }) => {
  return (
    <Row>
      <Col xs={12}>
        <h1 dangerouslySetInnerHTML={{ __html: data.text as string }} />

        {data.description !== null && <p dangerouslySetInnerHTML={{ __html: data.text as string }} />}

        <Answers>
          <Scaler
            dynamicImg={layout === 'b'}
            image={data.image!}
            values={data.answers}
            selected={selected}
            onChange={onChange}
          />
        </Answers>
      </Col>
    </Row>
  );
};

export default LayoutA;
