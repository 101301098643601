import * as React from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { mediaSmDefault } from '../../theme/grid';

interface ICustomFade {
  delay?: number;
  in?: boolean;
}

const CustomFade: React.FC<ICustomFade> = props => (
  <CSSTransition in={props.in} appear={true} timeout={1} classNames='fade'>
    <div>
      <StyledCustomFade>{props.children}</StyledCustomFade>
    </div>
  </CSSTransition>
);

export default CustomFade;

export const StyledCustomFade = styled.div`
  ${mediaSmDefault} {
    opacity: 0;
    top: 30px;
    position: relative;
    transition: 1s opacity, 1s top;
    height: 100%;

    .fade-enter-active > & {
      height: 0;
    }

    .fade-appear-active > &,
    .fade-enter-active > &,
    .fade-enter-done > & {
      opacity: 1;
      top: 0%;
    }

    .fade-exit-done > &,
    .fade-exit-active > & {
      opacity: 0;
      top: 30px;
      height: 0;
    }
  }

  .fade-exit-done > & {
    display: none;
  }
`;

export const StyledDelayedFade = styled.div`
  ${mediaSmDefault} {
    opacity: 0;
    top: 30px;
    position: relative;
    transition: 1s opacity, 1s top;
    width: 100%;

    .fade-enter-active > & {
      height: 0;
    }

    .fade-appear-active > &,
    .fade-enter-done > & {
      opacity: 1;
      top: 0%;
    }

    .fade-exit-done > &,
    .fade-enter-active > &,
    .fade-exit-active > & {
      opacity: 0;
      top: 30px;
    }
  }

  .fade-exit-done > & {
    display: none;
  }
`;
