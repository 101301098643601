import * as React from 'react';
import { StyledTestScreen, StyledRoundProgress, DropTargetBox } from './styles';
import { Row, Col } from 'react-styled-flexboxgrid';
import { FormattedMessage } from 'react-intl';
import { PrimaryButton } from '../../../atoms/Buttons';
import Options from './Options';
import TestDivider from '../../../atoms/TestDivider';
import Fade from 'react-reveal/Fade';
import wrapIntl, { IWrapIntl } from '../../../atoms/wrapIntl/index';
import { TestGridClassic } from '../../../theme/grid';
import { ThemeProvider } from 'styled-components';
import Selected from './Selected';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { getRoundStep, getData, ISelectedValue } from './data';
import withLifeValues, { IWithLifeValues } from '../../../molecules/withRedux/withLifeValues';
import DragLayer from './DragLayer';
import { withRouter, RouteComponentProps } from 'react-router';
import { ICurrentRound, addRecordData, IAddRecord } from '../data';
import { ITestTemplate } from 'components/pages/Sandbox/TestFactory';

const size: any = {
  options: { xs: 24, sm: 12, md: 8 },
  selected: { xs: 24, sm: 12 },
  selectedFinal: { xs: 24, sm: 24 },
};

export interface IRoundStep {
  round: ICurrentRound;
  size: number;
  steps: number;
  submit: (data: IAddRecord<ISelectedValue[]>) => unknown;
  openValueDefinition: (id: string) => unknown;
  template: ITestTemplate;
}

interface IRoundStepState {
  selected: Array<string | null>;
  rowOffset: number;
}

class RoundStep extends React.Component<
  IRoundStep & IWrapIntl & IWithLifeValues & RouteComponentProps<{}>,
  IRoundStepState
> {
  public readonly state: IRoundStepState = {
    selected: Array(this.props.size).fill(null),
    rowOffset: 0,
  };

  public render() {
    const round = getRoundStep(this.props.round, this.props.lifeValues);

    const remains = this.remains();

    return (
      <ThemeProvider theme={TestGridClassic}>
        <StyledTestScreen isFinalStep={round.type === 'final'}>
          <Row>
            <Col xs={24} md={14}>
              <Options
                round={round}
                selected={this.state.selected}
                size={size}
                selectValue={this.selectValue}
                resetValue={this.resetValue}
                remains={remains}
                openValueDefinition={this.props.openValueDefinition}
                rowOffset={this.state.rowOffset}
              />
            </Col>
            <Col xs={24} md={3}>
              <TestDivider
                withArrows={true}
                next={this.state.rowOffset + 3 < Object.keys(round.values).length / 4}
                prev={this.state.rowOffset !== 0}
                onNext={() => this.setState({ rowOffset: this.state.rowOffset + 1 })}
                onPrev={() => this.setState({ rowOffset: this.state.rowOffset - 1 })}
              />
            </Col>
            <Col xs={24} md={7}>
              <Fade ssrReveal bottom duration={1000} distance={'5rem'}>
                <div className='flex-vertical'>
                  {/*
                  <Box display={{ _: 'none', md: 'block' }}>
                    <Heading3 textAlign='center' my='1rem'>{this.props.template.info.introMainQuestionText}</Heading3>
                    <Paragraph textAlign='center' color='rgba(255, 255, 255, 0.8)' mb='2rem'>
                      {this.props.template.info.introMainQuestionDescription}
                    </Paragraph>
                  </Box>
                  */}

                  <DropTargetBox>
                    <Selected
                      round={round}
                      size={round.type === 'final' ? size.selectedFinal : size.selected}
                      selectValue={this.selectValue}
                      resetValue={this.resetValue}
                      selected={this.state.selected}
                      requiredValuesCount={this.props.round.requiredValuesCount}
                    />
                  </DropTargetBox>

                  <StyledRoundProgress>
                    {remains ? (
                      <span className='pick-more'>
                        <FormattedMessage id='test.remains' values={{ count: remains }} />
                      </span>
                    ) : (
                      <PrimaryButton onClick={this.submit}>
                        <FormattedMessage id='test.next' />
                      </PrimaryButton>
                    )}
                  </StyledRoundProgress>
                </div>
              </Fade>
            </Col>
            <Col xs={24} md={14}>
              <DragLayer wide={round.type !== 'final'} />
            </Col>
          </Row>
        </StyledTestScreen>
      </ThemeProvider>
    );
  }

  private selectValue = (id: string, index?: number) =>
    this.setState({
      selected: this.state.selected.map((v, i) =>
        i === // Does current index equals to target index?
        (index !== undefined // Does target index exists (double click / drag n drop)
          ? index
          : this.state.selected.findIndex(x => x === null)) // Find target index
          ? id
          : v === id // Does target id exists elsewhere?
            ? null
            : v,
      ),
    });

  private resetValue = (id: string) =>
    this.setState({
      selected: this.state.selected.map(x => x === id ? null : x),
    });

  private remains = () => this.state.selected.filter(s => s === null).length;

  private submit = () =>
    this.props.submit(addRecordData(this.props.round, getData(this.props.round, this.state.selected)));
}

export default withRouter(
  withLifeValues<IRoundStep & RouteComponentProps<{}>>(wrapIntl(DragDropContext(HTML5Backend)(RoundStep))),
);
