import * as React from 'react';
import { StyledUnloggedUserHeader, OldBurger } from './styles';
import Logo from '../../../atoms/Logo';
import Nav from '../../../atoms/Nav';
import { MenuLogin } from '../MenuUser/login';
import NavItem from '../../../atoms/Nav/NavItem';
import { createUrl } from '../../../pages/routes';
import { WithUser } from '../../../molecules/withRedux/withUser';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { VALUE_COMPARISON_ACTIVE } from 'config/config';
import { isAuthed, IUserState } from 'store/User/index';

interface ILegacyMenuState {
  activeMenu: boolean;
  activeSwitcher: boolean;
}

class LegacyMenu extends React.Component<{}, ILegacyMenuState> {
  public state: ILegacyMenuState = { activeMenu: false, activeSwitcher: false };

  public render() {
    const { activeMenu } = this.state;
    return (
      <StyledUnloggedUserHeader className={activeMenu ? 'opened' : ''}>
        <div className={'content'}>
          <div className={'left-align'}>
            <Logo app={false} />
            <OldBurger onClick={() => this.toggleMenu(!activeMenu)} isOpen={activeMenu}>
              <span />
              <span />
              <span />
            </OldBurger>
          </div>

          <div className={'drop'} onClick={() => this.toggleMenu(false)}>
            <Nav>
              <NavItem to={createUrl('')} label='legacy.menu.homepage' icon='game' exact />
              {/*
              {VALUE_COMPARISON_ACTIVE &&
                <NavItem to={createUrl('valuecomparisonlanding')} label='legacy.menu.valuesForTwo' exact />
              }
              */}
              <NavItem to={createUrl('about')} label='legacy.menu.about' icon='journal' exact />
              {/* <NavItem
                  to={createUrl("about.join")}
                  label="legacy.menu.join"
                  icon="comparison"
                  exact
                /> */}
            </Nav>

            <div className={'right-align'}>
              <WithUser readable={false}>
                {(_, res) =>
                  !isAuthed(res.user as IUserState) ?
                    <MenuLogin />
                    : (
                      <Link className='nav-link' to={createUrl('app')}>
                        <FormattedMessage id={'legacy.menu.backToApp'} />
                      </Link>
                    )
                }
              </WithUser>

              {/*
                  <div
                    className={"lang-switch" + (activeSwitcher ? " visible" : "")}
                  >
                  <span className="now" onClick={() => this.toggleSwitcher()}>
                    {getParams().locale.toUpperCase()}
                    <SVGDrop/>
                  </span>

                    {canUseDOM && (
                      <ul>
                        {["cs", "en"].map(
                          l =>
                            l !== getParams().locale && (
                              <li key={l}>
                                <a
                                  href={location.pathname.replace(
                                    /^\/[a-z1-9]+/,
                                    `/${l}`
                                  )}
                                >
                                  {l.toUpperCase()}
                                </a>
                              </li>
                            )
                        )}
                      </ul>
                    )}
                  </div>
                */}
            </div>
          </div>
        </div>
      </StyledUnloggedUserHeader>
    );
  }

  private toggleMenu = (state: boolean) => this.setState({ activeMenu: state });
  private toggleSwitcher = () => this.setState({ activeSwitcher: !this.state.activeSwitcher });
}

export default LegacyMenu;
