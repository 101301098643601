import * as React from 'react';
import { StyledQuestObjective } from './styles';
import { FormattedMessage } from 'react-intl';
import ExternalSVG from '../../atoms/ExternalSVG';

interface IQuestObjective {
  title: string;
  text?: string;
  icon: string;
  color: string;
  status?: 'done' | 'disabled' | 'todo';
  icoBackground?: string;
}

export const QuestObjective: React.FC<IQuestObjective> = ({
  title,
  text,
  icon,
  color,
  status = 'todo',
  icoBackground,
}) => {
  return (
    <StyledQuestObjective className={color} done={status} icoBackground={icoBackground}>
      <div className='ico-wrap'>
        {status === 'done' ? (
          <>
            <img src='/img/ico/check-curved.svg' alt='' />
          </>
        ) : (
          <ExternalSVG src={icon} />
        )}
      </div>
      <div className='text-wrap'>
        <span className='main-text'>{title}</span>
        <br />
        {status === 'done' ? (
          <span className='desc'>
            <FormattedMessage id={'questlog.done'} />
          </span>
        ) : (
          text && <span className='desc'>{text}</span>
        )}
      </div>
    </StyledQuestObjective>
  );
};

export default QuestObjective;
