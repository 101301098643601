import * as React from 'react';

const SVGgift: React.FC = () => {
  return (
    <svg width='22px' height='22px' viewBox='0 0 22 22' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <g id='MVP-FInal' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='Hodnotové-porovnání-–-Landing-page'
          transform='translate(-700.000000, -508.000000)'
          fill='#F5A623'
          fillRule='nonzero'
        >
          <g id='CONTENT' transform='translate(-320.000000, 0.000000)'>
            <g id='vizual'>
              <g id='pay-box' transform='translate(635.000000, 489.000000)'>
                <g id='c2a-voucher' transform='translate(335.000000, 0.000000)'>
                  <path
                    d='M68.6631844,24 L71,24 C71.5522847,24 72,24.4477153 72,25 L72,30 C72,30.5522847 71.5522847,31 71,31 L70,31 L70,40 C70,40.5522847 69.5522847,41 69,41 L53,41 C52.4477153,41 52,40.5522847 52,40 L52,31 L51,31 C50.4477153,31 50,30.5522847 50,30 L50,25 C50,24.4477153 50.4477153,24 51,24 L53.3368156,24 C53.1208455,23.5453723 53,23.0367986 53,22.5 C53,20.5670034 54.5670034,19 56.5,19 C58.4608557,19 59.9214191,20.1684508 60.9387159,22.0180813 C60.9594128,22.0557119 60.97984,22.0934783 61,22.1313719 C61.02016,22.0934783 61.0405872,22.0557119 61.0612841,22.0180813 C62.0785809,20.1684508 63.5391443,19 65.5,19 C67.4329966,19 69,20.5670034 69,22.5 C69,23.0367986 68.8791545,23.5453723 68.6631844,24 Z M65.5,24 C66.3284271,24 67,23.3284271 67,22.5 C67,21.6715729 66.3284271,21 65.5,21 C64.3983557,21 63.5151691,21.7065492 62.8137159,22.9819187 C62.6352613,23.3063817 62.4794169,23.6499234 62.3457449,24 L65.5,24 Z M65.5,26 L62,26 L62,29 L70,29 L70,26 L65.5,26 Z M56.5,26 L52,26 L52,29 L60,29 L60,26 L56.5,26 Z M56.5,24 L59.654257,24 C59.5205831,23.6499234 59.3647387,23.3063817 59.1862841,22.9819187 C58.4848309,21.7065492 57.6016443,21 56.5,21 C55.6715729,21 55,21.6715729 55,22.5 C55,23.3284271 55.6715729,24 56.5,24 Z M62,39 L68,39 L68,31 L62,31 L62,39 Z M60,39 L60,31 L54,31 L54,39 L60,39 Z'
                    id='ico/gift'
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SVGgift;
