import { Link, useModal } from '@xcorejs/ui';
import KidsModal from 'components/components/modals/KidsModal';
import * as React from 'react';
import { FC } from 'react';

interface KidsSwitchProps {
  onAgreement: () => unknown;
} 

const KidsSwitch: FC<KidsSwitchProps> = ({ onAgreement }) => {
  const [openKidsModal] = useModal(KidsModal);

  return (
    <Link id='kidsSwitch' onClick={() => openKidsModal({ onAgreement: onAgreement })} color='#d9dfb4'>zde</Link>
  );
};

export default KidsSwitch;
