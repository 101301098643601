import { fetchLifeValues } from '../../../store/Entities/LifeValues/LiveValuesActions';
import { IStringMap } from '../../../utils/index';
import { ILifeValue } from '../../../store/Entities/LifeValues/index';
import { IRescource, getData } from '../../../utils/rescource';
import withRedux from '.';
import { mergeRes } from './index';

export interface IWithLifeValues {
  lifeValues: IStringMap<ILifeValue>;
  rescource: { lifeValues: IRescource<IStringMap<ILifeValue>> };
}

export default withRedux(
  state => state.entities.lifeValues,
  dispatch => () => dispatch(fetchLifeValues()),
  (props): IWithLifeValues => ({
    lifeValues: getData(props.rescource),
    rescource: { ...mergeRes(props), lifeValues: props.rescource },
  }),
);
