// import { IFetchException } from 'utils/exceptions';
// import { jsonFetch } from 'utils/fetch';
import { Box, Container, Flex, Grid, Heading2, Heading3, Icon, Img, Link as UILink, Typography, useModal } from '@xcorejs/ui';
import { GameContext } from 'components/App';
import LoadingSpinner from 'components/atoms/Animations/LoadingSpinner';
import { useInfoModalTimeout } from 'components/atoms/hooks/useInfoModalTimeout';
import { StyledHelpLink } from 'components/atoms/Popups/styles';
import { PrimaryButton } from 'components/components/Button';
import Carousel from 'components/components/Carousel';
import EticiaInfoModal from 'components/components/modals/EticiaInfoModal';
import GameGuideModal from 'components/components/modals/GameGuideModal';
import withLifeValues, { IWithLifeValues } from 'components/molecules/withRedux/withLifeValues';
import InfoMessage from 'components/organisms/InfoMessage';
import { gameLevels } from 'components/pages/App/ValueCenter/Game/data';
import { createUrl } from 'components/pages/routes';
import { peaGreen } from 'components/theme/colors';
import * as React from 'react';
import { FC, useContext, useEffect, useState } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';
import GameIcon from '../../../../components/icons/32/game.svg';
import JournalIcon from '../../../../components/icons/32/journal.svg';
import PlusIcon from '../../../../components/icons/32/plus.svg';
import ShopIcon from '../../../../components/icons/32/shop.svg';
import StatsIcon from '../../../../components/icons/32/stats.svg';
import ValuesIcon from '../../../../components/icons/32/values.svg';
import MyValues from './components/MyValues';
import { BoxPulse } from './styles';
const CompareMatrix = React.lazy(() => import('../../../../components/CompareMatrix/CompareMatrix'));

const Center:FC<IWithLifeValues> = ({ lifeValues }) => {
  const [openGameGuideModal] = useModal(GameGuideModal);
  const [openEticiaInfo] = useModal(EticiaInfoModal);
  const [close] = useModal();
  const { eticiaPlus, userLvl } = useContext(GameContext);
  const [randomState, setRandomState] = useState<number | undefined>();
  useInfoModalTimeout(openEticiaInfo, close);

  useEffect(() => {
    setRandomState(Math.random());
  }, []);

  return (
    <>
      <InfoMessage>
        <FormattedMessage id='infoMessage' />
      </InfoMessage>

      <BoxPulse
        position='fixed'
        right={{ _: '1rem', md:'3rem' }}
        bottom={{ _: '1rem', md:'3rem' }}
        zIndex={2}
        animate={localStorage.getItem('infoModalGlow') == 'true'}
      >
        <StyledHelpLink
          className='help-link'
          variant='normal'
          size={3}
          mdSize={5}
          onClick={() => {
            openEticiaInfo({ onClose: close });
            localStorage.removeItem('infoModalGlow');
          }} />
      </BoxPulse>

      {/* mé hodnoty */}
      <MyValues lifeValues={lifeValues} randomState={randomState} />

      {/* výprava do světa hodnot */}
      <Box
        bg='rgba(11, 12, 28, 0.3)'
        pt={{ _: '6rem', sm: '8rem' }}
        pb={{ _: '6rem', sm: '8rem' }}
        position='relative'
      >
        <Fade bottom ssrReveal duration={1000} distance='5rem'>
          <Container justifyContent='center' position='relative'>
            <Flex flexDirection='column' alignItems='center' width='100%'>
              <Heading2 px={{ _: '2rem', sm: 0 }} color='#e7e6cf'>
                <FormattedMessage id='valueCenter.game.heading' />
              </Heading2>

              <Grid
                columns={{ _: 'auto', sm: 'auto auto' }}
                rows='auto'
                gap={{ _: '1.6rem', sm: '8.5rem' }}
                width='100%'
                maxWidth='79.5rem'
                alignItems='center'
                justifyItems='center'
                mt='4rem'
              >
                <Flex
                  alignItems='center'
                  flexDirection='column'
                >
                  <Link to={createUrl('app') + '/hra'}>
                    <Box position='relative'>
                      <Img src={`/img/game/images/${gameLevels[userLvl].image}`} display='block' alt='' />

                      <Typography
                        width='100%'
                        justifyContent='center'
                        position='absolute'
                        left='50%'
                        transform='translateX(-50%)'
                        top='20rem'
                        textAlign='center'
                        maxWidth='18rem'
                        color='#fff'
                      >
                        <strong><FormattedHTMLMessage id={gameLevels[userLvl].name} /></strong><br /> 
                        Level {userLvl}
                      </Typography>
                    </Box>
                  </Link>
                </Flex>

                <Flex alignItems='center' flexDirection='column' width='100%' maxWidth='41rem'>
                  <Heading3 color='yellowWhite'>
                    <FormattedMessage id='valueCenter.game.progress' />
                  </Heading3>

                  <Typography mt='1rem' color={{ _: 'rgba(255, 255, 255, 0.7)', sm: '#fff' } as any}>
                    <FormattedMessage id='valueCenter.game.text' />
                  </Typography>

                  <Link to={createUrl('app') + '/hra'}>
                    <PrimaryButton
                      mt='3rem'
                      background={peaGreen}
                      color='rgba(255, 255, 255, 1)'
                      _hover={{
                        color: 'rgba(0, 0, 0, 0.8)'
                      }}
                    >
                      {gameLevels[userLvl].buttonText ? 
                        <FormattedHTMLMessage id={gameLevels[userLvl].buttonText as string} /> : 
                        <FormattedMessage id='valueCenter.game.continue' />
                      }
                    </PrimaryButton>
                  </Link>

                  <UILink
                    v='underline'
                    color='rgba(244, 244, 246, 1)'
                    borderColor='rgba(244, 244, 246, 0.8)'
                    mt='2rem'
                    onClick={() => openGameGuideModal({ pageId: 56 })}
                  >
                    <FormattedMessage id='valueCenter.game.guide' />
                  </UILink>
                </Flex>
              </Grid>
            </Flex>
          </Container>
        </Fade>
      </Box>

      {/* hodnotové žebříčky */}
      <Box
        pt={{ _: '6rem', sm: '8rem' }}
        pb={{ _: '6rem', sm: '8rem' }}
      >
        <Fade bottom ssrReveal duration={1000} distance='5rem'>
          <Container justifyContent='center'>
            <Flex flexDirection='column' alignItems='center' width='100%'>
              <Heading2 px={{ _: '2rem', sm: 0 }} color='#e7e6cf'>
                <FormattedMessage id={'journal.ladders.heading'} />
              </Heading2>

              <Box mt={{ _: '2rem', sm: '2rem' }} width='100%'>
                <Carousel />
              </Box>
            </Flex>
          </Container>
        </Fade>
      </Box>

      {/* porovnání tvých hodnotových žebříčků */}
      <Box
        py={{ _: '6rem', sm: '8rem' }}
        bg={{ _: 'rgba(11, 12, 28, 0.15)' }}
        position='relative'
        zIndex={1}
      >
        <Fade bottom ssrReveal duration={1000} distance='5rem'>
          <React.Suspense fallback={<LoadingSpinner />}>
            <CompareMatrix />
          </React.Suspense>
        </Fade>
      </Box>

      {/* objevuj více s eticia */}
      <Box
        bg='rgba(11, 12, 28, 0.3)'
        pt={{ _: '6rem', sm: '8rem' }}
        pb={{ _: '6rem', sm: '8rem' }}
        position='relative'
      >
        <Heading2>
          <FormattedMessage id='valueCenter.otherContent' />
        </Heading2>

        <Flex flexWrap='wrap' justifyContent='center' mt='4rem' {...{ style: { gap: '3rem' } }}>
          <Flex width='8rem' justifyContent='center'>
            <Link to={createUrl('app.journal')}>
              <UILink
                v='underlined'
                flexDirection='column'
                alignItems='center'
                opacity={0.6}
                _hover={{
                  opacity: 1,
                }}
                transition='opacity 300ms'
              >
                <Icon
                  svg={<JournalIcon />}
                  fill='#fff'
                  mb='1rem'
                />

                <Typography color='#f4f4f6'>
                  <FormattedHTMLMessage id='footer.diary' />
                </Typography>
              </UILink>
            </Link>
          </Flex>

          <Flex width='8rem' justifyContent='center'>
            <Link to={createUrl('app.valueWord')}>
              <UILink
                v='underlined'
                flexDirection='column'
                alignItems='center'
                opacity={0.6}
                _hover={{
                  opacity: 1,
                }}
                transition='opacity 300ms'
              >
                <Icon
                  svg={<ValuesIcon />}
                  fill='#fff'
                  mb='1rem'
                />

                <Typography color='#f4f4f6'>
                  <FormattedHTMLMessage id='footer.valueWord' />
                </Typography>
              </UILink>
            </Link>
          </Flex>

          <Flex width='8rem' justifyContent='center'>
            <Link to={createUrl('app') + '/hra'}>
              <UILink
                v='underlined'
                flexDirection='column'
                alignItems='center'
                opacity={0.6}
                _hover={{
                  opacity: 1,
                }}
                transition='opacity 300ms'
              >
                <Icon
                  svg={<GameIcon />}
                  fill='#fff'
                  mb='1rem'
                />

                <Typography color='#f4f4f6'>
                  <FormattedHTMLMessage id='footer.game2' />
                </Typography>
              </UILink>
            </Link>
          </Flex>

          <Flex width='8rem' justifyContent='center'>
            <Link to={createUrl('app.stats')}>
              <UILink
                v='underlined'
                flexDirection='column'
                alignItems='center'
                opacity={0.6}
                _hover={{
                  opacity: 1,
                }}
                transition='opacity 300ms'
              >
                <Icon
                  svg={<StatsIcon />}
                  fill='#fff'
                  mb='1rem'
                />

                <Typography color='#f4f4f6'>
                  <FormattedHTMLMessage id='footer.statistics' />
                </Typography>
              </UILink>
            </Link>
          </Flex>

          <Flex width='8rem' justifyContent='center'>
            {/* eslint-disable-next-line no-extra-parens */}
            <Link to={createUrl('app.eticiaPlus')}>
              <UILink
                v='underlined'
                flexDirection='column'
                alignItems='center'
                opacity={0.6}
                _hover={{
                  opacity: 1,
                }}
                transition='opacity 300ms'
              >
                <Icon
                  svg={<PlusIcon />}
                  fill='#fff'
                  mb='1rem'
                />

                <Typography color='#f4f4f6'>
                  <FormattedHTMLMessage id='footer.plus' />
                </Typography>
              </UILink>
            </Link>
          </Flex>

          <Flex width='8rem' justifyContent='center'>
            <Link to={createUrl('app.shop')}>
              <UILink
                v='underlined'
                flexDirection='column'
                alignItems='center'
                opacity={0.6}
                _hover={{
                  opacity: 1,
                }}
                transition='opacity 300ms'
              >
                <Icon
                  svg={<ShopIcon />}
                  fill='#fff'
                  mb='1rem'
                />

                <Typography color='#f4f4f6'>
                  <FormattedHTMLMessage id='footer.shop' />
                </Typography>
              </UILink>
            </Link>
          </Flex>
        </Flex>

        <Flex flexWrap='wrap' justifyContent='center' mt='8rem' {...{ style: { gap: '2.4rem' } }}>
          <Link to={createUrl('app.about')}>
            <UILink
              v='underline'
              flexDirection='column'
              alignItems='center'
              color='#f4f4f6'
              opacity={0.5}
              _hover={{
                opacity: 1,
              }}
              transition='opacity 300ms'
            >
              <FormattedHTMLMessage id='footer.about' />
            </UILink>
          </Link>

          <Link to={createUrl('app.faq')}>
            <UILink
              v='underline'
              flexDirection='column'
              alignItems='center'
              color='#f4f4f6'
              opacity={0.5}
              _hover={{
                opacity: 1,
              }}
              transition='opacity 300ms'
            >
              FAQ
            </UILink>
          </Link>
          {/*
          <UILink
            v='underline'
            flexDirection='column'
            alignItems='center'
            color='#f4f4f6'
            href='https://www.instagram.com/eticia21/'
            target='_blank'
            opacity={0.5}
            _hover={{
              opacity: 1,
            }}
            transition='opacity 300ms'
          >
            Instagram
          </UILink>
          <UILink
            v='underline'
            flexDirection='column'
            alignItems='center'
            color='#f4f4f6'
            href='https://www.facebook.com/eticia21/'
            target='_blank'
            opacity={0.5}
            _hover={{
              opacity: 1,
            }}
            transition='opacity 300ms'
          >
            Facebook
          </UILink>
          */}
          <Link to={createUrl('about')}>
            <UILink
              v='underline'
              flexDirection='column'
              alignItems='center'
              color='#f4f4f6'
              opacity={0.5}
              _hover={{
                opacity: 1,
              }}
              transition='opacity 300ms'
            >
              <FormattedHTMLMessage id='footer.contact' />
            </UILink>
          </Link>
        </Flex>

        <Typography mt='2rem'>
          &copy; 2017 - {new Date(Date.now()).getFullYear()} Eticia 21
        </Typography>
      </Box>
    </>
  );
};

export default withLifeValues(Center);
