import { IValueResult } from 'config/api/valueTestResult';
import React, { FC } from 'react';
import { IStringMap } from 'utils';

import { ValueMapTheme } from '..';
import { IEticon } from '../../../../store/Entities/Eticons';
import { IProfile } from '../../../pages/App/Result/PartnerTestResult/data';
import ValueMapValues from './ValueMapValues';

interface Props {
  theme: ValueMapTheme;
  eticon?: IEticon;
  lifeValues: IValueResult[];
  profiles?: IStringMap<IProfile>;
}

const ValueMapFull: FC<Props> = ({ lifeValues, theme, eticon, profiles }) => (
  <>
    {(theme === 'normal' || theme === 'afterYear') && eticon && (
      <>
        <img src={eticon.icon} alt={eticon.name} className='eticon' />
        <h1>{eticon.name}</h1>
      </>
    )}
    <ValueMapValues allLifeValues={lifeValues} profiles={profiles} theme={theme} />
  </>
);

export default ValueMapFull;
