import * as React from 'react';
import Fade from 'react-reveal/Fade';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import TextBox from '../../TextBox';
import { Heading2 } from '../../../atoms/Headings';
import { BiggerText } from '../../../atoms/Text';
import { IEticon } from '../../../../store/Entities/Eticons';
import { denormalize } from '../../../../utils/helpers';
import SliderValues from '../ValuePage/Slider/StyledSliderValues';
import SliderTypes from '../ValuePage/Slider/StyledSliderTypes';
import LoadingSpinnerLocal from '../../../atoms/Animations/LoadingSpinnerLocal';
import ValueDefinition from '../../ValueDefinition';
import withEticons, { IWithEticons } from '../../../molecules/withRedux/withEticons';
import { IValueTestResultDetail } from 'config/api/valueTestResult';

interface IValuePageMerged {
  result: IValueTestResultDetail | null;
  eticonOnly?: boolean;
}

interface IValuePageMergedState {
  detail: string | null;
}

class ValuePageMerged extends React.Component<IValuePageMerged & IWithEticons, IValuePageMergedState> {
  public state: IValuePageMergedState = { detail: null };

  public render() {
    const { eticons, result, eticonOnly } = this.props;

    return (
      <>
        <div className={'bg-blue'}>
          <TextBox className='blue'>
            <Fade ssrReveal bottom duration={1000} distance={'5rem'}>
              {eticonOnly ? (
                <>
                  <Heading2>
                    <FormattedMessage id={'valuepagemerged.eticonsOnly.heading'} />
                  </Heading2>
                  <BiggerText>
                    <FormattedHTMLMessage id={'valuepagemerged.eticonsOnly.text'} />
                  </BiggerText>
                </>
              ) : (
                <>
                  <Heading2>
                    <FormattedMessage id={'valuepagemerged.heading'} />
                  </Heading2>
                  <BiggerText>
                    <FormattedHTMLMessage id={'valuepagemerged.text'} />
                  </BiggerText>
                </>
              )}
            </Fade>
          </TextBox>

          {eticons !== null ? (
            <SliderTypes values={denormalize<IEticon>(eticons)} tribal={result} />
          ) : (
            <LoadingSpinnerLocal />
          )}

          {!eticonOnly && (
            <SliderValues setDetail={(detail: string | null) => this.setState({ detail })} />
          )}
        </div>
        {!eticonOnly && this.state.detail && (
          <ValueDefinition value={this.state.detail} inTest={false} onClose={() => this.setState({ detail: null })} />
        )}
      </>
    );
  }
}

export default withEticons(ValuePageMerged);
