import * as React from 'react';
import { Row, Col, Grid } from 'react-styled-flexboxgrid';
import { ILifeValueDetail } from '../../../../store/Entities/LifeValues';
import QuoteCard from '../Cards/QuoteCard';
import AuthorCard from '../Cards/AuthorCard';
import TriviaCard from '../Cards/TriviaCard';
import CardWrapper from '../CardWrapper';
import { getQuotes, getAuthors, getTrivia } from '..';
import CustomFade from '../../../atoms/Animations/CustomFade';
import { TransitionGroup } from 'react-transition-group';

interface IAll {
  inTest: boolean;
  detail: ILifeValueDetail;
  onVote: (id: string, type: string, text?: string) => any;
}

const All: React.FC<IAll> = ({ detail, inTest, onVote }) => (
  <Grid>
    <Row>
      <Col xs={inTest ? 24 : 12} sm={inTest ? 24 : 12} md={inTest ? 8 : 4}>
        <TransitionGroup>
          <CustomFade key={getQuotes(detail)[0] ? getQuotes(detail)[0].id : 'c2a'}>
            <CardWrapper label={'Citát'} item={getQuotes(detail)[0]} color='green'>
              <QuoteCard
                quote={getQuotes(detail)[0]}
                valueId={detail.id}
                onVote={(id: string, type: string, text?: string) => onVote(id, type, text)}
              />
            </CardWrapper>
          </CustomFade>
        </TransitionGroup>
      </Col>
      <Col xs={inTest ? 24 : 12} sm={inTest ? 12 : 6} md={inTest ? 8 : 4}>
        <TransitionGroup>
          <CustomFade key={getAuthors(detail)[0] ? getAuthors(detail)[0].id : 'c2a'}>
            <CardWrapper label={'Osobnost'} item={getAuthors(detail)[0]} color='purple'>
              <AuthorCard
                author={getAuthors(detail)[0]}
                valueId={detail.id}
                onVote={(id: string, type: string, text?: string) => onVote(id, type, text)}
              />
            </CardWrapper>
          </CustomFade>
        </TransitionGroup>
      </Col>
      <Col xs={inTest ? 24 : 12} sm={inTest ? 12 : 6} md={inTest ? 8 : 4}>
        <TransitionGroup>
          <CustomFade key={getTrivia(detail)[0] ? getTrivia(detail)[0].id : 'c2a'}>
            <CardWrapper label={'Zajímavost'} item={getTrivia(detail)[0]} color='blue'>
              <TriviaCard
                trivia={getTrivia(detail)[0]}
                valueId={detail.id}
                onVote={(id: string, type: string, text?: string) => onVote(id, type, text)}
              />
            </CardWrapper>
          </CustomFade>
        </TransitionGroup>
      </Col>
    </Row>
  </Grid>
);

export default All;
