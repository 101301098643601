import React, { FC } from 'react';
import DelayedAccordion from 'components/atoms/Accordion/DelayedAccordion';
import { PlusTheme } from 'components/theme/colors';
import { Link, Paragraph } from '@xcorejs/ui';
import { useHistory } from 'react-router-dom';
import { createUrl } from 'components/pages/routes';

const EticiaPlusBanner: FC = () => {
  const history = useHistory();

  return (
    <DelayedAccordion
      delay={2000}
      wrapperProps={{
        width: { _: 'calc(100% + 3.2rem)', sm: 'calc(100% + 8rem)' },
        position: 'relative',
        top: '-4rem',
        left: { _: '-1.6rem', sm: '-4rem' },
        backgroundColor: PlusTheme.primary,
      }}
      justifyContent='center'
      alignItems='center'        
    >
      <Paragraph color={PlusTheme.text} paddingY='3rem'>
          Prozatím nemůžeš porovnávat své žebříčky.<br />
          Pořiď si <Link
          v='underline'
          onClick={() => {
            const modalWindow = document.querySelector('reach-portal');
            modalWindow && modalWindow.remove();
            history.push(createUrl('app.eticiaPlus'));
          }}>Eticia Plus</Link>
      </Paragraph>
    </DelayedAccordion>
  );
};
 
export default EticiaPlusBanner;
