import {
  Box, Button, Card,
  Flex,
  Heading2,
  Heading3,
  Heading4,
  Img, Link,
  Modal, Paragraph,
  Tag,
  Typography,
  useModal
} from '@xcorejs/ui';
import { GameContext } from 'components/App';
import LoadingSpinnerLocal from 'components/atoms/Animations/LoadingSpinnerLocal';
import Fetch from 'components/atoms/Fetch';
import { PlusTag } from 'components/atoms/Logo';
import { PremiumButton } from 'components/components/Button';
import Block1Match from 'components/components/modals/LaddersCompareTestModal/Block1Match';
import Block2Graphs from 'components/components/modals/LaddersCompareTestModal/Block2Graphs';
import Block3Values from 'components/components/modals/LaddersCompareTestModal/Block3Values';
import ValuesGraph from 'components/molecules/Results/Graph/ValuesGraph';
import ValueMap from 'components/molecules/ValueMap';
import ValueMapFull from 'components/molecules/ValueMap/types/ValueMapFull';
import { GraphLegend } from 'components/organisms/PartnerTest/Result/graphLegend';
import { BlueTheme, LeastStatisticsTheme, MiddleStatisticsTheme, PlusTheme } from 'components/theme/colors';
import { IVirtualTestDetail, VIRTUAL_VALUE_TEST_DETAIL } from 'config/api/virtualValueTestDetail';
import { FC, useContext, useState } from 'react';
import * as React from 'react';
import { _switch } from 'utils/option';
import { Link as ReactLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { OliLight } from 'components/theme/fonts';

interface LifeValuesModalProps {
  customVisible?: boolean;
}

const LifeValuesModal: FC<LifeValuesModalProps> = ({ customVisible }) => {
  const [close] = useModal();
  const [allValues3Visible, setAllValues3Visible] = useState(false);
  const { eticiaPlus } = useContext(GameContext);
  const visible = eticiaPlus || customVisible;


  return (
    <Modal
      background={BlueTheme.primary}
      maxWidth={{ _: '37.5rem', sm: '63rem' }}
      p={{ _: '3.6rem 0', sm: '3.6rem 0' }}
      overflowX='hidden'
    >
      <Fetch url={VIRTUAL_VALUE_TEST_DETAIL}>
        {_switch({
          success: (result: IVirtualTestDetail) => (
            <Box>
              <Box px='1.6rem'>
                <Img src='/img/80x80_hodnoty.png' display='block' mx='auto' alt='' />
                
                <Heading2 mt='1rem'>
                  <FormattedMessage id='journal.lifeValuesModal.headingMain' />
                  <br />
                  {result.virtualResult.yearFrom} – {result.virtualResult.yearTo}
                </Heading2>

                <Heading4 mt='4rem' color='#c0f3d6'>
                  <FormattedMessage id='journal.lifeValuesModal.headingImportantText' />
                </Heading4>

                <Typography mt='1rem' mx='auto' maxWidth='90%'>
                  <FormattedMessage id='journal.lifeValuesModal.importantText' />
                </Typography>

                <Box position='relative' mt='4rem' mb='6rem'>
                  <ValueMap theme='afterYear' imagePath=''>
                    <Box>
                      <Img
                        src='/img/ziv_cloud.png'
                        width='6rem'
                        height='6rem'
                        mx='auto'
                        alt=''
                      />

                      <Typography mt='2rem'>
                        <Paragraph as={'span' as any} color='#f9f5dc'>
                          <FormattedMessage id='journal.lifeValuesModal.afterYear.heading' />
                        </Paragraph>
                        <br />
                        {result.virtualResult.yearFrom} – {result.virtualResult.yearTo}
                      </Typography>

                      <ValueMapFull
                        theme='normal'
                        lifeValues={result.virtualResult.lifeValues}
                      />
                    </Box>
                  </ValueMap>

                  <Flex flexDirection='column' alignItems='center' {...{ style: { gap: '0.5rem' } }} mt='5rem'>
                    {allValues3Visible && result
                      .virtualResult.lifeValues.slice(0, 42).map((value, i) => (
                        <Card
                          key={value.valueId}
                          px='1.6rem'
                          py='0.8rem'
                          minHeight='4.1rem'
                          position='relative'
                          // background={i < 10 ? 
                          //   `linear-gradient(180deg, ${PlusTheme.primary} 0%, ${PlusTheme.darken} 100%)` : 
                          //   i >= 30 ? 
                          //     `linear-gradient(180deg, ${LeastStatisticsTheme.primary} 0%, ${LeastStatisticsTheme.darken} 100%)` : 
                          //     `linear-gradient(180deg, ${MiddleStatisticsTheme.primary} 0%, ${MiddleStatisticsTheme.darken} 100%)`}
                          background={i < 10 ? 
                            'rgb(143, 58, 88)' : 
                            i >= 30 ? 
                              'rgb(179, 94, 122)' :
                              'rgb(166, 66, 99)'}
                          borderRadius='0.8rem'
                          marginTop={i > 0 && i < 40 && i % 10 == 0 ? '1.5rem' : '0'}
                          _body={{
                            padding: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                          maxWidth='385px'
                        >
                          <Typography
                            width='1.4rem'
                            marginLeft='1rem' 
                            color='rgba(255, 255, 255, 0.5)'
                            position='absolute'
                            left={0}
                          >
                            {i+1}.
                          </Typography>

                          <Typography color={i < 10 ? '#fff' : 'rgba(255, 255, 255, 0.7)'}>
                            {value.name}
                          </Typography>
                        </Card>
                      ))}

                    {!allValues3Visible  && (
                      <Flex justifyContent='center' mt='1rem'>
                        {visible ? (
                          <Link v='underline' as='span' onClick={() => setAllValues3Visible(true)}>Celý žebříček</Link>
                        ) : (
                          // eslint-disable-next-line no-extra-parens
                          <ReactLink to={`/${(global as any).requestContext.locale}/app/presenting?id=12`} onClick={close}>
                            <Flex flexDirection='column' alignItems='center'>
                              <Link v='underline' as='span'>Celý žebříček</Link>
                              <PlusTag mt='1rem' />
                            </Flex>
                          </ReactLink>
                        )}
                      </Flex>
                    )}
                    {/* ? bude možnost i žebříček zase schovat? */}
                  </Flex>
                </Box>
              </Box>

              <Box bg='rgba(11, 12, 28, 0.15)' px='1.6rem' pt='6rem'>
                <Heading2>
                  <FormattedMessage id='journal.lifeValuesModal.valueChange.heading' />
                </Heading2>

                <Typography mt='1rem'>
                  <FormattedMessage id='journal.lifeValuesModal.valueChange.info' />                  
                </Typography>

                <Box mt='4rem'>
                  <Tag
                    mt='2rem'
                    display='block'
                    mx='auto'
                    bg='primaryGreen'
                    border='2px solid'
                    borderColor='primaryGreen'
                    py='0.4rem'
                    width='24rem'
                    justifyContent='center'
                    whiteSpace='nowrap'
                    overflow='hidden'
                    textOverflow='ellipsis'
                    textAlign='center'
                    borderRadius='0.5rem'
                    fontFamily={OliLight}
                    fontSize={{ _: '2rem' }}
                  >
                    {result.virtualResult.yearTo}
                  </Tag>

                  <Typography mt='1rem' textAlign='center'>
                    {new Date(result.currentValueTestResult.doneAt * 1000).toLocaleDateString()}
                  </Typography>
                </Box>

                <Box mt='2rem'>
                  <Tag
                    mt='2rem'
                    display='block'
                    mx='auto'
                    bg='#2B406B'
                    border='2px solid'
                    borderColor='#2B406B'
                    py='0.4rem'
                    width='24rem'
                    justifyContent='center'
                    whiteSpace='nowrap'
                    overflow='hidden'
                    textOverflow='ellipsis'
                    textAlign='center'
                    borderRadius='0.5rem'
                    fontFamily={OliLight}
                    fontSize={{ _: '2rem' }}
                  >
                    {result.virtualResult.yearFrom}
                  </Tag>

                  <Typography mt='1rem' textAlign='center' >
                    {new Date(result.oldValueTestResult.doneAt * 1000).toLocaleDateString()}
                  </Typography>
                </Box>

                <Block1Match valueMatch={result.valueMatch} />

                <Block2Graphs
                  visible={visible ?? false}
                  valueTestResultA={result.currentValueTestResult}
                  valueTestResultB={result.oldValueTestResult}
                  virtualResult={result.virtualResult}
                />

                <Block3Values
                  visible={visible ?? false}
                  valueTestResultA={result.currentValueTestResult}
                  valueTestResultB={result.oldValueTestResult}
                  virtualResult={result.virtualResult}
                />

                <Heading4 mt='4rem' color='#c0f3d6'>
                  <FormattedMessage id='journal.lifeValuesModal.valueChange.headingEnd' />
                </Heading4>

                <Typography mt='1rem'>
                  <FormattedMessage id='journal.lifeValuesModal.valueChange.infoEnd' />
                </Typography>

                <Flex justifyContent='center'>
                  <Button s='xs' mt='4rem' mb='6rem' onClick={close}>
                    <FormattedMessage id='journal.lifeValuesModal.close' />
                  </Button>
                </Flex>

              </Box>
            </Box>
          ),
          fetching: () => <LoadingSpinnerLocal />,
        })()}
      </Fetch>
    </Modal>
  );
};

export default LifeValuesModal;
