import * as React from 'react';
import { FakeCheckboxMark, StyledQuestionCheckbox } from './styles';
import { IFormItem } from '..';

interface IQuestionProps {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => any;
  value?: string | number;
  disabled?: boolean;
  checked?: boolean;
}

const Checkbox: React.FC<IFormItem & IQuestionProps> = ({ label, form, id, onChange, value, disabled, checked }) => (
  <StyledQuestionCheckbox>
    <input
      type='checkbox'
      name={form}
      value={value}
      id={id}
      onChange={onChange}
      disabled={disabled}
      checked={checked}
    />
    <label htmlFor={id}>
      <FakeCheckboxMark />
      {label}
    </label>
  </StyledQuestionCheckbox>
);

export default Checkbox;
