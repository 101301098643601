import styled from 'styled-components';
import { Icon } from '@xcorejs/ui';

interface IconBlinkProps {
  duration?: string;
  opacityStart?: number;
  opacityEnd?: number;
  scale?: number;
}

export const IconBlink = styled(Icon)<IconBlinkProps>`
  transform: ${({ scale }) => scale ? 'scale(' + scale + ')' : 'scale(1)'};
  animation: myAnim ${({ duration }) => duration || '2s'} ease infinite normal none;

  @keyframes myAnim {
    0%,
    50%,
    100% {
      opacity: ${({ opacityStart }) => opacityStart || 1};
    }
    25%,
    75% {
      opacity: ${({ opacityEnd })=> opacityEnd || 0};
    }
  }
`;