import * as React from 'react';
import { FC, useContext } from 'react';
import { BlueTheme, yellowLighter } from 'components/theme/colors';
import { Box, Flex, Icon, Paragraph, Typography, Grid, Img } from '@xcorejs/ui';
import { GameContext } from 'components/App';
import GameIcon from '../../../../components/icons/32/game.svg';
import { CSSProperties } from 'styled-components';
import { fillArray } from 'utils/fillArray';
import { FormattedMessage } from 'react-intl';

type Bonus = {
  level: number;
  icon: string;
  name: string;
};

type HintsAndBonuses = {
  hints: Bonus[];
  bonuses: Bonus[];
};

const hintsAndBonuses: HintsAndBonuses = {
  hints: [
    { level: 2, icon: 'images/podzimni_list.png', name: 'Podzimní list' },
    { level: 13, icon: 'images/nezrale_jablko.png', name: 'Nezralé jablko' },
    { level: 31, icon: 'images/vecny_ohen.png', name: 'Věčný oheň' },
    { level: 39, icon: 'images/cara_zivota.png', name: 'Čára života' },
    { level: 42, icon: 'images/voyager.png', name: 'Voyager' },
    { level: 47, icon: 'images/pomijivost.png', name: 'Pomíjivost' },
  ],
  bonuses: [
    { level: 3, icon: 'images/kanci_zub.png', name: 'Kančí zub' },
    { level: 7, icon: 'images/nejblizsi_hodnoty.png', name: 'Tvé nejbližší hodnoty' },
    { level: 8, icon: 'images/totem_zvire.png', name: 'Totemové zvíře' },
    { level: 16, icon: 'images/idealni_partak.png', name: 'Žebříček Ideální parťák' },
    { level: 18, icon: 'images/opar.png', name: 'Opar' },
    { level: 20, icon: 'images/souboj_pohlavi.png', name: 'Souboj pohlaví' },
    { level: 20, icon: 'images/zadani_nezadani.png', name: 'Zadaní vs. nezadaní' },
    { level: 22, icon: 'images/kouzelne_padlo.png', name: 'Kouzelné pádlo' },
    { level: 22, icon: 'images/stari_mladi.png', name: 'Staří vs. mladí' },
    { level: 29, icon: 'images/moudra_kniha.png', name: 'Moudrá kniha' },
    { level: 35, icon: 'images/idealni_partak.png', name: 'Žebříček Ideální parťák' },
    { level: 35, icon: 'images/trus_stesti.png', name: 'Trus štěstí' },
  ],
};

type InventoryProps = {
  open?: boolean;
  setOpen?: () => unknown;
  insideLevel?: boolean;
}

const Inventory: FC<InventoryProps> = ({ open, setOpen, insideLevel }) => {
  const { inventory, setInventory, userLvl } = useContext(GameContext);

  const ImageStyle = {
    maxWidth: '7.5rem',
    maxHeight: '7.5rem',
  };

  return (
    <Box
      position='absolute'
      width='100%'
      height={insideLevel ? '100%' : '100vh'}
      left='0'
      top='0'
      display={inventory ? 'block' : 'none'}
      background={BlueTheme.primary}
      zIndex={insideLevel ? 12000 : 1200}
    >
      <Flex
        alignItems='center'
        bg='rgba(11, 12, 28, 0.15)'
        height='4.6rem'
        width='100%'
      >
        <Box
          width='4.5rem'
          minWidth='4.5rem'
        />

        <Typography width='100%' fontSize='2rem' lineHeight='2.5rem'>
          <FormattedMessage id='game.inventory' />
        </Typography>

        <Flex
          alignItems='center'
          justifyContent='center'
          width='4.5rem'
          minWidth='4.5rem'
          height='4.5rem'
          onClick={() => setInventory(!inventory)}
        >
          <Icon
            svg={<GameIcon />}
            fill='rgba(255, 255, 255, 0.7)'
            width='2.4rem'
            height='2.4rem'
            _icon={{ width: '2.4rem', height: '2.4rem' }}
          />
        </Flex>
      </Flex>

      <Box mt='1rem'>
        <Typography color='yellow'>
          <FormattedMessage id='game.inventory.hints.heading' />
        </Typography>

        <Paragraph fontSize='1.4rem' lineHeight='2.1rem'>
          <FormattedMessage id='game.inventory.hints.description' />
        </Paragraph>

        <Grid
          columns='repeat(3, 7.5rem)'
          rows='7.5rem 7.5rem'
          gap='1rem'
          justifyContent='center'
          alignContent='space-evenly'
          justifyItems='center'
          alignItems='center'
          paddingX='2rem'
          marginTop='1rem'
        >
          {fillArray<Bonus>(hintsAndBonuses.hints, 
            { level: 99, icon: '', name: '' }, 
            6).map((hint, i) => (
            <Flex
              key={i}
              width='100%'
              style={{ aspectRatio: '1/1' } as CSSProperties}
              border={'1px solid ' + yellowLighter}
              flexDirection='column'
              alignItems='center'
              justifyContent='center'
              position='relative'
            >
              <>
                {hint.level < userLvl ?
                  <Img {...ImageStyle} src={'/img/game/' + hint.icon} alt={hint.name} /> :
                  (
                    <>
                      <Img {...ImageStyle} src='/img/game/images/question_mark.png' alt='Otaznik' />
                      <Typography color='yellow' textAlign='center' position='absolute' bottom='0'>
                  Level {hint.level}
                      </Typography>
                    </>
                  )}
              </>
            </Flex>
          ))}
        </Grid>

        <Typography color='yellow' mt={{ _:'4rem', sm:'2rem' }}>
          <FormattedMessage id='game.inventory.bonuses.heading' />
        </Typography>

        <Grid
          columns='repeat(4, 7.5rem)'
          rows='7.5rem 7.5rem 7.5rem'
          gap='1rem'
          justifyContent='space-evenly'
          alignContent='space-evenly'
          justifyItems='center'
          alignItems='center'
          paddingX='2rem'
          marginTop='1rem'
        >
          {fillArray<Bonus>(hintsAndBonuses.bonuses,
            { level: 99, icon: '', name: '' },
            12).map((bonus, i) => (
            <Flex
              key={i}
              width='100%'
              style={{ aspectRatio: '1/1' } as CSSProperties}
              border={'1px solid ' + yellowLighter}
              flexDirection='column'
              alignItems='center'
              justifyContent='center'
              position='relative'
            >
              <>
                {bonus.level < userLvl ?
                  <Img {...ImageStyle} src={'/img/game/' + bonus.icon} alt={bonus.name} /> :
                  (
                    <>
                      <Img {...ImageStyle} src='/img/game/images/question_mark.png' alt='Otaznik' />
                      <Typography color='yellow' textAlign='center' position='absolute' bottom='0'>
                  Level {bonus.level}
                      </Typography>
                    </>
                  )}
              </>
            </Flex>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default Inventory;
