import * as React from 'react';
import { StyledSlider, StyledSliderTypes } from './styles';
import { IEticon } from '../../../../../store/Entities/Eticons';
import SVGarrow from '../../../../atoms/SVGIcons/SVGarrow';
import { FormattedMessage } from 'react-intl';
import { IValueTestResultDetail } from 'config/api/valueTestResult';

interface ISliderValuesProps {
  values: IEticon[];
  tribal: IValueTestResultDetail | null;
}

interface ISliderValuesState {
  index: number;
  prevArrowVisible: boolean;
  nextArrowVisible: boolean;
}

class SliderValues extends React.Component<ISliderValuesProps, ISliderValuesState> {
  private slider: any;
  private card: any;

  constructor(props: ISliderValuesProps) {
    super(props);
    this.state = {
      index: 0,
      prevArrowVisible: false,
      nextArrowVisible: true,
    };
  }

  public render() {
    const values = this.props.values;
    return (
      <StyledSliderTypes>
        <StyledSlider>
          <div className='slider'>
            <div className='items' ref={this.getSlider}>
              {values.map((v, i) => (
                <div
                  className={
                    'plate' +
                    (this.props.tribal !== null && this.props.tribal.eticon.id === v.id ? ' plate--active active' : '')
                  }
                  key={v.id}
                  ref={i === 0 ? this.getCard : undefined}
                >
                  <img src={v.icon} alt='' />
                  <span className={'name'}>{v.name}</span>

                  <div className='hoverText'>{v.text2}</div>
                  <span className={'pickedBy'}>
                    <FormattedMessage id={'slider.values.total'} /> {v.totalCountUsage}{' '}
                    <FormattedMessage id={'slider.values.players'} />
                  </span>
                </div>
              ))}
            </div>
          </div>

          {this.state.prevArrowVisible && (
            <div className='arr-before' onClick={this.onPrev}>
              <SVGarrow />
            </div>
          )}

          {this.state.nextArrowVisible && (
            <div className='arr-after' onClick={this.onNext}>
              <SVGarrow />
            </div>
          )}
        </StyledSlider>
      </StyledSliderTypes>
    );
  }

  private getSlider = (el: any) => {
    this.slider = el;
  };

  private getCard = (el: any) => {
    this.card = el;
  };

  private onPrev = () => {
    const left = parseInt(this.slider.style.left, 10);
    const cardWidth = parseInt(window.getComputedStyle(this.card).getPropertyValue('width'), 10);
    this.slider.style.left = (left || 0) + cardWidth + 'px';
    const numCardsDisplayed = Math.floor(window.innerWidth / cardWidth);
    const numCardsInLine = this.props.values.length;
    this.setState({
      ...this.state,
      prevArrowVisible: this.state.index - 1 > 0,
      nextArrowVisible: this.state.index - 1 < numCardsInLine - numCardsDisplayed,
      index: this.state.index - 1,
    });
  };

  private onNext = () => {
    const left = parseInt(this.slider.style.left, 10);
    const cardWidth = parseInt(window.getComputedStyle(this.card).getPropertyValue('width'), 10);
    const numCardsDisplayed = Math.floor(window.innerWidth / cardWidth);
    const numCardsInLine = this.props.values.length;
    this.slider.style.left = (left || 0) - cardWidth + 'px';
    this.setState({
      ...this.state,
      prevArrowVisible: this.state.index + 1 > 0,
      nextArrowVisible: this.state.index + 1 < numCardsInLine - numCardsDisplayed,
      index: this.state.index + 1,
    });
  };
}

export default SliderValues;
