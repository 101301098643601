export interface PropObjectSizing {
  _?: string;
  xs?: string;
  sm?: string;
  md?: string;
  lg?: string;
}

export const setStyleValue = (prop: string | PropObjectSizing | undefined, BASE: string, minSize: '_' | 'xs' |'sm' | 'md' | 'lg' = '_') => {
  return typeof prop === 'string'
    ? prop
    : typeof prop === 'object'
      ? minSize === 'lg'
        ? prop.lg || prop.md || prop.sm || prop.xs || prop._ || BASE
        : minSize === 'md'
          ? prop.md || prop.sm || prop.xs || prop._ || BASE
          : minSize === 'sm'
            ? prop.sm || prop.xs || prop._ || BASE
            : minSize === 'xs'
              ? prop.xs || prop._ || BASE
              : prop._ || BASE
      : BASE;
};