import { createUrl } from 'components/pages/routes';
import { FC, useState } from 'react';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Box, Icon, Flex, Button, Heading2, Heading3 } from '@xcorejs/ui';
import GiftIcon from '../../../../components/icons/64/gift.svg';
import styled from 'styled-components';
import { Fox } from 'components/theme/fonts';
import { FormattedMessage } from 'react-intl';
import { claimVoucher } from '../../Shop/data';
import { Link as ReactLink } from 'react-router-dom';

interface CodeProps {
  notLoggedIn?: boolean;
}

const Code: FC<CodeProps> = ({ notLoggedIn }) => {
  const [code, setCode] = useState('');
  const history = useHistory();
  const [state, setState] = useState<'fail' | 'ok' | undefined>(undefined);
  
  const claim = (code: string) => {
    claimVoucher(code)
      .then(() => {
        setState('ok');
        history.replace('/cs/app/obchod/moje/');
      }).catch(() => {
        setState('fail');
      });
  };

  return (
    <Box maxWidth='65rem' mx='auto'>
      <Flex justifyContent='center' mt='8rem'>
        <Icon
          svg={<GiftIcon />}
          fill='rgba(255, 255, 255, 0.5)'
          _icon={{
            width: '6.4rem',
            height: '6.4rem'
          }}
        />
      </Flex>

      <Heading2
        as='h1'
        mt='3rem'
        fontWeight={400}
        color='yellow'
        textAlign='center'
      >
        <FormattedMessage id='activation.heading' />
      </Heading2>

      <Typography mt='1rem'>
        <FormattedMessage id='activation.text' />
      </Typography>

      <Box position='relative' mt={notLoggedIn ? '3rem' : 0}>
        {notLoggedIn && (
          <Flex
            justifyContent='center'
            alignItems='center'
            flexDirection='column'
            position='absolute'
            width='100%'
            height='100%'
            p='3rem'
            bg='rgba(11, 12, 28, 0.8)'
            borderRadius='4px'
            zIndex={1}
          >
            <Typography color='#fff'>
              <FormattedMessage id='activation.enterCode.login' />
            </Typography>

            <ReactLink to={createUrl('login')}>
              <Button s='sm' v='outline' mt='3rem'>
                <FormattedMessage id='activation.enterCode.loginBtn' />
              </Button>
            </ReactLink>
          </Flex>
        )}

        <Box
          filter={notLoggedIn ? 'blur(3px)' : 'none'}
          padding={notLoggedIn ? '2rem' : 0}
        >
          <Heading3 mt='6rem'>
            <FormattedMessage id='activation.enterCode' />
          </Heading3>

          <Flex mt='1rem' justifyContent='center'>
            <InputStyle onChange={e => setCode(e.target.value)} />
          </Flex>

          {state === 'fail' && (
            <Typography mt='3rem' textAlign='center'>
              <FormattedMessage id='activation.enterCodeError' />
            </Typography>
          )}

          <Flex mt='3rem' justifyContent='center'>
            <Button
              s='lg'
              fontSize={{ _: '2rem', sm: '2.2rem' }}
              lineHeight={{ _: '2.2rem', sm: '2.8rem' }}
              letterSpacing='0.5px'
              px='5rem'
              py='1.9rem'
              height='6rem'
              disabled={code.length !== 6 }
              _disabled={{
                opacity: 0.5
              }}
              onClick={() => claim(code)}
            >
              <FormattedMessage id='activation.enterCodeButton' />
            </Button>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

const InputStyle = styled.input`
  background: #fff;
  border: 0;
  border-radius: 4px;
  color: rgba(11, 12, 28,1);
  font-size: 2.4rem;
  letter-spacing: 1rem;
  line-height: 2.9rem;
  text-align: center;
  font-weight: 600;
  padding-top: 2.2rem;
  padding-bottom: 2.1rem;
  width: 100%;
  max-width: 41rem;
  font-family: ${Fox};
`;

export default Code;
