import * as React from 'react';
import { ILifeValueDetail, IPerson } from '../../../../store/Entities/LifeValues';
import { Row, Grid } from 'react-styled-flexboxgrid';
import AuthorCard from '../Cards/AuthorCard';
import TabWrapper from '../TabWrapper';
import { getAuthors } from '..';

interface IChars {
  inTest: boolean;
  detail: ILifeValueDetail;
  onVote: (id: string, type: string, text?: string) => any;
}

const Chars: React.FC<IChars> = ({ detail, inTest, onVote }) => (
  <Grid>
    <Row>
      <TabWrapper collection={getAuthors(detail)} inTest={inTest} color='purple' label={'Osobnost'}>
        {(itm: IPerson) => (
          <AuthorCard
            author={itm}
            valueId={detail.id}
            onVote={(id: string, type: string, text?: string) => onVote(id, type, text)}
          />
        )}
      </TabWrapper>
    </Row>
  </Grid>
);

export default Chars;
