export const TestGridClassic = {
  ValueCard: {
    height: '100%',
    imageSize: '12rem',
    fontSize: '2rem',
    lineHeight: '2.6rem',
    innerMargin: '3rem',
    draggWidth: '18%',
  },
  EmptySpace: {
    height: '100%',
    imageSize: '10rem',
  },
};

export const TestGridFinal = {
  ValueCard: {
    height: 'calc(20% - 1rem)',
    imageSize: '8rem',
    fontSize: '1.8rem',
    lineHeight: '2.3rem',
    innerMargin: '1rem',
    draggWidth: '13%',
  },
  EmptySpace: {
    height: 'calc(20% - 1rem)',
    imageSize: '6rem',
  },
};

export const DefaultGridTheme = {
  flexboxgrid: {
    // Defaults
    gridSize: 12, // rem
    gutterWidth: 3.2, // rem
    outerMargin: 4, // rem
    mediaQuery: 'only screen',
    container: {
      sm: 76.8, // rem
      md: 102.4, // rem
      lg: 129, // rem
    },
    breakpoints: {
      xs: 0, // em
      sm: 48, // em
      md: 64, // em
      lg: 82.5, //
    },
  },
  topMargin: '5rem',
};

export const CardsGridTheme = {
  flexboxgrid: {
    // Defaults
    gridSize: 24, // cols number
    gutterWidth: 1, // rem
    outerMargin: 1.6, // rem
    mediaQuery: 'only screen',
    container: {
      sm: 76.8, // rem
      md: 180, // rem
      lg: 180, // rem
    },
    breakpoints: {
      xs: 0, // em
      sm: 12, // em
      md: 64, // em
      lg: 82.5, // em
    },
  },
  topMargin: '35px',
};

export const mediaLgCards = '@media only screen and (min-width: 115em)';
export const mediaMdCards = '@media only screen and (min-width: 64em)';
export const mediaSmCards = '@media only screen and (min-width: 12em)';

export const mediaXlDefault = '@media only screen and (min-width: 90em)'; // 1440
export const mediaLgDefault = '@media only screen and (min-width: 82.5em)';
export const mediaMdDefault = '@media only screen and (min-width: 64em)';
export const mediaSmDefault = '@media only screen and (min-width: 48em)';
export const mediaXsDefault = '@media only screen and (min-width: 30em)';
export const mediaHonzaDefault = '@media only screen and (min-width: 64em) and (max-height: 48em)';
export const mediaLandscapePhone = '@media (orientation: landscape) and (min-width: 30em) and (max-width: 47.9375em)';
export const mediaLandscapePhoneAndSm =
  '@media (orientation: landscape) and (min-width: 30em) and (max-width: 47.9375em), (min-width: 48em)';
export const mediaLandscapePhoneAndMd =
  '@media (orientation: landscape) and (min-width: 30em) and (max-width: 47.9375em), (min-width: 64em)';
export const mediaLgMax = '@media only screen and (max-width: 82.5em)';
export const mediaMdMax = '@media only screen and (max-width: 64em)';
export const mediaSmMax = '@media only screen and (max-width: 47.9999em)';
