import React, { FC, useState, useEffect } from 'react';
import { Link, Img, Heading3, Paragraph, Icon, Box, Flex } from '@xcorejs/ui';
import { CompareIcon } from '../../components/icons/16/compare.svg';
import { yellowWhite } from 'components/theme/colors';

export interface ComparisonMoreInfoProps {
  summaryHeader: string;
  summaryDescription: string;
  changingTarget?: boolean;
}

const ComparisonMoreInfo: FC<ComparisonMoreInfoProps> = ({ summaryDescription, summaryHeader, changingTarget }) => {
  const [moreInfoVisible, setMoreInfoVisible] = useState<boolean>(false);
  const [debouncedMoreInfoVisible, setDebouncedMoreInfoVisible] = useState<boolean>(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedMoreInfoVisible(moreInfoVisible);
    }, 500);

    return () => clearTimeout(timeout);
  }, [moreInfoVisible]);
  
  const infoDisplayStyle: React.CSSProperties = {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'opacity 500ms ease-in-out',
    display: moreInfoVisible ? 'flex' : 'none',
  };

  const toggleDisplayStyle: React.CSSProperties = {
    ...infoDisplayStyle,
    display: debouncedMoreInfoVisible ? 'none' : 'flex',
  };

  return (
    <Box opacity={changingTarget ? 0 : 1} transition='opacity 500ms ease-in-out' position='relative'>
      <Flex
        {...toggleDisplayStyle}
        opacity={moreInfoVisible ? 0 : 1}
        position='relative'
      >
        <Link
          onClick={() => setMoreInfoVisible(true)}
          variant='underline' 
          marginTop='2rem'
          position='absolute'
          bottom='4rem'
          color='white'
        >Více o porovnání</Link>

        <Img
          src='/img/comparison/porovnani_fade.png'
          alt='comparison'
          maxWidth={{ _: '35rem', sm: '45rem' }}
        />
      </Flex>

      <Flex
        {...infoDisplayStyle}
        opacity={debouncedMoreInfoVisible ? 1 : 0}
      >
        <Heading3 marginTop='2rem'>{summaryHeader}</Heading3>

        <Paragraph
          marginTop='1rem'
          fontSize={{ _: '1.3rem', sm: '1.5rem' }}
          lineHeight={{ _: '1.5rem', sm: '2rem' }}
        >{summaryDescription}</Paragraph>

        <Img 
          src='/img/comparison/full_png.png'
          alt='comparison' 
          maxWidth={{ _: '35rem', sm: '45rem' }}
        />

      </Flex>
      
      <Paragraph
        marginTop='1rem'
        marginX='auto'
        color={yellowWhite}
        fontSize={{ _: '1.2rem', sm: '1.4rem' }}
        lineHeight={{ _: '1.8rem', sm: '2rem' }}
        width='30rem'
        maxWidth='90%'
        transition='opacity 500ms ease-in-out'
        opacity={changingTarget ? 0 : 1}
      >
        Porovnání kompatibilních žebříčků spustíš z deníku přes ikonu{' '}
        <Icon 
          svg={<CompareIcon />} 
          border={'1px solid ' + yellowWhite}
          borderRadius='9999px'
          p='0.2rem'
          height={{ _: '2rem', sm:'2.5rem' }}
          width={{ _: '2rem', sm:'2.5rem' }}
          fill={yellowWhite}
        />
      </Paragraph>
    </Box>
  );
};
 
export default ComparisonMoreInfo;