import { Box, BoxProps } from '@xcorejs/ui';
import { Link } from 'react-router-dom';
import { Col, Row } from 'react-styled-flexboxgrid';
import styled, { css } from 'styled-components';
import { SecondaryButton } from '../../../../atoms/Buttons/index';
import { StyledCard } from '../../../../atoms/Cards/styles';
import { Heading1, Heading2 } from '../../../../atoms/Headings/index';
import { StyledCircleProgressBar } from '../../../../atoms/ProgressBar/styles';
import { CustomText } from '../../../../atoms/Text/index';
import { StyledStatisticsSlider } from '../../../../molecules/StatisticsSlider/styles';
import { BoxActions } from '../../../../organisms/PartnerTest/Result/styles';
import { darkBaseRGB, darkenBlue, darkenGreen, gray, grayRGB, primaryGreen, VioletTheme, yellow } from '../../../../theme/colors';
import { mediaLgDefault, mediaMdDefault, mediaSmDefault } from '../../../../theme/grid';

export const StyledValueCenter = styled.section`
  text-align: center;
  padding-top: 3rem;
  padding-bottom: 8rem;

  ${mediaMdDefault} {
    text-align: left;
    padding-top: 9rem;
  }

  ${Heading1} {
    text-align: center;
  }

  &.not-relative {
    position: static;
  }

  .intro {
    margin-top: 1rem;
    text-align: center;
  }

  .level {
    text-align: center;
    margin-top: 3rem;

    ${mediaMdDefault} {
      margin-top: 0;
      text-align: left;
    }

    ${StyledCircleProgressBar} {
      vertical-align: middle;
      margin-right: 2rem;
    }

    .level-number {
      font-size: 2rem;
    }

    .level-text {
      color: ${props => props.theme.text};
      vertical-align: middle;
    }
  }

  .more-info {
    & > ${Row} {
      ${Col} {
        margin-bottom: 4rem;
      }
    }

    .stat-column {
      text-align: center;

      ${StyledStatisticsSlider} {
        margin-bottom: -2rem;

        .slide {
          .pointer {
            display: none;
          }
        }
      }

      .u--mobile {
        ${mediaSmDefault} {
          display: none;
        }
      }

      .what-else {
        max-width: 36rem;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
    }

    .institut {
      display: block;
      margin-top: 3.5rem;
      opacity: 0.8;
    }

    .c2a-box-comparison {
      position: relative;
      margin: 4rem auto 0;
      display: block;
      max-width: 30rem;
      border-radius: 0.4rem;
      box-shadow: 0 0.5rem 1rem 0 rgba(${darkBaseRGB}, 0.4);
      text-align: center;
      overflow: hidden;

      img {
        display: block;
      }
    }

    ${Heading2} {
      text-align: center;

      .ico,
      span {
        vertical-align: middle;

        svg {
          vertical-align: middle;
        }
      }

      .ico {
        max-width: 3.2rem;
        margin-right: 2.5rem;

        svg {
          max-width: 3.2rem;
        }

        ${mediaMdDefault} {
          margin-right: 1.5rem;
        }

        ${mediaLgDefault} {
          margin-right: 2rem;
          max-width: 4.8rem;
          max-height: 4.8rem;

          svg {
            max-width: 4.8rem;
          }
        }
      }
    }

    ${CustomText} {
      margin-top: 3.5rem;
    }

    .values-ordered {
      display: inline-block;
      position: relative;
      margin-top: 3rem;
      max-width: 100%;
      width: 100%;

      a {
        margin-top: 0;

        &:first-child {
          margin-top: 0;
        }

        ${BoxActions} {
          margin-top: 0;
        }
      }

      ${SecondaryButton} {
        margin-top: 4rem;
      }
    }

    .values-ordered {
      text-align: center;

      ${StyledCard} {
        background: ${primaryGreen};
        box-shadow: 0 2px 5px 0 rgba(${darkBaseRGB}, 0.4);

        &:after {
          opacity: 0;
          background: radial-gradient(circle at top, ${primaryGreen} 0%, ${darkenGreen} 100%);
          transition: opacity 0.3s;
        }

        &:hover {
          &:after {
            opacity: 1;
          }
        }
      }
    }

    .eticon-help {
      display: flex;
      align-items: center;
      margin-top: 3.5rem;
      margin-bottom: 0.5rem;
      justify-content: center;

      ${mediaMdDefault} {
        justify-content: flex-start;
      }

      img {
        width: 3rem;
        min-width: 3rem;
        margin-right: 1.5rem;
      }

      span {
        color: ${gray};
        font-size: 1.6rem;
        line-height: 2rem;
        text-align: left;
      }
    }
  }

  .my-values-column {
    position: relative;

    &.blurred,
    .blurred {
      filter: blur(4px);
      pointer-events: none;
      opacity: 0.5;
    }

    ${CustomText} {
      text-align: center;
      color: ${gray};
      font-size: 1.6rem;
      line-height: 2rem;
    }

    .select-row {
      margin-top: 2rem;
      text-align: center;

      & > span {
        position: relative;
        margin-left: 2rem;
        display: inline-block;
        font-size: 1.8rem;
        line-height: 2.7rem;
        cursor: pointer;

        &:before {
          margin-top: 0.2rem;
          margin-right: 1rem;
          content: "";
          display: inline-block;
          width: 0.3rem;
          height: 2rem;
          vertical-align: middle;
          float: left;
        }

        &.yellow {
          &:before {
            background-color: ${yellow};
          }
        }

        &.white {
          &:before {
            background-color: #fff;
          }
        }

        &.blue {
          &:before {
            background-color: ${darkenBlue};
          }
        }

        &.purple {
          &:before {
            background-color: ${VioletTheme.darken};
          }
        }

        &:hover {
          .tooltip-hover {
            opacity: 1;
          }
        }
      }

      .tooltip-hover {
        position: absolute;
        margin-top: 0.5rem;
        padding: 0.5rem 1.5rem;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        font-size: 1.6rem;
        line-height: 2rem;
        background: rgba(${darkBaseRGB}, 0.6);
        opacity: 0;
        border-radius: 4px;
        pointer-events: none;
        transition: opacity 300ms ease;
        z-index: 10;

        span {
          font-weight: 400;
        }
      }
    }

    .overlay {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      transform: translateX(-50%) translateY(-50%);
      text-align: center;
      z-index: 2;

      p {
        margin-bottom: 2rem;
      }

      a {
        padding: 1.6rem 3rem;
        font-size: 1.8rem;
        line-height: 3rem;
        border-radius: 3rem;
        height: 6rem;
        letter-spacing: 0.5px;
      }

      .start-btn {
        font-size: 2.2rem;
        letter-spacing: 0.5px;
        line-height: 2.8rem;
        padding: 1.9rem 5rem;
      }
    }
  }

  .styled-value-center-popup-button {
    position: absolute;
    right: 3rem;
    bottom: 3rem;
  }
`;

export const EticonLvl = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  //background: url("/img/circle-bg.svg") center center no-repeat;
  text-align: center;
  width: 26rem;
  margin: 0 auto;

  ${mediaLgDefault} {
    width: 26rem;

    ${StyledCircleProgressBar} {
      svg {
        width: 26rem;
        height: 26rem;
      }
    }
  }

  .absolute {
    position: absolute;
    max-width: 75%;
    top: 0;
    left: 50%;
    padding-top: 2.5rem;
    transform: translateX(-50%);

    & > a > strong,
    & > strong {
      margin-bottom: 1rem;
      display: block;
      font-size: 2rem;
      letter-spacing: 0.5px;
      line-height: 2.6rem;
    }
  }

  .img {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-height: 7.2rem;

    img {
      max-width: 9.2rem;
    }

    svg {
      height: 7.2rem;
    }
  }

  p {
    font-size: 1.6rem;
    line-height: 2.1rem;

    strong {
      color: ${yellow};
    }
  }

  .anchor-link {
    display: block;
    margin-top: 2rem;
    font-size: 1.6rem;
    line-height: 2.1rem;

    span {
      border-bottom: 1px solid ${gray};
      transition: border 0.3s;
    }

    &:hover {
      span {
        border-bottom: 1px solid transparent;
      }
    }
  }
`;

export const StyledWhatElseLink = styled(Link)`
  width: 33%;
  padding: 0 1rem;
  margin-top: 3rem;
  color: rgba(${grayRGB}, 0.8);
  font-size: 1.6rem;
  line-height: 2rem;
  transition: color 0.3s;
  text-align: center;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.5rem;
    height: 4.2rem;
  }

  svg {
    opacity: 0.5;
    height: 100%;

    path {
      fill: #fefefe;
    }
  }

  &:hover {
    color: rgba(${grayRGB}, 1);
  }
`;

interface BoxPurpleProps extends BoxProps {
  animate?: boolean;
}

export const BoxPulse = styled(Box)<BoxPurpleProps>`
  ${({ animate }) => animate && css`
    display: flex;
  
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      box-shadow: 0 0 10px 2px red;
      -webkit-animation: pulse 5s infinite;
      animation: pulse 5s infinite;
      z-index: 0;
      pointer-events: none;
    }

    @-webkit-keyframes pulse {
      0% {
        opacity: 0.3;
      }

      50% {
        opacity: 0.9;
      }

      100% {
        opacity: 0.3;
      }
    }

    @keyframes pulse {
      0% {
        opacity: 0.3;
      }

      50% {
        opacity: 0.9;
      }

      100% {
        opacity: 0.3;
      }
    }
  `}  
`;
