import * as React from 'react';
import { FakeCheckboxMark, StyledQuestionRadio } from './styles';
import { IFormItem } from '..';

interface IQuestionProps {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => any;
  checked?: boolean;
  disabled?: boolean;
}

const Radio: React.FC<IFormItem & IQuestionProps> = ({ label, form, id, onChange, checked, disabled }) => (
  <StyledQuestionRadio>
    <input type='radio' name={form} id={id} onChange={onChange} checked={checked} disabled={disabled} />
    <label htmlFor={id}>
      <FakeCheckboxMark />
      {label}
    </label>
  </StyledQuestionRadio>
);

export default Radio;
