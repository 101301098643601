import * as React from 'react';
import { FetchState, FetchReload } from './index';
import { jsonFetch } from '../../../utils/fetch';

interface IFetch<T> {
  result: T | null;
  state: FetchState;
  error: any | null;
  helper?: string;
}

export interface IUseFetch<T> extends IFetch<T> {
  reload: FetchReload;
  helper?: string;
}

const useFetch = <T extends {}>(url: string): IUseFetch<T> => {
  const [result, setResult] = React.useState<IFetch<T>>({
    result: null,
    state: 'fetching',
    helper: undefined,
    error: null,
  });
  React.useEffect(() => {
    if (result.state === 'fetching' || result.state === 'reload') {
      jsonFetch<T>(url)
        .then(r => setResult({ state: 'success', result: r, error: null }))
        .catch(e => setResult({ state: 'fail', result: null, error: e }));
    }
  });

  return {
    ...result,
    reload: (_?, helper?: string) => setResult({ state: 'reload', result: result.result, helper, error: null }),
  };
};

export default useFetch;
