import { Box } from '@xcorejs/ui';
import Navigation from 'components/atoms/HPNav';
import InteractiveMap from 'components/components/InteractiveMap/InteractiveMap';
import React, { FC } from 'react';
import AboutEticia from '../Homepage/components/AboutEticia';
import CloudMapCarousel from './CloudMapCarousel';
import Footer from './Footer';
import Header from './Header';
import Statistics from './Statistics';
import ValueCardsBox from './ValueCardsBox';
import YourEticon from './YourEticon';

const Eticiameter: FC = () => {  
  return ( 
    <>
      <Navigation />

      <Box>
        <Header />
        <CloudMapCarousel />       
        <InteractiveMap />
        {/* <Advertisement /> */}
        <Statistics />
        <YourEticon />    
        <ValueCardsBox />
        <AboutEticia />
        <Footer />
      </Box>
    </>
  );
};
 
export default Eticiameter;
