import styled from 'styled-components';
import { yellow, darkBaseRGB } from '../../theme/colors';

export const StyledProgressValue = styled.span`
  width: ${props => props.theme.progressValue}%;
  height: 0.5rem;
  border-radius: 1rem;
  background-color: ${yellow};
`;

export const StyledProgress = styled.div`
  width: 100%;

  .full-bar {
    position: relative;
    display: block;
    width: 100%;
    height: 0.5rem;
    border-radius: 1rem;
    background-color: rgba(${darkBaseRGB}, 0.3);
  }

  ${StyledProgressValue} {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
  }
`;

export const StyledProgressPoint = styled.span`
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  background-color: white;
  opacity: 0.5;
  &:first-of-type {
    left: 15%;
  }
  &:nth-of-type(2) {
    left: 50%;
    transform: translateX(-50%);
  }
  &:last-of-type {
    right: 15%;
  }
  &.passed {
    opacity: 1;
  }
  &:before {
    content: "";
    position: absolute;
    top: -0.8rem;
    left: 0.3rem;
    display: block;
    width: 0.2rem;
    height: 2.4rem;
    background-color: white;
  }
`;

export const StyledCircleProgressBar = styled.div`
  position: relative;
  display: inline-block;
  .value {
    position: absolute;
    left: 0;
    right: 0;
    transform: rotate(-90deg);
  }

  .label {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: block;
    white-space: nowrap;
    text-align: center;

    &:before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      height: 100%;
    }

    * {
      display: inline-block;
      vertical-align: middle;
      white-space: normal;
      max-width: 85%;
    }
  }
`;
