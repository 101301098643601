import { jsonFetch } from '../../../utils/fetch';
import { PASSWORD_FORGOTTEN_CHANGE } from 'config/apiRoutes';

export interface IForgottenPasswordChangeState {
  password: string;
  passwordConfirm: string;
  userId: string;
  token: string;
  error: {
    Password: string;
    PasswordConfirm: string;
  } | null;
}

export const changePassword = (body: IForgottenPasswordChangeState, onSuccess: () => any, onError: (o: any) => any) =>
  jsonFetch(PASSWORD_FORGOTTEN_CHANGE, {
    method: 'POST',
    body: JSON.stringify({ ...body }),
  })
    .then(() => onSuccess())
    .catch(e =>
      e.data.json().then((json: any) => {
        if (json) {
          onError(json);
        }
      }),
    );
