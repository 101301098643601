import React, { useEffect, FC } from 'react';
import { SHOP_SUMMARY } from 'config/apiRoutes';
import { ShopSummaryResponse } from 'components/pages/App/Shop/data';
import { Flex, Typography, Icon, Box } from '@xcorejs/ui';
import LadderIcon from '../../components/icons/24/ladder.svg';
import withUser, { IWithUser } from 'components/molecules/withRedux/withUser';
import { FormattedMessage } from 'react-intl';

const Membership: FC<IWithUser> = ( { user }) => {
  const eticiaPlus = useEticiaPlusInfo(user.eticiaPlus);

  const dateHelper = (date: string) => 
    Math.round(Math.abs(new Date(date).getTime() - new Date(Date.now()).getTime()) / (24 * 60 * 60 * 1000));

  if (eticiaPlus === null) return null;

  return (
    <Flex as='section' bg='rgb(127, 53, 81)' flexDirection='column'>
      {eticiaPlus.eticiaPlusValidTo ? (
        <Typography color='pink'>
          <FormattedMessage id='eticiaPlus.membership.validTo' />
          <Box as='span' display={{ _: 'block', sm: 'inline' }} color='#fff'>
            {dateHelper(eticiaPlus.eticiaPlusValidTo) > 10950 ? (
              <FormattedMessage id='eticiaPlus.membership.validToForever' />
            ) : (
              <>
                {dateHelper(eticiaPlus.eticiaPlusValidTo)} 
                <FormattedMessage id='eticiaPlus.membership.validToDays' />
                {new Date(eticiaPlus.eticiaPlusValidTo).toLocaleDateString()}
              </>
            )}
          </Box>
        </Typography>
      ) : (
        <Typography>
          <FormattedMessage id='eticiaPlus.membership.notValid' />
        </Typography>
      )}

      <Flex
        alignItems='center'
        justifyContent='center'
        mt='2.4rem'
        flexDirection={{ _: 'row' }}
      >
        <Typography color='#e7e6cf'>
          <FormattedMessage id='eticiaPlus.membership.available' />
        </Typography>

        <Typography fontFamily='OliMedium' color='yellow' ml='1rem' >
          <Icon svg={<LadderIcon />} mr='0.8rem' fill='yellow' />
          {eticiaPlus.availableMagicTokenCount}
        </Typography>

        <Typography color='#ffde71' ml='0.5rem'>
          <FormattedMessage id='eticiaPlus.membership.magicTokens' />
        </Typography>
      </Flex>
    </Flex>
  );
};
 
export default withUser(Membership);

const useEticiaPlusInfo = (isPlus: boolean) => {
  const [eticiaPlus, setEticiaPlus] = React.useState<ShopSummaryResponse | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(SHOP_SUMMARY);
      if (res.status === 200) {
        const data: ShopSummaryResponse | null = await res.json().catch(() => null);
        return data;
      }
      return null;
    };

    if (isPlus) fetchData().then(data => {
      if (data) {
        setEticiaPlus(data);
      }
    });
  }, [isPlus]);

  return eticiaPlus;
};
