import styled, { css } from 'styled-components';
import { Col, Grid, Row } from 'react-styled-flexboxgrid';
import { mediaSmDefault } from '../../theme/grid';

export const StyledInfoMessage = styled.div<{ closed?: boolean }>`
  position: relative;
  overflow: hidden;
  transition: height 1s;
  height: 6.8rem;
  display: flex;
  justify-content: center;

  ${props =>
    props.closed &&
    css`
      height: 0;
    `}

  &:after {
    content: " ";
    display: block;
    width: 100%;
    height: 6.8rem;
  }

  ${Grid} {
    position: absolute;
    padding: 1rem 0;
    margin-top: 0;
    margin-bottom: 3rem;
    background-color: rgba(${props => props.theme.flash}, 0.3);
    border-radius: 0.4rem;
  }

  ${Row} {
    margin: 0;
  }

  ${Col} {
    display: flex;
    align-items: center;
    justify-content: center;

    & > div {
      padding-right: 1.6rem;
      display: flex;
      align-items: center;
      text-align: left;
      font-size: 1.3rem;

      ${mediaSmDefault} {
        font-size: 1.5rem;
      }

      svg {
        min-width: 2.5rem;
        height: 2.2rem;
        margin-right: 1rem;
      }

      ${mediaSmDefault} {
        text-align: center;
      }
    }
  }

  .close {
    position: absolute;
    right: 1.6rem;
  }

  svg {
    display: block;
    height: 1.8rem;

    path {
      fill: #fff;
    }
  }
`;
