import React, { useState, FC, useEffect } from 'react';
import { Box, Flex, Typography, Img, Paragraph } from '@xcorejs/ui';
import Rectangles from './Rectangles';
import { OliLight } from 'components/theme/fonts';
import Fade from './Fade';
import styled from 'styled-components';

interface GameAnimationIntroProps {
  setStatus?: React.Dispatch<React.SetStateAction<number>>;
  onlyOnce?: boolean;
}

const GameAnimationIntro: FC<GameAnimationIntroProps> = ({ setStatus, onlyOnce = false }) => {
  const [animationStatus, setAnimationStatus] = useState<number>(0);
  const hasPlayedSession = onlyOnce ? sessionStorage.getItem('hasPlayedSession') : false;

  const increaseStatus = () => setStatus ? setStatus(prev => prev + 1) : null;

  if (hasPlayedSession && onlyOnce) {
    increaseStatus();
    return null;
  }

  const nextAnimation = () => {
    if (animationStatus === 3) {
      onlyOnce && sessionStorage.setItem('hasPlayedSession', 'true');
      increaseStatus();
    } else {
      null;
    }
  };

  return ( 
    <BoxAnimation
      height={{ _: '100%' }}
      width='100%'
      overflow='hidden'
      position='absolute'
      zIndex={3}
      top='0'
      left='0'
      background='transparent'
    >
      <Flex
        justifyContent='center'
        alignItems='center'
        position='absolute'
        bottom='0'
        left='0'
        width='100%'
        height='100%'
        className={animationStatus >= 3 ? 'animate-flex' : ''}
        onAnimationEnd={nextAnimation}
      >
        <Box
          width='100%'
          height='100%'
          position='absolute'
          top='0'
          left='0'          
        />
        <Flex
          position='absolute'
          top='0'
          left='0'
          width='100%'
          justifyContent='center'
          zIndex={99}
          opacity={animationStatus > 0 && animationStatus < 3 ? 1 : 0}
          transition='opacity 500ms ease-in-out'
        >
          <Typography 
            onClick={animationStatus > 0 && animationStatus < 3 ? () => setAnimationStatus(3) : () => {}}
            padding='1rem 2rem'
            userSelect='none'
            fontFamily={OliLight}
            color='#000'
            fontSize='1.2rem'>Přeskočit</Typography>
        </Flex>
        {
          animationStatus < 3 ? 
            <Mlha src='/img/hodnotova-hra-presenting/mlha2.png' alt='mlha' delay={2} /> : 
            null
        }
        
        {animationStatus === 0 ? (
          <Fade setStatus={setAnimationStatus} fadeType='in' duration={2} delay={1} />
        ) : animationStatus === 1 ? (
          <Rectangles setStatus={setAnimationStatus} />
        ) : animationStatus === 2 ? (
          <Fade setStatus={setAnimationStatus} fadeType='out' duration={2} color='#FFF'  />
        ) : animationStatus === 3 ? (
          <Box
            position='absolute'
            bottom='0'
            left='0'
            width='100%'
            height='80%'
            backgroundColor='#FFF'
          >
            <Img
              src='/img/hodnotova-hra-presenting/mlha2.png'
              alt='mlha'
              width='110%'
              position='absolute'
              top='-280px'
              left='0'
              objectFit='cover'
              transform='rotate(180deg)'
            />
          </Box>
        ) : null}
      </Flex>
    </BoxAnimation>
  );
};
 
export default GameAnimationIntro;

const Mlha = styled(Img)<{delay?: number}>`
  --mist-animation-duration: 2s;
  --mist-animation-delay: ${({ delay }) => delay || 0}s;
  position: absolute;
  top: -50px;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  
  animation-name: mistFadeIn;
  -webkit-animation-name: mistFadeIn;
  animation-duration: var(--mist-animation-duration);
  -webkit-animation-duration: var(--mist-animation-duration);
  animation-delay: var(--mist-animation-delay);
  -webkit-animation-delay: var(--mist-animation-delay);
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;

  @-webkit-keyframes mistFadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes mistFadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`;


const BoxAnimation = styled(Box)`
  .animate-flex {
    --lowerSelf-duration: 1.5s;
    transform: translateY(0);

    animation-name: lowerSelf;
    -webkit-animation-name: lowerSelf;
    animation-duration: var(--lowerSelf-duration);
    -webkit-animation-duration: var(--lowerSelf-duration);
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    
    @keyframes lowerSelf {
      0% {
        transform: translateY(0);
      }

      100% {
        transform: translateY(200%);
      }
    }
    
    @-webkit-keyframes lowerSelf {
      0% {
        transform: translateY(0);
      }
      
      100% {
        transform: translateY(200%);
      }
    }
  }
`;