import * as React from 'react';

import { StyledTestDivider } from './styles';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

interface ITestDivider {
  withArrows?: boolean;
  onPrev?: () => any;
  onNext?: () => any;
  prev?: boolean;
  next?: boolean;
}

const TestDivider: React.FC<ITestDivider> = ({ withArrows, onPrev, onNext, prev, next }) => (
  <StyledTestDivider>
    {withArrows ? (
      <div className='divider-wrap'>
        {prev ? (
          <span className='prev' onClick={onPrev}>
            <img src={'/img/ico/ico-arrow-up.svg'} alt='ico arrow' />
            <p>
              <FormattedMessage id={'nextscroll'} /> 4
            </p>
          </span>
        ) : null}
        <div className='divider-middle'>
          <img src={'/img/ico/right-dotted.svg'} alt='ico arrow' />
          <p>
            <FormattedHTMLMessage id={'test.dragDrop'} />
          </p>
        </div>
        {next ? (
          <span className='next' onClick={onNext}>
            <img src={'/img/ico/ico-arrow-down.svg'} alt='ico arrow' />
            <p>
              <FormattedMessage id={'nextscroll'} /> 4
            </p>
          </span>
        ) : null}
      </div>
    ) : (
      <div className='divider-middle'>
        <img src={'/img/ico/right-dotted.svg'} alt='ico arrow' />
        <p>
          <FormattedHTMLMessage id={'test.dragDrop'} />
        </p>
      </div>
    )}
  </StyledTestDivider>
);

export default TestDivider;
