import { ProductProps } from '../data';
import { FC, useState } from 'react';
import { Flex, Icon, Heading1, Heading3, Heading2, Link } from '@xcorejs/ui';
import StarIcon from '../../../components/icons/24/star.svg';
import { FormattedMessage } from 'react-intl';
import Accordion from 'components/atoms/Accordion/Accordion';
import { Product as ProductStyled } from '../styles';
import { ShopProps } from '../Shop';
import React from 'react';
import ProductInfo from './ProductInfo';
import ProductRouterLinks from './ProductRouterLinks';

export interface ProductCardProps extends ProductProps, Omit<ShopProps, 'extend'> {
  show: boolean;
}

const ProductCard: FC<ProductCardProps> = ({ 
  show,
  color,
  backgroundColor,
  length, 
  tree, 
  price, 
  ladders, 
  text1, 
  text2, 
  ...props
}) => {
  const [showMore, setShowMore] = useState(show);

  const productInfo = {
    ladders,
    tree,
    text1,
    text2,
    color
  };

  const productRouterLinks = {
    color,
    ...props
  };
  
  const clickMore = () => {
    setShowMore(true);
  };
  
  return (
    <ProductStyled
      borderColor={color}
      backgroundColor={backgroundColor}
      onClick={clickMore}
    >
      <Flex
        alignItems='flex-start'
        justifyContent='center'
        flexWrap='wrap'
        color={color}
        position='relative'
      >
        <Icon 
          svg={<StarIcon />}
          transform='scale(0.6)'
          position={{ _:'relative', md: 'absolute' }}
          top={{ _: '-14%', md:'-20%' }}
        />
        <Heading1 flexGrow={1} mt={{ _: 0, md:'2rem' }}>
            Eticia Plus <strong>{length}</strong>
        </Heading1>
        {tree ? (
          <Heading3
            position='absolute'
            bottom='-20%'
          ><FormattedMessage id='eticiaPlus.shop.heading.tree' />
          </Heading3>
        ) : null} 
      </Flex>
  
      {/* phone info */}
      <Accordion
        expanded={showMore}
        flexDirection='column'
        alignItems='center'
        onlyPhone
      >
        <ProductInfo {...productInfo} />
      </Accordion>
  
      {/* pc info */}
      <Flex
        display={{ _: 'none', md: 'flex' }}
        flexDirection='column'
        alignItems='center'>
        <ProductInfo {...productInfo} />
      </Flex>

      <Heading2 color={color} mt='2rem'>
        {price},- Kč
      </Heading2>
        
      {/* show more button */}
      <Accordion expanded={!showMore} onlyPhone>
        <Link
          onClick={clickMore}
          color='#C9C9C9'
          textDecoration='underline'
          mt='1rem'
          fontSize='1.2rem' >
          <FormattedMessage id='eticiaPlus.shop.more' />
        </Link>
      </Accordion>

      <ProductRouterLinks {...productRouterLinks} />
    </ProductStyled>
  );
};

export default ProductCard;
