import * as React from 'react';
import { IQuote } from '../../../../store/Entities/LifeValues';
import ValueDefinitionQuote from './ValueDefinitionQuote';
import Vote from '../Vote';
import wrapIntl, { IWrapIntl } from '../../../atoms/wrapIntl';

interface IQuoteCard {
  quote: IQuote;
  valueId: string;
  onVote: (id: string, type: string, text?: string) => any;
}

const QuoteCard: React.FC<IWrapIntl & IQuoteCard> = ({ quote, formatMsg, valueId, onVote }) => (
  <ValueDefinitionQuote
    color={'green'}
    label={formatMsg('valueDefinition.quote')}
    name={quote.author}
    job={quote.profession}
    year={quote.life}
    text={quote.content}
  >
    <Vote
      id={valueId}
      defId={quote.id}
      type='quote'
      onVote={(id: string, type: string, text?: string) => onVote(id, type, text)}
    />
  </ValueDefinitionQuote>
);

export default wrapIntl(QuoteCard);
