import * as React from 'react';
import { StyledTable } from './styles';

interface ITable {
  children?: any;
  className?: string;
}

export const Table: React.FC<ITable> = ({ children, className }) => {
  return <StyledTable className={className}>{children}</StyledTable>;
};

export default Table;
