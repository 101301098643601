import { QueryKey, useQuery } from 'react-query';
import { IFetchException } from '../exceptions';
import { jsonFetch } from '../fetch';

export const useQueryFetch = <T>({
  queryKey,
  path,
  enabled = true
}:{
  queryKey: QueryKey;
  path: string;
  enabled?: boolean;
}) => {
  return useQuery<T, IFetchException>(
    [...queryKey, path],
    () => jsonFetch<T>(path),
    {
      enabled
    },
  );
};
