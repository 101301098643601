import { Box, Button, Container, Flex, Grid, Heading1, Heading2, Icon, Img, Link as XLink, List, ListItem, Paragraph, Tag, Typography, useModal } from '@xcorejs/ui';
import { GameContext } from 'components/App';
import Accordion from 'components/atoms/Accordion/Accordion';
import useIframeModalLinkClickHandler from 'components/atoms/hooks/useIframeModalLinkClickHandler';
import AnimatedArrow from 'components/atoms/Icons/AnimatedArrow';
import SVGarrow from 'components/atoms/SVGIcons/SVGarrow';
import SVGGame1 from 'components/atoms/SVGIcons/SVGGame1';
import SVGGame2 from 'components/atoms/SVGIcons/SVGGame2';
import SVGGame3 from 'components/atoms/SVGIcons/SVGGame3';
import { PremiumButton, PrimaryButton, SecondaryButton } from 'components/components/Button';
import Carousel, { CarouselButtonProps } from 'components/components/Carousel';
import EticiaPlusModalNew from 'components/components/modals/EticiaPlusModalNew';
import Table from 'components/organisms/EticiaPlus/Table';
import { ButtonProps, LinkProps, PageProps, Section10Props, Section11Props, Section12Props, Section13Props, Section1Props, Section2Props, Section3Props, Section4aProps, Section4Props, Section5Props, Section6Props, Section7Props, Section8Props, Section9Props } from 'components/pages/Presenting/data';
import { createUrl } from 'components/pages/routes';
import { BlueTheme, PlusTheme } from 'components/theme/colors';
import { OliLight } from 'components/theme/fonts';
import { mediaSmDefault } from 'components/theme/grid';
import { debounce } from 'lodash';
import * as React from 'react';
import { FC, useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import { Link } from 'react-router-dom';
import styled, { css, CSSProperties } from 'styled-components';
import LadderIcon from '../../components/icons/24/ladder.svg';

interface GeneralPageProps {
  page: PageProps;
  withoutPadding?: boolean;
}

const Page: FC<GeneralPageProps> = ({ page, withoutPadding }) => {  
  useIframeModalLinkClickHandler([]);

  return (
    <Box pt={ withoutPadding ? 0 : { _: '1rem', md: '6rem' }} position='relative' zIndex={1}>
      {page.section1 && (
        <Section1 data={page.section1} />
      )}
      {page.section2 && (
        <Section2 data={page.section2} />
      )}
      {page.section4 && (
        <Section4 data={page.section4} />
      )}
      {page.section4a && (
        <Section4a data={page.section4a} />
      )}
      {page.section5 && (
        <Section5 data={page.section5} />
      )}
      {page.section6 && (
        <Section6 data={page.section6} />
      )}
      {page.section7 && (
        <Section7 data={page.section7} />
      )}
      {page.section3 && (
        <Section3 data={page.section3} />
      )}
      {page.section8 && (
        <Section8 data={page.section8} />
      )}
      {page.section9 && (
        <Section9 data={page.section9} />
      )}
      {page.section10 && (
        <Section10 data={page.section10} />
      )}
      {page.section11 && (
        <Section11 data={page.section11} />
      )}
      {page.section12 && (
        <Section12 data={page.section12} />
      )}
      {page.section1 && !page.doNotDuplicateFirstSection && (
        <Section1 data={page.section1} thinner />
      )}
      {page.section13 && (
        <Section13 data={page.section13} />
      )}
    </Box>
  );
};

export default Page;


const Section1: FC<{ data: Section1Props; thinner?: boolean }> = ({ data, thinner }) => {
  const { eticiaPlus } = useContext(GameContext);
  const [scroll, setScroll] = useState<number>(0);
  const [iconsVisible, setIconsVisible] = useState<boolean>(false);
  const [openEticiaPlusModal] = useModal(EticiaPlusModalNew);
  
  const handleScroll = () => setScroll(window.scrollY);

  useEffect(() => {
    const debouncedHandleScroll = debounce(handleScroll, 10);
    window.addEventListener('scroll', debouncedHandleScroll);
    return () => window.removeEventListener('scroll', debouncedHandleScroll);
  }, []);
  

  useEffect(() => {
    let timeout = setTimeout(() => setIconsVisible(true), 1500);

    return () => clearTimeout(timeout);
  }, []);


  return (
    <Flex
      pt={thinner ? '0rem' : '0rem'}
      pb={thinner ? '0rem' : '4rem'}
      background={thinner ? `url(/img/presenting/${data.backgroundImgThinner})` : `url(/img/presenting/${data.backgroundImg})`}
      backgroundPosition='center'
      backgroundSize='cover'
      position='relative'
      minHeight={{ _: 'auto', sm: '500px' }}
      {...{ style: { backgroundAttachment: 'fixed' } }}
    >
      {!thinner && data.backgroundImg2 && (
        <Box
          opacity={iconsVisible ? 1 : 0}
          top='0'
          position='absolute'
          width='100%'
          height='100%'
          transition='opacity 1600ms cubic-bezier(0.72, 0.35, 1, 1)'
          zIndex={1}
          background={{
            _: `url(/img/presenting/${data.backgroundImg2Mobile})`,
            sm: `url(/img/presenting/${data.backgroundImg2Tablet})`,
            lg: `url(/img/presenting/${data.backgroundImg2})`
          }}
          backgroundPosition={{
            _: '50% 100%',
            sm: '50% 100%',
            lg: '50% 100%'
          }}
          backgroundSize={{
            _: '100%',
            sm: '100%',
            lg: '100%'
          }}
          backgroundRepeat={{
            _: 'no-repeat',
            sm: 'no-repeat',
            lg: 'no-repeat'
          }}
          transform={{
            _: `scale(1 + ${scroll * 0.0005})`,
            sm: `scale(1 + ${scroll * 0.0005})`,
            lg: `scale(1 + ${scroll * 0.0005})`
          }}
        />
      )}

      {data.gradient && (
        <GradientStyle
          color1={data.gradient.color1}
          color2={data.gradient.color2}
          color3={data.gradient.color3}
          color4={data.gradient.color4}
          speed={data.gradient.speed}
        />
      )}

      <Container
        flexDirection='column'
        alignItems='center'
        maxWidth='85rem'
        width='85%'
        position='relative'
        zIndex={2}
      >
        {thinner ? (
          <Fade bottom ssrReveal duration={1000} distance='5rem' delay={100}>
            <Box position='relative' marginTop='-1rem'>
              <Img
                src={`/img/presenting/${thinner ? data.imgThinnerMobile : data.imgMobile}`}
                display={{ _: 'block', sm: 'none' }}
                maxWidth='7.5rem'
                mx='auto'
                alt=''
              />

              <Img
                src={`/img/presenting/${thinner ? data.imgThinner : data.img}`}
                display={{ _: 'none', sm: 'block' }}
                maxWidth='10rem'
                mx='auto'
                alt=''
              />
              <Box 
                position='absolute'
                width='100%'
                borderRadius='50%'
                boxShadow='0 0 4rem 2rem #ffffff67'
                bottom={0}
                transform='scale(0.75) translate(0px, 7px)'
                zIndex={-1}
                style={{ aspectRatio: '1/1' } as CSSProperties} />
            </Box>
          </Fade>
        ) : (
          <Fade top ssrReveal duration={1500} distance='10rem' delay={100}>
            <Box position='relative' marginTop='-1rem'>
              <Img
                src={`/img/presenting/${thinner ? data.imgThinnerMobile : data.imgMobile}`}
                display={{ _: 'block', sm: 'none' }}
                mx='auto'
                alt=''
              />

              <Img
                src={`/img/presenting/${thinner ? data.imgThinner : data.img}`}
                display={{ _: 'none', sm: 'block' }}
                mx='auto'
                alt=''
              />

              {/* bílý podmaz pod obrázkem */}
              <Box 
                position='absolute'
                width='100%'
                borderRadius='50%'
                boxShadow='0 0 4rem 2rem #ffffff67'
                bottom={0}
                transform='scale(0.75) translate(0px, 7px)'
                zIndex={-1}
                style={{ aspectRatio: '1/1' } as CSSProperties} />
            </Box>
          </Fade>
        )}

        <Fade ssrReveal bottom duration={1000} delay={1300} distance={'5rem'}>
          {thinner ? (
            <Heading1 as='h2' {...Heading2Style} mt='4rem' textAlign='center'>
              <div dangerouslySetInnerHTML={{ __html: data.header }} />
            </Heading1>
          ) : (
            <Heading1
              fontSize={{ _: '2.4rem', sm: '2.8rem' }}
              lineHeight={{ _: '3rem', sm: '3.4rem' }}
              mt='4rem'
              textAlign='center'
              fontWeight={400}
            >
              <div dangerouslySetInnerHTML={{ __html: data.header }} />
            </Heading1>
          )}
        </Fade>

        <Fade ssrReveal bottom duration={1000} delay={2000} distance={'5rem'}>
          <div>
            {!thinner && (
              <Typography {...TypographyStyle} mb='2rem' mt='2rem' color='white' fontFamily={OliLight}>
                <div dangerouslySetInnerHTML={{ __html: data.subtitle }} />
              </Typography>
            )}
          </div>

          <div>
            {data.text && (
              <Typography fontSize='1.4rem' mt='1rem' color='rgba(255, 255, 255, 0.7)'>
                <div dangerouslySetInnerHTML={{ __html: data.text }} />
              </Typography>
            )}
          </div>

          <ScaleInsideDiv thinner={thinner}>
            {data.button && (
              <PresentingButton _button={data.button} />
            )}

            {data.link && (
              <PresentingLink _link={data.link} />
            )}
          </ScaleInsideDiv>

          <Box>
            {data.eticiaPlus && !eticiaPlus && (
              <Typography
                fontSize={{ _: '1.4rem', sm: '1,4rem' }}
                lineHeight={{ _: '1.8rem', sm: '2rem' }}
                color='rgba(255, 255, 255, 0.8)'
                mt='1rem'
                alignItems='center'
                fontFamily='OliLight'
              >
                <FormattedMessage id='presenting.plusOnly1' />
                <Tag
                  borderRadius='0.5rem'
                  border='1px solid rgba(255, 255, 255, 0.9)'
                  color='inherit'
                  ml='0.2rem'
                  px={{ _: '0.3rem', sm: '0.5rem' }}
                  lineHeight='1'
                  fontFamily='inherit'
                  background='unset'
                  fontSize='inherit'
                  onClick={() => openEticiaPlusModal({ inApp: location.pathname.includes('/app') })}
                >
                  Eticia plus
                </Tag>
                <br />
                <span><FormattedMessage id='presenting.plusOnly2' /></span>
              </Typography>
            )}

            {data.bottomText && (
              <Typography fontSize={{ _: '1.4rem', sm: '1,4rem' }} lineHeight='2rem' color='rgba(255, 255, 255, 0.8)' mt='4rem'>
                <div dangerouslySetInnerHTML={{ __html: data.bottomText }} />
              </Typography>
            )}
          </Box>

          <Flex
            justifyContent='center'
            width='100%'
            position='relative'
            bottom={{ _: '-1rem', md: '1rem' }}
            left='0'
            zIndex={10}
            onClick={() => window.scrollTo({ top: window.innerHeight - 300, behavior: 'smooth' })}
            height='3rem'
            top='2rem'
          >
            {!thinner && (
              <AnimatedArrow
                color='#ffffff'
                transform={{ _: 'scale(0.5) rotate(90deg)', md: 'scale(0.65) rotate(90deg)' }}
                cursor='pointer'
              />
            )}
          </Flex>          
        </Fade>
      </Container>

      <Box position='absolute' width='100%' height='20rem' background='linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, #00000080 100%)' top='0' />
      {data.backgroundAnimatedOverlay && (
        <Box 
          backgroundImage={`url(/img/presenting/${data.backgroundAnimatedOverlay.imageUrl})`}
          backgroundRepeat={{ 
            _: data.backgroundAnimatedOverlay.repeat.phone,
            sm: data.backgroundAnimatedOverlay.repeat.desktop 
          }}
          backgroundPosition='center'
          backgroundSize={{
            _: data.backgroundAnimatedOverlay.size.phone,
            sm: data.backgroundAnimatedOverlay.size.desktop 
          }}
          position='absolute'
          top='0'
          right='0'
          bottom='0'
          left='0'
          opacity={data.backgroundAnimatedOverlay.opacity} />
      )}
      <Box position='absolute' width='100%' height='20rem' background='linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #FFF4 100%)' bottom='0' />
    </Flex>
  );
};

const Section2: FC<{ data: Section2Props }> = ({ data }) => {
  return (
    <Flex
      py='7rem'
      background={data.bgColor}
      backgroundRepeat='no-repeat'
      backgroundPosition='85% 65%'
      {...{ style: { backgroundAttachment: 'fixed' } }}
    >
      <Container
        flexDirection='column'
        alignItems='center'
        maxWidth='85rem'
        width='85%'
      >
        <Fade ssrReveal bottom duration={1000} distance={'5rem'} delay={1100}>
          {data.header && (
            <Heading2 {...Heading2Style} color='presentingBlack'>
              <div dangerouslySetInnerHTML={{ __html: data.header }} />
            </Heading2>
          )}

          {data.subheader && (
            <Typography {...TypographyStyle} color='presentingBlack' mt={data.header ? '2.5rem' : 0}>
              <div dangerouslySetInnerHTML={{ __html: data.subheader }} />
            </Typography>
          )}

          <Typography {...TypographyStyle}  mt={data.header || data.subheader ? '3rem' : 0} color='presentingBlack' >
            <div dangerouslySetInnerHTML={{ __html: data.subtitle }} />
          </Typography>

          <Img src={`/img/presenting/${data.backgroundImg}`} display='block' mx='auto' mt='3rem' alt='' />

          {data.downtext && (
            <Typography {...TypographyStyle} color='presentingBlack' mt='3rem'>
              <div dangerouslySetInnerHTML={{ __html: data.downtext }} />
            </Typography>
          )}
        </Fade>
      </Container>
    </Flex>
  );
};

const Section3: FC<{ data: Section3Props }> = ({ data }) => {
  const [open, setOpen] = useState(false);

  return (
    <Flex
      py='3rem'
      background={`url(/img/presenting/${data.backgroundImg}), radial-gradient(circle, #90A5BD 0%, #697B90 100%)`}
      backgroundPosition='center'
      {...{ style: { backgroundAttachment: 'fixed' } }}
    >
      <Container
        flexDirection='column'
        alignItems='center'
        maxWidth='85rem'
        width='85%'
      >
        <Fade ssrReveal bottom duration={1000} distance={'5rem'}>
          <Typography color='rgba(255, 255, 255, 0.8)'>
            <div dangerouslySetInnerHTML={{ __html: data.subtitle }} />
          </Typography>

          <Heading2 {...Heading2Style} fontSize='2rem' color='#fff' mt='1rem'>
            <div dangerouslySetInnerHTML={{ __html: data.header }} />
          </Heading2>

          <Typography
            {...TypographyStyle}
            color='#fff'
            mt='1rem'
            overflow='hidden'
            display='-webkit-box'
            WebkitLineClamp={open ? 'unset' : 3}
            WebkitBoxOrient='vertical'
          >
            <div dangerouslySetInnerHTML={{ __html: data.text }} />
          </Typography>

          <Box transform={open ? 'rotate(90deg)' : 'rotate(-90deg)'} mt='3rem' transition='transform 300ms'>
            <XLink v='simple' color='#fff' onClick={() => setOpen(!open)}>
              <SVGarrow />
            </XLink>
          </Box>
        </Fade>
      </Container>
    </Flex>
  );
};

const Section4: FC<{ data: Section4Props }> = ({ data }) => {
  return (
    <Flex
      py='7rem'
      background={data.backgroundColor}
      flexDirection='column'
    >
      <Container
        flexDirection='column'
        alignItems='center'
        maxWidth='85rem'
        width='85%'
      >
        <Fade ssrReveal bottom duration={1000} distance={'5rem'}>
          <Img src={'/img/presenting/' + data.image} display='block' mx='auto' alt='' />

          <Heading2 {...Heading2Style} fontWeight={400} mt='3rem'>
            <div dangerouslySetInnerHTML={{ __html: data.header }} />
          </Heading2>

          <Typography {...TypographyStyle} mt='3rem'>
            <div dangerouslySetInnerHTML={{ __html: data.subtitle }} />
          </Typography>
        </Fade>

        <Fade ssrReveal bottom duration={1000} distance={'5rem'}>
          <Typography
            {...TypographyStyle}
            mt='2rem'
            alignItems='center'
            flexDirection={{ _: 'column', sm: 'row' }}
          >
            {/*<strong><FormattedMessage id='presenting.section4.ladder' /></strong>{' '}*/}

            <Flex as='span' justifyContent='center' mt='2rem'>
              <Typography {...TypographyStyle} fontSize='2rem' color='yellow'>
                <div dangerouslySetInnerHTML={{ __html: data.boxHeaderText }} />
              </Typography>
            </Flex>
          </Typography>
        </Fade>
      </Container>

      <Container flexDirection='column'>
        <Grid columns={{ _: 'minmax(15rem, 25rem)', sm: 'repeat(3, 1fr)' }} rows='auto' gap='3rem' mt='3rem' maxWidth={{ _: '85%', md: '80rem' }} mx='auto'>
          <Zoom ssrReveal delay={200}>
            <Typography
              {...TypographyStyle}
              marginX='1rem'
              py='1rem'
              px='2rem'
              border='1px solid #2222'
              borderRadius='1rem'
              bg='rgba(255, 255, 255, 1)'
              color='presentingBlack'
              mx='auto'
              height='100%'
            >
              <Img src={`/img/presenting/${data.box1.img}`} width={data.box1.width} height={data.box1.height} mb='1rem' alt='' />
              <div dangerouslySetInnerHTML={{ __html: data.box1.text }} />
            </Typography>
          </Zoom>

          <Zoom ssrReveal delay={300}>
            <Typography
              {...TypographyStyle}
              marginX='1rem'
              py='1rem'
              px='2rem'
              border='1px solid #2222'
              borderRadius='1rem'
              bg='rgba(255, 255, 255, 1)'
              color='presentingBlack'
              mx='auto'
              height='100%'
            >
              <Img src={`/img/presenting/${data.box2.img}`} width={data.box2.width} height={data.box2.height} mb='1rem' alt='' />
              <div dangerouslySetInnerHTML={{ __html: data.box2.text }} />
            </Typography>
          </Zoom>

          <Zoom ssrReveal delay={400}>
            <Typography
              {...TypographyStyle}
              marginX='1rem'
              py='1rem'
              px='2rem'
              border='1px solid #2222'
              borderRadius='1rem'
              bg='rgba(255, 255, 255, 1)'
              color='presentingBlack'
              mx='auto'
              height='100%'
            >
              <Img src={`/img/presenting/${data.box3.img}`} width={data.box3.width} height={data.box3.height} mb='1rem' alt='' />
              <div dangerouslySetInnerHTML={{ __html: data.box3.text }} />
            </Typography>
          </Zoom>
        </Grid>

        {data.boxBottomText && (
          <Typography {...TypographyStyle} mt='4rem'>
            <div dangerouslySetInnerHTML={{ __html: data.boxBottomText }} />
          </Typography>
        )}
      </Container>
    </Flex>
  );
};

const Section4a: FC<{ data: Section4aProps }> = ({ data }) => {
  return (
    <Flex
      py='7rem'
      background={data.backgroundColor}
    >
      <Container
        flexDirection='column'
        alignItems='center'
        maxWidth='85rem'
        width='85%'
      >
        <Flex flexDirection={{ _: 'column', sm: 'row' }} alignItems='center'>
          <Img
            src={'/img/presenting/' + data.image}
            display='block'
            mx='auto'
            mr={{ _: 'auto', sm: '3rem' }}
            mb={{ _: '3rem', sm: '0' }}
            flex='0 0 auto'
            alt=''
          />

          <Typography {...TypographyStyle} fontFamily='OliLight' color='#fff' textAlign='left'>
            <div dangerouslySetInnerHTML={{ __html: data.text }} />
          </Typography>
        </Flex>
      </Container>
    </Flex>
  );
};

const Section5: FC<{ data: Section5Props }> = ({ data }) => {
  return (
    <Flex
      py='7rem'
      background={data.backgroundColor}
    >
      <Container
        flexDirection='column'
        alignItems='center'
        maxWidth='85rem'
        width='85%'
      >
        <Heading2 {...Heading2Style} color='presentingBlack'>
          {data.header}
        </Heading2>

        <List mt='5rem' display='flex' flexDirection='column' alignItems={{ _: 'flex-start', md: 'center' }}>
          <Fade ssrReveal left duration={1000} distance={'5rem'}>
            <ListItem mb={0} _before={{ content: '"" !important' }}>
              <Flex alignItems='center'>
                {data.customIcon1 ? (
                  <Img
                    src={'/img/presenting/' + data.customIcon1}
                    maxWidth='2rem'
                    maxHeight='2rem'
                    mr='1.5rem'
                    alt=''
                  />
                ) : (
                  <Icon svg={<SVGGame1 />} mr='1.5rem' />
                )}

                <Typography {...TypographyStyle} textAlign={{ _: 'left', sm: 'center' }} color='presentingBlack'>
                  <div dangerouslySetInnerHTML={{ __html: data.text1 }} />
                </Typography>
              </Flex>
            </ListItem>

            <ListItem mt='2rem' mb={0} _before={{ content: '"" !important' }}>
              <Flex alignItems='center'>
                {data.customIcon2 ? (
                  <Img
                    src={'/img/presenting/' + data.customIcon2}
                    maxWidth='2rem'
                    maxHeight='2rem'
                    mr='1.5rem'
                    alt=''
                  />
                ) : (
                  <Icon svg={<SVGGame2 />} mr='1.5rem' />
                )}

                <Typography {...TypographyStyle} textAlign={{ _: 'left', sm: 'center' }} color='presentingBlack'>
                  <div dangerouslySetInnerHTML={{ __html: data.text2 }} />
                </Typography>
              </Flex>
            </ListItem>

            <ListItem mt='2rem' mb={0} _before={{ content: '"" !important' }}>
              <Flex>
                {data.customIcon3 ? (
                  <Img
                    src={'/img/presenting/' + data.customIcon3}
                    maxWidth='2rem'
                    maxHeight='2rem'
                    mr='1.5rem'
                    alt=''
                  />
                ) : (
                  <Icon svg={<SVGGame3 />} mr='1.5rem' />
                )}

                <Typography {...TypographyStyle} textAlign={{ _: 'left', sm: 'center' }} color='presentingBlack'>
                  <div dangerouslySetInnerHTML={{ __html: data.text3 }} />
                </Typography>
              </Flex>
            </ListItem>
          </Fade>
        </List>

        <Typography {...TypographyStyle} color='rgba(11, 12, 28, 0.7)' mt='6rem'>
          <div dangerouslySetInnerHTML={{ __html: data.downtext }} />
        </Typography>
      </Container>
    </Flex>
  );
};

const Section6: FC<{ data: Section6Props }> = ({ data }) => {
  return (
    <Flex
      py='7rem'
      background={data.backgroundColor}
    >
      <Container
        flexDirection='column'
        alignItems='center'
        maxWidth='85rem'
        width='85%'
      >
        <Box>
          <Img src={'/img/presenting/' + data.image} display='block' mx='auto' alt='' />

          <Heading2 {...Heading2Style} fontWeight={400} mt='3rem'>
            <div dangerouslySetInnerHTML={{ __html: data.header }} />
          </Heading2>

          <Typography {...TypographyStyle} color='#fff' mt='3rem'>
            <div dangerouslySetInnerHTML={{ __html: data.subtitle }} />
          </Typography>

          <Zoom ssrReveal>
            <div>
              <Img src={`/img/presenting/${data.image2Mobile}`} display={{ _: 'block', sm: 'none' }} mx='auto' mt='6rem' alt='' />

              <Img src={`/img/presenting/${data.image2}`} display={{ _: 'none', sm: 'block' }} mx='auto' mt='6rem' alt='' />
            </div>
          </Zoom>
        </Box>
      </Container>
    </Flex>
  );
};

const Section7: FC<{ data: Section7Props }> = ({ data }) => {
  return (
    <Flex
      py='7rem'
      background={data.backgroundColor}
    >
      <Container
        flexDirection='column'
        alignItems='center'
        maxWidth='85rem'
        width='85%'
      >
        <Box maxWidth='85rem' mx='auto' width='100%'>
          <Img src={`/img/presenting/${data.image}`} display='block' mx='auto' alt='' />

          <Heading2 {...Heading2Style} fontWeight={400} mt='3rem'>
            <div dangerouslySetInnerHTML={{ __html: data.header }} />
          </Heading2>

          <Flex
            alignItems='center'
            justifyContent='center'
            mt='6rem'
            flexDirection={{ _: 'column', md: 'row' }}
          >
            <Flex alignItems='center'>
              <Icon svg={<LadderIcon />} fill='#ffde71' mr='1.5rem' />

              <Typography {...TypographyStyle} color='yellow' fontWeight={700}>
                {data.link1}
              </Typography>
            </Flex>

            <Typography mx='3rem' color='rgba(255, 255, 255, 0.5)' fontWeight={500}>
              x
            </Typography>

            <Flex alignItems='center'>
              <Icon svg={<LadderIcon />} fill='#ffde71' mr='1.5rem' />

              <Typography {...TypographyStyle} color='yellow' fontWeight={700}>
                {data.link2}
              </Typography>
            </Flex>
          </Flex>

          <Typography {...TypographyStyle} mt='6rem' color='#fff'>
            {data.subtitle}
          </Typography>
        </Box>
      </Container>
    </Flex>
  );
};

const Section8: FC<{ data: Section8Props }> = ({ data }) => {
  return (
    <Flex
      py='7rem'
      background={BlueTheme.primary}
      flexDirection='column'
    >
      <Container
        flexDirection='column'
        alignItems='center'
        maxWidth='85rem'
        width='85%'
      >
        <Box maxWidth='85rem' textAlign='center'>
          <Zoom ssrReveal>
            <Heading2 {...Heading2Style} fontWeight={400}>
              <div dangerouslySetInnerHTML={{ __html: data.heading }} />
            </Heading2>
          </Zoom>
        </Box>
      </Container>

      <Container>
        <Box mt='3rem' width='100%'>
          <Carousel presentingV />
        </Box>
      </Container>

      <Container
        flexDirection='column'
        alignItems='center'
        maxWidth='85rem'
        width='85%'
      >
        <Box>
          <Box mt='3rem'>
            <XLink
              v='underline'
              color='#fff'
              borderColor='#fff'
            >
              <div dangerouslySetInnerHTML={{ __html: data.linkText }} />
            </XLink>
          </Box>
        </Box>
      </Container>
    </Flex>
  );
};

const Section9: FC<{ data: Section9Props }> = ({ data }) => {
  return (
    <Flex
      py='7rem'
      background={`url(/img/presenting/${data.backgroundImg})`}
      backgroundPosition='center'
      backgroundSize='cover'
      position='relative'
      {...{ style: { backgroundAttachment: 'fixed' } }}
    >
      {data.gradient && (
        <GradientStyle
          color1={data.gradient.color1}
          color2={data.gradient.color2}
          color3={data.gradient.color3}
          color4={data.gradient.color4}
          speed={data.gradient.speed}
        />
      )}
      <Container
        flexDirection='column'
        alignItems='center'
        position='relative'
        maxWidth='85rem'
        width='85%'
      >
        <Heading2 {...Heading2Style}>
          <div dangerouslySetInnerHTML={{ __html: data.heading }} />
        </Heading2>

        {/*
        <Typography {...TypographyStyle} mt='1.5rem' fontSize='2.4rem' lineHeight='2.9rem' color='textGreen'>
          <div dangerouslySetInnerHTML={{ __html: data.subheading }} />
        </Typography>
        */}

        <Img
          src={'/img/presenting/chyse_text_hra_mobil.png'}
          width='25rem'
          alt=''
          mt='1rem'
          display={{ _: 'flex', sm: 'none' }}
        />

        <Img
          src={'/img/presenting/chyse_text_hra_desktop.png'}
          width='70rem'
          alt=''
          mt='1rem'
          display={{ _: 'none', sm: 'flex' }}
        />


        <Typography
          {...TypographyStyle}
          mt='3rem'
          position='relative'
          zIndex={1}
        >
          <div dangerouslySetInnerHTML={{ __html: data.text }} />
        </Typography>

        <Flex alignItems='center' justifyContent='center' mt='3rem' position='relative'>
          <Img
            src='/img/game/frame.png'
            alt=''
            position='relative'
            zIndex={1}
            width={{ _: '280px', sm: '300px' } as any}
          />
          <Img
            position='absolute'
            width={{ _: '120%', sm: '150%' } as any}
            maxWidth='none'
            src='/img/game/game-shadow.png'
            alt=''
          />
        </Flex>

        <SecondaryButton
          mt='3rem'
          borderColor='textGreen'
          {...SecondaryButtonStyle}
          position='relative'
          zIndex={1}
        >
          <div dangerouslySetInnerHTML={{ __html: data.button.text }} />
        </SecondaryButton>
      </Container>
    </Flex>
  );
};

export const Section10: FC<{ data: Section10Props; fluid?: boolean }> = ({ data, fluid }) => {
  type controlsProps = {
    circles: boolean;
    table: boolean;
  };

  const [controls, setControls] = useState<controlsProps>({
    circles: false,
    table: false
  });
  
  return (
    <Flex
      py='7rem'
      background={`url(/img/presenting/${data.backgroundImage}), ${data.backgroundColor}`}
      backgroundPosition='center'
      backgroundSize='cover'
      position='relative'
      flexDirection='column'
    >
      {data.gradient && (
        <GradientStyle
          color1={data.gradient.color1}
          color2={data.gradient.color2}
          color3={data.gradient.color3}
          color4={data.gradient.color4}
          speed={data.gradient.speed}
        />
      )}
      <Container
        flexDirection='column'
        alignItems='center'
        maxWidth='85rem'
        width='85%'
        position='relative'
        zIndex={1}
        {...fluid && { width: '100%' }}
      >
        <Flex justifyContent='center' >
          <PremiumButton px='3rem' />
        </Flex>

        <Heading2 {...Heading2Style} mt='3rem'>
          <div dangerouslySetInnerHTML={{ __html: data.heading }} />
        </Heading2>

        <Typography {...TypographyStyle} mt='1.5rem'>
          <div dangerouslySetInnerHTML={{ __html: data.text }} />
        </Typography>

        <Typography
          mt={{ _: '4rem', sm: '6rem' }}
          fontSize={{ _: '1.8rem', sm: '2rem' }}
          lineHeight={{ _: '2.2rem', sm: '2.6rem' }}
          onClick={() => setControls({ ...controls, circles: true })}
          cursor='pointer'
        >
          <div dangerouslySetInnerHTML={{ __html: data.plusHeading }} />
        </Typography>
      </Container>

      <Container position='relative' justifyContent='center' zIndex={1} {...fluid && { width: '100%' }}>
        <Accordion expanded={controls.circles}>
          <Flex flexWrap='wrap' justifyContent='center'>
            {data.list.map((i, k) => {
              const delay = 200*k;
              return (
                <Flex flexDirection='column' key={k} alignItems='center' mx='1rem' width={{ _: '14rem', sm: '16rem' }} mt='4rem'>
                  <Fade delay={delay} key={k}>
                    <Flex
                      position='relative'
                      width={{ _: '10rem', sm: '12rem' }}
                      height={{ _: '10rem', sm: '12rem' }}
                      display='flex'
                      mx='auto'
                      alignItems='center'
                      justifyContent='center'
                      borderRadius='50%'
                      border='2px solid rgba(255, 255, 255, 0.4)'
                      backgroundColor='rgba(11, 12, 28, 0.1)'
                      overflow='hidden'
                    >
                      <Img
                        src={`/img/presenting/${i[1]}`}
                        width={{ _: '6rem', sm: '8rem' } as any}
                        height={{ _: '6rem', sm: '8rem' } as any}
                        alt={i[0]}
                      />

                      <GradientStyle
                        color1='rgba(255, 255, 255, 0.05)'
                        color2='rgba(255, 255, 255, 0.25)'
                        color3='rgba(255, 255, 255, 0.25)'
                        color4='rgba(255, 255, 255, 0.05)'
                        speed='8s'
                        rotation={10*k + 'deg'}
                        delay={1000*k + 'ms'}
                      />
                    </Flex>
                  </Fade>

                  <Fade delay={delay + 50}>
                    <Typography {...TypographyStyle} fontSize='1.5rem' lineHeight='1.8rem' mt='2rem'>
                      <div dangerouslySetInnerHTML={{ __html: i[0] }} />
                    </Typography>
                  </Fade>
                </Flex>
              );
            })}
          </Flex>
        </Accordion>
      </Container>

      <Container
        flexDirection='column'
        alignItems='center'
        maxWidth='85rem'
        position='relative'
        zIndex={1}
        {...fluid && { width: '100%' }}
      >
        {/* <Typography {...TypographyStyle} mt='4rem'>
          <div dangerouslySetInnerHTML={{ __html: data.plusOther }} />
        </Typography> */}

        <SecondaryButton mt='6rem' {...SecondaryButtonStyle} onClick={() => setControls({ ...controls, table: true })}>
          <div dangerouslySetInnerHTML={{ __html: data.button.text }} />
        </SecondaryButton>

        <Accordion expanded={controls.table}>
          <Table transparentBackground />
        </Accordion>
      </Container>
    </Flex>
  );
};

//? Kdo ví kam to vede a jestli se vůbec používá
//? Pokud bude potřeba tak taky přehodit do lokalizace, ale teď CBA
export const Section11: FC<{ data: Section11Props }> = ({ data }) => {
  return (
    <Flex
      py='7rem'
      bg={data.backgroundColor}
      flexDirection='column'
    >
      <Container
        flexDirection='column'
        alignItems='center'
        maxWidth='85rem'
        width='85%'
      >
        <Heading2 {...Heading2Style} color='presentingBlack'>
          <div dangerouslySetInnerHTML={{ __html: data.heading }} />
        </Heading2>

        <Typography {...TypographyStyle} mt='1.5rem' color='rgba(11, 12, 28, 0.7)'>
          <div dangerouslySetInnerHTML={{ __html: data.subheading }} />
        </Typography>

      </Container>

      <Grid
        columns={{ _: 'repeat(1, 1fr)', sm: 'repeat(3, 1fr)' }}
        rows='auto'
        gap='4rem'
        maxWidth='107rem'
        mx='auto'
        mt='6rem'
      >
        <Flex
          flexDirection='column'
          p='3rem'
          borderRadius='1.2rem'
          bg={`radial-gradient(circle, ${PlusTheme.primary} 0%, ${PlusTheme.darken} 100%)`}
          alignItems='center'
        >
          <Flex height='100%' flexDirection='column'>
            <Heading2>Eticia Plus<br />na 1 rok</Heading2>

            <Typography mt='1rem'>
              <strong>plný přístup do Eticia 21</strong><br />
              obsahující všechny funkcionality
            </Typography>

            <Typography color='rgba(255, 255, 255, 0.7)' mt='2rem'>
              + 1 žebříček<br />
              + 1x životní hodnoty s kompletním vyhodnocením
            </Typography>
          </Flex>

          <Paragraph fontSize='2.4rem' lineHeight='3.2rem' color='yellow' mt='1rem'>
              121,- Kč
          </Paragraph>

          <Link to={createUrl('shop') + '/koupit/voucher-1rok'}>
            <PrimaryButton flexDirection='column' mt='2rem'>
              <Paragraph fontSize='2rem' lineHeight='2.6rem' color='#000'>Objednat</Paragraph>
              <Paragraph fontSize={{ _: '1.3rem', sm: '1.4rem' }} lineHeight='1.7rem' color='#000'></Paragraph>
            </PrimaryButton>
          </Link>

          {/*
          <XLink variant='underline' mt='1rem' lineHeight='2rem'>
            Více informací
          </XLink>
          */}
        </Flex>

        <Flex
          flexDirection='column'
          p='3rem'
          borderRadius='1.2rem'
          bg={`radial-gradient(circle, ${PlusTheme.primary} 0%, ${PlusTheme.darken} 100%)`}
          alignItems='center'
        >
          <Flex height='100%' flexDirection='column'>
            <Heading2>Eticia Plus<br />na 3 roky</Heading2>

            <Typography mt='1rem'>
              <strong>plný přístup do Eticia 21</strong><br />
              obsahující všechny funkcionality
            </Typography>

            <Typography color='rgba(255, 255, 255, 0.7)' mt='2rem'>
              + 3 žebříčky<br />
              + 3x životní hodnoty s kompletním vyhodnocením
            </Typography>
          </Flex>

          <Paragraph fontSize='2.4rem' lineHeight='3.2rem' color='yellow' mt='1rem'>
            249,- Kč
          </Paragraph>

          <Link to={createUrl('shop') + '/koupit/voucher-3roky'}>
            <PrimaryButton flexDirection='column' mt='2rem'>
              <Paragraph fontSize='2rem' lineHeight='2.6rem' color='#000'>Objednat</Paragraph>
              <Paragraph fontSize={{ _: '1.3rem', sm: '1.4rem' }} lineHeight='1.7rem' color='#000'></Paragraph>
            </PrimaryButton>
          </Link>

          {/*
          <XLink variant='underline' mt='1rem' lineHeight='2rem'>
            Více informací
          </XLink>
          */}
        </Flex>

        <Flex
          flexDirection='column'
          p='3rem'
          borderRadius='1.2rem'
          bg={`radial-gradient(circle, ${PlusTheme.primary} 0%, ${PlusTheme.darken} 100%)`}
          alignItems='center'
        >
          <Flex height='100%' flexDirection='column'>
            <Heading2>Eticia Plus<br />navždy</Heading2>

            <Typography mt='1rem'>
              <strong>Výjimečné členství pro ty,</strong>kteří si chtějí užívat Eticia naplno
            </Typography>

            <Typography color='rgba(255, 255, 255, 0.7)' mt='2rem'>
              + 6 žebříčků<br />
              + 1x Strom společných hodnot<br />
              + navždy životní hodnoty s kompletním vyhodnocením
            </Typography>
          </Flex>

          <Paragraph fontSize='2.4rem' lineHeight='3.2rem' color='yellow' mt='1rem'>
              777,- Kč
          </Paragraph>

          <Link to={createUrl('shop') + '/koupit/voucher-navzdy'}>
            <PrimaryButton flexDirection='column' mt='2rem'>
              <Paragraph fontSize='2rem' lineHeight='2.6rem' color='#000'>Objednat</Paragraph>
              <Paragraph fontSize={{ _: '1.3rem', sm: '1.4rem' }} lineHeight='1.7rem' color='#000'></Paragraph>
            </PrimaryButton>
          </Link>

          {/*
          <XLink variant='underline' mt='1rem' lineHeight='2rem'>
            Více informací
          </XLink>
          */}
        </Flex>
      </Grid>
      <Container
        flexDirection='column'
        alignItems='center'
        maxWidth='85rem'
        width='85%'
      >

        <SecondaryButton mt='3rem' color='presentingBlack'>
          {data.button.text}
        </SecondaryButton>

        <Typography {...TypographyStyle} mt='3rem' color='rgba(11, 12, 28, 0.5)'>
          <div dangerouslySetInnerHTML={{ __html: data.info }} />
        </Typography>
      </Container>
    </Flex>
  );
};

const Section12: FC<{ data: Section12Props }> = ({ data }) => {
  return (
    <Flex
      py='7rem'
      backgroundColor='#0a1119'
      backgroundImage='url(/img/homepage/planetCrop.jpg)'
      backgroundPosition='center'
      backgroundSize={{ _: 'contain', sm: 'auto' }}
      backgroundRepeat='no-repeat'
    >
      <Container
        flexDirection='column'
        alignItems='center'
        maxWidth='85rem'
        width='85%'
      >
        <Flex alignItems='center'>
          <Img
            src={'/img/presenting/ikona_vlajka.png'}
            alt=''
            mr='1rem'
            flex='0 0 auto'
          />

          <Heading2 {...Heading2Style} mt='0.5rem'>
            <div dangerouslySetInnerHTML={{ __html: data.heading }} />
          </Heading2>
        </Flex>

        <Typography  {...TypographyStyle} mt='3rem'>
          <div dangerouslySetInnerHTML={{ __html: data.text2 }} />
        </Typography>

        <Typography {...TypographyStyle} mt='3rem' color='rgba(255, 255, 255, 0.7)'>
          <div dangerouslySetInnerHTML={{ __html: data.text }} />
        </Typography>

        <Img src='/img/institut-white@2x.png' alt='Institut Eticia' width='19rem' mt='4rem' />

        <Link to={createUrl('about')}>
          <SecondaryButton {...SecondaryButtonStyle} mt='4rem'>
            {data.btn1}
          </SecondaryButton>
        </Link>

        <Img
          src='/img/eticiam.png'
          srcSet='/img/eticiam.png, /img/eticiam@2x.png 2x'
          alt='[etic I am]'
          mt='4rem'
        />
      </Container>
    </Flex>
  );
};

const Section13: FC<{ data: Section13Props }> = ({ data }) => {
  return (
    <Flex
      pt={{ _: '2rem', sm: '2rem' }}
      pb={{ _: '1rem', sm: '3rem' }}
      background={data.backgroundColor}
    >
      <Container
        flexDirection='column'
        alignItems='center'
        maxWidth='85rem'
        width='85%'
      >
        <Heading2
          {...Heading2Style}
          fontSize={{ _: '1.8rem', sm: '3.2rem' }}
          lineHeight={{ _: '2.6rem', sm: '4.1rem' }}
        >
          <div dangerouslySetInnerHTML={{ __html: data.heading }} />
        </Heading2>

        <Typography {...TypographyStyle} mt={{ _: '1rem', sm: '3rem' }} fontSize={{ _: '1.4rem', sm: '1.5rem' }}>
          <div dangerouslySetInnerHTML={{ __html: data.text }} />
        </Typography>

        <Link to={createUrl('root')}>
          <XLink v='underline' mt='3rem' color='#fff'>
            {data.linkText}
          </XLink>
        </Link>

        <Typography {...TypographyStyle}  fontSize={{ _: '1.3rem', sm: '1.4rem' }} lineHeight='1.8rem' mt={{ _: '3rem', sm: '8rem' }}>
          &copy; 2017 - {(new Date).getFullYear()} <strong>Eticia 21</strong>
        </Typography>
      </Container>
    </Flex>
  );
};

type _ButtonProps = ButtonProps | CarouselButtonProps;
type PresentingButtonProps = {
  _button: _ButtonProps;
  locale?: 'cs' | 'en';
};
const isOverriddenType = (obj: _ButtonProps): obj is CarouselButtonProps => 'text' in obj && typeof obj.text === 'object';

export const PresentingButton: FC<PresentingButtonProps> = ({ _button, locale }) => {
  const text: string = isOverriddenType(_button) ? locale ? _button.text[locale] : 'chybí locale' : _button.text;
  
  return (
    <Button
      mt='1rem'
      py='1rem'
      px='3rem'
      fontWeight={400}
      {..._button.background && { bg: _button.background }}
      {..._button.icon && { leftElement: <Img src={`/img/presenting/${_button.icon}`} maxWidth='2.4rem' maxHeight='2.4rem' mr='0.8rem' alt='' /> }}
      {..._button.href && { href: `${_button.href}${_button.redirectAfterRegistration ? '&redirect=1' : ''}`, as: 'a' }}
      // eslint-disable-next-line no-extra-parens
      {...typeof _button.text === 'object' && (_button as CarouselButtonProps).border && { border: (_button as CarouselButtonProps).border }}
    >
      <div style={{ marginTop: '4px' }} dangerouslySetInnerHTML={{ __html: text }} />
    </Button>
  );
};

export const PresentingButtonNoLink: FC<PresentingButtonProps> = ({ _button, locale }) => {
  const text: string = isOverriddenType(_button) ? locale ? _button.text[locale] : 'chybí locale' : _button.text;
  
  return (
    <Button
      mt='1rem'
      py='1rem'
      px='3rem'
      fontWeight={400}
      {..._button.background && { bg: _button.background }}
      {..._button.icon && { leftElement: <Img src={`/img/presenting/${_button.icon}`} maxWidth='2.4rem' maxHeight='2.4rem' mr='0.8rem' alt='' /> }}
    >
      <div style={{ marginTop: '4px' }} dangerouslySetInnerHTML={{ __html: text }} />
    </Button>
  );
};

export const PresentingLink: FC<{ _link: LinkProps }> = ({ _link }) => {
  return (
    <XLink
      mt='1rem'
      fontWeight={400}
      v='underline'
      href={_link.link}
      target={_link.newWindow ? '_blank' : '_self'}
    >
      <div dangerouslySetInnerHTML={{ __html: _link.text }} />
    </XLink>
  );
};

const TypographyStyle = {
  fontSize: { _: '1.4rem', sm: '1.5rem' },
  lineHeight: { _: '2rem', sm: '2.2rem' },
  fontFamily: OliLight
};

const Heading2Style = {
  mt: 0,
  fontSize: { _: '2rem', sm: '2.6rem' },
  letterSpacing: 0,
  lineHeight: { _: '2.6rem', sm: '3.2rem' },
  fontWeight: 400,
  fontFamily: OliLight
};

const SecondaryButtonStyle = {
  fontSize: { _: '1.5rem', sm: '1.8rem' },
  lineHeight: '2.3rem'
};


export const GradientStyle = styled.div<{
  rotation?: string;
  color1: string;
  color2: string;
  color3: string;
  color4: string;
  speed?: string;
  count?: string;
  delay?: string;
}>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(${props => props.rotation ? props.rotation : '-45deg'}, ${props => props.color1}, ${props => props.color2}, ${props => props.color3}, ${props => props.color4});
  background-size: 400% 400%;
  animation: gradient ${props => props.speed ? props.speed : '5s'} ease ${props => props.count ? props.count : 'infinite'};
  animation-delay: ${props => props.delay};
  border-radius: inherit;

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

const ScaleInsideDiv = styled.div<{thinner?: boolean}>`
  & > * {
    transform: scale(0.9);
  }

  ${({ thinner }) => thinner && css`
    margin-top: 2rem;
    transform: scale(0.8);

    ${mediaSmDefault} {
      margin-top: 0;
    }
  `}
`;