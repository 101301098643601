import { Box, Flex, FlexProps, Img, Tag, Typography } from '@xcorejs/ui';
import { GameContext } from 'components/App';
import QuestionCircle from 'components/atoms/QuestionCircle';
import { SecondaryButton } from 'components/components/Button';
import { createUrl } from 'components/pages/routes';
import { peaGreen, PlusTheme } from 'components/theme/colors';
import React, { FC, Fragment, ReactNode, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { IDemoUserInfo, IUserInfo } from 'store/User';
import { useFetchUserProfile, useFetchUserProfileDetail } from 'utils/useQuery/queryFetchHelpers';

export interface CircleGroupProps extends FlexProps {
  colorScheme?: 'green' | 'gray' | 'blue' | 'violet';
  circleSize?: 'sm' | 'large';
  img?: string | null;
  name?: string | ReactNode;
  mostCommon?: boolean;
  leastCommon?: boolean;
  onlyPlusOrGame?: boolean;
  onlyInterestGroup?: boolean;
  onlyDemo?: boolean;
  minGameLevelToSee?: number;
}

const CircleGroup: FC<CircleGroupProps> = (
  {
    name,
    colorScheme,
    circleSize= 'sm',
    img,
    mostCommon,
    leastCommon,
    onlyPlusOrGame,
    onlyInterestGroup,
    onlyDemo,
    minGameLevelToSee,
    ...props
  }
) => {
  const { eticiaPlus, userLvl } = useContext(GameContext);

  const visiblePlusOrGame = onlyPlusOrGame ? eticiaPlus || userLvl > (minGameLevelToSee || 0) : true;
  const [visibleInterestGroup, setVisibleInterestGroup] = useState(false);
  const [visibleDemo, setVisibleDemo] = useState(false);

  const profileQuery = useFetchUserProfile();
  const profileDetailQuery = useFetchUserProfileDetail();


  useEffect(() => {
    // eslint-disable-next-line no-extra-parens
    if (profileQuery.isSuccess) {
      const info = profileQuery.data as IUserInfo;
      info.groups && setVisibleInterestGroup(info.groups.length > 0);
    }

    setVisibleInterestGroup(true); //! WIP - zrušily se prozatím zájmové skupiny
  }, [profileQuery.data, profileQuery.isSuccess]);

  useEffect(() => {
    // eslint-disable-next-line no-extra-parens
    if (profileDetailQuery.isSuccess) {
      const info = profileDetailQuery.data as IDemoUserInfo;
      info.maritalStatus && setVisibleDemo(true);
    }
  }, [profileDetailQuery.data, profileDetailQuery.isSuccess]);

  return (
    <Flex flexDirection='column' alignItems='center' {...props}>
      {mostCommon && (
        <Fragment>
          <Typography
            fontWeight={700}
            fontSize='1.4rem'
            lineHeight='1.8rem'
          >
            Nejpodobnější
          </Typography>
          <Typography
            mt='0.5rem'
            mb='1.5rem'
            maxWidth='17rem'
            mx='auto'
            fontSize='1.3rem'
            lineHeight='1.6rem'
            color='rgba(255, 255, 255, 0.5)'
            minHeight='3.2rem'
          >
            Nejvíce společných
            hodnot
          </Typography>
        </Fragment>
      )}
      {leastCommon && (
        <Fragment>
          <Typography
            fontWeight={700}
            fontSize='1.4rem'
            lineHeight='1.8rem'
          >
            Nejméně podobné
          </Typography>
          <Typography
            mt='0.5rem'
            mb='1.5rem'
            maxWidth='17rem'
            mx='auto'
            fontSize='1.3rem'
            lineHeight='1.6rem'
            color='rgba(255, 255, 255, 0.5)'
            minHeight='3.2rem'
          >
            Nejméně společných
            hodnot
          </Typography>
        </Fragment>
      )}

      {(visiblePlusOrGame && visibleInterestGroup && !onlyDemo || onlyDemo && visibleDemo) && (
        <>
          <Box
            border={colorScheme === 'green' ? '2px solid rgba(0, 153, 102, 1)' :
              colorScheme === 'blue' ? '2px solid rgba(36, 123, 203, 1)' :
                colorScheme === 'violet' ? '2px solid rgba(193, 66, 167, 1)' : '2px solid rgba(98, 98, 98, 1)'}
            bg={colorScheme === 'green' ? 'rgba(8,71,50,1) radial-gradient(circle, rgba(255, 255, 255, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%)' :
              colorScheme === 'blue' ? 'rgba(9, 83, 152, 1) radial-gradient(circle, rgba(255,255,255,0.5) 0%, rgba(0,0,0,0.5) 100%)' :
                colorScheme === 'violet' ? 'radial-gradient(circle, rgba(69, 8, 71, 1) 0%, rgba(0,0,0,0.5) 100%)' :
                  'rgba(11, 12, 28, 1) radial-gradient(circle, rgba(255,255,255,0.5) 0%, rgba(0,0,0,0.5) 100%)'}
            width={circleSize === 'sm' ? '8.5rem' : '10rem'}
            height={circleSize === 'sm' ? '8.5rem' : '10rem'}
            borderRadius='50%'
            overflow='hidden'
          >
            {img && (
              <Img src={img} alt='' />
            )}
          </Box>

          {name && (
            <Typography
              mt='1rem'
              fontWeight={700}
              fontSize='1.4rem'
              lineHeight='1.8rem'
            >
              {name}
            </Typography>
          )}
        </>
      )}

      {!visibleInterestGroup && visiblePlusOrGame && !onlyDemo && (
        <Flex
          background='rgba(11,12,28,0.5)'
          width='17rem'
          height='14.4rem'
          alignItems='center'
          justifyContent='center'
          flexDirection='column'
          borderRadius='0.5rem'
        >
          <Typography
            fontSize='1.3rem'
            lineHeight='1.8rem'
            color='rgba(255, 255, 255, 0.9)'
            maxWidth='15rem'
            mx='auto'
          >
            Abys mohl vidět některé ze statistik, doplň si drobné informace.
          </Typography>

          <Link to={createUrl('app.profile') + '/zajmove-skupiny'}>
            <SecondaryButton as='a' borderColor='rgba(214, 40, 120, 1)' size='xs' mt='2rem'>
              Doplnit zájmy
            </SecondaryButton>
          </Link>
        </Flex>
      )}

      {!visibleDemo && onlyDemo && (
        <Flex
          background='rgba(11,12,28,0.5)'
          width='17rem'
          height='14.4rem'
          alignItems='center'
          justifyContent='center'
          flexDirection='column'
          borderRadius='0.5rem'
        >
          <Typography
            fontSize='1.3rem'
            lineHeight='1.8rem'
            color='rgba(255, 255, 255, 0.9)'
            maxWidth='15rem'
            mx='auto'
          >
            Abys mohl vidět některé ze statistik, doplň si drobné informace.
          </Typography>

          <Link to={createUrl('app.profile') + '/profile-info'}>
            <SecondaryButton as='a' borderColor={peaGreen} size='xs' mt='2rem'>
              Doplnit demoúdaje
            </SecondaryButton>
          </Link>
        </Flex>
      )}

      {!visiblePlusOrGame && (
        <Flex flexDirection='column' position='relative' alignItems='center'>
          <QuestionCircle circleSize={circleSize} />
          <Tag
            position='relative'
            top='-1.1rem'
            display='inline-flex'
            bg={PlusTheme.primary}
            borderRadius='0.5rem'
            lineHeight='1.4'
          >
            Plus
          </Tag>
          <Typography color={peaGreen} fontSize='1.4rem' lineHeight='1.8rem' mt='-0.5rem'>
            nebo Hodnotová hra
          </Typography>
        </Flex>
      )}
    </Flex>
  );
};

export default CircleGroup;
