import * as React from 'react';
import { StyledValueDefinitionQuote } from '../styles';

interface IValueDefinitionCard {
  color: string;
  label: string;
  name: string;
  job: string;
  year: string;
  text: string;
}

export const ValueDefinitionQuote: React.FC<IValueDefinitionCard> = ({
  color,
  label,
  name,
  job,
  year,
  text,
  children,
}) => {
  return (
    <StyledValueDefinitionQuote data-color={color}>
      <span className='label'>{label}</span>

      <div>
        <p>{text}</p>
        <strong>{name}</strong>
        <span>
          {job}
          {job && year && ','}
          {year}
        </span>
      </div>

      {children}
    </StyledValueDefinitionQuote>
  );
};

export default ValueDefinitionQuote;
