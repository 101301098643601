import * as React from 'react';
import { Row, Col, Grid } from 'react-styled-flexboxgrid';
import { StyledTextArea } from '../../../atoms/FormItems/styles';
import { PrimaryButtonButton } from '../../../atoms/Buttons';
import { FormattedMessage } from 'react-intl';
import wrapIntl, { IWrapIntl } from '../../../atoms/wrapIntl';
import { _switch } from '../../../../utils/option';
import LoadingSpinnerLocal from '../../../atoms/Animations/LoadingSpinnerLocal';
import { ILifeValueDetail } from '../../../../store/Entities/LifeValues';
import { sendDefinition } from '../actions';
import { getDefinitions } from '../index';
import { CustomText } from '../../../atoms/Text/index';

interface IDef {
  inTest: boolean;
  detail: ILifeValueDetail;
  onSubmit: (id: string, type: string, text?: string) => any;
  shouldShowThank: boolean;
}

interface IDefState {
  step: number;
  text: string;
}

class Def extends React.Component<IDef & IWrapIntl, IDefState> {
  public state: IDefState = {
    step: 0,
    text: getDefinitions(this.props.detail).length > 0 ? getDefinitions(this.props.detail)[0].content : '',
  };

  public render() {
    const { detail } = this.props;
    return detail.definitions.length === 0 ? (
      _switch({
        0: this.form(false),
        1: <LoadingSpinnerLocal />,
        2: (
          <div>
            <CustomText
              style={{
                color: '#191D2C',
                width: 'calc(100% - 3rem)',
                margin: '0 auto',
                textAlign: 'center',
              }}
            >
              <FormattedMessage id={'valueDefinition.def.thank'} />
            </CustomText>
          </div>
        ),
      })()(this.state.step)
    ) : this.props.shouldShowThank ? (
      <div>
        <CustomText
          style={{
            color: '#191D2C',
            width: 'calc(100% - 3rem)',
            margin: '0 auto',
            textAlign: 'center',
          }}
        >
          <FormattedMessage id={'valueDefinition.def.thank'} />
        </CustomText>
      </div>
    ) : (
      this.form(false, this.state.text)
    );
  }

  private submit = (e: React.FormEvent<any>) => {
    e.preventDefault();
    this.setState({ step: 1 });
    sendDefinition(this.props.detail.id, this.state.text)
      .then(() => this.setState({ step: 2 }))
      .then(() => this.props.onSubmit(this.props.detail.id, 'def', this.state.text));
  };

  private form = (disabled: boolean, value?: string) => (
    <form onSubmit={this.submit} className={'def-form'}>
      <Grid>
        <Row>
          <Col xs={this.props.inTest ? 24 : 12}>
            <StyledTextArea
              name={'textNote'}
              required
              disabled={disabled}
              value={value}
              onChange={(e: any) => this.setState({ text: e.target.value })}
              placeholder={this.props.formatMsg('valueDefinition.form.placeholder')}
            />
          </Col>
        </Row>
        {!disabled && (
          <PrimaryButtonButton>
            <FormattedMessage id={'valueDefinition.form.add'} />
          </PrimaryButtonButton>
        )}
      </Grid>
    </form>
  );
}

export default wrapIntl(Def);
