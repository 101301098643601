import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Heading1 } from '../../../../atoms/Headings';
import { StyledValueComparisonSection, StyledVoucherError } from '../Comparisons/styles';
import SVGcamel from '../../../../atoms/SVGIcons/SVGcamel';
import { SmallerText } from '../../../../organisms/VisualValuesComparison/style';
import { PrimaryButtonLink } from '../../../../atoms/Buttons';
import { Col, Row, Grid } from 'react-styled-flexboxgrid';
import { createUrl } from '../../../routes';
import { withRouter, RouteComponentProps } from 'react-router';
import { parseQueryParams } from 'utils/helpers';

interface IOrderError {
  voucher?: boolean;
  gift?: boolean;
}

const OrderError: React.FC<IOrderError & RouteComponentProps<{}>> = ({ voucher, location, gift }) => {
  const error = parseQueryParams(location.search).alreadyPaid !== undefined ? 'alreadyPaid' : 'notPaid';
  const baseUrl = createUrl('app.comparison');

  return (
    <StyledVoucherError>
      <Grid>
        <Row>
          <StyledValueComparisonSection>
            <Col xs={12}>
              {/* Is this voucher error page */}
              {gift ? (
                <>
                  <Heading1>
                    <FormattedMessage id={'voucher.error'} />
                  </Heading1>

                  <SVGcamel />

                  {error === 'notPaid' && (
                    <>
                      <SmallerText>
                        <FormattedMessage id={'voucher.error.text'} />
                      </SmallerText>
                      <PrimaryButtonLink to={baseUrl + '/darkovy-balicek'}>
                        <FormattedMessage id={'voucher.error.tryAgain'} />
                      </PrimaryButtonLink>
                    </>
                  )}
                  {error === 'alreadyPaid' && (
                    <>
                      <SmallerText>
                        <FormattedMessage id={'voucher.error.alreadyPaid.text'} />
                      </SmallerText>
                      <PrimaryButtonLink to={baseUrl + '/darkovy-balicek'}>
                        <FormattedMessage id={'voucher.error.back'} />
                      </PrimaryButtonLink>
                    </>
                  )}
                </>
              ) : (
                <>
                  {voucher ? (
                    <>
                      <Heading1>
                        <FormattedMessage id={'voucher.error'} />
                      </Heading1>

                      <SVGcamel />

                      {error === 'notPaid' && (
                        <>
                          <SmallerText>
                            <FormattedMessage id={'voucher.error.text'} />
                          </SmallerText>
                          <PrimaryButtonLink to={baseUrl + '/koupit-voucher'}>
                            <FormattedMessage id={'voucher.error.tryAgain'} />
                          </PrimaryButtonLink>
                        </>
                      )}
                      {error === 'alreadyPaid' && (
                        <>
                          <SmallerText>
                            <FormattedMessage id={'voucher.error.alreadyPaid.text'} />
                          </SmallerText>
                          <PrimaryButtonLink to={baseUrl + '/koupit-voucher'}>
                            <FormattedMessage id={'voucher.error.back'} />
                          </PrimaryButtonLink>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <Heading1>
                        <FormattedMessage id={'voucher.error'} />
                      </Heading1>

                      <SVGcamel />
                      {error === 'notPaid' && (
                        <>
                          <SmallerText>
                            <FormattedMessage id={'voucher.error.text'} />
                          </SmallerText>

                          <PrimaryButtonLink to={baseUrl + '/koupit-porovnani'}>
                            <FormattedMessage id={'voucher.error.tryAgain'} />
                          </PrimaryButtonLink>
                        </>
                      )}
                      {error === 'alreadyPaid' && (
                        <>
                          <SmallerText>
                            <FormattedMessage id={'voucher.error.alreadyPaid.text'} />
                          </SmallerText>
                          <PrimaryButtonLink to={baseUrl + '/koupit-porovnani'}>
                            <FormattedMessage id={'voucher.error.back'} />
                          </PrimaryButtonLink>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </Col>
          </StyledValueComparisonSection>
        </Row>
      </Grid>
    </StyledVoucherError>
  );
};

export default withRouter(OrderError);
