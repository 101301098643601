import styled from 'styled-components';
import { Box } from '@xcorejs/ui';
import { BlueTheme, yellow } from 'components/theme/colors';
import { OliRegular } from 'components/theme/fonts';

export const SelectStyle = styled(Box)`
  .Select.is-focused > .Select-control,
  .Select-control {
    text-align: center;
    padding: 1.1rem 2rem 0.7rem;
    margin-top: 1rem;
    background: rgba(12, 14, 15, 0.4);
  }
  
  .Select-placeholder {
    color: ${yellow};
    font-size: 1.8rem;
    line-height: 2.5rem;
    font-family: ${OliRegular};
  }
  
  .Select-menu-outer {
    padding: 1.5rem 3rem;
  }
  
  .Select-option {
    font-size: 1.5rem;
    line-height: 3rem;
    padding: 0;
    font-weight: 400;
  }
  
  .Select-arrow {
    border-color: rgba(255, 255, 255, 0.9);
  }

  .Select-input,
  .Select-control .Select-input:focus {
    background: transparent; // čára uprostřed input okna
  }
`;

export const HigherZIndex = styled.div`
  .react-reveal {
    position: relative;
    z-index: 9999;
  }
`;

export const StyledFade = styled.div<{delay?: number}>`
  opacity: 0;
  -webkit-animation: fade-in-block 1000ms ease-in-out ${({ delay }) => delay || '0'}ms forwards;
  animation: fade-in-block 1000ms ease-in-out ${({ delay }) => delay || '0'}ms forwards;

  @-webkit-keyframes fade-in-block {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fade-in-block {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;