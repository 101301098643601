import React from 'react';
import { Icon } from '@xcorejs/ui';
import { CompareIcon } from '../../../icons/16/compare.svg';
import { BlueTheme, PinkTheme, MidnightTheme, GreenTheme, VioletTheme, KidsTheme, GrayTheme, EmeraldTheme, yellow } from 'components/theme/colors';
import { compareMatrixData } from 'components/atoms/CompareMatrixData/data';

type StringJSXUnion = string | JSX.Element;

export type ComparisonInsideDataProps = {
  image: string;
  name: StringJSXUnion;
  id?: string | undefined;
  noPlus?: boolean;
  noButton?: boolean;
}

export type SingleComparisonDataProps = {
  id: string;
  i18nId: string;
  title: string;
  mainImage: string;
  comparison: {
    left?: ComparisonInsideDataProps;
    right?: ComparisonInsideDataProps;
    middle?: ComparisonInsideDataProps;
  };
  steps: StringJSXUnion[];
  backgroundColor: string;
};

export type ComparisonDataProps = {
  personal: SingleComparisonDataProps[];
  multiple: SingleComparisonDataProps[];
};

export const comparisonData: ComparisonDataProps = {
  personal: [
    {
      id: '1',
      i18nId: 'personal.comparison.valuesInTime',
      title: 'Moje hodnoty v čase',
      mainImage: '/img/comparison/values-in-time.png',
      comparison: {
        middle: {
          image: '/img/comparison/zivot_80.png',
          name: '...více variant žebříčků',
          noButton: true,
          noPlus: true,
        },
      },
      steps: [
        'Vyber si nebo vytvoř daný žebříček',
        <React.Fragment key={'p.1.2'}>Jdi do deníku, vyber žebříček, který chceš porovnat, a klikni na ikonu <Icon svg={<CompareIcon />} /></React.Fragment>,
      ],
      backgroundColor: BlueTheme.primary,
    },
    {
      id: '2',
      i18nId: 'personal.comparison.oppositeAttraction',
      title: 'Protiklady se přitahují',
      mainImage: '/img/comparison/opposite-attraction.png',
      comparison: {
        left: {
          image: '/img/comparison/zivot_80.png',
          name: 'Moje hodnoty',
          id: compareMatrixData['Moje hodnoty'],
          noPlus: true,
        },
        right: {
          image: '/img/comparison/ideal_partner80.png',
          name: 'Můj ideální partner',
          id: compareMatrixData['Můj ideální partner']
        },
      },
      steps: [
        'Vyber si nebo vytvoř daný žebříček',
        <React.Fragment key={'p.2.2'}>Jdi do deníku, vyber žebříček, který chceš porovnat, a klikni na ikonu <Icon svg={<CompareIcon />} /></React.Fragment>
      ],
      backgroundColor: PinkTheme.primary,
    },
    {
      id: '3',
      i18nId: 'personal.comparison.wellbeing',
      title: 'Moje spokojenost',
      mainImage: '/img/comparison/wellbeing.png',
      comparison: {
        left: {
          image: '/img/comparison/spokojenost_80.png',
          name: '...více variant žebříčků',
          noButton: true,
        },
        right: {
          image: '/img/comparison/spokojenost_80.png',
          name: 'Moje spokojenost',
          id: compareMatrixData['Moje spokojenost']
        },
      },
      steps: [
        'Vyber si nebo vytvoř daný žebříček',
        <React.Fragment key={'p.3.2'}>Jdi do deníku, vyber žebříček, který chceš porovnat, a klikni na ikonu <Icon svg={<CompareIcon />} /></React.Fragment>
      ],
      backgroundColor: MidnightTheme.primary,
    },
    {
      id: '4',
      i18nId: 'personal.comparison.valuesNow',
      title: 'Hodnoty právě teď',
      mainImage: '/img/comparison/values-now.png',
      comparison: {
        left: {
          image: '/img/comparison/prave_ted_80.png',
          name: (
            <React.Fragment>
              Moje hodnoty <span style={{ color: 'rgba(255, 255, 255, 0.7)' }}>nebo</span> Hodnoty právě teď
            </React.Fragment>
          ),
          noButton: true,
        },
        right: {
          image: '/img/comparison/prave_ted_80.png',
          name: 'Hodnoty tady a teď',
          id: compareMatrixData['Hodnoty tady a teď'],
        },
      },
      steps: [
        'Vyber si nebo vytvoř daný žebříček',
        <React.Fragment key={'p.4.2'}>Jdi do deníku, vyber žebříček, který chceš porovnat, a klikni na ikonu <Icon svg={<CompareIcon />} /></React.Fragment>
      ],
      backgroundColor: GreenTheme.primary,
    },
    {
      id: '5',
      i18nId: 'personal.comparison.actualWishes',
      title: 'Aktuální přání',
      mainImage: '/img/comparison/actual-wishes.png',
      comparison: {
        left: {
          image: '/img/comparison/ma_prani_80.png',
          name: 'Více variant',
          noButton: true,
        },
        right: {
          image: '/img/comparison/ma_prani_80.png',
          name: 'Více variant',
          noButton: true,
        },
      },
      steps: [
        'Vyber si nebo vytvoř daný žebříček',
        <React.Fragment key={'p.5.2'}>Jdi do deníku, vyber žebříček, který chceš porovnat, a klikni na ikonu <Icon svg={<CompareIcon />} /></React.Fragment>
      ],
      backgroundColor: VioletTheme.primary,
    },
  ],
  multiple: [
    {
      id: '1',
      i18nId: 'multiple.comparison.commonValues',
      title: 'Společné hodnoty',
      mainImage: '/img/comparison/common-values.png',
      comparison: {
        left: {
          image: '/img/comparison/zivot_80.png',
          name: 'Moje hodnoty',
          id: compareMatrixData['Moje hodnoty'],
        },
        right: {
          image: '/img/comparison/zivot_80.png',
          name: 'Tvoje hodnoty',
          id: compareMatrixData['Tvoje hodnoty'],
        },
      },
      steps: [
        'Vytvoř si žebříček, který chceš porovnat',
        'Nech na svém profilu vytvořit daný žebříček druhou osobu',
        <React.Fragment key={'m.1.3'}>Jdi do deníku, vyber si tvůj žebříček a kliknu na ikonu <Icon svg={<CompareIcon />} /></React.Fragment>,
        'Najdi žebříček druhé osoby a stiskni porovnat',
        'Společné porovnání se zobrazí'
      ],
      backgroundColor: PinkTheme.primary,
    },
    {
      id: '2',
      i18nId: 'multiple.comparison.parentsAndChildren',
      title: 'Rodiče a děti',
      mainImage: '/img/comparison/parents-and-children.png',
      comparison: {
        left: {
          image: '/img/comparison/deti_rodice_80.png',
          name: 'Rodiče a děti',
          id:compareMatrixData['Rodiče a děti'],
        },
        right: {
          image: '/img/comparison/deti_rodice_80.png',
          name: 'Rodiče a děti',
          id:compareMatrixData['Rodiče a děti'],
        },
      },
      steps: [
        'Jakožto rodič si vytvořte žebříček Rodiče a Děti',
        'Stejný žebříček dejte vytvořit svému dítěti',
        <React.Fragment key={'m.2.3'}>Jdi do deníku, vyber si tvůj žebříček a kliknu na ikonu <Icon svg={<CompareIcon />} /></React.Fragment>,
        'Najdi žebříček dětského uživatele a stiskni porovnat',
        'Porovnání se zobrazí'
      ],
      backgroundColor: KidsTheme.primary,
    },
    {
      id: '3',
      i18nId: 'multiple.comparison.whatAppreciate',
      title: 'Co na tobě oceňuji',
      mainImage: '/img/comparison/what-appreciate.png',
      comparison: {
        left: {
          image: '/img/comparison/ocenuji_80.png',
          name: 'Co na tobě oceňuji?',
          id: compareMatrixData['Co na tobě oceňuji?']
        },
        right: {
          image: '/img/comparison/ocenuji_80.png',
          name: 'Co na mě oceňuješ?',
          id: compareMatrixData['Co na mě oceňuješ?']
        },
      },
      steps: [
        'Vytvoř si žebříček, který chceš porovnat',
        'Nech na svém profilu vytvořit daný žebříček druhou osobu',
        <React.Fragment key={'m.3.3'}>Jdi do deníku, vyber si tvůj žebříček a kliknu na ikonu <Icon svg={<CompareIcon />} /></React.Fragment>,
        'Najdi žebříček druhé osoby a stiskni porovnat',
        'Společné porovnání se zobrazí'
      ],
      backgroundColor: VioletTheme.primary,
    },
    {
      id: '4',
      i18nId: 'multiple.comparison.ourWellbeing',
      title: 'Naše spokojenost',
      mainImage: '/img/comparison/our-wellbeing.png',
      comparison: {
        left: {
          image: '/img/comparison/spokojenost_80.png',
          name: 'Moje spokojenost',
          id: compareMatrixData['Moje spokojenost'],
        },
        right: {
          image: '/img/comparison/spokojenost_80.png',
          name: 'Tvoje spokojenost',
          id: compareMatrixData['Tvoje spokojenost'],
        },
      },
      steps: [
        'Vytvoř si žebříček, který chceš porovnat',
        'Nech na svém profilu vytvořit daný žebříček druhou osobu',
        <React.Fragment key={'m.4.3'}>Jdi do deníku, vyber si tvůj žebříček a kliknu na ikonu <Icon svg={<CompareIcon />} /></React.Fragment>,
        'Najdi žebříček druhé osoby a stiskni porovnat',
        'Společné porovnání se zobrazí'
      ],
      backgroundColor: MidnightTheme.primary,
    },
    {
      id: '5',
      i18nId: 'multiple.comparison.ourSuperpowers',
      title: 'Naše superschopnosti',
      mainImage: '/img/comparison/our-superpowers.png',
      comparison: {
        left: {
          image: '/img/comparison/superschopnosti_80.png',
          name: 'Moje superschopnosti',
          id: compareMatrixData['Moje superschopnosti']
        },
        right: {
          image: '/img/comparison/superschopnosti_80.png',
          name: 'Tvoje superschopnosti',
          id: compareMatrixData['Tvoje superschopnosti']
        },
      },
      steps: [
        'Vytvoř si žebříček, který chceš porovnat',
        'Nech na svém profilu vytvořit daný žebříček druhou osobu',
        <React.Fragment key={'m.5.3'}>Jdi do deníku, vyber si tvůj žebříček a kliknu na ikonu <Icon svg={<CompareIcon />} /></React.Fragment>,
        'Najdi žebříček druhé osoby a stiskni porovnat',
        'Společné porovnání se zobrazí'
      ],
      backgroundColor: GrayTheme.primary,
    },
    {
      id: '6',
      i18nId: 'multiple.comparison.multipleCard6',
      title: 'Další porovnání',
      mainImage: '/img/comparison/multiple-card6.png',
      comparison: {
        left: {
          image: '/img/comparison/multiple-card6-left.png',
          name: 'Další porovnání',
          id: compareMatrixData['Další porovnání']
        },
        right: {
          image: '/img/comparison/multiple-card6-right.png',
          name: 'Další porovnání',
          id: compareMatrixData['Další porovnání']
        },
      },
      steps: [
        'Vytvoř si žebříček, který chceš porovnat',
        'Nech na svém profilu vytvořit daný žebříček druhou osobu',
        <React.Fragment key={'m.1.3'}>Jdi do deníku, vyber si tvůj žebříček a kliknu na ikonu <Icon svg={<CompareIcon />} /></React.Fragment>,
        'Najdi žebříček druhé osoby a stiskni porovnat',
        'Společné porovnání se zobrazí'
      ],
      backgroundColor: EmeraldTheme.primary,
    }
  ]
};
