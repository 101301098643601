import { Box, Flex, Img, Paragraph } from '@xcorejs/ui';
import { RequestContext } from 'components/atoms/RequestContext';
import { mediaMdDefault } from 'components/theme/grid';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const data: {img: string; text: {cs: string; en: string}}[] = [
  {
    img: '/img/ads/pexels-elina-volkova-19985445.jpg',
    text: {
      cs: 'Eticia Plus je služba, která vám umožní získat přístup k neomezenému množství testů a hodnocení. Získáte také přístup k výsledkům ostatních uživatelů a mnoho dalších výhod.',
      en: 'Eticia Plus is a service that allows you to access an unlimited number of tests and ratings. You will also gain access to the results of other users and many other benefits.'
    }
  },
  {
    img: '/img/ads/pexels-pixabay-416160.jpg',
    text: {
      cs: 'Hodnotová hra je zábavná a interaktivní hra, která vám umožní zjistit, jaké hodnoty jsou pro vás nejdůležitější. Zjistěte, jaké hodnoty jsou pro vás nejdůležitější.',
      en: 'The value game is a fun and interactive game that allows you to find out what values are most important to you. Find out what values are most important to you.'
    }
  },
  {
    img: '/img/ads/pexels-alexandra-bilham-7329892.jpg',
    text: {
      cs: 'Eticia je platforma, která vám umožní získat přístup k mnoha užitečným nástrojům, které vám pomohou pochopit, co je pro vás skutečně důležité.',
      en: 'Eticia is a platform that allows you to access many useful tools that will help you understand what is really important to you.'
    }
  },
  {
    img: '/img/ads/pexels-lumn-406014.jpg',
    text: {
      cs: 'Eticia Plus',
      en: 'Eticia Plus',
    },
  },
  {
    img: '/img/ads/pexels-pixabay-45170.jpg',
    text: {
      cs: 'Hodnotová hra',
      en: 'Value game',
    },
  },
  {
    img: '/img/ads/pexels-chevanon-photography-1108099.jpg',
    text: {
      cs: 'Eticia',
      en: 'Eticia',
    },
  },
  {
    img: '/img/ads/pexels-niklas-jeromin-10479040.jpg',
    text: {
      cs: 'Eticia Plus',
      en: 'Eticia Plus',
    },
  },
  {
    img: '/img/ads/pexels-pixabay-45201.jpg',
    text: {
      cs: 'Hodnotová hra',
      en: 'Value game',
    },
  }
];

const Slideshow = () => {
  
  const [active, setActive] = React.useState(0);
  
  useEffect(() => {
    const interval = setInterval(() => {
      setActive((prev) => (prev + 1) % data.length);
    }, 17000);
  
    return () => clearInterval(interval);    
  }, []);
  
  return (
    <RequestContext>
      {({ locale }) => (
        <StyledBox>
          <Paragraph><FormattedMessage id='slideshow.title' /></Paragraph>
  
          <Flex flexDirection='column' alignItems='center' justifyContent='center' marginTop='2rem' position='relative'>
            <Img src={data[active].img} alt='' />
  
            <Paragraph maxWidth={{ _:'20rem', sm:'35rem' }} position='absolute' bottom='0' textShadow='0 0 4px black' color='white'>{data[active].text[locale]}</Paragraph>
          </Flex>
        </StyledBox>
      )}
    </RequestContext>
  );
};

export default Slideshow;
  
export const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -5rem;

  & > div {
    -webkit-animation: fade-slideshow 17s infinite;
    animation: fade-slideshow 17s infinite;
    padding-left: 5rem;
    padding-right: 5rem;

    & img {
      aspect-ratio: 1 / 1;
      
      max-width: 100%;

      ${mediaMdDefault} {
        max-width: 40rem;
      }
    }
  }

  @-webkit-keyframes fade-slideshow {
    0% { opacity: 0; }
    23% { opacity: 1; }
    77% { opacity: 1; }
    100% { opacity: 0; }
  }

  @keyframes fade-slideshow {
    0% { opacity: 0; }
    23% { opacity: 1; }
    77% { opacity: 1; }
    100% { opacity: 0; }
  }
`;