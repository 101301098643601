import React, { FC } from 'react';
import { Product, ShopButton } from './styles';
import { Flex, Paragraph, Heading2, FlexProps } from '@xcorejs/ui';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { createUrl } from 'components/pages/routes';
import { PlusTag } from 'components/atoms/Logo';
import { yellowWhite } from 'components/theme/colors';

interface ThreeLaddersProps {
  noPadding?: boolean;
  free?: boolean;
}

const WhitePurple = {
  background: '#d2d2ff',
  specialText: 'rgb(69, 65, 94)',
  text: '#555',
  fill: '#2a70ad',
  disabled: '#999',
};

const ThreeLadders: FC<ThreeLaddersProps & FlexProps> = ({ noPadding, free, ...props }) => {  
  const to = free ? '#' : `${createUrl('app.shop')}/koupit/ladders`;

  return (
    <Flex 
      as='section'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      backgroundColor='#7e76ab'
      paddingTop={noPadding ? '0 !important' : 'auto'}
      {...props}
    >
      <Product
        borderColor={WhitePurple.specialText}
        backgroundColor='rgba(0, 0, 0, 0.3)'
        style={{ flexGrow: 1 }}
      >
        <Flex
          flexDirection='column'
          alignItems='center'
        >
          <Heading2 color={yellowWhite} mt='2rem' >
            <FormattedMessage id='eticiaPlus.threeLadders.title' />
          </Heading2>

          <Paragraph mt='2rem' maxWidth='30rem' color='white'>
            <FormattedMessage id='eticiaPlus.threeLadders.requirement' />{' '}
            <PlusTag transform='scale(0.85)' onClick={()=>{}} />
          </Paragraph>
        </Flex>

        <Heading2 color={yellowWhite} mt='2rem'>
        121,- Kč
        </Heading2>

        {free ? (
          <ShopButton
            borderColor='rgb(69, 65, 94)'
            backgroundColor='#7e76ab'
            blackHover
            disabled
          >
            <FormattedMessage id='eticiaPlus.shop.buy' />
          </ShopButton>
        ) : (
          <Link to={to}>
            <ShopButton 
              borderColor='rgb(69, 65, 94)'
              backgroundColor='#7e76ab'
              blackHover
            >
              <FormattedMessage id='eticiaPlus.shop.buy' />
            </ShopButton>
          </Link>
        )}
      </Product>
    </Flex>
  );
};
 
export default ThreeLadders;