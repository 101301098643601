import * as React from 'react';
import { CardsGridTheme } from '../../../theme/grid';
import { ThemeProvider } from 'styled-components';
import ValueDefinition from '../../../organisms/ValueDefinition';
import Test from '../../../organisms/Test';
import { RouteComponentProps, withRouter } from 'react-router';
import { addTestRecord, skipTestRecord } from './data';
import { TEST_CURRENT_STEP } from 'config/apiRoutes';
import useFetch from 'components/atoms/Fetch/useFetch';
import { ICurrentRound } from '../../../organisms/Test/data';
import { createUrl } from '../../routes';
import { useContext } from 'react';
import { GameContext } from 'components/App';

const ValueTest: React.FC<RouteComponentProps<{ id: string }>> = ({ match }) => {
  const [detail, setDetail] = React.useState<string | null>(null);
  const round = useFetch<ICurrentRound>(TEST_CURRENT_STEP(match.params.id));
  const { insideQuest } = useContext(GameContext);

  return (
    <>
      <ThemeProvider theme={CardsGridTheme}>
        <Test
          id={match.params.id}
          round={round}
          openValueDefinition={setDetail}
          sendData={addTestRecord}
          skip={skipTestRecord}
          onFinish={() =>
            createUrl('app.testResult', {
              id: round.result!.testResultId,
            })
          }
        />
      </ThemeProvider>
      {detail !== null && <ValueDefinition value={detail} onClose={() => setDetail(null)} inTest={false} />}
    </>
  );
};

export default withRouter(ValueTest);
