import Background from './Background';
import * as React from 'react';
import { StyledPage, StyledMain } from './styles';
import { ColoredThemes } from '../../theme/colors';
import Header from './Header';
import MainPopup from '../MainPopup';
import ErrorBoundary from '../../templates/General/ErrorBoundary';
import FlashMessages from '../FlashMessages';
import OGTags from 'components/atoms/OGTags';

interface ILayout {
  layout?: string;
  background?: string;
  scroll?: boolean;
}

class Layout extends React.Component<ILayout> {
  public render() {
    const { layout, children, background, scroll } = this.props;
    return (
      <React.Fragment>
        <Background themes={ColoredThemes} active={background} />
        <StyledPage className={layout + (scroll ? '' : '  no-scroll')}>
          <Header />
          <OGTags themeColor={background} />
          <MainPopup />
          <ErrorBoundary error={stack => this.setState({ error: stack })}>
            <StyledMain className={layout} layout={layout}>
              <FlashMessages />
              {children}
            </StyledMain>
          </ErrorBoundary>
        </StyledPage>
      </React.Fragment>
    );
  }
}

export default Layout;
