import { connect } from 'react-redux';
import ShirtForm from './component';
import { IApplicationState } from '../../../../../../store/store';
import { openRawInfoPopup } from '../../../../../../store/Popup/infoPopups';

interface IProp {
  updateSlider: (type: number) => any;
}

const mapState = (_: IApplicationState, props: IProp) => ({
  ...props,
});

const mapDispatch = (dispatch: any) => ({
  popup: (content: React.ComponentType, button?: string) =>
    // dispatch(layoutOpenPopup("raw", body, "terms.heading", button))
    openRawInfoPopup({ content, title: 'terms.heading', button })(dispatch),
});

export default connect(mapState, mapDispatch)(ShirtForm);
