import ValueComparison from '.';
import ComparisonSetup from './ComparisonSetup';
import Invitation from './Invitation';
import SendInvitation from './SendInvitation';
import SuccessVoucher from './Vouchers/components/SuccessVoucher';
import StartTest from './StartTest';
import { IRoute } from 'utils/router';
import { IThemeData } from '../../routes';
import SuccesComparison from './Vouchers/components/SuccesComparison';
import SuccesGift from './Vouchers/components/SuccesGift';

export const valueComparisonRoutes: { comparison: IRoute<IThemeData> } = {
  comparison: {
    path: 'route.comparison',
    component: ValueComparison,
    data: {
      background: 'midnight',
      authUser: true,
    },
    children: {
      intro: {
        path: 'route.comparison.intro',
        exact: true,
        component: ComparisonSetup,
        data: {
          background: 'midnight',
          authUser: true,
        },
      },
      startTest: {
        path: 'route.comparison.startTest',
        exact: true,
        component: StartTest,
        data: {
          authUser: true,
          background: 'midnight',
        },
      },
      invitation: {
        path: 'route.comparison.invitation',
        exact: true,
        component: Invitation,
        data: {
          background: 'midnight',
          authUser: true,
        },
      },
      sendInvitation: {
        path: 'route.comparison.sendInvitation',
        exact: true,
        component: SendInvitation,
        data: {
          background: 'midnight',
          authUser: true,
        },
      },
      //vouchers: {
      //path: "route.comparison.vouchers",
      //exact: false,
      //component: Vouchers,
      //data: {
      //background: "valuesForTwo",
      //authUser: true,
      //},
      //},
      voucherSuccess: {
        path: 'route.comparison.voucherSuccess',
        exact: true,
        component: SuccessVoucher,
        data: {
          background: 'midnight',
          authUser: true,
        },
      },
      comparisonSucces: {
        path: 'route.comparison.comparisonSuccess',
        exact: true,
        component: SuccesComparison,
        data: {
          background: 'midnight',
          authUser: true,
        },
      },
      giftSucces: {
        path: 'route.comparison.giftSuccess',
        exact: true,
        component: SuccesGift,
        data: {
          background: 'midnight',
          authUser: true,
        },
      },
    },
  },
};

export const ShopPathnames = [
  'koupit-voucher',
  'koupit-porovnani',
  'darkove-poukazy',
  'hodnotove-porovnani/darkovy-balicek',
];
