import styled from 'styled-components';
import { primaryGreen, textGreen } from '../../theme/colors';
import { CustomText } from '../../atoms/Text';
import { mediaMdDefault, mediaSmDefault } from '../../theme/grid';

export const StyledRegistrationPopup = styled.div`
  h2 {
    margin-top: 2rem;
  }

  p {
    margin-top: 2rem;

    a {
      opacity: 0.8;
      color: ${primaryGreen};
      display: inline-block;
      font-weight: 400;

      span {
        border-bottom: 1px solid ${primaryGreen};
        transition: border 0.3s;
      }

      &:hover {
        span {
          border-bottom: 1px solid transparent;
        }
      }
    }
  }

  ol {
    margin-top: 2rem;
    text-align: left;
  }
`;

export const StyledRegistrationForm = styled.div`
  ${CustomText} {
    text-align: center;
  }

  .form-inline-wrapper {
    margin-bottom: 4rem;
  }

  .form-inline {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-top: 4rem;

    ${mediaMdDefault} {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .form-inline-flex {
    ${mediaMdDefault} {
      flex-direction: column;
    }

    & > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      margin-top: 3rem;

      &:first-child {
        margin-top: 0;
      }

      ${mediaSmDefault} {
        flex-direction: row;

        & > span {
          width: 50%;
          padding-right: 3rem;
          text-align: right;
        }
      }
    }
  }

  .Select-arrow {
    border-color: ${textGreen} transparent transparent !important;
  }

  .Select.is-open > .Select-control .Select-arrow {
    border-color: ${textGreen} transparent transparent !important;
  }
`;
