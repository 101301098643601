import styled from 'styled-components';
import { darkBaseHEX, yellow, gray } from '../../theme/colors';
import { Fox } from '../../theme/fonts';
import { mediaMdDefault, mediaSmDefault } from '../../theme/grid';
import { StyledLink } from '../Buttons/styles';

export const StyledFormGroup = styled.div`
  display: block;
  label {
    display: block;
    margin-bottom: 1.5rem;
    color: ${gray};
    font-size: 2rem;
    line-height: 2.6rem;
    font-weight: 500;
    text-align: center;

    ${mediaSmDefault} {
      text-align: left;
    }

    span.required {
      display: block;
      text-align: center;
      font-weight: 400;
      opacity: 0.8;
      color: ${gray};
      font-size: 1.6rem;
      line-height: 2rem;
    }
  }

  input {
    //padding 1.9rem 2.0rem;
    padding: 2.1rem 2rem 1.7rem;
    display: block;
    width: 100%;
    border-radius: 0.4rem;
    border: 0;
    color: ${darkBaseHEX};
    font-family: ${Fox};
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  .error {
    display: block;
    text-align: left;
    color: ${yellow};
    margin-bottom: 0;
    margin-top: 2rem;
  }

  .notRequired {
    display: inline-block;
    float: right; /* TODO: float with display?? */
    margin-top: 3px;

    span {
      font-size: 2rem;
    }
  }

  ${StyledLink} {
    display: block;
    margin-top: 0.3rem;

    span {
      display: inline-block;
    }

    ${mediaMdDefault} {
      display: inline-block;
      float: right; /* TODO: float with display?? */
    }
  }
` as any;
