import * as React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { createUrl } from '../../routes';
import NavItem from '../../../atoms/Nav/NavItem';
import Settings from './Settings';
import Demographic from '../../../organisms/Demographic';
import Subnav from 'components/atoms/Subnav';
import Messages from './Messages';
import Friends from './Friends';
import Password from './Settings/Password';
import Nick from './Settings/Nick';
import Email from './Settings/Email';
import InterestsGroup from 'components/pages/App/Profile/InterestsGroup';
import { FC } from 'react';

const Profile: FC = () => {
  const { pathname } = useLocation();
  const baseUrl = createUrl('app.profile');
  const demographicUrl = createUrl('app.profile') + '/profile-info';

  return (
    <div>
      <Subnav black={pathname === demographicUrl}>
        <NavItem to={baseUrl} exact={true} label='profile.menu.settings' />
        {/*
            <NavItem to={baseUrl + '/invites'} label='profile.menu.friends' />
            <NavItem to={baseUrl + "/zpravy"} label="profile.menu.messages" />
          */}
      </Subnav>

      <Switch>
        <Route path={baseUrl} exact={true} render={() => <Settings />} />
        <Route path={baseUrl + '/invites'} exact={true} render={() => <Friends />} />
        <Route path={baseUrl + '/zpravy'} exact={true} render={() => <Messages />} />

        <Route path={baseUrl + '/heslo'} exact={true} render={() => <Password />} />

        <Route path={baseUrl + '/email'} exact={true} render={() => <Email />} />

        <Route path={baseUrl + '/nick'} exact={true} render={() => <Nick />} />

        <Route path={demographicUrl} exact={true} render={() => <Demographic inSettings={true} />} />

        <Route path={baseUrl + '/zajmove-skupiny'} exact={true} render={() => <InterestsGroup />} />
      </Switch>
    </div>
  );
};

export default Profile;
