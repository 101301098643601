import * as React from 'react';
import { Row, Grid } from 'react-styled-flexboxgrid';
import { ILifeValueDetail, IQuote } from '../../../../store/Entities/LifeValues';
import QuoteCard from '../Cards/QuoteCard';
import TabWrapper from '../TabWrapper';
import { getQuotes } from '..';

interface IQuotes {
  inTest: boolean;
  detail: ILifeValueDetail;
  onVote: (id: string, type: string, text?: string) => any;
}

const Quotes: React.FC<IQuotes> = ({ detail, inTest, onVote }) => (
  <Grid>
    <Row>
      <TabWrapper collection={getQuotes(detail)} inTest={inTest} label={'Citát'} color='green'>
        {(itm: IQuote) => (
          <QuoteCard
            quote={itm}
            valueId={detail.id}
            onVote={(id: string, type: string, text?: string) => onVote(id, type, text)}
          />
        )}
      </TabWrapper>
    </Row>
  </Grid>
);

export default Quotes;
