import * as React from 'react';
import { IFetchException } from '../../../utils/exceptions';
import { jsonFetch } from '../../../utils/fetch';

export type FetchState = 'fetching' | 'success' | 'fail' | 'reload';
export type FetchReload<T = {}> = (result?: T, helper?: string) => unknown;

interface IFetch<T> {
  url: string;
  aggressive?: boolean;
  children: (key: FetchState) => (result: T | null, reload: FetchReload<T>) => React.ReactElement<any>;
}

interface IFetchState<T> {
  state: FetchState;
  result: T | null;
}

class Fetch<T> extends React.Component<IFetch<T>, IFetchState<T>> {
  public state: IFetchState<T> = { state: 'fetching', result: null };

  public componentDidMount() {
    this.fetch();
  }

  public componentDidUpdate() {
    if (this.state.state === 'reload' || this.props.aggressive) {
      this.fetch();
    }
  }

  public render() {
    const f = this.props.children(this.state.state);
    return f !== undefined ? React.Children.only(f(this.state.result, this.reload)) : null;
  }

  private fetch = () => {
    this.setState({ state: 'fetching', result: null });
    jsonFetch<T>(this.props.url)
      .then(result => this.setState({ state: 'success', result }))
      .catch((e: IFetchException) =>
        e.data.json().then(error =>
          this.setState({
            state: 'fail',
            result: { status: e.data.status, ...error },
          }),
        ),
      );
  };

  private reload: FetchReload<T> = (result?: T) =>
    result === undefined ? this.setState({ state: 'reload', result: null }) : this.setState({ result });
}

export default Fetch;
