import { VOUCHERS_INVITE } from 'config/apiRoutes';
import { jsonFetch } from 'utils/fetch';

export interface IInvitationData {
  name: string;
  email: string;
  message: string;
}

export const sendInvitation = (data: IInvitationData, voucherId: string) =>
  jsonFetch(VOUCHERS_INVITE, {
    method: 'post',
    body: JSON.stringify({
      ...data,
      voucherId,
    }),
  });

export interface IFetchException {
  error: string;
  data: Response;
}

export const fetchException = (response: Response): IFetchException => ({
  error: 'Error ' + (response as Response).status + ' ' + (response as Response).statusText,
  data: response,
});
