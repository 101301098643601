import { changeUser, changeUserData } from 'components/pages/App/Profile/Settings/actions';
import KidsSwitch from 'components/pages/Registration/KidsSwitch';
import { CURRENT_PROFILE } from 'config/apiRoutes';
import * as React from 'react';
import { IUserInfo } from 'store/User';
import { IFetchException } from 'utils/exceptions';
import { jsonFetch } from 'utils/fetch';
import { StyledProfileForm } from '../../pages/styles';
import { Col, Grid, Row } from 'react-styled-flexboxgrid';
import Fade from 'react-reveal/Fade';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import AddDemographic from '../../molecules/DemographicForm';
import { createUrl } from '../../pages/routes';
import { parseQueryParams } from '../../../utils/helpers';
import { RouteComponentProps, withRouter } from 'react-router';
import AddDemographicWithoutUser from '../../molecules/DemographicForm/AddDemographicWithoutUser';
import { StyledLinkBorder } from '../../atoms/Buttons/styles';
import { Box, Flex, Heading1, Typography, Paragraph, Heading3 } from '@xcorejs/ui';
import { PrimaryButton, SecondaryButton } from 'components/components/Button';
import { GameContext } from 'components/App';
import { useContext, useEffect, useState } from 'react';

interface Props {
  inSettings?: boolean;
  afterRegister?: () => void;
}

const Demographic: React.FC<Props & RouteComponentProps<any>> = ({ location, inSettings, afterRegister, history }) => {
  const { insideQuest } = useContext(GameContext);
  const intro = parseQueryParams(location.search).intro !== undefined;
  const afterTest = location.state !== undefined && location.state.afterTest;
  const redirect = location.state && location.state.redirect;
  const [child, setChild] = useState<boolean>(false);
  const [user, setUser] = useState<IUserInfo | undefined>(undefined);

  useEffect(() => {
    inSettings &&
    jsonFetch<IUserInfo>(CURRENT_PROFILE)
      .then(user => user && setUserInfo(user))
      .catch((e: IFetchException) => console.log('error'));
      
    function setUserInfo(user: IUserInfo) {
      setUser(user);
      setChild(user.child);
    }
  }, [inSettings]);

  useEffect(() => {
    user && changeUserData(
      changeUser(user, child, 'child'),
      () => {},
      () => console.log('fail'),
    );
  }, [child, user]);

  return (
    <Box
      bg="url('/img/global.jpg')"
      backgroundPosition='center bottom'
      backgroundSize='cover'
      {...{ backgroundAttachment: 'fixed' }}
      minHeight='100vh'
      pb='3.5rem'
      mb='-3.5rem'
      mt={inSettings ? '0' : { _: '-5rem', sm: '-6rem' }}
    >      
      <StyledProfileForm>
        <Row>
          <Col xs={12}>
            <Fade ssrReveal bottom duration={1000} distance={'5rem'}>
              <Heading1 fontSize='3.2rem' lineHeight='3.9rem' fontWeight={400} textAlign='center'>
                <FormattedMessage id={'registration.finish'} />
              </Heading1>

              <Box>
                {child ? (
                  <Heading3 mt='0' textAlign='center' color='#ffa4a3'>
                    <FormattedHTMLMessage id={'registration.finish.under16.heading'} />
                  </Heading3>
                ) : null }
              </Box>

              <Typography mt='2.4rem'>
                <FormattedHTMLMessage id={'registration.finish.text'} />

                {!child ? (
                  <>
                    <FormattedHTMLMessage id={'registration.finish.under16'} />
                    <KidsSwitch onAgreement={() => setChild(true)} />
                  </>
                ) : null}
              </Typography>

              {afterRegister ? (
                <AddDemographicWithoutUser onSubmit={afterRegister} child={child}>
                  {DemoButton(afterTest, intro, inSettings, redirect, afterRegister)}
                </AddDemographicWithoutUser>
              ) : (
                <AddDemographic insideQuest={insideQuest}>
                  {DemoButton(afterTest, intro, inSettings, redirect, () =>
                    history.push(
                      redirect
                        ? redirect
                        : insideQuest ? '/cs/app/hra'
                          : !afterTest
                            ? createUrl(intro ? 'app.gameIntro' : 'app')
                            : createUrl('app.testResult', { id: afterTest }),
                    ),
                  )}
                </AddDemographic>
              )}

              <div>
                {inSettings && (
                  <StyledLinkBorder to={createUrl('app.profile')}>
                    <FormattedMessage id={'registration.finish.back'} />
                  </StyledLinkBorder>
                )}
              </div>
            </Fade>
          </Col>
        </Row>
      </StyledProfileForm>
    </Box>
  );
};

const DemoButton = (_: string, __: boolean, inSettings?: boolean, ___?: string, onSkip?: () => void) => (
  <>
    <Flex className={'btn-box'} mt='3.2rem' justifyContent='center'>
      <div>
        <PrimaryButton bg='rgba(12, 107, 75, 0.9)' color='#fff' _hover={{ color: '#000' }}>
          <FormattedMessage id={'demo.complete'} />
        </PrimaryButton>
      </div>

      {!inSettings && onSkip && (
        <SecondaryButton
          ml='3rem'
          borderColor='rgba(12, 107, 75, 0.9)'
          onClick={e => {
            e.preventDefault();
            onSkip();
          }}
          height='5rem'
        >
          <FormattedMessage id={'demo.skip'} />
        </SecondaryButton>
      )}
    </Flex>
  </>
);

export default withRouter(Demographic);
