import React, { FC } from 'react';
import { Box, Button, Flex, Heading2, Img, Typography, Link as UILink } from '@xcorejs/ui';
import { PremiumButton } from 'components/components/Button';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { createUrl } from 'components/pages/routes';

interface SuccessProps {
  noApp?: boolean;
}

const Success: FC<SuccessProps> = ({ noApp }) => {
  return (
    <Flex flexDirection='column' alignItems='center' py='8rem'>
      <PremiumButton />

      <Heading2
        as='h1'
        textAlign='center'
        mt='3rem'
      >
        <FormattedMessage id='shop.buy.success.congrats' />{' '}
      </Heading2>

      <Img
        src='/img/voucher-small.jpg'
        height='28rem'
        mt='4rem'
        opacity={0.5}
        alt=''
      />

      <Box maxWidth='63rem' mx='auto'>
        <Typography color='#f4f4f6' mt='4rem'>
          <FormattedMessage id='shop.buy.success.text1' />
        </Typography>

        <Flex justifyContent='center'>
          <Link to={createUrl('app.activation')}>
            <UILink v='underline' color='rgba(244, 244, 246, 0.5)' mt='2rem'>
              <FormattedMessage id='shop.buy.success.text2' />
            </UILink>
          </Link>
        </Flex>
      </Box>

      <Flex justifyContent='center' mt='4rem'>
        <Link to={noApp ? createUrl('') : createUrl('app.shop')}>
          <Button
            as='span'
            s='lg'
            fontSize={{ _: '2rem', sm: '2.2rem' }}
            lineHeight={{ _: '2.2rem', sm: '2.8rem' }}
            letterSpacing='0.5px'
            px='5rem'
            py='1.9rem'
            height='6rem'
          >
            <FormattedMessage id='shop.buy.success.button' />
          </Button>
        </Link>
      </Flex>
    </Flex>
  );
};

export default Success;
