import styled from 'styled-components';
import {
  gray,
  white,
  whiteFade,
  darkBaseHEX,
  darkBaseRGB,
  textGreen,
  grayRGB,
  yellowWhite
} from '../../../theme/colors';
import { mediaLgDefault, mediaMdDefault } from '../../../theme/grid';
import { OliMedium } from 'components/theme/fonts';

export const StyledNotifications = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: middle;

  .icon-link {
    display: inline-block;
    vertical-align: middle;
    background-color: transparent;
    border-right: 1px solid rgba(${darkBaseRGB}, 0.25);
    padding: 1rem 1rem;
    cursor: pointer;
    transition: background-color 300ms ease;

    ${mediaLgDefault} {
      padding: 1rem 2rem;
    }
  }

  .count {
    display: inline-block;
    height: 1.5rem;
    width: 1.5rem;
    position: absolute;
    top: 0.3rem;
    right: 1rem;
    color: ${white};
    background-color: #ee3249;
    border-radius: 10rem;
    font-size: 1rem;
    text-align: center;
  }

  .on-hover {
    visibility: hidden;
    position: absolute;
    left: 0;
    top: 4.4rem;
    opacity: 0;
    transition: opacity 300ms ease, visibility 300ms ease;
  }

  &:hover {
    .icon-link {
      background-color: ${darkBaseHEX};
    }

    .on-hover {
      display: block;
      visibility: visible;
      opacity: 1;
    }
  }
`;

export const StyledMenuUser = styled.div`
  display: block;
  margin-top: 3rem;
  color: ${gray};

  &.login {
    display: flex;
    flex-direction: column;
    align-items: baseline;

    .login-btn {
      border-color: ${textGreen};

      &:hover {
        background: ${textGreen};
      }

      ${mediaMdDefault} {
        padding: 0;
        border: 0;
        justify-content: center;
        min-height: 1rem;
        height: 1rem;
        color: rgba(${grayRGB}, 0.8);
        transition: color 0.3s;

        &:hover {
          background: transparent;
          color: rgba(${grayRGB}, 1);
        }
      }
    }
  }

  .nav-link {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    padding: 1.5rem 0;
    font-size: 1.5rem;
    line-height: 2rem;
    letter-spacing: 1px;
    background-position-x: center;
    opacity: 0.7;
    transition: opacity 500ms ease;
    color: #fff;

    &:hover {
      opacity: 1;
    }
  }

  .userInfo {
    display: inline-block;
    vertical-align: middle;
    position: relative;

    ${mediaMdDefault} {
      margin: 0 auto;
    }

    .userText {
      display: inline-block;
      vertical-align: middle;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.9rem;
      text-align: left;

      .userName {
        display: block;
        vertical-align: middle; /* TODO: vertical-align nefunguje s display: block */
        color: ${yellowWhite};
        font-weight: 500;
        max-width: 13rem;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: ${OliMedium};
      }

      .userTitle {
        display: block;
        vertical-align: middle;
        color: ${whiteFade};
        margin-top: 0.4rem;
        width: 100%;
        max-width: 13rem;
        font-size: 1.3rem;
        letter-spacing: 0.1rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .userImage {
      vertical-align: middle;
      display: inline-block;
      border-radius: 5rem;
      width: 5rem;
      height: 5rem;
      line-height: 4.8rem;
      margin-right: 2rem;
      text-align: center;
      background-color: rgba(${darkBaseRGB}, 0.25);
      //overflow: hidden;
      position: relative;

      &:before {
        //content: "";
        //display: inline-block;
        //vertical-align: middle;
        //height: 100%;
      }

      img {
        min-height: 5rem;
        vertical-align: middle;
        min-width: 5rem;
      }
    }

    .external-svg {
      width: 100%;
      height: 100%;
      
      svg {
        display: inline-block;
        width: 100%;
        height: 100%;

        path {
          fill: #fff !important;
        }
      }
    }
  }
`;

export default StyledMenuUser;
