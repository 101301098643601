import React, { FC, Fragment } from 'react';
import { Button, Img, Paragraph, Box, Icon } from '@xcorejs/ui';
import { PlusTag } from 'components/atoms/Logo';
import { FormattedMessage } from 'react-intl';
import { IJournal } from 'components/pages/App/TestIntro/data';
import { compareMatrixData } from 'components/atoms/CompareMatrixData/data';
import { LoadingDot } from 'components/atoms/Animations/LoadingDots';
import { FetchState } from 'components/atoms/Fetch';
import { ComparisonInsideDataProps } from './data';
import { StyledGrid } from './style';
import CompareIcon from '../../../icons/16/compare.svg';

interface LadderInfoCardProps {
  onClick: (testId: string | undefined) => void;
  data: {
    left?: ComparisonInsideDataProps;
    right?: ComparisonInsideDataProps;
    middle?: ComparisonInsideDataProps;
  };
  journal: IJournal | null;
  state?: FetchState;
  close: () => void;
}

const LadderInfoCard: FC<LadderInfoCardProps> = ({ onClick, data, journal, state = 'success', close }) => {
  const journalCondition = {
    left: false,
    right: false
  };

  if (journal && journal.mains.length > 0) {
    if (data.left) journalCondition.left = data.left.id === compareMatrixData['Moje hodnoty'];
    if (data.right) journalCondition.right = data.right.id === compareMatrixData['Moje hodnoty'];
  }

  return (
    <StyledGrid singleColumn={data.middle !== undefined}>
      {data.middle && (
        <Fragment>
          <Img src={data.middle.image} alt='' className='image image__middle' />

          <Box className='text-block__middle'>
            <p className='ladder-name'>{data.middle.name}</p>
          </Box>
        </Fragment>
      )}

      {data.left && data.right && (
        <Fragment>
          <Img src={data.left.image} alt='' className='image image__left' />

          <Box className='compare-icon'>
            <Icon svg={<CompareIcon />} fill='white' />
          </Box>

          <Img src={data.right.image} alt='' className='image image__right' />

          <Box className='text-block text-block__left'>
            <p className='ladder-name'>{data.left.name}</p>
            {!data.left.noButton ? state === 'success' ? (
              <Button onClick={() => onClick(data.left!.id)}>
                <FormattedMessage id='comparison.modal.openLadder.button' />
              
                {!data.left.noPlus && (
                  <PlusTag onClick={(e) => e.stopPropagation()} />
                )}
              </Button>
            ) : 
              <LoadingDot /> : 
              null}
          </Box>

          <Box className='text-block text-block__right'>
            <p className='ladder-name'>{data.right.name}</p>
            {!data.right.noButton ? state === 'success' ? (
              <Button onClick={() => onClick(data.right!.id)}>
                <FormattedMessage id='comparison.modal.openLadder.button' />
              
                {!data.right.noPlus && (
                  <PlusTag onClick={(e) => e.stopPropagation()} />
                )}
              </Button>
            ) : 
              <LoadingDot /> : 
              null}
          </Box>
        </Fragment>
      )}
    </StyledGrid>    
  );
};
 
export default LadderInfoCard;
