import { IValueTestResultDetail, IValueResult } from 'config/api/valueTestResult';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { IStringMap } from 'utils';

import { ValueMapTheme } from '..';
import ExternalSVG from '../../../atoms/ExternalSVG';
import SVGresultTwo from '../../../atoms/SVGIcons/result/SVGresultTwo';
import { CustomText, SectionText } from '../../../atoms/Text';
import { IProfile } from '../../../pages/App/Result/PartnerTestResult/data';
import OrderedValues from '../OrderedValues';
import { StyledValueMap } from '../styles';

interface Props {
  theme: ValueMapTheme;
  result?: IValueTestResultDetail;
  allLifeValues: IValueResult[];
  profiles?: IStringMap<IProfile>;
}

const ValueMapValues: FC<Props> = ({ theme, allLifeValues, profiles }) => (
  <>
    {theme === 'afterYear' && (
      <h2>
        <FormattedMessage id='valuemap.myLifeValues' />
      </h2>
    )}
    {theme === 'forTwo' && (
      <h2>
        <SVGresultTwo />
        <FormattedMessage id='valuemap.valuesForTwo' />
      </h2>
    )}

    {theme === 'afterYear' && (
      <CustomText>
        <em>
          <FormattedMessage id='valuemap.resultAfterYear' />
        </em>
      </CustomText>
    )}

    {theme === 'forTwo' && (
      <CustomText>
        <em>
          <strong>
            <FormattedMessage id='valuemap.commonValues' />
          </strong>
        </em>
      </CustomText>
    )}

    <OrderedValues allLifeValues={allLifeValues} />

    {theme == 'forTwo' && profiles && (
      <div className='eticons'>
        {Object.keys(profiles).map((key, index) => (
          <div key={key}>
            <SectionText color={index === 0 ? '#00E58D' : '#47A7FF'}>
              {profiles[key].nick ? profiles[key].nick : profiles[key].fullname}
            </SectionText>

            <ExternalSVG src={profiles[key].eticon.avatar} className={`eticon i${index}`} />

            <SectionText color={index === 0 ? '#00E58D' : '#47A7FF'}>
              <strong>{profiles[key].eticon.name}</strong>
            </SectionText>
          </div>
        ))}
      </div>
    )}

    <img src='/img/logo/eticia-logo.svg' alt='' className='logo' />
  </>
);

export default ValueMapValues;
