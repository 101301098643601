import * as React from 'react';
import { Ball } from './styles';
import { ISurveyQuestion, ISurveyAnswer } from '../../../pages/App/Result/PartnerTestResult/data';
import { FormattedMessage } from 'react-intl';
import { Flex, Typography, Heading3 } from '@xcorejs/ui';
import { Fragment } from 'react';
import { yellow } from 'components/theme/colors';

interface ISingleAnswer {
  question: ISurveyQuestion<ISurveyAnswer[]>;
}

const SingleAnswer: React.FC<ISingleAnswer> = ({ question }) => (
  <>
    {!question.skipped ? (
      <>
        {question.answerType === 'value' ? (
          <>
            <div className={'range'}>
              <div>
                <div>
                  <Ball
                    left={
                      (parseInt(question.answers[0].value!, 10) - question.minValue) /
                        (question.maxValue - question.minValue) *
                      100
                    }
                    background='green'
                    backgroundString='rgba(11, 12, 28, 0.15)'
                  >
                    {question.answers[0].value}
                  </Ball>
                </div>
              </div>

              <div className={'description'}>
                <span>{question.minText}</span>
                <span>{question.maxText}</span>
              </div>
            </div>
          </>
        ) : (
          <>
            <div>
              {question.answerType === 'text' && (
                <>
                  <Heading3 fontSize={{ _: '1.8rem', sm: '2rem' }} color={yellow}>
                    Tvé odpovědi
                  </Heading3>
                
                  <Flex display='flex' flexDirection='column' alignItems='center' marginTop='1rem' style={{ gap: '0.5rem' }}>
                    {question.answers.map(answer => (
                      <Typography
                        key={answer.id}
                        width={answer.image && question.answers.length > 1 ? '50%' : '100%'}
                        px='1rem'
                        minWidth='10rem'
                        color='#fff'
                      >
                        {answer.image ? (
                          <Fragment>
                            <img src={answer.image} alt='' width='150' />
                          </Fragment>
                        ) :
                          answer.text
                        }
                        <br />
                      </Typography>
                    ))}
                  </Flex>
                </>
              )}
              {question.answerType === 'image' && (
                <>
                  {question.answers.map((answer, i) => (
                    <Typography key={i} color='#fff'>
                      <img src={answer.image} alt='' />
                    </Typography>
                  ))}
                </>
              )}
              {question.answerType === 'free' && (
                <>
                  {question.answers.map((answer, i) => (
                    <Typography
                      key={i}
                      mt={i === 0 ? 0 : '1rem'}
                      px='2rem'
                      py='0.5rem'
                      color='#fff'
                    >
                      {answer.value}
                    </Typography>
                  ))}
                </>
              )}
            </div>
          </>
        )}
      </>
    ) : (
      <FormattedMessage id={'survey.result.skip'} />
    )}
  </>
);

export default SingleAnswer;
