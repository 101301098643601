import { Box, Flex, FlexProps, Heading4, Img, Paragraph, Typography } from '@xcorejs/ui';
import { FreeTag, PlusTag } from 'components/atoms/Logo';
import { EticiaMeterTheme } from 'components/theme/colors';
import { OliLight, OliThin } from 'components/theme/fonts';
import React, { FC } from 'react';

type Group = {
  gender: string;
  country: string;
  years: string;
}

export interface GroupProps extends FlexProps {
  title: string;
  subtitle: string;
  image: string;
  plus?: boolean;
  free?: boolean;
  flag?: string;
  group: Group;
}

const Group: FC<GroupProps> = ({
  title,
  subtitle,
  image,
  plus,
  free,
  flag,
  group,
  ...props
}) => {
  return ( 
    <Flex flexDirection='column' alignItems='center' justifyContent='space-between' height='100%' flex='1 1 0' {...props}>
      <Flex flexDirection='column' alignItems='center'>
        <Heading4 fontSize={{ _: '1.6rem' }} lineHeight={{ _: '2.2rem' }} color={EticiaMeterTheme.text}>{title}</Heading4>

        <Typography fontSize={{ _: '1.3rem' }} lineHeight={{ _: '1.7rem' }} color='rgba(255,255,255,0.8)' fontFamily={OliThin}>{subtitle}</Typography>
      </Flex>

      <Flex flexDirection='column' alignItems='center' marginTop={{ _: '2rem' }} >
        <Box
          position='relative'
          width='8rem'
          height='8rem'
          borderRadius='50%'
          backgroundImage={`url(${image})`}
          backgroundSize='cover'
          backgroundPosition='center'
        >
          {plus ? (
            <PlusTag
              position='absolute'
              bottom='-0.5rem'
              right='-3rem'
              transform='scale(0.6)'
            >Eticia Plus</PlusTag>
          ) : free ? (
            <FreeTag
              onClick={() => {}} 
              position='absolute'
              bottom='-0.5rem'
              right='-3rem'
              transform='scale(0.6)'
            >Eticia Free</FreeTag>
          ) : flag ? (
            <Img
              position='absolute'
              bottom='0'
              right='0'
              src={`/img/eticiameter/flags/${flag}.png`}
              alt={flag}
              width='4rem' />
          ) : null}
        </Box>

        <Paragraph marginTop={{ _: '1rem' }} fontFamily={OliLight} color='white' textTransform='capitalize'>{group.gender}, {group.country}</Paragraph>
        <Paragraph fontFamily={OliLight} color='white'>{group.years}</Paragraph>
      </Flex>
    </Flex>
  );
};
 
export default Group;
