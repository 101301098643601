import styled from 'styled-components';
import { Heading1 } from '../../atoms/Headings';
import { BiggerText } from '../../atoms/Text';
import { StyledLink } from '../../atoms/Buttons/styles';
import { gray } from '../../theme/colors';

export const StyledFeedback = styled.section`
  text-align: center;

  ${Heading1} {
    text-align: center;
    margin-bottom: 3rem;
  }

  ${BiggerText} {
    text-align: center;
    margin-bottom: 3rem;
  }

  textarea, input {
    width: 100%;
    margin: 0 auto 3rem;
    max-width: 65rem;
    border-radius: 0.4rem;
    background-color: #ffffff;
    color: #191d2c;
    font-size: 1.8rem;
    line-height: 2.2rem;
    padding: 1.4rem 2rem;
    border: none;
    height: 10.5rem;
    resize: none;

    &:focus {
      outline: none;
    }
  }
  
  input {
    margin-top: 2rem;
    max-width: 20rem;
    height: 2rem;
  }

  ${StyledLink} {
    opacity: 0.8;
    color: ${gray};
    font-size: 1.8rem;
    line-height: 2.2rem;
    display: inline-block;

    span {
      border-bottom: 1px solid ${gray};
      transition: border 0.3s;
    }

    &:hover {
      span {
        border-bottom: 1px solid transparent;
      }
    }
  }

  .feedback-thanks {
    span {
      position: relative;
      padding-left: 3rem;

      &:before {
        content: "";
        display: inline-block;
        height: 1.5rem;
        width: 0.8rem;
        border-top: 2px solid #ffde71;
        border-left: 2px solid #ffde71;
        transform: rotate(-140deg) translateY(-50%);
        position: absolute;
        top: 50%;
        margin-top: -2rem;
        margin-left: 0.1rem;
        left: 0;
      }
    }
  }
`;
