import styled, { css } from 'styled-components';
import { Heading1, Heading2, Heading3 } from '../atoms/Headings';
import { BiggerText, CustomText, SectionText } from '../atoms/Text';
import { StyledPrimaryButtonLink } from '../atoms/Buttons';
import {
  StyledLink,
  StyledLinkBorder,
  StyledPrimaryButton,
  StyledPrimaryButtonButton,
  StyledSecondaryButton,
  StyledSecondaryButtonLinkBigger,
} from '../atoms/Buttons/styles';
import { Col, Row, Grid } from 'react-styled-flexboxgrid';
import {
  darkBaseHEX,
  GreenTheme,
  gray,
  yellow,
  primaryGreen,
  selectColor,
  textGreen,
  blue,
  whiteFade,
  grayRGB,
  GrayTheme,
  primaryBlue,
  darkBaseRGB, BlueTheme,
} from '../theme/colors';
import { mediaHonzaDefault, mediaLgDefault, mediaMdDefault, mediaSmDefault, mediaSmMax } from '../theme/grid';
import { StyledCheckboxInput } from '../atoms/FormItems/styles';
import { StyledCustomSelect } from '../atoms/Select/styles';
import { StyledPersonBox } from '../molecules/PersonBox/style';
import { StyledTextBox } from '../organisms/TextBox/style';
import { StyledFormGroup } from '../atoms/Form/styles';
import { StyledFormGroupInline } from '../atoms/InlineForm/styles';
import { bold } from '../theme/fonts';

export const StyledResultsContent = styled.section`
  padding-top: 3rem;
  background: ${BlueTheme.primary};
  min-height: calc(100vh - 6rem);
  margin-bottom: -3.5rem;
  padding-bottom: 3.5rem;
  
  ${mediaSmDefault} {
    padding-top: 3rem;
  }

  {/*
  &:before {
    top: 0;
    display: block;
    position: absolute;
    content: " ";
    width: 100%;
    height: 50rem;
    opacity: 0.8;
    background: linear-gradient(#1c3389 0%, rgba(14, 35, 112, 0) 100%);
  }
  */}

  ${Heading2} {
    text-align: center;
    font-weight: 400;
  }

  ${CustomText} {
    margin-top: 2rem;
    text-align: center;
  }

  ${Grid} {
    position: relative;
  }

  .btn-wrap {
    margin-top: 3rem;
    text-align: center;
  }

  .before-title {
    display: block;
    width: 100%;
    opacity: 0.95;
    font-size: 2.4rem;
    line-height: 3.2rem;
    text-align: center;
  }

  .main-popup-button {
    margin-top: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .help-link {
      margin-right: 1.5rem;
      position: relative;
      top: 0;
      right: 0;
    }

    span {
      color: ${yellow};
    }
  }

  .faq-popup-button {
    position: fixed;
    right: 1.5rem;
    bottom: 1.5rem;

    .help-link {
      margin-right: 0;

      ${mediaSmDefault} {
        width: 5rem;
        height: 5rem;
      }
    }

    ${mediaSmDefault} {
      right: 3rem;
      bottom: 3rem;
    }
  }

  ${StyledLinkBorder} {
    display: inline-block;
    justify-content: center;
    margin-top: 2rem;
    font-size: 1.6rem;
    line-height: 2rem;
  }

  .u--mobile {
    display: block;

    ${mediaMdDefault} {
      display: none !important;
    }
  }

  .u--desktop {
    display: none;

    ${mediaMdDefault} {
      display: block;
    }
  }

  .background {
    margin: 5rem -1.6rem -3.5rem;
    padding-top: 5rem;
    padding-bottom: 3rem;
    background-color: rgba(${darkBaseRGB}, 0.5);
    text-align: center;
    max-width: 129rem;

    ${mediaSmDefault} {
      border-radius: 0.4rem;
      padding-top: 3rem;
      margin: 2rem auto 0;
      box-shadow: 0 0.5rem 1rem 0 rgba(${darkBaseRGB}, 0.4);
    }
  }
`;

export const StyledHomepage = styled.section`
  ${Heading1} {
    margin-bottom: 4rem;
  }

  .bg-green {
    background: #009966;
  }

  .bg-blue {
    background: radial-gradient(circle at top, #247bcb 0%, #1769b4 100%);
  }

  .bg-white {
    background: #fff;
  }

  .bg-purple {
    background: radial-gradient(circle at top, #6624cb 0%, #5617b6 100%);
  }

  .bg-black {
    background: linear-gradient(180deg, #252b42 0%, #363e5b 100%);
  }

  .bg-gray {
    background: radial-gradient(circle at top, ${GrayTheme.primary} 0%, ${GrayTheme.darken} 100%);
  }

  .hp-reasons {
    ${mediaSmMax} {
      background: ${gray};
    }

    ${Heading2} {
      margin-top: 0;
      color: ${darkBaseHEX};

      ${mediaSmMax} {
        font-size: 2.4rem;
        line-height: 3.2rem;
      }
    }

    .list-wrapper {
      li {
        & > span {
          display: inline-flex;
          margin-right: 2rem;
        }
      }
    }

    .text-green,
    .text-blue {
      ${Heading2} {
        padding-top: 3rem;
        font-size: 1.8rem;
        line-height: 2.2rem;
        letter-spacing: 0.5px;
        font-weight: 600;

        ${mediaMdDefault} {
          padding-top: 0;
          font-size: 2.6rem;
          line-height: 3.9rem;
        }
      }

      .list-wrapper {
        ${mediaSmMax} {
          max-width: 75%;
          width: 100%;
        }

        li {
          ${mediaSmMax} {
            font-size: 1.6rem;
            line-height: 2rem;
            font-weight: 400;

            .list {
              margin-right: 1.6rem;
              min-width: 2rem;
              max-width: 2rem;
              height: 2rem;
            }
          }

          ${mediaMdDefault} {
            &:first-child {
              margin-top: 0;
            }
          }
        }
      }
    }

    .text-blue {
      svg {
        path {
          fill: ${primaryBlue};
        }
      }
    }
  }

  .hp-reasons-faq {
    text-align: center;

    img {
      display: block;
      margin: 4rem auto;
      width: 20rem;
    }
  }

  .hp-project-game-info {
    .etic {
      margin-top: 5rem;
      width: 9.8rem;
    }

    .institut {
      img {
        width: 25rem;
      }
    }

    ${Heading3} {
      margin-top: 3rem;
      margin-bottom: 3rem;
    }
  }

  ${Heading2} {
    font-weight: 400;
  }

  .institut {
    margin-top: 1rem;

    img {
      width: 12rem;
    }
  }
`;

export const StyledValueComparisonLanding = styled.section<{ isNotInApp?: boolean }>`
  margin-bottom: -3.5rem;
  
  ${props =>
    props.isNotInApp &&
    css`
      margin-top: -5rem;

      ${mediaSmDefault} {
        margin-top: 9rem;
      }
    `}
  
  .bg-fakeWhite {
    background: ${gray};
  }
  
  .bg-blue {
    background: radial-gradient(circle at top, #247bcb 0%, #1769b4 100%);
  }
  
  .bg-purple {
    background: radial-gradient(circle at top, #6624CB 0%, #5617B6 100%);
  }
  
  .bg-black {
    background: linear-gradient(180deg, #252B42 0%, #363E5B 100%);
  }
  
  .bg-green {
    background: radial-gradient(circle at top, #009966 0%, #008358 100%);
  }
  
  .value-page-merged {
    .blue {
      padding-bottom: 0;
    }
  }
  
  ${Heading2} {
    font-weight: 400;
  }

  ${StyledTextBox} {
    ${Heading3} {
      margin-top: 3rem;
      color: ${primaryGreen};
      font-size: 3.2rem;
      letter-spacing: 0.5px;
      line-height: 4.1rem;
      font-weight: 800;
      
      ${mediaSmDefault} {
        margin-top: 8rem;
      }
    }
    
    ${Heading3} ~ ${BiggerText} {
      margin-top: 2rem;
      color: ${selectColor};
    }

    ${BiggerText} {
      max-width: 96rem;
      margin-bottom: 3rem;
      

      ${mediaSmDefault} {
        margin-bottom: 6rem;
      }
    }
  }

  .alignCenter {
    ${Row as any} {
      align-items: center;
    }
  }

  .list-wrapper {
    padding-left: 0;

    li {
      &:first-child {
        margin-top: 0;
      }
    }

    a {
      color: ${primaryGreen};

      &:hover {
        span {
          border-bottom: 1px solid transparent;
        }
      }

      span {
        border-bottom: 1px solid ${primaryGreen};
        transition: border-color 0.3s;
      }
    }
  }
`;

export const StyledNotificationCenter = styled.section`
  display: block;

  ${Heading1} {
    margin-bottom: 4rem;
  }

  ul {
    display: block;
    margin-bottom: 2rem;
    text-align: center;

    ${mediaSmDefault} {
      text-align: left;
    }

    li {
      margin-right: 2rem;
      display: inline-block;
      font-size: 1.8rem;
      line-height: 2.2rem;
      font-weight: 500;
    }
  }

  ${StyledLink} {
    opacity: 0.8;
    color: ${gray};
    display: inline-block;
    font-weight: 400;

    span {
      border-bottom: 1px solid ${gray};
      transition: border 0.3s;
    }

    &:hover {
      span {
        border-bottom: 1px solid transparent;
      }
    }
  }
`;

export const StyledErrorPage = styled.section`
  text-align: center;

  ${mediaMdDefault} {
    text-align: left;
  }

  ${Heading1} {
    padding-top: 4rem;

    ${mediaSmDefault} {
      padding-top: 10rem;
    }
  }

  ${BiggerText} {
    margin-top: 4rem;
  }

  .subtext {
    display: block;
    margin-top: 2rem;
    margin-bottom: 4rem;
    opacity: 0.8;
    color: ${gray};
    font-size: 1.6rem;
    line-height: 2rem;
  }
`;

export const StyledRegistration = styled.section`
  text-align: center;
  max-width: 85rem;
  margin: 0 auto;

  .logo {
    width: 16.4rem;
  }

  ${Heading1} {
    margin-top: 6rem;
    margin-bottom: 4rem;
    text-align: center;

    ${mediaHonzaDefault} {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }

  .heading {
    margin-bottom: 3rem;

    ${Heading1} {
      margin-top: 2rem;
      margin-bottom: 2rem;
      line-height: 4rem;
    }

    p,
    ${StyledLink} {
      font-size: 1.6rem;
      line-height: 2rem;
    }

    img {
      margin-top: 3rem;
      max-width: 25rem;
    }
  }

  p {
    font-size: 2rem;
    line-height: 3rem;

    ${StyledLink} {
      font-size: 2rem;
      line-height: 3rem;

      span {
        color: ${yellow};
        border-color: ${yellow};
      }

      &:hover {
        span {
          border-color: transparent;
        }
      }
    }
  }

  ${BiggerText} {
    margin-bottom: 7rem;
  }

  .soc {
    padding-bottom: 1rem;
    margin-bottom: 4rem;
    border-bottom: 1px solid rgba(9, 89, 48, 0.5);
  }

  ${StyledFormGroup} {
    margin-bottom: 3rem;
  }

  ${StyledLink} {
    font-size: 1.6rem;
    color: ${gray};
    opacity: 0.8;

    span {
      border-bottom: 1px solid ${gray};
      transition: border 0.3s;
    }

    &:hover {
      span {
        border-bottom: 1px solid transparent;
      }
    }
  }

  ${StyledCheckboxInput} {
    ${StyledLink} {
      font-size: 2rem;
      color: ${yellow};
      opacity: 1;

      span {
        border-bottom: 1px solid ${yellow};
      }

      &:hover {
        span {
          border-bottom: 1px solid transparent;
        }
      }
    }
  }

  ${StyledPrimaryButtonButton} {
    display: block;
    margin: 3rem auto 3rem;
    max-width: 30rem;

    ${mediaHonzaDefault} {
      margin-top: 2rem;
    }
  }

  ${StyledCheckboxInput} {
    display: inline;

    .error {
      text-align: center;
    }
  }

  .anonymized {
    margin-top: 4rem;
    opacity: 0.8;
    color: #f4f4f6;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    font-style: italic;
  }

  .btn-box {
    margin-top: -1.5rem;
    margin-bottom: 1.5rem;

    ${StyledPrimaryButtonButton}, ${StyledSecondaryButtonLinkBigger} {
      display: inline-flex;
      margin: 1.5rem 1.5rem;

      ${mediaMdDefault} {
        width: 30rem;
      }
    }

    ${StyledSecondaryButtonLinkBigger} {
      border-radius: 3.5rem;
      padding-left: 3.8rem;
      padding-right: 3.8rem;

      ${mediaMdDefault} {
        padding-left: 4rem;
        padding-right: 4rem;
      }
    }
  }
`;

export const StyledProfileForm = styled.section`
  padding-top: 4rem;
  max-width: 85rem;
  margin: 0 auto;
  text-align: center;
  padding-left: 16px;
  padding-right: 16px;

  ${Heading1} {
    margin-top: 0;
    margin-bottom: 2rem;
    font-size: 2.4rem;
    letter-spacing: 0.5px;
    line-height: 3.1rem;
    text-align: center;
  }

  .map {
    margin-top: 4rem;

    img {
      max-width: 23rem;
      margin: 0 1rem;
    }

    .country {
      font-size: 2.4rem;
      line-height: 3.2rem;
    }

    .flex {
      margin-top: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;

      & > div {
        width: 19rem;
        font-size: 1.4rem;
        line-height: 1.8rem;

        ${mediaSmMax} {
          display: none;
        }
      }

      .circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 8rem;
        height: 8rem;
        margin: 0 auto 1rem;
        border-radius: 50%;
        background-color: rgba(${darkBaseRGB}, 0.23);

        svg {
          max-height: 4.8rem;
        }
      }
    }
  }

  form {
    margin-bottom: 3rem;
  }

  .form-inline-wrapper {
    margin-top: 4rem;
  }

  .form-inline {
    margin-top: 0;
  }

  .info {
    margin-top: 4rem;
  }

  ${StyledFormGroupInline} {
    input {
      border-bottom: 0.3rem solid rgba(71, 167, 255, 0.8);

      &:focus,
      &:hover {
        border-bottom: 0.3rem solid rgba(71, 167, 255, 1);
      }
    }

    {/*
    ${StyledCustomSelect} {
      .Select {
        border-bottom: 0.3rem solid rgba(71, 167, 255, 0.8);
      }

      .Select-arrow {
        border-color: ${blue} transparent transparent !important;
      }

      .Select.is-open > .Select-control .Select-arrow {
        border-color: ${blue} transparent transparent !important;
      }

      .Select-arrow-zone:hover .Select-arrow {
        border-top-color: ${blue};
      }
    }
    */}
  }

  .btn-box {
    display: flex;
    flex-direction: column;

    ${mediaSmDefault} {
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    & > div {
      margin-top: 2rem;

      &:first-child {
        margin-top: 0;
      }

      ${mediaSmDefault} {
        margin: 0 1.5rem;
      }
    }
  }

  .skip {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 4rem;
    height: 4rem;
    top: 3rem;
    right: 3rem;
    border: 2px solid ${yellow};
    border-radius: 50%;
  }
  
  .Select-placeholder {
    text-align: center;
  }
`;

export const StyledLogin = styled(StyledRegistration)`
  max-width: none;

  .error {
    color: ${yellow};
    margin-bottom: 1rem;

    ${mediaMdDefault} {
      text-align: left;
    }
  }

  ${Row as any} {
    position: relative;
  }

  ${Col as any} {
    &:nth-child(2) {
      ${mediaMdDefault} {
        display: flex;
        align-items: center;

        &:before {
          content: "";
          display: block;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 50%;
          height: 44rem;
          width: 1px;
          opacity: 0.5;
          background-color: #095930;
        }
      }
    }
  }

  .socials {
    margin-top: 3rem;
    display: block;
    width: 100%;

    ${mediaMdDefault} {
      margin-top: 0;
    }

    a {
      &:last-child {
        margin-bottom: 0;
      }

      span {
        font-size: 1.6rem;

        ${mediaLgDefault} {
          font-size: 2rem;
        }
      }
    }
  }

  ${StyledCheckboxInput} {
    ${mediaMdDefault} {
      display: block;
    }
  }

  .login-help {
    display: flex;
    align-items: center;
    margin-top: 3rem;
    margin-bottom: 3rem;
    justify-content: center;

    img {
      min-width: 3rem;
      margin-right: 1.5rem;
    }

    span {
      color: ${gray};
      font-size: 1.6rem;
      line-height: 2rem;
    }
  }
`;

export const StyledRegistrationFull = styled(StyledRegistration)`
  max-width: none;

  form {
    margin-top: 3rem;
  }

  ${CustomText} {
    text-align: center;
  }
`;

export const StyledIntro = styled.section`
  display: block;
  text-align: center;

  ${Heading1} {
    min-height: 9.1rem;
  }

  .intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    top: 0;
    margin-top: -6rem;
    margin-bottom: -3.5rem;
    position: relative;
    z-index: 10;
  }

  .eticia {
    margin-top: 2rem;
    max-width: 15rem;

    ${mediaSmDefault} {
      margin-top: 3rem;
      max-width: none;
    }

    ${mediaHonzaDefault} {
      margin-top: 2rem;
      max-width: 22rem;
    }
  }

  .img {
    width: 100%;
    max-width: 15rem;
    min-height: 15rem;
    margin: 3rem auto 0;
    position: relative;

    ${mediaSmDefault} {
      margin-top: 4rem;
      min-height: 30rem;
      max-width: 30rem;
    }

    ${mediaHonzaDefault} {
      margin-top: 2rem;
      min-width: 20rem;
      max-width: 20rem;
      min-height: 20rem;
      max-height: 20rem;

      img {
        max-width: 20rem;
      }
    }

    img {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      transition: opacity 1s;
    }
  }

  .slider-box {
    margin-top: 3rem;
    zoom: 0.7;

    ${mediaSmDefault} {
      margin-top: 4rem;
      zoom: 1;
    }

    ${mediaHonzaDefault} {
      margin-top: 2rem;
    }
  }

  .text {
    margin-top: 2rem;
    margin-bottom: 3rem;

    ${mediaSmDefault} {
      margin-top: 4rem;
      margin-bottom: 4rem;
    }

    ${mediaHonzaDefault} {
      margin-top: 3rem;
      margin-bottom: 2rem;
    }

    ${Heading2} {
      margin-bottom: 1rem;
      letter-spacing: 0.5px;
      font-size: 2.2rem;
      line-height: 2.8rem;

      ${mediaSmDefault} {
        margin-bottom: 3rem;
        line-height: 3.9rem;
        font-size: 3.2rem;
      }
    }

    .desc {
      color: ${gray};
      font-size: 1.8rem;
      line-height: 2.2rem;

      ${mediaSmDefault} {
        font-size: 2.4rem;
        line-height: 3rem;
      }
    }
  }

  .skip {
    position: static;
    transform: none;
    margin-bottom: 3rem;

    ${mediaMdDefault} {
      position: absolute;
      text-align: center;
      left: 50%;
      transform: translateX(-50%);
      bottom: 3rem;

      @media (max-height: 980px) {
        position: static;
        transform: none;
        margin-bottom: 3rem;
      }
    }

    ${StyledLink} {
      font-size: 1.6rem;
      color: ${gray};
      opacity: 0.8;

      span {
        border-bottom: 1px solid ${gray};
        transition: border 0.3s;
      }

      &:hover {
        span {
          border-bottom: 1px solid transparent;
        }
      }
    }
  }

  .slider-box {
    span {
      display: inline-block;
      cursor: pointer;
      vertical-align: middle;
      transition: opacity 0.3s;

      img {
        display: inline-block;
        transition: opacity 0.3s;
      }
    }

    .left {
      margin-right: 4.5rem;
    }

    .right {
      margin-left: 4.5rem;
    }

    .left,
    .right {
      opacity: 0.6;
      transition: opacity 0.3s;

      &:hover {
        opacity: 1;
      }
    }

    .dots {
      img {
        display: inline-block;
        margin: 0 1.5rem;
        padding: 2.5px;
        max-width: 2rem;
        opacity: 0.6;
        transition: opacity 0.3s, padding 0.3s;
      }

      img:hover {
        opacity: 1;
      }

      .active {
        padding: 0;
        opacity: 1;
      }
    }
  }

  .disabled {
    opacity: 0;
    pointer-events: none;
  }

  ${StyledPrimaryButtonButton} {
    margin-top: 2rem;

    ${mediaMdDefault} {
      margin-top: 4rem;
    }
  }
`;

export const StyledAbout = styled(StyledHomepage)`
  .person-boxes {
    margin-top: 6rem;

    ${StyledPersonBox} {
      margin-bottom: 3rem;

      ${mediaMdDefault} {
        margin-bottom: 0;
      }

      h3 {
        color: ${textGreen};
      }

      p {
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
      }

      img {
        ${mediaMdDefault} {
          max-width: 30rem;
          border-radius: 0;
        }
      }

      a {
        display: inline-block;
        margin-top: 2rem;
        color: ${yellow};
        font-size: 1.6rem;
        line-height: 2rem;

        span {
          border-bottom: 1px solid ${yellow};
          transition: border 0.3s;
        }

        &:hover {
          span {
            border-bottom: 1px solid transparent;
          }
        }
      }
    }

    .fake-link {
      display: inline-block;
      margin-top: 2rem;
      color: ${yellow};
      font-size: 1.6rem;
      line-height: 2rem;
      cursor: pointer;

      span {
        border-bottom: 1px solid ${yellow};
        transition: border 0.3s;
      }

      &:hover {
        span {
          border-bottom: 1px solid transparent;
        }
      }
    }
  }

  .team {
    ${StyledPersonBox} {
      margin-bottom: 2rem;
    }
  }

  .team-external {
    margin-top: 6rem;
    text-align: center;

    ${Heading3} {
      color: #fff;
      font-size: 2.6rem;
      letter-spacing: 0.5px;
      line-height: 3.3rem;
      font-weight: 500;
    }
  }

  .green-text {
    margin-top: 3rem;
    color: ${textGreen};

    strong {
      display: block;
      margin-bottom: 1rem;
      font-size: 2.6rem;
      letter-spacing: 0.5px;
      line-height: 3.3rem;
      text-align: center;
    }

    & > span {
      font-size: 1.8rem;
    }

    ${mediaSmDefault} {
      margin-top: 6rem;
      margin-bottom: 4rem;
    }
  }

  .timeline {
    padding-top: 6rem;

    ${Heading3} {
      margin-top: 4rem;

      &.blue {
        color: ${blue};
      }

      &.green {
        color: ${textGreen};
      }
    }

    .quote {
      margin-bottom: 3rem;
      opacity: 0.8;
      color: ${whiteFade};

      em {
        display: block;
        color: ${whiteFade};
        font-weight: 400;
        font-style: italic;
      }
    }

    img {
      max-width: 7.7rem;
    }
  }

  .btn-wrap {
    & > .main-popup-button > span,
    & > .main-popup-button > button,
    & > ${StyledPrimaryButtonLink}, & > .main-popup-button > ${StyledSecondaryButton} {
      margin: 2rem 1.5rem 0;
      width: 25rem;
      vertical-align: top;
    }
  }
`;

export const StyledJoin = styled(StyledHomepage)`
  .bg-green {
    ${Heading2} {
      margin-bottom: 5rem;
    }

    ${Heading3} {
      margin-bottom: 2rem;
      color: ${yellow};
      font-size: 2.6rem;
      font-weight: 500;
      letter-spacing: 0.5px;
      line-height: 3.3rem;
      text-align: center;
    }

    p {
      margin-bottom: 4rem;
      color: #fff;
      font-size: 1.8rem;
      line-height: 3rem;
      text-align: center;
    }

    .choose {
      ${Row as any} {
        justify-content: center;
      }

      ${Col as any} {
        margin-top: 5rem;

        &:first-child {
          margin-top: 4rem;
        }

        ${mediaSmDefault} {
          margin-top: 4rem;

          &:first-child {
            margin-top: 4rem;
          }
        }
      }
    }
  }

  .cards {
    margin-top: 4rem;

    ${mediaSmDefault} {
      margin-top: 10rem;
    }

    ${Col as any} {
      margin-top: 1.5rem;

      &:first-child {
        margin-top: 0;
      }

      ${mediaSmDefault} {
        margin-top: 0;
      }
    }

    .card {
      display: block;
      padding: 4rem 3rem;
      height: 100%;
      border-radius: 4px;
      box-shadow: 0 5px 10px 0 rgba(25, 29, 44, 0.4);
      text-align: center;
      transition: box-shadow 0.3s;
      cursor: pointer;

      &:hover {
        box-shadow: 0 5px 10px 0 rgba(25, 29, 44, 0.6);
      }

      strong {
        font-size: 2rem;
        letter-spacing: 0.5px;
        line-height: 2.6rem;
      }

      p {
        margin: 0 auto;
        max-width: 410px;
        color: #f4f4f6;
        font-size: 1.8rem;
        line-height: 2.7rem;
        text-align: center;
      }

      &.green {
        background: radial-gradient(at top, ${GreenTheme.primary}, ${GreenTheme.darken});
      }

      &.blue {
        background: radial-gradient(at top, ${BlueTheme.primary}, ${BlueTheme.darken});
      }

      img {
        border-radius: 0.4rem;
      }
    }

    .img-box {
      padding: 3rem 0;
      display: flex;
      align-items: center;
      justify-content: center;

      ${mediaSmDefault} {
        height: 253px;
      }
    }
  }

  form {
    margin-top: 6rem;
    margin-bottom: 4rem;

    label {
      display: none;
      color: ${gray};
      font-size: 2rem;
      line-height: 2.6rem;
      font-weight: 500;

      &.label {
        display: block;
        margin-bottom: 2rem;
      }
    }

    input {
      margin: 0 auto 3rem;
      max-width: 41rem;
    }

    textarea {
      display: block;
      max-width: 41rem;
      height: 20rem;
      margin: 0 auto 3rem;
    }
  }

  .institut-info {
    color: ${gray};

    .email {
      margin-top: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${yellow};
      font-size: 2.6rem;
      letter-spacing: 0.5px;
      line-height: 33px;
      font-weight: 500;

      span {
        margin-top: 0;
      }

      svg {
        margin-right: 3.3rem;
      }
    }

    ${Heading3} {
      margin-top: 6rem;
      margin-bottom: 2rem;
      display: block;
      color: #fff;
      font-size: 2.6rem;
      font-weight: 500;
      letter-spacing: 0.5px;
      line-height: 3.3rem;
    }

    ${BiggerText} {
      margin-top: 0;

      span {
        margin-top: 1rem;
        display: block;
        line-height: 3rem;

        &:first-child {
          margin-top: 0;
        }
      }

      .email {
        span {
          margin-top: 0;
        }
      }
    }
  }
`;

export const StyledAcceptInvite = styled.section`
  display: block;
  text-align: center;

  ${Heading1} {
    margin-bottom: 5rem;
    color: ${yellow};
    text-align: center;
  }

  ${CustomText} {
    max-width: 63rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 4rem;
    text-align: center;
    line-height: 3rem;
  }

  small {
    display: block;
    margin-top: 1.5rem;
    margin-bottom: 3rem;
    color: rgba(${grayRGB}, 0.5);
    font-size: 1.6rem;
    line-height: 2rem;
  }

  .logo {
    margin-top: 2rem;
    margin-bottom: 3rem;
  }
`;

export const StyledGeneralPage = styled.section`
  padding-top: 3rem;
  text-align: center;

  ${Heading2} {
    font-size: 2.4rem;
    line-height: 3.1rem;
    font-weight: ${bold};
  }

  ${Heading3} {
    margin-top: 2rem;
    font-size: 2rem;
    line-height: 2.6rem;
    font-weight: ${bold};

    & ~ ${SectionText} {
      margin-top: 2rem;
    }
  }
`;
