export type FlipCardProps = {
  img: string;
  title: string;
  frontDescription: string;
  backDescription: string;
  className: 'normal' | 'higher' | 'wider' | 'big' | string;
  link?: {
    type: 'link' | 'modal';
    path: string;
    text: string;
  };
  free?: boolean;
  plus?: boolean;
};

export const data: FlipCardProps[] = [
  {
    img: 'https://placehold.it/300x300',
    title: 'infoModal.flipcard.valueGame.title',
    frontDescription: 'infoModal.flipcard.valueGame.frontDescription',
    backDescription: 'infoModal.flipcard.valueGame.backDescription',
    link:{
      type: 'link',
      path: 'app.gameGame',
      text: 'infoModal.flipcard.valueGame.linkText'
    },
    className: 'wider',
    free: true,
    plus: true,
  },
  {
    img: 'https://placehold.it/300x300',
    title: 'infoModal.flipcard.lifeValues.title',
    frontDescription: 'infoModal.flipcard.lifeValues.frontDescription',
    backDescription: 'infoModal.flipcard.lifeValues.backDescription',
    className: 'normal',
    link: {
      type: 'modal',
      path: 'life-values-presenting',
      text: 'infoModal.flipcard.lifeValues.linkText'
    },
    free: true,
    plus: true,
  },
  {
    img: 'https://placehold.it/300x300',
    title: 'infoModal.flipcard.allLadders.title',
    frontDescription: 'infoModal.flipcard.allLadders.frontDescription',
    backDescription: 'infoModal.flipcard.allLadders.backDescription',
    className: 'higher',
    link: {
      type: 'modal',
      path: 'all-ladders',
      text: 'infoModal.flipcard.allLadders.linkText'
    },
    plus: true,
  },
  {
    img: 'https://placehold.it/300x300',
    title: 'infoModal.flipcard.compareMatrix.title',
    frontDescription: 'infoModal.flipcard.compareMatrix.frontDescription',
    backDescription: 'infoModal.flipcard.compareMatrix.backDescription',
    className: 'higher',
    link: {
      type: 'modal',
      path: 'ladders-compare-matrix',
      text: 'infoModal.flipcard.compareMatrix.linkText'
    },
    plus: true,
  },
  {
    img: 'https://placehold.it/300x300',
    title: 'infoModal.flipcard.valueWord.title',
    frontDescription: 'infoModal.flipcard.valueWord.frontDescription',
    backDescription: 'infoModal.flipcard.valueWord.backDescription',
    className: 'normal',
    link: {
      type: 'link',
      path: 'app.valueWord',
      text: 'infoModal.flipcard.valueWord.linkText'
    },
    free: true,
  },
  {
    img: 'https://placehold.it/300x300',
    title: 'infoModal.flipcard.valuesForTwo.title',
    frontDescription: 'infoModal.flipcard.valuesForTwo.frontDescription',
    backDescription: 'infoModal.flipcard.valuesForTwo.backDescription',
    className: 'wider',
    link: {
      type: 'link',
      path: 'app.valuesForTwo',
      text: 'infoModal.flipcard.valuesForTwo.linkText'
    },
    plus: true,
  },
  {
    img: 'https://placehold.it/300x300',
    title: 'infoModal.flipcard.journal.title',
    frontDescription: 'infoModal.flipcard.journal.frontDescription',
    backDescription: 'infoModal.flipcard.journal.backDescription',
    className: 'normal',
    link: {
      type: 'link',
      path: 'app.journal',
      text: 'infoModal.flipcard.journal.linkText'
    },
    plus: true,
  },
  {
    img: 'https://placehold.it/300x300',
    title: 'infoModal.flipcard.stats.title',
    frontDescription: 'infoModal.flipcard.stats.frontDescription',
    backDescription: 'infoModal.flipcard.stats.backDescription',
    className: 'normal',
    link: {
      type: 'link',
      path: 'app.stats',
      text: 'infoModal.flipcard.stats.linkText'
    },
    plus: true,
  },
];
