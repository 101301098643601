import * as React from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Heading1, Heading2 } from '../../../../../atoms/Headings';
import { StyledComparisonSetupPopup, StyledGamesConfiguration } from '../style';
import { CustomText, SectionText } from '../../../../../atoms/Text';
import { PrimaryButton } from '../../../../../atoms/Buttons';
import { StyledLinkBorder } from '../../../../../atoms/Buttons/styles';
import { ColoredBoxLink, ColoredBoxLinkHeading } from '../../LandingPage/style';
import { createUrl } from '../../../../routes';
import SVGresultTwo from '../../../../../atoms/SVGIcons/result/SVGresultTwo';
import { BackgroundedBox } from '../../Comparisons/styles';
import FAQPopup from '../../../../../atoms/Popups/FAQPopup';
import { Box } from '@xcorejs/ui';
import PopupBase from '../../../../../atoms/Popups/PopupBase';
import { FC } from 'react';

interface IChooseTest {
  submit: () => any;
}

export const PartnersPopup = () => (
  <PopupBase color={'purple'} button={'popup.btn.close'} maxWidth='41rem'>
    <StyledComparisonSetupPopup>
      <Box maxWidth='14rem' mx='auto'>
        <img src='/img/vlc/typy/partnersky@3x.png' alt='' />
      </Box>

      <Heading2 color='#fff'>
        <FormattedMessage id={'gamesForTwo.partners'} />
      </Heading2>

      <SectionText>
        <FormattedHTMLMessage id={'gamesForTwo.partners.info'} />
      </SectionText>

      <CustomText>
        <FormattedHTMLMessage id='gamesForTwo.partners.quote' />
      </CustomText>
    </StyledComparisonSetupPopup>
  </PopupBase>
);

export const FriendsPopup = () => (
  <PopupBase color={'green'} button={'popup.btn.close'} maxWidth='41rem'>
    <StyledComparisonSetupPopup>
      <Box maxWidth='14rem' mx='auto'>
        <img src='/img/vlc/typy/pratelsky@3x.png' alt='' />
      </Box>

      <Heading2 color='#fff'>
        <FormattedMessage id={'gamesForTwo.friends'} />
      </Heading2>

      <SectionText>
        <FormattedHTMLMessage id='gamesForTwo.friends.info' />
      </SectionText>

      <CustomText>
        <FormattedHTMLMessage id='gamesForTwo.friends.quote' />
      </CustomText>
    </StyledComparisonSetupPopup>
  </PopupBase>
);

const ChooseTest: FC<IChooseTest> = ({ submit }) => (
  <Col xs={12} md={8} lg={6}>
    <StyledGamesConfiguration>
      <Heading1 className='welcome-heading'>
        <SVGresultTwo />
        <FormattedMessage id={'config.chooseTest.heading'} />
      </Heading1>

      <BackgroundedBox style={{ marginTop: '3rem', marginBottom: '3rem', background: 'rgba(11, 12, 28, 0.5)' }}>
        <SectionText opacity={true} style={{ marginTop: 0 }}>
          <FormattedHTMLMessage id={'config.chooseTest.text'} />
        </SectionText>
      </BackgroundedBox>

      <Heading1 as='h2'>
        <FormattedMessage id={'config.chooseTest.heading2'} />
      </Heading1>

      <CustomText>
        <FormattedHTMLMessage id={'config.chooseTest.text2'} />
      </CustomText>

      <div className={'boxes'}>
        <Row>
          <Col xs={12} sm={12}>
            <ColoredBoxLink
              background={'new-purple'}
              isLink={false}
              isSelected={true}
            >
              <img src='/img/vlc/typy/partnersky@3x.png' alt='' />

              <ColoredBoxLinkHeading>
                <FormattedMessage id={'gamesForTwo.partners'} />
              </ColoredBoxLinkHeading>

              <CustomText>
                <FormattedMessage id={'gamesForTwo.partners.text'} />
              </CustomText>
            </ColoredBoxLink>

            <Box mt='2rem'>
              <FAQPopup popup={PartnersPopup}>
                <SectionText color='#8e45ff'>
                  <FormattedMessage id={'gamesForTwo.moreInfo'} />
                </SectionText>
              </FAQPopup>
            </Box>
          </Col>
        </Row>

        <PrimaryButton onClick={() => submit()} isUnvisible={false}>
          <FormattedMessage id={'config.next'} />
        </PrimaryButton>

        <div className={'back'}>
          <StyledLinkBorder to={createUrl('app.comparison') + '/moje-porovnani'} color='yellow'>
            <FormattedMessage id={'config.back'} />
          </StyledLinkBorder>
        </div>
      </div>
    </StyledGamesConfiguration>
  </Col>
);

export default ChooseTest;
