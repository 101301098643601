import { ICharacter, IValueTestResultDetail } from 'config/api/valueTestResult';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Flex, Paragraph, Img, Heading2 } from '@xcorejs/ui';
import ValuesGraph from '../../../../../molecules/Results/Graph/ValuesGraph';
import { IColorTheme } from '../../../../../theme/colors';
import isIE from '../../../../../../utils/ie11';
import { PlayButton } from '../../../../../atoms/Buttons';
import VimeoPopup from '../../../../../atoms/Popups/VimeoPopup';
import SVGplay from '../../../../../atoms/SVGIcons/SVGplay';
import withPopups, { IWithPopups } from '../../../../../molecules/withRedux/withPopups';
import { PremiumButton } from '../../../../../components/Button';
import { FC, useContext } from 'react';
import { GameContext } from 'components/App';

interface ResultGraphProps {
  result: IValueTestResultDetail;
  heading: string | null;
  description: string | null;
  theme: IColorTheme;
  videoAboutGraph?: boolean;
  plusOrVisible?: boolean;
}

const ResultGraph: FC<ResultGraphProps & IWithPopups> = (
  {
    result,
    popups,
    heading,
    description,
    videoAboutGraph,
    theme,
    plusOrVisible
  }
) => {
  const { eticiaPlus } = useContext(GameContext);

  return (
    <React.Fragment>
      <Heading2 mt={{ _: '6rem', sm: '2rem' }} textAlign='center'>
        {heading}
      </Heading2>

      {description && (
        <Paragraph mt='1rem' color='rgba(255, 255, 255, 0.7)' textAlign='center'>
          {description}
        </Paragraph>
      )}

      {eticiaPlus || plusOrVisible ? (
        <Box mt='3rem'>
          <ValuesGraph
            points={[
              {
                points: result.characters.map(x => x.percentage),
                fill: theme.darken,
                fillOpacity: '0.8',
                stroke: theme.graphContour,
                strokeWidth: '3',
                animate: isIE()
                  ? undefined
                  : {
                    duration: '1.5s',
                    from: '200',
                    fill: 'freeze',
                  },
              },
            ]}
            characters={result.characters}
            backgroundIndex={0}
            onlyTopPercentage={!eticiaPlus}
          />
        </Box>
      ) : (
        <Box position='relative' mt='3rem'>
          <Img src='/img/graphs.png' alt='' />

          <Flex
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
            position='absolute'
            top='50%'
            left='50%'
            transform='translateX(-50%) translateY(-50%)'
            width='100%'
          >
            <Paragraph>
              Graf je možné zobrazit<br />
              <strong>pouze ve verzi Eticia Plus</strong>
            </Paragraph>

            <PremiumButton mt='2rem' whiteSpace='nowrap' />
          </Flex>
        </Box>
      )}

      {videoAboutGraph && (
        popups && (
          <Box mt='3rem'>
            <PlayButton type='button' onClick={() => popups.custom(() => <VimeoPopup id={'376328496'} muted={false} />)}>
              <SVGplay />
              <FormattedMessage id={'test.result.graph.video'} />
            </PlayButton>
          </Box>
        )
      )}
    </React.Fragment>
  );
};

export default withPopups(ResultGraph);
