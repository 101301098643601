import styled, { css } from 'styled-components';
import { mediaSmDefault, mediaLgDefault } from '../../../../theme/grid';
import { StyledPrimaryButton, StyledPrimaryButtonLink } from '../../../../atoms/Buttons/styles';
import { PaymentMethod } from '../Comparisons/styles';
import { Heading1 } from '../../../../atoms/Headings/index';
import { CustomText } from '../../../../atoms/Text/index';
import { Link } from 'react-router-dom';
import { yellow, darkBaseRGB, GreenTheme, RedTheme, primaryGreen } from '../../../../theme/colors';

export const Flex = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
  margin-bottom: -1rem;

  ${mediaLgDefault} {
    flex-direction: row;
  }

  ${StyledPrimaryButton}, ${StyledPrimaryButtonLink} {
    margin-top: 0 !important;
    margin-bottom: 2rem;

    ${mediaSmDefault} {
      margin-right: 3rem;
      margin-bottom: 0;
    }
  }

  ${PaymentMethod} {
    margin: 0;
  }
`;

export const Left = styled.div`
  ${mediaSmDefault} {
    text-align: left;

    ${Heading1} {
      text-align: left !important;
    }

    ${CustomText} {
      text-align: left !important;
    }
  }

  ul {
    margin-top: 4rem;
    margin-bottom: 4rem;

    li {
      margin-top: 2rem;

      &:first-child {
        margin-top: 0;
      }

      &:before {
        content: url("/img/check_yellow.svg");
        display: inline-block;
        margin-right: 2rem;
      }
    }
  }
`;

export const MoreLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  color: ${yellow};
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.1rem;
  text-align: left;

  &:hover {
    svg {
      transform: translateX(1rem);
    }
  }

  span {
    margin-right: 2rem;
  }

  svg {
    width: 3.2rem;
    height: 1.5rem;
    transition: transform 0.3s;
  }
`;

export const ColoredBoxLink = styled.div<{ background: 'red' | 'green' | 'new-purple' | 'new-green'; isDisabled?: boolean; isSelected?: boolean; isLink?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
  margin-top: 1rem;
  box-shadow: 0 0.5rem 1rem 0 rgba(${darkBaseRGB}, 0.4);
  border-radius: 0.4rem;
  text-align: center;
  transition: box-shadow 0.3s;

  &:first-child {
    margin-top: 0;
  }

  ${mediaSmDefault} {
    display: flex;
    flex-direction: row;
    text-align: left;
  }

  ${props =>
    props.background === 'red' &&
    css`
      background: ${RedTheme.primary};
      border: 2px solid ${RedTheme.darken};
    `};

  ${props =>
    props.background === 'green' &&
    css`
      background: ${GreenTheme.primary};
      border: 2px solid ${GreenTheme.darken};
    `};

  ${props =>
    props.background === 'new-purple' &&
    css`
      border: 2px solid #5d1dc0;
    `};

  ${props =>
    props.background === 'new-green' &&
    css`
      border: 2px solid ${primaryGreen};
    `};

  ${props =>
    props.isDisabled === true &&
    css`
      opacity: 0.5;
    `};

  ${props =>
    props.isSelected === true &&
    css`
      border: 2px solid ${yellow};
    `};

  ${props =>
    props.isLink === true &&
    css`
      &:hover {
        box-shadow: 0 0.5rem 1rem 0 rgba(${darkBaseRGB}, 0.8);

        ${props.isSelected &&
          css`
            box-shadow: 0 0.5rem 1rem 0 rgba(${darkBaseRGB}, 0.4);
          `};

        ${props.isDisabled &&
          css`
            box-shadow: 0 0.5rem 1rem 0 rgba(${darkBaseRGB}, 0.4);
          `};
      }
    `};

  img {
    margin-bottom: 2rem;

    ${mediaSmDefault} {
      margin-right: 3rem;
      margin-bottom: 0;
    }
  }

  ${CustomText} {
    max-width: 100%;
  }
`;

export const ColoredBoxLinkHeading = styled.strong`
  margin-top: 3rem;
  margin-bottom: 0;
  color: #fff;
  font-size: 2rem;
  letter-spacing: 0.5px;
  line-height: 2.6rem;
  text-align: center;
`;
