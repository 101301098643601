import * as React from 'react';
import { FC } from 'react';
import { createUrl } from '../../routes';
import Subnav from '../../../atoms/Subnav';
import NavItem from '../../../atoms/Nav/NavItem';
import { Col, Grid, Row } from 'react-styled-flexboxgrid';
import { SectionText } from '../../../atoms/Text';
import { Heading2 } from '../../../atoms/Headings';
import { StyledGeneralPage } from '../../styles';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import FaqBox from './components/Box';
import {
  FAQ1,
  FAQ10,
  FAQ11,
  FAQ12,
  FAQ2,
  FAQ3,
  FAQ4,
  FAQ5,
  FAQ6,
  FAQ7,
  FAQ8,
  FAQ9,
} from '../../../atoms/Popups/FAQPopup/popups';
import FAQPopup from '../../../atoms/Popups/FAQPopup';
import { StyledFaqBoxes } from './components/styles';
import { StyledFakeLinkBorder } from '../../../atoms/Buttons/styles';

const Faq: FC = () => {
  const baseUrl = createUrl('app.faq');
  return (
    <div>
      <Subnav>
        <NavItem to={baseUrl} exact label='faq.menu' />
      </Subnav>

      <StyledGeneralPage>
        <Grid>
          <Row>
            <Col xs={12}>
              <Heading2>
                <FormattedMessage id='faq.heading' />
              </Heading2>

              <SectionText>
                <FormattedMessage id='faq.text' />
              </SectionText>
            </Col>
          </Row>

          <StyledFaqBoxes>
            <Row>
              <Col xs={12} md={6} lg={3}>
                <FormattedMessage id='faq.faq1.title'>
                  {message => (
                    <FaqBox iconColor='green' heading={message as string} icon='/img/faq/game.svg'>
                      {[...Array(3)].map((i, index) => (
                        <FAQPopup popup={FAQ1} step={index.toString()} key={i}>
                          <StyledFakeLinkBorder color='blue' withoutAfterBorder={true}>
                            <FormattedHTMLMessage id={'faq.faq1.subtitle.' + index} />
                          </StyledFakeLinkBorder>
                        </FAQPopup>
                      ))}
                    </FaqBox>
                  )}
                </FormattedMessage>
              </Col>

              <Col xs={12} md={6} lg={3}>
                <FormattedMessage id='faq.faq2.title'>
                  {message => (
                    <FaqBox iconColor='green' heading={message as string} icon='/img/faq/ladder.svg'>
                      {[...Array(3)].map((i, index) => (
                        <FAQPopup popup={FAQ2} step={index.toString()} key={i}>
                          <StyledFakeLinkBorder color='blue' withoutAfterBorder={true}>
                            <FormattedHTMLMessage id={'faq.faq2.subtitle.' + index} />
                          </StyledFakeLinkBorder>
                        </FAQPopup>
                      ))}
                    </FaqBox>
                  )}
                </FormattedMessage>
              </Col>

              <Col xs={12} md={6} lg={3}>
                <FormattedMessage id='faq.faq3.title'>
                  {message => (
                    <FaqBox iconColor='green' heading={message as string} icon='/img/faq/main-profile.svg'>
                      {[...Array(3)].map((i, index) => (
                        <FAQPopup popup={FAQ3} step={index.toString()} key={i}>
                          <StyledFakeLinkBorder color='blue' withoutAfterBorder={true}>
                            <FormattedHTMLMessage id={'faq.faq3.subtitle.' + index} />
                          </StyledFakeLinkBorder>
                        </FAQPopup>
                      ))}
                    </FaqBox>
                  )}
                </FormattedMessage>
              </Col>
              <Col xs={12} md={6} lg={3}>
                <FormattedMessage id='faq.faq4.title'>
                  {message => (
                    <FaqBox iconColor='green' heading={message as string} icon='/img/faq/diary.svg'>
                      {[...Array(3)].map((i, index) => (
                        <FAQPopup popup={FAQ4} step={index.toString()} key={i}>
                          <StyledFakeLinkBorder color='blue' withoutAfterBorder={true}>
                            <FormattedHTMLMessage id={'faq.faq4.subtitle.' + index} />
                          </StyledFakeLinkBorder>
                        </FAQPopup>
                      ))}
                    </FaqBox>
                  )}
                </FormattedMessage>
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={6} lg={3}>
                <FormattedMessage id='faq.faq5.title'>
                  {message => (
                    <FaqBox iconColor='blue' heading={message as string} icon='/img/faq/scope.svg'>
                      {[...Array(3)].map((i, index) => (
                        <FAQPopup popup={FAQ5} step={index.toString()} key={i}>
                          <StyledFakeLinkBorder color='blue' withoutAfterBorder={true}>
                            <FormattedHTMLMessage id={'faq.faq5.subtitle.' + index} />
                          </StyledFakeLinkBorder>
                        </FAQPopup>
                      ))}
                    </FaqBox>
                  )}
                </FormattedMessage>
              </Col>

              <Col xs={12} md={6} lg={3}>
                <FormattedMessage id='faq.faq6.title'>
                  {message => (
                    <FaqBox iconColor='blue' heading={message as string} icon='/img/faq/values-2.svg'>
                      {[...Array(3)].map((i, index) => (
                        <FAQPopup popup={FAQ6} step={index.toString()} key={i}>
                          <StyledFakeLinkBorder color='blue' withoutAfterBorder={true}>
                            <FormattedHTMLMessage id={'faq.faq6.subtitle.' + index} />
                          </StyledFakeLinkBorder>
                        </FAQPopup>
                      ))}
                    </FaqBox>
                  )}
                </FormattedMessage>
              </Col>

              <Col xs={12} md={6} lg={3}>
                <FormattedMessage id='faq.faq7.title'>
                  {message => (
                    <FaqBox iconColor='blue' heading={message as string} icon='/img/faq/stats.svg'>
                      {[...Array(3)].map((i, index) => (
                        <FAQPopup popup={FAQ7} step={index.toString()} key={i}>
                          <StyledFakeLinkBorder color='blue' withoutAfterBorder={true}>
                            <FormattedHTMLMessage id={'faq.faq7.subtitle.' + index} />
                          </StyledFakeLinkBorder>
                        </FAQPopup>
                      ))}
                    </FaqBox>
                  )}
                </FormattedMessage>
              </Col>
              <Col xs={12} md={6} lg={3}>
                <FormattedMessage id='faq.faq8.title'>
                  {message => (
                    <FaqBox heading={message as string} icon='/img/faq/premium.svg'>
                      {[...Array(3)].map((i, index) => (
                        <FAQPopup popup={FAQ8} step={index.toString()} key={i}>
                          <StyledFakeLinkBorder color='blue' withoutAfterBorder={true}>
                            <FormattedHTMLMessage id={'faq.faq8.subtitle.' + index} />
                          </StyledFakeLinkBorder>
                        </FAQPopup>
                      ))}
                    </FaqBox>
                  )}
                </FormattedMessage>
              </Col>
            </Row>
          </StyledFaqBoxes>
        </Grid>
      </StyledGeneralPage>
    </div>
  );
};

export default Faq;
