import { IValueResult, IValueTestResultDetail } from 'config/api/valueTestResult';
import { API_URL } from '../apiRoutes';

export const VIRTUAL_VALUE_TEST_DETAIL = API_URL + '/virtual-value-test-result/last';

export interface IVirtualResult {
  id: string;
  yearFrom: number;
  yearTo: number;
  doneAt: number;
  lifeValues: IValueResult[];
}

export interface IVirtualTestDetail {
  virtualResult: IVirtualResult;
  oldValueTestResult: IValueTestResultDetail;
  currentValueTestResult: IValueTestResultDetail;
  valueMatch: number;
}