import { CURRENT_PROFILE_DETAIL, LOGOUT } from 'config/apiRoutes';
import { fetchTask } from 'utils/helpers';
import { IUserInfo } from '../User';
import { jsonFetch } from 'utils/fetch';
import { createUrl } from '../../components/pages/routes';
import { createRescourceActions } from '../../utils/rescource';

export const userActions = createRescourceActions<IUserInfo>('user');

export const fetchUserInfo = () => (dispatch: any) =>
  fetchTask<IUserInfo>(CURRENT_PROFILE_DETAIL, userActions.fetching, userActions.success, userActions.fail)(dispatch);

export const logout = () => jsonFetch(LOGOUT, { method: 'POST' }).then(() => location.href = createUrl(''));

export interface IProfileData {
  sex: number;
  yearOfBirth: number;
  city: string;
  maritalStatus: number;
  education: number;
  country: { name: string; value: string };
}
