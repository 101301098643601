import styled from 'styled-components';
import { Box } from '@xcorejs/ui';
import { yellow } from 'components/theme/colors';
import { Link } from 'react-router-dom';

export const SelectStyle = styled(Box)`
  .Select.is-focused:not(.is-open) > .Select-control,
  .Select.is-focused > .Select-control,
  .Select-control {
    background-color: rgb(200, 200, 210); // pozadí input okna a aktuálně vybrané položky při rozbalení seznamu
  }

  .Select-control {
    text-align: center;
    padding: 1.1rem 2rem 0.7rem;
    margin-top: 1rem;
  }
  
  .Select-placeholder {
    color: rgba(11, 12, 28, 0.8);
    font-size: 15px;
    line-height: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-right: 5rem;
    padding-left: 5rem;
  }

  .Select-input,
  .Select-control .Select-input:focus {
    background: transparent; // čára uprostřed input okna
  }
  
  .Select-menu-outer {
    padding: 1.5rem 3rem;
  }
  
  .Select-option {
    font-size: 1.5rem;
    line-height: 3rem;
    padding: 0;
    font-weight: 400;
    display: flex;
    justify-content: center;
  }

  .Select-option--left {
    text-align: left;
  }
  
  .Select-option--right {
    text-align: right;
    margin-left: auto;
  }
  
  .Select-arrow {
    border-color: rgba(11, 12, 28, 0.8);
  }
`;

type StyledLinkProps = {
  color?: string;
};

export const StyledLink = styled(Link)<StyledLinkProps>`
  margin-top: 2rem;
  align-self: center;
  color: ${({ color }) => color || yellow};
  text-decoration: underline;
  transition: text-decoration-color 0.3s ease-in-out;
  
  &:hover {
    text-decoration: underline;
    text-decoration-color: transparent;
  }
`;
