import * as React from 'react';
import { createUrl } from '../../routes';
import Subnav from '../../../atoms/Subnav';
import { Route, withRouter, RouteComponentProps, Switch } from 'react-router-dom';
import Comparisons from './Comparisons';
import NavItem from '../../../atoms/Nav/NavItem';
import OrderError from './OrderError';
import ValueComparisonLanding from '../../ValueComparisonLanding';
import withUser from '../../../molecules/withRedux/withUser';
import { IWithUser } from '../../../molecules/withRedux/withUser';
import { isReadable } from '../../../../utils/rescource';
import AddVoucher from './Vouchers/components/AddVoucher';
import SuccessGift from './Gift/SuccessGift';
import { ShopPathnames } from './routes';

const ValueComparison: React.FC<IWithUser & RouteComponentProps> = ({ rescource, location, history }) => {
  const baseUrl = createUrl('app.comparison');

  React.useEffect(() => {
    if (!isReadable(rescource.user) && ShopPathnames.filter(x => location.pathname.includes(x)).length === 0) {
      history.push(createUrl('login'), {
        redirect: location.pathname + location.search + location.hash,
      });
    }
  });

  return (
    <>
      {isReadable(rescource.user) && (
        <Subnav>
          <NavItem to={baseUrl} label={'comparison.menu.gamesFor2'} exact />
          <NavItem to={baseUrl + '/moje-porovnani'} label={'comparison.menu.myComparison'} />
          {/*
          <NavItem to={baseUrl + '/seznam-voucher'} label={'comparison.menu.voucher'} />
          <NavItem to={baseUrl + '/darkovy-balicek'} label={'comparison.menu.package'} />
          */}
        </Subnav>
      )}

      <Switch>
        <Route path={baseUrl} exact={true} render={() => <ValueComparisonLanding />} />
        <Route path={baseUrl + '/moje-porovnani'} exact={true} render={() => <Comparisons />} />
        <Route path={baseUrl + '/objednavka-neuspech'} exact={false} render={() => <OrderError voucher={false} />} />
        <Route path={baseUrl + '/voucher-neuspech'} exact={false} render={() => <OrderError voucher={true} />} />
        <Route path={baseUrl + '/darek-neuspech'} exact={false} render={() => <OrderError gift={true} />} />
        <Route
          path={baseUrl + '/darkovy-balicek/platba'}
          exact={true}
          render={() => <SuccessGift delivery={false} />}
        />
        <Route
          path={baseUrl + '/darkovy-balicek/dobirka'}
          exact={true}
          render={() => <SuccessGift delivery={true} />}
        />
        <Route path={baseUrl + '/darkovy-balicek/pridat'} exact={true} component={AddVoucher} />
      </Switch>
    </>
  );
};

export default withUser(withRouter(ValueComparison), false);
