import { Flex, Grid, Heading2, Heading3 } from '@xcorejs/ui';
import { RequestContext } from 'components/atoms/RequestContext';
import { AllLaddersData } from 'components/components/modals/AllLaddersModalBeta';
import { Button } from 'components/pages/Homepage/styles';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { IUserInfo } from 'store/User';
import LadderCard from './LadderCard';

export interface LaddersProps {
  user?: IUserInfo;
}

const Ladders: FC<LaddersProps> = ({ user }) => {
  const [allLaddersVisible, setAllLaddersVisible] = React.useState(false);
  
  return ( 
    <Flex
      as='section'
      flexDirection='column'
      alignItems='center'
      bg='linear-gradient(to bottom, #ece9e6, #ffffff)'
    >
      <Heading2
        color='#222'
        mt='2rem'
      >
        <FormattedMessage id='eticiaPlus.ladders.heading' />
      </Heading2>

      {AllLaddersData.map((group, i) => (
        <RequestContext key={i}>
          {({ locale }) => (

            <Flex
              flexDirection='column'
              display={i <= 1 ? 'flex' : allLaddersVisible ? 'flex' : 'none'}
            >
              <Heading3
                mt='4rem'
                color='#222'
              >
                {group.heading[locale]}
              </Heading3>

              <Grid
                columns={{ _: '1fr', xs:'repeat(2, 1fr)', md: `repeat(${i === 0 || allLaddersVisible ? group.ladders.length : 2}, minmax(0, 240px))` }}
                rows='auto'
                bg={group.background}
                mt='3rem'
              >
                {group.ladders.map((ladder, j) => (
                  <LadderCard 
                    display={i === 0  || i === 1 && j <= 1 ? 'flex' : allLaddersVisible ? 'flex' : 'none'}
                    key={i.toString() + j.toString()}
                    ladder={{
                      ...ladder,
                      text: ladder.text[locale],
                      heading: ladder.heading[locale],
                    }}
                    loggedIn={typeof user === 'object' ? true : false}
                  />
                ))}
              </Grid>
            </Flex>
          )}
        </RequestContext>
      ))}

      {!allLaddersVisible ? (
        <Button 
          mt='2rem'
          onClick={() => setAllLaddersVisible(true)}
          position='relative'
          style={{ transform: 'translateY(1rem)' }}
        >
          <FormattedMessage id='eticiaPlus.ladders.button' />
        </Button>
      ) : null}
    </Flex>
  );
};
 
export default Ladders;
