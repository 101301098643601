import * as React from 'react';
import ExternalSVG from '../ExternalSVG';
import { ReactNode } from 'react';

const CardBody: React.FC<{
  index?: number;
  img?: string;
  svg?: string;
  badge?: string;
  name: string | ReactNode;
  onClick?: () => any;
  position?: ReactNode;
}> = ({ img, svg, badge, name, onClick, position, index }) => (
  <>
    {index && <small className='index'>{index}. </small>}
    {img && !svg && <img src={img} alt='' />}
    {svg && <ExternalSVG src={svg} />}
    {badge && <small className='top'>{badge}</small>}
    <span onClick={onClick ? onClick : undefined}>{name}</span>
    {position !== undefined && position}
  </>
);

export default CardBody;
