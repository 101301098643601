import { Link } from '@xcorejs/ui';
import LoadingSpinnerLocal from 'components/atoms/Animations/LoadingSpinnerLocal';
import { createUrl } from 'components/pages/routes';
import { yellowWhite } from 'components/theme/colors';
import React, { FC, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useFetchSurvey } from 'utils/useQuery/queryFetchHelpers';

interface ResultLinkProps {
  id: string;
}

const ResultLink: FC<ResultLinkProps> = ({ id }) => {
  const resultsQuery = useFetchSurvey(id);

  const history = useHistory();

  if (resultsQuery.isLoading)
    return <LoadingSpinnerLocal />;

  if (resultsQuery.isError)
    return <></>;

  const results = resultsQuery.data!;
    
  return ( 
    <Fragment>
      {results.find(_ => true) && (
        <Link
          v='underline'
          color={yellowWhite}
          borderColor={yellowWhite}
          mt='2rem'
          fontSize='1.4rem'
          lineHeight='2rem'
          mx='auto'
          maxWidth='24rem'
          onClick={() =>
            history.push(createUrl('app.surveyResult', {
              id: results.find(_ => true)!.surveyResultId,
            }))
          }
        >
          <FormattedMessage id='valuePage.valueCard.results.link' />
        </Link>
      )}
    </Fragment>
  );
};
 
export default ResultLink;