import { Box, Flex, Heading3, Img, Link, Paragraph, Typography } from '@xcorejs/ui';
import { PremiumButton } from 'components/components/Button';
import { CompareConfig } from 'components/components/Compare/data';
import ValuesGraph from 'components/molecules/Results/Graph/ValuesGraph';
import { GraphLegend } from 'components/organisms/PartnerTest/Result/graphLegend';
import { IValueTestResultDetail } from 'config/api/valueTestResult';
import { IVirtualResult } from 'config/api/virtualValueTestDetail';
import * as React from 'react';
import { FC, useState } from 'react';

interface Block2GraphsProps {
  visible: boolean;
  valueTestResultA: IValueTestResultDetail;
  valueTestResultB: IValueTestResultDetail;
  virtualResult?: IVirtualResult;
  compareConfig?: CompareConfig;
}

const Block2Graphs: FC<Block2GraphsProps> = (
  {
    visible,
    valueTestResultA,
    valueTestResultB,
    virtualResult,
    compareConfig
  }
) => {
  const [graphLegendVisible, setGraphLegendVisible] = useState(false);

  return (
    <Flex
      flexDirection='column'
      alignItems='center'
      mt='6rem'
    >
      <Flex
        border='2px solid rgba(255,255,255,0.3)'
        width='5rem'
        height='5rem'
        alignItems='center'
        justifyContent='center'
        borderRadius='50%'
      >
        <Typography fontSize='3rem' lineHeight='3rem'>
          2
        </Typography>
      </Flex>

      <Heading3 mt='2rem'>
        Porovnání grafů hodnotových preferencí
      </Heading3>

      <Typography mt='1rem' textAlign='center'>
        Rozdíly v grafech ukazují na změny tvých hodnotových postojů a mohou taktéž ukazovat na cíle či
        přání, ke kterým směřuješ.
      </Typography>

      <Box mt='4rem'>
        {visible ? (
          <ValuesGraph
            points={[
              {
                points: valueTestResultB.characters.map(x => x.percentage),
                fill: '#2B406B',
                fillOpacity: '0.8',
                stroke: '#fff',
                strokeWidth: '2px',
              },
              {
                points: valueTestResultA.characters.map(x => x.percentage),
                fill: '#3e8f74',
                fillOpacity: '0.8',
                stroke: '#fff',
                strokeWidth: '2px',
              },
            ]}
            backgroundIndex={0}
          />
        ): (
          <Box position='relative' mt='3rem'>
            <Img src='/img/graphs.png' alt='' />

            <Flex
              flexDirection='column'
              alignItems='center'
              justifyContent='center'
              position='absolute'
              top='50%'
              left='50%'
              transform='translateX(-50%) translateY(-50%)'
              width='100%'
            >
              <Paragraph>
                Graf je možné zobrazit<br />
                <strong>pouze ve verzi Eticia Plus</strong>
              </Paragraph>

              <PremiumButton mt='2rem' whiteSpace='nowrap' />
            </Flex>
          </Box>
        )}

        <Flex justifyContent='center' mt='3rem'>
          <Flex
            flexDirection='column'
            display='inline-flex'
          >
            <Flex alignItems='center'>
              <Box
                width='2.4rem'
                height='2.4rem'
                bg='primaryGreen'
                mr='1rem'
                borderRadius='50%'
              />

              <Typography color='#fff' fontWeight={600}>
                {compareConfig ? compareConfig.templateA.name : (
                  <>Mé hodnoty {virtualResult && virtualResult.yearTo}</>
                )}
              </Typography>
            </Flex>

            <Flex alignItems='center' fontWeight={600} mt='1.2rem'>
              <Box
                width='2.4rem'
                height='2.4rem'
                bg='#2B406B'
                mr='1rem'
                borderRadius='50%'
              />

              <Typography color='#fff'>
                {compareConfig ? compareConfig.templateB.name : (
                  <>Mé hodnoty {virtualResult && virtualResult.yearFrom}</>
                )}
              </Typography>
            </Flex>
          </Flex>
        </Flex>

        {visible && (
          graphLegendVisible ? (
            <Box mt='3rem'>
              { GraphLegend }
            </Box>
          ) : (
            <Flex justifyContent='center' mt='3rem'>
              <Link v='underline' as='span' onClick={() => setGraphLegendVisible(true)}>Popis grafu</Link>
            </Flex>
          )
        )}
      </Box>
    </Flex>
  );
};

export default Block2Graphs;