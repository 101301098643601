import * as React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Heading1 } from '../../../../atoms/Headings';
import { PrimaryButton } from '../../../../atoms/Buttons';
import { StyledGameForTwoIntro } from '../../../../organisms/Test/Question/styles';
import { Row, Col, Grid } from 'react-styled-flexboxgrid';
import { CustomText } from '../../../../atoms/Text';
import { StyledFakeLinkBorder } from '../../../../atoms/Buttons/styles';
import withFetch, { IWithFetch } from 'components/atoms/Fetch/withFetch';
import LoadingSpinner from 'components/atoms/Animations/LoadingSpinner';
import { IValueTestResult, VALUE_TEST_RESULTS } from 'config/api/valueTestResult';
import { ITestConfig } from './data';
import wrapIntl, { IWrapIntl } from '../../../../atoms/wrapIntl';
import { useTheme, IThemeProps } from 'components/templates/General/ThemeContext';
import { GameConfig } from '../ComparisonSetup/style';
import SVGpartnerType from '../../../../atoms/SVGIcons/SVGpartnerType';
import SVGpartnerQuestion from '../../../../atoms/SVGIcons/SVGpartnerQuestion';

interface IIntro {
  config: ITestConfig;
  submit: (valueTestId: string | null) => any;
  back?: () => any;
}

export const toDate = (time: number) =>
  new Date(time * 1000).toLocaleDateString('cs', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

class Intro extends React.Component<IIntro & IWithFetch<IValueTestResult[]> & IWrapIntl & IThemeProps> {
  public render() {
    const { config, state } = this.props;

    if (state !== 'success') {
      return <LoadingSpinner />;
    }

    return (
      <StyledGameForTwoIntro img={'partnersky'}>
        <Grid>
          <Row>
            <Col xs={12}>
              <Heading1>
                <FormattedMessage id={'gamesForTwo.intro.heading'} />
              </Heading1>

              {config.invitedFrom ? (
                <div className={'test-note'}>
                  <FormattedMessage id={'gamesForTwo.intro.invitedFrom'} /> {config.invitedFrom}
                </div>
              ) : (
                <>
                  {config.note ? (
                    <div className={'test-note'}>
                      <FormattedMessage id={'gamesForTwo.intro.note'} /> {config.note}
                    </div>
                  ) : (
                    <div className={'test-note'}>
                      <FormattedMessage id={'gamesForTwo.intro.noNote'} />
                    </div>
                  )}
                </>
              )}
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={6} mdOffset={3}>
              <CustomText>
                {config.invitedFrom ? (
                  <FormattedMessage id={'gamesForTwo.intro.text.settings'} />
                ) : (
                  <FormattedMessage id={'gamesForTwo.intro.text.settings2'} />
                )}
              </CustomText>

              <GameConfig>
                <div>
                  <SVGpartnerType />
                  <span>
                    <FormattedMessage id={'gamesForTwo.intro.partners'} />
                  </span>
                </div>

                <div>
                  <SVGpartnerQuestion />
                  <span>
                    {config.surveys[0].name}
                    <br />
                    {!!config.surveys[1] && config.surveys[1].name}
                  </span>
                </div>
              </GameConfig>

              <CustomText>
                <FormattedHTMLMessage id={'gamesForTwo.intro.noHistoryTest'} />
              </CustomText>

              <PrimaryButton
                onClick={() => this.props.submit(null)}
              >
                <FormattedMessage id={'config.start'} />
              </PrimaryButton>

              {!!this.props.back && (
                <div className={'back'}>
                  <StyledFakeLinkBorder color='yellow' onClick={this.props.back}>
                    <FormattedMessage id={'config.back'} />
                  </StyledFakeLinkBorder>
                </div>
              )}
            </Col>
          </Row>
        </Grid>
      </StyledGameForTwoIntro>
    );
  }
}

export default withFetch<IIntro>(VALUE_TEST_RESULTS)(wrapIntl(useTheme(Intro)));
