import { Box, Flex, Img, Stack, Typography } from '@xcorejs/ui';
import { GameContext } from 'components/App';
import { PrimaryButton } from 'components/components/Button';
import { gameLevels as data } from 'components/pages/App/ValueCenter/Game/data';
import { GradientStyle } from 'components/pages/Presenting/pages';
import * as React from 'react';
import { FC, useContext } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import Zoom from 'react-reveal/Zoom';

const Popup: FC = () => {
  const { userLvl, questLvl, setGamePopup, setUserLvl, setQuestLvl, setQuestVersion } = useContext(GameContext);
  const dataLvl = data[userLvl];
  const content = data[userLvl].popup;

  return (
    content ? (
      <Box
        width='100%'
        height='100%'
        position='absolute'
        left='0'
        top='0'
        zIndex={1000}
        _before={{
          content: '\'\'',
          position: 'absolute',
          width: '100%',
          height: '100%',
          display: 'block',
          background: 'rgba(11, 12, 28, 0.9)',
          alignItems: 'flex-start',
          opacity: 1,
          transition: 'opacity 500ms',
        }}
      >
        <Flex
          background={`${content.bgImage ? 'url(/img/game/bg/' + content.bgImage + '),' : ''} ${!content.bgGradientHidden && 'radial-gradient(circle at top, '+ dataLvl.theme.primary + ' 0%, ' + dataLvl.theme.darken + ' 100%)'}`}
          maxWidth='90%'
          height='calc(100% - 6rem)'
          borderRadius='1rem'
          marginTop='3rem'
          marginLeft='5%'
          overflow='auto'
          padding='4.3rem 1.6rem 3rem'
          position='relative'
          backgroundSize='cover'
        >
          <Box maxWidth='92%' mx='auto'>
            <Stack gap='1.2rem' direction='column' alignItems='center' wrapItems>
              {content.image0 && (
                <Zoom ssrReveal delay={400}>
                  <Img src={`/img/game/${content.image0}`} mx='auto' mb='1.2rem' display='block' alt='' />
                </Zoom>
              )}

              {content.text && (
                <Typography color='yellow' fontWeight={800} lineHeight='1.8rem'>
                  <FormattedHTMLMessage id={content.text} />
                </Typography>
              )}

              {content.text2 && (
                <Typography fontSize='1.4rem' lineHeight='1.8rem'>
                  <FormattedHTMLMessage id={content.text2} />
                </Typography>
              )}

              {content.image && (
                <Zoom ssrReveal delay={400}>
                  <Img src={`/img/game/${content.image}`} mx='auto' display='block' alt='' />
                </Zoom>
              )}

              {content.divider && (
                <Box>
                  <Box width='4rem' height='0.1rem' bg='#f4f4f6' mx='auto' />
                </Box>
              )}

              {content.text3 && (
                <Typography fontSize='1.4rem' lineHeight='1.8rem' color='rgba(255, 255, 255, 0.7)'>
                  <FormattedHTMLMessage id={content.text3} />
                </Typography>
              )}

              {content.image2 && (
                <Zoom ssrReveal delay={400}>
                  <Img src={`/img/game/${content.image2}`} mx='auto' display='block' alt='' />
                </Zoom>
              )}

              {content.text4 && (
                <Typography fontSize='1.4rem' lineHeight='1.8rem' color='yellow'>
                  <FormattedHTMLMessage id={content.text4} />
                </Typography>
              )}

              <PrimaryButton
                position='relative'
                variant='outline'
                bg={content.buttonBg ?? 'rgba(0, 0, 0, 0.7)'}
                color={content.buttonColor ?? 'textGreen'}
                border={0}
                onClick={() => {
                  setTimeout( () => setUserLvl(userLvl + 1), 500);
                  setTimeout( () => setQuestLvl(questLvl + 1), 500);
                  setTimeout( () => setQuestVersion(0), 500);
                  setGamePopup(false);
                }}
              >
                {content.buttonGradient && <GradientStyle {...content.buttonGradient!} />}

                <span style={{ position: 'relative', zIndex: 1 }}>
                  <FormattedHTMLMessage id={content.buttonText ? content.buttonText : 'Pokračovat'} />
                </span>
              </PrimaryButton>
            </Stack>
          </Box>
        </Flex>
      </Box>
    ) : <></>);
};

export default Popup;
