import styled, { css } from 'styled-components';
import {
  blue, BlueTheme,
  darkBaseHEX,
  darkBaseRGB,
  grayRGB, IColorTheme,
  textGreen,
} from '../../theme/colors';
import { CustomText } from '../../atoms/Text';
import { ValueMapTheme } from './index';
import { OliMedium, OliRegular } from 'components/theme/fonts';

export const StyledValueMap = styled.div<{theme: ValueMapTheme; customColorTheme?: IColorTheme}>`
  padding: 2rem;
  padding-bottom: 4.5rem;
  margin: 0 auto;
  width: 100%;
  max-width: 38.5rem;
  border-radius: 1.2rem;
  background-color: rgba(${darkBaseRGB}, 0.25);
  box-shadow: 0 0.5rem 1rem 0 rgba(11, 12, 28, 0.2);
  text-align: center;
  color: #fff;
  position: relative;
  
  ${props =>
    props.theme === 'normal' &&
    css`
      background: linear-gradient(0deg, ${BlueTheme.darken} 0%, ${BlueTheme.primary} 100%);
    `}

  ${props =>
    props.theme === 'afterYear' &&
    css`
      background: linear-gradient(0deg, #B1476A 0%, #A64263 100%);
    `};

  ${props =>
    props.theme === 'forTwo' &&
    css`
      border: 2px solid rgba(${grayRGB}, 0.25);
      border-radius: 0.4rem;
      background: linear-gradient(0deg, #101125 0%, rgba(16, 17, 37, 0) 100%);
      box-shadow: 0 0.5rem 1rem 0 rgba(${darkBaseRGB}, 0.4);
    `}

  ${props => props.customColorTheme && css`
      background: linear-gradient(0deg, ${props.customColorTheme.darken} 0%, ${props.customColorTheme.primary} 100%);
    `};
  
  .eticon {
    width: 11rem;  
    margin: 0 auto;
    
    svg {
      width: 100%;
      height: 100%;
    }
    
    &.i0 {
      path {
        fill: ${textGreen} !important;       
      }
    }
    
    &.i1 {
      path {
        fill: ${blue} !important;        
      }
    }
  }
  
  .eticons {
    display: flex;
    align-items: center;
    max-width: 24rem;
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;
    
    & > div {
      padding: 0 1.5rem;
      width: 50%;
    }
    
    .eticon {
      width: 8rem;
      height: 8rem;
    }
  }

  h1 {
    text-align: center;
    font-size: 2rem;
    line-height: 3rem;
    font-family: ${OliMedium};

    ${props =>
    props.theme === 'afterYear' &&
      css`
        color: ${blue};
      `};
  }

  h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem !important;
    font-size: 1.8rem;
    line-height: 2.2rem;
    letter-spacing: 0.5px;
    font-weight: 600;
    
    svg {
      margin-right: 1.5rem;
      width: 3.2rem;
      
      path, polygon {
        fill: ${blue};
      }
    }

    ${props =>
    props.theme === 'normal' &&
      css`
        opacity: 0.6;
      `}

    ${props =>
    props.theme === 'afterYear' &&
      css`
        color: ${darkBaseHEX};
      `}
    
    ${props =>
    props.theme === 'forTwo' &&
      css`
        color: ${blue};
      `};
  }

  ${CustomText} {
    font-size: 1.8rem;
    line-height: 2.2rem;
    font-weight: 600;
    text-align: center;

    em {
      display: block;
      font-size: 1.4rem;
      line-height: 1.8rem;
      color: ${blue};
      
      ${props =>
    props.theme === 'forTwo' &&
        css`
          font-size: 1.6rem;
          color: ${textGreen};
        `};
    }
  }

  .logo {
    margin: 3rem auto 0;
    width: 8rem;
    height: 2.9rem;
    opacity: 0.6;
  }

  .values {
    margin-top: 2rem;
    font-family: ${OliRegular};

    span {
      display: inline-block;
      margin: 0 0.6rem;
      font-weight: 500;
      
      ${props =>
    props.theme === 'forTwo' &&
        css`
          line-height: 1.2;
        `};

      &:nth-child(1) {
        font-size: 2.8rem;
      }

      &:nth-child(2) {
        font-size: 2.6rem;
        
        ${props =>
    props.theme === 'forTwo' &&
          css`
            font-size: 2.6rem;
          `};
      }

      &:nth-child(3) {
        font-size: 2rem;
        opacity: 0.6;
        
        ${props =>
    props.theme === 'forTwo' &&
          css`
            font-size: 2.4rem;
            opacity: 1;
          `};
      }

      &:nth-child(4) {
        font-size: 2.6rem;
        
        ${props =>
    props.theme === 'forTwo' &&
          css`
            font-size: 2.4rem;
          `};
      }

      &:nth-child(5) {
        font-size: 2.4rem;
      }

      &:nth-child(6) {
        font-size: 1.9rem;
        opacity: 0.4;
        
        ${props =>
    props.theme === 'forTwo' &&
          css`
            font-size: 2.4rem;
            opacity: 0.5;
          `};
      }

      &:nth-child(7) {
        font-size: 2.1rem;
        opacity: 0.75;
        
        ${props =>
    props.theme === 'forTwo' &&
          css`
            font-size: 2rem;
            opacity: 0.5;
          `};
      }

      &:nth-child(8) {
        font-size: 1.9rem;
        opacity: 0.4;
        
        ${props =>
    props.theme === 'forTwo' &&
          css`
            font-size: 2rem;
            opacity: 0.5;
          `};
      }

      &:nth-child(9) {
        font-size: 2.1rem;
        opacity: 0.6;
        
        ${props =>
    props.theme === 'forTwo' &&
          css`
            font-size: 2rem;
            opacity: 0.5;
          `};
      }

      &:nth-child(10) {
        font-size: 1.9rem;
        opacity: 0.4;
        
        ${props =>
    props.theme === 'forTwo' &&
          css`
            font-size: 2rem;
            opacity: 0.5;
          `};
      }
    }
  }

  .eticia-link {
    display: block;
    font-size: 1.2rem;
    font-weight: 500;
  }
  
  .forTwo-eticons {
    margin-top: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    color: ${textGreen};
    font-size: 1.3rem;
    
    svg {
      width: 50px;
      height: 50px;
      
      path {
        fill: ${textGreen};
      }
    }
  }
`;
