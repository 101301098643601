import { IUserInfo } from 'store/User';
import { changeUser, changeUserData } from '../../Profile/Settings/actions';

export const updateUserLevel = (user: IUserInfo, level: number) => {
  user && changeUserData(
    changeUser(user, level, 'level'),
    () => {
      console.log('level changed to level: ', level); //TODO: remove
      user.level = level;
    },
    () => console.log('error changing level'),
  );
};