import { createAction, EmptyActionCreator, ComplexActionCreator1 } from 'redux-act';

export type RescourceState = 'init' | 'fetching' | 'success' | 'fail' | 'reload';
export type Error = any;

export interface IRescource<T> {
  data: T | null | string | undefined;
  state: RescourceState;
}

export const rescource = <T>(state: RescourceState, data?: T | null | string): IRescource<T> => ({
  state,
  data,
});

export const isFetched = <T>(res: IRescource<T>) => res.state === 'success' || res.state === 'fail';

export const isFetching = <T>(res: IRescource<T>) => res.state === 'fetching' || res.state === 'reload';

export const isLoadable = <T>(res: IRescource<T>) => !isFetched(res) && !isFetching(res);

export const isReadable = <T>(res: IRescource<T>) => res.state === 'success';

export const getData = <T>(res: IRescource<T>): T => res.data as T;

interface IRescourceActions<T> {
  fetching: EmptyActionCreator;
  success: ComplexActionCreator1<T, T>;
  fail: EmptyActionCreator;
}

export const createRescourceActions = <T>(name: string): IRescourceActions<T> => ({
  fetching: createAction(name + ': fetching'),
  success: createAction(name + ': success', (data: T) => data),
  fail: createAction(name + ': fail'),
});
