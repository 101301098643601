import * as React from 'react';
import { FC, useContext } from 'react';
import { GameContext } from 'components/App';
import {
  Container,
  Flex,
  Box,
  Typography,
  Button,
  Icon,
  Grid,
  Link,
  Heading3, Img, Heading2, Paragraph, useModal
} from '@xcorejs/ui';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import GiftIcon from '../../../../components/icons/22/gift.svg';
import { SecondaryButton } from 'components/components/Button';
import { Link as ReactLink } from 'react-router-dom';
import { createUrl } from 'components/pages/routes';
import EticiaPlusTimingBox from 'components/components/EticiaPlusTimingBox';
import Shop from 'components/organisms/EticiaPlus/Shop';
import { TablePlus } from 'components/organisms/EticiaPlus/Table';
import ThreeLadders from 'components/organisms/EticiaPlus/ThreeLadders';
import { Product as StyledProduct, ShopButton } from 'components/organisms/EticiaPlus/styles';
import { PlusTag } from 'components/atoms/Logo';
import PresentingPageModal from 'components/components/modals/PresentingPageModal';

export const shopData = [
  {
    color: '231, 175, 208',
    price: 121,
    heading: 'na 1 rok',
    text1: '<strong>Plný přístup do Eticia </strong><br/>obsahuje všechny funkcionality',
    ladders: '+ 1 žebříček',
    lifeValues: '+ 1x životní hodnoty s kompletním vyhodnocením',
    activateLink: '/koupit' + '/plus-1rok',
    buyLink: '/koupit' + '/voucher-1rok'
  },
  {
    color: '134, 212, 182',
    price: 249,
    heading: 'na 3 roky',
    text1: '<strong>Plný přístup do Eticia </strong><br/>obsahuje všechny funkcionality',
    ladders: '+ 3 žebříčky',
    lifeValues:'+ 3x životní hodnoty s kompletním vyhodnocením',
    darkBg: true,
    activateLink: '/koupit' + '/plus-3roky',
    buyLink: '/koupit' + '/voucher-3roky'
  },
  {
    color: '242, 173, 124',
    price: 919,
    heading: 'navždy',
    text1: '<strong>Výjimečné členství</strong> pro ty, kteří si chtějí užívat Eticia naplno',
    ladders: '+ 6 žebříčků',
    lifeValues: 'Navždy Životní hodnoty s kompletním vyhodnocením každý rok',
    withTree: true,
    darkBg: true,
    activateLink: '/koupit' + '/plus-navzdy',
    buyLink: '/koupit' + '/voucher-navzdy',
    disabled: true
  }
];

const Offers: FC = () => {
  const { eticiaPlus } = useContext(GameContext);
  const [openPresentingPageModal] = useModal(PresentingPageModal);

  return (
    <Box pt={{ _: '3rem', sm: '4rem' }} pb={{ _: '6rem', sm: '8rem' }}>
      <Container flexDirection='column' maxWidth='110.2rem'>
        <Flex flexDirection='column' alignItems='center'>
          <Heading2 as='h1'>
            <FormattedMessage id='shop.heading' />
          </Heading2>

          <EticiaPlusTimingBox mt={{ _: '3rem', sm: '4rem' }} capitalize />

          <ReactLink to={createUrl('app.activation')}>
            <Flex mt='2rem' alignItems='center' pl='1rem'>
              <Icon svg={<GiftIcon />} fill='rgba(255, 255, 255, 0.5)' mr='1.5rem' transform='scale(1.3) rotate(-20deg)' />

              <Typography color='rgba(255, 255, 255, 0.5)' textAlign='left'>
                <FormattedHTMLMessage id='shop.haveVoucher' />
              </Typography>

              <Flex
                justifyContent='center'
                alignItems='center'
              >
                <Button
                  s='xs'
                  border='2px solid rgba(0, 229, 141, 0.5)'
                  borderRadius='1.7rem'
                  bg='rgba(11, 12, 28, 0.5)'
                  color='rgba(255, 255, 255, 0.9)'
                  height='auto'
                  paddingTop='1rem'
                  paddingBottom='0.7rem'
                  _hover={{
                    bg: 'rgba(11, 12, 28, 0.5)',
                    color: '#fff'
                  }}
                  ml='1rem'
                  fontWeight={400}
                  type='button'
                >
                  <FormattedHTMLMessage id='shop.continue' />
                </Button>
              </Flex>
            </Flex>
          </ReactLink>

          <Shop
            both
            inApp
            mt={{ _:'4rem', md: '6rem' }}
          />

          <Grid
            columns={{ _: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }}
            rows='auto'
            gap='4rem'
            mt='6rem'
          >
            <Grid
              columns='1'
              rows='auto'
              gap='4rem'
              column={{ _: '1', md: 'auto / span 2' }}
            >
              <Grid
                columns={{ _: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
                rows='auto min-content auto'
                gap='4rem'
              >
                <Flex as='section' flexDirection='column'>
                  <Heading3 color='rgba(255, 255, 255, 0.7)' fontWeight={400}>
                    <FormattedMessage id='shop.eticiaPlus.moreLadders.heading' />
                  </Heading3>
                  
                  <ThreeLadders
                    free={!eticiaPlus}
                    mt='3rem'
                    flexGrow={1}
                  />
                </Flex>

                <Flex as='section' flexDirection='column' justifyContent='center' alignItems='center'>
                  <Heading3 color='rgba(255, 255, 255, 0.7)' fontWeight={400}>
                    <FormattedMessage id='shop.eticiaPlus.moreValues.heading' />
                  </Heading3>

                  <Box mt='3rem'>
                    <StyledProduct
                      borderColor='#322e44'
                      backgroundColor='#585277'
                    >
                      <Heading2 
                        position='relative'
                        mt='2rem'
                        lineHeight='1'
                      >
                        <FormattedHTMLMessage id='shop.eticiaPlus.moreValues.product.heading' />
                        <PlusTag 
                          position='absolute'
                          top='-2rem'
                          right='0'
                          transform='scale(0.85)' />
                      </Heading2>

                      <Paragraph mt='2rem'>
                        <FormattedHTMLMessage id='shop.eticiaPlus.moreValues.product.text' />
                      </Paragraph>

                      <ShopButton
                        borderColor='#322e44'
                        backgroundColor='rgba(11, 12, 28, 0.2)'
                        color='rgba(255, 255, 255, 0.8)'
                        onClick={() => alert('Nová podstránka pro společné hodnoty?')}
                      >
                        <FormattedMessage id='shop.eticiaPlus.moreValues.product.button' />
                      </ShopButton>

                      <Link
                        v='underline'
                        color='rgba(255, 255, 255, 0.7)'
                        borderColor='rgba(255, 255, 255, 0.7)'
                        fontSize='1.4rem'
                        letterSpacing={0}
                        lineHeight='2rem'
                        mt='2rem'
                        mb='1rem'
                        onClick={() => openPresentingPageModal({ pageId: 30, loggedUser: true })}
                      >
                        <FormattedHTMLMessage id='shop.eticiaPlus.moreValues.product.link' />
                      </Link>
                    </StyledProduct>
                  </Box>
                </Flex>

                <Box
                  column={{ _: 1, md: 'auto / span 2' }}
                  display='flex'
                  flexDirection='column'
                  alignItems='center'
                  justifyContent='center'
                >
                  <Box borderRadius='1.2rem' backgroundColor='rgba(11, 12, 28, 0.2)' p='2rem' >
                    <Heading3 color='rgba(242, 173, 124, 0.8)'>
                      <FormattedMessage id='shop.otherBox1.heading' />
                    </Heading3>

                    {/* <Img src='/img/znesnaze21_640x150.png' mt='3rem' alt='' /> */}
                    <Img src='https://placehold.it/640x150' mt='3rem' alt='' />
                  </Box>
                </Box>

                <Flex
                  p='3rem'
                  pt='0'
                  flexDirection='column'
                  alignItems='center'
                >
                  <Heading3 color='rgba(249, 245, 220, 0.8)'>
                    <FormattedMessage id='shop.otherBox1.text' />
                  </Heading3>

                  <Typography
                    mt='1rem'
                    color='rgba(249, 245, 220, 0.7)'
                  >
                    <FormattedMessage id='shop.otherBox1.text2' />
                  </Typography>

                  <Link
                    v='underline'
                    color='rgba(249, 245, 220, 0.5)'
                    borderColor='rgba(249, 245, 220, 0.5)'
                    fontSize='1.4rem'
                    letterSpacing={0}
                    lineHeight='2rem'
                    mt='2rem'
                  >
                    <FormattedMessage id='shop.otherBox1.link' />
                  </Link>
                </Flex>

                <Flex
                  p='3rem'
                  pt='0'
                  flexDirection='column'
                  alignItems='center'
                >
                  <Heading3 color='rgba(249, 245, 220, 0.8)'>
                    <FormattedMessage id='shop.otherBox2.heading' />
                  </Heading3>

                  <Typography mt='1rem'>
                    <FormattedMessage id='shop.otherBox2.text' />
                  </Typography>

                  <Link
                    v='underline'
                    color='rgba(249, 245, 220, 0.5)'
                    borderColor='rgba(249, 245, 220, 0.5)'
                    fontSize='1.4rem'
                    letterSpacing={0}
                    lineHeight='2rem'
                    href='mailto:info@eticia21.com'
                    target='_blank'
                    mt='2rem'
                  >
                    <FormattedMessage id='shop.otherBox2.link' />
                  </Link>
                </Flex>
              </Grid>
            </Grid>

            <Flex
              flexDirection='column'
              alignItems='center'
              justifyContent='start'
            >
              <Heading3 color='rgba(255, 255, 255, 0.7)' fontWeight={400}>
                <FormattedHTMLMessage id='shop.include' />
              </Heading3>
      
              <TablePlus  />
            </Flex>
          </Grid>

          <ReactLink to={createUrl('app.shop') + '/moje'}>
            <SecondaryButton
              as='a'
              borderColor='textGreen' 
              // size='lg'
              // fontSize='2.2rem'
              letterSpacing='0.5px'
              // lineHeight='2.8rem'
              px='5rem'
              mt={{ _:'2rem' }}>
              <FormattedMessage id='shop.myAccount' />
            </SecondaryButton>
          </ReactLink>
        </Flex>
      </Container>
    </Box>
  );
};

export default Offers;
