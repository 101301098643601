import * as React from 'react';
import { Row, Grid } from 'react-styled-flexboxgrid';
import TriviaCard from '../Cards/TriviaCard';
import { ILifeValueDetail, IInterest } from '../../../../store/Entities/LifeValues';
import TabWrapper from '../TabWrapper';
import { getTrivia } from '..';

interface ITrivia {
  inTest: boolean;
  detail: ILifeValueDetail;
  onVote: (id: string, type: string, text?: string) => any;
}

const Trivia: React.FC<ITrivia> = ({ detail, inTest, onVote }) => (
  <Grid>
    <Row>
      <TabWrapper collection={getTrivia(detail)} inTest={inTest} label={'Zajímavost'} color='blue'>
        {(itm: IInterest) => (
          <TriviaCard
            trivia={itm}
            valueId={detail.id}
            onVote={(id: string, type: string, text?: string) => onVote(id, type, text)}
          />
        )}
      </TabWrapper>
    </Row>
  </Grid>
);

export default Trivia;
