import styled, { css } from 'styled-components';
import { darkBaseRGB } from '../../../../theme/colors';
import { StyledFakeLinkBorder, StyledLinkBorder } from '../../../../atoms/Buttons/styles';

export const StyledMessage = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  padding: 2rem 3rem 2rem 2rem;
  border-radius: 0.4rem;
  text-align: left;

  &:nth-child(odd) {
    background-color: rgba(${darkBaseRGB}, 0.4);
  }

  &:nth-child(even) {
    background-color: rgba(${darkBaseRGB}, 0.3);
  }

  .deco {
    min-width: 3rem;
    height: 3rem;
    margin-right: 3rem;
    border-radius: 50%;
    background: #d8d8d8;
  }

  ${StyledLinkBorder} {
    font-size: 1.6rem;
    line-height: 2rem;
  }
`;

export const StyledMessageList = styled.div<{ read?: boolean }>`
  margin-top: 3rem;

  &:first-child {
    margin-top: 0;
  }

  ${props =>
    props.read &&
    css`
      ${StyledMessage} {
        opacity: 0.5;
      }
    `}

  ${StyledFakeLinkBorder} {
    margin-top: 4rem;
    font-size: 1.6rem;
    line-height: 2rem;
  }
`;
