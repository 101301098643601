import { EDIT_PROFILE } from 'config/apiRoutes';
import { jsonFetch } from '../../../utils/fetch';
import { IDemoUserInfo } from 'store/User';

export interface IProfileData {
  yearOfBirth: number;
  countryId: string;
  city: string;
  maritalStatus: number;
  workType: number;
  regionId: string;
}

export const editProfile = (data: Partial<IProfileData>) =>
  jsonFetch<IDemoUserInfo>(EDIT_PROFILE, {
    method: 'POST',
    body: JSON.stringify(data),
  });
