import { BlueTheme } from 'components/theme/colors';
import React, { FC } from 'react';
import { StyledCountry } from '../styles';

interface CountryInfoProps {
  i: number;
  device: string;
  firstCondition: boolean;
  secondCondition: boolean;
  children: React.ReactNode;
}

const CountryInfo: FC<CountryInfoProps> = ({
  i,
  device,
  firstCondition,
  secondCondition,
  children
}) => {
  return ( 
    <StyledCountry>
      <defs>
        <linearGradient id={`gradient-${i}-${device}`} x1='0%' y1='100%' x2='100%' y2='0%'>
          {firstCondition ? (
            <>
              <stop offset='0%' stopColor={BlueTheme.extraDark} />
              <stop offset='100%' stopColor={BlueTheme.extraDark} />
            </>
          ) : secondCondition ? (
            <>
              <stop offset='0%' stopColor='#77b4cf' />
              <stop offset='100%' stopColor='#9dd5eb' />
            </>
          ) : (
            <>
              <stop offset='0%' stopColor='#CCC' />
              <stop offset='100%' stopColor='#CCC' />
            </>
          )}
        </linearGradient>
      </defs>
      {children}
    </StyledCountry>
  );
};
 
export default CountryInfo;
