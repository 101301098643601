import { jsonFetch } from 'utils/fetch';
import { API_URL } from '../apiRoutes';

export const SURVEY = API_URL + '/surveys';
export const SURVEYS_LIFEVALUE = (id: string) => SURVEY + '/life-value/' + id;
export const SURVEY_START = SURVEY;
export const SURVEY_ID = (id: string) => SURVEY + `/${id}`;
export const SURVEY_ADD_RECORD = (id: string) => SURVEY_START + `/${id}/add-record`;
export const SURVEY_CURRENT_STEP = (id: string) => SURVEY_START + `/${id}/current-step`;
export const SURVEY_SKIP = (id: string) => SURVEY_START + `/${id}/skip`;
export const SURVEY_RESULTS = SURVEY + '/results/';
export const SURVEY_RESULT = (id: string) => SURVEY_RESULTS + id;

interface IResult {
  id: string;
}

export const startSurvey = (surveyId: string, lifeValueId: string | null) =>

  jsonFetch<IResult>(SURVEY_START, {
    method: 'POST',
    body: JSON.stringify({
      surveyId,
      lifeValueId,
    }),
  });