import * as React from 'react';
import { RequestContext } from 'components/atoms/RequestContext';
import wrapIntl, { IWrapIntl } from 'components/atoms/wrapIntl';
import { RouteComponentProps, withRouter } from 'react-router';
import LoadingSpinner from 'components/atoms/Animations/LoadingSpinner';
import { StyledRegistration } from 'components/pages/styles';
import { createUrl } from 'components/pages/routes';
import { IFetchException } from 'utils/exceptions';
import RegisterAfterGoogleForm from './RegisterAfterGoogleForm';
import { editProfile } from './action';
import { IDemoUserInfo } from 'store/User';
import { userActions } from 'store/User/UserActions';
import { connect } from 'react-redux';
import { Box, Container, Heading1, Typography } from '@xcorejs/ui';
import { FormattedMessage } from 'react-intl';

interface IRegistrationProps {
  popup: (body: React.ComponentType, title?: string, button?: string) => any;
  popupParents: () => any;
  updateUser: (data: IDemoUserInfo) => unknown;
}

export interface IRegistrationState {
  step: number;
  errors: IRegisterUserErrors;
  knownUserInfo: IRegisterUserVm | null;
}

export interface IRegisterUserVm {
  nick: string;
  femalePronouns?: boolean;
  countryId: string;
}

export interface IRegisterUserErrors {
  Nick?: string[];
  agree?: string;
  sex?: string;
  countryId?: string;
}

type Props = IRegistrationProps & IWrapIntl & RouteComponentProps<any>;

class RegisterAfterGoogle extends React.Component<Props, IRegistrationState> {
  public state: IRegistrationState = {
    step: 0,
    errors: {},
    knownUserInfo: null,
  };

  public componentDidMount() {
    // if (
    //   !getParams().isDev &&
    //   getParams().registrationStatus === "globalDisabled"
    // ) {
    //   this.disabledRegistation();
    // }
  }

  public render() {
    const { popup } = this.props;
    const { step } = this.state;

    if (step === 1) {
      return <LoadingSpinner />;
    }

    return (
      <>
        <Box
          bg="url('/img/global.jpg')"
          backgroundPosition='center bottom'
          backgroundSize='cover'
          {...{ backgroundAttachment: 'fixed' }}
          minHeight='100vh'
          top={0}
          pt='6rem'
          pb='3.5rem'
          mt='-6rem'
          mb='-3.5rem'
        >
          <Container flexDirection='column'>
            <Heading1 fontSize='3.2rem' lineHeight='3.9rem' fontWeight={400} textAlign='center'>
              <FormattedMessage id={'registration.heading.afterGoogle'} />
            </Heading1>

            <Typography mt='2.4rem'>
              <FormattedMessage id={'registration.text.afterGoogle'} />
            </Typography>

            {/* Registration form */}
            <RequestContext>
              {() => (
                <RegisterAfterGoogleForm
                  sendRegistration={user => {
                    if (user.femalePronouns === undefined) {
                      this.setState({
                        step: this.state.step,
                        errors: {
                          ...this.state.errors,
                          sex: this.props.formatMsg('registration.empty'),
                        },
                      });
                      return;
                    }
                    editProfile(user)
                      .then(data => {
                        this.props.updateUser(data);
                        this.props.history.push(createUrl('register.profile'));
                      })
                      .catch((e: IFetchException) =>
                        e.data.json().then(errors =>
                          this.setState({
                            step: 0,
                            errors: { ...errors, agree: this.state.errors.agree },
                          }),
                        ),
                      );
                  }}
                  popup={popup}
                  popups={{} as any}
                  errors={this.state.errors}
                  knownUserInfo={this.state.knownUserInfo}
                />
              )}
            </RequestContext>
          </Container>
        </Box>
      </>
    );
  }
}
const mapDispatch = (dispatch: any) => ({
  updateUser: (data: IDemoUserInfo) => dispatch(userActions.success(data)),
});

export default connect(null, mapDispatch)(withRouter(wrapIntl(RegisterAfterGoogle)));
