import * as React from 'react';
import { StyledRegistrationPopup } from './styles';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import popupContext from '../../organisms/MainPopup/popupContext';
import SVGclosegray from '../../atoms/SVGIcons/SVGclosegray';
import Fade from 'react-reveal/Fade';
import { StyledHelpPopup } from '../../atoms/Popups/HelpPopup/styles';

const Popup: React.FC = () => {
  const close = React.useContext(popupContext);

  return (
    <StyledHelpPopup>
      <Fade ssrReveal bottom duration={1000} distance={'5rem'}>
        <div className='content'>
          <span className='close' onClick={() => close()}>
            <SVGclosegray />
          </span>

          <h2 className='popup-title'>
            <FormattedMessage id='terms.parents.heading' />
          </h2>

          <StyledRegistrationPopup>
            <div>
              <FormattedHTMLMessage id={'terms.gpdr.popup'} />
            </div>
          </StyledRegistrationPopup>
        </div>
      </Fade>
    </StyledHelpPopup>
  );
};

export default Popup;
