import * as React from 'react';
import wrapIntl, { IWrapIntl } from '../../../atoms/wrapIntl';
import FeedbackForm from '../../../organisms/FeedbackForm';
import { sendFeedback } from './actions';

const FeedbackBug: React.FC<IWrapIntl> = ({ formatMsg }) => (
  <FeedbackForm title={formatMsg('feedback.bug')} text={formatMsg('feedback.bug.text')} submit={sendFeedback('bug')} />
);

export default wrapIntl(FeedbackBug);
