import { ExtendedTypographyProps, Flex, FlexProps, Paragraph } from '@xcorejs/ui';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQueryClient } from 'react-query';
import { queryKeysAll } from 'utils/useQuery/queryKeys';

interface CountdownTimerProps extends FlexProps {
  timeInSeconds: number;
  _paragraphProps?: ExtendedTypographyProps;
}

const Countdown: React.FC<CountdownTimerProps> = ({ timeInSeconds, _paragraphProps, ...props }) => {
  const [time, setTime] = useState<number>(timeInSeconds);
  const queryClient = useQueryClient();

  queryKeysAll.forEach(k => queryClient.invalidateQueries([k]));

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (time > 0) {
      timer = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [time]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  return (
    <Flex {...props}>
      {time > 0 ? (
        <Paragraph {..._paragraphProps}>
          <FormattedMessage id='test.countdown' />{' '}
          {formatTime(time)}</Paragraph>
      ) : (
        <Paragraph {..._paragraphProps}>
          <FormattedMessage id='test.countdown2' />
        </Paragraph>
      )}
    </Flex>
  );
};

export default Countdown;
