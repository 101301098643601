import * as React from 'react';
import Helmet from 'react-helmet';
import { ColoredThemes } from '../../theme/colors';

interface IOGTags {
  title?: string;
  description?: string;
  url?: string;
  image?: string;
  siteName?: string;
  type?: string;
  locale?: string;
  themeColor?: string;
}

const OGTags: React.FC<IOGTags> = (
  {
    title,
    description,
    url,
    image,
    siteName,
    type,
    locale,
    themeColor
  }) => (


  <Helmet>
    {title !== null && <meta property='og:url' content={title} />}
    {description !== null && <meta property='og:url' content={description} />}
    {url !== null && <meta property='og:url' content={url} />}
    {image !== null && <meta property='og:image' content={image} />}
    {siteName !== null && <meta property='og:site_name' content={siteName} />}
    {type !== null && <meta property='og:type' content={type} />}
    {locale !== null && <meta property='og:locale' content={locale} />}
    {themeColor !== null && <meta name='theme-color' content={ColoredThemes[themeColor ? themeColor : 'blue'].primary} />}

  </Helmet>
);

export default OGTags;
