import styled from 'styled-components';
import { Cell, Flex } from '@xcorejs/ui';
import { mediaMdDefault, mediaSmDefault } from 'components/theme/grid';
import { OliLight, OliRegular } from 'components/theme/fonts';

export const IconStyle = styled.div`
  svg {
    width: 6.4rem;
    height: 6.4rem;

    path {
      fill: #fff;
    }
  }
`;

export const EticiaCell = styled(Cell)`
  padding-top: ${props => props.py || props.paddingY || props.paddingTop || props.pt || '3rem'};
  padding-bottom: ${props => props.py || props.paddingY || props.paddingBottom || props.pb || '3rem'};
  padding-left: ${props => props.px || props.paddingX || props.paddingLeft || props.pl || '2rem'};
  padding-right: ${props => props.px || props.paddingX || props.paddingRight || props.pr || '2rem'};

  border-radius: ${props => props.borderRadius || '1rem'};

  order: ${props => props.order || '1'};

  h3 {
    font-size: 2rem;
    line-height: 2.5rem;
    font-weight: 400;

    margin-top: 2rem;    
  }

  p {
    font-family: ${OliLight};
    font-size: 1.3rem;
    line-height: 1.9rem;
    color: rgba(255,255,255,0.65);

    max-width: 90%;

    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;
  }

  p:first-of-type {
    color: rgba(255,255,255,1);
  }
  
  ${mediaMdDefault} {
    order: 1;

    h3 {
      font-size: 2.4rem;
      line-height: 2.8rem;
    }

    p {
      font-size: 1.6rem;
      line-height: 2.2rem;
    }
  }
`;

export const StyledSpan = styled.span`
  color: rgba(255, 255, 255, 0.7);
  display: block;

  ${mediaMdDefault} {
    display: inline;
  }
`;

export const StyledTag = styled.span`
  font-family: ${OliRegular};
  font-size: 1.3rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.4rem;
  padding-bottom: 0.2rem;
`;

interface StyledSectionProps {
  position?: string;
}

export const StyledSection = styled.section<StyledSectionProps>`
  position: ${props => props.position || 'relative'};
`;

export const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(auto, 150px));
  grid-template-rows: auto;
  grid-gap: 1rem;
  margin-top: 2rem;
  justify-content: center;
  justify-items: center;
  align-items: center;
  position: relative;
`;

export const NumberSpan = styled.span`
  display: inline-block;
  font-size: 1.8rem;
  line-height: 2.4rem;

`;
