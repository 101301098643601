import * as React from 'react';
import SVGclose from '../../SVGIcons/SVGclose';
import { LevelUp5 } from './styles';
import { Heading2 } from '../../Headings';
import { SecondaryButton } from '../../Buttons';
import { CustomText } from '../../Text';
import { FormattedMessage } from 'react-intl';
import QuestObjective from '../../../organisms/QuestLog/QuestObjective';
import { IWrapIntl } from '../../wrapIntl';
import wrapIntl from '../../wrapIntl';
import { PopupComponent } from 'store/Popup';

const Level5: PopupComponent<IWrapIntl> = ({ formatMsg, resolve }) => {
  return (
    <>
      <LevelUp5>
        <div className='content'>
          <a className='close' onClick={() => resolve()}>
            <SVGclose />
          </a>

          <img src='/img/popup/hra-lvl-5.png' alt='' />
          <Heading2>
            <FormattedMessage id={'popupLvl.5.heading'} />
          </Heading2>

          <CustomText>
            <FormattedMessage id={'popupLvl.5.text'} />
          </CustomText>

          <CustomText>
            <strong>
              <FormattedMessage id={'popupLvl.next.5'} />
            </strong>
          </CustomText>

          <div className='next'>
            <QuestObjective
              icon={'/img/ico/tests/values-love.svg'}
              color='transparent'
              title={formatMsg('questlog.love.title')}
              text={formatMsg('questlog.love.text')}
              icoBackground={'blue'}
            />
          </div>

          <SecondaryButton onClick={() => resolve()}>
            <FormattedMessage id={'popupLvl.btn'} />
          </SecondaryButton>
        </div>
      </LevelUp5>
    </>
  );
};

export default wrapIntl(Level5);
