import styled from 'styled-components';
import { Heading2 } from '../../../../atoms/Headings';
import {
  darkBaseRGB,
  darkenBlue,
  darkenGreen,
  gray,
  grayRGB,
  lightGrayBG,
  primaryBlue,
  primaryGreen,
  white,
  yellow,
} from '../../../../theme/colors';
import { CustomText } from '../../../../atoms/Text';
import { StyledColorBox } from '../../../../organisms/PartnerTest/Result/styles';
import { mediaLgDefault, mediaMdDefault, mediaSmDefault } from '../../../../theme/grid';
import { bold } from '../../../../theme/fonts';
import { Grid, Row } from 'react-styled-flexboxgrid';
import { UL1 } from '../../../../organisms/VisualValuesComparison/style';

export const StyledPartnerTestResult = styled.div`
  display: flex;
  text-align: center;
  min-height: calc(100vh - 9.5rem);

  & > ${Grid} {
    display: flex;
    justify-content: center;

    & > ${Row} {
      width: 100%;
      align-items: flex-start;
      justify-content: center;
    }
  }

  ${Heading2} {
    font-size: 2.4rem;
    letter-spacing: 0.5px;
    line-height: 3.1rem;
    font-weight: 800;
  }

  .intro-text {
    margin-top: 4rem;
    opacity: 0.8;
    color: ${gray};
    font-size: 1.6rem;
    line-height: 2rem;
    text-align: center;

    .id {
      margin-top: 7.5rem;
    }
  }

  ${CustomText} {
    text-align: center;
    color: ${gray};
    font-size: 1.6rem;
    line-height: 2rem;

    p {
      strong {
        color: ${yellow};
      }
    }
  }

  .content-date {
    margin-top: 3rem;
    width: 100%;
    color: rgba(${grayRGB}, 0.2);
    font-size: 1.6rem;
    line-height: 2rem;
    text-align: center;

    ${mediaLgDefault} {
      margin-top: 6rem;
    }
  }

  .finish-button {
    letter-spacing: 0.5px;
    ${mediaSmDefault} {
      font-size: 2.2rem;
      line-height: 2.8rem;
    }
  }

  .faq-popup-button {
    position: fixed;
    right: 3rem;
    bottom: 3rem;
  }
`;

export const StyledDefaultIntroduction = styled.div`
  width: 100%;

  .test-type {
    margin-top: 1rem;
    color: rgba(255, 255, 255, 0.5);
    font-size: 2rem;
    letter-spacing: 0.5px;
    line-height: 2.5rem;

    ${mediaSmDefault} {
      margin-top: 3rem;
    }
  }

  .intro-type-topics {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 3rem;
    margin-bottom: 2rem;
    text-align: center;

    ${mediaSmDefault} {
      flex-direction: row;
      margin-top: 6rem;
      margin-bottom: 4rem;
    }

    div {
      margin: 0 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.6rem;
      color: #fff;

      &:first-child {
        margin-bottom: 1rem;

        ${mediaSmDefault} {
          margin-bottom: 0;
        }
      }
    }

    svg {
      margin-right: 1.5rem;
      opacity: 0.4;
      min-width: 2.2rem;

      path {
        fill: ${lightGrayBG};
      }
    }
  }

  .people {
    margin-top: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    ${mediaSmDefault} {
      margin-top: 6rem;
      flex-direction: row;
    }

    .box {
      display: flex;
      width: 100%;

      .img {
        padding: 1rem;
        box-shadow: 0 0.5rem 1rem 0 rgba(${darkBaseRGB}, 0.4);

        &.green {
          margin-right: 0.2rem;
          border-radius: 0.4rem 0 0 0.4rem;
          background: rgba(25, 29, 44, 0.25) radial-gradient(circle at top, ${primaryGreen} 0%, ${darkenGreen} 100%);
        }

        &.blue {
          margin-left: 0.2rem;
          border-radius: 0 0.4rem 0.4rem 0;
          background: rgba(25, 29, 44, 0.25) radial-gradient(circle at top, ${primaryBlue} 0%, ${darkenBlue} 100%);
        }

        img {
          display: block;
          width: 6rem;
          min-width: 6rem;
          height: 6rem;
        }

        ${mediaSmDefault} {
          padding: 2rem;

          img {
            width: 8rem;
            min-width: 8rem;
            height: 8rem;
          }
        }
      }

      ${StyledColorBox} {
        min-height: 0;
        width: 100%;
        font-weight: ${bold};

        ${mediaMdDefault} {
          font-size: 2.4rem;
          letter-spacing: 0.5px;
          line-height: 3.1rem;
        }
      }

      &:first-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &:last-child {
        flex-direction: row-reverse;

        ${StyledColorBox} {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }

    .divider {
      margin: 1rem 3.3rem;

      ${mediaSmDefault} {
        margin: 2rem 3.3rem;
      }
    }
  }
`;

export const StyledDefaultIntroductionPopup = styled.div`
  color: ${white};

  ${Heading2} {
    margin-bottom: 2rem;
    color: #fff;
    font-weight: 400;
  }

  ${CustomText} {
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align: center;

    strong {
      color: ${yellow};
    }
  }

  ${UL1} {
    li {
      justify-content: center;
    }

    span {
      font-size: 1.6rem;
      line-height: 2rem;
      color: #fff;
    }
  }
`;
