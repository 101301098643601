import styled from 'styled-components';

interface MovingRectangleProps {
  backgroundImageUrl: string;
  delay?: number;
  backgroundColor?: string;
  bottom?: string;
}

export const MovingRectangle = styled.div<MovingRectangleProps>`
  --rectangle-animation-duration: 2.5s;

  background: ${({ backgroundImageUrl }) => `url(${backgroundImageUrl})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: ${({ backgroundColor }) => backgroundColor ? backgroundColor : 'transparent'};

  border-radius: 10px;
  height: 155px;
  width: 155px;
  position: absolute;
  ${({ bottom }) => bottom && `bottom: ${bottom};`}
  will-change: transform opacity scale;
  opacity: 0;

  animation: fly var(--rectangle-animation-duration) linear forwards;
  -webkit-animation: fly var(--rectangle-animation-duration) linear forwards;
  -moz-animation: fly var(--rectangle-animation-duration) linear forwards;

  animation-delay: ${({ delay }) => delay ? `${delay}s` : '0s'};
  -webkit-animation-delay: ${({ delay }) => delay ? `${delay}s` : '0s'};
  -moz-animation-delay: ${({ delay }) => delay ? `${delay}s` : '0s'};

  @keyframes fly {
    0% {
      transform: scale(0.2) translate(0, 0%);
      opacity: 0;
    }
    30% {
      opacity: 1;
    }
    70% {
      transform: scale(1) translate(0, 0%);
    }
    75% {
      transform: scale(1) translate(0, 0%);
      opacity: 1;
    }
    100% {
      transform: translate(0, 300%);
      opacity: 0;
    }
  }
`;
  