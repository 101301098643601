import styled from 'styled-components';
import {
  darkBaseRGB,
  primaryGreen,
  red,
  darkBaseHEX,
  selectColor,
  GreenTheme,
  VioletTheme,
  yellow,
  gray,
  whiteFade,
  grayFooter,
  darkenGreen, BlueTheme,
} from '../../theme/colors';
import { Row, Col, Grid } from 'react-styled-flexboxgrid';
import { StyledPrimaryButton, StyledPrimaryButtonButton } from '../../atoms/Buttons/styles';
import { mediaLgDefault, mediaMdDefault, mediaSmDefault, mediaSmMax } from '../../theme/grid';
import { StyledTextArea } from '../../atoms/FormItems/styles';
import { BiggerText } from '../../atoms/Text';
import { ScrollbarStyles } from '../../templates/General/styles';

export const StyledBackground = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(${darkBaseRGB}, 0.5);
  z-index: 1003;
  text-align: center;
  white-space: nowrap;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.2);
    transition: background 0.3s;
  }

  ::-webkit-scrollbar-track:hover {
    background: rgba(255, 255, 255, 0.3);
  }

  ::-webkit-scrollbar-thumb {
    background-color: #191d2c;
    background: rgba(25, 29, 44, 0.3);
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgba(25, 29, 44, 0.5);
  }
`;

export const StyledModal = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 2rem;
  width: calc(100% - 3rem);
  height: 100%;
  max-width: 180rem;
  min-height: 60vh;
  max-height: calc(100% - 12rem);
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 10px 20px 0 rgba(25, 29, 44, 0.5);
  color: ${primaryGreen};
  text-align: right;
  white-space: normal;
  overflow-y: auto;

  ${mediaMdDefault} {
    max-height: calc(100% - 20rem);
  }

  .flex {
    position: relative;

    .value-wrapper {
      text-align: center;
    }

    .detail-wrapper {
      width: 100%;
      overflow: hidden;

      .tab {
        position: relative;
      }
    }

    ${mediaMdDefault} {
      display: flex;
      min-height: calc(100% - 10rem);

      .value-wrapper {
        flex: 0 0 32rem;
        padding-top: 7rem;
      }
    }

    ${mediaLgDefault} {
      .value-wrapper {
        flex: 0 0 38rem;
      }
    }
  }

  ${mediaSmDefault} {
    padding: 3rem;
    width: calc(100% - 6rem);
  }

  ${Grid as any} {
    width: 100%;
  }

  ${Row as any} {
    text-align: center;
  }

  .value-definition-help {
    top: 0;
    padding-bottom: 1rem;
    position: absolute;
    width: 100%;
    //min-height: calc(100% + 10px);
    min-height: calc(100% + 1.5rem);
    background: rgba(255, 255, 255, 0.95);

    ${mediaMdDefault} {
      height: 50vh;
      max-height: 50rem;
    }

    &.--hidden {
      opacity: 0;
      pointer-events: none;
    }

    h2 {
      color: ${darkBaseHEX};
      font-weight: 500;
      font-size: 2.6rem;
      letter-spacing: 0.5px;
      line-height: 3.3rem;
      text-align: center;
    }

    .flex {
      display: flex;
      margin-top: 5rem;

      ${mediaLgDefault} {
        padding-left: 38rem;
      }
    }

    ${BiggerText} {
      padding: 3rem;
      color: ${selectColor};
      font-size: 1.6rem;
      line-height: 2.4rem;

      strong {
        font-weight: 500;
        color: ${darkBaseHEX};
      }

      span {
        &::selection {
          color: #fff;
          background: ${darkenGreen};
        }
      }
    }

    .action-btns {
      span {
        &:hover {
          .tooltip-hover {
            opacity: 0.8;
          }
        }
      }
    }
  }

  .tooltip-hover {
    position: fixed;
    margin-top: -4.5rem;
    padding: 0.8rem 1.5rem;
    text-align: center;
    width: 20rem;
    font-size: 1.4rem;
    line-height: 1.7rem;
    background: rgba(${darkBaseRGB}, 0.6);
    opacity: 0;
    border-radius: 1.5rem;
    pointer-events: none;
    transition: opacity 300ms ease;
    -webkit-backface-visibility: hidden;

    span {
      font-weight: 400;
    }
  }

  .transition-group {
    width: 100%;

    ${mediaMdDefault} {
      display: block;

      .value-wrapper {
        //flex: 0 0 32rem;
      }
    }
  }

  .close {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #ffde71;
    border-radius: 50%;
    width: 4rem;
    height: 4rem;

    svg {
      display: block;
      width: 1.7rem;
      height: 1.7rem;

      g {
        g {
          fill: ${red};
        }
      }
    }
  }

  .value {
    ${mediaSmDefault} {
      padding: 0 3rem;
    }

    img,
    svg {
      margin-bottom: 3rem;
      width: 100%;
      height: 100%;
      max-width: 9rem;
      max-height: 9rem;

      path {
        fill: ${darkenGreen};
      }
    }

    h2 {
      margin-bottom: 1rem;
      color: ${darkBaseHEX};
      font-size: 2.6rem;
      letter-spacing: 0.5px;
      line-height: 3.3rem;
      font-weight: 500;
    }

    .picked {
      display: block;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
    }

    p {
      margin-top: 3rem;
      margin-bottom: 3rem;
      color: ${selectColor};
      font-size: 1.6rem;
      line-height: 2.4rem;
      text-align: center;
    }
  }

  ${StyledPrimaryButton} {
    margin-top: 3rem;
    padding: 1.8rem 3rem;
    height: 6rem;
    font-size: 2rem;
    letter-spacing: 0.5px;
    line-height: 2.6rem;
  }

  ${Col as any} {
    margin-top: 3rem;

    &:first-child {
      margin-top: 0;
    }

    ${mediaMdDefault} {
      margin-top: 0;
    }

    .react-reveal {
      height: 100%;
    }
  }

  .submenu {
    margin-top: 3rem;
    display: block;
    text-align: center;

    ${mediaMdDefault} {
      margin-top: 1.5rem;
    }

    &:after {
      content: "";
      display: block;
      margin-bottom: 3rem;
      width: 100%;
      height: 2px;
      opacity: 0.15;
      border-radius: 1px;
      background-color: #080b16;
      box-shadow: 0 5px 10px 0 rgba(8, 11, 22, 0.25);
    }
  }

  ul {
    ${mediaSmDefault} {
      padding-bottom: 1.5rem;
    }

    li {
      position: relative;
      display: inline-block;
      padding: 0 1.5rem 1.5rem;
      cursor: pointer;

      ${mediaSmDefault} {
        padding-bottom: 1px;
      }

      &.active {
        color: ${darkBaseHEX};
        font-weight: 500;

        ${mediaSmDefault} {
          &:after {
            content: "";
            display: block;
            position: absolute;
            left: 50%;
            top: 100%;
            margin-top: 1.5rem;
            margin-left: -1.5rem;
            width: 3rem;
            height: 0.2rem;
            background: ${darkBaseHEX};
          }
        }
      }
    }
  }

  .card {
    display: block;
    height: 100%;
    border-radius: 0.4rem;

    box-shadow: 0 5px 10px 0 rgba(25, 29, 44, 0.4);
  }

  ${StyledTextArea} {
    border: 2px solid ${whiteFade};
    height: 100%;
    transition: border 0.3s;

    &:hover,
    &:focus {
      border: 2px solid ${grayFooter};
    }
  }

  form {
    img {
      display: block;
    }

    ${StyledPrimaryButtonButton} {
      margin-top: 3rem;
    }

    ${mediaMdDefault} {
      text-align: left;
    }
  }

  .tab {
    margin-left: -1.5rem;
    margin-right: -1.5rem;

    .fade-enter-done {
      height: 100%;
    }

    ${Col as any} {
      & > div {
        position: relative;
        height: 100%;

        & > div {
          ${mediaMdDefault} {
            //position: absolute;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
`;

export const StyledValueDefinitionCard = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 0.4rem;
  background: radial-gradient(circle at top, #247bcb 0%, #1769b4 100%);
  box-shadow: 0 5px 10px 0 rgba(25, 29, 44, 0.4);
  overflow: hidden;

  ${mediaMdDefault} {
    min-height: 38rem;
    height: 50vh;
    max-height: 50rem;
  }

  &[data-color="green"] {
    background: radial-gradient(circle at top, ${GreenTheme.primary} 0%, ${GreenTheme.darken} 100%);
  }

  &[data-color="blue"] {
    background: radial-gradient(circle at top, ${BlueTheme.primary} 0%, ${BlueTheme.darken} 100%);
  }

  &[data-color="purple"] {
    background: radial-gradient(circle at top, ${VioletTheme.primary} 0%, ${VioletTheme.darken} 100%);
  }

  .label {
    position: absolute;
    padding: 0.8rem 3rem;
    top: 2rem;
    left: 0;
    border-radius: 0 3rem 3rem 0;
    background-color: ${yellow};
    color: #191d2c;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.8rem;
    text-transform: uppercase;
  }

  .img-wrapper {
    position: relative;
    min-height: 15rem;
    overflow: hidden;

    ${mediaLgDefault} {
      min-height: 20rem;
    }

    img {
      display: block;
      width: 100%;
    }

    .img-bottom {
      position: static;
      padding: 2rem;
      padding-bottom: 0;
      background: transparent;
      bottom: 0;
      width: 100%;
      color: #fff;

      ${mediaSmDefault} {
        position: absolute;
        padding: 2rem;
        background: linear-gradient(180deg, rgba(25, 29, 44, 0) 0%, #191d2c 100%);
      }

      ${mediaLgDefault} {
        padding: 3rem;
      }

      strong {
        font-size: 1.8rem;
        line-height: 2.3rem;
        opacity: 1;
      }

      span {
        display: block;
        margin-top: 0.6rem;
        font-size: 1.6rem;
        line-height: 2rem;
      }

      ${mediaLgDefault} {
        text-align: left;
      }
    }
  }

  p {
    padding: 2rem;
    padding-bottom: 6rem;
    color: ${gray};
    font-size: 1.6rem;
    line-height: 2.4rem;
    ${ScrollbarStyles};

    ${mediaSmDefault} {
      padding: 2rem;
      height: 100%;
      max-height: 100%;
      overflow-y: auto;
    }

    ${mediaLgDefault} {
      text-align: left;
      padding: 3rem;
    }
  }

  strong,
  span {
    ${mediaSmMax} {
      font-size: 1.5rem;
    }
  }
  .action-btns {
    display: flex;
    justify-content: space-between;
    position: static;
    width: 100%;
    height: 4rem;
    min-height: 4rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    padding: 0 1.5rem;

    & > span {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      border: 2px solid ${yellow};
      cursor: pointer;
      transition: background 0.3s;

      svg {
        display: block;
      }

      &:hover {
        background: ${yellow};
      }

      &.active {
        background: ${yellow};
        cursor: not-allowed;
      }
    }
  }

  & > div {
    p {
      height: auto;
    }
  }
`;

export const StyledValueDefinitionQuote = styled(StyledValueDefinitionCard)`
  align-items: center;

  div {
    padding: 7.5rem 2rem;
    width: 100%;
    height: calc(100% - 7rem);
    color: #fff;
    text-align: center;

    ${mediaLgDefault} {
      padding: 8.5rem 3rem;
      text-align: left;
    }

    strong {
      display: block;
      margin-top: 3rem;
      margin-bottom: 0.6rem;
      font-weight: 400;
      opacity: 0.7;
    }

    span {
      display: block;
      font-size: 1.4rem;
      line-height: 2rem;
      opacity: 0.7;
    }
  }

  p {
    padding: 0;
  }
`;

export const StyledTransitionWrapper = styled.div`
  position: relative;
  width: 100%;

  .transition-group {
    display: flex;
    position: relative;
  }

  /* & > .transition-group > .fade-enter-active,
  & > .transition-group > .fade-exit-active {
    position: absolute;
    top: 0;
  } */
`;
