import * as React from 'react';
import { FC } from 'react';
import { Box, TextProps, Typography } from '@xcorejs/ui';
import Typewriter from 'typewriter-effect';

type TypedParagraphProps = {
  text?: string;
  _text?: TextProps;
  setTypeWriterDone: () => unknown;
  delay: number;
  state: boolean;
}

const TypedParagraph: FC<TypedParagraphProps> = (
  {
    text,
    _text,
    setTypeWriterDone,
    delay,
    state
  }
) => {
  return (
    <Box position='relative'>
      <Typography
        fontSize='1.4rem'
        lineHeight='1.8rem'
        opacity={state ? 1 : 0 }
        display={state ? 'block' : 'none' }
        {..._text}
      >
        {text && <div dangerouslySetInnerHTML={{ __html: text }} />}
      </Typography>

      <Typography
        position='relative'
        left={0}
        top={0}
        fontSize='1.4rem'
        lineHeight='1.8rem'
        textAlign='center'
        width='100%'
        display={state ? 'none' : 'block' }
        {..._text}
      >
        <Typewriter
          options={{
            cursor: '|',
            delay: 40,
          }}
          onInit={(typewriter:any) => {
            typewriter
              .pauseFor(delay + 300)
              .typeString(`${text}`)
              .start()
              .pauseFor(100)
              .callFunction(() => {
                setTypeWriterDone();
              });
          }}
        />
      </Typography>
    </Box>
  );
};

export default TypedParagraph;
