import styled from 'styled-components';
import { Icon } from '@xcorejs/ui';

interface IconJumpProps {
  duration?: string;
  delay?: string;
  scale?: number;
  pos?: string;
  overflow?: string;
}

export const IconJump = styled(Icon)<IconJumpProps>`
  --icon-scale: scale(${({ scale }) => scale || 1});

  position: ${({ pos }) => pos + ' !important' || 'relative'};
  transform: var(--icon-scale);
  overflow: ${({ overflow }) => overflow + ' !important' || 'hidden'};

  animation: myAnim ${({ duration }) => duration || '2s'} infinite;
  -moz-animation: myAnim ${({ duration }) => duration || '2s'} infinite;
  -webkit-animation: myAnim ${({ duration }) => duration || '2s'} infinite;

  animation-delay: ${({ delay }) => delay || '0s'};

  @keyframes myAnim {
    0%,
    25%,
    50%,
    75%,
    100% {
      transform: var(--icon-scale) translateY(0);
    }
    37% {
      transform: var(--icon-scale) translateY(-16px);
    }
    60% {
      transform: var(--icon-scale) translateY(-8px);
    }
  }
`;