import { IAddRecord } from '../../../organisms/Test/data';
import { jsonFetch } from '../../../../utils/fetch';
import { SURVEY_ADD_RECORD, SURVEY_SKIP } from 'config/api/surveyInternal';

export const addSurveyRecord = <TData>(surveyId: string, data: IAddRecord<TData>) =>
  jsonFetch(SURVEY_ADD_RECORD(surveyId), {
    method: 'post',
    body: JSON.stringify(data),
  });

export const skipSurvey = (surveyId: string) =>
  jsonFetch(SURVEY_SKIP(surveyId), {
    method: 'post',
  });
