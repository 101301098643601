import * as React from 'react';
import withStatistics, { IWithStatistics } from '../../molecules/withRedux/withStatistics';
import withLifeValues, { IWithLifeValues } from '../../molecules/withRedux/withLifeValues';
import Values, { ILifeValueItem } from './Values';
import { CardColor } from '../../atoms/Cards';
import { IValueTestResultDetail } from 'config/api/valueTestResult';
import { IColorTheme } from 'components/theme/colors';

interface IMyValues {
  cols?: number;
  rows?: number;
  result: IValueTestResultDetail;
  color?: CardColor | ((item: ILifeValueItem) => CardColor);
  badge?: string | ((itm: ILifeValueItem) => string);
  action?: React.ComponentType<ILifeValueItem>;
  changeBehavior?: (detail: string) => void;
  cardAction?: React.ReactNode;
  theme?: IColorTheme;
}

const MyValues: React.FC<IMyValues & IWithStatistics & IWithLifeValues> = ({
  lifeValues,
  statistics,
  cols = 1,
  rows = 5,
  color = 'green' as CardColor,
  badge,
  action,
  result,
  changeBehavior,
  cardAction,
  theme
}) => (
  <Values
    items={result.allLifeValues
      .sort((a, b) => a.rank - b.rank)
      .slice(0, 10)
      .map(v => ({
        value: lifeValues[v.valueId],
        top: !!statistics[v.valueId] && statistics[v.valueId].rank < 11,
      }))}
    cols={cols}
    rows={rows}
    color={color}
    badge={badge === undefined ? itm => itm.top ? 'top' : undefined : badge}
    action={action}
    changeBehavior={changeBehavior}
    cardAction={cardAction}
    theme={theme}
  />
);

export default withLifeValues(withStatistics(MyValues));
