import * as React from 'react';
import { FC } from 'react';
import { StyledPremiumStrip } from './styles';
import { Col, Grid, Row } from 'react-styled-flexboxgrid';
import { PlayButton } from '../../../atoms/Buttons';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import VimeoPopup from '../../../atoms/Popups/VimeoPopup';
import SVGplay from '../../../atoms/SVGIcons/SVGplay';
import withPopups, { IWithPopups } from '../../../molecules/withRedux/withPopups';
import { Flex } from '@xcorejs/ui';

const PremiumStrip: FC<IWithPopups> = ({ popups }) => (
  <StyledPremiumStrip>
    <Grid>
      <Row>
        <Col xs={12}>
          <div className='inner'>
            <FormattedHTMLMessage id='premium.strip.text' />

            {/*
            <FAQPopup popup={EPremium}>
              <PremiumButton />
            </FAQPopup>
            */}

            <Flex mt='1.5rem' mb='-1.5rem' className='flex'>
              <PlayButton onClick={() => popups.custom(() => <VimeoPopup id={'314753998'} />)}>
                <SVGplay />
                <FormattedMessage id={'vlc.video.play2'} />
              </PlayButton>

              <PlayButton onClick={() => popups.custom(() => <VimeoPopup id={'376328496'} />)}>
                <SVGplay />
                <FormattedMessage id={'test.result.graph.video'} />
              </PlayButton>
            </Flex>
          </div>
        </Col>
      </Row>
    </Grid>
  </StyledPremiumStrip>
);

export default withPopups(PremiumStrip);
