import React, { FC } from 'react';
import { Flex, Heading2, Img, Paragraph, Icon, FlexProps } from '@xcorejs/ui';
import { TablePlus as StyledTable, StyledTag, StyledList } from './styles';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { OliLight } from 'components/theme/fonts';
import StarIcon from '../../components/icons/24/star.svg';
import ToolIcon from '../../components/icons/24/tool.svg';
import { FreeTag, PlusTag } from 'components/atoms/Logo';
import { PlusTheme } from 'components/theme/colors';

interface TierInfo {
  text: string;
  color?: string;
}

type TierProps = boolean | TierInfo | string[] | number[];

interface DataProps {
  name: string;
  free: TierProps;
  plus: TierProps;
  ladders?: boolean;
  tree?: boolean;
}

const plusColor = '#f2ad7c';

const data: DataProps[] = [
  {
    name: 'Vyhodnocení životních hodnot',
    free: {
      text: 'Základní'
    },
    plus: {
      text: 'Kompletní',
      color: plusColor
    }
  },
  {
    name: 'Funkcionality v&nbsp;hodnotové hře',
    free: {
      text: 'Základní'
    },
    plus: {
      text: 'Kompletní',
      color: plusColor
    }
  },
  {
    name: 'Zobrazení hodnotových grafů',
    free: false,
    plus: true
  },
  {
    name: 'Zobrazení všech 42 hodnot ve&nbsp;vyhodnocení',
    free: false,
    plus: true
  },
  {
    name: 'Funkce porovnání hodnotových žebříčků',
    free: false,
    plus: true
  },
  {
    name: 'Plný přístup k&nbsp;anketám a&nbsp;statistikám Eticia',
    free: false,
    plus: true
  },
  {
    name: 'Další hodnotové žebříčky dle&nbsp;tvého výběru',
    free: ['free', 'plus1', 'plus3', 'plusForever'],
    plus: [0, 1, 3, 6],
    ladders: true
  },
  // {
  //   name: 'Strom hodnot pro dva',
  //   free: ['free', 'plus1', 'plus3', 'plusForever'],
  //   plus: [0, 0, 0, 1],
  //   tree: true
  // },
];

interface TableProps extends FlexProps {
  transparentBackground?: boolean;
}

const Table: FC<TableProps> = ({ transparentBackground, ...props }) => {


  return ( 
    <Flex
      as='section'
      flexDirection='column'
      backgroundColor={transparentBackground ? 'transparent' : '#b14669'}
      alignItems='center'
      {...props}
    >
      <Heading2 mt='2rem'>
        <FormattedMessage id='eticiaPlus.table.heading' />
      </Heading2>

      <TablePlus />
      
    </Flex>
  );
};
 
export default Table;

export const TablePlus: FC<{noMargin?: boolean}> = ({ noMargin }) => {
  const styledTagProps = {
    fontFamily: OliLight,
    mb: 'unset !important',
    borderRadius: '0.5rem',
  };
  
  const writeInfo = (info: TierProps, ladders?: boolean, tree?: boolean) => {
    if (typeof info === 'boolean') {
      return info ? (
        <Flex justifyContent='center' alignItems='center'>
          <Img src='/img/check_orange.svg' alt='' transform='scale(1.15)' />
        </Flex>
      ) : <Paragraph>-</Paragraph>;
    } else if (Array.isArray(info)) {
      let infoArray = info as string[] | number[];
      if (infoArray.length > 0)
        if (typeof infoArray[0] === 'string') {
          infoArray = infoArray as string[];
          return (
            <StyledList>
              {infoArray.map((item, j) => (
                <li key={j}>
                  {item === 'free' ? <FreeTag {...styledTagProps} onClick={() => {}} /> : 
                    item.includes('plus') ? 
                      isNaN(Number(item.charAt(4))) ? (
                        <StyledTag 
                          bg='radial-gradient(circle, #EB9A61 0%, #EB9A61 100%)'
                          opacity={0.6}
                          {...styledTagProps}
                        >Plus Navždy</StyledTag>
                      ) : (
                        <StyledTag
                          bg={PlusTheme.primary}
                          {...styledTagProps}
                        >
                          {item.charAt(4) == '1' ? 'Plus 1 rok' : item.charAt(4) == '3' ? 'Plus 3 roky' : null}
                        </StyledTag>
                      )
                      : null }
                </li>
              ))}
            </StyledList>
          );
        } else if (typeof infoArray[0] === 'number') {
          infoArray = infoArray as number[];
          return (
            <>
              <StyledList>
                {infoArray.map((item, j) => (
                  <li key={j}>
                    {tree ? (
                      <>
                        <Paragraph>{item === 0 ? '-' : item + 'x'}</Paragraph>
                        {item !== 0 ? (
                          <Img
                            src='/img/value-tree.svg'
                            alt=''
                            position='absolute'
                            top='0'
                            right='-26px'
                            transform='scale(2.2)'
                          />
                        ) : null }
                      </>
                    ) :
                      ladders ?  
                        item === 0 ? '-' : (
                          <StyledTag
                            bg='transparent'
                            border='1px solid #9b4b6e'
                            width='5.5rem'
                            justifyContent='center'
                            padding='unset !important'
                            opacity={j === infoArray.length - 1 ? 0.6 : 1}
                          >
                            <Paragraph color='#f2ad7c' mr='0.4rem'>+ {item}</Paragraph>
                            <Img src='/img/ladder.svg' alt='' />
                          </StyledTag>
                        )
                        : null
                    }
                  </li>
                ))}
              </StyledList>
            </>
          );
        } else return null;
    } else if (typeof info === 'object') {
      const tierInfo = info as TierInfo;
      return (              
        <Paragraph 
          color={tierInfo.color || '#ddd'} 
          fontFamily={OliLight}
          fontSize={{ _: '1.2rem', md: '1.5rem' }}
        >
          {tierInfo.text}
        </Paragraph>
      );
    } else return null;
  };

  return (
    <StyledTable noMargin={noMargin}>
      <thead>
        <tr>
          <th>&nbsp;</th>
          <th><FreeTag /></th>
          <th>
            {/* <Flex
              justifyContent='center'
              position='relative'
            >
              <Icon 
                svg={<StarIcon />} 
                color={plusColor} 
                transform='scale(0.4) translate(-70px, -16px)'
                position='absolute'
                overflow='visible !important'
              />Plus
            </Flex> */}
            <PlusTag />
          </th>
        </tr>
      </thead>

      <tbody>
        {data.map((row, index) => (
          <tr key={index}>
            <td dangerouslySetInnerHTML={{ __html: row.name }}></td>
            <td>{writeInfo(row.free, row.ladders, row.tree)}</td>
            <td>{writeInfo(row.plus, row.ladders, row.tree)}</td>
          </tr>
        ))}
        <tr
          className='tree-coming-soon'
        >
          <td>
            <FormattedHTMLMessage id='eticiaPlus.table.tree1' />
            <span style={{ marginLeft: '-7px' }}>
              <Icon svg={<ToolIcon />} transform='scale(0.7)' mr='0.3rem' />
              <FormattedHTMLMessage id='eticiaPlus.table.tree2' />
            </span>
          </td>
          <td>
            <Flex justifyContent='center' alignItems='center' flexDirection='column'>
              <StyledTag 
                bg='radial-gradient(circle, #EB9A61 0%, #EB9A61 100%)'
                opacity={0.6}
                {...styledTagProps}
              >
                <FormattedMessage id='eticiaPlus.table.comingSoon' />
              </StyledTag>
              <Paragraph
                fontSize={{ _:'1rem', md: '1.2rem' }} 
                lineHeight={{ _:'1.4rem', md: '1.8rem' }}
                mt='0.5rem'
                fontFamily={OliLight}
                color='#ddd'
              >
                <FormattedHTMLMessage id='eticiaPlus.table.comingSoonDate' />
              </Paragraph>
            </Flex>
          </td>
          <td>
            <Flex
              justifyContent='center'
              alignItems='center'
              position='relative'>
              <Paragraph>1x</Paragraph>
              <Img
                src='/img/value-tree.svg'
                alt=''
                ml='1rem'
                mr='1rem'
                transform='scale(1.2)'
              />
            </Flex>
          </td>
        </tr>
      </tbody>
    </StyledTable>
  );
};
