import React, { FC } from 'react';
import Navigation from 'components/atoms/HPNav';
import { StyledHomepage } from '../styles';
import AboutEticia from './Components/AboutEticia';
import Timeline from './Components/Timeline';
import Team from 'components/organisms/InfoSlides/Team/component';
import PartnersWithInfo from 'components/organisms/InfoSlides/Partners/PartnersWithInfo';
import { Footer } from 'components/organisms/Layout/Footer';

const About: FC = () => {
  return ( 
    <>
      <Navigation />

      <StyledHomepage>
        <AboutEticia />
        <Timeline />
        <Team />
        <PartnersWithInfo />
        <Footer />
      </StyledHomepage>
    </>
  );
};
 
export default About;