import { Flex, Heading1, Img, Paragraph } from '@xcorejs/ui';
import AnimatedArrow from 'components/atoms/Icons/AnimatedArrow';
import { BlueTheme, white } from 'components/theme/colors';
import React from 'react';
import { Heading3Em } from './styles';

const Header = () => {
  return ( 
    <Flex flexDirection='column' justifyContent='center' alignItems='center' paddingTop={{ _: '4rem' }} paddingX={{ _: '2rem' }} background={`linear-gradient(180deg, ${BlueTheme.extraDark}, transparent)`} position='relative'>
      <Img src='/img/atlas/eticiameter.svg' alt='eticiameter' width='30rem' />
    
      <Heading1 marginTop={{ _: '4rem' }}>Global world values</Heading1>

      <Heading3Em marginTop={{ _: '2rem' }}>Already <span>242125</span> people from <span>38</span> countries has their life values ladder.</Heading3Em>

      <Paragraph marginTop={{ _: '2rem' }} color={white} maxWidth={{ _: '55rem' }}>
    Hodnoty mohou spojovat lidi, vzdálené na stovky kilometrů i rozdělovat ty, kdo bydli za rohem.
    Někdy skoro automaticky očekáváme, že budeme mit nejvíc podobné hodnoty s lidmi,
    kteří jsou nám mistem, věkem či sociálním postavenim nejbližší. Ale je to vždycky tak?
      </Paragraph>

      <Flex
        justifyContent='center'
        width='100%'
        // position='absolute'
        bottom={{ _: '-1rem', md: '1rem' }}
        left='0'
        zIndex={10}
        onClick={() => window.scrollTo({ top: window.innerHeight - 300, behavior: 'smooth' })}
      >
        <AnimatedArrow
          color='#white'
          transform={{ _: 'scale(0.7) rotate(90deg)', md: 'scale(0.55) rotate(90deg)' }}
          cursor='pointer'
        />
      </Flex>
    </Flex>
  );
};
 
export default Header;