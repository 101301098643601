import { Box } from '@xcorejs/ui';
import { StyledFakeLinkBorder, StyledLink } from 'components/atoms/Buttons/styles';
import { StyledTable } from 'components/atoms/Table/styles';
import { darkBaseRGB, grayRGB } from 'components/theme/colors';
import { mediaLgDefault, mediaMdDefault, mediaSmDefault } from 'components/theme/grid';
import styled from 'styled-components';

export const ChoiceStyle = (insideGame?: boolean) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: insideGame ? '6.4rem' : { _: '6.4rem', sm: '9.4rem' },
  minWidth: '4rem',
  fontSize: '1.3rem',
  lineHeight: '1.8rem',
  px: '0.4rem',
  fontWeight: 800,
});

export const CircleFlexStyle = (insideGame?: boolean) => ({
  alignItems:'center',
  justifyContent:'center',
  width: insideGame ? '6.4rem' : { _: '6.4rem', sm: '9.4rem' },
  minWidth: insideGame ? '6.4rem' : { _: '6.4rem', sm: '9.4rem' },
  height:'4rem',
});

export const InputStyle = styled(Box)`
  width: 100%;
  
  input {
    font-size: 1.6rem;
    line-height: 2rem;
    width: 100%;
    max-width: 41rem;
    margin: 0 auto;
    text-align: center;
  }
`;

export const TableStyle = styled(StyledTable)`
    table-layout: auto;
    display: block;

    ${mediaSmDefault} {
      display: table;
    }

    tbody {
      display: block;

      ${mediaSmDefault} {
        display: table-row-group;
      }
    }

    tr {
      margin-bottom: 2rem;
      transition: background 0.3s;
      display: block;
      border-top: 1px solid rgba(${darkBaseRGB}, 0.25);
      
      &:first-child {
        border-top: 0;
      }

      ${mediaSmDefault} {
        margin-bottom: 0;
        display: table-row;
        border-top: 0;

        &:last-child {
          td {
            border-bottom: 0
          }
        }
      }
    }

    td {
      padding: 1rem 1rem 0.9rem;
      width: auto;
      text-align: center;
      display: block;
      border-bottom: 0;
      
      &:first-child {
        padding: 2rem 1rem 0.9rem 1rem;
        
        ${mediaSmDefault} {
          padding: 1rem 1rem 0.9rem 3rem;
        }
      }

      &:last-child {
        ${mediaLgDefault} {
          padding-right: 3rem;
        }
      }
      
      span, strong {
        text-align: center;

        ${mediaSmDefault} {
          display: inline-block;
          width: auto;
          vertical-align: middle;
          text-align: left;
        }
      }

      ${mediaSmDefault} {
        display: table-cell;
        width: 33.33%;
        border-bottom: 1px solid rgba(${darkBaseRGB}, 0.25);
      }

      ${mediaMdDefault} {
        text-align: left;
      }

      ${mediaLgDefault} {
        padding: 1.5rem 2rem 1.4rem;
        text-align: left;
      }

      & > * {
        vertical-align: middle;
      }

      &.btn {
        span {
          display: inline-block;
          vertical-align: middle;
        }
      }

      &:first-child {
        border-top-left-radius: .4rem;
        border-bottom-left-radius: .4rem;
      }

      &:last-child {
        text-align: center;
        border-bottom-right-radius: .4rem;
        border-top-right-radius: .4rem;
      }

      img {
        margin-right: 2rem;
        vertical-align: middle;
      }

      &.name {
        strong {
          vertical-align: middle;
        }
      }
    }

    .type,
    ${StyledLink}, .name, ${StyledFakeLinkBorder} {
      font-size: 1.5rem;
      line-height: 2.1rem;
    }

    ${StyledLink}, ${StyledFakeLinkBorder} {
      color: rgba(${grayRGB}, .8);
    }

    .date {
      text-align: center;
      white-space: nowrap;
      min-width: 6.5rem;

      ${mediaSmDefault} {
        text-align: right;
      }
    }

    .active {
      border-radius: 0.4rem;

      .type > span {
        display: block;
      }
    }
`;
