import { pdf } from '@react-pdf/renderer';
import { Icon } from '@xcorejs/ui';
import { saveAs } from 'file-saver';
import React, { FC, Fragment } from 'react';
import { IUserInfo } from 'store/User';
import { useFetchTestResultDetail } from 'utils/useQuery/queryFetchHelpers';
import { PdfIcon } from '../../../../components/icons/16/pdf.svg';
import PdfResultId from '../../pdfs/result/[id]';
import { ITestResultResponse } from '../../TestIntro/data';

interface PDFButtonProps {
  resultId: string;
  user: IUserInfo;
}
const PDFButton: FC<PDFButtonProps> = ({ resultId, user }) => {
  const testResultFetch = useFetchTestResultDetail(resultId);

  if (testResultFetch.isLoading || testResultFetch.isError) {
    return <Fragment />;
  }

  const resultDetail = testResultFetch.data!;
  
  const generatePdfDocument = async (result: ITestResultResponse, user: IUserInfo, fileName: string, ) => {
    const blob = await pdf((
      <PdfResultId result={result} user={user} />
    )).toBlob();
    saveAs(blob, fileName);
  };

  return ( 
    <div>
      <Icon
        {...{ as: 'div' }}
        display='flex'
        flex='0 0 auto'
        alignItems='center'
        justifyContent='center'
        svg={<PdfIcon />}
        width='3rem'
        height='3rem'
        bg='rgba(11, 12, 28, 0.5)'
        borderRadius='50%'
        mr='1rem'
        fill='#fefefe'
        p='0.3rem'
        cursor='pointer'
        onClick={() => generatePdfDocument(resultDetail, user, resultId)}
      />
      {/* {console.log('resultId: ' + result.id)} */}
    </div>
  );
};
 
export default PDFButton;