import * as React from 'react';
import { Heading2 } from '../../../atoms/Headings';
import { BiggerText } from '../../../atoms/Text';
import { Row, Col } from 'react-styled-flexboxgrid';
import TextBox from '../../../organisms/TextBox';
import { FormattedMessage } from 'react-intl';
import Fade from 'react-reveal/Fade';
import { HowItWorksSection, ImgWrapper, ValuesAdvantages } from './styles';
import FAQPopup from '../../../atoms/Popups/FAQPopup';
import { StyledLinkBorder } from '../../../atoms/Buttons/styles';
import { Box } from '@xcorejs/ui';
import { HowToValuesComparison } from '../../../organisms/VisualValuesComparison';

const HowItWorks: React.FC = () => (
  <HowItWorksSection className={'bg-blue'}>
    <TextBox className='blue'>
      <Fade ssrReveal bottom duration={1000} distance={'5rem'}>
        <Heading2>
          <FormattedMessage id={'vlc.howItWorks.heading'} />
        </Heading2>

        <BiggerText>
          <FormattedMessage id={'vlc.howItWorks.text'} />
        </BiggerText>

        <ValuesAdvantages>
          <Row>
            <Col xs={12} sm={6} md={3}>
              <ImgWrapper>
                <img src='/img/ico/game/game.svg' alt='' />
              </ImgWrapper>
              <p>
                <FormattedMessage id={'vlc.howItWorks.advantages.1'} />
              </p>
            </Col>
            <Col xs={12} sm={6} md={3}>
              <ImgWrapper>
                <img src='/img/vlc/jak-to-funguje/okruhy.svg' style={{ opacity: 0.8 }} alt='' />
              </ImgWrapper>
              <p>
                <FormattedMessage id={'vlc.howItWorks.advantages.2'} />
              </p>
            </Col>
            <Col xs={12} sm={6} md={3}>
              <ImgWrapper>
                <img src='/img/vlc/jak-to-funguje/pozvanka.svg' style={{ opacity: 0.7 }} alt='' />
              </ImgWrapper>
              <p>
                <FormattedMessage id={'vlc.howItWorks.advantages.3'} />
              </p>
            </Col>
            <Col xs={12} sm={6} md={3}>
              <ImgWrapper>
                <img src='/img/ico/game/ladders.svg' alt='' />
              </ImgWrapper>
              <p>
                <FormattedMessage id={'vlc.howItWorks.advantages.4'} />
              </p>
            </Col>
          </Row>
        </ValuesAdvantages>

        <Box mt='4rem'>
          <StyledLinkBorder to='#' color='yellow' withoutAfterBorder={true}>
            <FAQPopup popup={HowToValuesComparison}>
              <span>
                <FormattedMessage id='vlc.howItWorks.popup' />
              </span>
            </FAQPopup>
          </StyledLinkBorder>
        </Box>
      </Fade>
    </TextBox>
  </HowItWorksSection>
);

export default HowItWorks;
