import { adformVoucherUrl } from 'config/adform';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { isReadable } from '../../../../../../utils/rescource';
import { PrimaryButtonLink } from '../../../../../atoms/Buttons';
import { Heading1 } from '../../../../../atoms/Headings';
import SVGvoucher from '../../../../../atoms/SVGIcons/SVGvoucher';
import withUser, { IWithUser } from '../../../../../molecules/withRedux/withUser';
import { SmallerText } from '../../../../../organisms/VisualValuesComparison/style';
import { createUrl } from '../../../../routes';
import { FlexColumn, StyledSuccessVoucher, StyledValueComparisonSection } from '../../Comparisons/styles';

const SuccesComparison: React.FC<IWithUser> = ({ rescource }) => (
  <StyledValueComparisonSection>
    <FlexColumn>
      <StyledSuccessVoucher>
        <Heading1>
          <FormattedMessage id={'comparison.success'} />
        </Heading1>

        <SVGvoucher />

        <SmallerText>
          <FormattedMessage id={'comparison.success.text'} />
        </SmallerText>

        {isReadable(rescource.user) && (
          <PrimaryButtonLink to={createUrl('app.comparison') + '/seznam-voucher'}>
            <FormattedMessage id={'voucher.success.btn'} />
          </PrimaryButtonLink>
        )}
      </StyledSuccessVoucher>
    </FlexColumn>
    <iframe src={adformVoucherUrl(0)} style={{ display: 'none' }}></iframe>
  </StyledValueComparisonSection>
);

export default withUser(SuccesComparison, false);
