import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { createUrl } from 'components/pages/routes';
import withLifeValues, { IWithLifeValues } from 'components/molecules/withRedux/withLifeValues';
import Subnav from 'components/atoms/Subnav';
import NavItem from 'components/atoms/Nav/NavItem';
import { ResultConfig, ConfigWrapper } from './data';
import WaitingForResult from '../../../../../organisms/PartnerTest/Result/WaitingForResult';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import useFetch from '../../../../../atoms/Fetch/useFetch';
import LoadingSpinner from 'components/atoms/Animations/LoadingSpinner';
import { ITestCompare } from '../data';
import BasePage from './BasePage';
import { StyledPartnerTestResult } from '../styles';
import { PARTNER_TEST_RESULT_FUNC } from '../../../../../../config/apiRoutes';

const urlToComponents = (url: string, offset: number) => {
  const parsedUrl = url.split('/');
  return {
    page: parsedUrl[offset],
    subpage: parseInt(parsedUrl[offset + 1], 10) || 0,
  };
};

const ValuesForTwoResult: React.FC<RouteComponentProps<{ id: string }> & IWithLifeValues> = ({
  match,
  location,
  history,
  lifeValues,
}) => {
  const id = match.params.id;
  const url = urlToComponents(location.pathname, 6);
  const baseUrl = createUrl('app.partnerResult', { id });
  const pages = ConfigWrapper(ResultConfig.pages);
  if (url.page === undefined) {
    history.replace(baseUrl + pages[0].to);
  }

  const result = useFetch(PARTNER_TEST_RESULT_FUNC(id));

  return (
    <>
      <Subnav>
        {pages.map((x, i) => (
          <NavItem key={i} to={baseUrl + x.to} label={x.label} exact={false} />
        ))}
      </Subnav>

      <StyledPartnerTestResult>
        <Grid>
          <Row>
            {result.state === 'success' && result.result && (
              <BasePage
                lifeValues={lifeValues}
                result={result.result as ITestCompare}
                pages={pages}
                url={url}
                id={id}
              />
            )}
            {(result.state === 'fetching' || result.state === 'reload') && <LoadingSpinner />}
            {result.state === 'fail' && (
              <Grid>
                <Row>
                  <Col xs={12} md={6} mdOffset={3}>
                    <WaitingForResult id={id} errorCode={result.error!.status!} />
                  </Col>
                </Row>
              </Grid>
            )}
          </Row>
        </Grid>
      </StyledPartnerTestResult>
    </>
  );
};

export default withRouter(withLifeValues(ValuesForTwoResult));
