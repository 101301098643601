import { jsonFetch } from '../../../../utils/fetch';
import { EDIT_PROFILE } from 'config/apiRoutes';
import { IRegisterUserVm } from './RegisterAfterGoogle';
import { IDemoUserInfo } from 'store/User';

export interface IRegisterData extends IRegisterUserVm {
  registrationLocale: string;
}

export const editProfile = (data: IRegisterUserVm) =>
  jsonFetch<IDemoUserInfo>(EDIT_PROFILE, {
    method: 'POST',
    body: JSON.stringify(data),
  });
