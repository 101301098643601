import React from 'react';
import { IPerson } from 'store/Entities/LifeValues';

interface useShowArrowProps {
  projects: IPerson[];
  infoCardVisible: boolean;
}

export const useShowArrow = ({ projects, infoCardVisible }: useShowArrowProps) => {
  const [cursor, setCursor] = React.useState('default');
  const [display, setDisplay] = React.useState('none');

  React.useEffect(() => {
    if (projects.length <= 4 && !infoCardVisible) {
      setCursor('default');
      setTimeout(() => setDisplay('none'), 300);
    } else {
      setCursor('pointer');
      setDisplay('block');
    }
  }, [projects.length, infoCardVisible]);

  return { cursor, display };
};

export const useGetBoxHeight = (ref: React.RefObject<HTMLDivElement>) => {
  const [infoBoxHeight, setInfoBoxHeight] = React.useState(0);

  const getBoxHeight = React.useCallback(() => {
    setTimeout(() => {
      setInfoBoxHeight(ref.current && ref.current.offsetHeight || 0);
    }, 200);
  }, [ref]);

  React.useEffect(() => {
    getBoxHeight();
  }, [getBoxHeight]);

  return { infoBoxHeight, getBoxHeight };
};

interface useHandleProjectChangeProps {
  projects: IPerson[];
  infoCardVisible: boolean;
  setInfoCardVisible: React.Dispatch<React.SetStateAction<boolean>>;
  getBoxHeight: () => void;
  offset?: number;
}

interface ProjectChangeParams {
  index: number;
  open?: boolean;
  direction?: 'left' | 'right';
}

export const useHandleProjectChange = ({ 
  projects, 
  infoCardVisible, 
  setInfoCardVisible, 
  getBoxHeight,
  offset = 0
} : useHandleProjectChangeProps) => {
  const [currentProjectInfo, setCurrentProjectInfo] = React.useState(projects[0]); 
  const [currentProjectIndex, setCurrentProjectIndex] = React.useState(0);
  
  const [projectsPage, setProjectsPage] = React.useState(0);

  React.useEffect(() => {
    setProjectsPage(Math.floor(currentProjectIndex / offset));
  }, [currentProjectIndex, offset]);

  const handleProjectChange = ({ index, open, direction }: ProjectChangeParams) => {
    if (open || infoCardVisible) {
      setCurrentProjectInfo(projects[index]);
      setCurrentProjectIndex(index);
      getBoxHeight();
      !infoCardVisible && setTimeout(() => setInfoCardVisible(true), 250);
    } else if (!infoCardVisible && projects.length > 4) {
      if (direction === 'left') {
        setProjectsPage(projectsPage - 1 < 0 ? Math.ceil(projects.length / 4) - 1 : projectsPage - 1);
      } else if (direction === 'right') {
        setProjectsPage(projectsPage + 1 > Math.ceil(projects.length / 4) - 1 ? 0 : projectsPage + 1);
      }
    } 
  };

  return { handleProjectChange, currentProjectInfo, currentProjectIndex, projectsPage };
};