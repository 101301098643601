import * as React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import { Col, Grid, Row } from 'react-styled-flexboxgrid';
import { GiftButton, PrimaryButton } from '../../atoms/Buttons';
import { StyledFakeLinkBorder, StyledGreenStrokeLink, StyledLinkBorder } from '../../atoms/Buttons/styles';
import { Heading2, Heading3 } from '../../atoms/Headings';
import { SectionText } from '../../atoms/Text';
import { createUrl } from '../../pages/routes';
import HeadingValuesComparison from './Heading';
import {
  BtnGroup,
  StyledHowToValuesComparisonPopup,
  ValuesComparisonVisual as StyledValuesComparisonVisual,
} from './style';
import SVGgift from '../../atoms/SVGIcons/SVGgift';
import AnchorLink from '../../atoms/AnchorLink';
import TestInfoCard, { ITestInfoCard, TestInfoCardState } from '../../molecules/TestIntro/TestInfoCard';
import { Box } from '@xcorejs/ui';
import { Flex } from '@xcorejs/ui';
import { Christmas, GamesForTwoVisualPopup } from '../../atoms/Popups/FAQPopup/popups';
import FAQPopup from '../../atoms/Popups/FAQPopup';
import ImgSlider from '../Slider';
import BasePopup from '../../atoms/Popups/FAQPopup/BasePopup';
import SVGresultTwo from '../../atoms/SVGIcons/result/SVGresultTwo';
import popupContext from '../MainPopup/popupContext';
import { StyledHelpLink } from '../../atoms/Popups/styles';

const cards: ITestInfoCard[] = [
  {
    icon: 'vlc1.png',
    textBottom: 'vlc.infoCard.bottom.1',
    color: 'black',
  },
  {
    icon: 'vlc2.png',
    textBottom: 'vlc.infoCard.bottom.2',
    color: 'black',
  },
  {
    icon: 'vlc3.png',
    textBottom: 'vlc.infoCard.bottom.3',
    color: 'black',
  },
  {
    icon: 'vlc4.png',
    textBottom: 'vlc.infoCard.bottom.4',
    color: 'black',
  },
];

export const HowToValuesComparison = () => {
  const close = React.useContext(popupContext);
  return (
    <BasePopup fullScreen background='blue'>
      <StyledHowToValuesComparisonPopup>
        <Grid>
          <Row>
            <Col xs={12} sm={10} smOffset={1}>
              <SVGresultTwo />

              <Heading2>
                <FormattedMessage id='vlc.popup.heading' />
              </Heading2>

              <ImgSlider
                imgUrls={[
                  '/img/vlc/results/2v.png',
                  '/img/vlc/results/3v.png',
                  '/img/vlc/results/4v.png',
                  '/img/vlc/results/5v.png',
                  '/img/vlc/results/6v.png',
                  '/img/vlc/results/7v.png',
                  '/img/vlc/results/8v.png',
                  '/img/vlc/results/9v.png',
                  '/img/vlc/results/10v.png',
                ]}
                customNextButton={
                  <Box mt='3rem'>
                    <StyledGreenStrokeLink to='#' onClick={e => e.preventDefault()}>
                      <FormattedMessage id='vlc.popup.btn' />
                    </StyledGreenStrokeLink>
                  </Box>
                }
              />

              <Box mt='2rem'>
                <StyledLinkBorder
                  to='#'
                  color='yellow'
                  onClick={e => {
                    e.preventDefault();
                    close();
                  }}
                >
                  <SectionText color='rgba(227, 210, 64, 0.8)'>
                    <FormattedMessage id='vlc.popup.exit' />
                  </SectionText>
                </StyledLinkBorder>
              </Box>
            </Col>
          </Row>
        </Grid>
      </StyledHowToValuesComparisonPopup>
    </BasePopup>
  );
};

const VisualValuesComparison: React.FC<RouteComponentProps> = ({ location }) => {
  return (
    <StyledValuesComparisonVisual inApp={location.pathname === createUrl('app.comparison')}>
      <Grid>
        <Row>
          <Col xs={12} md={6}>
            <Flex flexDirection='column' justifyContent='space-between' height='100%'>
              <div>
                <div className='visual-headings'>
                  <HeadingValuesComparison />
                  <div className='head-info'>
                    <FormattedMessage id='vlc.visual.subheading2' />
                  </div>

                  <div className='head-info2'>
                    <FormattedHTMLMessage id='vlc.visual.subheading' />
                  </div>
                </div>

                {/*
                <FAQPopup popup={Package}>
                  <img className='osoby' src='/img/vlc/krab_et.png' srcSet='/img/vlc/krab_et.png' alt='' />
                </FAQPopup>
                */}
              </div>

              <Box className='u--desktop'>
                <StyledFakeLinkBorder color='yellow' withoutAfterBorder={true}>
                  <FAQPopup popup={HowToValuesComparison}>
                    <Flex alignItems='center'>
                      <StyledHelpLink size={2} as='span' />
                      <Box as='span' ml='1.5rem'>
                        <FormattedMessage id='vlc.visual.link.1' />
                      </Box>
                    </Flex>
                  </FAQPopup>
                </StyledFakeLinkBorder>
              </Box>
            </Flex>
          </Col>

          <Col xs={12} md={6}>
            <Flex flexDirection='column' justifyContent='space-between' height='100%'>
              <div>
                <Flex className='custom-order'>
                  <div>
                    <Heading3 style={{ color: '#00E58D', marginTop: '1.5rem' }}>
                      <FormattedHTMLMessage id='vlc.visual.box.heading' />
                    </Heading3>

                    <Box mt='3rem' maxWidth='42rem' mx='auto' className='cards'>
                      <FAQPopup popup={GamesForTwoVisualPopup}>
                        <TestInfoCard state={TestInfoCardState.def} cards={cards} />
                      </FAQPopup>
                    </Box>
                  </div>

                  {/*
                  <BtnGroup>
                    <FAQPopup popup={Voucher}>
                      <PrimaryButton style={{ whiteSpace: 'nowrap' }}>
                        <FormattedHTMLMessage id='vlc.visual.btn' />
                      </PrimaryButton>

                      <SectionText color='rgba(244, 244, 246, 0.5)'>
                        <FormattedHTMLMessage id='vlc.visual.price1' />*
                      </SectionText>

                      <SectionText
                        color='rgba(244, 244, 246, 0.5)'
                        style={{ fontSize: '1.6rem', lineHeight: '1.8rem', marginTop: '0' }}
                      >
                        <FormattedHTMLMessage id='vlc.visual.price1.starting' />
                      </SectionText>
                    </FAQPopup>

                    <FAQPopup popup={Package}>
                      <GiftButton>
                        <SVGgift />
                        <FormattedHTMLMessage id={'vlc.visual.btnGift'} />
                      </GiftButton>

                      <SectionText color='rgba(244, 244, 246, 0.5)'>
                        <FormattedHTMLMessage id='vlc.visual.price2' />*
                      </SectionText>
                    </FAQPopup>
                  </BtnGroup>
                  */}

                  <Box className='u--mobile' mt='2rem' mb='4rem'>
                    <StyledFakeLinkBorder color='blue' withoutAfterBorder={true}>
                      <AnchorLink href='#variants' duration={500}>
                        <FormattedMessage id='vlc.visual.link.2' />
                      </AnchorLink>
                    </StyledFakeLinkBorder>
                  </Box>
                </Flex>
              </div>

              <Box className='u--mobile' mt='2rem' mb='4rem'>
                <StyledLinkBorder to='#' color='yellow' withoutAfterBorder={true}>
                  <FAQPopup popup={HowToValuesComparison}>
                    <span>
                      <FormattedMessage id='vlc.visual.link.1' />
                    </span>
                  </FAQPopup>
                </StyledLinkBorder>
              </Box>

              <Box className='u--desktop'>
                <StyledFakeLinkBorder color='blue' withoutAfterBorder={true}>
                  <AnchorLink href='#variants' duration={500}>
                    <FormattedMessage id='vlc.visual.link.2' />
                  </AnchorLink>
                </StyledFakeLinkBorder>
              </Box>
            </Flex>
          </Col>
        </Row>
      </Grid>
      {/*<FAQPopup showOnLoad={"Christmas"} popup={Christmas} delay={7000}></FAQPopup>*/}
    </StyledValuesComparisonVisual>
  );
};

export default withRouter(VisualValuesComparison);
