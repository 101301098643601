import { Box, Img } from '@xcorejs/ui';
import LoadingSpinnerLocal from 'components/atoms/Animations/LoadingSpinnerLocal';
import { useUser } from 'components/atoms/hooks/useUser';
import { IStatistic } from 'components/molecules/StatisticsSlider/data';
import CollectingData from 'components/pages/App/Statistics/components/CollectingData';
import Ladders from 'components/pages/App/Statistics/components/Ladders';
import StatisticsModal, { StatisticsModalProps } from 'components/pages/App/Statistics/components/StatisticModal';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useFetchStatGroupMatchProfile } from 'utils/useQuery/queryFetchHelpers';
import { ModalContentStyle, StatisticsModalStyle } from '../style';

const GenderModal: FC<StatisticsModalProps> = () => {
  const user = useUser();
  const statisticsProfileQuery = useFetchStatGroupMatchProfile();

  if (statisticsProfileQuery.isLoading)
    return <LoadingSpinnerLocal />;

  const statistics = statisticsProfileQuery.data as IStatistic[];

  return (
    <StatisticsModal
      {...StatisticsModalStyle({ minHeightInput:'65.5rem', minMobileHeightInput:'64.5rem', colorSchemeInput:'gray' })}
      content={
        [
          {
            heading: <FormattedMessage id='statistics.site.other.result4.head1' />,
            tag: <FormattedMessage id='statistics.gender.content1.tag' />,
            _tag: {
              bg: 'rgba(72, 73, 91, 1)'
            },
            text: <FormattedMessage id='statistics.gender.content1.text' />,
            box1: <Img src='//placehold.it/180x273' alt='' />,
            text2: <FormattedMessage id='statistics.gender.content1.text2' />,
            ...ModalContentStyle
          },
          {
            heading: <FormattedMessage id='statistics.site.other.result4.head1' />,
            tag: <FormattedMessage id='statistics.gender.content2.tag' />,
            _tag: {
              bg: 'rgba(36, 123, 203, 1)'
            },
            text: <FormattedMessage id='statistics.gender.content2.text' />,
            circle: {
              name: statistics.filter(statistic => statistic.code === 'M-ALL')[0].title,
              circleSize: 'large',
              colorScheme: 'blue',
              img: statistics.filter(statistic => statistic.code === 'M-ALL')[0].image
            },
            text2: <FormattedMessage id='statistics.gender.content2.text2' />,
            box2: (
              <Ladders values={statistics.filter(statistic => statistic.code === 'M-ALL')[0].topTenValueResults} user={user} />
            ),
            ...ModalContentStyle
          },
          {
            heading: <FormattedMessage id='statistics.site.other.result4.head1' />,
            tag: <FormattedMessage id='statistics.gender.content3.tag' />,
            _tag: {
              bg: 'rgba(193, 66, 167, 1)'
            },
            text: <FormattedMessage id='statistics.gender.content3.text' />,
            circle: {
              name: statistics.filter(statistic => statistic.code === 'F-ALL')[0].title,
              circleSize: 'large',
              colorScheme: 'violet',
              img: statistics.filter(statistic => statistic.code === 'F-ALL')[0].image
            },
            text2: <FormattedMessage id='statistics.gender.content3.text2' />,
            box2: (
              <Ladders values={statistics.filter(statistic => statistic.code === 'F-ALL')[0].topTenValueResults} user={user} />
            ),
            ...ModalContentStyle
          },
          {
            heading: <FormattedMessage id='statistics.site.other.result4.head1' />,
            tag: <FormattedMessage id='statistics.gender.content4.tag' />,
            _tag: {
              bg: 'rgba(36, 123, 203, 1)'
            },
            text: <FormattedMessage id='statistics.gender.content4.text' />,
            circle: {
              name: 'Non-binary',
              circleSize: 'large',
              colorScheme: 'blue'
            },
            text2: <FormattedMessage id='statistics.gender.content4.text2' />,
            box2: (<Box marginTop='3rem'><CollectingData /></Box>),
            ...ModalContentStyle
          }
        ]
      }
    />
  );
};

export default GenderModal;
