import React, { FC } from 'react';
import { Flex, Typography, Icon, Heading3 } from '@xcorejs/ui';
import SVGDataShrooms from 'components/atoms/SVGIcons/SVGdataShrooms';
import { OliLight } from 'components/theme/fonts';
import { FormattedMessage } from 'react-intl';

const CollectingData: FC = () => {
  return (
    <Flex
      background='rgba(11,12,28,0.2)'
      width='90%'
      // height='16.8rem'
      paddingY='4rem'
      paddingX='6rem'
      alignItems='center'
      justifyContent='center'
      flexDirection='column'
      // mt='3rem'
      mx='auto'
    >
      <Icon svg={<SVGDataShrooms /> } />
      
      <Heading3 mt='2rem'>
        <FormattedMessage id='statistics.collectingData.title' />
      </Heading3>
      <Typography
        fontSize='1.3rem'
        lineHeight='1.8rem'
        // color='rgba(161, 210, 255, 0.8)'
        fontFamily={OliLight}
        maxWidth='21rem'
        mt='1rem'
        mx='auto'
      >
        <FormattedMessage id='statistics.collectingData.text' />
      </Typography>
    </Flex>
  );
};

export default CollectingData;
