import { jsonFetch } from '../../../utils/fetch';
import { DATING, DATING_PHOTO, REGISTRATION } from 'config/apiRoutes';
import { IRegisterMeeticiaUserVm, IRegisterUserVm } from './component';

export interface IRegisterData extends IRegisterUserVm {
  registrationLocale: string;
}

export const register = (data: IRegisterData) =>
  jsonFetch(REGISTRATION, {
    method: 'POST',
    body: JSON.stringify(data),
  });

export const registerDating = (data: IRegisterMeeticiaUserVm) =>
  jsonFetch(DATING, {
    method: 'POST',
    body: JSON.stringify(data),
  });

export const registerDatingPhoto = (data: any) =>
  jsonFetch(DATING_PHOTO, {
    method: 'POST',
    body: JSON.stringify(data),
  });


