import { TEST_CREATE_FROM_TEMPLATE } from 'config/apiRoutes';
import { jsonFetch } from 'utils/fetch';
import { ITestConfig } from '../Result/PartnerTestResult/data';
import { IValueResult, IValueTestResultDetail as IValueTestResultDetail } from '../../../../config/api/valueTestResult';

export interface ITest {
  id: string;
  name: string;
  testVersionId: string;
  theme: string;
  allowCustomConfig: boolean;
  allowSelectValueTestResult: boolean;
  allowSelectSurveys: boolean;
  minSurveyCount: number;
  maxSurveyCount: number;
  surveys: ITestSurvey[];
  valueTestResults: ITestResultListItem[];
  free: boolean;
}

export interface ITestSurvey {
  id: string;
  name: string;
  text: string;
  image: string;
  questions: Array<{
    id: string;
    type: string;
    layout: string;
    required: boolean;
    priority: number;
    data: {};
  }>;
}

export interface ITestResultListItem {
  id: string;
  name: string;
  eticonName: string;
  eticonAvatar: string;
}

export interface IMyLifeValuesResult {
  id: string;
  yearFrom: number;
  yearTo: number;
  doneAt: number;
  lifeValues: Array<IValueResult>;
}

export interface IJournal {
  myLifeValues: IMyLifeValuesResult | null;
  mains: Array<ITestResultPreviewResponse>;
  others: Array<ITestResultPreviewResponse>;
  trees: Array<ITreeTestResultPreviewResponse>;
}

export enum TREE_TEST_STATE {
  waitingForPayment,
  waitingForSetup,
  waitingForOwnerStart,
  waitingForOwner,
  waitingForInvited,
  waitingForResult,
  done,
}

export interface ITreeTestResultPreviewResponse {
  testConfigId: string | null;
  testResultId: string | null;
  magicTokenId: string | null;
  orderNumber: number | null;
  createdAt: number;
  canISendInvitation: boolean;
  amIOwner: boolean;
  secondNick: string | null;
  state: TREE_TEST_STATE;
}

export interface ITestResultPreviewResponse {
  id: string;
  testConfigId: string;
  valueTestResultId: string | null;
  name: string;
  eticonName: string | null;
  eticonIcon: string | null;
  valueTestNote: string | null;
  doneAt: number;
  semaphoreVisible: boolean;
}

export interface ITestResultResponse {
  id: string;
  testConfig: ITestConfig;
  valueTestResult: IValueTestResultDetail;
}

export const ONE_ROUND_TEST_ID = 'fa385f22-7954-4029-8e18-06dc3b92f400';
export const TREE_TEST_ID = 'f2ef989c-06c2-4bd6-a803-e405658058cb';

export const startValueTest = (testTemplateId: string, note: string) =>
  jsonFetch<ITestResultPreviewResponse>(TEST_CREATE_FROM_TEMPLATE, {
    method: 'POST',
    body: JSON.stringify({ note, testTemplateId })
  });
