import { FEEDBACK } from 'config/apiRoutes';
import { jsonFetch } from 'utils/fetch';

export const sendFeedback = (type: string | undefined = undefined) => (text: string) =>
  jsonFetch(FEEDBACK, {
    method: 'POST',
    body: JSON.stringify({
      text,
      type,
    }),
  });
