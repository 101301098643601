import { IStringMap } from '../../../utils';
import { rescource, IRescource, createRescourceActions } from '../../../utils/rescource';
import { fetchTask, normalize } from '../../../utils/helpers';
import { createReducer } from 'redux-act';
import { IValueResult } from 'config/api/valueTestResult';
import { STATISTICS_LIFE_VALUES } from 'config/apiRoutes';

export const statisticsInitState: IRescource<IStringMap<IValueResult>> = rescource<IStringMap<IValueResult>>(
  'init',
  null,
);

const actions = createRescourceActions('statistics');

export const fetchStatistics = () =>
  fetchTask<IValueResult[]>(STATISTICS_LIFE_VALUES, actions.fetching, actions.success, actions.fail);

export const statisticsReducer = createReducer<IRescource<IStringMap<IValueResult>>>(
  {
    [actions.fetching.getType()]: state => ({
      ...state,
      ...rescource('fetching', null),
    }),
    [actions.success.getType()]: (state, payload: IValueResult[]) => ({
      ...state,
      ...rescource(
        'success',
        normalize(payload, itm => itm.valueId),
      ),
    }),
    [actions.fail.getType()]: state => ({
      ...state,
      ...rescource('fail', null),
    }),
  },
  statisticsInitState,
);
