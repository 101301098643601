import * as React from 'react';
import { createUrl } from '../../../pages/routes';

import { PrimaryButtonLink, SecondaryButtonLink } from '../../../atoms/Buttons';
import wrapIntl, { IWrapIntl } from '../../../atoms/wrapIntl';

import { StyledMenuUser } from './styles';

const MenuLoginUnwraped: React.FC<IWrapIntl> = ({ formatMsg }) => (
  <StyledMenuUser className='login'>
    <SecondaryButtonLink className={'login-btn'} to={createUrl('login')}>
      {formatMsg('menu.login')}
    </SecondaryButtonLink>

    <PrimaryButtonLink to={createUrl('register')}>{formatMsg('registration')}</PrimaryButtonLink>
  </StyledMenuUser>
);

export const MenuLogin = wrapIntl<{}>(MenuLoginUnwraped);
