import React, { FC, Fragment, useState } from 'react';
import { Box, Button, Container, Flex, Heading2, Icon, Img, Link, Tag, Typography } from '@xcorejs/ui';
import { PlusTheme } from 'components/theme/colors';
import { PremiumButton } from 'components/components/Button';
import SVGstar from 'components/atoms/SVGIcons/SVGstar';
import styled from 'styled-components';
import { getShopRedirectLink, ProductType } from '../data';
import { PlusTag } from 'components/atoms/Logo';

interface BuyProps {
  v: ProductType;
}

const data: Record<ProductType, any> = {
  [ProductType.EticiaPlus1Year]: {
    type: 'plus',
    heading: 'Eticia Plus na <span style="color: #ffde71">1 rok za 121,- Kč</span>',
    text: 'Nákup nebo prodloužení tvého členství Eticia Plus.',
    icon: '',
    reward: '1x speciální hodnotový žebříček dle vlastního výběru a plný přístup do Eticia',
    price: 121
  },
  [ProductType.EticiaPlus3Years]: {
    type: 'plus',
    heading: 'Eticia Plus na <span style="color: #ffde71">3 roky za 249,- Kč</span>',
    text: 'Nákup nebo prodloužení tvého členství Eticia Plus.',
    icon: '',
    reward: '3x speciální hodnotový žebříček dle vlastního výběru a plný přístup do Eticia',
    price: 249
  },
  [ProductType.EticiaPlusForever]: {
    type: 'plus',
    heading: 'Členství Eticia Plus <span style="color: #ffde71">Navždy za 777,- Kč</span>',
    text: 'Užij si celoživotní členství v Eticia.',
    reward: '3x speciální hodnotový žebříček dle vlastního výběru a plný přístup do Eticia<br/>' +
      '1x Strom společných hodnot',
    price: 777
  },
  [ProductType.EticiaPlus1YearVoucher]: {
    type: 'voucher',
    heading: 'Poukaz Eticia Plus na <span style="color: #ffde71">1 rok za 121,- Kč</span>',
    text: 'Obsahuje pdf poukaz s aktivačním kódem, který ti zašleme na e-mailovou adresu,  uvedenou v objednávce. Můžeš ho věnovat jako dárek druhé osobě, nebo jej využít k prodloužení tvého členství Eticia Plus. Lze jej vytisknout, nebo jednoduše přeposlat e-mailem.<br/> <strong>Poukaz zahrnuje:</strong> Eticia Plus na 1 rok + 1x speciální žebříček dle vlastního výběru',
    reward: '',
    price: 121
  },
  [ProductType.EticiaPlus3YearsVoucher]: {
    type: 'voucher',
    heading: 'Poukaz Eticia Plus na <span style="color: #ffde71">3 roky za 249,- Kč</span>',
    text: 'Obsahuje pdf poukaz s aktivačním kódem, který ti zašleme na e-mailovou adresu,  uvedenou v objednávce. Můžeš ho věnovat jako dárek druhé osobě, nebo jej využít k prodloužení tvého členství Eticia Plus. Lze jej vytisknout, nebo jednoduše přeposlat e-mailem. <br/><strong>Poukaz zahrnuje:</strong> Eticia Plus na 3 roky + 3x speciální žebříček dle vlastního výběru.',
    reward: '',
    price: 249
  },
  [ProductType.EticiaPlusForeverVoucher]: {
    type: 'voucher',
    heading: 'Poukaz Eticia Plus <span style="color: #ffde71">Navždy za 777,- Kč</span>',
    text: 'Obsahuje pdf poukaz s aktivačním kódem, který ti zašleme na e-mailovou adresu,  uvedenou v objednávce. Můžeš ho věnovat jako dárek druhé osobě, nebo jej využít k prodloužení tvého členství Eticia Plus. Lze jej vytisknout, nebo jednoduše přeposlat e-mailem. <br/><strong>Poukaz zahrnuje:</strong> aktivaci Eticia Plus navždy + 6x speciální žebříček dle vlastního výběru + 1x Strom společných hodnot pro dvě osoby.',
    reward: '',
    price: 777
  },
  [ProductType.ThreeLadders]: {
    type: 'ladders',
    heading: '+3 speciální žebříčky dle tvého výběru <span style="color: #ffde71">za 121,- Kč</span>',
    text: 'Nákupem přidáš do tvého účtu tři hodnotové žebříčky navíc.',
    reward: '',
    price: 121
  },
  [ProductType.TreeVoucher]: {
    type: 'tree',
    heading: 'Strom společných hodnot<span style="color: #ffde71"> za 249,- Kč</span>',
    text: 'Pozvěte druhého, užijte si Eticia společně a nechte své hodnoty vyrůstat :)',
    reward: '',
    price: 249
  }
};

const Buy: FC<BuyProps> = ({ v }) => {
  const [quantity, setQuantity] = useState(1);
  const [agreement, setAgreement] = useState(false);

  return (
    <Container>
      <Flex
        flexDirection='column'
        justifyContent='center'
        maxWidth='85rem'
        mx='auto'
        py='6rem'
      >
        {v === ProductType.TreeVoucher && (
          <Img src='/img/shop/value-tree.svg' height='6.4rem' alt='' />
        )}

        {v === ProductType.ThreeLadders && (
          <Img
            src='/img/shop/3-zebricky.svg'
            height='6.4rem'
            alt=''
          />
        )}

        {(data[v].type === 'plus' || data[v].type === 'voucher') && (
          <Flex justifyContent='center'>
            <PremiumButton
              bg={
                v === ProductType.EticiaPlus1Year || v === ProductType.EticiaPlus1YearVoucher ?
                  'radial-gradient(circle, rgba(190, 76, 124, 1) 0%, rgba(123, 2, 101, 1) 100%)'
                  : v === ProductType.EticiaPlus3Years || v === ProductType.EticiaPlus3YearsVoucher ?
                    'radial-gradient(circle, rgba(29, 43, 124, 1) 0%, rgba(11, 12, 28, 1) 100%)'
                    :
                    'radial-gradient(circle, rgba(214, 169, 29, 1) 0%, rgba(214, 114, 24, 1) 100%)'
              }
            >
              <Flex alignItems='center' mr='1.5rem'>
                <Icon svg={<SVGstar />} />
              </Flex>

              {v === ProductType.EticiaPlus1Year || v === ProductType.EticiaPlus1YearVoucher ?
                'Eticia Plus na 1 rok'
                : v === ProductType.EticiaPlus3Years || v === ProductType.EticiaPlus3YearsVoucher ?
                  'Eticia Plus na 3 roky'
                  :
                  'Eticia Plus Navždy'
              }
            </PremiumButton>
          </Flex>
        )}

        <Heading2
          as='h1'
          fontWeight={400}
          textAlign='center'
          mt='3rem'
        >
          <div dangerouslySetInnerHTML={{ __html: data[v].heading }} />
        </Heading2>

        {data[v].type === 'ladders' && (
          <Typography
            display='flex'
            justifyContent='center'
            alignItems='center'
            flexDirection={{ _: 'column', sm: 'row' }}
            mt='1rem'
            color='rgba(255, 255, 255, 0.8)'
          >
            Lze zakoupit pouze pro uživatele s členstvím

            <PlusTag ml='1rem'>
              Eticia Plus
            </PlusTag>
          </Typography>
        )}

        <Typography mt='1rem' lineHeight='2rem'>
          <div dangerouslySetInnerHTML={{ __html: data[v].text }} />
        </Typography>

        {data[v].type === 'voucher' && (
          <Flex justifyContent='center' mt='2rem'>
            <Link v='underline' color='yellow' borderBottom='1px solid'>
              Náhled poukazu
            </Link>
          </Flex>
        )}

        {data[v].reward.length > 0 && (
          <>
            <Typography fontWeight={700} color='2rem' lineHeight='2rem' mt='3rem'>
              Co získáš?
            </Typography>

            <Typography mt='1rem'>
              <div dangerouslySetInnerHTML={{ __html: data[v].reward }} />
            </Typography>
          </>
        )}

        {/* Pocet kusu nepodporujeme! */}
        {false && data[v].type === 'voucher' && (
          <Flex alignItems='center' mt='6rem' justifyContent='center'>
            <Typography
              mr='2rem'
            >
              Počet ks
            </Typography>

            <TextInputStyle
              defaultValue={quantity}
              value={quantity}
              onChange={(e) => !isNaN(Number(e.target.value)) &&
                Number(e.target.value) > 0 && setQuantity(Number(e.target.value))
              }
            />

            <Button
              ml='2rem'
              fontSize='2.2rem'
              lineHeight='2.8rem'
              padding='0'
              bg='none'
              border='2px solid'
              borderColor='yellow'
              width='3rem'
              height='3rem'
              minHeight='3rem'
              color='#fff'
              flex='0 0 auto'
              onClick={() => setQuantity(quantity + 1)}
            >
              +
            </Button>

            <Button
              ml='1rem'
              fontSize='2.2rem'
              lineHeight='2.8rem'
              padding='0'
              bg='none'
              border='2px solid'
              borderColor='yellow'
              width='3rem'
              height='3rem'
              minHeight='3rem'
              color='#fff'
              flex='0 0 auto'
              onClick={quantity > 1 ? () => setQuantity(quantity - 1) : undefined}
              disabled={quantity === 0}
            >
              -
            </Button>
          </Flex>
        )}

        <Flex as='label' mt='6rem' display='flex' alignItems='center' justifyContent='center'>
          <Box position='relative'>
            <CheckboxInputStyle type='checkbox' onChange={(e) => setAgreement(e.target.checked)} />
            <CheckedIconStyle className='checkbox-icon-checked'  />
          </Box>

          <Typography
            ml='1.2rem'
          >
            Souhlasím{' '}
            <Link
              v='underline'
              color='rgba(227, 210, 64, 1)'
              borderColor='rgba(227, 210, 64, 1)'
              fontSize='inherit'
              lineHeight='inherit'
            >
              s podmínkami</Link>
          </Typography>
        </Flex>

        <Flex display='flex' alignItems='center' justifyContent='center' mt='3rem'>
          <Button
            s='lg'
            fontSize={{ _: '2rem', sm: '2.2rem' }}
            lineHeight={{ _: '2.2rem', sm: '2.8rem' }}
            letterSpacing='0.5px'
            px='5rem'
            py='1.9rem'
            height='6rem'
            disabled={!agreement}
            _disabled={{
              opacity: 0.5
            }}
            onClick={async () => {
              window.location.href = await getShopRedirectLink(v);
            }}
          >
            <Fragment>
              Objednat za {data[v].price * quantity},- Kč
            </Fragment>
          </Button>
        </Flex>

        <Typography mt='3rem'>
          <strong>Máš slevový kód?</strong>{' '}Zadej ho v dalším kroku.
        </Typography>

        <Img src='/img/secured-payments.png' alt='' mx='auto' mt='5rem' width='16.4rem' />
      </Flex>
    </Container>
  );
};

const TextInputStyle = styled.input`
  width: 6rem;
  background: transparent;
  border: 0;
  border-bottom: 3px solid rgba(0, 153, 102, 0.8);
  font-size: 2.4rem;
  letter-spacing: 0;
  line-height: 3.1rem;
  text-align: center;
  color: #fff;
  font-weight: 800;
`;

export const CheckboxInputStyle = styled.input`
  height: 2.4rem;
  width: 2.4rem;
  border: 2px solid rgba(255, 255, 255, 0.49);
  border-radius: 0.25rem;
  background-color: rgba(255, 255, 255, 0.2);
  appearance: none;
  
  & ~ .checkbox-icon-checked {
    position: absolute;
    left: 0.6rem;
    top: 0.6rem;
    display: none;
  }

  &:checked {
    border: 2px solid rgba(255, 255, 255, 1);
    
    & ~ .checkbox-icon-checked {
      display: block;
    }
  }
`;

export const CheckedIconStyle = styled.div`
  width: 1.2rem;
  height: 1.2rem;
  background: #fff;
  border-radius: 0.25rem;
  display: block
`;

export default Buy;
