import { ITestResultPreviewResponse } from 'components/pages/App/TestIntro/data';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { CompareConfigTestResultPreview, CompareConfigMatrix } from 'components/components/Compare/data';
import { COMPARE_TESTS, COMPARE_MATRIX } from 'config/apiRoutes';
import { jsonFetch } from 'utils/fetch';
import { Option } from 'react-select';

export const useSelectOption = (result: ITestResultPreviewResponse) => {
  const [selectOptions, setSelectOptions] = useState<CompareConfigTestResultPreview[]>([]);
  
  const compareTest = async (testResultId: string) => {
    const response = await fetch(`${COMPARE_TESTS(testResultId)}`,
      {
        method: 'GET',
      }).then(r => r.json());

    if (!response.ok) {
      console.log('error');
    }
    console.log('response', response);
    let responseWithoutCurrentTest = response.filter(function(result: CompareConfigTestResultPreview) {
      return result.id !== testResultId;
    });
    console.log('responseWithoutCurrentTest', responseWithoutCurrentTest);
    setSelectOptions(responseWithoutCurrentTest);
  };

  useEffect(() => {
    compareTest(result.id);
  }, [result.id]);

  return selectOptions;
};
export const useMatrixData = (result: ITestResultPreviewResponse, active: Option | undefined) => {
  const [matrix, setMatrix] = useState<CompareConfigMatrix>();
  
  const fetchCompareMatrix = async () => await jsonFetch<CompareConfigMatrix[]>(COMPARE_MATRIX);
  
  const compareMatrix = useCallback(async () => 
    await fetchCompareMatrix()
      .then(matrix => {
        const data = matrix.filter(test => result.name.includes(test.name));
        if (data.length) {
          setMatrix(data[0]);
        }
      }),
  [result.name]);

  useEffect(() => {
    compareMatrix();
  }, [compareMatrix]);

  const [compareNames, setCompareNames] = useState<{main: string; compared: string}>({
    main: result.name.replace(/\s\d+/, ''),
    compared: '',
  });

  useEffect(() => {
    if (active && active.label) {
      // eslint-disable-next-line no-extra-parens
      const compared = (active.label as any).props.children[0].props.children;
      //* trochu trial and error, než se podařilo získat z option jméno
      setCompareNames(prev => ({ ...prev, compared }));
    }
  }, [active]);

  const description = useMemo(() => {
    if (compareNames.compared.trim().length && matrix) {
      const target = matrix.targets.find(test => compareNames.compared.includes(test.templateB.name));
      return target ? target.mainDescription : '';
    }
    return '';
  }, [matrix, compareNames.compared]);

  return { description, compareNames };
};