import * as React from 'react';
import { StyledVimeoPopup } from './styles';
import popupContext from 'components/organisms/MainPopup/popupContext';
import SVGclosegray from '../../SVGIcons/SVGclosegray';

interface IVimeoPopup {
  id: string;
  muted?: boolean;
}

const getUrl = (id: string, muted = true) =>
  `https://player.vimeo.com/video/${id}?` +
  `muted=${muted ? 1 : 0}&`+
  'autoplay=1&' +
  'loop=0&' +
  'color=009966&' +
  'title=0&' +
  'byline=0&' +
  'portrait=0&' +
  'controls=1';

const VimeoPopup: React.FC<IVimeoPopup> = ({ id, muted }) => {
  const close = React.useContext(popupContext);

  return (
    <StyledVimeoPopup>
      <div className='content'>
        <span className='close' onClick={() => close()}>
          <SVGclosegray />
        </span>

        <div>
          <iframe src={getUrl(id, muted)} allowFullScreen />
          <script src='https://player.vimeo.com/api/player.js' />
        </div>
      </div>
    </StyledVimeoPopup>
  );
};

export default VimeoPopup;
