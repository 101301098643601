import styled from 'styled-components';
import {
  darkBaseHEX,
  white,
  selectColor,
  primaryGreen,
  textGreenRGB,
  primaryBlue,
  darkenBlue,
  yellow,
  whiteFade,
  darkenViolet,
  violet,
  grayRGB,
} from '../../../theme/colors';
import { bold } from '../../../theme/fonts';
import { mediaSmDefault, mediaSmMax } from '../../../theme/grid';
import { SecondaryButton } from '../../Buttons';
import { GraphLegendDescription } from '../../../organisms/PartnerTest/Result/styles';
import { ScrollbarStyles } from '../../../templates/General/styles';
import { Heading2 } from '../../Headings';
import { StyledLinkBorder, StyledSecondaryButton } from '../../Buttons/styles';
import { CustomText } from '../../Text';
import { UL1 } from '../../../organisms/VisualValuesComparison/style';
import { StyledTextArea } from '../../FormItems/styles';
import { StyledQuestObjective } from '../../../organisms/QuestLog/styles';

export const StyledHelpPopup = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  color: ${darkBaseHEX};
  background: #fff;
  border-radius: 0.4rem;
  white-space: normal;
  overflow: auto;

  .content {
    background-color: ${white};
    padding: 3rem 3rem 3rem;
    max-height: 100vh;

    ${mediaSmDefault} {
      padding: 6rem 10rem 3rem;
      max-height: 90vh;
    }

    ${mediaSmMax} {
      overflow-y: scroll;
    }
  }

  ${mediaSmMax} {
    max-width: 100vw;
    max-height: 100vh;

    .close {
      right: 0.5rem;
      top: 0.5rem;
    }
  }

  &.welcome {
    background: radial-gradient(circle at top, ${primaryBlue} 0%, ${darkenBlue} 100%);

    ${Heading2} {
      color: ${yellow};
    }

    *::selection {
      color: ${primaryBlue};
      background: #fff;
    }

    .content {
      padding: 4rem 4rem 0 3rem;
      max-height: 90vh;
      background: transparent;

      ${mediaSmDefault} {
        padding-left: 10rem;
        padding-right: 10rem;
      }
    }

    .close {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 4rem;
      height: 4rem;
      top: 1.5rem;
      right: 1.5rem;
      border: 2px solid ${yellow};
      border-radius: 50%;

      ${mediaSmDefault} {
        top: 3rem;
        right: 3rem;
      }
    }

    .tag {
      display: inline-block;
      margin-top: 1.5rem;
      margin-bottom: 1rem;
      padding: 0.5rem 1rem;
      border-radius: 1.5rem;
      background: #00e58d;
      color: #006644;
      font-size: 1.4rem;
      line-height: 1.5rem;
      height: 2.4rem;
      vertical-align: middle;
      position: static;
      cursor: pointer;
      transition: background 0.3s;
    }

    .beta-info {
      margin-top: 3rem;
      margin-bottom: 3rem;

      p {
        margin-top: 1rem;
        font-size: 1.4rem;
        color: ${whiteFade};
      }

      ${mediaSmDefault} {
        text-align: center;

        p {
          display: block;
          margin-top: 0;
        }
      }
    }

    ${StyledSecondaryButton} {
      color: #fff;
    }

    .heading {
      display: block;
      margin-top: 3rem;
      margin-bottom: 1rem;
      color: #fff;
      font-size: 1.8rem;
      line-height: 2.7rem;
    }

    ${CustomText} {
      color: #fff;
      font-size: 1.6rem;
      line-height: 2.4rem;
      text-align: center;

      &.ul-heading {
        margin-top: 2rem;
        color: ${yellow};
        font-weight: ${bold};
      }
    }

    ${UL1} {
      margin-top: 2rem;
      margin-bottom: 2rem;
      max-width: 58rem;

      li {
        justify-content: center;

        &:before {
          margin-right: 1.5rem;
        }

        span {
          color: #fff;
          font-size: 1.6rem;
          line-height: 2.4rem;
          text-align: center;
        }
      }
    }
  }

  &.value-center {
    background: radial-gradient(circle at top, ${violet} 0%, ${darkenViolet} 100%);
    width: 96rem;

    .content {
      background: transparent;

      ${mediaSmDefault} {
        padding-left: 6rem;
        padding-right: 6rem;
      }
    }

    ${StyledSecondaryButton} {
      color: #fff;
    }

    *::selection {
      color: ${violet};
      background: #fff;
    }

    .heading {
      display: block;
      margin-bottom: 2rem;
      color: #fff;
      font-size: 1.8rem;
      line-height: 2.7rem;

      small {
        color: rgba(${grayRGB}, 0.5);
        font-size: 1.6rem;
        line-height: 2.4rem;
      }
    }

    .close {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 4rem;
      height: 4rem;
      top: 1.5rem;
      right: 1.5rem;
      border: 2px solid ${yellow};
      border-radius: 50%;

      ${mediaSmDefault} {
        top: 3rem;
        right: 3rem;
      }
    }

    ${CustomText} {
      color: #fff;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 2rem;
      max-width: 65rem;
      font-size: 1.6rem;
      line-height: 2.4rem;
      text-align: center;

      &.ul-heading {
        margin-top: 2rem;
        color: ${yellow};
        font-weight: ${bold};
      }
    }

    ${StyledQuestObjective} {
      text-align: left;
      align-items: flex-start;

      .ico-wrap {
        display: block;

        svg {
          path {
            fill: #fff;
          }
        }
      }

      ${mediaSmDefault} {
        width: 50%;
        margin-left: 0;
      }

      ${StyledLinkBorder} {
        font-size: 1.4rem;
        line-height: 1.7rem;
      }
    }

    .next {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      ${mediaSmDefault} {
        flex-direction: row;
      }

      &.last {
        margin-bottom: 1rem;
      }
    }
  }

  &.graph-legend {
    .react-reveal {
      opacity: 1 !important;
    }
  }

  ${ScrollbarStyles};

  .popup-title,
  h2,
  h3 {
    color: ${props => props.theme.primary};
    font-weight: ${bold};
  }

  .popup-title {
    font-size: 3.2rem;
    line-height: 4.1rem;
  }

  .popup-text {
    margin-top: 3rem;
    font-size: 1.8rem;
    line-height: 2.7rem;

    p:not(:first-child) {
      margin-top: 2rem;
    }

    ${StyledTextArea} {
      margin-bottom: 2.5rem;
    }
  }

  .close {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    display: inline-block;
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;

    ${mediaSmDefault} {
      right: 3rem;
      top: 3rem;
    }

    svg {
      display: block;

      .fill {
        transition: fill 0.3s;
      }
    }

    &:hover {
      .fill {
        fill: ${selectColor};
      }
    }
  }

  ${SecondaryButton} {
    color: ${props => props.theme.primary};
  }

  ${GraphLegendDescription} {
    display: block;

    & > div {
      span {
        color: ${primaryGreen};
      }

      p {
        margin-top: 0 !important;

        span {
          color: ${selectColor};
        }
      }
    }

    .symbol {
      color: ${primaryGreen};
      border: 0.2rem solid rgba(${textGreenRGB}, 0.5);
    }
  }

  .btn-wrap {
    margin: 3rem 0;

    a {
      margin-top: 3rem;

      &:first-child {
        margin-top: 0;
      }
    }

    ${mediaSmDefault} {
      margin: 3rem 1.5rem;

      a {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
      }
    }
  }
`;
