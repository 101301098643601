import styled from 'styled-components';
import { Heading1 } from '../../atoms/Headings';
import { CustomText } from '../../atoms/Text';
import { SecondaryButton } from '../../atoms/Buttons';
import {
  darkBaseHEX,
  darkBaseRGB,
  darkenBlue,
  GreenTheme,
  yellow,
  primaryGreen,
  primaryBlue, VioletTheme,
} from '../../theme/colors';
import { bold } from '../../theme/fonts';
import { IValueOrdList } from './ValueOrderedList';
import { mediaHonzaDefault, mediaLgDefault, mediaMdDefault, mediaSmDefault } from '../../theme/grid';

export const StyledValueDesc = styled.div`
  text-align: center;

  ${Heading1} {
    margin-top: 3rem;
    text-align: center;
    font-size: 2.4rem;
    line-height: 3.2rem;
  }

  .eticon-img {
    margin-top: 1rem;
    display: inline-block;
    width: 100%;
    max-width: 28rem;
    
    img {
      display: block;
    }
  }

  ${CustomText} {
    margin-top: 2rem;
  }

  .eticon-title {
    margin-top: 2rem;
  }

  ${SecondaryButton} {
    margin-top: 3rem;
  }
`;

export const StyledValueOrderedList = styled.ol<IValueOrdList>`
  padding-left: 0;
  counter-reset: li;

  ${mediaMdDefault} {
    column-count: ${props => (props.twoCols ? 2 : 1)};
  }

  li {
    position: relative;
    list-style: none;
    //padding-left: 4rem;

    &:before {
      content: counter(li) ".";
      counter-increment: li;
      position: absolute;
      left: 0;
      top: 3rem;
      font-size: 2rem;
      line-height: 2.5rem;
    }

    &:not(:first-child) {
      margin-top: 1rem;
    }

    &.my-value {
      .value-img {
        border: 3px solid ${yellow};
      }
    }
  }

  .value-img {
    display: inline-block;
    margin-right: 3rem;
    padding: 1.5rem;
    width: 8rem;
    min-width: 8rem;
    height: 8rem;
    border-radius: 50%;
    background-color: ${props => props.theme.primary};
    vertical-align: middle;
    text-align: center;

    img {
      display: inline-block;
      width: 5rem;
      vertical-align: middle;
    }
  }

  .value-name {
    display: inline-block;
    vertical-align: middle;
    font-size: 2rem;
    line-height: 2.6rem;
    font-weight: ${bold};
  }
`;

export const StyledTopXValuesOrdered = styled.div`
  padding-left: 0;
  width: 100%;

  ${mediaMdDefault} {
    display: flex;
    flex-wrap: wrap;
  }

  .card {
    margin-bottom: 1rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    position: relative;
    min-height: 8rem;
    list-style: none;
    font-size: 1.6rem;
    line-height: 2.1rem;
    font-weight: 500;
    box-shadow: 0 5px 10px 0 rgba(25, 29, 44, 0.4);
    border-radius: 0.4rem;
    background: radial-gradient(circle at top, ${GreenTheme.primary} 0%, ${GreenTheme.darken} 100%);

    ${mediaSmDefault} {
      display: inline-block;
      width: calc(50% - 1rem);
    }

    span {
      vertical-align: middle;
      text-align: left;
    }

    & > div {
      padding: 1.6rem 3rem;
      display: flex;
      height: 100%;
      align-items: center;
      position: relative;
    }

    &--active {
      &:before {
        background-color: ${yellow};
        color: ${darkBaseHEX};
      }
    }

    .rotate-ico-wrapper {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      opacity: 0;
      transition: opacity 0.3s;

      img {
        position: static;
        display: block;
        transform: none;
      }
    }

    &:hover {
      .img {
        span {
          opacity: 1;
        }
      }

      .rotate-ico-wrapper {
        opacity: 1;
      }
    }

    .img {
      position: relative;
      margin-right: 2rem;

      img {
        display: block;
        max-width: 4.8rem;
      }

      span {
        position: absolute;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        top: 50%;
        left: 50%;
        background: radial-gradient(circle at top, rgba(0, 153, 102, 0.5) 0%, rgba(0, 131, 88, 0.5) 100%);
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
        opacity: 0;
        transform: translateX(-50%) translateY(-50%);
        transition: opacity 0.3s;
      }
    }

    .symbols {
      position: absolute;
      transform: rotate(270deg);
      left: 0;

      span {
        display: block;
        position: relative;
        margin-bottom: 0.4rem;
        width: 3rem;
        height: 0.3rem;

        &.yellow {
          background-color: ${yellow};
        }

        &.white {
          background-color: #fff;
        }

        &.blue {
          background-color: ${darkenBlue};
        }

        &.purple {
          background-color: ${VioletTheme.darken};
        }
      }

      .tooltip-hover {
        position: absolute;
        margin-top: 1.5rem;
        padding: 0.5rem 1.5rem;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        font-size: 1.6rem;
        line-height: 2rem;
        background: rgba(${darkBaseRGB}, 0.6);
        opacity: 0;
        border-radius: 4px;
        pointer-events: none;
        transition: opacity 300ms ease;

        span {
          font-weight: 400;
        }
      }
    }
  }
`;

const middlePadding = '0';
const cornerPadding = '5rem';
const cornerPaddingMobile = '3rem';

export const StyledValuesGraph = styled.div`
  text-align: center;

  .actual-graph {
    display: inline-block;
    padding: 5.5rem 3.8rem;
    position: relative;

    ${mediaSmDefault} {
      padding: 6rem 5rem;
    }

    .result {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      max-width: 65%;
      max-height: 65%;

      ${mediaHonzaDefault} {
        max-width: 55%;
        max-height: 55%;
      }

      ${mediaMdDefault} {
        max-width: 65%;
        max-height: 65%;
      }

      ${mediaLgDefault} {
        max-width: 70%;
        max-height: 70%;
      }
    }

    img {
      margin: 0 auto;
      width: 32rem;
      max-width: 100%;
    }
  }

  .letters-wrapp {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    .letter-wrapper {
      position: absolute;
      display: inline-block;
      padding: 0.7rem;
      margin-top: -0.8rem;
      text-align: center;

      &.active {
        .letter {
          background: ${yellow};
          color: #000;
        }

        &.green {
          .letter {
            background: ${primaryGreen};
            color: #fff;
          }
        }

        &.blue {
          .letter {
            background: ${primaryBlue};
            color: #fff;
          }
        }

        &.both {
          .letter {
            background: linear-gradient(to left, ${primaryBlue} 50%, ${primaryGreen} 0);
            border: 2px solid ${yellow};
            color: #fff;
          }
        }

        .type {
          color: #fff;
          font-weight: 500;
        }
      }

      &:before {
        //content: attr(data-tooltip);
        position: absolute;
        margin-top: -4rem;
        left: 50%;
        transform: translateX(-50%);
        padding: 0.5rem 1.5rem;
        text-align: left;
        font-size: 1.6rem;
        line-height: 2rem;
        background: rgba(${darkBaseRGB}, 0.6);
        opacity: 0;
        border-radius: 1.5rem;
        pointer-events: none;
        transition: opacity 300ms ease;
      }

      &:hover {
        &:before {
          opacity: 0.8;
        }
      }

      &.m {
        left: ${middlePadding};
        margin-left: -1rem;
      }

      &.i,
      &.v {
        left: ${cornerPaddingMobile};

        ${mediaSmDefault} {
          left: 3rem;
        }
      }

      &.f,
      &.c {
        left: 50%;
        transform: translateX(-50%);
      }

      &.e,
      &.s {
        right: ${cornerPaddingMobile};

        ${mediaSmDefault} {
          right: 3rem;
        }
      }

      &.r {
        right: ${middlePadding};
        margin-right: -1rem;
      }

      &.f {
        top: ${middlePadding};
      }

      &.i,
      &.e {
        top: ${cornerPaddingMobile};

        ${mediaSmDefault} {
          top: ${cornerPadding};
        }
      }

      &.m,
      &.r {
        top: 50%;
        transform: translateY(-50%);
      }

      &.v,
      &.s {
        bottom: ${cornerPaddingMobile};

        ${mediaSmDefault} {
          bottom: ${cornerPadding};
        }
      }

      &.c {
        bottom: ${middlePadding};
      }
    }

    .letter {
      width: 2.4rem;
      height: 2.4rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 1.6rem;
      font-weight: ${bold};
      border-radius: 50%;
      color: rgba(255, 255, 255, 0.55);

      ${mediaSmDefault} {
        width: 3rem;
        height: 3rem;
      }
    }

    .type {
      display: block;
      margin-top: 0.2rem;
      color: rgba(255, 255, 255, 0.55);
      font-size: 1.3rem;
      line-height: 1.6rem;
    }

    .percentage {
      display: block;
      margin-top: 0.2rem;
      color: rgba(255, 255, 255, 0.55);
      font-size: 1.3rem;
      line-height: 1.3rem;
    }
  }
`;
