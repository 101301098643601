import React, { FC } from 'react';
import { Flex } from '@xcorejs/ui';
import GiftEticiaPlus from 'components/atoms/GiftEticiaPlus/GiftEticiaPlus';

interface GiftEticiaProps {
  linkTo?: string;
  purpleBackground?: boolean;
}

const GiftEticia: FC<GiftEticiaProps> = ({ linkTo, purpleBackground }) => {
  return (
    <Flex
      as='section'
      justifyContent='center'
      alignItems='center'
      backgroundColor={purpleBackground ? '#7e76ab' : 'white'}
    >
      <GiftEticiaPlus noMargin linkTo={linkTo} lightText={purpleBackground === true} />
    </Flex>
  );
};

export default GiftEticia;