import React, { FC, Fragment } from 'react';
import { Button, Flex, Heading2, Icon, Img, Typography } from '@xcorejs/ui';
import SVGstar from 'components/atoms/SVGIcons/SVGstar';
import { PremiumButton } from 'components/components/Button';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { createUrl } from 'components/pages/routes';

interface SuccessProps {
  v: 'plus-1rok' | 'plus-3roky' | 'plus-navzdy';
}

const Success: FC<SuccessProps> = ({ v }) => {
  return (
    <Flex flexDirection='column' alignItems='center' py='8rem'>
      <PremiumButton
        bg={
          v === 'plus-1rok' ?
            'radial-gradient(circle, rgba(190, 76, 124, 1) 0%, rgba(123, 2, 101, 1) 100%)'
            : v === 'plus-3roky'?
              'radial-gradient(circle, rgba(29, 43, 124, 1) 0%, rgba(11, 12, 28, 1) 100%)'
              :
              'radial-gradient(circle, rgba(214, 169, 29, 1) 0%, rgba(214, 114, 24, 1) 100%)'
        }
      >
        <Flex alignItems='center' mr='1.5rem'>
          <Icon svg={<SVGstar />} />
        </Flex>

        {v === 'plus-1rok' ?
          'Eticia Plus na 1 rok'
          : v === 'plus-3roky' ?
            'Eticia Plus na 3 roky'
            :
            'Eticia Plus Navždy'
        }
      </PremiumButton>

      <Heading2
        as='h1'
        textAlign='center'
        mt='3rem'
      >
        <FormattedMessage id='activationSuccess.congrats' />{' '}
        {v === 'plus-1rok' ?
          'Eticia Plus na 1 rok'
          : v === 'plus-3roky' ?
            'Eticia Plus na 3 roky'
            :
            'Eticia Plus Navždy'
        }
        <br />
        <FormattedMessage id='activationSuccess.congrats2' />
      </Heading2>

      {/* <Typography mt='2rem'>
        {v === 'plus-1rok' ? (
          <Fragment>
            <FormattedHTMLMessage id='activationSuccess.end1' />{' '}<strong style={{ color: '#ffde71' }}>
              {new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toLocaleDateString()}</strong>
          </Fragment>
        ) : v === 'plus-3roky' ? (
          <Fragment>
            <FormattedHTMLMessage id='activationSuccess.end2' />{' '}<strong style={{ color: '#ffde71' }}>
              {new Date(new Date().setFullYear(new Date().getFullYear() + 3)).toLocaleDateString()}</strong>
          </Fragment>
        ) : (
          <Fragment>
            <FormattedHTMLMessage id='activationSuccess.end3' />
          </Fragment>
        )}
      </Typography> */}

      <Img
        src='/img/shop/1-zebricek.svg'
        height='6.4rem'
        mt='4rem'
        opacity={0.5}
        alt=''
      />

      <Typography fontFamily='OliMedium' color='#fff' mt='2rem'>
        <FormattedMessage id='activationSuccess.extra' />
      </Typography>

      {v === 'plus-1rok' ? (
        <Fragment>
          <Typography mt='1rem' lineHeight='2rem'>
            <FormattedMessage id='activationSuccess.extra1' />
          </Typography>
        </Fragment>
      ) : v === 'plus-3roky' ? (
        <Fragment>
          <Typography mt='1rem' lineHeight='2rem'>
            <FormattedMessage id='activationSuccess.extra2' />
          </Typography>
        </Fragment>
      ) : (
        <Fragment>
          <Typography mt='1rem' lineHeight='2rem'>
            <FormattedMessage id='activationSuccess.extra3' />
          </Typography>

          <Typography mt='1rem' lineHeight='2rem'>
            <FormattedMessage id='activationSuccess.extra4' />
          </Typography>
        </Fragment>
      )}

      <Flex justifyContent='center' mt='4rem'>
        <Link to={createUrl('app.welcomePlus')}>
          <Button
            as='span'
            s='lg'
            fontSize={{ _: '2rem', sm: '2.2rem' }}
            lineHeight={{ _: '2.2rem', sm: '2.8rem' }}
            letterSpacing='0.5px'
            px='5rem'
            py='1.9rem'
            height='6rem'
          >
            <FormattedMessage id='activationSuccess.button' />
          </Button>
        </Link>
      </Flex>

      <Typography textAlign='center' mt='4rem' color='rgba(244, 244, 246, 0.5)'>
        <FormattedMessage id='activationSuccess.thanks' />
      </Typography>
    </Flex>
  );
};

export default Success;
