import About from './component';
import { SuggestValue } from '../Feedback/SuggestValue';
import Join from './Join';
import Support from './Support';

export const aboutRoutes = {
  about: {
    path: 'route.about',
    exact: true,
    component: About,
    data: {
      layout: 'homepage',
      authUser: true,
    },
    children: {
      join: {
        path: 'route.about.join',
        exact: true,
        component: Join,
        data: {
          layout: 'homepage',
          authUser: true,
        },
      },
      support: {
        path: 'route.about.support',
        exact: true,
        component: Support,
        data: {
          authUser: true,
        },
      },
      suggest: {
        path: 'route.about.suggest-value',
        exact: true,
        component: SuggestValue,
        data: {
          authUser: true,
        },
      },
    },
  },
};
