import * as React from 'react';
import { useEffect } from 'react';
import { PopupComponent } from 'store/Popup';

import withPopups, { IWithPopups } from '../../../molecules/withRedux/withPopups';
import { useRequest } from '../../RequestContext';

interface Props {
  popup: PopupComponent;
  className?: string;
  showOnLoad?: string;
  delay?: number;
  step?: string;
  showEveryTime?: boolean;
}

const cookiesText = 'Eticia.Cookies.FAQPopup';

const FAQPopup: React.FC<Props & IWithPopups> = ({
  popups,
  popup,
  children,
  showOnLoad,
  className,
  delay,
  step,
  showEveryTime,
}) => {
  const { cookies, setCookie } = useRequest();

  useEffect(() => {
    if ((!cookies || showOnLoad === undefined || Object.keys(cookies).length === 0) && !showEveryTime) {
      if (cookies && Object.keys(cookies).length === 0) {
        setCookie('first cokie', '1', {
          expires: 365,
        });
      }
      return;
    }
    if (cookies[cookiesText + showOnLoad] !== '1' && showEveryTime) {
      if (delay) {
        setTimeout(() => {
          popups.custom(popup);
          setCookie(cookiesText + showOnLoad, '1', {
            expires: 365,
          });
        }, delay);
      } else {
        popups.custom(popup);
        setCookie(cookiesText + showOnLoad, '1', {
          expires: 365,
        });
      }
    }
  }, [cookies]);
  
  return (
    <a
      className={`main-popup-button ${className}`}
      onClick={() => {
        popups.custom(popup);
        {
          step && setCookie('faqStep', step, { expires: 10 });
        }
      }}
    >
      {children}
    </a>
  );
};

export default withPopups(FAQPopup);
