import { ComponentType } from 'react';
import { createAction, createReducer } from 'redux-act';

interface IPopupComponentProps {
  resolve: (result?: string) => unknown;
}

export type PopupComponent<TProps = {}> = ComponentType<IPopupComponentProps & TProps>;

export interface IPopupState {
  popup?: PopupComponent | undefined;
  resolve?: (result?: string) => unknown;
}

const popupInitState: IPopupState = {
  popup: undefined,
};

export const openPopup = (popup: PopupComponent) => (dispatch: any): Promise<string> => {
  return new Promise<string>(resolve => dispatch(innerOpenPopup({ popup, resolve })));
};

const innerOpenPopup = createAction('popup: open', (p: IPopupState) => p);

export const closePopup = createAction('popup: close');

export const popupReducer = createReducer(
  {
    [innerOpenPopup.getType()]: (state, popup: IPopupState | null) => ({
      ...state,
      ...popup,
    }),
    [closePopup.getType()]: state => ({
      ...state,
      popup: undefined,
      resolve: undefined,
    }),
  },
  popupInitState,
);
