import * as React from 'react';
import { createUrl } from '../../../pages/routes';
import { isAuthed, IUserState } from '../../../../store/User/index';
import withUser from '../../../molecules/withRedux/withUser';
import { withRouter } from 'react-router';
import { IWithUser } from '../../../molecules/withRedux/withUser';
import { RouteComponentProps } from 'react-router-dom';
import { useTheme, IThemeProps } from '../../../templates/General/ThemeContext';
import { HelpLink } from 'components/atoms/Popups/HelpLink';
import wrapIntl, { IWrapIntl } from 'components/atoms/wrapIntl';
import { InfoPopupButton } from '../../MainPopup/InfoPopup/InfoPopupButton';
import { usePopupIcon, PopupIconContext } from '../../MainPopup/PopupIcon';
import { useRequest } from '../../../atoms/RequestContext/index';

interface IPage {
  id: string;
}

const Page: React.FC<IPage & IWithUser & IThemeProps & RouteComponentProps<{}> & IWrapIntl> = ({
  theme,
  children,
  rescource,
  history,
  location,
  id,
  keyExists,
}) => {
  const popupInfo = usePopupIcon();
  const { isDev } = useRequest();

  React.useEffect(() => {
    if (!(!theme.original!.data || !theme.original!.data.authUser || isAuthed(rescource.user as IUserState))) {
      history.push(createUrl('login'), {
        redirect: location.pathname + location.search + location.hash,
      });
    }
  }, [theme.original, rescource.user, history, location]);

  isDev && console.log(`You are currently on ${id} page`);

  const hint = 'pageHint.' + id;

  return (
    <>
      <PopupIconContext.Provider value={popupInfo}>{children}</PopupIconContext.Provider>

      {popupInfo.popup !== null ? (
        <InfoPopupButton type='html' content={popupInfo.popup.text} title={popupInfo.popup.title}>
          <HelpLink size={3} />
        </InfoPopupButton>
      ) : 
        keyExists(hint + '.text') && (
          <InfoPopupButton
            type='html'
            content={hint + '.text'}
            title={keyExists(hint + '.title') ? hint + '.title' : undefined}
          >
            <HelpLink size={3} />
          </InfoPopupButton>
        )
      }
    </>
  );
};

export default wrapIntl<IPage>(withUser<IPage & IWrapIntl>(withRouter(useTheme(Page)), false));
