import { Flex, Grid, Typography } from '@xcorejs/ui';
import { GameContext } from 'components/App';
import LoadingSpinnerLocal from 'components/atoms/Animations/LoadingSpinnerLocal';
import { SecondaryButton } from 'components/components/Button';
import { IValueWithName } from 'components/molecules/StatisticsSlider/data';
import CollectingData from 'components/pages/App/Statistics/components/CollectingData';
import { createUrl } from 'components/pages/routes';
import { IValueTestResultDetail } from 'config/api/valueTestResult';
import React, { FC, Fragment, useContext, useEffect, useState } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { IUserInfo } from 'store/User';
import { useFetchTribalValueTestResults } from 'utils/useQuery/queryFetchHelpers';

interface LaddersProps {
  values: IValueWithName[];
  user: IUserInfo | null;
}

const Ladders: FC<LaddersProps> = ({ values, user }) => {
  const { eticiaPlus, userLvl } = useContext(GameContext);
  const [tribal, setTribal] = useState<IValueTestResultDetail | null>(null);
  const tribalQuery = useFetchTribalValueTestResults();

  useEffect(() => {
    if (tribalQuery.isSuccess) {
      setTribal(tribalQuery.data);
    }
  }, [tribalQuery.data, tribalQuery.isSuccess]);

  const closeModal = () => {
    const modal = document.querySelector('reach-portal');
    modal && modal.remove();
  };

  return (
    <Fragment>
      {tribal ? (
        <>
          {user && user.maritalStatus ? 
            values.length > 0 ? (
              <Grid columns='repeat(2, 1fr)' rows='repeat(5, 1fr)' {...{ gridAutoFlow: 'column' }} gap='0.2rem 0.4rem' mt='3rem' height='16.8rem'>
                {values.slice(0, 10).map((value, i) => {
                  const isTribalValue = tribal && tribal.allLifeValues
                    .slice(0, 10)
                    .some(e => e.valueId === value.valueId);
                  const backgroundColor = isTribalValue ? 'rgb(254, 222, 113)' : 'rgb(63, 143, 116)';
                  const textColor = isTribalValue ? '#454545' : 'rgba(255, 255, 255, 1)';

                  return (
                    <Fragment key={i}>
                      <Flex
                        px='1.1rem'
                        alignItems='center'
                        justifyContent='center'
                        borderRadius='2px'
                        bg={backgroundColor}
                        minHeight='3.2rem'
                      >
                        <Typography
                          fontSize='1.2rem'
                          lineHeight='1.5rem'
                          color={textColor}
                        >
                          {i + 1}.
                        </Typography>

                        <Typography
                          fontSize={{ _: '1.2rem', md: '1.3rem' }}
                          lineHeight={{ _: '1.4rem', md: '1.5rem' }}
                          width='100%'
                          color={textColor}
                        >
                          {value.name}
                        </Typography>
                      </Flex>
                    </Fragment>
                  );
                })}
              </Grid>
            ) : (
              <CollectingData />
            )
            : (
              <Link to={createUrl('app.profile') + '/profile-info'} onClick={closeModal}>
                <Flex
                  background='rgba(11,12,28,0.5)'
                  width='35rem'
                  maxWidth='100%'
                  height='16.8rem'
                  alignItems='center'
                  justifyContent='center'
                  flexDirection='column'
                  mt='3rem'
                >
                  <Typography
                    fontSize='1.3rem'
                    lineHeight='1.8rem'
                    color='rgba(255, 255, 255, 0.9)'
                    maxWidth='15rem'
                    mx='auto'
                  >
                    <FormattedMessage id='statistics.ladder.alert' />
                  </Typography>

                  <SecondaryButton as='a' borderColor='textGreen' size='xs' mt='2rem'>
                    <FormattedMessage id='statistics.ladder.alert.button' />
                  </SecondaryButton>
                </Flex>
              </Link>
            )}
        </>
          
      ) : (
        <LoadingSpinnerLocal />
      )}

      <Flex alignItems='center' justifyContent='center' mt='3rem'>
        <Typography
          fontSize='1.3rem'
          lineHeight='1.5rem'
          color='rgba(255, 255, 255, 0.5)'
          maxWidth='70%'
        >
          <FormattedHTMLMessage id={'statistics.top10.match'} />
        </Typography>
      </Flex>
    </Fragment>
  );
};

export default Ladders;
