import React, { FC, ReactNodeArray } from 'react';
import { Modal, Heading2, Flex, Paragraph, Icon, Heading3, Link, Img, Box, useModal, Heading4 } from '@xcorejs/ui';
import { BlueTheme, yellowWhite, yellow, yellowLighter } from 'components/theme/colors';
import { CompareIcon } from '../../icons/16/compare.svg';
import { createUrl } from 'components/pages/routes';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { SVGFillColor } from 'components/pages/App/InterestingComparison/styles';
import { OliLight } from 'components/theme/fonts';
import LaddersCompareMatrixModal from '../LaddersCompareMatrixModal/LaddersCompareMatrixModal';

const HowToCompareModal: FC<{close: () => void}> = ({ close: closeHowTo }) => {
  const [openLaddersCompareMatrixModal] = useModal(LaddersCompareMatrixModal);
  const history = useHistory();
  const [close] = useModal();

  const linkAction = (address: string) => {
    history.push(createUrl(address));
    closeHowTo();
  };

  const ParagraphStyle = {
    fontSize: { _: '1.4rem', sm: '1.6rem' },
    lineHeight: { _: '1.6rem', sm: '2rem' }
  };
  
  return ( 
    <Modal
      p={{ _: '4rem 1.6rem', sm: '4rem 4rem' }}
      alignItems='center'
      background={BlueTheme.primary}
    >
      <Heading2 color={BlueTheme.text}>Porovnání hodnotových žebříčků</Heading2>
      
      <Heading2 color={yellow} marginTop='2rem'>Jak porovnat vlastní žebříčky?</Heading2>

      <Heading4 as='p' color='white'>Je to jednoduché</Heading4>

      <Flex flexDirection='column' alignItems='center' marginTop='2rem'>
        <Paragraph
          color='rgb(42, 56, 89)'
          textAlign='center'
          {...ParagraphStyle}
        >
        Klikni u konkrétního žebříčku na ikonu porovnávače v hodnotovém deniku
        </Paragraph>

        <Icon
          display='flex'
          alignItems='center'
          justifyContent='center'
          svg={<CompareIcon />}
          width='5rem'
          height='5rem'
          borderRadius='50%'
          fill='#fff'
          marginTop='1rem'
          background='rgb(137,71,105)'
        />
      </Flex>

      <Paragraph marginTop='2rem' color='white'>
    Díky této unikátní funkci můžeš snadno sledovat zda a jak se tvé hodnoty mění, nebo objevovat
vzájemné souvislosti mezi tvými hodnotami v nejrůznějších oblastech života
      </Paragraph>

      <Link
        variant='underline'
        onClick={() => {
          closeHowTo();
          openLaddersCompareMatrixModal({ close });
        }}
        marginTop='1rem'
      >
        Seznam porovnání objevíš zde
      </Link>

      <Heading2 marginTop='2rem' color={yellow}>
        Jak porovnat s druhými
      </Heading2>

      <Img src='http://placehold.it/80x80' alt='' marginTop='1rem' />

      <Heading3 marginTop='2rem' color={yellow}>
        <FormattedMessage id='interestingComparison.howto.heading' />
      </Heading3>

      <Paragraph
        mt='1rem'
        color='white'
        textAlign={{ _: 'justify', xs: 'center' }}
        fontSize={{ _: '1.2rem', sm: '1.3rem' }}
        lineHeight={{ _: '1.5rem', sm: '1.6rem' }}
        fontFamily={OliLight}
      >
        <SVGFillColor color='white'>
          <FormattedMessage 
            id='interestingComparison.howto.text' 
            values={{
              'odkaz': (...chunks: ReactNodeArray) => (
                <Link
                  href={createUrl('app.shop')}
                  onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                    e.preventDefault();
                    linkAction('app.shop');
                  }}
                  variant='underline'
                  style={{ 
                    color: '#FFFFDB !important', 
                    fontSize: 'inherit', 
                    lineHeight: 'inherit', 
                    fontFamily: 'inherit' 
                  }}
                >
                  {chunks}
                </Link>
              ),
              'ikona': () => (
                <Icon svg={<CompareIcon />} color='white' /> 
              ),
              'nbsp': () => <>&nbsp;</>
            }}
          />
        </SVGFillColor>
      </Paragraph>

      <Flex
        justifyContent='space-evenly'
        width='100%'
        marginTop='2rem'
      >
        <Box>
          <Img src='http://placehold.it/80x80' alt='' />
          <Paragraph>Osoba 1</Paragraph>
        </Box>

        <Box>
          <Img src='http://placehold.it/80x80' alt='' />
          <Paragraph>Osoba 2</Paragraph>
        </Box>
      </Flex>

      <Paragraph
        maxWidth='35rem'
        color='#000c'
        marginTop='1rem'
        lineHeight={{ _: '1.7rem', sm: '1.8rem' }}
      >
        Vytvoř si žebříček a nech u svého vytvořit žebříček druhou osobu
      </Paragraph>

      <Paragraph
        maxWidth='35rem'
        color='#000c'
        marginTop='1rem'
        lineHeight={{ _: '1.7rem', sm: '1.8rem' }}
      >
        Jakmile budete mít oba konkrétní žebříček vytvořen, klikněte na ikonu porovnání
        <Icon
          display='flex'
          alignItems='center'
          justifyContent='center'
          svg={<CompareIcon />}
          width='3rem'
          height='3rem'
          padding='0.5rem'
          borderRadius='50%'
          fill='#fff'
          background='rgb(137,71,105)'
          marginX='auto'
        />
        a objevte své hodnoty
      </Paragraph>

      <Link
        variant='underline'
        href={createUrl('app.valuesForTwo')}
        onClick={(e: any) => {
          e.preventDefault();
          linkAction('app.valuesForTwo');
        }}
        marginTop='2rem'>
          Jaké žebříčky lze s druhými porovnat?
      </Link>
    </Modal>
  );
};
 
export default HowToCompareModal;