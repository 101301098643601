import * as React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { IStringMap } from 'utils';
import { remove } from 'utils/option';

export interface IWrapIntl {
  formatMsg: (id: string, values?: IStringMap<any>, defaultMessage?: string) => string;
  formatHtml: (id: string, values?: IStringMap<any>, defaultMessage?: string) => React.ReactNode;
  keyExists: (id: string) => boolean;
}

// Wrapper component for easier use of react-intl without Formatted... components
// TODO:
// formatDate(value: DateSource, options?: FormattedDate.PropsBase): string;
// formatTime(value: DateSource, options?: FormattedTime.PropsBase): string;
// formatRelative(value: DateSource, options?: FormattedRelative.PropsBase & { now?: any }): string;
// formatNumber(value: number, options?: FormattedNumber.PropsBase): string;
// formatPlural(value: number, options?: FormattedPlural.Base): keyof FormattedPlural.PropsBase;

const wrapIntl = <P extends {}>(Component: React.ComponentType<P & IWrapIntl>) =>
  injectIntl((props: WrappedComponentProps<'intl'> & P) => {
    const formatMsg = (id: string, values?: IStringMap<string>, defaultMessage?: string) =>
      props.intl.formatMessage({ id, defaultMessage }, values);

    const formatHtml = (id: string, values?: IStringMap<string>, defaultMessage?: string) => (
      <span
        dangerouslySetInnerHTML={{
          __html: props.intl.formatMessage({ id, defaultMessage }, values),
        }}
      />
    );

    const keyExists = (id: string) => !!props.intl.messages[id];

    return (
      <Component
        formatMsg={formatMsg}
        formatHtml={formatHtml}
        keyExists={keyExists}
        {...((remove({ ...props }, 'intl') as unknown) as P)}
      />
    );
  });

export default wrapIntl;
