import React, { FC } from 'react';
import { SectionHomepage, Button } from '../styles';
import { Heading1, Paragraph, Heading3, Typography, Img, Box } from '@xcorejs/ui';
import { FormattedMessage } from 'react-intl';
import { createUrl } from 'components/pages/routes';
import { useHistory } from 'react-router-dom';

const AboutEticia: FC = () => {
  const history = useHistory();
  
  return ( 
    <SectionHomepage
      backgroundColor='#0a1119'
      display='flex'
      backgroundImage='/img/homepage/planetCrop.jpg'
      backgroundSize={{ _: '125%', xs: 'auto' }}
    >
      <Box m='auto' maxWidth={{ _:'32rem', md: '40rem' }} display='flex' alignItems='center' flexDirection='column'>
        <Heading1 textAlign='center'>
          Eticia 21
        </Heading1>

        <Heading3 color='#808088' mt='4rem' fontSize={{ _: '2rem', xs: '1', md:'2.4rem' }} lineHeight={{ _: '2.2rem', md:'3rem' }}>
          <FormattedMessage id='homepage.box.aboutEticia.subtitle' />
        </Heading3>

        <Paragraph mt='3rem' fontFamily='OliLight' fontSize={{ _: '1.5rem', md:'1.8rem' }} lineHeight={{ _: '2.2rem', md:'2.8rem' }}>
          <FormattedMessage id='homepage.box.aboutEticia.paragraph1' />
        </Paragraph>

        <Img 
          src='/img/logo/institut-white.png'
          alt='Institut Eticia' 
          width='160px'
          mt='4rem'
          style={{ opacity: '0.8' }}
        />

        <Paragraph mt='3rem' fontFamily='OliLight' fontSize={{ _: '1.5rem', md:'1.8rem' }} lineHeight={{ _: '2.2rem', md:'2.8rem' }}>
          <FormattedMessage id='homepage.box.aboutEticia.paragraph2' />
        </Paragraph>

        <Typography mt='4rem' color='#808088' fontSize={{ _: '1.5rem', md:'1.8rem' }} lineHeight={{ _: '2.2rem', md:'2.8rem' }}>
          <FormattedMessage id='homepage.box.aboutEticia.paragraph3' />
        </Typography>

        <Button mt='3rem' borderColor='#6f6996' backgroundColor='#0003' onClick={() => history.push(createUrl('about'))}>
          <FormattedMessage id='homepage.box.aboutEticia.button' />
        </Button>
      </Box>
    </SectionHomepage>
  );
};
 
export default AboutEticia;