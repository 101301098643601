import { Box, Container, Flex, Grid, Heading2, Icon, Paragraph, Typography } from '@xcorejs/ui';
import { GameContext } from 'components/App';
import LoadingSpinner from 'components/atoms/Animations/LoadingSpinner';
import { useUser } from 'components/atoms/hooks/useUser';
import { PlusTag } from 'components/atoms/Logo';
import TribalTestMissing from 'components/atoms/TribalTestMissing/TribalTestMissing';
import { IStatistic } from 'components/molecules/StatisticsSlider/data';
import CircleGroup from 'components/pages/App/Statistics/components/CircleGroup';
import Ladders from 'components/pages/App/Statistics/components/Ladders';
import StatisticsCard from 'components/pages/App/Statistics/components/StatisticsCard';
import StatisticsPage from 'components/pages/App/Statistics/index';
import TopValuesModal from 'components/pages/App/Statistics/modals/TopValuesModal';
import { IGameStatus } from 'config/api/game';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { useFetchGameStatus, useFetchStatGroupMatchTest } from 'utils/useQuery/queryFetchHelpers';
import SvetHodnotIcon from '../../../../components/icons/48/svethodnot.svg';

type FilteredProps = {
  mostCommon: IStatistic | null;
  leastCommon: IStatistic | null;
  ageGroup: IStatistic[] | null;
}

const Funny: FC = () => {
  const { eticiaPlus, userLvl } = useContext(GameContext);
  const user = useUser();

  const [gameStatus, setGameStatus] = useState<IGameStatus | null>(null);
  const [tribalResultId, setTribalResultId] = useState<string>('');

  const [statistics, setStatistics] = useState<IStatistic[] | null>(null);
  const [filtered, setFiltered] = useState<FilteredProps>({
    mostCommon: null, 
    leastCommon: null, 
    ageGroup: null
  });
  
  const gameStatusQuery = useFetchGameStatus();
  const statisticsQuery = useFetchStatGroupMatchTest(tribalResultId);

  const filterStatistics = useCallback((statistics: IStatistic[]) => {
    const filteredStatistics = statistics
      .filter(s => s.groupCategory === null);
    const mostCommon = filteredStatistics
      .reduce((max, stat) => max.value > stat.value ? max : stat);
    const leastCommon = filteredStatistics
      .reduce((max, stat) => max.value < stat.value ? max : stat);
    const ageGroup = user ? statistics
      .filter(s =>
        s.ageGroup && 
      s.ageFrom <= (new Date).getFullYear() - user.yearOfBirth && 
      s.ageTo > (new Date).getFullYear() - user.yearOfBirth)
      : null;

    setFiltered({ mostCommon, leastCommon, ageGroup });
  }, [user]);

  useEffect(() => {
    if (gameStatusQuery.isSuccess) {
      setGameStatus(gameStatusQuery.data);
      setTribalResultId(gameStatusQuery.data.tribalTestResult.id);
    }
  }, [gameStatusQuery.data, gameStatusQuery.isSuccess]);

  useEffect(() => {
    if (statisticsQuery.isSuccess) {
      setStatistics(statisticsQuery.data);
    }
  }, [statisticsQuery.data, statisticsQuery.isSuccess]);

  useEffect(() => {
    if (statistics) {
      filterStatistics(statistics);
    }
  }, [filterStatistics, statistics]);

  return (
    <StatisticsPage>
      <Container flexDirection='column'>
        <Flex flexDirection='column' alignItems='center' pt='4rem' maxWidth='85rem' mx='auto'>
          <Flex alignItems='center' flexDirection={{ _: 'column', sm: 'row' }}>
            <Icon svg={<SvetHodnotIcon />} />

            <Heading2
              as='h1'
              ml={{ _: 0, sm: '2rem' }}
              mt={{ _: '2rem', sm: 0 }}
            >
              <FormattedHTMLMessage id='statistics.site1.funnyuser.head1' />
            </Heading2>
          </Flex>

          <Paragraph mt='1rem'>
            <FormattedHTMLMessage id='statistics.site1.funnyuser.text' />
          </Paragraph>

          <Grid gap='3rem' columns={{ _: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }} rows='auto' mt='3rem'>
            {gameStatus ? 
              gameStatus.tribalTestResult !== null ? 
                statistics && filtered.mostCommon && filtered.leastCommon ? (
                  <StatisticsCard
                    heading={<FormattedHTMLMessage id='statistics.site.funny.result1.head1' />}
                    _heading={{ color: 'yellow', fontWeight: 800 }}
                    paragraph={<FormattedHTMLMessage id='statistics.site.funny.result1.text1' />}
                    noSubheading
                    modalComponent={() => 
                      <TopValuesModal resultId={gameStatus.tribalTestResult.id} user={user} />
                    }
                    onlyPlusOrGame
                    minGameLevelToSee={8}
                  >
                    <Flex alignItems='flex-start' justifyContent='space-evenly' mt='2rem'>
                      <CircleGroup
                        mostCommon
                        name={filtered.mostCommon.title + ', ' + filtered.mostCommon.perex}
                        colorScheme='green'
                        img={filtered.mostCommon.image}
                        onlyPlusOrGame
                        minGameLevelToSee={8}
                      />
                      <CircleGroup
                        leastCommon
                        name={filtered.leastCommon.title + ', ' + filtered.leastCommon.perex}
                        img={filtered.leastCommon.image}
                        colorScheme='gray'
                        onlyDemo
                      />
                    </Flex>
                  </StatisticsCard>
                ) : null
                : (<TribalTestMissing buttonBackgroundColor='#008056' />)
              : gameStatusQuery.isLoading ? 
                <LoadingSpinner /> : 
                null}

            {user && new Date().getFullYear() - user.yearOfBirth >= 16 && (
              <StatisticsCard
                heading={<FormattedHTMLMessage id='statistics.site.funny.result2.head1' />}
                _heading={{ color: 'yellow', fontWeight: 800 }}
                paragraph={<FormattedHTMLMessage id='statistics.site.funny.result2.text1' />}
                noSubheading
                noLink
                narrowPadding
              >
                {eticiaPlus ? 
                  !statisticsQuery.isLoading ? (
                    <Box>
                      {user && user.yearOfBirth > 1 ? (
                        <Box>
                          {filtered.ageGroup && filtered.ageGroup.length > 0 ? (
                            <Ladders
                              user={user}
                              values={filtered.ageGroup[0].topTenValueResults
                              }
                            />
                          ) : (
                            <Typography mt='1rem'>
                            Není vhodná skupina
                            </Typography>
                          )}                            
                        </Box>
                      ) : (
                        <Typography mt='1rem'>
                        Musíte se narodit v tomhle tisíciletí
                        </Typography>
                      )}
                    </Box>
                  ) : <LoadingSpinner />
                  : (
                    <Box
                      p='2rem'
                      bg='rgba(11, 12, 28, 0.5)'
                      borderRadius='4px'
                      mt='3rem'
                    >
                      <Typography fontSize='1.3rem' lineHeight='1.8rem' color='rgba(255, 255, 255, 0.8)'>
                      Chceš-li vidět hodnoty skupiny, aktivuj si Eticia Plus.
                      </Typography>

                      <Flex justifyContent='center' mt='2rem'>
                        <PlusTag />
                      </Flex>
                    </Box>
                  )}
              </StatisticsCard>
            )}
          </Grid>
          {/* 
          <Heading3 mt='6rem'>
            <FormattedHTMLMessage id='statistics.site1.funnyuser.head2' />
          </Heading3>

          <Paragraph mt='1rem'>
            <FormattedHTMLMessage id='statistics.site1.funnyuser.text2' />
          </Paragraph> */}
        </Flex>

        {/* <Flex maxWidth='129rem' mx='auto'>
          <Grid gap='3rem' columns={{ _: 'repeat(1, 1fr)', sm: 'repeat(3, 1fr)' }} rows='auto' mt='3rem'>
            <Fetch url={GAME_STATUS}>
              {_switch({
                success: (detail: IGameStatus) =>
                  detail.tribalTestResult !== null ? (
                    <Fetch url={STATISTICS_GROUP_MATCH_TEST(detail.tribalTestResult.id)}>
                      {_switch({
                        success: (statistics: IStatistic[]) =>
                          statistics && (
                            <StatisticsCard
                              heading={<FormattedHTMLMessage id='statistics.site.funny.result3.head1' />}
                              paragraph={<FormattedHTMLMessage id='statistics.site.funny.result3.text1' />}
                              noSubheading
                              modalComponent={() => <PositiveModal />}
                              onlyPlusOrGame
                              minGameLevelToSee={26}
                            >
                              <Flex alignItems='flex-start' justifyContent='space-evenly' mt='2rem' {...{ style: { gap: '2rem' } }}>
                                <CircleGroup
                                  mostCommon
                                  name={statistics.filter(s => s.groupCategory !== null && s.groupCategory.name === 'Totemové zvíře').reduce((max, stat) => max.value > stat.value ? max : stat).title
                              + (statistics.filter(s => s.groupCategory !== null && s.groupCategory.name === 'Totemové zvíře').reduce((max, stat) => max.value > stat.value ? max : stat).perex !== null
                                ? ', ' + statistics.filter(s => s.groupCategory !== null && s.groupCategory.name === 'Totemové zvíře').reduce((max, stat) => max.value > stat.value ? max : stat).perex : '')}
                                  colorScheme='green'
                                  img={statistics.filter(s => s.groupCategory !== null && s.groupCategory.name === 'Totemové zvíře').reduce((max, stat) => max.value > stat.value ? max : stat).image}
                                  onlyPlusOrGame
                                  minGameLevelToSee={26}
                                />
                                <CircleGroup
                                  leastCommon
                                  name={statistics.filter(s => s.groupCategory !== null && s.groupCategory.name === 'Totemové zvíře').reduce((max, stat) => max.value < stat.value ? max : stat).title
                              + (statistics.filter(s => s.groupCategory !== null && s.groupCategory.name === 'Totemové zvíře').reduce((max, stat) => max.value < stat.value ? max : stat).perex !== null
                                ? ', ' + statistics.filter(s => s.groupCategory !== null && s.groupCategory.name === 'Totemové zvíře').reduce((max, stat) => max.value < stat.value ? max : stat).perex : '')}
                                  img={statistics.filter(s => s.groupCategory !== null && s.groupCategory.name === 'Totemové zvíře').reduce((max, stat) => max.value < stat.value ? max : stat).image}
                                  colorScheme='gray'
                                  onlyInterestGroup
                                />
                              </Flex>
                            </StatisticsCard>
                          )
                        ,
                        fetching: () => (<LoadingSpinnerLocal />),
                      })()}
                    </Fetch>
                  ) : (<TribalTestMissing />)
                ,
                fetching: () => <LoadingSpinnerLocal />,
              })()}
            </Fetch>

            <Fetch url={GAME_STATUS}>
              {_switch({
                success: (detail: IGameStatus) =>
                  detail.tribalTestResult !== null ? (
                    <Fetch url={STATISTICS_GROUP_MATCH_TEST(detail.tribalTestResult.id)}>
                      {_switch({
                        success: (statistics: IStatistic[]) => (
                          <StatisticsCard
                            heading={<FormattedHTMLMessage id='statistics.site.funny.result4.head1' />}
                            paragraph={<FormattedHTMLMessage id='statistics.site.funny.result4.text1' />}
                            noSubheading
                            modalComponent={() => <TimeWasteModal />}
                            onlyPlusOrGame
                            minGameLevelToSee={8}
                          >
                            <Flex alignItems='flex-start' justifyContent='space-evenly' mt='2rem' {...{ style: { gap: '2rem' } }}>
                              <CircleGroup
                                mostCommon
                                name={statistics.filter(s => s.groupCategory !== null && s.groupCategory.name === 'Flákání a lenošení').reduce((max, stat) => max.value > stat.value ? max : stat).title
                              + (statistics.filter(s => s.groupCategory !== null && s.groupCategory.name === 'Flákání a lenošení').reduce((max, stat) => max.value > stat.value ? max : stat).perex !== null
                                ? ', ' + statistics.filter(s => s.groupCategory !== null && s.groupCategory.name === 'Flákání a lenošení').reduce((max, stat) => max.value > stat.value ? max : stat).perex : '')}
                                colorScheme='green'
                                img={statistics.filter(s => s.groupCategory !== null && s.groupCategory.name === 'Flákání a lenošení').reduce((max, stat) => max.value > stat.value ? max : stat).image}
                                onlyPlusOrGame
                                minGameLevelToSee={8}
                              />
                              <CircleGroup
                                leastCommon
                                name={statistics.filter(s => s.groupCategory !== null && s.groupCategory.name === 'Flákání a lenošení').reduce((max, stat) => max.value < stat.value ? max : stat).title
                              + (statistics.filter(s => s.groupCategory !== null && s.groupCategory.name === 'Flákání a lenošení').reduce((max, stat) => max.value < stat.value ? max : stat).perex !== null
                                ? ', ' + statistics.filter(s => s.groupCategory !== null && s.groupCategory.name === 'Flákání a lenošení').reduce((max, stat) => max.value > stat.value ? max : stat).perex : '')}
                                colorScheme='gray'
                                img={statistics.filter(s => s.groupCategory !== null && s.groupCategory.name === 'Flákání a lenošení').reduce((max, stat) => max.value < stat.value ? max : stat).image}
                                onlyInterestGroup
                              />
                            </Flex>
                          </StatisticsCard>
                        ),
                        fetching: () => (<LoadingSpinnerLocal />),
                      })()}
                    </Fetch>
                  ) : (<TribalTestMissing />)
                ,
                fetching: () => <LoadingSpinnerLocal />,
              })()}
            </Fetch>

            <Fetch url={GAME_STATUS}>
              {_switch({
                success: (detail: IGameStatus) =>
                  detail.tribalTestResult !== null ? (
                    <Fetch url={STATISTICS_GROUP_MATCH_TEST(detail.tribalTestResult.id)}>
                      {_switch({
                        success: (statistics: IStatistic[]) => (
                          <StatisticsCard
                            heading={<FormattedHTMLMessage id='statistics.site.funny.result5.head1' />}
                            paragraph={<FormattedHTMLMessage id='statistics.site.funny.result5.text1' />}
                            noSubheading
                            modalComponent={() => <SelfDestructionModal />}
                            onlyPlusOrGame
                            minGameLevelToSee={8}
                          >
                            <Flex alignItems='flex-start' justifyContent='space-evenly' mt='2rem' {...{ style: { gap: '2rem' } }}>
                              <CircleGroup
                                mostCommon
                                name={statistics.filter(s => s.groupCategory !== null && s.groupCategory.name === '2 hodiny týdně navíc').reduce((max, stat) => max.value > stat.value ? max : stat).title
                              + (statistics.filter(s => s.groupCategory !== null && s.groupCategory.name === '2 hodiny týdně navíc').reduce((max, stat) => max.value < stat.value ? max : stat).perex !== null
                                ? ', ' + statistics.filter(s => s.groupCategory !== null && s.groupCategory.name === '2 hodiny týdně navíc').reduce((max, stat) => max.value < stat.value ? max : stat).perex : '')}
                                colorScheme='green'
                                img={statistics.filter(s => s.groupCategory !== null && s.groupCategory.name === '2 hodiny týdně navíc').reduce((max, stat) => max.value > stat.value ? max : stat).image}
                                onlyPlusOrGame
                                minGameLevelToSee={8}
                              />
                              <CircleGroup
                                leastCommon
                                name={statistics.filter(s => s.groupCategory !== null && s.groupCategory.name === '2 hodiny týdně navíc').reduce((max, stat) => max.value < stat.value ? max : stat).title
                              + (statistics.filter(s => s.groupCategory !== null && s.groupCategory.name === '2 hodiny týdně navíc').reduce((max, stat) => max.value < stat.value ? max : stat).perex !== null
                                ? ', ' + statistics.filter(s => s.groupCategory !== null && s.groupCategory.name === '2 hodiny týdně navíc').reduce((max, stat) => max.value < stat.value ? max : stat).perex : '')}
                                colorScheme='gray'
                                img={statistics.filter(s => s.groupCategory !== null && s.groupCategory.name === '2 hodiny týdně navíc').reduce((max, stat) => max.value < stat.value ? max : stat).image}
                                onlyInterestGroup
                              />
                            </Flex>
                          </StatisticsCard>
                        )
                        ,
                        fetching: () => (<LoadingSpinnerLocal />),
                      })()}
                    </Fetch>
                  ) : (<TribalTestMissing />)
                ,
                fetching: () => <LoadingSpinnerLocal />,
              })()}
            </Fetch>
          </Grid>
        </Flex> */}
      </Container>
    </StatisticsPage>
  );
};

export default Funny;
