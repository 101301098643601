import { Box, Flex, Img, Link, Paragraph, useModal } from '@xcorejs/ui';
import { GameContext } from 'components/App';
import { PlusTag } from 'components/atoms/Logo';
import { BlackTheme, BlueTheme } from 'components/theme/colors';
import React, { FC, Fragment, ReactNode, useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { FormattedMessage, IntlContext } from 'react-intl';
import { SecondaryButton } from '../Button';
import FeedbackModal from '../modals/FeedbackModal';

type Projects = {
  cs: string[];
  en: string[];
  [key: string]: string[];
};

const projects: Projects = { 
  cs: ['adra.png', 'pexels-elina-volkova-19985445.jpg', 'pexels-pixabay-416160.jpg', 'pexels-alexandra-bilham-7329892.jpg',
    'pexels-lumn-406014.jpg', 'pexels-pixabay-45170.jpg', 'pexels-chevanon-photography-1108099.jpg',
    'pexels-niklas-jeromin-10479040.jpg', 'pexels-pixabay-45201.jpg'],
  en: ['adra.png', 'pexels-elina-volkova-19985445.jpg', 'pexels-pixabay-416160.jpg', 'pexels-alexandra-bilham-7329892.jpg',
    'pexels-lumn-406014.jpg', 'pexels-pixabay-45170.jpg', 'pexels-chevanon-photography-1108099.jpg',
    'pexels-niklas-jeromin-10479040.jpg', 'pexels-pixabay-45201.jpg']
};
const TIMER = 8;

interface FullScreenAdProps {
  /** co se stane když kliknu na tlačítko */ onClick: () => void;
}

const FullScreenAd: FC<FullScreenAdProps> = ({ onClick }) => {
  const { eticiaPlus } = useContext(GameContext);
  const [countdown, setCountdown] = useState<number>(TIMER);
  const [openFeedback] = useModal(FeedbackModal);
  const [projectImage, setProjectImage] = useState<string>('');
  const { locale } = useContext(IntlContext);

  useEffect(() => {
    const lastTimeShown = sessionStorage.getItem('lastTimeShown') || 0;
    const now = new Date().getTime();
    const timeDiff = now - Number(lastTimeShown);
    const timeDiffInMinutes = Number((timeDiff / 1000 / 60).toFixed(2));
    if (timeDiffInMinutes < 1) {
      onClick();
    } else {
      sessionStorage.setItem('lastTimeShown', now.toString());
    }
  }, [onClick]);

  useEffect(() => {
    const lang = locale;
    if (projects[lang]) setProjectImage(projects[lang][Math.floor(Math.random() * projects[lang].length)]);
    else setProjectImage(projects['en'][Math.floor(Math.random() * projects['en'].length)]);
  }, [locale]);

  useEffect(() => {
    if (eticiaPlus) return;

    const i = setInterval(() => {
      setCountdown((c) => c > 0 ?  c - 1 : 0);
    }, 1000);

    return () => {
      clearInterval(i);
    };
  }, [eticiaPlus]);

  if (eticiaPlus) return null;

  return (
    createPortal(
      <Flex width='100%' height='100%' flexDirection='column' justifyContent='center' alignItems='center' backgroundColor='rgba(11, 12, 14, 0.8)' position='fixed' left='0' right='0' top='0' bottom='0' zIndex={99999}>
        <Flex flexDirection='column' alignItems='center' style={{ gap: '2rem' }} background={BlueTheme.extraDark} padding={{ _: '2rem 1rem', md: '2rem 2rem 4rem' }} maxWidth='65rem'>
          <Flex flexDirection='column' justifyContent='center' height={{ _:'6rem', md: '8rem' }}>
            {countdown > 0 ? (
              <Fragment>
                <Paragraph><FormattedMessage id='advertisement.heading' /></Paragraph>
                <Paragraph>
                  <FormattedMessage id='advertisement.seconds' values={{ count : countdown }} />
                </Paragraph>
              </Fragment>
            ) : (
              <SecondaryButton 
                onClick={onClick}
                cursor='pointer'
                background={BlackTheme.primary}
              >
                <FormattedMessage id='advertisement.closeButton' />
              </SecondaryButton>
            )}
          </Flex>

          <Box position='relative'>
            {projectImage.includes('pexels') ? <Paragraph position='absolute' top='1rem' left='1rem' fontSize='2rem' opacity={0.5}>Stock fotografie pexels.com</Paragraph> : null}
            <Img src={`/img/ads/${projectImage}`} alt='' maxHeight='40rem' />
          </Box>

          <Paragraph onClick={onClick}>
            <FormattedMessage id='advertisement.text' values={{ tag: <PlusTag>Eticia Plus</PlusTag>, break: <br /> }} />
          </Paragraph>

          <Paragraph onClick={onClick} fontSize={{ _: '1.2rem', md: '1.3rem' }} color={BlueTheme.light} opacity={0.9}>
            <FormattedMessage id='advertisement.text2' values={{ feedback: (...x: ReactNode[]) => <Link v='underline' fontSize='inherit' onClick={() => openFeedback({ charity: true })}>{x}</Link>, break: <br /> }} />
          </Paragraph>
        </Flex>
      </Flex>,
      document.body
    ) );
};
 
export default FullScreenAd;