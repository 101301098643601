import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { PrimaryButton } from '../../Buttons';
import popupContext from '../../../organisms/MainPopup/popupContext';
import { ClickableText } from './data';
import ExternalSVG from '../../ExternalSVG';

interface Props {
  img?: string;
  name: string;
  texts: number;
  label?: string;
  position?: number;
}

const WithChildPopup: React.FC<Props> = ({ img, name, texts, label, children, position }) => {
  const close = React.useContext(popupContext);
  return (
    <div>
      {img && (
        <span key='img'>
          <ExternalSVG src={img} />
        </span>
      )}

      <h2>
        <FormattedMessage id={'faq.' + name + '.title'} />
      </h2>

      {Array(texts)
        .fill(null)
        .map((_, index) => (
          <ClickableText key={index} name={name} index={index}>
            {index + 1 === position && children}
          </ClickableText>
        ))}

      {label && (
        <span onClick={() => close()}>
          <PrimaryButton>
            <FormattedMessage id={label} />
          </PrimaryButton>
        </span>
      )}
    </div>
  );
};

export default WithChildPopup;
