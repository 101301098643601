import * as React from 'react';
import wrapIntl, { IWrapIntl } from '../../../atoms/wrapIntl';
import FeedbackForm from '../../../organisms/FeedbackForm';
import { sendFeedback } from './actions';

export const Feedback: React.FC<IWrapIntl> = ({ formatMsg }) => (
  <FeedbackForm title={formatMsg('feedback')} text={formatMsg('feedback.text')} submit={sendFeedback()} />
);

export default wrapIntl(Feedback);
