import styled from 'styled-components';
import { mediaLgDefault, mediaMdDefault } from '../../../../theme/grid';
import { darkBaseHEX, grayFooter, yellow, textGreen, textGreenRGB } from '../../../../theme/colors';
import { Heading1 } from '../../../../atoms/Headings';
import {
  StyledPrimaryButton,
  StyledPrimaryButtonButton,
  StyledSecondaryButton,
} from '../../../../atoms/Buttons/styles';
import { Col } from 'react-styled-flexboxgrid';
import { StyledCheckboxInput } from '../../../../atoms/FormItems/styles';
import { StyledCustomSelect } from '../../../../atoms/Select/styles';
import { StyledFormGroupInline } from '../../../../atoms/InlineForm/styles';

export const StyledSupport = styled.section`
  display: block;
  text-align: center;

  ${mediaMdDefault} {
    text-align: left;
  }

  ${Col as any} {
    &:first-child {
      margin-bottom: 3rem;

      ${mediaMdDefault} {
        margin-bottom: 0;
      }
    }
  }

  ${mediaMdDefault} {
    .only-mobile {
      display: none;
    }
  }

  .not-mobile {
    display: none;

    ${mediaMdDefault} {
      display: block;
    }
  }

  ${Heading1} {
    margin-bottom: 3rem;
  }

  .slider-box {
    padding-bottom: 2rem;
    border-radius: 0.4rem;
    background-color: #fff;
    box-shadow: 0 5px 10px 0 rgba(25, 29, 44, 0.4);
    text-align: center;

    ${mediaMdDefault} {
      padding-bottom: 5rem;
    }

    .slide {
      padding-top: 4rem;

      strong {
        margin-bottom: 3rem;
        display: block;

        color: ${darkBaseHEX};
        font-size: 2rem;
        letter-spacing: 0.5px;
        line-height: 2.6rem;
        text-align: center;
      }
    }
  }

  .dots {
    margin-top: 3rem;
    font-size: 0;

    li {
      margin: 0 0.5rem;
      list-style-type: none;
      display: inline-block;
      width: 1.5rem;
      height: 1.5rem;
      background: ${grayFooter};
      border-radius: 1.5rem;
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }

      &.active {
        background: green;
      }

      button {
        display: block;
        background: transparent;
        border: 0;
        height: 100%;
        cursor: pointer;

        &:focus {
          opacity: 0;
        }
      }
    }
  }

  .form-inline {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-top: 4rem;

    & > span {
      font-weight: 500;

      ${mediaMdDefault} {
        font-weight: 400;
      }
    }

    ${StyledSecondaryButton} {
      margin: 0.75rem 0.75rem 0;
      padding: 1.3rem 3rem;
      height: 5rem;
      font-size: 1.8rem;
      line-height: 2.3rem;

      ${mediaMdDefault} {
        margin: 0;
        margin-left: 1.5rem;
      }

      &.active {
        color: ${darkBaseHEX};
        background-color: ${yellow};
      }
    }
  }

  ${StyledPrimaryButton}, ${StyledPrimaryButtonButton} {
    margin-top: 4rem;
  }

  ${StyledFormGroupInline} {
    ${StyledCustomSelect} {
      .Select-control {
        width: 15rem;
      }

      .Select {
        border-bottom: 0.3rem solid rgba(${textGreenRGB}, 0.8) !important;
      }

      .Select-arrow {
        border-color: ${textGreen} transparent transparent;
      }

      .Select.is-open > .Select-control .Select-arrow {
        border-color: ${textGreen} transparent transparent;
        border-top-color: ${textGreen};
      }

      .Select-arrow-zone:hover .Select-arrow {
        border-top-color: ${textGreen};
      }
    }
  }

  .error {
    display: block;
    margin-top: 1rem;
    margin-bottom: 0;
    width: 100%;
    text-align: left;
    font-size: 1.6rem;
    line-height: 1.8rem;
    color: ${yellow};
  }

  ${StyledCheckboxInput} {
    display: inline-block;
    margin-top: 4rem;
  }

  .step2 {
    ${StyledFormGroupInline} {
      input {
        width: calc(100% - 1rem);

        ${mediaMdDefault} {
          width: auto;
        }
      }
    }
  }

  .address {
    ${StyledFormGroupInline} {
      ${mediaMdDefault} {
        input {
          width: 7rem;
        }

        &:first-of-type {
          input {
            width: 21rem;
          }
        }

        &:last-child {
          input {
            width: 12rem;
          }
        }
      }

      ${mediaLgDefault} {
        input {
          width: 8rem;
        }

        &:first-of-type {
          input {
            width: 23rem;
          }
        }

        &:last-child {
          input {
            width: 18rem;
          }
        }
      }
    }
  }

  .tshirt-description {
    margin-top: 4rem;
  }
`;
