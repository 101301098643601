import * as React from 'react';
import { StyledBurger, StyledQuestionIntro } from './styles';
import { CustomText } from '../../../atoms/Text';
import { Row, Col, Grid } from 'react-styled-flexboxgrid';
import { PrimaryButton } from '../../../atoms/Buttons';
import { IIntroSurvey } from '../data';
import { FormattedMessage } from 'react-intl';
import { DefaultGridTheme } from '../../../theme/grid';
import { ThemeProvider } from 'styled-components';
import { BurgerFlex } from '../../Layout/Header/styles';
import ShowMenuButton from '../../ShowMenuButton';
import Logo from '../../../atoms/Logo';

interface IQuestionIntro {
  data: IIntroSurvey;
  test: boolean;
  meeticia?: boolean;
}

interface IQuestionIntroState {
  intro: boolean;
}

class QuestionIntro extends React.Component<IQuestionIntro, IQuestionIntroState> {
  public state = { intro: true };

  public render() {
    const { data, children, test, meeticia } = this.props;

    return !!data && data.showIntro && this.state.intro ? (
      <ThemeProvider theme={DefaultGridTheme}>
        <>
          {!meeticia && (
            <StyledBurger>
              <BurgerFlex>
                <div>
                  <ShowMenuButton />
                  <Logo app />
                </div>
              </BurgerFlex>
            </StyledBurger>
          )}

          <StyledQuestionIntro decorationUrl={data.icon}>
            <Grid>
              <Row>
                <Col xs={12} md={6} mdOffset={1}>
                  <small>
                    <FormattedMessage id={!test ? 'question.intro.theme.survey' : 'question.intro.theme'} />
                  </small>
                  <h1>{data.name}</h1>

                  <CustomText>{data.text}</CustomText>

                  <PrimaryButton onClick={() => this.setState({ intro: false })}>
                    <FormattedMessage id={'question.intro.start'} />
                  </PrimaryButton>
                </Col>
              </Row>
            </Grid>
          </StyledQuestionIntro>
        </>
      </ThemeProvider>
    ) : (
      <>{children}</>
    );
  }
}

export default QuestionIntro;
