import * as React from 'react';

interface IPopupIconContext {
  popup: IPopup | null;
  show: (text: string, title?: string) => unknown;
  clear: () => unknown;
}

export const PopupIconContext = React.createContext<IPopupIconContext | null>(null);

interface IPopup {
  title?: string;
  text: string;
}

export const usePopupIcon = (): IPopupIconContext => {
  const [popup, setPopup] = React.useState<IPopup | null>(null);

  return {
    popup,
    show: (text: string, title?: string) =>
      (popup === null || text !== popup.text || title !== popup.title) && setPopup({ text, title }),
    clear: () => popup !== null && setPopup(null),
  };
};

interface IPopupIcon {
  title?: string;
  text: string;
}

const PopupIcon: React.FC<IPopupIcon> = ({ title, text }) => {
  const popup = React.useContext(PopupIconContext);
  React.useEffect(() => {
    popup!.show(text, title);
    return () => {
      popup!.clear();
    };
  });
  return null;
};

export default React.memo(PopupIcon, (prev, next) => prev.text === next.text && prev.title === next.title);
