import React, { FC } from 'react';
import Ladders from 'components/pages/App/Statistics/components/Ladders';
import Groups from 'components/pages/App/Statistics/components/Groups';
import { GAME_STATUS, IGameStatus } from 'config/api/game';
import { _switch } from 'utils/option';
import { STATISTICS_GROUP_MATCH_TEST } from 'config/apiRoutes';
import { IStatistic } from 'components/molecules/StatisticsSlider/data';
import LoadingSpinnerLocal from 'components/atoms/Animations/LoadingSpinnerLocal';
import Fetch from 'components/atoms/Fetch';
import StatisticsModal, { StatisticsModalProps } from 'components/pages/App/Statistics/components/StatisticModal';
import { FormattedHTMLMessage } from 'react-intl';
import { useUser } from 'components/atoms/hooks/useUser';

const PositiveModal: FC<StatisticsModalProps> = () => {
  const user = useUser();
  
  return (
    <Fetch url={GAME_STATUS}>
      {_switch({
        success: (detail: IGameStatus) => (
          <Fetch url={STATISTICS_GROUP_MATCH_TEST(detail.tribalTestResult.id)}>
            {_switch({
              success: (statistics: IStatistic[]) => {
                const totemAnimal = statistics.filter(s => s.groupCategory !== null && s.groupCategory.name === 'Totemové zvíře');
                const totemAnimalMax = totemAnimal.reduce((max, stat) => max.value > stat.value ? max : stat);
                const totemAnimalMin = totemAnimal.reduce((max, stat) => max.value < stat.value ? max : stat);
                
                return (
                  <StatisticsModal
                    colorScheme='blue'
                    content={
                      [
                        {
                          heading: <FormattedHTMLMessage id='statistics.site.funny.result3.head1' />,
                          tag: <FormattedHTMLMessage id='statistics.positive.content1.tag' />,
                          text: <FormattedHTMLMessage id='statistics.positive.content1.text' />,
                          circle: {
                            name: totemAnimalMax.title + (totemAnimalMax.perex !== null ? ', ' + totemAnimalMax.perex : ''),
                            onlyPlusOrGame: true,
                            img: totemAnimalMax.image
                          },
                          box2: <Ladders
                            user={user}
                            values={totemAnimalMax.topTenValueResults} />
                        },
                        {
                          heading: <FormattedHTMLMessage id='statistics.site.funny.result3.head1' />,
                          tag: <FormattedHTMLMessage id='statistics.positive.content2.tag' />,
                          text: <FormattedHTMLMessage id='statistics.positive.content2.text' />,
                          circle: {
                            name: totemAnimalMin.title + (totemAnimalMin.perex !== null ? ', ' + totemAnimalMin.perex : ''),
                            onlyInterestGroup: true,
                            img: totemAnimalMin.image
                          },
                          box2: <Ladders
                            user={user}
                            values={totemAnimalMin.topTenValueResults} />
                        },
                        {
                          heading: <FormattedHTMLMessage id='statistics.site.funny.result3.head1' />,
                          tag: <FormattedHTMLMessage id='statistics.positive.content3.tag' />,
                          text: <FormattedHTMLMessage id='statistics.positive.content3.text' />,
                          box1: <Groups statistics={totemAnimal} />,
                          text2: <FormattedHTMLMessage id='statistics.positive.content3.text2' />
                        }
                      ]
                    }
                  />
                );
              }
              ,
              fetching: () => (<LoadingSpinnerLocal />),
            })()}
          </Fetch>
        ),
        fetching: () => <LoadingSpinnerLocal />,
      })()}
    </Fetch>
  );
};

export default PositiveModal;
