import * as React from 'react';
import { FC, useContext } from 'react';
import { GameContext } from 'components/App';
import {
  Container,
  Flex,
  Heading2,
  Paragraph,
  Box,
  Typography,
  Tag,
  Link,
  FlexProps,
  Img,
  Grid,
  Heading4
} from '@xcorejs/ui';
import { createUrl } from 'components/pages/routes';
import { SecondaryButton } from 'components/components/Button';
import { Link as ReactLink } from 'react-router-dom';
import EticiaPlusTimingBox from 'components/components/EticiaPlusTimingBox';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import Fetch from '../../../../atoms/Fetch';
import { _switch } from '../../../../../utils/option';
import { ShopSummaryResponse } from '../data';
import LoadingSpinnerLocal from '../../../../atoms/Animations/LoadingSpinnerLocal';
import { SHOP_SUMMARY } from '../../../../../config/apiRoutes';

const Account: FC = () => {
  const { eticiaPlus } = useContext(GameContext);
  const [treeStillInWorks, setTreeStillInWorks] = React.useState(true);



  const notUsedColor = 'rgba(242, 173, 124, 0.8)';

  return (
    <>
      <Container flexDirection='column' overflowY='auto'>
        {/* testování přepínání hodnotový strom */}
        <Box position='fixed' bottom='20px' left='20px' onClick={() => setTreeStillInWorks(prev => !prev)} bg={!treeStillInWorks ? 'red' : 'green'} color='white' maxWidth='10rem' padding='1rem' fontSize='1.2rem'>
          Přepnout na verzi {!treeStillInWorks? 'bez hodnotového stromu' : 's hodnotovým stromem'}.
        </Box>
        
        <Flex flexDirection='column' alignItems='center' pt='4rem'>
          <Heading2 as='h1'>
            <FormattedMessage id='shop.account.heading' />
          </Heading2>

          <Paragraph mt='1rem' mx='auto' maxWidth='65rem' >
            <FormattedMessage id='shop.account.text' />
          </Paragraph>

          <EticiaPlusTimingBox mt={{ _: '3rem', sm: '5rem' }} />
        </Flex>

        <Heading2 color='orange' mt='6rem'>
          <FormattedHTMLMessage id='shop.account.heading2' />
        </Heading2>
        <Heading4 color='orange'>
          <FormattedMessage id='shop.account.heading2a' />
        </Heading4>

        <Fetch url={SHOP_SUMMARY}>
          {_switch({
            success: (summary: ShopSummaryResponse) => (
              <>
                {summary.mains.length ? (
                  <Box
                    flexDirection='column'
                    {...BoxStyle}
                  >
                    {summary.mains.map((row, i) => (
                      <Grid
                        key={i}
                        {...GridStyle} >
                        <Flex {...FlexStyle}>
                          <Typography>
                            {toDate(row.createdAt)}
                          </Typography>
                        </Flex>

                        <Flex {...FlexStyle}>
                          <Tag 
                            bg={row.used ? notUsedColor : 'rgba(0, 0, 0, 0.2)'} 
                            padding='8px 16px !important'
                            border='1px solid rgba(255, 255, 255, 0.3)'
                            borderRadius='9999px'
                            line-height='1'
                            opacity={row.used ? 0.7 : 1}
                            marginX={{ _: '1rem', sm: '7rem' }}
                          >
                            {row.title}
                          </Tag>
                        </Flex>

                        <Flex {...FlexStyle}>
                          <Typography color={`rgba(244, 244, 246, ${row.used ? '0.7' : '1'})`}>
                            <FormattedMessage id={`shop.account.${row.used ? 'used' : 'notUsed'}`} />
                          </Typography>
                        </Flex>

                        <Flex
                          {...FlexStyle}
                          paddingY={row.used ? '0.1rem' : FlexStyle.paddingY}
                        >
                          {!row.used && (
                            <Link
                              v='underline'
                              color={notUsedColor}
                              borderColor={notUsedColor}
                              href={`/api/v1/shop/pdf/${row.id}`}
                              target='_blank'
                            >
                              <FormattedMessage id='shop.account.download' />
                            </Link>
                          )}
                        </Flex>
                      </Grid>
                    ))}
                  </Box>
                ) : (
                  <Paragraph {...InfoTextStyle}>
                    <FormattedMessage id='shop.account.noVouchers' />
                  </Paragraph>
                )}

                <Heading2 color='#121212' mt='6rem'>
                  <FormattedMessage id='shop.account.heading3' />
                </Heading2>
                <Heading4 color='#121212'>
                  <FormattedMessage id='shop.account.heading3a' />
                </Heading4>

                {treeStillInWorks ? (
                  <Paragraph {...InfoTextStyle}>
                    <FormattedHTMLMessage id='shop.account.heading3b' />
                  </Paragraph>
                ) : summary.trees.length ? (
                  <>
                    <Paragraph {...InfoTextStyle}>
                      <FormattedMessage id='shop.account.tree.more' />
                    </Paragraph>
                    
                    <Box
                      flexDirection='column'
                      {...BoxStyle}
                    >
                      {summary.trees.map((row, i) => (
                        <Grid
                          key={i}
                          {...GridStyle}
                        >
                          <Flex {...FlexStyle}>
                            <Typography>
                              {toDate(row.createdAt)}
                            </Typography>
                          </Flex>
                          <Flex {...FlexStyle}>
                            <Tag 
                              bg={row.used ? notUsedColor : 'rgba(0, 0, 0, 0.2)'} 
                              opacity={row.used ? 0.7 : 1}
                              {...TagStyle}
                              textAlign='center'
                            >{row.title}</Tag>

                            {row.secondNick && (
                              <Link
                                ml='1rem'
                                color='rgba(244, 244, 246, 0.7)'
                                fontSize='1.4rem'
                                lineHeight='1.7rem'
                                borderBottom='1px solid'
                                v='underline'
                              >
                                {row.secondNick}
                              </Link>
                            )}
                          </Flex>

                          <Flex {...FlexStyle}>
                            <Typography color={`rgba(244, 244, 246, ${row.used ? '0.7' : '1'})`}>
                              <FormattedMessage id={`shop.account.${row.used ? 'used' : 'notUsed'}`} />
                            </Typography>
                          </Flex>

                          <Flex
                            {...FlexStyle}
                            paddingY={row.used ? '0.1rem' : FlexStyle.paddingY}
                          >
                            {!row.used && (
                              <Link
                                v='underline'
                                color={notUsedColor}
                                borderColor={notUsedColor}
                                href={`/api/v1/shop/pdf/${row.id}`}
                                target='_blank'
                              >
                                <FormattedMessage id='shop.account.tree.activate' />
                              </Link>
                            )}
                          </Flex>
                        </Grid>
                      ))}
                    </Box>
                  </>
                ) : (
                  <Paragraph {...InfoTextStyle}>
                    <FormattedMessage id='shop.account.tree.noTree' />
                  </Paragraph>
                )
                }
                
                {!eticiaPlus && (
                  <Img
                    src='/img/plus@2x.png'
                    width='33rem'
                    mt='5rem'
                    maxWidth='100%'
                    mx='auto'
                    alt=''
                  />
                )}
              </>
            ),
            fetching: () => <LoadingSpinnerLocal />
          })()}
        </Fetch>

        <Flex justifyContent='center' mt='8rem' pb='5rem'>
          <ReactLink to={createUrl('app.shop')}>
            <SecondaryButton as='a' borderColor='textGreen' >
              <FormattedMessage id='shop.account.button' />
            </SecondaryButton>
          </ReactLink>
        </Flex>
      </Container>
    </>
  );
};

export default Account;

export const toDate = (time: number) =>
  new Date(time * 1000).toLocaleDateString('cs', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

const BoxStyle = {
  display: 'flex',
  marginTop: { _:'1rem', md: '4rem' },
  width: '100%',
  maxWidth: '85rem',
  mx: 'auto',
  style: { borderSpacing: '0 1px' },
};

const GridStyle = {
  columns: { 
    _: '1fr', 
    sm: '11rem 1fr 8rem 18rem',
    md: '12rem 1fr 8rem 18rem',
  },
  rows: 'auto',
  backgroundColor: 'rgba(11, 12, 28, 0.2)',
  marginBottom: { _: '1rem', sm: '0.2rem', md: '0.5rem' },
  borderRadius: '1.2rem',
  paddingY: '1rem',
  paddingX: { _: '0', md: '2rem' },
};

const FlexStyle = {
  justifyContent: 'center',
  alignItems: 'center',
  paddingY: { _: '1rem', sm: '0.5rem' },
  paddingX: { _: '2rem', sm: '0' },   
};

const TagStyle = {
  padding: '8px 16px !important',
  border: '1px solid rgba(255, 255, 255, 0.3)',
  borderRadius: '9999px',
  lineHeight: '1',
  marginX: 'auto',
  whiteSpace: { _:'normal' },
  // maxWidth: { _: '20rem' },
};

const InfoTextStyle = {
  mt: '2rem',
  mx: 'auto',
  maxWidth: { _: '30rem', md: '65rem' },
  color: 'white',
};
