import Helmet from 'react-helmet';
import * as React from 'react';
import wrapIntl, { IWrapIntl } from '../wrapIntl';
import OGTags from '../OGTags/index';
import { RouteComponentProps, withRouter } from 'react-router';
import { useEffect } from 'react';

const HeadBase: React.FC<IWrapIntl & RouteComponentProps> = ({ children, formatMsg, location }) => {
  return (
    <>
      <Helmet>
        <title>
          {location.pathname === '/cs/hodnoty-pro-dva' ? formatMsg('head.comparison.title') : formatMsg('head.title')}
        </title>
        <meta
          name='description'
          content={
            location.pathname === '/cs/hodnoty-pro-dva'
              ? formatMsg('head.comparison.description')
              : formatMsg('head.description')
          }
        />
        <meta name='msapplication-TileColor' content='#5674B4' />
        <meta name='theme-color' content='#5674B4' />
        <meta name='twitter:card' content='summary_large_image' />
        <link rel='apple-touch-icon' sizes='180x180' href='/favicon/apple-touch-icon.png' />
        <link rel='icon' type='image/png' sizes='32x32' href='/favicon/favicon-32x32.png' />
        <link rel='icon' type='image/png' sizes='16x16' href='/favicon/favicon-16x16.png' />
        <link rel='mask-icon' href='/favicon/safari-pinned-tab.svg' color='#5674B4' />
        {children}
      </Helmet>
      {location.pathname.lastIndexOf('hodnotove-porovnani') || location.pathname.lastIndexOf('hodnoty-pro-dva') ? (
        <OGTags
          title={formatMsg('head.comparison.title')}
          description={formatMsg('head.comparison.description')}
          url={'https://www.hodnotyprodva.cz'}
          image={'/img/og-image-porovnani.png'}
          siteName={'website'}
          type={'website'}
          locale={'cs_CZ'}
        />
      ) : (
        <OGTags
          title={formatMsg('head.title')}
          description={formatMsg('head.description')}
          url={'https://www.eticia21.com'}
          image={'/img/og-image.png'}
          siteName={'website'}
          type={'website'}
          locale={'cs_CZ'}
        />
      )}
    </>
  );
};

export default wrapIntl<{}>(withRouter(HeadBase));
