import { IPage } from '../ValuesForTwoResult/data';
import * as React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import TogetherPrimalAgreement from './TogetherPrimalAgreement';
import TogetherDisagreements from './TogetherDisagreements';
import TogetherCharacterMatch from './TogetherCharacterMatch';
import TogetherPracticalMatch from './TogetherPracticalMatch';

export const togetherConfig: IPage = {
  label: 'results.partners.menu.together',
  name: 'together',
  subpage: [
    {
      label: 'results.partners.together.agreement',
      name: 'agreement',
      generate: (data, values) => (
        <TogetherPrimalAgreement
          valueTestCompare={data.valueTestCompares}
          mapValues={values}
          values={data.valuesMerged}
        />
      ),
    },
    {
      label: 'results.partners.together.disagreement',
      name: 'disagreement',
      header: () => (
        <div className='test-type'>
          <FormattedHTMLMessage id='results.partners.together.disagreement.header' />
        </div>
      ),
      generate: (data, values) => (
        <TogetherDisagreements
          valueTestCompare={data.valueTestCompares}
          mapValues={values}
          values={data.valuesMerged}
          allLifeValues={data.valueTestResults[Object.keys(data.valueTestResults)[0]].allLifeValues}
          valueTestResults={data.valueTestResults}
          profiles={data.profiles}
        />
      ),
    },
    {
      label: 'results.partners.together.character',
      name: 'character',
      generate: data => (
        <TogetherCharacterMatch
          characterMatch={data.valueTestCompares}
          profiles={data.profiles}
          graphCharacters={data.valueTestResults}
        />
      ),
    },
    {
      label: 'results.partners.together.practic',
      name: 'practic',
      generate: data => (
        <TogetherPracticalMatch
          practicalMatch={data.valueTestCompares}
          profiles={data.profiles}
          graphCharacters={data.valueTestResults}
        />
      ),
    },
  ],
};
/*
    value test compares:
        valueMatchAList: spolecne TOP
        valueMatchBList: dulezite hodnoty
        valueMatchCList: nepouzivame zatim
        valueMatchDList: neshody
 */
