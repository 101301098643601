import { SUBMIT_SHIRT } from 'config/apiRoutes';
import { jsonFetch } from 'utils/fetch';

type ShirtData = IStep1Data & IStep2Data & { currency: string };

export interface IStep1Data {
  // type: string;
  color: string;
  size: string;
  price: string;
}

export interface IStep2Data {
  name: string;
  surname: string;
  email: string;
  street: string;
  zip: string;
  city: string;
}

export const submitShirt = (data: ShirtData) => jsonFetch(SUBMIT_SHIRT, { method: 'POST', body: JSON.stringify(data) });
