import * as React from 'react';
import { FC, ReactNode, useState } from 'react';
import { BlueTheme } from 'components/theme/colors';
import { Modal, ModalProps, Heading3, Tag, Typography, Box, Flex, TypographyProps, TagProps, BoxProps } from '@xcorejs/ui';
import SVGarrow from '../../../../../components/atoms/SVGIcons/SVGarrow';
import CircleGroup, { CircleGroupProps } from 'components/pages/App/Statistics/components/CircleGroup';

export interface StatisticsModalProps {
  _modal?: ModalProps;
  colorScheme?: 'dark' | 'blue' | string;
  content?: {
      heading: string | ReactNode;
      _heading?: TypographyProps;
      tag: string | ReactNode;
      _tag?: TagProps;
      text: string | ReactNode;
      circle?: CircleGroupProps;
      box1?: ReactNode;
      text2?: string | ReactNode;
      box2?: ReactNode;
  }[];
}

const StatisticsModal: FC<StatisticsModalProps> = (
  {
    _modal,
    colorScheme,
    content
  }
) => {
  const [contentState, setContentState] = useState(0);

  return (
    <Modal
      background={colorScheme === 'dark' ?
        'radial-gradient(circle, rgba(108,147,191,1) 0%, rgba(68,103,142,1) 100%), radial-gradient(circle, rgba(50,119,221,1) 0%, rgba(12,70,163,1) 100%)'
        : colorScheme ==='blue' ? `${BlueTheme.primary}` : colorScheme}
      padding='3rem 2rem 3rem'
      maxWidth={{ _:'34.5rem', md: '45rem' }}
      mx='auto'
      alignItems='center'
      _close={{
        right: '1rem',
        top: '1rem'
      }}
      {..._modal}
    >
      {content && (
        <>
          <Heading3 {...content[contentState]._heading}>
            {content[contentState].heading}
          </Heading3>

          <Tag mt='2rem' {...content[contentState]._tag}>
            {content[contentState].tag}
          </Tag>

          <Typography mt='0.5rem' color='rgba(255, 255, 255, 0.7)' fontSize='1.4rem' lineHeight='1.8rem' maxWidth='30rem'>
            {content[contentState].text}
          </Typography>

          <Flex alignItems='flex-start' mt='3rem' width='100%' justifyContent='space-between'>
            <Flex
              cursor='pointer'
              opacity={0.7}
              _hover={{
                opacity: 1
              }}
              transition='opacity 300ms'
              width='2.7rem'
              mt='3.7rem'
              flex='0 0 auto'
            >
              {contentState !== 0 && (
                <Box onClick={() => setContentState(contentState - 1)}>
                  <SVGarrow />
                </Box>
              )}
            </Flex>

            <Box>
              {content[contentState].box1}

              {content[contentState].circle && (
                <CircleGroup
                  circleSize='large'
                  {...content[contentState].circle}
                />
              )}
            </Box>

            <Flex
              cursor='pointer'
              opacity={0.7}
              _hover={{
                opacity: 1
              }}
              transform='rotate(180deg)'
              transition='opacity 300ms'
              width='2.7rem'
              mt='3.7rem'
              flex='0 0 auto'
            >
              {contentState !== content.length - 1 && (
                <Box onClick={() => setContentState(contentState + 1)}>
                  <SVGarrow />
                </Box>
              )}
            </Flex>
          </Flex>

          {content[contentState].text2 && (
            <Typography mt='2rem' fontSize='1.3rem' lineHeight='1.5rem' color='rgba(255, 255, 255, 0.5)'>
              {content[contentState].text2}
            </Typography>
          )}

          <Box width='100%'>
            {content[contentState].box2}
          </Box>
        </>
      )}
    </Modal>
  );
};

export default StatisticsModal;
