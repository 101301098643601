import ValueCardPopup from 'components/organisms/InfoSlides/ValuePage/ValueCard/ValueCard';
import * as React from 'react';
import { Fragment } from 'react';
import { ILifeValue } from '../../../../../../store/Entities/LifeValues/index';
import { StyledCard, StyledValueCard } from './styles';

export enum ValueCardState {
  def = 'def',
  draged = 'draged',
  selected = 'selected',
  disabled = 'disabled',
}

interface IValueCard {
  state: ValueCardState;
  lifeValue: ILifeValue;
  openClick?: (id: string) => any;
  preventClick?: (arg: boolean) => any;
}

interface IValueCardState {
  detail: string | undefined;
  primary: boolean;
}


class ValueCard extends React.Component<IValueCard> {
  public state: IValueCardState = { detail: undefined, primary: true };

  public render() {
    const { lifeValue, openClick, preventClick, state } = this.props;

    return (
      <Fragment>
        <StyledValueCard className={state}>
          <StyledCard>
            {/* TODO: ValueCardPopup */}
            {state === ValueCardState.def && !lifeValue.infoCardHidden && (
              <img
                className='rotate-ico'
                src={'/img/ico/rotate.svg'}
                alt='rotate ico'
                onClick={e => {
                  e.preventDefault();
                  open !== undefined && this.setState({ detail: lifeValue.id, primary: lifeValue.type === 'main' });
                }}
                onTouchStart={() => preventClick !== undefined && preventClick(true)}
                onTouchEnd={e => {
                  e.preventDefault();
                  open !== undefined && this.setState({ detail: lifeValue.id, primary: lifeValue.type === 'main' });
                  // preventClick !== undefined && preventClick(false); //byl tu bug, při zavření ValueCardPopup se automaticky vybrala položka
                }}
              />
            )}
           
            <span className='value-img'>
              <img src={lifeValue.icon} alt={lifeValue.name} />
            </span>

            <h2 className='value-name'>{lifeValue.name}</h2>
          </StyledCard>
        </StyledValueCard>

        {this.state.detail && (
          <Fragment>
            <ValueCardPopup
              value={this.state.detail}
              primary={this.state.primary}
              onClose={() => {
                this.setState({ detail: null });
                preventClick !== undefined && preventClick(false);
              }} />
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export default ValueCard;
