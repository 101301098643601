import styled from 'styled-components';
import { textGreen } from '../../theme/colors';
import { mediaSmDefault } from '../../theme/grid';

export const StyledCounterUp = styled.div`
  padding: 0 1.5rem;
  margin-top: 1rem;
  text-align: center;
  font-size: 1.6rem;
  color: #fff;

  strong {
    color: ${textGreen};
    font-size: 2.2rem;
  }

  ${mediaSmDefault} {
    padding: 0;
    margin-top: 2rem;
    font-size: 2rem;
    line-height: 3rem;
  }
`;
