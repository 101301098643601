import useIframeModalLinkClickHandler from 'components/atoms/hooks/useIframeModalLinkClickHandler';
import Navigation from 'components/atoms/HPNav';
import Find from 'components/organisms/EticiaPlus/Find';
import EticiaPlusFooter from 'components/organisms/EticiaPlus/Footer';
import Intro from 'components/organisms/EticiaPlus/Intro';
import Ladders from 'components/organisms/EticiaPlus/Ladders';
import Shop from 'components/organisms/EticiaPlus/Shop';
import { StyledEticiaPlus } from 'components/organisms/EticiaPlus/styles';
import Table from 'components/organisms/EticiaPlus/Table';
import React, { FC } from 'react';

const EticiaPlusPresenting: FC = () => {
  useIframeModalLinkClickHandler([]);
  
  return (
    <>
      <Navigation />

      <StyledEticiaPlus>
        <Intro />
        <Shop gift />
        <Find />
        <Table />
        <Ladders />
        <EticiaPlusFooter />
      </StyledEticiaPlus>
    </>
  );
};

export default EticiaPlusPresenting;
