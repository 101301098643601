import { Flex } from '@xcorejs/ui';
import LoadingSpinnerLocal from 'components/atoms/Animations/LoadingSpinnerLocal';
import { useUser } from 'components/atoms/hooks/useUser';
import CircleGroup from 'components/pages/App/Statistics/components/CircleGroup';
import StatisticsCard from 'components/pages/App/Statistics/components/StatisticsCard';
import TopValuesModal from 'components/pages/App/Statistics/modals/TopValuesModal';
import React, { FC, Fragment } from 'react';
import { useFetchStatGroupMatchTest } from 'utils/useQuery/queryFetchHelpers';

interface SimilarValuesProps {
  resultId: string;
}

const SimilarValues: FC<SimilarValuesProps> = ({ resultId }) => {
  const user = useUser();
  const statisticsFetch = useFetchStatGroupMatchTest(resultId);

  if (statisticsFetch.isLoading)
    return <LoadingSpinnerLocal />;
  
  if (statisticsFetch.isError) {
    console.log(statisticsFetch.error);
    return <Fragment></Fragment>;
  }

  const statistics = statisticsFetch.data!;

  if (statistics.length === 0)
    return <Fragment></Fragment>;
  
  const filtered = statistics.filter(s => s.ageGroup);
  const mostCommon = filtered.reduce((max, stat) => max.value > stat.value ? max : stat);
  const leastCommon = filtered.reduce((max, stat) => max.value < stat.value ? max : stat);
  
  return (
    <StatisticsCard
      heading='Kdo má podobné hodnoty jako ty?'
      _heading={{ color: 'yellow', fontWeight: 800 }}
      paragraph='Češi stavy tedy ať i světové slona telefony končetinách S obří, nám přirovnává severní příjezdu'
      noSubheading
      modalComponent={() => <TopValuesModal resultId={resultId} user={user} />}
      onlyPlusOrGame
      minGameLevelToSee={99}
    >
      <Flex alignItems='flex-start' justifyContent='space-evenly' mt='2rem'>
        <CircleGroup
          mostCommon
          name={mostCommon.title.trim() + ', ' + mostCommon.perex.trim()}
          colorScheme='green'
          img={mostCommon.image}
          onlyPlusOrGame
        />
        <CircleGroup
          leastCommon
          name={leastCommon.title.trim() + ', ' + leastCommon.perex.trim()}
          colorScheme='gray'
          img={leastCommon.image}
          onlyDemo
        />
      </Flex>
    </StatisticsCard>
  );
};
 
export default SimilarValues;