import SwitchMembershipButtons, { useTextFreePlusForever } from 'components/atoms/hooks/SwitchMembershipButtons';
import useIframeModalLinkClickHandler from 'components/atoms/hooks/useIframeModalLinkClickHandler';
import { useScrollToAnchor } from 'components/atoms/hooks/useScrollToAnchor';
import NavItem from 'components/atoms/Nav/NavItem';
import Subnav from 'components/atoms/Subnav';
import withUser, { IWithUser } from 'components/molecules/withRedux/withUser';
import Coffee from 'components/organisms/EticiaPlus/Coffee';
import ExtendShop from 'components/organisms/EticiaPlus/ExtendShop';
import Find from 'components/organisms/EticiaPlus/Find';
import EticiaPlusFooter from 'components/organisms/EticiaPlus/Footer';
import GiftEticia from 'components/organisms/EticiaPlus/GiftEticia';
import Intro from 'components/organisms/EticiaPlus/Intro';
import Ladders from 'components/organisms/EticiaPlus/Ladders';
import Membership from 'components/organisms/EticiaPlus/Membership';
import Shop from 'components/organisms/EticiaPlus/Shop';
import { StyledEticiaPlus } from 'components/organisms/EticiaPlus/styles';
import Table from 'components/organisms/EticiaPlus/Table';
import ThreeLadders from 'components/organisms/EticiaPlus/ThreeLadders';
import { createUrl } from 'components/pages/routes';
import React, { FC } from 'react';

const EticiaPlusPresenting: FC<IWithUser> = ({ user }) => {
  const { isPlus, isForever, ...testButtons } = useTextFreePlusForever(user);
  
  const baseUrl = createUrl('app');
  // const [isPlus, setIsPlus] = React.useState<boolean>(user.eticiaPlus);
  // const [isForever, setIsForever] = React.useState<boolean>(user.eticiaPlusForever);
  useScrollToAnchor();
  useIframeModalLinkClickHandler([isPlus, isForever]);

  return (
    <>
      <Subnav>
        <NavItem to={baseUrl} label={'eticiaPlus.heading'} exact />
      </Subnav>

      {/* TODO:
       Jenom na přehazování free a plus, vyhodit po testování */}
      <SwitchMembershipButtons 
        isPlus={isPlus}
        isForever={isForever}
        {...testButtons}
      />

      <StyledEticiaPlus>
        {isPlus ? 
          isForever ? (
            <>
              <Membership />
              <Intro forever />
              <ThreeLadders />
              <GiftEticia linkTo='#shop' purpleBackground />
              {/* <Find /> */}
              <Table />
              <Ladders user={user} />
              <Shop gift inApp />
              <EticiaPlusFooter plus />
            </>
          ) : (
            <>
              <Membership />
              <Intro plus />
              <ExtendShop />
              <ThreeLadders />
              <GiftEticia linkTo='#shop' purpleBackground />
              {/* <Find /> */}
              <Table />
              <Ladders user={user} />
              <Shop gift inApp />
              <EticiaPlusFooter plus />
            </>
          ) : (
            <>
              {/* <Intro free /> */}
              <Coffee />
              <Shop free inApp />
              <ThreeLadders noPadding free />
              <GiftEticia linkTo={createUrl('app.shop')} purpleBackground />
              <Find />
              <Table />
              <Ladders user={user} />
              <EticiaPlusFooter free />
            </>
          )}
      </StyledEticiaPlus>
    </>
  );
};

export default withUser(EticiaPlusPresenting);
