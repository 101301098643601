import * as React from 'react';
import { createUrl } from '../../routes';
import Subnav from '../../../atoms/Subnav';
import NavItem from '../../../atoms/Nav/NavItem';
import { Route } from 'react-router';
import Activity from './Activity/component';
import JournalJournal from './Journal/index';

const Journal:React.FC = () => {
  const baseUrl = createUrl('app.journal');

  return ( 
    <>
      <Subnav>
        <NavItem to={baseUrl} label={'journal.menu.results'} exact />
        <NavItem to={baseUrl + '/aktivita'} label={'journal.menu.activity'} />
      </Subnav>

      <div>
        <Route path={baseUrl} exact={true} render={() => <JournalJournal />} />
        <Route path={baseUrl + '/aktivita'} exact={true} render={() => <Activity />} />
      </div>
    </> 
  );
};
 
export default Journal;
