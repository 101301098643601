import { Box, Flex, Heading1, Heading2, Img, Link as UILink, List, ListItem, Paragraph, Typography, useModal } from '@xcorejs/ui';
import { GameContext } from 'components/App';
import LoadingSpinnerLocal from 'components/atoms/Animations/LoadingSpinnerLocal';
import { KidsTag } from 'components/atoms/Logo';
import EticiaPlusModalNew from 'components/components/modals/EticiaPlusModalNew';
import KidsModal from 'components/components/modals/KidsModal';
import { changeUser, changeUserData } from 'components/pages/App/Profile/Settings/actions';
import { EticonText } from 'components/pages/App/Result/ValueTestResult/styles';
import { ITestTemplate } from 'components/pages/Sandbox/TestFactory';
import { ColoredThemes } from 'components/theme/colors';
import { TEST_CURRENTLY_RUNNING, TEST_TEMPLATES } from 'config/apiRoutes';
import * as React from 'react';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { useQueryClient } from 'react-query';
import Fade from 'react-reveal/Fade';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import { Col, Grid, Row } from 'react-styled-flexboxgrid';
import { IUserInfo } from 'store/User';
import { IFetchException } from 'utils/exceptions';
import { _switch } from 'utils/option';
import { useFetchShopSummary, useFetchUserProfile } from 'utils/useQuery/queryFetchHelpers';
import { queryKeysAll } from 'utils/useQuery/queryKeys';
import LoadingSpinner from '../../../atoms/Animations/LoadingSpinner';
import Fetch from '../../../atoms/Fetch';
import withFetch, { IWithFetch } from '../../../atoms/Fetch/withFetch';
import wrapIntl, { IWrapIntl } from '../../../atoms/wrapIntl';
import { PrimaryButton } from '../../../components/Button';
import { createUrl } from '../../routes';
import { startValueTest } from './data';

interface ICurrentlyRunningTest {
  id: string;
  type: string;
}

const TestIntro: React.FC<IWrapIntl & RouteComponentProps<{}> & IWithFetch<ICurrentlyRunningTest[]>> = props => {
  const query = new URLSearchParams(useLocation().search).get('t');
  const redirect = new URLSearchParams(useLocation().search).get('redirect');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // console.log(props.result);

    if (redirect !== '0' && props.state === 'success') {
      const running = props.result.find(x => x.type === 'simple');
      if (running) {
        props.history.push(createUrl('app.game', { id: running.id }));
      }
    }
  });

  return !loading ?
    _switch({
      success: () => (
        <Box>
          {query === 'welcome' ? (
            <TestIntroContent t={welcomeIntro as ITestTemplate} fake {...props} />
          ) : (
            <Fetch url={TEST_TEMPLATES}>
              {_switch({
                success: (templates: ITestTemplate[]) => (
                  <Box>
                    {templates.filter(t => t.id === query).length > 0 ?
                      templates.filter(t => t.id === query).map(t => (
                        <TestIntroContent t={t} key={t.id} {...props} />
                      ))
                      : (
                        <Typography>
                          Test neexistuje
                        </Typography>
                      )}
                  </Box>
                )
                ,
                fetching: () => <LoadingSpinner />,
              })()}
            </Fetch>
          )}
        </Box>
      ),
      fetching: () => <LoadingSpinnerLocal />,
    })()(props.state)()
    : (
      <LoadingSpinner />
    );
};

export default withFetch<{}>(TEST_CURRENTLY_RUNNING)(
  wrapIntl<IWithFetch<ICurrentlyRunningTest[]>>(withRouter(TestIntro)),
);

interface TestIntroContentProps {
  t: ITestTemplate;
  fake?: boolean;
}
const TestIntroContent: FC<TestIntroContentProps & RouteComponentProps<{}> & IWithFetch<ICurrentlyRunningTest[]>> = (
  {
    t,
    fake,
    ...props
  }
) => {
  const [openEticiaPlusModal] = useModal(EticiaPlusModalNew);

  const runTest = (id: string) =>
    startValueTest(id, '')
      .then(result => props.history.push(createUrl('app.game', { id: result.id })))
      .catch((ex: IFetchException) =>
        // TODO better message
        ex.data.json().then((error: any) => {
          console.log('AA');
          openEticiaPlusModal({});
        }),
      );

  const [openKidsModal] = useModal(KidsModal);
  const [kids, setKids] = useState(false);
  const [user, setUser] = useState<IUserInfo | undefined>(undefined);
  const { eticiaPlus } = useContext(GameContext);

  const queryClient = useQueryClient();
  const userQuery = useFetchUserProfile();
  const shopQuery = useFetchShopSummary();

  const summary = useMemo(() => shopQuery.isSuccess ? shopQuery.data : undefined
    , [shopQuery.data, shopQuery.isSuccess]);

  useEffect(() => {
    if (userQuery.isSuccess) {
      setUser(userQuery.data);
    }
  }, [userQuery.data, userQuery.isSuccess]);

  useEffect(() => {
    user && user.child && setKids(true);
  }, [user]);

  useEffect(() => {
    user && changeUserData(
      changeUser(user, kids, 'child'),
      () => {
        queryKeysAll.forEach(k => queryClient.invalidateQueries([k]));
      },
      () => console.log('fail'),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kids]);
  
  return (
    <Box
      py={{ _: '3rem', sm: '8rem' }}
      minHeight='100vh'
      background={ColoredThemes[t.theme].primary}
    >
      <Fade bottom ssrReveal duration={1000} distance='5rem'>
        <Grid>
          <Row>
            <Col xs={12} md={5}>
              <Flex alignItems='center' transform={{ _: 'none', lg: 'translateX(-9.6rem)' }} flexDirection={{ _: 'column', lg: 'row' }}>
                <Flex width='8rem' height='8rem' mr={{ lg: '1.6rem', sm: '0rem', xs: '0rem' }}  alignItems='center' justifyContent='center'>
                  {t.info.introIcon && <Img src={t.info.introIcon} alt='' />}
                </Flex>
                <Heading2 mt={{ _: '1.6rem', lg: 0 }} position='relative' display='flex' alignItems='center'>
                  {t.name}

                  {kids && (
                    <KidsTag
                      ml='2rem'
                      position='absolute'
                      left='100%'
                      top='50%'
                      transform='translateY(-50%)'
                    />
                  )}
                </Heading2>
              </Flex>

              {t.info.introDescription && (
                <EticonText>
                  <Paragraph
                    mt={{ _: '1rem', lg: 0 }}
                    color='rgba(244, 244, 246, 0.8)'
                    textAlign={{ _: 'center', md: 'left' }}
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: t.info.introDescription }}
                    />
                  </Paragraph>
                </EticonText>
              )}
              {t.info.introMainQuestionDescription && (
                <Paragraph color='rgba(244, 244, 246, 0.8)' mt='4rem' fontSize='1.8rem' lineHeight='2.7rem' textAlign={{ _: 'center', md: 'left' }}>
                  {t.info.introMainQuestionDescription}
                </Paragraph>
              )}

              <Heading1 fontSize='2.8rem' lineHeight='3.9rem' mt='2.2rem' mb='2.2rem'>
                {t.info.introMainQuestionText}
              </Heading1>

              {t.info.introBenefits && (
                <List
                  mt='2rem'
                  _bullet={{
                    color: '#fff',
                    content: 'url(/img/check_yellow.svg)',
                  }}
                >
                  {t.info.introBenefits.split(/\n/).map(benefit => (
                    <ListItem key={benefit} mt='2rem'>
                      <Paragraph color='#fff' textAlign='left'>
                        {benefit}
                      </Paragraph>
                    </ListItem>
                  ))}
                </List>
              )}

              <Flex alignItems='center' flexDirection='column' display={{ _: 'flex', md: 'none' }}>
                {t.info.introImage && (
                  <Img src={t.info.introImage} alt='' mt={{ _: '3rem', lg: 0 }} />
                )}

                {t.info.introLinkUrl && (
                  <UILink v='underline' mt='2rem' href={t.info.introLinkUrl} target='_blank'>
                    {t.info.introLinkText}
                  </UILink>
                )}
              </Flex>

              {shopQuery.isFetching && <LoadingSpinnerLocal />}
              {summary && (
                <>
                  <Flex mt='6rem' justifyContent={{ _: 'center', sm: 'flex-start' }}>
                    <Flex display='inline-flex' justifyContent='center' alignItems='center' flexDirection={{ _: 'column', sm: 'row' }}>
                      <PrimaryButton
                        leftElement={t.info.introBtnIcon && <Img src={t.info.introBtnIcon} mr='1.5rem' alt='' />}
                        onClick={() => runTest(t.id)}
                        plusTag={!fake && !t.free && !eticiaPlus && summary.availableMagicTokenCount <= 1}
                      >
                        {t.info.introBtnText}
                      </PrimaryButton>

                      {!fake && !t.free && !eticiaPlus && summary.availableMagicTokenCount <= 1 && (
                        <Paragraph
                          textAlign={{ _: 'center', sm: 'left' }}
                          color='yellowWhite'
                          ml={{ _: '0', sm: '2rem' }}
                          mt={{ _: '2rem', sm: '0' }}
                        >
                          <FormattedHTMLMessage id='testIntro.eticiaPlus' />
                        </Paragraph>
                      )}
                    </Flex>
                  </Flex>

                  {!fake && !t.free && (
                    <Paragraph
                      textAlign={{ _: 'center', sm: 'left' }}
                      color='yellowWhite'
                      mt='2rem'
                    >
                          K dispozici máš <strong>{summary.availableMagicTokenCount} volných žebříčků</strong><br />

                      <Link to={createUrl('app.shop')}>
                        <UILink as='span' fontFamily='OliMedium' color='#91bee7' v='underline'>
                              Přikoupit
                        </UILink>
                      </Link>
                    </Paragraph>
                  )}
                </>
              )}

              {fake && !kids && (
                <Box onClick={() => openKidsModal({ onAgreement: () => setKids(true) })} mt='6rem'>
                  <Flex alignItems='center'>
                    <Img src='/img/child_16x16_sun.png' minWidth='1.6rem' minHeight='1.6rem' alt='' mr='1rem' />

                    <UILink v='underline' color='accentGreen' borderColor='accentGreen'>
                      Dětská verze žebříčku
                    </UILink>
                  </Flex>

                  <Paragraph fontSize='1.3rem' lineHeight='1.8rem' mt='1rem' maxWidth='43rem' textAlign={{ sm: 'left' }}>
                    Eticia je tu i pro dětské uživatele. Hodnotový žebříček pro děti je jen lehce přizpůsobený
                    jejich hodnotovým prioritám.
                  </Paragraph>
                </Box>
              )}
            </Col>

            <Col xs={12} md={4} mdOffset={1}>
              <Flex alignItems='center' flexDirection='column' display={{ _: 'none', md: 'flex' }}>
                {t.info.introImage && (
                  <Img src={t.info.introImage} alt='' mt={{ _: '3rem', md: 0 }} />
                )}

                {t.info.introLinkUrl && (
                  <UILink v='underline' mt='2rem' href={t.info.introLinkUrl} target='_blank'>
                    {t.info.introLinkText}
                  </UILink>
                )}
              </Flex>
            </Col>
          </Row>
        </Grid>
      </Fade>
    </Box>
  );
};

const welcomeIntro: Partial<ITestTemplate> = {
  'id': 'fa385f22-7954-4029-8e18-06dc3b92f400',
  'name': 'Hodnotový žebříček Eticia',
  'testVersionId': 'bf88412e-a95d-44e8-a27e-4db5758d0bec',
  'theme': 'blue',
  // @ts-ignore
  'info': {
    'introIcon': '/img/my-values2.png',
    'introDescription': 'Tvorba žebříčku ti zabere jen pár minut, najdi si na to volnou a nerušenou chvilku.\n',
    'introBenefits': 'Z šesti zobrazených hodnot vyber jako první vždy tu "nej"\r\nA ostatní seřaď podle toho, jak důležité se ti zdají být\r\nSoustřeď se, ale příliš si s tím nelámej hlavu, zvol prostě ty hodnoty, které tě právě teď přitahují nejvíc',
    'introBtnText': 'Začít teď',
    'introImage': '/img/my-values.png',
    'introLinkUrl': 'https://eticia21.wnh.cz/cs/presenting?id=31',
    'introLinkText': 'Více o životních hodnotách',
    'introAdditionalNote': null,
  }
};
