import { Flex } from '@xcorejs/ui';
import CreateValuesButton from 'components/components/EticiameterComponents/CreateValuesButton';
import Contacts from 'components/molecules/Contacts';
import React from 'react';

const Footer = () => {
  return (
    <Flex flexDirection='column' alignItems='center' paddingY={{ _: '4rem' }} backgroundColor='#0a1119'>
      <CreateValuesButton marginBottom={{ _: '-2rem' }} />

      <Contacts theme='dark' />
    </Flex>
  );
};
 
export default Footer;