import { useMutation, useQueryClient } from 'react-query';
import { jsonFetch } from 'utils/fetch';

type useMutationProps = {
  path: string;
  body: BodyInit | null;
  onSuccess?: () => void;
  queryKey: unknown[];
  method?: 'POST' | 'PUT' | 'DELETE';
};

export const useMutationFetch = ({ path, onSuccess, method = 'POST', body, queryKey }: useMutationProps) => {
  const options = {
    method,
    body
  };

  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: () => jsonFetch(path, options),
    onSuccess: () => {
      onSuccess && onSuccess();

      queryClient.invalidateQueries([...queryKey, path]);
    }
  });
};
