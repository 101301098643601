import * as React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import withPopups, { IWithPopups } from '../../../molecules/withRedux/withPopups';
import { PopupComponent } from '../../../../store/Popup';
import { ClickableText } from './data';
import ExternalSVG from '../../ExternalSVG';
import { ReactElement } from 'react';
import { useRequest } from '../../RequestContext/index';

interface Props {
  img?: string;
  svg?: ReactElement<{}>;
  name: string;
  texts: number;
  label?: string;
  nextPopup?: PopupComponent;
}

const WithStepsPopup: React.FC<Props & IWithPopups> = ({ img, svg, name, texts }) => {
  const { cookies } = useRequest();
  const step = cookies.faqStep;

  return (
    <div>
      {img && (
        <span key='img'>
          <ExternalSVG src={img} />
        </span>
      )}
      {svg && (
        <span key='img'>
          <div className={'external-svg'}>{svg}</div>
        </span>
      )}
      <h2>
        <FormattedMessage id={'faq.' + name + '.title'} />
      </h2>

      <h3>
        <FormattedHTMLMessage id={'faq.' + name + '.subtitle.' + step} />
      </h3>

      <p>
        <FormattedHTMLMessage id={'faq.' + name + '.text.' + step} />
      </p>

      {Array(texts)
        .fill(null)
        .map((_, index) => (
          <ClickableText key={index} name={name} index={index} step={step} />
        ))}
    </div>
  );
};

export default withPopups(WithStepsPopup);
