import styled from 'styled-components';
import { darkBaseHEX, grayFooter, yellow, whiteFade, KidsTheme } from '../../theme/colors';
import { Fox, OliLight } from '../../theme/fonts';
import { mediaSmDefault } from '../../theme/grid';

const StyledNavigationMenu = styled.ul`
  &.bottom {
    margin-top: 4rem;
    
    svg {
      display: none;
     
      ${mediaSmDefault} {
        display: inline-block;
      }
    }

    .bottom-item--white,
    .bottom-item--blue,
    .write-us {
      transition: color 0.3s, opacity 0.3s;
    }

    .bottom-item--white {
      
      .light-link {
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
      }
    }

    .bottom-item--blue {
      color: ${KidsTheme.primary}b2;
      cursor: pointer;

      &:hover {
        color: ${KidsTheme.primary};
      }
    }

    
    .write-us {
      color: rgba(62, 143, 116, 0.7);
      cursor: pointer; 
      fontSize: 1.6rem;

      &:hover {
        color: rgba(62, 143, 116, 1);
      }
    }

    li {
      margin-top: 1rem;
      font-size: 1.3rem;

      &:first-child {
        margin-top: 0;
      }
    }
  }
  
  li {
    margin-top: 2rem;

    &:first-child {
      margin-top: 0;
    }

    a {
      display: flex;
      align-items: center;
      color: ${whiteFade};
      font-size: 1.5rem;
      font-family: ${Fox};
      line-height: 2rem;
      transition: color 0.3s;
     

      &:hover {
        color: #fff;

        svg {
          path {
            fill: #fff;
          }
        }
      }

      svg {
        min-width: 3rem;
        max-width: 3rem;
        height: 3rem;
        margin-right: 2rem;

        path {
          fill: ${grayFooter};
          transition: fill 0.3s;
        }
      }
    }

    &.active {
      color: #e7e6cf;
      font-weight: 500;

      svg {
        path {
          fill: ${yellow};
        }
      }
    }
  }
`;

export const MenuNotification = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  width: 2rem;
  margin-left: 1rem;
  border-radius: 50%;
  background-color: ${yellow};
  color: ${darkBaseHEX};
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 0.8rem;
`;

export default StyledNavigationMenu;
