import * as React from 'react';
import { setFetchCallback } from '../../../utils/fetch';
import withUser, { IWithUser } from '../../molecules/withRedux/withUser';
import { isAuthed, IUserState, IUserInfo } from '../../../store/User';
import { isReadable, IRescource, getData } from '../../../utils/rescource';
import { IStringMap } from 'utils';
import { INotification } from 'store/Entities/Notifications';
import Level1 from '../../atoms/Popups/LevelUp/Level1';
import Level2 from '../../atoms/Popups/LevelUp/Level2';
import Level3 from '../../atoms/Popups/LevelUp/Level3';
import Level4 from '../../atoms/Popups/LevelUp/Level4';
import Level5 from '../../atoms/Popups/LevelUp/Level5';
import withPopups, { IWithPopups } from '../../molecules/withRedux/withPopups';
import { RouteComponentProps, withRouter } from 'react-router';
import { createUrl } from '../../pages/routes';

interface IProps {
  notifications: IRescource<IStringMap<INotification>>;
  fetchNotifications: () => unknown;
  fetchUserInfo: () => Promise<IUserInfo>;
  markAsRead: (id: string) => unknown;
}

export const LevelPopups = [Level1, Level2, Level3, Level4, Level5];

class NotificationFetcher extends React.Component<IProps & IWithUser & IWithPopups & RouteComponentProps> {
  public componentDidMount() {
    setFetchCallback(() => {
      if (isAuthed(this.props.rescource.user as IUserState)) {
        this.props.fetchNotifications();
      }
    });
  }

  public componentDidUpdate() {
    if (isAuthed(this.props.rescource.user as IUserState) && isReadable(this.props.notifications)) {
      const notifications = getData(this.props.notifications);
      {/*
      Object.keys(notifications).map(k => {
        if (notifications[k].type === 'LvlUp') {
          if (
            this.props.user.level > 1 ||
            (!this.props.location.pathname.includes(
              createUrl('app.testResult')
                .split('/')
                .slice(0, 5)
                .join('/'),
            ) &&
              !this.props.location.pathname.includes(
                createUrl('app.game')
                  .split('/')
                  .slice(0, 4)
                  .join('/'),
              ) &&
              !this.props.location.pathname.includes(
                createUrl('app.profile')
                  .split('/')
                  .slice(0, 5)
                  .join('/'),
              ))
          ) {
            this.props.fetchUserInfo().then(user => {
              this.props.popups
                .custom(LevelPopups[user.level - 1 < LevelPopups.length ? user.level - 1 : LevelPopups.length - 1])
                .then(() => this.props.markAsRead(notifications[k].id));
            });
          }
        }
      });
      */}
    }
  }

  public render() {
    return React.Children.only(this.props.children);
  }
}

export default withRouter(withPopups(withUser(NotificationFetcher, false)));
