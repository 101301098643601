import { Col, Row } from 'react-styled-flexboxgrid';
import styled, { css } from 'styled-components';

import {
  StyledFakeLinkBorder,
  StyledLinkBorder,
  StyledPrimaryButton,
  StyledPrimaryButtonLink,
  StyledSmallButton,
  StyledSmallButtonLink,
} from '../../../../atoms/Buttons/styles';
import { StyledFormGroup } from '../../../../atoms/Form/styles';
import { StyledCheckboxInput } from '../../../../atoms/FormItems/styles';
import { Heading1, Heading2, Heading3 } from '../../../../atoms/Headings';
import { StyledFormGroupInline } from '../../../../atoms/InlineForm/styles';
import { CustomText, SectionText } from '../../../../atoms/Text';
import { SmallerText } from '../../../../organisms/VisualValuesComparison/style';
import {
  darkBaseHEX,
  darkBaseRGB,
  darkenBlue,
  darkenGreen,
  darkenYellow,
  gray,
  grayFooter,
  primaryBlue,
  primaryGreen,
  white,
  yellow,
} from '../../../../theme/colors';
import { mediaMdDefault, mediaSmDefault } from '../../../../theme/grid';
import { MoreLink } from '../LandingPage/style';
import { PaymentInfoPrice } from './style2';
import { StyledImgSlider } from '../../../../organisms/Slider/styles';

export const PaymentMethod = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 3rem;
  opacity: 0.5;
  color: #fff;
  font-size: 1.4rem;
  line-height: 1.7rem;

  svg {
    margin-left: 2rem;
    min-width: 2.4rem;
    max-width: 2.4rem;
    min-height: 2.4rem;
    max-height: 2.4rem;
  }
`;

export const Table = styled.div`
  display: block;
  margin-top: 2rem;

  ${mediaSmDefault} {
    display: flex;
    flex-direction: column;
  }
`;

export const TableItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
  border-top: 1px solid rgba(${darkBaseRGB}, 0.25);

  &:last-child {
    border-bottom: 1px solid rgba(${darkBaseRGB}, 0.25);
  }

  ${mediaSmDefault} {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 0 1.5rem;
    min-height: 5rem;
  }

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;

    ${mediaSmDefault} {
      flex-direction: row;
      align-items: center;
      margin-bottom: 0;
    }

    svg {
      min-width: 2rem;
      margin-right: 1.5rem;
      opacity: 0.5;
    }

    span {
      margin-top: 0.3rem;
      text-align: left;

      span {
        opacity: 0.8;
      }
    }

    strong {
      margin-top: 0.3rem;
    }
  }

  ${StyledLinkBorder} {
    font-size: 1.6rem;

    ${mediaSmDefault} {
      margin-right: 3rem;
      white-space: nowrap;
    }
  }
`;

export const TableFlex = styled.div`
  flex-wrap: wrap;
  width: 100%;

  & > div {
    padding: 1rem 0;
    display: flex;
    align-items: center;

    ${mediaSmDefault} {
      margin-right: 6rem;
    }
  }

  .user {
    ${mediaSmDefault} {
      width: 20rem;
    }
  }
`;

export const TableTag = styled.span<{ color: 'white' | 'black'; background: 'yellow' | 'gray' | 'black' | 'blue' | 'green' | 'white' }>`
  display: inline-block;
  margin-top: 2rem;
  padding: 0.7rem 1.5rem;
  font-size: 1.6rem;
  line-height: 1.6rem;
  font-weight: 800;
  border-radius: 1.5rem;
  white-space: nowrap;
  transition: background 0.3s;

  ${mediaSmDefault} {
    margin-top: 0;
  }

  ${props =>
    props.color === 'black' &&
    css`
      color: ${darkBaseHEX};
    `};

  ${props =>
    props.color === 'white' &&
    css`
      color: #fff;
    `};

  ${props =>
    props.background === 'yellow' &&
    css`
      background: ${yellow};
      cursor: pointer;

      &:hover {
        background: ${darkenYellow};
      }
    `};

  ${props =>
    props.background === 'gray' &&
    css`
      background: ${grayFooter};
    `};

  ${props =>
    props.background === 'black' &&
    css`
      background: ${darkBaseHEX};
    `};

  ${props =>
    props.background === 'blue' &&
    css`
      background: ${primaryBlue};
      cursor: pointer;

      &:hover {
        background: ${darkenBlue};
      }
    `};

  ${props =>
    props.background === 'green' &&
    css`
      background: ${primaryGreen};
      cursor: pointer;

      &:hover {
        background: ${darkenGreen};
      }
    `};

  ${props =>
    props.background === 'white' &&
    css`
      background: #fff;
    `};
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 10rem);
  padding-bottom: 4rem;

  & > div {
    &:first-child {
      height: 100%;
    }
  }

  & > img {
    margin-top: 3rem;
    width: 100vw;
  }
`;

export const StyledValueComparisonSection = styled.section`
  display: flex;
  justify-content: center;
  padding-top: 4rem;
  text-align: center;
  width: 100%;
  min-height: calc(100vh - 10rem);
  position: relative;
  //margin-bottom: -3.5rem;

  ${Heading1} {
    color: #fff;
    font-size: 2.4rem;
    letter-spacing: 0.5px;
    line-height: 3.1rem;
    text-align: center;
  }

  ${CustomText} {
    margin-top: 2rem;
    font-size: 1.6rem;
    line-height: 2rem;
    text-align: center;

    a {
      color: ${yellow};

      span {
        border-bottom: 1px solid #ffde71;
        transition: border 0.3s;
      }

      &:hover {
        span {
          border-bottom: 1px solid transparent;
        }
      }
    }
  }

  ${StyledPrimaryButton}, ${StyledPrimaryButtonLink} {
    margin-top: 3rem;
    letter-spacing: 0.5px;
  }

  .lighter {
    opacity: 0.8;
  }
  
  ${Table} ~ ${Heading1} {
    margin-top: 4rem;
  }
  
  ${Heading1} ~ p {
    margin-top: 3rem;
    margin-left: auto;
    margin-right: auto;
  }
  
  ${SmallerText} ~ ${StyledPrimaryButton},
  ${SmallerText} ~ ${StyledPrimaryButtonLink} {
    margin-top: 3rem;
  }
  
  .packagePicker {
    flex-direction: column;
    
    ${mediaSmDefault} {
      flex-direction: row;
    }
  }
`;

export const BackgroundedBox = styled.div`
  border-radius: 0.4rem;
  background-color: rgba(${darkBaseRGB}, 0.25);
  box-shadow: 0 0.5rem 1rem 0 rgba(${darkBaseRGB}, 0.4);
  width: calc(100% - 3rem);
  max-width: 63rem;
  margin: 0 auto;
  padding: 3rem;

  ${Heading2} {
    font-size: 2rem;
    line-height: 2.6rem;
  }

  & > ${Heading3} {
    &:first-child {
      margin-top: 0;
    }
  }

  ${SectionText} {
    margin-top: 2rem;
  }

  & ~ .back {
    margin-top: 3rem;
  }
`;

export const StyledAddVoucher = styled.div`
  ${Heading1} ~ ${BackgroundedBox} {
    margin-top: 3rem;
  }
  
  ${StyledFormGroup} {
    margin: 0 auto 2rem;
    max-width: 41rem;;
    
    input {
      text-align: center;
    }
  }
  
  ${MoreLink} {
    text-align: center;
  }
`;

export const StyledVoucherError = styled.div`
  svg {
    margin: 6rem 0;
  }
`;

export const StyledSuccessVoucher = styled.div`
  svg {
    margin: 6rem 0 3rem;
  }
`;

export const StyledOrderVoucher = styled.div`
  .form-inline-wrapper {
    margin-top: 5rem;
    color: ${gray};
    font-size: 2rem;
    line-height: 2.5rem;
    text-align: center;

    ${mediaSmDefault} {
      text-align: left;
    }

    ${StyledFormGroupInline} {
      margin-bottom: 4rem;

      input {
        width: calc(100% - 1rem);
        border-bottom-color: rgba(126, 68, 218, 0.8);

        &:hover,
        &:focus {
          border-bottom-color: rgba(126, 68, 218, 1);
        }
      }

      ${mediaSmDefault} {
        display: inline-block;
      }
    }

    .name {
      ${mediaSmDefault} {
        ${StyledFormGroupInline} {
          &:first-of-type {
            width: 16rem;
          }

          &:last-child {
            width: 26rem;
          }
        }
      }
    }

    .address {
      ${mediaSmDefault} {
        ${StyledFormGroupInline} {
          width: 8rem;

          &:first-of-type {
            width: 24rem;
          }

          &:last-child {
            width: 18rem;
          }
        }
      }
    }

    .quantity {
      ${mediaSmDefault} {
        ${StyledFormGroupInline} {
          width: 12rem;
        }
      }
    }

    .fake-checkbox {
      &:before {
        width: 1.7rem;
        height: 1.7rem;
      }
    }

    ${StyledCheckboxInput} {
      display: inline;

      .error {
        font-size: 1.6rem;
      }
    }

    ${StyledLinkBorder} {
      color: ${yellow};
      opacity: 1;

      span {
        border-color: ${yellow};
      }

      &:hover {
        span {
          border-color: transparent;
        }
      }
    }
  }

  ${Row as any} {
    ${Col as any} {
      ${mediaSmDefault} {
        text-align: left;
      }
    }
  }

  ${PaymentInfoPrice} {
    margin-top: 4rem;

    ${mediaSmDefault} {
      text-align: left;
    }
  }

  ${StyledPrimaryButton} {
    margin-top: 2rem;
  }

  ${PaymentMethod} {
    ${mediaSmDefault} {
      justify-content: flex-start;
    }
  }

  .lighter {
    margin: 0 auto;
    max-width: 63rem;
    opacity: 0.5;
    color: ${gray};
    font-style: italic;

    ${mediaSmDefault} {
      margin-left: 0;
    }
  }

  .img-voucher {
    display: block;
    margin: 3rem auto;
    box-shadow: 0 0.3rem 1rem 0 rgba(${darkBaseRGB}, 0.5);

    ${mediaMdDefault} {
      margin: 0 auto;
    }
  }
`;

export const InvitationLink = styled.span<{ isCopied?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4rem 3rem 3rem;
  padding: 1.4rem 1.5rem;
  border-top: 1px solid rgba(${darkBaseRGB}, 0.25);
  border-bottom: 1px solid rgba(${darkBaseRGB}, 0.25);
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: rgba(${darkBaseRGB}, 0.25);
  }

  span {
    display: inline-block;
    margin-right: 2rem;
    opacity: 0.8;
    color: ${yellow};
    font-size: 1.6rem;
    line-height: 2rem;
    text-align: center;
    word-break: break-all;
  }

  svg {
    display: inline-block;
    min-width: 2.2rem;
  }

  ${props =>
    props.isCopied &&
    css`
      svg {
        path {
          fill: ${yellow};
        }
      }
    `}
`;

export const StyledShopVariant = styled.div<{ active?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0.8rem 1.6rem;
  padding: 2rem;
  background: rgba(11, 12, 28, 0.5);
  border: 2px solid transparent;
  transition: border 0.3s;

  ${mediaSmDefault} {
    min-width: 23rem;
    margin: 0 1rem;
  }

  .count {
    color: rgba(255, 255, 255, 0.7);
    font-size: 2rem;
    line-height: 2.5rem;
  }

  .price {
    margin-top: 1rem;
    color: ${yellow};
    font-size: 2rem;
    line-height: 2.5rem;
  }

  .totalPrice {
    margin-top: 1rem;
    color: rgba(255, 255, 255, 0.7);
    font-size: 1.4rem;
    line-height: 1.7rem;
  }

  ${StyledSmallButton}, ${StyledSmallButtonLink} {
    display: none;
    margin-top: 2rem;
    padding: 0 3rem;
    height: 4rem;
    border: 2px solid ${yellow};
    border-radius: 35px;
    font-size: 1.6rem;
    line-height: 2.1rem;

    ${mediaSmDefault} {
      display: inline-flex;
    }
  }

  ${props =>
    props.active &&
    css`
      border: 2px solid ${yellow};
    `}
`;

export const GraphLegendStyle = styled.div`
  ${Heading2} {
    color: ${white};
  }

  p {
    margin-top: 0;
    color: #fff;
  }
`;

export const StyledShopVariantPopup = styled.div`
  max-width: 65rem;
  margin: 0 auto;

  svg {
    width: 4.8rem;
    height: 4.8rem;

    path {
      fill: #fff;
    }
  }

  ${Heading2} {
    margin-top: 2rem;
    font-size: 2.4rem;
    line-height: 3.2rem;
    font-weight: 400;
    color: #fff;
  }

  ${SectionText} {
    margin-top: 0;

    &.smaller {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }

  .price {
    margin-top: 3rem;
    font-size: 2.4rem;
    line-height: 3.1rem;
    font-weight: 500;
    color: ${yellow};
  }

  ${StyledPrimaryButtonLink} {
    margin-top: 2rem;

    ${mediaSmDefault} {
      font-size: 2.2rem;
      letter-spacing: 0.5px;
      line-height: 2.8rem;
    }
  }

  ${StyledImgSlider} {
    max-width: 42rem;

    & > div {
      width: 100%;
      justify-content: space-between;
    }

    .slide {
      max-width: 100%;
    }

    ${StyledFakeLinkBorder} {
      display: none;
    }
  }
`;

export const StyledShopVariantPopupPackages = styled.div`
  ${Heading2} {
    margin-top: 2rem;
    font-size: 2.4rem;
    line-height: 3.2rem;
    font-weight: 400;
    color: #fff;
  }

  .flex {
    flex-direction: column;

    ${mediaSmDefault} {
      flex-direction: row;
    }
  }
`;
