import * as React from 'react';
import { FC } from 'react';
import Fade from 'react-reveal/Fade';
import TextBox from '../../../organisms/TextBox';
import { StyledVariants } from './styles';
import { Heading2, Heading3 } from '../../../atoms/Headings';
import { Col, Row } from 'react-styled-flexboxgrid';
import { SectionText } from '../../../atoms/Text';
import { PrimaryButton, PrimaryButtonLink } from '../../../atoms/Buttons';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { createUrl } from '../../routes';
import { Link } from 'react-router-dom';
import SVGgift from '../../../atoms/SVGIcons/SVGgift';
import SVGresultTwo from '../../../atoms/SVGIcons/result/SVGresultTwo';
import { Box } from '@xcorejs/ui';
import { StyledFakeLinkBorder } from '../../../atoms/Buttons/styles';
import FAQPopup from '../../../atoms/Popups/FAQPopup';
import { GetShopPrice } from 'components/organisms/Shop/config';

const Variants: FC = () => (
  <StyledVariants id='variants'>
    <Fade ssrReveal bottom duration={1000} distance='5rem'>
      <TextBox className='light-white'>
        <Heading2>
          <FormattedMessage id='vlc.variants.heading' />
        </Heading2>

        <Row className='boxes'>
          <Col xs={12} md={4}>
            <div className='card card--blue'>
              <SVGgift />
              <Heading3>
                <FormattedMessage id='vlc.variants.card2.heading' />
              </Heading3>

              <SectionText>
                <FormattedMessage id='vlc.variants.card2.text' />
              </SectionText>

              <div className='price'>
                <FormattedMessage id='vlc.variants.card2.price' />
              </div>

              <PrimaryButtonLink to={createUrl('app.comparison') + '/koupit-voucher'} className='small'>
                <FormattedMessage id='vlc.variants.card2.buy' />
              </PrimaryButtonLink>

              {/*
              <Box mt='4rem' mb='-2rem'>
                <StyledFakeLinkBorder>
                  <FAQPopup popup={Voucher}>
                    <SectionText opacity={true}>
                      <FormattedMessage id='vlc.variants.moreInfo' />
                    </SectionText>
                  </FAQPopup>
                </StyledFakeLinkBorder>
              </Box>
              */}
            </div>

            <div className='card-info'>
              <SectionText>
                <FormattedHTMLMessage id='vlc.variants.card2.package' />
              </SectionText>

              <div className='variant'>
                <div className='c2a'>
                  <Link to={createUrl('app.comparison') + '/koupit-voucher?count=3'}>3 ks</Link>
                </div>

                <div>
                  <strong>{GetShopPrice('voucher', 3) * 3} Kč</strong>
                  <span>({GetShopPrice('voucher', 3)},- Kč/ks)</span>
                </div>
              </div>

              <div className='variant'>
                <div className='c2a'>
                  <Link to={createUrl('app.comparison') + '/koupit-voucher?count=5'}>5 ks</Link>
                </div>

                <div>
                  <strong>{GetShopPrice('voucher', 5) * 5} Kč</strong>
                  <span>({GetShopPrice('voucher', 5)},- Kč/ks)</span>
                </div>
              </div>
            </div>
          </Col>

          <Col xs={12} md={4}>
            <div className='card card--cyan'>
              <SVGgift />
              <Heading3>
                <FormattedHTMLMessage id='vlc.variants.card3.heading' />
              </Heading3>

              <Box mt='1rem' mb='-2rem' mx='-1rem'>
                <img src='/img/shop/krabicka.png' alt='' />
              </Box>

              <SectionText>
                <FormattedHTMLMessage id='vlc.variants.card3.text' />
              </SectionText>

              <div className='price'>
                <FormattedMessage id='vlc.variants.card3.price' />
              </div>

              <PrimaryButton
                className='small disabled'
              >
                <FormattedMessage id='vlc.variants.card3.buy' />
              </PrimaryButton>

              <Box mb='-1rem'>
                <SectionText>
                  <FormattedMessage id='vlc.variants.card3.shipping' />
                </SectionText>
              </Box>

              {/*
              <Box mt='4rem' mb='-2rem'>
                <StyledFakeLinkBorder>
                  <FAQPopup popup={Package}>
                    <SectionText opacity={true}>
                      <FormattedMessage id='vlc.variants.moreInfo' />
                    </SectionText>
                  </FAQPopup>
                </StyledFakeLinkBorder>
              </Box>
              */}
            </div>
          </Col>

          <Col xs={12} md={4}>
            <div className='card card--green'>
              <SVGresultTwo />
              <Heading3>
                <FormattedMessage id='vlc.variants.card1.heading' />
              </Heading3>
              <SectionText>
                <FormattedMessage id='vlc.variants.card1.text' />
              </SectionText>

              <div className='price'>
                <FormattedMessage id='vlc.variants.card1.price' />
              </div>

              <PrimaryButtonLink to={createUrl('app.comparison') + '/koupit-porovnani'} className='small'>
                <FormattedMessage id='vlc.variants.card1.buy' />
              </PrimaryButtonLink>

              {/*
              <Box mt='4rem' mb='-2rem'>
                <StyledFakeLinkBorder>
                  <FAQPopup popup={ForTwo}>
                    <SectionText opacity={true}>
                      <FormattedMessage id='vlc.variants.moreInfo' />
                    </SectionText>
                  </FAQPopup>
                </StyledFakeLinkBorder>
              </Box>
              */}
            </div>

            <div className='card-info'>
              <SectionText>
                <FormattedMessage id='vlc.variants.card1.package' />
              </SectionText>

              <div className='variant'>
                <div className='c2a'>
                  <Link to={createUrl('app.comparison') + '/koupit-porovnani?count=3'}>3 ks</Link>
                </div>

                <div>
                  <strong>{GetShopPrice('comparison', 3) * 3} Kč</strong>
                  <span>({GetShopPrice('comparison', 3)},- Kč/ks)</span>
                </div>
              </div>

              <div className='variant'>
                <div className='c2a'>
                  <Link to={createUrl('app.comparison') + '/koupit-porovnani?count=5'}>5 ks</Link>
                </div>

                <div>
                  <strong>{GetShopPrice('comparison', 5) * 5} Kč</strong>
                  <span>({GetShopPrice('comparison', 5)},- Kč/ks)</span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </TextBox>
    </Fade>
  </StyledVariants>
);

export default Variants;
