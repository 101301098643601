import { Box, Button, Flex, Heading2, Typography } from '@xcorejs/ui';
import { GameContext } from 'components/App';
import { useWindowWidth } from 'components/atoms/hooks/useWindowWidth';
import { useRequest } from 'components/atoms/RequestContext';
import Subnav from 'components/atoms/Subnav';
import { PrimaryButton } from 'components/components/Button';
import withUser, { IWithUser } from 'components/molecules/withRedux/withUser';
import ValueGamePresenting, { GlowingBorder } from 'components/organisms/ValueGame/ValueGamePresenting';
import { gameLevels as data } from 'components/pages/App/ValueCenter/Game/data';
import Inventory from 'components/pages/App/ValueCenter/Game/Inventory';
import GameLevelModal from 'components/pages/App/ValueCenter/Game/Modal';
import Popup from 'components/pages/App/ValueCenter/Game/Popup';
import UI from 'components/pages/App/ValueCenter/Game/Ui';
import Welcome from 'components/pages/App/ValueCenter/Game/Welcome';
import { Darken } from 'components/pages/ValueGame/styles';
import { BlackTheme } from 'components/theme/colors';
import { OliLight, OliMedium } from 'components/theme/fonts';
import * as React from 'react';
import { FC, useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Fade from 'react-reveal/Fade';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import styled from 'styled-components';
import { updateUserLevel } from './updateUserLevel';


const Game: FC<IWithUser> = ({ user }) => {
  const { isDesktop, isMobile } = useWindowWidth(1023);
  const [hasClickedPlayButton, setHasClickedPlayButton] = useState<boolean>(sessionStorage.getItem('hasClickedStart') === 'true');

  useEffect(() => {
    const checkLocalStorage = () => {
      if (sessionStorage.getItem('hasClickedStart') === 'true') 
        setHasClickedPlayButton(true);
    };

    window.addEventListener('storage', checkLocalStorage);

    return () => {
      window.removeEventListener('storage', checkLocalStorage);
    };
  }, []);

  return (
    <>
      <Subnav />

      {hasClickedPlayButton ? (
        <Box
          bg='url(/img/hodnotova-hra-presenting/forest.jpg) no-repeat'
          width='100vw'
          height={{ _: 'calc(100vh - 5rem)', md: 'calc(100vh - 6rem)' }}
          display='flex'
          backgroundSize='cover'
          backgroundRepeat='no-repeat'
          flexDirection='column'
          justifyContent='flex-start'
          alignItems='center'
        >
          <Heading2 mt='4rem' display={{ _:'none', md: 'block' }} color={BlackTheme.text} fontFamily={OliLight}>
            <FormattedMessage id='game.page.title' />
          </Heading2>

          <Typography mt='2rem' fontFamily={OliLight} display={{ _:'none', md: 'block' }}>
            <FormattedMessage id='game.page.description' />
          </Typography>
          
          <Flex
            alignItems='center'
            justifyContent='center'
            position={{ _ :'relative', md: 'absolute' }}
            top={{ _ :'0', md: '50%' }}
            left={{ _ :'0', md: '50%' }}
            transform={{ _ :'none', md: 'translateX(-50%) translateY(-40%)' }}
            boxShadow='0px 16px 30px 0px rgba(0, 0, 0, 0.8)'
          >
            <ZoomWrapper
              user={user}
            />

            <GlowingBorder />
          </Flex>
        </Box>
      ) : isMobile ? (
        <Box
          bg='url(/img/game/hra-bg.jpg)'
          width='100vw'
          height={{ _: 'calc(100dvh - 5rem)' }}
          display='flex'
          backgroundSize='cover'
          backgroundRepeat='no-repeat'
          alignItems='flex-start'
          justifyContent='center'
        >
          <Flex
            alignItems='center'
            justifyContent='center'
            position={{ _ :'relative' }}
            top={{ _ :'0' }}
            left={{ _ :'0' }}
            transform={{ _ :'none' }}
            boxShadow='0px 16px 30px 0px rgba(0, 0, 0, 0.8)'
          >
            <ZoomWrapper
              user={user}
            />
          </Flex>
        </Box>
      ) : isDesktop ? (
        <Flex
          background={{ _: 'url(/img/game/hra-bg.jpg)', md:'url(/img/hodnotova-hra-presenting/forest.jpg) no-repeat' }}
          backgroundSize='cover'
          backgroundRepeat='no-repeat'
          flexDirection={{ md:'column' }}
          height={{ md:'calc(100vh - 6rem)' }}
          alignItems={{ md: 'center' }}
          justifyContent='center'
          width='100vw'
        >
          <Box position='relative'>
            <ValueGamePresenting withMenu onlyOnce insideApp />
          </Box>
          <Darken darker direction='bottom' />
        </Flex>
      ) : null }
    </>
  );
};

export default withUser(Game);

interface ZoomWrapperProps extends Pick<IWithUser, 'user'> {}

const ZoomWrapper: FC<ZoomWrapperProps> = ({ user }) => {
  const { cookies, setCookie } = useRequest();
  const levels = data;
  const { insideQuest, userLvl, questLvl, gamePopup } = useContext(GameContext);
  
  const [welcomeModal, setWelcomeModal] = useState(true);
  
  const [smallScreen, setSmallScreen] = useState(false);
  useEffect(() => {
    setSmallScreen(window.innerWidth >= 1024 && window.innerHeight < 768);
  }, []);

  useEffect(() => {
    if (userLvl > user.level) {
      updateUserLevel(user, userLvl);
    }
  }, [userLvl, user.level, user]);
  
  return (
    <ZoomWrapperStyle>
      <TransformWrapper
        pan={{
          disabled: true,
          velocity: false,
        }}
        pinch={{ disabled: true }}
        doubleClick={{ disabled: true }}
        wheel={{
          wheelEnabled: false,
          touchPadEnabled: false
        }}
        positionX={insideQuest ? levels[questLvl].map.zoomX : 0 }
        positionY={insideQuest ? (smallScreen ? -480 : -600) + levels[questLvl].map.zoomY : 0 }
        scale={insideQuest ? 2 : 1}
      >
        <TransformComponent>
          <Box width='37.5rem' maxWidth='100%' mx='auto' height={{ _: 'calc(100vh - 5rem)', sm: 'calc(100vh - 6rem)' }} maxHeight='60rem' position='relative'>
            <Inventory />

            <UI />
          </Box>
        </TransformComponent>
      </TransformWrapper>

      <Buttons user={user} />

      {userLvl === 0 && welcomeModal && cookies.showWelcomeModal !== '1' && (
        <Box
          width='100%'
          height='100%'
          position='absolute'
          left='0'
          top='0'
          zIndex={1000}
          _before={{
            content: '\'\'',
            position: 'absolute',
            width: '100%',
            height: '100%',
            display: 'block',
            background: 'rgba(11, 12, 28, 0.9)',
            alignItems: 'flex-start',
            opacity: 1,
            transition: 'opacity 500ms',
          }}
        >
          <Fade top ssrReveal duration={1000} delay={2000} distance='5rem'>
            <Welcome
              user={user}
              close={() => {
                setWelcomeModal(false);
                setCookie('showWelcomeModal', '1', {
                  expires: 365,
                });
              }
              }
            />
          </Fade>
        </Box>
      )}

      {gamePopup && (
        <Popup />
      )}

      {insideQuest && (
        <Box
          padding={0}
          overflow='auto'
          _before={{
            content: '\'\'',
            position: 'absolute',
            width: '100%',
            height: '100%',
            display: 'block',
            background: 'rgba(11, 12, 28, 0.95)',
            alignItems: 'flex-start',
            opacity: insideQuest ? 1 : 0,
            transition: 'opacity 500ms',
          }}
          // maxWidth='37.5rem'
          background='transparent'
          position='absolute'
          left='-1px'
          right='-1px'
          top='0'
          height='100%'
          // width='100%'
          maxHeight='60rem'
          opacity={insideQuest ? 1 : 0}
          pointerEvents={insideQuest ? 'all' : 'none'}
          zIndex={1000}
          display='flex'
          flexDirection='column'
        >
          <GameLevelModal user={user} />
        </Box>
      )}
    </ZoomWrapperStyle>
  );
};


export const PinStyle = styled.div`
  & > div {
    animation-name: pulse;
    animation-duration: 3s;
    animation-iteration-count: infinite;

    &:hover {
      animation-name: unset;
    }

    @keyframes pulse {
      from { transform: scale(1.2); }
      50% { transform: scale(1); }
      to { transform: scale(1.2); }
    }

    @keyframes spin {
      100% {
        transform: rotateZ(360deg);
      }
    }
  }
`;

export const ZoomWrapperStyle  = styled.div`
  width: 37.5rem;
  margin: 0 auto;
  position: relative;

  & > div {
    & > div {
      transition: transform 500ms;
    }
  }
`;

const Buttons: FC<ZoomWrapperProps> = ({ user }) => {
  const { userLvl, questLvl, setUserLvl, testMode, setTestMode } = useContext(GameContext);

  return (
    <Flex flexDirection='column' position='absolute' right={0} bottom={0} zIndex={1000}>
      <Button
        size='xs'
        onClick={() => setTestMode(!testMode)}
        bg='transparent'
        color='#fff'
        opacity={0.2}
      >
        test
      </Button>

      {testMode && (
        <>  
          <PrimaryButton
            size='xs'
            onClick={() => setUserLvl(userLvl + 1)}
            bg='transparent'
            color='#fff'
          >
        +
          </PrimaryButton>

          <PrimaryButton
            size='xs'
            onClick={() => setUserLvl(userLvl - 1)}
            bg='transparent'
            color='#fff'
          >
        -
          </PrimaryButton>

          <StyledInput
            type='number'
            onChange={(e) => {
              const value = parseInt(e.target.value);
              if (isNaN(value)) return;
              if (value < 0) setUserLvl(0);
              else if (value > data.length - 1) setUserLvl(data.length - 1);
              else setUserLvl(value); 
            }} 
            onFocus={(e) =>  e.target.value = '' }
          />
          
          <PrimaryButton
            size='xs'
            onClick={() => updateUserLevel(user, userLvl)}
            bg='transparent'
            color='#fff'
          >
            Force
          </PrimaryButton>
        </>
      )}
    </Flex>
  );
};

const StyledInput = styled.input`
  width: 5rem;
  height: 5rem;
  background: transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  border: 0;
  font-family: ${OliMedium};
  color: #fff;
  text-align: center;

  -moz-appearance: textfield;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  :hover, :focus {
    background: #FFA4A3;
  }
`;
