import * as React from 'react';
import { jsonFetch } from '../../utils/fetch';
import { createUrl } from './routes';
import { StyledRegistrationFull } from './styles';
import { Heading1 } from '../atoms/Headings';
import { FormattedMessage } from 'react-intl';
import { Col, Row, Grid } from 'react-styled-flexboxgrid';
import { StyledLink } from '../atoms/Buttons/styles';
import { StyledCheckboxInput } from '../atoms/FormItems/styles';
import { PrimaryButtonButton } from '../atoms/Buttons';
import wrapIntl, { IWrapIntl } from '../atoms/wrapIntl';
import Fade from 'react-reveal/Fade';
import { CustomText } from '../atoms/Text';
import { FormInput } from '../atoms/Form/FormInput';

export const loginSocial = async (url: string, onSuccess: (redirect: string) => any, onError: (e: string) => any) => {
  try {
    const res = await jsonFetch<any>(url, {
      method: 'POST',
    });
    if (!res.redirect) {
      throw res;
    }
    onSuccess(res.redirect);
  } catch (e) {
    const json = await e.data.json();
    if (json.error) {
      onError(json.error);
    }
  }
};

interface ILoginProps {
  addInfo: (msg: string) => any;
}

interface ILoginState {
  email: string;
  password: string;
  rememberPassword: boolean;
  error: string | null;
}

class RegistrationFull extends React.Component<ILoginProps & IWrapIntl, ILoginState> {
  constructor(props: ILoginProps & IWrapIntl) {
    super(props);
    this.state = {
      email: '',
      password: '',
      rememberPassword: false,
      error: null,
    };
    this.displayError = this.displayError.bind(this);
  }

  public handleInputChange = (e: any) => {
    this.setState({
      [e.target.name as any]: e.target.value,
    } as any);
  };

  public displayError(error: string) {
    this.setState({ ...this.state, error });
  }

  public render() {
    return (
      <StyledRegistrationFull>
        <Grid>
          <Row>
            <Col xs={12}>
              <Fade ssrReveal bottom duration={1000} distance={'5rem'}>
                <img src={'/img/logo-big.svg'} className={'logo'} alt='Eticia' />
              </Fade>
              <Fade ssrReveal bottom duration={1000} delay={200} distance={'5rem'}>
                <div className='heading'>
                  <Heading1>
                    <FormattedMessage id={'registrationclosed.entrance'} />
                  </Heading1>
                  <p>
                    <FormattedMessage id={'login.alreadyAccount'} />{' '}
                    <StyledLink to={createUrl('login')}>
                      <FormattedMessage id={'login.alreadyAccount.login'} />
                    </StyledLink>
                  </p>
                </div>
              </Fade>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={8} mdOffset={2}>
              <Fade ssrReveal bottom duration={1000} delay={200} distance={'5rem'}>
                <CustomText>
                  <FormattedMessage id={'registrationclosed.text'} />
                </CustomText>
              </Fade>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={4} mdOffset={4}>
              <Fade ssrReveal bottom duration={1000} delay={400} distance={'5rem'}>
                <form>
                  <div className='error'>{this.state.error !== null ? this.state.error : ''}</div>

                  <FormInput
                    type='email'
                    name='email'
                    text='E-mail'
                    id='e-mail'
                    onChange={this.handleInputChange}
                    value={this.state.email}
                  />

                  <StyledCheckboxInput>
                    <input
                      type='checkbox'
                      id='remember-password'
                      name='rememberPassword'
                      checked={this.state.rememberPassword}
                      onChange={this.handleInputChange}
                    />
                    <label htmlFor={'remember-password'}>
                      <span className={'fake-checkbox'} />
                      <FormattedMessage id={'password.remember'} />
                    </label>
                  </StyledCheckboxInput>

                  <div>
                    <PrimaryButtonButton type='submit'>
                      <FormattedMessage id={'login'} />
                    </PrimaryButtonButton>
                  </div>

                  <StyledLink to={createUrl('')}>
                    <FormattedMessage id={'backToIntro'} />
                  </StyledLink>
                </form>
              </Fade>
            </Col>
          </Row>
        </Grid>
      </StyledRegistrationFull>
    );
  }
}

export default wrapIntl(RegistrationFull);
