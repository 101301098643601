import * as React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { SecondaryButton, PrimaryButtonButton } from '../../../../../atoms/Buttons';
import { IStringMap } from '../../../../../../utils';
import { IStep1Data } from './actions';
import { BiggerText } from '../../../../../atoms/Text';
import wrapIntl, { IWrapIntl } from '../../../../../atoms/wrapIntl';
import { Heading1 } from '../../../../../atoms/Headings';
import InlineFormSelect from '../../../../../atoms/InlineForm/InlineFormSelect';
import { Option } from 'react-select';

interface IStep1 {
  data: IStep1Data;
  handleInput: (name: string, value: string) => unknown;
  setState: (newState: IStep1Data) => any;
  submit: () => any;
}

interface IStep1State {
  errors: IStringMap<string | undefined>;
}

const prices: IStringMap<string> = {
  s: '250',
  m: '350',
  l: '450',
};

const isDisabled = (data: IStep1Data) => data.color !== '' && !!data.price && !!data.size;

class Step1 extends React.Component<IStep1 & IWrapIntl, IStep1State> {
  public state: IStep1State = { errors: {} };

  public render() {
    const { data, handleInput, setState, submit, formatMsg } = this.props;
    const { errors } = this.state;

    return (
      <form
        onSubmit={e => {
          e.preventDefault();
          this.setState({
            errors: {
              color: data.color === '' ? formatMsg('support.tshirt.errors.color') : undefined,
              price: !data.price ? formatMsg('support.tshirt.errors.price') : undefined,
              size: !data.size ? formatMsg('support.tshirt.errors.size') : undefined,
            },
          });
          return isDisabled(data) && submit();
        }}
      >
        <Heading1 className={'not-mobile'}>
          <FormattedMessage id={'support.heading.step1'} />
        </Heading1>
        <BiggerText>
          <FormattedHTMLMessage id={'support.text.step1'} />
        </BiggerText>
        <div className='form-inline-wrapper'>
          <div className='form-inline'>
            <FormattedMessage id={'support.tshirt'} />{' '}
            <InlineFormSelect
              name='color'
              placeholder={formatMsg('support.tshirt.type')}
              error={errors.color}
              onChange={o => handleInput('size', (o as Option).value as any)}
              value={data.color}
              options={[
                { value: 0, label: 'Černé' },
                { value: 1, label: 'Modré' },
              ]}
            />
            <InlineFormSelect
              name='size'
              placeholder={formatMsg('support.tshirt.size')}
              error={errors.size}
              onChange={o => handleInput('size', (o as Option).value as any)}
              value={data.size}
              options={[
                { value: 's', label: 'S' },
                { value: 'm', label: 'M' },
                { value: 'l', label: 'L' },
                { value: 'xl', label: 'XL' },
                { value: 'xxl', label: 'XXL' },
              ]}
            />
          </div>

          <div className='form-inline'>
            <FormattedMessage id={'support.tshirt.donate'} />{' '}
            {Object.keys(prices).map((k, key) => (
              <SecondaryButton
                key={key}
                onClick={() => setState({ ...data, price: prices[k] })}
                className={data.price === prices[k] ? 'active' : ''}
              >
                <FormattedMessage id={'support.tshirt.donate.' + k} />
              </SecondaryButton>
            ))}
            {errors.price && <div className='error'>{errors.price}</div>}
          </div>
        </div>

        {data.color !== '' && (
          <div className={'tshirt-description'}>
            <BiggerText>
              <FormattedMessage id={'support.tshirt.' + data.color} />
            </BiggerText>
          </div>
        )}

        <PrimaryButtonButton>
          <FormattedMessage id={'support.tshirt.donate.buy'} />
        </PrimaryButtonButton>
      </form>
    );
  }
}

export default wrapIntl(Step1);
